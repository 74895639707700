import { GameIds } from '../enums';
import { IBaseItemDto } from './games';

export interface ISkinItem {
  price: number;
  icon: string;
  available: boolean;
  color: string;
  name: string;
  shortName: string;
  skin: string;
  exterior: string;
  statTrak: boolean;
  weapon: string;
  id: number;
  appId?: GameIds;
  purchased?: boolean;
  passed?: boolean;
  virtual?: boolean;
  coinsAmount?: number;
  selectedGameId?: number;
  steamInventoryId?: number;
  type: string | null;
  phase?: string;
  rarity: string;
}
export interface ISkinItemV2 {
  id: number;
  baseItem: IBaseItemDto;
  price: number;
  steamPrice: number;
  available: boolean;
}
export interface ISkinStoreParams {
  sortBy: string;
  appId: GameIds;
  page?: {
    number: number;
    size: number;
  };
  price?: {
    min: number;
    max: number;
  };
}
