<ng-container *ngIf="caseItem">
  <div class="case-content-container">
    <app-ui-case-card
      [item]="caseItem"
      [isHovered]="false"
      [showDescription]="false"
      [showRevision]="false"
      [showTags]="false"
    >
    </app-ui-case-card>
    <div class="case-stats-container">
      <div class="stats-title">
        <div class="case-name">{{ caseItem.name }}</div>
        <ng-container *ngIf="caseItem.type !== caseTypeEnum.FREE">
          <div class="case-price">{{ activeRevision?.revision?.price || caseItem.lastRevision.price | appCurrency }}</div>
        </ng-container>
      </div>
      <ng-container *ngIf="activeRevision">
        <div class="stats-description">
          <div class="date-label">{{ 'LOCAL.CASES.MODAL.REVISION.PERIOD' | translate }}:</div>
          <div class="revision-period">
            <div>
              <span class="period-date">{{ activeRevision.revision.createdAt | date: 'dd.MM.yyyy' }} </span>
              <span class="period-time"> {{ activeRevision.revision.createdAt | date: '(hh:mm:ss)' }}</span>
            </div>
            <div>
              <span class="period-date">{{ activeRevision.revision.updatedAt | date: 'dd.MM.yyyy' }}</span>
              <span class="period-time">{{ activeRevision.revision.updatedAt | date: '(hh:mm:ss)' }}</span>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="selectedCases">
        <div class="buttons-wrapper">
          <div class="left-wrapper">
            <button
              class="btn btn-primary"
              [disabled]="selectedCases.length >= 10"
              (click)="addCase(caseItem)"
            >
              {{ 'LOCAL.CASES.MODAL.REVISION.ADD_TO_BATTLE' | translate }}
            </button>
          </div>
          <div class="right-wrapper">
            <a
              class="btn btn-secondary"
              langRouterLink
              [routerLink]="['/game/cases/case', caseItem.id]"
              (click)="closeModal()"
              >{{ 'LOCAL.CASES.MODAL.REVISION.OPEN' | translate }}</a
            >
            <button
              class="btn table-btn"
              [class.table]="isTable"
              (click)="toggleListView()"
            >
              <ng-container *ngIf="!isTable">
                <mat-icon
                  class="icon"
                  [svgIcon]="'sort-block'"
                ></mat-icon>
              </ng-container>
              <ng-container *ngIf="isTable">
                <mat-icon
                  class="icon"
                  [svgIcon]="'sort-table'"
                ></mat-icon>
              </ng-container>
            </button>
          </div>
        </div>
      </ng-container>
    </div>
    <ng-container *ngIf="showSelectRevision && revisionsList && caseItem.type !== caseTypeEnum.FREE">
      <div class="case-revision-container">
        <div class="range_change-label">{{ 'LOCAL.CASES.MODAL.REVISION.REVISION' | translate }}:</div>
        <div class="range_change-filter">
          <button
            class="btn select-btn"
            (click)="menuToggle(true)"
            [matMenuTriggerFor]="menu"
          >
            {{ selectedRevision }}
            <mat-icon
              class="menu-icon-toggle"
              [class]="isOpenMenu ? 'open' : 'close'"
              [svgIcon]="'chevron-left'"
            >
            </mat-icon>
          </button>
          <mat-menu
            (closed)="menuToggle(false)"
            class="mat-menu-revision"
            #menu="matMenu"
            xPosition="after"
          >
            <ng-scrollbar [autoHeightDisabled]="false">
              <button
                mat-menu-item
                *ngFor="let revision of revisionsList"
                (click)="getCaseRevision(revision.value)"
              >
                {{ revision.viewValue }}
              </button>
            </ng-scrollbar>
          </mat-menu>
        </div>
      </div>
    </ng-container>
  </div>
</ng-container>
