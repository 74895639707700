import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, NgModule } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';

import { LazyLoadImageModule, SkinItemImageModule } from '@app/shared/directives';
import { AppCurrencyModule } from '@app/shared/pipes';
import { colorDictionary, toShortRevisionWear } from '@app/shared/utils';

@Component({
  selector: 'app-ui-skin-card',
  templateUrl: './skin-card.component.html',
  styleUrls: ['./skin-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SkinCardComponent {
  @Input() index = 0;
  @Input() weaponName: string | null = null;
  @Input() skinName: string | null = null;
  @Input() skinWear: string | null = null;
  @Input() statTrak = false;
  @Input() price = 0;
  @Input() chance = 0;
  @Input() avatarSrc: string | null = null;
  @Input() backgroundColor: string | null = null;
  @Input() range?: { min: number | undefined; max: number | undefined };

  indexColor(index: number): string {
    return index % 2 === 0 ? 'skin-item__wrapper_light' : 'skin-item__wrapper_dark';
  }
  calcChancePercent(count: number): string {
    return `${count / 1000} %`;
  }
  getStyle(color: string): { background: string } {
    const colorDTO = color;
    const newColor = colorDictionary[colorDTO.toUpperCase()];
    return {
      background: `linear-gradient(180.57deg, #213137 0%, ${newColor} 100%)`,
    };
  }
  revisionWear(exterior: string): string {
    return toShortRevisionWear(exterior);
  }
}
@NgModule({
  imports: [CommonModule, TranslateModule, LazyLoadImageModule, AppCurrencyModule, MatTooltipModule, SkinItemImageModule],
  declarations: [SkinCardComponent],
  exports: [SkinCardComponent],
})
export class SkinCardModule {}
// единственная проблема может быть с шаред стилями вродек flex и тд
