import { InjectionToken } from '@angular/core';
import { Design } from '@dev-fast/types';

import packageJson from '../../../../../package.json';

export interface Environments {
  // Версия проекта
  VERSION: string;
  production: boolean;
  // Имя билда (stage, develop, prod, prod-eu)
  BUILD_NAME: string;
  // Имя проекта (market, csgofast, csgofast-new)
  PROJECT: string;
  // Имя нашего клиента. Используется для сентри и локалей (по дефолту везде должен быть csgofast)
  CLIENT_PROJECT: string;
  // Доменное имя. Вставляется повсюду от title до реферальных ссылок
  HOSTNAME: string;
  // Ссылка на бэкбон проект, который вставляется через фрейм
  BACKBONE_URL?: string;
  // Ссылка на дефолтную картинку. Вставляется через директиву если не удается загрузить любую другую картинку
  CHIP_FALLBACK_URL: string;
  // адрес на API локалей
  LOCALIZATION_URL: string;
  // Почта техподдержки
  SUPPORTS_EMAIL: string;
  // Адрес до нашего бэкенда
  GATEWAY_URL: string;
  // Адрес для сокетов
  WS_GATEWAY_URL: string;
  // Адрес для запросов связанных с платежкой
  PAYMENT_URL: string;
  // Адрес для запросов связанных с платежкой
  PAYMENT_URL_2: string;
  // Адрес для запросов связанных с выводами
  WITHDRAWAL_URL: string;
  // Адрес до сентри (TODO: нужно донастроить)
  SENTRY_DSN: string;
  // Включаем ли мы логи на сайте (TODO: LOG_ACTIONS не используется)
  LOG_ACTIONS?: boolean;
  // Ссылка на POGGI режим
  POGGI_URL?: string;
  FULL_LOGIN_FLOW: boolean;
  // Ссылка на игру ESPORTS которой пока нет (TODO: ESPORTS_API_URL не используется)
  ESPORTS_API_URL?: string;
  // Настройки recaptcha
  RECAPTCHA_SITE_KEY: string;
  // Внешние URL SIH (Steam Inventory Helper)
  SIH_STAT_API_URL: string;
  SIH_API_URL: string;
  // TODO: выпилить AVAILABLE_DESIGNS
  AVAILABLE_DESIGNS: Design[];
  MODULES: IModules;
  IS_NEW_WALLETS: boolean;
  hmr: boolean;
}

export interface IModules {
  games?: string[];
  account?: {
    main?: string[];
    balance?: string[];
    settings?: string[];
    levels?: string[];
    statistics?: string[];
    premium?: string[];
    referrals?: string[];
  };
  transaction?: {
    refill?: string[];
    withdrawal?: string[];
  };
  soundToggler?: boolean;
  widgets?: string[];
  inventory?: { [subKey: string]: string[] };
}

export const ENVIRONMENTS = new InjectionToken<Environments>('ENVIRONMENTS');

export const ENVIRONMENTS_DEFAULT_FAST: Environments = {
  BUILD_NAME: '',
  production: false,
  SENTRY_DSN: 'https://2c8a1ff49d984c87b4aa0666ec916de4@sentry.gogologins.com/3',
  LOG_ACTIONS: false,
  FULL_LOGIN_FLOW: true,
  WS_GATEWAY_URL: 'https://back-new.devfast.io',
  GATEWAY_URL: 'https://back-new.devfast.io',
  BACKBONE_URL: 'https://bridge.devfast.io',
  ESPORTS_API_URL: `https://back-new.devfast.io/esports-api`,
  PAYMENT_URL: 'https://pay.csgofast.com',
  PAYMENT_URL_2: 'https://pay.csgofast.com',
  WITHDRAWAL_URL: 'https://back-new.devfast.io',
  LOCALIZATION_URL: 'https://locales.csgofastbackend.com/api',
  RECAPTCHA_SITE_KEY: '6Lego1oUAAAAAAY8jdGEFL2zfNI-JyDnIIW-C4k8',
  SIH_STAT_API_URL: 'https://graph.sih.app',
  SIH_API_URL: 'https://api.steaminventoryhelper.com',
  SUPPORTS_EMAIL: 'support@csgofast.com',
  HOSTNAME: ``,
  PROJECT: 'csgofast',
  CLIENT_PROJECT: 'renew-fast', //'csgofast',
  VERSION: packageJson.version,
  POGGI_URL: 'https://slots-test.csgofastbackend.com',
  CHIP_FALLBACK_URL: '//d2lomvz2jrw9ac.cloudfront.net/common/currency/money-bag-pngrepo-com.png',
  MODULES: {
    account: {
      main: [],
      // settings: ['mail', 'steamLinks'],
      balance: [],
      // levels: [],
      // premium: [],
      // statistics: [],
      // referrals: [],
    },
    widgets: ['chat'],
    inventory: {},
    transaction: {
      withdrawal: [],
      refill: [],
    },
    games: ['classic', 'double', 'cases', 'poggi', 'crash', 'x50', 'case_battle'],
  },
  AVAILABLE_DESIGNS: [Design.NEW, Design.OLD],
  IS_NEW_WALLETS: true,
  hmr: false,
};

// Отдельный дефолтный environment для маркета, чтобы случайно данные с дефолтного environment фаста не попали в маркет
export const ENVIRONMENTS_DEFAULT_MARKET: Environments = {
  BUILD_NAME: '',
  PROJECT: 'market',
  CLIENT_PROJECT: 'csmarket',
  HOSTNAME: ``,
  production: false,
  LOG_ACTIONS: false,
  FULL_LOGIN_FLOW: true,
  SUPPORTS_EMAIL: 'support@csmarket.gg',
  LOCALIZATION_URL: 'https://locales.csmarket.gg/api',
  PAYMENT_URL: 'https://pay.csmarket.gg',
  PAYMENT_URL_2: 'https://pay.csmarket.gg',
  WITHDRAWAL_URL: 'https://back-new.devfast.io',
  CHIP_FALLBACK_URL: '//d2lomvz2jrw9ac.cloudfront.net/common/currency/money-bag-pngrepo-com.png',
  VERSION: packageJson.version,
  SIH_STAT_API_URL: 'https://graph.sih.app',
  SIH_API_URL: 'https://api.steaminventoryhelper.com',
  MODULES: {
    account: {
      main: [],
      balance: [],
    },
    widgets: ['chat'],
    transaction: {
      refill: [],
    },
  },
  IS_NEW_WALLETS: true,
  // TODO: перебрать то, что не нужно на маркете
  SENTRY_DSN: 'https://2c8a1ff49d984c87b4aa0666ec916de4@sentry.gogologins.com/3',
  WS_GATEWAY_URL: 'https://back-new.devfast.io',
  GATEWAY_URL: 'https://back-new.devfast.io',
  RECAPTCHA_SITE_KEY: '6Lego1oUAAAAAAY8jdGEFL2zfNI-JyDnIIW-C4k8',
  AVAILABLE_DESIGNS: [Design.NEW, Design.OLD],
  hmr: false,
};
