export enum GameSoundGroup {
  CASES_GROUP = 'CASES_GROUP',
  CSGO_CASES = 'CSGO_CASES',
  CLASSIC_GAME = 'CLASSIC_GAME',
}
export enum ActiveSoundGame {
  CASES_ACTIVE_SOUND = 'active_cases_sound',
  CASE_BATTLE_ACTIVE_SOUND = 'active_battle_sound',
  BLACK_FRIDAY_EVENT = 'black_friday_event_sound',

  // CASES_GROUP = 'CASES_GROUP',
  // CSGO_CASES = 'CSGO_CASES',
}
export enum SoundNameFast {
  STANDART = 'STANDART',
  JINGLE = 'JINGLE',
  DANGER = 'DANGER',
  MIX = 'MIX',
  RANDOM = 'RANDOM',
}

export enum SoundName {
  // ----- AWARDED -----
  CSGO_AWARDED_0 = 'CSGO_AWARDED_0',
  CSGO_AWARDED_1 = 'CSGO_AWARDED_1',
  CSGO_AWARDED_2 = 'CSGO_AWARDED_2',
  CSGO_AWARDED_3 = 'CSGO_AWARDED_3',
  CSGO_AWARDED_4 = 'CSGO_AWARDED_4',
  CSGO_AWARDED_5 = 'CSGO_AWARDED_5',

  // ----- DROP -----
  CSGO_DROP_0 = 'CSGO_DROP_0',
  CSGO_DROP_1 = 'CSGO_DROP_1',
  CSGO_DROP_2 = 'CSGO_DROP_2',
  CSGO_DROP_3 = 'CSGO_DROP_3',
  CSGO_DROP_4 = 'CSGO_DROP_4',
  CSGO_DROP_5 = 'CSGO_DROP_5',
  CSGO_DROP_6 = 'CSGO_DROP_6',
  CSGO_DROP_CASEITEM = 'CSGO_DROP_CASEITEM',

  // ----- HOVER -----
  CSGO_HOVER = 'CSGO_HOVER',

  // ----- INSPECT -----
  CSGO_INSPECT = 'CSGO_INSPECT',

  // ----- OPEN -----
  CSGO_OPEN = 'CSGO_OPEN',

  // ----- SHOW -----
  CSGO_SHOW_ITEM_0 = 'CSGO_SHOW_ITEM_0',
  CSGO_SHOW_ITEM_1 = 'CSGO_SHOW_ITEM_1',

  // ----- TICK -----
  CSGO_TICK = 'CSGO_TICK',

  // ----- UNLOCK -----
  CSGO_UNLOCK_LONG = 'CSGO_UNLOCK_LONG',
  CSGO_UNLOCK_SHORT = 'CSGO_UNLOCK_SHORT',

  // ----- WINNING -----
  WINNING = 'WINNING',
}
