import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { IFilterFormTemplate } from '@dev-fast/types';

import { DFormControlType, IDFormControl, IDFormPrepared } from '@app/core/dynamic-forms-service';

/**
 * Больной компонент фильтров. Содержит в себе кучу контролов: список чекбоксов, поле числа, ползунка
 */
@Component({
  selector: 'app-ui-filter-panel',
  templateUrl: './filter-panel.component.html',
  styleUrls: ['./filter-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterPanelComponent {
  // Текущий фильтр
  @Input() filterForm: FormGroup<IFilterFormTemplate> | undefined;
  // Контролы в формате DynamicForms, из которых формировалась this.filterForm
  @Input() set filterControls(dForm: IDFormPrepared) {
    this.localeRoot = dForm.localeRoot;
    this.controls = this.groupItems(dForm.controls).map((item: any) => item.group);
    this.disabledParams = dForm.disableCountParams;
  }
  // Максимальная цена товара с учетом всех фильтров. Нужна для плейсхолдера в фильтры
  @Input() maxAvailablePrice!: number | null | undefined;
  // сгруппированые контролы
  controls!: any;
  // корень для локалей
  localeRoot!: string;
  // Список свойств, которые не нужно считать чтобы показать примененные фильтры
  disabledParams: string[] = [];

  // Consts
  readonly types = DFormControlType;

  /**
   * Переданные контролы группируем по свойству group
   */
  groupItems(items: { [key: string]: { control: FormControl; params: [IDFormControl] } }): any[] {
    return Object.values(items).reduce((result: any, data: any) => {
      // NOTE: params - массив полей которые ссылаются на один и тот же control
      // (в теории ползунок и ввод числа могут одно и то же значение менять)
      // Я под это пока функционал не закладывал, так что беру лишь [0] элемент, подразумивая что он там один.
      // Но в случае нужды можно расширить
      const item = data.params[0];

      // Группировка элементов
      if (!item.group) {
        if (
          !result.find(
            (group: any) =>
              group.name === null && group.group.find((control: any) => control.type === item.type && control.name === item.name),
          )
        ) {
          result.push({ name: null, group: [item] });
        }
      } else {
        const group = result.find((groupItem: any) => groupItem.name === item.group);
        if (group) {
          if (!group.group.find((control: any) => control.type === item.type && control.name === item.name)) {
            group.group.push(item);
          }
        } else {
          result.push({ name: item.group, group: [item] });
        }
      }
      return result;
    }, []);
  }
}
