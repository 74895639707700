<ng-container *ngIf="user()">
  <ng-container *ngIf="hasSih(); else noSihTemplate">
    <div class="sih-button__wrapper">
      <div class="sih-button__icon">
        <app-sih-custom-icon></app-sih-custom-icon>
      </div>
      <span class="sih-button__text">
        {{ 'SIH.SELL.ACTIVE' | translate }}
      </span>
    </div>
  </ng-container>
  <ng-template #noSihTemplate>
    <div
      class="sih-button__wrapper sih-button__wrapper--noSih"
      (click)="onOpenSihInstallModal()"
    >
      <mat-icon
        class="steam-icon"
        [svgIcon]="'steam-bold'"
      ></mat-icon>
      <span class="sih-button__text">
        {{ 'SIH.SELL.SHOWN' | translate }}
        {{ availableItems + '/' + totalItems() }}
      </span>
    </div>
  </ng-template>
</ng-container>
