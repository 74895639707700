/**
 * Данные для построения графика спха
 */
export const P2P_ITEM_CHART_OPTIONS = {
  responsive: true, // Говорим графике, что ему нужно подстраиваться под разные резрешения
  // NOTE: responsive: true менее оптимизированна, т.к. пересчитывает отрисовку всего графика
  // В идеале делать графики одинаковые на всех разрешениях (что вряд-ли возможно с нашим дизайном)

  maintainAspectRatio: false, // Это позволяет графику растягиваться в высоту
  scales: {
    x: {
      ticks: {
        display: false, // прячем подписи снизу
      },
      grid: {
        display: false, // Прячем сетку по x
      },
    },
    y: {
      beginAtZero: true, // Это гарантирует, что график начнется с 0$ и 0 продаж и не будет показывать отрицательные значения
      ticks: {
        display: false, // прячем подписи слева
      },
      grid: {
        // Делаем цветной лишь центральную линию
        color: (context: any) => {
          if (context.index === 2) {
            return '#354855';
          }
          return 'transparent';
        },
      },
      border: {
        dash: [4, 4], // Делаем центральную линию прерывистой
      },
    },
  },
  elements: {
    point: {
      radius: 0, // убираем явное отображение точек на графике (остается лишь при наведении)
    },
  },
  interaction: {
    // При наведении в тултип показываем все данные по y
    intersect: false,
    mode: 'index' as any,
  },
  plugins: {
    tooltip: {
      callbacks: {
        label: (context: any) => {
          if (context?.datasetIndex === 1) {
            return '$' + (context?.parsed?.y / 100).toFixed(2);
          }
          return context?.parsed?.y || '';
        },
      },
    },
    legend: {
      display: false,
      labels: {
        boxWidth: 0,
        usePointStyle: true,
      },
    },
  },
};

/**
 * Стили для отдельных данных. [0] для продаж, [1] для цен
 */
export const P2P_ITEM_CHART_DATASETS = [
  {
    data: [],
    label: '',
    fill: false,
    tension: 0,
    borderColor: '#0D91EC',
    backgroundColor: 'rgba(0,0,0,0)',
    pointBorderColor: '#0D91EC',
    pointBackgroundColor: '#0D91EC',
  },
  {
    data: [],
    label: '',
    fill: false,
    tension: 0,
    borderColor: '#23ff00',
    backgroundColor: 'rgba(0,0,0,0)',
    pointBorderColor: '#23ff00',
    pointBackgroundColor: '#23ff00',
  },
];
