<!-- Что рефачить:
1) Есть блок с "настройте стим, бан" и т.д. он минимум используется в трех местах: в п2п, в модалке скина, здесь
2) Почему бы не вынести в отдельный компонент доп настройки
3) Попадался increase компонент для красивых счетчиков валюты-->
<div class="lotSetupPanel tw-flex tw-flex-col tw-overflow-hidden tw-fixed tw-top-0 tw-right-0 tw-h-screen">
  <div class="lotSetupPanel-title tw-flex tw-items-center tw-justify-between tw-pb-8 tw-pr-5 tw-font-bold tw-text-base">
    <div>{{ 'P2P_WIDGETS.DEPOSITING.SIDE_HEADER' | translate }}</div>
    <div
      class="close-btn tw-cursor-pointer"
      (click)="onPanelClose()"
    >
      <mat-icon svgIcon="close" />
    </div>
  </div>
  <app-p2p-sell-adviser
    class="tw-pr-5 tw-pb-6"
    [selected]="!!selectedItems"
    [selectedSum]="selectedSum"
    (goToSteamInventoryMarketTab)="goToInventoryMarketTab()"
    (reqAutoSelect)="updateAutoSelected($event)"
  />
  <ng-scrollbar
    class="lotSetupPanel-content hide-scroll tw-h-full"
    *ngIf="selectedItems && lotDepositSettings"
  >
    <div class="tw-flex tw-flex-col tw-pr-5 tw-w-full">
      <app-p2p-sell-setup-item
        *ngFor="let entity of selectedItems"
        class="tw-mb-2"
        [item]="entity"
        [duration]="lotDepositSettings.tradesDuration"
        (itemRemoved)="removeSelectedItem(entity)"
      />
      <div
        class="deleteAllBtn tw-cursor-pointer tw-mt-4"
        (click)="onPanelClose()"
      >
        {{ 'P2P_WIDGETS.DEPOSITING.DELETE_ALL' | translate }}
      </div>
    </div>
  </ng-scrollbar>
  <div class="lotSettings tw-pt-6 tw-pr-5">
    <ng-container
      *ngIf="permissions?.success && permissions?.canTrade?.success && permissions?.canSteamAPI?.success; else premissionsFallbackTemplate"
    >
      <div
        *ngIf="overprice !== null"
        class="lotSettings-priceSlider tw-flex tw-flex-col tw-gap-3"
      >
        <mat-slider
          class="p2p-setup-slider"
          discrete
          [displayWith]="formatLabel"
          [min]="-30"
          [max]="100"
          [step]="1"
          (change)="updateSteamSelectedItems()"
          [style.--mdc-slider-label-label-text-color]="
            overprice < -4 ? 'var(--color-green-500)' : overprice > 10 ? 'var(--color-red-500)' : 'var(--color-yellow-500)'
          "
        >
          <input
            matSliderThumb
            [(ngModel)]="overprice"
          />
        </mat-slider>

        <div class="lotSettings-priceSlider-tips tw-flex tw-justify-between">
          <div>{{ 'P2P_WIDGETS.DEPOSITING.SELLING_SPEED' | translate }}</div>
          <ng-container [ngSwitch]="true">
            <div *ngSwitchCase="overprice > 10">{{ 'P2P_WIDGETS.DEPOSITING.SLOW' | translate }}</div>
            <div *ngSwitchCase="overprice < -4">{{ 'P2P_WIDGETS.DEPOSITING.QUICK_SALE' | translate }}</div>
            <div *ngSwitchDefault>{{ 'P2P_WIDGETS.DEPOSITING.RECOMMENDED' | translate }}</div>
          </ng-container>
        </div>
      </div>
      <button
        class="btn btn-primary--design lotSettings-sellBtn tw-w-full"
        (click)="sellSelectedItems()"
      >
        {{ 'P2P_WIDGETS.DEPOSITING.SALE_FOR' | translate }}
        <ng-container *ngIf="selectedSumWithOverprice"> {{ selectedSumWithOverprice | appCurrency }}</ng-container>
      </button>
      <app-p2p-sell-additional-settings
        [lotDepositSettings]="lotDepositSettings"
        (settingsChanged)="updateTradeSettings($event)"
      />
    </ng-container>
    <ng-template #premissionsFallbackTemplate>
      <div class="tw-flex tw-flex-col">
        <span class="tooltip tw-font-normal"> {{ permissionErrorTooltip | translate }} </span>
        <button
          class="btn btn-primary--design lotSettings-sellBtn tw-w-full"
          (click)="handleErrorAction(permissions)"
          [disabled]="isActionBtnDisabled"
        >
          {{ permissionErrorBtnText | translate: { days: permissions?.canTrade?.days || 0 } | uppercase }}
        </button>
      </div>
    </ng-template>
  </div>
</div>
