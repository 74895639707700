<div class="pagination pagination__marketplace">
  <ng-container *ngIf="view !== 'full'; else fullView">
    <button
      class="btn btn-sm pagination__btn"
      [class]="buttonClass"
      [disabled]="page.value <= minPage"
      (click)="setPrevPage()"
    >
      <ng-container *ngIf="view === 'compact'; else fullButtonPrev">
        <mat-icon
          class="pagination__arrow"
          svgIcon="chevron-left"
        ></mat-icon>
      </ng-container>
      <ng-template #fullButtonPrev>
        {{ 'LOCAL.PAGINATION.PREV' | translate }}
      </ng-template>
    </button>
    <label
      class="pagination-input__wrapper"
      for="counter"
    >
      <input
        id="counter"
        class="pagination__counter"
        [style.width]="pageDigitCount * 0.875 + 'rem'"
        type="text"
        [formControl]="page"
        autocomplete="off"
        (keypress)="onAmountInput($event)"
      />
      <span class="pagination__limit">
        {{ 'LOCAL.PAGINATION.FROM' | translate }}
        {{ maxPage }}
      </span>
    </label>
    <button
      class="btn btn-sm pagination__btn"
      [class]="buttonClass"
      [disabled]="page.value >= maxPage"
      (click)="setNextPage()"
    >
      <ng-container *ngIf="view === 'compact'; else fullButtonNext">
        <mat-icon
          class="pagination__arrow arrow_right"
          svgIcon="chevron-left"
        ></mat-icon>
      </ng-container>
      <ng-template #fullButtonNext>
        {{ 'LOCAL.PAGINATION.NEXT' | translate }}
      </ng-template>
    </button>
  </ng-container>
  <!-- <ng-template #fullView>
    Раньше темплейт fullView был тут. Вынесен, чтобы никак не перетрлись существующие стили. Если все ок, то можно удалить.
  </ng-template> -->
</div>
<ng-template #fullView>
  <div class="fullViewWrapper flex gap-1 fx-align-center">
    <div
      class="paginationBtn on-hover flex fx-align-center fx-justify-center"
      [class.disabled]="page.value <= minPage"
      (click)="setPrevPage()"
    >
      <mat-icon
        class="arrow-left"
        svgIcon="double-arrow--design"
      ></mat-icon>
    </div>
    <div
      (click)="setPage(1)"
      [class.active]="page.value === 1"
      class="paginationBtn on-hover flex fx-align-center fx-justify-center"
    >
      {{ 1 }}
    </div>
    <div
      class="paginationBtn flex fx-align-center fx-justify-center"
      *ngIf="page.value > 4"
    >
      ...
    </div>
    <div
      *ngFor="let availablePage of pagesArray"
      (click)="setPage(availablePage)"
      [class.active]="page.value === availablePage"
      class="paginationBtn on-hover flex fx-align-center fx-justify-center"
    >
      {{ availablePage }}
    </div>
    <div
      class="paginationBtn flex fx-align-center fx-justify-center"
      *ngIf="page.value < maxPage - 3"
    >
      ...
    </div>
    <div
      (click)="setPage(maxPage)"
      [class.active]="page.value === maxPage"
      class="paginationBtn on-hover flex fx-align-center fx-justify-center"
    >
      {{ maxPage }}
    </div>
    <div
      class="paginationBtn on-hover flex fx-align-center fx-justify-center"
      [class.disabled]="page.value >= maxPage"
      (click)="setNextPage()"
    >
      <mat-icon
        class=""
        svgIcon="double-arrow--design"
      ></mat-icon>
    </div>
  </div>
</ng-template>
