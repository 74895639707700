import { Injectable } from '@angular/core';
import { TransactionWay } from '@dev-fast/types';
import { Select } from '@ngxs/store';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { Observable } from 'rxjs';

import { SetTransactionWay } from '../payment.actions';
import { PaymentState } from '../payment.state';

@Injectable()
export class PaymentService {
  @Select(PaymentState.transactionWay)
  readonly transactionWay$!: Observable<TransactionWay | null>;

  @Dispatch()
  setTransactionWay(way: TransactionWay | null): SetTransactionWay {
    return new SetTransactionWay(way);
  }
}
