import { Injectable } from '@angular/core';
import { CaseBattleBackendService } from '@dev-fast/backend-services';
import { ICaseItemDtoV2, ICasesFilter, IFilterRange } from '@dev-fast/types';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Observable, tap } from 'rxjs';

import { CommonFiltersState } from '../../../state';
import { ChangeFilter, ChangePriceRangeValues, GetAllCasesForBattleCreate } from './cases.actions';
import { CASE_BATTLE_CASES_INITIAL_STATE, CaseBattleCasesStateModel } from './cases.model';

@State<CaseBattleCasesStateModel>({
  name: 'case_battle_cases',
  defaults: CASE_BATTLE_CASES_INITIAL_STATE,
})
@Injectable()
export class CaseBattleCasesState extends CommonFiltersState<ICasesFilter> {
  @Selector()
  public static casesForSelect({ casesForSelect }: CaseBattleCasesStateModel): ICaseItemDtoV2[] {
    return casesForSelect;
  }
  constructor(private caseBattleBackendService: CaseBattleBackendService) {
    super();
  }
  @Action(ChangePriceRangeValues)
  public setCasesPriceForRange({ patchState, getState }: StateContext<CaseBattleCasesStateModel>, { range }: ChangePriceRangeValues): void {
    const { priceRange } = getState();
    patchState({
      priceRange: this.calcRange(priceRange, range),
    });
  }
  @Action(ChangeFilter)
  public setCaseListFilters({ patchState }: StateContext<CaseBattleCasesStateModel>, { casesFilter }: ChangeFilter): void {
    patchState({
      filters: casesFilter,
    });
  }
  // @Action(ResetFilters)
  // public resetFilters({  patchState }: StateContext<CaseBattleCasesStateModel>): void {
  //   patchState({
  //     filters: {
  //       sortBy: DefaulSortingTypes.DEFAULT,
  //       query: '',
  //       minPrice:100
  //     }
  //   })
  // }

  @Action(GetAllCasesForBattleCreate, { cancelUncompleted: true })
  public getAllCasesForBattleCreate(
    { patchState, dispatch, getState }: StateContext<CaseBattleCasesStateModel>,
    { casesFilter }: GetAllCasesForBattleCreate
  ): Observable<ICaseItemDtoV2[]> {
    return this.caseBattleBackendService.getAllCasesForBattleCreate(casesFilter ? casesFilter : getState().filters).pipe(
      tap((response: ICaseItemDtoV2[]) => {
        const filterItems: ICaseItemDtoV2[] = response.filter((item) => !item.free?.count && item.enabled);
        const minmax: IFilterRange = filterItems.reduce(
          (acc, curr) => {
            return {
              minValue: Math.min(acc.minValue, curr.lastRevision.price),
              maxValue: Math.max(acc.maxValue, curr.lastRevision.price),
            };
          },
          { minValue: Number.MAX_SAFE_INTEGER, maxValue: 0 }
        );
        dispatch([new ChangePriceRangeValues(minmax)]);
        patchState({
          casesForSelect: filterItems,
        });
        if (casesFilter) {
          dispatch(new ChangeFilter(casesFilter));
        }
      })
      // catchError((error) => this.onError(error))
    );
  }
}
