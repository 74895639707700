import {
  AgentKeys,
  BonusTransactionTypes,
  GameTypes,
  LegacyTransactionTypes,
  OrderStatusEnum,
  PaymentStatus,
  TransactionMethods,
  TransactionRefillSystem,
  TransactionStatus,
  TransactionWithdrawSystem,
  WalletTransactionTypeId,
  WithdrawState,
} from '../../enums';
import { TransactionNamespaces } from '../../enums/transactions/transaction-namespaces.enum';
import { TransactionOperations } from '../../enums/transactions/transaction-operations.enum';
import { IBaseItemDtoV2 } from '../games';
import { IP2pBaseItem } from '../p2p/p2p-base-item.interface';

export interface IBetDto {
  amount: number;
  type: any;
  isWin: boolean;
}

export interface ITransactionDto {
  namespace: TransactionNamespaces;
  timestamp?: string; // 2023-07-27T10:43:04.821Z
  coinsAmount?: number;
  typeId: WalletTransactionTypeId;
  // Only if namespace !== 'bonus'
  operation?: TransactionOperations;
  // Only if namespace === 'bonus'
  type?: BonusTransactionTypes;
  // Only if namespace === 'game'
  gameTypeId?: GameTypes;
  bets?: IBetDto[];
  // Only if operation === 'credit' не всегда есть, даже если operation === 'credit'
  kit?: ISkinTransactionItem;
  payment?: Partial<{
    system: TransactionWithdrawSystem | TransactionRefillSystem;
    status: PaymentStatus | WithdrawState;
    method: TransactionMethods | null;
    wallet: string;
  }>;
  // Only If namespace === 'legacy
  body?: ILegacyTransactionBody;
  // Only If operation === 'winning'
  gameId?: number;
  // Only if namespace === 'inventory'
  agentKey?: AgentKeys;
  // items?: IHistoryItemSkin[];
}

export interface ITransactionKit {
  express: boolean;
  fastDelivery: boolean;
  id: number;
  isCrossFast: boolean;
  isLastBidByUser: boolean;
  isUserSeller: boolean;
  items: ITransactionItem[];
  price: number;
  recommendedPrice: number;
  sellerPrice: number;
  status: OrderStatusEnum;
  statusAt: string;
  tradeLockEndAt: string;
}

export interface ITransactionItem {
  baseItem: IBaseItemDtoV2;
  float: any;
  id: number;
  phase: any;
  price: number;
  recommendedPrice: number;
  sellerPrice: number;
  steamInspectLink: string;
  steamItemPosition: any;
  stickers: any[];
}

export interface ITransactionHistoryIds {
  typeIds: WalletTransactionTypeId[];
}

export interface IHistoryItemSkin {
  name: string;
  icon: string;
  color: string;
}

export interface ILegacyTransactionBody {
  id: number;
  createdAt: string;
  amount: number;
  type: LegacyTransactionTypes;
  payload: ILegacyTransactionBodyPayload;
}

export interface ILegacyTransactionBodyPayload {
  [key: string]: any;
  // Only if type === 'error'
  fakeRefill?: boolean;
  // or
  itemId?: number;
  fastOfferId?: number;
  isP2P?: true;
  // Only if type === 'unknown #58'
  gameId?: number;
  isFree?: boolean;
}

export interface ISkinTransactionItem extends IP2pBaseItem {
  items: ISkinTransactionKit[];
}
export interface ISkinTransactionKit extends IP2pBaseItem {
  baseItem: IBaseItemDtoV2;
}
export interface ISkinTransactionBaseItem extends IBaseItemDtoV2 {
  steamInspectLink: string;
  steamItemPosition: number[];
}
export interface IBalanceBet {
  amount: number;
  type: number;
  isWin: boolean;
}
export interface INotifyData {
  paymentStatus: TransactionStatus;
  customMessage?: string;
  params?: {
    amount?: number;
    id?: number;
  };
}
