export interface RefillType {
  imageSrc: string;
  methodName: string;
  methodType: ['popular', 'crypto', 'regional', 'card', 'items'];
  isEnabled: boolean;
  bonus?: RefillBonusType;
  isBonusEnabled?: boolean;
}

export interface RefillBonusType {
  type: string;
  bonus: number;
  isFixed: boolean;
}

export interface ICountry {
  key: string;
  value: string;
  icon: string;
  index: number;
}
