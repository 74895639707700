import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IFilterFormTemplate } from '@dev-fast/types';

import { DFormControlType, IDFormControl, IDFormPrepared } from '@app/core/dynamic-forms-service';

@Component({
  selector: 'app-ui-filter-mobile-menu',
  templateUrl: './filter-mobile-menu.component.html',
  styleUrls: ['./filter-mobile-menu.component.scss'],
})
export class FilterMobileMenuComponent {
  // сгруппированые контролы
  controls!: any;
  // корень для локалей
  localeRoot!: string;
  // Список свойств, которые не нужно считать чтобы показать примененные фильтры
  disabledParams: string[] = [];

  // Fix FAST_NEW_FRONT-1676
  subPanelTopOffset = 0;

  // Consts
  readonly types = DFormControlType;

  constructor(
    public dialogRef: MatDialogRef<FilterMobileMenuComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      filterForm: FormGroup<IFilterFormTemplate> | undefined;
      dForm: IDFormPrepared;
      resetFilters: () => void;
      applyFilters: () => void;
    },
  ) {
    this.localeRoot = data.dForm.localeRoot;
    this.controls = this.groupItems(data.dForm.controls).map((item: any) => item.group);
    this.disabledParams = data.dForm.disableCountParams;
  }

  applyFilters(): void {
    this.data.applyFilters();
    this.closeModal();
  }

  resetFilters(): void {
    this.data.resetFilters();
    this.closeModal();
  }

  closeModal = (): void => this.dialogRef.close();

  /**
   * Переданные контролы группируем по свойству group
   */
  groupItems(items: { [key: string]: { control: FormControl; params: [IDFormControl] } }): any[] {
    return Object.values(items).reduce((result: any, data: any) => {
      // NOTE: params - массив полей которые ссылаются на один и тот же control
      // (в теории ползунок и ввод числа могут одно и то же значение менять)
      // Я под это пока функционал не закладывал, так что беру лишь [0] элемент, подразумивая что он там один.
      // Но в случае нужды можно расширить
      const item = data.params[0];

      // Группировка элементов
      if (!item.group) {
        if (
          !result.find(
            (group: any) =>
              group.name === null && group.group.find((control: any) => control.type === item.type && control.name === item.name),
          )
        ) {
          result.push({ name: null, group: [item] });
        }
      } else {
        const group = result.find((groupItem: any) => groupItem.name === item.group);
        if (group) {
          if (!group.group.find((control: any) => control.type === item.type && control.name === item.name)) {
            group.group.push(item);
          }
        } else {
          result.push({ name: item.group, group: [item] });
        }
      }
      return result;
    }, []);
  }
}
