// FIXME заменить на ngx-scrollbar (уже установлен на проекте)
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  NgModule,
  OnDestroy,
  Output,
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { GameMode, GameStatus, IGame, NEW_GAMES } from '@dev-fast/types';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { filter, Subscription } from 'rxjs';

import { EnvironmentService } from '@app/core/environment-service';
import { LanguageService } from '@app/core/language-service';
import { LangRouterModule } from '@app/shared/directives';
import { AppCurrencyModule } from '@app/shared/pipes';
import { IS_SERVER_TOKEN } from '@app/shared/utils';

import { SocialGroupsComponentModule } from '../social-groups/social-groups.component';
import { TagComponent } from '../tag/tag.component';
import { ExpandedAreaComponent } from './expanded-area/expanded-area.component';
import { GameItemComponent } from './game-item/game-item.component';
import { areaAnimation } from './game-menu.animation';
import { MenuItemComponent } from './menu-item/menu-item.component';

export const newGames: Record<string, boolean> = { cases: true, 'case-battle': true };
// import { ConnectedPosition } from '@angular/cdk/overlay';
@Component({
  selector: 'app-ui-game-menu',
  templateUrl: './game-menu.component.html',
  styleUrls: ['./game-menu.component.scss'],
  animations: [areaAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GameMenuComponent implements OnDestroy {
  private subscriptions = new Subscription();
  environment = this.environmentService.environments;

  @Input() state: Record<string, GameStatus>;
  //FIXME в стейт или сервис по хорошему
  @Input() set availableGames(payload: IGame[] | null) {
    if (!payload) {
      return;
    }
    this.gamesDict = {};
    payload
      .filter((value) => this.environment.MODULES.games?.includes(value.key))
      .forEach((game) => {
        if (this.gamesDict[game.mode]) {
          this.gamesDict[game.mode]?.push(game);
        } else {
          this.gamesDict[game.mode] = [game];
        }
      });
    this.modes = Object.values(GameMode).filter((value) => {
      return Object.keys(this.gamesDict).includes(value);
    });
  }
  @Input() currentGame: IGame | undefined;
  @Input() online: number | null;
  @Input() isShowFullView: boolean | null;
  @Output() selectionChange: EventEmitter<IGame>;
  @Output() toggleFullView: EventEmitter<boolean>;
  modes: GameMode[];
  activeUrl = '/';
  gamesDict: Partial<Record<GameMode, IGame[]>>;
  newGames = NEW_GAMES;
  constructor(
    @Inject(IS_SERVER_TOKEN) public isServer: boolean,
    private environmentService: EnvironmentService,
    private cdr: ChangeDetectorRef,
    private router: Router,
  ) {
    this.environment = this.environmentService.environments;
    this.availableGames = [];
    this.isShowFullView = false;
    this.modes = [];
    this.state = {};
    this.gamesDict = {};
    this.online = 0;
    this.selectionChange = new EventEmitter(false);
    this.toggleFullView = new EventEmitter(false);
    this.subscribeEmitters();
  }

  onSelectGame = (game: IGame): void => this.selectionChange.emit(game);

  showFullView = (): void => {
    this.toggleFullView.emit(!this.isShowFullView);
  };

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  subscribeEmitters(): void {
    this.subscriptions.add(
      this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event) => {
        if (event instanceof NavigationEnd) {
          this.activeUrl = LanguageService.getBaseUrl(this.router.url.split('?')[0]);
          this.cdr.markForCheck();
        }
      }),
    );
  }
}

@NgModule({
  imports: [
    CommonModule,
    TagComponent,
    MatIconModule,
    RouterModule,
    SocialGroupsComponentModule,
    NgScrollbarModule,
    AppCurrencyModule,
    LangRouterModule,
  ],
  declarations: [GameMenuComponent, MenuItemComponent, ExpandedAreaComponent, GameItemComponent],
  exports: [GameMenuComponent],
})
export class GameMenuComponentModule {}
