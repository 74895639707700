<ng-container
  class="p2p-purchase-trade-modal__container"
  *ngIf="data$ | async as data"
>
  <div class="purchase-modal-title">
    <span>
      {{ 'P2P_WIDGETS.PURCHASE_TRADE_DIALOG.YOU_WON' | translate }}
    </span>
  </div>
  <div class="purchase-modal-lot">
    <div class="lot-item">
      <div class="thumbnail">
        <img
          skinItemImage
          [src]="data.baseItem.icon"
          alt=""
        />
      </div>
      <div>
        <div style="margin-bottom: 0.5rem">
          <ng-container *ngIf="data.baseItem.name; else noNameTemplate">
            {{ data.baseItem.name }}
          </ng-container>
          <ng-template #noNameTemplate>
            {{ data.baseItem.shortName }} | {{ data.baseItem.skin }} ({{ data.baseItem.exterior }})
          </ng-template>
        </div>
        <div class="color-primary">{{ data.price | appCurrency }}</div>
      </div>
    </div>
  </div>
  <div class="purchase-until-time">
    <div class="p2p-purchase-modal__until-time__body color-muted">
      <ng-container *ngIf="offerTimer$ | async as offerTimer">
        <span>
          <!-- TODO chane to dict -->
          {{ 'The seller has, ' }}
          <span class="p2p-purchase-modal__until-time__timer">
            {{ offerTimer | date: 'mm:ss' }}
          </span>
          {{ 'to send you an item' }}
        </span>
      </ng-container>
    </div>
  </div>
  <div class="progress-line">
    <div
      class="inner-line primary"
      [ngStyle]="{
        'animation-duration': (offerProgress$ | async) + 'ms',
        transform: 'translate3d(-' + (initialProgress$ | async) + '%, 0, 0)'
      }"
    ></div>
  </div>
  <div class="p2p-purchase-modal__trade-info__container color-muted">
    {{ 'P2P_WIDGETS.PURCHASE_TRADE_DIALOG.INFO' | translate }}
  </div>

  <div class="p2p-purchase-modal__trade-alert__container">
    <div>
      {{ 'P2P_WIDGETS.WARN.DELIBERATELY_BREAKDOWN_OFFER' | translate }}.
      {{ 'P2P_WIDGETS.PURCHASE_TRADE_DIALOG.ALERT_TITLE' | translate }}.
      {{ 'P2P_WIDGETS.PURCHASE_TRADE_DIALOG.ALERT_DESCRIBE' | translate }}
    </div>
  </div>
  <div
    class="purchase-modal-controller w-100"
    (click)="onClose()"
  >
    <a
      class="btn btn-primary btn-sm w-100"
      href="https://steamcommunity.com/id/me/tradeoffers"
      target="_blank"
    >
      <mat-icon
        class="icon-steam"
        svgIcon="steam"
      ></mat-icon>
      {{ 'P2P_WIDGETS.PURCHASE_TRADE_DIALOG.OPEN_TRADE' | translate }}
    </a>
  </div>
</ng-container>
