<div
  class="inventory__wrapper"
  [formGroup]="form"
>
  <div class="inventory__navigation-wrapper">
    <!-- <ng-container *ngIf="!!activePanel?.subPanel">
      <ng-container *ngIf="activePanel?.subPanel === subPanelEnum.BET">

      </ng-container>
      <ng-container  *ngIf="activePanel?.subPanel === subPanelEnum.EXHANGE"> -->
    <ng-container *ngIf="activePanel?.subPanel !== subPanelEnum.NONE && !isMobile">
      <button
        class="btn btn-semi-round md btn-transparent-dark--design j-fs w-f"
        disabled
      >
        {{ 'TRADE_PANEL.CHOOSE_SKINS' | translate }}
      </button>
    </ng-container>
    <!-- </ng-container>
    </ng-container> -->
    <div class="inventory__navigation-container">
      <ng-container *ngIf="activePanel?.subPanel === subPanelEnum.NONE">
        <button
          class="btn btn-semi-round md btn-transparent-dark--design j-fs w-f"
          (click)="setActivePanel({ panel: panelEnum.MAIN_MENU, subPanel: subPanelEnum.NONE })"
        >
          <mat-icon
            class="icon lg"
            svgIcon="arrow-left-design"
          ></mat-icon>
          <span>{{ 'LOCAL.INVENTORY.TITLE_INVENTORY' | translate }}</span>
        </button>
      </ng-container>
      <ng-container *ngIf="activePanel?.subPanel === subPanelEnum.EXHANGE">
        <button
          class="btn btn-semi-round md btn-transparent-dark--design j-fs w-f"
          (click)="setActivePanel({ panel: panelEnum.INVENTORY, subPanel: subPanelEnum.NONE })"
        >
          <!-- FIXME: добавить локаль -->
          <mat-icon
            class="icon lg rotate"
            svgIcon="arrow-left-design"
          ></mat-icon>
          <span>{{ 'LOCAL.INVENTORY.TITLE_EXCHANGE' | translate }}</span>
        </button>
      </ng-container>
      <ng-container *ngIf="activePanel?.subPanel === subPanelEnum.BET">
        <button
          class="btn btn-semi-round md btn-transparent-dark--design j-fs w-f"
          (click)="setActivePanel({ panel: panelEnum.INVENTORY, subPanel: subPanelEnum.NONE })"
        >
          <!-- FIXME: добавить локаль -->
          <mat-icon
            class="icon lg rotate"
            svgIcon="arrow-left-design"
          ></mat-icon>
          <span>{{ 'LOCAL.INVENTORY.TITLE_BET' | translate }}</span>
        </button>
      </ng-container>
      <button
        class="btn btn-semi-round md btn-transparent-dark--design"
        (click)="setActivePanel(null)"
      >
        <mat-icon
          class="icon lg"
          svgIcon="close"
        ></mat-icon>
      </button>
    </div>
  </div>
  <div
    class="inventory__panels-wrapper"
    [class.is-mobile]="isMobile"
  >
    <div class="inventory__container">
      <div class="inventory__balance-panel">
        <div class="inventory__balance-panel-inner">
          <div class="inventory__balance-panel-wrapper">
            <div class="inventory__balance-title">
              {{ 'STEAM_PANEL.PRICE' | translate }}
            </div>
            <app-ui-increase
              [className]="'inventory__balance-sum'"
              [valueType]="'currency'"
              [setCurValue]="totalBalance"
            >
            </app-ui-increase>
            <!-- <div class="inventory__balance-sum">{{ totalBalance | appCurrency }}</div> -->
          </div>
          <button
            class="inventory__balance-button"
            (click)="onToggleExchangePanel()"
          >
            <mat-icon
              class="inventory__balance-icon"
              svgIcon="{{ showNotice ? 'close' : 'refresh' }}"
            ></mat-icon>
          </button>
        </div>
        <div
          class="inventory__balance-btn__notice"
          *ngIf="!isInventoryAvailable && showNotice"
        >
          <span>{{ 'INVENTORY_PANEL.ROTATE' | translate }}</span>
        </div>
      </div>
      <div class="inventory__sort-panel">
        <div class="filters__link-wrapper">
          <label class="btn btn-semi-round btn-transparent-dark--design">
            {{ 'INVENTORY_PANEL.BY_PRICE' | translate }}
            <input
              type="checkbox"
              formControlName="sortBy"
            />
            <mat-icon
              class="icon filters__link_icon"
              svgIcon="arrow-up"
            ></mat-icon>
          </label>
        </div>
        <div class="inventory__refresh-panel">
          <button
            class="btn select__btn"
            (click)="toggleAllInventoryItems()"
          >
            {{ 'LOCAL.INVENTORY.INVENTORY_PANEL.SELECT_ALL' | translate }}
          </button>
          <button class="btn btn-semi-round md btn-transparent-dark--design">
            <mat-icon
              class="icon sm refresh-icon"
              svgIcon="refresh"
              (click)="onRefresh()"
            ></mat-icon>
          </button>
        </div>
      </div>
      <div
        class="inventory__items-wrapper"
        #inventoryListContainer
      >
        <ng-scrollbar
          visibility="hover"
          (reachedBottom)="onBottomReached()"
          [reachedOffset]="200"
        >
          <ng-container *ngIf="inventoryList && inventoryList.length > 0; else emptyList">
            <div class="inventory__items-list">
              <ng-container *ngFor="let item of inventoryList; trackBy: trackingFunction">
                <app-ui-skin-item-new
                  [type]="item.type"
                  [name]="item.name"
                  [shortName]="item.shortName"
                  [skinName]="item.skin"
                  [phase]="item.phase"
                  [icon]="item.icon"
                  [color]="item.color"
                  [statTrak]="item.statTrak"
                  [price]="item.price"
                  [exterior]="item.exterior"
                  [isSelect]="isSelected(item.id)"
                  [id]="item.id"
                  (skinClick)="selectItem($event)"
                >
                </app-ui-skin-item-new>
              </ng-container>
            </div>
          </ng-container>
          <ng-template #emptyList>
            <div class="inventory__items-empty">
              <span class="items-empty_title">
                {{ 'INVENTORY_PANEL.INVENTORY_IS_EMPTY' | translate }}
              </span>
              <span class="items-empty_description">
                {{ 'INVENTORY_PANEL.EXCHANGE_SKINS_IN_STORE' | translate }}
              </span>
              <button
                class="btn btn-primary btn-semi-round md w-f"
                (click)="onToggleExchangePanel()"
              >
                <span>{{ 'INVENTORY_PANEL.TRADE' | translate }}</span>
                <mat-icon
                  class="icon sm"
                  svgIcon="update-arrows"
                ></mat-icon>
              </button>
            </div>
          </ng-template>
        </ng-scrollbar>
      </div>
      <div class="inventory__control-panel">
        <button
          class="btn btn-primary btn-semi-round"
          [disabled]="!selectedItems.length || gameInProgress"
          (click)="onSellItems()"
        >
          <ng-container *ngIf="!selectedItems.length || inventoryList.length === selectedItems.length; else selectedBtn">
            {{ 'LOCAL.INVENTORY.INVENTORY_PANEL.SELL_ALL' | translate }}
          </ng-container>
          <ng-template #selectedBtn>
            {{ 'LOCAL.INVENTORY.INVENTORY_PANEL.SELL_SELECTED' | translate }}
          </ng-template>
          {{ calcItemsPrice(selectedItems) | appCurrency }}
        </button>
      </div>
    </div>
    <ng-container *ngIf="activePanel?.subPanel !== subPanelEnum.NONE">
      <ng-container *ngIf="activePanel?.subPanel === subPanelEnum.EXHANGE">
        <app-exchange-sub-panel
          [user]="user"
          [shopItems]="shopItems"
          [selectedShopItems]="selectedShopItems"
          [tradeBalance]="inventoryTradeBalance"
          [params]="shopParams"
          [meta]="shopMeta"
          [loading]="inventoryShopLoading"
          (changeParams)="changeShopParams($event)"
          (clickOnSkinEmit)="selectShopItem($event)"
          (createTrade)="createTrade()"
        >
        </app-exchange-sub-panel>
      </ng-container>
      <ng-container *ngIf="activePanel?.subPanel === subPanelEnum.BET">
        <app-bet-sub-panel
          [selectedItems]="selectedItems"
          [config]="legacyConfig"
          [state]="legacyState"
          [liteState]="legacyLiteState"
          [participated]="participatedSuccess"
          [participatedItems]="legacyParticipation"
          (onparticipate)="participate()"
          (oncleanParticipate)="cleanParticipate($event)"
          (clickOnSkinEmit)="selectItem($event)"
        >
        </app-bet-sub-panel>
      </ng-container>
    </ng-container>
  </div>
</div>
