import { inject, Injectable } from '@angular/core';
import { ActiveSoundGame, AnimationSpeed, GameSoundGroup, QuickFaqName, SoundName, SoundNameFast } from '@dev-fast/types';
import { Action, Selector, State, StateContext } from '@ngxs/store';

import { LocalStorageService } from '@app/core/local-storage-service';

import { SetActiveSound, SetAnimationSpeed, ToggleControlPanel, ToggleSoundState } from './settings-state.actions';
import { CASES_SETTINGS_INITIAL_STATE, ICasesSettingsStateModel } from './settings-state.model';

@State<ICasesSettingsStateModel>({
  name: 'casesSettings',
  defaults: CASES_SETTINGS_INITIAL_STATE,
})
@Injectable()
export class CasesSettingsState {
  readonly #storageService = inject(LocalStorageService);

  @Selector() static controlPanelHidden({ controlPanelHidden }: ICasesSettingsStateModel): boolean {
    return controlPanelHidden;
  }
  @Selector() static animationSpeed({ animationSpeed }: ICasesSettingsStateModel): AnimationSpeed {
    return animationSpeed;
  }
  @Selector() static soundEnabled({ soundEnabled }: ICasesSettingsStateModel): boolean {
    return soundEnabled;
  }
  @Selector()
  static activeSound({ activeSound }: ICasesSettingsStateModel): SoundNameFast | undefined {
    return activeSound;
  }

  ngxsOnInit({ dispatch }: StateContext<ICasesSettingsStateModel>): void {
    const controlPanelHidden = !!this.#storageService.get('quickFaq')?.[QuickFaqName.CASE_PANEL];
    const soundEnabled = !!this.#storageService.get('soundSettings')?.[`${GameSoundGroup.CSGO_CASES}.${SoundName.CSGO_TICK}`];
    const activeSound = this.#storageService.get(ActiveSoundGame.CASES_ACTIVE_SOUND);
    const animationSpeed = this.#storageService.get('animationSpeed') || AnimationSpeed.SLOW;
    dispatch([
      new ToggleControlPanel(controlPanelHidden),
      new ToggleSoundState(soundEnabled),
      new SetAnimationSpeed(animationSpeed),
      new SetActiveSound(activeSound),
    ]);
  }

  @Action(SetActiveSound)
  SetActiveSound({ patchState }: StateContext<ICasesSettingsStateModel>, { sound }: SetActiveSound): void {
    patchState({
      activeSound: sound,
    });
    this.#storageService.set(ActiveSoundGame.CASES_ACTIVE_SOUND, sound);
  }
  @Action(SetAnimationSpeed)
  setAnimationSpeed({ patchState, getState }: StateContext<ICasesSettingsStateModel>, { speed }: SetAnimationSpeed): void {
    const toggledSpeed = (speed ||= getState().animationSpeed === AnimationSpeed.QUICK ? AnimationSpeed.SLOW : AnimationSpeed.QUICK);
    patchState({
      animationSpeed: toggledSpeed,
    });
    this.#storageService.set('animationSpeed', toggledSpeed);
  }
  @Action(ToggleControlPanel)
  toggleControlPanel({ patchState }: StateContext<ICasesSettingsStateModel>, { controlPanelHidden }: ToggleControlPanel): void {
    patchState({
      controlPanelHidden,
    });
    const quickFaq = this.#storageService.get('quickFaq');
    this.#storageService.set('quickFaq', { ...quickFaq, [QuickFaqName.CASE_PANEL]: controlPanelHidden });
  }
  @Action(ToggleSoundState)
  toggleSoundState({ patchState }: StateContext<ICasesSettingsStateModel>, { soundEnabled }: ToggleSoundState): void {
    patchState({
      soundEnabled,
    });
  }
}
