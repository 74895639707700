<form
  *ngIf="filterForm && checkboxList"
  [formGroup]="filterForm"
  class="subPanel"
>
  <div class="subPanelHeader flex fx-align-center gap-3 tw-font-normal pb-sm-3">
    <div
      class="flex fx-align-center gap-3 pointer"
      (click)="closeSubPanel()"
    >
      <mat-icon svgIcon="point-arrow--design"></mat-icon>
      {{ categoryTitle | uppercase | translate }}
    </div>

    <div
      class="type tw-font-medium"
      *ngIf="checkboxList.localeKey"
    >
      {{ checkboxList.localeKey | translate }}
    </div>
  </div>

  <ng-scrollbar>
    <div class="subPanelContent flex fx-wrap gap-2">
      <div
        *ngFor="let item of controls; let i = index"
        class="filterItem flex fx-column gap-5 p-2 tw-rounded-2xl relative pointer"
        [class.selected]="!!controls[i].value"
        (click)="onPayloadValueChange(i)"
      >
        <mat-checkbox
          class="market-checkbox"
          [checked]="controls[i].value"
        ></mat-checkbox>

        <img
          [src]="'assets/img/design/market/skins/' + checkboxList.controls[i].outputData.checkedValue + '.webp'"
          alt=""
        />

        <div class="tw-font-normal">{{ checkboxList.controls[i].localeKey }}</div>
      </div>
    </div>
  </ng-scrollbar>
</form>
