<ng-container *ngIf="config">
  <div class="room-navigation">
    <a
      langRouterLink
      [routerLink]="'/game/' + config.title"
      [routerLinkActive]="'active'"
      class="room-navigation__item"
    >
      <div class="room-navigation__logo">
        <mat-icon [svgIcon]="'logo-' + config.title"></mat-icon>
      </div>
      <div>
        {{ config.title }}
      </div>
    </a>
    <a
      langRouterLink
      [routerLink]="'./history'"
      [routerLinkActive]="'active'"
      class="room-navigation__item"
      *ngIf="config.hasHistorySection"
      >{{ 'History' | translate }}</a
    >
    <a
      langRouterLink
      [routerLink]="'./championship'"
      [routerLinkActive]="'active'"
      class="room-navigation__item"
      *ngIf="config.hasTopSection"
      >{{ 'Top' | translate }}</a
    >
    <ng-container *ngIf="howToPlayBtn; else standartSection">
      <ng-container *ngTemplateOutlet="howToPlayBtn"></ng-container>
    </ng-container>
    <ng-template #standartSection>
      <a
        langRouterLink
        [routerLink]="'./faq'"
        [routerLinkActive]="'active'"
        class="room-navigation__item"
        *ngIf="config.howPlaySection"
        >{{ 'How To Play' | translate }}</a
      >
    </ng-template>
  </div>

  <ng-content></ng-content>
</ng-container>
