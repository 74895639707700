import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ICaseItemDtoV2, ICaseRevisionItem } from '@dev-fast/types';
import { Observable } from 'rxjs';

import { CaseBattleService } from '../../services';

@Component({
  selector: 'app-case-info',
  templateUrl: './case-info-modal.component.html',
  styleUrls: ['./case-info-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CaseInfoModalComponent {
  caseItem$: Observable<ICaseItemDtoV2> = this.payload.caseBattleService.getCaseById(this.payload.caseId);
  selectedCases$: Observable<ICaseItemDtoV2[]> = this.payload.caseBattleService.selectedCases$;
  isTable = true;

  constructor(
    public dialogRef: MatDialogRef<CaseInfoModalComponent>,
    @Inject(MAT_DIALOG_DATA) public payload: { caseId: number; caseBattleService: CaseBattleService },
  ) {}

  close(): void {
    this.dialogRef.close();
  }
  calcChancePercent(count: number): string {
    return `${count / 1000} %`;
  }
  findCurrentCase(caseItems: ICaseItemDtoV2[], caseId: number): number {
    return caseItems.filter((caseItem) => caseItem.id === caseId).length;
  }
  addCase(caseItem: ICaseItemDtoV2): void {
    this.payload.caseBattleService.addSelectedItems([caseItem]);
    this.close();
  }
  removeCase(caseItem: ICaseItemDtoV2): void {
    this.payload.caseBattleService.removeSelectedItems([caseItem]);
  }
  toggleListView(): void {
    this.isTable = !this.isTable;
  }
  sortItems(caseItems: ICaseRevisionItem[]): ICaseRevisionItem[] {
    return caseItems.sort((a, b) => b.inventoryItem.price - a.inventoryItem.price);
  }
}
