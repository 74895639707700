import { ComponentType } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { TuiSwipe, TuiSwipeModule } from '@taiga-ui/cdk';

import { expandAnimation } from '@app/ui/animations';

@Component({
  selector: 'app-ui-mobile-wrapper',
  standalone: true,
  imports: [CommonModule, TuiSwipeModule],
  templateUrl: './mobile-wrapper.component.html',
  styleUrls: ['./mobile-wrapper.component.scss'],
  animations: [expandAnimation],

  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MobileWrapperComponent {
  @Input() content: ComponentType<any> | undefined;
  @Output() closeWrapper: EventEmitter<any> = new EventEmitter(false);
  close(): void {
    this.closeWrapper.emit();
  }
  onSwipe(swipe: TuiSwipe): void {
    if (swipe.direction === 'bottom') {
      this.close();
    }
  }
}
