import { ISteamStoreInventory, SteamStatuses } from '@dev-fast/types';

export interface P2pStateModel {
  items: ISteamStoreInventory[] | null | undefined;
  sellerBanEndAt: number | null;
  steamStatus: SteamStatuses | null;
}

export const P2P_INITIAL_STATE: P2pStateModel = {
  items: undefined,
  sellerBanEndAt: null,
  steamStatus: null,
};
