import { IFilterMethod, IFilterRange, IPriceFilter, Pagination, Sorting } from '@dev-fast/types';

export interface CommonFiltersStateModel<T, K> {
  // pagination: Pagination;
  // sorting: Sorting;
  filters: T;
  sortingMethods: IFilterMethod<K>[];
  priceFilters: IPriceFilter[];
  priceRange: IFilterRange | null;
}
