import { FormControl, FormControlOptions, FormGroup } from '@angular/forms';

export enum DForms {
  InventoryForm = 'inventoryForm',
  MarketForm = 'marketForm',
}

/**
 * Все типы для наших контролов. Можно расширять
 */
export enum DFormControlType {
  Checkbox = 'checkbox',
  CheckboxList = 'checkbox-list',
  NumberFromTo = 'Number-from-to',
  RangeFromTo = 'Range-from-to',
  NumberFromToWithOptions = 'Number-from-to-with-options',
  Text = 'text',
}

/**
 * Базовый объект контрола. Эти свойства едины для всех контролов. От него наследуются другие
 */
export interface IDFormControlBase {
  name: string; // Идентификатор для фронта
  type: DFormControlType; // Тип контрола (чекбокс, текст, число и тп)
  group?: string; // группа к которой принадлежит контрол. Нужно для группировки контролов в общую обертку
  class?: string;
  localeKey?: string;
  outputData?: any; // Объект который содержит выходные данные
  fbOptions?: FormControlOptions;
}

/**
 * Обычный чекбокс
 */
export interface IDFormControlCheckbox extends IDFormControlBase {
  type: DFormControlType.Checkbox;
  checked: boolean;
  outputData: {
    checkedValue: any;
    checkboxKey: string;
  };
}

/**
 * Обычное поле ввода двойного числа (от и до)
 */
export interface IDFormControlNumberFromTo extends IDFormControlBase {
  type: DFormControlType.NumberFromTo;
  min: number | null;
  max: number | null;
  initMin: number;
  initMax: number;
  outputData: {
    minValueKey: string;
    maxValueKey: string;
  };
}

/**
 * Ввод числа, но с возможностью выбирать опции (например: от 5 до 100, от 101 до 500, от 500 и тп)
 */
export interface IDFormControlNumberFromToWithOptions extends IDFormControlBase {
  type: DFormControlType.NumberFromToWithOptions;
  min: number | null;
  max: number | null;
  initMin: number;
  initMax: number | null;
  outputData: {
    minValueKey: string;
    maxValueKey: string;
  };
  minMaxOptions: { from: number; to: number | null }[]; // Сами опции
}

/**
 * Ползунок с выбором двойного числа (от и до).
 * TODO: Если понадобится, то можно сделать еще тип для выбора одного числа
 */
export interface IDFormControlRangeFromTo extends IDFormControlBase {
  type: DFormControlType.RangeFromTo;
  min: number | null;
  max: number | null;
  initMin: number;
  initMax: number;
  outputData: {
    minValueKey: string;
    maxValueKey: string;
  };
}

/**
 * Обычное текстовое поле ввода
 */
export interface IDFormControlText extends IDFormControlBase {
  type: DFormControlType.Text;
  value: string;
  maxLength: number;
  outputData: {
    valueKey: string;
  };
}

/**
 * Список из чекбоксов
 * TODO: Если потребуется, то можно сделать опцию, которая говорит сколько чекбоксов можно выбрать. По дефолту хоть все
 */
export interface IDFormControlCheckboxList extends IDFormControlBase {
  type: DFormControlType.CheckboxList;
  hasGroupCheckbox?: boolean; // Добавить ли чекбокс, который выбирает сразу все опции
  checked?: boolean;
  controls: any[];
}

/**
 * Список всех контролов в одном типе
 */
export type IDFormControl =
  | IDFormControlCheckbox
  | IDFormControlCheckboxList
  | IDFormControlText
  | IDFormControlNumberFromTo
  | IDFormControlNumberFromToWithOptions
  | IDFormControlRangeFromTo;

/**
 * Общий объект контролов
 */
export interface IDFormData {
  disableCountParams: string[]; // То какие параметры не отображать в подсчете примененных фильтров
  localeRoot: string; // Путь до локалей
  controls: IDFormControl[][]; // [Контролы для первого таба][Контролы для второго таба].
}

export interface IDFormPrepared {
  localeRoot: string;
  disableCountParams: string[];
  controls: { [key: string]: { control: FormControl; params: [IDFormControl] } };
}
export interface IFilterMobileData {
  filterForm: FormGroup<any>;
  dForm: IDFormPrepared;
  resetFilters: () => void;
}
