export enum WithdrawalWalletType {
  PHONE = 'phone',
  EMAIL = 'email',
  WALLET = 'wallet',
  DEFAULT = 'default',
}
export enum PaymentSystem {
  BITCOIN123 = 'bitcoin123',
  BNB = 'bnb',
  BUSD = 'busd',
  DASH = 'dash',
  DOGECOIN = 'doge',
  ETHEREUM = 'ethereum',
  LITECOIN = 'ltc',
  MATIC = 'matic',
  SKRILL_EURO = 'skrill_eur',
  SKRILL_USD = 'skrill_usd',
  USDT_ERC20 = 'tether',
  USDT_TRC20 = 'tether_trc20',
  TETHER_ERC20 = 'usdc',
  TETHER_TRC20 = 'usdc_trc20',
  TON = 'ton',
  TRX = 'tron',
}
export enum WithdrawState {
  CREATED = 'created',
  FINISHED = 'finished',
  REJECTED = 'rejected',
}
export enum WithdrawalType {
  CARDS = 'credit_card',
  CRYPTO = 'crypto_currency',
}
