import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { GameMode, GameStatus, IGame, NEW_GAMES } from '@dev-fast/types';
import { SpinnerLoaderSAComponent } from '@dev-fast/ui-components';

import { getModes } from '../../utils';
import { GameCardComponent } from '../game-card/game-card.component';

@Component({
  selector: 'ui-desktop-game-menu',
  standalone: true,
  imports: [CommonModule, GameCardComponent, SpinnerLoaderSAComponent, MatTooltipModule],
  templateUrl: './desktop-game-menu.component.html',
  styleUrls: ['./desktop-game-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DesktopGameMenuComponent {
  @Input() state: Record<string, GameStatus> = {};
  @Input() set availableGames(payload: Partial<Record<GameMode, IGame[]>> | null | undefined) {
    if (!payload) {
      return;
    }
    this.gamesDict = payload;
    this.modes = getModes(payload);
  }
  @Input() currentGame: IGame | undefined;
  @Output() selectionChange: EventEmitter<IGame> = new EventEmitter(false);
  @Output() toggleFullView: EventEmitter<boolean> = new EventEmitter(false);
  activeUrl = '/';
  modes: GameMode[] = [];
  gamesDict: Partial<Record<GameMode, IGame[]>> = {};
  newGames = NEW_GAMES;

  getMode(mode: GameMode): IGame[] | undefined {
    return this.gamesDict[mode];
  }
}
