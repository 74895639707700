import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { P2pItemStatus } from '@dev-fast/types';
import { TranslateModule } from '@ngx-translate/core';

import { CurrencyComponent } from '@app/core/currency';
import { HashPipe } from '@app/shared/pipes';

import { appearanceAnimation, skinItemAnimation } from '../../../skin-item-new/skin-item.animation';

@Component({
  selector: 'app-ui-custom-case-card',
  templateUrl: './custom-case-card.component.html',
  styleUrls: ['./custom-case-card.component.scss'],
  animations: [skinItemAnimation, appearanceAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, CurrencyComponent, TranslateModule, MatIconModule, HashPipe],
})
export class CustomCaseCardComponent {
  @Input() status: P2pItemStatus = P2pItemStatus.Default;
  @Input() backgroundImage: string | null | undefined;
  @Input() collectionImage: string | null | undefined;
  @Input() mainItemImage: string | undefined;
  @Input() caseImage: string | undefined;
  @Input() enabled: boolean | undefined = true;
  @Input() historyImage: string | undefined;
  @Input() name!: string;
  @Input() casePrice!: number; // цена кейса, установленная в админке
  @Input() marketPrice: number | undefined; // цена лота с таким кейсом в маркете
  @Input() itemDropProbability = ''; // процент выпадения предмета для показа в левом углу
  // @Input() public tags!: any; //TODO на макетах нет, но будут же

  @Output() openCaseModal: EventEmitter<void> = new EventEmitter<void>();
  @Output() buyCase: EventEmitter<void> = new EventEmitter<void>();

  P2pItemStatus = P2pItemStatus;

  @HostBinding('class.selected') get _isSelected(): boolean {
    return this.status === P2pItemStatus.Selected;
  }

  get caseImageFallbackImage(): string | null | undefined {
    return this.mainItemImage || this.caseImage ? null : this.historyImage;
  }

  get enabledStatus(): boolean {
    return this.enabled === undefined ? true : this.enabled;
  }

  clickOnCard(): void {
    this.marketPrice ? this.buyCase.emit() : this.openCaseModal.emit();
  }
}
