<div class="tw-relative tw-flex tw-flex-col tw-gap-8 tw-p-8 tw-w-[100vw] tw-max-w-[32rem] tw-rounded-2xl tw-bg-[--color-gray-650]">
  <button
    class="!tw-absolute tw-right-4 tw-top-4 btn btn-sm sq btn-round btn-darken--design"
    (click)="closeModal()"
  >
    <mat-icon [svgIcon]="'close'"> </mat-icon>
  </button>
  <div class="tw-flex tw-flex-col tw-gap-4 tw-items-center tw-text-gray-50">
    <mat-icon
      class="!tw-w-20 !tw-h-20 tw-text-[--color-joker] tw-font-medium"
      [svgIcon]="'joker'"
    >
    </mat-icon>
    <div class="tw-text-xl">
      {{ 'LOCAL.CASES.MODAL.JOKER.HEADER' | translate }}
    </div>
  </div>
  <div class="tw-text-base">
    {{ 'LOCAL.CASES.MODAL.JOKER.TEXT' | translate }}
  </div>
  <button
    class="refill-btn btn btn-primary--design btn-semi-round"
    (click)="closeModal()"
  >
    {{ 'LOCAL.CASES.MODAL.JOKER.BUTTON' | translate }}
  </button>
  <div
    class="tw-flex tw-justify-center"
    [formGroup]="form"
  >
    <app-ui-checkbox
      [formControlName]="formControlName"
      [labels]="labels"
    >
    </app-ui-checkbox>
  </div>
</div>
