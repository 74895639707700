import { IconConfig } from '@dev-fast/types';

export const iconsNotifications: IconConfig[] = [
  {
    name: 'notification-bell',
    path: 'assets/img/mat-icons/notifications/notification-bell.svg',
  },
  {
    name: 'notification-error',
    path: 'assets/img/mat-icons/notifications/notification-error.svg',
  },
  {
    name: 'notification-level',
    path: 'assets/img/mat-icons/notifications/notification-level.svg',
  },
  {
    name: 'notification-info',
    path: 'assets/img/mat-icons/notifications/notification-info.svg',
  },
  {
    name: 'notification-read',
    path: 'assets/img/mat-icons/notifications/notification-read.svg',
  },
  {
    name: 'notification-remove',
    path: 'assets/img/mat-icons/notifications/notification-remove.svg',
  },
  {
    name: 'notification-success',
    path: 'assets/img/mat-icons/notifications/notification-success.svg',
  },
  {
    name: 'notification-attention',
    path: 'assets/img/mat-icons/notifications/attentions.svg',
  },
  {
    name: 'notification-success-outline',
    path: 'assets/img/mat-icons/notifications/check.svg',
  },
  {
    name: 'notification-error-outline',
    path: 'assets/img/mat-icons/notifications/error.svg',
  },
  {
    name: 'notification-new-success',
    path: 'assets/img/mat-icons/notifications/check.svg',
  },
  {
    name: 'notification-new-info',
    path: 'assets/img/mat-icons/notifications/attentions.svg',
  },
  {
    name: 'notification-new-error',
    path: 'assets/img/mat-icons/notifications/forbidden.svg',
  },
];
