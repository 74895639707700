import { ILocales } from '../models';

/**
 * Главный язык на сайте. Ставится при маршрутах / (в то время как остальные /ru/, /pl/ и тп.
 */
export const MAIN_LANG = 'en';

/**
 * Это заглушка, которая применяется если нам SSR не передал языки с которыми он работает
 * (Например при запуске проекта на локалхосте без SSR)
 */
export const DEFAULT_LANGS: { [route: string]: ILocales } = {
  de: { path: 'de_DE', title: 'Deutsch', isActive: true },
  es: { path: 'es_ES', title: 'Espanol', isActive: true },
  nb: { path: 'nb_NO', title: 'Norsk', isActive: true },
  pl: { path: 'pl_PL', title: 'Polskie', isActive: true },
  ro: { path: 'ro_RO', title: 'Romana', isActive: true },
  sv: { path: 'sv_SE', title: 'Svenska', isActive: true },
  th: { path: 'th_TH', title: 'Thai', isActive: true },
  tr: { path: 'tr_TR', title: 'Turk', isActive: true },
  zh: { path: 'zh_CN', title: 'Chinese', isActive: true },
  ru: { path: 'ru_RU', title: 'Русский', isActive: true },
  en: { path: 'en_US', title: 'English', isActive: true },
  vi: { path: 'vi_VN', title: 'Vietnamese', isActive: true },
  pt: { path: 'pt_PT', title: 'Portugues', isActive: true },
};
