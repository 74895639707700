import { Clipboard } from '@angular/cdk/clipboard';
import { AsyncPipe, NgClass, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, DestroyRef, inject, Input, OnInit } from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
  NullableForm,
  StateActionStatus,
  STEAM_API_KEY_LINK,
  STEAM_API_KEY_REG_EXP,
  STEAM_TRADE_LINK,
  TRADE_LINK_REG_EXP,
} from '@dev-fast/types';
import { TranslateModule } from '@ngx-translate/core';
import { combineLatest, merge, Observable, tap } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

import { NotificationsService } from '@app/core/notification-service';
import { IS_SERVER_TOKEN } from '@app/shared/utils';

import { TRADE_BLOCK_ENGINE, TradeBlockEngine } from './symbols';

interface Links {
  link: string | null;
  apiKey: string | null;
}
/**
 * Блок управления полями трейд ссылки и ключа Steam API пользователя
 * В нем он может менять эти поля и копировать их в буффер обмена
 */
@Component({
  selector: 'app-trade',
  templateUrl: './trade.component.html',
  styleUrls: ['./trade.component.scss', './trade.component.media.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [TranslateModule, NgClass, ReactiveFormsModule, AsyncPipe, MatTooltipModule, MatIconModule, MatProgressSpinnerModule, NgIf],
})
export class TradeComponent implements OnInit {
  readonly #engine: TradeBlockEngine = inject(TRADE_BLOCK_ENGINE);
  readonly #destroyRef = inject(DestroyRef);
  readonly #isServer: boolean | null = inject(IS_SERVER_TOKEN, { optional: true });
  readonly #clipboard = inject(Clipboard);
  // убрать в engine???
  readonly #notificationsService = inject(NotificationsService);

  @Input() showLink = true;
  @Input() showKey = true;
  // ---- ENUMS
  readonly steamApiKeyLink = STEAM_API_KEY_LINK;
  readonly steamTradeLink = STEAM_TRADE_LINK;

  private readonly tradeLinkRegExp = TRADE_LINK_REG_EXP;
  private readonly steamApiKeyRegExp = STEAM_API_KEY_REG_EXP;
  tradeForm: FormGroup<NullableForm<Links>> = new FormGroup<NullableForm<Links>>({
    link: new FormControl('', [Validators.pattern(this.tradeLinkRegExp), Validators.required]),
    apiKey: new FormControl('', [Validators.pattern(this.steamApiKeyRegExp), Validators.required]),
  });
  user = toSignal(this.#engine.user$);
  // Пока не пришли данные о пользователе показывает заглушки с градиентами
  isLoading = computed(() => this.#isServer || !this.user());

  tradeLinkLoading$: Observable<boolean> = merge(this.#engine.tradeLinkValidation$, this.#engine.tradeLinkLoadStatus$).pipe(
    map((status) => status === StateActionStatus.DISPATCH),
  );
  keyLoading$: Observable<boolean> = merge(this.#engine.apiKeyValidation$, this.#engine.apiKeyLoadStatus$).pipe(
    map((status) => status === StateActionStatus.DISPATCH),
  );
  canChangeInputLink$: Observable<boolean> = combineLatest([this.#engine.tradeLink$, this.tradeForm.controls.link.valueChanges]).pipe(
    map(([link, value]) => link !== value && this.tradeForm.controls.link.valid),
  );
  canChangeInputKey$: Observable<boolean> = combineLatest([this.#engine.apiKey$, this.tradeForm.controls.apiKey.valueChanges]).pipe(
    map(([key, value]) => key !== value && this.tradeForm.controls.apiKey.valid),
  );
  tradeLinkInvalid$: Observable<boolean> = combineLatest([
    this.#engine.tradeLink$,
    this.#engine.tradeLinkValidation$,
    this.tradeForm.controls.link.statusChanges.pipe(startWith('VALID')),
    this.#engine.tradeLinkLoadStatus$,
  ]).pipe(
    map(([link, status, controlStatus, loadStatus]) => {
      return (status === StateActionStatus.ERROR && !!link) || controlStatus === 'INVALID' || loadStatus === StateActionStatus.ERROR;
    }),
  );
  apiKeyInvalid$: Observable<boolean> = combineLatest([
    this.#engine.apiKey$,
    this.#engine.apiKeyValidation$,
    this.tradeForm.controls.apiKey.statusChanges.pipe(startWith('VALID')),
    this.#engine.apiKeyLoadStatus$,
  ]).pipe(
    map(([key, status, controlStatus, loadStatus]) => {
      return (status === StateActionStatus.ERROR && !!key) || controlStatus === 'INVALID' || loadStatus === StateActionStatus.ERROR;
    }),
  );
  tradeLinkChanged$ = this.#engine.tradeLinkLoadStatus$.pipe(map((status) => status === StateActionStatus.SUCCESS));
  apiKeyChanged$ = this.#engine.apiKeyLoadStatus$.pipe(map((status) => status === StateActionStatus.SUCCESS));

  copy(code: string): void {
    this.#clipboard.copy(code);
    this.#notificationsService.addSuccessNotification('ACTION.COPY');
  }
  onSubmitTradeLink(): void {
    const steamLink = this.tradeForm.controls.link.value;
    if (steamLink) {
      this.#engine.updateTradeLink({ steam: steamLink });
    }
  }
  onSubmitApiKey(): void {
    const apiKey = this.tradeForm.controls.apiKey.value;
    if (apiKey) {
      this.#engine.updateApiKey(apiKey);
    }
  }

  // ---- ANGULAR LIFE CYCLE
  ngOnInit(): void {
    this.#engine.getP2pPermissions();
    this.#subscribeEmitters();
  }
  #subscribeEmitters(): void {
    this.#engine.tradeLink$
      .pipe(
        tap((link) => {
          this.tradeForm.controls.link.patchValue(link || '');
        }),
        takeUntilDestroyed(this.#destroyRef),
      )
      .subscribe();
    this.#engine.apiKey$
      .pipe(
        tap((key) => {
          this.tradeForm.controls.apiKey.patchValue(key || '');
        }),
        takeUntilDestroyed(this.#destroyRef),
      )
      .subscribe();
  }
}
