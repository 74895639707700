export interface IEvent {
  key: string;
  title: string;
  isCurrent: boolean;
  isActive?: boolean;
  // isMain?: boolean;
  // for statuses new locked etc
  tag?: any;
  url?: string;
}
