import { DOCUMENT } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  effect,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  Output,
  signal,
  SimpleChanges,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { ILocales, MAIN_LANG } from '@dev-fast/types';
import { fromEvent } from 'rxjs';
import { filter, tap } from 'rxjs/operators';

import { LanguageService } from '@app/core/language-service';

@Component({
  selector: 'app-language-dropdown',
  templateUrl: './language.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LanguageDropDownComponent implements OnChanges {
  @Input() items: ILocales[] = [];
  @Input() selected!: ILocales;

  @Output() changeSelect: EventEmitter<ILocales> = new EventEmitter<ILocales>();

  lang = signal<string>(MAIN_LANG);
  opened = signal<boolean>(false);

  readonly #document = inject(DOCUMENT);
  readonly #router = inject(Router);

  /**
   * При клике вне компонента закрывать выпадающий список
   */
  #clickOutside = toSignal(
    fromEvent(this.#document, 'click').pipe(
      filter((event: any) => this.opened() && !(event.target.closest('app-language-dropdown') instanceof HTMLElement)),
      tap(() => {
        this.opened.set(false);
      }),
    ),
  );

  constructor() {
    effect(() => {
      this.#clickOutside();
    });
  }

  /**
   * В Angular 17.1 когда появятся input() то можно избавиться от этого метода
   * @param changes
   * @returns
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (!changes['selected']) {
      return;
    }

    const path = changes['selected'].currentValue.path;

    if (!!path && path !== 'default') {
      this.lang.set(path.substring(3, 5).toLowerCase());
      return;
    }

    const pathLang = this.#router.url.substring(1, 3);
    const isAcceptedLanguage = LanguageService.isAcceptedLanguage(pathLang);
    const language = isAcceptedLanguage ? pathLang : MAIN_LANG;
    this.lang.set(language);
  }

  /**
   * Открыть/закрыть выпадающий список
   */
  toggleDropdown(): void {
    this.opened.set(!this.opened());
  }

  /**
   * Выбор языка
   * @param item
   */
  select(item: any): void {
    this.changeSelect.emit(item);
    this.opened.set(false);
  }
}
