import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import {
  IP2pDepositItem,
  IP2pDepositSettings,
  IUserP2pPermissionInterface,
  SteamErrorsEnum,
  SteamErrorsEnumLocales,
} from '@dev-fast/types';

@Component({
  selector: 'app-p2p-sell-setup-panel',
  templateUrl: './p2p-sell-setup-panel.component.html',
  styleUrls: ['./p2p-sell-setup-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class P2pSellSetupPanelComponent {
  @Input() lotDepositSettings: IP2pDepositSettings | null = null;
  @Input() overprice: number | null = null;
  @Input() set userPermissions(permissions$: IUserP2pPermissionInterface) {
    if (permissions$) {
      this.permissions = permissions$;
      this.permissionErrorBtnText = this.formatErrBtnText(permissions$);
      this.isActionBtnDisabled = permissions$?.canTrade?.error === SteamErrorsEnum.STEAM_TRADE_BAN;
    }
  }
  @Input() selectedItems: IP2pDepositItem[] | undefined;
  @Input() selectedSum: number | null = null;
  @Input() selectedSumWithOverprice: number | null = null;

  @Output() panelClosed: EventEmitter<void> = new EventEmitter<void>();
  @Output() sellSelected: EventEmitter<void> = new EventEmitter<void>();
  @Output() setupSteam: EventEmitter<void> = new EventEmitter<void>();
  @Output() setOverprice: EventEmitter<number> = new EventEmitter<number>();
  @Output() reqAutoSelectItems: EventEmitter<number> = new EventEmitter<number>();
  @Output() itemRemoved: EventEmitter<IP2pDepositItem> = new EventEmitter<IP2pDepositItem>();
  @Output() itemsUpdated: EventEmitter<IP2pDepositItem[]> = new EventEmitter<IP2pDepositItem[]>();
  @Output() settingsChanged: EventEmitter<IP2pDepositSettings> = new EventEmitter<IP2pDepositSettings>();
  @Output() goToSteamInventoryMarketTab: EventEmitter<void> = new EventEmitter<void>();

  permissions: IUserP2pPermissionInterface | null = null;
  permissionErrorTooltip = '';
  permissionErrorBtnText = '';
  isActionBtnDisabled = false;
  // Enums
  readonly steamErrorsEnum = SteamErrorsEnum;

  updateSteamSelectedItems(): void {
    this.setOverprice.emit(this.overprice ? this.overprice : 0);
  }
  updateAutoSelected(currency: number): void {
    this.setOverprice.emit(this.overprice ? this.overprice : 0);
    this.reqAutoSelectItems.emit(currency);
  }
  updateTradeSettings(newValue: IP2pDepositSettings): void {
    this.lotDepositSettings = newValue;
    this.settingsChanged.emit(this.lotDepositSettings);
  }

  removeSelectedItem = (item: IP2pDepositItem): void => this.itemRemoved.emit(item);
  onSetupSteam = (): void => this.setupSteam.emit();
  onPanelClose = (): void => this.panelClosed.emit();
  sellSelectedItems = (): void => this.sellSelected.emit();

  formatLabel(value: number): string {
    return value + ' %';
  }
  formatErrBtnText(permissions: IUserP2pPermissionInterface): string {
    let title: string | undefined = 'P2P_SETTINGS.SETUP_TITLE';
    const errorMsg = permissions?.canTrade?.error || permissions?.canSteamAPI?.error || permissions?.error;
    if (errorMsg) {
      title = SteamErrorsEnumLocales[errorMsg];
    }
    return title ?? 'P2P_SETTINGS.SETUP_TITLE';
  }
  goToInventoryMarketTab = (): void => this.goToSteamInventoryMarketTab.emit();
  handleErrorAction(permissions: IUserP2pPermissionInterface | null): void {
    if (!permissions) {
      return;
    }
    const errorMsg = permissions.canTrade?.error || permissions.canSteamAPI?.error || permissions.error;
    switch (errorMsg) {
      case SteamErrorsEnum.UNBINDING_STEAM:
        window.open('/account/main', '_blank');
        break;
      case SteamErrorsEnum.STEAM_GUARD:
        window.open('https://store.steampowered.com/mobile', '_blank');
        break;
      case SteamErrorsEnum.PRIVATE_INVENTORY:
        window.open('https://steamcommunity.com/my/edit/settings', '_blank');
        break;
      case SteamErrorsEnum.STEAM_TRADE_BAN:
      case SteamErrorsEnum.STEAM_GUARD_HOLD:
        break;
      case SteamErrorsEnum.INVALID_TRADELINK:
      case SteamErrorsEnum.NO_API_KEY:
      default:
        this.onSetupSteam();
        break;
    }
  }
}
