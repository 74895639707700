<div
  class="case-revesion-modal"
  *ngIf="caseItem$ | async as caseItem"
>
  <app-ui-case-full-info
    [caseItem]="caseItem"
    [isTable]="isTable"
    [selectedCases]="selectedCases$ | async"
    (onaddCase)="addCase($event)"
    (oncloseModal)="close()"
    (ontoggleView)="toggleListView()"
  >
  </app-ui-case-full-info>
  <ng-container *ngIf="!isTable && caseItem.lastRevision.items.length > 0">
    <div class="case-header_title-container">
      <div class="title_skin">{{ 'LOCAL.CASES.MODAL.REVISION.SKIN' | translate }}</div>
      <div class="title_price">{{ 'LOCAL.CASES.MODAL.REVISION.PRICE' | translate }}</div>
      <div class="title_odds">{{ 'LOCAL.CASES.MODAL.REVISION.CHANCE' | translate }}</div>
    </div>
  </ng-container>
  <div class="items-list-container">
    <ng-container *ngIf="isTable; else listTemplate">
      <ng-scrollbar>
        <div class="case-revision_items-container table">
          <app-ui-possible-items-list
            [isCompact]="true"
            [caseId]="caseItem.id"
            [caseItems]="caseItem.lastRevision.items"
            [isHovered]="false"
          >
          </app-ui-possible-items-list>
        </div>
      </ng-scrollbar>
    </ng-container>
    <ng-template #listTemplate>
      <ng-scrollbar>
        <div class="case-revision_items-container">
          <ng-container *ngFor="let item of sortItems(caseItem.lastRevision.items); let index = index">
            <app-ui-skin-card
              [index]="index"
              [weaponName]="
                item.inventoryItem.baseItem.shortName
                  ? item.inventoryItem.baseItem.shortName
                  : (item.inventoryItem.baseItem.type | titlecase)
              "
              [skinName]="item.inventoryItem.baseItem.skin"
              [skinWear]="item.inventoryItem.baseItem.exterior"
              [price]="item.inventoryItem.price"
              [chance]="item.count"
              [statTrak]="item.inventoryItem.baseItem.statTrak"
              [avatarSrc]="item.inventoryItem.baseItem.icon"
              [range]="{ min: item.rangeFrom, max: item.rangeTo }"
            >
            </app-ui-skin-card>
          </ng-container>
        </div>
      </ng-scrollbar>
    </ng-template>
  </div>
  <button
    class="btn close-btn"
    (click)="close()"
  >
    <mat-icon
      [title]="'LOCAL.CASES.MODAL.REVISION.CLOSE' | translate"
      class="close-icon"
      [svgIcon]="'close'"
    >
    </mat-icon>
  </button>
</div>
