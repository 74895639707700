<div class="default-modal-header">
  <div class="default-modal-header__title">
    {{ 'LOCAL.CASES.CATEGORIES.FAQ.HISTORY' | translate }}
  </div>
  <button
    class="btn btn-sm sq btn-round btn-darken--design"
    (click)="closeModal()"
  >
    <mat-icon svgIcon="close"></mat-icon>
  </button>
</div>
<ng-container *ngIf="marketHistoryItems | async as historyItemsArray">
  <app-ui-history [items]="historyItemsArray"></app-ui-history>
</ng-container>
<!-- (scrollToBottom)="onScrollToBottom(data?.historyParams?.page)" -->
