import { EClassicViewMode, IconConfig } from '@dev-fast/types';

export const iconsClassic: IconConfig[] = [
  {
    name: 'award-limb',
    path: 'assets/img/games/classic/award-limb.svg',
  },
  {
    name: 'drop-placement',
    path: 'assets/img/games/classic/drop-placement.svg',
  },
  {
    name: 'lent-pointer',
    path: 'assets/img/games/classic/lent-pointer.svg',
  },
  {
    name: 'question',
    path: 'assets/img/games/classic/question.svg',
  },

  // Иконки, используемые для игры classic
  {
    name: 'classic-arrow-left',
    path: 'assets/img/games/classic/arrow-left.svg',
  },
  {
    name: 'classic-arrow-right',
    path: 'assets/img/games/classic/arrow-right.svg',
  },
  {
    name: 'classic-arrow-separator',
    path: 'assets/img/games/classic/arrow-separator.svg',
  },
  {
    name: EClassicViewMode.GRID,
    path: 'assets/img/games/classic/grid.svg',
  },
  {
    name: EClassicViewMode.LIST,
    path: 'assets/img/games/classic/list.svg',
  },
  {
    name: 'classic-play',
    path: 'assets/img/games/classic/play.svg',
  },

  // Модалка победителя
  {
    name: 'radiant-circle',
    path: 'assets/img/games/classic/radiant-circle.svg',
  },
  {
    name: 'radiant-back',
    path: 'assets/img/games/classic/radiant-back.svg',
  },
  {
    name: 'shield-circle',
    path: 'assets/img/games/classic/shield-circle.svg',
  },
  {
    name: 'wing-left',
    path: 'assets/img/games/classic/wing-left.svg',
  },
  {
    name: 'wing-right',
    path: 'assets/img/games/classic/wing-right.svg',
  },
];
