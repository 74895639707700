<ng-container *ngIf="checkboxControl && data">
  <div>
    <div
      class="filterItem flex fx-align-center fx-justify-between w-100"
      [ngClass]="data.class"
      [class.selected]="checkboxControl.value"
    >
      <label class="flex fx-align-center gap-3">
        <mat-checkbox
          [checked]="checkboxControl.value"
          (change)="onValueChange()"
          class="filterItem-checkbox market-checkbox"
        />

        {{ translateRoot + data.localeKey | translate }}
      </label>
    </div>
  </div>
</ng-container>
