import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';

import { P2pBuyingService, P2pDepositService } from './services';
import { P2pBuyingState, P2pCommonState, P2pDepositState } from './states';

@NgModule({
  imports: [CommonModule, NgxsModule.forFeature([P2pCommonState, P2pDepositState, P2pBuyingState])],
  providers: [P2pDepositService, P2pBuyingService],
})
export class P2pModule {}
