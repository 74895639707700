import { CrossServices } from '../enums';
import { IBaseWidgetBlock } from '../models';
import { IInventoryItem } from './inventory';
import { ISkinItem } from './skin-item';
import { IUserShort } from './user';

export interface IClassicParticipation extends IUserShort {
  items: (ISkinItem | IInventoryItem)[];
  index: number;
}

export interface IClassicRafflingItem extends ISkinItem {
  playerId: number;
  playerColor: string;
  tickets?: number[];
}

export interface IBetItem {
  type: 'coins';
  amount: number;
  icon?: string;
  color?: string;
}

export interface ISkinBetItem {
  type: 'skin';
  id: number;
  amount: number;
  icon?: string;
}

export interface IGameBet {
  items: (ISkinBetItem | IBetItem)[];
  appId: CrossServices;
  delay?: number;
  timestamp?: number;
  betId?: number;
}
export interface IGameBetResponseDto {
  coins?: IGameBetResponse;
  inventory?: IGameBetResponse;
}
export interface IGameBetResponse {
  betId: number;
  timeout: number;
}

export type IClassicWidgetBlock = IBaseWidgetBlock<{
  prize: number;
  gameId: number;
  bet: number;
}>;

export interface IClassicHistory {
  md5: string;
  number: number;
  players: IClassicHistoryParticipation[];
  prize: number;
  rand: string;
  timeAdded: string;
  winner: IUserShort;
}

export interface IHistorySkinItem extends ISkinItem {
  playerId?: number;
  tickets: number[];
}

export interface IClassicHistoryParticipation extends IUserShort {
  items: IHistorySkinItem[];
  betId: number;
  itemsCount: number;
  itemsPrice: number;
}
