import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { LangRouterModule } from '@app/shared/directives';
import { AppCurrencyModule } from '@app/shared/pipes';

import { BalancePanelComponent } from './balance-panel/balance-panel.component';
import { BalanceWidgetV2Component } from './balance-widget-v2.component';

@NgModule({
  declarations: [BalanceWidgetV2Component, BalancePanelComponent],
  imports: [CommonModule, RouterModule, MatIconModule, AppCurrencyModule, TranslateModule, LangRouterModule],
  exports: [BalanceWidgetV2Component],
})
export class BalanceWidgetV2Module {}
