<a
  *ngIf="game"
  langRouterLink
  [routerLink]="'/game/' + game.key"
  [routerLinkActive]="'active'"
  [class.active]="isActive"
  class="menu-item"
>
  <span
    class="menu-item__icon-wrapper"
    *ngIf="gameDetail as details"
  >
    <img
      [src]="details.icon"
      alt=""
      class="game-icon"
    />
  </span>
  <span
    class="menu-item__body"
    *ngIf="titleType === 'bottom'"
  >
    <span class="menu-item__title">{{ game.title }}</span>
  </span>
</a>
