import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { RouterLink } from '@angular/router';
import { GAMES_DETAILS, IGame, IGameDetail } from '@dev-fast/types';
import { TagComponent } from '@dev-fast/ui-components/lib/tag/tag.component';

import { LangRouterModule } from '@app/shared/directives';

@Component({
  selector: 'ui-game-card',
  standalone: true,
  imports: [CommonModule, LangRouterModule, TagComponent, RouterLink],
  templateUrl: './game-card.component.html',
  styleUrls: ['./game-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GameCardComponent {
  @Input() game: IGame | undefined;
  @Input() titleType: 'hover' | 'bottom' = 'hover';
  // @Input() public status: GameStatus | undefined;
  @Input() isNew = false;
  @Input() isActive = false;
  gamesDetails = GAMES_DETAILS;
  get gameDetail(): IGameDetail | undefined {
    return this.game ? this.gamesDetails[this.game.key] : undefined;
  }
}
