import { Inject, Injectable, Optional } from '@angular/core';

import { BB_ROUTES, BB_ROUTES_DEFAULT, FEATURE_FLAGS, FEATURE_FLAGS_DEFAULT, IBBConfig } from './feature-flags.model';

@Injectable({ providedIn: 'root' })
export class FeatureFlagsService {
  featureFlags: Record<string, boolean>;
  bbConfig: IBBConfig;

  constructor(
    @Optional() @Inject(FEATURE_FLAGS) featureFlags: Record<string, boolean> | null,
    @Optional() @Inject(BB_ROUTES) bbConfig: IBBConfig | null
  ) {
    this.featureFlags = featureFlags ?? FEATURE_FLAGS_DEFAULT;
    this.bbConfig = bbConfig ?? BB_ROUTES_DEFAULT;
  }

  getFlags(): Record<string, boolean> {
    return this.featureFlags;
  }
  getBBConfig(): IBBConfig {
    return this.bbConfig;
  }
  addFlags(dict: Record<string, boolean>): void {
    this.featureFlags = { ...this.featureFlags, ...dict };
  }
  isFeatureFlagEnabled(flags: string[]): boolean {
    const compare = flags.filter((value) => this.featureFlags[value] !== undefined && this.featureFlags[value]);
    return this.featureFlags && compare.length !== 0;
  }
}
