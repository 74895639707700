import { DateTime } from 'luxon';
/** Сравнение года, если текущий год, то возвращаем пустую строку
 * @param value - год. Напр. 2021
 */
export const getNormalizedYear = (value: string | null): string => {
  if (!value) {
    return '';
  }
  const nowYear = DateTime.now().year.toString();
  return value === nowYear ? '' : value;
};
