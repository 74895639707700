import { CommonModule } from '@angular/common';
import { Component, NgModule } from '@angular/core';
// TODO Лучше удалить этот компонент. Целый компонент для того, чтобы стилистически оборачивать элементы в карточки.
//  Куда оптимизированнее и проще будет сделать глобальный класс
@Component({
  selector: 'app-ui-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
})
export class CardComponent {}

@NgModule({
  imports: [CommonModule],
  declarations: [CardComponent],
  exports: [CardComponent],
})
export class CardComponentModule {}
