import { inject, Injectable } from '@angular/core';
import {
  CLOUD_DOMAIN,
  countriesDictionary,
  GIFT_PROVIDERS_TOKEN,
  IActivePayment,
  ICountry,
  ICryptoMethodData,
  IGiftProvider,
  IMarketplaceKitData,
  IP2pDepositingItem,
  IP2pDepositItem,
  IPaymentParams,
  IPaymentSelectedV2,
  IPaymentTypeV2,
  ISteamInventoryAutoSelect,
  ISteamMappedItems,
  ISteamStoreInventory,
  ModalNames,
  PaymentExchangeCurrency,
  StateActionStatus,
  SteamItemsStatus,
  TransactionWay,
} from '@dev-fast/types';
import { Actions, Select } from '@ngxs/store';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { combineLatest, map, Observable } from 'rxjs';

import { OpenModal } from '@app/core/state/modals';
import {
  DepositSelected,
  GetDepositingAutoSelection,
  GetSteamInventory,
  P2pCommonState,
  P2pDepositState,
  ResetSelected,
  ToggleDepositItem,
} from '@app/core/state/p2p';
import { actionLoadStatus } from '@app/shared/utils';

import { SetTransactionWay } from '../payment.actions';
import { RefillState } from '../refill';
import {
  ChangePaymentsParams,
  CreateNewWallet,
  GetCryptoWallets,
  GetPaymentMethodsNew,
  LeftRefill,
  Refill,
  RefillWithCode,
  SelectCountry,
  SelectPayment,
  SetWalletTabIndex,
  TogglePaymentTypeSize,
} from '../refill/refill.actions';

@Injectable()
export class RefillService {
  readonly #actions$ = inject(Actions);

  @Select(RefillState.currentCountry)
  readonly currentCountry$!: Observable<ICountry | null>;

  @Select(P2pDepositState.depositingItems)
  readonly depositingItems$!: Observable<IP2pDepositingItem[]>;

  @Select(RefillState.paymentTypesV2)
  readonly paymentTypesV2$!: Observable<Record<number, IPaymentTypeV2> | null>;

  @Select(P2pDepositState.selected)
  readonly selectedItems$!: Observable<IP2pDepositItem[]>;

  @Select(RefillState.selectedPayment)
  readonly selectedPayment$!: Observable<IPaymentSelectedV2 | null>;

  @Select(P2pCommonState.steamInventory)
  readonly steamInventory$!: Observable<ISteamStoreInventory[]>;

  @Select(P2pCommonState.steamInventoryStatus)
  readonly steamInventoryStatus$!: Observable<SteamItemsStatus>;

  @Select(RefillState.cryptoData)
  readonly cryptoData$!: Observable<ICryptoMethodData>;

  readonly steamItemsData$: Observable<ISteamMappedItems> = combineLatest([
    this.steamInventory$,
    this.selectedItems$,
    this.depositingItems$,
  ]).pipe(
    map((val) => {
      return {
        steamItems: val[0],
        selectedItems: val[1],
        depositingItems: val[2],
      };
    }),
  );
  readonly countriesList: ICountry[] = Object.entries(countriesDictionary).map((country, index) => {
    return {
      key: country[0],
      value: country[1],
      icon: `//${CLOUD_DOMAIN}/country-flags/${country[0].toLowerCase()}.png`,
      index: index,
    };
  });
  readonly giftProviders: IGiftProvider[] = inject<IGiftProvider[]>(GIFT_PROVIDERS_TOKEN);

  readonly createWalletStatus$: Observable<StateActionStatus> = actionLoadStatus(this.#actions$, CreateNewWallet);

  readonly paymentsLoadStatus$: Observable<StateActionStatus> = actionLoadStatus(this.#actions$, GetPaymentMethodsNew);

  readonly depositActionStatus$: Observable<StateActionStatus> = actionLoadStatus(this.#actions$, DepositSelected);

  @Dispatch()
  changePaymentsParams(params: IPaymentParams | null): ChangePaymentsParams {
    return new ChangePaymentsParams(params);
  }
  @Dispatch()
  createInvoice(): Refill {
    return new Refill();
  }
  @Dispatch()
  createNewWallet(userId: number, walletName: PaymentExchangeCurrency): CreateNewWallet {
    return new CreateNewWallet(userId, walletName);
  }
  @Dispatch()
  depositSelected(): DepositSelected {
    return new DepositSelected(true);
  }
  @Dispatch()
  getDepositAutoSelection(params: ISteamInventoryAutoSelect): GetDepositingAutoSelection {
    return new GetDepositingAutoSelection(params);
  }
  @Dispatch()
  getPaymentsMethodsNew = (countryIndex: number, userId: number): GetPaymentMethodsNew => {
    const country = this.countriesList[countryIndex];
    return new GetPaymentMethodsNew(country, userId);
  };
  @Dispatch()
  getSteamItems(): GetSteamInventory {
    return new GetSteamInventory();
  }
  @Dispatch()
  getCryptoWallets(userId: number): GetCryptoWallets {
    return new GetCryptoWallets(userId);
  }
  @Dispatch()
  leftRefill(): LeftRefill {
    return new LeftRefill();
  }
  @Dispatch()
  openNotifyRefillModal(modal: ModalNames, payload?: any): OpenModal {
    return new OpenModal(modal, payload);
  }
  @Dispatch()
  refillWithCode(code: string): ResetSelected {
    return new RefillWithCode(code);
  }
  @Dispatch()
  resetSelected(): ResetSelected {
    return new ResetSelected();
  }
  @Dispatch()
  selectCountry = (countryIndex: number): SelectCountry => {
    const country = this.countriesList[countryIndex];
    return new SelectCountry(country);
  };
  @Dispatch()
  selectPayment = (activePayment: IActivePayment | null): SelectPayment => {
    return new SelectPayment(activePayment);
  };
  @Dispatch()
  setTransactionWay(way: TransactionWay | null): SetTransactionWay {
    return new SetTransactionWay(way);
  }
  @Dispatch()
  setWalletTabIndex = (index: number): SetWalletTabIndex => {
    return new SetWalletTabIndex(index);
  };
  @Dispatch()
  showBatchDetailsModal(item: IMarketplaceKitData | ISteamStoreInventory): OpenModal {
    return new OpenModal(ModalNames.P2P_KIT_DETAILS, item);
  }
  @Dispatch()
  togglePaymentTypeSize = (isExpanded: boolean, typeIndex: number): TogglePaymentTypeSize => {
    return new TogglePaymentTypeSize(isExpanded, typeIndex);
  };
  @Dispatch()
  toggleDepositItem(value: IP2pDepositItem): ToggleDepositItem {
    return new ToggleDepositItem(value);
  }
}
