import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
// import { NotificationsService } from '@app/notifications';
import { ApiService } from '@dev-fast/backend-services';
import { Socket as WrappedSocket } from 'ngx-socket-io';
// FIXME сокеты
// import { Socket } from 'ngx-socket-io';
import { Observable, of } from 'rxjs';

import { Environments, EnvironmentService } from '@app/core/environment-service';

@Injectable({
  providedIn: 'root',
})
export class AuthApiService {
  private environment: Environments;
  private endpoint = 'api/authorization';

  constructor(
    private readonly api: ApiService,
    private readonly environmentService: EnvironmentService,
    private readonly ws: WrappedSocket // private readonly ws: Socket, // @Optional() private readonly notifyService: NotificationsService
  ) {
    this.environment = this.environmentService.environments;
  }

  public socketLogin = (payload: { accessToken: string }): any => this.ws.emit('user.auth', payload);
  // public login = (payload: { accessToken: string }) => console.log(payload);
  public logout = (): Observable<void> =>
    this.api.post(`${this.environment.GATEWAY_URL}/${this.endpoint}/logout`, null, { withCredentials: true });

  public updateTokens = (
    refreshToken: string
  ): Observable<{
    accessToken: string;
    refreshToken: string;
  }> =>
    this.api.post<{ accessToken: string; refreshToken: string }>(`${this.environment.GATEWAY_URL}/${this.endpoint}/refresh-token`, {
      refreshToken,
    });

  public getAuthorizationTokens = (code: string): Observable<{ accessToken: string }> =>
    this.api.post<{ accessToken: string }>(`${this.environment.GATEWAY_URL}/${this.endpoint}/tokens`, { code }, { withCredentials: true });

  public removeAccount = (account: string): Observable<void> =>
    this.api.delete<void>(`${this.environment.GATEWAY_URL}/api/providers/${account}`, {
      withCredentials: true,
    });

  private onError(e: HttpErrorResponse): Observable<void> {
    // this.notifyService.addNotification({
    //   id: Date.now(),
    //   type: 'error',
    //   icon: 'warning',
    //   message: e.error.message || e.error.error,
    //   createDate: Date.now(),
    //   status: NotificationStatus.new,
    //   system: true,
    // });
    return of();
  }
}
