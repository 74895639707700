import { ChangeDetectionStrategy, Component } from '@angular/core';
import {
  ActivePanel,
  IInventoryItem,
  IInventoryRequestParams,
  ILocales,
  InventoryTradeBalance,
  IShopMeta,
  ISkinItem,
  IUserDetailed,
  LegacyGameConfig,
  LegacyGameState,
  LegacyLiteGameState,
  Panel,
  Permissions,
  SkinClickEvent,
  SubPanel,
} from '@dev-fast/types';
import { map, Observable, tap } from 'rxjs';

import { SoundService } from '@app/core/sound-service';
import { ConfirmDialogService } from '@app/shared/modules';

import { DesktopRightsidebarService } from './desktop-right-sidebar.service';
import { notificationAnimation } from './notification.animation';

@Component({
  selector: 'app-desktop-right-sidebar',
  templateUrl: './desktop-right-sidebar.component.html',
  styleUrls: ['./desktop-right-sidebar.component.scss'],
  providers: [DesktopRightsidebarService],
  animations: [notificationAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DesktopRightSidebarComponent {
  public user$: Observable<IUserDetailed | null | undefined>;
  public activePanel$: Observable<ActivePanel | null>;
  public panels = Panel;
  public subPanels = SubPanel;
  public permissions = Permissions;
  public gameInProgress$: Observable<boolean>;
  private activePanel: ActivePanel | null;
  public readonly locales$: Observable<ILocales[]>;
  public readonly currentLocale$: Observable<ILocales>;

  public totalBalance$: Observable<number>;
  // public notificationList$: Observable<INotification[]>;
  public hasNewNotifications$: Observable<boolean>;
  public countNewNotifications$: Observable<number>;

  public inventoryItems$: Observable<IInventoryItem[] | null>;
  public selectedInventoryItems$: Observable<IInventoryItem[]>;
  public shopItems$: Observable<ISkinItem[]>;
  public selectedShopItems$: Observable<ISkinItem[]>;
  public inventoryTradeBalance$: Observable<InventoryTradeBalance>;
  public shopParams$: Observable<IInventoryRequestParams>;
  public inventoryParams$: Observable<IInventoryRequestParams>;
  public shopMeta$: Observable<IShopMeta>;
  public legacyConfig$: Observable<LegacyGameConfig | null>;
  public legacyState$: Observable<LegacyGameState | null>;
  public legacyLiteState$: Observable<LegacyLiteGameState | null>;
  public participatedSuccess$: Observable<{ items: any[]; timeout: number }>;
  public inventoryShopLoading$: Observable<boolean>;
  public legacyParticipation$: Observable<IInventoryItem[]>;
  public lockedPanels$: Observable<Panel[]>;
  public canLevelUp$: Observable<boolean>;
  public soundStatus$: Observable<boolean>;

  constructor(
    private readonly service: DesktopRightsidebarService,
    // eslint-disable-next-line @typescript-eslint/no-shadow
    private readonly ConfirmDialogService: ConfirmDialogService,
    private readonly soundService: SoundService
  ) {
    this.inventoryItems$ = this.service.inventoryItems$;
    this.selectedInventoryItems$ = this.service.selectedInventoryItems$;
    this.shopItems$ = this.service.shopItems$;
    this.selectedShopItems$ = this.service.selectedShopItems$;
    this.inventoryTradeBalance$ = this.service.inventoryTradeBalance$;
    this.totalBalance$ = this.service.totalBalance$;
    this.shopParams$ = this.service.shopParams$;
    this.inventoryParams$ = this.service.inventoryParams$;
    this.shopMeta$ = this.service.shopMeta$;
    this.gameInProgress$ = this.service.gameInProgress$;

    this.activePanel = null;
    this.activePanel$ = this.service.activePanel$.pipe(
      // fixme чет придумать лучше
      // filter((value) => !value || value.panel !== Panel.EVENT_MENU),
      map((value) => (value && value.panel === Panel.EVENT_MENU ? null : value)),
      tap((val) => (this.activePanel = val))
    );
    this.user$ = this.service.user$;
    this.canLevelUp$ = this.service.canLevelUp$;
    // this.notificationList$ = this.service.notifications$;
    this.hasNewNotifications$ = this.service.hasNewNotifications$;
    this.countNewNotifications$ = this.service.countNewNotifications$;

    this.legacyConfig$ = this.service.legacyConfig$;
    this.legacyState$ = this.service.legacyState$;
    this.legacyLiteState$ = this.service.legacyLiteState$;
    this.participatedSuccess$ = this.service.participatedSuccess$;
    this.legacyParticipation$ = this.service.legacyParticipation$;
    this.inventoryShopLoading$ = this.service.inventoryShopLoading$.pipe(map((el) => !!el));
    this.lockedPanels$ = this.service.lockedPanels$;

    this.soundStatus$ = this.soundService.status$;
    this.locales$ = this.service.locales$;
    this.currentLocale$ = this.service.currentLocale$;
  }
  public onSwitchLang = (locale: ILocales): void => {
    this.service.switchLang(locale);
  };
  public toggleSounds(): void {
    this.soundService.toggleSoundStatus();
  }
  public goToMobile(): void {
    this.ConfirmDialogService.open(
      {
        message: 'Do you want to switch to the mobile version of the site?',
        title: 'Mobile version of the site',
        confirmText: 'Switch to mobile version',
      },
      (confirmed) => {
        if (confirmed) {
          this.service.navigateToMobile();
        }
        return;
      }
    );
  }

  // FIXME вынести в стейт
  public isLockedMenuItem(lockedPanels: Panel[], panel: Panel): boolean {
    return lockedPanels.includes(panel);
  }
  public onAvatar(user: IUserDetailed): void {
    this.service.navigate([user.experience.xp > user.experience.nextLevelXp ? '/account/experience' : '/account']);
  }
  public isActivePanel(panel: Panel): boolean {
    return this.activePanel ? this.activePanel.panel === panel : false;
  }
  public onChangeActivePanel = (panel: Panel | null, event?: Event): void => {
    // FIXME Добавить активный класс (active) для выбранного элемента

    if (panel && panel === this.activePanel?.panel) {
      this.service.changeActivePanel(null);
    } else {
      panel ? this.service.changeActivePanel({ panel, subPanel: SubPanel.NONE }) : this.service.openAvailablePanel();
    }
  };
  public onToggleExchangePanel(): void {
    if (this.activePanel?.subPanel === SubPanel.NONE) {
      this.service.changeActivePanel({ panel: Panel.INVENTORY, subPanel: SubPanel.EXHANGE });
    } else {
      this.service.changeActivePanel({ panel: Panel.INVENTORY, subPanel: SubPanel.NONE });
    }
  }
  public onCloseSubInventoryPanel(): void {
    this.service.changeActivePanel({ panel: Panel.INVENTORY, subPanel: SubPanel.NONE });
  }
  public clickOnSkinInventory(event: SkinClickEvent): void {
    this.service.clickOnSkinInventory(event);
  }
  public clickOnSkinShop(event: SkinClickEvent): void {
    this.service.clickOnSkinShop(event);
  }
  public onSellAll(): void {
    this.service.sellAll();
  }

  public clickOnCreateTrade(): void {
    this.service.createTrade();
  }
  // public onReloadPrice() {
  //   this.service.getInventoryItems();
  // }
  public changeInventoryParams(event: IInventoryRequestParams): void {
    this.service.changeParamsInventory(event);
  }
  public changeShopParams(event: IInventoryRequestParams): void {
    this.service.changeParamsShop(event, 'old');
  }
  public onWatchAllNotification(): void {
    this.service.watchAllNotifications();
  }
  public onDeleteSelectedNotification(id: number): void {
    this.service.removeNoty(id);
  }
  public onParticipate(): void {
    this.service.participate();
  }
  public onCleanParticipate(ids: number[]): void {
    this.service.unselectInventoryItems(ids);
  }

  public onCloseAttention(): void {
    // console.log('trades close attention');
  }

  public onChangeTradesSettings(settings: object): void {
    // console.log('trades duration settings is ', settings);
  }

  public openTechSupportDialog(): void {
    // const params = {
    //   width: '35.75rem',
    //   height: 'auto',
    // };
    // this.dialog.open(TechSupportComponent, params);
    this.service.openSupportSelectorModal();
  }

  public sendMessage(message: object): void {
    // console.log('chat send message', message);
  }
  public onLogin(): void {
    this.service.login();
  }
  public onLogout(): void {
    this.service.logout();
  }
}
