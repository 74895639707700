import { ProfileTypes } from '@dev-fast/types';

export const validateAccountLevel = (current: ProfileTypes, needed: ProfileTypes): boolean => {
  const base = {
    [ProfileTypes.DEMO]: [
      ProfileTypes.DEMO,
      ProfileTypes.BRONZE,
      ProfileTypes.SILVER,
      ProfileTypes.GOLD,
      ProfileTypes.PLATINUM,
      ProfileTypes.DIAMOND,
    ],
    [ProfileTypes.BRONZE]: [
      ProfileTypes.BRONZE,
      ProfileTypes.SILVER,
      ProfileTypes.GOLD,
      ProfileTypes.PLATINUM,
      ProfileTypes.DIAMOND,
    ],
    [ProfileTypes.SILVER]: [ProfileTypes.SILVER, ProfileTypes.GOLD, ProfileTypes.PLATINUM, ProfileTypes.DIAMOND],
    [ProfileTypes.GOLD]: [ProfileTypes.GOLD, ProfileTypes.PLATINUM, ProfileTypes.DIAMOND],
    [ProfileTypes.PLATINUM]: [ProfileTypes.PLATINUM, ProfileTypes.DIAMOND],
    [ProfileTypes.DIAMOND]: [ProfileTypes.DIAMOND],
  };
  if (!base[needed]) {
    console.error(`No account ${needed} in types!`);
    return false;
  }
  return !base[needed].includes(current);
};
