<div
  class="skinFloatIndicator relative w-100"
  *ngIf="float"
>
  <div
    class="dot absolute"
    [style.left]="'calc(' + float * 100 + '% - 5.5px)'"
  ></div>
  <div class="line w-100"></div>
</div>
