import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-ui-arrow-up',
  templateUrl: './arrow-up.component.html',
  styleUrls: ['./arrow-up.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ArrowUpComponent {
  @Input() public isShow = true;
}
@NgModule({
  declarations: [ArrowUpComponent],
  imports: [CommonModule, FormsModule, MatIconModule, ReactiveFormsModule],
  exports: [ArrowUpComponent],
})
export class ArrowUpComponentModule {}
