import { Directive, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, Renderer2 } from '@angular/core';

@Directive({
  selector: '[clickStop]',
})
export class StopPropagationDirective implements OnInit, OnDestroy {
  @Input() public extraCondition = true;
  @Output() public stopPropEvent = new EventEmitter();
  public unsubscribe: (() => void) | undefined;

  constructor(private renderer: Renderer2, private element: ElementRef) {}

  public ngOnInit() {
    this.unsubscribe = this.renderer.listen(this.element.nativeElement, 'click', (event) => {
      if (!this.extraCondition) return;
      event.stopPropagation();
      this.stopPropEvent.emit(event);
    });
  }

  public ngOnDestroy() {
    if (this.unsubscribe) {
      this.unsubscribe();
    }
  }
}
