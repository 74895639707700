export interface IRefillOrder {
  orderId: number;
  userId: number;
  amount: number;
  price: number;
  timestampAdded: string;
  isPaid: boolean;
  timestampPaid: string;
  isRefunded: boolean;
}
