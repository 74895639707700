<div
  class="increase-container"
  [class]="differencePosition"
>
  <ng-container *ngIf="valueType === 'number'">
    <div class="main-wrapper">
      <input
        *ngIf="mainPerfix"
        type="button"
        class="main prefix"
        [value]="mainPerfix"
      />
      <input
        type="button"
        class="main"
        [class]="className + '_' + index"
        [value]="curValue"
      />
    </div>
    <div class="difference-wrapper">
      <input
        type="button"
        class="difference prefix"
        [ngClass]="{ increase: difference > 0, decrease: difference < 0, show: isDifferenceShow }"
        [value]="differencePrefix"
      />
      <input
        type="button"
        class="difference"
        [ngClass]="{ increase: difference > 0, decrease: difference < 0, show: isDifferenceShow }"
        [value]="difference"
      />
    </div>
  </ng-container>
  <ng-container *ngIf="valueType === 'currency' && currency">
    <div class="main-wrapper">
      <input
        type="button"
        class="main prefix"
        [value]="currency.prefix"
      />
      <input
        type="button"
        class="main"
        [class]="className + '_' + index"
        [value]="curValue"
      />
    </div>
    <div class="difference-wrapper">
      <input
        type="button"
        class="difference prefix"
        [ngClass]="{ increase: difference > 0, decrease: difference < 0, show: isDifferenceShow }"
        [value]="differencePrefix"
      />
      <input
        type="button"
        class="difference"
        [ngClass]="{ increase: difference > 0, decrease: difference < 0, show: isDifferenceShow }"
        [value]="difference"
      />
    </div>
  </ng-container>

  <ng-container *ngIf="valueType === 'percent'">
    <div class="main-wrapper main-wrapper_percent">
      <input
        *ngIf="mainPerfix"
        type="button"
        class="main prefix"
        [value]="mainPerfix"
      />

      <input
        type="button"
        class="main"
        [class]="className + '_' + index"
        [value]="curValue | appPercent: { maxValue: maxValue, rank: rank }"
      />
    </div>
    <div class="difference-wrapper">
      <input
        type="button"
        class="difference prefix"
        [ngClass]="{ increase: difference > 0, decrease: difference < 0, show: isDifferenceShow }"
        [value]="differencePrefix"
      />
      <input
        type="button"
        class="difference"
        [ngClass]="{ increase: difference > 0, decrease: difference < 0, show: isDifferenceShow }"
        [value]="difference | appPercent: { maxValue: maxValue, rank: rank }"
      />
    </div>
  </ng-container>
</div>
