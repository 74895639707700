import { Injectable } from '@angular/core';
import { IP2pDepositingItem, IP2pDepositItem, MarketAction, ModalNames } from '@dev-fast/types';
import { Actions, Select, Store } from '@ngxs/store';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { Observable } from 'rxjs';

import { OpenModal } from '@app/core/state/modals';

import {
  ChangeSelectedItem,
  Deposit,
  DepositSelected,
  P2pDepositState,
  PauseDepositing,
  RemoveDepositItem,
  RemoveMarketItems,
  ReqAppointing,
  ResetSelected,
  ResumeDepositing,
  SellNow,
  StopDepositing,
  ToggleDepositItem,
} from '../states';

@Injectable()
export class P2pDepositService {
  @Select(P2pDepositState.selected)
  public selected$!: Observable<IP2pDepositItem[]>;

  @Select(P2pDepositState.selectedSum)
  public selectedSum$!: Observable<number>;

  @Select(P2pDepositState.depositingItems)
  public depositing$!: Observable<IP2pDepositingItem[]>;

  @Select(P2pDepositState.isItemsOnPause)
  public isItemsOnPause$!: Observable<boolean>;

  // public depositModifier$ = this.actions$.pipe(
  //   ofActionSuccessful(GetDepositModifierSuccess),
  //   map((action: GetDepositModifierSuccess) => action.payload)
  // );

  constructor(private readonly _store: Store, private readonly actions$: Actions) {}

  // public getDepositById(id: number): Observable<IP2pDepositItem> {
  //   return this._store.select(P2pDepositState.depositById).pipe(
  //     map((filterFn) => filterFn(id)),
  //     filter((el): el is IP2pDepositItem => !!el)
  //   );
  // }
  public getDepositById(id: number): IP2pDepositItem | undefined {
    return this._store.selectSnapshot(P2pDepositState.selected).find((s) => s.steamInventoryId === id);
  }

  public requestConfirm(id: number): void {
    this.reqAppointing(id, MarketAction.Confirm);
  }

  public requestDecline(id: number): void {
    this.reqAppointing(id, MarketAction.Decline);
  }

  @Dispatch() public removeDepositItem = (payload: IP2pDepositItem): RemoveDepositItem => new RemoveDepositItem(payload);
  @Dispatch() public toggleDepositItem = (payload: IP2pDepositItem): ToggleDepositItem => new ToggleDepositItem(payload);
  @Dispatch() public depositSelected = (): DepositSelected => new DepositSelected();
  @Dispatch() public resetSelected = (): ResetSelected => new ResetSelected();
  @Dispatch() public reqAppointing = (id: number, action: MarketAction): ReqAppointing => new ReqAppointing(id, action);
  @Dispatch() public sellNow = (id: number): SellNow => new SellNow(id);
  @Dispatch() public deleted = (ids: number[]): RemoveMarketItems => new RemoveMarketItems(ids);
  @Dispatch() public stop = (): StopDepositing => new StopDepositing();
  @Dispatch() public pause = (id?: number): PauseDepositing => new PauseDepositing(id);
  @Dispatch() public resume = (id?: number): ResumeDepositing => new ResumeDepositing(id);
  @Dispatch() public deposit = (id: number, price: number): Deposit => new Deposit(id, price);
  @Dispatch() public setDepositById = (item: IP2pDepositItem): ChangeSelectedItem => new ChangeSelectedItem(item);
  @Dispatch() public showAuctionCompletedModal = (item: Observable<IP2pDepositingItem>): OpenModal =>
    new OpenModal(ModalNames.P2P_AUCTION_COMPLETED, item);
  // @Dispatch() public getDepositModifier = () => new GetDepositModifier();
}
