import { InjectionToken } from '@angular/core';
import { ILocales, IUserDetailed } from '@dev-fast/types';
import { Observable } from 'rxjs';

export const REFILL_BANNER_ENGINE: InjectionToken<RefillBannerEngine> = new InjectionToken('REFILL_BANNER_ENGINE');

export interface RefillBannerEngine {
  readonly user$: Observable<IUserDetailed | null | undefined>;
  readonly currentLocale$: Observable<ILocales>;

  openAuthModal(): void;
}
