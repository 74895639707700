<div
  class="skin-item__wrapper"
  [class]="indexColor(index)"
>
  <div class="skin-item__info-container skin-item__info-container_main">
    <ng-container *ngIf="!!avatarSrc">
      <div class="skin-item__avatar-wrapper">
        <img
          class="skin-item__avatar-image"
          [src]="avatarSrc"
          alt
          skinItemImage
        />
      </div>
    </ng-container>
    <div class="skin-item__name-wrapper">
      <span
        class="skin-item__name"
        [class.skin-item__name_stat-trak]="statTrak"
      >
        <ng-container *ngIf="statTrak"> StatTrak™ </ng-container>
        {{ weaponName }}
      </span>
      <ng-container *ngIf="!!skinName">
        <span class="skin-item__skin-name">| {{ skinName }}</span>
      </ng-container>
      <ng-container *ngIf="skinWear">
        <span class="skin-item__revision-wear"> ({{ revisionWear(skinWear) }})</span>
      </ng-container>
    </div>
  </div>
  <div class="skin-item__info-container skin-item__info-container_stats">
    <div class="skin-item__stat skin-item__stat_price">
      {{ price | appCurrency }}
    </div>
    <ng-container *ngIf="range">
      <div class="skin-item__stat skin-item__stat_range">
        <ng-container *ngIf="range.min">
          {{ range.min }}
        </ng-container>
        <ng-container *ngIf="range.min && range.max"> - </ng-container>
        <ng-container *ngIf="range.max">
          {{ range.max }}
        </ng-container>
      </div>
    </ng-container>
    <div class="skin-item__stat skin-item__stat_chance">
      {{ calcChancePercent(chance) }}
    </div>
  </div>
</div>
