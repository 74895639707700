import {
  ActiveSoundGame,
  Affise,
  AgreementName,
  AnimationSpeed,
  BaseType,
  Design,
  ICrossService,
  IFaqStorageDictionary,
  IGame,
  ILocales,
  INotification,
  IPromoCode,
  SoundNameFast,
  TClassicViewMode,
  Themes,
} from '@dev-fast/types';

export interface LocalStorageModel {
  affise?: Affise;
  availableGames?: IGame[];
  crossService?: ICrossService;
  currency?: string;
  lang?: ILocales;
  soundSettings?: Record<string, boolean>;
  sound?: boolean;
  theme?: Themes;
  // user?: IUserDetailed;
  isAuth?: boolean;
  volume?: number;
  [ActiveSoundGame.CASES_ACTIVE_SOUND]?: SoundNameFast;
  [ActiveSoundGame.CASE_BATTLE_ACTIVE_SOUND]?: SoundNameFast;
  [ActiveSoundGame.BLACK_FRIDAY_EVENT]?: string;
  notifications?: INotification[];
  'preview-deposit-modifier'?: { agree: boolean };
  'marketplace-showWarn'?: boolean;
  fastDelivery?: boolean;
  animationSpeed?: AnimationSpeed;
  referralCode?: IPromoCode;
  unknownRefCode?: string;
  isDesktop?: boolean;
  // refillCountry: string;
  classicTypeView?: TClassicViewMode;
  lastGames?: string[];
  design?: Design;
  refillCountry?: any;
  quickFaq?: IFaqStorageDictionary;
  agreements?: Partial<Record<AgreementName, boolean>>;
  agreementsNew?: BaseType<number, Partial<Record<AgreementName, boolean>>>[];
  'bf-hint-shown': boolean;
  // inventorySellItems?: { data: SellItemsData; orderId: number }[];
  techWorksWidgetViewed?: boolean;
  auctionCompletedWarningViewed?: boolean;
}
