import { StateActionStatus } from '@dev-fast/types';
import { Actions, ActionType, ofActionDispatched, ofActionErrored, ofActionSuccessful } from '@ngxs/store';
import { delay, map, merge, Observable, startWith } from 'rxjs';

export const actionLoadStatus = (
  actions$: Actions,
  actionClass: ActionType | ActionType[],
  completeDelay = 0,
): Observable<StateActionStatus> => {
  const actionClasses = Array.isArray(actionClass) ? actionClass : [actionClass];
  return merge(
    actions$.pipe(
      ofActionErrored(...actionClasses),
      delay(completeDelay),
      map(() => StateActionStatus.ERROR),
    ),
    actions$.pipe(
      ofActionDispatched(...actionClasses),
      map(() => StateActionStatus.DISPATCH),
    ),
    actions$.pipe(
      delay(completeDelay),
      ofActionSuccessful(...actionClasses),
      map(() => StateActionStatus.SUCCESS),
    ),
  ).pipe(startWith(StateActionStatus.DEFAULT));
};
