<ng-container *ngIf="item">
  <ng-container *ngIf="item.baseItem.isSouvenir; else stattrackTemplate"
    ><span class="souvenir"> SV </span>
    <ng-container *ngIf="item.baseItem.exterior">|</ng-container>
  </ng-container>
  <ng-template #stattrackTemplate>
    <ng-container *ngIf="item.baseItem.statTrak">
      <span class="stat-track"> ST </span> <ng-container *ngIf="item.baseItem.exterior">|</ng-container>
    </ng-container>
  </ng-template>
  <ng-container *ngIf="item.baseItem.exterior">
    <ng-container *ngIf="exteriorShortView || item.float; else fullExteriorTemplate">
      {{ toShortRevision(item.baseItem.exterior) }}
    </ng-container>
    <ng-template #fullExteriorTemplate> {{ item.baseItem.exterior }} </ng-template>
  </ng-container>
  <ng-container *ngIf="item.float">
    <span
      [matTooltipClass]="['gray-500', 'float-tooltip']"
      [matTooltipDisabled]="!toShowTooltip"
      matTooltip="{{ item.float }}"
      matTooltipPosition="above"
    >
      | {{ getFormatNumber(item.float, numberFormat) }}
    </span>
  </ng-container>
</ng-container>
