import { animate, query, sequence, style, transition, trigger } from '@angular/animations';

export const wideningAnimation = trigger('wideningAnimation', [
  transition(':enter', [
    query(
      ':self',
      [
        style({ width: 0, margin: 0, padding: 0, opacity: 0 }),
        sequence([
          animate('150ms ease-in-out', style({ width: '*', margin: '*', padding: '*' })),
          animate('150ms ease-in-out', style({ opacity: 1 })),
        ]),
      ],
      { optional: true },
    ),
  ]),
]);
