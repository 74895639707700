import { Injectable } from '@angular/core';
import {
  IActivateCampaignResponse,
  ICampaignInfo,
  IPromoCode,
  IReferralBonus,
  IReferralCampaignDto,
  IReferralCampaignLite,
  IReferralCampaignReport,
  IReferralCampaignReportParams,
  IReferralCode,
  SocketResponse,
} from '@dev-fast/types';
import { Socket } from 'ngx-socket-io';
import { map, Observable } from 'rxjs';

import { Environments, EnvironmentService } from '@app/core/environment-service';

import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class ReferralsApiService {
  private environment: Environments;

  constructor(
    private readonly api: ApiService,
    private readonly environmentService: EnvironmentService,
    private readonly ws: Socket,
  ) {
    this.environment = this.environmentService.environments;
  }

  requestCampaignProfit(params: { campaignId?: number; referralId?: number }): Observable<any> {
    return this.api.post(`${this.environment.GATEWAY_URL}/api/referral-income/take`, params, { withCredentials: true });
  }

  requestCampaignCreate(params: Partial<IReferralCode>): Observable<void> {
    const appId = 2; // TODO: CrossService id from nowhere (csgo);
    return this.api.post(
      `${this.environment.GATEWAY_URL}/api/referral-campaigns`,
      {
        ...params,
        appId,
      },
      { withCredentials: true },
    );
  }

  requestCampaignPatch(params: Partial<IReferralCode>): Observable<IReferralCampaignLite> {
    const { id, ...rest } = params;
    return this.api.patch(`${this.environment.GATEWAY_URL}/api/referral-campaigns/${id}`, rest, {
      withCredentials: true,
    });
  }

  requestCampaignCodeExisting(code: string): Observable<void> {
    return this.api.post(`${this.environment.GATEWAY_URL}/api/referral-campaigns/code`, { code });
  }
  requestPromoActivation(code: string): Observable<IActivateCampaignResponse> {
    return this.api.post<IActivateCampaignResponse>(
      `${this.environment.GATEWAY_URL}/api/referral-codes/${encodeURIComponent(code)}/activate`,
      {},
      { withCredentials: true },
    );
  }
  requestPromoClicks(code: string): Observable<IPromoCode> {
    return this.api.post<IPromoCode>(`${this.environment.GATEWAY_URL}/api/referral-codes/${encodeURIComponent(code)}/click`);
  }
  getReferralsCampaign(): Observable<any> {
    return this.api.get<any>(`${this.environment.GATEWAY_URL}/referrals/kek`, { withCredentials: true });
  }
  getReferralCampaigns(): Observable<IReferralCampaignDto> {
    return this.api.get<IReferralCampaignDto>(`${this.environment.GATEWAY_URL}/api/referral-campaigns`, {
      withCredentials: true,
    });
  }

  getCurrentReferralCampaign(): Observable<IPromoCode | null> {
    return this.api.get<IPromoCode | null>(`${this.environment.GATEWAY_URL}/api/referral-campaigns/current`, {
      withCredentials: true,
    });
  }
  getInfo(): Observable<ICampaignInfo> {
    return this.api.get(`${this.environment.GATEWAY_URL}/api/referral-income`, { withCredentials: true });
  }
  getCampaignReferralsReport(params: IReferralCampaignReportParams): Observable<IReferralCampaignReport[]> {
    return this.api.get<IReferralCampaignReport[]>(`${this.environment.GATEWAY_URL}/api/referral-reports/incomes`, {
      withCredentials: true,
      params,
    });
  }
  bonusCaseReceived(): Observable<SocketResponse<IReferralBonus>> {
    return this.ws
      .fromEvent<IReferralBonus>('referral-bonus:add-cases')
      .pipe(map((value) => ({ payload: value, name: 'referral-bonus:add-cases' })));
  }
}
