import { HttpErrorResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CaseBattleBackendService } from '@dev-fast/backend-services';
import {
  ActiveSoundGame,
  CaseBattleGameStatus,
  CaseBattleLobbyLists,
  CaseBattlePhases,
  IBattleEmojiNoty,
  IBattleEmojiSocketMessage,
  IBattleEndEmojisNoty,
  ICaseBattleDto,
  ICaseBattleFilter,
  ICaseBattleGame,
  ICaseBattleGameCreateDto,
  ICaseBattleGameCreateNotify,
  ICaseBattleGameFinishDto,
  ICaseBattleGameJoinNotify,
  ICaseBattleHistory,
  ICaseBattleOneGame,
  ICaseBattlePlayer,
  ICaseBattlePlayerCard,
  ICaseBattleRoundDto,
  ICaseBattleRoundResult,
  ICaseBattleTeam,
  ICaseBattleUserJoinDto,
  ICaseBattleWinnersModalData,
  ICaseItemDtoV2,
  ICaseRevisionItem,
  IEmojiInfo,
  IEmojiTimeStampDTO,
  IFilterRange,
  ModalNames,
  NotificationStatus,
  NotificationType,
  SocketResponse,
  SortingCaseBattleTypes,
  SoundNameFast,
} from '@dev-fast/types';
import { Navigate } from '@ngxs/router-plugin';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { append, insertItem, patch, removeItem, updateItem } from '@ngxs/store/operators';
import { catchError, EMPTY, merge, Observable, of, ReplaySubject, switchMap, tap } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { LanguageService } from '@app/core/language-service';
import { LocalStorageService } from '@app/core/local-storage-service';
import { NotificationsService } from '@app/core/notification-service';
import { GetInventoryInfo } from '@app/core/state/inventory';
import { UserState } from '@app/core/state/user-store';
import { getUniqueObjectsFromArr, minMaxValueOfItemField } from '@app/shared/utils';

import { CommonFiltersState } from '../../state';
import { CaseBattleModalService } from '../services/case-battle-modal.service';
import {
  AddBotToCaseBattle,
  AddCaseBattle,
  AddPlayer,
  AddSelectedItems,
  ChangeBattlesFilter,
  ChangeBattlesPriceRangeValues,
  ClearActiveLobby,
  CreateCaseBattle,
  CreatePlayerCards,
  EndBattleRound,
  EndRoundInList,
  EndRoundInLobby,
  FinishActiveBattle,
  GetAllBattles,
  GetBattleLastHistory,
  GetBattleUserHistory,
  GetCasesByIdsForLobby,
  GetLobbyById,
  GetOtherUsersBattleList,
  GetUserBattleList,
  InitCases,
  JoinCaseBattle,
  LeftBattles,
  RemoveCaseBattle,
  RemoveSelectedItems,
  ReplayGame,
  ReplayPreparation,
  ReplaySetRounds,
  SendEmoji,
  SetActiveEmoji,
  SetActiveSound,
  SetBattlePhase,
  SetCaseBattleFilters,
  SetFinallyEmojis,
  SetPrizeItemAndRange,
  ToggleBattleLists,
  WatchCaseBattle,
} from './case-battle.actions';
import { CASE_BATTLE_INITIAL_STATE, CaseBattleStateModel } from './case-battle.model';
import { CaseBattleCasesState } from './cases/cases.state';

@State<CaseBattleStateModel>({
  name: 'case_battle',
  defaults: CASE_BATTLE_INITIAL_STATE,
  children: [CaseBattleCasesState],
})
@Injectable()
export class CaseBattleState extends CommonFiltersState<ICaseBattleFilter> {
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  readonly #router = inject(Router);
  readonly #store = inject(Store);
  readonly #languageService = inject(LanguageService);
  readonly #notificationsService = inject(NotificationsService);
  readonly #caseBattleBackendService = inject(CaseBattleBackendService);
  readonly #caseBattleModalService = inject(CaseBattleModalService);
  readonly #storageService = inject(LocalStorageService);

  // для того что бы не лезть в стейт каждый раз когда приходит эвент
  // а в принципе этот костыль для того что бы таким образом якобы отписываться от сокетов.
  // Потому-что если отписаться по обычному то заново подписка не срабатывает
  private isActive = false;

  @Selector()
  static casesIsLoad({ availableCases }: CaseBattleStateModel): boolean {
    return !!availableCases.length;
  }
  @Selector()
  static activeLobby({ activeLobby }: CaseBattleStateModel): ICaseBattleGame | null {
    return activeLobby;
  }
  @Selector()
  static gamesList({ availableGames, availableGamesHistory, myGames }: CaseBattleStateModel): ICaseBattleGame[] {
    const concatGames = [...myGames, ...availableGames, ...availableGamesHistory];
    return concatGames;
  }
  @Selector()
  static activeLobbyRequestState({ activeLobbyRequestState }: CaseBattleStateModel): boolean | null {
    return activeLobbyRequestState;
  }

  @Selector()
  static availableCases({ availableCases }: CaseBattleStateModel): ICaseItemDtoV2[] {
    return availableCases;
  }
  @Selector()
  static battlePhase({ battlePhase }: CaseBattleStateModel): CaseBattlePhases {
    return battlePhase;
  }
  @Selector()
  static caseBattleHistory({ caseBattleHistory }: CaseBattleStateModel): ICaseBattleGame[] {
    return caseBattleHistory;
  }
  @Selector()
  static playerCards({ playerCards }: CaseBattleStateModel): Record<number, ICaseBattlePlayerCard> {
    return playerCards;
  }
  @Selector()
  static selectedCases({ selectedCases }: CaseBattleStateModel): ICaseItemDtoV2[] {
    return selectedCases;
  }
  @Selector()
  static activeList({ activeList }: CaseBattleStateModel): CaseBattleLobbyLists {
    return activeList;
  }
  @Selector()
  static emojis({ emojis }: CaseBattleStateModel): IEmojiInfo[] {
    return emojis;
  }
  @Selector()
  static activeSound({ activeSound }: CaseBattleStateModel): SoundNameFast | undefined {
    return activeSound;
  }

  ngxsOnInit(ctx: StateContext<CaseBattleStateModel>): void {
    const activeSound = this.#storageService.get(ActiveSoundGame.CASE_BATTLE_ACTIVE_SOUND) as SoundNameFast | undefined;
    ctx.patchState({
      activeSound,
    });
    this.#subscribeEmitters(ctx);
  }
  #subscribeEmitters({ dispatch, getState }: StateContext<CaseBattleStateModel>): void {
    // const provablyFair = this.store.selectSnapshot(ProvablyFairState.provablyFairInfo);
    // if (!provablyFair) {
    //   ctx.dispatch(new GetProvablyFairInfo())
    // }
    merge(
      this.#caseBattleBackendService.onCreateBattle(),
      this.#caseBattleBackendService.onJoinBattle(),
      this.#caseBattleBackendService.onEndBattleRound(),
      this.#caseBattleBackendService.onEmojiGet(),
      this.#caseBattleBackendService.onFinallyEmojisGet(),
      this.#caseBattleBackendService.onEndBattle(),
    )
      .pipe(takeUntil(this.destroyed$))
      .subscribe(
        (
          value: SocketResponse<
            | ICaseBattleGameCreateDto
            | ICaseBattleUserJoinDto
            | ICaseBattleRoundDto[]
            | ICaseBattleGameFinishDto
            | IBattleEmojiSocketMessage
            | IBattleEndEmojisNoty
          >,
        ) => {
          if (!this.isActive) {
            return;
          }
          const { activeLobby } = getState();
          if (value.name === 'new') {
            dispatch(new AddCaseBattle(value.payload as ICaseBattleGameCreateDto));
          }
          if (value.name === 'join') {
            dispatch(new AddPlayer(value.payload as ICaseBattleUserJoinDto));
          }
          if (value.name === 'roll') {
            dispatch(new EndBattleRound(value.payload as ICaseBattleRoundDto[]));
          }
          if (value.name === 'finish') {
            dispatch([
              new FinishActiveBattle(value.payload as ICaseBattleGameFinishDto),
              new RemoveCaseBattle(value.payload as ICaseBattleGameFinishDto),
            ]);
          }
          if (value.name === 'emoji' && activeLobby?.id === (value.payload as IBattleEmojiSocketMessage).battleId) {
            dispatch(new SetActiveEmoji(activeLobby, value.payload as IBattleEmojiSocketMessage));
          }
          if (value.name === 'finally-emojis' && activeLobby?.id === (value.payload as IBattleEndEmojisNoty).battleId) {
            dispatch(new SetFinallyEmojis(activeLobby, (value.payload as IBattleEndEmojisNoty).state));
          }
        },
      );
  }
  @Action(InitCases, { cancelUncompleted: true })
  initCases({ patchState, dispatch }: StateContext<CaseBattleStateModel>): Observable<ICaseItemDtoV2[]> {
    return this.#caseBattleBackendService.getAllCasesForRenderingInGames().pipe(
      tap((response: ICaseItemDtoV2[]) => {
        const minmax: IFilterRange = response.reduce(
          (acc, curr) => {
            return {
              minValue: Math.min(acc.minValue, curr.lastRevision.price),
              maxValue: Math.max(acc.maxValue, curr.lastRevision.price),
            };
          },
          { minValue: Number.MAX_SAFE_INTEGER, maxValue: 0 },
        );
        patchState({
          availableCases: response,
          isActive: true,
        });
        this.isActive = true;
        dispatch(new ChangeBattlesPriceRangeValues(minmax));
      }),
      catchError((error) => this.#onError(error)),
    );
  }
  @Action(LeftBattles)
  leftBattles({ patchState, getState }: StateContext<CaseBattleStateModel>): void {
    patchState({
      ...CASE_BATTLE_INITIAL_STATE,
      activeSound: getState().activeSound,
    });
    this.isActive = false;
  }
  @Action(ChangeBattlesPriceRangeValues)
  setBattlesPriceForRange({ patchState, getState }: StateContext<CaseBattleStateModel>, { range }: ChangeBattlesPriceRangeValues): void {
    const { priceRange } = getState();
    patchState({
      priceRange: this.calcRange(priceRange, range),
    });
  }
  @Action(ChangeBattlesFilter)
  changeBattlesFilter({ patchState }: StateContext<CaseBattleStateModel>, { filter }: ChangeBattlesFilter): void {
    patchState({
      filters: filter,
    });
  }
  @Action(AddBotToCaseBattle)
  addBot({ dispatch }: StateContext<CaseBattleStateModel>, { payload }: AddBotToCaseBattle): Observable<void> {
    return this.#caseBattleBackendService.addBot(payload).pipe(
      switchMap(() => {
        if (LanguageService.getBaseUrl(this.#router.url) !== `/game/case-battle/${payload.battleId}`) {
          return dispatch([new Navigate([this.#languageService.getCurrentLangUrl(`/game/case-battle/${payload.battleId}`)])]);
        }
        return of();
      }),
      catchError((error) => this.#onError(error)),
    );
  }
  @Action(AddCaseBattle)
  addBattleOnCreate(
    { getState, setState }: StateContext<CaseBattleStateModel>,
    { notify }: AddCaseBattle,
  ): Observable<ICaseBattleOneGame | []> {
    return this.#caseBattleBackendService.getCaseBattleById(notify.battleId).pipe(
      tap((response: ICaseBattleOneGame) => {
        const user = this.#store.selectSnapshot(UserState.user);
        const { filters, availableGamesHistory, availableGames } = getState();
        const { teams } = this.#teamsConstructor(
          [...response.game.players],
          [],
          response.game.players_count,
          response.game.players_in_team,
          response.game.teams,
        );
        const updatedGame: ICaseBattleGame = {
          ...response.game,
          gameStatus: CaseBattleGameStatus.JOIN,
          casesItems: notify.cases,
          leaderTeamSum: 0,
          loserTeamSum: Number.MAX_SAFE_INTEGER,
          potentialGain: 0,
          teams: teams,
        };
        if (user?.id === response.game.creator_id) {
          setState(
            patch<CaseBattleStateModel>({
              myGames: insertItem(updatedGame),
              availableGamesHistory: removeItem(availableGamesHistory.length - 1),
            }),
          );
        } else {
          // if (this.filterNewBattle(filters, updatedGame)) {
          const items = [...availableGames, updatedGame];
          switch (filters.sortBy) {
            case SortingCaseBattleTypes.FIRST_NEW: {
              items.sort((a, b) => b.id - a.id);
              break;
            }
            case SortingCaseBattleTypes.FIRST_OLD: {
              items.sort((a, b) => a.id - b.id);
              break;
            }
            case SortingCaseBattleTypes.FIRST_CHEAP: {
              items.sort((a, b) => a.total_price - b.total_price);
              break;
            }
            case SortingCaseBattleTypes.FIRST_EXPENSIVE: {
              items.sort((a, b) => b.total_price - a.total_price);
              break;
            }
          }
          // при добавлении новой игры в список, удаляется последняя из истории последних игр
          setState(
            patch<CaseBattleStateModel>({
              availableGames: items,
              availableGamesHistory: removeItem(availableGamesHistory.length - 1),
            }),
          );
        }
      }),
      catchError((error) => this.#onError(error)),
    );
  }
  @Action(AddPlayer)
  addPlayerToLobby({ setState, getState, dispatch, patchState }: StateContext<CaseBattleStateModel>, { notify }: AddPlayer): void {
    // // чет хрень, но бошка не варит уже
    const { activeLobby, availableGames, myGames } = getState();
    if (
      activeLobby &&
      activeLobby.id === notify.battleId &&
      activeLobby.players.findIndex((value) => value.user_id === notify.user_id) === -1
    ) {
      setState(
        patch<any>({
          activeLobby: patch<ICaseBattleGame>({
            gameStatus: this.#checkGameStatus(
              !!activeLobby.is_return,
              activeLobby.prize_given,
              activeLobby.players_count,
              activeLobby.players.length + 1,
              activeLobby.rounds?.length,
            ),
            players: append([notify.player]),
            ...this.#teamsConstructor(
              [...activeLobby.players, notify.player],
              activeLobby.rounds,
              activeLobby.players_count,
              activeLobby.players_in_team,
              activeLobby.teams,
            ),
          }),
        }),
      );
      dispatch(new CreatePlayerCards());
    }
    const updateGames = (games: ICaseBattleGame[]): ICaseBattleGame[] => {
      return games.map((game: ICaseBattleGame) => {
        if (game.id === notify.battleId) {
          return {
            ...game,
            gameStatus: game.players.length + 1 === game.players_count ? CaseBattleGameStatus.AWAIT : CaseBattleGameStatus.JOIN,
            players: [...game.players, notify.player],
            ...this.#teamsConstructor([...game.players, notify.player], [], game.players_count, game.players_in_team, game.teams),
          };
        }
        return game;
      });
    };
    if (availableGames.find((game) => game.id === notify.battleId)) {
      patchState({
        availableGames: updateGames(availableGames),
      });
    }
    if (myGames.find((game) => game.id === notify.battleId)) {
      patchState({
        myGames: updateGames(myGames),
      });
    }
  }
  @Action(AddSelectedItems)
  addSelectedItems({ patchState, getState }: StateContext<CaseBattleStateModel>, { caseItems }: AddSelectedItems): void {
    const { selectedCases } = getState();
    const updatedCases = [...selectedCases];
    caseItems.forEach((caseItem) => {
      updatedCases.push({ ...caseItem, tempId: Date.now() + (Math.random() * (10000 - 1) + 1) });
    });
    patchState({
      selectedCases: updatedCases,
    });
  }
  @Action(ClearActiveLobby)
  clearActiveLobby({ patchState }: StateContext<CaseBattleStateModel>): void {
    patchState({
      activeLobby: null,
      playerCards: {},
      battlePhase: CaseBattlePhases.INITIALIZE,
    });
  }
  @Action(CreateCaseBattle)
  createCaseBattle(
    { patchState, dispatch }: StateContext<CaseBattleStateModel>,
    { newBattleInfo }: CreateCaseBattle,
  ): Observable<ICaseBattleGameCreateNotify | []> {
    return this.#caseBattleBackendService.createNewCaseBattle(newBattleInfo).pipe(
      tap((response: ICaseBattleGameCreateNotify) => {
        if (response.success) {
          patchState({
            activeLobby: null,
            playerCards: {},
            selectedCases: [],
            battlePhase: CaseBattlePhases.INITIALIZE,
          });
          // if (createType === 'recreate') {
          dispatch([new Navigate([`/game/case-battle/${response.battleId}`])]);
          // }
        } else if (response.message) {
          this.#notificationsService.addErrorNotification(response.message, { category: 'Battle' });
        }
      }),
      catchError((error) => this.#onError(error)),
    );
  }
  //// FIXME почекать
  @Action(CreatePlayerCards)
  createPlayerCards({ getState, patchState }: StateContext<CaseBattleStateModel>): void {
    const { activeLobby, playerCards } = getState();
    if (activeLobby) {
      let games = { ...playerCards };
      let maxPrizeSum = 0;
      activeLobby.players.forEach((player) => {
        const playerRounds: ICaseBattleRoundDto[] = activeLobby.rounds?.filter((round) => round.user_id === player.user_id) || [];
        const lastRound = activeLobby.rounds?.length ? activeLobby.rounds[activeLobby.rounds.length - 1].roundNumber - 1 : 0;
        const game = {
          player: player,
          firstInit: true,
          itemsLine: this.#randomTrackConstructor(
            activeLobby.casesItems[lastRound],
            playerRounds.length ? playerRounds[lastRound].result : undefined,
          ),
          rounds: playerRounds,
          rounds_amount: activeLobby.cases_count,
          prize_sum: this.#calcPlayerPrizeSum(playerRounds),
        };
        if (maxPrizeSum < game.prize_sum) {
          maxPrizeSum = game.prize_sum;
        }
        if (!playerCards[player.user_id]) {
          games = { ...games, [player.user_id]: game };
        }
      });
      patchState({
        playerCards: games,
        activeLobby: { ...activeLobby, maxPrizeSum: maxPrizeSum },
      });
    }
  }
  @Action(EndBattleRound)
  endBattleRound({ getState, dispatch }: StateContext<CaseBattleStateModel>, { notify }: EndBattleRound): void {
    const { activeLobby } = getState();
    if (activeLobby?.id === notify[0].battleId) {
      dispatch([new EndRoundInLobby(notify), new EndRoundInList(notify)]);
    } else {
      dispatch(new EndRoundInList(notify));
    }
  }
  @Action(EndRoundInLobby)
  endRoundInLobby({ setState, dispatch, getState }: StateContext<CaseBattleStateModel>, { notify }: EndRoundInLobby): void {
    const { activeLobby } = getState();
    if (activeLobby) {
      setState(
        patch<any>({
          activeLobby: patch<ICaseBattleGame>({
            gameStatus: CaseBattleGameStatus.DRAW,
            rounds: append(notify),
            potentialGain: this.#calcPotentialGain([...(activeLobby.rounds || []), ...notify]),
            lastRoundNumber: notify[0].roundNumber,
            ...this.#teamsConstructor(
              activeLobby.players,
              [...(activeLobby.rounds || []), ...notify],
              activeLobby.players_count,
              activeLobby.players_in_team,
            ),
          }),
          currentRounds: append(notify),
        }),
      );
    }
    dispatch(new SetPrizeItemAndRange());
  }
  @Action(EndRoundInList)
  endRoundInList({ setState, getState }: StateContext<CaseBattleStateModel>, { notify }: EndRoundInList): void {
    const battleId = notify[0].battleId;
    const { myGames, availableGames } = getState();
    const updatedGameRounds = this.#calcPotentialGain([
      ...([...myGames, ...availableGames].find((game) => game.id === battleId)?.rounds || []),
      ...notify,
    ]);
    setState(
      patch<CaseBattleStateModel>({
        myGames: updateItem<ICaseBattleGame>(
          (o) => o?.id === battleId,
          patch({
            lastRoundNumber: notify[0].roundNumber,
            potentialGain: updatedGameRounds,
            gameStatus: CaseBattleGameStatus.DRAW,
            rounds: append(notify),
          }),
        ),
        availableGames: updateItem<ICaseBattleGame>(
          (o) => o?.id === battleId,
          patch({
            lastRoundNumber: notify[0].roundNumber,
            potentialGain: updatedGameRounds,
            gameStatus: CaseBattleGameStatus.DRAW,
            rounds: append(notify),
          }),
        ),
      }),
    );
  }
  @Action(FinishActiveBattle)
  finishActiveBattle({ setState, getState, dispatch }: StateContext<CaseBattleStateModel>, { notify }: FinishActiveBattle): void {
    const { activeLobby } = getState();
    const userId = this.#store.selectSnapshot(UserState.user)?.id;
    if (userId && notify.winnerIds?.includes(userId)) {
      setTimeout(() => {
        dispatch(new GetInventoryInfo());
      }, 1000);
    }
    if (activeLobby && notify.battleId === activeLobby.id) {
      setState(
        patch<any>({
          activeLobby: patch<ICaseBattleGame>({
            gameStatus: this.#checkGameStatus(!!notify.is_return, !notify.is_return),
            is_return: notify.is_return,
            potentialGain: notify.totalWinning,
            prizes: notify.prizes,
            prize_given: true,
            round_winner_ids: [],
            total_winning: notify.totalWinning,
            total_loss: this.#calcLosePlayersSum(activeLobby?.rounds, notify.winnerIds),
            winner_ids: notify.winnerIds,
          }),
          myGames: updateItem<ICaseBattleGame>(
            (o) => o?.id === notify.battleId,
            patch({
              gameStatus: this.#checkGameStatus(!!notify.is_return, !notify.is_return),
              total_winning: notify.totalWinning,
              potentialGain: notify.totalWinning,
              prizes: notify.prizes,
              prize_given: true,
              winner_ids: notify.winnerIds,
            }),
          ),
          availableGames: updateItem<ICaseBattleGame>(
            (o) => o?.id === notify.battleId,
            patch({
              gameStatus: this.#checkGameStatus(!!notify.is_return, !notify.is_return),
              total_winning: notify.totalWinning,
              potentialGain: notify.totalWinning,
              prizes: notify.prizes,
              prize_given: true,
              winner_ids: notify.winnerIds,
            }),
          ),
          battlePhase: CaseBattlePhases.VICTORY,
          replayBuffer: null,
        }),
      );
      // this.openWinnersModal({ ...activeLobby, prizes: notify.prizes }, userId);
    } else {
      setState(
        patch<CaseBattleStateModel>({
          myGames: updateItem<ICaseBattleGame>(
            (o) => o?.id === notify.battleId,
            patch({
              gameStatus: this.#checkGameStatus(!!notify.is_return, !notify.is_return),
              total_winning: notify.totalWinning,
              potentialGain: notify.totalWinning,
              prizes: notify.prizes,
              prize_given: true,
              winner_ids: notify.winnerIds,
            }),
          ),
          availableGames: updateItem<ICaseBattleGame>(
            (o) => o?.id === notify.battleId,
            patch({
              gameStatus: this.#checkGameStatus(!!notify.is_return, !notify.is_return),
              total_winning: notify.totalWinning,
              potentialGain: notify.totalWinning,
              prizes: notify.prizes,
              prize_given: true,
              winner_ids: notify.winnerIds,
            }),
          ),
        }),
      );
    }
  }
  @Action(GetAllBattles, { cancelUncompleted: true })
  getAllBattles({ patchState, dispatch }: StateContext<CaseBattleStateModel>, { userId }: GetAllBattles): Observable<void> {
    patchState({
      caseBattleHistory: [],
      myGames: [],
      availableGames: [],
      availableGamesHistory: [],
    });
    return dispatch([new GetUserBattleList(userId), new GetOtherUsersBattleList(null, userId, 0), new GetBattleLastHistory()]);
  }
  @Action(GetBattleUserHistory)
  getBattleUserHistory(
    { patchState, getState }: StateContext<CaseBattleStateModel>,
    { offset }: GetBattleUserHistory,
  ): Observable<ICaseBattleHistory | []> {
    const { caseBattleHistory, availableCases, historyListOffset } = getState();
    return this.#caseBattleBackendService.getBattleUserHistory(offset === 0 ? offset : historyListOffset, 20, true).pipe(
      tap((response: ICaseBattleHistory) => {
        const games = response.games.map((game) => {
          return {
            ...game,
            casesItems: this.#caseIdsToCaseItemsTransform(game.cases, availableCases),
            gameStatus: CaseBattleGameStatus.END,
            ...this.#teamsConstructor(game.players, game.rounds, game.players_count, game.players_in_team),
          };
        });
        if (offset === 0) {
          patchState({
            caseBattleHistory: games,
            historyListOffset: 20,
          });
        } else {
          const updatedGames = [...(caseBattleHistory || []), ...games];
          patchState({
            caseBattleHistory: updatedGames,
            historyListOffset: historyListOffset + response.games.length,
          });
        }
      }),
      catchError((error) => this.#onError(error)),
    );
  }
  @Action(GetBattleLastHistory)
  getBattleLastHistory({ patchState, getState }: StateContext<CaseBattleStateModel>): Observable<ICaseBattleHistory | []> {
    const { availableCases } = getState();
    return this.#caseBattleBackendService.getBattleListHistory().pipe(
      tap((response: ICaseBattleHistory) => {
        const games = response.games.map((game) => {
          return {
            ...game,
            casesItems: this.#caseIdsToCaseItemsTransform(game.cases, availableCases),
            gameStatus: CaseBattleGameStatus.END,
            ...this.#teamsConstructor(game.players, game.rounds, game.players_count, game.players_in_team),
          };
        });
        patchState({
          availableGamesHistory: games.filter((game) => game.prize_given),
        });
      }),
      catchError((error) => this.#onError(error)),
    );
  }
  @Action(GetCasesByIdsForLobby, { cancelUncompleted: true })
  getCasesByIdsForLobby(
    { patchState, dispatch, getState }: StateContext<CaseBattleStateModel>,
    { game }: GetCasesByIdsForLobby,
  ): Observable<void> {
    return this.#caseBattleBackendService.getCasesByIds(game.cases).pipe(
      tap((response: ICaseItemDtoV2[]) => {
        // для того что бы игроков не потерять и раунды
        const { availableGames, myGames } = getState();
        const foundLobby = [...availableGames, ...myGames].find((el) => el.id === game.id);
        const responseGame = game;
        const resposeRounds = responseGame.rounds ? responseGame.rounds : [];
        const foundLobbyRounds = foundLobby && foundLobby.rounds ? foundLobby.rounds : [];
        const responsePlayers = responseGame.players;
        const foundLobbyPlayers = foundLobby && foundLobby.players ? foundLobby.players : [];
        const rounds = getUniqueObjectsFromArr([...foundLobbyRounds, ...resposeRounds], 'roundNumber', 'user_id').sort(
          (a, b) => a.roundNumber - b.roundNumber,
        );
        const players = getUniqueObjectsFromArr([...foundLobbyPlayers, ...responsePlayers], 'user_id').map((player, index) =>
          player.team ? player : { ...player, team: index + 1 },
        );
        const currentWinners: { winnerId: number | null; price: number }[] = [];
        if (rounds && rounds.length) {
          rounds.forEach((round) => {
            if (rounds && rounds[rounds.length - 1].roundNumber === round.roundNumber) {
              const winnerData = { winnerId: round.user_id, price: round.result.price };
              currentWinners.push(winnerData);
            }
          });
        }
        const lastRoundNumber = rounds.length ? Math.max(...rounds.map((value) => value.roundNumber)) : 0;
        const updatedGame: ICaseBattleGame = {
          ...game,
          casesItems: this.#caseIdsToCaseItemsTransform(game.cases, response),
          gameStatus: this.#checkGameStatus(
            !!game.is_return,
            game.prize_given,
            game.players_count,
            game.players.length,
            game.rounds?.length,
          ),
          lastRoundNumber,
          players,
          players_in_team: game.players_in_team ? game.players_in_team : 1,
          potentialGain: this.#calcPotentialGain(game.rounds),
          rounds: rounds,
          roundsWinPrice: this.#getRoundsWinPrice(rounds, !!game.settings?.jokerMode),
          round_winner_ids: minMaxValueOfItemField(currentWinners, 'price', !!game.settings?.jokerMode, 'winnerId'),
          total_loss: this.#calcLosePlayersSum(rounds, game.winner_ids),
          timeLineEmojis: this.#emojisByTimeline(game.emoji, true),
          ...this.#getRoundPrices(rounds),
          ...this.#teamsConstructor(players, rounds, game.players_count, game.players_in_team),
        };
        patchState({
          activeLobby: updatedGame,
          playerCards: {},
        });
      }),
      switchMap(() => dispatch(new CreatePlayerCards())),
      catchError((error) => this.#onError(error)),
    );
  }
  @Action(GetOtherUsersBattleList)
  GetOtherUsersBattleList(
    { patchState, getState, dispatch }: StateContext<CaseBattleStateModel>,
    { filter, offset, userId }: GetOtherUsersBattleList,
  ): Observable<ICaseBattleDto | []> {
    const { availableGames, availableCases, gameListOffset, filters } = getState();
    return this.#caseBattleBackendService.getBattleGames(false, { filters: filters }).pipe(
      tap((response: ICaseBattleDto) => {
        let games = response.games.map((game) => this.#updateListGame(game, availableCases));
        if (userId) {
          games = games.filter((game) => game.creator_id !== userId);
        }
        if (offset === 0) {
          patchState({
            availableGames: games,
            gameListOffset: 20,
          });
        } else {
          const updatedItems = [...availableGames, ...games];
          patchState({
            availableGames: updatedItems,
            gameListOffset: gameListOffset + response.games.length,
          });
        }
        if (filter) {
          dispatch(new ChangeBattlesFilter(filter));
        }
      }),
      catchError((error) => this.#onError(error)),
    );
  }
  @Action(GetUserBattleList)
  GetUserBattleList(
    { patchState, getState }: StateContext<CaseBattleStateModel>,
    { userId }: GetUserBattleList,
  ): Observable<ICaseBattleDto | []> | void {
    if (userId) {
      return this.#caseBattleBackendService.getBattleGames(true).pipe(
        tap((response: ICaseBattleDto) => {
          const { availableCases } = getState();
          patchState({
            myGames: response.games.map((game) => this.#updateListGame(game, availableCases)),
          });
        }),
        catchError((error) => this.#onError(error)),
      );
    } else {
      patchState({
        myGames: [],
      });
    }
  }
  @Action(GetLobbyById, { cancelUncompleted: true })
  getActiveLobby({ dispatch, patchState }: StateContext<CaseBattleStateModel>, { battleId }: GetLobbyById): Observable<void> {
    return this.#caseBattleBackendService.getCaseBattleById(battleId).pipe(
      switchMap((response) => {
        patchState({
          activeLobbyRequestState: true,
        });
        return dispatch(new GetCasesByIdsForLobby(response.game));
      }),
      catchError((error) => {
        patchState({
          activeLobbyRequestState: false,
        });
        return this.#onError(error);
      }),
    );
  }
  @Action(JoinCaseBattle)
  joinBattle({ dispatch }: StateContext<CaseBattleStateModel>, { payload }: JoinCaseBattle): Observable<ICaseBattleGameJoinNotify | []> {
    return this.#caseBattleBackendService.joinCaseBattle(payload).pipe(
      tap((response) => {
        if (response.success) {
          if (payload.navigate) {
            dispatch([new Navigate([`/game/case-battle/${payload.battleId}`])]);
          }
        } else if (response.message) {
          this.#notificationsService.addErrorNotification(response.message, { category: 'Battle' });
        }
      }),
      catchError((error) => this.#onError(error)),
    );
  }
  @Action(RemoveCaseBattle)
  removeCaseBattle({ setState, getState }: StateContext<CaseBattleStateModel>, { notify }: RemoveCaseBattle): void {
    const { availableGames, myGames } = getState();
    const removedGame = [...availableGames, ...myGames].find((game) => game.id === notify.battleId);
    setState(
      patch<CaseBattleStateModel>({
        availableGames: removeItem((item) => item?.id === notify.battleId),
        myGames: removeItem((item) => item?.id === notify.battleId),
      }),
    );
    // для перемещения завершенной игры в общую историю во время удаления ее из списка активных
    if (removedGame) {
      setState(
        patch<CaseBattleStateModel>({
          availableGamesHistory: insertItem(removedGame),
        }),
      );
    }
  }
  @Action(RemoveSelectedItems)
  removeSelectedItems({ patchState, getState }: StateContext<CaseBattleStateModel>, { caseItems }: RemoveSelectedItems): void {
    if (caseItems) {
      const { selectedCases } = getState();
      let updatedCases = [...selectedCases];
      caseItems.forEach((caseItem) => {
        if (caseItem.tempId) {
          updatedCases = updatedCases.filter((it) => it.tempId !== caseItem.tempId);
        } else {
          let index = 0;
          let date = 0;
          updatedCases.forEach((item, i) => {
            if (caseItem.id === item.id && item.tempId) {
              if (date < item.tempId) {
                date = item.tempId;
                index = i;
              }
            }
          });
          updatedCases.splice(index, 1);
        }
      });
      patchState({
        selectedCases: updatedCases,
      });
    } else {
      patchState({
        selectedCases: [],
      });
    }
  }
  @Action(ReplayGame)
  replayGame({ dispatch }: StateContext<CaseBattleStateModel>, { battleId }: ReplayGame): void {
    dispatch([new ReplayPreparation(), new ReplaySetRounds(battleId)]);
  }
  @Action(ReplayPreparation)
  replayPreparation({ getState, patchState }: StateContext<CaseBattleStateModel>): void {
    const { activeLobby, playerCards } = getState();
    if (activeLobby) {
      let clearCards: Record<number, ICaseBattlePlayerCard> = {};
      Object.values(playerCards).forEach((card) => {
        const clearCard: ICaseBattlePlayerCard = {
          player: card.player,
          firstInit: true,
          itemsLine: [],
          rounds: [],
          rounds_amount: activeLobby.cases_count,
          active_round: undefined,
          prize_skins: undefined,
          prize_sum: 0,
        };
        clearCards = { ...clearCards, [card.player.user_id]: clearCard };
      });
      patchState({
        activeLobby: {
          ...activeLobby,
          gameStatus: CaseBattleGameStatus.AWAIT,
          lastRoundNumber: 0,
          rounds: [],
          winner_ids: null,
          potentialGain: 0,
          prizes: null,
          prize_given: false,
          round_winner_ids: [],
          maxPrizeSum: undefined,
          isReplay: true,
          ...this.#teamsConstructor(
            activeLobby.players,
            activeLobby.rounds,
            activeLobby.players_count,
            activeLobby.players_in_team,
            activeLobby.teams,
            true,
          ),
        },
        battlePhase: CaseBattlePhases.INITIALIZE,
        playerCards: clearCards,
        replayBuffer: {
          rounds: activeLobby.rounds,
          winner_ids: activeLobby.winner_ids,
          is_return: activeLobby.is_return,
          prizes: activeLobby.prizes,
          prize_given: true,
          totalWinning: activeLobby.total_winning,
        },
      });
    }
  }
  @Action(ReplaySetRounds)
  replaySetRounds({ getState, dispatch }: StateContext<CaseBattleStateModel>, { battleId }: ReplaySetRounds): void {
    const { replayBuffer } = getState();
    let lastRound = 1;
    let currentRound: ICaseBattleRoundDto[] = [];
    replayBuffer?.rounds?.forEach((round, index) => {
      setTimeout(() => {
        const { activeLobby } = getState();
        if (activeLobby && activeLobby.id === battleId) {
          if (lastRound === round.roundNumber) {
            currentRound.push(round);
          } else {
            currentRound = [];
            currentRound.push(round);
          }
          if (currentRound.length % activeLobby.players_count === 0) {
            dispatch(new EndRoundInLobby(currentRound));
            currentRound = [];
          }
          if (replayBuffer?.rounds && index === replayBuffer.rounds.length - 1) {
            setTimeout(() => {
              dispatch(
                new FinishActiveBattle({
                  battleId: activeLobby.id,
                  prizes: replayBuffer.prizes,
                  winnerIds: replayBuffer.winner_ids,
                  totalWinning: replayBuffer.totalWinning,
                }),
              );
            }, 4000);
          }
          lastRound = round.roundNumber;
        }
      }, round.roundNumber * 4000);
    });
  }
  @Action(SendEmoji)
  sendEmoji(context: StateContext<CaseBattleStateModel>, { payload }: SendEmoji): Observable<IBattleEmojiNoty | []> {
    return this.#caseBattleBackendService.setEmoji(payload).pipe(catchError((error) => this.#onError(error)));
  }
  //** добавляет эмоции в активное лобби */
  @Action(SetActiveEmoji)
  setActiveEmoji({ patchState }: StateContext<CaseBattleStateModel>, { activeLobby, payload }: SetActiveEmoji): void {
    if (activeLobby && !activeLobby.prize_given) {
      const lobbyEmojis = activeLobby.emoji || {};
      const emojis: Record<number, IEmojiTimeStampDTO> = {
        ...activeLobby.emoji,
        0: { id: payload.emojiId, count: (lobbyEmojis[payload.emojiId]?.count || 0) + 1 },
      };
      patchState({
        activeLobby: { ...activeLobby, timeLineEmojis: this.#emojisByTimeline(emojis, false) },
      });
    }
  }
  @Action(SetActiveSound)
  setActiveSound({ patchState }: StateContext<CaseBattleStateModel>, { sound }: SetActiveSound): void {
    patchState({
      activeSound: sound,
    });
    this.#storageService.set(ActiveSoundGame.CASE_BATTLE_ACTIVE_SOUND, sound);
  }
  @Action(SetCaseBattleFilters)
  setCaseBattleFilters({ patchState, dispatch }: StateContext<CaseBattleStateModel>, { filters }: SetCaseBattleFilters): void {
    patchState({
      filters: filters,
    });
    dispatch(new GetOtherUsersBattleList(filters, 0));
  }
  //** обновляет эмоции активного лобби (нужно для реплеев) */
  @Action(SetFinallyEmojis)
  setFinalyEmojis({ patchState }: StateContext<CaseBattleStateModel>, { activeLobby, payload }: SetFinallyEmojis): void {
    if (activeLobby && payload?.emojis) {
      patchState({
        activeLobby: { ...activeLobby, timeLineEmojis: this.#emojisByTimeline(payload.emojis, true) },
      });
    }
  }
  @Action(SetBattlePhase, { cancelUncompleted: true })
  setBattlePhase({ patchState }: StateContext<CaseBattleStateModel>, { phase }: SetBattlePhase): void {
    patchState({
      battlePhase: phase,
    });
  }
  @Action(SetPrizeItemAndRange)
  setPrizeItemAndRange({ patchState, getState }: StateContext<CaseBattleStateModel>): void {
    const { playerCards, activeLobby, currentRounds } = getState();
    const currentWinners: { winnerId: number | null; price: number }[] = [];
    if (activeLobby?.rounds && activeLobby.rounds.length % activeLobby.players_count === 0) {
      let bufferedPlayerCards: Record<number, ICaseBattlePlayerCard> = {};
      let maxPrizeSum = 0;
      const lastRoundIndex: number = Math.ceil(
        activeLobby.rounds.length / activeLobby.players_count - 1 > 0 ? activeLobby.rounds.length / activeLobby.players_count - 1 : 0,
      );
      activeLobby.players.forEach((player) => {
        if (playerCards[player.user_id]) {
          const currentRound = currentRounds.find((round) => round.user_id === player.user_id);
          if (currentRound) {
            const currLine = playerCards[player.user_id].itemsLine;
            let line: ICaseBattleRoundResult[];
            const uplatedRounds = [...playerCards[player.user_id].rounds, currentRound];
            if (playerCards[player.user_id].firstInit) {
              line = this.#spinItemTrackConstructor(activeLobby.casesItems[lastRoundIndex], currentRound.strip);
            } else {
              const newLine = this.#spinItemTrackConstructor(activeLobby.casesItems[lastRoundIndex], currentRound.strip);
              line = this.#rangeSpinItems(currLine, newLine);
            }
            const prizeSum = this.#calcPlayerPrizeSum(uplatedRounds);
            const updatedPlayerCard: ICaseBattlePlayerCard = {
              ...playerCards[player.user_id],
              firstInit: false,
              itemsLine: line,
              rounds: uplatedRounds,
              active_round: uplatedRounds[uplatedRounds.length - 1].roundNumber,
              prize_sum: prizeSum,
            };
            if (maxPrizeSum < prizeSum) {
              maxPrizeSum = prizeSum;
            }
            const winnerData = { winnerId: currentRound.user_id, price: currentRound.result.price };
            currentWinners.push(winnerData);
            bufferedPlayerCards = { ...bufferedPlayerCards, [player.user_id]: updatedPlayerCard };
          }
        }
      });
      const roundWinners: number[] = minMaxValueOfItemField(currentWinners, 'price', !!activeLobby.settings?.jokerMode, 'winnerId');
      patchState({
        activeLobby: {
          ...activeLobby,
          round_winner_ids: roundWinners,
          maxPrizeSum: maxPrizeSum,
          roundsWinPrice: this.#getRoundsWinPrice(
            activeLobby.rounds?.length ? activeLobby.rounds : [],
            !!activeLobby.settings?.jokerMode,
            activeLobby.roundsWinPrice,
          ),
          ...this.#getRoundPrices(activeLobby.rounds?.length ? activeLobby.rounds : []),
        },
        playerCards: bufferedPlayerCards,
        currentRounds: [],
        battlePhase: CaseBattlePhases.RAFFLE,
      });
    }
  }
  @Action(ToggleBattleLists)
  toggleBattleLists({ patchState, getState }: StateContext<CaseBattleStateModel>, { activeList }: ToggleBattleLists): void {
    patchState({
      activeList,
    });
  }
  @Action(WatchCaseBattle)
  watchBattle({ dispatch }: StateContext<CaseBattleStateModel>, { gameId }: WatchCaseBattle): void {
    dispatch([new Navigate([`/game/case-battle/${gameId}`])]);
  }

  #adapter(item: ICaseRevisionItem): ICaseBattleRoundResult {
    return {
      id: item.inventoryItem.id,
      price: item.inventoryItem.price,
      baseItem: item.inventoryItem.baseItem,
    };
  }
  #caseIdsToCaseItemsTransform(ids: number[], availableCases: ICaseItemDtoV2[]): ICaseItemDtoV2[] {
    const cases: ICaseItemDtoV2[] = [];
    ids.forEach((id) =>
      availableCases.forEach((caseItem) => {
        if (caseItem.id === id) {
          cases.push(caseItem);
        }
      }),
    );
    return cases;
  }
  //** сумма предметов игрока */
  #calcPlayerPrizeSum(rounds: ICaseBattleRoundDto[]): number {
    return rounds.reduce((acc, cur) => {
      return acc + (cur.result.price || 0);
    }, 0);
  }
  #calcLosePlayersSum(rounds: ICaseBattleRoundDto[] | undefined, winner_ids: number[] | null): number {
    let loseSum = 0;
    if (rounds?.length) {
      loseSum = rounds.reduce((acc, cur) => {
        if (!winner_ids?.includes(cur.user_id)) {
          return acc + cur.result.price;
        }
        return acc + 0;
      }, 0);
    }
    return loseSum;
  }
  #calcMaxTeamPrice(teams: Record<number, ICaseBattleTeam>): number {
    let maxPrice = 0;
    Object.values(teams).forEach((team) => {
      if (team.teamWinPrice > maxPrice) {
        maxPrice = team.teamWinPrice;
      }
    });
    return maxPrice;
  }
  #calcTeamPrice(team: (ICaseBattlePlayer | null)[], rounds: ICaseBattleRoundDto[]): number {
    let sum = 0;
    if (team) {
      const teamIds = team.map((player) => player?.user_id);
      sum = rounds.filter((round) => teamIds.includes(round.user_id)).reduce((acc, curr) => acc + curr.result.price || 0, 0);
    }
    return sum;
  }
  #calcPotentialGain(rounds: ICaseBattleRoundDto[] = []): number {
    return (
      rounds?.reduce((acc, curr) => {
        return acc + curr.result.price;
      }, 0) || 0
    );
  }
  //** проверка статуса батла */
  #checkGameStatus(
    gameBreak: boolean,
    prizeGiven: boolean,
    playersCount?: number,
    playersJoin?: number,
    currRounds?: number,
  ): CaseBattleGameStatus {
    if (gameBreak) {
      return CaseBattleGameStatus.ERROR;
    } else if (prizeGiven) {
      return CaseBattleGameStatus.END;
    } else if (!!playersJoin && !!playersCount && playersJoin < playersCount) {
      return CaseBattleGameStatus.JOIN;
    } else if (!currRounds) {
      return CaseBattleGameStatus.AWAIT;
    }
    return CaseBattleGameStatus.DRAW;
  }
  //** функция расстановки id эмоций по таймлайну */
  #emojisByTimeline(emojis: Record<number, IEmojiTimeStampDTO> = {}, needAll: boolean): Record<number, number> {
    const timelineEmojis: Record<number, number> = {};
    Object.values(emojis).forEach((emoji) =>
      (emoji.ts || [0]).forEach((time) => {
        if (!needAll) {
          timelineEmojis['0'] = emoji.id;
        } else {
          if (time !== 0) {
            timelineEmojis[time] = emoji.id;
          }
        }
      }),
    );
    return timelineEmojis;
  }
  #getRoundsWinPrice(
    rounds: ICaseBattleRoundDto[] = [],
    jokerMode: boolean,
    previousRounds: Record<number, number> = {},
  ): Record<number, number> {
    let result = { ...previousRounds };
    rounds?.forEach((round) => {
      const currRound = result[round.roundNumber];
      if (jokerMode) {
        if ((currRound && currRound > round.result.price) || !currRound) {
          result = { ...result, [round.roundNumber]: round.result.price };
        }
      } else {
        if ((currRound && currRound < round.result.price) || !currRound) {
          result = { ...result, [round.roundNumber]: round.result.price };
        }
      }
    });
    return result;
  }
  #getRoundPrices(rounds: ICaseBattleRoundDto[] = []): { roundsMinPrice: number; roundsMaxPrice: number } {
    let roundsMinPrice = Number.MAX_SAFE_INTEGER;
    let roundsMaxPrice = 0;
    rounds.forEach((round) => {
      if (round.result.price > roundsMaxPrice) {
        roundsMaxPrice = round.result.price;
      }
      if (round.result.price < roundsMinPrice) {
        roundsMinPrice = round.result.price;
      }
    });
    return { roundsMinPrice, roundsMaxPrice };
  }
  #rangeSpinItems(prevItems: ICaseBattleRoundResult[], currItems: ICaseBattleRoundResult[]): ICaseBattleRoundResult[] {
    return [...prevItems.slice(prevItems.length - 5), ...currItems.slice(currItems.length - 7, currItems.length)];
  }
  #randomTrackConstructor(caseItem: ICaseItemDtoV2, prizeItem?: ICaseBattleRoundResult): ICaseBattleRoundResult[] {
    let fillingItems: ICaseBattleRoundResult[] = [];
    for (let i = 0; i < 12; i++) {
      const randomItem = caseItem.lastRevision.items[Math.floor(Math.random() * (caseItem.lastRevision.items.length - 0) + 0)];
      if (randomItem) {
        fillingItems.push(this.#adapter(randomItem));
      }
    }
    if (prizeItem) {
      fillingItems = [...fillingItems.slice(0, 2), prizeItem, ...fillingItems.slice(3, fillingItems.length)];
    }
    return fillingItems;
  }
  #spinItemTrackConstructor(caseItem: ICaseItemDtoV2, strip: number[]): ICaseBattleRoundResult[] {
    const battleItems = caseItem.lastRevision.items.map((item) => this.#adapter(item));
    return strip.map((id) => battleItems.find((item) => item.id === id) || battleItems[0]);
  }
  #teamsConstructor(
    players: ICaseBattlePlayer[],
    rounds: ICaseBattleRoundDto[] = [],
    playersAmount: number,
    playersInTeam = 1,
    teams: Record<number, ICaseBattleTeam> = {},
    resetTeams = false,
  ): { leaderTeamSum: number; loserTeamSum: number; teams: Record<number, ICaseBattleTeam> } {
    const teamsAmount = playersAmount / playersInTeam; // ------------------------------------------------------- количество команд в лобби
    let updatedTeams = { ...teams }; // ----------------------------------------------------------- если есть команды, взять их как инишиал
    let leaderTeamSum = 0;
    let loserTeamSum = Number.MAX_SAFE_INTEGER;
    for (let teamNumber = 1; teamNumber <= teamsAmount; teamNumber++) {
      const currTeamPlayers = players.filter((player) => player.team === teamNumber); // --------------------------- игроки текущей команды
      const currTeamPrice = resetTeams ? 0 : this.#calcTeamPrice(currTeamPlayers, rounds); // ------------ общая стоимость предметов команды
      leaderTeamSum = currTeamPrice > leaderTeamSum ? currTeamPrice : leaderTeamSum;
      loserTeamSum = currTeamPrice < loserTeamSum ? currTeamPrice : loserTeamSum;
      let currTeam: ICaseBattleTeam = { players: currTeamPlayers, teamWinPrice: currTeamPrice }; // --------------------- собранная команда
      if (currTeam.players.length < playersInTeam) {
        // ------------------------------------------------------------------------------ если команда не полная, заполнить null
        const length = playersInTeam - currTeam.players.length;
        const filledArray = Array(length).fill(null);
        currTeam = { players: [...currTeam.players, ...filledArray], teamWinPrice: currTeamPrice };
      }
      updatedTeams = { ...updatedTeams, [teamNumber]: { players: currTeam.players, teamWinPrice: currTeamPrice } }; // --- обновить команды
      currTeam = { players: [], teamWinPrice: 0 }; // ------------------------------------------------------------ обнулить текущую команду
    }
    return { leaderTeamSum, loserTeamSum, teams: updatedTeams };
  }
  #onError(error: HttpErrorResponse): Observable<never> {
    this.#notificationsService.addNotification({
      id: Date.now(),
      type: error.error && error.error.type ? error.error.type : NotificationType.Error,
      icon: 'warning',
      message: error.error && error.error.message ? error.error.message : typeof error.error === 'string' ? error.error : 'Error',
      createDate: Date.now(),
      system: true,
      status: NotificationStatus.new,
    });
    return EMPTY;
  }
  #updateListGame(game: ICaseBattleGame, availableCases: ICaseItemDtoV2[]): ICaseBattleGame {
    const lastRoundNumber = game.rounds ? game.rounds[game.rounds.length - 1].roundNumber : 0;
    return {
      ...game,
      casesItems: this.#caseIdsToCaseItemsTransform(game.cases, availableCases),
      gameStatus: this.#checkGameStatus(!!game.is_return, game.prize_given, game.players_count, game.players.length, lastRoundNumber),
      ...this.#teamsConstructor(game.players, game.rounds, game.players_count, game.players_in_team),
    };
  }
  #openWinnersModal(activeLobby: ICaseBattleGame, userId: number | undefined): void {
    const players: ICaseBattleWinnersModalData[] = [];
    activeLobby?.players.forEach((player) => {
      const playerData: ICaseBattleWinnersModalData = {
        player: player,
        prizeSum:
          activeLobby.rounds?.reduce((acc, cur) => {
            if (cur.user_id === player.user_id) {
              return acc + cur.result.price;
            }
            return acc;
          }, 0) || 0,
        skins: activeLobby.prizes?.skins[player.user_id],
        money: activeLobby.prizes?.money[player.user_id],
      };
      players.push(playerData);
    });
    this.#caseBattleModalService.openModal(ModalNames.CASE_BATTLE_WINNERS, {
      battleId: activeLobby.id,
      playersData: { userId: userId, players: players },
    });
  }
}
