<div class="panel-mobile flex fx-column">
  <div class="panel-mobile__title tw-flex tw-items-center tw-m-auto tw-space-x-3">
    <div class="title-text tw-font-normal">{{ 'TRADE_PANEL.BET_SUM' | translate }}</div>
    <app-currency [value]="participatedItemsSum || 0" />
    <app-currency
      *ngIf="selectedItemsSum"
      class="pl-sm-1 add-to-highlight"
      [value]="selectedItemsSum"
      [hidePrefix]="true"
    />
  </div>
  <ng-scrollbar track="horizontal">
    <div class="panel-mobile__selected-items flex fx-align-center gap-2">
      <app-ui-item-simple-card
        *ngFor="let selectedITem of inventoryItems"
        class="item-card tw-cursor-pointer"
        [timestamp]="timestamp"
        [icon]="selectedITem.icon"
        [name]="selectedITem.name"
        [price]="selectedITem.price"
        [color]="selectedITem.color"
        [rarity]="selectedITem.rarity"
        [status]="selectedITem.participateStatus"
        (cardClick)="onInventoryItem(selectedITem)"
      />
      <div
        *ngFor="let mockItem of itemsArrayMock; let i = index"
        class="item-card mock-item-card flex fx-all-center"
        [class.min-bet-count]="highlightMockItem(i)"
      >
        <mat-icon svgIcon="gun-detailed" />
      </div>
    </div>
  </ng-scrollbar>
</div>
