import { animate, query, style, transition, trigger } from '@angular/animations';

export const areaAnimation = trigger('areaAnimation', [
  transition(':enter', [
    query(':self', [
      style({ transform: 'translate3d(-90%, 0, 0)' }),
      animate('350ms 150ms ease-in', style({ transform: 'translate3d(0, 0, 0)' })),
    ]),
  ]),
  transition(':leave', [
    query(':self', [
      style({ transform: 'translate3d(0, 0, 0)' }),
      animate('250ms ease-in-out', style({ transform: 'translate3d(-100%, 0, 0)' })),
    ]),
  ]),
]);
