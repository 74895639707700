import { Portal, TemplatePortal } from '@angular/cdk/portal';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  inject,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { NavigationEnd, Router } from '@angular/router';
import {
  ActiveRoute,
  BreakpointsTypes,
  ControlStateEnum,
  FooterCounts,
  GameMode,
  IGame,
  MarketCounts,
  MenuItem,
  ModalNames,
  NewMenu,
  NewPanel,
} from '@dev-fast/types';
import { combineLatest, filter, map, Observable, tap } from 'rxjs';

import { ChatService } from '@app/core/chatra-service';
import { IFrameCommonService } from '@app/core/iframe';
import { LayoutTypeService } from '@app/core/layout-service';
import { PortalService } from '@app/core/portal-service';
import { P2pMarketService } from '@app/modules/p2p-new';
import { ResizedEvent } from '@app/shared/directives';
import { IS_SERVER_TOKEN } from '@app/shared/utils';

import { GofastLayoutService } from './services/gofast-layout.service';
import { MainModalsService } from './services/main-modals.service';

@Component({
  selector: 'ui-gofast-layout',
  templateUrl: './gofast-layout.component.html',
  styleUrls: ['./gofast-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GofastLayoutComponent implements OnInit, AfterViewInit {
  readonly isServer = inject<boolean>(IS_SERVER_TOKEN);
  readonly #router = inject(Router);
  readonly #viewContainerRef = inject(ViewContainerRef);
  //services
  readonly #moduleService = inject(GofastLayoutService);
  readonly #iFrameService = inject(IFrameCommonService);
  readonly #layoutService = inject(LayoutTypeService);
  readonly #modalsService = inject(MainModalsService);
  readonly #portalService = inject(PortalService);
  readonly #chatService = inject(ChatService);
  readonly #marketService = inject(P2pMarketService);

  // todo: Пока так по тупому, похорошему надо layout в порядок привести
  showGameMenu = toSignal<boolean>(
    this.#router.events.pipe(
      filter((event: any) => event instanceof NavigationEnd),
      map(() => this.#router.url),
      map((url) => {
        const hideMenuResource = ['hell-case'];
        return !hideMenuResource.some((resource) => url.includes(resource));
      }),
    ),
  );

  showBottomBaner = toSignal<boolean>(
    this.#router.events.pipe(
      filter((event: any) => event instanceof NavigationEnd),
      map(() => this.#router.url),
      map((url) => {
        const hideMenuResource = ['hell-case'];
        return !hideMenuResource.some((resource) => url.includes(resource));
      }),
    ),
  );

  readonly #marketSuccessfullLots = toSignal(
    combineLatest([this.#marketService.p2pActionsToNotify$, this.#moduleService.panels$]).pipe(
      map(([notys, panels]) => {
        return panels.includes(NewPanel.TRADES) ? 0 : notys;
      }),
    ),
  );

  readonly dictAvailableGames$: Observable<Partial<Record<GameMode, IGame[]>> | undefined> = this.#moduleService.dictAvailableGames$;
  readonly lastGames$: Observable<IGame[]> = this.#moduleService.lastGames$;

  readonly panels$: Observable<NewPanel[]> = this.#moduleService.panels$.pipe(
    tap((value) => {
      this.panels = value;
    }),
  );
  readonly online$: Observable<number> = this.#moduleService.online$;
  readonly curLegacyGame$: Observable<string | null> = this.#moduleService.curLegacyGame$;
  readonly activeRoute$: Observable<ActiveRoute | null> = this.#moduleService.activeRoute$;
  // FIXME я этого не хотел, но пока не нашел другого способа
  readonly bottomControlClass$: Observable<string> = combineLatest([this.activeRoute$, this.curLegacyGame$, this.panels$]).pipe(
    map(([route, legacyGame, panels]) => {
      if (panels.some((panel) => panel === NewPanel.BET)) {
        return 'bottom-control-panel--bet__opened';
      }
      return route === ActiveRoute.CLASSIC || route === ActiveRoute.FAST || legacyGame === 'fast' ? 'bottom-control-panel--bet' : '';
    }),
  );
  readonly counts$: Observable<FooterCounts> = this.#moduleService.counts$;
  readonly marketCounts$: Observable<MarketCounts> = this.#moduleService.marketCounts$;

  readonly fadeSides$ = combineLatest([this.#iFrameService.isIFrameLoaded$, this.#moduleService.isFadeSides$]).pipe(
    map((data) => data.every(Boolean)),
  );
  readonly sectionHeaderPortal$: Observable<Portal<any> | undefined> = this.#portalService.sectionHeaderPortal.value$;
  readonly breakpoints$: Observable<BreakpointsTypes | null> = this.#moduleService.breakpoints$;
  readonly gameSelectorOpened$: Observable<boolean> = this.#moduleService.gameSelectorOpened$;

  readonly menu$: Observable<NewMenu[]> = this.#moduleService.activeMenu$;

  private panels: NewPanel[] = [];

  readonly rightControls: MenuItem[] = [
    {
      icon: 'cart-renew',
      key: 'cart',
      label: 'Cart',
      notificationCount: this.#marketSuccessfullLots,
      callback: () => {
        this.#_onCart();
      },
    },
    {
      icon: 'chat-renew',
      key: 'chat',
      label: 'Chat',
      callback: () => {
        this.#_onChat();
      },
    },
    {
      icon: 'headphones-renew',
      key: 'chatra',
      label: 'Chatra',
      callback: () => {
        this.#_onChatra();
      },
      status: this.hasChatra() ? ControlStateEnum.ENABLE : ControlStateEnum.HIDEN,
    },
  ];

  @ViewChild('layoutWrapper') layoutWrapper!: ElementRef<any>;
  @ViewChild('modalTmpl') modalTmpl: TemplateRef<unknown> | undefined;

  toggleRightPanel(panel: NewPanel): void {
    if (this.panels.includes(panel)) {
      this.#moduleService.closePanel(panel);
    } else {
      if (this.panels.length > 0) {
        this.#moduleService.closePanel(this.panels[0]);
      }
      this.#moduleService.openPanel(panel);
    }
  }
  // отставил пока, не успел разобраться нужно или нет
  onResize(event: ResizedEvent): void {
    if (!this.isServer) {
      this.#layoutService.setBreakpoints(event.newRect.width);
    }
  }
  ngOnInit(): void {
    this.#modalsService.registerModals();
    this.#moduleService.init();
  }

  ngAfterViewInit(): void {
    if (this.modalTmpl) {
      this.#portalService.routableModalPortal.value = new TemplatePortal(this.modalTmpl, this.#viewContainerRef, {});
    }
  }
  onClickLockedSide(): void {
    this.#moduleService.clickOnLockedSide();
  }
  onOpenGleamModal(): void {
    this.#moduleService.openModal(ModalNames.GLEAM);
  }
  isOpenRightPanel(panel: NewPanel[] | null): boolean {
    return !!panel && (panel.includes(NewPanel.TRADES) || panel.includes(NewPanel.CHAT));
  }
  #_onCart(): void {
    this.#router.navigate(['/store']);
    this.toggleRightPanel(NewPanel.TRADES);
  }
  #_onChat(): void {
    this.toggleRightPanel(NewPanel.CHAT);
  }
  hasChatra(): boolean {
    return this.#chatService.hasChat();
  }
  #_onChatra(): void {
    if (this.hasChatra()) {
      this.#chatService.openChat();
    }
  }
  scrollToTop(): void {
    this.layoutWrapper?.nativeElement.scrollTo({ top: 0 });
  }
}
