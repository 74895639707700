import { Injectable } from '@angular/core';
import { IInventoryItemV2, IMotivatorList, ResponseEventData } from '@dev-fast/types';
import { Socket as WrappedSocket } from 'ngx-socket-io';
import { Observable } from 'rxjs';

import { Environments, EnvironmentService } from '@app/core/environment-service';

import { ApiService } from './../api.service';

@Injectable({
  providedIn: 'root',
})
export class BlackFridayBackendService {
  private environment: Environments;

  constructor(
    private readonly api: ApiService,
    private readonly environmentService: EnvironmentService,
    private readonly ws: WrappedSocket
  ) {
    this.environment = this.environmentService.environments;
  }

  /** Общая инфа по квесту и прогрессу*/
  public getEventInfo = (): Observable<ResponseEventData> => {
    return this.api.get<ResponseEventData>(`${this.environment.GATEWAY_URL}/api/cases/quest`, {
      withCredentials: true,
      params: { type: 'rifle-range' },
    });
  };

  /** Забрать приз. возвращает или успех или ошибку например:
   * {
   *   "statusCode": 400,
   *   "message": "ERRORS.CASES.QUEST_REWARD_NOT_AVAILABLE"
   * }
   * @param targetId - index from arr from 0 to 19
   * @param categoryId - id game field 0-3
   * @param questId - hard
   */
  public pickUpPrize = (categoryId: number, targetId: number, questId: number = 4): Observable<IInventoryItemV2> => {
    return this.api.post(
      `${this.environment.GATEWAY_URL}/api/cases/quest/${categoryId}/quest/${questId}/reward`,
      { idx: targetId },
      {
        withCredentials: true,
      }
    );
  };

  public getMotivatorList = (): Observable<IMotivatorList> => {
    return this.api.get(`${this.environment.GATEWAY_URL}/api/motivator/cases-quest`, {
      withCredentials: true,
    });
  };
}
