import { AsyncPipe, DatePipe, DOCUMENT, NgClass, NgIf } from '@angular/common';
import { Component, inject, OnDestroy } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import moment from 'moment';
import { map, Observable, of, Subject, switchMap, takeWhile, timer } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { LocalStorageService } from '@app/core/local-storage-service';
import { IS_SERVER_TOKEN } from '@app/shared/utils';

@Component({
  selector: 'app-tech-works-widget',
  standalone: true,
  imports: [TranslateModule, NgClass, AsyncPipe, DatePipe, MatIconModule, NgIf],
  templateUrl: './tech-works-widget.component.html',
  styleUrls: ['./tech-works-widget.component.scss'],
})
export class TechWorksWidgetComponent implements OnDestroy {
  readonly #_document = inject<Document>(DOCUMENT);
  readonly #_isServer = inject(IS_SERVER_TOKEN);
  readonly #_storage = inject(LocalStorageService);
  #_destroyed$ = new Subject<void>();
  state = this.#_storage.get('techWorksWidgetViewed');

  readonly config = 'Tue Mar 21 2024 09:00:00 GMT+0300 (Moscow Standard Time)';
  readonly timer$: Observable<string> | undefined;
  constructor() {
    if (!this.state && !this.#_isServer) {
      this.#_setVariable('7rem');
      this.timer$ = of(this.config).pipe(
        map((date) => Date.parse(date)),
        filter((date) => date > Date.now()),
        switchMap((timeout) =>
          timer(0, 1000).pipe(
            takeUntil(this.#_destroyed$),
            map((i) => {
              const diff = timeout - Date.now();
              return diff - i;
            }),
            takeWhile((value) => value >= 0),
            map((value) => {
              const days = moment.duration(value).days();
              const hours = moment.duration(value).hours() + days * 24;
              const minutes = moment.duration(value).minutes();
              const seconds = moment.duration(value).seconds();

              return `${hours}:${minutes}:${seconds}`;
            }),
          ),
        ),
      );
    }
  }

  ngOnDestroy(): void {
    this.#_destroyed$.next();
    this.#_destroyed$.complete();
  }
  onClose(): void {
    this.#_setVariable('4rem');
    this.#_storage.set('techWorksWidgetViewed', true);
    this.state = true;
    this.#_destroyed$.next();
    this.#_destroyed$.complete();
  }
  #_setVariable(value: string): void {
    if (this.#_isServer) {
      return;
    }
    this.#_document.documentElement.style.setProperty('--header-size', value);
  }
}
