import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

import { P2pModalSetupService } from './modal.service';
import { P2pSetupModalComponent } from './p2p-setup-modal.component';

@NgModule({
  declarations: [P2pSetupModalComponent],
  imports: [CommonModule, MatIconModule, TranslateModule, ReactiveFormsModule],
  exports: [P2pSetupModalComponent],
  providers: [P2pModalSetupService],
})
export class P2pSetupModalModule {}
