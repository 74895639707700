import { ChangeDetectionStrategy, Component, ElementRef, inject, ViewChild } from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup } from '@angular/forms';
import {
  CardStatusEnum,
  IGameSettings,
  IInventoryItem,
  IInventoryRequestParams,
  IItemsFilterForm,
  InventorySortingTypes,
  ModalNames,
  StateActionStatus,
  TradeOriginalItem,
} from '@dev-fast/types';
import { NgScrollbar } from 'ngx-scrollbar';
import { combineLatest, iif, startWith, switchMap } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { distinctUntilChanged, map, tap } from 'rxjs/operators';

import { PARTICIPATION_PANEL_ENGINE, ParticipationPanelEngine } from '../symbols';
import { LoadingState } from '../types';

@Component({
  selector: 'app-participation-panel',
  templateUrl: './participation-panel.component.html',
  styleUrls: ['./participation-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ParticipationPanelComponent {
  @ViewChild('scrollbar') ngScrollbar: NgScrollbar | undefined;
  @ViewChild('scrollContent') scrollContent: ElementRef | undefined;
  //injects
  readonly #service: ParticipationPanelEngine = inject(PARTICIPATION_PANEL_ENGINE);
  //signals
  isAuth = toSignal(this.#service.isAuth$);

  readonly selectedInventoryItems$: Observable<IInventoryItem[]> = this.#service.selectedItems$;
  readonly newItemsSum$: Observable<number> = this.#service.newItemsSum$;
  readonly newItemsCount$: Observable<number> = this.#service.newItemsCount$;
  readonly participatedItemsSum$: Observable<number> = this.#service.participatedItemsSum$; // сумма предметов уже в ставке
  readonly participatedItems$: Observable<TradeOriginalItem[] | null> = this.#service.participatedItemsFromGame$; // предметы учавствующие в игре
  readonly currentGameSettings$: Observable<IGameSettings | null> = this.#service.settings$;

  readonly inventoryParams$: Observable<IInventoryRequestParams> = this.#service.inventoryParams$;
  readonly maxInventoryPages$: Observable<number | null> = this.#service.maxInventoryPages$;

  readonly temporaryTimer$: Observable<number> = this.#service.timer$!;
  readonly temporaryItems$: Observable<IInventoryItem[]> = this.#service.temporaryItems$!;

  readonly loadingInventoryStatus$: Observable<LoadingState> = this.#service.inventoryLoadStatus$.pipe(
    map((status) => {
      if (status === StateActionStatus.DISPATCH) {
        return this.itemsFilterForm.controls.page.value === 1 ? 'loading_first' : 'loading_more';
      }
      if (status === StateActionStatus.ERROR) {
        return 'error';
      }
      return 'loaded';
    }),
  );

  readonly isPanelOpened$: Observable<boolean> = this.#service.isPanelOpened$.pipe(
    startWith(false),
    distinctUntilChanged((prev, curr) => prev === curr),
    tap((isOpened) => {
      if (isOpened) {
        this.#service.getInventoryItems();
      }
    }),
  );
  readonly isMobile$: Observable<boolean> = this.#service.breakpoints$.pipe(
    map((breakpoint) => {
      if (breakpoint && breakpoint.native) {
        return ['xs', 's'].includes(breakpoint.native);
      } else {
        return false;
      }
    }),
  );
  readonly inventoryItemsByIsMobile$ = this.isMobile$.pipe(
    switchMap((v) =>
      iif(
        () => v,
        this.#service.inventoryItems$.pipe(
          map((items) => {
            return (
              items?.filter(
                (item) =>
                  !item.participateStatus ||
                  item.participateStatus === CardStatusEnum.DEFAULT ||
                  (item.participateStatus === CardStatusEnum.DISABLED && !item.isFrozen),
              ) || items
            );
          }),
        ),
        this.#service.inventoryItems$,
      ),
    ),
  );
  readonly inventoryItemsForMobile$ = this.#service.inventoryItems$.pipe(
    map((items) => {
      return (
        items?.filter(
          (item) =>
            item.participateStatus &&
            (item.participateStatus === CardStatusEnum.PARTICIPATED ||
              item.participateStatus === CardStatusEnum.SELECTED ||
              item.participateStatus === CardStatusEnum.TIMER ||
              (item.participateStatus === CardStatusEnum.DISABLED && item.isFrozen)),
        ) || items
      );
    }),
  );
  readonly actionBtnIsDisabled$: Observable<boolean> = combineLatest([
    this.#service.selectedItems$,
    this.#service.newItemsSum$,
    this.#service.participatedItems$,
    this.#service.settings$,
    this.#service.isPanelOpened$,
    this.loadingInventoryStatus$,
    this.#service.addBetStatus$,
  ]).pipe(
    map(([selectedItems, selectedSum, participatedItems, settings, isPanelOpened, inventoryLoadingStatus, addBetStatus]) => {
      return (
        !this.#_betIsValid(selectedItems?.length || 0, selectedSum || 0, participatedItems, settings) ||
        this.#_inventoryLoading(isPanelOpened, inventoryLoadingStatus) ||
        addBetStatus === StateActionStatus.DISPATCH
      );
    }),
  );
  itemsFilterForm = new FormGroup<IItemsFilterForm>({
    sortBy: new FormControl<InventorySortingTypes>(InventorySortingTypes.MAX_PRICE),
    minPrice: new FormControl<number>(0),
    maxPrice: new FormControl<number>(10000),
    page: new FormControl<number>(1),
    pageSize: new FormControl<number>(50),
  });

  constructor() {
    this.itemsFilterForm.valueChanges.pipe(takeUntilDestroyed()).subscribe((value) => {
      this.#service.getInventoryItems({ ...value, page: 1 });
    });
    this.inventoryParams$.pipe(takeUntilDestroyed()).subscribe((params) => {
      if (this.ngScrollbar && params.page === 1) {
        this.ngScrollbar.scrollTo({ top: 0, left: 0 });
      }
      this.itemsFilterForm.patchValue({ ...params }, { emitEvent: false });
    });
  }

  closePanel(): void {
    this.#service.togglePanel();
  }

  selectInventoryItem(item: IInventoryItem): void {
    this.#service.onItemClick(item);
  }

  clearSelectedItems(): void {
    this.#service.unselectItems();
  }

  navigateTo(path: string): void {
    this.#service.navigateTo(path);
  }

  proceedBtnAction(selectedItems: IInventoryItem[] | null): void {
    if (selectedItems && selectedItems.length) {
      this.#service.placeBet(selectedItems);
    } else {
      this.#service.togglePanel();
    }
  }

  togglePanel(): void {
    this.#service.togglePanel();
  }

  onExchange(): void {
    this.#service.openModal(ModalNames.TRADE);
  }

  onReachedBottom(maxCountPages?: number | null): void {
    // когда появляется лоадер скрол ранее достигший низа тригерится и считает что он снова его достиг.
    // поэтому такая защитка
    const height = this.scrollContent ? this.scrollContent.nativeElement.offsetHeight : 0;
    if (height < 300) {
      return;
    }
    this.#_paginationChange(maxCountPages);
  }
  onReachedEnd(maxCountPages?: number | null): void {
    this.#_paginationChange(maxCountPages);
  }
  trackByInventoryItems(index: number, item: IInventoryItem): number {
    return item.inventoryItemId;
  }
  #_paginationChange(maxCountPages?: number | null): void {
    const value = this.itemsFilterForm.value;
    const { page } = value;
    if (maxCountPages && page && page < maxCountPages) {
      this.#service.getInventoryItems({ ...value, page: page + 1 });
    }
  }
  /// Validators
  #_countIsValid(minItemsPerTrade: number, maxItemsPerTrade: number, selectedCount: number, participatedCount: number): boolean {
    return selectedCount + participatedCount >= minItemsPerTrade && selectedCount + participatedCount <= maxItemsPerTrade;
  }
  #_betValueIsValid(minBet: number, maxBet: number | null, sum: number): boolean {
    return sum >= minBet && (!maxBet || sum <= maxBet);
  }
  #_betIsValid(
    selectedCount: number,
    selectedSum: number,
    participatedItems: TradeOriginalItem[] | null,
    settings: IGameSettings | null,
  ): boolean {
    if (!settings) {
      return false;
    }
    if (selectedCount === 0) {
      return true;
    }
    const participatedCount = participatedItems ? participatedItems.length : 0;
    const { minItemsPerTrade, maxItemsPerTrade, minBet, maxBet } = settings;
    return (
      this.#_countIsValid(minItemsPerTrade, maxItemsPerTrade, selectedCount, participatedCount) &&
      this.#_betValueIsValid(minBet, maxBet, selectedSum)
    );
  }
  #_inventoryLoading(isPanelOpened: boolean, inventoryLoadingStatus: LoadingState): boolean {
    return isPanelOpened && inventoryLoadingStatus !== 'loaded';
  }
}
