import { Injectable } from '@angular/core';
import {
  ActivePanel,
  BreakpointsTypes,
  IInventoryItem,
  IInventoryRequestParams,
  ILocales,
  INotification,
  InventoryTradeBalance,
  IShopMeta,
  ISkinItem,
  IUserDetailed,
  LegacyGameConfig,
  LegacyGameState,
  LegacyLiteGameState,
  ModalNames,
  Panel,
  SkinClickEvent,
} from '@dev-fast/types';
import { Navigate } from '@ngxs/router-plugin';
import { Actions, ofActionSuccessful, Select } from '@ngxs/store';
import { ActionsExecuting, actionsExecuting } from '@ngxs-labs/actions-executing';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { map, Observable } from 'rxjs';

import { AuthService } from '@app/auth';
import { LanguageService } from '@app/core/language-service';
import { NotificationsService } from '@app/core/notification-service';
import { AccountState } from '@app/core/state/account';
import { ConfirmParticipateLegacySuccess, GamesState, ParticipateLegacy } from '@app/core/state/games-store';
import {
  ChangeParamsInventory,
  ChangeParamsShop,
  ClickOnInventoryItem,
  ClickOnShopItem,
  GetShopItems,
  InventoryLegacyState,
  SellAllItems,
  Trade,
  UnselectInventoryItemById,
} from '@app/core/state/inventory-legacy';
import { ChangeActivePanel, LayoutState, NavigateToMobile, OpenAvailablePanel } from '@app/core/state/layout';
import { OpenModal } from '@app/core/state/modals';
import { RemoveNotification, WatchAllNotification } from '@app/core/state/notifications';
import { CommonStoreService } from '@app/core-state-common';

@Injectable()
export class DesktopRightsidebarService {
  public user$: Observable<IUserDetailed | null | undefined> = this.commonService.user$;
  public activePanel$: Observable<ActivePanel | null> = this.commonService.activePanel$;
  public notifications$: Observable<INotification[]> = this.notificationsService.notifications$;
  public hasNewNotifications$: Observable<boolean> = this.notificationsService.hasNewNotifications$;
  public countNewNotifications$: Observable<number> = this.notificationsService.countNewNotifications$;
  public locales$: Observable<ILocales[]> = this.languageService.locales$;
  public currentLocale$: Observable<ILocales> = this.languageService.currentLocale$;

  @Select(actionsExecuting([GetShopItems, ChangeParamsShop]))
  public inventoryShopLoading$!: Observable<ActionsExecuting>;
  @Select(InventoryLegacyState.items)
  public inventoryItems$!: Observable<IInventoryItem[] | null>;
  @Select(InventoryLegacyState.selectedItems)
  public selectedInventoryItems$!: Observable<IInventoryItem[]>;
  @Select(InventoryLegacyState.shopItems)
  public shopItems$!: Observable<ISkinItem[]>;
  @Select(InventoryLegacyState.contracts)
  public selectedShopItems$!: Observable<ISkinItem[]>;
  @Select(InventoryLegacyState.tradeBalance)
  public inventoryTradeBalance$!: Observable<InventoryTradeBalance>;
  @Select(InventoryLegacyState.inventorySum)
  public totalBalance$!: Observable<number>;
  @Select(InventoryLegacyState.inventorySortingParams)
  public inventorySortByPrice$!: Observable<boolean>;
  @Select(InventoryLegacyState.shopParams)
  public shopParams$!: Observable<IInventoryRequestParams>;
  @Select(InventoryLegacyState.inventoryParams)
  public inventoryParams$!: Observable<IInventoryRequestParams>;
  @Select(InventoryLegacyState.shopMeta)
  public shopMeta$!: Observable<IShopMeta>;
  @Select(InventoryLegacyState.gameInProgress)
  public readonly gameInProgress$!: Observable<boolean>;
  @Select(GamesState.legacyConfig)
  public legacyConfig$!: Observable<LegacyGameConfig | null>;
  @Select(GamesState.legacyState)
  public legacyState$!: Observable<LegacyGameState | null>;
  @Select(GamesState.legacyLiteState)
  public legacyLiteState$!: Observable<LegacyLiteGameState | null>;
  @Select(GamesState.legacyParticipation)
  public legacyParticipation$!: Observable<IInventoryItem[]>;
  @Select(LayoutState.lockedPanels)
  public lockedPanels$!: Observable<Panel[]>;
  @Select(AccountState.canLevelUp)
  public canLevelUp$!: Observable<boolean>;
  @Select(LayoutState.breakpoints)
  public breakpoints$!: Observable<BreakpointsTypes | null>;

  public participatedSuccess$ = this.actions$.pipe(
    ofActionSuccessful(ConfirmParticipateLegacySuccess),
    map((action: ConfirmParticipateLegacySuccess) => action.payload)
  );

  constructor(
    private commonService: CommonStoreService,
    private readonly authService: AuthService,
    private readonly notificationsService: NotificationsService,
    private readonly actions$: Actions,
    private readonly languageService: LanguageService
  ) {}

  public login = (): void => {
    this.authService.openLoginProvidersModal();
  };

  public logout = (): void => {
    this.authService.onLogout();
  };

  public switchLang(locale: ILocales): void {
    this.languageService.setLanguage(locale);
  }

  public changeActivePanel = (panel: ActivePanel | null): ChangeActivePanel => this.commonService.changeActivePanel(panel);
  public removeNoty = (notyId: number): RemoveNotification => this.notificationsService.removeNotification(notyId);
  public watchAllNotifications = (): WatchAllNotification => this.notificationsService.watchAllNotifications();

  @Dispatch() public openAvailablePanel = (): OpenAvailablePanel => new OpenAvailablePanel();
  @Dispatch() public openSupportSelectorModal = (): OpenModal => new OpenModal(ModalNames.TECH_SUPPORT_SELECT_METHOD);
  @Dispatch() public getShopItems = (): GetShopItems => new GetShopItems();
  @Dispatch() public clickOnSkinInventory = (event: SkinClickEvent): ClickOnInventoryItem | null | 0 =>
    event.id && new ClickOnInventoryItem(event.id);
  @Dispatch() public clickOnSkinShop = (event: SkinClickEvent): ClickOnShopItem | null | 0 => event.id && new ClickOnShopItem(event.id);
  @Dispatch() public sellAll = (): SellAllItems => new SellAllItems();
  @Dispatch() public createTrade = (): Trade => new Trade();
  @Dispatch() public changeParamsInventory = (params: IInventoryRequestParams): ChangeParamsInventory => new ChangeParamsInventory(params);
  @Dispatch() public changeParamsShop = (params: IInventoryRequestParams, design?: 'old' | 'new'): ChangeParamsShop =>
    new ChangeParamsShop(params, design);
  @Dispatch() public participate = (): ParticipateLegacy => new ParticipateLegacy();
  @Dispatch() public unselectInventoryItems = (ids: number[]): UnselectInventoryItemById => new UnselectInventoryItemById(ids);
  @Dispatch() public navigateToMobile = (): NavigateToMobile => new NavigateToMobile();
  @Dispatch() public navigate = (path: any[]): Navigate => new Navigate(path);
}
