import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, DestroyRef, EventEmitter, inject, Input, NgModule, Output, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { TranslateModule } from '@ngx-translate/core';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { Subject, tap } from 'rxjs';

import { ClickOutsideModule } from '@app/shared/directives';
import { expandAnimation } from '@app/ui/animations';

import { UiInputSAComponent } from '../input/input.component';
import { AutocompleteListComponent } from './autocomplete-list/autocomplete-list.component';
import { ISelectionMenuItem } from './shared';

@Component({
  selector: 'app-ui-autocomplete-menu',
  templateUrl: './autocomplete-menu.component.html',
  styleUrls: ['./autocomplete-menu.component.scss'],
  animations: [expandAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AutocompleteMenuComponent {
  #destroyRef = inject(DestroyRef);

  @Input() importantItems: (string | undefined)[] = [];
  @Input() disabled = false;
  @Input() placeholder: string | undefined;
  @Input() elementsVisible = 7; //кол-во видимых элементов
  @Input() set selectionItems(payload: { items: ISelectionMenuItem[]; activeIndex: number | undefined }) {
    this.#menuItems = payload.items;
    this.selectedItem = typeof payload.activeIndex === 'number' ? payload.items[payload.activeIndex] : null;
    this.autocompleteFormGroup.controls['autocompleteControl'].setValue(this.selectedItem?.title || '', { emitEvent: false });
    this.#sortMenuItems();
  }
  @Output() activeItemIndex: EventEmitter<number> = new EventEmitter<number>();
  @Output() panelOpened: EventEmitter<boolean> = new EventEmitter<boolean>();

  sortedItems = signal<ISelectionMenuItem[]>([]);

  #menuItems: ISelectionMenuItem[] = [];

  isMenuOpened = false;
  selectedItem: ISelectionMenuItem | null = null;

  scrollToTop$: Subject<void> = new Subject();

  autocompleteFormGroup: FormGroup<any> = new FormGroup({
    autocompleteControl: new FormControl('', {}),
  });

  constructor() {
    this.autocompleteFormGroup.controls['autocompleteControl'].valueChanges
      .pipe(
        tap((value) => {
          if (!this.isMenuOpened) {
            this.toggleMenu(true);
          }
          this.#sortMenuItems(value);
        }),
        takeUntilDestroyed(this.#destroyRef),
      )
      .subscribe();
  }
  toggleMenu(isToggle: boolean, event?: Event): void {
    event?.stopPropagation();
    this.isMenuOpened = isToggle;

    if (!isToggle) {
      this.#checkValue();
      this.#sortMenuItems();
    }
  }

  selectItem(itemName: string): void {
    const itemIndex = this.#menuItems.findIndex((item) => item.name === itemName);
    this.activeItemIndex.emit(itemIndex);
    this.selectedItem = this.#menuItems[itemIndex];
    this.toggleMenu(false);
  }

  #checkValue(): void {
    const value = this.autocompleteFormGroup.controls['autocompleteControl'].value || '';
    const itemIndex = this.#menuItems.findIndex((item) => item.title.toLowerCase() === value.toLowerCase());
    if (itemIndex < 0) {
      this.autocompleteFormGroup.controls['autocompleteControl'].patchValue(this.selectedItem?.title || '', { emitEvent: false });
    }
  }

  #sortMenuItems(stringToAutocomplete: string | null = null): void {
    this.scrollToTop$.next();
    const filteredItems = stringToAutocomplete ? this.#filter(stringToAutocomplete) : this.#menuItems;
    this.sortedItems.set(this.#sortMenuItemsWithImportant(filteredItems, this.importantItems));
  }

  /**
    Функция для размещения приоритетных элементов в начале списка
  */
  #sortMenuItemsWithImportant(itemsToSort: ISelectionMenuItem[], importantItems: (string | undefined)[]): ISelectionMenuItem[] {
    if (!importantItems.length) {
      return itemsToSort;
    }
    const sortedItems = itemsToSort.reduce(
      (acc: { important: ISelectionMenuItem[]; other: ISelectionMenuItem[] }, curr) => {
        if (importantItems.some((item) => item?.toLowerCase() === (curr.name || curr.title).toLowerCase())) {
          acc.important.push(curr);
        } else {
          acc.other.push(curr);
        }
        return acc;
      },
      { important: [] as ISelectionMenuItem[], other: [] as ISelectionMenuItem[] },
    );
    return [...sortedItems.important, ...sortedItems.other];
  }

  #filter(value: string): ISelectionMenuItem[] {
    const sortedItems = this.#menuItems.reduce(
      (acc: { matching: any[]; mismatched: any[] }, cur) => {
        if (cur.title.toLowerCase().includes(value.toLowerCase())) {
          return { ...acc, matching: [...acc.matching, cur] };
        }
        return { ...acc, mismatched: [...acc.mismatched, cur] };
      },
      { matching: [], mismatched: [] },
    );
    return [...sortedItems.matching];
  }
}
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatIconModule,
    MatFormFieldModule,
    MatSelectModule,
    MatAutocompleteModule,
    MatInputModule,
    TranslateModule,
    NgScrollbarModule,
    UiInputSAComponent,
    ClickOutsideModule,
  ],
  declarations: [AutocompleteMenuComponent, AutocompleteListComponent],
  exports: [AutocompleteMenuComponent],
})
export class AutocompleteMenuModule {}
