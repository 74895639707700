import { Injectable } from '@angular/core';
import { Affise } from '@dev-fast/types';
import { Observable } from 'rxjs';

import { Environments, EnvironmentService } from '@app/core/environment-service';

import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class AnalyticsApiService {
  private environment: Environments;

  constructor(private readonly api: ApiService, private readonly environmentService: EnvironmentService) {
    this.environment = this.environmentService.environments;
  }

  public affisePost(data: Affise): Observable<void> {
    return this.api.post<void>(`${this.environment.GATEWAY_URL}/api/affise/register`, data, {
      withCredentials: true,
    });
  }
}
