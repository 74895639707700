import { ICurrencyRate } from '../entities';

export const currencyDic: ICurrencyRate[] = [
  {
    key: 'USD',
    rate: 0.01,
    prefix: '$',
  },
  {
    key: 'RUB',
    rate: 0.8,
    prefix: '₽',
  },
  {
    key: 'CNY',
    rate: 0.068,
    prefix: '¥',
  },
  {
    key: 'PLN',
    rate: 0.0394,
    prefix: 'zł',
  },
  {
    key: 'Coins',
    rate: 1,
    prefix: 'c',
  },
  {
    key: 'EUR',
    rate: 0.0085,
    prefix: '€',
  },
];

export const countriesDic = {
  europe: [
    'BE',
    'EL',
    'LT',
    'PT',
    'BG',
    'ES',
    'LU',
    'RO',
    'CZ',
    'FR',
    'HU',
    'SI',
    'DK',
    'HR',
    'MT',
    'SK',
    'DE',
    'IT',
    'NL',
    'FI',
    'EE',
    'CY',
    'AT',
    'SE',
    'IE',
    'LV',
    'PL',
    'UK',
  ],
  russia: ['RU'],
  poland: ['PL'],
  china: ['CN'],
  usa: ['US'],
};
