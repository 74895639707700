import { IconConfig } from '@dev-fast/types';

export const socialsCommonIcons: IconConfig[] = [
  {
    name: 'facebook',
    path: 'assets/img/mat-icons/icons-lib/common/socials/facebook.svg',
  },
  {
    name: 'youtube-btn',
    path: 'assets/img/mat-icons/icons-lib/common/socials/youtube-btn.svg',
  },
  {
    name: 'discord',
    path: 'assets/img/mat-icons/icons-lib/common/socials/discord.svg',
  },
  {
    name: 'socials-x',
    path: 'assets/img/mat-icons/icons-lib/common/socials/socials-x.svg',
  },
  {
    name: 'vk-bold',
    path: 'assets/img/mat-icons/icons-lib/common/socials/vk-bold.svg',
  },
  {
    name: 'instagram-line',
    path: 'assets/img/mat-icons/icons-lib/common/socials/instagram-line.svg',
  },
  {
    name: 'steam-bold',
    path: 'assets/img/mat-icons/icons-lib/common/socials/steam-bold.svg',
  },
];
