import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

import { CurrencyComponent } from '@app/core/currency';
import { AppCurrencyModule, HashPipe, ReplacePipeModule, StickerHashPipe } from '@app/shared/pipes';

import { FloatIndicatorModule } from '../float-indicator/float-indicator.component';
import { P2pLotCardComponent } from './p2p-lot-card.component';

@NgModule({
  declarations: [P2pLotCardComponent],
  exports: [P2pLotCardComponent],
  imports: [
    CommonModule,
    MatIconModule,
    TranslateModule,
    ReplacePipeModule,
    AppCurrencyModule,
    StickerHashPipe,
    FloatIndicatorModule,
    HashPipe,
    CurrencyComponent,
  ],
})
export class P2pLotCardModule {}
