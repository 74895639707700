import { Component, inject } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

import { DefaultSihModalTranslations } from '../../model';
import { SihService } from '../../sih.service';
import { SteamDoubleIconComponent } from '../steam-double-icon/steam-double-icon.component';

@Component({
  selector: 'app-sih-check-card',
  standalone: true,
  imports: [TranslateModule, MatIconModule, SteamDoubleIconComponent],
  providers: [SihService],
  templateUrl: './sih-check-card.component.html',
  styleUrls: ['../../shared-style.scss', './sih-check-card.component.scss'],
})
export class SihCheckCardComponent {
  readonly #sihService = inject(SihService);
  onOpenSihInstallModal(): void {
    this.#sihService.openSihInstallModal({ ...DefaultSihModalTranslations, body: 'SIH.FIRST_MODAL.BODY_SELL', body_2: '' });
  }
}
