export enum ModalNames {
  CASES_BONUS = 'CASES_BONUS',
  SERVER_SEED_HISTORY = 'SERVER_SEED_HISTORY',
  CLIENT_SEED_HISTORY = 'CLIENT_SEED_HISTORY',
  TECH_SUPPORT_SELECT_METHOD = 'tech-support',
  USER_PROFILE = 'USER_PROFILE',
  LOTTERY_RULES = 'LOTTERY_RULES',
  AUTH = 'AUTH',
  REFERRAL = 'REFERRAL',
  NY_EVENT_TERMS = 'NY_EVENT_TERMS',
  STANDART_EVENT_REWARD = 'STANDART_EVENT_REWARD',
  GLEAM = 'GLEAM',
  DDOS = 'DDOS',
  BAN = 'BAN',
  OFFERWALL = 'OFFERWALL',
  STEAM_ERROR = 'STEAM_ERROR',
  VIDEO = 'VIDEO',
  AUTHV2 = 'AUTHV2',
  TRANSACTION_NOTIFICATION = 'TRANSACTION_NOTIFICATION',
  // --------------P2P--------------------------//
  P2P_SETUP = 'P2P_SETUP',
  P2P_KIT_DETAILS = 'P2P_KIT_DETAILS',
  P2P_MARKET_HISTORY = 'P2P_MARKET_HISTORY',
  P2P_AUCTION_COMPLETED = 'P2P_AUCTION_COMPLETED',
  P2P_FILTER_MOBILE_MENU = 'P2P_FILTER_MOBILE_MENU',
  P2P_RWT = 'P2P_RWT',
  SIH_INSTALL = 'SIH_INSTALL',
  // --------------Cases------------------------//
  CASE_REVISION = 'CASE_REVISION',
  AUTOPLAY_GAME = 'AUTOPLAY_GAME',
  JOKER_MODE = 'JOKER_MODE',
  // --------------Case-battle------------------//
  CASE_BATTLE_WINNERS = 'CASE_BATTLE_WINNERS',
  CASE_BATTLE_ACCOUNT_NOTY = 'CASE_BATTLE_ACCOUNT_NOTY',
  CASE_BATTLE_GAME_BREAK = 'CASE_BATTLE_GAME_BREAK',
  CASE_BATTLE_CASE_INFO = 'CASE_BATTLE_CASE_INFO',
  CASE_BATTLE_PROVABLY_FAIR = 'CASE_BATTLE_PROVABLY_FAIR',
  // -------------- Inventory ------------------//
  INVENTORY_HISTORY = 'INVENTORY-HISTORY',
  TRADE = 'TRADE',
  SELF_EXCLUSION = 'SELF_EXCLUSION',
  // -------------- Refill ---------------------//
  REFILL_IS_SUCCESS = 'REFILL_IS_SUCCESS',
  // REFILL_IS_UNSUCCESS = 'REFILL_IS_UNSUCCESS',
  REFILL_STEAM_ERROR = 'REFILL_STEAM_ERROR',
  // -------------- Personal Account -----------//
  CONFIRM_EMAIL = 'CONFIRM-EMAIL',
  USER_EDIT = 'USER_EDIT',
  COUNTRY_SELECT = 'COUNTRY_SELECT',
  AVATAR_CROP = 'AVATAR_CROP',
  SOCIAL_INFO = 'SOCIAL_INFO',
  RENEW_TABLE_ITEM = 'RENEW_TABLE_ITEM',
  // ------ MARKET CASE -------
  CASE = 'CASE',

  // CLASSIC
  FAIR_GAME = 'FAIR_GAME',
  VICTORY = 'VICTORY',
  // -------------- REFERRAL ------------------------//
  REFERRAL_CREATE = 'REFERRAL_CREATE',
}

export enum RoutableModalNames {
  COMMON = 'common',
}
