import { ILocales } from '@dev-fast/types';

export interface LanguageStateModel {
  locales: ILocales[];
  lang: ILocales;
}

export const LANGUAGE_INITIAL_STATE: LanguageStateModel = {
  locales: [],
  lang: {
    path: 'default',
    isActive: true,
    title: 'Mock',
    isLocal: true,
  },
};
