import { ISteamStoreInventory } from '../steam-store.interface';

export interface IP2pDepositItem extends ISteamStoreInventory, IP2pDepositSettings {
  extra: {
    increase: number;
    price: number;
    safeOverprice?: number;
  };
}
export interface IP2pDepositSettings {
  autoApprove?: boolean;
  fastDelivery?: boolean;
  tradesDuration?: number;
}
