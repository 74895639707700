import {
  ActivePanel,
  ActiveRoute,
  BreakpointsTypes,
  Design,
  FooterCounts,
  MarketCounts,
  NewMenu,
  NewPanel,
  Panel,
  Themes,
  Widgets,
} from '@dev-fast/types';

export interface LayoutStateModel {
  theme: Themes;
  design: Design;
  background: string;
  activePanel: ActivePanel | null;
  activePanelNew: NewPanel[];
  activeMenu: NewMenu[];
  activeWidgets: Widgets[];
  gameSelectorOpened: boolean;
  allowedPanels: Panel[];
  allowTradePanelAutoOpen: boolean;
  /** Эти модули доступны но в даный момент времени ими нельзя воспользоваться */
  lockedPanels: Panel[];
  isFadeSides: boolean;
  // для открывания десктопа в мобиле
  hasDesktopParams: boolean;
  breakpoints: BreakpointsTypes | null;
  online: number | null;
  counts: FooterCounts | null;
  marketCounts: MarketCounts | null;
  activeRoute: ActiveRoute | null;
}

export const LAYOUT_INITIAL_STATE: LayoutStateModel = {
  theme: Themes.BLUE,
  design: Design.OLD,
  allowedPanels: [],
  activePanel: null,
  activePanelNew: [],
  activeMenu: [],
  gameSelectorOpened: false,
  allowTradePanelAutoOpen: true,
  isFadeSides: false,
  lockedPanels: [],
  activeWidgets: [],
  hasDesktopParams: false,
  background: 'url(/assets/img/background/bg-standart.jpg)',
  breakpoints: null,
  online: null,
  counts: null,
  marketCounts: null,
  activeRoute: null,
};
