import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { SocialItem, SocialType } from '@dev-fast/types';
import { Observable } from 'rxjs';

import { EnvironmentService } from '@app/core/environment-service';

import { AuthService } from '../../auth.service';

@Component({
  selector: 'fast-auth-modal',
  templateUrl: './auth-modal.component.html',
  styleUrls: ['./auth-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class AuthModalComponent {
  public loginAttempt: boolean;
  public agreementChecked: boolean;
  public socials$: Observable<SocialItem[]>;
  public email: string = this.envService.getEnvironment().SUPPORTS_EMAIL;
  @Input() public showAttention: boolean;

  constructor(private authService: AuthService, private readonly envService: EnvironmentService) {
    this.loginAttempt = false;
    this.agreementChecked = true;
    this.socials$ = this.authService.socials$;
    this.showAttention = true;
  }

  public authorize(provider: SocialType): void {
    this.loginAttempt = true;
    if (this.agreementChecked) {
      this.authService.onLogin(provider);
    }
  }
}
