import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { TranslateModule } from '@ngx-translate/core';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { BehaviorSubject, map, Observable, startWith } from 'rxjs';

import { IS_SERVER_TOKEN } from '@app/shared/utils';

interface ISelectionMenuItem {
  title: string;
  name: string;
  icon?: {
    type: string;
    src: string;
  };
}
@Component({
  selector: 'app-ui-selection-menu',
  standalone: true,
  templateUrl: './selection-menu.component.html',
  styleUrls: ['./selection-menu.component.scss'],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatIconModule,
    MatFormFieldModule,
    MatSelectModule,
    MatAutocompleteModule,
    MatInputModule,
    TranslateModule,
    NgScrollbarModule,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectionMenuSAComponent implements OnInit {
  @Input() importantItems: (string | undefined)[] = [];
  @Input() isInputAutocomplete = false;
  @Input() disabled = false;
  @Input() labelText: string | undefined;
  @Input() set selectionItems(payload: { items: ISelectionMenuItem[]; activeIndex: number | undefined }) {
    this.menuItems = payload.items;
    if (payload.items && payload.activeIndex !== undefined) {
      this.selectedItem = payload.items[payload.activeIndex];
    } else {
      this.selectedItem = null;
    }
    if (this.isInputAutocomplete) {
      this.filteredItems$ = this.filteredItemsObserverConstructor();
    }
  }
  @Input() formClass = 'ui-form';
  @Input() panelClass = 'ui-menu';
  @Output() activeTabChanged: EventEmitter<number> = new EventEmitter<number>();
  @Output() onpanelOpened: EventEmitter<boolean> = new EventEmitter<boolean>();
  @ViewChild('elementInput', { read: MatAutocompleteTrigger }) elementInput: MatAutocompleteTrigger | undefined;

  private inputValue$: BehaviorSubject<string> = new BehaviorSubject('');
  filteredItems$!: Observable<ISelectionMenuItem[]>;
  menuItems: ISelectionMenuItem[] = [];
  selectedItem: ISelectionMenuItem | null = null;
  autocompleteControl = new FormControl('');
  isInputFocused = false;

  readonly #isServer = inject(IS_SERVER_TOKEN);

  ngOnInit(): void {
    // для прилипания открытого меню к инпуту
    // https://stackoverflow.com/questions/46888635/disable-scrolling-when-angular-material-select-is-open
    if (this.#isServer) {
      return;
    }
    window.addEventListener('scroll', this.scrollEvent, true);
  }

  private filteredItemsObserverConstructor(): Observable<ISelectionMenuItem[]> {
    this.autocompleteControl.setValue(this.selectedItem?.title || '');
    return this.inputValue$.pipe(
      startWith(this.selectedItem?.title || ''),
      map((value: string | null) => (value ? this.filter(value) : this.menuItems)),
    );
  }
  private filter(value: string): ISelectionMenuItem[] {
    const sortedItems = this.menuItems.reduce(
      (acc: { matching: any[]; mismatched: any[] }, cur) => {
        if (cur.title.toLowerCase().includes(value.toLowerCase())) {
          return { ...acc, matching: [...acc.matching, cur] };
        }
        return { ...acc, mismatched: [...acc.mismatched, cur] };
      },
      { matching: [], mismatched: [] },
    );
    return [...sortedItems.matching, ...sortedItems.mismatched];
  }

  private scrollEvent = (): void => {
    if (this.elementInput && this.elementInput.panelOpen) {
      this.elementInput.updatePosition();
    }
  };

  filterItems($event: Event): void {
    const value = ($event.target as HTMLInputElement).value;
    this.inputValue$.next(value);
  }
  /*
    функция для размещения приоритетных элементов в начале списка
  */
  sortMenuItems(menuItems: ISelectionMenuItem[] | null, importantItems: (string | undefined)[]): ISelectionMenuItem[] {
    if (menuItems) {
      if (!importantItems.length) {
        return menuItems;
      }
      const sortedItems = menuItems.reduce(
        (acc: { important: ISelectionMenuItem[]; other: ISelectionMenuItem[] }, curr) => {
          if (importantItems.some((item) => item?.toLowerCase() === (curr.name || curr.title).toLowerCase())) {
            acc.important.push(curr);
          } else {
            acc.other.push(curr);
          }
          return acc;
        },
        { important: [] as ISelectionMenuItem[], other: [] as ISelectionMenuItem[] },
      );
      return [...sortedItems.important, ...sortedItems.other];
    }
    return [];
  }
  selectItem(index: number): void {
    this.activeTabChanged.emit(index);
  }
  selectItemForAutocomplete(itemName: string): void {
    if (!this.elementInput) {
      return;
    }
    this.elementInput.closePanel();
    const itemIndex = this.menuItems.findIndex((item) => item.name === itemName);
    this.activeTabChanged.emit(itemIndex);
  }
  checkValue(): void {
    const value = this.autocompleteControl.value || '';
    const isSomeItemsMatching = this.menuItems.some((item) => item.title.trim().toLowerCase().includes(value.trim().toLowerCase()));
    if (!value.length || !isSomeItemsMatching) {
      this.autocompleteControl.setValue(this.selectedItem?.title || '');
    }
  }
  closeMenu(event: Event): void {
    if (!this.elementInput) {
      return;
    }
    if (this.elementInput.panelOpen) {
      event.stopPropagation();
    }
    this.elementInput.closePanel();
  }
  toggleInputValue(isOpened: boolean): void {
    this.isInputFocused = isOpened;
    this.onpanelOpened.emit(isOpened);
    if (isOpened) {
      this.inputValue$.next('');
      this.autocompleteControl.setValue('');
    } else {
      this.checkValue();
    }
  }
}
