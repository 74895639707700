import { PaymentTypes, PaymentWalletType } from '../../enums';
import { IPaymentGate } from './payment-gate';
import { IPaymentType, IPaymentTypeV2 } from './payments.interface';

export interface IPaymentMethod {
  title: string;
  name: string;
  is_enabled: boolean;
  is_terminal: boolean;
  gates: IPaymentGate[];
  icon?: string;
  group_name?: string;
  method_name?: string;
}
export interface IPaymentSelected {
  type: IPaymentType;
  method?: IPaymentMethod;
  gate?: IPaymentGate;
}
export interface IPaymentSelectedV2 {
  type: IPaymentTypeV2;
  method?: IPaymentMethodV2;
}
export interface IPaymentSuccessMethod {
  name: string;
  title: string;
}

export interface IPaymentMethodV2 {
  currency: string[];
  id: number;
  method_name: string;
  method_title: string;
  gate_title: string;
  gate_id: number;
  minPayment: number;
  maxPayment: number;
  walletType: PaymentWalletType;
  icon: boolean | string;
  is_popular: boolean;
  is_terminal: boolean;
  bonus: number;
  group_name: PaymentTypes[];
  feeString: string;
  hash: string;
  sort: number;
  fees: {
    percent: number;
    fixed: number;
  };
}
