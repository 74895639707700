export enum Panel {
  CHAT = 'chat',
  NOTIFICATIONS = 'notify',
  INVENTORY = 'inventory',
  LANGUAGE = 'language',
  TRADES = 'trades',
  MOBILE_LANG = 'm_lang',
  EVENT_MENU = 'event_menu',
  MAIN_MENU = 'main_menu',
}

export enum NewPanel {
  CHAT = 'chat',
  TRADES = 'trades',
  BET = 'bet',
}

export enum NewMenu {
  NOTIFICATIONS = 'notify',
  LANGUAGE = 'language',
  MOBILE_LANG = 'm_lang',
  EVENT_MENU = 'event_menu',
  MAIN_MENU = 'main_menu',
  ALL_GAMES = 'all_games',
}

export enum SubPanel {
  NONE = 'none',
  EXHANGE = 'exchange',
  BET = 'bet',
}

export enum MarketPanel {
  Purchase = 0,
  Sell = 1,
  Settings = 2,
}

export enum InventoryTrade {
  Inventory = 0,
  Trade = 1,
}
