<form
  [formGroup]="filterForm"
  autocomplete="off"
  class="marketFilter mobile-view tw-flex tw-gap-3"
>
  <app-ui-tab-group
    [activeTab]="activeTab() || null"
    [tabGroupClass]="'market-filter-tabs'"
    [tabLabels]="['P2P_WIDGETS.BUY', 'P2P_WIDGETS.SELL']"
    (activeTabChanged)="updateCurrentTab($event)"
  />
  <div class="flex fx-justify-between gap-3 tw-w-full tw-relative">
    <!-- Кнопка фильтров -->
    <app-ui-items-filter-button
      [filterForm]="filterForm"
      [initValues]="initFormValues"
      [disableCountParams]="dformData.disableCountParams"
      [isFilterPanelOpened]="isFilterPanelOpened()"
      [isLoading]="isLoading() || false"
      [itemsFoundAmount]="searchResultCount() || null"
      (panelStatusChanged)="changePanelStatus($event)"
      (openMobileFilter)="mobileFilterPanelOpen()"
      (newFiltersApplied)="applyFilters()"
      (clearFiltersEvent)="clearFiltersEvent()"
      [class.tw-hidden]="isSearchFieldOpened()"
    />
    <app-sih-check-button
      [availableItems]="resultCount()!"
      *ngIf="isPurchase() && resultCount()"
    />
    <!-- Строка поиска -->
    <div class="flex gap-3 tw-ml-auto">
      <mat-form-field
        *ngIf="filterForm.get('query')"
        appearance="outline"
        class="ui-input filter-search mobile-hide relative"
        [class.active-background-color]="filterForm.get('query')?.value"
        [class.open-search-field]="isSearchFieldOpened()"
      >
        <input
          matInput
          type="text"
          [formControlName]="'query'"
          [placeholder]="'LOCAL.FILTERS.SEARCH_PLACEHOLDER' | translate"
        />
        <mat-icon
          matTextPrefix
          class="tw-mr-2"
          svgIcon="search--design"
        />

        <div
          matTextSuffix
          class="tw-cursor-pointer tw-mr-2 mobile-show"
          (click)="clearSearchField(); openMobileSearchForm()"
        >
          <mat-icon svgIcon="close" />
        </div>

        <div
          role="button"
          *ngIf="filterForm.get('query')"
          matTextSuffix
          class="tw-cursor-pointer tw-mr-2 mobile-hide"
          (click)="clearSearchField()"
        >
          <mat-icon svgIcon="close" />
        </div>
      </mat-form-field>

      <!-- Мобильная кнопка открытия фильтров (модалка) -->
      <div
        class="filter-btn round-btn mobile-show tw-cursor-pointer flex fx-all-center tw-relative"
        (click)="openMobileSearchForm()"
      >
        <div
          *ngIf="filterForm.controls['query']?.value && !isSearchFieldOpened()"
          class="active-dot tw-absolute"
        ></div>
        <mat-icon svgIcon="search--design" />
      </div>

      <!-- Сортировки предметов -->
      <app-ui-selection-menu
        *ngIf="sortingMethods()"
        class="price-select trade-select-panel"
        [formClass]="'ui-form trade-select filter-selector'"
        [panelClass]="'ui-menu trade-select'"
        [selectionItems]="{ items: sortingMethodsArr(), activeIndex: sortingIndex() }"
        (activeTabChanged)="onSortingTypeChange($event)"
      />

      <ng-container
        *ngTemplateOutlet="
          resetBtnTemplate;
          context: {
            steamInventoryCachedAt: steamInventoryCachedAt()
          }
        "
      />
    </div>
  </div>
</form>

<!-- Большая панель всевозможных фильтров предметов -->
<app-ui-filter-panel
  *ngIf="isFilterPanelOpened()"
  class="marketFilter-extendedPanel tw-relative"
  [filterForm]="filterForm"
  [filterControls]="dformData"
/>
<!-- Reset filters btn template -->
<ng-template
  #resetBtnTemplate
  let-steamInventoryCachedAt="steamInventoryCachedAt"
>
  <div
    class="reset-btn filter-btn round-btn tw-flex tw-items-center tw-justify-center tw-cursor-pointer"
    [class.timer]="steamInventoryCachedAt && isSell()"
    [class.disabled]="isLoading()"
    (click)="refreshFilters(steamInventoryCachedAt)"
  >
    <mat-icon svgIcon="refresh-line" />
    <ng-container *ngIf="isSell() && steamInventoryCachedAt">
      <span>{{ steamInventoryCachedAt.timeToShow }} {{ steamInventoryCachedAt.localeSuff | translate }}</span>
    </ng-container>
  </div>
</ng-template>
