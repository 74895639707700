import { CurrencyPipe, NgForOf, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, inject, Input, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatIconModule } from '@angular/material/icon';
import { ICurrencyRate } from '@dev-fast/types';
import { combineLatest, Observable, tap } from 'rxjs';

import { CurrencyService } from '../currency.service';

const DEFAULT_KEY = 'fastcoins';
const EMPTY_STRING = '';

@Component({
  selector: 'app-currency',
  templateUrl: './currency.component.html',
  styleUrls: ['./currency.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatIconModule, NgIf, NgForOf],
})
/** Beta version: нативно переводы пока не делает но можно передать text через appCurrencyTranslate
 Нужно доработать верстку, сейчас сложно настроить размер и выравнивание иконки
 Чтобы донастроить размеры нужно переопрделить внутренние переменные*/
export class CurrencyComponent implements OnInit {
  readonly #currencyPipe: CurrencyPipe = inject(CurrencyPipe);
  readonly #currencyService: CurrencyService = inject(CurrencyService);
  readonly #cdr: ChangeDetectorRef = inject(ChangeDetectorRef);
  readonly #destroyRef = inject(DestroyRef);

  @Input() value: number | undefined;
  @Input() text: string | undefined;
  @Input() translate: string | undefined;
  @Input() translateParams: Record<string, any> | undefined;

  @Input() iconClass: string | undefined;
  @Input() customIcon: string | undefined;
  @Input() usePrefix = false;
  @Input() type: 'primary' | 'secondary' = 'primary';
  @Input() delimiter: 'comma' | 'dot' | 'space' = 'space';
  @Input() delimiterRest: '.' | ',' = '.'; // TODO переделать в человеческий вид

  @Input() set cKey(val: string | undefined) {
    if (val) {
      this.#prefix = this.#getPrefix(val);
      this.#cKey = val;
    }
  }
  @Input() currencyCode: string | undefined;
  @Input() display: 'code' | 'symbol' | 'symbol-narrow' | string | boolean = '';
  @Input() digitsInfo: string | undefined;
  @Input() locale: string | undefined;
  @Input() hidePrefix: boolean | undefined;
  @Input() translateKey = 'amount';

  readonly #delimitersDic = {
    comma: ',',
    dot: '.',
    space: ' ',
  };
  #cKey: string | undefined;
  #currency$: Observable<ICurrencyRate | null> = this.#currencyService.currency$;
  #currencyList$: Observable<ICurrencyRate[] | null> = this.#currencyService.currencyList$;
  #currencyList: ICurrencyRate[] | null | undefined;
  #prefix = '◎';

  currency: ICurrencyRate | null | undefined;
  get icon(): string {
    return this.customIcon || `coin-${this.type}`;
  }
  get isOnlyIcon(): boolean {
    return !this.text && !this.translate && this.value === undefined;
  }

  ngOnInit(): void {
    combineLatest([this.#currency$, this.#currencyList$])
      .pipe(
        takeUntilDestroyed(this.#destroyRef),
        tap(([currency, currencyList]) => {
          this.currency = currency;
          this.#currencyList = currencyList;
          this.#prefix = this.#getPrefix(this.#getRateKey(currency));
          this.#cdr.markForCheck();
        }),
      )
      .subscribe();
  }

  transform(): { rest?: string; sum: string } {
    if (this.currency === null || this.value === undefined) {
      return { sum: '0' };
    }

    const value = this.#transformByPipe(this.value);
    if (!value) {
      return { sum: '0' };
    }

    const { wholePart, decimalPart } = this.#splitAndParse(value, this.#prefix);

    return { sum: this.#formatSum(wholePart), rest: decimalPart };
  }

  transformText(text: string, currency: ICurrencyRate | null): string[] {
    if (currency === null) {
      return [''];
    }
    const hasPrefix = text.includes(this.#prefix);
    if (!hasPrefix || this.usePrefix) {
      return [text];
    }
    return text.split(this.#prefix);
  }
  #getPrefix(key: string): string {
    if (!this.#currencyList) {
      return '◎';
    }
    const currencyBycKey = this.#getCurrencyBycKey(key, this.#currencyList);
    const prefix = currencyBycKey?.prefix.trim() || (!this.hidePrefix && this.currency && this.currency.prefix) || '';
    return prefix || '◎';
  }
  #getRateKey(currency: ICurrencyRate | null | undefined): string {
    return this.#cKey ? this.#cKey : currency ? currency.key : 'fastcoins';
  }
  #convertValue = (number: number, cKey: string): number => {
    return this.#currencyService.convertBySelectedRateKey(cKey, number);
  };
  #getCurrencyBycKey = (cKey: string | undefined, currencyList: ICurrencyRate[] | null | undefined): ICurrencyRate | undefined => {
    return currencyList?.find((currency) => currency.key === cKey);
  };
  #splitAndParse(text: string, separator: string): { wholePart: string; decimalPart: string } {
    const [wholePart, decimalPart] = text.split('.');
    return { wholePart: wholePart, decimalPart };
  }
  #formatSum(sum: string): string {
    return sum.replace(/,/g, this.#delimitersDic[this.delimiter]).trim();
  }
  #transformValue(value: any): any {
    const result = this.#currencyPipe.transform(
      value,
      this.currencyCode || EMPTY_STRING,
      this.usePrefix ? this.#prefix : '',
      this.digitsInfo,
    );
    return result && result.endsWith('.00') ? result.slice(0, -3) : result;
  }
  #transformByPipe(value: number | undefined): string | null {
    if (value) {
      const tempValue = this.#convertValue(value, this.#cKey || DEFAULT_KEY);
      return this.#transformValue(tempValue);
    }
    return this.#currencyPipe.transform(0, this.currencyCode, this.usePrefix ? this.#prefix : this.display, this.digitsInfo, this.locale);
  }
}
