import { FormArray, FormControl, FormGroup } from '@angular/forms';

export type NullableForm<T> = {
  [P in keyof T]: T[P] extends 'object' ? FormGroup<NullableForm<T[P]>> : FormControl<T[P] | null>;
};
export type Form<T> = {
  [P in keyof T]: T[P] extends 'object' ? FormGroup<Form<T[P]>> : FormControl<T[P]>;
};
export type Controls<TAbstractControl> = TAbstractControl extends FormGroup<infer TControls>
  ? {
      [K in keyof TControls]: TControls[K];
    }
  : TAbstractControl extends FormArray<infer TControls>
  ? TControls[]
  : TAbstractControl extends FormControl
  ? TAbstractControl
  : never;
