import { Injectable } from '@angular/core';
import { IMarketplaceKitData, MarketPanel, ModalNames, NewPanel } from '@dev-fast/types';
import { Select } from '@ngxs/store';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { Observable } from 'rxjs';

import { ClosePanel } from '@app/core/state/layout';
import { OpenModal } from '@app/core/state/modals';
import {
  ChangeActiveTab,
  ConfirmBid,
  CreateBid,
  DeleteBid,
  GetMyBids,
  P2pBuyingState,
  RemoveItem,
  ToggleSelected,
  UpdateShowWarnValue,
} from '@app/core/state/p2p';

@Injectable()
export class P2pBuyingService {
  @Select(P2pBuyingState.purchasing)
  purchasing$!: Observable<IMarketplaceKitData[]>;
  @Select(P2pBuyingState.showWarn)
  showWarn$!: Observable<boolean>;

  addSelected(value: IMarketplaceKitData): void {
    this.toggleSelected(value);
  }

  @Dispatch() close = (idx: number): RemoveItem => new RemoveItem(idx);
  @Dispatch() deleted = (idx: number): DeleteBid => new DeleteBid(idx);
  @Dispatch() toggleSelected = (value: IMarketplaceKitData): ToggleSelected => new ToggleSelected(value);
  @Dispatch() createBid = (id: number): CreateBid => new CreateBid(id);
  @Dispatch() confirmBid = (id: number): ConfirmBid => new ConfirmBid(id);
  @Dispatch() initialize = (): GetMyBids => new GetMyBids();
  @Dispatch() updateShowWarnValue = (value: boolean): UpdateShowWarnValue => new UpdateShowWarnValue(value);
  @Dispatch() goToStoreMarketTab = (): ChangeActiveTab => new ChangeActiveTab(MarketPanel.Purchase);
  @Dispatch() closeMarketPanel = (): ClosePanel => new ClosePanel(NewPanel.TRADES);

  //Modals
  @Dispatch()
  openModal(modalName: ModalNames, payload?: IMarketplaceKitData): OpenModal {
    return new OpenModal(modalName, payload);
  }
}
