import { Injectable } from '@angular/core';
import {
  ICaseCollection,
  ICaseEventCollection,
  ICaseItemDetailedReq,
  ICaseItemDtoV2,
  ICaseOpenParams,
  ICaseOpenPrizeDataDto,
  ICaseRevisionInfo,
  ICasesFilter,
  IHistoryOfOpenCases,
  IMotivatorStatistic,
} from '@dev-fast/types';
import { Observable, shareReplay, tap } from 'rxjs';

import { Environments, EnvironmentService } from '@app/core/environment-service';

import { ApiService } from '../../api.service';

@Injectable({
  providedIn: 'root',
})
export class CasesBackendService {
  private environment: Environments;
  #getAvailabilityCache = new Map<string, Observable<ICaseItemDtoV2[]>>();

  constructor(
    private readonly api: ApiService,
    private readonly environmentService: EnvironmentService,
  ) {
    this.environment = this.environmentService.getEnvironment();
  }

  // eslint-disable-next-line @typescript-eslint/no-shadow
  getStat = (filter: { keys: string; limit?: number; caseId?: number }): Observable<IMotivatorStatistic> =>
    this.api.get(`${this.environment.GATEWAY_URL}/api/motivator/cases`, { params: filter });

  getCategoriesById = (casesFilter: ICasesFilter, id: number): Observable<ICaseCollection> => {
    return this.api.get(`${this.environment.GATEWAY_URL}/api/cases/categories/${id}`, {
      params: casesFilter,
      withCredentials: true,
    });
  };

  getCasesFromCategoryAll = (casesFilter: ICasesFilter): Observable<ICaseItemDtoV2[]> => {
    return this.api.get(`${this.environment.GATEWAY_URL}/api/cases/suitcases?enabled=true`, {
      params: casesFilter,
      withCredentials: true,
    });
  };

  getFreeCategory = (): Observable<ICaseCollection> => {
    return this.api.get(`${this.environment.GATEWAY_URL}/api/cases/free`);
  };

  getRoutingCategories = (): Observable<ICaseCollection[]> => {
    return this.api.get(`${this.environment.GATEWAY_URL}/api/cases/categories?detailed=true`, {
      withCredentials: true,
    });
  };

  getCasesEvents = (): Observable<ICaseEventCollection[]> => {
    return this.api.get(`${this.environment.GATEWAY_URL}/api/cases/events`, { withCredentials: true });
  };

  getCaseItemById = (id: number): Observable<ICaseItemDtoV2> =>
    this.api.get(`${this.environment.GATEWAY_URL}/api/cases/suitcases/${id}`, { withCredentials: true });

  toggleFavouriteCase = (id: number): Observable<void> => {
    return this.api.get(`${this.environment.GATEWAY_URL}/api/cases/suitcases/${id}/toggle-favorite`);
  };

  getSpinItems = (lines: number, caseId: number): Observable<Array<number[]>> =>
    this.api.get(`${this.environment.GATEWAY_URL}/api/cases/strip/calc?lines=${lines}&caseId=${caseId}`, {
      withCredentials: true,
    });

  getCaseOpeningHistory = (page: number): Observable<IHistoryOfOpenCases> =>
    this.api.get(`${this.environment.GATEWAY_URL}/api/cases/suitcases/history?page%5Bnumber%5D=${page}&page%5Bsize%5D=20`, {
      withCredentials: true,
    });

  getItemsFromOpenedCase = (params: ICaseOpenParams): Observable<ICaseOpenPrizeDataDto> =>
    this.api
      .post(
        `${this.environment.GATEWAY_URL}/api/cases/suitcases/${params.caseId}/open${params.gameType === 'demo' ? '/demo' : ''}`,
        { count: params.count, isFree: params.isFree, ticketColor: params.ticketColor },
        { withCredentials: true },
      )
      .pipe(
        tap((data: any) => {
          if (!this.environment.production) {
            // eslint-disable-next-line no-console
            console.log(JSON.stringify(data, null, 2));
          }
        }),
      );
  getReplays = (openUUID: string): Observable<ICaseOpenPrizeDataDto> =>
    this.api.get(`${this.environment.GATEWAY_URL}/api/cases/replay/${openUUID}`);

  getCaseRevisionById = (id: number, round: number): Observable<ICaseRevisionInfo> =>
    this.api.get(`${this.environment.GATEWAY_URL}/api/cases/suitcases/revision/${id}/${round}`);

  getAvailabilityItemInCases = (params: ICaseItemDetailedReq): Observable<ICaseItemDtoV2[]> => {
    const key = JSON.stringify(params);

    if (!this.#getAvailabilityCache.has(key)) {
      const observable = this.api
        .get<ICaseItemDtoV2[]>(`${this.environment.GATEWAY_URL}/api/cases/suitcases/item`, {
          withCredentials: true,
          params,
        })
        .pipe(shareReplay(1));

      this.#getAvailabilityCache.set(key, observable);
    }

    return this.#getAvailabilityCache.get(key) as Observable<ICaseItemDtoV2[]>;
  };

  getCasesMetaData = (): Observable<{ min: number; max: number }> =>
    this.api.get(`${this.environment.GATEWAY_URL}/api/cases/suitcases/price-range`, {
      withCredentials: true,
    });
}
