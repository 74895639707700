import { LotteryType } from '../enums';

export interface ILottery {
  type: LotteryType;
  gameEndTimestamp: Date;
  hash: string;
  rand: string | null;
  salt: string | null;
  id: number;
  gameInfo: ILotteryInfo;
  tickets: number;
  players: ILotteryPlayer[];
  playersNumber: number;
  winnerInfo: ILotteryWinner;
}
export interface ILotteryPlayer {
  id: number;
  ava_link: string;
}
export interface ILotteryWinner extends ILotteryPlayer {
  userName: string;
  tickets: ILotteryTicket[];
}
export interface ILotteryTicket {
  ticketsFrom: number;
  ticketsTo: number;
}

export interface ILotteryInfo {
  prize: number;
  prizeImage: string;
  prizeName: string;
  ticketPrice: number;
}
