import { animate, query, sequence, style, transition, trigger } from '@angular/animations';

export const tracksAnimation = trigger('tracksAnimation', [
  transition(':enter', [
    query(':self, .mobile', [
      style({ height: '0', opacity: 0 }),
      sequence([
        animate('150ms ease-in-out', style({ height: '*' })),
        animate('150ms 150ms ease-in-out', style({ opacity: 1 })),
      ]),
    ]),
    query(':self, .vertical', [
      style({ width: '0', opacity: 0 }),
      sequence([animate('150ms ease-in-out', style({ width: '100%', opacity: 1 }))]),
    ]),
  ]),
  transition(':leave', [
    query(':self, .mobile', [
      style({ height: '100%', opacity: 1 }),
      sequence([
        animate('150ms ease-in-out', style({ opacity: 0 })),
        animate('150ms 150ms ease-in-out', style({ height: '0' })),
      ]),
    ]),
    query(':self, .vertical', [
      style({ width: '100%', opacity: 1 }),
      sequence([animate('150ms ease-in-out', style({ width: '0', opacity: 0 }))]),
    ]),
  ]),
]);
