import { BaseType } from '../entities';

export const MIRRORS: BaseType[] = [
  {
    name: 'csgofastx.com',
    value: 'https://csgofastx.com',
  },
  {
    name: 'csgofast.com',
    value: 'https://csgofast.com',
  },
  {
    name: 'csgofast4.com',
    value: 'https://csgofast4.com',
  },
  {
    name: 'csgofast123.com',
    value: 'https://csgofast123.com',
  },
  {
    name: 'xcsgofast.com',
    value: 'https://xcsgofast.com',
  },
  {
    name: 'csgofast.tl',
    value: 'https://csgofast.tl',
  },
  {
    name: 'csgofast.gg',
    value: 'https://csgofast.gg',
  },
  {
    name: 'cs2gofast.com',
    value: 'https://cs2gofast.com',
  },
];
