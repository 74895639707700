import { IEmojiInfo } from '../entities';

export const battleEmojis: IEmojiInfo[] = [
  {
    name: 'HAPPY',
    src: 'happy-emoji',
    id: 1,
  },
  {
    name: 'CONFUSED',
    src: 'confused-emoji',
    id: 2,
  },
  {
    name: 'SURPRISED',
    src: 'surprised-emoji',
    id: 3,
  },
  {
    name: 'SAD',
    src: 'sad-emoji',
    id: 4,
  },
  {
    name: 'ANGRY',
    src: 'angry-emoji',
    id: 5,
  },
  {
    name: 'ARROGANT',
    src: 'arrogant-emoji',
    id: 6,
  },
  {
    name: 'SHOCKED',
    src: 'shocked-emoji',
    id: 7,
  },
  {
    name: 'SICK',
    src: 'sick-emoji',
    id: 8,
  },
  {
    name: 'GREED',
    src: 'greed-emoji',
    id: 9,
  },
  {
    name: 'EMBARRASSED',
    src: 'embarrassed-emoji',
    id: 10,
  },
  {
    name: 'KISS',
    src: 'kiss-emoji',
    id: 11,
  },
];
