interface IAnimKeyframes {
  value: number | string;
  duration?: number;
  delay?: number;
  easing?: string;
}
interface IAnimConfig {
  translateY: IAnimKeyframes[];
  translateX: IAnimKeyframes[];
}
export const casesAnimationConstructor = (
  trackIndex: number,
  fastAnimation: boolean,
  direction: 'horizontal' | 'vertical' | 'mobile',
  sizes: { cardSize: number; blockSize: number },
  lineLength: number,
  prizeOffset: number,
  complete: boolean
): IAnimConfig => {
  const duration = fastAnimation ? 1000 : 4000;
  const delay = (trackIndex + 1) * 75;
  const cardSize = direction === 'vertical' ? sizes.blockSize : sizes.cardSize * -1; // вычисляемый размер карточки (в ремах)
  const lengthInRem = cardSize * lineLength; // длина всей линии карточек (в ремах)
  const firstSidePosition = (lengthInRem - prizeOffset * cardSize + cardSize / 2) * -1; // позиция с одного края
  const secondSidePosition = prizeOffset * cardSize - cardSize / 2; // позиция с другого края

  const config: IAnimConfig = {
    translateY: [],
    translateX: [],
  };
  const droppingPosition = {
    value: `${direction === 'vertical' ? firstSidePosition : secondSidePosition}rem`,
    duration: 0,
  };
  const nullPosition = {
    value: 0,
    duration: 0,
  };
  const rafflePosition = {
    value: `${
      (direction === 'vertical' ? secondSidePosition : firstSidePosition) * -1 +
      (cardSize / 2 - Math.random() * cardSize)
    }rem`,
    duration: duration,
    delay: delay,
    easing: 'cubicBezier(0.2, 0, 0.15, 1)',
  };
  const victoryPosition = {
    value: `${(direction === 'vertical' ? secondSidePosition : firstSidePosition) * -1}rem`,
    duration: 450,
    easing: 'cubicBezier(0.85, 0.28, 0.67, 1.25)',
  };
  const outFromAnimation = {
    value: `${(direction === 'vertical' ? secondSidePosition : firstSidePosition) * -1}rem`,
    delay: delay,
  };
  if (complete) {
    if (direction === 'vertical') {
      config.translateX.push(nullPosition);
      config.translateY.push(droppingPosition, rafflePosition, victoryPosition, outFromAnimation);
    } else {
      config.translateY.push(nullPosition);
      config.translateX.push(droppingPosition, rafflePosition, victoryPosition, outFromAnimation);
    }
  } else {
    if (direction === 'vertical') {
      config.translateX.push(nullPosition);
      config.translateY.push(droppingPosition);
    } else {
      config.translateY.push(nullPosition);
      config.translateX.push(droppingPosition);
    }
  }
  return config;
};
export const caseBattleAnimationConstructor = (
  playerIndex: number,
  isMobile: boolean,
  lineLength: number,
  continueAnimation: boolean
): IAnimConfig => {
  const duration = 1300;
  const delay = (playerIndex + 1) * 75;
  const oneItemLenght = 100 / lineLength; // длина одного айтема
  const randomOffset = oneItemLenght / 2 - Math.random() * oneItemLenght;
  const defaultPosition = `-${oneItemLenght * (isMobile ? 2 : 1)}%`; // начальная позиция
  const finishPosition = 100 - oneItemLenght * (isMobile ? 3 : 4); //финишная позиция - 100% - 2 длины айтема
  const randomPosition = finishPosition + randomOffset; //финишная позиция - 100% - 2 длины айтема

  const config: IAnimConfig = {
    translateY: [],
    translateX: [],
  };
  const nullPosition = {
    value: 0,
    duration: 0,
  };
  const droppingPosition = {
    value: defaultPosition,
    duration: 0,
  };
  const rafflePosition = {
    value: `-${randomPosition}%`,
    duration: duration,
    delay: delay,
    easing: 'easeInOutExpo',
  };
  const victoryPosition = {
    value: `-${finishPosition}%`,
    duration: 450,
    easing: 'cubicBezier(0.20, 0.35, 0.67, 1.25)',
  };
  const breakPosition = {
    value: `-${finishPosition}%`,
    duration: 0,
  };
  if (isMobile) {
    config.translateX.push(nullPosition);
    if (!continueAnimation) {
      config.translateY.push(droppingPosition, rafflePosition, victoryPosition);
    } else {
      config.translateY.push(breakPosition);
    }
  }
  if (!isMobile) {
    config.translateY.push(nullPosition);
    if (!continueAnimation) {
      config.translateX.push(droppingPosition, rafflePosition, victoryPosition);
    } else {
      config.translateX.push(breakPosition);
    }
  }
  return config;
};
