import { DefaulSortingTypes, DefaultPriceFilters, DefaultSortMethods, ICaseItemDtoV2, ICasesFilter } from '@dev-fast/types';

import { CommonFiltersStateModel } from '../../../state';

export type CaseBattleCasesStateModel = CommonFiltersStateModel<ICasesFilter, DefaulSortingTypes> & {
  casesForSelect: ICaseItemDtoV2[];
};

export const CASE_BATTLE_CASES_INITIAL_STATE: CaseBattleCasesStateModel = {
  casesForSelect: [],

  filters: {
    sortBy: DefaulSortingTypes.DEFAULT,
    query: '',
    minPrice: 10,
  },
  sortingMethods: [...DefaultSortMethods],
  priceFilters: [...DefaultPriceFilters],
  priceRange: null,
};
