import { IHistoryParams, IInventoryRequestParams, IWithdrawalRequest, IWithdrawalRequestParams } from '@dev-fast/types';

export class SellItems {
  static readonly type = '[Inventory] Sell items';
  constructor(public ids: number[]) {}
}

export class SellAllItems {
  static readonly type = '[Inventory] Sell all items';
  constructor(
    public useTimer = false,
    public useFilters = false,
  ) {}
}

export class SellAllItemsSuccess {
  static readonly type = '[Inventory] Sell all items success';
  constructor(public itemsIds: number[]) {}
}

export class RemoveInventoryItems {
  static readonly type = '[Inventory] Remove Inventory Items';
  constructor(public itemsIds: number[]) {}
}

export class Purchase {
  static readonly type = '[Inventory] Purchase';
  constructor(
    public ids: number[],
    public userInventoryIds: number[],
  ) {}
}

export class Trade {
  static readonly type = '[Inventory] Trade';
}

export class GetInventoryItems {
  static readonly type = '[Inventory] Get inventory items';
  constructor(
    public params?: any,
    public buffer = false,
  ) {}
}

export class GetInventoryInfo {
  static readonly type = '[Inventory] Get inventory info';
}

export class GetShopItems {
  static readonly type = '[Inventory] Get shop items';
  constructor(public design?: 'old' | 'new') {}
}
export class ChangeGamesItemsStatus {
  static readonly type = '[Inventory] Change item status';
  constructor(public ids: number[]) {}
}
export class ChangeParamsShop {
  static readonly type = '[Inventory] Change Params Shop';
  constructor(
    public params: IInventoryRequestParams,
    public design?: 'old' | 'new',
  ) {}
}

export class ChangeParamsInventory {
  static readonly type = '[Inventory] Change Params Inventory';
  constructor(public params: IInventoryRequestParams) {}
}

export class ClickOnShopItem {
  static readonly type = '[Inventory] Click On Shop Item';
  constructor(public id: number) {}
}

export class ClickOnInventoryItem {
  static readonly type = '[Inventory] Click On Inventory Item';
  constructor(public id: number) {}
}

export class ToggleAllInventoryItems {
  static readonly type = '[Inventory] Toggle All Inventory Items';
}
export class ToggleGameStatus {
  static readonly type = '[Inventory] Toggle game progress status';
  constructor(public gameInProgress: boolean) {}
}

export class UnselectInventoryItemById {
  static readonly type = '[Inventory] Unselect Inventory Item By Id';
  constructor(public ids: number[]) {}
}

export class FreezeParticipatedItems {
  static readonly type = '[Inventory] Freeze Participated Items';
  constructor(public ids: number[]) {}
}

export class RequestInventoryHistory {
  static readonly type = '[Inventory] request inventory history';
}

export class InventoryHistoryLoading {
  static readonly type = '[Inventory] inventory history loading';
}

export class InventoryHistoryLoaded {
  static readonly type = '[Inventory] inventory history loaded';
}

export class Refresh {
  static readonly type = '[Inventory] refresh';
  constructor(public payload: 'inventory' | 'trade') {}
}

export class ChangeInventoryPage {
  static readonly type = '[Inventory] change inventory page';
  constructor(public page: number) {}
}

export class ChangeShopPage {
  static readonly type = '[Inventory] change shop page';
  constructor(public page: number) {}
}

export class UnselectItems {
  static readonly type = '[Inventory] unselect items';
  constructor(public payload?: 'shop' | 'inventory') {}
}

export class ChangeHistoryParams {
  static readonly type = '[Inventory] change history params';
  constructor(public payload: IHistoryParams) {}
}

export class ToggleIsSelectAll {
  static readonly type = '[Inventory] toggle select all items';
}

export class FreezeItems {
  static readonly type = '[Inventory] freeze items';
  constructor(public ids: number[]) {}
}

export class InventoryItemsLoaded {
  static readonly type = '[Inventory] items loaded';
}

export class InventoryItemsLoad {
  static readonly type = '[Inventory] items loading';
}

export class ApplyInventoryFilters {
  static readonly type = '[Inventory] apply inventory filters';
}

export class WithdrawItems {
  static readonly type = '[Inventory] withdraw inventory items';
  constructor(public withdrawalRequest: IWithdrawalRequest) {}
}

export class GetWithdrawalItems {
  static readonly type = '[Inventory] get withdrawal inventory items';
  constructor(public params: IWithdrawalRequestParams) {}
}

export class InventoryActionsDisable {
  static readonly type = '[Inventory] inventory actions disable';
}

export class InventoryActionsEnable {
  static readonly type = '[Inventory] inventory actions enable';
}

export class ClearPreSearchItemsLength {
  static readonly type = '[Inventory] clear filter pre-search items length';
}

export class ClearTotalSum {
  static readonly type = '[Inventory] clear totalSum';
}

export class UpdateTotalSum {
  static readonly type = '[Inventory] update totalSum';
}
