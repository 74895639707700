<div class="sih-banner__icon">
  <ng-container *ngIf="isFirstStep(); else secondStepIconTemplate">
    <app-steam-double-icon />
  </ng-container>
  <ng-template #secondStepIconTemplate>
    <app-sih-custom-icon [subIcon]="'warning-circle'" />
  </ng-template>
</div>
<div class="sih-banner__content">
  <div class="sih-banner__text">
    <div class="sih-banner__header">
      {{ 'SIH.REFILL.HEADER' | translate }}
    </div>
    <div class="sih-banner__description">
      <ng-container *ngIf="isFirstStep(); else secondStepTextTemplate">
        {{ 'SIH.REFILL.BODY' | translate }}
        <a
          class="sih-link"
          [href]="sihLink"
          target="_blank"
        >
          {{ 'SIH.REFILL.SIH' | translate }}
        </a>
      </ng-container>
      <ng-template #secondStepTextTemplate>
        {{ 'SIH.REFILL.SECOND_BODY' | translate }}
        <a
          class="sih-link"
          [href]="sihLink"
          target="_blank"
        >
          {{ 'SIH.REFILL.SIH' | translate }}
        </a>
      </ng-template>
    </div>
  </div>
  <div class="sih-banner__actions">
    <ng-container *ngIf="isFirstStep(); else secondStepActionsTemplate">
      <a
        class="btn btn-primary sih-button"
        [href]="sihLink"
        target="_blank"
      >
        {{ 'SIH.REFILL.INSTALL' | translate }}
      </a>
      <button
        class="btn btn-outline-blue--design refresh-sih-button"
        [disabled]="loading()"
        (click)="onCheckSihInstall()"
      >
        <ng-container *ngIf="!loading(); else loadingTmpl">
          {{ 'STEAM_PANEL.UPDATE' | translate }}
        </ng-container>
        <ng-template #loadingTmpl>
          <mat-icon
            class="refresh-line loading"
            svgIcon="refresh-line"
          />
        </ng-template>
      </button>
    </ng-container>
    <ng-template #secondStepActionsTemplate>
      <button
        class="btn btn-primary sih-button"
        [disabled]="loading()"
        (click)="onRequestPermissions()"
      >
        {{ 'SIH.REFILL.VERIFY' | translate }}
        <ng-container *ngIf="loading()">
          <div class="tw-absolute tw-top-0 tw-bottom-0 tw-w-full tw-flex tw-items-center tw-justify-center loader">
            <mat-icon
              class="refresh-line loading"
              svgIcon="refresh-line"
            />
          </div>
        </ng-container>
      </button>
      <button
        class="btn btn-outline-blue--design refresh-sih-button"
        [disabled]="loading()"
        (click)="onRequestPermissions()"
      >
        {{ 'SIH.REFILL.SEND_REQUEST' | translate }}
      </button>
    </ng-template>
  </div>
</div>
