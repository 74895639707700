<div
  class="balance-widget"
  *ngIf="user"
>
  <div class="balance-widget__currency-demo">{{ user.demoCoinsAmount | appCurrency }}</div>
  <div class="balance-widget__currency-skins">
    <div></div>
    <div>150</div>
  </div>
  <app-ui-balance-panel
    [coinsAmount]="user.coinsAmount"
    [view]="view"
    class="balance-widget__refill"
  ></app-ui-balance-panel>
</div>
