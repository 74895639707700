import { inject, Injectable } from '@angular/core';
import { LocalizationApiService } from '@dev-fast/backend-services';
import { FaqStateItem, IFaq, IFaqArticleItems, QuickFaqName } from '@dev-fast/types';
import { RouterNavigation } from '@ngxs/router-plugin';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { Observable, of, switchMap, tap, timer } from 'rxjs';

import { LanguageState, SetLanguage } from '@app/core/language-service';
import { LocalStorageService } from '@app/core/local-storage-service';
import { RouterStateParams } from '@app/core/state/utils';

import { ChangeIsActive, GetItems, QuickFaqCheckStatus, QuickFaqSetStatus } from './faq.actions';
import { FAQ_INITIAL_STATE, FaqStateModel } from './faq-state.model';

@State<FaqStateModel>({
  name: 'faq',
  defaults: FAQ_INITIAL_STATE,
})
@Injectable()
export class FaqState {
  readonly #store = inject(Store);
  readonly #storage = inject(LocalStorageService);
  readonly #apiService = inject(LocalizationApiService);

  @Selector() static items({ items }: FaqStateModel): FaqStateItem[] {
    return items;
  }
  @Selector() static activeSection({ activeSection }: FaqStateModel): string {
    return activeSection;
  }
  @Selector() static activePosition({ activePosition }: FaqStateModel): string | null {
    return activePosition;
  }
  @Selector() static currentLangFaq({ currentLangFaq }: FaqStateModel): IFaq[] {
    return currentLangFaq;
  }
  @Selector() static quickFaqStatuses({ quickFaqStatuses }: FaqStateModel): Partial<Record<QuickFaqName, boolean>> | undefined {
    return quickFaqStatuses;
  }
  @Selector() static selectedItem({ activePosition, activeSection, currentLangFaq }: FaqStateModel): IFaqArticleItems | undefined {
    if (!currentLangFaq) {
      return;
    }
    const section = currentLangFaq.find((item) => item.url === activeSection) || currentLangFaq[0];
    return section.faq_items.find((item) => {
      if (activePosition) {
        return item.identifier?.length ? item.identifier === activePosition : item.position === Number(activePosition);
      }
      return item.position === 0;
    });
  }
  @Selector()
  static filteredItems({ currentLangFaq }: FaqStateModel) {
    return (query: string | null): IFaq[] | undefined => {
      if (!query) {
        return currentLangFaq;
      }
      const lowerCaseQuery = query.toLowerCase();
      const filteredItems = currentLangFaq.filter(
        (item) =>
          item.title.toLowerCase().includes(lowerCaseQuery) ||
          item.faq_items.some((faqItem) => faqItem.item_title.toLowerCase().includes(lowerCaseQuery)),
      );
      return filteredItems.length ? filteredItems : undefined;
    };
  }

  ngxsOnInit({ patchState }: StateContext<FaqStateModel>): void {
    const quickFaqStatuses = this.#storage.get('quickFaq');
    patchState({
      quickFaqStatuses,
    });
  }

  @Action(QuickFaqSetStatus)
  quickFaqSetStatus({ patchState, getState }: StateContext<FaqStateModel>, { payload }: QuickFaqSetStatus): void {
    const { quickFaqStatuses } = getState();
    const updatedStatuses = { ...quickFaqStatuses, [payload.menuName]: payload.wasClosed };
    patchState({
      quickFaqStatuses: updatedStatuses,
    });
    this.#storage.set('quickFaq', updatedStatuses);
  }
  @Action(QuickFaqCheckStatus)
  checkStatus({ getState, patchState, dispatch }: StateContext<FaqStateModel>, { menuName }: QuickFaqCheckStatus): void {
    const { quickFaqStatuses } = getState();
    const faqItemsIsLoad = !!this.#store.selectSnapshot(FaqState.currentLangFaq).length;
    const getItems = (): void => {
      dispatch(new GetItems());
    };
    if (faqItemsIsLoad) {
      return;
    }

    if (!quickFaqStatuses || (quickFaqStatuses && !quickFaqStatuses[menuName])) {
      getItems();
    }
  }
  @Action(SetLanguage)
  setLanguage({ getState, dispatch }: StateContext<FaqStateModel>, { locale }: SetLanguage): void {
    const { isActive } = getState();
    if (isActive) {
      dispatch(new GetItems({ lang: locale.path }));
    }
  }
  @Action(ChangeIsActive)
  changeIsActive({ patchState }: StateContext<FaqStateModel>, { isActive }: ChangeIsActive): void {
    patchState({ isActive });
  }
  @Action(RouterNavigation)
  navigate({ patchState }: StateContext<FaqStateModel>, { routerState }: RouterNavigation<RouterStateParams>): void {
    const { params } = routerState;
    if (routerState.url.includes('/faq')) {
      const section: string | undefined = params['section'];
      const position: string | undefined = params['position'];
      patchState({ activeSection: section ? section : '', activePosition: position ? position : null });
    }
  }
  @Action(GetItems, { cancelUncompleted: true })
  get({ getState, patchState, dispatch }: StateContext<FaqStateModel>, { payload }: GetItems): Observable<IFaq[]> {
    // FIXME не очень мне нравится, но пока так
    return timer(500).pipe(
      switchMap(() => {
        const { items } = getState();
        const langInStore = this.#store.selectSnapshot(LanguageState.lang).path;
        const reqLang = payload?.lang ? payload.lang : langInStore;
        const index = items.findIndex((val) => val.lang === reqLang);

        if (index !== -1) {
          const currentLangFaq = items[index].value;
          patchState({
            currentLangFaq,
          });
          return of([]);
        }

        return this.#apiService.getFAQ(reqLang).pipe(
          tap((value) => {
            patchState({
              items: [...items, { lang: reqLang, value }],
              currentLangFaq: value,
            });
          }),
        );
      }),
    );
  }
}
