import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { IFilterFormTemplate } from '@dev-fast/types';

@Component({
  selector: 'app-ui-filter-slider',
  templateUrl: './filter-slider-range.component.html',
  styleUrls: ['./filter-slider-range.component.scss'],
})
export class FilterSliderRangeComponent {
  @Input() public filterForm: FormGroup<IFilterFormTemplate> | undefined;
  @Input() public control!: any;
  @Input() public mobileView = false;

  public formatLabel(value: number): string {
    return value + ' %';
  }
}
