import { QuickFaqName } from '@dev-fast/types';

export class GetItems {
  static readonly type = '[Faq] Get items';
  constructor(public payload?: { activeMenu?: string; lang?: string }) {}
  // constructor(public lang?: string) {}
}
export class ChangeIsActive {
  static readonly type = '[Faq] Change Is Active';
  constructor(public isActive: boolean) {}
}
export class QuickFaqSetStatus {
  static readonly type = '[Quick Faq] set status';
  constructor(public payload: { menuName: QuickFaqName; wasClosed: boolean }) {}
}
export class QuickFaqCheckStatus {
  static readonly type = '[Quick Faq] check status';
  constructor(public menuName: QuickFaqName) {}
}
