<ng-container *ngIf="storeForm">
  <form
    class="inventory"
    [formGroup]="storeForm"
  >
    <div class="exchange-panel">
      <div class="control-panel">
        <ng-container *ngIf="tradeBalance">
          <div class="resume-container">
            <div class="resume-body">
              <div class="result-price-panel__wrapper">
                <div class="exchange-panel__balance-column give-box">
                  <div class="exchange-panel__balance-title">
                    {{ 'TRADE_PANEL.YOU_WILL_SPEND' | translate }}
                  </div>
                  <app-ui-increase
                    [className]="'exchange-panel__give-price'"
                    [valueType]="'currency'"
                    [setCurValue]="tradeBalance.selectedItemsSum"
                  >
                  </app-ui-increase>
                  <!-- <div class="exchange-panel__balance-value">
                    {{ tradeBalance.selectedItemsSum | appCurrency }}
                  </div> -->
                </div>
                <div class="exchange-panel__balance-column take-box">
                  <div class="exchange-panel__balance-title">
                    {{ 'TRADE_PANEL.YOU_WILL_GET' | translate }}
                  </div>
                  <app-ui-increase
                    [className]="'exchange-panel__take-price'"
                    [valueType]="'currency'"
                    [setCurValue]="tradeBalance.selectedShopItemsSum"
                  >
                  </app-ui-increase>
                  <!-- <div class="exchange-panel__balance-value">
                    {{ tradeBalance.selectedShopItemsSum | appCurrency }}
                  </div> -->
                </div>
              </div>
              <div class="accept-panel__wrapper">
                <div class="accept-info">
                  <span class="accept-panel__balance-title">
                    <!--          FIXME Поменять ключ с текстом на Balance-->
                    {{ 'TRADE_PANEL.SUBTOTAL' | translate }}:
                  </span>
                  <app-ui-increase
                    [className]="'accept-panel__total-price'"
                    [valueType]="'currency'"
                    [setCurValue]="tradeBalance.tradeBalance"
                  >
                  </app-ui-increase>
                  <!-- <span class="exchange-panel__balance-value"> {{
                    tradeBalance.tradeBalance | appCurrency }} </span> -->
                </div>
                <button
                  class="btn btn-primary btn-semi-round lg"
                  [disabled]="tradeBalance.selectedItemsSum === 0 && tradeBalance.selectedShopItemsSum === 0"
                  (click)="onCreateTrade()"
                >
                  {{ 'TRADE_PANEL.CONFIRM_ACTION' | translate | titlecase }}
                </button>
              </div>
            </div>
          </div>
        </ng-container>
        <div class="filters exchange-panel__filters filters-container">
          <div class="left-filters-container">
            <label class="filters__link-wrapper">
              <span class="filters__link">{{ 'CONTRACT_PANEL.PRICE' | translate }}</span>
              <input
                type="checkbox"
                formControlName="sortBy"
              />
              <mat-icon
                class="filters__link_icon"
                svgIcon="arrow-up"
              ></mat-icon>
            </label>
            <input
              type="text"
              class="filters__input"
              [placeholder]="'TRADE_PANEL.SEARCH_PLACEHOLDER' | translate"
              formControlName="query"
            />
          </div>
          <div class="right-filters-container">
            <div class="range-wrapper">
              <input
                type="number"
                class="filters__input"
                [placeholder]="'TRADE_PANEL.MIN_PRICE' | translate"
                formControlName="minPrice"
              />
              <input
                type="number"
                class="filters__input"
                [placeholder]="'TRADE_PANEL.MAX_PRICE' | translate"
                formControlName="maxPrice"
              />
            </div>
            <div
              class="btn btn-semi-round btn-transparent-dark--design"
              (click)="onRefresh()"
            >
              <mat-icon
                class="icon sm"
                svgIcon="update-arrows"
              ></mat-icon>
            </div>
          </div>
        </div>
      </div>
      <div class="items-container">
        <ng-container *ngIf="loading; then loadingTemplate; else loadedTemplate"> </ng-container>
        <ng-template #loadingTemplate>
          <app-ui-cube-grid-loader
            class="inventory-loader-wrapper"
            [size]="'5rem'"
            [color]="'var(--color-gray-200)'"
          >
          </app-ui-cube-grid-loader>
        </ng-template>
        <ng-template #loadedTemplate>
          <ng-container *ngIf="shopItems.length; else emptyTemplate">
            <ng-scrollbar
              [autoHeightDisabled]="true"
              visibility="hover"
              (reachedBottom)="onBottomReached()"
              [reachedOffset]="200"
            >
              <div class="exchange-panel__items-list items-wrapper">
                <app-ui-skin-item-new
                  *ngFor="let item of shopItems"
                  [name]="item.name"
                  [icon]="item.icon"
                  [color]="item.color"
                  [price]="item.price"
                  [backgroundColor]="'var(--color-theme-500)'"
                  [id]="item.id"
                  [isSelect]="isSelected(item.id)"
                  (skinClick)="clickOnSkinEmit.emit($event)"
                >
                </app-ui-skin-item-new>
              </div>
            </ng-scrollbar>
          </ng-container>
          <ng-template #emptyTemplate>
            <div class="inventory-empty-wrapper">
              <h5 class="inventory-empty-header">
                {{ 'No items found' | translate }}
              </h5>
              <p class="inventory-empty-description">
                {{ 'Change your filter preferences' | translate }}
              </p>
            </div>
          </ng-template>
        </ng-template>
      </div>
      <div
        class="pagination-container"
        *ngIf="meta"
      >
        <app-ui-pagination
          [maxPage]="totalPage()"
          [minPage]="1"
          (onpageChange)="pageChange($event)"
        >
        </app-ui-pagination>
      </div>
    </div>
  </form>
</ng-container>
<ng-container *ngIf="validatePremium(user)">
  <div class="account-requirement-container">
    <div class="account-requirement-wrapper">
      <div class="account-requirement-icon">
        <mat-icon svgIcon="account-minimal"></mat-icon>
      </div>
      <div class="account-requirement-description">
        {{ 'BET_PANEL.PREVENT_DESCRIPTION' | translate : { amount: 250 | appCurrency } }}
      </div>
      <a
        langRouterLink
        [routerLink]="['refill']"
        class="btn btn-primary"
      >
        {{ 'Refill' | translate }}
      </a>
    </div>
  </div>
</ng-container>
