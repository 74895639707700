<ng-container *ngIf="onlyText; else styledTemplate">
  <ng-container *ngIf="showDays">
    <ng-container *ngIf="days$ | async as days">
      <ng-container *ngFor="let day of days">
        <span>{{ day }}</span>
      </ng-container>
    </ng-container>
    <span class="delimiter">{{ ' : ' }}</span>
  </ng-container>

  <ng-container *ngIf="showHours">
    <ng-container *ngIf="hours$ | async as hours">
      <ng-container *ngFor="let hour of hours">
        <span>{{ hour }}</span>
      </ng-container>
    </ng-container>
    <span class="delimiter">{{ ' : ' }}</span>
  </ng-container>

  <ng-container *ngIf="minutes$ | async as minutes">
    <ng-container *ngFor="let minute of minutes">
      <span> {{ minute }}</span>
    </ng-container>
  </ng-container>
  <span class="delimiter">{{ ' : ' }}</span>

  <ng-container *ngIf="seconds$ | async as seconds">
    <ng-container *ngFor="let second of seconds">
      <span>{{ second }}</span>
    </ng-container>
  </ng-container>
</ng-container>
<ng-template #styledTemplate>
  <ng-container *ngIf="showDays">
    <ng-container
      *ngTemplateOutlet="
        daysTpl ? daysTpl : defaultDigitTmpl;
        context: {
          digits: days$ | async,
          sign: 'LOCAL.CASES.SERVICE_PAGES.PROMO_TIMER_DURATION'
        }
      "
    >
    </ng-container>
  </ng-container>
  <ng-container *ngIf="showHours">
    <ng-container
      *ngTemplateOutlet="
        hoursTpl ? hoursTpl : defaultDigitTmpl;
        context: {
          digits: hours$ | async,
          sign: 'LOCAL.CASES.SERVICE_PAGES.PROMO_TIMER_DURATION_HOURS'
        }
      "
    >
    </ng-container>
  </ng-container>
  <ng-container *ngIf="showMinutes">
    <ng-container
      *ngTemplateOutlet="
        minutesTpl ? minutesTpl : defaultDigitTmpl;
        context: {
          digits: minutes$ | async,
          sign: 'LOCAL.CASES.SERVICE_PAGES.PROMO_TIMER_DURATION_MINUTES'
        }
      "
    >
    </ng-container>
  </ng-container>
  <ng-container *ngIf="showSeconds">
    <ng-container
      *ngTemplateOutlet="
        secondsTpl ? secondsTpl : defaultDigitTmpl;
        context: {
          digits: seconds$ | async,
          sign: 'LOCAL.CASES.SERVICE_PAGES.PROMO_TIMER_DURATION_SECONDS',
          noDelimiter: true
        }
      "
    >
    </ng-container>
  </ng-container>
</ng-template>
<ng-template
  #defaultDigitTmpl
  let-digits="digits"
  let-sign="sign"
  let-noDelimiter="noDelimiter"
>
  <div class="countdown-item-box">
    <div
      class="countdown-item"
      *ngIf="digits"
    >
      <div
        class="countdown-item-digit"
        *ngFor="let digit of digits"
      >
        {{ digit }}
      </div>
    </div>
    <div
      *ngIf="viewSign"
      class="time-sign"
    >
      {{ sign | translate | titlecase }}
    </div>
  </div>
  <div
    class="countdown-item-digit"
    *ngIf="!noDelimiter"
  >
    :
  </div>
</ng-template>
