import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CurrencyFormatDirective } from './currency-format.directive';

@NgModule({
  declarations: [CurrencyFormatDirective],
  imports: [CommonModule],
  exports: [CurrencyFormatDirective],
})
export class CurrencyFormatModule {}
