import { NgIf } from '@angular/common';
import { Component, computed, inject, Input } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { map } from 'rxjs/operators';

import { DefaultSihModalTranslations } from '../../model';
import { SihService } from '../../sih.service';
import { SihCustomIconComponent } from '../sih-custom-icon/sih-custom-icon.component';

@Component({
  selector: 'app-sih-check-button',
  standalone: true,
  imports: [MatIconModule, TranslateModule, NgIf, SihCustomIconComponent],
  providers: [SihService],
  templateUrl: './sih-check-button.component.html',
  styleUrls: ['./sih-check-button.component.scss'],
})
export class SihCheckButtonComponent {
  readonly #sihService = inject(SihService);

  @Input() availableItems = 0;

  hasSih = toSignal(this.#sihService.sih$.pipe(map((sih) => !!sih && sih.online && sih.permission)));
  user = toSignal(this.#sihService.user$);
  totalItems = computed(() => (this.hasSih() ? this.availableItems : Math.ceil(this.availableItems + this.availableItems * 0.1)));
  onOpenSihInstallModal(): void {
    this.#sihService.openSihInstallModal({ ...DefaultSihModalTranslations, body: 'SIH.FIRST_MODAL.BODY_BUY', body_2: '' });
  }
}
