export enum LayoutPanelEnum {
  CLOSE = '',
  MAIN_MENU = 'main_menu',
  LANGUAGE_MENU = 'language_menu',
  INVENTORY_MENU = 'inventory_menu',
}

export enum ViewportType {
  MOBILE = 'mobile',
  TABLET = 'tablet',
  DESKTOP = 'desktop',
}
