<div
  class="icon"
  *ngIf="icon"
>
  <mat-icon [svgIcon]="icon"> </mat-icon>
</div>
<div
  class="title"
  *ngIf="title"
>
  {{ title }}
</div>
