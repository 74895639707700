<div
  class="event-menu__container"
  *ngIf="eventsList && eventBtn.key !== ''"
  [@eventMenuHover]="hovered"
  (mouseenter)="hovered = true"
  (mouseleave)="hovered = false"
>
  <app-ui-event-menu-item
    class="event-menu__container__icon"
    [event]="eventBtn"
  ></app-ui-event-menu-item>

  <div
    class="event-menu__container__body event-menu__animation-body"
    *ngIf="hovered"
  >
    <app-ui-event-menu-item
      *ngFor="let event of eventsList"
      class="menu-item__animation"
      [event]="event"
    ></app-ui-event-menu-item>
  </div>
</div>
