import { HttpErrorResponse } from '@angular/common/http';
import { inject, Injectable, Optional } from '@angular/core';
import { UserApiService } from '@dev-fast/backend-services';
import {
  EmailSubscribe,
  INotification,
  IUserDetailed,
  IUserExperience,
  IUserP2pPermissionInterface,
  IUserSettings,
  IUserSettingsDetailed,
  IUserTradeStats,
  IUserWallet,
  MIN_TRADE_DURATION,
  ModalNames,
  Permissions,
  ProfileTypes,
  RequestState,
  SteamErrorsEnum,
  SteamErrorsEnumLocales,
  UserCountry,
  WalletTypeEnum,
} from '@dev-fast/types';
import { Navigate } from '@ngxs/router-plugin';
import { Action, NgxsOnInit, Selector, State, StateContext, Store } from '@ngxs/store';
import { patch, updateItem } from '@ngxs/store/operators';
import * as Sentry from '@sentry/angular-ivy';
import { Socket as WrappedSocket } from 'ngx-socket-io';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';

import { AnalyticsService } from '@app/core/analytics-service';
import { ChatService } from '@app/core/chatra-service';
import { EnvironmentService } from '@app/core/environment-service';
import { ChangeFrameLocalStorage, FrameMessageTypes, IFrameMessageService, IFrameState } from '@app/core/iframe';
import { LocalStorageService } from '@app/core/local-storage-service';
import { NotificationsService } from '@app/core/notification-service';
import { CloseModal, OpenModal } from '@app/core/state/modals';

import {
  ChangeAdditionalSettings,
  ConfirmEmail,
  DemandSelfExclusion,
  GetAdditionalSettings,
  GetExperience,
  GetP2pPermissions,
  GetUser,
  GetUserCountry,
  GetUserTradeStats,
  GetUserWallets,
  Init,
  InitError,
  InitSuccess,
  RefreshCurrentUser,
  SwitchWalletType,
  SwitchWalletTypeComplete,
  Unsubscribe,
  UpdateAccountType,
  UpdateAvatar,
  UpdateCoinsAmount,
  UpdateCurrentUser,
  UpdateDemoCoinsAmount,
  UpdateEmail,
  UpdateXp,
  UpdUserWallets,
} from './user.actions';
import { USER_INITIAL_STATE, USER_STATE_UNDEFINED, UserStateModel } from './user-state.model';

@State<UserStateModel>({
  name: 'user',
  defaults: USER_INITIAL_STATE,
})
@Injectable()
export class UserState implements NgxsOnInit {
  readonly #store = inject(Store);
  readonly #storageService = inject(LocalStorageService);
  readonly #environmentService = inject(EnvironmentService);
  readonly #wrappedSocket = inject(WrappedSocket);
  readonly #userApiService = inject(UserApiService);
  readonly #analyticsService = inject(AnalyticsService);
  readonly #notificationsService = inject(NotificationsService);
  readonly #chatService = inject(ChatService);

  constructor(@Optional() private readonly frameMessageService: IFrameMessageService) {}

  @Selector()
  static p2pPermissions({ p2pPermissions }: UserStateModel): IUserP2pPermissionInterface | null {
    return p2pPermissions;
  }
  @Selector()
  static isUserBanned({ user }: UserStateModel): boolean {
    return !!user?.permLock;
  }
  @Selector()
  static permissions({ permissions }: UserStateModel): Permissions[] {
    return permissions;
  }

  @Selector()
  static additionalSettings({ additionalSettings }: UserStateModel): IUserSettingsDetailed | null {
    return additionalSettings;
  }

  @Selector()
  static user({ user }: UserStateModel): IUserDetailed | null | undefined {
    return user;
  }

  @Selector()
  static userCountry({ country }: UserStateModel): UserCountry | null {
    return country;
  }
  @Selector()
  static userWallets({ wallets }: UserStateModel): IUserWallet[] {
    return wallets;
  }
  @Selector()
  static userWallet(state: UserStateModel) {
    return (id: WalletTypeEnum) => {
      const wallet = state.wallets.find((item) => item.walletType.id === id);
      return wallet ? wallet.balance : 0;
    };
  }
  @Selector() // TODO: думаю логику лучше будет сюда перенести из юзер сервиса
  static tradelinkIsValid({ p2pPermissions }: UserStateModel): boolean {
    if (!p2pPermissions) {
      return false;
    }
    if (p2pPermissions.error) {
      return ![SteamErrorsEnum.INVALID_TRADELINK, SteamErrorsEnum.NO_TRADE_LINK, SteamErrorsEnum.NO_TRADE_TOKEN].includes(
        p2pPermissions.error,
      );
    } else {
      return !!(
        p2pPermissions.canTrade?.success &&
        ![SteamErrorsEnum.INVALID_TRADELINK, SteamErrorsEnum.NO_TRADE_LINK, SteamErrorsEnum.NO_TRADE_TOKEN].includes(
          p2pPermissions.canTrade.error,
        )
      );
    }
  }
  @Selector() // TODO: думаю логику лучше будет сюда перенести из юзер сервиса
  static apikeyIsValid({ p2pPermissions }: UserStateModel): boolean {
    if (!p2pPermissions) {
      return false;
    }
    if (p2pPermissions.error) {
      return ![SteamErrorsEnum.NO_API_KEY, SteamErrorsEnum.INVALID_API_KEY, SteamErrorsEnum.FAKE_API_KEY].includes(p2pPermissions.error);
    } else {
      return (
        !!p2pPermissions.canSteamAPI?.success &&
        ![SteamErrorsEnum.NO_API_KEY, SteamErrorsEnum.INVALID_API_KEY, SteamErrorsEnum.FAKE_API_KEY].includes(
          p2pPermissions.canSteamAPI.error,
        )
      );
    }
  }
  ngxsOnInit({ dispatch, patchState }: StateContext<UserStateModel>): void {
    // нужно, чтобы при логауте значение поля юзера устанавливать в null с помощью StateReset
    patchState({
      user: USER_STATE_UNDEFINED,
    });

    if (this.frameMessageService) {
      this.frameMessageService.on(
        FrameMessageTypes.MESSAGE_FROM_BB_SOCKET,
        'wallet-new.switch',
        (payload: { success: boolean; walletType: number }) => dispatch(new SwitchWalletTypeComplete(payload)),
      );
      this.frameMessageService.on(FrameMessageTypes.MESSAGE_FROM_BB, 'level-up', () => dispatch(new GetExperience()));
      this.frameMessageService.on(FrameMessageTypes.MESSAGE_FROM_BB, 'open self exclusion modal', (res) =>
        dispatch(new OpenModal(ModalNames.SELF_EXCLUSION, res)),
      );
    }
    /////// refactor need
    this.#wrappedSocket.on('wallet-new.switch', (payload: { success: boolean; walletType: number }) => {
      const frameLoaded = this.#store.selectSnapshot(IFrameState.loaded);
      if (!frameLoaded) {
        dispatch(new SwitchWalletTypeComplete(payload));
      }
    });
    this.#wrappedSocket.on('user.coinsAmount.updated', (coinsAmount: number) => dispatch(new UpdateCoinsAmount(coinsAmount)));
    this.#wrappedSocket.on('user.demoCoinsAmount.updated', (demoCoinsAmount: number) =>
      dispatch(new UpdateDemoCoinsAmount(demoCoinsAmount)),
    );
    this.#wrappedSocket.on('user.xp.updated', (value: { xp: number }) => dispatch(new UpdateXp(value.xp)));
    this.#wrappedSocket.on('user.account-type.updated', (value: { type: ProfileTypes }) => dispatch(new UpdateAccountType(value.type)));
    this.#wrappedSocket.on('user.perm-lock', (payload: { value: string | null }) =>
      payload.value
        ? dispatch([new RefreshCurrentUser({ permLock: payload.value })])
        : dispatch([new RefreshCurrentUser({ permLock: payload.value }), new CloseModal(ModalNames.BAN), new Navigate(['./'])]),
    );
    //////
    this.#userApiService.listenCoinsAmount().subscribe((response) => dispatch(new UpdUserWallets(response)));
  }

  @Action(Init)
  init({ dispatch, patchState, getState }: StateContext<UserStateModel>): Observable<void> {
    const { IS_NEW_WALLETS } = this.#environmentService.getEnvironment();
    return forkJoin([this.#userApiService.getMeProfile({ detailed: true }) /**this.apiService.getUserCountry()*/]).pipe(
      switchMap(([user]) => {
        const state = getState();
        this.#analyticsService.addUserIdEvent(user.id);
        const userModified: IUserDetailed = {
          ...user,

          // Доп защита для фолбэка на старый кошелек. Потому-что по коду не везед перешли на новые кошельки
          coinsAmount: IS_NEW_WALLETS
            ? state.wallets.find((item) => item.walletType.id === WalletTypeEnum.BALANCE)?.balance || 0
            : user.coinsAmount,
        };
        Sentry.configureScope((scope: any) => {
          scope.setUser({
            id: `${user.id}`,
            username: user.name,
            email: user.email.address,
          });
          scope.setTag('language', user.locale);
        });
        this.#chatService.setIntegrationData({
          name: user.name,
          USER_ID: user.id.toString(),
          email: user.email ? user.email.address : '',
        });
        return dispatch([
          new GetAdditionalSettings(),
          new GetUserCountry(),
          new RefreshCurrentUser(userModified),
          new GetUserWallets(),
          new InitSuccess(user),
        ]);
      }),
      catchError((error: HttpErrorResponse) => {
        if (error.status === 403 || error.status === 503) {
          patchState({ user: null });
        }
        return dispatch(new InitError());
      }),
    );
  }

  /*TODO подумать может сравнивать со старым юзером и патчить
  только измененные поля, что бы лишний раз не дергать изменения в других стейтах*/
  @Action(GetUser)
  get({ dispatch, patchState, getState }: StateContext<UserStateModel>, { fullData }: { fullData: boolean }): Observable<void> {
    const { IS_NEW_WALLETS } = this.#environmentService.getEnvironment();

    return this.#userApiService.getMeProfile({ detailed: true }).pipe(
      switchMap((result: IUserDetailed) => {
        Sentry.configureScope((scope: any) => {
          scope.setUser({
            id: `${result.id}`,
            username: result.name,
            email: result.email.address,
          });
          scope.setTag('language', result.locale);
          // scope.setExtra(
          //   'important-information',
          //   'a very important information to help!'
          // );
        });
        const state = getState();

        const userModified: IUserDetailed = {
          ...result,

          // Доп защита для фолбэка на старый кошелек. Потому-что по коду не везед перешли на новые кошельки
          coinsAmount: IS_NEW_WALLETS
            ? state.wallets.find((item) => item.walletType.id === WalletTypeEnum.BALANCE)?.balance || 0
            : result.coinsAmount,
        };
        // Если мы обновляем юзера то нам не всегда нужно перезапрашивать все данные (например не нужно если меняем почту)
        if (fullData) {
          return dispatch([new RefreshCurrentUser(userModified), new GetAdditionalSettings(), new GetUserWallets()]);
        } else {
          return dispatch([new RefreshCurrentUser(userModified)]);
        }
      }),
      catchError((error: HttpErrorResponse) => {
        if (error.status === 403 || error.status === 503) {
          patchState({ user: null });
        }

        return of();
      }),
    );
  }
  @Action(GetUserWallets)
  getWallets({ patchState, dispatch }: StateContext<UserStateModel>): Observable<IUserWallet[]> | void {
    const { IS_NEW_WALLETS } = this.#environmentService.getEnvironment();
    if (!IS_NEW_WALLETS) {
      return;
    }
    return this.#userApiService.getMyBalance().pipe(
      tap((response) => {
        patchState({
          wallets: response,
        });
        const userWallet = response.find((item) => item.walletType.id === WalletTypeEnum.BALANCE);
        // Доп защита для фолбэка на старый кошелек. Потому-что по коду не везед перешли на новые кошельки
        if (userWallet && userWallet.balance > 0) {
          dispatch(new RefreshCurrentUser({ coinsAmount: userWallet.balance }));
        }
      }),
      catchError((error: HttpErrorResponse) => this.#onError(error)),
    );
  }
  @Action(UpdUserWallets)
  updWallets({ setState, dispatch }: StateContext<UserStateModel>, { payload }: UpdUserWallets): void {
    setState(
      patch({
        wallets: updateItem<IUserWallet>(
          (o) => o?.walletType.id === payload.walletTypeId,
          patch({
            balance: payload.amount,
          }),
        ),
      }),
    );
    // Доп защита для фолбэка на старый кошелек. Потому-что по коду не везед перешли на новые кошельки
    if (payload.walletTypeId === WalletTypeEnum.BALANCE) {
      dispatch(new RefreshCurrentUser({ coinsAmount: payload.amount }));
    }
  }

  @Action(DemandSelfExclusion)
  demandSelfEsclusion({ duration }: DemandSelfExclusion): Observable<void> {
    return this.#userApiService.demandSelfExclusion(duration).pipe(catchError((error: HttpErrorResponse) => this.#onError(error)));
  }
  @Action(GetUserTradeStats)
  getUserTradeStats({ patchState, getState }: StateContext<UserStateModel>): Observable<IUserTradeStats> {
    const { user } = getState();
    if (!user) {
      return of();
    }
    return this.#userApiService.getUserTradeStats().pipe(
      tap((response) => {
        patchState({
          user: { ...user, market: response },
        });
      }),
    );
  }
  // @Action(SetUser)
  // public setUser({ patchState }: StateContext<UserStateModel>, { payload }: SetUser): void {
  //   patchState({ user: payload });
  // }
  @Action(GetAdditionalSettings)
  getAdditionalSettings({ patchState }: StateContext<UserStateModel>): Observable<IUserSettings> {
    return this.#userApiService.getMeSettings().pipe(
      tap((response) => {
        const fastDelivery = this.#storageService.get('fastDelivery');
        // response.market and response.market.tradesDuration if empty should be set to 1
        const normalizeForTradesDuration = response.market ? response.market?.tradesDuration || MIN_TRADE_DURATION : MIN_TRADE_DURATION;
        const market = response.market
          ? { ...response.market, tradesDuration: normalizeForTradesDuration }
          : { tradesDuration: normalizeForTradesDuration };
        patchState({
          additionalSettings: {
            ...response,
            fastDelivery,
            market,
          },
        });
      }),
      catchError((error: HttpErrorResponse) => this.#onError(error)),
    );
  }
  @Action(ChangeAdditionalSettings)
  changeAdditionalSettings(
    { getState, patchState, dispatch }: StateContext<UserStateModel>,
    { payload }: ChangeAdditionalSettings,
  ): Observable<IUserSettings> {
    const state = getState();
    if (state.user === null || state.user === undefined) {
      return of();
    }
    if (payload.fastDelivery !== null && payload.fastDelivery !== undefined) {
      this.#storageService.set('fastDelivery', payload.fastDelivery);
      dispatch(new ChangeFrameLocalStorage({ fastDelivery: payload.fastDelivery }));
    }
    const { fastDelivery, ...main } = payload;
    return this.#userApiService.patchUserSettings(main).pipe(
      tap((response) => {
        // const fastDeliveryStorage = this.storage.get('fastDelivery');
        patchState({
          additionalSettings: { ...response, fastDelivery },
        });
      }),
    );
  }
  @Action(GetExperience)
  getExperience({ dispatch }: StateContext<UserStateModel>): Observable<void> {
    return this.#userApiService
      .getMeExperience()
      .pipe(switchMap((result: IUserExperience) => dispatch([new RefreshCurrentUser({ experience: result })])));
  }
  @Action(GetUserCountry)
  getCountry({ patchState }: StateContext<UserStateModel>): Observable<UserCountry> {
    return this.#userApiService.getUserCountry().pipe(
      tap((country: UserCountry) => {
        patchState({ country });
      }),
    );
  }
  @Action(GetP2pPermissions)
  getP2pPermissions({ patchState }: StateContext<UserStateModel>): Observable<IUserP2pPermissionInterface> {
    // Fetch error
    return this.#userApiService.getP2pPermissions().pipe(
      tap((p2pPermissions: IUserP2pPermissionInterface) => {
        if (p2pPermissions.canTrade && !p2pPermissions.canTrade.success) {
          this.#notificationsService.addErrorNotification(
            SteamErrorsEnumLocales[p2pPermissions.canTrade.error] ?? p2pPermissions.canTrade.error,
            { showToast: false, updateNotification: true },
          );
        }
        if (p2pPermissions.canSteamAPI && !p2pPermissions.canSteamAPI.success) {
          this.#notificationsService.addErrorNotification(
            SteamErrorsEnumLocales[p2pPermissions.canSteamAPI.error] ?? p2pPermissions.canSteamAPI.error,
            { showToast: false, updateNotification: true },
          );
        }
        patchState({ p2pPermissions });
      }),
      catchError((error: HttpErrorResponse) => this.#onError(error, { showToast: false })),
    );
  }
  @Action(UpdateCurrentUser)
  updUser({ getState, dispatch }: StateContext<UserStateModel>, { payload }: UpdateCurrentUser): Observable<void> {
    const state = getState();
    if (state.user === null || state.user === undefined) {
      return of();
    }

    return this.#userApiService.patchUserData(payload).pipe(
      switchMap(() => {
        return dispatch([new RefreshCurrentUser(payload)]);
      }),
    );
  }
  @Action(RefreshCurrentUser)
  refreshUser({ patchState, getState, dispatch }: StateContext<UserStateModel>, { payload }: RefreshCurrentUser): void {
    const state = getState();
    const { IS_NEW_WALLETS } = this.#environmentService.getEnvironment();

    const newUser: IUserDetailed = {
      ...(state.user as IUserDetailed),
      ...payload,
    };

    if (payload.experience) {
      newUser.experience = { ...newUser.experience, nextReward: { type: 'case' } };
      //TODO:LK ждем свойства от бэка награду следующего уровня
    }

    if (
      state.user &&
      ((payload.steamApiKey && state.user?.steamApiKey !== payload.steamApiKey) ||
        (payload.links?.steam && state.user?.links?.steam !== payload.links?.steam))
    ) {
      dispatch(new GetP2pPermissions());
    }
    if (payload.locale) {
      Sentry.configureScope((scope: any) => {
        scope.setTag('language', payload.locale);
      });
    }
    if (payload.permLock) {
      dispatch([new OpenModal(ModalNames.BAN, payload.permLock), new Navigate(['block'])]);
    }
    patchState({
      user: newUser,
      wallets:
        !IS_NEW_WALLETS && newUser.coinsAmount
          ? [{ walletType: { id: WalletTypeEnum.BALANCE }, balance: newUser.coinsAmount }]
          : state.wallets,
      permissions: this.#permissionsFactory(newUser),
    });

    // this.storage.set('user', newUser);
  }
  @Action(Unsubscribe)
  unsubscribe({ dispatch }: StateContext<UserStateModel>, { email }: Unsubscribe): Observable<EmailSubscribe> {
    return this.#userApiService.unsubscribeFromEmails(email).pipe(
      tap(() => {
        dispatch(new GetUser());
      }),
    );
  }

  @Action(UpdateEmail)
  updateEmail({ dispatch, patchState }: StateContext<UserStateModel>, { params }: UpdateEmail): Observable<EmailSubscribe> {
    return this.#userApiService.patchEmail(params).pipe(
      tap(() => {
        patchState({ updateEmailRequestState: RequestState.Success });
        dispatch(new GetUser(false));
      }),
      catchError((err) => {
        this.#onError(err);
        throw new Error(err.message);
      }),
    );
  }
  @Action(ConfirmEmail)
  confirmEmail({ dispatch, patchState }: StateContext<UserStateModel>, { code }: ConfirmEmail): Observable<EmailSubscribe> {
    return this.#userApiService.confirmEmail(code).pipe(
      tap(() => {
        patchState({ emailConfirmRequestState: RequestState.Success });
        dispatch(new GetUser(false));
      }),
      catchError((err) => {
        this.#onError(err);
        throw new Error(err.message);
      }),
    );
  }

  @Action(UpdateAvatar)
  updateAvatar(
    { dispatch }: StateContext<UserStateModel>,
    { file }: UpdateAvatar,
  ): Observable<{
    avatar: string;
  }> {
    return this.#userApiService.updateUserAvatar(file).pipe(
      tap(() => {
        dispatch(new GetUser());
      }),
    );
  }
  @Action(SwitchWalletType)
  switchWalletType(): void | Observable<void> {
    const frameLoaded = this.#store.selectSnapshot(IFrameState.loaded);

    if (frameLoaded && this.frameMessageService) {
      return this.frameMessageService.sendMessage({
        type: FrameMessageTypes.MESSAGE_TO_BB_SOCKET,
        eventName: 'wallet-new.switch',
        payload: {},
      });
    } else {
      // return this.apiService.switchWalletType().pipe(switchMap(() => dispatch([new SwitchWalletTypeComplete(true)])));
      return this.#userApiService.switchWalletType();
    }
  }
  @Action(SwitchWalletTypeComplete)
  switchWalletTypeComplete({ dispatch, getState }: StateContext<UserStateModel>, { payload }: SwitchWalletTypeComplete): void {
    const { user } = getState();
    if (payload.success) {
      dispatch([new RefreshCurrentUser({ walletType: payload.walletType === 1 ? 'demo' : 'real' })]);
    } else {
      dispatch([new RefreshCurrentUser({ walletType: user?.walletType })]);
    }
  }
  @Action(UpdateCoinsAmount)
  updateCoinsAmount({ dispatch }: StateContext<UserStateModel>, { payload }: UpdateCoinsAmount): void {
    dispatch(new RefreshCurrentUser({ coinsAmount: payload }));
  }
  @Action(UpdateDemoCoinsAmount)
  updateDemoCoinsAmount({ dispatch }: StateContext<UserStateModel>, { payload }: UpdateCoinsAmount): void {
    dispatch([new RefreshCurrentUser({ demoCoinsAmount: payload })]);
  }
  @Action(UpdateAccountType)
  updateAccountType({ dispatch }: StateContext<UserStateModel>, { payload }: UpdateAccountType): void {
    dispatch([new RefreshCurrentUser({ profileType: payload })]);
  }
  @Action(UpdateXp)
  updateXp({ patchState, getState }: StateContext<UserStateModel>, { payload }: UpdateXp): void {
    //FIXME
    // dispatch([new RefreshCurrentUser({ experience: {payload} })]);
    const { user } = getState();
    if (!user?.experience) {
      return;
    }
    const experience: IUserExperience = { ...user.experience, xp: payload };
    patchState({ user: { ...user, experience } });
  }
  #onError(e: HttpErrorResponse, noty?: Partial<INotification>): Observable<never> {
    const { error } = e;
    this.#notificationsService.addErrorNotification(noty?.message || error.message || error.error, {
      ...noty,
      icon: 'error',
      system: error.system || noty?.system,
    });
    return of();
  }
  #permissionsFactory(user: IUserDetailed | null): Permissions[] {
    const result: Permissions[] = [];
    if (user) {
      result.push(Permissions.MEMBER);
    }
    if ((user && !user.permLock) || !user) {
      result.push(Permissions.CAN_USE);
    }

    return result;
  }
}
