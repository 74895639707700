import { HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { CasesBackendService, InventoryApiService, P2pApiNewService } from '@dev-fast/backend-services';
import { ICaseItemDetailed, NotificationType } from '@dev-fast/types';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { LanguageState } from '@app/core/language-service';
import { NotificationsService } from '@app/core/notification-service';
import { IS_SERVER_TOKEN } from '@app/shared/utils';

import { ClearCasesMotivator } from '../../motivator';
import { CasesGameState, ClearGameState } from '../game-state';
import { CasesSelectState } from '../select-state';
import { CasesSettingsState } from '../settings-state';
import {
  ClearMarketCasesState,
  GetCaseItemAffiliatedCases,
  GetCaseItemAffiliatedMarketLots,
  GetItemDetails,
  SetSelectedItemName,
} from './market-state.actions';
import { IMarketCasesStateModel, MARKET_CASES_INITIAL_STATE } from './market-state.model';

@State<IMarketCasesStateModel>({
  name: 'marketCases',
  defaults: MARKET_CASES_INITIAL_STATE,
  children: [CasesGameState, CasesSelectState, CasesSettingsState],
})
@Injectable()
export class MarketCasesState {
  // ----- SELECTORS -----

  @Selector()
  static caseItemsDetailed({ caseItemsDetailed }: IMarketCasesStateModel): Record<string, ICaseItemDetailed> {
    return caseItemsDetailed;
  }
  @Selector()
  static selectedItem({ selectedItemName, caseItemsDetailed }: IMarketCasesStateModel): ICaseItemDetailed | undefined {
    return selectedItemName ? caseItemsDetailed[selectedItemName] : undefined;
  }
  constructor(
    private readonly store: Store,
    private readonly casesBackendService: CasesBackendService,
    private readonly notificationsService: NotificationsService,
    private readonly inventoryBackendService: InventoryApiService,
    private readonly marketBackendService: P2pApiNewService,
    @Inject(IS_SERVER_TOKEN) public isServer: boolean,
  ) {}

  // ----- ACTIONS -----

  /**
   * Открыть панель с информацией про итем кейса
   */
  @Action(GetItemDetails)
  GetItemDetails({ dispatch, getState, patchState }: StateContext<IMarketCasesStateModel>, { payload }: GetItemDetails): Observable<any> {
    const { caseItemsDetailed } = getState();
    const selectedItemName = `${payload.shortName} | ${payload.skin}`;
    const lang = this.store.selectSnapshot(LanguageState.lang).path.split('_')[0].toUpperCase();
    if (caseItemsDetailed[selectedItemName]) {
      dispatch([new GetCaseItemAffiliatedMarketLots(selectedItemName), new SetSelectedItemName(selectedItemName)]);
      return of();
    } else {
      return this.inventoryBackendService.getInventoryItemDetailed({ shortName: payload.shortName, skin: payload.skin, lang }).pipe(
        tap((caseItemDetailedInfo) => {
          patchState({
            caseItemsDetailed: { ...caseItemsDetailed, [selectedItemName]: { item: caseItemDetailedInfo, icon: payload.icon } },
          });
          dispatch([new GetCaseItemAffiliatedCases(payload.shortName, payload.skin), new SetSelectedItemName(selectedItemName)]);
        }),
        catchError((error: HttpErrorResponse) => {
          this.#onError(error);
          throw new Error(error.message);
        }),
      );
    }
  }

  @Action(SetSelectedItemName)
  setSelectedItemName({ patchState }: StateContext<IMarketCasesStateModel>, { selectedItemName }: SetSelectedItemName): void {
    patchState({
      selectedItemName,
    });
  }

  /**
   * Получение кейсов, в которых есть этот итем
   */
  @Action(GetCaseItemAffiliatedCases)
  getCaseItemAffiliatedCases(
    { getState, patchState, dispatch }: StateContext<IMarketCasesStateModel>,
    { shortName, skin }: { shortName: string; skin: string },
  ): Observable<any> {
    const { caseItemsDetailed } = getState();
    const selectedItemName = `${shortName} | ${skin}`;
    if (caseItemsDetailed[selectedItemName]) {
      return this.casesBackendService.getAvailabilityItemInCases({ shortName, skin }).pipe(
        tap((affiliatedCases) => {
          // console.log(affiliatedCases);
          patchState({
            caseItemsDetailed: {
              ...caseItemsDetailed,
              [selectedItemName]: { ...caseItemsDetailed[selectedItemName], cases: affiliatedCases },
            },
          });
          dispatch(new GetCaseItemAffiliatedMarketLots(selectedItemName));
        }),
        catchError((error: HttpErrorResponse) => {
          this.#onError(error);
          throw new Error(error.message);
        }),
      );
    } else {
      // TODO add error
      return of();
    }
  }
  /**
   * Получение p2p лoтов, в которых есть этот итем
   */
  @Action(GetCaseItemAffiliatedMarketLots)
  getCaseItemAffiliatedMarketLots(
    { getState, patchState }: StateContext<IMarketCasesStateModel>,
    { query }: GetCaseItemAffiliatedMarketLots,
  ): Observable<any> {
    const { caseItemsDetailed } = getState();
    // const params = {
    //   minPrice: null,
    //   maxPrice: null,
    //   minOverprice: null,
    //   maxOverprice: null,
    //   sortBy: MarketSortingTypes.MIN_PRICE,
    //   query: query,
    // }
    const params = undefined;
    if (!caseItemsDetailed[query]) {
      return this.marketBackendService.getMarketItems(params).pipe(
        tap((affiliatedLots) => {
          // console.log(affiliatedLots.kits);
          patchState({
            caseItemsDetailed: {
              ...caseItemsDetailed,
              [query]: { ...caseItemsDetailed[query], lots: affiliatedLots.kits },
            },
          });
        }),
        catchError((error: HttpErrorResponse) => {
          this.#onError(error);
          throw new Error(error.message);
        }),
      );
    } else {
      // TODO add error
      return of();
    }
  }
  /**
   * Очистить данные о текущем кейсе
   */
  @Action(ClearMarketCasesState)
  ClearMarketState({ patchState, dispatch }: StateContext<IMarketCasesStateModel>): Observable<void> {
    patchState({
      ...MARKET_CASES_INITIAL_STATE,
    });
    return dispatch([new ClearGameState(), new ClearCasesMotivator()]);
  }
  #onError(error: HttpErrorResponse): void {
    this.notificationsService.addErrorNotification(
      error.error && error.error.message ? error.error.message : typeof error.error === 'string' ? error.error : 'Error',
      { type: error.error && error.error.type ? error.error.type : NotificationType.Error },
    );
  }
}
