import { HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { Router } from '@angular/router';
import { ReferralsApiService } from '@dev-fast/backend-services';
import {
  Affise,
  ICampaignInfo,
  IError,
  IPromoCode,
  IPromocodeData,
  IReferralCampaign,
  IReferralCampaignDto,
  IReferralCampaignLite,
  IReferralCampaignReport,
  ModalNames,
  PromoStatus,
} from '@dev-fast/types';
import { RouterNavigation } from '@ngxs/router-plugin';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { iif, patch, updateItem } from '@ngxs/store/operators';
import { StateReset } from 'ngxs-reset-plugin';
import { catchError, finalize, Observable, of, retry, switchMap, tap, throwError, timer } from 'rxjs';

import { Logout } from '@app/auth';
import { AnalyticsService } from '@app/core/analytics-service';
import { FrameMessageTypes, IFrameMessageService } from '@app/core/iframe';
import { LocalStorageService } from '@app/core/local-storage-service';
import { NotificationsService } from '@app/core/notification-service';
import { ModalsState, OpenModal } from '@app/core/state/modals';
import { InitSuccess, UserState } from '@app/core/state/user-store';
import { RouterStateParams } from '@app/core/state/utils';
import { IS_SERVER_TOKEN } from '@app/shared/utils';

import {
  ActivatePromoCode,
  ActivateRefCode,
  AffiseEvent,
  ChangeCompaign,
  ClickPromoCode,
  CreateCompaign,
  GetCampaignReferralsReport,
  GetCampaigns,
  GetCurrentPromo,
  GetInfo,
  OpenPromoModal,
  SetActiveCampaign,
} from './referrals.actions';
import { REFERRALS_INITIAL_STATE, ReferralsStateModel } from './referrals-state.model';

@State<ReferralsStateModel>({
  name: 'referrals',
  defaults: REFERRALS_INITIAL_STATE,
})
@Injectable()
export class ReferralsState {
  constructor(
    @Optional() private readonly frameMessageService: IFrameMessageService,
    @Inject(IS_SERVER_TOKEN) private isServer: boolean,
    private readonly store: Store,
    private readonly router: Router,
    private readonly storage: LocalStorageService,
    private readonly apiService: ReferralsApiService,
    private readonly analyticsService: AnalyticsService,
    private readonly notificationsService: NotificationsService,
  ) {
    this.apiService
      .bonusCaseReceived()
      .subscribe(
        (value) =>
          !this.store.selectSnapshot(ModalsState.activeModals).includes(ModalNames.CASES_BONUS) &&
          this.store.dispatch(new OpenModal(ModalNames.CASES_BONUS, value.payload)),
      );
  }

  @Selector()
  static promoStatus({ activationErr, promoActivated, activePromoCode }: ReferralsStateModel): PromoStatus {
    if (activationErr && !promoActivated) {
      return PromoStatus.ERROR;
    }
    if (activePromoCode && promoActivated) {
      return PromoStatus.SUCCESS;
    }
    return PromoStatus.DEFAULT;
  }
  @Selector()
  static activePromoCode({ activePromoCode }: ReferralsStateModel): IPromoCode | null {
    return activePromoCode;
  }
  @Selector()
  static activeReferralCampaign({ activeReferralCampaign }: ReferralsStateModel): IPromoCode | null {
    return activeReferralCampaign;
  }
  @Selector()
  static activationErr({ activationErr }: ReferralsStateModel): IError | null {
    return activationErr;
  }
  @Selector()
  static promoData({ activationErr, activePromoCode, promoActivated }: ReferralsStateModel): IPromocodeData {
    return { activationErr, activePromoCode, promoActivated };
  }
  // --------------------------------------------------------
  @Selector()
  static campaignReferralsReport({ campaignReferralsReport }: ReferralsStateModel): IReferralCampaignReport[] {
    return campaignReferralsReport;
  }
  @Selector()
  static campaigns({ campaigns }: ReferralsStateModel): IReferralCampaign[] {
    return campaigns;
  }
  @Selector()
  static campaign({ campaign }: ReferralsStateModel): IReferralCampaign | null {
    return campaign;
  }
  @Selector()
  static info({ info }: ReferralsStateModel): ICampaignInfo | null {
    return info;
  }
  @Action(GetCampaignReferralsReport)
  getCampaignReferralsReport(
    { patchState }: StateContext<ReferralsStateModel>,
    { params }: GetCampaignReferralsReport,
  ): Observable<IReferralCampaignReport[]> {
    return this.apiService.getCampaignReferralsReport(params).pipe(
      tap((response) =>
        patchState({
          campaignReferralsReport: response,
        }),
      ),
    );
  }
  @Action(GetCampaigns)
  getCampaigns({ patchState }: StateContext<ReferralsStateModel>): Observable<IReferralCampaignDto> {
    return this.apiService.getReferralCampaigns().pipe(
      tap((response) => {
        // TODO убрать, бэк поменялся в дэве. Новый вариант response.campaigns раньше все лежало в response
        const fallback = response.campaigns ? response.campaigns : (response as any);
        patchState({ campaigns: fallback ?? [] });
      }),
      catchError((error: HttpErrorResponse) => {
        patchState({
          campaign: null,
        });
        return this.onError(error);
      }),
    );
  }
  @Action(GetInfo, { cancelUncompleted: true })
  getInfo({ patchState }: StateContext<ReferralsStateModel>): Observable<any> {
    return this.apiService.getInfo().pipe(tap((response) => patchState({ info: response })));
  }
  @Action(SetActiveCampaign)
  setActiveCampaign({ patchState, getState }: StateContext<ReferralsStateModel>, { id }: SetActiveCampaign): void {
    const { campaigns } = getState();
    const campaign = campaigns?.find((item) => item.id === id);
    patchState({ campaign: campaign ? campaign : null });
  }
  @Action(CreateCompaign)
  createCompaign({ dispatch }: StateContext<ReferralsStateModel>, { payload }: CreateCompaign): Observable<void> {
    return this.apiService.requestCampaignCreate(payload).pipe(
      catchError((error: HttpErrorResponse) => this.onError(error)),
      switchMap(() => dispatch(new GetCampaigns())),
    );
  }
  @Action(ChangeCompaign)
  changeCompaign(
    { setState, getState }: StateContext<ReferralsStateModel>,
    { payload }: ChangeCompaign,
  ): Observable<IReferralCampaignLite> {
    return this.apiService.requestCampaignPatch(payload).pipe(
      tap((response) => {
        const { campaign } = getState();
        setState(
          patch<ReferralsStateModel>({
            campaigns: updateItem<IReferralCampaign>((o) => o?.id === payload.id, patch<IReferralCampaign>({ ...response })),
            campaign: iif((el) => !!el && el.id === response.id, campaign && { ...campaign, ...response }, campaign),
          }),
        );
      }),
    );
  }
  // ----------------------------------- ------------------------------------------
  @Action(RouterNavigation)
  routerNavigation(
    { dispatch, patchState }: StateContext<ReferralsStateModel>,
    { routerState }: RouterNavigation<RouterStateParams>,
  ): void {
    const { queryParams } = routerState;
    if (queryParams && !this.isServer) {
      if (queryParams['clickid'] || queryParams['utm_campaign']) {
        const affise: Affise = {};
        if (queryParams['clickid']) {
          affise['clickId'] = queryParams['clickid'];
        }
        if (queryParams['utm_campaign']) {
          affise['utmCampaign'] = queryParams['utm_campaign'];
        }

        patchState({ affise });
        this.storage.set('affise', affise);
        dispatch(new AffiseEvent(affise));
      }
      // NOTE: Костыль для того, чтобы старый формат рефералок с # перебрасывал на новый формат
      if (routerState.url.includes('#r')) {
        this.redirectFromLegacyPromo(routerState.url);
      }
      if (queryParams['ref']) {
        const code = queryParams['ref'];
        const codeFromStorage = this.getRefCode();
        if (code && !codeFromStorage) {
          dispatch(new ClickPromoCode(code));
        }
      }
    }
  }
  @Action(GetCurrentPromo)
  getCurrentPromo({ patchState }: StateContext<ReferralsStateModel>): Observable<IPromoCode | null> {
    return this.apiService.getCurrentReferralCampaign().pipe(
      tap((response) => {
        patchState({
          activePromoCode: response,
          activationErr: null,
          promoActivated: false,
        });
      }),
      catchError(({ error }: HttpErrorResponse) => {
        patchState({
          activePromoCode: null,
          activationErr: error,
        });
        return of(error);
      }),
    );
  }
  @Action(AffiseEvent)
  affiseEvent({ patchState }: StateContext<ReferralsStateModel>, { affise }: AffiseEvent): Observable<void> | undefined {
    const user = this.store.selectSnapshot(UserState.user);
    if (!user) {
      return;
    }
    return this.analyticsService.affiseEvent(affise).pipe(
      retry({
        count: 5,
        delay: (error, retryCount) => (error && retryCount < 5 ? timer(5000 * retryCount) : throwError(() => error)),
      }),
      tap(() => {
        this.storage.remove('affise');
        patchState({ affise: null });
      }),
    );
  }
  @Action(ActivatePromoCode)
  activatePromoCode({ patchState, dispatch }: StateContext<ReferralsStateModel>, { code }: ActivatePromoCode): Observable<any> {
    // return dispatch(new ActivateRefCode(code));
    return this.apiService.requestPromoActivation(code).pipe(
      tap(({ appId, ...response }) => {
        patchState({
          activePromoCode: response,
          activationErr: null,
          promoActivated: true,
        });
        if (this.frameMessageService) {
          this.frameMessageService.sendMessage({
            type: FrameMessageTypes.MESSAGE_TO_BB,
            eventName: 'promoActivated',
            payload: response,
          });
        }
      }),
      // switchMap((val) => dispatch(new ActivatePromoCodeSuccess(val))),
      // switchMap((val) => dispatch(new OpenPromoModal(val))),
      catchError(({ error }: HttpErrorResponse) => {
        patchState({
          activationErr: error,
          promoActivated: false,
        });
        this.notificationsService.addErrorNotification(error.message, { showToast: false });
        return of(error);
      }),
      finalize(() => this.storage.removeMultiple(['referralCode', 'unknownRefCode'])),
    );
  }
  @Action(ActivateRefCode, { cancelUncompleted: true })
  activateRefCode({ patchState, dispatch }: StateContext<ReferralsStateModel>, { code }: ActivateRefCode): Observable<any> {
    return this.apiService.requestPromoActivation(code).pipe(
      retry({
        count: 5,
        delay: (error, retryCount) => (error && retryCount < 5 ? timer(5000 * retryCount) : throwError(() => error)),
      }),
      tap(({ appId, ...response }) => {
        patchState({
          activePromoCode: response,
          activationErr: null,
          promoActivated: true,
        });
        if (this.frameMessageService) {
          this.frameMessageService.sendMessage({
            type: FrameMessageTypes.MESSAGE_TO_BB,
            eventName: 'promoActivated',
            payload: response,
          });
        }
      }),
      switchMap((val) => dispatch(new OpenPromoModal(val))),
      catchError(({ error }: HttpErrorResponse) => {
        patchState({
          activationErr: error,
          promoActivated: false,
        });
        this.notificationsService.addErrorNotification(error.message, { showToast: false });
        return of(error);
      }),
      finalize(() => this.storage.removeMultiple(['referralCode', 'unknownRefCode'])),
    );
  }
  @Action(ClickPromoCode)
  clickPromoCode({ patchState, dispatch }: StateContext<ReferralsStateModel>, { code }: ClickPromoCode): Observable<any> {
    return this.apiService.requestPromoClicks(code).pipe(
      retry({
        count: 5,
        delay: (error, retryCount) => (error && retryCount < 5 ? timer(5000 * retryCount) : throwError(() => error)),
      }),
      tap((response) => {
        patchState({
          activeReferralCampaign: response,
        });
        this.storage.set('referralCode', response);
      }),
      switchMap((res) => {
        return this.store.selectSnapshot(UserState.user) ? dispatch(new ActivateRefCode(res.code)) : dispatch(new OpenPromoModal(res));
      }),
      catchError(({ error, status }: HttpErrorResponse) => {
        if (status !== 404) {
          this.storage.set('unknownRefCode', code);
        }
        this.notificationsService.addErrorNotification(error.message);
        return of(error);
      }),
    );
  }
  // @Action(ProceedPromoCode)
  // public proceedPromoCode({ dispatch }: StateContext<ReferralsStateModel>, { code }: ProceedPromoCode) {
  //   this.store.selectSnapshot(UserState.user) && dispatch(new ActivatePromoCode(code.code));
  // }

  @Action(OpenPromoModal)
  openPromoModal({ dispatch }: StateContext<ReferralsStateModel>, { code }: OpenPromoModal): void {
    if (!this.store.selectSnapshot(ModalsState.activeModals).includes(ModalNames.REFERRAL)) {
      dispatch(new OpenModal(ModalNames.REFERRAL, code));
    }
  }
  @Action(InitSuccess)
  initSuccess({ dispatch }: StateContext<ReferralsStateModel>): void {
    const affise = this.storage.get('affise');
    const code = this.getRefCode();
    // TODO мож проверить не протух ли он
    if (code) {
      dispatch(new ActivateRefCode(code));
    }
    if (affise) {
      dispatch(new AffiseEvent(affise));
    }
  }
  @Action(Logout)
  logout({ dispatch }: StateContext<ReferralsStateModel>): Observable<any> {
    return dispatch([new StateReset(ReferralsState)]);
  }
  private getRefCode(): string | undefined {
    const refCode = this.storage.get('referralCode');
    const unknownRefCode = this.storage.get('unknownRefCode');
    return refCode ? refCode.code : unknownRefCode;
  }
  /**
   * Переброс пользователя с /#r/123 на ?ref=123
   */
  private redirectFromLegacyPromo(url: string): void {
    const [page, data] = url.split('#r/');
    const [code, query] = data.split('?');
    this.router.navigate([page], { queryParams: { ref: code + (query ? '&' + query : '') } });
  }
  private onError(e: HttpErrorResponse, msg?: string, system = true): Observable<any> {
    const { error } = e;
    this.notificationsService.addErrorNotification(msg || error.message || error.error, {
      icon: 'warning',
      system: error.system || system,
    });
    return of();
  }
}
