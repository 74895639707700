import { Affise, ICampaignInfo, IError, IPromoCode, IReferralCampaign, IReferralCampaignReport } from '@dev-fast/types';

export interface ReferralsStateModel {
  activePromoCode: IPromoCode | null;
  activeReferralCampaign: IPromoCode | null;
  promoActivated: boolean;
  activationErr: IError | null;
  // ----------------------------------------
  affise: Affise | null;
  campaignReferralsReport: IReferralCampaignReport[];
  campaigns: IReferralCampaign[];
  campaign: IReferralCampaign | null;
  info: ICampaignInfo | null;
}
export const REFERRALS_INITIAL_STATE: ReferralsStateModel = {
  activePromoCode: null,
  activeReferralCampaign: null,
  activationErr: null,
  promoActivated: false,
  affise: null,
  campaignReferralsReport: [],
  campaigns: [],
  campaign: null,
  info: null,
};
