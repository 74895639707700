import { Injectable } from '@angular/core';
import {
  IHistoryInventoryItem,
  IHistoryParams,
  IInventoryInfo,
  IInventoryItem,
  IInventoryItemDetailed,
  IInventoryItemDetailedReq,
  IInventoryRequestResponse,
  IInventorySellAllRequestParams,
  IShop,
  IWithdrawalItems,
  IWithdrawalItemUpdate,
  IWithdrawalRequest,
  IWithdrawalRequestParams,
  IWithdrawalResponse,
  SellItemsResponse,
} from '@dev-fast/types';
import { Socket } from 'ngx-socket-io';
import { Observable } from 'rxjs';

import { Environments, EnvironmentService } from '@app/core/environment-service';
import { paramsToQuery } from '@app/shared/utils';

import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class InventoryApiService {
  private environment: Environments;

  constructor(
    private readonly api: ApiService,
    private readonly environmentService: EnvironmentService,
    private ws: Socket,
  ) {
    this.environment = this.environmentService.environments;
  }
  sellInventoryItems(ids: number[]): Observable<SellItemsResponse> {
    return this.api.post<SellItemsResponse>(
      `${this.environment.GATEWAY_URL}/api/inventory/v2/items/sell`,
      { ids: ids },
      {
        withCredentials: true,
      },
    );
  }

  sellAll(ignoreIds?: number[], filters?: IInventorySellAllRequestParams | null): Observable<SellItemsResponse> {
    return this.api.post<SellItemsResponse>(
      `${this.environment.GATEWAY_URL}/api/inventory/v2/items/sell/all${paramsToQuery(filters)}`,
      { ignoreIds: ignoreIds || [] },
      { withCredentials: true },
    );
  }

  freezeItems(ids: number[]): Observable<void> {
    return this.api.patch(
      `${this.environment.GATEWAY_URL}/api/inventory/v2/items/freeze`,
      {
        ids: ids,
        unfreeze: true,
      },
      {
        withCredentials: true,
      },
    );
  }

  requestSihImage(): Observable<void> {
    return this.api.get<void>('chrome-extension://cmeakgjggjdlcpncigglobpjbkabhmjl/assets/icon128.png');
  }

  updateWithdrawItem(callback: (updateData: IWithdrawalItemUpdate) => void): void {
    this.ws.on('withdraw-order:update-state', callback);
  }

  onInventoryItemDelayedSellSuccess(callback: ({ ids }: { ids: number[] }) => void): void {
    this.ws.on('inventory-items:sell:success', callback);
  }

  onInventoryItemDelayedSellError(callback: ({ ids }: { ids: number[]; error: string }) => void): void {
    this.ws.on('inventory-items:sell:error', callback);
  }

  withdrawSelectedItems(data: IWithdrawalRequest): Observable<IWithdrawalResponse[]> {
    return this.api.post<IWithdrawalResponse[]>(`${this.environment.GATEWAY_URL}/api/inventory/items/withdraw`, data, {
      withCredentials: true,
    });
  }

  getWithdrawalItems(data: IWithdrawalRequestParams): Observable<IWithdrawalItems> {
    return this.api.get<IWithdrawalItems>(`${this.environment.GATEWAY_URL}/api/inventory/items/withdraw`, {
      withCredentials: true,
      params: { ...data, appId: 730 },
    });
  }

  getInventoryItemDetailed(params: IInventoryItemDetailedReq): Observable<IInventoryItemDetailed> {
    return this.api.get(`${this.environment.GATEWAY_URL}/api/inventory/items/detailed`, {
      withCredentials: true,
      params,
    });
  }
  requestInventory(params?: object): Observable<IInventoryRequestResponse> {
    return this.api.get<IInventoryRequestResponse>(`${this.environment.GATEWAY_URL}/api/inventory/v2/items${paramsToQuery(params)}`, {
      withCredentials: true,
    });
  }

  requestInventoryInfo(): Observable<IInventoryInfo[]> {
    return this.api.get<IInventoryInfo[]>(`${this.environment.GATEWAY_URL}/api/inventory/info`, {
      withCredentials: true,
    });
  }

  requestInventoryHistory(params?: IHistoryParams): Observable<{ count: number; items: IHistoryInventoryItem[] }> {
    return this.api.get<{ count: number; items: IHistoryInventoryItem[] }>(`${this.environment.GATEWAY_URL}/api/inventory/v2/history`, {
      withCredentials: true,
      params,
    });
  }

  requestShop(params: object): Observable<IShop> {
    return this.api.get<IShop>(`${this.environment.GATEWAY_URL}/api/inventory/v2/shop`, {
      withCredentials: true,
      params,
    });
  }

  requestCreateTrade(params: { ids: number[]; userInventoryIds: number[] }): Observable<IInventoryItem[]> {
    return this.api.post(`${this.environment.GATEWAY_URL}/api/inventory/items/purchase`, params, {
      withCredentials: true,
    });
  }
}
