export enum QRCorrectionLevel {
  L = 'L',
  M = 'M',
  H = 'H',
  Q = 'Q',
}
export enum QRCodeType {
  URL = 'url',
  IMG = 'img',
  CANVAS = 'canvas',
  SVG = 'svg',
}
