<ng-container *ngIf="direction">
  <div class="spin-block-wrapper">
    <div class="spin-block-onset">
      <div class="spin-items-wrapper">
        <ng-container *ngFor="let track of tracks; trackBy: trackByIndex; let trackIndex = index">
          <div
            class="spin-track"
            [class]="['track-' + trackIndex, direction]"
          >
            <ng-container *ngFor="let item of track; trackBy: trackByIndex; let itemIndex = index">
              <ng-container
                [ngTemplateOutlet]="itemTemplate"
                [ngTemplateOutletContext]="{
                  item: item,
                  itemIndex: itemIndex,
                  isItemFocus: itemIndex === prizeOffset - 1 || itemIndex === track.length - prizeOffset,
                  trackIndex
                }"
              >
              </ng-container>
            </ng-container>
          </div>
        </ng-container>
      </div>
    </div>
    <ng-container *ngIf="direction === 'horizontal' && showFocus">
      <!-- <div class="focus-wrapper first"></div>
      <div class="focus-wrapper second"></div> -->
    </ng-container>
  </div>
  <div class="spin-arrow first"></div>
  <div class="spin-arrow second"></div>
</ng-container>
