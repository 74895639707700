import { ISeoData } from './seo.state.model';

export class SetData {
  public static readonly type = '[Seo] Set H1';

  constructor(public data: ISeoData) {}
}

export class SetTitle {
  public static readonly type = '[Seo] Set Title';

  constructor(public title: { value: string; data: any }) {}
}

export class SetDescription {
  public static readonly type = '[Seo] Set Description';

  constructor(public description: { value: string; data: any }) {}
}
