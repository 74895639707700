<button
  class="btn control-button btn-sm"
  (click)="toggleMenu()"
>
  <ng-container *ngIf="user; else notAuthTemplate">
    <app-ui-user-avatar
      [url]="user.avatar"
      [levelUpAnimation]="canLevelUp"
      [newLevel]="user.experience.level + 1"
      [level]="user.experience.level"
      [ngClass]="user.profileType"
    ></app-ui-user-avatar>
  </ng-container>
  <ng-template #notAuthTemplate>
    <mat-icon svgIcon="settings-selector"></mat-icon>
  </ng-template>
</button>

<ng-container *ngIf="isMenuShowed">
  <div class="wrapper">
    <div
      class="wrapper__menu"
      *ngIf="currentPanel === null"
    >
      <ng-scrollbar>
        <!-- [config]="{ wheelPropagation: false, suppressScrollX: true }" -->
        <div class="grid">
          <div class="col-18 nav-size">
            <ng-container *ngIf="user; else elseTemplate">
              <div class="grid">
                <div class="col-4 avatar">
                  <app-ui-user-avatar
                    [url]="user.avatar"
                    [levelUpAnimation]="canLevelUp"
                    [newLevel]="user.experience.level + 1"
                    [level]="user.experience.level"
                    [ngClass]="user.profileType"
                  ></app-ui-user-avatar>
                </div>
                <div class="col-16">
                  <div class="grid">
                    <div class="col-20">
                      <span class="user-name">
                        {{ user.name }}
                      </span>
                    </div>
                    <div class="col-20">
                      <app-ui-balance-widget [config]="balance"></app-ui-balance-widget>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
            <ng-template #elseTemplate>
              <div class="grid w-100">
                <div class="col-20">
                  <div class="login-btn">
                    <button
                      class="btn btn-sm btn-outline-primary"
                      (click)="onLogin()"
                    >
                      {{ 'Войти' }}
                    </button>
                  </div>
                </div>
              </div>
            </ng-template>
          </div>
          <div class="col-2 nav-size close-btn">
            <mat-icon
              (click)="onCloseMenu()"
              [svgIcon]="'close'"
            ></mat-icon>
          </div>
          <div
            class="col-20"
            *ngIf="user"
          >
            <div class="refill-btn">
              <button
                class="btn btn-sm btn-outline-primary"
                langRouterLink
                [routerLink]="['/balance/refill']"
                (click)="onCloseMenu()"
              >
                {{ 'Пополнить баланс' }}
              </button>
            </div>
          </div>
          <ng-container *ngFor="let link of links">
            <ng-container *ngIf="user; else notAuthRequiredLinks">
              <div class="col-20">
                <div
                  class="navigator-item"
                  langRouterLink
                  [routerLink]="link.path"
                  (click)="onCloseMenu()"
                >
                  <span>
                    {{ link.label }}
                  </span>
                  <mat-icon [svgIcon]="'arrow-right-up'"></mat-icon>
                </div>
              </div>
            </ng-container>
            <ng-template #notAuthRequiredLinks>
              <div
                class="col-20"
                *ngIf="!link.authRequired"
              >
                <div
                  class="navigator-item"
                  langRouterLink
                  [routerLink]="link.path"
                  (click)="onCloseMenu()"
                >
                  <span>
                    {{ link.label }}
                  </span>
                  <mat-icon [svgIcon]="'arrow-right-up'"></mat-icon>
                </div>
              </div>
            </ng-template>
          </ng-container>
          <div class="col-20">
            <div
              class="navigator-item"
              (click)="selectSetting(panels.MOBILE_LANG)"
            >
              {{ 'Язык' }}
              <mat-icon [svgIcon]="'arrow-right'"></mat-icon>
            </div>
          </div>
          <!-- <div class="col-20">
            <div
              class="navigator-item"
              (click)="selectSetting('theme')"
            >
              {{ 'Цветовая схема' }}
              <mat-icon [svgIcon]="'arrow-right'"></mat-icon>
            </div>
          </div> -->
          <div
            class="col-20"
            *ngIf="user"
          >
            <div
              class="navigator-item"
              (click)="selectSetting(panels.NOTIFICATIONS)"
            >
              {{ 'Нотификации' }}
              <mat-icon [svgIcon]="'arrow-right'"></mat-icon>
            </div>
          </div>
          <div class="col-20">
            <div
              class="navigator-item"
              (click)="selectSetting(panels.CHAT)"
            >
              {{ 'Чат' }}
              <mat-icon [svgIcon]="'arrow-right'"></mat-icon>
            </div>
          </div>
          <ng-container *ngIf="user">
            <div class="col-20">
              <mat-divider></mat-divider>
            </div>
            <div class="col-20">
              <div
                class="navigator-item"
                (click)="onLogout()"
              >
                {{ 'Выйти' }}
              </div>
            </div>
          </ng-container>
        </div>
      </ng-scrollbar>
    </div>

    <!-- <div
        class="grid"
        *ngIf="selectedSetting === 'theme'"
      >
        <div class="col-18 nav-size">
          <div (click)="selectSetting()">
            <mat-icon [svgIcon]="'chevron-left'"></mat-icon>
          </div>
        </div>
        <div class="col-2 nav-size close-btn">
          <div (click)="onCloseMenu()">
            <mat-icon [svgIcon]="'close'"></mat-icon>
          </div>
        </div>
        <div
          class="col-20"
          *ngFor="let theme of themeList"
        >
          <button
            class="btn btn-dark header-settings-dropdown-addition-item"
            (click)="selectTheme(theme)"
            [ngClass]="{ selected: currentTheme === theme }"
            [ngClass]="theme + '-theme'"
          >
            <span>{{ theme | uppercase }}</span>
            <span class="theme-selector-substrate"> </span>
          </button>
        </div>
      </div> -->
    <!-- <div
      class="grid"
      *ngIf="currentPanel === panels.MOBILE_LANG"
    >
      <div class="col-18 nav-size">
        <div (click)="selectSetting()">
          <mat-icon [svgIcon]="'chevron-left'"></mat-icon>
        </div>
      </div>
      <div class="col-2 nav-size close-btn">
        <div (click)="onCloseMenu()">
          <mat-icon [svgIcon]="'close'"></mat-icon>
        </div>
      </div>
      <div class="col-20"></div>
      <div class="col-20"></div>
    </div> -->
    <div
      class="wrapper__widget"
      *ngIf="currentPanel !== null"
    >
      <ng-container *ngIf="currentPanel === panels.CHAT">
        <ng-content select="app-chat"></ng-content>
      </ng-container>
      <ng-container *ngIf="currentPanel === panels.NOTIFICATIONS">
        <ng-content select="app-notifications"></ng-content>
      </ng-container>
    </div>
  </div>
</ng-container>
