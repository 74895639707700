import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stickerHash',
  standalone: true,
})
export class StickerHashPipe implements PipeTransform {
  public transform(value: string | null | undefined): string {
    if (!value) return '';
    if (!(value.startsWith('http') || value.startsWith('//'))) {
      return `https://steamcdn-a.akamaihd.net/apps/730/icons/econ/${value}`;
    }
    return value;
  }
}
