import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { P2pApiNewService } from '@dev-fast/backend-services';
import { IMarketplaceKitData, IP2pParticipateItemUpdated, IP2pPurchaseItem, OrderStatusEnum, Panel, SubPanel } from '@dev-fast/types';
import { Action, NgxsOnInit, Selector, State, StateContext, Store } from '@ngxs/store';
import { patch, removeItem, updateItem } from '@ngxs/store/operators';
import moment from 'moment';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { LocalStorageService } from '@app/core/local-storage-service';
import { NotificationsService } from '@app/core/notification-service';
import { ChangeActivePanel, LayoutState } from '@app/core/state/layout';
import { RefreshCurrentUser } from '@app/core/state/user-store';
import { PriorityList, sortBy, sortByPriority } from '@app/shared/utils';

import {
  ConfirmBid,
  CreateBid,
  DeleteBid,
  GetMyBids,
  MergePurchasing,
  RemoveItem,
  SetBidStatus,
  ToggleSelected,
  UpdateShowWarnValue,
} from './buy.actions';
import { P2P_BUYING_INITIAL_STATE, P2pBuyingStateModel } from './buy-state.model';

const priorityList: PriorityList = {
  high: [OrderStatusEnum.NEW, OrderStatusEnum.WAIT_FOR_TRADE, OrderStatusEnum.WAIT_FOR_CONFIRM, OrderStatusEnum.WAIT_FOR_BUYER_ACCEPT],
};
@State<P2pBuyingStateModel>({
  name: 'p2p_buy',
  defaults: P2P_BUYING_INITIAL_STATE,
})
@Injectable()
export class P2pBuyingState implements NgxsOnInit {
  @Selector()
  static purchasing({ purchasing }: P2pBuyingStateModel): IP2pPurchaseItem[] {
    return purchasing;
  }
  @Selector()
  static showWarn({ showWarn }: P2pBuyingStateModel): boolean {
    return showWarn;
  }

  constructor(
    private readonly _api: P2pApiNewService,
    private readonly _store: Store,
    private readonly _notificationsService: NotificationsService,
    private readonly _localStorage: LocalStorageService,
  ) {}

  ngxsOnInit({ getState, patchState, dispatch }: StateContext<P2pBuyingStateModel>): void {
    dispatch(new GetMyBids());

    const showWarn = this._localStorage.get('marketplace-showWarn');
    if (showWarn !== undefined) {
      patchState({ showWarn });
    }
    const elementInState = (id: number): IP2pPurchaseItem | undefined => {
      const { purchasing } = getState();
      return purchasing.find((el) => el.id === id);
    };
    this._api.participantItemUpdatedEvent((payload: IP2pParticipateItemUpdated) => {
      const element = elementInState(payload.id);
      if (
        payload.status &&
        element &&
        [OrderStatusEnum.WAIT_FOR_TRADE, OrderStatusEnum.CRATED].includes(element.status) &&
        [OrderStatusEnum.WAIT_FOR_TRADE, OrderStatusEnum.CRATED].includes(payload.status)
      ) {
        return;
      }
      this._store.dispatch(new MergePurchasing(payload.id, payload));
    });
    this._api.itemDeletedEvent((payload) => {
      if (elementInState(payload.id)) {
        this._store.dispatch(new RemoveItem(payload.id, true));
      }
    });
  }
  @Action(RefreshCurrentUser)
  refreshUser({ dispatch }: StateContext<P2pBuyingStateModel>, { payload }: RefreshCurrentUser): void {
    if (payload.id && payload.id !== null) {
      dispatch(new GetMyBids());
    }
  }
  @Action(UpdateShowWarnValue)
  updateShowWarnValue({ patchState }: StateContext<P2pBuyingStateModel>, { value }: UpdateShowWarnValue): void {
    this._localStorage.set('marketplace-showWarn', value);
    patchState({ showWarn: value });
  }

  @Action(GetMyBids)
  getMyBids({ patchState }: StateContext<P2pBuyingStateModel>): Observable<void | { kits: IP2pPurchaseItem[] }> {
    const now = moment();
    return this._api.reqPurchasing().pipe(
      tap((response) => {
        return patchState({
          purchasing: (sortByPriority(response.kits, 'status', 'statusAt', priorityList, 'asc') as IP2pPurchaseItem[]).filter(
            (value) => moment(value.nextStatusAt).diff(now, 'days') >= 0,
          ),
        });
      }),
      catchError((e) => this.onError(e)),
    );
  }
  @Action(ToggleSelected)
  toggleSelected({ patchState, getState, dispatch }: StateContext<P2pBuyingStateModel>, { payload }: ToggleSelected): void {
    const { purchasing } = getState();
    const has = purchasing.some((el) => el.id === payload.id);
    if (has) {
      dispatch(new RemoveItem(payload.id));
    } else {
      patchState({ purchasing: this.insertItemAtSortedArray(payload, purchasing, priorityList) });
      const { allowTradePanelAutoOpen, activePanel } = this._store.selectSnapshot(LayoutState);
      if ((activePanel === null || activePanel.panel !== Panel.TRADES) && allowTradePanelAutoOpen) {
        dispatch(new ChangeActivePanel({ panel: Panel.TRADES, subPanel: SubPanel.NONE }));
      }
    }
  }
  @Action(MergePurchasing)
  mergePurchasing({ setState }: StateContext<P2pBuyingStateModel>, { id, data }: MergePurchasing): void {
    setState(
      patch({
        purchasing: updateItem<IP2pPurchaseItem>(
          (o) => o?.id === id,
          patch({
            ...data,
            isActive: false,
          }),
        ),
      }),
    );
  }
  @Action(SetBidStatus)
  setBidStatus({ setState }: StateContext<P2pBuyingStateModel>, { id, isBidActive }: SetBidStatus): void {
    setState(
      patch({
        purchasing: updateItem<IP2pPurchaseItem>(
          (o) => o?.id === id,
          patch({
            isActive: isBidActive,
          }),
        ),
      }),
    );
  }
  @Action(CreateBid)
  createBid({ dispatch }: StateContext<P2pBuyingStateModel>, { id }: CreateBid): Observable<void> {
    dispatch(new SetBidStatus(id, true));
    return this._api.reqBid(id).pipe(
      catchError((e) => {
        dispatch(new SetBidStatus(id, false));
        this.onError(e);
        return of();
      }),
    );
  }
  @Action(ConfirmBid)
  confirmBid({ dispatch }: StateContext<P2pBuyingStateModel>, { id }: ConfirmBid): Observable<void | Partial<IP2pPurchaseItem>> {
    return this._api.reqConfirm(id).pipe(
      tap((response) => dispatch([new MergePurchasing(id, response)])),
      catchError((e) => this.onError(e)),
    );
  }
  @Action(DeleteBid)
  deleteBid({ dispatch }: StateContext<P2pBuyingStateModel>, { id }: DeleteBid): Observable<void> {
    return this._api.reqBidDelete(id).pipe(
      tap((r) => {
        return dispatch(new RemoveItem(id));
      }),
      catchError((e) => {
        return this.onError(e);
      }),
    );
  }
  @Action(RemoveItem)
  removeItem({ setState }: StateContext<P2pBuyingStateModel>, { id, isActive }: RemoveItem): void {
    setState(
      patch({
        purchasing: removeItem<IP2pPurchaseItem>((purchasingItem) => {
          return purchasingItem?.id === id && purchasingItem?.status !== OrderStatusEnum.WAIT_FOR_TRADE;
        }),
      }),
    );
  }

  private onError(e: HttpErrorResponse): Observable<void> {
    const { error } = e;
    this._notificationsService.addErrorNotification(error.message || error.error, {
      icon: 'warning',
      system: error.system,
      params: error?.params ?? {},
    });
    return of();
  }

  private insertItemAtSortedArray(
    itemToInsert: IMarketplaceKitData,
    arrayToInsert: IP2pPurchaseItem[],
    highPriorityStatuses: PriorityList,
  ): IP2pPurchaseItem[] {
    const sortedByPriority: {
      high: IP2pPurchaseItem[];
      unprioritized: IP2pPurchaseItem[];
    } = {
      high: [],
      unprioritized: [],
    };
    arrayToInsert.forEach((arrayItem) => {
      if (highPriorityStatuses.high && highPriorityStatuses.high.includes(arrayItem['status'])) {
        sortedByPriority.high.push(arrayItem);
      } else {
        sortedByPriority.unprioritized.push(arrayItem);
      }
    });
    return [...sortBy(sortedByPriority.high, 'statusAt'), itemToInsert, ...sortBy(sortedByPriority.unprioritized, 'statusAt')];
  }
}
