import { Injectable } from '@angular/core';
import { ICurrencyRate } from '@dev-fast/types';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';

import { CurrencyState } from '@app/core/state/currency-store';

@Injectable({
  providedIn: 'root',
})
export class CurrencyService {
  @Select(CurrencyState.currency)
  readonly currency$!: Observable<ICurrencyRate | null>;

  @Select(CurrencyState.currencyList)
  readonly currencyList$!: Observable<ICurrencyRate[]>;

  constructor(private readonly store: Store) {}

  convertBySelectedRateKey(key: string, value: number): number {
    const currencyList = this.store.selectSnapshot(CurrencyState.currencyList);

    const currencyRate = currencyList.find((c: ICurrencyRate) => c.key === key.toLowerCase());
    if (currencyRate) {
      return parseFloat((value * currencyRate.rate).toFixed(3));
    }
    return 0;
  }
  revertBySelectedRateKey(key: string, value: number): number {
    const currencyList = this.store.selectSnapshot(CurrencyState.currencyList);
    const currencyRate = currencyList.find((c: ICurrencyRate) => c.key === key.toLowerCase());
    if (currencyRate) {
      return parseFloat((value / currencyRate.rate).toFixed(0));
    }
    return 0;
  }
  convert(value: number): number {
    const currency = this.store.selectSnapshot(CurrencyState.currency);
    if (currency) {
      const { rate } = currency;
      return parseFloat((value * rate).toFixed(3));
    }
    return value;
  }
  revert(value: number): number {
    const currency = this.store.selectSnapshot(CurrencyState.currency);
    if (currency) {
      const { rate } = currency;
      return parseFloat((value / rate).toFixed(3));
    }
    return value;
  }
  convertCurrencyToCurrency(currKey: string, targetKey: string, amount: string | number = 0, rounding = 2): number {
    if (amount) {
      const currencyList = this.store.selectSnapshot(CurrencyState.currencyList);
      const currRate = currencyList.find((c) => c.key === currKey.toLowerCase())?.rate || 1;
      const targetRate = currencyList.find((c) => c.key === targetKey.toLowerCase())?.rate || 1;
      return +((currRate / targetRate) * +amount).toFixed(rounding);
    }
    return 0;
  }
  commissionToPay(value: string | number, payFee: number, action: 'add' | 'subtract'): string {
    const commissionCoeff = (100 - payFee) / 100;
    const result = action === 'add' ? +value / commissionCoeff : +value * commissionCoeff;
    return result.toString();
  }
}
