<ng-container
  *ngIf="{
    isMobile: isMobile$ | async,
    isPanelOpened: isPanelOpened$ | myAsync,
    inventoryItemsByIsMobile: inventoryItemsByIsMobile$ | async,
    inventoryItemsForMobile: inventoryItemsForMobile$ | async,
    participatedItems: participatedItems$ | async,
    participatedItemsSum: participatedItemsSum$ | async,
    selectedItems: selectedInventoryItems$ | async,
    newItemsSum: newItemsSum$ | async,
    newItemsCount: newItemsCount$ | async,
    gameSettings: currentGameSettings$ | async,
    loadingInventoryStatus: loadingInventoryStatus$ | async,
    maxInventoryPages: maxInventoryPages$ | async,
    actionBtnIsDisabled: actionBtnIsDisabled$ | async,
    temporaryTimer: temporaryTimer$ | async,
    temporaryItems: temporaryItems$ | async
  } as data"
>
  <ng-container *ngIf="isAuth()">
    <app-ui-mobile-wrapper
      *ngIf="data.isPanelOpened && data.isMobile"
      (closeWrapper)="closePanel()"
    />
    <div
      class="participation-panel flex fx-column gap-4 tw-rounded-2xl"
      [class.open]="data.isPanelOpened"
      [class.mobile-view]="data.isMobile"
    >
      <ng-container *ngIf="data.isPanelOpened">
        <app-participation-panel-items-mobile
          *ngIf="data.isMobile"
          [timestamp]="data.temporaryTimer"
          [inventoryItems]="data.inventoryItemsForMobile"
          [selectedItems]="data.selectedItems"
          [selectedItemsSum]="data.newItemsSum"
          [selectedItemsCount]="data.newItemsCount"
          [participatedItems]="data.participatedItems"
          [participatedItemsSum]="data.participatedItemsSum"
          [gameSettings]="data.gameSettings"
          (unselectItem)="selectInventoryItem($event)"
        />
        <app-participation-panel-filter
          [panelForm]="itemsFilterForm"
          [isMobile]="data.isMobile"
          (navigateToInventory)="navigateTo('/inventory')"
        />
        <div class="panel-items__wrapper">
          <ng-scrollbar
            #scrollbar
            class="scroll-y-offset-right"
            [track]="data.isMobile ? 'horizontal' : 'vertical'"
            (reachedBottom)="onReachedBottom(data.maxInventoryPages)"
            (reachedEnd)="onReachedEnd(data.maxInventoryPages)"
            [reachedOffset]="100"
          >
            <div
              class="panel-items"
              #scrollContent
            >
              <div
                class="panel-items__goto-exchange flex fx-all-center tw-cursor-pointer tw-rounded"
                [class.empty-inventory]="data.inventoryItemsByIsMobile?.length === 0"
              >
                <button
                  (click)="onExchange()"
                  class="flex fx-column fx-all-center gap-4"
                >
                  <mat-icon svgIcon="arrow-two-way" />
                  <span class="tw-font-medium"> {{ 'TRADE_PANEL.EXCHANGE' | translate }} </span>
                </button>
              </div>
              <div
                *ngIf="data.inventoryItemsByIsMobile?.length === 0 && data.loadingInventoryStatus === 'loaded'"
                class="panel-items__exchange flex fx-column fx-justify-center gap-4 pl-2 tw-font-normal"
              >
                <!-- TODO add locale -->
                <div class="panel-items__exchange-header">{{ 'Not enough items for bet' | translate }}</div>
                <div class="panel-items__exchange-text">{{ 'Use the exchange to replenish inventory' | translate }}</div>
              </div>
              <ng-container
                *ngIf="data.loadingInventoryStatus === 'loading_first' || data.loadingInventoryStatus === 'error'; else inventoryCardsTmpl"
              >
                <div class="tw-rounded loading-gradient"></div>
                <div class="tw-rounded loading-gradient"></div>
                <div class="tw-rounded loading-gradient"></div>
              </ng-container>
              <ng-template #inventoryCardsTmpl>
                <app-ui-item-simple-card
                  *ngFor="let item of data.inventoryItemsByIsMobile; trackBy: trackByInventoryItems"
                  class="tw-cursor-pointer"
                  [icon]="item.icon"
                  [name]="item.name"
                  [price]="item.price"
                  [color]="item.color"
                  [timestamp]="data.temporaryTimer"
                  [rarity]="item.rarity"
                  [status]="item.participateStatus"
                  (cardClick)="selectInventoryItem(item)"
                />
                <div
                  *ngIf="data.loadingInventoryStatus === 'loading_more'"
                  class="tw-rounded loading-gradient"
                ></div>
              </ng-template>
            </div>
          </ng-scrollbar>
        </div>
      </ng-container>
      <app-participation-panel-actions
        [isPrticipationPanelOpened]="data.isPanelOpened"
        [actionBtnIsDisabled]="data.actionBtnIsDisabled"
        [isMobile]="data.isMobile"
        [participatedItemsSum]="data.participatedItemsSum"
        [participatedItemsLength]="data.participatedItems?.length"
        [selectedItemsSum]="data.newItemsSum"
        [newItemsLength]="data.newItemsCount"
        [selectedItemsLength]="data.selectedItems?.length"
        [gameSettings]="data.gameSettings"
        (panelBtnAction)="proceedBtnAction(data.selectedItems)"
        (clearSelectedItems)="clearSelectedItems()"
      />
    </div>
    <div
      class="overlay"
      *ngIf="data.isPanelOpened"
      (click)="togglePanel()"
    ></div>
  </ng-container>
</ng-container>
