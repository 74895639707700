import { IconConfig } from '@dev-fast/types';

export const iconsFaucet: IconConfig[] = [
  {
    name: 'faucet',
    path: 'assets/img/mat-icons/faucet/faucet.svg',
  },
  {
    name: 'faucet-subscribe',
    path: 'assets/img/mat-icons/faucet/faucet-subscribe.svg',
  },
  {
    name: 'faucet-x6',
    path: 'assets/img/mat-icons/faucet/faucet-x6.svg',
  },
];
