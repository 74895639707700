import { NgClass, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, ElementRef, inject, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { IUserAvatarNickname, IUserAvatarPosition, ModalNames } from '@dev-fast/types';
import { Store } from '@ngxs/store';

import { OpenModal } from '@app/core/state/modals';
import { LazyLoadImageModule } from '@app/shared/directives';
import { IS_SERVER_TOKEN } from '@app/shared/utils';

import { winnerIconAnimation } from './user-avatar.animation';

@Component({
  selector: 'app-ui-user-avatar',
  templateUrl: './user-avatar.component.html',
  styleUrls: ['./user-avatar.component.scss'],
  animations: [winnerIconAnimation],
  standalone: true,
  imports: [MatIconModule, LazyLoadImageModule, NgClass, NgIf],
  providers: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserAvatarComponent {
  get type(): string {
    return this.#type;
  }

  @Input() set type(val: string | undefined) {
    if (val) {
      this.#type = val;
    }
  }

  get url(): string {
    return this.#image;
  }

  @Input() set url(url: string) {
    this.#image = this.computeUserAvatar(url);
  }

  get position(): IUserAvatarPosition {
    return this.username?.position || 'right';
  }

  @Input() fallback = '//d2lomvz2jrw9ac.cloudfront.net/avatars/avatar-fallback_2.png';
  @Input() alt = '';
  @Input() level?: number = 0;
  @Input() levelLabelOn = false;
  @Input() levelUpAnimation?: boolean = false;
  @Input() newLevel?: number = 1;
  @Input() notRound?: boolean;
  @Input() noBorder?: boolean;
  @Input() noBorderline?: boolean;
  @Input() noModal?: boolean;
  @Input() noChevron?: boolean;
  @Input() winnerIcon?: 'winner' | 'joker';
  // @Input() public isWinner?: boolean;
  @Input() additionalIcon?: string;
  @Input() username?: IUserAvatarNickname;
  @Input() userId?: number | null;

  #type = '';
  #image!: string;
  #dimensions!: number;

  readonly #elementRef = inject(ElementRef);
  readonly #store = inject(Store);
  readonly #isServer = inject(IS_SERVER_TOKEN);

  onImageError(): void {
    this.url = this.fallback;
  }

  computeUserAvatar(url: string): string {
    if (this.#isServer || !url) {
      return this.fallback;
    }
    this.#dimensions = this.computeComponentDimensions();
    if (url.startsWith('data:') || url.startsWith('http') || url.startsWith('//')) {
      if ((url || '').startsWith('https://din8r827idtuo.cloudfront.net/')) {
        const [source, ...query] = url.split('?');
        query.push(`width=${this.#dimensions}`);
        query.push(`height=${this.#dimensions}`);
        // ToDo: move it out
        return source.replace('din8r827idtuo.cloudfront.net', 'de4khei8i4ut2.cloudfront.net') + `?${query.join('&')}`;
      }
      if (url.startsWith('https://api.adorable.io/avatars/')) {
        const avatar = url.split('/');
        const path = avatar[avatar.length - 1];
        return `https://api.adorable.io/avatars/${this.#dimensions}/${path}`;
      }
      return url;
    }

    return `https://steamcdn-a.akamaihd.net/steamcommunity/public/images/avatars/${url}_full.jpg`;
  }

  computeComponentDimensions(): number {
    if (this.#isServer) {
      return 0;
    }

    const host = getComputedStyle(this.#elementRef.nativeElement);
    const size = host.getPropertyValue('--size');
    const [, dimensions, type] = size.split(/([0-9]+)/);
    const width = 96;
    const dimensionsNumber = isNaN(Number(dimensions)) ? width : Number(dimensions);
    if (type === 'rem') {
      const calculated = dimensionsNumber * 16;
      return calculated < width ? width : calculated;
    }
    return Math.max(width, dimensionsNumber);
  }

  openProfile(event: Event, userId?: number | null): void {
    if (userId && !this.noModal) {
      this.#store.dispatch(new OpenModal(ModalNames.USER_PROFILE, { userId }));
    }
  }
}
