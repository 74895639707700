import { Injectable } from '@angular/core';
import {
  BreakpointsTypes,
  IFilterMethod,
  IHistoryInventoryItem,
  IHistoryParams,
  IInventoryItem,
  IInventoryRequestParams,
  IInventoryShortInfo,
  IShopMeta,
  ISkinItem,
  IUserDetailed,
  IWithdrawalItems,
  IWithdrawalRequest,
  IWithdrawalRequestParams,
  MarketSortingTypes,
  ModalNames,
  WalletTypeEnum,
} from '@dev-fast/types';
import { Select } from '@ngxs/store';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { combineLatest, map, Observable } from 'rxjs';

import { AuthState } from '@app/auth';
import { CurrencyService } from '@app/core/currency';
import {
  ApplyInventoryFilters,
  ChangeHistoryParams,
  ChangeInventoryPage,
  ChangeParamsInventory,
  ChangeParamsShop,
  ChangeShopPage,
  ClearPreSearchItemsLength,
  ClearTotalSum,
  ClickOnInventoryItem,
  ClickOnShopItem,
  FreezeItems,
  GetInventoryItems,
  GetShopItems,
  GetWithdrawalItems,
  InventoryState,
  Purchase,
  Refresh,
  RequestInventoryHistory,
  SellAllItems,
  SellItems,
  ToggleAllInventoryItems,
  ToggleIsSelectAll,
  Trade,
  UnselectItems,
  UpdateTotalSum,
  WithdrawItems,
} from '@app/core/state/inventory';
import { LayoutState, ToggleBackground } from '@app/core/state/layout';
import { CloseModal, ModalsState, OpenModal } from '@app/core/state/modals';
import { UserState } from '@app/core/state/user-store';
import { CommonStoreService } from '@app/core-state-common';

@Injectable({ providedIn: 'root' })
export class InventoryService {
  @Select(ModalsState.activeModals)
  readonly activeModals$!: Observable<any>;

  @Select(LayoutState.breakpoints)
  readonly breakpoints$!: Observable<BreakpointsTypes | null>;

  @Select(AuthState.isAuth)
  readonly isAuth$!: Observable<boolean>;

  @Select(UserState.user)
  readonly user$!: Observable<IUserDetailed | null | undefined>;

  @Select(InventoryState.inventoryShortInfo)
  readonly inventoryShortInfo$!: Observable<IInventoryShortInfo>;

  @Select(InventoryState.items)
  readonly items$!: Observable<IInventoryItem[]>;

  @Select(InventoryState.selectedItems)
  readonly selectedItems$!: Observable<IInventoryItem[]>;

  @Select(InventoryState.itemsCount)
  readonly itemsCount$!: Observable<number>;

  @Select(InventoryState.currentSum)
  readonly currentSum$!: Observable<number>;

  @Select(InventoryState.historyItems)
  readonly historyItems$!: Observable<IHistoryInventoryItem[]>;

  @Select(InventoryState.historyItemsLoading)
  readonly historyItemsLoading$!: Observable<boolean>;

  @Select(InventoryState.shopItems)
  readonly shopItems$!: Observable<ISkinItem[]>;

  @Select(InventoryState.contracts)
  readonly contracts$!: Observable<ISkinItem[]>;

  @Select(InventoryState.shopParams)
  readonly shopParams$!: Observable<IInventoryRequestParams>;

  @Select(InventoryState.actionsDisabled)
  readonly actionsDisabled$!: Observable<boolean>;

  @Select(InventoryState.inventoryParams)
  readonly inventoryParams$!: Observable<IInventoryRequestParams>;

  @Select(InventoryState.maxInventoryPages)
  readonly maxInventoryPages$!: Observable<number | null>;

  @Select(InventoryState.maxFilterPages)
  readonly maxFilterPages$!: Observable<number | null>;

  @Select(InventoryState.shopMeta)
  readonly shopMeta$!: Observable<IShopMeta>;

  @Select(InventoryState.maxShopPages)
  readonly maxShopPages$!: Observable<number | null>;

  @Select(InventoryState.selectionAmount)
  readonly selectionAmount$!: Observable<number | null>;

  @Select(InventoryState.historyParams)
  readonly historyParams$!: Observable<IHistoryParams | null>;

  @Select(InventoryState.historyCount)
  readonly historyCount$!: Observable<number | null>;

  @Select(InventoryState.isSelectAll)
  readonly isSelectAll$!: Observable<boolean>;

  @Select(InventoryState.currentFiltersAllSold)
  readonly currentFiltersAllSold$!: Observable<boolean>;

  @Select(InventoryState.preSearchItemsLength)
  preSearchCount$!: Observable<number | null>;

  @Select(InventoryState.totalSum)
  totalSum$!: Observable<number | null>;

  @Select(InventoryState.preliminarySum)
  preliminarySum$!: Observable<number | null>;

  @Select(InventoryState.isItemsLoading)
  isLoading$!: Observable<boolean>;

  @Select(InventoryState.sortingMethods)
  sortingMethods$!: Observable<IFilterMethod<MarketSortingTypes>[] | null>;

  @Select(InventoryState.withdrawalList)
  withdrawalList$!: Observable<IWithdrawalItems | null>;

  readonly userBalance$: Observable<number> = this.commonService.getWalletById(WalletTypeEnum.BALANCE);
  readonly currentSelectedItemsSum$: Observable<number> = this.selectedItems$.pipe(
    map((items) => items.reduce((acc, item) => acc + item.price, 0)),
  );
  readonly selectedShopItemsPrice$: Observable<number> = this.contracts$.pipe(
    map((items) => items.reduce((acc, item) => acc + item.price, 0)),
  );
  readonly userBalanceDelta$: Observable<number> = combineLatest([
    this.currentSelectedItemsSum$,
    this.selectedShopItemsPrice$,
    this.isSelectAll$,
    this.inventoryShortInfo$,
  ]).pipe(
    map(([inventorySelectedSum, shopSelectedSum, isSelectAll, inventoryShortInfo]) => {
      return (isSelectAll ? inventoryShortInfo.inventorySum : inventorySelectedSum) - shopSelectedSum;
    }),
  );
  convertValue = (number: number): number => {
    return this.currencyService.convert(number);
  };
  constructor(
    private readonly commonService: CommonStoreService,
    private readonly currencyService: CurrencyService,
  ) {}

  @Dispatch() getWithdrawalItems = (params: IWithdrawalRequestParams): GetWithdrawalItems => new GetWithdrawalItems(params);
  @Dispatch() withdrawItems = (withdrawalRequest: IWithdrawalRequest): WithdrawItems => new WithdrawItems(withdrawalRequest);
  @Dispatch() applyFilters = (): ApplyInventoryFilters => new ApplyInventoryFilters();
  @Dispatch() toggleBackground = (background?: string): ToggleBackground => new ToggleBackground(background);
  @Dispatch() getInventoryHistory = (): RequestInventoryHistory => new RequestInventoryHistory();
  @Dispatch() getInventoryItems = (params?: any, buffer = false): GetInventoryItems => new GetInventoryItems(params, buffer);
  @Dispatch() onItemClick = (id: number): ClickOnInventoryItem => new ClickOnInventoryItem(id);
  @Dispatch() onShopItemClick = (id: number): ClickOnShopItem => new ClickOnShopItem(id);
  @Dispatch() toggleAllInventoryItems = (): ToggleAllInventoryItems => new ToggleAllInventoryItems();
  @Dispatch() sellItems = (ids: number[]): SellItems => new SellItems(ids);
  @Dispatch() openModal = (modal: ModalNames, params?: any): OpenModal => new OpenModal(modal, params);
  @Dispatch() closeModal = (modal: ModalNames): CloseModal => new CloseModal(modal);
  @Dispatch() getShop = (design?: 'old' | 'new'): GetShopItems => new GetShopItems(design);
  @Dispatch() changeParams = (params: IInventoryRequestParams): ChangeParamsInventory => new ChangeParamsInventory(params);
  @Dispatch() changeShopParams = (params: IInventoryRequestParams, design?: 'old' | 'new'): ChangeParamsShop =>
    new ChangeParamsShop(params);
  @Dispatch() refresh = (payload: 'inventory' | 'trade'): Refresh => new Refresh(payload);
  @Dispatch() trade = (): Trade => new Trade();
  @Dispatch() changeShopPage = (page: number): ChangeShopPage => new ChangeShopPage(page);
  @Dispatch() changeInventoryPage = (page: number): ChangeInventoryPage => new ChangeInventoryPage(page);
  @Dispatch() unselectItems = (payload?: 'shop' | 'inventory'): UnselectItems => new UnselectItems(payload);
  @Dispatch() sellAll = (useTimer = false, useFilters = false): SellAllItems => new SellAllItems(useTimer, useFilters);
  @Dispatch() changeHistoryParams = (payload: IHistoryParams): ChangeHistoryParams => new ChangeHistoryParams(payload);
  @Dispatch() toggleIsSelectAll = (): ToggleIsSelectAll => new ToggleIsSelectAll();
  @Dispatch() freezeItems = (ids: number[]): FreezeItems => new FreezeItems(ids);
  @Dispatch() purchase = (ids: number[], userInventoryIds: number[]): Purchase => new Purchase(ids, userInventoryIds);
  @Dispatch() clearPreSearchItemsLength = (): ClearPreSearchItemsLength => new ClearPreSearchItemsLength();
  @Dispatch() clearTotalSum = (): ClearTotalSum => new ClearTotalSum();
  @Dispatch() updateTotalSum = (): UpdateTotalSum => new UpdateTotalSum();
}
