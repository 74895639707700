import { AnimationSpeed, SoundNameFast } from '@dev-fast/types';

export class ToggleSoundState {
  static readonly type = '[Cases settings] toggle sound state';
  constructor(public soundEnabled: boolean) {}
}
export class SetActiveSound {
  static readonly type = '[Cases settings] set active sound';
  constructor(public sound?: SoundNameFast) {}
}
export class SetAnimationSpeed {
  static readonly type = '[Cases settings] Set animation speed';
  constructor(public speed?: AnimationSpeed) {}
}
export class ToggleControlPanel {
  static readonly type = '[Cases settings] toggle control panel';
  constructor(public controlPanelHidden: boolean) {}
}
