import { IGameDetail } from '../models';
import { AgentKeys } from './agent-keys.enum';

export enum GameTypes {
  CLASSIC = 1,
  FAST = 2,
  DOUBLE = 3,
  DUEL = 4,
  SLOT = 5,
  CSGO_BOTS = 6,
  CRASH = 7,
  CRAFT = 9,
  CASES = 10,
  X50 = 13,
  TOWER = 14,
  WHEEL = 15,
  HILO = 16,
  POGGI = 19,
  BACCARAT = 18,
  CASE_BATTLE = 19,
  SHOP = 33,
  INVENTORY = 34,
}

export enum GameTypesNames {
  CLASSIC = 'CLASSIC',
  FAST = 'FAST',
  DOUBLE = 'DOUBLE',
  DUEL = 'DUEL',
  SLOT = 'SLOT',
  CSGO_BOTS = 'CSGO BOTS',
  CRASH = 'CRASH',
  CRAFT = 'CRAFT',
  CASES = 'CASES',
  CASE_BATTLE = 'CASE_BATTLE',
  X50 = 'X50',
  TOWER = 'TOWER',
  WHEEL = 'WHEEL',
  HILO = 'HILO',
  POGGI = 'POGGI',
  BACCARAT = 'BACCARAT',
  SHOP = 'SHOP',
  INVENTORY = 'INVENTORY',
}

export const GAMES_DETAILS: Record<string, IGameDetail> = {
  classic: {
    background: 'rgba(255, 213, 44, 0.30)',
    hover: 'rgba(255, 213, 44, 0.50)',
    label: 'Classic',
    icon: 'assets/img/mat-icons/left-sidebar/classic.webp',
    agentKey: AgentKeys.CLASSIC_GAME,
    gameType: GameTypes.CLASSIC,
  },
  fast: {
    background: 'rgba(252, 63, 30, 0.30)',
    hover: 'rgba(252, 63, 30, 0.50)',
    label: 'Fast',
    icon: 'assets/img/mat-icons/left-sidebar/fast.webp',
    agentKey: AgentKeys.FAST_GAME,
    gameType: GameTypes.FAST,
  },
  double: {
    background: 'linear-gradient(158deg, rgba(159, 2, 243, 0.30) 0%, rgba(23, 43, 188, 0.30) 100%)',
    hover: 'linear-gradient(158deg, rgba(159, 2, 243, 0.50) 0%, rgba(23, 43, 188, 0.50) 100%)',
    label: 'Double',
    icon: 'assets/img/mat-icons/left-sidebar/double.webp',
    agentKey: AgentKeys.DOUBLE_GAME,
    gameType: GameTypes.DOUBLE,
  },
  hilo: {
    background: 'linear-gradient(180deg, rgba(254, 167, 1, 0.20) 27.08%, rgba(48, 36, 198, 0.20) 80.73%)',
    hover: 'linear-gradient(180deg, rgba(254, 167, 1, 0.30) 27.08%, rgba(48, 36, 198, 0.30) 80.73%)',
    label: 'HI / LO',
    icon: 'assets/img/mat-icons/left-sidebar/hilo.webp',
    agentKey: AgentKeys.HILO_GAME,
    gameType: GameTypes.HILO,
  },
  crash: {
    background: 'linear-gradient(138deg, rgba(148, 97, 251, 0.30) 0%, rgba(9, 105, 222, 0.30) 100%)',
    hover: 'linear-gradient(138deg, rgba(148, 97, 251, 0.50) 0%, rgba(9, 105, 222, 0.50) 100%)',
    label: 'Crash',
    icon: 'assets/img/mat-icons/left-sidebar/crash.webp',
    agentKey: AgentKeys.CRASH_GAME,
    gameType: GameTypes.CRASH,
  },
  slot: {
    background: 'linear-gradient(43deg, rgba(255, 206, 83, 0.30) 0%, rgba(22, 23, 31, 0.30) 100%)',
    hover: 'linear-gradient(43deg, rgba(255, 206, 83, 0.50) 0%, rgba(22, 23, 31, 0.50) 100%)',
    label: 'Slot',
    icon: 'assets/img/mat-icons/left-sidebar/slots.webp',
    agentKey: AgentKeys.SLOTS_GAME,
    gameType: GameTypes.SLOT,
  },
  tower: {
    background: 'rgba(5, 140, 255, 0.20)',
    hover: 'rgba(5, 140, 255, 0.40)',
    label: 'Tower',
    icon: 'assets/img/mat-icons/left-sidebar/tower.webp',
    agentKey: AgentKeys.TOWER_GAME,
    gameType: GameTypes.TOWER,
  },
  cases: {
    background: 'linear-gradient(157deg, rgba(123, 20, 161, 0.30) 0%, rgba(64, 2, 247, 0.30) 100%)',
    hover: 'linear-gradient(157deg, rgba(123, 20, 161, 0.50) 0%, rgba(64, 2, 247, 0.50) 100%)',
    label: 'Cases',
    icon: 'assets/img/mat-icons/left-sidebar/case.webp',
    agentKey: AgentKeys.CASES_GAME,
    gameType: GameTypes.CASES,
  },
  'fortune-wheel': {
    background: 'linear-gradient(134deg, rgba(255, 83, 104, 0.20) 0%, rgba(8, 244, 245, 0.20) 100%)',
    hover: 'linear-gradient(134deg, rgba(255, 83, 104, 0.30) 0%, rgba(8, 244, 245, 0.30) 100%)',
    label: 'Wheel',
    icon: 'assets/img/mat-icons/left-sidebar/wheel.webp',
    agentKey: AgentKeys.WHEEL_GAME,
    gameType: GameTypes.WHEEL,
  },
  baccarat: {
    background: 'linear-gradient(126deg, rgba(0, 186, 217, 0.20) 0%, rgba(57, 182, 3, 0.20) 51.01%, rgba(240, 81, 41, 0.20) 100%)',
    hover: 'linear-gradient(126deg, rgba(0, 186, 217, 0.40) 0%, rgba(57, 182, 3, 0.40) 51.01%, rgba(240, 81, 41, 0.40) 100%)',
    label: 'Baccarat',
    icon: 'assets/img/mat-icons/left-sidebar/baccarat.webp',
    agentKey: AgentKeys.BACCARAT_GAME,
    gameType: GameTypes.BACCARAT,
  },
  poggi: {
    background: 'linear-gradient(126deg, rgba(196, 178, 119, 0.20) 0%, rgba(144, 127, 85, 0.20) 100%)',
    hover: 'linear-gradient(126deg, rgba(196, 178, 119, 0.40) 0%, rgba(144, 127, 85, 0.40) 100%)',
    label: 'Poggi',
    icon: 'assets/img/mat-icons/left-sidebar/poggi.webp',
    agentKey: AgentKeys.POGGI_GAME,
    gameType: GameTypes.POGGI,
  },
  x50: {
    background: 'linear-gradient(126deg, rgba(255, 168, 3, 0.20) 0%, rgba(255, 77, 2, 0.20) 100%)',
    hover: 'linear-gradient(126deg, rgba(255, 168, 3, 0.40) 0%, rgba(255, 77, 2, 0.40) 100%)',
    label: 'x50',
    icon: 'assets/img/mat-icons/left-sidebar/x50.webp',
    agentKey: AgentKeys.X50_GAME,
    gameType: GameTypes.X50,
  },
  'case-battle': {
    background: 'linear-gradient(95deg, rgba(255, 209, 26, 0.20) 0%, rgba(204, 37, 47, 0.20) 100%)',
    hover: 'linear-gradient(95deg, rgba(255, 209, 26, 0.40) 0%, rgba(204, 37, 47, 0.40) 100%)',
    label: 'Case Battle',
    icon: 'assets/img/mat-icons/left-sidebar/case-battle.webp',
    agentKey: AgentKeys.CASE_BATTLE,
    gameType: GameTypes.CASE_BATTLE,
  },
};
