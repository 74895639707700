import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, NgModule, OnInit, Output } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { ActivePanel, ICurrencyRate, ILocales, IUserDetailed, MenuLink, Panel, SubPanel, Themes } from '@dev-fast/types';
import { TranslateModule } from '@ngx-translate/core';
import { NgScrollbarModule } from 'ngx-scrollbar';

import { LangRouterModule } from '@app/shared/directives';
import { UiGridModule } from '@app/ui/grid';

import { BalanceWidgetConfig } from '../balance-widget';
import { BalanceWidgetComponentModule } from '../balance-widget/balance-widget.component';
import { UserAvatarModule } from '../user-avatar';

@Component({
  selector: 'app-ui-mobile-menu',
  templateUrl: './mobile-menu.component.html',
  styleUrls: ['./mobile-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MobileMenuComponent implements OnInit {
  @Input() links: MenuLink[];
  @Input() balance: BalanceWidgetConfig;

  @Input() soundState: boolean;
  @Input() user: IUserDetailed | null;
  @Input() canLevelUp: boolean;

  @Input() currentTheme: Themes | undefined;
  @Input() themeList: Themes[];

  @Input() currentLang: ILocales | undefined;
  @Input() langList: ILocales[];

  @Input() currentCurrency: ICurrencyRate | undefined;
  @Input() currencyList: ICurrencyRate[];

  @Input() currentPanel: Panel | undefined | null;

  @Output() closeClick: EventEmitter<void>;
  @Output() changeTheme: EventEmitter<Themes>;
  @Output() changeLang: EventEmitter<ILocales>;
  @Output() changeCurrency: EventEmitter<ICurrencyRate>;
  @Output() changePanel: EventEmitter<ActivePanel | null>;

  @Output() login: EventEmitter<void>;
  @Output() logout: EventEmitter<void>;

  isMenuShowed: boolean;
  menuItems: string[];
  panels = Panel;

  // public selectedSetting: string;
  constructor() {
    this.links = [];
    this.isMenuShowed = false;
    this.menuItems = [];
    // this.selectedSetting = '';
    this.themeList = [];
    this.langList = [];
    this.currencyList = [];
    this.closeClick = new EventEmitter(false);
    this.changeTheme = new EventEmitter(false);
    this.changeLang = new EventEmitter(false);
    this.changeCurrency = new EventEmitter(false);
    this.login = new EventEmitter(false);
    this.logout = new EventEmitter(false);
    this.changePanel = new EventEmitter(false);
    this.soundState = false;
    this.canLevelUp = false;
    this.user = null;
    this.balance = {
      countCoins: 0,
      countSkins: 0,
    };
  }
  ngOnInit(): void {
    //FIXME брать available из стейта
    this.menuItems = ['Язык', 'Цветовая схема', 'Нотификации', 'Чат'];
  }

  toggleMenu = (): void => {
    this.isMenuShowed = !this.isMenuShowed;
  };
  onCloseMenu = (): void => {
    this.isMenuShowed = false;
    this.selectSetting();
  };

  selectLocale = (locale: ILocales): void => {
    this.changeLang.next(locale);
  };

  selectCurrency = (currency: ICurrencyRate): void => {
    this.changeCurrency.next(currency);
  };

  selectTheme = (theme: Themes): void => {
    this.changeTheme.next(theme);
  };
  onLogin = (): void => {
    this.login.emit();
    this.toggleMenu();
  };

  onLogout = (): void => {
    this.logout.emit();
    this.toggleMenu();
  };
  selectSetting = (setting?: Panel | null): void => {
    if (!setting || this.currentPanel === setting) {
      // this.selectedSetting = '';
      this.changePanel.emit(null);
    } else {
      // this.selectedSetting = setting;
      this.changePanel.emit({ panel: setting, subPanel: SubPanel.NONE });
    }
  };
}

@NgModule({
  imports: [
    CommonModule,
    UiGridModule,
    MatIconModule,
    BalanceWidgetComponentModule,
    RouterModule,
    TranslateModule,
    MatDividerModule,
    UserAvatarModule,
    NgScrollbarModule,
    LangRouterModule,
  ],
  declarations: [MobileMenuComponent],
  exports: [MobileMenuComponent],
})
export class MobileMenuComponentModule {}
