/**
 * Информация о фильтре
 */
export interface FilterInfo {
  value: string | number;
  matchMode: string;
}

export interface Filters {
  [field: string]: FilterInfo;
}

/**
 * Информация о фильтрах
 */
export interface Filtering {
  filters: Filters;
}

/**
 * Метод для специальной сортировки
 */
export type SpecialFilter<TSource> = (item: TSource, filterInfo: FilterInfo) => boolean;

/**
 * Информация о специальных сортировках
 */
export interface SpecialFilters<TSource> {
  [fieldName: string]: SpecialFilter<TSource>;
}
