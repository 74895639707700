<ng-scrollbar
  track="vertical"
  class="history-scrollbar hide-scrollbar"
>
  <div class="history-content m-a">
    <ng-container *ngIf="historyDates.length; else noHistoryTemplate">
      <div *ngFor="let day of historyDates; let index = index; trackBy: trackingFunctionDates">
        <ng-container *ngIf="formatedItems[index]">
          <div class="history-content__date">{{ day }}</div>
          <div class="history-content__items">
            <app-ui-history-item
              *ngFor="let historyItem of formatedItems[index]; trackBy: trackingFunction"
              [item]="historyItem"
            ></app-ui-history-item>
          </div>
        </ng-container>
      </div>
      <div
        class="pagination-viewport"
        inViewport
        [inViewportOptions]="{ threshold: [0] }"
        (inViewportAction)="onScrollToBottom($event)"
      ></div>
    </ng-container>
    <ng-template #noHistoryTemplate>
      <mat-spinner
        [strokeWidth]="2"
        [diameter]="24"
        [color]="'primary'"
        *ngIf="loading; else empty"
      />
      <ng-template #empty>
        <app-ui-not-found-stub
          class="tw-font-medium"
          image="/assets/img/not-found-ufo.png"
          message="P2P_SETTINGS.EMPTY_HISTORY"
        />
      </ng-template>
    </ng-template>
  </div>
</ng-scrollbar>
