import { IClassicStateDto } from '../../../../interfaces/games/classic/dto/classic-state-dto.interface';

export type TClassicStateGameDto = Pick<
  IClassicStateDto,
  'biggestBet' | 'lastWinner' | 'luckyPlayer' | 'md5' | 'number' | 'prize' | 'rand' | 'tape' | 'timeOldEnds' | 'trades' | 'winner'
>;

export type TClassicStateParticipantDto = Pick<IClassicStateDto, 'prize' | 'rafflingTimestampDiff' | 'skin' | 'timeOldEnds' | 'trades'>;

export type TClassicStateWinnerDto = Pick<
  IClassicStateDto,
  'idsTape' | 'rafflingTimestampDiff' | 'rand' | 'tape' | 'timeOldEnds' | 'winner'
>;
