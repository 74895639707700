<ng-container *ngIf="content">
  <div
    class="wrapper"
    @expandAnimation
  >
    <div
      class="close-row"
      (tuiSwipe)="onSwipe($event)"
    >
      <button class="close-btn"></button>
    </div>
    <ng-container *ngComponentOutlet="content"></ng-container>
  </div>
</ng-container>
