import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { IFilterFormTemplate } from '@dev-fast/types';

import { IDFormControlCheckboxList } from '@app/core/dynamic-forms-service';

@Component({
  selector: 'app-ui-filter-sub-panel',
  templateUrl: './filter-sub-panel.component.html',
  styleUrls: ['./filter-sub-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterSubPanelComponent {
  @Input() filterForm: FormGroup<IFilterFormTemplate> | undefined;
  @Input() checkboxList!: IDFormControlCheckboxList;
  @Input() controls: FormControl[] = [];
  @Input() filterPanelHeight = ''; // Высота всего блока фильтров (Костыль)
  @Input() categoryTitle = ''; // Заголовок всей категории

  @Output() closePanel = new EventEmitter<void>();
  @Output() selected = new EventEmitter<number>();

  selectedData: { [key: string]: string[] } = {};

  @HostBinding(`style.--items-filter-sub-panel-height`)
  get height(): string {
    return this.filterPanelHeight;
  }

  closeSubPanel(): void {
    this.closePanel.emit();
  }
  onPayloadValueChange(index: number): void {
    this.selected.emit(index);
  }
}
