import { Location } from '@angular/common';
import { ChangeDetectionStrategy, Component, DestroyRef, inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { IMarketplaceKitData, IP2pDepositItem, MarketPanel } from '@dev-fast/types';
import { merge, Observable } from 'rxjs';
import { map, skipWhile } from 'rxjs/operators';

import { P2pBuyingService, P2pDepositService } from '@app/modules/p2p-new';

@Component({
  selector: 'app-p2p',
  templateUrl: './p2p.component.html',
  styleUrls: ['./p2p.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class P2PWidgetComponent implements OnInit {
  readonly #destroyRef = inject(DestroyRef);
  readonly #location = inject(Location);
  readonly #p2pDepositService = inject(P2pDepositService);
  readonly #p2pBuyingService = inject(P2pBuyingService);
  readonly selected$: Observable<IP2pDepositItem[]> = this.#p2pDepositService.selected$;
  readonly purchasing$: Observable<IMarketplaceKitData[]> = this.#p2pBuyingService.purchasing$;

  activeTab: MarketPanel = this.#location.path().includes('store') ? MarketPanel.Purchase : MarketPanel.Sell;

  ngOnInit(): void {
    // FIXME всю логику по определению активной вкладки возложить на стейт, тут только отображать
    merge(
      this.purchasing$.pipe(
        skipWhile((purchasing) => purchasing.length === 0),
        map(() => MarketPanel.Purchase),
      ),
      this.selected$.pipe(
        skipWhile((selected) => selected.length === 0),
        map(() => MarketPanel.Sell),
      ),
    )
      .pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe((value) => (this.activeTab = value));
  }
}
