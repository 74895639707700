import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { P2pSettingsComponent } from './p2p-settings.component';
import { P2pSettingsService } from './p2p-settings.service';

@NgModule({
  declarations: [P2pSettingsComponent],
  imports: [CommonModule, ReactiveFormsModule],
  exports: [P2pSettingsComponent],
  providers: [P2pSettingsService],
})
export class P2pSettingsModule {}
