//The size number reflect max-width of breakpoint
export interface IBreakpoints {
  xs: number;
  s: number;
  m: number;
  l: number;
  xl: number;
  xxl: number;
  xxxl: number;
}

export const BREAKPOINTS: IBreakpoints = {
  xs: 320,
  s: 480,
  m: 768,
  l: 1024,
  xl: 1280,
  xxl: 1684,
  xxxl: 2024,
};
