<div
  class="autocomplete__wrapper"
  [formGroup]="autocompleteFormGroup"
  (clickOutside)="toggleMenu(false)"
>
  <div class="autocomplete__input-wrapper">
    <div class="autocomplete__prefix-wrapper">
      <ng-container *ngIf="selectedItem && selectedItem.icon">
        <ng-container *ngIf="isMenuOpened || !selectedItem; else image">
          <mat-icon
            class="autocomplete__prefix-icon"
            [svgIcon]="'peer-to-peer-edit-icon'"
          />
        </ng-container>
        <ng-template #image>
          <ng-container *ngIf="selectedItem.icon.type === 'image'">
            <img
              class="autocomplete__prefix-image"
              [class]="selectedItem.icon.type"
              [src]="selectedItem.icon.src"
            />
          </ng-container>
          <ng-container *ngIf="selectedItem.icon.type === 'icon'">
            <mat-icon
              class="autocomplete__prefix-icon"
              [class]="selectedItem.icon.type"
              [svgIcon]="selectedItem.icon.src"
            />
          </ng-container>
        </ng-template>
      </ng-container>
    </div>
    <input
      class="autocomplete__input"
      type="text"
      [class.autocomplete__input_focused]="isMenuOpened"
      formControlName="autocompleteControl"
      [placeholder]="placeholder"
      autocomplete="off"
      (click)="toggleMenu(true, $event)"
    />
    <button
      class="autocomplete__toggle-button"
      [class.autocomplete__toggle-button_opened]="isMenuOpened"
      (click)="toggleMenu(!isMenuOpened, $event)"
      [disabled]="disabled"
    >
      <mat-icon
        class="autocomplete__arrow-icon"
        [svgIcon]="'arrow-left-design'"
      />
    </button>
  </div>
  <ng-container *ngIf="isMenuOpened">
    <app-ui-autocomplete-list
      [menuItems]="sortedItems()"
      [selectedItem]="selectedItem"
      [elementsVisible]="elementsVisible"
      [scrollToTop$]="scrollToTop$"
      (onselectItem)="selectItem($event)"
      @expandAnimation
    />
  </ng-container>
</div>
