<div class="sih-modal__main-content">
  <div
    class="sih-modal__close-btn"
    (click)="onClose()"
  >
    <mat-icon svgIcon="close"></mat-icon>
  </div>
  <ng-container *ngIf="isFirstStep(); else secondStepContentTemplate">
    <div class="sih-modal__image-row">
      <img
        class="sih-modal-image"
        src="https://d2lomvz2jrw9ac.cloudfront.net/common/sih.png"
        alt="Steam Inventory Helper"
      />
    </div>
    <div class="sih-modal-header">
      <ng-container *ngIf="dialogData.header">
        {{ dialogData.header | translate }}
      </ng-container>
    </div>
    <div class="sih-modal-text">
      <ng-container *ngIf="dialogData.body">
        {{ dialogData.body | translate }}
      </ng-container>
      <ng-container *ngIf="dialogData.body_2">
        <a
          class="sih-link"
          [href]="sihLink"
          target="_blank"
        >
          {{ dialogData.body_2 | translate }}
        </a>
      </ng-container>
    </div>
    <app-trade
      [showKey]="false"
      *ngIf="!tradeLink()"
    ></app-trade>
  </ng-container>

  <ng-template #secondStepContentTemplate>
    <div class="sih-modal-header">
      {{ 'SIH.SECOND_MODAL.HEADER' | translate }}
    </div>
    <div class="sih-modal-text">
      {{ 'SIH.SECOND_MODAL.BODY' | translate }}
    </div>
  </ng-template>
</div>

<div class="sih-modal-actions">
  <ng-container *ngIf="isFirstStep(); else secondStepActionsTemplate">
    <a
      class="btn btn-primary"
      [href]="sihLink"
      target="_blank"
      [class.disabled]="!tradeLink()"
    >
      <ng-container *ngIf="dialogData.button">
        {{ dialogData.button | translate }}
      </ng-container>
    </a>
    <button
      class="btn btn-secondary desktop"
      (click)="onCheckSihInstall()"
      [disabled]="btnsDisabled()"
    >
      <mat-icon
        class="refresh-line"
        [class.loading]="loading()"
        svgIcon="refresh-line"
      />
    </button>
    <button
      class="btn btn-secondary mobile"
      (click)="onCheckSihInstall()"
      [disabled]="btnsDisabled()"
    >
      <ng-container *ngIf="dialogData.button_2">
        <ng-container *ngIf="!loading(); else loadingTmpl">
          {{ dialogData.button_2 | translate }}
        </ng-container>
        <ng-template #loadingTmpl>
          <mat-icon
            class="refresh-line loading"
            svgIcon="refresh-line"
          />
        </ng-template>
      </ng-container>
    </button>
  </ng-container>
  <ng-template #secondStepActionsTemplate>
    <button
      class="btn btn-outline-blue--design"
      (click)="onRequestPermissions()"
      [disabled]="btnsDisabled()"
    >
      {{ 'SIH.SECOND_MODAL.BUTTON1' | translate }}
    </button>
    <button
      class="btn btn-primary tw-relative"
      (click)="onCheckSihInstall()"
      [disabled]="btnsDisabled()"
    >
      {{ 'SIH.SECOND_MODAL.BUTTON2' | translate }}
      <ng-container *ngIf="loading()">
        <div class="tw-absolute tw-top-0 tw-bottom-0 tw-w-full tw-flex tw-items-center tw-justify-center loader">
          <mat-icon
            class="refresh-line loading"
            svgIcon="refresh-line"
          />
        </div>
      </ng-container>
    </button>
  </ng-template>
</div>

<div class="sih-modal-footer">
  <ng-container *ngIf="isFirstStep(); else secondStepFooterTemplate">
    <ng-container *ngIf="dialogData.footer">
      {{ dialogData.footer | translate }}
    </ng-container>
  </ng-container>
  <ng-template #secondStepFooterTemplate>
    {{ 'SIH.SECOND_MODAL.NOTE' | translate }}
  </ng-template>
</div>
