import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { NgScrollbar } from 'ngx-scrollbar';
import { Subject, takeUntil } from 'rxjs';

import { ISelectionMenuItem } from '../shared';

@Component({
  selector: 'app-ui-autocomplete-list',
  templateUrl: './autocomplete-list.component.html',
  styleUrls: ['./autocomplete-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AutocompleteListComponent implements OnInit, OnDestroy {
  @Input() public menuItems: ISelectionMenuItem[] = [];
  @Input() public selectedItem: ISelectionMenuItem | null = null;
  @Input() public activeIndex = 0;
  @Input() public elementsVisible = 7; //кол-во видимых элементов
  @Input() public scrollToTop$: Subject<void> = new Subject();
  @Output() public onselectItem: EventEmitter<string> = new EventEmitter();

  @ViewChild('autocompleteScrollbar') public ngScrollbar: NgScrollbar | undefined;
  @ViewChild('autocompleteList') public elementsList: ElementRef | undefined;

  @HostBinding(`style.--elements-visible`) public get _elementsVisible(): number {
    return this.elementsVisible;
  }

  constructor(private cdr: ChangeDetectorRef, private renderer: Renderer2, private el: ElementRef) {}

  private destroy$: Subject<void> = new Subject();

  public trackFn(index: number): number {
    return index;
  }

  public ngOnInit(): void {
    this.scrollToTop$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.ngScrollbar?.scrollTo({ top: 0 });
    });
  }
  public ngOnDestroy(): void {
    this.destroy$.next();
  }
  public selectItem(itemName: string): void {
    this.onselectItem.emit(itemName);
  }
}
