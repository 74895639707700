import { Portal } from '@angular/cdk/portal';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { IActivities, IBaseWidgetBlock, Widgets } from '@dev-fast/types';
import { Observable } from 'rxjs';

import { DesktopRightsidebarService } from '@app/desktop-app/right-sidebar';
import { IS_SERVER_TOKEN, skinNameNormalize } from '@app/shared/utils';

import { RECENT_ACTIVITIES_ENGINE, RecentActivitiesEngine } from './symbols';

@Component({
  selector: 'app-recent-activities',
  templateUrl: './recent-activities.component.html',
  styleUrls: ['./recent-activities.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RecentActivitiesComponent {
  activitiesWidgets$: Observable<Widgets[]>;
  activities$: Observable<IActivities | null>;
  widgets = Widgets;
  // @HostBinding('@widgetsMenuComponentAnimation') private animation = true;
  customWidgetsPortal$: Observable<Portal<any> | undefined>;

  constructor(
    @Inject(IS_SERVER_TOKEN) public isServer: boolean,
    @Inject(RECENT_ACTIVITIES_ENGINE) private recentActivitiesEngine: RecentActivitiesEngine,
    private readonly service: DesktopRightsidebarService,
  ) {
    this.customWidgetsPortal$ = this.recentActivitiesEngine.customWidgetsPortal$;
    this.activitiesWidgets$ = this.recentActivitiesEngine.activitiesWidgets$;
    this.activities$ = this.recentActivitiesEngine.activities$;
  }
  navigateToCaseItem(params: { skin: string; shortName: string }): void {
    if (params.skin && params.shortName) {
      return this.recentActivitiesEngine.navigateTo([`/skins/item/${skinNameNormalize(params)}`]);
    } else {
      return this.recentActivitiesEngine.navigateTo(['not-found'], { replaceUrl: true });
    }
  }
  widgetToData(activities: IActivities, widget: string): IBaseWidgetBlock<Record<string, unknown>> | undefined {
    return activities[widget as keyof IActivities];
  }
}
