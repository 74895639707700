import { InjectionToken } from '@angular/core';
import { IUserDetailed } from '@dev-fast/types';
import { Observable } from 'rxjs';

export const P2P_ENGINE = new InjectionToken('P2P_ENGINE');

export interface P2pEngine {
  // messages$: Observable<IMessage[]>;
  readonly user$: Observable<IUserDetailed | null>;
  // lang: string;
  // addMessage(message: { value: string | ISticker; game: string }): void;
  // getMessagesList(): void;
}
