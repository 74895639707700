import { SelectDirection } from '@dev-fast/types';

// переключение кейсов
export class SelectCase {
  static readonly type = '[Market Cases] select case';
  constructor(
    public target: SelectDirection,
    public getMotivator: boolean,
  ) {}
}
