import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { IPublicUserDetailed, IUserDetailed, IUserTradeStats, PROJECT, SocialType, UserCharacter } from '@dev-fast/types';

@Component({
  selector: 'app-ui-user-profile-block',
  templateUrl: './user-profile-block.component.html',
  styleUrls: ['./user-profile-block.component.scss', './user-profile-block.component.media.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserProfileBlockComponent {
  @Input() set profile(value: IPublicUserDetailed | IUserDetailed | null | undefined) {
    if (value) {
      this.user = value;
      this.country = this.getUserCountry(value);
      this.isCharacterChanging = false;
      if ('market' in value) {
        this.tradeStats = value.market;
      }
    }
  }
  @Input() showSocials = false;
  @Input() showLevel = false;
  @Input() allowAction = false;
  @Input() simpleView = false;

  @Output() actionEvent: EventEmitter<void> = new EventEmitter(false);
  @Output() characterChangeEvent: EventEmitter<UserCharacter> = new EventEmitter<UserCharacter>(false);
  @Output() clickOnCopyId: EventEmitter<number> = new EventEmitter(false);

  user: IPublicUserDetailed | IUserDetailed | null = null;
  tradeStats!: IUserTradeStats | undefined;
  country!: string;

  isCharacterChanging = false;

  readonly projects = PROJECT;
  readonly socialTypes = SocialType;
  readonly userCharacters = UserCharacter;

  changeProfileCharacter(): void {
    this.isCharacterChanging = true;
    this.characterChangeEvent.emit(
      this.user?.character === UserCharacter.CounterTerrorist ? UserCharacter.Terrorist : UserCharacter.CounterTerrorist,
    );
  }
  getUserRating(): string {
    if (!this.tradeStats) {
      return '0,00%';
    }
    const ratingToSting = this.tradeStats.rating.toString();
    return this.tradeStats.rating > 100
      ? `${ratingToSting.slice(0, -2)},${ratingToSting.slice(-2)}%`
      : `0,${ratingToSting.padStart(2, '0')}%`;
  }
  getUserCountry(user: IPublicUserDetailed | IUserDetailed): string {
    return user.country ? `//d2lomvz2jrw9ac.cloudfront.net/country-flags/${user.country.toLowerCase()}.png` : '';
  }
  onCopyUserId(userId: number): void {
    this.clickOnCopyId.emit(userId);
  }
}
