import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { Router } from '@angular/router';
import { IInventoryShortInfo, ILocales, IUserDetailed, NewMenu, Panel, SubPanel, TransactionWay } from '@dev-fast/types';
import { Observable } from 'rxjs';

import { AuthService } from '@app/auth';
import { InventoryService } from '@app/core/inventory-service';
import { LanguageService } from '@app/core/language-service';
import { LayoutTypeService } from '@app/core/layout-service';
import { UserService } from '@app/core/user-service';

@Component({
  selector: 'app-desktop-header-v2',
  templateUrl: './desktop-header.component.html',
  styleUrls: ['./desktop-header.component.scss', './desktop-header.media.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DesktopHeaderV2Component {
  readonly #router = inject(Router);
  readonly #languageService = inject(LanguageService);
  readonly #layoutService = inject(LayoutTypeService);
  readonly #authService = inject(AuthService);
  readonly #userService = inject(UserService);
  readonly #inventoryService = inject(InventoryService);

  readonly locales$: Observable<ILocales[]> = this.#languageService.locales$;
  readonly currentLocale$: Observable<ILocales> = this.#languageService.currentLocale$;
  readonly user$: Observable<IUserDetailed | null | undefined> = this.#userService.user$;
  readonly canLevelUp$: Observable<boolean> = this.#userService.canLevelUp$;
  readonly userBalance$: Observable<number> = this.#userService.userBalance$;
  readonly inventoryShortInfo$: Observable<IInventoryShortInfo> = this.#inventoryService.inventoryShortInfo$;
  readonly menu$: Observable<NewMenu[]> = this.#layoutService.activeMenu$;

  onLogin = (): void => this.#authService.openLoginProvidersModal();

  onInventoryOpen(): void {
    this.#layoutService.changeActivePanel({ panel: Panel.INVENTORY, subPanel: SubPanel.NONE });
  }
  onOpenWithdrawal(way: TransactionWay): void {
    this.#router.navigate([{ outlets: { modal: ['transaction', way] } }], { queryParamsHandling: 'preserve' });
  }
  isMainMenuOpen(menu: NewMenu[] | null): boolean {
    return !!menu && (menu.includes(NewMenu.MAIN_MENU) || menu.includes(NewMenu.LANGUAGE));
  }
}
