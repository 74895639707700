<app-ui-brand></app-ui-brand>
<div class="title">{{ 'LOCAL.MODAL.BANNED.HEADER' | translate }}</div>
<div class="description">{{ banReason | translate }}</div>
<div class="description">
  {{ 'LOCAL.MODAL.BANNED.TEXT' | translate }}

  <a
    *ngIf="hasChatra()"
    (click)="openChatra()"
    >{{ 'LOCAL.MODAL.BANNED.LINK' | translate }}
  </a>
</div>
<div class="logout-btn">
  <button
    class="btn btn-primary"
    (click)="onLogout()"
  >
    <mat-icon svgIcon="logout"></mat-icon>
    {{ 'LOGOUT' | translate | titlecase }}
  </button>
</div>
