import { animate, query, style, transition, trigger } from '@angular/animations';

export const expandAnimation = trigger('expandAnimation', [
  transition(':enter', [
    query(
      ':self',
      [
        style({ opacity: 0, height: 0, padding: 0 }),
        animate('150ms ease-in-out', style({ height: '*', padding: '*' })),
        animate('150ms ease-in-out', style({ opacity: 1 })),
      ],
      {
        optional: true,
      },
    ),
  ]),
  transition(':leave', [
    query(
      ':self',
      [
        style({ opacity: 1, height: '*', padding: '*' }),
        animate('150ms ease-in-out', style({ opacity: 0 })),
        animate('150ms ease-in-out', style({ height: 0, padding: 0 })),
      ],
      { optional: true },
    ),
  ]),
]);
