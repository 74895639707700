<div
  class="possible-items-container"
  [class.compact]="isCompact"
>
  <ng-container *ngIf="!isCompact">
    <div class="possible-items-header">
      <div class="header-title">{{ 'LOCAL.CASES.SPIN_GAME.POSSIBLE_ITEMS.CASE_CONTENTS' | translate }}:</div>
      <div class="possible-gain-motivator">
        <span class="possible-gain-motivator-text">{{ 'LOCAL.CASES.SPIN_GAME.POSSIBLE_ITEMS.POTENTIAL_MOTIVATOR' | translate }}: </span>
        <span class="possible-gain-motivator-currency">{{ totalPossibleWin() | appCurrency }}</span>
      </div>
      <div class="case-info">
        <ng-container *ngIf="isRevisionShow">
          <button
            type="button"
            class="btn btn-md btn-semi-round btn-transparent-dark--design btn_back"
            (click)="openCaseRevisionModal()"
          >
            {{ 'LOCAL.CASES.SPIN_GAME.BUTTONS.BUTTON_CASE_REVISION' | translate }}
          </button>
        </ng-container>
      </div>
    </div>
  </ng-container>
  <div class="possible-items">
    <ng-container>
      <app-ui-possible-card
        *ngFor="let item of sortedItems; let index = index"
        (clickOnCard)="navigateToCaseItem(item)"
        [index]="index"
        [itemRevisions]="item"
        [showChance]="showChance"
        [isHovered]="isHovered"
        [tracksAmount]="tracksAmount"
        [isNewPalette]="isNewPalette"
        [cardClass]="cardClass"
      >
      </app-ui-possible-card>
    </ng-container>
  </div>
</div>
