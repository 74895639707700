import { OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { IPercent, IPercentProperties } from '@dev-fast/types';
import { Subject } from 'rxjs';

@Pipe({
  name: 'appPercent',
  pure: false,
})
export class AppPercentPipe implements PipeTransform, OnDestroy {
  public percent!: IPercent;
  private readonly destroyed$: Subject<void>;
  constructor() {
    this.destroyed$ = new Subject<void>();
  }

  public transform(value: number, payload?: IPercentProperties): string | null {
    return `${Math.abs(value / (payload?.rank ? payload?.rank : 1))}%`;
  }

  public ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
