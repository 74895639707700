import { ProfileTypes } from '../enums';

export interface IPremiumType {
  type: Exclude<ProfileTypes, ProfileTypes.DEMO>;
  refillAction: 'refill' | 'subscribe';
  refillActionDescription: string;
  refillDescription: string;
  'ACCOUNT_PREMIUM.UNLIMITED_BALANCE': boolean;
  'ACCOUNT_PREMIUM.ACCESS_LR'?: boolean;
  'ACCOUNT_PREMIUM.XP': boolean;
  'ACCOUNT_PREMIUM.GIVEAWAYS'?: boolean;
  'ACCOUNT_PREMIUM.CASHBACK.ABILITY_TAKE'?: Record<string, number | string>;
  'ACCOUNT_PREMIUM.ADDITIONAL_XP'?: string;
  'ACCOUNT_PREMIUM.FREE_CASE'?: boolean;
  'ACCOUNT_PREMIUM.FREE_SPIN'?: boolean;
  'ACCOUNT_PREMIUM.ADDITIONAL_POINTS_LR'?: string;
  'ACCOUNT_PREMIUM.ACCESS'?: boolean;
  'ACCOUNT_PREMIUM.MAX_BET': number | string;
}
