import { Attribute, Directive, ElementRef, Input, OnChanges, OnDestroy, OnInit, Renderer2, SimpleChanges } from '@angular/core';
import { ActivatedRoute, IsActiveMatchOptions, NavigationEnd, Router } from '@angular/router';
import { MAIN_LANG } from '@dev-fast/types';
import { Store } from '@ngxs/store';
import { distinctUntilChanged, filter, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

import { LanguageService, LanguageState } from '@app/core/language-service';

@Directive({
  selector: '[langRouterLink]',
})
export class LangRouterLinkDirective implements OnDestroy, OnInit, OnChanges {
  private subscriptions = new Subscription();
  @Input() public routerLink: string | any[] | undefined | null;
  @Input() public routerLinkActiveOptions: { exact: boolean } | IsActiveMatchOptions = { exact: false };
  @Input() public set routerLinkActive(data: string[] | string) {
    this.activeClasses = Array.isArray(data) ? data : data.split(' ').filter((c) => !!c);
  }
  private isActive = false;
  private activeClasses!: string[];
  private initHref!: string;
  private lastHref!: string;

  // ------------------------------

  constructor(
    @Attribute('tabindex') private _tabIndexAttribute: string | null | undefined,
    private _route: ActivatedRoute,
    private renderer: Renderer2,
    private _router: Router,
    private el: ElementRef,
    private store: Store
  ) {
    // Если ссылка относительная, то нет смысла ставить языки
    if (this.el.nativeElement.href?.[0] !== '.') {
      this.subscribeEmitters();
    }
  }

  // ---------- METHODS ----------

  /**
   * Обновление ссылки с добавление языковой локали
   */
  private updateRoute(lang?: string): void {
    const href: string = this.el.nativeElement.href;
    // Если ссылка никак не изменилась, то заново пересчитывать смысла нет
    if (!this.lastHref || href !== this.lastHref) {
      // Если язык на который нужно менять не передан, то берем его из маршрута
      if (!lang) {
        const langPath = this._router.url.split('/')[1].substring(0, 2);
        lang = LanguageService.isAcceptedLanguage(langPath) ? langPath : MAIN_LANG;
      }

      // При первом получении ссылки во время инита запоминаем её оригинал, чтобы к нему уже добавлять языки
      if (!this.initHref && href) {
        this.setInitHref(href);
      }

      // --------- Определение итоговой ссылки с языковым кодом -------
      let langHref!: string;
      if (this.initHref) {
        if (this.initHref[0] !== '.' && lang !== MAIN_LANG) {
          if (href.includes('://')) {
            const url = new URL(this.initHref);
            // Если новый язык не находится в списке доступных языков - не ставим его, а оставляем оригинал ссылки
            // Если у нас уже стоит языковой код, то заменяем его на новый. Если нет, то ставим после хоста
            langHref = LanguageService.isAcceptedLanguage(lang)
              ? this.initHref.replace(`${url.host}`, `${url.host}/${lang}`)
              : this.initHref;
          } else {
            langHref = this.initHref[0] === '/' ? '/' + lang + this.initHref : lang + '/' + this.initHref;
          }
        } else {
          langHref = this.initHref;
        }

        this.applyAttributeValue('href', langHref);
      }

      // Сохраняем текущую ссылку для того, чтобы проверять, не изменилась ли она
      this.lastHref = href;
    }

    if (this.activeClasses) {
      const initURL = href.includes('://') ? new URL(this.initHref).pathname : this.initHref;
      const currentBaseRoute = LanguageService.getBaseUrl(this._router.url);
      const isActive = (this.routerLinkActiveOptions as { exact: boolean }).exact
        ? currentBaseRoute === initURL
        : currentBaseRoute.startsWith(initURL);
      this.applyActiveClasses(isActive);
    }
  }

  private applyActiveClasses(isActive: boolean): void {
    if (!this.isActive && isActive) {
      this.renderer.addClass(this.el.nativeElement, this.activeClasses.join(' '));
    } else if (this.isActive && !isActive) {
      this.renderer.removeClass(this.el.nativeElement, this.activeClasses.join(' '));
    }
    this.isActive = isActive;
  }

  private applyAttributeValue(attrName: string, attrValue: string | null): void {
    if (attrValue !== null) {
      this.renderer.setAttribute(this.el.nativeElement, attrName, attrValue);
    } else {
      this.renderer.removeAttribute(this.el.nativeElement, attrName);
    }
  }

  /**
   * устанавливаем дефолтное значение this.initHref, к которой будут добавляться языки
   */
  private setInitHref(href: string): void {
    // Если ссылка полная (https://site...)
    let initHref = '';
    if (href.includes('://')) {
      const url = new URL(href);
      const langPath = url.pathname.split('/')[1];
      initHref = LanguageService.isAcceptedLanguage(langPath) ? `${url.origin}${url.pathname.replace('/' + langPath, '')}` : href;
      // Если ссылка сокращенная (/games/cases/)
    } else if (href[0] !== '.') {
      const langPath = href.split('/')[href[0] === '/' ? 1 : 0];
      initHref = LanguageService.isAcceptedLanguage(langPath) ? href.replace(langPath + '/', '') : href;
    } else {
      initHref = href;
    }

    this.initHref = initHref[initHref.length - 1] === '/' ? initHref.slice(0, -1) : initHref;
  }

  // ---------- SUBSCRIPTIONS ----------

  private subscribeEmitters(): void {
    // При смене языка - меняем href ссылку
    this.subscriptions.add(
      this.store
        .select(LanguageState)
        .pipe(
          map((state) => state.lang?.path.substring(0, 2)),
          distinctUntilChanged()
        )
        .subscribe((lang: string) => {
          this.updateRoute(lang);
        })
    );

    // При навигации дефолтный routerLink обновляет ссылку. Так что после его обновления мы снова навешиваем язык
    this.subscriptions.add(
      this._router.events.pipe(filter((routerEvent) => routerEvent instanceof NavigationEnd)).subscribe(() => {
        this.updateRoute();
      })
    );
  }

  // ---------- ANGULAR LIFE CYCLE ----------

  // На старте - ставим href ссылку
  public ngOnInit(): void {
    // Если ссылка относительная, то нет смысла ставить языки
    if (this.el.nativeElement.href?.[0] !== '.') {
      this.updateRoute();
    }
  }

  // При динамической смене параметра routerLink - переделываем href ссылку
  public ngOnChanges(changes: SimpleChanges): void {
    // Если ссылка относительная, то нет смысла ставить языки
    if (changes['href']?.currentValue?.[0] !== '.') {
      this.updateRoute();
    }
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
