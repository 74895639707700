import { animate, group, keyframes, query, stagger, style, transition, trigger } from '@angular/animations';

export const winnerAnimation = trigger('winnerAnimation', [
  transition(':enter', [
    group([
      query('.winner-banner__avatar', [
        style({ transform: 'scale(0)' }),
        animate(
          '550ms linear',
          keyframes([
            style({ transform: 'scale(0) rotate(-180deg)' }),
            style({ transform: 'scale(1.2) rotate(-90deg)' }),
            style({ transform: 'scale(1.2) rotate(0)' }),
            style({ transform: 'scale(1) rotate(0)' }),
          ])
        ),
      ]),
      query('.winner-banner__text-title', [
        style({ transform: 'translateX(-3rem)', opacity: 0 }),
        stagger(30, [
          animate(
            '550ms linear',
            keyframes([
              style({ transform: 'translateX(-2rem)', opacity: 0.2 }),
              style({ transform: 'translateX(-1rem) translateY(-1rem) rotate(-60deg)', opacity: 0.5 }),
              style({ transform: 'translateX(-0.5rem translateY(-1rem) rotate(-30deg))', opacity: 1 }),
              style({ transform: 'translateX(0)', opacity: 1 }),
            ])
          ),
        ]),
      ]),
      query('.winner-banner__text-gain', [
        style({ transform: 'translateX(-3rem)', opacity: 0 }),
        stagger(30, [
          animate(
            '550ms linear',
            keyframes([
              style({ transform: 'translateX(-2rem)', opacity: 0.2 }),
              style({ transform: 'translateX(-1rem)', opacity: 0.5 }),
              style({ transform: 'translateX(-0.5rem)', opacity: 1 }),
              style({ transform: 'translateX(0)', opacity: 1 }),
            ])
          ),
        ]),
      ]),
      query('.winner-banner__confetti__1', [
        style({ transform: 'translate(1rem, 1rem)', opacity: 0 }),
        stagger(0, [
          animate(
            '750ms linear',
            keyframes([
              style({ transform: 'translate(1rem, 1rem)', opacity: 1 }),
              style({ transform: 'translate(1.9rem, -1.8rem) rotate(30deg)', opacity: 1 }),
              style({ transform: 'translate(4.4rem, -1rem) rotate(205deg)', opacity: 1 }),
              style({ transform: 'translate(6.9rem, 1.2rem) rotate(270deg)', opacity: 0.7 }),
            ])
          ),
        ]),
      ]),
      query('.winner-banner__confetti__2', [
        style({ transform: 'translate(1rem, 1rem)', opacity: 0 }),
        stagger(5, [
          animate(
            '750ms linear',
            keyframes([
              style({ transform: 'translate(1.3rem, -1rem)', opacity: 0.6 }),
              style({ transform: 'translate(3.3rem, -2rem)', opacity: 1 }),
              style({ transform: 'translate(5rem, -1.5rem) rotate(45deg)', opacity: 0.7 }),
              style({ transform: 'translate(6rem, 1rem) rotate(70deg)', opacity: 0.2 }),
            ])
          ),
        ]),
      ]),
      query('.winner-banner__confetti__3', [
        style({ transform: 'translate(-0.4rem, 0.8rem)', opacity: 0 }),
        stagger(5, [
          animate(
            '750ms linear',
            keyframes([
              style({ transform: 'translate(-0.4rem, 0.8rem)', opacity: 0.6 }),
              style({ transform: 'translate(0.6rem, -1rem) rotate(85deg)', opacity: 1 }),
              style({ transform: 'translate(3.6rem, 0.8rem)', opacity: 0.7 }),
            ])
          ),
        ]),
      ]),
      query('.winner-banner__confetti__4', [
        style({ transform: 'translate(-2rem, 1rem)', opacity: 0 }),
        stagger(5, [
          animate(
            '750ms linear',
            keyframes([
              style({ transform: 'translate(-2rem, 1rem)', opacity: 0.6 }),
              style({ transform: 'translate(2rem, -3rem)', opacity: 1 }),
              style({ transform: 'translate(3.5rem, -1.3rem) rotate(-25deg)', opacity: 0.5 }),
              style({ transform: 'translate(3.8rem, 0.5rem) rotate(-70deg)', opacity: 0.2 }),
            ])
          ),
        ]),
      ]),
      query('.winner-banner__confetti__5', [
        style({ transform: 'translate(-0.1rem, 0.2rem)', opacity: 0 }),
        stagger(5, [
          animate(
            '750ms linear',
            keyframes([
              style({ transform: 'translate(-4.1rem, 0.2rem)', opacity: 0.6 }),
              style({ transform: 'translate(-2.1rem, -2.2rem)', opacity: 1 }),
              style({ transform: 'translate(1rem, -1.2rem) rotate(97deg)', opacity: 1 }),
              style({ transform: 'translate(2.3rem, 0.1rem) rotate(105deg) scale(1.5)', opacity: 0.2 }),
            ])
          ),
        ]),
      ]),
      query('.winner-banner__confetti__6', [
        style({ transform: 'translate(0.2rem, -0.9rem)', opacity: 0 }),
        stagger(5, [
          animate(
            '750ms linear',
            keyframes([
              style({ transform: 'translate(0.2rem, -0.9rem)', opacity: 0.6 }),
              style({ transform: 'translate(5.2rem, -2.5rem) rotate(12deg)', opacity: 1 }),
              style({ transform: 'translate(6.5rem, -1rem) rotate(46deg) scale(0.8)', opacity: 0.2 }),
            ])
          ),
        ]),
      ]),
      query('.winner-banner__confetti__7', [
        style({ transform: 'translate(-1.4rem, -1rem)', opacity: 0 }),
        stagger(5, [
          animate(
            '750ms linear',
            keyframes([
              style({ transform: 'translate(-1.4rem, -1rem)', opacity: 1 }),
              style({ transform: 'translate(0.6rem, -4rem)', opacity: 0.2 }),
            ])
          ),
        ]),
      ]),
      //   query('.winner-banner__confetti__1', [
      //     style({ transform: 'translate(1rem, 1rem)', opacity: 0 }),
      //     stagger(5, [
      //       animate(
      //         '1550ms linear',
      //         keyframes([
      //           style({ transform: 'translate(1rem, 1rem)', opacity: 1 }),
      //           style({ transform: 'translate(1rem, 1rem)', opacity: 1 }),
      //           style({ transform: 'translate(1rem, 1rem)', opacity: 1 }),
      //           style({ transform: 'translate(1rem, 1rem)', opacity: 1 }),
      //         ])
      //       ),
      //     ]),
      //   ]),
    ]),
  ]),
  transition(':leave', [
    group([
      query('.winner-banner__avatar', [
        style({ transform: 'scale(1)' }),
        animate(
          '350ms linear',
          keyframes([
            style({ transform: 'scale(1) rotate(0)' }),
            style({ transform: 'scale(1.2) rotate(0)' }),
            style({ transform: 'scale(1.2) rotate(-90deg)' }),
            style({ transform: 'scale(0) rotate(-180deg)' }),
          ])
        ),
      ]),
      query('.winner-banner__text-title', [
        style({ transform: 'translateX(1)', opacity: 1 }),
        stagger(30, [
          animate(
            '350ms linear',
            keyframes([
              style({ transform: 'translateX(0)', opacity: 1 }),
              style({ transform: 'translateX(-0.5rem translateY(-1rem) rotate(-30deg))', opacity: 1 }),
              style({ transform: 'translateX(-1rem) translateY(-1rem) rotate(-60deg)', opacity: 0.5 }),
              style({ transform: 'translateX(-2rem)', opacity: 0.2 }),
            ])
          ),
        ]),
      ]),
      query('.winner-banner__text-gain', [
        style({ transform: 'translateX(0)', opacity: 1 }),
        stagger(30, [
          animate(
            '350ms linear',
            keyframes([
              style({ transform: 'translateX(0)', opacity: 1 }),
              style({ transform: 'translateX(-0.5rem)', opacity: 1 }),
              style({ transform: 'translateX(-1rem)', opacity: 0.5 }),
              style({ transform: 'translateX(-2rem)', opacity: 0.2 }),
            ])
          ),
        ]),
      ]),
    ]),
  ]),
]);
