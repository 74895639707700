import { inject, Injectable } from '@angular/core';
import { IUserDetailed, StateActionStatus, TradeLinks } from '@dev-fast/types';
import { Observable } from 'rxjs';

import { UserService } from '@app/core/user-service';
import { TradeBlockEngine } from '@app/shared/components';

@Injectable()
export class TradesService implements TradeBlockEngine {
  readonly #userService = inject(UserService);
  readonly apiKey$: Observable<string | undefined> = this.#userService.apiKey$;
  readonly apiKeyLoadStatus$: Observable<StateActionStatus> = this.#userService.apiKeyLoadStatus$;
  readonly apiKeyValidation$: Observable<StateActionStatus> = this.#userService.apiKeyValidation$;
  readonly tradeLink$: Observable<string | undefined> = this.#userService.tradeLink$;
  readonly tradeLinkLoadStatus$: Observable<StateActionStatus> = this.#userService.tradeLinkLoadStatus$;
  readonly tradeLinkValidation$: Observable<StateActionStatus> = this.#userService.tradeLinkValidation$;
  readonly user$: Observable<IUserDetailed | null | undefined> = this.#userService.user$;

  getP2pPermissions(): void {
    this.#userService.getP2pPermissions();
  }

  updateApiKey(apiKey: string): void {
    this.#userService.updateApiKey(apiKey);
  }

  updateTradeLink(links: TradeLinks): void {
    this.#userService.updateTradeLink(links);
  }
}
