import { GameSoundGroup, SoundName } from '@dev-fast/types';

import { IAudio } from './sound.interface';

// const soundSourceUrl = 'https://d2lomvz2jrw9ac.cloudfront.net/audio';
const soundSourceUrl = '/assets/sounds';

export const CASES_GROUP: IAudio[] = [
  {
    name: 'STANDART_START',
    shortName: 'STANDART',
    description: 'STANDART_START',
    group: GameSoundGroup.CASES_GROUP,
    phase: 'START',
    link: `${soundSourceUrl}/cases/start/standart/STANDART_START.mp3`,
  },
  {
    name: 'STANDART_START_FAST',
    shortName: 'STANDART',
    description: 'STANDART_START_FAST',
    group: GameSoundGroup.CASES_GROUP,
    phase: 'START',
    type: 'FAST',
    link: `${soundSourceUrl}/cases/start/standart/STANDART_START_FAST.mp3`,
  },
  {
    name: 'DANGER_START',
    shortName: 'DANGER',
    description: 'DANGER_START',
    group: GameSoundGroup.CASES_GROUP,
    phase: 'START',
    link: `${soundSourceUrl}/cases/start/danger/DANGER_START.mp3`,
  },
  {
    name: 'DANGER_START_FAST',
    shortName: 'DANGER',
    description: 'DANGER_START_FAST',
    group: GameSoundGroup.CASES_GROUP,
    phase: 'START',
    type: 'FAST',
    link: `${soundSourceUrl}/cases/start/danger/DANGER_START_FAST.mp3`,
  },
  {
    name: 'JINGLE_START',
    shortName: 'JINGLE',
    description: 'JINGLE_START',
    group: GameSoundGroup.CASES_GROUP,
    phase: 'START',
    link: `${soundSourceUrl}/cases/start/jingle/JINGLE_START.mp3`,
  },
  {
    name: 'JINGLE_START_FAST',
    shortName: 'JINGLE',
    description: 'JINGLE_START_FAST',
    group: GameSoundGroup.CASES_GROUP,
    phase: 'START',
    type: 'FAST',
    link: `${soundSourceUrl}/cases/start/jingle/JINGLE_START_FAST.mp3`,
  },
  {
    name: 'MIX_START',
    shortName: 'MIX',
    description: 'MIX_START',
    group: GameSoundGroup.CASES_GROUP,
    phase: 'START',
    link: `${soundSourceUrl}/cases/start/mix/MIX_START.mp3`,
  },
  {
    name: 'MIX_START_FAST',
    shortName: 'MIX',
    description: 'MIX_START_FAST',
    group: GameSoundGroup.CASES_GROUP,
    phase: 'START',
    type: 'FAST',
    link: `${soundSourceUrl}/cases/start/mix/MIX_START_FAST.mp3`,
  },

  {
    name: 'STANDART_RAFFLE',
    shortName: 'STANDART',
    description: 'STANDART_RAFFLE',
    group: GameSoundGroup.CASES_GROUP,
    phase: 'RAFFLE',
    link: `${soundSourceUrl}/cases/raffle/standart/STANDART_RAFFLE.mp3`,
    forBtnsList: true,
  },
  {
    name: 'STANDART_RAFFLE_FAST',
    shortName: 'STANDART',
    description: 'STANDART_RAFFLE_FAST',
    group: GameSoundGroup.CASES_GROUP,
    phase: 'RAFFLE',
    type: 'FAST',
    link: `${soundSourceUrl}/cases/raffle/standart/STANDART_RAFFLE_FAST.mp3`,
  },
  {
    name: 'DANGER_RAFFLE',
    shortName: 'DANGER',
    description: 'DANGER_RAFFLE',
    group: GameSoundGroup.CASES_GROUP,
    phase: 'RAFFLE',
    link: `${soundSourceUrl}/cases/raffle/danger/DANGER_RAFFLE.mp3`,
    forBtnsList: true,
  },
  {
    name: 'DANGER_RAFFLE_FAST',
    shortName: 'DANGER',
    description: 'DANGER_RAFFLE_FAST',
    group: GameSoundGroup.CASES_GROUP,
    phase: 'RAFFLE',
    type: 'FAST',
    link: `${soundSourceUrl}/cases/raffle/danger/DANGER_RAFFLE_FAST.mp3`,
  },
  {
    name: 'JINGLE_RAFFLE',
    shortName: 'JINGLE',
    description: 'JINGLE_RAFFLE',
    group: GameSoundGroup.CASES_GROUP,
    phase: 'RAFFLE',
    link: `${soundSourceUrl}/cases/raffle/jingle/JINGLE_RAFFLE.mp3`,
    forBtnsList: true,
  },
  {
    name: 'JINGLE_RAFFLE_FAST',
    shortName: 'JINGLE',
    description: 'JINGLE_RAFFLE_FAST',
    group: GameSoundGroup.CASES_GROUP,
    phase: 'RAFFLE',
    type: 'FAST',
    link: `${soundSourceUrl}/cases/raffle/jingle/JINGLE_RAFFLE_FAST.mp3`,
  },
  {
    name: 'MIX_RAFFLE',
    shortName: 'MIX',
    description: 'MIX_RAFFLE',
    group: GameSoundGroup.CASES_GROUP,
    phase: 'RAFFLE',
    link: `${soundSourceUrl}/cases/raffle/mix/MIX_RAFFLE.mp3`,
    forBtnsList: true,
  },
  {
    name: 'MIX_RAFFLE_FAST',
    shortName: 'MIX',
    description: 'MIX_RAFFLE_FAST',
    group: GameSoundGroup.CASES_GROUP,
    phase: 'RAFFLE',
    type: 'FAST',
    link: `${soundSourceUrl}/cases/raffle/mix/MIX_RAFFLE_FAST.mp3`,
  },

  {
    name: 'STANDART_END',
    shortName: 'STANDART',
    description: 'STANDART_END',
    group: GameSoundGroup.CASES_GROUP,
    phase: 'END',
    link: `${soundSourceUrl}/cases/end/standart/STANDART_END.mp3`,
  },
  {
    name: 'STANDART_END_FAST',
    shortName: 'STANDART',
    description: 'STANDART_END_FAST',
    group: GameSoundGroup.CASES_GROUP,
    phase: 'END',
    type: 'FAST',
    link: `${soundSourceUrl}/cases/end/standart/STANDART_END_FAST.mp3`,
  },
  {
    name: 'DANGER_END',
    shortName: 'DANGER',
    description: 'DANGER_END',
    group: GameSoundGroup.CASES_GROUP,
    phase: 'END',
    link: `${soundSourceUrl}/cases/end/danger/DANGER_END.mp3`,
  },
  {
    name: 'DANGER_END_FAST',
    shortName: 'DANGER',
    description: 'DANGER_END_FAST',
    group: GameSoundGroup.CASES_GROUP,
    phase: 'END',
    type: 'FAST',
    link: `${soundSourceUrl}/cases/end/danger/DANGER_END_FAST.mp3`,
  },
  {
    name: 'JINGLE_END',
    shortName: 'JINGLE',
    description: 'JINGLE_END',
    group: GameSoundGroup.CASES_GROUP,
    phase: 'END',
    link: `${soundSourceUrl}/cases/end/jingle/JINGLE_END.mp3`,
  },
  {
    name: 'JINGLE_END_FAST',
    shortName: 'JINGLE',
    description: 'JINGLE_END_FAST',
    group: GameSoundGroup.CASES_GROUP,
    phase: 'END',
    type: 'FAST',
    link: `${soundSourceUrl}/cases/end/jingle/JINGLE_END_FAST.mp3`,
  },
  {
    name: 'MIX_END',
    shortName: 'MIX',
    description: 'MIX_END',
    group: GameSoundGroup.CASES_GROUP,
    phase: 'END',
    link: `${soundSourceUrl}/cases/end/mix/MIX_END.mp3`,
  },
  {
    name: 'MIX_END_FAST',
    shortName: 'MIX',
    description: 'MIX_END_FAST',
    group: GameSoundGroup.CASES_GROUP,
    phase: 'END',
    type: 'FAST',
    link: `${soundSourceUrl}/cases/end/mix/MIX_END_FAST.mp3`,
  },
];

export const CSGO_CASES: IAudio[] = [
  {
    name: SoundName.CSGO_TICK,
    shortName: SoundName.CSGO_TICK,
    description: 'sound when spin card changes',
    group: GameSoundGroup.CSGO_CASES,
    link: `${soundSourceUrl}/renew/ticks/${SoundName.CSGO_TICK}.mp3`,
  },
  {
    name: SoundName.CSGO_TICK,
    shortName: SoundName.CSGO_TICK,
    description: 'sound when spin card changes',
    group: GameSoundGroup.CSGO_CASES,
    link: `${soundSourceUrl}/renew/ticks/${SoundName.CSGO_TICK}.mp3`,
  },
  {
    name: SoundName.CSGO_UNLOCK_LONG,
    shortName: SoundName.CSGO_UNLOCK_LONG,
    description: 'sound when preview block changed spin block',
    group: GameSoundGroup.CSGO_CASES,
    link: `${soundSourceUrl}/renew/unlock/${SoundName.CSGO_UNLOCK_LONG}.mp3`,
  },
  {
    name: SoundName.CSGO_UNLOCK_SHORT,
    shortName: SoundName.CSGO_UNLOCK_SHORT,
    description: 'sound when case already was opened',
    group: GameSoundGroup.CSGO_CASES,
    link: `${soundSourceUrl}/renew/unlock/${SoundName.CSGO_UNLOCK_SHORT}.mp3`,
  },
  {
    name: SoundName.CSGO_DROP_0,
    shortName: SoundName.CSGO_DROP_0,
    description: 'sound when user get item',
    group: GameSoundGroup.CSGO_CASES,
    link: `${soundSourceUrl}/renew/drop/${SoundName.CSGO_DROP_0}.mp3`,
  },
  {
    name: SoundName.CSGO_DROP_1,
    shortName: SoundName.CSGO_DROP_1,
    description: 'sound when user get item',
    group: GameSoundGroup.CSGO_CASES,
    link: `${soundSourceUrl}/renew/drop/${SoundName.CSGO_DROP_1}.mp3`,
  },
  {
    name: SoundName.CSGO_DROP_2,
    shortName: SoundName.CSGO_DROP_2,
    description: 'sound when user get item',
    group: GameSoundGroup.CSGO_CASES,
    link: `${soundSourceUrl}/renew/drop/${SoundName.CSGO_DROP_2}.mp3`,
  },
  {
    name: SoundName.CSGO_DROP_3,
    shortName: SoundName.CSGO_DROP_3,
    description: 'sound when user get item',
    group: GameSoundGroup.CSGO_CASES,
    link: `${soundSourceUrl}/renew/drop/${SoundName.CSGO_DROP_3}.mp3`,
  },
  {
    name: SoundName.CSGO_DROP_4,
    shortName: SoundName.CSGO_DROP_4,
    description: 'sound when user get item',
    group: GameSoundGroup.CSGO_CASES,
    link: `${soundSourceUrl}/renew/drop/${SoundName.CSGO_DROP_4}.mp3`,
  },
  {
    name: SoundName.CSGO_DROP_5,
    shortName: SoundName.CSGO_DROP_5,
    description: 'sound when user get item',
    group: GameSoundGroup.CSGO_CASES,
    link: `${soundSourceUrl}/renew/drop/${SoundName.CSGO_DROP_5}.mp3`,
  },
  {
    name: SoundName.CSGO_DROP_6,
    shortName: SoundName.CSGO_DROP_6,
    description: 'sound when user get item',
    group: GameSoundGroup.CSGO_CASES,
    link: `${soundSourceUrl}/renew/drop/${SoundName.CSGO_DROP_6}.mp3`,
  },
  {
    name: SoundName.CSGO_DROP_CASEITEM,
    shortName: SoundName.CSGO_DROP_CASEITEM,
    description: 'sound when user changes case',
    group: GameSoundGroup.CSGO_CASES,
    link: `${soundSourceUrl}/renew/drop/${SoundName.CSGO_DROP_CASEITEM}.mp3`,
  },
  {
    name: SoundName.CSGO_HOVER,
    shortName: SoundName.CSGO_HOVER,
    description: 'sound when user hover cursor to item',
    group: GameSoundGroup.CSGO_CASES,
    link: `${soundSourceUrl}/renew/hover/${SoundName.CSGO_HOVER}.mp3`,
  },
];

export const CLASSIC_GAME: IAudio[] = [
  {
    name: SoundName.WINNING,
    shortName: SoundName.WINNING,
    description: 'sound when user won a game and the modal window is openning',
    group: GameSoundGroup.CLASSIC_GAME,
    link: `${soundSourceUrl}/renew/winnings/${SoundName.WINNING}.mp3`,
  },
];

// export const poggiSounds: IAudio[] = [{ name: 'poggi', description: 'SOUND.POGGI.GAME', group: 'poggi', link: `` }];
export const eventBlackFridaySound: IAudio[] = [
  {
    name: 'GUN_LOADING_1',
    shortName: 'LOADING',
    description: 'GUN_LOADING',
    group: 'eventBlackFridaySound',
    link: `${soundSourceUrl}/events/black-friday/1/GUN_LOADING_1.mp3`,
  },
  {
    name: 'GUN_LOADING_2',
    shortName: 'LOADING',
    description: 'GUN_LOADING',
    group: 'eventBlackFridaySound',
    link: `${soundSourceUrl}/events/black-friday/2/GUN_LOADING_2.mp3`,
  },
  {
    name: 'GUN_LOADING_3',
    shortName: 'LOADING',
    description: 'GUN_LOADING',
    group: 'eventBlackFridaySound',
    link: `${soundSourceUrl}/events/black-friday/3/GUN_LOADING_3.mp3`,
  },
  {
    name: 'GUN_SHOT_1',
    shortName: 'LOADING',
    description: 'GUN_LOADING',
    group: 'eventBlackFridaySound',
    link: `${soundSourceUrl}/events/black-friday/1/GUN_SHOT_1.mp3`,
  },
  {
    name: 'GUN_SHOT_2',
    shortName: 'LOADING',
    description: 'GUN_LOADING',
    group: 'eventBlackFridaySound',
    link: `${soundSourceUrl}/events/black-friday/2/GUN_SHOT_2.mp3`,
  },
  {
    name: 'SWIPE',
    shortName: '',
    description: '',
    group: 'eventBlackFridaySound',
    link: `${soundSourceUrl}/events/black-friday/SWIPE.mp3`,
  },
  {
    name: 'ROUND_COMPLETE',
    shortName: '',
    description: '',
    group: 'eventBlackFridaySound',
    link: `${soundSourceUrl}/events/black-friday/ROUND_COMPLETE.mp3`,
  },
];

// ToDo: add all sounds
