import {
  GameIds,
  IHistoryInventoryItem,
  IHistoryParams,
  IInventoryItem,
  IInventoryRequestParams,
  InventorySortingTypes,
  IShopMeta,
  ISkinItem,
} from '@dev-fast/types';

export interface InventoryStateLegacyModel {
  // FIXME убрать отсюда
  appId: GameIds;
  items: IInventoryItem[];
  isSelectAll: boolean;
  itemsIds: number[];
  selectedItems: IInventoryItem[];
  inventoryCount: number;
  inventorySum: number;
  params: IInventoryRequestParams;
  shopItems: ISkinItem[];
  contracts: ISkinItem[];
  shopParams: IInventoryRequestParams;
  shopMeta: IShopMeta;
  gameInProgress: boolean;
  historyItems: IHistoryInventoryItem[];
  historyParams: IHistoryParams | null;
  historyCount: number | null;
}

export const INVENTORY_LEGACY_INITIAL_STATE: InventoryStateLegacyModel = {
  appId: GameIds.CSGO,
  items: [],
  isSelectAll: false,
  itemsIds: [],
  selectedItems: [],
  inventoryCount: 0,
  inventorySum: 0,
  params: {
    sortBy: InventorySortingTypes.MAX_PRICE,
    page: 1,
    pageSize: 50,
    append: false,
  },

  shopItems: [],
  contracts: [],
  shopParams: {
    sortBy: InventorySortingTypes.MAX_PRICE,
    page: 1,
    pageSize: 50,
    append: false,
  },
  shopMeta: {
    amount: 0,
    limit: 0,
    offset: 0,
  },
  gameInProgress: false,
  historyItems: [],
  historyParams: {
    page: 1,
    size: 100,
  },
  historyCount: null,
};
