import { GameMode, ProfileTypes } from '../enums';

//TODO понять какие генерики могут быть их задекларировать и убрать Record'
export type IGameSettings<T = Record<string, unknown>> = T & {
  enabled: boolean;
  endgameMs: number;
  id: number;
  maxBet: number;
  maxItems: number;
  maxItemsPerTrade: number;
  maxPlayers: number;
  minAccountType: ProfileTypes;
  minBet: number;
  minItemPrice: number;
  minItemsPerTrade: number;
  rouletteMs: number;
  timeoutMs: number;

  allowSkinsFromGames?: number[];
  betSources?: {
    source: string;
    itemTypes: number[];
  };
  defaultSource?: string;
  description?: string;
  limit?: {
    demo: { allowed: boolean };
    bronze: { allowed: boolean };
  };
  mode?: GameMode;
  //TODO возможно енамчик бахнуть
  name: string;
  title?: string;
};
