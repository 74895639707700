import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, NgModule } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ILotCard } from '@dev-fast/types';

import { toShortRevisionWear } from '@app/shared/utils';

@Component({
  selector: 'app-ui-float-indicator',
  templateUrl: './float-indicator.component.html',
  styleUrls: ['./float-indicator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FloatIndicatorComponent {
  @Input() public item: ILotCard | undefined;
  @Input() public numberFormat: number | undefined;
  @Input() public exteriorShortView = false;
  public toShowTooltip = true;

  public toShortRevision(revision: string): string {
    return toShortRevisionWear(revision);
  }

  public getFormatNumber(floatNumber: number, numberFormat: number | undefined): string {
    this.toShowTooltip = !!numberFormat;

    if (numberFormat) {
      const floatToString = floatNumber.toString();
      return floatToString.slice(0, floatToString.indexOf('.') + numberFormat + 1);
    }

    return floatNumber.toString();
  }
}

@NgModule({
  imports: [CommonModule, MatTooltipModule],
  declarations: [FloatIndicatorComponent],
  exports: [FloatIndicatorComponent],
})
export class FloatIndicatorModule {}
