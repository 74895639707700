import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { RouterModule, Routes } from '@angular/router';
import {
  FilterByMethodModule,
  FloatIndicatorModule,
  FloatLineModule,
  GhostComponentModule,
  HistoryModule,
  ItemsFilterModule,
  P2pLotCardModule,
  PaginationModule,
  SelectionMenuSAComponent,
  SihStatsComponent,
  SkinItemModule,
  SkinItemNewModule,
  TabGroupModule,
  UiCheckboxSAComponent,
} from '@dev-fast/ui-components';
import { TranslateModule } from '@ngx-translate/core';
import { NgxsModule } from '@ngxs/store';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { NgScrollbarReachedModule } from 'ngx-scrollbar/reached-event';

import { CurrencyComponent } from '@app/core/currency';
import { P2pBuyingState, P2pDepositState, P2pMarketState } from '@app/core/state/p2p';
import { FeatureFlagsModule } from '@app/feature-flags';
import { LangRouterModule, ScrolledToModule } from '@app/shared/directives';
import { AppCurrencyModule, CustomAsyncPipeModule, HashPipe, StickerHashPipe } from '@app/shared/pipes';
import { MarketItemCardComponent } from '@app/ui/components/lib/market-item-card/market-item-card.component';
import { SihCheckCardComponent } from '@app/widgets/sih';

import { P2pMarketHistoryComponent } from './modals/p2p-market-history/p2p-market-history.component';
import { P2pActionBtnComponent, P2pSkinImageComponent, P2pStickerImageComponent } from './modals/p2p-market-modal';
import { P2pMarketModalComponent } from './modals/p2p-market-modal/p2p-market-modal.component';
import { P2pMarketComponent } from './p2p-market.component';
import { P2pMarketFilterComponent } from './p2p-market-filter/p2p-market-filter.component';
import { P2pBuyingService, P2pDepositService, P2pMarketService, P2PModalService } from './services';

export const routes: Routes = [
  {
    path: '',
    component: P2pMarketComponent,
  },
];
@NgModule({
  declarations: [
    P2pMarketComponent,
    P2pMarketHistoryComponent,
    P2pMarketModalComponent,
    P2pSkinImageComponent,
    P2pStickerImageComponent,
    P2pActionBtnComponent,
  ],
  providers: [P2pDepositService, P2pBuyingService, P2pMarketService, P2PModalService],
  imports: [
    P2pMarketFilterComponent,
    CommonModule,
    NgxsModule.forFeature([P2pBuyingState, P2pDepositState, P2pMarketState]),
    RouterModule.forChild(routes),
    ReactiveFormsModule,
    SkinItemModule,
    TabGroupModule,
    TranslateModule,
    LangRouterModule,
    AppCurrencyModule,
    MatIconModule,
    MatInputModule,
    MatDialogModule,
    MatDividerModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatProgressSpinnerModule,
    P2pLotCardModule,
    NgScrollbarModule,
    NgScrollbarReachedModule,
    PaginationModule,
    SihStatsComponent,
    FilterByMethodModule,
    SkinItemNewModule,
    SelectionMenuSAComponent,
    StickerHashPipe,
    HashPipe,
    ItemsFilterModule,
    FloatIndicatorModule,
    FloatLineModule,
    HistoryModule,
    GhostComponentModule,
    FeatureFlagsModule,
    FormsModule,
    CustomAsyncPipeModule,
    ScrolledToModule,
    CurrencyComponent,
    UiCheckboxSAComponent,
    MarketItemCardComponent,
    P2pMarketFilterComponent,
    SihCheckCardComponent,
  ],
})
export class P2pNewModule {}
