import { ICaseCollectionType, ICasesFilter, IFilterRange } from '@dev-fast/types';

export class ChangeFilter {
  static readonly type = '[Cases] Change Filter';
  constructor(public casesFilter: ICasesFilter) {}
}
export class ChangePriceRangeValues {
  static readonly type = '[Cases] Change Price Range Values';
  constructor(public range: IFilterRange) {}
}
export class GetRoutingCategories {
  static readonly type = '[Cases] Get routing categories';
}
export class GetEventCategories {
  static readonly type = '[Cases] Get event categories';
}
export class GetActiveCategory {
  static readonly type = '[Cases] Get active category';
  constructor(public casesFilter?: ICasesFilter) {}
}
export class GetAllCases {
  static readonly type = '[Cases] Get all cases';
  constructor(public casesFilter?: ICasesFilter) {}
}
export class GetCasesByCategory {
  static readonly type = '[Cases] Get Cases By Category';
  constructor(public casesFilter?: ICasesFilter) {}
}
export class ChangeCasesCategory {
  static readonly type = '[Cases] Change category';
  constructor(public type: ICaseCollectionType) {}
}
export class GetCaseOpeningHistory {
  static readonly type = '[Cases game] Get items of history';
  constructor(public page: number) {}
}
export class CasesStateSubscribe {
  static readonly type = '[Cases] Cases State Subscribe';
}
export class CasesStateUnsubscribe {
  static readonly type = '[Cases] Cases State Unsubscribe';
}
export class ClearCasesState {
  static readonly type = '[Cases] Clear cases sate';
  constructor(public key?: string) {}
}

// export class GetCaseItemDetails {
//   public static readonly type = '[Cases] Get item details';
//   constructor(public params: IInventoryItemDetailedReq) {}
// }
// export class GetAvailabilityItemInCases {
//   public static readonly type = '[Cases] Get item availability in cases';
//   constructor(public params: ICaseItemDetailedReq) {}
// }

// export class ClearItemDetailedMeta {
//   public static readonly type = '[Cases] Clear ItemDetailedMeta';
// }
