import { SocialType } from '../enums';

export interface SocialItem {
  title?: string;
  name: SocialType;
  id?: string;
  isHidden?: boolean;
  link?: string;
  svgIcon?: string;
}

export interface ISocialInfoModalPayload {
  provider: SocialItem;
  unlinkAction: () => void;
}
