import { Component, Input, signal } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { IFilterFormTemplate } from '@dev-fast/types';

/**
 * Ползунок числовых значений "от" и "до". Работает с контролом типа IDFormControlRangeFromTo
 */
@Component({
  selector: 'app-ui-filter-number-options',
  templateUrl: './filter-price-range.component.html',
  styleUrls: ['./filter-price-range.component.scss'],
})
export class FilterPriceRangeComponent {
  @Input() filterForm: FormGroup<IFilterFormTemplate> | undefined;
  // control в формате IDFormControlRangeFromTo
  @Input() control!: any;
  @Input() maxAvailablePrice!: number | null | undefined;

  maxPricePlaceholder = signal<number | undefined>(0);

  get minPrice(): number | null | undefined {
    return this.filterForm ? this.filterForm.get('minPrice')?.value : null;
  }

  get maxPrice(): number | null | undefined {
    return this.filterForm ? this.filterForm.get('maxPrice')?.value : null;
  }

  updatePrice(price: { from: number; to: number }): void {
    if (!this.filterForm) {
      return;
    }
    this.filterForm.get('minPrice')?.patchValue(price.from);
    this.filterForm.get('maxPrice')?.patchValue(price.to);
    // TODO Спрятано до появления поля в мете от бека
    // if (price.to) {
    //   this.maxPricePlaceholder.set(0);
    // } else {
    //   this.maxPricePlaceholder.set(this.#currencyService.convert(this.maxAvailablePrice || 0));
    // }
  }

  resetPrice(): void {
    if (!this.filterForm) {
      return;
    }
    this.filterForm.get('minPrice')?.patchValue(this.control.initMin);
    this.filterForm.get('maxPrice')?.patchValue(this.control.initMax);
  }
}
