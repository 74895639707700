export interface ILevel {
  level: number;
  xp: number;
  rank: string;
  chevron: number;
  stars: number;
  prize: {
    imageSrc?: string;
    reward: number;
    count: number;
    type: 'reward' | 'bonus';
    name: string;
  };
}
