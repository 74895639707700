export enum HistoryIcons {
  CLASSIC = 'icon-classic-mini',
  FAST = 'icon-fast-mini',
  DOUBLE = 'icon-double-mini',
  CASES = 'icon-cases-mini',
  X50 = 'icon-x50-mini',
  TOWER = 'icon-tower-mini',
  WITHDRAW = 'icon-minus-circle',
  REFILL = 'icon-plus-circle',
  CONTRACT = 'icon-contract-mini',
}

export enum HistoryTypes {
  UNKNOWN,
  GAME,
  BALANCE,
  SKIN_REFILL,
  SKIN_WITHDRAWAL,
  BONUS,
  INVENTORY,
}

export enum HistorySubTypes {
  UNKNOWN,
  GAME_BET,
  GAME_CASE,
  GAME_TOWER,
  BALANCE_REFILL,
  BALANCE_WITHDRAWAL,
  BALANCE_INVENTORY_BUY,
  BALANCE_INVENTORY_SELL,
  BONUS_FREE,
  BONUS_REFERRAL,
}

export enum HistoryItemStatus {
  SOLD = 'sold',
  TAKEN = 'taken',
  WITHDRAW = 'withdraw',
  DEPOSIT = 'deposit',
  BET_UPGRADE = 'bet-upgrade',
  BET = 'bet',
}

export enum HistorySourceType {
  CASES = 'cases',
  CASES_QUEST = 'cases-quest',
  CASE_BATTLES = 'case-battles',
  INVENTORY = 'inventory',
  MARKET = 'market',
}
