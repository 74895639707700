// import moment from 'moment-timezone';

export interface EventsConfigValue {
  startEvent: string;
  endEvent: string;
  hide?: string;
}
export interface EventsConfig {
  ny?: EventsConfigValue;
  ny_china?: EventsConfigValue;
  halloween?: EventsConfigValue;
}
// export const configs: EventsConfig = {
//   ny: {
//     startEvent: moment.tz('2023-11-27', 'Europe/Moscow').format(),
//     endEvent: moment.tz('2024-01-18 15:00', 'Europe/Moscow').format(),
//     hide: moment.tz('2024-01-18 15:00', 'Europe/Moscow').format(),
//   },
//   ny_china: {
//     startEvent: moment.tz('2023-01-18 15:01', 'Europe/Moscow').format(),
//     endEvent: moment.tz('2023-01-25 15:00', 'Europe/Moscow').format(),
//     hide: moment.tz('2023-01-27 15:00', 'Europe/Moscow').format(),
//   },
//   halloween: {
//     startEvent: moment.tz('2023-10-13', 'Europe/Moscow').format(),
//     endEvent: moment.tz('2023-11-01 12:00', 'Europe/Moscow').format(),
//     hide: moment.tz('2023-11-01 12:00', 'Europe/Moscow').format(),
//   },
// };
