import { IconConfig } from '@dev-fast/types';

export const iconsPeerToPeer: IconConfig[] = [
  {
    name: 'p2p-ban-icon',
    path: 'assets/img/mat-icons/peer-to-peer/p2p-ban-icon.component.svg',
  },
  {
    name: 'p2p-dash-icon',
    path: 'assets/img/mat-icons/peer-to-peer/p2p-dash-icon.component.svg',
  },
  {
    name: 'p2p-down-arrow-icon',
    path: 'assets/img/mat-icons/peer-to-peer/p2p-down-arrow-icon.component.svg',
  },
  {
    name: 'p2p-phase-failure-icon',
    path: 'assets/img/mat-icons/peer-to-peer/p2p-phase-failure-icon.component.svg',
  },
  {
    name: 'p2p-phase-success-icon',
    path: 'assets/img/mat-icons/peer-to-peer/p2p-phase-success-icon.component.svg',
  },
  {
    name: 'p2p-phase-warn-icon',
    path: 'assets/img/mat-icons/peer-to-peer/p2p-phase-warn-icon.component.svg',
  },
  {
    name: 'p2p-purchase-icon',
    path: 'assets/img/mat-icons/peer-to-peer/p2p-purchase-icon.component.svg',
  },
  {
    name: 'p2p-sold-icon',
    path: 'assets/img/mat-icons/peer-to-peer/p2p-sold-icon.component.svg',
  },
  {
    name: 'p2p-steam-trade-lock-icon',
    path: 'assets/img/mat-icons/peer-to-peer/p2p-steam-trade-lock-icon.component.svg',
  },
  {
    name: 'p2p-timer-icon',
    path: 'assets/img/mat-icons/peer-to-peer/p2p-timer-icon.component.svg',
  },
  {
    name: 'p2p-tooltip-alert-icon',
    path: 'assets/img/mat-icons/peer-to-peer/p2p-tooltip-alert-icon.component.svg',
  },
  {
    name: 'p2p-tooltip-info-icon',
    path: 'assets/img/mat-icons/peer-to-peer/p2p-tooltip-info-icon.component.svg',
  },
  {
    name: 'p2p-trading-icon',
    path: 'assets/img/mat-icons/peer-to-peer/p2p-trading-icon.component.svg',
  },
  {
    name: 'p2p-up-arrow-icon',
    path: 'assets/img/mat-icons/peer-to-peer/p2p-up-arrow-icon.component.svg',
  },
  {
    name: 'peer-to-peer-edit-icon',
    path: 'assets/img/mat-icons/peer-to-peer/peer-to-peer-edit-icon.component.svg',
  },
  //из ББ пак
  {
    name: 'p2p-trade-icon',
    path: 'assets/img/mat-icons/peer-to-peer/p2p-trade-icon.svg',
  },
  {
    name: 'p2p-timer',
    path: 'assets/img/mat-icons/peer-to-peer/p2p-timer.svg',
  },
  {
    name: 'p2p-steam-icon',
    path: 'assets/img/mat-icons/peer-to-peer/p2p-steam-icon.svg',
  },
  {
    name: 'p2p-status-warning',
    path: 'assets/img/mat-icons/peer-to-peer/p2p-status-warning.svg',
  },
  {
    name: 'p2p-status-success',
    path: 'assets/img/mat-icons/peer-to-peer/p2p-status-success.svg',
  },
  {
    name: 'p2p-status-search',
    path: 'assets/img/mat-icons/peer-to-peer/p2p-status-search.svg',
  },
  {
    name: 'p2p-status-alert',
    path: 'assets/img/mat-icons/peer-to-peer/p2p-status-alert.svg',
  },
  {
    name: 'p2p-start-trade-icon',
    path: 'assets/img/mat-icons/peer-to-peer/p2p-start-trade-icon.svg',
  },
  {
    name: 'p2p-seller-banned',
    path: 'assets/img/mat-icons/peer-to-peer/p2p-seller-banned.svg',
  },
  {
    name: 'p2p-remove-trade-icon',
    path: 'assets/img/mat-icons/peer-to-peer/p2p-remove-trade-icon.svg',
  },
  {
    name: 'p2p-remove-icon',
    path: 'assets/img/mat-icons/peer-to-peer/p2p-remove-icon.svg',
  },
  {
    name: 'p2p-price-direction',
    path: 'assets/img/mat-icons/peer-to-peer/p2p-price-direction.svg',
  },
  {
    name: 'p2p-play-trade-icon',
    path: 'assets/img/mat-icons/peer-to-peer/p2p-play-trade-icon.svg',
  },
  {
    name: 'p2p-pause-trade-icon',
    path: 'assets/img/mat-icons/peer-to-peer/p2p-pause-trade-icon.svg',
  },
  {
    name: 'p2p-edit-icon',
    path: 'assets/img/mat-icons/peer-to-peer/p2p-edit-icon.svg',
  },
  {
    name: 'p2p-attention-icon',
    path: 'assets/img/mat-icons/peer-to-peer/p2p-attention-icon.svg',
  },
  {
    name: 'notification-pause',
    path: 'assets/img/mat-icons/peer-to-peer/notification-pause.svg',
  },
  {
    name: 'market-price-direction',
    path: 'assets/img/mat-icons/peer-to-peer/market-price-direction.svg',
  },
  {
    name: 'item-mock',
    path: 'assets/img/mat-icons/peer-to-peer/item-mock.svg',
  },
  {
    name: 'balance-attention',
    path: 'assets/img/mat-icons/peer-to-peer/balance-attention.svg',
  },
];
