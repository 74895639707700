import { Inject, Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Store } from '@ngxs/store';

import { EnvironmentService } from '@app/core/environment-service';
import { IS_SERVER_TOKEN } from '@app/shared/utils';

import { DFormControlType, DForms, IDFormControl, IDFormPrepared } from './dynamic-forms.interface';
import { DynamicFormsState } from './dynamic-forms.state';

@Injectable({
  providedIn: 'root',
})
export class DynamicFormsService {
  private readonly forms: { [key: string]: IDFormPrepared } = {};

  constructor(
    private readonly store: Store,
    private readonly environmentService: EnvironmentService,
    @Inject(IS_SERVER_TOKEN) private isServer: boolean,
  ) {}

  /**
   * На основе параметров создаем FormControls на основе которых FormBuilder создаст форму
   */
  #generateInputs(name: DForms, tab = 0): IDFormPrepared {
    const form = this.store.selectSnapshot(DynamicFormsState)[name];

    const formControls: { [key: string]: { control: FormControl; params: [IDFormControl] } } = {};

    form.controls[tab].forEach((dcontrol: IDFormControl) => {
      this.#getControlKeys(dcontrol).forEach((key: string, index: number) => {
        if (formControls[key]) {
          // Если несколько контролов влияют на одно и то же значение, то дополняем список зависимости
          formControls[key].params.push(dcontrol);
          return;
        }
        const control = this.#formatFormControl(dcontrol, index);
        if (control) {
          formControls[key] = control;
        }
      });
    });

    return { controls: formControls, localeRoot: form.localeRoot, disableCountParams: form.disableCountParams };
  }

  #formatFormControl(dcontrol: IDFormControl, index: number): { control: FormControl; params: [IDFormControl] } | null {
    switch (dcontrol.type) {
      case DFormControlType.Text:
        return {
          params: [dcontrol],
          control: new FormControl(dcontrol.value, dcontrol.fbOptions),
        };
      case DFormControlType.RangeFromTo:
      case DFormControlType.NumberFromTo:
      case DFormControlType.NumberFromToWithOptions:
        return {
          params: [dcontrol],
          control: new FormControl(index === 0 ? dcontrol.initMin : dcontrol.initMax, dcontrol.fbOptions),
        };
      case DFormControlType.Checkbox:
        return {
          params: [dcontrol],
          control: new FormControl(dcontrol.checked ? dcontrol.outputData.checkedValue : null, dcontrol.fbOptions),
        };
      case DFormControlType.CheckboxList:
        // Если у нас список, который содержит внутри себя чекбоксы, и по дефолту checked, то объединяем все их свойства
        return {
          params: [dcontrol],
          control: new FormControl(
            dcontrol.controls.reduce(
              (result: string, subControl: any) =>
                subControl.checked
                  ? result
                    ? (result += ',' + subControl.outputData.checkedValue)
                    : subControl.outputData.checkedValue
                  : result,
              '',
            ),
            dcontrol.fbOptions,
          ),
        };
      default:
        return null;
    }
  }

  /**
   * Метод достает из каждого контрола outputData из которых потом создаются контролы
   */
  #getControlKeys(control: IDFormControl): string[] {
    const controls: Set<string> = new Set<string>();
    const outputData = control.outputData || [];

    Object.entries(outputData).forEach((property) => {
      if (property[0].toLowerCase().endsWith('key') && typeof property[1] === 'string') {
        controls.add(property[1]);
      }
    });

    // Пока только один тип содержит вложенные контролы - CheckboxList
    if (control.type === DFormControlType.CheckboxList && control.controls) {
      control.controls.forEach((subControl: IDFormControl) =>
        this.#getControlKeys(subControl).forEach((subControls) => {
          controls.add(subControls);
        }),
      );
    }
    return [...controls];
  }

  getForm(name: DForms, tab = 0): IDFormPrepared {
    this.forms[name] = this.#generateInputs(name, tab);
    return this.forms[name];
  }
}
