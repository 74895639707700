<div
  class="left-navbar__container flex fx-align-center fx-column gap-2"
  [class.left-navbar__table-view]="isTable"
>
  <div class="left-navbar__all-games left-navbar__game-container flex fx-justify-center">
    <ng-container *ngIf="modes.length">
      <app-ui-menu-item-v2
        (click)="proceedPanelStatus()"
        [itemConfig]="this.getItemConfig(allGamesBtn)"
        class="menu-item_sidebar"
      ></app-ui-menu-item-v2>
      <app-ui-games-list-panel
        *ngIf="isOpen"
        class="games-list-panel"
        [class.games-list-panel__table-view]="isTable"
        [panelConfig]="this.getPanelConfig()"
        [events]="eventsConfig"
        [modes]="modes"
        (AllGamesPanelStatus)="proceedPanelStatus()"
      ></app-ui-games-list-panel>
    </ng-container>
  </div>

  <ng-container *ngIf="!isTable; else table_view">
    <!-- <button (click)="testAnim = !testAnim">anim test</button>-->
    <!-- FIXME Никакого триггера для анимации кстати нет-->
    <ng-container *ngIf="lastGames.length">
      <div
        class="left-navbar__game-container flex fx-justify-center"
        *ngFor="let game of lastGames | slice : 0 : sliceMod"
      >
        <app-ui-menu-item-v2
          [itemConfig]="this.getItemConfig(game)"
          class="menu-item_sidebar"
        ></app-ui-menu-item-v2>
        <app-ui-winner-banner
          *ngIf="testAnim"
          [winnerBannerInfo]="winnerBannerInfo"
        ></app-ui-winner-banner>
      </div>
      <div
        class="left-navbar__delimiter-wrapper"
        (click)="operateNavbar()"
      >
        <div class="left-navbar__delimiter"></div>
      </div>
    </ng-container>

    <app-ui-menu-item-v2
      class="left-navbar__game-container flex fx-justify-center market menu-item_sidebar"
      [itemConfig]="this.getItemConfig(marketBtn)"
    ></app-ui-menu-item-v2>
  </ng-container>
  <ng-template #table_view>
    <div
      class="left-navbar__table-view__show-navbar flex fx-align-center"
      *ngIf="isOpen"
    >
      <div
        (click)="proceedPanelStatus()"
        class="flex fx-align-center close-button"
      >
        <mat-icon
          class="menu-item__icon table-close-icon"
          svgIcon="menu-close-v2"
        ></mat-icon>
      </div>
      <ng-scrollbar track="horizontal">
        <div class="left-navbar__scroll-container flex fx-align-center">
          <app-ui-menu-item-v2
            class="m-0 menu-item_panel"
            *ngFor="let game of lastGames"
            (click)="proceedPanelStatus()"
            [itemConfig]="this.getItemConfig(game)"
          ></app-ui-menu-item-v2>
        </div>
      </ng-scrollbar>
    </div>
  </ng-template>
</div>
