<ng-container *ngIf="data">
  <div
    class="widget"
    [style.height]="data.height || '19.5rem'"
  >
    <h4 class="widget-header">
      {{ data.header | translate }}
    </h4>

    <ng-container *ngIf="data.replayDrop; else avatarTemplate">
      <div class="widget-content drop-card">
        <app-ui-drop-card
          [drop]="data.replayDrop"
          [className]="'bestdrop-widget'"
          (clickOnCard)="navigateToCaseItem($event)"
          [isFullView]="true"
        ></app-ui-drop-card>
      </div>
    </ng-container>

    <ng-template #avatarTemplate>
      <div class="widget-content">
        <div class="widget-avatar-container">
          <app-ui-user-avatar
            [url]="data.avaLink"
            [userId]="data.id"
            [@changeAnimation]="data.id"
          ></app-ui-user-avatar>
        </div>
        <div class="widget-player-name">
          <div class="widget-player-name-wrapper">{{ data.name }}</div>
        </div>
      </div>
    </ng-template>

    <div
      class="widget-footer"
      *ngIf="data.payload as payload"
    >
      <div
        class="widget-footer--card-info --plain-text"
        *ngFor="let item of payload"
      >
        <div class="widget-footer--card-info-title">{{ item.label | translate }}:</div>
        <div
          class="widget-footer--card-info-value"
          [ngSwitch]="item.valueType"
        >
          <ng-container *ngSwitchCase="'currency'">
            {{ item.value | appCurrency }}
          </ng-container>
          <ng-container *ngSwitchCase="'percent'"> {{ item.value }}% </ng-container>
          <ng-container *ngSwitchCase="'user'">
            <app-ui-user-avatar
              [url]="data.avaLink"
              [userId]="data.id"
              [@changeAnimation]="data.id"
            ></app-ui-user-avatar>
            <span class="widget-footer--card-info-value--user-nickname"> {{ data.name }}</span>
          </ng-container>
          <ng-container *ngSwitchDefault>
            {{ item.value }}
          </ng-container>
        </div>
      </div>

      <!-- <div class="widget-footer--card-info --action-btn">
        <app-ui-user-avatar
          [url]="data.avaLink"
          [userId]="data.id"
          [@changeAnimation]="data.id"
        ></app-ui-user-avatar>
        <div>x170</div>
      </div> -->
    </div>
  </div>
</ng-container>
