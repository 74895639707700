<div
  class="case-revesion-modal"
  *ngIf="caseRevision$ | async as caseRevision"
>
  <app-ui-case-full-info
    [caseItem]="caseRevision.revision.case"
    [caseRevision]="caseRevision"
    (onselectRevision)="getCaseRevision($event)"
  >
  </app-ui-case-full-info>
  <ng-container *ngIf="caseRevision.revision.case.type !== caseTypeEnum.FREE">
    <app-ui-skin-cards-list [caseRevision]="caseRevision"> </app-ui-skin-cards-list>
  </ng-container>
  <button
    class="btn close-btn"
    (click)="close()"
  >
    <mat-icon
      [title]="'LOCAL.CASES.MODAL.REVISION.CLOSE' | translate"
      class="close-icon"
      [svgIcon]="'close'"
    >
    </mat-icon>
  </button>
</div>
