import { ModalNames, RoutableModalNames } from '@dev-fast/types';

export class OpenModal {
  public static readonly type = '[Modals] Open modal';
  constructor(public name: ModalNames, public payload?: any) {}
}
export class ModalOpened {
  public static readonly type = '[Modals] Open modal success';
  constructor(public name: ModalNames, public payload?: any) {}
}
export class OpenRoutableModal {
  public static readonly type = '[Modals] Open routable modal';
  constructor(public name: RoutableModalNames, public payload?: any) {}
}
export class CloseModal {
  public static readonly type = '[Modals] Close modal';
  constructor(public name: ModalNames, public payload?: any) {}
}
export class CloseRoutableModal {
  public static readonly type = '[Modals] Close routable modal';
  constructor(public payload?: { url?: string; queryParams?: Record<string, string> }) {}
}
export class CloseAllModals {
  public static readonly type = '[Modals] Close all modals';
}
