import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { LazyLoadImageModule, SkinItemImageModule } from '@app/shared/directives';
import { AppCurrencyModule } from '@app/shared/pipes';

import { SkinItemTooltipComponent } from './skin-item-tooltip.component';
import { SkinItemTooltipDirective } from './skin-item-tooltip.directive';

@NgModule({
  declarations: [SkinItemTooltipComponent, SkinItemTooltipDirective],
  imports: [CommonModule, OverlayModule, AppCurrencyModule, LazyLoadImageModule, SkinItemImageModule, TranslateModule.forChild()],
  exports: [SkinItemTooltipComponent, SkinItemTooltipDirective],
})
export class SkinItemTooltipModule {}
