import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IP2pDepositingItem, OrderStatusEnum } from '@dev-fast/types';
import moment from 'moment';
import { interval, Observable, timer } from 'rxjs';
import { filter, map, startWith, switchMap, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'app-p2p-auction-completed-modal',
  templateUrl: './p2p-auction-completed-modal.component.html',
  styleUrls: ['./p2p-auction-completed-modal.component.scss', './p2p-auction-completed-modal.media.scss', '../../styles/skin-lot.scss'],
})
export class P2pAuctionCompletedModalComponent {
  initialProgress$: Observable<string> | undefined;
  offerProgress$: Observable<number> | undefined;
  offerTimer$: Observable<number> | undefined;

  cardsBulk = Array(16);
  data$: Observable<IP2pDepositingItem>;

  constructor(
    @Inject(MAT_DIALOG_DATA) private inData$: Observable<IP2pDepositingItem>,
    readonly dialogRef: MatDialogRef<P2pAuctionCompletedModalComponent>,
  ) {
    this.data$ = this.inData$.pipe(
      tap((val) => {
        if (val && val.status !== OrderStatusEnum.WAIT_FOR_TRADE) {
          this.dialogRef.close();
        }
      }),
    );
    this.subscribeEmitters();
  }

  calcPages(position: number[]): number[] {
    const [page] = position;
    return page > 2 ? [page - 2, page - 1, page, page + 1, page + 2] : [1, 2, 3, 4, 5];
  }
  private subscribeEmitters(): void {
    this.offerTimer$ = this.data$.pipe(
      filter(({ nextStatusAt }) => Date.parse(nextStatusAt) > Date.now()),
      switchMap(({ nextStatusAt }) =>
        interval(1000).pipe(
          startWith(Date.parse(nextStatusAt) - Date.now()),
          map(() => Date.parse(nextStatusAt) - Date.now()),
          takeUntil(timer(Date.parse(nextStatusAt) - Date.now())),
        ),
      ),
    );

    this.initialProgress$ = this.data$.pipe(
      map(({ nextStatusAt, statusAt }) =>
        (100 - (moment(nextStatusAt).diff(moment()) / moment(nextStatusAt).diff(statusAt)) * 100).toFixed(2),
      ),
    );

    this.offerProgress$ = this.data$.pipe(map(({ nextStatusAt }) => Date.parse(nextStatusAt) - Date.now()));
  }
}
