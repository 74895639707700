import { ComponentType } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, NgModule } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { TuiSwipe, TuiSwipeModule } from '@taiga-ui/cdk';

import { expandAnimation } from '@app/ui/animations';

@Component({
  selector: 'app-mobile-modificator',
  templateUrl: './mobile-modificator.component.html',
  styleUrls: ['./mobile-modificator.component.scss'],
  animations: [expandAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MobileModificatorComponent {
  @Input() content: ComponentType<any> | undefined;
  constructor(public dialogRef: MatDialogRef<MobileModificatorComponent>) {}
  close(): void {
    this.dialogRef.close();
  }
  onSwipe(swipe: TuiSwipe): void {
    if (swipe.direction === 'bottom') {
      this.close();
    }
  }
}

@NgModule({
  imports: [CommonModule, TuiSwipeModule],
  declarations: [MobileModificatorComponent],
  exports: [MobileModificatorComponent],
})
export class MobileModificatorComponentModule {}
