import { IconConfig } from '@dev-fast/types';

export const iconsRefill: IconConfig[] = [
  {
    name: 'wallet-error',
    path: 'assets/img/mat-icons/refill/wallet-error.svg',
  },
  {
    name: 'bonus_case',
    path: 'assets/img/mat-icons/refill/bonus_case.svg',
  },
  {
    name: 'bonus_percent',
    path: 'assets/img/mat-icons/refill/bonus_percent.svg',
  },
];
