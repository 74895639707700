import { Portal } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';

import { BehaviorSubjectItem } from '@app/shared/utils';

@Injectable({
  providedIn: 'root',
})
export class PortalService {
  public sectionHeaderPortal: BehaviorSubjectItem<Portal<any> | undefined> = new BehaviorSubjectItem<Portal<any> | undefined>(undefined);
  public customWidgetsPortal: BehaviorSubjectItem<Portal<any> | undefined> = new BehaviorSubjectItem<Portal<any> | undefined>(undefined);
  public routableModalPortal: BehaviorSubjectItem<Portal<any> | undefined> = new BehaviorSubjectItem<Portal<any> | undefined>(undefined);
  public controlPanelPortal: BehaviorSubjectItem<Portal<any> | undefined> = new BehaviorSubjectItem<Portal<any> | undefined>(undefined);
}
