import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, NgModule, OnDestroy, Output } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatSliderModule } from '@angular/material/slider';
import { IFilterFormContent, IFilterFormTemplate, IFilterMobilePayload, MarketSortingTypes } from '@dev-fast/types';
import { TranslateModule } from '@ngx-translate/core';
import { TuiCheckboxModule } from '@taiga-ui/kit';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { Subject, Subscription, takeUntil } from 'rxjs';

import { CurrencyComponent } from '@app/core/currency';

import { TabGroupModule } from '../tab-group/tab-group.component';
import {
  FilterCheckboxListItemComponent,
  FilterMobileMenuComponent,
  FilterPanelComponent,
  FilterPriceRangeComponent,
  FilterSliderRangeComponent,
  FilterSubPanelComponent,
} from './components';
import { FilterCheckboxItemComponent } from './components/filter-checkbox-item/filter-checkbox-item.component';

const APPLIED_FILTERS_AMOUNT_LIMIT = 9;

/**
 * Компонент кнопки фильров. Рассчитана на инвентарь и маркет
 * (или на любое другое место где нужно использовать подробный фильтр предметов)
 * Работает в связке с DynamicFormService
 * Открывает/закрывает панель фильтров, отображает изменения фильтров и позвляет применить фильтры
 */
@Component({
  selector: 'app-ui-items-filter-button',
  templateUrl: './items-filter-button.component.html',
  styleUrls: ['./items-filter-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ItemsFilterButtonComponent implements OnDestroy {
  private readonly destroy$ = new Subject<void>();

  // Идет ли поиск предметов
  @Input() isLoading: boolean | null = false;
  // открыта ли эта панель
  @Input() isFilterPanelOpened = false;
  // Форма содержащая контролы этого фильтра
  @Input() set filterForm(form: FormGroup<IFilterFormTemplate> | undefined) {
    this.form = form;
    this.subscribeFormChanges();
  }
  // Дефолтное значение формы (по нему фильтр сравнивает есть ли изменения)
  @Input() set initValues(initFormValue: IFilterFormContent<MarketSortingTypes>) {
    if (initFormValue) {
      this.initFormValue = initFormValue;
      this.appliedFiltersAmount = this.#countAppliedFilters(this.form?.value as IFilterFormContent<MarketSortingTypes>);
    }
  }
  // Количество найденных предметов (отображается над фильтром)
  @Input() itemsFoundAmount: number | null = 0;
  // Список параметров которые не нужно учитывать при подсчете "сколько фильтров применено"
  @Input() disableCountParams: string[] = [];
  // Отправка события закрытия фильтров наружу
  @Output() panelStatusChanged: EventEmitter<boolean> = new EventEmitter<boolean>();
  // Отправка события сброса суммы выбранных элементов
  @Output() resetTotalSum: EventEmitter<void> = new EventEmitter<void>();
  // Открытие мобильных фильтров (почему наружу?)
  @Output() openMobileFilter: EventEmitter<IFilterMobilePayload> = new EventEmitter<IFilterMobilePayload>();
  // Нажатие на кнопку "применить фильтры"
  @Output() newFiltersApplied: EventEmitter<void> = new EventEmitter<void>();
  // Событие для очистки стейтов после сброса фильтров
  @Output() clearFiltersEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

  isFilterResultHovered = false;
  initFormValue!: IFilterFormContent<MarketSortingTypes>;
  appliedFiltersAmount = 0;
  startAppliedFiltersAmount = 0;
  skipCountParams: string[] = [];
  formChanges!: Subscription | undefined;
  form: FormGroup<IFilterFormTemplate> | undefined;

  get appliedFiltersInfo(): number | string {
    return this.appliedFiltersAmount > APPLIED_FILTERS_AMOUNT_LIMIT ? `${APPLIED_FILTERS_AMOUNT_LIMIT}+` : this.appliedFiltersAmount;
  }

  /**
   * Открытие модалки фильтров (на телефоне)
   */
  openMobileFilterPanel(): void {
    if (!this.isFilterPanelOpened) {
      this.openMobileFilter.emit();
    }
  }

  /**
   * Очистить фильтры
   */
  clearSelectedFilters(): void {
    this.panelStatusChanged.emit(false);
    this.resetTotalSum.emit();
    this.form?.patchValue(this.initFormValue);
  }

  /**
   * Применить выбранные фильтры
   */
  applySelectedFilters(): void {
    this.newFiltersApplied.emit();
    this.changePanelStatus();
  }

  /**
   * смена состояния панели фильтров
   */
  changePanelStatus(): void {
    // Если панель сейчас будет открываться, то запомним были ли в ней какие-то фильтры на старте
    // Нужно для того, чтобы мы показывали галочку даже если примененных фильтров 0, в случае если до этого был хоть один
    if (!this.isFilterPanelOpened) {
      this.startAppliedFiltersAmount = this.appliedFiltersAmount;
    }

    this.panelStatusChanged.emit(!this.isFilterPanelOpened);
  }

  subscribeFormChanges(): void {
    if (this.formChanges) {
      this.formChanges.unsubscribe();
    }
    // Подсчитываем сколько фильтров применил пользователь
    this.formChanges = this.form?.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((values) => {
      this.appliedFiltersAmount = this.#countAppliedFilters(values as IFilterFormContent<MarketSortingTypes>);
    });
  }

  #countAppliedFilters(values: IFilterFormContent<MarketSortingTypes>): number {
    let changes = 0;
    Object.entries(values)
      .filter((keyValue) => !this.disableCountParams.includes(keyValue[0]))
      .forEach((keyValue) => {
        // Считаем лишь те значения что отличаются от initValues (значения по умолчанию)
        if (this.initFormValue[keyValue[0]] !== keyValue[1]) {
          // Эсли это строка, то делим её по запятой и подсчитываем сколько опций мы в неё засунули
          if (typeof keyValue[1] === 'string' && Boolean(keyValue[1])) {
            // Каждая опция - один примененный фильтр
            changes += keyValue[1].split(',').length;
          } else {
            // Если это не строка, то просто +1 изменение
            changes += 1;
          }
        }
      });
    return changes;
  }

  // ----- ANGULAR LIFE CYCLE -----

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TabGroupModule,
    MatIconModule,
    MatCheckboxModule,
    MatSliderModule,
    TranslateModule,
    NgScrollbarModule,
    CurrencyComponent,
    TuiCheckboxModule,
  ],
  declarations: [
    FilterCheckboxListItemComponent,
    FilterCheckboxItemComponent,
    FilterPanelComponent,
    ItemsFilterButtonComponent,
    FilterSubPanelComponent,
    FilterPriceRangeComponent,
    FilterMobileMenuComponent,
    FilterSliderRangeComponent,
  ],
  exports: [ItemsFilterButtonComponent, FilterPanelComponent, FilterMobileMenuComponent, FilterSubPanelComponent],
})
export class ItemsFilterModule {}
