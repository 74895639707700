import { Injectable } from '@angular/core';
import { ILottery } from '@dev-fast/types';
import { Socket as WrappedSocket } from 'ngx-socket-io';
import { Observable } from 'rxjs';

import { Environments, EnvironmentService } from '@app/core/environment-service';

import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class LotteryApiService {
  private environment: Environments;
  private endpoint = '/api/classic-lottery';

  constructor(
    private readonly api: ApiService,
    private readonly environmentService: EnvironmentService,
    private readonly ws: WrappedSocket
  ) {
    this.environment = this.environmentService.environments;
  }
  public getLotteryState(): Observable<ILottery[]> {
    return this.api.get<ILottery[]>(`${this.environment.GATEWAY_URL}${this.endpoint}/state`);
  }
  /** на самом деле не понятно что он вернет */
  public getLotteryStateById(id: number): Observable<ILottery> {
    return this.api.get<ILottery>(`${this.environment.GATEWAY_URL}${this.endpoint}/${id}/state`, {
      withCredentials: true,
    });
  }
  public participateOnFree(): Observable<void> {
    return this.api.post(`${this.environment.GATEWAY_URL}${this.endpoint}/free`, {
      withCredentials: true,
    });
  }
  //FIXME добавить removeListener
  // public onLotteryItemUpdate(gameId: number, callback: (response: ILottery) => void): void {
  //   this.ws.on(`refill-game:games:${gameId}:update`, callback);
  // }
  // public onStateUpdate(callback: (response: ILottery[]) => void): void {
  //   this.ws.on(`refill-game:games:ny`, callback);
  // }
  // public onUserStateUpdate(gameId: number, callback: (response: any) => void): void {
  //   this.ws.on(`refill-game:games:${gameId}:user:ny`, callback);
  // }
  // нет вроде такого
  public fetch(gameNumber: number): void {
    this.ws.emit('refill-game:user:fetch', { gameNumber });
  }
}
