<div class="lot-setup tw-flex tw-flex-col tw-gap-3.5 tw-font-normal">
  <ng-container
    *ngIf="{
      activeTab: activeTab$ | async
    } as marketData"
  >
    <div
      class="lot-setup__title"
      [class.disable]="marketData.activeTab === MarketPanelEnum.Sell"
    >
      <a
        routerLink
        [queryParams]="{ type: 'sell' }"
        class="mobile-hide"
        >{{ 'P2P_WIDGETS.DEPOSITING.SELECT_ITEM' | translate }}
      </a>
      <a
        routerLink
        [queryParams]="{ type: 'sell' }"
        class="desktop-hide"
        (click)="goToInventoryMarketTabMobile()"
        >{{ 'P2P_WIDGETS.DEPOSITING.SELECT_ITEM' | translate }}</a
      >
      {{ 'P2P_WIDGETS.DEPOSITING.ENTER_REFILL_AMOUNT' | translate }}
    </div>
    <div
      class="tw-flex tw-flex-col"
      [formGroup]="adviserFormGroup"
    >
      <app-ui-input
        formControlName="currency"
        [prefixIcon]="adviserFormGroup.controls.currency.value ? 'coin-secondary' : undefined"
        [checkValidation$]="checkValidation$"
        [placeholder]="'P2P_WIDGETS.DEPOSITING.ENTER_AMOUNT' | translate"
      >
        <div
          *ngIf="selected"
          class="lot-setup__refresh tw-cursor-pointer tw-mr-2"
          (click)="reqAutoSelectSteamItems()"
        >
          <mat-icon svgIcon="refresh-line"></mat-icon>
        </div>
      </app-ui-input>
      <ng-container *ngIf="!selected">
        <button
          [disabled]="!adviserFormGroup.controls.currency.value"
          class="btn lot-setup__action-btn tw-pb-2 tw-font-normal"
          (click)="reqAutoSelectSteamItems()"
        >
          {{ 'P2P_WIDGETS.DEPOSITING.PICK_ITEMS' | translate }}
        </button>
      </ng-container>
    </div>
  </ng-container>
</div>
