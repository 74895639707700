import { IconConfig } from '@dev-fast/types';

export const iconsObjects: IconConfig[] = [
  {
    name: 'automate-gun',
    path: 'assets/img/mat-icons/objects/automate-gun.svg',
  },
  {
    name: 'clever',
    path: 'assets/img/mat-icons/objects/clever.svg',
  },
  {
    name: 'diamond',
    path: 'assets/img/mat-icons/objects/diamond.svg',
  },
  {
    name: 'theme-selector',
    path: 'assets/img/mat-icons/objects/theme-selector.svg',
  },
  {
    name: 'privacy-star',
    path: 'assets/img/mat-icons/objects/star.svg',
  },
];
