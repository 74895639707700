import { IconConfig } from '@dev-fast/types';
// TODO Delete -design prefix after release
export const iconsMarketplaceDesign: IconConfig[] = [
  {
    name: 'lvl-chevron--design',
    path: 'assets/img/design/market/lvl-chevron.svg',
  },
  {
    name: 'ghost--design',
    path: 'assets/img/design/market/ghost.svg',
  },
  {
    name: 'green-arrow--design',
    path: 'assets/img/design/market/green-arrow.svg',
  },
  {
    name: 'white-arrow--design',
    path: 'assets/img/design/market/white-arrow.svg',
  },
  {
    name: 'market--design',
    path: 'assets/img/design/market/market.svg',
  },
  {
    name: 'market--design-sold',
    path: 'assets/img/design/market/sold-white.svg',
  },
];
