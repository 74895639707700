import { HttpErrorResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { CasesBackendService } from '@dev-fast/backend-services';
import {
  ICaseCollection,
  ICaseCollectionType,
  ICaseEventCollection,
  ICaseItemDtoV2,
  ICasesFilter,
  ICaseType,
  ICategoryLink,
  IFilterRange,
  IHistoryOfOpenCases,
  IItemStatus,
  NotificationStatus,
  NotificationType,
} from '@dev-fast/types';
import { Action, Actions, Selector, State, StateContext } from '@ngxs/store';
import { EMPTY, Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { NotificationsService } from '@app/core/notification-service';
import { SellItems } from '@app/core/state/inventory';
import { ChangeGamesItemsStatus } from '@app/core/state/inventory-legacy';

import { CommonFiltersState } from '../..';
import { CasesGameState } from '../game-state';
import { CasesSettingsState } from '../settings-state';
import {
  ChangeCasesCategory,
  ChangeFilter,
  ChangePriceRangeValues,
  ClearCasesState,
  GetActiveCategory,
  GetAllCases,
  GetCaseOpeningHistory,
  GetCasesByCategory,
  GetEventCategories,
  GetRoutingCategories,
} from './cases.actions';
import { CASES_INITIAL_STATE, ICasesStateModel } from './cases-state.model';

@State<ICasesStateModel>({
  name: 'cases',
  defaults: CASES_INITIAL_STATE,
  children: [CasesGameState, CasesSettingsState],
})
@Injectable()
export class CasesState extends CommonFiltersState<ICasesFilter> {
  readonly #actions$ = inject(Actions);
  readonly #casesApiService = inject(CasesBackendService);
  readonly #notificationsService = inject(NotificationsService);

  @Selector()
  static activeCategory({ categoryType }: ICasesStateModel): ICaseCollectionType {
    return categoryType;
  }
  @Selector()
  static categoriesLinks({ categoriesLinks }: ICasesStateModel): ICategoryLink[] {
    return categoriesLinks;
  }
  @Selector()
  static casesCategories({ categories }: ICasesStateModel): ICaseCollection | null {
    return categories;
  }
  @Selector()
  static casesEventCategories({ eventCategories }: ICasesStateModel): ICaseEventCollection[] {
    return eventCategories;
  }
  @Selector()
  static casesEventCategoriesSuccess({ eventCategoriesSuccess }: ICasesStateModel): boolean {
    return eventCategoriesSuccess;
  }
  @Selector()
  static freeCasesAvailable({ freeCasesAvailable }: ICasesStateModel): number {
    return freeCasesAvailable;
  }
  @Selector()
  static casesHistory({ casesHistory }: ICasesStateModel): IHistoryOfOpenCases | null {
    return casesHistory;
  }

  // @Selector()
  // public static itemAvailabilityInCases({ itemAvailabilityInCases }: ICasesStateModel): ICaseItemDtoV2[] | null {
  //   return itemAvailabilityInCases;
  // }

  @Action(GetRoutingCategories)
  getRoutingCategories({ patchState }: StateContext<ICasesStateModel>): Observable<ICaseCollection[]> {
    return this.#casesApiService.getRoutingCategories().pipe(
      tap((response: ICaseCollection[]) => {
        // let freeCasesAvailable = 0;
        const links: ICategoryLink[] = [{ path: 'all', title: 'all', id: 0, tags: null, newCases: false }];
        response.map((category) => {
          // category.cases.forEach((item) => (freeCasesAvailable += item.free?.count ? item.free.count : 0));
          links.push({
            path: category.id.toString(),
            title: category.name,
            id: category.id,
            tags: category.tags,
            newCases: this.#checkNewCases(category.cases),
          });
        });

        links.push({ path: 'free', title: 'free', id: null, tags: null, newCases: false });
        patchState({
          categoriesLinks: links,
          // freeCasesAvailable: freeCasesAvailable,
        });
      }),
      catchError((error) => this.#onError(error)),
    );
  }
  @Action(GetAllCases, { cancelUncompleted: true })
  getAllCases(
    { patchState, getState, dispatch }: StateContext<ICasesStateModel>,
    { casesFilter }: GetAllCases,
  ): Observable<ICaseItemDtoV2[]> {
    const { filters } = getState();
    return this.#casesApiService.getCasesFromCategoryAll(casesFilter ? casesFilter : filters).pipe(
      tap((response: ICaseItemDtoV2[]) => {
        const { categoryType } = getState();
        const minmax: IFilterRange = response.reduce(
          (acc, curr) => {
            return {
              minValue: Math.min(acc.minValue, curr.lastRevision.price),
              maxValue: Math.max(acc.maxValue, curr.lastRevision.price),
            };
          },
          { minValue: Number.MAX_SAFE_INTEGER, maxValue: 0 },
        );
        if (casesFilter) {
          dispatch(new ChangeFilter(casesFilter));
        }
        dispatch(new ChangePriceRangeValues(minmax));
        const collection: ICaseCollection = {
          id: categoryType === 'all' ? 0 : 101,
          name: categoryType.toString(),
          cases:
            categoryType === 'all'
              ? response.filter((item: ICaseItemDtoV2) => item.type !== ICaseType.FREE)
              : response.filter(
                  (item: ICaseItemDtoV2) =>
                    (item.type === ICaseType.FREE || item.type === ICaseType.PAID_FREE) && item.free && item.free.count > 0,
                ),
          image: null,
          grid: '',
          disableAt: null,
          sortable: true,
          tags: null,
        };
        const countFromResponse = response
          .filter((value) => value.free)
          .map((val) => val.free?.count)
          .reduce((a, b) => (a ? a : 0) + (b ? b : 0), 0);
        const { categories } = getState();

        patchState({
          categories: categoryType === 'all' || categoryType === 'free' ? collection : categories,
          freeCasesAvailable: countFromResponse ? countFromResponse : 0,
        });
      }),
      catchError((error) => this.#onError(error)),
    );
  }
  @Action(GetCasesByCategory, { cancelUncompleted: true })
  getCasesByCategory(
    { patchState, getState, dispatch }: StateContext<ICasesStateModel>,
    { casesFilter }: GetCasesByCategory,
  ): Observable<ICaseCollection> {
    const { categoryType, filters } = getState();

    // if (categoryType === 'all' || categoryType === 'free') {
    return this.#casesApiService.getCategoriesById(casesFilter ? casesFilter : filters, categoryType as number).pipe(
      tap((response: ICaseCollection) => {
        patchState({
          categories: {
            ...response,
            cases: response.cases.filter((item: ICaseItemDtoV2) => item.type !== ICaseType.FREE),
          },
          // categories: { ...response, cases: response.cases.filter((item: ICaseItemDtoV2) => !item.isFree)},
        });
        if (casesFilter) {
          dispatch(new ChangeFilter(casesFilter));
        }
      }),
      catchError((error) => {
        patchState({
          categories: null,
        });
        return this.#onError(error);
      }),
    );
  }
  @Action(ChangeFilter)
  setCaseListFilters({ patchState }: StateContext<ICasesStateModel>, { casesFilter }: ChangeFilter): void {
    patchState({
      filters: casesFilter,
    });
  }
  @Action(ChangePriceRangeValues)
  setCasesPriceForRange({ patchState, getState }: StateContext<ICasesStateModel>, { range }: ChangePriceRangeValues): void {
    const { priceRange } = getState();
    patchState({
      priceRange: this.calcRange(priceRange, range),
    });
  }
  @Action(ChangeCasesCategory)
  changeCasesCategory({ dispatch, patchState }: StateContext<ICasesStateModel>, { type }: ChangeCasesCategory): Observable<void> {
    patchState({
      categoryType: type,
    });
    return dispatch([new GetActiveCategory()]);
  }
  @Action(ChangeGamesItemsStatus)
  changeItemsStatusAfterSell({ patchState, getState }: StateContext<ICasesStateModel>, { ids }: ChangeGamesItemsStatus): void {
    const { casesHistory } = getState();
    patchState({
      casesHistory: this.#updateHistoryItemStatus(casesHistory, ids),
    });
  }
  @Action(ClearCasesState)
  clearCasesState({ patchState }: StateContext<ICasesStateModel>): void {
    patchState({
      ...CASES_INITIAL_STATE,
    });
  }
  @Action(GetActiveCategory, { cancelUncompleted: true })
  getActiveCategory(
    { getState, dispatch }: StateContext<ICasesStateModel>,
    { casesFilter }: GetActiveCategory,
  ): Observable<void> | undefined {
    const { categoryType, categories } = getState();

    if (categoryType !== 'all' && categoryType !== 'free') {
      if (categories) {
        return dispatch(new GetCasesByCategory(casesFilter));
      } else {
        return dispatch([new GetAllCases(casesFilter), new GetCasesByCategory(casesFilter)]);
      }
    }
    if (categoryType === 'all' || categoryType === 'free') {
      return dispatch(new GetAllCases(casesFilter));
    }
    return;
  }
  @Action(GetEventCategories)
  getEventCategories({ patchState }: StateContext<ICasesStateModel>): Observable<ICaseEventCollection[]> {
    return this.#casesApiService.getCasesEvents().pipe(
      tap((response: ICaseEventCollection[]) => {
        // if (response.length) {
        //   dispatch(new ToggleBackground('var(--event-background)'));
        // }
        patchState({
          eventCategories: response,
          eventCategoriesSuccess: true,
        });
      }),
    );
  }
  @Action(GetCaseOpeningHistory, { cancelUncompleted: true })
  getCaseOpeningHistory(
    { patchState, getState }: StateContext<ICasesStateModel>,
    { page }: GetCaseOpeningHistory,
  ): Observable<IHistoryOfOpenCases> {
    const { casesHistory } = getState();
    return this.#casesApiService.getCaseOpeningHistory(page).pipe(
      tap((response: IHistoryOfOpenCases) => {
        if (casesHistory) {
          const updatedItems = [...casesHistory.items, ...response.items];
          patchState({
            casesHistory: { ...response, items: updatedItems },
          });
        } else {
          patchState({
            casesHistory: response,
          });
        }
      }),
    );
  }
  @Action(SellItems)
  sellItemsById({ getState, patchState }: StateContext<ICasesStateModel>, { ids }: SellItems): void {
    const { casesHistory } = getState();
    patchState({
      casesHistory: this.#updateHistoryItemStatus(casesHistory, ids),
    });
  }
  #updateHistoryItemStatus(casesHistory: IHistoryOfOpenCases | null, updatedItemIds: number[]): IHistoryOfOpenCases | null {
    if (casesHistory) {
      const { meta, items } = casesHistory;
      const updatedItems = items.map((item) => {
        if (updatedItemIds.includes(item.userInventoryItem.id)) {
          return { ...item, status: IItemStatus.SOLD };
        }
        return item;
      });
      return { meta, items: updatedItems };
    }
    return null;
  }
  #checkNewCases(cases: ICaseItemDtoV2[]): boolean {
    return !!cases.filter((caseItem) => caseItem.tags.includes('new'.toLowerCase()) && !caseItem.free).length;
  }
  #onError(error: HttpErrorResponse): Observable<never> {
    this.#notificationsService.addNotification({
      id: Date.now(),
      type: error.error && error.error.type ? error.error.type : NotificationType.Error,
      icon: 'warning',
      message: error.error && error.error.message ? error.error.message : typeof error.error === 'string' ? error.error : 'Error',
      createDate: Date.now(),
      system: true,
      status: NotificationStatus.new,
    });
    return EMPTY;
  }
}
