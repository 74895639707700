import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, NgModule } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';

import { LazyLoadImageModule } from '@app/shared/directives';
import { AppCurrencyModule } from '@app/shared/pipes';
import { colorDictionary } from '@app/shared/utils';

@Component({
  selector: 'app-ui-skin-card-new',
  templateUrl: './skin-card.component.html',
  styleUrls: ['./skin-card.component.scss', './skin-card.component.media.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SkinCardNewComponent {
  @Input() range?: { min: number | undefined; max: number | undefined };
  @Input() backgroundColor: string | null = null;
  @Input() weaponName: string | null = null;
  @Input() avatarSrc: string | null = null;
  @Input() skinName: string | null = null;
  @Input() skinWear: string | null = null;
  @Input() selected!: boolean;
  @Input() roll: number | undefined;
  @Input() chance = 0;
  @Input() index = 0;
  @Input() price = 0;

  getStyle(color: string): { background: string } {
    const newColor = colorDictionary[color.toUpperCase()];
    return { background: `linear-gradient(180.57deg, #213137 0%, ${newColor} 100%)` };
  }
}

@NgModule({
  imports: [CommonModule, TranslateModule, LazyLoadImageModule, AppCurrencyModule, MatTooltipModule],
  declarations: [SkinCardNewComponent],
  exports: [SkinCardNewComponent],
})
export class SkinCardModuleNew {}
