import { Injectable } from '@angular/core';
import {
  IWithdrawalHistory,
  IWithdrawalMethods,
  IWithdrawalReceive,
  IWithdrawalReceiveDto,
  IWithdrawalReceiveRequest,
  IWithdrawalWithdrawRequest,
  IWithdrawalWithdrawResponse,
} from '@dev-fast/types';
import { Observable } from 'rxjs';

import { Environments, EnvironmentService } from '@app/core/environment-service';

import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class WithdrawalApiService {
  private environment: Environments;

  constructor(
    private readonly api: ApiService,
    private readonly environmentService: EnvironmentService,
  ) {
    this.environment = this.environmentService.environments;
  }
  withdrawalReceive(payload: IWithdrawalReceiveRequest): Observable<IWithdrawalReceiveDto> {
    return this.api.post<IWithdrawalReceive>(`${this.environment.WITHDRAWAL_URL}/api/payout/calc`, payload, {
      withCredentials: true,
    });
  }

  withdraw(payload: IWithdrawalWithdrawRequest): Observable<IWithdrawalWithdrawResponse> {
    return this.api.post<IWithdrawalWithdrawResponse>(
      `${this.environment.WITHDRAWAL_URL}/api/payout/withdraw`,
      { ...payload },
      { withCredentials: true },
    );
  }

  getCardsList(): Observable<string[]> {
    return this.api.get<string[]>(`${this.environment.PAYMENT_URL_2}/cards`, { withCredentials: true });
  }

  addCard(): Observable<{ url: string }> {
    return this.api.post<{ url: string }>(
      `${this.environment.PAYMENT_URL_2}/cards`,
      { domain: window.location.hostname },
      { withCredentials: true },
    );
  }

  removeCard(card: string): Observable<void> {
    return this.api.delete(`${this.environment.PAYMENT_URL_2}/cards/${card}`, { withCredentials: true });
  }

  getMethods(userId: number): Observable<{ success: boolean; methods: IWithdrawalMethods }> {
    return this.api.get<{ success: boolean; methods: IWithdrawalMethods }>(`${this.environment.PAYMENT_URL_2}/payout-methods/${userId}`, {
      withCredentials: true,
    });
  }
  getHistory(): Observable<IWithdrawalHistory[]> {
    return this.api.get<IWithdrawalHistory[]>(`${this.environment.WITHDRAWAL_URL}/api/payout/history`, { withCredentials: true });
  }
}
