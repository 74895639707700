export function querySaver(allQuery: Record<string, string>, wasteParams: string[]): Record<string, string> {
  const neededParams: Record<string, string> = Object.keys(allQuery).reduce((acc: Record<string, string>, cur: string) => {
    if (!wasteParams.includes(cur)) {
      return { ...acc, [cur]: allQuery[cur] };
    }
    return acc;
  }, {});
  return neededParams;
}
export function queryPicker(allQuery: Record<string, string>, targetParams: string[]): Record<string, string> {
  const neededParams: Record<string, string> = Object.keys(allQuery).reduce((acc: Record<string, string>, cur: string) => {
    if (targetParams.includes(cur)) {
      return { ...acc, [cur]: allQuery[cur] };
    }
    return acc;
  }, {});
  return neededParams;
}
