import { Directive, ElementRef, EventEmitter, inject, NgZone, OnDestroy, OnInit, Output } from '@angular/core';

import { IS_SERVER_TOKEN } from '@app/shared/utils';

import { ResizedEvent } from './resized.event';

@Directive({
  selector: '[resized]',
})
export class ResizedDirective implements OnInit, OnDestroy {
  readonly #isServer = inject<boolean>(IS_SERVER_TOKEN);
  readonly #zone = inject(NgZone);
  readonly #element = inject(ElementRef);

  readonly #observer?: ResizeObserver;

  #oldRect?: DOMRectReadOnly;

  @Output() readonly resized = new EventEmitter<ResizedEvent>();

  constructor() {
    if (!this.#isServer) {
      this.#observer = new ResizeObserver((entries) => this.#zone.run(() => this.#observe(entries)));
    }
  }

  ngOnInit(): void {
    if (!this.#isServer) {
      this.#observer?.observe(this.#element.nativeElement);
    }
  }

  ngOnDestroy(): void {
    if (!this.#isServer) {
      this.#observer?.disconnect();
    }
  }

  #observe(entries: ResizeObserverEntry[]): void {
    // Фикс для ResizeObserver loop completed with undelivered notifications.
    // Возникала из-за тонны вызовов в секунду. Так что мы теперь их откладываем с помощью requestAnimationFrame.
    requestAnimationFrame(() => {
      const domSize = entries[0];

      const resizedEvent = new ResizedEvent(domSize.contentRect, this.#oldRect);
      this.#oldRect = domSize.contentRect;
      this.resized.emit(resizedEvent);
    });
  }
}
