<ng-container *ngIf="items.length">
  <div class="menu__group">
    <ng-container *ngFor="let item of items">
      <div
        class="menu__item"
        [class]="item.itemClass"
        *ngIf="item.status !== controlStateEnum.HIDEN"
      >
        <ng-container>
          <button
            *ngIf="item.icon"
            (click)="item.callback && item.callback()"
            class="tw-relative"
          >
            <div
              *ngIf="!!item.notificationCount && item.notificationCount()"
              class="icon-status tw-absolute tw-font-black tw-flex tw-items-center tw-justify-center tw-roun"
            >
              {{ notyFormat(item.notificationCount() || 0) }}
            </div>
            <mat-icon [svgIcon]="item.icon"> </mat-icon>
          </button>
        </ng-container>
      </div>
    </ng-container>
  </div>
</ng-container>
