import { CommonModule, CurrencyPipe } from '@angular/common';
import { NgModule } from '@angular/core';

import { AppCurrencyTranslatePipe } from './currency-translate.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [AppCurrencyTranslatePipe],
  exports: [AppCurrencyTranslatePipe],
  providers: [CurrencyPipe, AppCurrencyTranslatePipe],
})
export class AppCurrencyTranslateModule {}
