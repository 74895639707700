import { IconConfig } from '@dev-fast/types';

export const substratesImg: IconConfig[] = [
  {
    name: 'thunder_1',
    path: 'assets/img/substrates/thunder_1.svg',
  },
  {
    name: 'thunder_2',
    path: 'assets/img/substrates/thunder_2.svg',
  },
];
