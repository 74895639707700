import { Injectable } from '@angular/core';
import {
  ActivePanel,
  ActiveRoute,
  BreakpointsTypes,
  Design,
  FooterCounts,
  GameMode,
  IGame,
  IInventoryShortInfo,
  MarketCounts,
  ModalNames,
  NewMenu,
  NewPanel,
  Panel,
  WalletTypeEnum,
} from '@dev-fast/types';
import { Navigate } from '@ngxs/router-plugin';
import { Select } from '@ngxs/store';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { map, Observable } from 'rxjs';

import { LanguageService } from '@app/core/language-service';
import { NotificationsService } from '@app/core/notification-service';
import { AccountState, GetLevelsRoadMap } from '@app/core/state/account';
import { GamesState } from '@app/core/state/games-store';
import { GetInventoryInfo, InventoryState } from '@app/core/state/inventory';
import { ChangeDesign, ClickOnLockedSide, ClosePanel, LayoutState, OpenPanel } from '@app/core/state/layout';
import { GetLotteryItems } from '@app/core/state/lottery';
import { OpenModal } from '@app/core/state/modals';
import { CommonStoreService } from '@app/core-state-common';

import { getDictGames } from '../utils';

@Injectable({
  providedIn: 'root',
})
export class GofastLayoutService {
  @Select(LayoutState.activePanelNew)
  readonly panels$!: Observable<NewPanel[]>;
  @Select(LayoutState.activeMenu)
  activeMenu$!: Observable<NewMenu[]>;
  @Select(LayoutState.isFadeSides)
  readonly isFadeSides$!: Observable<boolean>;
  @Select(LayoutState.isNewDesign)
  readonly isNewDesign$!: Observable<boolean>;
  @Select(LayoutState.breakpoints)
  readonly breakpoints$!: Observable<BreakpointsTypes | null>;
  @Select(LayoutState.gameSelectorOpened)
  readonly gameSelectorOpened$!: Observable<boolean>;
  @Select(LayoutState.counts)
  readonly counts$!: Observable<FooterCounts>;
  @Select(LayoutState.marketCounts)
  readonly marketCounts$!: Observable<MarketCounts>;
  @Select(LayoutState.online)
  readonly online$!: Observable<number>;
  @Select(LayoutState.activeRoute)
  readonly activeRoute$!: Observable<ActiveRoute | null>;
  @Select(GamesState.availableGames)
  readonly availableGames$!: Observable<IGame[]>;
  @Select(GamesState.lastGames)
  readonly lastGames$!: Observable<IGame[]>;
  @Select(GamesState.curLegacyGame)
  readonly curLegacyGame$!: Observable<string | null>;
  @Select(AccountState.canLevelUp)
  canLevelUp$!: Observable<boolean>;
  @Select(InventoryState.inventoryShortInfo)
  inventoryShortInfo$!: Observable<IInventoryShortInfo>;

  userBalance$: Observable<number> = this.commonService.getWalletById(WalletTypeEnum.BALANCE);
  locales$ = this.languageService.locales$;
  currentLocale$ = this.languageService.currentLocale$;
  user$ = this.commonService.user$;
  dictAvailableGames$: Observable<Partial<Record<GameMode, IGame[]>> | undefined> = this.availableGames$.pipe(
    map((games) => getDictGames(games)),
  );
  init(): void {
    this.commonService.init();
    this.languageService.getLocales();
    this.notificationsService.getNotifications();
    this.getLevelsRoadMap();
  }

  constructor(
    private commonService: CommonStoreService,
    private readonly notificationsService: NotificationsService,
    private readonly languageService: LanguageService,
  ) {}

  changeActivePanel = (activePanel: ActivePanel | null): void => {
    this.commonService.changeActivePanel(activePanel);
    if (activePanel?.panel === Panel.MAIN_MENU) {
      this.getInventoryInfo();
    }
  };

  login = (): void => this.commonService.login();
  logout = (): void => this.commonService.logout();

  @Dispatch() openModal = (name: ModalNames, payload?: any): OpenModal => new OpenModal(name, payload);
  @Dispatch() getInventoryInfo = (): GetInventoryInfo => new GetInventoryInfo();
  @Dispatch() navigate = (path: any[]): Navigate => new Navigate(path);
  @Dispatch() clickOnLockedSide = (): ClickOnLockedSide => new ClickOnLockedSide();
  @Dispatch() getLotteryItems = (): GetLotteryItems => new GetLotteryItems();
  @Dispatch() getLevelsRoadMap = (): GetLevelsRoadMap => new GetLevelsRoadMap();
  @Dispatch() changeDesign = (value: Design): ChangeDesign => new ChangeDesign(value);
  @Dispatch() openPanel = (panel: NewPanel): OpenPanel => new OpenPanel(panel);
  @Dispatch() closePanel = (panel: NewPanel): ClosePanel => new ClosePanel(panel);
}
