import { animate, query, sequence, style, transition, trigger } from '@angular/animations';

export const narrowingAnimation = trigger('narrowingAnimation', [
  transition(':leave', [
    query(
      ':self',
      [
        style({ width: '*', opacity: 1, margin: '*', padding: '*' }),
        sequence([animate('150ms ease-in-out', style({ opacity: 0, width: 0, margin: 0, padding: 0 }))]),
      ],
      { optional: true },
    ),
  ]),
]);
