import { IconConfig } from '@dev-fast/types';
// TODO Delete -design prefix after release
// TODO arrow settings user полюбому уйдут в common
export const iconsHeaderMenu: IconConfig[] = [
  {
    name: 'bank-card-design',
    path: 'assets/img/design/header/bank-card.svg',
  },
  {
    name: 'brifecase-design',
    path: 'assets/img/design/header/brifecase.svg',
  },
  {
    name: 'profile-users-design',
    path: 'assets/img/design/header/profile-users.svg',
  },
  {
    name: 'user-design',
    path: 'assets/img/design/header/user.svg',
  },
  {
    name: 'volume-on-outline-design',
    path: 'assets/img/design/header/volume-on-outline.svg',
  },
  {
    name: 'volume-off-outline-design',
    path: 'assets/img/design/header/volume-off-outline.svg',
  },
  {
    name: 'settings-fill--design',
    path: 'assets/img/design/header/settings-fill.svg',
  },
  {
    name: 'login-design',
    path: 'assets/img/design/header/login.svg',
  },
  {
    name: 'logout-design',
    path: 'assets/img/design/header/logout.svg',
  },
  {
    name: 'lvlup-star-design',
    path: 'assets/img/design/header/lvlup-star.svg',
  },
  //FIXME заменить везде на arrow--design и rotate
  {
    name: 'arrow-left-design',
    path: 'assets/img/design/header/arrow-left.svg',
  },
  {
    name: 'talon-knife--design',
    path: 'assets/img/design/header/talon-knife.svg',
  },
];
