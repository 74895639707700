<ng-container *ngIf="$any(ngControl.control) as checkboxControl">
  <div
    class="checkbox"
    [@bellAnimation]="animTrigger()"
  >
    <mat-checkbox
      [class]="classes"
      [class.alert]="checkValid(checkboxControl)"
      [formControl]="checkboxControl"
    >
      <ng-container *ngFor="let label of labels">
        <ng-container *ngIf="label.href; else labelTemplate">
          <a
            langRouterLink
            [routerLink]="label.href"
            class="label-text_link"
            [target]="label.target"
          >
            {{ label.title | translate }}
          </a>
        </ng-container>
        <ng-template #labelTemplate>
          <span class="label-text">
            {{ label.title | translate }}
          </span>
        </ng-template>
      </ng-container>
      <ng-container *ngIf="innerLabels; else outlet">
        <div
          class="label-inner"
          [innerHTML]="innerLabels"
        ></div>
      </ng-container>
    </mat-checkbox>
  </div>
</ng-container>

<ng-template #outlet>
  <ng-content></ng-content>
</ng-template>
