<ng-container
  *ngIf="{
    user: user$ | async,
    locales: locales$ | async,
    currentLocale: currentLocale$ | async,
    inventoryShortInfo: inventoryShortInfo$ | async,
    canLevelUp: canLevelUp$ | async,
    menu: menu$ | async,
    balance: userBalance$ | myAsync
  } as data"
>
  <div
    [ngClass]="{ 'header-wrapper_open-main-menu': isMainMenuOpen(data.menu) }"
    class="header-wrapper"
  >
    <div class="header-wrapper__logo">
      <app-ui-brand
        [link]="'/'"
        [query]="{ type: 'buy' }"
      ></app-ui-brand>
    </div>

    <div
      *ngIf="data.user"
      class="header__actions"
    >
      <app-balance-component
        [inventoryCount]="data.inventoryShortInfo?.inventoryCount"
        [userBalance]="data.balance"
        (openInventory)="onInventoryOpen()"
        (onopenTransactions)="onOpenWithdrawal($event)"
      >
      </app-balance-component>
    </div>

    <div class="header-wrapper__menu">
      <ng-container *ngIf="data.user !== undefined; else loader">
        <ng-container *ngIf="data.user; else notAuthTemplate">
          <app-notifications-v2> </app-notifications-v2>
        </ng-container>
        <ng-template #notAuthTemplate>
          <button
            class="login-btn btn btn-basic--design"
            (click)="onLogin()"
          >
            {{ 'LOGIN' | translate }}
          </button>
        </ng-template>
        <app-header-action-menu
          [user]="data.user"
          [locales]="data.locales"
          [locale]="data.currentLocale"
          [canLevelUp]="data.canLevelUp"
          [userBalance]="data.balance"
        >
        </app-header-action-menu>
      </ng-container>
      <ng-template #loader>
        <app-ui-spinner-loader> </app-ui-spinner-loader>
      </ng-template>
    </div>
  </div>
</ng-container>
