<ng-container *ngIf="item$ | async as item">
  <!-- {{ item.status }} -->
  <div
    *ngIf="[statuses.NEW].includes(item.status)"
    class="new-selling-item"
  >
    <div class="selling-indicator">
      <div class="selling-indicator__icon">
        <mat-spinner
          [strokeWidth]="2"
          [diameter]="18"
          [color]="'accent'"
        >
        </mat-spinner>
      </div>
      <div class="selling-indicator__text">
        {{ 'P2P_WIDGETS.DEPOSITING.DEPOSIT' | translate | uppercase }}
      </div>
      <span
        class="value countdown"
        *ngIf="offerTimer$ | async as offerTimer"
      >
        <span class="p2p-purchase-modal__until-time__timer">
          {{ offerTimer | date: 'mm:ss' }}
        </span>
      </span>
      <ng-container *ngIf="item.status !== statuses.PAUSED">
        <button
          class="btn btn-sm btn-play-pause"
          (click)="onPauseItem(item.id)"
        >
          <mat-icon svgIcon="p2p-pause-trade-icon"></mat-icon>
        </button>
      </ng-container>
      <div
        class="sell-setup-item__remove-at"
        (click)="onDeleted(item.id)"
      >
        <mat-icon svgIcon="close"> </mat-icon>
      </div>
    </div>
    <a
      class="selling-lot"
      langRouterLink
      [routerLink]="['/refill/skins']"
    >
      <div class="selling-lot__thumbnail">
        <img
          [src]="item.baseItem.icon"
          [alt]="item.baseItem.skin"
        />
      </div>
      <div class="selling-lot__details">
        <div class="display-skin">
          <ng-container *ngIf="item.baseItem.name; else noNameTmpl">
            {{ item.baseItem.name | replace: '(.*)(\\(.*\\))(.*)' : '$1' }}
          </ng-container>
          <ng-template #noNameTmpl> {{ item.baseItem.shortName }} | {{ item.baseItem.skin }} </ng-template>
        </div>
        <span class="value"> {{ item.recommendedPrice | appCurrency }} </span>
      </div>
    </a>
    <div
      style="padding: 9px"
      class="border-bottom"
    >
      <div class="offer-wait-trade__amount">
        <!-- TODO ADD DICT -->
        <span>Your offer: </span><span class="color-primary">{{ item.sellerPrice | appCurrency }}</span>
      </div>
    </div>
  </div>
  <div
    class="selling-indicator"
    [ngClass]="{ 'indicator-paused': item.status === statuses.PAUSED }"
    *ngIf="![statuses.NEW].includes(item.status)"
  >
    <div
      class="selling-indicator__icon"
      *ngIf="item.status !== statuses.PAUSED"
    >
      <!-- <mat-spinner
        *ngIf="[statuses.NEW, statuses.FIRST_BID, statuses.SECOND_BID, statuses.THIRD_PLUS_BID].includes(item.status)"
        [strokeWidth]="2"
        [diameter]="18"
        [color]="'accent'"
      > -->
      <mat-spinner
        *ngIf="[statuses.FIRST_BID, statuses.SECOND_BID, statuses.THIRD_PLUS_BID, statuses.CRATED].includes(item.status)"
        [strokeWidth]="2"
        [diameter]="18"
        [color]="'accent'"
      >
      </mat-spinner>
      <mat-icon
        svgIcon="p2p-status-success"
        *ngIf="[statuses.WAIT_FOR_BUYER_ACCEPT, statuses.WAIT_FOR_CONFIRM, statuses.WAIT_FOR_TRADE].includes(item.status)"
      ></mat-icon>
      <mat-icon
        svgIcon="success"
        *ngIf="[statuses.COMPLETED].includes(item.status)"
      ></mat-icon>

      <mat-icon
        svgIcon="p2p-status-alert"
        *ngIf="
          [
            statuses.CANCELED_BY_TIMER,
            statuses.CANCELED_BY_SELLER,
            statuses.CANCELED_BY_BUYER,
            statuses.CANCELED_BY_SELLER_BEFORE_TRADE,
            statuses.CANCELED_BY_FASTOFFER
          ].includes(item.status)
        "
      ></mat-icon>
    </div>
    <div
      class="selling-indicator__text"
      *ngIf="item.status !== statuses.PAUSED"
    >
      <ng-container *ngIf="item.status === statuses.CRATED; else depositTextTemplate">
        {{ 'P2P_WIDGETS.PURCHASE.OFFER_PHASES.OFFER_PROCESSED' | translate }}
      </ng-container>
      <ng-template #depositTextTemplate> {{ 'P2P_WIDGETS.DEPOSITING.DEPOSIT' | translate | uppercase }}</ng-template>
    </div>

    <div
      class="selling-indicator__text"
      *ngIf="item.status === statuses.PAUSED"
    >
      {{ 'Paused' | translate }}
    </div>
    <ng-container *ngIf="item.status === statuses.PAUSED">
      <div class="paused_controls">
        <ng-container *ngIf="offerTimer$ | async as offerTimer; else timeoutTemplate">
          <span class="value countdown paused-block"> {{ 'Blocked for: ' }}{{ offerTimer | date: 'mm:ss' }} </span>
        </ng-container>
        <ng-template #timeoutTemplate>
          <button
            class="btn btn-sm btn-play-pause tw-mr-4"
            (click)="onResumeItem(item.id)"
          >
            <mat-icon svgIcon="p2p-start-trade-icon"></mat-icon>
          </button>
        </ng-template>
        <div
          class="paused__remove-at"
          (click)="onDeleted(item.id)"
        >
          <mat-icon svgIcon="close"> </mat-icon>
        </div>
      </div>
    </ng-container>
    <ng-container
      *ngIf="
        [
          statuses.CANCELED_BY_TIMER,
          statuses.CANCELED_BY_SELLER,
          statuses.CANCELED_BY_BUYER,
          statuses.CANCELED_BY_SELLER_BEFORE_TRADE,
          statuses.CANCELED_BY_FASTOFFER
        ].includes(item.status)
      "
    >
      <div
        class="main__remove-at"
        (click)="onDeleted(item.id)"
      >
        <mat-icon svgIcon="close"> </mat-icon>
      </div>
    </ng-container>
    <span
      class="value countdown"
      *ngIf="offerTimer$ | async as offerTimer"
    >
      <!-- <app-ui-countdown
        *ngIf="
          [
            statuses.NEW,
            statuses.FIRST_BID,
            statuses.SECOND_BID,
            statuses.THIRD_PLUS_BID,
            statuses.WAIT_FOR_TRADE,
            statuses.WAIT_FOR_CONFIRM,
            statuses.WAIT_FOR_BUYER_ACCEPT
          ].includes(item.status)
        "
        class="p2p-purchase-modal__until-time__timer"
        [until]="offerTimer"
        [reset]="false"
        [minutes]="0.92"
        [showHours]="true"
      > -->
      <span
        *ngIf="[statuses.FIRST_BID, statuses.SECOND_BID, statuses.THIRD_PLUS_BID, statuses.WAIT_FOR_CONFIRM].includes(item.status)"
        class="p2p-purchase-modal__until-time__timer countdown"
      >
        {{ offerTimer | date: 'mm:ss' }}
      </span>
    </span>
  </div>
  <a
    class="selling-lot"
    langRouterLink
    [routerLink]="['/refill/skins']"
    *ngIf="![statuses.NEW].includes(item.status)"
  >
    <ng-container *ngIf="item.status === statuses.PAUSED; else activeImgTemplate">
      <mat-icon
        class="selling-lot__thumbnail-pause"
        svgIcon="p2p-pause-trade-icon"
      ></mat-icon>
    </ng-container>
    <ng-template #activeImgTemplate>
      <div class="selling-lot__thumbnail">
        <img
          [src]="item.baseItem.icon"
          [alt]="item.baseItem.skin"
        />
      </div>
    </ng-template>

    <div class="selling-lot__details">
      <!-- <div class="display-name">{{ item.baseItem.shortName || item.baseItem.name }}</div>
      <div class="display-skin">{{ item.baseItem.skin }}</div> -->

      <div class="display-skin">{{ item.baseItem.shortName || item.baseItem.name }} | {{ item.baseItem.skin }}</div>
      <span class="value"> {{ item.recommendedPrice | appCurrency }} </span>

      <!-- <ng-container *ngIf="![statuses.WAIT_FOR_TRADE].includes(item.status)">
        <ng-container *ngIf="increase$ | async as increase; else no_modifier">
          <ng-container *ngIf="increase !== 0; else no_modifier">
            <div class="price">
              <span>{{ item.recommendedPrice | appCurrency }}</span>
              <span
                class="positive"
                *ngIf="increase > 0"
              >
                +{{ increase }}%
              </span>
              <span
                class="negative"
                *ngIf="increase < 0"
              >
                {{ increase }}%
              </span>
            </div>
          </ng-container>
        </ng-container>
        <ng-template #no_modifier>
          <div class="price">
            <span>{{ item.recommendedPrice | appCurrency }}</span>
          </div>
        </ng-template>
      </ng-container> -->
    </div>
  </a>
  <div
    style="padding: 9px"
    class="border-bottom"
    *ngIf="
      [
        statuses.PAUSED,
        statuses.COMPLETED,
        statuses.FIRST_BID,
        statuses.SECOND_BID,
        statuses.THIRD_PLUS_BID,
        statuses.WAIT_FOR_TRADE,
        statuses.WAIT_FOR_CONFIRM,
        statuses.WAIT_FOR_BUYER_ACCEPT,
        statuses.CANCELED_BY_BUYER,
        statuses.CANCELED_BY_TIMER,
        statuses.CANCELED_BY_FASTOFFER,
        statuses.CANCELED_BY_SELLER_BEFORE_TRADE
      ].includes(item.status)
    "
  >
    <div class="offer-wait-trade__amount">
      <!-- TODO ADD DICT -->
      <span>{{ item.status === statuses.NEW || item.status === statuses.COMPLETED ? 'Your' : 'Current' }} offer: </span
      ><span class="color-primary">{{ item.sellerPrice | appCurrency }}</span>
    </div>
  </div>
  <div
    class="offer-selling"
    *ngIf="[statuses.FIRST_BID, statuses.SECOND_BID, statuses.THIRD_PLUS_BID].includes(item.status)"
  >
    <!-- <div class="current-offer">
      <div
        class="current-offer__title"
        *ngIf="[statuses.FIRST_BID, statuses.SECOND_BID, statuses.THIRD_PLUS_BID].includes(item.status)"
      >
        {{ 'P2P_WIDGETS.DEPOSITING.CURRENT_OFFER' | translate }}
      </div>

      <div class="current-offer__amount">
        <div class="icon">
          <mat-icon svgIcon="p2p-dash-icon"></mat-icon>
        </div>
        <span class="value"> f bid {{ item.price | appCurrency }} </span>
        <ng-container *ngIf="[statuses.FIRST_BID, statuses.SECOND_BID, statuses.THIRD_PLUS_BID].includes(item.status)">
          <span
            class="value"
            *ngIf="offerTimer$ | async as offerTimer"
          >
            {{ offerTimer | date: 'mm:ss' }}
          </span>
        </ng-container>
      </div>
    </div> -->
    <ng-container *ngIf="item.status !== statuses.PAUSED">
      <div
        class="offer-confirm"
        *ngIf="!confirm; else confirm_layout"
      >
        <div class="offer-confirm__describe">
          {{ 'P2P_WIDGETS.DEPOSITING.YOU_CAN_SELL_NOW' | translate }}
        </div>
        <div class="offer-confirm__confirm-action">
          <button
            class="btn btn-darken btn-sell-confirm"
            (click)="toggleConfirm()"
          >
            {{ 'P2P_WIDGETS.DEPOSITING.SELL_NOW' | translate }}
          </button>
        </div>
      </div>
      <ng-template #confirm_layout>
        <div class="offer-confirm">
          <div class="offer-confirm__describe">
            {{ 'P2P_WIDGETS.DEPOSITING.YOU_CAN_SELL_NOW' | translate }}
          </div>
          <div class="offer-confirm__sell-action">
            <button
              class="btn btn-sm btn-outline-primary"
              (click)="toggleConfirm()"
            >
              {{ 'CANCEL' | translate }}
            </button>
            <button
              class="btn btn-sm btn-primary"
              (click)="onSellNow(item.id)"
            >
              {{ 'ACCEPT' | translate }}
            </button>
          </div>
        </div>
      </ng-template>
    </ng-container>
  </div>
  <div
    class="offer-waiting-confirm"
    *ngIf="[statuses.WAIT_FOR_CONFIRM].includes(item.status)"
  >
    <!-- <div class="current-offer">
      <div class="current-offer__title">aaa{{ 'P2P_WIDGETS.DEPOSITING.CURRENT_OFFER' | translate }}</div>
      <div class="current-offer__amount">
        <span class="value"> ccc {{ item.price | appCurrency }} </span>
      </div>
    </div> -->

    <div class="offer-confirm">
      <div class="offer-confirm__describe">
        {{ 'P2P_WIDGETS.DEPOSITING.ARE_YOU_READY' | translate }}
      </div>
      <div class="offer-confirm__sell-action">
        <button
          class="btn btn-sm btn-outline-primary"
          (click)="onDecline(item.id)"
        >
          {{ 'CANCEL' | translate }}
        </button>
        <button
          class="btn btn-sm btn-primary"
          (click)="onConfirm(item.id)"
        >
          {{ 'ACCEPT' | translate }}
        </button>
      </div>
    </div>
    <div class="progress-line">
      <div
        class="inner-line primary"
        [ngStyle]="{
          'animation-duration': (offerProgress$ | async) + 'ms',
          transform: 'translate3d(-' + (initialProgress$ | async) + '%, 0, 0)'
        }"
      ></div>
    </div>
    <div class="offer-waiting-confirm__until-at-describe">
      <span
        class="value countdown"
        *ngIf="offerTimer$ | async as offerTimer"
      >
        {{ 'P2P_WIDGETS.DEPOSITING.WAITING_CONFIRM_SELLER' | translate }}
        <span
          style="color: #fff"
          class="p2p-purchase-modal__until-time__timer countdown"
        >
          {{ offerTimer | date: 'mm:ss' }}
        </span>
      </span>
    </div>
    <!-- <div class="offer-waiting-confirm__until-at-describe">
      {{ 'P2P_WIDGETS.DEPOSITING.WAITING_CONFIRM_SELLER' | translate }}
    </div>
    <div class="offer-waiting-confirm__until-at-timer">
      <div
        class="timer"
        *ngIf="offerTimer$ | async as offerTimer"
      >
        <mat-icon svgIcon="p2p-timer-icon"></mat-icon>
        {{ offerTimer | date: 'mm:ss' }}
      </div>
    </div> -->
  </div>

  <!-- wait for trade action -->

  <div
    class="offer-wait-trade"
    *ngIf="[statuses.WAIT_FOR_TRADE].includes(item.status)"
  >
    <ng-container *ngIf="offerTimer$ | async as offerTimer">
      <!-- <div class="offer-wait-trade__amount">
      <span>Current offer: </span><span class="color-primary">{{ item.price | appCurrency }}</span>
    </div> -->

      <div class="offer-wait-trade__description">
        {{ 'Are you ready to send a trade?' }}
      </div>
      <div
        class="offer-wait-trade__controller"
        *ngIf="item.order && item.order.buyerTradeLink && item.order.buyerTradeLink.length"
      >
        <button
          class="btn btn-sm btn-primary"
          [disabled]="offerTimer < 1000"
          (click)="onSendTrade()"
        >
          {{ 'P2P_WIDGETS.DEPOSITING.SEND_TRADE' | translate }}
        </button>
      </div>
      <div class="progress-line">
        <div
          class="inner-line primary"
          [ngStyle]="{
            'animation-duration': (offerProgress$ | async) + 'ms',
            transform: 'translate3d(-' + (initialProgress$ | async) + '%, 0, 0)'
          }"
        ></div>
      </div>
      <div class="offer-wait-trade__until-at-describe">
        <span class="value countdown">
          {{ 'P2P_WIDGETS.DEPOSITING.WAIT_FOR_TRADE' | translate }}
          <span
            style="color: #fff"
            class="p2p-purchase-modal__until-time__timer countdown"
          >
            {{ offerTimer | date: 'mm:ss' }}
          </span>
        </span>
      </div>
      <!-- <div class="offer-wait-trade__until-at">
      <div
        class="timer"
        *ngIf="offerTimer$ | async as offerTimer"
      >
        <mat-icon svgIcon="p2p-timer-icon"></mat-icon>
        {{ offerTimer | date: 'mm:ss' }}
      </div>
    </div> -->
    </ng-container>
  </div>
  <div
    class="offer-waiting"
    *ngIf="[statuses.WAIT_FOR_BUYER_ACCEPT].includes(item.status)"
  >
    <!-- <span *ngIf="statuses.NEW === item.status">
      {{ 'P2P_WIDGETS.DEPOSITING.FIND_BUYER' | translate }}
    </span> -->
    <span
      class="offer-waiting__text"
      *ngIf="statuses.WAIT_FOR_BUYER_ACCEPT === item.status"
    >
      {{ 'P2P_WIDGETS.DEPOSITING.WAIT_FOR_BUYER_ACCEPT' | translate }}
      <span
        class="timer countdown"
        *ngIf="offerTimer$ | async as offerTimer"
      >
        {{ offerTimer | date: getDataFormat(offerTimer) }}
      </span>
    </span>
    <!-- <div class="offer-wait-trade__amount">
      <span>Your offer: </span><span class="color-primary">{{ item.price | appCurrency }}</span>
    </div> -->

    <div class="progress-line">
      <div
        class="inner-line primary"
        [ngStyle]="{
          'animation-duration': (offerProgress$ | async) + 'ms',
          transform: 'translate3d(-' + (initialProgress$ | async) + '%, 0, 0)'
        }"
      ></div>
    </div>
  </div>
  <div
    class="offer-canceled border-top"
    *ngIf="
      [
        statuses.CANCELED_BY_SELLER,
        statuses.CANCELED_BY_BUYER,
        statuses.CANCELED_BY_SELLER_BEFORE_TRADE,
        statuses.CANCELED_BY_TIMER,
        statuses.CANCELED_BY_FASTOFFER,
        statuses.AUCTION_FINISHED,
        statuses.COMPLETED
      ].includes(item.status)
    "
  >
    <div [ngSwitch]="item.status">
      <ng-container *ngSwitchCase="statuses.CANCELED_BY_SELLER">{{ 'P2P_WIDGETS.DEPOSITING.CANCELED_BY_SELLER' | translate }}</ng-container>
      <ng-container *ngSwitchCase="statuses.CANCELED_BY_BUYER">{{ 'P2P_WIDGETS.DEPOSITING.CANCELED_BY_BUYER' | translate }}</ng-container>
      <ng-container *ngSwitchCase="statuses.CANCELED_BY_SELLER_BEFORE_TRADE">{{
        'P2P_WIDGETS.DEPOSITING.CANCELED_BY_SELLER_BEFORE_TRADE' | translate
      }}</ng-container>
      <ng-container *ngSwitchCase="statuses.CANCELED_BY_TIMER">{{ 'P2P_WIDGETS.DEPOSITING.CANCELED_BY_TIMER' | translate }}</ng-container>
      <ng-container *ngSwitchCase="statuses.CANCELED_BY_FASTOFFER">{{
        'P2P_WIDGETS.DEPOSITING.CANCELED_BY_FASTOFFER' | translate
      }}</ng-container>
      <ng-container *ngSwitchCase="statuses.AUCTION_FINISHED">{{ 'P2P_WIDGETS.DEPOSITING.AUCTION_FINISHED' | translate }}</ng-container>
      <ng-container *ngSwitchCase="statuses.COMPLETED">{{ 'P2P_WIDGETS.DEPOSITING.COMPLETED' | translate }}</ng-container>
    </div>
    <button
      class="btn btn-sm btn-darken btn-sell-cansel"
      (click)="onDeleted(item.id)"
    >
      {{ 'CLOSE' | translate }}
    </button>
  </div>
</ng-container>
