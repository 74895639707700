import { inject, Injectable } from '@angular/core';
import {
  EmailStatus,
  IUserDetailed,
  IUserP2pPermissionInterface,
  SihParams,
  StateActionStatus,
  SteamErrorsEnum,
  SteamTradeStatus,
  TradeLinks,
  WalletTypeEnum,
} from '@dev-fast/types';
import { Actions, Select, Store } from '@ngxs/store';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { combineLatest, distinctUntilChanged, filter, map, Observable, startWith, switchMap } from 'rxjs';

import { AccountState, SteamGuard, UpdateApiKey, UpdateTradeLink } from '@app/core/state/account';
import { ConfirmEmail, GetP2pPermissions, UpdateEmail, UserState } from '@app/core/state/user-store';
import { actionLoadStatus } from '@app/shared/utils';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  readonly #actions$ = inject(Actions);
  readonly #store = inject(Store);

  @Select(UserState.user)
  readonly user$!: Observable<IUserDetailed | null | undefined>;
  @Select(UserState.p2pPermissions)
  readonly p2pPermissions$!: Observable<IUserP2pPermissionInterface | null>;
  @Select(UserState.isUserBanned)
  readonly isUserBanned$!: Observable<boolean>;

  @Select(AccountState.canLevelUp)
  readonly canLevelUp$!: Observable<boolean>;

  readonly sih$: Observable<SihParams | undefined> = this.p2pPermissions$.pipe(
    filter((value) => !!value),
    map((permissions) => permissions?.connections?.SIH),
  );
  readonly steamTradeStatus$: Observable<SteamTradeStatus> = actionLoadStatus(this.#actions$, GetP2pPermissions).pipe(
    switchMap(() => {
      return combineLatest([this.user$, this.p2pPermissions$]).pipe(
        map(([user, permissions]) => {
          if (!!user?.isLocked || !!user?.permLock) {
            return SteamTradeStatus.CANT_TRADE;
          }
          if (!user?.providers.some((provider) => provider.name.toLowerCase() === 'steam')) {
            return SteamTradeStatus.NEED_STEAM_LOGIN;
          }
          if (
            !permissions ||
            permissions.error ||
            permissions.canTrade?.error ||
            !permissions.canTrade?.success ||
            permissions.canSteamAPI?.error ||
            !permissions.canSteamAPI?.success
          ) {
            return SteamTradeStatus.NEED_PERMISSIONS_ADD;
          }
          return SteamTradeStatus.READY;
        }),
        distinctUntilChanged((prev, curr) => prev === curr),
      );
    }),
  );
  readonly userBalance$: Observable<number> = this.#getWalletById(WalletTypeEnum.BALANCE);
  readonly userEmail$: Observable<{ status: EmailStatus; address: string; isSubscribed: boolean } | undefined> = this.user$.pipe(
    filter((user) => !!user),
    map((user) => user?.email),
    distinctUntilChanged((prev, curr) => prev === curr),
  );
  readonly tradeLink$: Observable<string | undefined> = this.user$.pipe(
    filter((user) => !!user),
    map((user) => user?.links.steam),
    distinctUntilChanged((prev, curr) => prev === curr),
  );
  readonly apiKey$: Observable<string | undefined> = this.user$.pipe(
    filter((user) => !!user),
    map((user) => user?.steamApiKey),
    distinctUntilChanged((prev, curr) => prev === curr),
  );
  readonly steamGuard$: Observable<string | undefined> = this.user$.pipe(
    filter((user) => !!user),
    map((user) => user?.links.steam),
    distinctUntilChanged((prev, curr) => prev === curr),
  );
  readonly tradeLinkValidation$: Observable<StateActionStatus> = this.p2pPermissions$
    .pipe(
      map((permissions) => {
        if (!permissions) {
          return StateActionStatus.DISPATCH;
        }
        if (
          permissions.canTrade?.error === SteamErrorsEnum.STEAM_GUARD_IS_IN_HOLD ||
          permissions.canTrade?.error === SteamErrorsEnum.STEAM_GUARD
        ) {
          return StateActionStatus.DEFAULT;
        }
        if (permissions && (permissions.error || permissions.canTrade?.error || !permissions.canTrade?.success)) {
          return StateActionStatus.ERROR;
        }
        return StateActionStatus.SUCCESS;
      }),
    )
    .pipe(
      startWith(StateActionStatus.DEFAULT),
      distinctUntilChanged((prev, curr) => prev === curr),
    );
  // работает как steamGuardValidation и tradeLinkValidation в одном месте. можно сразуполучить локаль, подставив результат в SteamErrorsEnumLocales
  readonly tradeLinkStatus$: Observable<SteamErrorsEnum> = this.p2pPermissions$
    .pipe(
      map((permissions) => {
        if (!permissions) {
          return SteamErrorsEnum.DISPATCH;
        }

        if (permissions.error || permissions.canTrade?.error) {
          const errorMsg = permissions.error || permissions.canTrade?.error;
          return errorMsg ?? SteamErrorsEnum.ERROR;
        }

        return SteamErrorsEnum.SUCCESS;
      }),
    )
    .pipe(
      startWith(SteamErrorsEnum.DEFAULT),
      distinctUntilChanged((prev, curr) => prev === curr),
    );

  readonly steamGuardValidation$: Observable<StateActionStatus | SteamErrorsEnum> = this.p2pPermissions$
    .pipe(
      map((permissions) => {
        if (!permissions) {
          return StateActionStatus.DISPATCH;
        }
        if (permissions.canTrade?.error) {
          switch (permissions.canTrade.error) {
            case SteamErrorsEnum.STEAM_GUARD_IS_IN_HOLD:
              return SteamErrorsEnum.STEAM_GUARD_IS_IN_HOLD;
            case SteamErrorsEnum.STEAM_GUARD:
              return SteamErrorsEnum.STEAM_GUARD;
            default:
              return StateActionStatus.ERROR;
          }
        }
        return StateActionStatus.SUCCESS;
      }),
    )
    .pipe(
      startWith(StateActionStatus.DEFAULT),
      distinctUntilChanged((prev, curr) => prev === curr),
    );

  readonly apiKeyValidation$: Observable<StateActionStatus> = this.p2pPermissions$
    .pipe(
      map((permissions) => {
        if (!permissions) {
          return StateActionStatus.DISPATCH;
        }
        if (permissions && (permissions.error || permissions.canSteamAPI?.error || !permissions.canSteamAPI?.success)) {
          return StateActionStatus.ERROR;
        }
        return StateActionStatus.SUCCESS;
      }),
    )
    .pipe(
      startWith(StateActionStatus.DEFAULT),
      distinctUntilChanged((prev, curr) => prev === curr),
    );

  readonly tradeLinkLoadStatus$: Observable<StateActionStatus> = actionLoadStatus(this.#actions$, UpdateTradeLink, 500);
  readonly apiKeyLoadStatus$: Observable<StateActionStatus> = actionLoadStatus(this.#actions$, UpdateApiKey, 500);
  readonly confirmEmailLoadStatus$: Observable<StateActionStatus> = actionLoadStatus(this.#actions$, ConfirmEmail, 500);
  readonly steamGuardLoadStatus$: Observable<StateActionStatus> = actionLoadStatus(this.#actions$, SteamGuard, 500);

  #getWalletById(id: WalletTypeEnum): Observable<number> {
    return this.#store.select(UserState.userWallet).pipe(map((filterFn) => filterFn(id)));
  }

  @Dispatch() updateApiKey = (apiKey: string): UpdateApiKey => new UpdateApiKey(apiKey);
  @Dispatch() updateTradeLink = (links: TradeLinks): UpdateTradeLink => new UpdateTradeLink(links);
  @Dispatch() updateEmail = (params?: { address?: string; isSubscribed?: boolean }): UpdateEmail => new UpdateEmail(params);
  @Dispatch() confirmEmail = (code: number): ConfirmEmail => new ConfirmEmail(code);
  @Dispatch() getP2pPermissions = (): GetP2pPermissions => new GetP2pPermissions();
}
