<div class="skin-item-container flex fx-column fx-align-center fx-justify-between w-100">
  <div class="skin-item-header flex fx-justify-end fx-align-start w-100">
    <div class="skin-item-header_exterior p-1 w-100"></div>
  </div>
  <div class="skin-item-image w-100">
    <img class="skin-image w-80" />
  </div>
  <div class="skin-item-info flex fx-column fx-align-start fx-justify-center p-1 w-100">
    <div class="skin-item-info_skin disable-text-wrap w-100 tw-font-medium"><div class="mock loading-gradient"></div></div>
    <div class="skin-item-info_price tw-font-medium">
      ◎
      <div class="mock loading-gradient"></div>
    </div>
  </div>
  <div class="skin-item-check_box flex fx-align-center fx-justify-center">
    <mat-icon
      class="skin-item-check_icon"
      svgIcon="checked2"
    ></mat-icon>
  </div>
</div>
