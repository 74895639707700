import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { NotFoundStubComponent } from './not-found-stub.component';

@NgModule({
  declarations: [NotFoundStubComponent],
  imports: [CommonModule, TranslateModule],
  exports: [NotFoundStubComponent],
})
export class NotFoundStubModule {}
