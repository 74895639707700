<ng-container *ngIf="status !== P2pItemStatus.Default">
  <div
    class="status-icon w-100 h-100 flex fx-align-center fx-justify-center"
    [ngSwitch]="status"
    [class.disabled]="darkenBackground"
  >
    <div
      *ngSwitchCase="P2pItemStatus.Selected"
      class="is-selected icon mb-8 flex fx-align-center fx-justify-center"
    >
      <mat-icon svgIcon="checked-icon"> </mat-icon>
    </div>
    <div
      *ngSwitchCase="P2pItemStatus.Deposit"
      class="is-deposit icon mb-8 flex fx-align-center fx-justify-center"
    >
      <mat-icon svgIcon="market--design"> </mat-icon>
    </div>
    <div
      *ngSwitchCase="P2pItemStatus.Sold"
      class="icon mb-8 flex fx-align-center fx-justify-center"
    >
      <mat-icon
        class="icon-display"
        svgIcon="market--design-sold"
      />
    </div>
    <div
      *ngSwitchCase="P2pItemStatus.Disabled"
      [ngClass]="{
        'has-timer': tradelock$ | async,
        'has-timer days !tw-justify-between': dateFormat === DateFormat.DAYS,
        'has-timer hours !tw-justify-between': dateFormat === DateFormat.DEFAULT,
        'has-timer minutes !tw-justify-between': dateFormat === DateFormat.MINUTES
      }"
      class="is-disable tw-flex tw-items-center tw-justify-center tw-gap-2 tw-font-normal tw-mb-16"
    >
      <mat-icon svgIcon="lock-line--design"> </mat-icon>
      <ng-container *ngIf="tradelock$ | async as tradelockEnd">
        {{ tradelockEnd | date: dateFormat }}
      </ng-container>
    </div>
  </div>
</ng-container>
