import { Injectable } from '@angular/core';
import { ISteamStoreInventory } from '@dev-fast/types';
import { Select } from '@ngxs/store';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { Observable } from 'rxjs';

import { GetSteamInventory, P2pCommonState } from '@app/core/state/p2p';

@Injectable()
export class P2pCommonService {
  @Select(P2pCommonState.steamInventory)
  steamItems$!: Observable<ISteamStoreInventory[]>;

  @Dispatch() getSteamInventory = (): GetSteamInventory => new GetSteamInventory();
}
