import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { GameStatus, IGame } from '@dev-fast/types';

@Component({
  selector: 'app-ui-menu-item',
  templateUrl: './menu-item.component.html',
  styleUrls: ['./menu-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuItemComponent {
  @Input() public game!: IGame;
  @Input() public status!: GameStatus;
  @Input() public isShowFullView: boolean | null;
  @Input() public isNew: boolean;
  @Input() public isActive = false;

  constructor() {
    this.isShowFullView = false;
    this.isNew = false;
  }
}
