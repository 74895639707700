import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { IFilterFormTemplate } from '@dev-fast/types';
import { Subject, takeUntil } from 'rxjs';

import { IDFormControlCheckbox } from '@app/core/dynamic-forms-service';

/**
 * Компонент чекбокса. Работает на основе DynamicFormService
 */
@Component({
  selector: 'app-ui-filter-checkbox',
  templateUrl: './filter-checkbox-item.component.html',
  styleUrls: ['./filter-checkbox-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterCheckboxItemComponent implements OnInit, OnDestroy {
  // Форма в которой находится этот контрол
  @Input() set filterForm(form: FormGroup<IFilterFormTemplate> | undefined) {
    this.form = form;
    this.setInitCheckboxValue();
  }

  // данные о чекбоксе в формате IDFormControlCheckbox
  @Input() set checkboxData(data: any) {
    this.data = data;
    this.setInitCheckboxValue();
  }

  // Корень для локалей
  @Input() translateRoot = '';

  form: FormGroup<IFilterFormTemplate> | undefined;
  data!: IDFormControlCheckbox;
  checkboxControl: FormControl = new FormControl();

  #destroy$: Subject<void> = new Subject();

  /**
   * Изменение состояния чекбокса
   */
  onValueChange(): void {
    this.checkboxControl.patchValue(!this.checkboxControl.value);
  }

  /**
   * Устанавливает значения для чекбокса (если фильтры применены, то при раскрытии панельки нужно это отобразить)
   */
  setInitCheckboxValue(): void {
    if (!this.form || !this.data) {
      return;
    }

    const key = this.data.outputData.checkboxKey;
    const val = this.data.outputData.checkedValue;
    const checked = this.form?.value[key]?.split(',').includes(val);
    this.checkboxControl.patchValue(Boolean(checked), { emitEvent: false });
  }

  // ----- ANGULAR LIFE CYCLE -----
  ngOnInit(): void {
    this.checkboxControl.valueChanges.pipe(takeUntil(this.#destroy$)).subscribe((checked: boolean) => {
      const key = this.data.outputData.checkboxKey;
      const value = this.data.outputData.checkedValue;

      let currentFormValue = this.form?.value[key]?.split(',').filter((val: string) => !!val) || [];
      if (checked && !currentFormValue.includes(value)) {
        currentFormValue.push(value);
      } else if (!checked && currentFormValue.includes(value)) {
        currentFormValue = currentFormValue.filter((val: string) => val !== value);
      }

      this.form?.controls[key].patchValue(currentFormValue.join(','));
    });
  }

  ngOnDestroy(): void {
    this.#destroy$.next();
    this.#destroy$.complete();
  }
}
