<div class="notification-wrapper">
  <div
    [ngClass]="'notification ' + notification.type"
    @notificationSlideLeftAnimation
    (mouseover)="pauseNotifications()"
    (mouseleave)="resumeNotifications()"
    *ngFor="let notification of notifications$ | async; trackBy: trackByIndex"
  >
    <div class="notification-icon">
      <mat-icon [svgIcon]="getIcon(notification.type)"></mat-icon>
    </div>
    <div
      class="notification-content"
      *ngIf="notification.message || notification.category"
    >
      <div
        class="notification-header"
        *ngIf="notification.category"
      >
        {{ notification.category | titlecase }}
      </div>
      <div class="notification-message">
        <ng-container *ngIf="notification.message">
          <ng-container *ngIf="notification.legacy; else notLegacyTemplate">
            {{ notification.message }}
          </ng-container>
          <ng-template #notLegacyTemplate>
            {{ notification.message | appCurrencyTranslate: notification.params }}
          </ng-template>
        </ng-container>
      </div>

      <div
        class="notification-actions"
        *ngIf="notification.actions && notification.actions.length"
      >
        <div
          class="notification-action"
          *ngFor="let action of notification.actions"
          (click)="action?.callback ? action.callback() : null"
        >
          {{ action.name }}
        </div>
      </div>
    </div>
    <div
      class="notification-close"
      (click)="deleteNotification(notification)"
    >
      <button class="close-btn">
        <mat-icon svgIcon="close-soft"></mat-icon>
      </button>
    </div>
    <div [class]="'loading-row ' + notification.type"></div>
  </div>
</div>
