import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-ui-balance-panel',
  templateUrl: './balance-panel.component.html',
  styleUrls: ['./balance-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BalancePanelComponent {
  @Input() public coinsAmount = 0;
  @Input() public view: 'mobile' | 'desktop' = 'desktop';

  // constructor() {  }
}
