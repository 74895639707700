import { ICasesQuestStatus, TicketTypeEnum } from '../../../enums';
import { IDropItemDto, IMotivatorDto } from '../../motivator.interface';
import { IUserShort } from '../../user';
import { ICaseItemDtoV2 } from './cases.interface';

export interface ICaseQuestResponse {
  categories: ICasesQuestItem[];
  /** Финальная награда если доступна. entityId всегда -1 */
  reward?: ICasesQuestItem;
  // полный конец эвента
  endAt: string;
  // конец эвента нельзя играть но можно смотреть таблицу лидеров и тд
  progressEndAt: string;
  // Пока такое не приходит.
  // Нужно, чтобы разделить эвент на периоды. Когда играть, а когда остается только тратить монеты.
  // По задумке больше endAt на пару дней и после истечения этого срока эвент полностью закрывается
}
// TODO еще тут будет uid для открытия реплея, кейс айтем, вторая картинка и позиция для этой картинки
/** Модель квеста для кейсов */
export interface ICasesQuestItem {
  /** модель возможно не точно такая, приходит если приз забран или доступен для забора. Если кейс открыт то не придет*/
  case?: ICaseItemDtoV2;
  /** id категории кейса */
  entityId: number;
  /** сколько всего очков в категории*/
  count: number;
  /** сколько набрал очков в категории */
  progress: number;
  questId: number;
  /** название кейса где содержится приз */
  name: string;
  /** картинка основная (для сетки) */
  image: null | string;
  /** картинка (доп)*/
  externalImage?: null | string;
  updatedAt?: string | null;
  /** статус награды в категории */
  status: ICasesQuestStatus;
  /** инфа об открытом подарке openUUID где-то тут должен быть.*/
  caseRevisionResult?: IDropItemDto;
}

/** Поле квеста в категории */
export interface ICategoryQuestField {
  /** id квеста */
  id: number;
  params: {
    type: 'category';
    /** общее кол-во балов необходимое для прохождения */
    count: number;
  };
  /** баллов набрано в категории, если нет значит еще не было открытий кейсов в категории */
  progress?: number;
}
export interface IQuestParams {
  action: 'open-category';
  type: 'category';
  freeCaseSetId: number;
  chance: number;
  count: number;
}
export interface ITicketQuestPrice {
  freeCaseId?: number;
  ticketColor: TicketTypeEnum;
  ticketsAmount: number;
}
/** Поле квеста в кейсе */
export interface ICaseQuestField {
  /** id квеста */
  id: number;
  /** пока хз */
  params: IQuestParams;
  /** баллов набрано в категории, если null значит еще не было открытий кейсов в категории */
  progress: null | number;
  /** true только если набрано достаточное кол-во очков и приз еще не был забран. в остальных случаях false */
  rewardAvailable: boolean;
}

/** Бонусный приз приходит так в сокеты (можно расширить при необходимости)*/
export interface ICaseLuckyWonEvent {
  /** id категории кейса */
  caseId: number;
  entityId: number;
  questId: number;
}
export interface IBonusCasesInfo {
  id: number;
  count: number;
}
/** Из сокета */
export interface ICaseQuestProgress {
  /** для внутреннего пользования бэка, на фронте не нужно.*/
  id: number;
  quest: {
    /** id квеста */
    id: number;
    /** пока хз */
    params: IQuestParams;
    // TODO был баг на бэке надо проверить меняется или нет
    /** true только если набрано достаточное кол-во очков и приз еще не был забран. в остальных случаях false */
    rewardAvailable: boolean;
  };
  /** id категории кейса */
  entityId: number;
  /** баллов всего набрано в категории */
  progress: number;
  /** баллов добавилось за открытие в категории */
  count: number;
}
export interface ICaseQuestMotivatorLastDto {
  passed: number;
  image: string;
  date: Date;
  total: number;
  user: IUserShort;
}
export interface ICaseQuestMotivatorList {
  last: IMotivatorDto<ICaseQuestMotivatorLastDto>[];
}
export interface ICaseQuestTotalProgress {
  total: number;
  found: number;
  percent: number;
}
