import { CaseBattleGameStatus, CaseBattleLobbyLists } from '../../../enums';
import { IEmojiState, IEmojiTimeStampDTO } from '../../emojis.interface';
import { IBaseItemDto, ICaseItemDtoV2 } from '../cases/cases.interface';

export interface ICaseBattleDto {
  success: boolean;
  games: ICaseBattleGame[];
}
export interface ICaseBattleSkinItem {
  color: string;
  gameId: number;
  icon: string;
  id: number;
  name: string;
  price: number;
}
export interface ICaseBattleCreateNew {
  isPrivate: boolean;
  playersCount: number;
  caseIds: number[];
  winnersCount: number;
  settings: {
    winnersSplit: boolean;
    double: boolean;
    jokerMode: boolean;
  };
}
export interface ICaseBattlePlayer {
  user_id: number;
  ava_link: string;
  joined_at: string;
  name: string;
  team: number;
  type: string;
}
export interface ICaseBattleTeam {
  players: (ICaseBattlePlayer | null)[];
  teamWinPrice: number;
}
export interface ICaseBattleGameDto {
  id: number;
  cases: number[];
  cases_count: number;
  created_at: string | null;
  creator_id: number;
  finished_at: string | null;
  is_private: boolean;
  is_return?: boolean;
  maxPrizeSum?: number;
  players_count: number;
  players: ICaseBattlePlayer[];
  players_in_team: number;
  prize_given: boolean;
  prizes: ICaseBattlePrize | null;
  rounds?: ICaseBattleRoundDto[];
  settings?: {
    winnersSplit: boolean;
    jokerMode: boolean;
  };
  started_at: string | null;
  total_price: number;
  total_winning: number;
  winners_count: number;
  winner_ids: number[] | null;
  emoji?: Record<number, IEmojiTimeStampDTO>;
}
export interface ICaseBattleGame extends ICaseBattleGameDto {
  casesItems: ICaseItemDtoV2[];
  gameStatus?: CaseBattleGameStatus; //для стилизации в списке игр в зависимости от статуса
  isReplay?: boolean; //нужно для блокировки эмоций
  lastRoundNumber: number;
  leaderTeamSum: number; // максимальная сумма выигрыша среди всех команд
  loserTeamSum: number; // минимальная сумма выигрыша среди всех команд
  maxPrizeSum?: number;
  potentialGain: number;
  roundsWinPrice: Record<number, number>; // максимальный выигрыш в раунде <№ раунда, сумма выигрыша>
  roundsMaxPrice: number; // максимальный выигрыш в игре
  roundsMinPrice: number; // максимальный выигрыш в игре
  round_winner_ids: number[];
  teams: Record<number, ICaseBattleTeam>;
  total_loss: number;
  total_spent?: number;
  timeLineEmojis: Record<number, number>;
}
export interface ICaseBattleGameInfo {
  id: number;
  created_at: string | null;
  is_private: boolean;
  players_count: number;
  cases_count: number;
  cases: number[];
  finished_at: string | null;
  started_at: string | null;
  creator_id: number;
  winner_id: number | number[] | null;
  total_price: number;
  total_winning: number;
  prize_given: boolean;
  total_spent: number;
}
export interface ICaseBattleOneGame {
  success: boolean;
  game: ICaseBattleGame;
}
export interface ICaseBattleStartDto {
  battleId: number;
  info: {
    battle: ICaseBattleGameInfo;
    players: ICaseBattlePlayer[];
    cases: number[];
  };
}
export interface ICaseBattleGameCreateDto {
  battleId: number;
  cases: ICaseItemDtoV2[];
  casesCount: number;
  playersCount: number;
  user_id: number;
}
export interface ICaseBattleUserJoinDto {
  battleId: number;
  player: ICaseBattlePlayer;
  user_id: number;
}
export interface ICaseBattleRoundDto {
  battleId: number;
  caseId: number;
  id: number;
  played_at?: string;
  result: ICaseBattleRoundResult;
  result_amount?: number;
  roundNumber: number;
  strip: number[];
  user_id: number;
}
export interface ICaseBattleRoundResult {
  baseItem: IBaseItemDto;
  gameId?: number;
  id: number;
  price: number;
}
export interface ICaseBattlePlayerCard {
  player: ICaseBattlePlayer;
  firstInit: boolean;
  itemsLine: ICaseBattleRoundResult[];
  rounds: ICaseBattleRoundDto[];
  rounds_amount: number;
  active_round?: number;
  prize_skins?: { ids: number[]; sum: number };
  prize_sum: number;
}

export interface ICaseBattleGameFinishDto {
  battle?: ICaseBattleGame;
  battleId?: number;
  is_return?: boolean;
  players?: ICaseBattlePlayer[];
  prizes: ICaseBattlePrize | null;
  // prize_given?: boolean;
  round_winner_ids?: number[];
  totalWinning?: number;
  winnerIds: number[] | null;
}
export interface ICaseBattleEmojiDto {
  battleId: number;
  state: IEmojiState;
}
export interface ICaseBattleGameJoinNotify {
  success: boolean;
  battleId: number;
  message?: string;
}
export interface ICaseBattleGameCreateNotify {
  success: boolean;
  battleId: number;
  message?: string;
}
export interface ICaseBattlePrize {
  money: Record<number, number>;
  skins: Record<number, { ids: number[]; sum: number }>;
}
export interface ICaseBattleWinnersModalData {
  player: ICaseBattlePlayer;
  prizeSum: number;
  skins?: { ids: number[]; sum: number } | undefined;
  money?: number;
}
export interface ICaseBattleHistory {
  success: boolean;
  games: ICaseBattleGame[];
}
export interface ICaseBattleReplayBuffer {
  rounds?: ICaseBattleRoundDto[];
  winner_ids: number[] | null;
  prizes: ICaseBattlePrize | null;
  is_return?: boolean;
  prize_given: boolean;
  // round_winner_ids: number[];
  totalWinning: number;
}
export interface ICaseBattleListsButton {
  name: CaseBattleLobbyLists;
  title: string;
}
