import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { GamesHoverDirective } from './games-hover.directive';

@NgModule({
  declarations: [GamesHoverDirective],
  imports: [CommonModule],
  exports: [GamesHoverDirective],
})
export class GamesHoverModule {}
