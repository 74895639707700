<div class="user-profile">
  <ng-container *ngIf="user; else mockUserProfile">
    <div class="user-profile__left-block">
      <div
        class="user-country"
        *ngIf="country; else mockCountry"
      >
        <img
          class="user-country-img"
          [src]="country"
          alt="country"
        />
      </div>
      <ng-template #mockCountry>
        <div class="user-country"></div>
      </ng-template>

      <app-ui-user-avatar
        [noChevron]="true"
        [url]="user.avatar"
        [type]="user.profileType"
      ></app-ui-user-avatar>
      <div
        class="user-level"
        *ngIf="showLevel"
      >
        {{ user.experience.level + ' LvL' }}
      </div>
      <button
        class="btn btn__edit"
        *ngIf="allowAction"
        (click)="actionEvent.emit()"
        [matTooltip]="'PROFILE.RENEW_PROFILE.SETTINGS.EDIT_PROFILE' | translate"
        [matTooltipPosition]="'above'"
      >
        <mat-icon
          class="icon"
          [svgIcon]="'edit-new'"
        ></mat-icon>
      </button>
    </div>

    <div class="user-profile__right-block">
      <div class="user-name">{{ user.name }}</div>
      <div class="info info-wrapper">
        <div
          class="info-text tw-cursor-copy user-id"
          (click)="onCopyUserId(user.id)"
        >
          <ng-container *isProject="projects.MARKET; else fastId">{{ 'PROFILE.ID' | translate }}</ng-container>
          <ng-template #fastId>{{ 'PROFILE.FAST_ID' | translate }}</ng-template>
          {{ user.id }}
        </div>
        <div class="info-text">
          <ng-container *isProject="projects.MARKET; else firstGameText">
            {{ 'MARKET.FIRST_SEEN' | translate }}
          </ng-container>
          <ng-template #firstGameText>
            {{ ('USER_PANEL.FIRST_GAME' | translate) + ':' }}
          </ng-template>
          {{ user.activity?.registered | date: 'dd.MM.yyyy (HH:mm)' }}
        </div>
      </div>
      <div
        class="profile-link"
        *ngIf="user.providers?.length && showSocials"
      >
        <ng-container *ngFor="let provider of user.providers">
          <a
            class="link"
            [target]="'blank'"
            *ngIf="provider.name === socialTypes.Vkontakte"
            href="{{ 'https://vk.com/id' + provider.id }}"
          >
            {{ 'PROFILE.OTHER.VKONTAKTE' | translate }}
          </a>
          <a
            class="link"
            [target]="'blank'"
            *ngIf="provider.name === socialTypes.Steam"
            href="{{ 'https://steamcommunity.com/profiles/' + provider.id }}"
          >
            {{ 'PROFILE.OTHER.STEAM' | translate }}
          </a>
        </ng-container>
      </div>
    </div>
    <ng-container *ngIf="!simpleView">
      <ng-container *isProject="projects.MARKET; else fastTemplate">
        <ng-container *ngTemplateOutlet="userTradeStatTemplate"></ng-container>
      </ng-container>
      <ng-template #fastTemplate>
        <ng-container *ngIf="allowAction; else staticCharacter">
          <div
            class="user-profile__img-container user-profile__img-container_edit"
            [ngClass]="{ loading: isCharacterChanging }"
          >
            <div
              class="user-profile__img user-profile__img_t"
              [ngClass]="{ 'user-profile__img_active': user.character === userCharacters.Terrorist }"
            >
              <img
                src="https://d2lomvz2jrw9ac.cloudfront.net/common/profile-t.gif"
                alt="terrorist character"
                draggable="false"
              />
            </div>
            <div
              class="user-profile__img user-profile__img_ct"
              [ngClass]="{ 'user-profile__img_active': user.character === userCharacters.CounterTerrorist }"
            >
              <img
                src="https://d2lomvz2jrw9ac.cloudfront.net/common/profile-ct.gif"
                alt="counter-terrorist character"
                draggable="false"
              />
            </div>
            <ng-container *ngIf="isCharacterChanging; else userChangeButton">
              <mat-spinner
                [strokeWidth]="2"
                [diameter]="24"
                [color]="'primary'"
              />
            </ng-container>
            <ng-template #userChangeButton>
              <button
                class="user-profile__change-character-btn"
                (click)="this.changeProfileCharacter()"
              >
                {{ 'change character' | translate }}
              </button>
            </ng-template>
          </div>
        </ng-container>
      </ng-template>
    </ng-container>
    <ng-template #staticCharacter>
      <div class="user-profile__img-container">
        <div class="user-profile__img user-profile__img_{{ user.character }}">
          <img
            src="https://d2lomvz2jrw9ac.cloudfront.net/common/profile-{{ user.character }}.gif"
            alt="character"
            draggable="false"
          />
        </div>
      </div>
    </ng-template>
    <ng-template #userTradeStatTemplate>
      <div
        *ngIf="tradeStats"
        class="user-profile__trade-stats flex fx-justify-around w-50"
      >
        <div class="flex fx-column fx-all-center gap-2">
          <div class="value value-positive fw-500">{{ getUserRating() }}</div>
          <!-- TODO Add locale -->
          <div class="tooltip fw-400">{{ 'trade rating' | translate }}</div>
        </div>
        <div class="flex fx-column fx-all-center gap-2">
          <div class="value flex gap-1 fw-500">
            <span class="value value-positive">{{ tradeStats.success }}</span> /
            <span class="value value-negative">{{ tradeStats.fail }}</span>
          </div>
          <!-- TODO Add locale -->
          <div class="tooltip fw-400">{{ 'successful / interrupted transactions' | translate }}</div>
        </div>
      </div>
    </ng-template>
  </ng-container>
  <ng-template #mockUserProfile>
    <div
      class="user-profile__left-block"
      [ngClass]="{ 'user-profile__left-block_show-level': showLevel }"
    >
      <div class="user-country loading-gradient"></div>
      <div class="user-avatar mock">
        <div class="loading-gradient"></div>
      </div>
      <div
        class="user-level"
        *ngIf="showLevel"
      >
        <div class="mock loading-gradient"></div>
      </div>
    </div>

    <div class="user-profile__right-block">
      <div class="user-name"><div class="mock loading-gradient"></div></div>
      <div class="info info-wrapper">
        <div class="info-text">
          Fast ID:
          <div class="mock loading-gradient"></div>
        </div>
        <div class="info-text">
          {{ 'USER_PANEL.FIRST_GAME' | translate }}:
          <div class="mock loading-gradient"></div>
        </div>
      </div>
      <div
        class="profile-link"
        *ngIf="showSocials"
      >
        <div class="link mock loading-gradient"></div>
      </div>
    </div>

    <div class="user-profile__img-container"></div>
  </ng-template>
</div>
