export interface IColorPalette {
  plain: string;
  hover: string;
  default: string;
  active: string;
}

export interface ISkinRarity {
  consumer: IColorPalette;
  industrial: IColorPalette;
  mil_spec: IColorPalette;
  restricted: IColorPalette;
  classified: IColorPalette;
  covert: IColorPalette;
  extraordinary: IColorPalette;
  contraband: IColorPalette;
}

export const SkinRarity: ISkinRarity = {
  consumer: {
    plain: '',
    active: '#8A99AA',
    default: '#3F4354',
    hover: '#535C66',
  },
  industrial: {
    plain: '',
    active: '#4384C7',
    default: '#354A6A',
    hover: '#3E75AF 100%',
  },
  mil_spec: {
    plain: '',
    active: '#3E59E1',
    default: '#343D72',
    hover: '#3951C5',
  },
  restricted: {
    plain: '',
    active: '#7943EC',
    default: '#453775',
    hover: '#6B3ECF',
  },
  classified: {
    plain: '',
    active: '#C23DDE',
    default: '#622F6C',
    hover: '#C23DDE',
  },
  covert: {
    plain: '',
    active: '#A32B29',
    default: '#5F3641',
    hover: '#B43C3B',
  },
  extraordinary: {
    plain: '',
    active: '#998133',
    default: '#635936',
    hover: '#998133',
  },
  contraband: {
    plain: '',
    active: '#D38A2F',
    default: '#604C3D',
    hover: '#B77A2F',
  },
};

export const SkinRarityP2P: ISkinRarity = {
  consumer: {
    plain: '#545c66',
    default: 'linear-gradient(180deg, #2f3142 0%, #3f4354 100%)',
    hover: 'linear-gradient(180deg, #373c48 0%, #8a99aa 100%)',
    active: 'linear-gradient(180deg, #1e202a 0%, #373c48 100%)',
  },
  industrial: {
    plain: '#4384c7',
    default: 'linear-gradient(180deg, #303242 0%, #354a6a 100%)',
    hover: 'linear-gradient(180deg, #40516c 0%, #3e75af 100%)',
    active: 'linear-gradient(180deg, #1e202a 0%, #253449 100%)',
  },
  mil_spec: {
    plain: '#3e59e1',
    default: 'linear-gradient(180deg, #303242 0%, #343d72 100%)',
    hover: 'linear-gradient(180deg, #3f4870 0%, #3951c5 100%)',
    active: 'linear-gradient(180deg, #1e202a 0%, #242b4e 100%)',
  },
  restricted: {
    plain: '#7943ec',
    default: 'linear-gradient(180deg, #303243 0%, #453775 100%)',
    hover: 'linear-gradient(180deg, #4b4373 0%, #6b3ecf 100%)',
    active: 'linear-gradient(180deg, #1e202a 0%, #302750 100%)',
  },
  classified: {
    plain: '#c23dde',
    default: 'linear-gradient(180deg, #303143 0%, #622f6c 100%)',
    hover: 'linear-gradient(180deg, #5b3e6c 0%, #c23dde 100%)',
    active: 'linear-gradient(180deg, #281e2a 0%, #40224a 100%)',
  },
  covert: {
    plain: '#a32b29',
    default: 'linear-gradient(180deg, #303243 0%, #5f3641 100%)',
    hover: 'linear-gradient(180deg, #5c4350 0%, #b43c3b 100%)',
    active: 'linear-gradient(180deg, #1e202a 0%, #41262e 100%)',
  },
  extraordinary: {
    plain: '#998133',
    default: 'linear-gradient(180deg, #303243 0%, #635936 100%)',
    hover: 'linear-gradient(180deg, #595340 0%, #998133 100%)',
    active: 'linear-gradient(180deg, #2a271e 0%, #403a26 100%)',
  },
  contraband: {
    plain: '#a86d24',
    default: 'linear-gradient(180deg, #303242 0%, #604c3d 100%)',
    hover: 'linear-gradient(180deg, #5d524d 0%, #b77a2f 100%)',
    active: 'linear-gradient(180deg, #1e2029 0%, #42352b 100%)',
  },
};

export const SKIN_RARITY: Record<string, IColorPalette> = {
  default: SkinRarity.consumer,
  'consumer grade': SkinRarity.consumer,
  'base grade': SkinRarity.consumer,
  '#b0c3d9': SkinRarity.consumer,
  'industrial grade': SkinRarity.industrial,
  'mil-spec grade': SkinRarity.mil_spec,
  'high grade': SkinRarity.mil_spec,
  '#4b69ff': SkinRarity.mil_spec,
  restricted: SkinRarity.restricted,
  remarkable: SkinRarity.restricted,
  '#8847ff': SkinRarity.restricted,
  classified: SkinRarity.classified,
  exotic: SkinRarity.classified,
  '#d32ce6': SkinRarity.classified,
  d32ce6: SkinRarity.classified,
  covert: SkinRarity.covert,
  extraordinary: SkinRarity.extraordinary,
  '#eb4b4b': SkinRarity.extraordinary,
  '#fa8072': SkinRarity.extraordinary,
  contraband: SkinRarity.contraband,
  '#e4ae39': SkinRarity.contraband,
};

export const SKIN_RARITY_V2: Record<string, IColorPalette> = {
  default: SkinRarityP2P.consumer,
  'consumer grade': SkinRarityP2P.consumer,
  'base grade': SkinRarityP2P.consumer,
  '#b0c3d9': SkinRarityP2P.consumer,
  'industrial grade': SkinRarityP2P.industrial,
  'mil-spec grade': SkinRarityP2P.mil_spec,
  'high grade': SkinRarityP2P.mil_spec,
  '#4b69ff': SkinRarityP2P.mil_spec,
  '#4662e8': SkinRarityP2P.mil_spec,
  restricted: SkinRarityP2P.restricted,
  remarkable: SkinRarityP2P.restricted,
  '#8847ff': SkinRarityP2P.restricted,
  classified: SkinRarityP2P.classified,
  exotic: SkinRarityP2P.classified,
  '#d32ce6': SkinRarityP2P.classified,
  d32ce6: SkinRarityP2P.classified,
  '#d22ce5': SkinRarityP2P.classified,
  covert: SkinRarityP2P.covert,
  extraordinary: SkinRarityP2P.extraordinary,
  '#eb4b4b': SkinRarityP2P.extraordinary,
  '#fa8072': SkinRarityP2P.extraordinary,
  contraband: SkinRarityP2P.contraband,
  '#e4ae39': SkinRarityP2P.contraband,
};

export const getSkinColorsPalette = (key: string, targetPalette: Record<string, IColorPalette>): IColorPalette => {
  return targetPalette[key] ?? targetPalette['default'];
};
