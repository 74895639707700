import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

import { AppCurrencyModule, AppPercentModule } from '@app/shared/pipes';

@Component({
  selector: 'app-ui-circle-progress',
  templateUrl: './circle-progress.component.html',
  styleUrls: ['./circle-progress.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CircleProgressComponent {
  @Input() public prefix = true;
  @Input() public maxValue = 5;
  @Input() public set changeCurrValue(value: number) {
    this.diffrence = this.maxValue - value;
    this.currValue = value;
    this.prefixValue = this.prefix ? (this.diffrence > 0 ? '+' : this.diffrence < 0 ? '-' : '') : '';
    this.progressValue = this.computeProgressValue(value);
  }
  @Input() public description: number | string | undefined;
  @Input() public icon: string | undefined;

  public prefixValue = '';
  public currValue = 0;
  public diffrence = 0;
  public progressValue = 0;

  private computeProgressValue(val: number): number {
    const step = (val / this.maxValue) * 75;
    return step;
  }
}
@NgModule({
  declarations: [CircleProgressComponent],
  imports: [CommonModule, AppCurrencyModule, AppPercentModule, MatIconModule],
  exports: [CircleProgressComponent],
})
export class CircleProgressModule {}
