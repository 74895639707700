import { AsyncPipe, NgIf } from '@angular/common';
import { Component, inject, Signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { map } from 'rxjs/operators';

import { SIH_LINK } from '../../model';
import { SihService } from '../../sih.service';
import { SihCustomIconComponent } from '../sih-custom-icon/sih-custom-icon.component';
import { SteamDoubleIconComponent } from '../steam-double-icon/steam-double-icon.component';

@Component({
  selector: 'app-sih-check-banner',
  standalone: true,
  imports: [TranslateModule, SteamDoubleIconComponent, AsyncPipe, MatIconModule, NgIf, SihCustomIconComponent],
  providers: [SihService],
  templateUrl: './sih-check-banner.component.html',
  styleUrls: ['../../shared-style.scss', './sih-check-banner.component.scss'],
})
export class SihCheckBannerComponent {
  readonly #sihService = inject(SihService);
  readonly sihLink = SIH_LINK;
  readonly loading: Signal<boolean | undefined> = toSignal(this.#sihService.checkUserLoading$);
  readonly isFirstStep: Signal<boolean | undefined> = toSignal(this.#sihService.sih$.pipe(map((sih) => (sih ? !sih.online : true))));
  onCheckSihInstall(): void {
    this.#sihService.checkUser();
  }
  onRequestPermissions(): void {
    this.#sihService.checkUser();
  }
}
