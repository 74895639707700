/**
 - taken награда забрана
 - available доступна награда
 - blocked в процессе типа
 - opened когда кейс открыл из приза
 */
export enum ICasesQuestStatus {
  TAKEN = 'taken',
  OPENED = 'opened',
  AVAILABLE = 'available',
  BLOCKED = 'blocked',
}
export enum SpinDirection {
  HORIZONTAL = 'horizontal',
  VERTICAL = 'vertical',
  MOBILE = 'mobile',
}
export enum ICaseType {
  PAID = 'paid',
  FREE = 'free',
  PAID_FREE = 'paid-free',
}
export enum IItemStatus {
  TAKEN = 'taken',
  SOLD = 'sold',
}
export enum CasesGameType {
  MONEY = 'for-money',
  AUTOPLAY = 'autoplay',
  DEMO = 'demo',
  REPLAY = 'replay',
  BONUS = 'bonus',
  TICKETS = 'tickets',
}
export enum SelectDirection {
  PREV = 'PREV',
  NEXT = 'NEXT',
}
export enum AnimationSpeed {
  QUICK = 'quick',
  SLOW = 'slow',
}

export enum CasesSocketName {
  UPDATE = 'updateRevision',
}
