import {
  battleEmojis,
  CaseBattleLobbyLists,
  CaseBattlePhases,
  ICaseBattleFilter,
  ICaseBattleGame,
  ICaseBattlePlayerCard,
  ICaseBattleReplayBuffer,
  ICaseBattleRoundDto,
  ICaseItemDtoV2,
  IEmojiInfo,
  SortingCaseBattleTypes,
  SoundNameFast,
} from '@dev-fast/types';

import { CommonFiltersStateModel } from '../../state';

export interface CaseBattleStateModel extends CommonFiltersStateModel<ICaseBattleFilter, SortingCaseBattleTypes> {
  activeLobby: ICaseBattleGame | null;
  activeLobbyRequestState: boolean | null;
  availableCases: ICaseItemDtoV2[];
  availableGames: ICaseBattleGame[];
  availableGamesHistory: ICaseBattleGame[];
  battlePhase: CaseBattlePhases;
  bufferedReplayRounds: ICaseBattleRoundDto[];
  caseBattleHistory: ICaseBattleGame[];
  currentRounds: ICaseBattleRoundDto[];
  gameListOffset: number;
  historyListOffset: number;
  myGames: ICaseBattleGame[];
  playerCards: Record<number, ICaseBattlePlayerCard>;
  replayBuffer: ICaseBattleReplayBuffer | null;
  selectedCases: ICaseItemDtoV2[];
  activeList: CaseBattleLobbyLists;
  isActive: boolean;
  emojis: IEmojiInfo[];
  activeSound: SoundNameFast | undefined;
}
export const CASE_BATTLE_INITIAL_STATE: CaseBattleStateModel = {
  filters: {
    minPrice: undefined,
    sortBy: SortingCaseBattleTypes.FIRST_NEW,
  },
  sortingMethods: [
    { value: SortingCaseBattleTypes.FIRST_NEW, viewValue: 'FIRST_NEW' },
    { value: SortingCaseBattleTypes.FIRST_OLD, viewValue: 'FIRST_OLD' },
    { value: SortingCaseBattleTypes.FIRST_CHEAP, viewValue: 'FIRST_CHEAP' },
    { value: SortingCaseBattleTypes.FIRST_EXPENSIVE, viewValue: 'FIRST_EXPENSIVE' },
  ],
  priceFilters: [],
  priceRange: null,
  activeLobbyRequestState: null,
  activeLobby: null,
  availableCases: [],
  availableGames: [],
  availableGamesHistory: [],
  battlePhase: CaseBattlePhases.INITIALIZE,
  bufferedReplayRounds: [],
  caseBattleHistory: [],
  currentRounds: [],
  gameListOffset: 10,
  historyListOffset: 10,
  myGames: [],
  playerCards: {},
  replayBuffer: null,
  selectedCases: [],
  activeList: CaseBattleLobbyLists.GAMES,
  isActive: false,
  emojis: battleEmojis,
  activeSound: SoundNameFast.STANDART,
};
