import { HttpErrorResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { P2pApiNewService } from '@dev-fast/backend-services';
import { ISteamStore, ISteamStoreInventory, MarketSortingTypes, SteamItemsStatus } from '@dev-fast/types';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { iif, Observable, of, throwError, timer } from 'rxjs';
import { catchError, delayWhen, pluck, retryWhen, startWith, switchMap, tap } from 'rxjs/operators';

import { NotificationsService } from '@app/core/notification-service';

import { ApplySteamInventoryFilters, MarketItemsLoad, MarketItemsLoaded, P2pMarketState, SortSteamInventoryByMethod } from '../market';
import { GetSteamInventory } from './p2p.actions';
import { P2P_INITIAL_STATE, P2pStateModel } from './p2p-state.model';

@State<P2pStateModel>({
  name: 'p2p_common',
  defaults: P2P_INITIAL_STATE,
})
@Injectable()
export class P2pCommonState {
  readonly #store = inject(Store);
  readonly #p2pApiService = inject(P2pApiNewService);
  readonly #notificationsService = inject(NotificationsService);

  @Selector()
  static steamInventory({ items }: P2pStateModel): ISteamStoreInventory[] | null | undefined {
    return items;
  }
  @Selector()
  static steamInventoryCachedAt({ steamInventoryCachedAt }: P2pStateModel): string | null {
    return steamInventoryCachedAt;
  }
  @Selector()
  static steamInventoryStatus({ items }: P2pStateModel): SteamItemsStatus {
    if (items === undefined) {
      return SteamItemsStatus.INITIAL;
    }
    if (items === null) {
      return SteamItemsStatus.ERROR;
    }
    if (items && items.length === 0) {
      return SteamItemsStatus.EMPTY;
    }
    return SteamItemsStatus.LOADED;
  }
  @Selector()
  static sellerBanEndAt({ sellerBanEndAt }: P2pStateModel): number | null {
    return sellerBanEndAt && sellerBanEndAt > 0 ? sellerBanEndAt : null;
  }

  @Action(GetSteamInventory, { cancelUncompleted: true })
  getSteamInventory({ patchState, dispatch }: StateContext<P2pStateModel>, { params }: GetSteamInventory): Observable<ISteamStore> {
    dispatch(new MarketItemsLoad());
    return this.#p2pApiService.getInventory(params).pipe(
      catchError((e) => {
        this.#onError(e);
        dispatch(new MarketItemsLoaded());
        return of({ items: null, error: true, sellerBanEndAt: null });
      }),
      switchMap((res) => iif(() => !!res?.sellerBanEndAt, throwError(() => res).pipe(startWith(res)), of(res))),
      retryWhen((err) =>
        err.pipe(
          pluck('sellerBanEndAt'),
          delayWhen((val: string) => timer(Date.parse(val) - Date.now() + 5000)),
        ),
      ),
      tap((steamStoreItems: ISteamStore) => {
        if (steamStoreItems) {
          const { items, sellerBanEndAt, cachedAt } = steamStoreItems;
          const diff = (sellerBanEndAt ? Date.parse(sellerBanEndAt) : Date.now()) - Date.now();
          patchState({ items: this.#sortSteamItems(items, params?.sortBy), sellerBanEndAt: diff, steamInventoryCachedAt: cachedAt });
        } else {
          patchState({ items: [], sellerBanEndAt: null });
        }
        dispatch(new MarketItemsLoaded());
      }),
    );
  }
  @Action(ApplySteamInventoryFilters)
  confirmSteanInventorySearch({ patchState }: StateContext<P2pStateModel>): void {
    const tmpItems = this.#store.selectSnapshot(P2pMarketState.tmpItems);
    const { sortBy } = this.#store.selectSnapshot(P2pMarketState.tmpFilters);
    patchState({
      items: this.#sortSteamItems(tmpItems as ISteamStoreInventory[], sortBy),
    });
  }
  @Action(SortSteamInventoryByMethod)
  sortSteamInventoryByMethod({ patchState, getState }: StateContext<P2pStateModel>, { sortingMethod }: SortSteamInventoryByMethod): void {
    const { items } = getState();
    if (items) {
      patchState({ items: this.#sortSteamItems(items, sortingMethod) });
    }
  }
  #onError(e: HttpErrorResponse): void {
    this.#notificationsService.addErrorNotification(e.error.message || 'BALANCE_REFILL.TRADE_BOT.ERROR_UNAVAILABLE', {
      icon: 'warning',
      system: true,
    });
  }
  #sortSteamItems(inventoryItems: ISteamStoreInventory[] | null, sortMethod = MarketSortingTypes.MAX_PRICE): ISteamStoreInventory[] {
    if (!inventoryItems) {
      return [];
    }
    const copyItems = [...inventoryItems];
    const dir = sortMethod === MarketSortingTypes.MAX_PRICE ? 1 : -1;
    const sortedItems = copyItems
      .sort((a, b) => (b.price - a.price) * dir)
      .reduce(
        (acc, cur) => {
          if (cur.tradable && cur.passed) {
            return { ...acc, valid: [...acc.valid, cur] };
          }
          return { ...acc, invalid: [...acc.invalid, cur] };
        },
        { valid: [], invalid: [] } as { valid: ISteamStoreInventory[]; invalid: ISteamStoreInventory[] },
      );
    return [...sortedItems.valid, ...sortedItems.invalid];
  }
}
