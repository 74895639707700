import { InjectionToken } from '@angular/core';
import { IUserDetailed, StateActionStatus, TradeLinks } from '@dev-fast/types';
import { Observable } from 'rxjs';

export const TRADE_BLOCK_ENGINE: InjectionToken<TradeBlockEngine> = new InjectionToken('TRADE_BLOCK_ENGINE');

export interface TradeBlockEngine {
  readonly user$: Observable<IUserDetailed | null | undefined>;

  readonly tradeLink$: Observable<string | undefined>;
  readonly apiKey$: Observable<string | undefined>;
  // status requests
  readonly apiKeyLoadStatus$: Observable<StateActionStatus>;
  readonly tradeLinkLoadStatus$: Observable<StateActionStatus>;
  // validators from server
  readonly tradeLinkValidation$: Observable<StateActionStatus>;
  readonly apiKeyValidation$: Observable<StateActionStatus>;

  updateTradeLink(links: TradeLinks): void;
  updateApiKey(apiKey: string): void;
  getP2pPermissions(): void;
}
