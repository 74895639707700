import { IconConfig } from '@dev-fast/types';

export const coinsCommonIcons: IconConfig[] = [
  // TODO Удалить
  {
    name: 'gem-fill',
    path: 'assets/img/mat-icons/icons-lib/common/coins/gem-fill.svg',
  },
  {
    name: 'gem-plain',
    path: 'assets/img/mat-icons/icons-lib/common/coins/gem-plain.svg',
  },
  {
    name: 'gem-color-green',
    path: 'assets/img/mat-icons/icons-lib/common/coins/gem-color-green.svg',
  },
];
