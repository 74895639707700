import { FaqStateItem, IFaq, IFaqStorageDictionary } from '@dev-fast/types';

export interface FaqStateModel {
  items: FaqStateItem[];
  currentLangFaq: IFaq[];
  isActive: boolean;
  activeSection: string;
  activePosition: string | null;

  quickFaqStatuses: IFaqStorageDictionary;
}

export const FAQ_INITIAL_STATE: FaqStateModel = {
  items: [],
  currentLangFaq: [],
  isActive: false,

  activeSection: '',
  activePosition: null,

  quickFaqStatuses: {},
};
