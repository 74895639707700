import { IconConfig } from '@dev-fast/types';

export const iconsSupport: IconConfig[] = [
  {
    name: 'support-communications',
    path: 'assets/img/mat-icons/support/support-communications.svg',
  },
  {
    name: 'support-message',
    path: 'assets/img/mat-icons/support/support-message.svg',
  },
  {
    name: 'support-send-messages',
    path: 'assets/img/mat-icons/support/support-send-messages.svg',
  },
];
