import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, NgModule } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { RouterLink } from '@angular/router';
import { BrandComponentModule } from '@dev-fast/ui-components';
import { TranslateModule } from '@ngx-translate/core';

import { ChatService } from '@app/core/chatra-service';
import { LangRouterModule } from '@app/shared/directives';

import { BanService } from './ban.service';

@Component({
  selector: 'app-ban',
  templateUrl: './ban.component.html',
  styleUrls: ['./ban.component.scss'],
  providers: [BanService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BanComponent {
  readonly #dialogRef = inject(MatDialogRef<BanComponent>);
  readonly #chatService = inject(ChatService);
  readonly #banService = inject(BanService);
  readonly banReason: string = inject(MAT_DIALOG_DATA);

  onLogout(): void {
    this.#banService.logout();
  }
  onClose(): void {
    this.#dialogRef.close();
  }

  hasChatra(): boolean {
    return this.#chatService.hasChat();
  }

  openChatra(): void {
    if (this.hasChatra()) {
      this.#chatService.openChat();
    }
  }
}

@NgModule({
  imports: [CommonModule, BrandComponentModule, MatIconModule, TranslateModule, RouterLink, LangRouterModule],
  declarations: [BanComponent],
  exports: [BanComponent],
})
export class BanComponentModule {}
