<div
  class="panel-content"
  [class]="panelClass"
>
  <div class="left-controls">
    <ng-content select="[left-controls]"></ng-content>
  </div>
  <div class="center-controls">
    <ng-content> </ng-content>
    <ng-template [cdkPortalOutlet]="controlPanelPortal$ | async"></ng-template>
  </div>
  <div class="right-controls">
    <ng-content select="[right-controls]"></ng-content>
  </div>
</div>
<ng-content select="[bottom-banner]"></ng-content>
