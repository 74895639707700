import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

import { CurrencyComponent } from '@app/core/currency';
import { SkinItemImageModule } from '@app/shared/directives';
import { ISkinRarity, SKIN_RARITY } from '@app/shared/utils';

@Component({
  selector: 'app-ui-trade-skin',
  standalone: true,
  templateUrl: './trade-skin.component.html',
  styleUrls: ['./trade-skin.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, MatIconModule, SkinItemImageModule, CurrencyComponent],
})
export class TradeSkinComponent {
  skinRarity = SKIN_RARITY;
  @Input() name!: string;
  @Input() price!: number;
  @Input() icon!: string;
  @Input() rarity!: string;
  @Input() color!: string;
  @Input() isSelect!: boolean;
  @Input() isActive!: boolean;

  @Output() skinClick: EventEmitter<string> = new EventEmitter<string>();
  @HostBinding(`style.--active-color`)
  get activeColor(): string {
    const key = this.rarity ? this.rarity : this.color;
    return this.skinRarity[key.toLowerCase() as keyof ISkinRarity]?.active;
  }

  // @HostBinding(`style.--default-color`)
  // get defaultColor(): string {
  //   const key = this.rarity ? this.rarity : this.color;
  //   return this.skinRarity[key.toLowerCase() as keyof ISkinRarity]?.default;
  // }
  @HostBinding(`style.--hover-color`)
  get hoverColor(): string {
    const key = this.rarity ? this.rarity : this.color;
    return this.skinRarity[key.toLowerCase() as keyof ISkinRarity]?.hover;
  }

  onClick(): void {
    this.skinClick.emit();
  }
}
