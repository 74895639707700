import { Inject, Injectable, makeStateKey, TransferState } from '@angular/core';
import { IFaq, ILocales } from '@dev-fast/types';
import { Observable, pluck, tap } from 'rxjs';

import { Environments, EnvironmentService } from '@app/core/environment-service';
import { IS_SERVER_TOKEN } from '@app/shared/utils';

import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class LocalizationApiService {
  private environment: Environments;

  private readonly DEFAULT_LOCALE_KEY = makeStateKey('default-locale');

  constructor(
    private readonly api: ApiService,
    private readonly environmentService: EnvironmentService,
    private readonly transferState: TransferState,
    @Inject(IS_SERVER_TOKEN) private isServer: boolean,
  ) {
    this.environment = this.environmentService.getEnvironment();
  }

  getFAQ = (lang: string): Observable<IFaq[]> =>
    this.api
      .get<{ data: IFaq[] }>(`${this.environment.LOCALIZATION_URL}/faq/${this.environment.CLIENT_PROJECT}_${lang}`, {
        params: { project: this.environment.CLIENT_PROJECT },
      })
      .pipe(pluck('data'));

  getTranslation = (lang: string): Observable<any> => {
    const headers = new Headers({
      'Cache-Control': 'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
      Pragma: 'no-cache',
      Expires: '0',
    });

    return this.api
      .get(`${this.environment.LOCALIZATION_URL}/locales/${lang}`, {
        params: { project: this.environment.CLIENT_PROJECT, headers },
      })
      .pipe(
        tap((locale) => {
          if (this.isServer) {
            this.transferState.set(this.DEFAULT_LOCALE_KEY, locale);
          }
        }),
      );
  };

  getTranslationFromAssets = (lang: string): Observable<object> => {
    if (this.isServer) {
      return new Observable<object>((observer) => {
        this.#importAssetsFromServer().then((mock) => {
          observer.next(mock);
          observer.complete();
        });
      });
    }
    return this.api.get(`${this.environment.HOSTNAME}/assets/i18n/${['ru_RU', 'en_US'].includes(lang) ? lang : 'en_US'}.json`);
  };

  getAvailableLocales = (): Observable<ILocales[]> =>
    this.api.get<ILocales[]>(`${this.environment.LOCALIZATION_URL}/locales/list`, {
      params: { project: this.environment.CLIENT_PROJECT },
    });

  async #importAssetsFromServer(): Promise<any> {
    return await import('@app/shared/assets/i18n/en_US.json');
  }
}
