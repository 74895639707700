import { Directive, inject, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';

import { FeatureFlagsService } from './feature-flags.service';

@Directive({
  selector: '[appFeatureFlag]',
})
export class FeatureFlagDirective implements OnInit {
  readonly #templateRef = inject(TemplateRef<any>);
  readonly #viewContainer = inject(ViewContainerRef);
  readonly #featureFlags = inject(FeatureFlagsService);

  #requiredFlag: string[] | undefined;
  #elseTemplate: TemplateRef<any> | undefined;
  #isHidden = true;

  @Input() set appFeatureFlag(val: string[]) {
    if (val) {
      this.#requiredFlag = val;
      this.#updateView();
    }
  }
  @Input() set appFeatureFlagElse(template: TemplateRef<any> | undefined) {
    this.#elseTemplate = template;
  }

  ngOnInit(): void {
    this.#updateView();
  }

  #updateView(): void {
    if (this.#checkValidity()) {
      if (this.#isHidden) {
        this.#viewContainer.createEmbeddedView(this.#templateRef);
        this.#isHidden = false;
      }
    } else {
      if (this.#elseTemplate) {
        this.#isHidden = false;
        this.#viewContainer.createEmbeddedView(this.#elseTemplate);
      } else {
        this.#viewContainer.clear();
        this.#isHidden = true;
      }
    }
  }

  #checkValidity(): boolean | undefined {
    return this.#requiredFlag && this.#featureFlags.isFeatureFlagEnabled(this.#requiredFlag);
  }
}
