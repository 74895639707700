import { Injectable } from '@angular/core';
import { HistoryItem, IMarketplaceKitData, IP2pDepositingItem, ISteamStoreInventory, ModalNames } from '@dev-fast/types';
import { Select } from '@ngxs/store';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { Observable } from 'rxjs';

import { OpenAuthModal } from '@app/auth';
import { CloseModal, ModalsState, OpenModal } from '@app/core/state/modals';

@Injectable()
export class P2PModalService {
  @Select(ModalsState.isSomeModalOpened)
  readonly isSomeModalOpened$!: Observable<boolean>;

  @Dispatch() openModal = (name: ModalNames, payload?: any): OpenModal => new OpenModal(name, payload);
  @Dispatch() closeModal = (name: ModalNames, payload?: any): CloseModal => new CloseModal(name, payload);

  @Dispatch() openAuthModal = (): OpenAuthModal => new OpenAuthModal();
  @Dispatch() openCaseGameModal = (caseId: number): OpenModal => new OpenModal(ModalNames.CASE, caseId);
  @Dispatch() openAuctionModal = (item$: Observable<IP2pDepositingItem>): OpenModal => {
    return new OpenModal(ModalNames.P2P_AUCTION_COMPLETED, item$);
  };
  @Dispatch() openHistoryModal = (items$: Observable<HistoryItem[]>): OpenModal => {
    return new OpenModal(ModalNames.P2P_MARKET_HISTORY, items$);
  };
  @Dispatch() openKITModal = (payload: IMarketplaceKitData | ISteamStoreInventory): OpenModal => {
    return new OpenModal(ModalNames.P2P_KIT_DETAILS, payload);
  };
}
