export const YANDEX_DATA = {
  NAME: 'yandex-verification',
  CONTENT: '30f87b3d378673ba',
};

export const FACEBOOK_DATA = {
  NAME: 'facebook-domain-verification',
  CONTENT: 'z0zu48idnk8ra1xmxvlzkwr70za3wi',
};

export const GOOGLE_DATA = {
  NAME: 'google-site-verification',
  CONTENT: 'ktrCgruh4_bdILgHL6nns6rzZRAr0jztBG3LeYFTLK0',
};

export enum SeoType {
  DESCRIPTION = 'description',
  TITLE = 'title',
  H1 = 'h1',
  MAIN = 'main',
  FAQ = 'faq',
}
