import { Injectable } from '@angular/core';
import { IUserSettingsDetailed } from '@dev-fast/types';
import { Select } from '@ngxs/store';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { Observable } from 'rxjs';

import { ChangeAdditionalSettings, UserState } from '@app/core/state/user-store';

@Injectable()
export class P2pSettingsService {
  @Select(UserState.additionalSettings)
  public readonly additionalSettings$!: Observable<IUserSettingsDetailed>;

  @Dispatch() public changeAdditionalSettings = (payload: IUserSettingsDetailed): ChangeAdditionalSettings =>
    new ChangeAdditionalSettings(payload);
}
