<div class="sih-card__content">
  <app-steam-double-icon />
  <div class="sih-card__text">
    {{ 'SIH.REFILL.HEADER' | translate }}
  </div>
</div>

<div class="sih-card__action">
  <button
    class="btn btn-primary sih-button"
    (click)="onOpenSihInstallModal()"
  >
    {{ 'SIH.REFILL.DETAILS' | translate }}
  </button>
</div>
