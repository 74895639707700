import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { HistoryItem, HistoryItemStatus, HistoryItemTemplateData, HistorySourceType } from '@dev-fast/types';

// type PosibleStatusLocale = 'SOLD' | 'WITHDRAW' | 'DEPOSIT' | 'CASE' | 'CASE_BATTLE' | 'PICKED_TRADE';

@Component({
  selector: 'app-ui-history-item',
  templateUrl: './history-item.component.html',
  styleUrls: ['./history-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HistoryItemComponent {
  @Input() public set item(value: HistoryItem) {
    this.historyItem = value;
    this.formatHistoryItemView(value);
  }

  // Template
  public historyItem!: HistoryItem;
  public showCaseName = false;
  public isSourceCasesOrBattles = false;
  public price = 0;
  public templateData: HistoryItemTemplateData | null = null;
  // Enums
  public HistoryItemStatus = HistoryItemStatus;
  public HistorySourceType = HistorySourceType;
  private readonly HistoryItemTypesData = {
    [HistoryItemStatus.BET]: null,
    [HistoryItemStatus.TAKEN]: null,
    [HistoryItemStatus.BET_UPGRADE]: null,
    [HistoryItemStatus.SOLD]: { img: 'sold-status', locale: 'SOLD', isIcon: true },
    [HistoryItemStatus.DEPOSIT]: { img: 'steam-bold', locale: 'DEPOSIT', isIcon: true },
    [HistoryItemStatus.WITHDRAW]: { img: 'steam-bold', locale: 'WITHDRAW', isIcon: true },
  };
  private readonly HistoryItemStatusesData = {
    [HistorySourceType.MARKET]: null,
    [HistorySourceType.CASES]: { img: '', locale: 'CASE', isIcon: false },
    [HistorySourceType.CASES_QUEST]: { img: 'assets/img/mat-icons/left-sidebar/cases.webp', locale: 'CASE_QUEST', isIcon: false },
    [HistorySourceType.INVENTORY]: { img: 'refresh-line', locale: 'PICKED_TRADE', isIcon: true },
    [HistorySourceType.CASE_BATTLES]: { img: 'assets/img/mat-icons/left-sidebar/case-battle.webp', locale: 'CASE_BATTLE', isIcon: false },
  };

  private sourceCasesOrBattles(item: HistoryItem): boolean {
    return item.status.source.type === HistorySourceType.CASES || item.status.source.type === HistorySourceType.CASE_BATTLES;
  }

  private formatHistoryItemView(historyItem: HistoryItem): void {
    const status: HistoryItemStatus = historyItem.status.status;
    const type = historyItem.status.source.type;
    this.price = Math.abs(historyItem.item.price);
    this.isSourceCasesOrBattles = this.sourceCasesOrBattles(historyItem);

    if (status === HistoryItemStatus.TAKEN) {
      if (this.HistoryItemStatusesData[type]) {
        this.templateData = this.HistoryItemStatusesData[type];
        if (type === HistorySourceType.CASES && this.historyItem.status.source.data && this.templateData)
          this.templateData.img = this.historyItem.status.source.data.backgroundImage;
      }
    } else if (this.HistoryItemTypesData[status]) {
      this.templateData = this.HistoryItemTypesData[status];
    }
  }
}
