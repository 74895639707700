import { DOCUMENT } from '@angular/common';
import { inject, Injectable, makeStateKey, Optional, TransferState } from '@angular/core';
import { FooterService } from '@dev-fast/backend-services';
import {
  ActivePanel,
  ActiveRoute,
  BreakpointsTypes,
  Design,
  FooterCounts,
  MarketCounts,
  NewMenu,
  NewPanel,
  Panel,
  SubPanel,
  Themes,
  Widgets,
} from '@dev-fast/types';
import { RouterNavigation } from '@ngxs/router-plugin';
import { Action, NgxsOnInit, Selector, State, StateContext, Store } from '@ngxs/store';
import { append, patch } from '@ngxs/store/operators';

import { AnalyticsService } from '@app/core/analytics-service';
import { EnvironmentService } from '@app/core/environment-service';
import { FrameMessageTypes, IFrameMessageService, SetFrameLoadedStatus } from '@app/core/iframe';
import { LocalStorageService } from '@app/core/local-storage-service';
import { RefreshCurrentUser, UserState } from '@app/core/state/user-store';
import { RouterStateParams } from '@app/core/state/utils';
import { IS_SERVER_TOKEN } from '@app/shared/utils';

import {
  AllowTradePanelAutoOpen,
  ChangeActivePanel,
  ChangeActiveWidgets,
  ChangeDesign,
  ChangeTheme,
  ClickOnLockedSide,
  CloseAllMenu,
  CloseAllPanels,
  CloseMenu,
  ClosePanel,
  LockPanels,
  LockSides,
  NavigateToMobile,
  OpenAvailablePanel,
  OpenMenu,
  OpenPanel,
  PatchCounts,
  PatchMarketCounts,
  PatchOnline,
  RefreshActivePanel,
  SetActiveRoute,
  SetContentBreakpoint,
  SetLayoutType,
  ToggleBackground,
  ToggleGameSelector,
  ToggleNewPanel,
  UnLockPanels,
  UnLockSides,
} from './layout.actions';
import { LAYOUT_INITIAL_STATE, LayoutStateModel } from './layout-state.model';

@State<LayoutStateModel>({
  name: 'layout',
  defaults: LAYOUT_INITIAL_STATE,
})
@Injectable()
export class LayoutState implements NgxsOnInit {
  readonly #document = inject<Document>(DOCUMENT);
  readonly #isServer = inject<boolean>(IS_SERVER_TOKEN);
  readonly #storageService = inject(LocalStorageService);
  readonly #store = inject(Store);
  readonly #environmentService = inject(EnvironmentService);
  readonly #transferState = inject(TransferState);
  readonly #footerService = inject(FooterService);
  readonly #analyticsService = inject(AnalyticsService);

  private readonly ACTIVE_WIDGETS_KEY = makeStateKey('activeWidgets');

  private readonly window: Window | null = this.#document.defaultView;

  constructor(@Optional() private readonly frameMessageService: IFrameMessageService) {}

  @Selector()
  static theme({ theme }: LayoutStateModel): Themes {
    return theme;
  }

  @Selector()
  static activePanel({ activePanel }: LayoutStateModel): ActivePanel | null {
    return activePanel;
  }

  @Selector()
  static allowTradePanelAutoOpen({ allowTradePanelAutoOpen }: LayoutStateModel): boolean {
    return allowTradePanelAutoOpen;
  }

  @Selector()
  static activeWidgets({ activeWidgets }: LayoutStateModel): Widgets[] {
    return activeWidgets;
  }

  @Selector()
  static gameSelectorOpened({ gameSelectorOpened }: LayoutStateModel): boolean {
    return gameSelectorOpened;
  }

  @Selector()
  static isFadeSides({ isFadeSides }: LayoutStateModel): boolean {
    return isFadeSides;
  }

  @Selector()
  static lockedPanels({ lockedPanels }: LayoutStateModel): Panel[] {
    return lockedPanels;
  }
  @Selector()
  static design({ design }: LayoutStateModel): Design {
    return design;
  }
  @Selector()
  static isNewDesign({ design }: LayoutStateModel): boolean {
    return design === Design.NEW;
  }

  @Selector()
  static breakpoints({ breakpoints }: LayoutStateModel): BreakpointsTypes | null {
    return breakpoints;
  }

  @Selector()
  static online({ online }: LayoutStateModel): number | null {
    return online;
  }

  @Selector()
  static counts({ counts }: LayoutStateModel): FooterCounts | null {
    return counts;
  }

  @Selector()
  static marketCounts({ marketCounts }: LayoutStateModel): MarketCounts | null {
    return marketCounts;
  }

  @Selector()
  static activeRoute({ activeRoute }: LayoutStateModel): ActiveRoute | null {
    return activeRoute;
  }

  @Selector()
  static activePanelNew({ activePanelNew }: LayoutStateModel): NewPanel[] {
    return activePanelNew;
  }

  @Selector()
  static activeMenu({ activeMenu }: LayoutStateModel): NewMenu[] | null {
    return activeMenu;
  }
  ngxsOnInit({ dispatch, patchState }: StateContext<LayoutStateModel>): void {
    // Получаем с пререндера виджеты. Нужно чтобы со старта их не было 0 и не мелькала пустота
    const activeWidgets = this.#transferState.get(this.ACTIVE_WIDGETS_KEY, [] as any);
    if (activeWidgets.length) {
      patchState({ activeWidgets });
    }

    const isDesktop = this.#storageService.get('isDesktop');
    if (isDesktop) {
      patchState({ hasDesktopParams: isDesktop });
    }
    if (this.frameMessageService) {
      this.frameMessageService.on(FrameMessageTypes.MESSAGE_FROM_BB, 'render.modal', () => dispatch(new LockSides()));
      this.frameMessageService.on(FrameMessageTypes.MESSAGE_FROM_BB, 'close.modal', () => dispatch(new UnLockSides()));
      this.frameMessageService.on(FrameMessageTypes.MESSAGE_FROM_BB, 'inited', () => dispatch(new UnLockSides()));
    }
    this.#footerService.counts((payload) => {
      dispatch(new PatchCounts(payload));
    });
    this.#footerService.marketCounts((payload) => {
      dispatch(new PatchMarketCounts(payload));
    });
    this.#footerService.online((payload) => {
      dispatch(new PatchOnline(Number(payload)));
    });
  }

  @Action(LockSides)
  lockSides({ patchState, getState, dispatch }: StateContext<LayoutStateModel>): void {
    const { activePanel } = getState();
    // Если у нас открыты дополнительные панели перекрывающие экран после открытия модалок, то убираем их
    if (activePanel?.subPanel) {
      dispatch(new ChangeActivePanel({ panel: activePanel.panel, subPanel: SubPanel.NONE }));
    }
    patchState({ isFadeSides: true });
  }

  @Action(UnLockSides)
  unLockSides({ patchState }: StateContext<LayoutStateModel>): void {
    patchState({ isFadeSides: false });
  }

  @Action(ClickOnLockedSide)
  clickOnLockedSide(): void {
    if (!this.frameMessageService) {
      return;
    }
    this.frameMessageService.sendMessage({
      type: FrameMessageTypes.MESSAGE_TO_BB,
      eventName: 'click.OnLockedSide',
      payload: { message: 'click.OnLockedSide' },
    });
  }

  @Action(RefreshCurrentUser)
  refreshUser({ dispatch }: StateContext<LayoutStateModel>, { payload }: RefreshCurrentUser): void {
    if (payload.walletType) {
      payload.walletType === 'demo' ? dispatch(new LockPanels([Panel.INVENTORY])) : dispatch(new UnLockPanels([Panel.INVENTORY]));
    }
  }

  @Action(LockPanels)
  lockPanel({ dispatch, getState, setState }: StateContext<LayoutStateModel>, { panels }: LockPanels): void {
    const { activePanel, lockedPanels } = getState();
    if (activePanel && activePanel.panel === Panel.INVENTORY && panels.includes(Panel.INVENTORY)) {
      dispatch(new ChangeActivePanel({ panel: Panel.NOTIFICATIONS, subPanel: SubPanel.NONE }));
    }
    const newPanels = panels.filter((value) => !lockedPanels.includes(value));
    if (newPanels.length === 0) {
      return;
    }
    setState(patch({ lockedPanels: append<Panel>(newPanels) }));
  }

  @Action(UnLockPanels)
  unLockPanel({ patchState, getState }: StateContext<LayoutStateModel>, { panels }: UnLockPanels): void {
    const { lockedPanels } = getState();
    const newPanels = lockedPanels.filter((value) => !panels.includes(value));
    patchState({ lockedPanels: newPanels });
  }
  @Action(AllowTradePanelAutoOpen)
  allowTradeAoutoOpen({ patchState }: StateContext<LayoutStateModel>, { isOpen }: AllowTradePanelAutoOpen): void {
    patchState({ allowTradePanelAutoOpen: isOpen });
  }
  @Action(ChangeDesign)
  changeDesign({ setState }: StateContext<LayoutStateModel>, { design }: ChangeDesign): void {
    const allowedDesign = this.#getAllowedDesign(design);
    this.#storageService.set('design', allowedDesign);
    setState(patch({ design: allowedDesign }));
  }

  // @Action(LockInventory)
  // public lockInventory({ dispatch, getState }: StateContext<LayoutStateModel>) {
  //   const { activePanel } = getState();
  //   if (activePanel && activePanel.panel === Panel.INVENTORY) {
  //     dispatch(new ChangeActivePanel({ panel: Panel.NOTIFICATIONS, subPanel: SubPanel.NONE }));
  //   }
  // }

  @Action(RouterNavigation)
  routerNavigation(
    { dispatch, getState, patchState }: StateContext<LayoutStateModel>,
    { routerState }: RouterNavigation<RouterStateParams>,
  ): void {
    const { queryParams } = routerState;
    if (queryParams && queryParams['desktop']) {
      const value = queryParams['desktop'].toLowerCase() == 'true';
      patchState({ hasDesktopParams: value });
      this.#storageService.set('isDesktop', value);
    }
    const { activePanel } = getState();
    if (activePanel) {
      dispatch(new ChangeActivePanel({ panel: activePanel?.panel, subPanel: SubPanel.NONE }));
    }
  }

  @Action(ChangeTheme)
  changeTheme({ patchState }: StateContext<LayoutStateModel>, { theme }: ChangeTheme): void {
    const body = this.#document.querySelector('body');
    if (!body) {
      return;
    }

    const { classList } = body;
    Array.from(classList).forEach((classname) => classList.remove(classname));
    classList.add(theme);

    patchState({
      theme,
    });

    this.#storageService.set('theme', theme);
  }
  @Action(SetFrameLoadedStatus)
  changeFraneStatus({ dispatch }: StateContext<LayoutStateModel>, { loaded }: SetFrameLoadedStatus): void {
    // кост потому-что этот экшон вызывается по сути при событии навигации
    // const frameLoaded = this.store.selectSnapshot(IFrameState.loaded);
    // if (frameLoaded !== loaded) {
    //   dispatch(new ToggleBackground(loaded ? 'var(--main-image-background)' : 'var(--main-color-background)'));
    // }
  }
  /**Если ничего не передать поставит бэкграунд по умолчанию */
  @Action(ToggleBackground)
  toggleBackground({ patchState }: StateContext<LayoutStateModel>, { background }: ToggleBackground): void {
    if (!this.#isServer) {
      this.#document.documentElement.style.setProperty('--main-background', `${background ? background : 'var(--main-color-background)'}`);
    }

    patchState({
      background,
    });
  }

  // FIXME
  @Action(OpenAvailablePanel)
  openAvailablePanel({ dispatch }: StateContext<LayoutStateModel>, { design }: OpenAvailablePanel): void {
    const user = this.#store.selectSnapshot(UserState.user);
    if (user) {
      user.permLock
        ? dispatch(new ChangeActivePanel({ panel: Panel.NOTIFICATIONS, subPanel: SubPanel.NONE }))
        : dispatch(new ChangeActivePanel({ panel: design === Design.NEW ? Panel.TRADES : Panel.INVENTORY, subPanel: SubPanel.NONE }));
    } else {
      dispatch(new ChangeActivePanel({ panel: Panel.CHAT, subPanel: SubPanel.NONE }));
    }
  }

  @Action(ChangeActivePanel)
  changeActivePanel({ patchState, getState, dispatch }: StateContext<LayoutStateModel>, { panel }: ChangeActivePanel): void {
    const { activePanel, lockedPanels } = getState();

    if (panel && lockedPanels.includes(panel.panel)) {
      return;
    }

    patchState({
      activePanel: panel,
    });
    if (activePanel && panel && activePanel.panel === panel.panel) {
      dispatch(new RefreshActivePanel({ subPanel: panel.subPanel }));
    } else {
      dispatch(new RefreshActivePanel(panel));
    }
    if (!this.frameMessageService) {
      return;
    }
    if (panel === null || (panel && panel.subPanel === SubPanel.NONE)) {
      this.frameMessageService.sendMessage({
        type: FrameMessageTypes.MESSAGE_TO_BB,
        eventName: 'closeParticipationPanel',
        payload: {},
      });
    }
  }
  @Action(OpenMenu)
  changeActiveMenu({ patchState, getState }: StateContext<LayoutStateModel>, { menu }: OpenMenu): void {
    const { activeMenu } = getState();
    if (!activeMenu.includes(menu)) {
      patchState({
        activeMenu: [...activeMenu, menu],
      });
    }
  }
  @Action(CloseMenu)
  closeMenu({ patchState, getState }: StateContext<LayoutStateModel>, { menu }: CloseMenu): void {
    const { activeMenu } = getState();
    const menuArray = [...activeMenu];
    const index = menuArray.indexOf(menu);
    if (index !== -1) {
      menuArray.splice(index, 1);
      patchState({
        activeMenu: menuArray,
      });
    }
  }
  @Action(CloseAllMenu)
  closeAllMenu({ patchState }: StateContext<LayoutStateModel>): void {
    patchState({
      activeMenu: [],
    });
  }
  @Action(OpenPanel)
  OpenPanel({ patchState, getState }: StateContext<LayoutStateModel>, { panel }: OpenPanel): void {
    const { activePanelNew } = getState();
    const isUserBanned = !!this.#store.selectSnapshot(UserState.user)?.permLock;

    if (!activePanelNew.includes(panel) && !isUserBanned) {
      patchState({
        activePanelNew: [...activePanelNew, panel],
      });
    }
  }
  @Action(ClosePanel)
  ClosePanel({ patchState, getState }: StateContext<LayoutStateModel>, { panel }: ClosePanel): void {
    const { activePanelNew } = getState();
    const menuArray = [...activePanelNew];
    const index = menuArray.indexOf(panel);
    if (index !== -1) {
      menuArray.splice(index, 1);
      patchState({
        activePanelNew: menuArray,
      });
    }
  }
  @Action(ToggleNewPanel)
  ToggleNewPanel({ patchState, getState }: StateContext<LayoutStateModel>, { panel }: ToggleNewPanel): void {
    const { activePanelNew } = getState();
    const menuArray = [...activePanelNew];
    const index = menuArray.indexOf(panel);
    if (index !== -1) {
      menuArray.splice(index, 1);
      patchState({
        activePanelNew: menuArray,
      });
    } else {
      patchState({
        activePanelNew: [...menuArray, panel],
      });
    }
  }
  @Action(CloseAllPanels)
  CloseAllPanels({ patchState }: StateContext<LayoutStateModel>): void {
    patchState({
      activePanelNew: [],
    });
  }

  @Action(ToggleGameSelector)
  toggleGameSelector({ patchState }: StateContext<LayoutStateModel>, { active }: ToggleGameSelector): void {
    patchState({
      gameSelectorOpened: active,
    });
  }
  @Action(ChangeActiveWidgets)
  changeActiveWidgets({ patchState }: StateContext<LayoutStateModel>, { widgets }: ChangeActiveWidgets): void {
    if (this.#isServer) {
      this.#transferState.set(this.ACTIVE_WIDGETS_KEY, widgets as any);
    }

    patchState({
      activeWidgets: widgets,
    });
  }
  @Action(NavigateToMobile)
  navigateToMobile({ patchState }: StateContext<LayoutStateModel>): void {
    if (this.window) {
      if (this.window.location.host.startsWith('www')) {
        this.window.location.href = `//m.${window.location.host.split('.').slice(1).join('.')}`;
      } else {
        this.window.location.href = `//m.${window.location.host}`;
      }
    }

    patchState({
      hasDesktopParams: false,
    });
    this.#storageService.set('isDesktop', false);
  }

  @Action(SetContentBreakpoint)
  setContentBreakpoint({ patchState, getState }: StateContext<LayoutStateModel>, { bp }: SetContentBreakpoint): void {
    const { breakpoints } = getState();
    patchState({
      breakpoints: {
        ...breakpoints,
        content: bp,
      },
    });
  }
  @Action(SetLayoutType)
  setLayoutType({ patchState, getState }: StateContext<LayoutStateModel>, { type }: SetLayoutType): void {
    const { breakpoints } = getState();
    patchState({
      breakpoints: {
        ...breakpoints,
        native: type,
      },
    });
  }

  @Action(PatchCounts)
  patchCounts({ patchState }: StateContext<LayoutStateModel>, { counts }: PatchCounts): void {
    patchState({
      counts: { ...counts },
    });
  }

  @Action(PatchMarketCounts)
  patchMarketCounts({ patchState }: StateContext<LayoutStateModel>, { marketCounts }: PatchMarketCounts): void {
    patchState({
      marketCounts,
    });
  }

  @Action(PatchOnline)
  patchOnline({ patchState }: StateContext<LayoutStateModel>, { online }: PatchOnline): void {
    patchState({
      online: online,
    });
  }

  @Action(SetActiveRoute)
  setActiveRoute({ patchState, dispatch }: StateContext<LayoutStateModel>, { route }: SetActiveRoute): void {
    patchState({
      activeRoute: route,
    });
    if (route === ActiveRoute.STORE) {
      // mb need check user permanent lock there
      dispatch(new OpenPanel(NewPanel.TRADES));
    } else {
      dispatch(new ClosePanel(NewPanel.TRADES));
    }
  }

  #getAllowedDesign(design: Design): Design {
    const availablesDesigns = this.#environmentService.getEnvironment().AVAILABLE_DESIGNS;
    return availablesDesigns.some((value) => value === design) ? design : availablesDesigns[0];
  }
}
