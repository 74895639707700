import { IPaymentGate, IPaymentMethod } from '../entities';
import { RefillSystems, RefillTypes } from '../enums';

export interface IRefillForm {
  agreement: boolean;
  payment?: RefillSystems;
  amount: number;
  type?: RefillTypes;
  method?: IPaymentMethod;
  gate?: IPaymentGate;
  wallet?: string;
}
