import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

import { LazyLoadImageModule } from '@app/shared/directives';

import { UserAvatarMockComponent } from './mock/user-avatar.component.mock';
import { UserAvatarComponent } from './user-avatar.component';

@NgModule({
  imports: [CommonModule, MatIconModule, LazyLoadImageModule, UserAvatarComponent],
  declarations: [UserAvatarMockComponent],
  exports: [UserAvatarMockComponent, UserAvatarComponent],
})
export class UserAvatarModule {}
