import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, signal } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { CardStatusConfig, CardStatusEnum } from '@dev-fast/types';
import { TranslateModule } from '@ngx-translate/core';

import { CurrencyComponent } from '@app/core/currency';
import { HashPipe } from '@app/shared/pipes';
import { getSkinColorsPalette, SKIN_RARITY_V2 } from '@app/shared/utils';

import { SpinnerLoaderSAComponent } from '../loaders/spinner/spinner.component';

@Component({
  standalone: true,
  selector: 'app-ui-item-simple-card',
  templateUrl: './item-simple-card.component.html',
  styleUrls: ['./item-simple-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, MatIconModule, SpinnerLoaderSAComponent, CurrencyComponent, TranslateModule, HashPipe],
})
export class ItemSimpleCardComponent implements OnInit {
  @Input() name!: string;
  @Input() price = 0;
  @Input() icon = '';
  @Input() color = '#ffffff';
  @Input() rarity!: string;
  @Input() status: CardStatusEnum | null | undefined = null;
  @Input() timestamp: number | null = 0;
  @Input() placeholderText = '';

  @Output() cardClick: EventEmitter<void> = new EventEmitter<void>();

  isElementVisible = signal(true);

  backgroundColor = signal(SKIN_RARITY_V2['default'].default);
  hoverBackgroundColor = signal(SKIN_RARITY_V2['default'].hover);
  activeBackgroundColor = signal(SKIN_RARITY_V2['default'].plain);

  readonly CardStatusEnum = CardStatusEnum;

  ngOnInit(): void {
    this.initColor();
  }

  initColor(): void {
    const rarity = this.rarity ?? this.color;
    const key = rarity.toLowerCase();
    const color = getSkinColorsPalette(key, SKIN_RARITY_V2);
    this.backgroundColor.set(color.default);
    this.hoverBackgroundColor.set(color.hover);
    this.activeBackgroundColor.set(color.plain);
  }

  get statusIcon(): string | null | undefined {
    return this.status ? CardStatusConfig[this.status]?.matIcon : null;
  }

  get statusClass(): string | null | undefined {
    return this.status ? CardStatusConfig[this.status]?.class : null;
  }

  get isSelected(): boolean {
    return this.status === CardStatusEnum.SELECTED;
  }

  get #isCardDisabled(): boolean {
    if (!this.status) {
      return false;
    }
    return [CardStatusEnum.TIMER, CardStatusEnum.PARTICIPATED, CardStatusEnum.DISABLED].includes(this.status);
  }

  // убрал InViewport директиву пока, потому-что в классике из-за этого сетка ломалась
  // elementVisibleToggle(event: InViewportAction): void {
  //   this.isElementVisible.set(event.visible);
  // }

  handlerCard(): void {
    if (this.#isCardDisabled) {
      return;
    }
    this.cardClick.emit();
  }
}
