import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { DEFAULT_LANGS, ILocales, MAIN_LANG } from '@dev-fast/types';
import { Select } from '@ngxs/store';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import some from 'lodash-es/some';
import { Observable } from 'rxjs';

import { GetLocales, SetLanguage } from './language.action';
import { LanguageState } from './language.state';

@Injectable({ providedIn: 'root' })
export class LanguageService {
  static initLangs: { [route: string]: ILocales } | null;

  @Select(LanguageState.locales)
  locales$!: Observable<ILocales[]>;
  @Select(LanguageState.lang)
  currentLocale$!: Observable<ILocales>;

  /**
   * Переданный адрес преобразуется в тот же самый, но без языкового кода (/pl/aaa -> /aaa; /aaa -> /aaa)
   */
  static getBaseUrl(url: string): string {
    url = url.split('?')[0];
    if (!url.length) {
      url = '/';
    }
    return LanguageService.isSecondaryLanguageUrl(url) ? url.substring(3) || '/' : url;
  }

  /**
   * Проверяет содержит ли ссылка второстепенный язык (/ru/smth => true, /smth => false)
   */
  static isSecondaryLanguageUrl(url: string): boolean {
    return Object.keys(LanguageService.getRouteLanguages()).includes(url.split('/')[1]?.substring(0, 2) || '/');
  }

  /**
   * Возвращает список языков, которые участвуют в маршрутизации
   * - По умолчанию они берутся от пререндеров
   * - Если пререндера нет или он не отдал язык (например проект запущен без SSR), то возвращается дефолтный список из констант
   */
  static getRouteLanguages(): { [route: string]: ILocales } {
    // После первого же возврата сохраним результат и будем возвращать его впредь
    // NOTE: В этом месте языки для маршрутов сохраняются не через store из-за того,
    // что использовать их нужно еще до того как проиницилизируется весь ангуляр, компоненты и сервисы
    // (в том числе и store сервис)
    if (!LanguageService.initLangs) {
      let locales: ILocales[] = [];
      let langs: { [route: string]: ILocales } = DEFAULT_LANGS;
      // Если это браузер, то SSR передал нам данные о языках. Достаем их и строим на их основе маршруты
      if (typeof window !== 'undefined') {
        const element = document.getElementById('serverApp-state')?.innerHTML;
        if (element) {
          locales = JSON.parse(element.replace(/&q;/g, '"')).locales;
        }
      } else {
        locales = (global as any).locales;
      }

      if (locales && locales.length > 0) {
        langs = locales.reduce((routes: { [route: string]: ILocales }, item: ILocales) => {
          const route = item.path.substring(0, 2);
          if (!routes[route] && item.isActive) {
            routes[route] = item;
          }
          return routes;
        }, {});
      }

      LanguageService.initLangs = langs;
    }
    return LanguageService.initLangs;
  }

  /**
   * Проверяет по ключу языка разрешен ли он (en, pl, pt etc.)
   */
  static isAcceptedLanguage(lang: string): boolean {
    return Object.keys(LanguageService.getRouteLanguages()).includes(lang);
  }

  /**
   * Проверяет по коду языка разрешен ли он (en_US, pl_PL, pt_PT etc.)
   */
  static isAcceptedLanguageCode(lang: string): boolean {
    return some(LanguageService.getRouteLanguages(), (locale) => locale.path === lang);
  }

  /**
   * При получении кода возвращает ключ языка (en_US => en)
   */
  static getLangByCode(code: string): string {
    const lang = Object.entries(LanguageService.getRouteLanguages()).find(([, value]: any[]) => code === value.path)?.[0];
    return lang || MAIN_LANG;
  }

  /**
   * Преобразует в переданном пути из старого языка в новый
   */
  static updateLanguageURL(path: string, newLang: string, pathLang: string): string {
    const availableLangs = Object.keys(LanguageService.getRouteLanguages()).filter((langState: string) => langState !== MAIN_LANG);

    if (availableLangs.includes(newLang)) {
      return availableLangs.includes(pathLang) ? path.replace(`/${pathLang}`, `/${newLang}`) : `${newLang}${path}`;
    } else {
      // Если новый язык не входит в список доступных, то перенаправляем на главный язык
      if (MAIN_LANG !== newLang) {
        return path !== '/' ? `/${newLang}` : `/${newLang}${path}`;
      } else {
        return path.replace(`/${pathLang}`, '');
      }
    }
  }

  readonly #router = inject(Router);

  /**
   * Переданный адрес преобразуется в тот же самый, но c текущим языковом кодом.
   *
   * Можно использовать для навигации в тс файлах
   *  this.router.navigate([this.languageService.getCurrentLangUrl(URL)]);
   *
   *  Дает нам плюс в оптимизации. Не будет отрабатывать логика в "гварде" чтобы
   *  перенаправить нас на нужную страницу с нужным языковым кодом
   */
  getCurrentLangUrl(url: string): string {
    const routeLang = this.#router.url.substring(1, 3);
    const lang = LanguageService.isAcceptedLanguage(routeLang) ? routeLang : MAIN_LANG;
    const baseUrl = LanguageService.getBaseUrl(url);
    return lang === MAIN_LANG ? baseUrl : '/' + lang + baseUrl;
  }
  @Dispatch() setLanguage = (locale: ILocales, useNavigation = true): SetLanguage => new SetLanguage(locale, useNavigation);

  @Dispatch() getLocales = (): GetLocales => new GetLocales();
}
