import { HttpErrorResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { LevelsApiService, PayService, UserApiService, WithdrawalApiService } from '@dev-fast/backend-services';
import {
  BonusTransactionTypes,
  IExperience,
  IExperienceLeader,
  IExperienceStatistic,
  IGameStatistics,
  ILevel,
  ITransactionDto,
  IUserDetailed,
  IUserSettingsDetailed,
  prelude,
  Privacy,
  WalletTransactionTypeId,
  zeroLevel,
} from '@dev-fast/types';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { defer, forkJoin, iif, Observable, of, throwError } from 'rxjs';
import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';

import { NotificationsService } from '@app/core/notification-service';
import { GetAdditionalSettings, GetUser, UserState } from '@app/core/state/user-store';
import { mapFromWithdrawalHistoryToTransactionDto } from '@app/shared/utils/transformation';

import {
  ChangePrivacy,
  GetBalanceHistory,
  GetExperienceLeaders,
  GetExperienceStatistic,
  GetLevelsRoadMap,
  GetRecentExperienceGainList,
  GetUserStatistics,
  LevelUp,
  UpdateApiKey,
  UpdateApiKeySuccess,
  UpdateTradeLink,
  UpdateTradeLinks,
  UpdateTradeLinkSuccess,
} from './account.actions';
import { ACCOUNT_INITIAL_STATE, AccountStateModel } from './account-state.model';

@State<AccountStateModel>({
  name: 'account',
  defaults: ACCOUNT_INITIAL_STATE,
})
@Injectable()
export class AccountState {
  readonly #apiUserService = inject(UserApiService);
  readonly #levelsApiService = inject(LevelsApiService);
  readonly #payApiService = inject(PayService);
  readonly #withdrawalApiService = inject(WithdrawalApiService);

  readonly #notificationsService = inject(NotificationsService);

  @Selector([UserState.additionalSettings])
  static privacy(currentCtx: AccountStateModel, additionalSettings: IUserSettingsDetailed): Privacy | null | undefined {
    return additionalSettings?.privacy || null;
  }
  @Selector()
  static gameStatistics({ gameStatistics }: AccountStateModel): IGameStatistics[] {
    return gameStatistics;
  }

  @Selector()
  static leaders({ leaders }: AccountStateModel): IExperienceLeader[] {
    return leaders;
  }

  @Selector()
  static experience({ experience }: AccountStateModel): IExperience[] {
    return experience;
  }

  @Selector()
  static statistics({ statistics }: AccountStateModel): IExperienceStatistic[] {
    return statistics;
  }

  @Selector()
  static levels({ levels }: AccountStateModel): ILevel[] {
    return levels;
  }

  @Selector()
  static balanceHistory({ balanceHistory }: AccountStateModel): any[] {
    return balanceHistory;
  }

  @Selector([UserState.user])
  static canLevelUp({ levels }: AccountStateModel, user: IUserDetailed): boolean {
    const maxLevel = levels[levels.length - 1].level;
    return user && user.experience.level < maxLevel ? user.experience.xp > user.experience.nextLevelXp : false;
  }

  @Action(GetUserStatistics)
  getStatistics({ patchState }: StateContext<AccountStateModel>, { userId }: GetUserStatistics): Observable<IGameStatistics[]> {
    return this.#apiUserService.getUserStatistics(userId).pipe(tap((response) => patchState({ gameStatistics: response })));
  }

  @Action(GetExperienceLeaders)
  getExperienceLeaders({ patchState }: StateContext<AccountStateModel>): Observable<IExperienceLeader[]> {
    return this.#levelsApiService.getExperienceLeaders().pipe(tap((leaders) => patchState({ leaders })));
  }

  @Action(GetLevelsRoadMap)
  getLevelsRoadMap({ patchState }: StateContext<AccountStateModel>): Observable<ILevel[]> {
    return this.#levelsApiService.getLevelsRoadMap().pipe(
      tap((levels) => {
        return patchState({ levels: [prelude, zeroLevel, ...levels] });
      }),
    );
  }

  @Action(GetRecentExperienceGainList)
  getRecentExperienceGainList({ patchState }: StateContext<AccountStateModel>): Observable<IExperience[]> {
    return this.#levelsApiService.getRecentExperienceGainList().pipe(tap((experience) => patchState({ experience })));
  }

  @Action(GetExperienceStatistic)
  getExperienceStatistic({ patchState }: StateContext<AccountStateModel>): Observable<IExperienceStatistic[]> {
    return this.#levelsApiService.getExperienceStatistic().pipe(tap((statistics) => patchState({ statistics })));
  }

  @Action(LevelUp)
  levelUp({ dispatch }: StateContext<AccountStateModel>): Observable<void> {
    return this.#levelsApiService.levelUp().pipe(tap(() => dispatch(new GetUser())));
  }

  @Action(ChangePrivacy)
  changePrivacy({ dispatch }: StateContext<AccountStateModel>, { payload }: ChangePrivacy): Observable<void> {
    return this.#apiUserService.savePrivacy(payload).pipe(switchMap(() => dispatch(new GetAdditionalSettings())));
  }

  @Action(GetBalanceHistory)
  getBalanceHistory(
    { patchState, getState }: StateContext<AccountStateModel>,
    { offset, append }: GetBalanceHistory,
  ): Observable<ITransactionDto[]> {
    return forkJoin({
      paymentHistory: this.#payApiService
        .getPaymentHistory({
          typeIds: [WalletTransactionTypeId.DEPOSIT_ITEMS, WalletTransactionTypeId.PAYMENT, WalletTransactionTypeId.P2P_PAYOUT].join(','),
          page: {
            number: offset,
            size: 100,
          },
        })
        .pipe(map((res) => res.filter((item) => item.type !== BonusTransactionTypes.ERROR))),
      withdrawalHistory: append ? of([]) : this.#withdrawalApiService.getHistory(),
    }).pipe(
      map(({ paymentHistory, withdrawalHistory }) => {
        return [...mapFromWithdrawalHistoryToTransactionDto(withdrawalHistory), ...paymentHistory];
      }),
      tap((balanceHistory) => {
        const currentBalanceHistory = getState().balanceHistory;
        if (append) {
          patchState({
            balanceHistory: [...currentBalanceHistory, ...balanceHistory],
          });
        } else {
          patchState({
            balanceHistory,
          });
        }
      }),
      catchError((error: HttpErrorResponse) => {
        this.#onError(error);
        throw new Error(error.message);
      }),
    );
  }

  @Action(UpdateApiKey, { cancelUncompleted: true })
  updateApiKey({ dispatch }: StateContext<AccountStateModel>, { apiKey }: UpdateApiKey): Observable<any> {
    return this.#apiUserService.updateApiKey(apiKey).pipe(
      mergeMap((res) =>
        iif(
          () => res?.error,
          throwError(() => ({ message: res.error })),
          of(res),
        ),
      ),
      tap(() => {
        dispatch([new GetUser(), new UpdateApiKeySuccess()]);
      }),
      catchError((error: HttpErrorResponse) => {
        this.#onError(error, 'P2P_SETTINGS.TRADE_LINK.STEAM_MUST_BE_STEAM_API_KEY');
        throw new Error(error.message);
      }),
    );
  }
  @Action(UpdateTradeLink, { cancelUncompleted: true })
  updateTradeLink({ dispatch }: StateContext<AccountStateModel>, { links }: UpdateTradeLink): Observable<any> {
    return this.#apiUserService.updateTradeLinks(links).pipe(
      mergeMap((res) =>
        iif(
          () => res?.error,
          throwError(() => ({ message: res.error })),
          of(res),
        ),
      ),
      tap(() => {
        return dispatch([new GetUser(), new UpdateTradeLinkSuccess()]);
      }),
      catchError((error: HttpErrorResponse) => {
        this.#onError(error, 'P2P_SETTINGS.TRADE_LINK.STEAM_MUST_BE_STEAM_TRADE_LINK');
        throw new Error(error.message);
      }),
    );
  }
  @Action(UpdateTradeLinks)
  updateTradeLinks({ dispatch }: StateContext<AccountStateModel>, { links, apiKey }: UpdateTradeLinks): Observable<any> {
    //TODO вроде все как нужно но встарой было updateTradeLinks вместо updateApiKey. Проверить
    // FIXME пересмотреть когда настройки буду делать
    return defer(() =>
      apiKey
        ? forkJoin([this.#apiUserService.updateTradeLinks(links), this.#apiUserService.updateApiKey(apiKey)])
        : this.#apiUserService.updateTradeLinks(links),
    ).pipe(
      tap(() => {
        this.#notificationsService.addSuccessNotification('ACCOUNT_SETTINGS.LINK_UPDATED', {
          icon: 'info',
          system: true,
        });
        dispatch(new GetUser());
      }),
      catchError((error: HttpErrorResponse) => this.#onError(error)),
    );
  }
  #onError(e: HttpErrorResponse, msg?: string, system = true): Observable<any> {
    const { error } = e;
    this.#notificationsService.addErrorNotification(msg || error.message || error.error, {
      icon: 'warning',
      system: !!error?.system || system,
    });
    return of(msg || error.message || error.error);
  }
}
