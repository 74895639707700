import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TranslateModule } from '@ngx-translate/core';
import { NgxsModule } from '@ngxs/store';
import { NgScrollbarModule } from 'ngx-scrollbar';

import { IframeComponent } from './iframe.component';
import { IFrameCommonService } from './services/common.service';
import { IFrameMessageService } from './services/message.service';
import { IFrameRoutingService } from './services/routing.service';
import { IFrameState } from './state/iframe.state';

@NgModule({
  imports: [
    CommonModule,
    NgxsModule.forFeature([IFrameState]),
    MatProgressSpinnerModule,
    TranslateModule,
    NgScrollbarModule,
    // CubeGridLoaderComponentModule,
  ],
  declarations: [IframeComponent],
  providers: [IFrameCommonService, IFrameRoutingService, IFrameMessageService],
  exports: [IframeComponent],
})
export class CoreIframeModule {}
