<ng-container *ngIf="$any(_ngControl.control) as inputControl">
  <div
    class="ui-input"
    [@bellAnimation]="animTrigger()"
  >
    <ng-container *ngIf="prefixIcon || prefixText">
      <div
        class="ui-input__prefix"
        (mouseover)="hoverEvent(true)"
        (mouseout)="hoverEvent(false)"
        @narrowingAnimation
        @wideningAnimation
      >
        <ng-container *ngIf="prefixIcon">
          <mat-icon
            class="ui-input__prefix-icon"
            [svgIcon]="prefixIcon"
          >
          </mat-icon>
        </ng-container>
        <ng-container *ngIf="prefixText">
          <span class="ui-input__prefix-text">
            {{ prefixText }}
          </span>
        </ng-container>
      </div>
    </ng-container>
    <input
      class="ui-input__field"
      type="text"
      name="ui-input"
      [autocomplete]="_autocomplete"
      [placeholder]="placeholder"
      [formControl]="inputControl"
      [value]="inputControl.value"
      [maskito]="maskOptions"
      (paste)="pasteDefender()"
    />
    <ng-content></ng-content>
  </div>
</ng-container>
