import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { IP2pDepositItem } from '@dev-fast/types';

import { IColorPalette, SKIN_RARITY_V2 } from '@app/shared/utils';

export const FAST_SELL_OVERPRICE = -5;
export const SLOW_SELL_OVERPRICE = 10;

export enum SellSpeed {
  FAST = 'fast-sell',
  SLOW = 'slow-sell',
  DEFAULT = '',
}
@Component({
  selector: 'app-p2p-sell-setup-item',
  templateUrl: './p2p-sell-setup-item.component.html',
  styleUrls: ['./p2p-sell-setup-item.component.scss'],
})
export class P2pSellSetupItemComponent implements OnChanges {
  @Input() duration: number | undefined;
  @Input() item: IP2pDepositItem | undefined;

  @Output() itemRemoved: EventEmitter<void> = new EventEmitter<void>();

  bgColor = 'none';
  hvColor = 'none';

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['item']) {
      const color = this.#getColorPalette(changes['item'].currentValue.baseItem.rarity, changes['item'].currentValue.baseItem.color);
      this.bgColor = color.default ?? 'none';
      this.hvColor = color.hover ?? 'none';
    }
  }

  increaseFieldColorClass(increase: number): SellSpeed {
    if (increase > SLOW_SELL_OVERPRICE) {
      return SellSpeed.SLOW;
    }
    if (increase < FAST_SELL_OVERPRICE) {
      return SellSpeed.FAST;
    }
    return SellSpeed.DEFAULT;
  }
  removeAt(): void {
    this.itemRemoved.emit();
  }

  #getColorPalette(rarity: string, color: string): IColorPalette {
    return SKIN_RARITY_V2[(rarity ?? color).toLowerCase()];
  }
}
