import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ResizedDirective } from './resized.directive';

@NgModule({
  declarations: [ResizedDirective],
  imports: [CommonModule],
  exports: [ResizedDirective],
})
export class ResizedModule {}
