<div class="language-panel--wrapper">
  <div class="languages__header-panel">
    <button
      class="btn btn-semi-round btn-transparent-dark--design j-fs w-f"
      (click)="onClosePanel(menu.LANGUAGE)"
    >
      <mat-icon
        class="icon lg"
        svgIcon="arrow-left-design"
      ></mat-icon>
      <span>{{ 'HEADER_SETTINGS.LANGUAGE' | translate }}</span>
    </button>
  </div>
  <div class="languages__list-panel">
    <ng-container *ngIf="currentLanguage">
      <ng-container *ngFor="let lang of locales">
        <button
          class="btn btn-semi-round btn-transparent-dark--design j-sb w-f"
          [disabled]="!lang.isActive"
          [class.active-lang]="currentLanguage === lang.path"
          (click)="onLangChange(lang.path)"
        >
          <div class="language-title f-a-center">
            <img
              lazyLoadImage
              [src]="'//d2lomvz2jrw9ac.cloudfront.net/country-flags/' + lang.path.slice(3).toLowerCase() + '.png'"
            />
            <span>{{ lang.title }}</span>
          </div>
          <mat-radio-button
            class="languages-list-radio-btn"
            [value]="lang.path"
            [checked]="currentLanguage === lang.path"
          >
          </mat-radio-button>
        </button>
      </ng-container>
    </ng-container>
  </div>
</div>
