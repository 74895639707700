import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { HistoryItem } from '@dev-fast/types';
import { Observable } from 'rxjs';

import { P2pMarketModalComponent } from '../p2p-market-modal/p2p-market-modal.component';

@Component({
  selector: 'app-p2p-market-history',
  templateUrl: './p2p-market-history.component.html',
  styleUrls: ['./p2p-market-history.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class P2pMarketHistoryComponent {
  constructor(
    public dialogRef: MatDialogRef<P2pMarketModalComponent>,
    @Inject(MAT_DIALOG_DATA) public marketHistoryItems: Observable<HistoryItem[]>,
  ) {}

  closeModal = (): void => this.dialogRef.close();
}
