<footer class="footer-wrapper tw-w-full tw-flex tw-justify-center">
  <div class="footer">
    <div class="footer__indicators">
      <!-- online users count -->
      <div class="footer__indicators__block">
        <mat-icon svgIcon="online--footer" />
        <div
          *ngIf="online"
          class="footer__indicators__block__stats"
        >
          <div class="footer__indicators__block__stats__value">{{ online }}</div>
          <div class="footer__indicators__block__stats__label">{{ 'FOOTER.ONLINE' | translate }}</div>
        </div>
      </div>

      <!-- users count -->
      <div class="footer__indicators__block">
        <mat-icon svgIcon="users-line-bold" />
        <div class="footer__indicators__block__stats">
          <div class="footer__indicators__block__stats__value">
            <ng-container *ngIf="!!counts?.usersCount; else countGradientTemplate">
              {{ counts?.usersCount }}
            </ng-container>
          </div>
          <div class="footer__indicators__block__stats__label">{{ 'FOOTER.USERS' | translate }}</div>
        </div>
      </div>

      <ng-container *isProject="PROJECT.MARKET">
        <!-- skins on sale count -->
        <div class="footer__indicators__block">
          <mat-icon svgIcon="on--sale" />
          <div class="footer__indicators__block__stats">
            <div class="footer__indicators__block__stats__value">
              <ng-container *ngIf="marketCounts && marketCounts.onSale; else countGradientTemplate">
                {{ marketCounts.onSale }}
              </ng-container>
            </div>
            <div class="footer__indicators__block__stats__label">{{ 'FOOTER.SKINS_ON_SALE' | translate }}</div>
          </div>
        </div>

        <!-- total deals count -->
        <div class="footer__indicators__block">
          <mat-icon svgIcon="total--deals" />
          <div class="footer__indicators__block__stats">
            <div class="footer__indicators__block__stats__value">
              <ng-container *ngIf="marketCounts && marketCounts.totalDeals; else countGradientTemplate">
                {{ marketCounts.totalDeals }}
              </ng-container>
            </div>
            <div class="footer__indicators__block__stats__label">{{ 'FOOTER.DEALS_TOTAL' | translate }}</div>
          </div>
        </div>
      </ng-container>

      <ng-container *isProject="{ name: PROJECT.MARKET, exclude: true }">
        <!-- games count -->
        <div class="footer__indicators__block">
          <mat-icon svgIcon="games--footer" />
          <div class="footer__indicators__block__stats">
            <div class="footer__indicators__block__stats__value">
              <ng-container *ngIf="!!counts?.gamesCount; else countGradientTemplate">
                {{ counts?.gamesCount }}
              </ng-container>
            </div>
            <div class="footer__indicators__block__stats__label">{{ 'FOOTER.PLAYED_TODAY' | translate }}</div>
          </div>
        </div>

        <!-- cases count -->
        <div class="footer__indicators__block">
          <mat-icon svgIcon="open-cases--footer"></mat-icon>
          <div class="footer__indicators__block__stats">
            <div class="footer__indicators__block__stats__value">
              <ng-container *ngIf="!!counts?.casesCount; else countGradientTemplate">
                {{ counts?.casesCount }}
              </ng-container>
            </div>
            <div class="footer__indicators__block__stats__label">{{ 'FOOTER.CASES_OPENED' | translate }}</div>
          </div>
        </div>
      </ng-container>
    </div>

    <div class="footer__socials">
      <div class="footer__socials__label">
        {{ 'FOOTER.SUBSCRIBE' | translate }}
      </div>
      <div class="footer__socials__links">
        <a
          *ngFor="let item of socials"
          [href]="item.link"
          target="_blank"
          class="btn socials-button decoration-none"
        >
          <mat-icon
            *ngIf="item.svgIcon"
            [svgIcon]="item.svgIcon"
          ></mat-icon>
          <!-- subscribers count -->
          {{ '' }}
        </a>
      </div>
    </div>

    <div class="footer__inner">
      <div class="footer__column">
        <p class="footer__text">
          <ng-container *isProject="{ name: PROJECT.MARKET, exclude: true }; else marketTos">
            {{ 'FOOTER.GAMEVIO' | translate }}
          </ng-container>
          <ng-template #marketTos> {{ 'MARKET.ADDRESS' | translate }} </ng-template>
        </p>
        <div class="footer__links">
          <a
            langRouterLink
            [routerLink]="'/tos'"
            class="footer__link"
            >{{ 'FOOTER.TOS' | translate }}</a
          >
          <a
            langRouterLink
            [routerLink]="'/privacy-policy'"
            class="footer__link"
            >{{ 'FOOTER.PRIVACY_POLICY' | translate }}</a
          >
          <a
            *isProject="PROJECT.FAST_NEW"
            langRouterLink
            [routerLink]="'/aml-policy'"
            class="footer__link"
            >{{ 'FOOTER.AML_POLICY' | translate }}</a
          >
          <a
            *isProject="{ name: PROJECT.MARKET, exclude: true }"
            langRouterLink
            [routerLink]="'/bug-bounty'"
            class="footer__link"
            >{{ 'FOOTER.BUG_BOUNTY' | translate }}</a
          >
          <a
            *isProject="PROJECT.MARKET"
            langRouterLink
            [routerLink]="'/cookie-policy'"
            class="footer__link"
            >{{ 'FOOTER.COOKIE_POLICY' | translate }}</a
          >
        </div>
      </div>
      <div class="footer__row antillephone_container">
        <div class="footer__image">
          <img
            lazyLoadImage
            src="/assets/img/footer/18.png"
            alt="18+"
          />
        </div>
      </div>
    </div>
  </div>
</footer>
<div class="footer-wrapper__copyright">
  <div>
    <ng-container *isProject="{ name: PROJECT.MARKET, exclude: true }; else marketCopyrights">
      {{ 'FOOTER.RIGHTS' | translate }}
    </ng-container>
    <ng-template #marketCopyrights>
      {{ 'FOOTER.RIGHTS_MARKET' | translate }}
    </ng-template>
  </div>
</div>

<ng-template #countGradientTemplate>
  <div class="loading-gradient tw-w-14 tw-h-8"></div>
</ng-template>
