<form
  *ngIf="filterForm"
  [formGroup]="filterForm"
  [class.rangeFilterMobileView]="mobileView"
>
  <mat-slider
    [min]="control.min || 0"
    [max]="control.max || 100"
    class="p2p-setup-slider filter-slider"
    discrete
    [displayWith]="formatLabel"
    [step]="1"
  >
    <input
      matSliderStartThumb
      [value]="control.initMin"
      [formControlName]="control.outputData.minValueKey"
    />
    <input
      matSliderEndThumb
      [value]="control.initMax"
      [formControlName]="control.outputData.maxValueKey"
    />
  </mat-slider>
</form>
