import { IconConfig } from '@dev-fast/types';

export const iconsPremiumTypes: IconConfig[] = [
  {
    name: 'premium-bronze',
    path: 'assets/img/mat-icons/premium-types/premium-bronze.svg',
  },
  {
    name: 'premium-demo',
    path: 'assets/img/mat-icons/premium-types/premium-demo.svg',
  },
  {
    name: 'premium-diamond',
    path: 'assets/img/mat-icons/premium-types/premium-diamond.svg',
  },
  {
    name: 'premium-gold',
    path: 'assets/img/mat-icons/premium-types/premium-gold.svg',
  },
  {
    name: 'premium-platinum',
    path: 'assets/img/mat-icons/premium-types/premium-platinum.svg',
  },
  {
    name: 'premium-silver',
    path: 'assets/img/mat-icons/premium-types/premium-silver.svg',
  },
];
