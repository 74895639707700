<ng-container *ngIf="players">
  <div class="winners-modal-container">
    <button
      class="btn close-btn"
      (click)="closeModal()"
    >
      <mat-icon
        class="close-icon"
        [svgIcon]="'close'"
      ></mat-icon>
    </button>
    <div class="winners-modal-header">
      <div class="top-wrapper">
        <div
          *ngIf="battleId"
          class="title"
        >
          Battle №{{ battleId }}
        </div>
      </div>
      <div class="bottom-wrapper">
        <div class="list-header-item">#</div>
        <div class="list-header-item">
          {{ 'LOCAL.CASES.BATTLE_GAME.MODAL.PLAYER' | translate }}
        </div>
        <!-- <div class="list-header-item">
                    Client seed
                </div> -->
        <div class="list-header-item price">
          {{ 'LOCAL.CASES.BATTLE_GAME.MODAL.SKIN_PRICE' | translate }}
        </div>
        <div class="list-header-item price">
          {{ 'LOCAL.CASES.BATTLE_GAME.MODAL.WIN_PRICE' | translate }}
        </div>
      </div>
    </div>
    <div class="players-container">
      <!-- <div *ngIf="userData"
                class="player-card user"
                [class.winner]="userData.skins">
                <div *ngIf="userPosition" class="position">{{ userPosition }}</div>
                <app-ui-user-avatar
                    [userId]="userData.player.user_id"
                    [url]="userData.player.ava_link"
                    [username]="{ name: userData.player.name, position: 'right' }">
                </app-ui-user-avatar>
                <div class="price">
                    {{ userData.prizeSum| appCurrency }}
                </div>
                <div class="price">
                    <ng-container *ngIf="userData.skins; else loseTemplate">
                        {{ userData.skins.sum + (userData.money || 0) | appCurrency }}
                    </ng-container>
                    <ng-template #loseTemplate>
                        -
                    </ng-template>
                </div>
            </div> -->
      <ng-scrollbar
        [autoHeightDisabled]="false"
        [autoWidthDisabled]="false"
        [viewClass]="'case-battle-modal-srollbar'"
        track="vertical"
      >
        <div class="players-wrapper">
          <div
            *ngFor="let player of players; let i = index"
            class="player-card"
            [class.winner]="player.skins"
          >
            <div class="position">{{ i + 1 }}</div>
            <app-ui-user-avatar
              [userId]="player.player.name.toLowerCase().includes('bot') ? null : player.player.user_id"
              [url]="player.player.ava_link"
              [username]="{ name: player.player.name, position: 'right' }"
            >
            </app-ui-user-avatar>
            <!-- <div class="client-seed">

                        </div> -->
            <div class="price">
              {{ player.prizeSum | appCurrency }}
            </div>
            <div class="price">
              <ng-container *ngIf="player.skins; else loseTemplate">
                {{ player.skins.sum + (player.money || 0) | appCurrency }}
              </ng-container>
              <ng-template #loseTemplate> - </ng-template>
            </div>
          </div>
        </div>
      </ng-scrollbar>
    </div>
  </div>
</ng-container>
