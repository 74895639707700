import {
  IBaseItemDto,
  ICaseItemDtoV2,
  ICaseOpenPrizeItemDto,
  ICaseRevisionItem,
  IInventoryItemV2,
  IMotivatorDto,
  IUserShort,
} from '../../entities';
import { WalletTypeEnum } from '../../enums';

// export interface EventInfo {
//   rounds: RoundConfig[];
//   /** Финальная награда если доступна. */
//   reward?: any;
//   endAt: string;
// }
// /**
//  - available доступен раунд
//  - blocked предидущий еще не пройден
//  - completed когда прошел раунд (10 выстрелов)
//  */
// export enum RoundStatusEnum {
//   COMPLETED = 'completed',
//   AVAILABLE = 'available',
//   BLOCKED = 'blocked',
// }
// export interface RoundConfig {
//   round: RoundType;
//   iconBullet: string;
//   iconTarget: string;
//   /*стоимость выстрела в XP*/
//   shotCostInXp: number;
//   /*стоимость выстрела в кол-ве билетов*/
//   shotCostInTickets: number;
//   /** сколько всего мишеней в раунде*/
//   count: number;
//   /** сколько всего мишеней в раунде доступных к открытию*/
//   availableCount: number;
//   /** сколько сделал выстрелов в раунде */
//   progress: number;
//   totalWin: number;
//   status: RoundStatusEnum;
//   case: ICaseRevision;
//   rewards: RoundReward[];
// }
////////////////////////////////////
//NOTE: это тестовый вариант типов

/**
 - available доступен раунд
 - blocked предыдущий еще не пройден
 - completed когда прошел раунд (10 выстрелов)
 */
export enum StatusEnum {
  COMPLETED = 'completed',
  //for super prize
  AVAILABLE = 'available',
  BLOCKED = 'blocked',
  TAKEN = 'taken',
}

export type RoundType = 0 | 1 | 2 | 3;

export interface NavigateItem {
  round: RoundType;
  isSuperPrize: boolean;
  isActive: boolean;
  image: string;
  status: StatusEnum;
  shots: number;
  maxShots: number;
}
export interface Reward {
  /** инфа об открытом подарке openUUID где-то тут должен быть.*/
  caseRevisionResult?: ICaseRevisionItem;
  /** модель возможно не точно такая, приходит если приз забран или доступен для забора. Если кейс открыт то не придет*/
  // case?: SuitCaseData;\

  entityId: number;
  questId: number;
  // status: CasesQuestStatusEnum;
}

export interface IMotivatorItem {
  inventoryItem: {
    id: number;
    baseItem: IBaseItemDto;
  };
  user: IUserShort;
}
export interface IMotivatorList {
  last: IMotivatorDto<IMotivatorItem>[];
}
// export interface RoundReward {
//   index: number;
//   opened: boolean;
//   icon?: string;
//   price?: number;
// }

export interface ResponseEventData {
  id: number;
  endAt: string;
  ranges: Range[];
}

export interface Range {
  items: IInventoryItemV2[];
  // в последнем 1 в других 10
  maxShots: number;
  images: {
    bullet: string;
    weapon: string;
    target: string;
  };
  progress: Progress[];
  // only last
  suitcase?: ICaseItemDtoV2;
  shots: number;
  status: StatusEnum;
  minWalletTypes: EventWallet[];
  revisionResult?: ICaseOpenPrizeItemDto;
}
export interface RangeClient {
  // 0-3 only front
  id: RoundType;
  items: IInventoryItemV2[];
  // в последнем 1 в других 10
  maxShots: number;
  //optional only front
  images?: {
    bullet: string;
    weapon: string;
    target: string;
  };
  progress: Target[];
  // only last
  suitcase?: ICaseItemDtoV2;
  shots: number;
  status: StatusEnum;
  minWalletTypes: EventWallet[];
  revisionResult?: ICaseOpenPrizeItemDto;
}

export interface EventWallet {
  // 500|501
  id: WalletTypeEnum;
  // в центах
  amount: number;
}

export interface Progress {
  idx: number;
  inventoryItem: IInventoryItemV2;
  loose?: boolean;
}
export interface Target extends Partial<Progress> {
  icon: string;
}
