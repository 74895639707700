<div class="p2p-modal-container">
  <div class="tw-flex tw-justify-between tw-pb-6">
    <ng-container *ngIf="type === 'group'; else singleItemHeader">
      <div class="p2p-modal-group-header tw-font-medium color-white">
        {{ 'P2P_WIDGETS.RENEW_INFO_MODAL.SET' | translate }} {{ marketData.items.length }}
        {{ 'P2P_WIDGETS.RENEW_INFO_MODAL.ITEMS' | translate }}
      </div>
    </ng-container>
    <ng-template #singleItemHeader>
      <div
        class="p2p-modal-single-header tw-flex tw-flex-col tw-gap-1"
        *ngIf="selectedItem"
      >
        <div
          *ngIf="selectedItem.baseItem.weapon"
          class="single-header-description tw-font-normal"
        >
          {{ 'P2P_SETTINGS.FILTERS.' + selectedItem.baseItem.weapon.replace(' ', '_') | uppercase | translate }}
        </div>
        <div class="single-header-label tw-font-medium">{{ selectedItem.baseItem.name }}</div>
      </div>
    </ng-template>
    <div
      class="close-btn tw-cursor-pointer"
      (click)="close()"
    >
      <mat-icon svgIcon="close"></mat-icon>
    </div>
  </div>

  <div class="p2p-modal-content tw-flex">
    <div class="p2p-modal-content-left tw-flex tw-flex-col tw-gap-3 tw-pr-6">
      <ng-container *ngIf="type === 'group'">
        <div>{{ 'P2P_WIDGETS.RENEW_INFO_MODAL.YOU_GET' | translate }}</div>
        <ng-scrollbar class="p2p-modal-itemlist hide-scroll">
          <ng-container *ngIf="marketData">
            <app-ui-p2p-lot-card
              *ngFor="let item of marketData.items"
              [items]="[item]"
              class="p2p-modal-item mb-1"
              [class.active]="selectedItem === item"
              (click)="selectItem(item)"
            />
          </ng-container>
        </ng-scrollbar>
      </ng-container>
      <ng-container *ngIf="type !== 'group' && selectedItem">
        <app-p2p-skin-image
          class="tw-w-full"
          [img]="itemLargeImgHash"
          [stickers]="selectedItem.stickers"
        />
        <div class="tw-flex tw-flex-col tw-gap-1">
          <app-ui-float-line [float]="selectedItem.float" />
          <app-ui-float-indicator
            class="skinFloatNumber tw-font-normal"
            [item]="selectedItem"
          />
        </div>
      </ng-container>
      <div class="tw-flex tw-flex-col tw-gap-2 tw-pt-3 border-top">
        <ng-container *ngIf="marketData; else steamPriceTemplate">
          <div class="tw-flex tw-justify-between">
            {{ marketPriceStatusLocale | translate }}
            <app-currency
              class="p2p-modal-value color-white tw-font-medium"
              [value]="matDialogDataInjection.price"
            />
          </div>
        </ng-container>
        <ng-template #steamPriceTemplate>
          <div class="tw-flex tw-justify-between">
            <ng-container *ngIf="steamData.price > 0; else notForSaleTemplate">
              {{ 'P2P_WIDGETS.RENEW_INFO_MODAL.PRICE' | translate }}
              <app-currency
                class="p2p-modal-value color-white tw-font-medium"
                [value]="steamData.price"
              />
            </ng-container>
            <ng-template #notForSaleTemplate>
              {{ 'P2P_WIDGETS.RENEW_INFO_MODAL.NOT_FOR_SALE' | translate }}
            </ng-template>
          </div>
        </ng-template>
      </div>
      <div class="tw-pt-3 border-top">
        <app-p2p-action-btn
          [lotItem]="matDialogDataInjection"
          [isAuth]="isAuth$ | async"
          [offerTimer]="offerTimer$ | async"
          [permissions]="permissions$ | async"
          (isLogin)="login()"
          (isPlaceBid)="placeBid()"
          (isSetupSteam)="setupSteam()"
          (isSelectSteamItem)="selectItemToSell()"
        />
      </div>
    </div>
    <div class="p2p-modal-content-right tw-flex tw-flex-col tw-w-full tw-pl-6">
      <div
        *ngIf="type === 'group'"
        class="p2p-modal-item-preview"
      >
        <ng-container *ngIf="selectedItem">
          <div *ngIf="selectedItem.baseItem.weapon">
            {{ 'P2P_SETTINGS.FILTERS.' + selectedItem.baseItem.weapon.replace(' ', '_') | uppercase | translate }}
          </div>
          <div class="p2p-modal-preview-name">{{ selectedItem.baseItem.name }}</div>
          <app-p2p-skin-image
            class="tw-w-full"
            [img]="itemLargeImgHash"
            [stickers]="selectedItem.stickers"
          />
          <div class="tw-flex tw-flex-col tw-gap-1">
            <app-ui-float-line [float]="selectedItem.float" />
            <app-ui-float-indicator
              class="skinFloatNumber tw-font-normal"
              [item]="selectedItem"
            />
          </div>
        </ng-container>
      </div>
      <app-ui-sih-stats
        class="p2p-modal-content__sih-stats"
        *ngIf="{
          data: sihDataset$ | async,
          action: sihDataRequestState$ | myAsync
        } as sih"
        [item]="selectedItem"
        [sihDataset]="sih.data"
        [isError]="sih.action && sih.action.status === actionStatusEnum.Errored"
        [isLoading]="!sih.data || !sih.action || sih.action.status === actionStatusEnum.Dispatched"
      ></app-ui-sih-stats>
    </div>
  </div>
</div>
