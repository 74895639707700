import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, NgModule } from '@angular/core';

@Component({
  selector: 'app-ui-snack-bar',
  templateUrl: './snack-bar.component.html',
  styleUrls: ['./snack-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SnackBarComponent {}

@NgModule({
  imports: [CommonModule],
  declarations: [SnackBarComponent],
  exports: [SnackBarComponent],
})
export class SnackBarComponentModule {}
