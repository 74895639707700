import { ChangeDetectionStrategy, Component, inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  ActionStatusEnum,
  IMarketplaceKitData,
  IMarketplaceKitItem,
  ISihDataset,
  ISteamStoreInventory,
  IUserP2pPermissionInterface,
  ModalNames,
  // MAX_ACCEPTABLE_RECOMMENDED_PRICE,
  P2pModalType,
  SteamErrorsEnum,
} from '@dev-fast/types';
import { Actions } from '@ngxs/store';
import { filter, interval, map, Observable, of, startWith, switchMap, take, takeUntil, timer } from 'rxjs';

import { GetItemSihStats } from '@app/core/state/p2p';

import { P2pBuyingService, P2pMarketService, P2PModalService } from '../../services';

@Component({
  selector: 'app-p2p-market-modal',
  templateUrl: './p2p-market-modal.component.html',
  styleUrls: ['./p2p-market-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class P2pMarketModalComponent implements OnInit, OnDestroy {
  readonly #dialogRef = inject(MatDialogRef<P2pMarketModalComponent>);
  readonly #p2pBuyingService = inject(P2pBuyingService);
  readonly #p2pMarketService = inject(P2pMarketService);
  readonly #p2pModalService = inject(P2PModalService);
  readonly #actions$ = inject(Actions);
  readonly matDialogDataInjection: IMarketplaceKitData | ISteamStoreInventory = inject(MAT_DIALOG_DATA);

  offerTimer$: Observable<number> | undefined;
  readonly items$: Observable<IMarketplaceKitData[]> = this.#p2pBuyingService.purchasing$;
  readonly isAuth$: Observable<boolean> = this.#p2pMarketService.isAuth$;
  readonly permissions$: Observable<IUserP2pPermissionInterface> = this.#p2pMarketService.p2pPermissions$;
  readonly sihDataset$: Observable<ISihDataset> = this.#p2pMarketService.sihDataset$;
  readonly sihDataRequestState$ = this.#actions$.pipe(
    filter((action) => {
      return action.action instanceof GetItemSihStats;
    }),
  );
  readonly actionStatusEnum = ActionStatusEnum;
  // Template
  marketPriceStatusLocale = '';
  itemLargeImgHash = '';
  marketData!: IMarketplaceKitData;
  steamData!: ISteamStoreInventory;
  type: P2pModalType = P2pModalType.single;
  selectedItem?: IMarketplaceKitItem | ISteamStoreInventory;

  readonly steamErrors: typeof SteamErrorsEnum = SteamErrorsEnum;

  ngOnInit(): void {
    if ('items' in this.matDialogDataInjection) {
      this.marketData = this.matDialogDataInjection;
      this.type = this.marketData.items.length > 1 ? P2pModalType.group : P2pModalType.single;
      this.selectedItem = this.marketData.items[0];
      this.#p2pMarketService.getItemSihStats(this.selectedItem.baseItem.name);
      this.marketPriceStatusLocale = this.#formatMarketPriceStatusLocale(this.marketData);
      this.itemLargeImgHash = this.#getItemImgHash(this.selectedItem);

      this.offerTimer$ = of(this.marketData).pipe(
        filter(({ nextStatusAt }) => Date.parse(nextStatusAt) > Date.now()),
        switchMap(({ nextStatusAt }) =>
          interval(1000).pipe(
            startWith(Date.parse(nextStatusAt) - Date.now()),
            map(() => Date.parse(nextStatusAt) - Date.now()),
            takeUntil(timer(Date.parse(nextStatusAt) - Date.now())),
          ),
        ),
      );
    } else {
      this.type = P2pModalType.single;
      this.steamData = this.matDialogDataInjection;
      this.selectedItem = this.matDialogDataInjection;
      this.itemLargeImgHash = this.#getItemImgHash(this.selectedItem);
      this.#p2pMarketService.getItemSihStats(this.selectedItem.baseItem.name);
    }
  }

  selectItem(item: IMarketplaceKitItem): void {
    this.selectedItem = item;
    this.itemLargeImgHash = this.#getItemImgHash(this.selectedItem);
    this.#p2pMarketService.getItemSihStats(this.selectedItem.baseItem.name);
  }

  login(): void {
    this.#dialogRef.close();
    this.#p2pModalService.openAuthModal();
  }

  placeBid(): void {
    let items: IMarketplaceKitData[] = [];
    this.#p2pBuyingService.purchasing$.pipe(take(1)).subscribe((val) => (items = val));
    if (this.marketData.isLastBidByUser && !this.marketData.id) {
      return;
    }
    if (!items.some((it) => it.id === this.marketData.id)) {
      this.#p2pBuyingService.toggleSelected(this.marketData);
    }
    this.#p2pBuyingService.createBid(this.marketData.id);
    this.#dialogRef.close();
  }

  selectItemToSell(): void {
    this.#p2pMarketService.toggleDepositItem(this.steamData);
    this.#dialogRef.close();
  }
  setupSteam(): void {
    this.#dialogRef.close();
    this.#p2pModalService.openModal(ModalNames.P2P_SETUP);
  }
  close(): void {
    this.#dialogRef.close();
  }

  #formatMarketPriceStatusLocale(marketItem: IMarketplaceKitData): string {
    if (marketItem.isUserSeller) {
      return 'P2P_WIDGETS.RENEW_INFO_MODAL.PRICE';
    } else if (marketItem.isLastBidByUser) {
      return 'P2P_WIDGETS.PURCHASE.YOUR_OFFER';
    }
    return 'P2P_WIDGETS.PURCHASE.CURRENT_OFFER';
  }
  #getItemImgHash(item: ISteamStoreInventory | IMarketplaceKitItem): string {
    return item.baseItem.weapon === 'other' && item.baseItem.iconLarge ? item.baseItem.iconLarge : item.baseItem.icon;
  }
  ngOnDestroy(): void {
    this.#p2pMarketService.resetSihStat();
  }
}
