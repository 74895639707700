import { CommonModule } from '@angular/common';
import { Component, EventEmitter, HostListener, Input, NgModule, OnDestroy, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { IInventoryItem, IInventoryRequestParams, SkinClickEvent, SubPanel } from '@dev-fast/types';
import { TranslateModule } from '@ngx-translate/core';
import { NgScrollbar, NgScrollbarModule } from 'ngx-scrollbar';
import { NgScrollbarReachedModule } from 'ngx-scrollbar/reached-event';
import { debounceTime, Subject, takeUntil } from 'rxjs';

import { AppCurrencyModule } from '@app/shared/pipes';

import { SkinItemModule } from '../skin-item/skin-item.component';

@Component({
  selector: 'app-ui-inventory-widget',
  templateUrl: './inventory-widget.component.html',
  styleUrls: ['./inventory-widget.component.scss'],
})
export class InventoryWidgetComponent implements OnDestroy {
  @ViewChild(NgScrollbar) public scrollbarRef: NgScrollbar | undefined;
  @Input() public totalBalance: number;
  @Input() public inventoryList: IInventoryItem[] | null;
  @Input() public selectedItems: IInventoryItem[];
  @Input() public subPanel: SubPanel;
  @Input() public gameInProgress: boolean | null;
  @Input() public set params(params: IInventoryRequestParams) {
    if (params) {
      this.form.patchValue({ ...params }, { emitEvent: false });
    }
  }

  @Output() public paramsEmit: EventEmitter<IInventoryRequestParams> = new EventEmitter<IInventoryRequestParams>();
  @Output() public withdrawEmit: EventEmitter<void> = new EventEmitter<void>();
  @Output() public reloadPriceEmit: EventEmitter<void> = new EventEmitter<void>();
  @Output() public toggleExchangePanel: EventEmitter<void> = new EventEmitter<void>();
  @Output() public closeSubPanel: EventEmitter<void> = new EventEmitter<void>();
  @Output() public clickOnSkinEmit: EventEmitter<SkinClickEvent> = new EventEmitter<SkinClickEvent>();
  @Output() public sellAll: EventEmitter<void> = new EventEmitter<void>();

  public form: FormGroup;
  private destroy$: Subject<void>;
  public subPanels = SubPanel;
  public isInventoryAvailable = window.innerWidth >= 630;
  public showNotice = !this.isInventoryAvailable;
  @HostListener('window:resize')
  public onResize(): void {
    this.isInventoryAvailable = window.innerWidth >= 630;
    this.showNotice = !this.isInventoryAvailable;
    if (window.innerWidth < 630) this.onCloseSubPanel();
  }

  constructor(private readonly fb: FormBuilder) {
    this.totalBalance = 0;
    this.inventoryList = [];
    this.selectedItems = [];
    this.subPanel = SubPanel.NONE;
    this.gameInProgress = false;
    this.destroy$ = new Subject();
    this.form = this.fb.group({
      query: '',
      sortBy: [true],
      page: [1],
      minPrice: null,
      maxPrice: null,
      pageSize: 50,
    });
    this.form.valueChanges.pipe(takeUntil(this.destroy$), debounceTime(100)).subscribe((val) => {
      this.paramsEmit.emit(val);
      this.scrollToTop();
    });
  }

  public scrollToTop(): void {
    if (this.scrollbarRef) {
      this.scrollbarRef.scrollTo({ top: 0 });
    }
  }
  public sortPrice(): void {
    this.paramsEmit.emit(this.form.value);
  }

  public trackingFunction(index: number, item: IInventoryItem): number {
    return item.id;
  }
  public onRefresh(): void {
    this.form.patchValue(this.form.value);
  }
  public onCloseSubPanel(): void {
    this.closeSubPanel.emit();
  }
  public onToggleExchangePanel(): void {
    if (this.isInventoryAvailable) {
      this.toggleExchangePanel.emit();
    } else {
      this.showNotice = !this.showNotice;
    }
  }

  public onSellItems(): void {
    this.sellAll.emit();
  }
  public clickOnSkin(event: SkinClickEvent): void {
    this.clickOnSkinEmit.emit(event);
  }

  public isSelected(id: number): boolean {
    return this.selectedItems.some((item) => item.id === id);
  }
  public onBottomReached(): void {
    this.paramsEmit.emit({ ...this.form.value, page: this.form.value.page && this.form.value.page + 1 });
  }
  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}

@NgModule({
  imports: [
    CommonModule,
    MatIconModule,
    TranslateModule,
    SkinItemModule,
    AppCurrencyModule,
    ReactiveFormsModule,
    NgScrollbarModule,
    NgScrollbarReachedModule,
  ],
  declarations: [InventoryWidgetComponent],
  exports: [InventoryWidgetComponent],
})
export class InventoryWidgetComponentModule {}
