<ng-container *ngIf="$any(ngControl.control) as acceptControl">
  <div
    class="accept__wrapper"
    [@bellAnimation]="animTrigger()"
  >
    <ng-container *ngIf="labels.length">
      <div class="accept__text">
        <ng-container *ngFor="let label of labels">
          <ng-container *ngIf="label.href; else labelTemplate">
            <a
              langRouterLink
              [routerLink]="label.href"
              class="accept__link"
              [target]="label.target"
            >
              {{ label.title | translate }}
            </a>
          </ng-container>
          <ng-template #labelTemplate>
            {{ label.title | translate }}
          </ng-template>
        </ng-container>
      </div>
    </ng-container>
    <ng-container *ngIf="innerLabels">
      <div
        class="accept__text"
        [innerHTML]="innerLabels"
      ></div>
    </ng-container>
    <button
      class="btn"
      [class]="btnClass"
      (click)="acceptControl.patchValue(!acceptControl.value)"
      [disabled]="acceptControl.valid && acceptedDisable"
    >
      {{ acceptText | translate }}
    </button>
  </div>
</ng-container>
