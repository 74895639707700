import { Injectable } from '@angular/core';
import {
  EmailSubscribe,
  IChatAccess,
  IGameStatistics,
  IPublicUserDetailed,
  IUser,
  IUserDetailed,
  IUserExperience,
  IUserP2pPermissionInterface,
  IUserSettings,
  IUserTradeStats,
  IUserWallet,
  IUserWalletWsResponse,
  MAIN_LANG,
  Privacy,
  TradeLinks,
  UserCountry,
} from '@dev-fast/types';
import { Socket } from 'ngx-socket-io';
// FIXME сокеты
// import { Socket } from 'ngx-socket-io';
import { Observable } from 'rxjs';

import { Environments, EnvironmentService } from '@app/core/environment-service';

import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class UserApiService {
  private environment: Environments;
  private endpoint = 'api/users';
  //TODO убрать any везде
  constructor(
    private readonly api: ApiService,
    private readonly environmentService: EnvironmentService,
    private readonly ws: Socket,
  ) {
    this.environment = this.environmentService.environments;
    // console.log('userService');
    // this.ws.emit('message', 'msg');

    // this.ws.on('user.coinsAmount.updated', (coinsAmount: number) => {
    //   console.log(coinsAmount);
    // });
  }

  listenCoinsAmount(): Observable<IUserWalletWsResponse> {
    // return this.ws.fromEvent<number>('user.coinsAmount.updated');
    return this.ws.fromEvent<IUserWalletWsResponse>('user.walletCoinsAmount.updated');
  }
  savePrivacy(payload: Privacy): Observable<any> {
    return this.api.patch<any>(`${this.environment.GATEWAY_URL}/${this.endpoint}/me/privacy`, payload, {
      withCredentials: true,
    });
  }

  updateTradeLinks(links: TradeLinks): Observable<any> {
    return this.api.patch<any>(`${this.environment.GATEWAY_URL}/${this.endpoint}/me/links`, links, {
      withCredentials: true,
    });
  }

  updateApiKey(key: string): Observable<any> {
    return this.api.patch(`${this.environment.GATEWAY_URL}/${this.endpoint}/me/steam-api-key`, { key }, { withCredentials: true });
  }

  patchUserData(data: Partial<IUserDetailed>): Observable<void> {
    return this.api.patch<void>(`${this.environment.GATEWAY_URL}/${this.endpoint}/me`, data, { withCredentials: true });
  }
  getMeSettings(): Observable<IUserSettings> {
    return this.api.get<IUserSettings>(`${this.environment.GATEWAY_URL}/${this.endpoint}/me/settings`, {
      withCredentials: true,
    });
  }
  patchUserSettings(data: IUserSettings): Observable<IUserSettings> {
    return this.api.patch<IUserSettings>(`${this.environment.GATEWAY_URL}/${this.endpoint}/me/settings`, data, {
      withCredentials: true,
    });
  }

  updateUserAvatar(file: Blob): Observable<{ avatar: string }> {
    const formData = new FormData();
    formData.append('file', file);
    return this.api.put<{ avatar: string }>(`${this.environment.GATEWAY_URL}/${this.endpoint}/me/avatar`, formData, {
      withCredentials: true,
    });
  }

  getMeProfile(params: object): Observable<IUserDetailed> {
    return this.api.get<IUserDetailed>(`${this.environment.GATEWAY_URL}/${this.endpoint}/me`, {
      withCredentials: true,
      params,
    });
  }
  getMeExperience(): Observable<IUserExperience> {
    return this.api.get<IUserExperience>(`${this.environment.GATEWAY_URL}/${this.endpoint}/me/experience`, {
      withCredentials: true,
    });
  }
  getMyBalance(): Observable<IUserWallet[]> {
    return this.api.get<IUserWallet[]>(`${this.environment.GATEWAY_URL}/api/wallet/my`, {
      withCredentials: true,
    });
  }

  demandSelfExclusion(duration: number): Observable<void> {
    return this.api.post(
      `${this.environment.GATEWAY_URL}/${this.endpoint}/me/ban`,
      { duration, domain: this.environment.PROJECT },
      { withCredentials: true },
    );
  }

  // FIXME возвращаемый тип подправить
  getUserProfile<T extends IUser | IPublicUserDetailed>(id: number, params: object): Observable<T> {
    return this.api.get<T>(`${this.environment.GATEWAY_URL}/${this.endpoint}/${id}`, {
      withCredentials: true,
      params,
    });
  }
  getUserCountry = (): Observable<UserCountry> => this.api.get(`${this.environment.GATEWAY_URL}/api/country`);

  getP2pPermissions = (): Observable<IUserP2pPermissionInterface> =>
    this.api.get(`${this.environment.GATEWAY_URL}/api/market/steam/check-user`, { withCredentials: true });

  getUserTradeStats(): Observable<IUserTradeStats> {
    return this.api.get(`${this.environment.GATEWAY_URL}/api/market/my/rating`, { withCredentials: true });
  }

  getUserStatistics(id: string | number): Observable<IGameStatistics[]> {
    return this.api.get<IGameStatistics[]>(`${this.environment.GATEWAY_URL}/${this.endpoint}/${id}/statistics`, {
      withCredentials: true,
    });
  }

  checkCanChat(): Observable<IChatAccess> {
    return this.api.get<IChatAccess>(`${this.environment.GATEWAY_URL}/${this.endpoint}/me/chat-access`, {
      withCredentials: true,
    });
  }

  switchWalletType(): Observable<void> {
    return this.api.post<void>(`${this.environment.GATEWAY_URL}/${this.endpoint}/me/balance/switch`, {}, { withCredentials: true });
  }

  unsubscribeFromEmails(address: string): Observable<EmailSubscribe> {
    return this.api.post(
      `${this.environment.GATEWAY_URL}/${this.endpoint}/me/email/unsubscribe`,
      { address, domain: this.environment.PROJECT },
      { withCredentials: true },
    );
  }

  patchEmail(params: { address?: string; isSubscribed?: boolean; domain?: string } = {}): Observable<EmailSubscribe> {
    return this.api.patch(
      `${this.environment.GATEWAY_URL}/${this.endpoint}/me/email`,
      // FIXME это костыль, потому что бек не может добавить csgofast-new у себя в рассылку
      { ...params, lang: MAIN_LANG, domain: this.environment.PROJECT === 'csgofast-new' ? 'csgofast.com' : this.environment.PROJECT },
      { withCredentials: true },
    );
  }

  confirmEmail(code: number): Observable<EmailSubscribe> {
    return this.api.post(`${this.environment.GATEWAY_URL}/${this.endpoint}/me/email/confirm`, { code }, { withCredentials: true });
  }
}
