import { Injectable } from '@angular/core';
import { FooterCounts, MarketCounts } from '@dev-fast/types';
import { Socket as WrappedSocket } from 'ngx-socket-io';

@Injectable({
  providedIn: 'root',
})
export class FooterService {
  constructor(private readonly ws: WrappedSocket) {}

  online(callback: (payload: number) => void): void {
    this.ws.on('online', callback);
  }
  counts(callback: (payload: FooterCounts) => void): void {
    this.ws.on('motivator.counts', callback);
  }
  marketCounts(callback: (payload: MarketCounts) => void): void {
    this.ws.on('motivator.market.counts', callback);
  }
}
