import { IconConfig } from '@dev-fast/types';

export const iconsAccount: IconConfig[] = [
  {
    name: 'status-silver',
    path: 'assets/img/mat-icons/account/silver.svg',
  },
  {
    name: 'status-bronze',
    path: 'assets/img/mat-icons/account/bronze.svg',
  },
  {
    name: 'status-gold',
    path: 'assets/img/mat-icons/account/gold.svg',
  },
  {
    name: 'status-platinum',
    path: 'assets/img/mat-icons/account/platinum.svg',
  },
  {
    name: 'status-diamond',
    path: 'assets/img/mat-icons/account/diamond.svg',
  },
];
