import { Component, ElementRef, HostListener, Inject, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { MAIN_LANG } from '@dev-fast/types';
import { Observable } from 'rxjs';

import { EnvironmentService } from '@app/core/environment-service';
import { LanguageService } from '@app/core/language-service';
import { ISeoData, SeoService } from '@app/core/seo-service';
import { SoundService } from '@app/core/sound-service';
import { FeatureFlagsService, IBBConfig } from '@app/feature-flags';
import { IS_SERVER_TOKEN } from '@app/shared/utils';

import { FrameMessage, FrameMessageTypes } from './iframe.model';
import { IFrameCommonService } from './services/common.service';
import { IFrameMessageService } from './services/message.service';

@Component({
  selector: 'app-iframe',
  templateUrl: './iframe.component.html',
  styleUrls: ['./iframe.component.scss'],
})
export class IframeComponent implements OnInit {
  private soundStatus: boolean;
  readonly seoData$: Observable<ISeoData | null> = this.seoService.seoData$;

  get showOtherComponent(): boolean | undefined {
    return this._showOtherComponent;
  }

  set showOtherComponent(value: boolean | undefined) {
    if (value && value !== this._showOtherComponent) {
      //FIXME  костыль сраный на скорую руку
      setTimeout(() => this.#calcFrameScale(), 500);
    }
    this._showOtherComponent = value;
  }

  @ViewChild('iframe', { static: false })
  iframe!: ElementRef<HTMLIFrameElement>;
  @ViewChild('iframeWrapper', { static: false })
  iframeWrapper!: ElementRef<HTMLDivElement>;
  @Input() excludesRoutes: string[];

  url = 'https://bridge.csgofast.com';
  urlSafe: SafeResourceUrl | undefined;
  private _showOtherComponent: boolean | undefined;
  showIframe = false;
  environment = this.environmentService.environments;
  readonly hostName = this.environment.HOSTNAME;
  excludesRoutesFrame: string[];
  legacyRoutes: string[];
  routesToBB: string[];
  showLoader: boolean;
  private readonly isProduction: boolean;
  private timerId: any;
  private bbConfig: IBBConfig;

  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    this.#calcFrameScale();
  }

  @HostListener('window:message', ['$event'])
  message(event: MessageEvent<FrameMessage>): void {
    if (event.origin === this.url) {
      if (event.data.type === FrameMessageTypes.MESSAGE_FROM_BB && event.data.eventName === 'inited') {
        this.messageService.setInited = true;
        this.commonService.setFrameStatus(true);
      }

      if (event.data.type === FrameMessageTypes.ROUTING_FROM_BB) {
        const [initRoute] = this.router.url.split('?');
        const [path] = event.data.payload['path'].split('?');
        const baseInitRoute = LanguageService.getBaseUrl(initRoute);
        const matcher = (): string | undefined => {
          const match = this.bbConfig.MatchedRoutesFromBB[path];
          const specialMatch = this.bbConfig.SpecialPartRoutesFrmBB.some((el) => path.includes(el));
          return match ? match : specialMatch ? path : undefined;
        };
        const match = matcher();
        const outlet = event.data.payload['outlet'];
        if (baseInitRoute !== path && ((!this.isExcludedRoute(path) && !this.isExcludedRoute(baseInitRoute)) || match)) {
          this.router.navigate([match ? (outlet ? { outlets: { modal: ['transaction', match] } } : match) : path]);
        }
      }
      if (event.data.type === FrameMessageTypes.MESSAGE_FROM_BB_SOCKET) {
        this.messageService.trigerEvent(FrameMessageTypes.MESSAGE_FROM_BB_SOCKET, event.data.eventName, event.data.payload);
      }
      if (event.data.type === FrameMessageTypes.MESSAGE_FROM_BB) {
        this.messageService.trigerEvent(FrameMessageTypes.MESSAGE_FROM_BB, event.data.eventName, event.data.payload);
      }
      if (!this.isProduction && event.data.eventName !== 'change.gameStatus') {
        console.log(event.data);
      }
    }
  }

  constructor(
    @Inject(IS_SERVER_TOKEN) public isServer: boolean,
    private readonly messageService: IFrameMessageService,
    private readonly commonService: IFrameCommonService,
    private readonly environmentService: EnvironmentService,
    private readonly soundService: SoundService,
    private readonly languageService: LanguageService,
    private readonly renderer: Renderer2,
    readonly sanitizer: DomSanitizer,
    private readonly seoService: SeoService,
    private router: Router,
    private featureFlagsService: FeatureFlagsService,
  ) {
    this.bbConfig = this.featureFlagsService.getBBConfig();
    // TODO было бы круто всё, что тут в конструкторе упростить и раскидать по методам
    this.routesToBB = [...Object.keys(this.bbConfig.SpecialGameRoutesToBB), ...Object.keys(this.bbConfig.MatchedRoutesToBB)];
    this.isProduction = this.environmentService.environments.production;
    this.environment = this.environmentService.environments;
    this.excludesRoutesFrame = this.bbConfig.excludesRoutesFrame;
    this.legacyRoutes = this.bbConfig.legacyRoutes;
    this.soundStatus = false;
    this.showLoader = true;
    this.excludesRoutes = this.bbConfig.excludesRoutesHome.concat(
      ...Object.keys(LanguageService.getRouteLanguages()).reduce((routes: string[], lang) => {
        return routes.concat(...this.bbConfig.excludesRoutesHome.map((route) => (lang === MAIN_LANG ? route : `/${lang + route}`)));
      }, []),
    );

    if (!this.isServer) {
      this.soundService.status$.subscribe((value) => {
        this.soundStatus = value;
        this.#toggleSoundInFrame(value);
      });
      const defaultBBUrl = this.environmentService.environments.BACKBONE_URL;
      const getLocalBBHost = (): string => {
        const urlArr = this.hostName.split(':');
        urlArr.splice(1, 1, '1616');
        return `https://${urlArr.join(':')}`;
      };
      this.url = this.hostName.includes('local') && !this.environment.BACKBONE_URL ? getLocalBBHost() : defaultBBUrl || getLocalBBHost();
      this.setSafeUrl(`${this.url}`);
    }

    this.#showRouter();
  }

  ngOnInit(): void {
    const initRoute = LanguageService.getBaseUrl(this.router.url.split('?')[0]);
    this.setSafeUrl(`${this.url}${initRoute}`);
    this.showIframe = !this.excludesRoutes.some((route) => initRoute === route) && this.isLegacyRoute(initRoute) && initRoute !== '/';
    this.showOtherComponent =
      !this.excludesRoutes.some((route) => initRoute.startsWith(route)) && this.isLegacyRoute(initRoute) && initRoute !== '/';
  }

  #showRouter(): void {
    this.router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {
        const baseUrl = LanguageService.getBaseUrl(event.url);
        if (!this.isServer && this.iframe && this.iframe.nativeElement.contentWindow) {
          if (!this.isExcludedRoute(baseUrl)) {
            if (this.timerId) {
              clearTimeout(this.timerId);
            }
            this.timerId = setTimeout(() => this.navigateTo(baseUrl), 300);
            this.#toggleSoundInFrame(this.soundStatus);
          } else {
            this.#toggleSoundInFrame(false);
          }
        }
        if (this.featureFlagsService.featureFlags['sides-fade']) {
          const showIframe = !this.isExcludedRoute(baseUrl) && this.isLegacyRoute(baseUrl);
          // Если у нас ранее был фрейм, но более нет, то отправляем событие об закрытии фрейма
          if (!this.showIframe && showIframe) {
            this.showIframe = true;
            this.setSafeUrl(`${this.url}${baseUrl}`);
          } else if (this.showIframe) {
            this.commonService.setFrameStatus(showIframe);
          }
        } else {
          if (!this.showIframe) {
            this.showIframe = !this.isExcludedRoute(baseUrl) && this.isLegacyRoute(baseUrl);
            this.setSafeUrl(`${this.url}${baseUrl}`);
          }
        }

        this.showOtherComponent = !this.isExcludedRoute(baseUrl) && this.isLegacyRoute(baseUrl);
      }
    });
  }
  setSafeUrl = (url: string): void => {
    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(`${url}?uid=${new Date().getTime()}`);
  };
  isExcludedRoute(url: string): boolean {
    const excludesRoute = this.excludesRoutes.some((route) => url.split('?')[0].startsWith(route));
    return url === '/' || excludesRoute || this.excludesRoutesFrame.includes(url.split('?')[0]);
  }

  isLegacyRoute(url: string): boolean {
    return (
      this.legacyRoutes.some((route) => {
        let activeRoute = url.split('?')[0];
        let currentRoute = route;
        if (currentRoute.includes(':')) {
          const urlParts = activeRoute.split('/');
          const routeParts = currentRoute.split('/');
          activeRoute = urlParts.slice(0, urlParts.length - 1).join('/');
          currentRoute = routeParts.slice(0, routeParts.length - 1).join('/');
        }
        return activeRoute === '/' + currentRoute;
      }) || this.routesToBB.some((route) => url.split('?')[0] === route)
    );
  }

  navigateTo(url: string): void {
    if (this.bbConfig.SpecialGameRoutesToBB[url]) {
      this.messageService.sendMessage({
        type: FrameMessageTypes.ROUTING_TO_BB,
        eventName: 'specialGame',
        payload: { path: this.bbConfig.SpecialGameRoutesToBB[url] },
      });
      return;
    }
    if (url.includes('profile') || url.includes('/ref/') || url.includes('/fail-payment/') || url.includes('/success-payment/')) {
      this.messageService.sendMessage({
        type: FrameMessageTypes.ROUTING_TO_BB,
        eventName: 'routing',
        payload: { path: url },
      });
      return;
    }
    const route = this.bbConfig.MatchedRoutesToBB[url];
    if (route) {
      this.messageService.sendMessage({
        type: FrameMessageTypes.ROUTING_TO_BB,
        eventName: 'routing',
        payload: { path: route },
      });
      return;
    }
  }
  reloadFrameForce = (): void => {
    this.iframe.nativeElement.src = this.iframe.nativeElement.src += '';
  };

  frameLoaded(e: Event): void {
    if (!this.iframe) {
      return;
    }
    this.#calcFrameScale();
    this.messageService.target = this.iframe;
    this.showLoader = false;
  }

  frameRejected(event: ErrorEvent | string): void {
    if (typeof event === 'string') {
      throw new Error(event);
    }
    throw event;
  }

  #toggleSoundInFrame(status: boolean): void {
    this.messageService.sendMessage({
      type: FrameMessageTypes.MESSAGE_TO_BB,
      eventName: 'soundToggle',
      payload: { status },
    });
  }

  #calcFrameScale(): void {
    if (!this._showOtherComponent || this.isServer) {
      return;
    }
    const currentWidth = this.iframeWrapper.nativeElement.clientWidth;
    const scaleX = +(currentWidth / 900).toFixed(2);
    if (scaleX === 0) {
      return;
    }
    this.iframe.nativeElement.setAttribute(
      'style',
      `
      -ms-transform: scale(${scaleX <= 1 ? scaleX : 1});
      -moz-transform: scale(${scaleX <= 1 ? scaleX : 1});
     -o-transform: scale(${scaleX <= 1 ? scaleX : 1});
      -webkit-transform: scale(${scaleX <= 1 ? scaleX : 1});
      transform: scale(${scaleX <= 1 ? scaleX : 1});
    `,
    );
    this.renderer.setStyle(this.iframe.nativeElement, 'zoom', `${scaleX <= 1 ? scaleX : 1}`);
    this.renderer.setStyle(this.iframe.nativeElement, 'width', `${scaleX <= 1 ? 100 / scaleX : 100}%`);
    this.renderer.setStyle(this.iframe.nativeElement, 'height', `${scaleX <= 1 ? 100 / scaleX : 100}%`);
  }
}
