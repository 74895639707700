<!--<div class="chat__wrapper">-->
<div class="chat-body">
  <div class="chat-messages">
    <ng-container *ngFor="let message of messageList; trackBy: trackingFunction">
      <div class="chat-message">
        <!--      FIXME Прокинуть данные по сообщению и времени-->
        <div class="chat-message__avatar">
          <app-ui-user-avatar [url]="message.user.avatar"></app-ui-user-avatar>
        </div>
        <div class="chat-message__username">{{ message.user.name }}:</div>
        <div class="chat-message__text">{{ message.value }}</div>
        <div class="chat-message__time">{{ message.createdAt | date : 'HH:mm:ss' }}</div>
      </div>
    </ng-container>
  </div>
</div>
<div class="chat-footer">
  <!--  FIXME Пока не знаю как должно выглядеть нужно добавить контент и стилизовать-->
  <div class="chat-info-message">
    <span></span>
  </div>
  <div class="chat-ban">
    <span></span>
  </div>
  <div class="chat-not-auth"></div>
  <!--  FIXME Добавить ключ-->
  <div
    class="chat-not-play-any-game"
    style="display: none"
  >
    Only players who have played the game are allowed to write
  </div>
  <form
    [formGroup]="messageForm"
    class="message message__block"
  >
    <label class="message__wrapper">
      <input
        type="text"
        class="message__input"
        placeholder="Message..."
        formControlName="message"
      />
    </label>
    <div class="message__buttons">
      <button
        class="chat-footer__btn chat-footer__btn_sticker"
        (click)="onToggleStickerContainer()"
      >
        <mat-icon
          svgIcon="smile"
          class="chat-footer__btn_icon"
        ></mat-icon>
      </button>
      <button
        class="chat-footer__btn chat-footer__btn_send"
        (click)="sendMessage()"
      >
        <mat-icon
          svgIcon="telegram"
          class="chat-footer__btn_icon"
        ></mat-icon>
      </button>
    </div>
  </form>
</div>
<!--</div>-->
