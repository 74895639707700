import { Design, EmailStatus, ProfileTypes, Themes, WalletTypeEnum } from '../enums';
import { IPublicGameStatistic, Privacy, SocialItem, TradeLinks } from '../models';

export interface IUserShort {
  id: number;
  name: string;
  avatar: string;
  profileType?: ProfileTypes;
}
export interface IUserExperience {
  xp: number;
  level: number;
  nextLevelXp: number;
  chevron?: {
    name: string;
    url: string;
  };
  nextReward: {
    // TODO:LK бонус за следующий уровень. Загрушка
    type: string;
  };
}
export interface IUser {
  id: number;
  name: string;
  avatar: string;
  profileType: ProfileTypes;
  profileDateExpired: string; // TODO:LK свойство добавлено как заглушка
  activity?: {
    registered: Date;
    logged?: Date;
    lastSeen?: Date;
  };
  experience: IUserExperience;
  country?: string;
  refillCountry?: string;
  character?: UserCharacter;
  balance?: number;
  // leadersRace: {
  //   fast: any; // leaders-race-info
  //   great: any; // leaders-race-info
  // };
}

export interface IUserIdOnly {
  id: number;
}

export interface IEmailRequestParams {
  address?: string;
  isSubscribed?: boolean;
  domain?: string;
}

export enum UserCharacter {
  Terrorist = 't',
  CounterTerrorist = 'ct',
}

export interface IUserDetailed extends IUser {
  locale: string;
  theme?: Themes;
  //fixme попросить бэк добавить
  design?: Design;
  currency: string | null;
  isLocked: boolean;
  /** если стоит блок то тут стринга с причиной лока*/
  permLock?: string | null;
  /** @deprecated вместо это юзать такие штуки например: this.getWalletById(WalletTypeEnum.BALANCE) (есть в UserService) */
  coinsAmount: number;
  providers: SocialItem[];
  email: {
    status: EmailStatus;
    address: string;
    isSubscribed: boolean;
  };
  referral: {
    level: number;
    earnedSum: number;
    availableSum: number;
    count: number;
    code: string;
  };
  rewards: {
    freeSpins: number;
    blueCases: number;
    orangeCases: number;
    silverCases: number;
  };
  // работаем с полем additionalSettings стейта, там оно отдельно лежит
  privacy: Privacy;
  premium: {
    until: Date;
    goldLink: string;
  };
  links: TradeLinks;
  steamApiKey?: string;
  appId: number;
  steamAppId: number;
  walletType: 'demo' | 'real';
  demoCoinsAmount: number;
  market?: IUserTradeStats;
}
export interface IUserTradeStats {
  success: number;
  fail: number;
  rating: number;
}
export interface IPublicUserDetailed extends IUser {
  theme?: Themes;
  referral: {
    level: number;
    earnedSum: number;
    availableSum: number;
    count: number;
    code: string;
  };
  rewards: {
    freeSpins: number;
    blueCases: number;
    orangeCases: number;
    silverCases: number;
  };
  privacy: Privacy;
  providers: SocialItem[];
  premium: {
    until: Date;
    goldLink: string;
  };
  links: TradeLinks;
  statistics: IPublicGameStatistic[];
}
export type IUserSettingsDetailed = IUserSettings & {
  fastDelivery?: boolean;
};
export interface IUserSettings {
  locale?: string;
  theme?: Themes;
  currency?: string;
  refillCountry?: string;
  privacy?: Privacy;
  market?: {
    steamTradeLink?: string;
    steamApiKey?: string;
    steamAppId?: number;
    autoApprove?: boolean;
    tradesDuration?: number;
  };
}

export type IUserAvatarPosition = 'left' | 'right' | 'top' | 'bottom';

export interface IUserAvatarNickname {
  name: string;
  position: IUserAvatarPosition;
}

export interface IUserSelfExclusionData {
  address: string;
  domain: string;
  duration: number;
}
export interface IUserTradeInfo {
  user: IUserDetailed | null | undefined;
  steamLogin: boolean;
  steamTradeLink?: string;
  steamApiKey?: string;
}

export interface IUserWallet {
  balance: number;
  walletType: { id: WalletTypeEnum };
}
export interface IUserWalletWsResponse {
  amount: number;
  walletTypeId: WalletTypeEnum;
}
