import {
  BonusTransactionTypes,
  ITransactionDto,
  IWithdrawalHistory,
  TransactionNamespaces,
  TransactionOperations,
  WalletTransactionTypeId,
} from '@dev-fast/types';

export const mapFromWithdrawalHistoryToTransactionDto = (history: IWithdrawalHistory[]): ITransactionDto[] => {
  return history.map((item) => ({
    namespace: TransactionNamespaces.BALANCE,
    id: item.id,
    coinsAmount: item.points * -1,
    timestamp: item.date_added,
    type: BonusTransactionTypes.WITHDRAWAL,
    typeId: WalletTransactionTypeId.PAYOUT,
    operation: TransactionOperations.DEBIT,
    payment: {
      ...item.payment,
      system: item.system,
      status: item.state,
    },
  }));
};
