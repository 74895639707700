import { BreakpointsMaterial } from '../entities';

export const LAYOUT_NEW_TYPES: BreakpointsMaterial = {
  '(max-width: 480px)': 'xs',
  '(max-width: 768px)': 's',
  '(max-width: 1024px)': 'm',
  '(max-width: 1280px)': 'l',
  '(max-width: 1684px)': 'xl',
  '(max-width: 2024px)': 'xxl',
  '(min-width: 2024px)': 'xxxl',
};
