// что-то старое.
// TODO: найти концы и понять, нужно ли
export enum RefillTypes {
  BTC = 'bitcoin',
  WMZ = 'wmz',
  WMR = 'wmr',
  YANDEX = 'yandex',
  STEAM = 'steam',
  QIWI = 'qiwi',
  CARD = 'card',
}

// что-то старое.
// TODO: найти концы и понять, нужно ли
export enum RefillSystems {
  G2A = 'g2apay',
  SKRILL = 'skrill',
}
