import { Inject, Injectable, Optional } from '@angular/core';
import { PROJECT } from '@dev-fast/types';

import { ENVIRONMENTS, Environments, ENVIRONMENTS_DEFAULT_FAST } from './environment.model';

@Injectable({
  providedIn: 'root',
})
export class EnvironmentService {
  /** @deprecated Not use outside service, use instead getEnvironment() */
  readonly environments: Environments;

  constructor(@Optional() @Inject(ENVIRONMENTS) environments: Environments | null) {
    this.environments = environments ?? ENVIRONMENTS_DEFAULT_FAST;
    // TODO можно сделать что бы модули (настройки) брались при сборке и тогда возможно не придется разделять в ручную на несколько проектов
    // this.environments = {
    //   ...(environments ?? ENVIRONMENTS_DEFAULT_FAST),
    //   google: {
    //     key: process.env['GOOGLE_KEY'] ?? '',
    //     id: process.env['GOOGLE_ID'] ?? '',
    //     name: process.env['GOOGLE_NAME'] ?? '',
    //   },
    // };
  }

  getEnvironment(): Environments {
    return this.environments;
  }
  moduleIsOn(module: keyof typeof this.environments.MODULES, subModule?: string, block?: string): boolean {
    const moduleTmp: any = this.environments.MODULES[module];
    if (!moduleTmp) {
      return false;
    }
    if (Array.isArray(moduleTmp)) {
      return subModule ? moduleTmp.includes(subModule) : false;
    }
    if (subModule) {
      const arr = moduleTmp[subModule as keyof typeof moduleTmp];
      if (block && arr) {
        return arr.includes(block);
      }
      return !!arr;
    } else {
      return !!moduleTmp;
    }
  }
  gameIsOn(name: string): boolean {
    return this.environments.MODULES.games?.includes(name) || false;
  }

  isProject(projectData: { name: PROJECT | PROJECT[]; exclude?: boolean }): boolean {
    const projects = Array.isArray(projectData.name) ? projectData.name : [projectData.name];
    return projectData.exclude
      ? !projects.includes(this.environments.PROJECT as PROJECT)
      : projects.includes(this.environments.PROJECT as PROJECT);
  }
}
