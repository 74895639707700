<!-- TODO Заменить кусок с "предмет там-то там-то" на ng-template -->
<div
  *ngIf="lot"
  class="auction modal-wrapper"
  [ngClass]="templateTypeClass"
>
  <div class="auction-header flex fx-justify-between">
    <span class="tw-font-medium">{{ 'P2P_WIDGETS.AUCTION_COMPLETED_DIALOG.AUCTION_COMPLETED' | translate }}</span>
    <button
      class="btn-close p-0 m-0"
      (click)="onClose()"
    >
      <mat-icon svgIcon="close"> </mat-icon>
    </button>
  </div>
  <div class="flex fx-column-mobile gap-6 auction-main-content">
    <div class="auction__lot flex fx-column gap-6">
      <div class="w-100">
        <div class="text-sm text-muted mb-1">{{ 'P2P_WIDGETS.AUCTION_COMPLETED_DIALOG.YOU_WILL_GET' | translate }}</div>
        <div class="currency-gain container-dark w-100 tw-font-bold p-sm-3">{{ lot.sellerPrice | appCurrency }}</div>
      </div>
      <div>
        <div class="text-sm text-muted mb-1">{{ 'P2P_WIDGETS.AUCTION_COMPLETED_DIALOG.YOU_GIVING' | translate }}</div>
        <ng-scrollbar class="hide-scroll">
          <ng-container *ngFor="let lotItem of lot.items; trackBy: lotItemsTrackBy">
            <div
              class="card-item pointer"
              [class.active-item]="lotItem === currentSteamItem"
            >
              <app-ui-p2p-lot-card
                (click)="setActiveSteamItem(lotItem)"
                class="container-dark p-sm-3"
                [items]="[lotItem]"
              />
              <div class="flex fx-justify-between pt-xs-1 pl-sm-3 pr-sm-3 pb-sm-2">
                <span class="item-index fw-500">{{ lotItem.indicator }}</span>
                <div class="text-sm text-muted">
                  {{ lotItem.page }}
                  {{ 'P2P_WIDGETS.DEPOSITING.ITEM_LOCATION_PHRASE_1' | translate }}
                  {{ lotItem.line }}
                  {{ 'P2P_WIDGETS.DEPOSITING.ITEM_LOCATION_PHRASE_2' | translate }}
                  {{ lotItem.number }}
                </div>
              </div>
            </div>
          </ng-container>
        </ng-scrollbar>
      </div>

      <div class="divider-horizontal"></div>

      <div class="warning-banner text-sm">
        <div class="warning-banner__icon"><mat-icon svgIcon="danger-sign--design"></mat-icon></div>
        <div class="warning-banner__message">
          <div class="warning-banner__message-title">
            {{ 'P2P_WIDGETS.AUCTION_COMPLETED_DIALOG.ALERT_TITLE' | translate }}
          </div>
          <div class="warning-banner__message-body">
            {{ 'P2P_WIDGETS.AUCTION_COMPLETED_DIALOG.ALERT_DESCRIBE' | translate }}
          </div>
        </div>
      </div>

      <div class="auction__lot-buyer flex fx-justify-center fx-align-end text-sm">
        <mat-icon
          svgIcon="green-arrow--design"
          class="green-arrow"
        ></mat-icon>
        <mat-icon
          svgIcon="white-arrow--design"
          class="white-arrow"
        ></mat-icon>
        <div class="auction__lot-buyer-info container-dark flex fx-column gap-6 p-2">
          <div class="auction__lot-buyer-nickname flex fx-align-center gap-2">
            <app-ui-user-avatar [url]="lot.order.buyerSteamAvatar | replaceAvaHash"></app-ui-user-avatar>
            <div class="text-ellipsis">{{ lot.order.buyerSteamName }}</div>
          </div>
          <div class="auction__lot-buyer-lvl flex fx-align-center gap-2">
            <span class="text-muted"> {{ 'P2P_WIDGETS.AUCTION_COMPLETED_DIALOG.LEVEL' | translate }} </span>
            <div class="auction__lot-buyer-lvl-circle flex fx-align-center fx-justify-center tw-font-medium">
              <div>{{ lot.order.buyerSteamLevel }}</div>
            </div>
          </div>
          <div class="auction__lot-buyer-registration flex fx-align-center gap-2">
            <span class="text-muted"> {{ 'P2P_WIDGETS.AUCTION_COMPLETED_DIALOG.REGISTRATION_DATE' | translate }} </span>
            <span>{{ lot.order.buyerSteamCreatedAt | date: 'd MMM, y' }}</span>
          </div>
        </div>

        <div class="auction__lot-buyer__steam-card flex fx-column text-sm p-2">
          <span class="auction__lot-buyer__steam-card-title tw-font-medium pb-sm-3"> Steam App </span>
          <div class="auction__lot-buyer__steam-card-item container-dark flex fx-align-center gap-2 mb-1 w-100 p-1">
            <div class="lvl-wrapper flex fx-all-center">
              <mat-icon svgIcon="lvl-chevron--design"></mat-icon>
              <div class="lvl text-sm">{{ lot.order.buyerSteamLevel }}</div>
            </div>
            <div class="text-muted text-ellipsis">{{ lot.order.buyerSteamName }}</div>
          </div>
          <div class="auction__lot-buyer__steam-card-item container-dark flex fx-align-center gap-2 p-1">
            <app-ui-user-avatar [url]="lot.order.buyerSteamAvatar | replaceAvaHash"></app-ui-user-avatar>
            <span>{{ lot.order.buyerSteamCreatedAt | date: 'd MMM, y' }}</span>
          </div>
        </div>
      </div>

      <div class="divider-horizontal"></div>
      <div class="auction__lot-actions">
        <div class="auction__lot-actions-timer pb-2 timer text-sm text-muted">
          <mat-icon svgIcon="clock--design"></mat-icon>
          <span>{{ 'P2P_WIDGETS.AUCTION_COMPLETED_DIALOG.AUCTION_UNTIL_TIME' | translate }}</span>
          <span
            *ngIf="offerTimer$ | async as offerTimer"
            class="time-left"
          >
            {{ isTimerValid ? (offerTimer | date: 'mm:ss') : '00:00' }}
          </span>
        </div>
        <div class="auction__lot-actions-btns flex gap-3">
          <a
            rel="noopener noreferrer"
            class="btn btn-primary--design p-1 w-100"
            target="_blank"
            [href]="lot.order.buyerTradeLink"
            [class.disabled]="!isTimerValid"
            >{{ 'P2P_WIDGETS.AUCTION_COMPLETED_DIALOG.SEND_TRADE' | translate }}
          </a>
          <button
            class="btn btn-basic-dark--design p-1 w-100"
            (click)="onClose()"
          >
            {{ 'P2P_WIDGETS.DEPOSIT_MODIFIER_DIALOG.CANCEL_BUTTON' | translate }}
          </button>
        </div>
      </div>
    </div>
    <div class="divider-vertical"></div>
    <div
      *ngIf="currentSteamItem"
      class="auction__inventory"
    >
      <div class="pb-sm-3 text-sm text-muted">
        <div class="pb-sm-3">{{ 'P2P_WIDGETS.AUCTION_COMPLETED_DIALOG.POSITION_HEADER' | translate }}</div>
        <div class="auction__inventory-header__place mb-1">
          {{ currentSteamItem.page }}
          {{ 'P2P_WIDGETS.DEPOSITING.ITEM_LOCATION_PHRASE_1' | translate }}
          {{ currentSteamItem.line }}
          {{ 'P2P_WIDGETS.DEPOSITING.ITEM_LOCATION_PHRASE_2' | translate }}
          {{ currentSteamItem.number }}
        </div>
        <div class="auction__inventory-header__info">
          {{ 'P2P_WIDGETS.AUCTION_COMPLETED_DIALOG.POSITION_FOOTER' | translate }}
        </div>
      </div>
      <div class="auction__inventory-position">
        <div class="auction__inventory-position__page">
          <ng-container *ngFor="let card of allowedPagesDict[currentSteamItem.page]; index as i; trackBy: lotItemsTrackBy">
            <div
              class="inventory-item container-dark flex fx-all-center relative"
              [class.active]="card === currentSteamItem"
            >
              <ng-container *ngIf="card; else itemPlaceholderTemplate">
                <img [src]="card.baseItem.icon | hash" />
                <span class="item-index fw-500 absolute">{{ card.indicator }}</span>
              </ng-container>
              <ng-template #itemPlaceholderTemplate>
                <mat-icon
                  class="item-placeholder"
                  svgIcon="automate-gun"
                ></mat-icon>
              </ng-template>
            </div>
          </ng-container>
        </div>
        <div class="pages-row flex fx-all-center gap-2 pt-2 pb-3 text-sm">
          <div class="flex fx-all-center">
            <mat-icon
              *ngIf="showLeftArrow"
              svgIcon="arrow--design"
            ></mat-icon>
          </div>
          <div
            *ngFor="let visiblePage of visiblePagesArray; index as i; trackBy: trackPagesByIndex"
            class="flex fx-all-center container-dark"
            [class.active]="highlightCurentPage(visiblePage, currentSteamItem.page)"
          >
            {{ visiblePage }}
          </div>
          <div class="flex fx-all-center"><mat-icon svgIcon="arrow--design"></mat-icon></div>
        </div>
      </div>
      <div class="warning-banner text-sm">
        <div class="warning-banner__icon"><mat-icon svgIcon="danger-sign--design"></mat-icon></div>
        <div class="warning-banner__message">
          <div class="warning-banner__message-title">
            {{ 'P2P_WIDGETS.AUCTION_COMPLETED_DIALOG.POSITION_ATTENTION_HEADER' | translate }}
          </div>
          <div class="warning-banner__message-body">
            {{ 'P2P_WIDGETS.PURCHASE_TRADE_DIALOG.ALERT_TITLE' | translate }}
            {{ 'P2P_WIDGETS.PURCHASE_TRADE_DIALOG.ALERT_DESCRIBE' | translate }}
          </div>
        </div>
      </div>
    </div>
    <!-- TODO временный блок на пару дней. Удалить потом -->
    <div
      class="auction-warning"
      *ngIf="!warningSeen()"
    >
      <div class="auction-warning__title">
        {{ 'P2P_WIDGETS.DEPOSITING.WARNING_MODAL.WARNING' | translate }}
      </div>
      <div class="auction-warning__message">
        {{ 'P2P_WIDGETS.DEPOSITING.WARNING_MODAL.WARNING_TEXT' | translate }}
      </div>
      <button
        class="btn btn-primary--design auction-warning__action"
        (click)="onCloseWarning()"
      >
        {{ 'LOCAL.CASES.MODAL.JOKER.BUTTON' | translate }}
      </button>
    </div>
  </div>
</div>
