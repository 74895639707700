<mat-tab-group
  class="tabs-default tabsContainer"
  [(selectedIndex)]="activeTab"
  [ngClass]="tabGroupClass"
  (selectedIndexChange)="updateActiveTab($event)"
>
  <mat-tab *ngFor="let tab of tabLabels">
    <ng-template mat-tab-label> {{ tab | translate }} </ng-template>
    <div class="tabItemContent"></div>
  </mat-tab>
</mat-tab-group>
