export const APP_PATHS = {
  game: {
    classic: {
      base: 'classic',
      championship: 'championship',
      history: {
        base: 'history',
        id: ':id',
      },
    },
  },
  root: '',
  wildcard: '**',
} as const;
