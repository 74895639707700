import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ISticker } from '@dev-fast/types';

@Component({
  selector: 'app-p2p-sticker-image',
  templateUrl: './p2p-sticker-image.component.html',
  styleUrls: ['./p2p-sticker-image.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class P2pStickerImageComponent {
  @Input() sticker: ISticker | undefined;
}
