import { OrderStatusEnum } from '../../enums';
import { IBaseItemDtoV2 } from '../games';
import { IMarketplaceItem } from '../merketplace';
import { ISticker } from '../skin-sticker';

export interface IP2pBaseItem {
  status: OrderStatusEnum;
  statusAt: string;
  nextStatusAt: string;
  isUserSeller: boolean;
  isLastBidByUser: boolean;
  nextBidPrice?: number;
  price: number;
  express: boolean;
  phase?: string | null;
  float?: number;
  fastDelivery?: boolean;
  icon?: string;
  iconLarge?: string;
  isCrossFast: boolean;
  stickers?: ISticker[];
  recommendedPrice?: number;
  sellerPrice?: number;
  steamInspectLink?: string;
  tradeLockEndAt?: string;
}

export interface IP2pParticipateItemUpdated {
  id: number;
  isUserSeller?: boolean;
  isLastBidByUser?: boolean;
  status?: OrderStatusEnum;
  nextStatusAt?: string;
}
export interface IP2pItemUpdated {
  id: number;
  status: OrderStatusEnum;
  nextStatusAt: string;
  price?: number;
  sellerPrice?: number;
  nextBidPrice?: number;
  nextSellerPrice?: number;
}

export interface IDepositBatchPayload {
  steamInventoryId: number;
  price: number;
  autoApprove?: boolean;
  fastDelivery?: boolean;
  tradesDuration?: number;
}
// На деве поменялся немного payload на депозит
export interface IDepositBatchPayloadV2 {
  items: { steamInventoryId: number; price: number }[];
  autoApprove?: boolean;
  fastDelivery?: boolean;
  tradesDuration?: number;
}
export interface ILotCard {
  baseItem: IBaseItemDtoV2;
  float?: number;
  price: number;
  extra?: {
    price: number;
    increase: number;
  };
}

export interface IP2pModalData {
  id: number;
  price: number;
  recommendedPrice: number;
  nextBidPrice: number;
  auctionTime: Date;
  items: IMarketplaceItem[];
}
