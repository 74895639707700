<ng-container
  *ngIf="{
    panels: panels$ | async
  } as data"
>
  <div class="action-menu">
    <button
      class="btn btn-md sq btn-round btn-transparent-dark--design action-menu__button"
      (click)="openMenu(MenuEnum.MAIN_MENU)"
    >
      <ng-container *ngIf="user; else notAuthTemplate">
        <app-ui-user-avatar
          [url]="user.avatar"
          [type]="user.profileType"
        ></app-ui-user-avatar>
      </ng-container>
      <ng-template #notAuthTemplate>
        <mat-icon svgIcon="settings-fill--design"></mat-icon>
      </ng-template>
    </button>
    <ng-container *ngIf="activeMenu$ | async as activeMenu">
      <ng-container *ngIf="isMenuOpened(activeMenu)">
        <div
          class="active-panel__wrapper"
          inViewport
          [inViewportOptions]="{ threshold: [0] }"
          @replacementScaleAnimation
        >
          <div class="active-panel__container">
            <ng-container *ngIf="activeMenu.includes(MenuEnum.MAIN_MENU)">
              <app-menu-panel
                class="menu-panel"
                [activeMenu]="activeMenu"
                [userDetailed]="user"
                [inventoryShortInfo]="inventoryShortInfo$ | myAsync"
                [shopParams$]="shopParams$"
                [lockedPanels$]="lockedPanels$"
                [locales]="locales"
                [locale]="locale"
                [soundStatus]="soundStatus$ | myAsync"
                [canLevelUp]="canLevelUp"
                [userBalance]="userBalance"
                (languageChange)="onLangChange($event)"
                (openMenuEvent)="openMenu($event)"
                (closeMenuEvent)="closeMenu($event)"
                (onauthClick)="onAuthClick(user)"
                (soundToggle)="onSoundToggle()"
                (clickBack)="closeMenu(MenuEnum.MAIN_MENU)"
              >
              </app-menu-panel>
            </ng-container>
            <ng-container *ngIf="activeMenu.includes(MenuEnum.LANGUAGE)">
              <app-language-panel
                class="language-panel"
                [locales]="locales"
                [locale]="locale"
                (languageChange)="onLangChange($event)"
                (closePanel)="closeMenu($event)"
              >
              </app-language-panel>
            </ng-container>
          </div>
        </div>
        <div
          class="background-panel__wrapper"
          (click)="closeAllMenu()"
          @replacementAnimation
        ></div>
      </ng-container>
    </ng-container>
  </div>
</ng-container>
