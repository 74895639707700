import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sortBy',
})
export class SortByPipe implements PipeTransform {
  public transform<T>(value: ReadonlyArray<T>, key: string, order?: 'asc' | 'desc'): T[] {
    if (!Array.isArray(value)) throw Error(`InvalidPipeArgument: '${value}' for pipe sortBy`);
    const copiedValue = [...value];
    if (order)
      return copiedValue.sort((l, r) => (l[key] && r[key] ? (order === 'asc' ? l[key] - r[key] : r[key] - l[key]) : 0));
    return copiedValue.sort((l, r) => (l[key] && r[key] ? (l[key] > r[key] ? -1 : 1) : 0));
  }
}
