<div
  class="iframe"
  #iframeWrapper
  *ngIf="showIframe"
  [ngClass]="{ none: !showOtherComponent }"
>
  <iframe
    width="100%"
    height="100%"
    frameBorder="0"
    allow="autoplay"
    [src]="urlSafe"
    name="legacy"
    #iframe
    [id]="'bb_frame'"
    *ngIf="!isServer"
    (load)="frameLoaded($event)"
    (error)="frameRejected($event)"
  ></iframe>
  <div
    class="loader"
    *ngIf="showLoader"
  >
    <mat-spinner color="accent"></mat-spinner>
    <!--    <div class="message">-->
    <!--      {{'If the page takes a long time to load, you can try waiting longer or reloading using the button below'}}-->
    <!--      <button-->
    <!--        class="btn btn-primary&#45;&#45;design"-->
    <!--        (click)="reloadFrameForce()">-->
    <!--        {{'Reload' | translate}}-->
    <!--      </button>-->
    <!--    </div>-->
  </div>
</div>
<ng-container *ngIf="!showOtherComponent">
  <ng-content></ng-content>
</ng-container>
<ng-container *ngIf="seoData$ | async as seoData">
  <div
    class="seo"
    *ngIf="seoData && (seoData.h1 || seoData.main || seoData.faq)"
  >
    <ng-container *ngIf="seoData.h1 as h1"><h1 [innerHTML]="h1"></h1></ng-container>
    <ng-container *ngIf="seoData.main as main"><div [innerHTML]="main"></div></ng-container>
    <ng-container *ngIf="seoData.faq as faq"><div [innerHTML]="faq"></div></ng-container>
  </div>
</ng-container>
