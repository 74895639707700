import { Injectable } from '@angular/core';
import { ModalModel, ModalNames } from '@dev-fast/types';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';

import { ModalsService } from '@app/core/modal-service';
import { OpenModal } from '@app/core/state/modals';
import { CaseRevisionComponent } from '@app/shared/modals';

import { AccountNotyComponent } from '../modals/account-noty-modal/account-noty-modal.component';
import { CaseInfoModalComponent } from '../modals/case-info-modal/case-info-modal.component';
import { GameBreakModalComponent } from '../modals/game-break-modal/game-break-modal.component';
import { JokerModeModalSAComponent } from '../modals/joker-mode-modal/joker-mode-modal.component';
import { ProvablyFairModalComponent } from '../modals/provably-fair-modal/provably-fair-modal.component';
import { WinnersModalComponent } from '../modals/winners-modal/winners-modal.component';

@Injectable()
export class CaseBattleModalService {
  private modals: ModalModel[] = [
    {
      name: ModalNames.CASE_BATTLE_ACCOUNT_NOTY,
      component: AccountNotyComponent,
      config: {
        panelClass: 'case-battle-account-type',
      },
    },
    {
      name: ModalNames.CASE_BATTLE_GAME_BREAK,
      component: GameBreakModalComponent,
      config: {
        panelClass: 'case-battle-game-break',
      },
    },
    {
      name: ModalNames.CASE_BATTLE_WINNERS,
      component: WinnersModalComponent,
      config: {
        panelClass: 'case-battle-winners',
      },
    },
    {
      name: ModalNames.CASE_REVISION,
      component: CaseRevisionComponent,
      config: {
        panelClass: 'revesion-modal',
      },
    },
    {
      name: ModalNames.CASE_BATTLE_CASE_INFO,
      component: CaseInfoModalComponent,
      config: {
        panelClass: 'case-info-modal',
      },
    },
    {
      name: ModalNames.CASE_BATTLE_PROVABLY_FAIR,
      component: ProvablyFairModalComponent,
      config: {
        panelClass: 'case-battle-provably-modal',
      },
    },
    {
      name: ModalNames.JOKER_MODE,
      component: JokerModeModalSAComponent,
      config: {
        panelClass: 'case-battle-joker-modal',
      },
    },
  ];
  constructor(private readonly commonModalsService: ModalsService) {
    this.commonModalsService.registerModals(this.modals);
  }
  @Dispatch() openModal = (name: ModalNames, payload?: any): OpenModal => new OpenModal(name, payload);
}
