<ng-container *ngIf="checkboxList && subCheckboxesControls">
  <div>
    <div
      class="filterItem flex fx-align-center fx-justify-between w-100"
      [ngClass]="checkboxList.class"
      [class.selected]="selectedSubCheckboxesNumber || groupCheckboxControl.value"
      (click)="openSubPanel()"
    >
      <label
        class="filterItem__label flex fx-align-center gap-3"
        (click)="$event.preventDefault()"
      >
        <mat-checkbox
          class="filterItem-checkbox market-checkbox"
          [checked]="groupCheckboxControl.value"
          [indeterminate]="isGroupCheckboxIndeterminate"
          (change)="changeGroupCheckboxValue()"
          (click)="$event.stopPropagation()"
        ></mat-checkbox>

        {{ translateRoot + checkboxList.localeKey | translate }}
      </label>

      <ng-container *ngIf="this.subCheckboxesControls.length">
        <div class="flex fx-align-center gap-1 pointer pl-sm-3">
          <ng-container *ngIf="selectedSubCheckboxesNumber && isGroupCheckboxIndeterminate">{{ selectedSubCheckboxesNumber }}</ng-container>
          <mat-icon svgIcon="arrow--design"></mat-icon>
        </div>
      </ng-container>
    </div>

    <app-ui-filter-sub-panel
      *ngIf="isSubPanelOpened && this.subCheckboxesControls.length"
      class="absolute"
      [filterForm]="form"
      [checkboxList]="checkboxList"
      [categoryTitle]="categoryTitle"
      [controls]="subCheckboxesControls"
      [filterPanelHeight]="panelHeight"
      (selected)="changeSubCheckboxValue($event)"
      (closePanel)="isSubPanelOpened = false"
      [style.--bg-color]="'var(--color-gray-750)'"
      [style.width]="'100%'"
      [style.top]="subPanelTopOffset"
    />
  </div>
</ng-container>
