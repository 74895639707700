import { IExperience, IExperienceLeader, IExperienceStatistic, IGameStatistics, ILevel, ITransactionDto } from '@dev-fast/types';

export interface AccountStateModel {
  gameStatistics: IGameStatistics[];
  leaders: IExperienceLeader[];
  experience: IExperience[];
  statistics: IExperienceStatistic[];
  levels: ILevel[];
  balanceHistory: ITransactionDto[];
}

export const ACCOUNT_INITIAL_STATE: AccountStateModel = {
  gameStatistics: [],
  leaders: [],
  experience: [],
  statistics: [],
  levels: [],
  balanceHistory: [],
};
