import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { IFilterByPriceOption, IItemsFilterForm, InventorySortingTypes } from '@dev-fast/types';

export const filterByPriceConfig: IFilterByPriceOption[] = [
  //переделать под тугрики
  { from: 0, to: 10000 },
  { from: 0, to: 10 },
  { from: 10, to: 100 },
  { from: 100, to: 1000 },
  { from: 1000, to: null },
];

@Component({
  selector: 'app-participation-panel-filter',
  templateUrl: './participation-panel-filter.component.html',
  styleUrls: ['./participation-panel-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ParticipationPanelFilterComponent {
  @Input() panelForm!: FormGroup<IItemsFilterForm>;
  @Input() isMobile: boolean | null = false;
  @Output() navigateToInventory: EventEmitter<void> = new EventEmitter<void>(false);

  readonly filterOptions = filterByPriceConfig;
  readonly InventorySortingTypes = InventorySortingTypes;

  get sortBy(): InventorySortingTypes | null | undefined {
    return this.panelForm.get('sortBy')?.value;
  }
  get minPrice(): number | null | undefined {
    return this.panelForm.get('minPrice')?.value;
  }
  get maxPrice(): number | null | undefined {
    return this.panelForm.get('maxPrice')?.value;
  }
  filterByPriceRange(range: IFilterByPriceOption): void {
    this.panelForm.patchValue({
      minPrice: range.from,
      maxPrice: range.to,
    });
  }
  changeSortingDirection(): void {
    this.panelForm.controls['sortBy'].patchValue(
      this.sortBy === InventorySortingTypes.MAX_PRICE ? InventorySortingTypes.MIN_PRICE : InventorySortingTypes.MAX_PRICE,
    );
  }
  onNavigateToInventory(): void {
    this.navigateToInventory.emit();
  }
}
