import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import { IInventoryRequestParams, IInventoryShortInfo, ILocales, IUserDetailed, NewMenu, NewPanel, Panel } from '@dev-fast/types';
import { InViewportAction } from 'ng-in-viewport';
import { Observable } from 'rxjs';

import { SoundService } from '@app/core/sound-service';
import { replacementAnimation, replacementScaleAnimation } from '@app/ui/animations';

import { DesktopAppActionMenuService } from './action-menu.service';

@Component({
  selector: 'app-header-action-menu',
  templateUrl: './action-menu.component.html',
  styleUrls: ['./action-menu.component.scss', './action-menu.component.media.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [replacementAnimation, replacementScaleAnimation],
})
export class ActionMenuComponent {
  readonly #actionMenuService = inject(DesktopAppActionMenuService);
  readonly #soundService = inject(SoundService);

  @Input() user: IUserDetailed | null | undefined;
  @Input() locale!: ILocales | null;
  @Input() locales!: ILocales[] | null;
  @Input() canLevelUp!: boolean | null;
  @Input() userBalance = 0;

  readonly inventoryShortInfo$: Observable<IInventoryShortInfo> = this.#actionMenuService.inventoryShortInfo$;
  readonly shopParams$: Observable<IInventoryRequestParams> = this.#actionMenuService.shopParams$;
  readonly lockedPanels$: Observable<Panel[]> = this.#actionMenuService.lockedPanels$;
  readonly soundStatus$: Observable<boolean> = this.#soundService.status$;

  readonly activeMenu$: Observable<NewMenu[] | null> = this.#actionMenuService.activeMenu$;
  readonly panels$: Observable<NewPanel[]> = this.#actionMenuService.panels$;

  readonly MenuEnum = NewMenu;

  /**
   * Смена языка
   * @param newLanguage - Новый язык
   */
  onLangChange(newLanguage: ILocales): void {
    this.#actionMenuService.switchLang(newLanguage);
  }

  /**
   * Обрабатывает событие клика по кнопке аутентификации.
   * Если предоставлен пользователь, он выходит из системы и изменяет активную панель на null.
   * Если пользователь не предоставлен, он запускает процесс входа в систему.
   *
   * @param user - Объект пользователя.
   */
  onAuthClick(user: IUserDetailed | null | undefined): void {
    if (user) {
      this.#actionMenuService.logout();
      this.#actionMenuService.changeActivePanel(null);
    } else {
      this.#actionMenuService.login();
    }
  }

  /**
   * Переключает состояние звука.
   *
   * @returns {void}
   */
  onSoundToggle(): void {
    this.#soundService.toggleSoundStatus();
  }

  /**
   * Открывает указанное меню.
   */
  openMenu(menu: NewMenu): void {
    this.#actionMenuService.openMenu(menu);
  }

  /**
   * Закрывает указанное меню и, если закрывается языковое меню, открывает главное меню.
   * @param {NewMenu} menu - Меню, которое необходимо закрыть.
   */
  closeMenu(menu: NewMenu): void {
    this.#actionMenuService.closeMenu(menu);
    if (menu === NewMenu.LANGUAGE) {
      this.openMenu(NewMenu.MAIN_MENU);
    }
  }

  /**
   * Закрывает все меню.
   */
  closeAllMenu(e?: InViewportAction): void {
    if (!e || (e && !e.visible)) {
      this.#actionMenuService.closeAllMenu();
    }
  }

  /**
   * Проверяет, открыто ли меню на основе активных пунктов меню.
   * @param activeMenu - Массив активных пунктов меню.
   * @returns Логическое значение, указывающее, открыто ли меню или нет.
   */
  isMenuOpened(activeMenu: NewMenu[] | null): boolean {
    const menuArray = [NewMenu.MAIN_MENU, NewMenu.LANGUAGE];
    return !!activeMenu?.some((menu) => menuArray.includes(menu));
  }
}
