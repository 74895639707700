import { Overlay, OverlayPositionBuilder, OverlayRef, ScrollStrategy } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { ComponentRef, Directive, ElementRef, HostListener, Inject, Input } from '@angular/core';
import { MAT_TOOLTIP_SCROLL_STRATEGY } from '@angular/material/tooltip';
import { ISkinItem } from '@dev-fast/types';

import { TooltipService } from '../tooltip.service';
import { SkinItemTooltipComponent } from './skin-item-tooltip.component';

@Directive({ selector: '[appSkinItemTooltip]' })
export class SkinItemTooltipDirective {
  @Input()
  skin!: ISkinItem;
  private overlayRef!: OverlayRef;
  private tooltipRef!: ComponentRef<SkinItemTooltipComponent>;
  private manualEventListeners: Map<string, EventListenerOrEventListenerObject>;
  private readonly scrollStrategy: () => ScrollStrategy;

  constructor(
    private overlay: Overlay,
    private overlayPositionBuilder: OverlayPositionBuilder,
    private elementRef: ElementRef,
    private tooltipService: TooltipService,
    @Inject(MAT_TOOLTIP_SCROLL_STRATEGY) scrollStrategy: any,
  ) {
    this.manualEventListeners = new Map();
    this.manualEventListeners.set('mouseleave', () => this.hide());
    this.scrollStrategy = scrollStrategy;
  }

  @HostListener('click')
  show(): void {
    if (this.tooltipRef) {
      this.hide();
      return;
    }

    const positionStrategy = this.overlayPositionBuilder
      .flexibleConnectedTo(this.elementRef)
      .withFlexibleDimensions(false)
      .withTransformOriginOn('app-skin-item')
      .withViewportMargin(8)
      .withPositions([
        {
          originX: 'start',
          originY: 'top',
          overlayX: 'start',
          overlayY: 'top',
          offsetY: -75,
          offsetX: -75,
        },
      ]);

    this.overlayRef = this.overlay.create({
      panelClass: 'text-color-primary',
      width: 275,
      height: 342,
      positionStrategy,
      scrollStrategy: this.scrollStrategy(),
    });

    this.tooltipService.setTooltip(this.overlayRef);

    this.tooltipRef = this.overlayRef.attach(new ComponentPortal(SkinItemTooltipComponent));
    this.tooltipRef.instance.skin = this.skin;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const element: HTMLElement = this.overlayRef._pane;
    this.manualEventListeners.forEach((event: EventListenerOrEventListenerObject, listener: string) =>
      element.addEventListener(listener, event),
    );
  }

  hide(): void {
    this.overlayRef.detach();
    this.tooltipRef = null as any;
  }
}
