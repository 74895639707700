import { HttpErrorResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { CasesBackendService, CasesQuestBackendService, CasesSocketService } from '@dev-fast/backend-services';
import {
  CasesGamePhases,
  CasesGameType,
  CasesSocketName,
  ICaseItemDtoV2,
  ICaseOpenPrizeDataDto,
  ICaseOpenPrizeItemDto,
  ICaseRevisionItem,
  IItemStatus,
  ISocketMessage,
  ModalNames,
  NotificationCategory,
  NotificationStatus,
  NotificationType,
} from '@dev-fast/types';
import { Navigate, RouterNavigation } from '@ngxs/router-plugin';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { insertItem, patch } from '@ngxs/store/operators';
import { BehaviorSubject, EMPTY, Observable, of, timer } from 'rxjs';
import { catchError, filter, switchMap, tap } from 'rxjs/operators';

// eslint-disable-next-line @nx/enforce-module-boundaries
import { LogoutSuccess } from '@app/auth';
import { AnalyticsService } from '@app/core/analytics-service';
import { NotificationsService } from '@app/core/notification-service';
import { SuccessfulBid } from '@app/core/state/games-store';
import { ChangeGamesItemsStatus, GetInventoryInfo, GetInventoryItems, ToggleGameStatus } from '@app/core/state/inventory';
import { ChangeGamesItemsStatus as ChangeGamesItemsStatusLegacy } from '@app/core/state/inventory-legacy';
import { CloseModal } from '@app/core/state/modals';
import { RouterStateParams } from '@app/core/state/utils';

import { CasesStateSubscribe, CasesStateUnsubscribe } from '../cases-state';
import {
  ChangeFavorite,
  ClearGameState,
  GetCase,
  GetReplayItems,
  GetSpinItemsIds,
  HandleOpenBonusCase,
  OpenCaseItems,
  SetGameType,
  SetPrizeData,
  SetPrizeItems,
  SetSpinState,
  SetSpinTracksAmount,
  SetToDropPosition,
  StartReplay,
  UpdateBonusCasesInfo,
  UpdateCase,
} from './cases-game.actions';
import { CASES_GAME_INITIAL_STATE, ICasesGameStateModel } from './cases-game-state.model';

@State<ICasesGameStateModel>({
  name: 'casesGame',
  defaults: CASES_GAME_INITIAL_STATE,
})
@Injectable()
export class CasesGameState {
  readonly #subscribeSubject = new BehaviorSubject(false);
  readonly #casesApiService = inject(CasesBackendService);
  readonly #casesSocketService = inject(CasesSocketService);
  readonly #casesQuestApiService = inject(CasesQuestBackendService);
  readonly #notificationsService = inject(NotificationsService);
  readonly #analyticsService = inject(AnalyticsService);

  @Selector()
  static caseItem({ caseItem }: ICasesGameStateModel): ICaseItemDtoV2 | null {
    return caseItem;
  }
  @Selector()
  static caseItemRequestState({ caseItemRequestState }: ICasesGameStateModel): boolean | null {
    return caseItemRequestState;
  }
  @Selector()
  static gameType({ gameType }: ICasesGameStateModel): CasesGameType {
    return gameType;
  }
  @Selector()
  static prizeItems({ prizeItems }: ICasesGameStateModel): ICaseOpenPrizeItemDto[] {
    return prizeItems;
  }
  @Selector()
  static autoplayPrizeItems({ autoplayPrizeItems }: ICasesGameStateModel): ICaseOpenPrizeItemDto[] {
    return autoplayPrizeItems;
  }
  @Selector()
  static spinState({ spinState }: ICasesGameStateModel): CasesGamePhases {
    return spinState;
  }
  @Selector()
  static receivedBonusCase({ receivedBonusCase }: ICasesGameStateModel): boolean {
    return receivedBonusCase;
  }
  @Selector()
  static hasBonusCase({ bonusCases }: ICasesGameStateModel): boolean {
    return bonusCases && bonusCases.length > 0;
  }
  @Selector()
  static isBonusCase({ isBonusCase, caseItem, bonusCases }: ICasesGameStateModel): boolean {
    return isBonusCase && caseItem !== null && bonusCases.includes(caseItem.id);
  }
  @Selector()
  static spinTracks({ spinTracksIds, caseItemsDictionary }: ICasesGameStateModel): (ICaseRevisionItem | undefined)[][] {
    return spinTracksIds.map((t) => t.map((id) => caseItemsDictionary[id]));
  }
  @Selector()
  static caseItemsDictionary({ caseItemsDictionary }: ICasesGameStateModel): Record<number, ICaseRevisionItem> {
    return caseItemsDictionary;
  }

  @Selector()
  static prizeData({ prizeData }: ICasesGameStateModel): ICaseOpenPrizeDataDto | null {
    return prizeData;
  }

  ngxsOnInit({ dispatch }: StateContext<ICasesGameStateModel>): void {
    this.#casesQuestApiService.luckyWonEvent((msg) => dispatch(new UpdateBonusCasesInfo(msg.caseId)));
    this.#subscribeSubject
      .pipe(
        filter((isSubscribe) => isSubscribe),
        switchMap(() => {
          return this.#casesSocketService.onRevisionUpdate().pipe(
            tap((val: ISocketMessage<CasesSocketName, { caseId: number }>) => {
              dispatch(new UpdateCase(val.payload.caseId));
            }),
          );
        }),
      )
      .subscribe();
  }
  @Action(CasesStateSubscribe)
  casesStateSubscribe(ctx: StateContext<ICasesGameStateModel>): void {
    this.#subscribeSubject.next(true);
  }
  @Action(CasesStateUnsubscribe)
  casesStateUnsubscribe(ctx: StateContext<ICasesGameStateModel>): void {
    this.#subscribeSubject.next(false);
  }
  @Action(UpdateBonusCasesInfo)
  updateBonusCasesInfo({ setState }: StateContext<ICasesGameStateModel>, { caseId }: UpdateBonusCasesInfo): void {
    setState(
      patch({
        receivedBonusCase: true,
        bonusCases: insertItem(caseId),
      }),
    );
  }
  @Action(ChangeFavorite)
  changeFavorite({ patchState, dispatch, getState }: StateContext<ICasesGameStateModel>, { isFavorite }: ChangeFavorite): void {
    // TODO: сначала отправляем запрос на бэк, чтобы он сменил состояние "избранного" кейса, а уже потом если успешно меняем стейт
    const { caseItem } = getState();
    if (caseItem) {
      patchState({
        caseItem: { ...caseItem, isFavorite: !isFavorite },
      });
    }
  }

  @Action([ChangeGamesItemsStatus, ChangeGamesItemsStatusLegacy])
  changeItemsStatusAfterSell(
    { patchState, getState }: StateContext<ICasesGameStateModel>,
    { ids }: ChangeGamesItemsStatus | ChangeGamesItemsStatusLegacy,
  ): void {
    const { prizeItems, autoplayPrizeItems } = getState();
    patchState({
      prizeItems: this.#updatePrizeItemStatus(prizeItems, ids),
      autoplayPrizeItems: this.#updatePrizeItemStatus(autoplayPrizeItems, ids),
    });
  }

  @Action(CloseModal)
  closeCasesModal({ dispatch }: StateContext<ICasesGameStateModel>, { name }: CloseModal): void {
    if (name === ModalNames.AUTOPLAY_GAME) {
      dispatch(new HandleOpenBonusCase(true));
    }
  }

  @Action(ClearGameState)
  clearGameState({ patchState }: StateContext<ICasesGameStateModel>): void {
    patchState({
      ...CASES_GAME_INITIAL_STATE,
    });
  }

  @Action(HandleOpenBonusCase)
  handleOpenBonusCase(
    { dispatch, getState, patchState }: StateContext<ICasesGameStateModel>,
    { isFinish }: HandleOpenBonusCase,
  ): Observable<0> {
    return timer(900).pipe(
      tap(() => {
        const { bonusCases, isBonusCase, caseItem, gameType } = getState();
        const changedBonusCases = [...bonusCases];
        if (isFinish) {
          if (gameType === CasesGameType.BONUS) {
            changedBonusCases.shift();
            patchState({
              bonusCases: changedBonusCases,
            });
          }
          if (isBonusCase) {
            if (!changedBonusCases.length) {
              dispatch(new Navigate(caseItem ? [`game/cases/case/${caseItem.id}`] : ['game/cases']));
            }
            if (changedBonusCases.length && caseItem) {
              if (caseItem.free && caseItem.free.count > 0 && changedBonusCases[0] === caseItem?.id) {
                dispatch(new OpenCaseItems({ caseId: caseItem.id, count: 1, gameType: CasesGameType.BONUS, isFree: true }));
              } else if (changedBonusCases[0] !== caseItem?.id) {
                dispatch(new Navigate([`game/cases/case/${changedBonusCases[0]}`], { bonus: true, lines: 1 }));
              }
            }
          } else if (changedBonusCases.length) {
            dispatch(new Navigate([`game/cases/case/${changedBonusCases[0]}`], { bonus: true, lines: 1 }));
          }
        } else {
          if (gameType !== CasesGameType.BONUS && changedBonusCases.length && isBonusCase && caseItem?.free && caseItem.free.count > 0) {
            dispatch(new OpenCaseItems({ caseId: caseItem.id, count: 1, gameType: CasesGameType.BONUS, isFree: true }));
          }
        }
      }),
    );
  }
  @Action(GetCase)
  getCase({ patchState }: StateContext<ICasesGameStateModel>, { id }: GetCase): Observable<ICaseItemDtoV2 | []> {
    return this.#casesApiService.getCaseItemById(id).pipe(
      tap((response) => {
        patchState({
          caseItem: response,
          caseItemRequestState: true,
          caseItemsDictionary: this.#itemsDictionaryConstructor(response.lastRevision.items),
          // spinState: CasesGamePhases.INITIALIZE,
          spinTracksIds: [],
          prizeData: null,
          prizeItems: [],
        });
      }),
      catchError((error) => {
        patchState({
          caseItemRequestState: false,
        });
        return this.#onError(error);
      }),
    );
  }
  @Action(GetSpinItemsIds, { cancelUncompleted: true })
  getSpinItemsIds(
    { patchState, getState }: StateContext<ICasesGameStateModel>,
    { lines, caseId }: GetSpinItemsIds,
  ): Observable<number[][]> {
    const { spinTracksIds } = getState();
    return this.#casesApiService.getSpinItems(lines, caseId).pipe(
      tap((response) => {
        patchState({
          gameType: CasesGameType.MONEY,
          spinTracksIds: response,
          prizeData: null,
        });
      }),
      catchError((error) => {
        patchState({
          gameType: CasesGameType.MONEY,
          spinTracksIds,
        });
        this.#onError(error);
        throw new Error(error.message);
      }),
    );
  }
  @Action(GetReplayItems)
  getReplayItems(
    { patchState, dispatch }: StateContext<ICasesGameStateModel>,
    { openUUID }: GetReplayItems,
  ): Observable<ICaseOpenPrizeDataDto | []> {
    return this.#casesApiService.getReplays(openUUID).pipe(
      tap((response) => {
        const prizeIds = response.results.map((item) => item.userInventoryItemId);
        const infinitySpin: number[][] = response.strip.map((track, index) => {
          track[28] = response.results[index].caseRevisionItem.inventoryItem.id;
          return [...track.slice(25), ...track.slice(7)];
        });
        dispatch([new SetSpinTracksAmount(response.strip.length)]);
        patchState({
          gameType: CasesGameType.REPLAY,
          spinTracksIds: infinitySpin,
          prizeData: { ...response, strip: infinitySpin },
          prizeItems: this.#updatePrizeItemStatus(response.results, prizeIds, IItemStatus.TAKEN),
        });
      }),
      catchError((error) => {
        patchState({
          spinState: CasesGamePhases.INITIALIZE,
          prizeData: null,
          prizeItems: [],
          gameType: CasesGameType.MONEY,
          spinTracksIds: [],
        });
        dispatch([new ToggleGameStatus(false)]);
        return this.#onError(error);
      }),
    );
  }
  @Action(LogoutSuccess)
  onLogout({ patchState, getState }: StateContext<ICasesGameStateModel>): void {
    const { caseItem } = getState();
    patchState({
      spinState: CasesGamePhases.INITIALIZE,
      prizeItems: [],
      autoplayPrizeItems: [],
      receivedBonusCase: false,
      gameType: CasesGameType.MONEY,
      caseItem: caseItem ? { ...caseItem, free: undefined } : null,
    });
  }
  @Action(OpenCaseItems, { cancelUncompleted: true })
  openCaseItems(
    { patchState, dispatch, getState }: StateContext<ICasesGameStateModel>,
    { params }: OpenCaseItems,
  ): Observable<ICaseOpenPrizeDataDto> {
    patchState({
      spinState: CasesGamePhases.REQUEST,
      prizeItems: [],
    });
    return this.#casesApiService.getItemsFromOpenedCase(params).pipe(
      tap((response) => {
        const prizeItems = response.results.map((item) => ({ ...item, status: IItemStatus.TAKEN }));
        const { caseItem, autoplayPrizeItems } = getState();
        const stateUpdate: Partial<ICasesGameStateModel> = {
          prizeItems,
          gameType: params.gameType,
          receivedBonusCase: false,
        };
        if (caseItem) {
          stateUpdate.caseItem = {
            ...caseItem,
            free: caseItem.free && { ...caseItem.free, count: caseItem.free.count - params.count },
          };
        }
        if (params.gameType === CasesGameType.AUTOPLAY) {
          stateUpdate.autoplayPrizeItems = [...autoplayPrizeItems, ...prizeItems];
        }
        if (params.gameType !== CasesGameType.AUTOPLAY) {
          stateUpdate.autoplayPrizeItems = [];
        }

        this.#analyticsService.caseAutoOpenEvent(params.countOfAutoplayCases || params.count);

        patchState(stateUpdate);
        dispatch([new ToggleGameStatus(true), new SetPrizeItems(response), new SuccessfulBid('cases')]);
      }),
      catchError((error) => {
        patchState({
          spinState: CasesGamePhases.INITIALIZE,
          prizeItems: [],
          autoplayPrizeItems: [],
          gameType: params.gameType,
        });
        dispatch([new ToggleGameStatus(false)]);
        this.#onError(error);
        throw new Error(error.message);
      }),
    );
  }
  @Action(RouterNavigation)
  navigate(
    { dispatch, patchState, getState }: StateContext<ICasesGameStateModel>,
    { routerState }: RouterNavigation<RouterStateParams>,
  ): void {
    const { queryParams } = routerState;

    const caseId = routerState.params['id'];
    if (routerState.url.includes('/game/cases/case') && caseId) {
      const { prizeData, caseItem } = getState();
      const canChangePrizeData = prizeData !== null && caseItem && caseItem.id !== +caseId;
      const arrActions = [new SetSpinState(CasesGamePhases.INITIALIZE), new SetGameType(CasesGameType.MONEY), new GetCase(caseId)];
      dispatch(canChangePrizeData ? [new SetPrizeData(null), ...arrActions] : arrActions);

      if (queryParams) {
        const isBonusCase = !!queryParams[CasesGameType.BONUS];
        patchState({
          isBonusCase,
        });
      }
    }
  }
  @Action(SetGameType)
  setGameType({ patchState }: StateContext<ICasesGameStateModel>, { gameType }: SetGameType): void {
    patchState({
      gameType: gameType,
      prizeItems: [],
    });
  }
  @Action(SetSpinState)
  setSpinState({ patchState, dispatch, getState }: StateContext<ICasesGameStateModel>, { spinState }: SetSpinState): void {
    const { gameType } = getState();
    if (spinState === CasesGamePhases.VICTORY) {
      dispatch(new SetToDropPosition());
    }
    if (spinState === CasesGamePhases.VICTORY && (gameType === CasesGameType.MONEY || gameType === CasesGameType.BONUS)) {
      dispatch([new GetInventoryItems(), new GetInventoryInfo(), new HandleOpenBonusCase(true)]);
    }
    if (spinState === CasesGamePhases.INITIALIZE || spinState === CasesGamePhases.VICTORY) {
      dispatch([new ToggleGameStatus(false)]);
    }
    patchState({
      spinState: spinState,
    });
  }
  @Action(SetPrizeItems)
  setPrizeItems({ patchState, getState, dispatch }: StateContext<ICasesGameStateModel>, { payload }: SetPrizeItems): void {
    const { spinTracksIds } = getState();

    const lineWithPrize = this.#prizeSetter(spinTracksIds, payload.results);

    patchState({
      spinTracksIds: lineWithPrize,
      prizeData: payload,
    });
    dispatch(new SetSpinState(CasesGamePhases.RAFFLE));
  }
  @Action(SetToDropPosition)
  setToDropPosition({ patchState, getState, dispatch }: StateContext<ICasesGameStateModel>): void {
    const { spinTracksIds, prizeData } = getState();

    // берем кусок старой линии и кусок новой
    const nextLine = prizeData?.strip?.length && prizeData?.strip[0].length ? prizeData.strip : spinTracksIds;
    const newLine = this.#stripesConcat(spinTracksIds, nextLine);

    patchState({
      spinTracksIds: newLine,
    });
  }
  @Action(SetSpinTracksAmount)
  spinTracksAmount({ dispatch }: StateContext<ICasesGameStateModel>, { spinTracksAmount, caseId }: SetSpinTracksAmount): void {
    if (caseId) {
      dispatch(new GetSpinItemsIds(spinTracksAmount, caseId));
    }
  }
  @Action(SetPrizeData)
  setPrizeData({ patchState }: StateContext<ICasesGameStateModel>, { payload }: SetPrizeData): void {
    patchState({ prizeData: payload });
  }
  @Action(StartReplay)
  startReplay({ patchState }: StateContext<ICasesGameStateModel>): void {
    patchState({
      spinState: CasesGamePhases.REQUEST,
      gameType: CasesGameType.REPLAY,
    });
    setTimeout(() => {
      patchState({
        spinState: CasesGamePhases.RAFFLE,
      });
    }, 600);
  }
  @Action(UpdateCase)
  updateCase({ getState, patchState }: StateContext<ICasesGameStateModel>, { caseId }: UpdateCase): Observable<ICaseItemDtoV2> {
    const caseItem = getState().caseItem;
    if (caseItem?.id === caseId) {
      return this.#casesApiService.getCaseItemById(caseId).pipe(
        tap((response) => {
          const caseItemsDictionary = getState().caseItemsDictionary;
          patchState({
            caseItem: { ...caseItem, ...response },
            caseItemsDictionary: { ...caseItemsDictionary, ...this.#itemsDictionaryConstructor(response.lastRevision.items) },
            prizeItems: [],
          });
        }),
        catchError((error) => {
          return this.#onError(error);
        }),
      );
    }
    return of();
  }

  #updatePrizeItemStatus(
    prizeItems: ICaseOpenPrizeItemDto[],
    updatedItemIds: number[],
    status: IItemStatus = IItemStatus.SOLD,
  ): ICaseOpenPrizeItemDto[] {
    return prizeItems.map((item: ICaseOpenPrizeItemDto) => {
      if (updatedItemIds.includes(item.userInventoryItemId)) {
        return { ...item, status };
      }
      return item;
    });
  }

  #itemsDictionaryConstructor(items: ICaseRevisionItem[]): Record<number, ICaseRevisionItem> {
    return items.reduce((acc: Record<number, ICaseRevisionItem>, curr: ICaseRevisionItem) => {
      return { ...acc, [curr.inventoryItem.id]: curr };
    }, {});
  }

  #stripesConcat(oldStripes: number[][], newStripes: number[][]): number[][] {
    return oldStripes.map((oldStripe, index) => {
      const targetIndex = newStripes[index].length - 7;
      return [...oldStripe.slice(targetIndex), ...newStripes[index].slice(0, targetIndex)];
    });
  }

  #prizeSetter(stripes: number[][], prizeItems: ICaseOpenPrizeItemDto[]): number[][] {
    return stripes.map((stripe, index) => {
      const targetIndex = stripe.length - 3;
      return [...stripe.slice(0, targetIndex - 1), prizeItems[index].caseRevisionItem.inventoryItem.id, ...stripe.slice(targetIndex)];
    });
  }

  #onError(error: HttpErrorResponse): Observable<never> {
    this.#notificationsService.addNotification({
      id: Date.now(),
      type: error.error && error.error.type ? error.error.type : NotificationType.Error,
      icon: 'warning',
      category: NotificationCategory.GAME,
      message: error.error && error.error.message ? error.error.message : typeof error.error === 'string' ? error.error : 'Error',
      createDate: Date.now(),
      system: true,
      status: NotificationStatus.new,
    });
    return EMPTY;
  }
}
