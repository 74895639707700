import { inject, Injectable } from '@angular/core';
import {
  BreakpointsTypes,
  CasesGamePhases,
  CasesGameType,
  ICaseItemDetailed,
  ICaseItemDetailedPayload,
  ICaseItemDtoV2,
  ICaseLinesParams,
  ICaseOpenParams,
  ICaseOpenPrizeItemDto,
  ICaseRevisionItem,
  IDropItemDto,
  IMotivatorStatistic,
  IP2pPurchaseItem,
  ModalNames,
  PROJECT,
  SpinDirection,
  StateActionStatus,
} from '@dev-fast/types';
import { Actions, Select } from '@ngxs/store';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { BehaviorSubject, combineLatest, filter, map, Observable, of, switchMap } from 'rxjs';

import { Login } from '@app/auth';
import { EnvironmentService } from '@app/core/environment-service';
import { InventoryState, SellItems } from '@app/core/state/inventory';
import { LayoutState } from '@app/core/state/layout';
import { CloseModal } from '@app/core/state/modals';
import { GetAllCustomCases, P2pBuyingState, P2pMarketState } from '@app/core/state/p2p';
import { actionLoadStatus } from '@app/shared/utils';

import { AddSelectedItems } from '../../case-battle/state';
import { MAX_OPEN_CASE_COUNT, MOBILE_BREAKPOINTS } from '../../shared/constants';
import {
  CasesGameState,
  CasesStateSubscribe,
  CasesStateUnsubscribe,
  ChangeFavorite,
  ClearGameState,
  GetCase,
  GetReplayItems,
  GetSpinItemsIds,
  OpenCaseItems,
  SetGameType,
  SetSpinState,
  StartReplay,
} from '../../state/cases';
import { ClearMarketCasesState, GetItemDetails, SetSelectedItemName } from '../../state/cases/market-state/market-state.actions';
import { MarketCasesState } from '../../state/cases/market-state/market-state.state';
import { ToggleControlPanel } from '../../state/cases/settings-state';
import { GetCurrentCaseMotivator, MotivatorsState } from '../../state/motivator';

@Injectable({
  providedIn: 'root',
})
export class CasesRenewService {
  readonly #actions$ = inject(Actions);
  readonly #environmentService = inject(EnvironmentService);

  @Select(LayoutState.breakpoints)
  private readonly breakpoints$!: Observable<BreakpointsTypes | null>;
  @Select(CasesGameState.spinTracks)
  private readonly spinTracks$!: Observable<ICaseRevisionItem[][]>;

  @Select(InventoryState.inventoryItemsCount)
  readonly inventoryItemsCount$!: Observable<number>;

  @Select(P2pMarketState.customCaseList)
  readonly customCaseList$!: Observable<ICaseItemDtoV2[]>;
  @Select(P2pBuyingState.purchasing)
  readonly purchasing$!: Observable<IP2pPurchaseItem[]>;

  @Select(MarketCasesState.caseItemsDetailed)
  readonly caseItemsDetailed$!: Observable<Record<string, ICaseItemDetailed>>;
  @Select(MarketCasesState.selectedItem)
  readonly selectedItem$!: Observable<ICaseItemDetailed | undefined>;

  @Select(CasesGameState.caseItem)
  readonly caseItem$!: Observable<ICaseItemDtoV2 | null>;
  @Select(CasesGameState.prizeItems)
  readonly prizeItems$!: Observable<ICaseOpenPrizeItemDto[]>;
  @Select(CasesGameState.autoplayPrizeItems)
  readonly autoplayPrizeItems$!: Observable<ICaseOpenPrizeItemDto[]>;
  @Select(CasesGameState.gameType)
  readonly gameType$!: Observable<CasesGameType>;
  @Select(CasesGameState.spinState)
  readonly spinState$!: Observable<CasesGamePhases>;

  @Select(MotivatorsState.currentCaseMotivator)
  readonly currentCaseMotivator$!: Observable<IMotivatorStatistic>;

  readonly caseLoadStatus$: Observable<StateActionStatus> = actionLoadStatus(this.#actions$, GetCase);

  readonly availableModes$: Observable<CasesGameType[]> = of(this.#environmentService.getEnvironment().PROJECT).pipe(
    map((project) => {
      let availableModes = [CasesGameType.MONEY, CasesGameType.BONUS];
      if (project !== PROJECT.MARKET) {
        availableModes = [...availableModes, CasesGameType.AUTOPLAY, CasesGameType.DEMO, CasesGameType.REPLAY];
      }
      return availableModes;
    }),
  );
  readonly tracksData$: Observable<{ tracks: ICaseRevisionItem[][]; direction: SpinDirection }> = combineLatest([
    this.spinTracks$.pipe(filter((tracks) => !tracks.every((t) => t?.length && !t[0]))),
    this.breakpoints$.pipe(filter((breakpoints) => !!breakpoints?.native)),
  ]).pipe(
    map(([tracks, breakpoints]) => {
      let direction = tracks.length === 1 ? SpinDirection.HORIZONTAL : SpinDirection.VERTICAL;
      if (breakpoints?.native && MOBILE_BREAKPOINTS.includes(breakpoints.native)) {
        direction = SpinDirection.MOBILE;
      }
      return { tracks, direction };
    }),
  );
  readonly tracksSubject$: BehaviorSubject<number> = new BehaviorSubject<number>(1);

  readonly tracksStatus$: Observable<ICaseLinesParams> = this.tracksSubject$.pipe(
    switchMap((needAmount: number) => {
      return combineLatest([
        this.spinTracks$.pipe(filter((tracks) => !!tracks.length && tracks.length <= MAX_OPEN_CASE_COUNT)),
        actionLoadStatus(this.#actions$, [GetReplayItems, GetSpinItemsIds], 300),
      ]).pipe(
        map(([tracks, status]) => {
          const amount = status === StateActionStatus.ERROR ? tracks.length : needAmount;
          return {
            tracksAmount: amount,
            status: status,
          };
        }),
      );
    }),
  );
  readonly sellStatus$: Observable<StateActionStatus> = actionLoadStatus(this.#actions$, SellItems);

  @Dispatch() addCaseToBattle = (caseItem: ICaseItemDtoV2): AddSelectedItems => new AddSelectedItems([caseItem]);

  @Dispatch() changeFavorite = (isFavorite: boolean): ChangeFavorite => new ChangeFavorite(isFavorite);
  @Dispatch() clearCasesState = (): ClearMarketCasesState => new ClearMarketCasesState();
  @Dispatch() clearGameState = (): ClearGameState => new ClearGameState();
  @Dispatch() closeCaseModal = (): CloseModal => new CloseModal(ModalNames.CASE);

  @Dispatch() getAllCustomCases = (): GetAllCustomCases => new GetAllCustomCases();

  @Dispatch() getCaseItem = (caseId: number): GetCase => new GetCase(caseId);
  @Dispatch() getCurrentCaseMotivator = (caseId: number): GetCurrentCaseMotivator => new GetCurrentCaseMotivator(caseId);
  @Dispatch() getReplayItems = (openUUID: string): GetReplayItems => new GetReplayItems(openUUID);
  @Dispatch() getSpinItemsIds = (count: number, caseId: number): GetSpinItemsIds => new GetSpinItemsIds(count, caseId);

  @Dispatch() login = (): Login => new Login();

  @Dispatch() openCaseItems = (params: ICaseOpenParams): OpenCaseItems => new OpenCaseItems(params);

  @Dispatch() setSpinState = (spinState: CasesGamePhases): SetSpinState => new SetSpinState(spinState);
  @Dispatch() setGameType = (gameType: CasesGameType): SetGameType => new SetGameType(gameType);
  @Dispatch() sellItems = (itemIds: number[]): SellItems => new SellItems(itemIds);
  @Dispatch() startReplay = (replayPrizeItems?: IDropItemDto[]): StartReplay => new StartReplay(replayPrizeItems);

  @Dispatch() stateSubscribe = (): CasesStateSubscribe => new CasesStateSubscribe();
  @Dispatch() stateUnsubscribe = (): CasesStateUnsubscribe => new CasesStateUnsubscribe();

  @Dispatch() getItemDetails = (payload: ICaseItemDetailedPayload): GetItemDetails => new GetItemDetails(payload);
  @Dispatch() clearSelectedItemName = (name?: string): SetSelectedItemName => new SetSelectedItemName(name);

  @Dispatch() toggleControlPanel = (controlPanelHidden: boolean): ToggleControlPanel => new ToggleControlPanel(controlPanelHidden);
  getCurrentCase = (caseId: number): void => {
    this.getCaseItem(caseId);
    this.getCurrentCaseMotivator(caseId);
  };
}
