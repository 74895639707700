import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IEvent } from '@dev-fast/types';

@Component({
  selector: 'app-ui-event-menu-item',
  templateUrl: './event-menu-item.component.html',
  styleUrls: ['./event-menu-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventMenuItemComponent {
  @Input() public event: IEvent | undefined;
}
