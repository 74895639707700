import { IconConfig } from '@dev-fast/types';
// TODO Delete -design prefix after release
export const iconsRightSidebarDesign: IconConfig[] = [
  {
    name: 'chat-design',
    path: 'assets/img/design/right-sidebar/chat.svg',
  },
  {
    name: 'chat-renew',
    path: 'assets/img/design/right-sidebar/chat-renew.svg',
  },
  {
    name: 'send-renew',
    path: 'assets/img/design/right-sidebar/send.svg',
  },
  {
    name: 'cart-renew',
    path: 'assets/img/design/right-sidebar/cart-renew.svg',
  },
  {
    name: 'headphones-renew',
    path: 'assets/img/design/right-sidebar/headphones-renew.svg',
  },
  {
    name: 'support-agent-design',
    path: 'assets/img/design/right-sidebar/support-agent.svg',
  },
  {
    name: 'menu-dots--design',
    path: 'assets/img/design/right-sidebar/menu-dots.svg',
  },
  {
    name: 'shopping-cart-design',
    path: 'assets/img/design/right-sidebar/shopping-cart.svg',
  },
];
