import { FormControl } from '@angular/forms';

import { SteamErrorsEnum } from '../enums';

export interface SihParams {
  online: boolean;
  permission: boolean;
}
export interface IUserP2pPermissionInterface {
  success: boolean;
  error?: SteamErrorsEnum;
  connections?: {
    SIH: SihParams;
    waxPeer: boolean;
  };
  canTrade?: {
    success: boolean;
    error: SteamErrorsEnum;
    days?: number;
  };
  canSteamAPI?: {
    success: boolean;
    error: SteamErrorsEnum;
  };
}

export interface IP2pSettingsForm {
  tradelink: FormControl;
  apikey: FormControl;
  email: FormControl;
  emailcode: FormControl;
}

export interface IP2pSettingsInputTemplateContext {
  formControlName: keyof IP2pSettingsForm;
  inputInnerTemplate?: 'linkTemplate' | 'confirmTemplate';
  formPlaceholder: string;
  formErrorLocale?: string;
  whereLink?: string;
  whereLinkLocale?: string;
}
