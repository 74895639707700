import { DOCUMENT } from '@angular/common';
import { Inject, inject, Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { MAIN_LANG, PROJECT } from '@dev-fast/types';
import { TranslateService } from '@ngx-translate/core';
import { Select, Store } from '@ngxs/store';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { combineLatest, filter, Observable, take, tap } from 'rxjs';

import { Environments, EnvironmentService } from '@app/core/environment-service';
import { LanguageService } from '@app/core/language-service';
import { IS_SERVER_TOKEN } from '@app/shared/utils';

import { SetData, SetDescription, SetTitle } from './seo.action';
import { FACEBOOK_DATA, GOOGLE_DATA, SeoType, YANDEX_DATA } from './seo.const';
import { SeoState } from './seo.state';
import { ISeoData, SEO_ROUTES, SEO_ROUTES_DEFAULT } from './seo.state.model';

/**
 * Сервис для всего, что связано с маршрутами, ссылками, редиректами внутри проекта
 */
@Injectable({
  providedIn: 'root',
})
export class SeoService {
  readonly #seoRoutesToken: string[] | null = inject(SEO_ROUTES, { optional: true });
  private environment: Environments = this.environmentService.getEnvironment();
  readonly seoRoutes: string[] = this.#seoRoutesToken ?? SEO_ROUTES_DEFAULT;

  constructor(
    private translateService: TranslateService,
    private readonly environmentService: EnvironmentService,
    private readonly activatedRoute: ActivatedRoute,
    private router: Router,
    private title: Title,
    private meta: Meta,
    private readonly store: Store,
    @Inject(IS_SERVER_TOKEN) private isServer: boolean,
    @Inject('seoDefaultData') private readonly defaultSeoData: { title: string; description: string },
    @Inject(DOCUMENT) private doc: Document,
  ) {}
  start(): void {
    this.router.events.pipe(filter((routerEvent) => routerEvent instanceof NavigationEnd)).subscribe(() => {
      let route = this.activatedRoute;
      // Достаем самый крайний route. Нужно для того, чтобы получить язык, который хранится в крайнем коренном route
      while (route.firstChild) {
        route = route.firstChild;
      }
      this.setSeoParams(route);
    });
    this.setCommonMeta();
  }
  @Select(SeoState.data)
  seoData$!: Observable<ISeoData | null>;

  /**
   * Метод выставления метаданных при инициализации
   */
  setCommonMeta(): void {
    this.updateDescription('SEO.DESCRIPTION.DEFAULT', { hostName: this.environment.HOSTNAME });
    this.meta.updateTag({ name: 'og:image', content: ` https://${this.environment.HOSTNAME}/og.image.jpg` });
    this.meta.updateTag({ name: 'twitter:image', content: `https://${this.environment.HOSTNAME}/og.image.jpg` });
    this.meta.updateTag({ name: 'og:site_name', content: this.environment.HOSTNAME });
    if (this.environmentService.isProject({ name: PROJECT.MARKET, exclude: true })) {
      this.setFacebookMeta();
      this.setYandexMeta();
      this.setGoogleMeta();
    }
  }

  /**
   * Вставляет в head ссылку на альтернативный язык сайта
   */
  updateAlternateLinks(link: string, noAlternative: boolean): void {
    const alternates: NodeListOf<HTMLLinkElement> = this.doc.querySelectorAll('link[rel="alternate"]');
    const alternatesLink = link.split('?')[0];

    if (!alternates.length) {
      if (noAlternative) {
        return;
      }
      [MAIN_LANG, ...Object.keys(LanguageService.getRouteLanguages())].forEach((lang, index) => {
        const el = document.createElement('link');
        el.rel = 'alternate';
        el.hreflang = lang;
        el.href =
          'https://' + this.environment.HOSTNAME + (lang === MAIN_LANG ? '' : '/' + lang) + (alternatesLink !== '/' ? alternatesLink : '');
        if (index === 0) {
          el.hreflang = 'x-default';
        }
        this.doc.head.appendChild(el);
      });
    } else {
      if (noAlternative) {
        Array.from(alternates).forEach((el) => el.remove());
        return;
      }
      alternates.forEach(
        (el) =>
          (el.href =
            'https://' +
            this.environment.HOSTNAME +
            (el.hreflang === 'x-default' || el.hreflang === MAIN_LANG ? '' : '/' + el.hreflang) +
            (alternatesLink !== '/' ? alternatesLink : '')),
      );
    }
  }

  /**
   * Добавление метатега домена для фейсбука
   */
  setFacebookMeta(): void {
    const el = this.doc.createElement('meta');
    el.content = FACEBOOK_DATA.CONTENT;
    el.name = FACEBOOK_DATA.NAME;
    this.doc.head.appendChild(el);
  }

  /**
   * Добавление метатега домена для фейсбука
   */
  setGoogleMeta(): void {
    const el = this.doc.createElement('meta');
    el.content = GOOGLE_DATA.CONTENT;
    el.name = GOOGLE_DATA.NAME;
    this.doc.head.appendChild(el);
  }

  /**
   * Добавление метатега домена для фейсбука
   */
  setYandexMeta(): void {
    const el = this.doc.createElement('meta');
    el.content = YANDEX_DATA.CONTENT;
    el.name = YANDEX_DATA.NAME;
    this.doc.head.appendChild(el);
  }

  /**
   * Установка значения в заголовке страницы
   */
  updateTitle(
    titleLocale: string | null = this.store.selectSnapshot(SeoState.title).value,
    data: { [key: string]: string } = this.store.selectSnapshot(SeoState.title).data,
  ): void {
    if (titleLocale) {
      this.setTitle({ value: titleLocale, data });
      this.translateService.stream(titleLocale, data).subscribe((title) => {
        const updatedTitle = (newValue = ''): void => {
          this.updateMeta(newValue);
          this.title.setTitle(newValue);
        };
        if (this.hasTranslate(title, titleLocale)) {
          updatedTitle(title);
        } else {
          const standartTranslate = this.translateService.instant(this.defaultSeoData.title, data);
          updatedTitle(standartTranslate);
        }
      });
    }
  }

  /**
   * обновления og:url
   */
  updateOgUrl(url: string): void {
    this.meta.updateTag({ name: 'og:url', content: url });
    this.meta.updateTag({ name: 'twitter:url', content: url });
  }

  /**
   * Обновление каноничной страницы
   */
  updateCanonicalUrl(url: string): void {
    const canonical: HTMLLinkElement | null = this.doc.querySelector('link[rel="canonical"]');
    const canonicalUrl: string = url.split('?')[0];
    if (canonical) {
      canonical.href = canonicalUrl;
    } else {
      const el: HTMLLinkElement = this.doc.createElement('link');
      el.href = canonicalUrl;
      el.rel = 'canonical';
      this.doc.head.appendChild(el);
    }
  }

  /**
   * Обновление описания сайта
   */
  updateDescription(
    descLocale: string | null = this.store.selectSnapshot(SeoState.description).value,
    data: { [key: string]: string } = this.store.selectSnapshot(SeoState.description).data,
  ): void {
    if (descLocale) {
      this.setDescription({ value: descLocale, data });
      this.translateService
        .stream(descLocale, data)
        .pipe(take(1))
        .subscribe((desc) => {
          const updatedDesc = (newValue = ''): void => {
            this.meta.updateTag({ name: 'description', content: newValue });
            this.meta.updateTag({ name: 'og:description', content: newValue });
            this.meta.updateTag({ name: 'twitter:description', content: newValue });
          };
          if (this.hasTranslate(desc, descLocale)) {
            updatedDesc(desc);
          } else {
            const standartTranslate = this.translateService.instant(this.defaultSeoData.description, data);
            updatedDesc(standartTranslate);
          }
        });
    }
  }

  /**
   * Обновление параметров для сео блоков (iframe компонент в фасте или layout в маркете)
   * Если все параметры равны null или содержат пустые строки, то блок не покажется
   *
   * overwrite - заменять ли на null те свойства, которых нет в новых данных
   * если его не сделать true, то отсутствующие в новых данных свойства будут взяты из прошлых
   *
   * params - переменные для локалей
   */
  updateData(data: ISeoData, params: any = {}, overwrite = false): void {
    const currentData = this.store.selectSnapshot(SeoState.data);
    let seoData: Record<string, any> = overwrite ? { main: null, h1: null, faq: null } : { ...currentData };

    combineLatest(
      Object.entries(data)
        .filter(([seoProp, propValue]) => !!propValue)
        .map(([seoProp, propValue]) => {
          return this.translateService.stream(propValue, params).pipe(
            take(1),
            tap((translation) => {
              const hasNoTranslation = propValue === translation;

              seoData = { ...seoData, [seoProp]: hasNoTranslation ? null : translation.trim() };
            }),
          );
        }),
    ).subscribe(() => {
      this.setSeoData(seoData as ISeoData);
    });
  }

  /**
   * Помечает текущую страницу как недоступную для ботов
   * (используется для неважных для сео страниц)
   */
  setNoIndexMeta(): void {
    this.meta.updateTag({ name: 'robots', content: 'noindex' });
  }

  /**
   * Помечает текущую страницу как доступную для ботов
   * (используется для важных для сео страниц)
   */
  setIndexMeta(): void {
    this.meta.updateTag({ name: 'robots', content: 'all' });
  }

  /**
   * Очищение главного сео-текста текущей страницы
   * (используется в случае, когда текущая страница не важна для СЕО или уже имеет сео-текста хардкодом)
   */
  resetData(): void {
    this.setSeoData({ h1: null, main: null, faq: null });
  }

  /**
   * Добавление к маршрутам СЕО параметров
   */
  setSeoParams(route: any): void {
    const hostName = this.environment.HOSTNAME;
    const seo = route.snapshot.data['seo'];
    const seoType: SeoType[] = route.snapshot.data['seoType'];
    const noAlternative: boolean = route.snapshot.data['noAlternative'] ?? false;
    const languages = Object.keys(LanguageService.getRouteLanguages()).filter((lang) => lang !== MAIN_LANG);

    if (seo) {
      const newData = {
        h1: !seoType || seoType.includes(SeoType.H1) ? `SEO.H1.${seo}` : null,
        main: !seoType || seoType.includes(SeoType.MAIN) ? `SEO.MAIN.${seo}` : null,
        faq: !seoType || seoType.includes(SeoType.FAQ) ? `SEO.FAQ1.${seo}` : null,
      };
      const isAllValuesNull = Object.values(newData).every((value) => value === null);
      isAllValuesNull ? this.resetData() : this.updateData(newData, { hostName }, true);
      this.updateDescription(`SEO.DESCRIPTION.${seo}`, { hostName: this.environment.HOSTNAME });
      this.updateTitle(`SEO.TITLE.${seo}`, { hostName });
    } else {
      this.setDefaultDescription();
      this.setDefaultTitle();
      this.resetData();
    }
    // Если параметры не указаны, то по-умолчанию добавляем все параметры

    this.setSEOIndex();

    this.updateOgUrl(`https://${this.environment.HOSTNAME}${this.router.url === '/' ? '' : this.router.url}`);
    this.updateCanonicalUrl(`https://${this.environment.HOSTNAME}${this.router.url === '/' ? '' : this.router.url}`);

    if (!this.isServer) {
      this.updateAlternateLinks(
        LanguageService.getBaseUrl(languages.includes(this.router.url.split('/')[1]) ? this.router.url.slice(3) : this.router.url),
        noAlternative,
      );
    }
  }

  private setDefaultDescription(): void {
    this.updateDescription(this.defaultSeoData.description, { hostName: this.environment.HOSTNAME });
  }
  private setDefaultTitle(): void {
    this.updateTitle(this.defaultSeoData.title, { hostName: this.environment.HOSTNAME });
  }
  private hasTranslate(translation: string, propValue: string): boolean {
    return translation !== propValue;
  }
  /**
   * Определение пометить ли текущую страницу как важную для сео или неважную
   */
  private setSEOIndex(): void {
    // Если текущая страница не важна для сео, то помечаем её недоступной для ботов
    if (!this.seoRoutes.includes(LanguageService.getBaseUrl(this.router.url))) {
      this.setNoIndexMeta();
    } else {
      this.setIndexMeta();
    }
  }

  /**
   * Обновление мета заголовков страницы <meta/>
   */
  updateMeta(title: string): void {
    this.meta.updateTag({ name: 'og:title', content: title });
    this.meta.updateTag({ name: 'twitter:title', content: title });
  }

  @Dispatch() setSeoData = (data: ISeoData): SetData => new SetData(data);
  @Dispatch() setTitle = (title: { value: string; data: any }): SetTitle => new SetTitle(title);
  @Dispatch() setDescription = (desc: { value: string; data: any }): SetDescription => new SetDescription(desc);
}
