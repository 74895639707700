export class Logout {
  static readonly type = '[Auth] Logout';
}
export class LogoutSuccess {
  static readonly type = '[Auth] Logout success';
}
export class Login {
  static readonly type = '[Auth] Login';
  constructor(public payload?: { accessToken: string }) {}
}
export class GetAuthorizationToken {
  static readonly type = '[Auth] Get token';
  constructor(public payload: string) {}
}
export class RemoveAccount {
  static readonly type = '[Auth] Remove account';
  constructor(public payload: string) {}
}
export class BindAccount {
  static readonly type = '[Auth] Bind account';
}
export class OpenAuthModal {
  static readonly type = '[Auth] Open Auth Modal';
}
// export class OpenAuthWindow {
//   public static readonly type = '[Auth] Open Auth Window';
//   constructor(public url: string) {}
// }
