import { HttpErrorResponse } from '@angular/common/http';
import { inject, Injectable, Optional } from '@angular/core';
import { P2pApiService } from '@dev-fast/backend-services';
import {
  IDepositBatchPayload,
  IMarketplaceItem,
  IP2pDepositingItem,
  IP2pDepositItem,
  IP2pParticipateItemUpdated,
  ISteamStoreInventory,
  ModalNames,
  OrderStatusEnum,
  Panel,
  SubPanel,
} from '@dev-fast/types';
import { Action, NgxsOnInit, Selector, State, StateContext, Store } from '@ngxs/store';
import { insertItem, patch, removeItem, updateItem } from '@ngxs/store/operators';
import { delay, EMPTY, Observable, of } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';

import { FrameMessageTypes, IFrameMessageService } from '@app/core/iframe';
import { LocalStorageService } from '@app/core/local-storage-service';
import { NotificationsService } from '@app/core/notification-service';
import { ChangeActivePanel, LayoutState } from '@app/core/state/layout';
import { CloseModal, ModalsState, OpenModal } from '@app/core/state/modals';
import { RefreshCurrentUser, UserState } from '@app/core/state/user-store';

import {
  AutoShowP2PAuctionCompletedModal,
  ChangeSelectedItem,
  Deposit,
  DepositSelected,
  GetDepositing,
  GetDepositModifier,
  GetDepositModifierSuccess,
  OpenModifierDialogInBB,
  ParticipantItemUpdatedEvent,
  PauseDepositing,
  RemoveDepositItem,
  RemoveMarketItems,
  ReqAppointing,
  ResetSelected,
  ResumeDepositing,
  SellNow,
  SetDepositModifier,
  StopDepositing,
  ToggleDepositItem,
} from './deposit.actions';
import { P2P_DEPOSIT_INITIAL_STATE, P2pDepositStateModel } from './deposit-state.model';

@State<P2pDepositStateModel>({
  name: 'deposit',
  defaults: P2P_DEPOSIT_INITIAL_STATE,
})
@Injectable()
export class P2pDepositState implements NgxsOnInit {
  readonly #apiService = inject(P2pApiService);
  readonly #store = inject(Store);
  readonly #notificationsService = inject(NotificationsService);
  readonly #storageService = inject(LocalStorageService);

  constructor(@Optional() private readonly frameMessageService: IFrameMessageService) {}

  @Selector()
  static depositingItems({ depositingItems }: P2pDepositStateModel): IP2pDepositingItem[] {
    return depositingItems;
  }
  @Selector()
  static isItemsOnPause({ depositingItems }: P2pDepositStateModel): boolean {
    return depositingItems.some((el) => el.status === OrderStatusEnum.PAUSED);
  }
  @Selector()
  static selected({ selected }: P2pDepositStateModel): IP2pDepositItem[] {
    return selected;
  }
  @Selector()
  static selectedSum({ selected }: P2pDepositStateModel): number {
    return selected.reduce((a, b) => a + (b.preferredPrice ? b.preferredPrice : b.price), 0);
  }
  // public static depositById(id: number) {
  //   return createSelector([P2pDepositState], (ny: P2pDepositStateModel) => {
  //     return ny.selected.find((s) => s.steamInventoryId === id);
  //   });
  // }
  @Selector()
  static depositById(state: P2pDepositStateModel) {
    return (id: number) => {
      return state.selected.find((s) => s.steamInventoryId === id);
    };
  }

  ngxsOnInit({ getState, dispatch }: StateContext<P2pDepositStateModel>): void {
    this.#apiService.participantItemUpdatedEvent((payload: IP2pParticipateItemUpdated) => {
      const { depositingItems } = getState();
      const element = depositingItems.find((el) => el.id === payload.id);

      if (element) {
        if (element.status === OrderStatusEnum.WAIT_FOR_TRADE && payload.status === OrderStatusEnum.CRATED) {
          return;
        }
        dispatch(new ParticipantItemUpdatedEvent({ ...element, ...payload }));
      }
    });
    if (this.frameMessageService) {
      this.frameMessageService.on(FrameMessageTypes.MESSAGE_FROM_BB, 'p2p.ToggleDepositItem', ({ item }: { item: ISteamStoreInventory }) =>
        dispatch(new ToggleDepositItem(this.#addExtraPrice(item))),
      );
      this.frameMessageService.on(FrameMessageTypes.MESSAGE_FROM_BB, 'p2p.ChangeSelectedItem', ({ item }: { item: ISteamStoreInventory }) =>
        dispatch(new ChangeSelectedItem(this.#addExtraPrice(item))),
      );
      this.frameMessageService.on(FrameMessageTypes.MESSAGE_FROM_BB, 'p2p.RemoveDepositItem', ({ item }: { item: IP2pDepositItem }) =>
        dispatch(new RemoveDepositItem(item)),
      );
    }
  }
  @Action(RefreshCurrentUser)
  refreshUser({ dispatch, getState }: StateContext<P2pDepositStateModel>, { payload }: RefreshCurrentUser): void {
    if (payload.id && payload.id !== null) {
      dispatch([new GetDepositing()]);
    }
  }
  @Action(AutoShowP2PAuctionCompletedModal)
  autoShowP2PAuctionCompletedModal({ dispatch, getState }: StateContext<P2pDepositStateModel>): Observable<void> {
    const { depositingItems } = getState();
    const filteredItemForDeposit = depositingItems.filter(
      (el) =>
        el.status === OrderStatusEnum.WAIT_FOR_TRADE &&
        el.order &&
        el.order.steamItemPosition &&
        el.order.steamItemPosition.length &&
        el.order.buyerTradeLink,
    );
    const sorted = filteredItemForDeposit.sort((l, r) =>
      Date.parse(l.statusAt) && Date.parse(r.statusAt) ? (Date.parse(l.statusAt) > Date.parse(r.statusAt) ? -1 : 1) : 0,
    );
    const lastItemForDeposit = sorted[0];
    const activeModals = this.#store.selectSnapshot(ModalsState.activeModals);

    if (lastItemForDeposit && (!activeModals.length || (activeModals.length && !activeModals.includes(ModalNames.P2P_AUCTION_COMPLETED)))) {
      return dispatch(new OpenModal(ModalNames.P2P_AUCTION_COMPLETED, of(lastItemForDeposit)));
    } else {
      return of();
    }
  }

  @Action(RemoveDepositItem)
  removeDepositItem({ setState }: StateContext<P2pDepositStateModel>, { payload }: RemoveDepositItem): void {
    setState(
      patch({
        selected: removeItem<IP2pDepositItem>((x) => x?.steamInventoryId === payload.steamInventoryId),
      }),
    );
    if (this.frameMessageService) {
      this.frameMessageService.sendMessage({
        type: FrameMessageTypes.MESSAGE_TO_BB,
        eventName: 'p2p.RemoveDepositItem',
        payload: { item: payload },
      });
    }
  }
  @Action(ToggleDepositItem)
  toggleDepositItem({ setState, getState, dispatch }: StateContext<P2pDepositStateModel>, { payload }: ToggleDepositItem): void {
    const { selected } = getState();
    const hasInSelected = selected.some((el) => el.steamInventoryId === payload.steamInventoryId);

    if (hasInSelected) {
      // dispatch(new RemoveDepositItem(payload));
      // так специально что бы отреагировать на RemoveDepositItem в бэкбоне
      setState(
        patch({
          selected: removeItem<IP2pDepositItem>((x) => x?.steamInventoryId === payload.steamInventoryId),
        }),
      );
    } else {
      const activePanel = this.#store.selectSnapshot(LayoutState.activePanel);
      if ((activePanel === null || activePanel.panel !== Panel.TRADES) && selected.length === 0) {
        dispatch(new ChangeActivePanel({ panel: Panel.TRADES, subPanel: SubPanel.NONE }));
      }
      setState(patch({ selected: insertItem({ ...payload }) }));
    }
  }
  @Action(ParticipantItemUpdatedEvent)
  participantItemUpdated({ setState, dispatch }: StateContext<P2pDepositStateModel>, { payload }: ParticipantItemUpdatedEvent): void {
    if (payload.status && payload.status === OrderStatusEnum.DELETED) {
      setState(
        patch({
          depositingItems: removeItem<IP2pDepositingItem>((x) => x?.id === payload.id),
        }),
      );
    } else {
      setState(
        patch({
          depositingItems: updateItem<IP2pDepositingItem>(
            (o) => o?.id === payload.id,
            payload,
            // patch({
            //   ...payload,
            // })
          ),
        }),
      );
      if (payload.status === OrderStatusEnum.WAIT_FOR_TRADE && payload.order && payload.order.steamItemPosition) {
        dispatch(new AutoShowP2PAuctionCompletedModal());
      }
      const activeModals = this.#store.selectSnapshot(ModalsState.activeModals);

      if (
        activeModals.length &&
        activeModals.includes(ModalNames.P2P_AUCTION_COMPLETED) &&
        payload.status !== OrderStatusEnum.WAIT_FOR_TRADE
      ) {
        dispatch(new CloseModal(ModalNames.P2P_AUCTION_COMPLETED));
      }
    }
  }

  @Action(OpenModifierDialogInBB)
  openModifierDialog({ dispatch, getState, patchState }: StateContext<P2pDepositStateModel>, { item }: OpenModifierDialogInBB): void {
    if (!this.frameMessageService) {
      return;
    }
    this.frameMessageService.sendMessage({
      type: FrameMessageTypes.MESSAGE_TO_BB,
      eventName: 'openModifierDialog',
      payload: { item },
    });
  }
  @Action(GetDepositing)
  getDepositing({ patchState, dispatch, getState }: StateContext<P2pDepositStateModel>): Observable<void> {
    const { depositingItems } = getState();
    return this.#apiService.getMyItems().pipe(
      tap((response) => {
        // this.frameMessageService.sendMessage({
        //   type: FrameMessageTypes.MESSAGE_TO_BB,
        //   eventName: 'GetDepositing',
        //   payload: { items: response.items },
        // });
        return patchState({ depositingItems: this.#sortByStatus(response.items) });
      }),
      delay(7000),
      switchMap(() => {
        if (depositingItems.length === 0) {
          return dispatch(new AutoShowP2PAuctionCompletedModal());
        } else {
          return of();
        }
      }),
    );
  }
  @Action(ResetSelected)
  resetSelected({ patchState }: StateContext<P2pDepositStateModel>): void {
    patchState({ selected: [] });
    if (this.frameMessageService) {
      this.frameMessageService.sendMessage({
        type: FrameMessageTypes.MESSAGE_TO_BB,
        eventName: 'p2p.deposit.ResetSelected',
        payload: { items: [] },
      });
    }
  }
  @Action(StopDepositing)
  stopDepositing({ dispatch, getState }: StateContext<P2pDepositStateModel>): Observable<void> {
    const { depositingItems } = getState();
    //TODO
    const excludesStatuses: OrderStatusEnum[] = [OrderStatusEnum.AUCTION_FINISHED, OrderStatusEnum.COMPLETED, OrderStatusEnum.DELETED];
    const ids = depositingItems.filter((val) => !excludesStatuses.includes(val.status)).map((el) => el.id);

    return dispatch(new RemoveMarketItems(ids));
  }
  @Action(PauseDepositing)
  pauseDepositing(
    { getState, patchState, dispatch }: StateContext<P2pDepositStateModel>,
    { id }: PauseDepositing,
  ): Observable<void> | Observable<{ items: IMarketplaceItem[] }> {
    const { depositingItems } = getState();
    //TODO тупо, очень тупо
    const includesStatuses: OrderStatusEnum[] = [OrderStatusEnum.NEW];
    const ids = id ? [id] : depositingItems.filter((val) => includesStatuses.includes(val.status)).map((el) => el.id);
    if (ids.length === 0) {
      return dispatch(new StopDepositing());
    }
    return this.#apiService.reqPause(ids).pipe(
      tap((resp) => {
        const oldItems = getState().depositingItems;
        const respItemsIds = resp.items.map((el) => el.id);

        const filteredItems = oldItems.filter((el) => !respItemsIds.includes(el.id));
        patchState({ depositingItems: this.#sortByStatus([...filteredItems, ...resp.items]) });
      }),
    );
  }
  @Action(ResumeDepositing)
  resumeDepositing(
    { patchState, getState }: StateContext<P2pDepositStateModel>,
    { id }: ResumeDepositing,
  ): Observable<{
    errors: { error: string; id?: number | undefined; steamInventoryId?: number | undefined }[];
    items: IMarketplaceItem[];
  }> {
    const { depositingItems } = getState();
    //TODO тупо, очень тупо
    const includesStatuses: OrderStatusEnum[] = [OrderStatusEnum.PAUSED];
    const ids = id ? [id] : depositingItems.filter((val) => includesStatuses.includes(val.status)).map((el) => el.id);

    return this.#apiService.reqResume(ids).pipe(
      tap((resp) => {
        const oldItems = getState().depositingItems;
        const respItemsIds = resp.items.map((el) => el.id);

        const filteredItems = oldItems.filter((el) => !respItemsIds.includes(el.id));
        patchState({ depositingItems: this.#sortByStatus([...filteredItems, ...resp.items]) });
      }),
    );
  }

  @Action(RemoveMarketItems)
  removeMarketItem(
    { getState, patchState }: StateContext<P2pDepositStateModel>,
    { ids }: RemoveMarketItems,
  ): Observable<Observable<never> | { items: IMarketplaceItem[] }> {
    return this.#apiService.reqDelete(ids).pipe(
      tap((resp) => {
        const { depositingItems } = getState();
        const respItemsIds = resp.items.map((el) => el.id);

        const filteredItems = depositingItems.filter((el) => !respItemsIds.includes(el.id));
        patchState({
          depositingItems: this.#sortByStatus(filteredItems),
        });
        // setState(
        //   patch({
        //     depositingItems: removeItem<IP2pDepositingItem>((x) => x?.id === id),
        //   })
        // )}
      }),
      catchError((e) => {
        this.#onError(e);
        return of(EMPTY);
      }),
    );
  }
  @Action(SellNow)
  sellNow({ dispatch }: StateContext<P2pDepositStateModel>, { id }: SellNow): Observable<void | Observable<never>> {
    return this.#apiService.reqSellNow(id).pipe(
      // tap((val) =>
      // setState(
      //   patch({
      //     depositingItems: updateItem<IP2pDepositingItem>(
      //       (o) => o?.id === val.id,
      //       val
      //     ),
      //   })
      // )),
      switchMap((val) => dispatch(new GetDepositing())),
      catchError((e) => {
        this.#onError(e);
        return of(EMPTY);
      }),
    );
  }
  @Action(DepositSelected)
  depositSelected({
    dispatch,
    getState,
  }: StateContext<P2pDepositStateModel>): Observable<
    Observable<never> | { errors: { error: string; steamInventoryId: number }[]; items: IMarketplaceItem[] }
  > {
    const { selected } = getState();
    const additionalSettings = this.#store.selectSnapshot(UserState.additionalSettings);

    const payload: IDepositBatchPayload[] = selected.map(({ autoApprove, steamInventoryId, extra, fastDelivery, tradesDuration }) => ({
      steamInventoryId,
      price: extra.price,
      autoApprove: typeof autoApprove === 'boolean' ? autoApprove : additionalSettings?.market?.autoApprove,
      fastDelivery: typeof fastDelivery === 'boolean' ? fastDelivery : additionalSettings?.fastDelivery,
      tradesDuration: tradesDuration ? Number(tradesDuration) : additionalSettings?.market?.tradesDuration,
    }));

    return this.#apiService.reqDepositSelected(payload).pipe(
      tap((response) => {
        if (response.errors.length) {
          response.errors.forEach((el) => this.#notificationsService.addErrorNotification(el.error));
        }
        return dispatch([new GetDepositing(), new ResetSelected()]);
      }),
      catchError((e) => {
        this.#onError(e);
        return of(EMPTY);
      }),
    );
  }
  @Action(GetDepositModifier)
  getDepositModifier({ dispatch }: StateContext<P2pDepositStateModel>): Observable<void> {
    const preview = this.#storageService.get('preview-deposit-modifier');
    return dispatch(new GetDepositModifierSuccess(preview));
  }
  @Action(SetDepositModifier)
  setDepositModifier({ dispatch, getState, patchState }: StateContext<P2pDepositStateModel>, { payload }: SetDepositModifier): void {
    this.#storageService.set('preview-deposit-modifier', payload);
  }
  @Action(Deposit)
  deposit(
    { dispatch, getState, setState }: StateContext<P2pDepositStateModel>,
    { id, price }: Deposit,
  ): Observable<void | Observable<never>> {
    return this.#apiService.reqDeposit(id, price).pipe(
      catchError((e) => {
        this.#onError(e);
        return of(EMPTY);
      }),
    );
  }
  @Action(ReqAppointing)
  reqAppointing(
    { patchState, setState }: StateContext<P2pDepositStateModel>,
    { id, action }: ReqAppointing,
  ): Observable<Observable<never> | IMarketplaceItem> {
    return this.#apiService.reqAppointing(id, action).pipe(
      tap((val) =>
        setState(
          patch({
            depositingItems: updateItem<IP2pDepositingItem>((o) => o?.id === val.id, val),
          }),
        ),
      ),
      catchError((e) => {
        this.#onError(e);
        return of(EMPTY);
      }),
    );
  }
  @Action(ChangeSelectedItem)
  changeSelectedItem({ setState }: StateContext<P2pDepositStateModel>, { payload }: ChangeSelectedItem): void {
    setState(
      patch({
        selected: updateItem<IP2pDepositItem>((x) => x?.steamInventoryId === payload.steamInventoryId, payload),
      }),
    );
  }

  #onError(e: HttpErrorResponse): void {
    const { error } = e;
    this.#notificationsService.addErrorNotification(error.message || error.error, {
      icon: 'warning',
      system: error.system,
      params: error?.params ?? {},
    });
  }
  #addExtraPrice(item: ISteamStoreInventory): IP2pDepositItem {
    let extra = { price: item.price, increase: 0 };

    // if (!item.custom) {
    extra = {
      price: item.preferredPrice ? item.preferredPrice : Math.floor(item.price - item.price * 0.05),
      increase: item.selectedPercent !== null && item.selectedPercent !== undefined ? item.selectedPercent : -5,
    };
    // }
    return { ...item, extra };
  }
  #sortByStatus(items: IP2pDepositingItem[]): IP2pDepositingItem[] {
    const comparator = (modelA: IP2pDepositingItem, modelB: IP2pDepositingItem): number => {
      if (modelA.status === OrderStatusEnum.THIRD_PLUS_BID) {
        return -1;
      }
      if (modelA.status === OrderStatusEnum.SECOND_BID) {
        return -1;
      }
      if (modelA.status === OrderStatusEnum.FIRST_BID) {
        return -1;
      }
      if (modelA.status === OrderStatusEnum.WAIT_FOR_CONFIRM) {
        return -1;
      }
      if (modelA.status === OrderStatusEnum.WAIT_FOR_TRADE) {
        return -1;
      }
      if (modelA.status === OrderStatusEnum.NEW) {
        return -1;
      }
      // if (modelA.status === OrderStatusEnum.CANCELED_BY_SELLER) return 1;
      // return sortBy(newValue, 'statusAt', 'asc');
      return Date.parse(modelA.statusAt);
    };
    return items.sort(comparator);
  }
}
