import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import {
  BetPanelLegacyComponentModule,
  ExhangePanelModule,
  InventoryWidgetComponentModule,
  UserAvatarModule,
} from '@dev-fast/ui-components';
import { NgScrollbarModule } from 'ngx-scrollbar';

import { SharedComponentsModule } from '@app/shared/components';
import { HasPermissionModule, LangRouterModule, StopPropagationModule } from '@app/shared/directives';
import { CustomAsyncPipeModule } from '@app/shared/pipes';
import { ChatModule } from '@app/widgets/chat';
import { NotificationsWidgetsModule } from '@app/widgets/notifications';
// import { ChatService } from './services/chat.service';
import { P2pWidgetModule } from '@app/widgets/p2p';

import { DesktopRightSidebarComponent } from './desktop-right-sidebar.component';
import { TradesService } from './services/trades.service';
import { WidgetsComponent } from './widgets/widgets.component';

@NgModule({
  imports: [
    CommonModule,
    MatIconModule,
    NgScrollbarModule,
    // TradesWidgetComponentModule,
    InventoryWidgetComponentModule,
    // ChatComponentModule,
    // NotificationsComponentModule,
    NotificationsWidgetsModule,
    UserAvatarModule,
    ExhangePanelModule,
    BetPanelLegacyComponentModule,
    RouterModule,
    CustomAsyncPipeModule,
    ChatModule,
    SharedComponentsModule,
    P2pWidgetModule.forRoot(TradesService),
    HasPermissionModule,
    StopPropagationModule,
    LangRouterModule,
  ],
  declarations: [DesktopRightSidebarComponent, WidgetsComponent],
  exports: [DesktopRightSidebarComponent],
})
export class DesktopAppRightSidebarModule {}
