import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IP2pDepositItem } from '@dev-fast/types';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { P2pDepositService } from '@app/modules/p2p';

@Component({
  selector: 'app-p2p-sell-modifier-modal',
  templateUrl: './p2p-sell-modifier-modal.component.html',
  styleUrls: ['./p2p-sell-modifier-modal.component.scss', '../../../../styles/skin-lot.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class P2pSellModifierModalComponent {
  modifierForm: FormGroup;
  item$: Observable<IP2pDepositItem> | undefined;

  constructor(
    private readonly fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: { id: number },
    readonly dialogRef: MatDialogRef<P2pSellModifierModalComponent>,
    private readonly p2pDepositService: P2pDepositService,
  ) {
    const item = this.p2pDepositService.getDepositById(this.data.id);

    this.modifierForm = this.fb.group({
      increase: [item ? item.extra.increase : null],
    });
    this.#subscribeEmitters();
  }

  get increase(): number {
    return this.modifierForm.value['increase'];
  }

  confirm(price: number): void {
    const item = this.p2pDepositService.getDepositById(this.data.id);
    if (!item) {
      return;
    }
    this.p2pDepositService.setDepositById({ ...item, extra: { price, increase: this.increase } });
    this.dialogRef.close();
  }
  range(price: number, offset: number): number {
    const limit = (offset - price) / (price * 0.01);
    if (limit > 0) {
      return Math.floor(limit);
    }
    return Math.ceil(limit);
  }

  price(price: number): number {
    return Math.round(price + price * (this.increase / 100));
  }

  sliderLabelOffset(minRange: number, maxRange: number): string {
    return `${((this.increase + Math.abs(minRange)) * 100) / (maxRange + Math.abs(minRange)) - 3}%`;
  }

  cancel(): void {
    this.dialogRef.close();
  }

  #subscribeEmitters(): void {
    this.item$ = this.p2pDepositService.selected$.pipe(
      map((selected) => selected.find((el) => el.steamInventoryId === this.data.id)),
      filter((el): el is IP2pDepositItem => !!el),
    );
  }
}
