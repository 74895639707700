import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, NgModule, Output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-ui-tab-group',
  templateUrl: './tab-group.component.html',
  styleUrls: ['./tab-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabGroupComponent {
  @Input() activeTab: number | null = 0;
  @Input() tabGroupClass = '';
  @Input() tabLabels!: string[] | null;
  @Output() activeTabChanged: EventEmitter<number> = new EventEmitter<number>();

  updateActiveTab(tabIndex: number): void {
    this.activeTabChanged.emit(tabIndex);
  }
}

@NgModule({
  imports: [CommonModule, MatIconModule, MatTabsModule, TranslateModule],
  declarations: [TabGroupComponent],
  exports: [TabGroupComponent],
})
export class TabGroupModule {}
