import { AsyncPipe, JsonPipe, Location, NgIf, NgSwitch, NgSwitchCase, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, DestroyRef, inject, Input, OnDestroy, OnInit, signal } from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { ActivatedRoute, Params, QueryParamsHandling, Router } from '@angular/router';
import { IFilterFormContent, IFilterMethod, ISteamInventoryCachedAt, MarketPanel, MarketSortingTypes, ModalNames } from '@dev-fast/types';
import { TranslateModule } from '@ngx-translate/core';
import { DateTime } from 'luxon';
import moment from 'moment';
import { debounceTime, merge, Observable, Subscription } from 'rxjs';
import { filter, map, startWith, tap } from 'rxjs/operators';

import { DForms, DynamicFormsService, IDFormPrepared } from '@app/core/dynamic-forms-service';
import { SortingEnum } from '@app/core/state/p2p';
import { IS_SERVER_TOKEN, queryPicker } from '@app/shared/utils';
import { ItemsFilterModule, SelectionMenuSAComponent } from '@app/ui/components/index';
import { TabGroupModule } from '@app/ui/components/lib/tab-group/tab-group.component';
import { SihCheckButtonComponent } from '@app/widgets/sih';

import { P2pMarketService, P2PModalService } from '../services';
import { formatTimer } from './p2p-market-filter.functions';

/**
 * Компонент фильтров маркета.
 * Содержит поиск по имени, по редкости, по типу предметов и тп
 * Можно взять за основу если нужно создать компонент подробных фильтров предметов где-либо еще
 */
@Component({
  selector: 'app-p2p-market-filter',
  templateUrl: './p2p-market-filter.component.html',
  styleUrls: ['./p2p-market-filter.component.scss'],
  standalone: true,
  imports: [
    FormsModule,
    NgSwitch,
    NgSwitchCase,
    TabGroupModule,
    ReactiveFormsModule,
    ItemsFilterModule,
    AsyncPipe,
    MatFormFieldModule,
    MatIconModule,
    SelectionMenuSAComponent,
    TranslateModule,
    NgIf,
    NgTemplateOutlet,
    JsonPipe,
    MatInputModule,
    SihCheckButtonComponent,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class P2pMarketFilterComponent implements OnInit, OnDestroy {
  readonly #destroyRef = inject(DestroyRef);
  readonly #router = inject(Router);
  readonly #route = inject(ActivatedRoute);
  readonly #location = inject(Location);
  readonly #isServer: boolean = inject(IS_SERVER_TOKEN);
  readonly #dynamicFormsService: DynamicFormsService = inject(DynamicFormsService);
  readonly #p2pMarketService: P2pMarketService = inject(P2pMarketService);
  readonly #p2pModalService: P2PModalService = inject(P2PModalService);

  @Input() set resetFiltersEvent(value: Date | null) {
    if (value) {
      this.resetFilters();
    }
  }
  // Количество найденных предметов в процессе фильтрации
  readonly searchResultCount = toSignal(this.#p2pMarketService.preSearchCount$);
  // Количество найденных предметов после фильтрации
  readonly resultCount = toSignal(this.#p2pMarketService.searchCount$);

  // Дата последнего кэширования инвентаря
  readonly steamInventoryCachedAt = toSignal(this.#p2pMarketService.steamInventoryCachedAt$.pipe(map(formatTimer)));

  // Ищутся ли сейчас предметы
  readonly isLoading = toSignal(this.#p2pMarketService.isLoading$);

  //Выбранная вкладка. Переход на вкладку может произойти еще из app-p2p-sell-adviser
  readonly activeTab = toSignal<MarketPanel>(this.#p2pMarketService.activeTab$);

  // Способы сортировки предметов (по price/overprice)
  readonly sortingMethods = toSignal<IFilterMethod<MarketSortingTypes>[] | null>(
    this.#p2pMarketService.sortingMethods$.pipe(startWith([])),
  );
  readonly MarketPanelEnum = MarketPanel;

  readonly sortingIndex = signal(SortingEnum[MarketSortingTypes.MAX_PRICE]); // Текущий метод сортировки

  // Текущая открытая вкладка (по умолчанию MarketPanel.Purchase)
  activeMarketTab = signal<MarketPanel>(MarketPanel.Purchase);
  // Открыта ли панель подробных фильтров
  isFilterPanelOpened = signal(false);
  // Открыто ли поле ввода поиска по названию (актуально для малых разрешений)
  isSearchFieldOpened = signal(false);

  #currentQueryParams: Record<string, string> | null = null;
  // Consts
  #defaultFilterFormValue: IFilterFormContent<MarketSortingTypes> = {
    minPrice: 0,
    maxPrice: null,
    query: '',
    sortBy: MarketSortingTypes.MAX_PRICE,
  };
  //Forms
  // Значения формы по-умолчанию. Нужно для того, чтобы показывать изменены ли фильтры
  initFormValues!: IFilterFormContent<MarketSortingTypes>;
  // Данные формы из сервиса DynamicForms
  dformData!: IDFormPrepared;
  // Готовая форма из данных this.dformData
  filterForm!: FormGroup;
  // Текущая подписка на изменения формы (у каждого таба она своя. При переключении нужно отписываться от старой)
  changeFormSubscription!: Subscription;

  /**
   * Инициализирует форму фильтрации рынка.
   *
   * @param tab - Выбранный таб панели рынка.
   * @param formInitValue - Начальные значения формы фильтрации.
   * Проверка активного таба на покупку
   */
  readonly isSell = computed(() => this.activeMarketTab() === MarketPanel.Sell);

  /**
   * Проверка активного таба на покупку
   */
  readonly isPurchase = computed(() => this.activeMarketTab() === MarketPanel.Purchase);

  // ----- ANGULAR LIFE CYCLE -----
  ngOnInit(): void {
    merge(this.watchQueryParams).pipe(takeUntilDestroyed(this.#destroyRef)).subscribe();

    if (!this.#isServer) {
      this.#p2pMarketService.requestCustomCasesMeta();
    }
  }

  ngOnDestroy(): void {
    if (this.#isServer) {
      return;
    }
    this.resetFilters();
    this.#setLocationState({ type: null }, '');
  }

  /**
   * Возвращает наблюдаемый поток, который эмитирует объект с параметрами запроса.
   * @returns Наблюдаемый поток с объектом, содержащим параметры запроса.
   */
  get watchQueryParams(): Observable<Record<string, string>> {
    return this.#route.queryParams.pipe(
      map((params) => (params = queryPicker(params, ['type', 'weapon', 'otherType', 'query']))), // берем из урла только нужные поля
      filter((params: Record<string, string>) => {
        return this.#isNewQueryParamsDiffer(params, this.#currentQueryParams); // если парметры не поменялись то нет смысла реагировать
      }),
      tap((params) => {
        this.#currentQueryParams = params;
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { type: _, ...filtersFromParams } = params;
        this.changeActiveTab(
          params['type'] === 'sell' ? MarketPanel.Sell : MarketPanel.Purchase,
          filtersFromParams as IFilterFormContent<MarketSortingTypes>,
        );
      }),
    );
  }

  /**
   * Динамическое создание формы под текущий выбранный tab
   */
  initForm(tab: MarketPanel, formInitValue?: IFilterFormContent<MarketSortingTypes>): void {
    formInitValue = { ...this.#defaultFilterFormValue, ...formInitValue };
    this.dformData = this.#dynamicFormsService.getForm(DForms.MarketForm, tab);
    // Та часть, что будет всегда, независимо от того какой таб
    if (this.filterForm) {
      this.resetFilters();
    }

    this.filterForm = new FormGroup({
      query: new FormControl(''),
      sortBy: new FormControl(MarketSortingTypes.MAX_PRICE),
    });
    // Часть, что строится отдельно для каждого таба
    Object.entries(this.dformData.controls).forEach(([controlKey, control]: [key: string, control: any]) => {
      this.filterForm.addControl(controlKey, control.control);
    });

    // При создании формы запоминаем какие значения у неё по умолчанию, чтобы потом сравнивать
    this.initFormValues = this.filterForm.value;

    if (this.#isServer) {
      return;
    }

    // Отписываемся от прошлой формы
    if (this.changeFormSubscription) {
      this.changeFormSubscription.unsubscribe();
    }
    // Заполняет форму или дефолтными значениями или значениями из урла страницы
    Object.entries(formInitValue).forEach(([controlKey, value]: [key: string, control: any]) => {
      this.filterForm.get(controlKey)?.setValue(value);
    });

    if (tab === MarketPanel.Purchase) {
      this.#p2pMarketService.getMarketItems(formInitValue);
    } else {
      this.#p2pMarketService.getSteamInventory(formInitValue);
    }

    // Подписываемся на текущую форму
    this.changeFormSubscription = this.filterForm.valueChanges
      .pipe(takeUntilDestroyed(this.#destroyRef), debounceTime(500))
      .subscribe((values) => {
        this.sortingIndex.set(SortingEnum[values.sortBy as MarketSortingTypes]);
        this.requestItems(values);
      });
  }

  /**
   * Отправляем текущие примененные фильтры (в зависимости от того какой таб открыт и открыты ли фильтры)
   */
  requestItems(values: IFilterFormContent<MarketSortingTypes>): void {
    const isPurchaseTabActive = this.isPurchase();
    const shouldChangeFilters = isPurchaseTabActive && this.isFilterPanelOpened();
    const shouldGetMarketItems = isPurchaseTabActive && !this.isFilterPanelOpened();
    const shouldChangeInventoryFilters = !isPurchaseTabActive && this.isFilterPanelOpened();
    const shouldGetInventory = !isPurchaseTabActive && !this.isFilterPanelOpened();

    if (shouldChangeFilters) {
      this.#p2pMarketService.changeFilters(values);
      return;
    }

    if (shouldGetMarketItems) {
      this.#p2pMarketService.getMarketItems(values);
      return;
    }

    if (shouldChangeInventoryFilters) {
      this.#p2pMarketService.changeSteamInventoryFilters(values);
      return;
    }

    if (shouldGetInventory) {
      this.#p2pMarketService.getSteamInventory(values);
    }
  }

  /**
   * Применение фильтров при открытой панели фильтров
   */
  applyFilters(): void {
    this.isFilterPanelOpened.set(false);
    this.isSearchFieldOpened.set(false);
    if (this.activeMarketTab() === MarketPanel.Purchase) {
      this.#p2pMarketService.applyFilters();
      return;
    }

    this.#p2pMarketService.applySteamInventoryFilters();
  }

  /**
   * Открытие модалки фильтров (Актуально для маленьких разрешений)
   */
  mobileFilterPanelOpen(): void {
    this.#p2pModalService.openModal(ModalNames.P2P_FILTER_MOBILE_MENU, {
      filterForm: this.filterForm,
      dForm: this.dformData,
      resetFilters: () => this.resetFilters(),
    });
  }

  /**
   * Сброс фильтров
   */
  resetFilters(resetURL = false, inventoryCachedAt: ISteamInventoryCachedAt | null = null): void {
    const isSell = this.isSell();

    if (isSell && inventoryCachedAt) {
      const secSinceReset = moment.duration(moment().diff(inventoryCachedAt.cachedAt)).asSeconds();
      this.#p2pMarketService.getSteamInventory({ ...this.initFormValues, ignoreCache: secSinceReset > 20 });
    }

    this.filterForm.reset(this.initFormValues, { emitEvent: !isSell });

    if (resetURL) {
      this.#setLocationState({ type: this.isPurchase() ? 'buy' : 'sell' }, '');
    } // Удаляем любые фильтры в урле
    this.sortingIndex.set(0); // Сбрасываем способ сортировки
    this.closePanels();
    this.#p2pMarketService.clearMeta();
  }

  /**
   * Смена способа сортировки (по умолчанию от максимальной цены)
   */
  onSortingTypeChange(methodIndex = 0): void {
    const sortingMethods = this.sortingMethods();

    if (!sortingMethods) {
      return;
    }

    this.sortingIndex.set(methodIndex);
    this.filterForm.controls['sortBy'].patchValue(sortingMethods[methodIndex].value);

    if (this.isSell()) {
      this.sortSteamItems(methodIndex, sortingMethods);
    }
  }
  /**
   * Возвращает массив методов сортировки для дропдауна
   */
  sortingMethodsArr(): any {
    const sortingMethods = this.sortingMethods();
    return sortingMethods?.map((s) => s.dropdownItem);
  }

  /**
   * Обновить форму без сброса фильтров
   */
  refreshFilters(inventoryCachedAt: ISteamInventoryCachedAt | null = null): void {
    this.#refreshFiltersBySell(inventoryCachedAt);
    this.#refreshFiltersByBuy();

    this.closePanels();
  }

  /**
   * Разворачивает строку поиска (на мобилке по умолчанию не строка, а кнопка)
   */
  openMobileSearchForm(): void {
    this.isSearchFieldOpened.update((v) => !v);
  }

  updateCurrentTab(newTab: MarketPanel): void {
    if (newTab === MarketPanel.Purchase) {
      const { queryParams } = this.#route.snapshot;
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { type: _, ...filtersFromParams } = queryParams;
      this.changeActiveTab(newTab, filtersFromParams as IFilterFormContent<MarketSortingTypes>);
      this.#setLocationState(filtersFromParams, 'merge');
    } else {
      this.changeActiveTab(newTab);
      this.#setLocationState({ type: 'sell' }, '');
    }
  }
  /**
   * Меняем текущий активный таб
   */
  changeActiveTab(newTab: MarketPanel, formInitValue?: IFilterFormContent<MarketSortingTypes> | undefined): void {
    this.activeMarketTab.set(newTab);
    this.#p2pMarketService.changeActiveTab(newTab);
    this.initForm(newTab, formInitValue); // Создаем новую форму под новый таб
  }

  /**
   * Закрываем все открытые панельки
   */
  closePanels(): void {
    this.isFilterPanelOpened.set(false); // Закрываем панель
    this.isSearchFieldOpened.set(false); // Закрываем поиск
  }

  /**
   * Очистка фильтров
   */
  clearFiltersEvent(): void {
    this.#p2pMarketService.clearMeta();
  }

  /**
   * Очистка строки поиска (Актуально только для десктопа)
   */
  clearSearchField(): void {
    this.filterForm.controls['query'].patchValue('');
  }

  /**
   * Изменения статуса панели фильтров (отерыто/закрыто)
   */
  changePanelStatus(isPanelOpen: boolean): void {
    this.isFilterPanelOpened.set(isPanelOpen);
    this.#p2pMarketService.clearMeta();
  }

  sortSteamItems(sortingMethodIndex: number, sortingMethods: IFilterMethod<MarketSortingTypes>[] | null): void {
    if (sortingMethods) {
      this.#p2pMarketService.sortSteamInventoryByMethod(sortingMethods[sortingMethodIndex].value);
    }
  }

  /**
   * Меняем query в зависимотсти от таба, на который переключаем
   */
  #setLocationState(newQueryParams: Params = {}, queryParamsHandling: QueryParamsHandling = 'merge'): void {
    // Создаем новый URL с обновленным query параметром
    const currentQueryParams = this.#route.snapshot.queryParams;

    const newUrl = this.#router
      .createUrlTree([], {
        queryParams: { ...currentQueryParams, ...newQueryParams },
        queryParamsHandling: queryParamsHandling,
      })
      .toString();

    // Обновляем URL без перезагрузки компонента
    this.#location.replaceState(newUrl);
  }
  #isNewQueryParamsDiffer = (nQueryParams: Record<string, string>, cQuerryParams: Record<string, string> | null): boolean => {
    if (!cQuerryParams) {
      return true;
    }
    const newQueryParamsKeys = Object.keys(nQueryParams);
    const currentQueryParamsKeys = Object.keys(cQuerryParams);
    if (newQueryParamsKeys.length !== currentQueryParamsKeys.length) {
      return true;
    }
    for (const qkey of newQueryParamsKeys) {
      if (nQueryParams[qkey] !== cQuerryParams[qkey]) {
        return true;
      }
    }
    return false;
  };

  /**
   * Обновить фильтры для вкладки продажи
   */
  #refreshFiltersByBuy(): void {
    if (this.isSell()) {
      return;
    }

    const formData = this.filterForm.getRawValue();

    this.#p2pMarketService.getMarketItems(formData);
  }

  /**
   * Обновить фильтры для вкладки покупки
   */
  #refreshFiltersBySell(inventoryCachedAt: ISteamInventoryCachedAt | null = null): void {
    const CACHE_TIME = 10;

    if (!this.isSell()) {
      return;
    }

    const { cachedAt = new Date() } = inventoryCachedAt || {};
    const cachedAtDate = typeof cachedAt === 'string' ? DateTime.fromISO(cachedAt) : DateTime.fromJSDate(cachedAt);

    const secSinceReset = DateTime.now().diff(cachedAtDate).as('seconds');

    this.#p2pMarketService.getSteamInventory({
      ...this.filterForm.value,
      ignoreCache: secSinceReset > CACHE_TIME,
    });
  }
}
