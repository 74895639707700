import { animate, query, state, style, transition, trigger } from '@angular/animations';

export const emojiAnimation = trigger('emojiAnimation', [
  state('true', style({ opacity: 1 })),
  transition('false => true', [animate('150ms ease-out', style({ top: '-2rem' })), animate('150ms ease-out', style({ opacity: 0 }))]),
  transition('true => true', animate('150ms ease-out', style({ top: '-2rem', opacity: 0 }))),
]);

// export const emojiAnimation = trigger('emojiAnimation', [
// 	transition('void => *',  [
// 		query(':self',
// 		[
// 		  style({ opacity: 1 }),
// 		  animate('150ms ease-out', style({top: '-2rem'})),
// 		  animate('150ms ease-out', style({opacity: 0})),
// 		])]),
//   ])
