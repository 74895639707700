import { ComponentType } from '@angular/cdk/overlay';
import { inject, Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ModalConfig, ModalModel, ModalNames, RoutableModalNames } from '@dev-fast/types';
import { Navigate, RouterNavigation } from '@ngxs/router-plugin';
import { Actions, ofActionSuccessful, Select, Store } from '@ngxs/store';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { from, map, mergeMap, Observable, switchMap, tap, throwError, timer } from 'rxjs';

import { CloseAllModals, CloseModal, CloseRoutableModal, ModalOpened, ModalsState, OpenRoutableModal } from '@app/core/state/modals';
import { UserState } from '@app/core/state/user-store';
import { RouterStateParams } from '@app/core/state/utils';
import { IS_SERVER_TOKEN } from '@app/shared/utils';

import { MobileModificatorComponent } from './mobile-modificator/mobile-modificator.component';

@Injectable({ providedIn: 'root' })
export class ModalsService {
  readonly #dialog = inject(MatDialog);
  readonly #actions$ = inject(Actions);
  readonly #store = inject(Store);
  readonly #router = inject(Router);
  readonly #isServer = inject(IS_SERVER_TOKEN);
  readonly #modalsDictionary: Map<ModalNames | RoutableModalNames, MatDialogRef<any> | null> = new Map();

  protected modalsList: Record<string, (...args: any[]) => Observable<any>> = {};
  protected lazyModalsList: Record<string, (...args: any[]) => Promise<any>> = {};

  @Select(ModalsState.activeModals)
  activeModals$!: Observable<ModalNames[]>;
  @Select(ModalsState.activeRoutableModal)
  activeRoutableModal$!: Observable<RoutableModalNames | null>;
  @Select(ModalsState.isSomeModalOpened)
  isSomeModalOpened$!: Observable<boolean>;

  #_modalOpened$ = this.#actions$.pipe(
    ofActionSuccessful(ModalOpened),
    map((action: ModalOpened) => ({ name: action.name, payload: action.payload })),
  );
  #_navigated$ = this.#actions$.pipe(
    ofActionSuccessful<any>(RouterNavigation<RouterStateParams>),
    map((action: RouterNavigation<RouterStateParams>) => action),
  );
  #_routableModalOpened$ = this.#actions$.pipe(
    ofActionSuccessful(OpenRoutableModal),
    map((action: OpenRoutableModal) => ({ name: action.name, payload: action.payload })),
  );

  #_modalClosed$ = this.#actions$.pipe(
    ofActionSuccessful(CloseModal),
    map((action: CloseModal) => ({ name: action.name, payload: action.payload })),
  );
  #_routableModalClosed$ = this.#actions$.pipe(ofActionSuccessful(CloseRoutableModal));
  #_allModalClosed$ = this.#actions$.pipe(ofActionSuccessful(CloseAllModals));
  constructor() {
    if (this.#isServer) {
      return;
    }
    this.#_modalOpened$
      .pipe(
        mergeMap((el) => this.#_openDialog(el.name, el.payload)),
        tap((val) => {
          if (this.#modalsDictionary.has(val.name)) {
            this.#modalsDictionary.delete(val.name);
            this.closeModal(val.name, val.payload);
          }
        }),
      )
      .subscribe();
    this.#_routableModalOpened$
      .pipe(
        mergeMap((el) => this.#_openRoutableDialog(el.name, el.payload)),
        tap((val) => {
          if (this.#modalsDictionary.has(val.name)) {
            this.#modalsDictionary.delete(val.name);
            this.closeRoutableModal();
          }
        }),
      )
      .subscribe();
    this.#_modalClosed$
      .pipe(
        tap((val) => {
          if (this.#modalsDictionary.has(val.name)) {
            this.#modalsDictionary.get(val.name)?.close();
            this.#modalsDictionary.delete(val.name);
          }
        }),
      )
      .subscribe();
    this.#_routableModalClosed$
      .pipe(
        tap((val) => {
          if (this.#modalsDictionary.has(RoutableModalNames.COMMON)) {
            this.#modalsDictionary.get(RoutableModalNames.COMMON)?.close();
            this.#modalsDictionary.delete(RoutableModalNames.COMMON);
          }
        }),
      )
      .subscribe();
    this.#_navigated$
      .pipe(
        tap((val) => {
          this.#handleRoutableModal(val.routerState.url);
        }),
      )
      .subscribe();
    this.#_allModalClosed$
      .pipe(
        tap((val) => {
          this.#modalsDictionary.forEach((value) => {
            if (value) {
              value.close();
            }
          });
          this.#modalsDictionary.clear();
        }),
      )
      .subscribe();
    this.#handleRoutableModal(this.#router.url);
  }
  registerModals<T>(modals: ModalModel<T>[]): void {
    modals.forEach((el) => {
      this.modalsList[el.name as string] = this.#openModalFactory(el.component, el.config);
    });
  }
  registerLazyModals(modals: Partial<Record<ModalNames, () => Promise<ModalModel>>>): void {
    this.lazyModalsList = { ...this.lazyModalsList, ...modals };
  }
  #handleRoutableModal(url: string): void {
    const activeRoutableModal = this.#store.selectSnapshot(ModalsState.activeRoutableModal);
    const isUserBanned = this.#store.selectSnapshot(UserState.isUserBanned);
    if (url.includes('modal:')) {
      if (!activeRoutableModal) {
        if (!isUserBanned) {
          this.openRoutableModal(RoutableModalNames.COMMON);
        } else {
          this.closeRoutableModal();
        }
      }
    } else if (activeRoutableModal) {
      this.closeRoutableModal();
    }
  }
  #openModalFactory(component: ComponentType<any>, config?: ModalConfig) {
    return (name: ModalNames, payload: any) => {
      const params: ModalConfig = {
        ...config,
        data: payload,
        panelClass:
          config?.mobileViewAllowed && config?.panelClass
            ? Array.isArray(config.panelClass)
              ? [...config.panelClass, 'mobile-modificator']
              : 'mobile-modificator'
            : config?.panelClass,
      };
      if (config?.mobileViewAllowed) {
        MobileModificatorComponent.prototype.content = component;
      }
      const dialogRef = this.#dialog.open(config?.mobileViewAllowed ? MobileModificatorComponent : component, params);
      this.#modalsDictionary.set(name, dialogRef);
      return dialogRef.afterClosed().pipe(map((el) => ({ name, payload: el })));
    };
  }
  #_openDialog(name: ModalNames | RoutableModalNames, payload: any): Observable<any> {
    if (!this.modalsList) {
      return throwError(() => new Error(`Modals list empty`));
    }
    if (!this.modalsList[name]) {
      const lazyModal = this.lazyModalsList[name];
      if (!lazyModal) {
        return throwError(() => new Error(`Modal not found`));
      }
      const promiseSource = from(lazyModal());
      return promiseSource.pipe(
        switchMap((val: { inst: any; config: ModalConfig }): any => {
          return this.#openModalFactory(val.inst.getCommonComponentFactory(), (val.config ??= {}))(name as any, payload);
        }),
      );
    }
    return this.modalsList[name](name, payload);
  }
  #_openRoutableDialog(name: ModalNames | RoutableModalNames, payload: any): Observable<any> {
    const due = !this.modalsList[name] ? 700 : 0;
    return timer(due).pipe(switchMap(() => this.modalsList[name](name, payload)));
  }

  // Если уже открыта модалка с роутом, то нативно не получается открыть новый компонент в этой аутлетке. Пока такой Кост можно заюзать.
  safeOpenRoutableModal(path: string[]): void {
    this.#store.dispatch([new Navigate([{ outlets: { modal: null } }]), new Navigate([{ outlets: { modal: [...path] } }])]);
  }
  @Dispatch() closeModal = <T>(name: ModalNames, payload?: T): CloseModal => new CloseModal(name, payload);
  @Dispatch() openRoutableModal = <T>(name: RoutableModalNames, payload?: T): OpenRoutableModal => new OpenRoutableModal(name, payload);
  @Dispatch() closeRoutableModal = (payload?: { url?: string; queryParams?: Record<string, string> }): [CloseRoutableModal] => {
    return [new CloseRoutableModal(payload)];
  };
}
