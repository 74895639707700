<form
  *ngIf="filterForm && controls"
  [formGroup]="filterForm"
  #filterPanel
  class="filterPanel flex tw-font-normal"
>
  <div
    class="filterCategory border-right tw-mr-6 pr-2"
    *ngFor="let filterCategory of controls"
  >
    <ng-container *ngFor="let filter of filterCategory">
      <div class="filterCategory-title pb-xs-9">{{ localeRoot + (filter.localeKey | uppercase) | translate }}</div>
      <div
        class="filterCategory-body flex fx-column gap-xs-9 pb-4"
        [ngSwitch]="filter.type"
      >
        <!-- Ввод числа "от" и "до" с опциями -->
        <ng-container *ngSwitchCase="types.NumberFromToWithOptions">
          <app-ui-filter-number-options
            [filterForm]="filterForm"
            [control]="filter"
          />
        </ng-container>

        <!-- Ползунок с числовыми значениями "от" и "до" -->
        <ng-container *ngSwitchCase="types.RangeFromTo">
          <app-ui-filter-slider
            class="pt-2"
            [control]="filter"
            [filterForm]="filterForm"
          />
        </ng-container>

        <!-- Простой чекбокс -->
        <app-ui-filter-checkbox
          *ngSwitchCase="types.Checkbox"
          [filterForm]="filterForm"
          [checkboxData]="filter"
          [translateRoot]="localeRoot"
        />

        <!-- Список чекбоксов -->
        <ng-container *ngSwitchCase="types.CheckboxList">
          <ng-container *ngFor="let filterFormItem of filter.controls; let i = index">
            <!-- чекбокс с вложенными чекбоксами -->
            <app-ui-filter-checkbox-list
              *ngIf="filterFormItem.type === types.CheckboxList"
              [filterForm]="filterForm"
              [checkboxListItem]="filterFormItem"
              [translateRoot]="localeRoot"
              [categoryTitle]="localeRoot + filter.localeKey"
              [panelHeight]="filterPanel.offsetHeight + 10 + 'px'"
              [disabledParams]="disabledParams"
            />

            <!-- Простой чекбокс -->
            <app-ui-filter-checkbox
              *ngIf="filterFormItem.type === types.Checkbox"
              [filterForm]="filterForm"
              [checkboxData]="filterFormItem"
              [translateRoot]="localeRoot"
            />
          </ng-container>
        </ng-container>
      </div>
    </ng-container>
  </div>
</form>
