import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, NgModule, Output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { ICaseRevisionItem, IDropItemDto } from '@dev-fast/types';
import { TranslateModule } from '@ngx-translate/core';

import { DecimalBoldFormatModule, LangRouterModule, LazyLoadImageModule, SkinItemImageModule } from '@app/shared/directives';
import { AppCurrencyModule } from '@app/shared/pipes';
import { colorDictionary } from '@app/shared/utils';

import { UserAvatarModule } from '../user-avatar';
import { DropCardMockComponent } from './mock/drop-card.component.mock';

@Component({
  selector: 'app-ui-drop-card',
  templateUrl: './drop-card.component.html',
  styleUrls: ['./drop-card.component.scss'],
})
export class DropCardComponent {
  private _drop: IDropItemDto | undefined;
  public replayLink = '';

  @Input()
  public set drop(val: IDropItemDto | undefined) {
    if (val) {
      this._drop = val;
      this.replayLink = val.isBattle ? `/game/case-battle/${val.id}` : `/game/cases/case/${val.case.id}`;
    }
  }
  public get drop(): IDropItemDto | undefined {
    return this._drop;
  }
  @Input()
  public list: 'last' | 'best' | undefined;
  @Input()
  public className: string | undefined;
  @Input() public isFullView = false;
  @Output() public clickOnCard: EventEmitter<ICaseRevisionItem> = new EventEmitter();

  public isBoxFocused = false;
  public isPlayerFocused = false;

  public navigateToCaseItem(itemRevisions: ICaseRevisionItem): void {
    this.clickOnCard.emit(itemRevisions);
  }
  public getStyle(drop: IDropItemDto): { background: string } {
    const colorDTO = drop.caseRevisionItem.inventoryItem.baseItem.color;
    const newColor = colorDictionary[colorDTO.toUpperCase()];
    return {
      background: `linear-gradient(90.57deg, #121A1E -15%, ${newColor} 30%, ${newColor} 50%, #121A1E 150%)`,
    };
  }
  public getBackgroundImage(item: IDropItemDto): { 'background-image': string | null } {
    if (item.case.backgroundImage) {
      return {
        'background-image': `url(${item.case.historyImage})`,
      };
    }
    return {
      'background-image': `url(${item.caseRevisionItem.inventoryItem.baseItem.icon})`,
    };
  }
  public onElementHoverEnter(type: string): void {
    this.isBoxFocused = type === 'box';
    this.isPlayerFocused = !this.isBoxFocused;
  }
  public onElementHoverLeave(): void {
    this.isBoxFocused = false;
    this.isPlayerFocused = false;
  }
}
@NgModule({
  imports: [
    CommonModule,
    UserAvatarModule,
    MatIconModule,
    TranslateModule,
    AppCurrencyModule,
    LazyLoadImageModule,
    RouterModule,
    DecimalBoldFormatModule,
    SkinItemImageModule,
    LangRouterModule,
  ],
  declarations: [DropCardComponent, DropCardMockComponent],
  exports: [DropCardComponent, DropCardMockComponent],
})
export class DropCardComponentModule {}
