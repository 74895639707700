<form
  *ngIf="filterForm"
  class="priceSelector"
  [formGroup]="filterForm"
>
  <div class="flex fx-align-center gap-1 pb-3">
    <div class="priceInputWrapper relative flex fx-align-center gap-1">
      <!--      <app-currency></app-currency>-->
      <mat-icon svgIcon="coin-secondary"></mat-icon>
      <input
        [value]="control.initMin"
        class="priceInput"
        type="number"
        formControlName="minPrice"
        placeholder="0"
      />
    </div>

    <mat-icon svgIcon="minus--design"></mat-icon>
    <div class="priceInputWrapper relative flex fx-align-center gap-1">
      <!--      <app-currency></app-currency>-->
      <mat-icon svgIcon="coin-secondary"></mat-icon>
      <input
        [value]="control.initMax"
        class="priceInput"
        type="number"
        formControlName="maxPrice"
        [placeholder]="maxPricePlaceholder()"
      />
    </div>
    <mat-icon
      svgIcon="close"
      class="tw-cursor-pointer reset-icon"
      (click)="resetPrice()"
      [class.show-icon]="minPrice || maxPrice"
    ></mat-icon>
  </div>
  <div class="tw-flex tw-flex-col tw-place-items-start tw-gap-4">
    <div
      class="priceOptions tw-cursor-pointer"
      *ngFor="let price of control.minMaxOptions"
      (click)="updatePrice(price)"
    >
      <!--      <app-currency [text]="'◎ ' + price.from + (price.to ? ' - ' + price.to : '+')"></app-currency>-->
      {{ '◎ ' + price.from + (price.to ? ' - ' + price.to : '+') }}
    </div>
  </div>
</form>
