import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { GameMode, IGame } from '@dev-fast/types';
import { NgScrollbarModule } from 'ngx-scrollbar';

import { LangRouterModule } from '@app/shared/directives';

import { ExpandedAreaComponent } from './expanded-area/expanded-area.component';
import { GameItemComponent } from './game-item/game-item.component';

@Component({
  selector: 'app-ui-game-selector',
  templateUrl: './game-selector.component.html',
  styleUrls: ['./game-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GameSelectorComponent {
  //FIXME в стейт или сервис по хорошему
  @Input() set availableGames(payload: IGame[] | null) {
    if (!payload) {
      return;
    }
    this.gamesDict = {};
    payload.forEach((game) => {
      if (this.gamesDict[game.mode]) {
        this.gamesDict[game.mode]?.push(game);
      } else {
        this.gamesDict[game.mode] = [game];
      }
    });
    // this.modes = (Object.keys(this.gamesDict) as GameMode[]).sort((a: GameMode, b: GameMode) => {
    //   if (a === GameMode.PVP) return -1;
    //   if (b === GameMode.PVP) return 0;
    //   if (a < b) return -1;
    //   if (a > b) return 1;
    //   return 0;
    // });
  }
  gamesDict: Partial<Record<GameMode, IGame[]>>;
  isListShowed = false;
  constructor() {
    this.gamesDict = {};
  }

  toggleList = (): void => {
    this.isListShowed = !this.isListShowed;
  };
}

@NgModule({
  imports: [CommonModule, MatIconModule, RouterModule, LangRouterModule, NgScrollbarModule],
  declarations: [GameSelectorComponent, GameItemComponent, ExpandedAreaComponent],
  exports: [GameSelectorComponent],
})
export class GameSelectorComponentModule {}
