import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IPublicGameStatistic } from '@dev-fast/types';

@Component({
  selector: 'app-best-card',
  templateUrl: './best-card.component.html',
  styleUrls: ['./best-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BestCardComponent {
  @Input() public item: IPublicGameStatistic | undefined;
  @Input() public game: string | undefined;

  public stringTransformator(value: string): string {
    return value.split(' ').join('_').toUpperCase();
  }
  public isDateCehck(value: any): boolean {
    return typeof value === 'string';
  }
}
