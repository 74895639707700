import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { NgScrollbarModule } from 'ngx-scrollbar';

import { LangRouterModule } from '@app/shared/directives';
import { AppCurrencyModule } from '@app/shared/pipes';

import { UserAvatarModule } from '../user-avatar';
import { EventMenuContainerComponent } from './components/event-menu-container/event-menu-container.component';
import { EventMenuItemComponent } from './components/event-menu-container/event-menu-items/event-menu-item/event-menu-item.component';
import { GameTypeContainerComponent } from './components/games-list-panel/game-type-container/game-type-container.component';
import { GameTypeItemComponent } from './components/games-list-panel/game-type-item/game-type-item.component';
import { GamesListPanelComponent } from './components/games-list-panel/games-list-panel.component';
import { MenuItemV2Component } from './components/menu-item/menu-item.component';
import { WinnerBannerComponent } from './components/winner-banner/winner-banner.component';
import { GameMenuV2Component } from './game-menu-v2.component';

@NgModule({
  declarations: [
    GameMenuV2Component,
    MenuItemV2Component,
    EventMenuContainerComponent,
    EventMenuItemComponent,
    WinnerBannerComponent,
    GamesListPanelComponent,
    GameTypeContainerComponent,
    GameTypeItemComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    MatIconModule,
    UserAvatarModule,
    AppCurrencyModule,
    NgScrollbarModule,
    TranslateModule,
    LangRouterModule,
  ],
  exports: [GameMenuV2Component],
})
export class GameMenuV2Module {}
