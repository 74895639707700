export enum BonusTransactionTypes {
  CLASSIC_FAST = 'classic/fast',
  FAUCET = 'faucet',
  ADMIN = 'admin',
  TRADE_REFUND = 'trade-refund',
  MINI_JACKPOT = 'mini-jackpot',
  BOOST = 'boost',
  XP = 'xp',
  CASHBACK = 'cashback',
  REFERRAL = 'referral',
  CONTRACT = 'contract',
  SELL_ITEM = 'sell',
  WIN = 'win',
  SKINS = 'skins',
  WITHDRAWAL_SKINS = 'widthdrawal-skins',
  WITHDRAWAL = 'widthdrawal',
  ERROR = 'error',
}
