<div
  *ngIf="item"
  class="selectedItem-wrapper tw-rounded-2xl tw-relative"
  [style.--bg-color]="bgColor"
  [style.--hover-color]="hvColor"
>
  <div class="background-wrapper tw-absolute tw-rounded-2xl tw-w-full tw-h-full"></div>
  <div
    class="close-btn tw-absolute tw-cursor-pointer"
    (click)="removeAt()"
  >
    <mat-icon svgIcon="close" />
  </div>

  <div class="selectedItem tw-flex tw-items-center tw-gap-3 tw-relative tw-p-4 tw-pointer-events-none">
    <div class="tw-flex tw-items-center tw-justify-center tw-self-stretch tw-gap-3 tw-relative tw-min-w-20">
      <div class="stickers tw-flex tw-justify-end tw-flex-wrap tw-absolute tw-top-0 tw-left-0 tw-z-10">
        <ng-container *ngFor="let sticker of item.stickers">
          <div class="stickers__item">
            <ng-container *ngIf="sticker.icon">
              <img
                alt=""
                [src]="sticker.icon | stickerHash"
              />
            </ng-container>
          </div>
        </ng-container>
      </div>

      <img
        class="selectedItem-img"
        [src]="item.baseItem.icon | hash"
        alt=""
      />
    </div>

    <div class="itemInfo flex fx-column gap-2">
      <div class="itemInfo-skinName tw-font-medium">
        <ng-container *ngIf="item.baseItem.skin; else noNameTmpl">
          {{ item.baseItem.skin }}
        </ng-container>
        <ng-template #noNameTmpl> {{ item.baseItem.name }} </ng-template>
      </div>
      <app-ui-float-indicator
        class="itemInfo-float tw-font-normal color-gray-200"
        [item]="item"
      />
      <div class="itemInfo-lotInfo flex fx-align-center flex-wrap">
        <div class="itemInfo-lotInfo-price flex fx-justify-center gap-1 p-xs-5 tw-font-medium">
          <app-currency
            class="currency"
            [value]="item.extra.price"
          />
          <div
            *ngIf="item.extra.increase"
            class="modifier"
            [class]="increaseFieldColorClass(item.extra.increase)"
          >
            <ng-container *ngIf="item.extra.increase > 0">+</ng-container> {{ item.extra.increase }}%
          </div>
        </div>
        <div
          *ngIf="duration"
          class="pl-sm-1 color-gray-200 tw-font-normal"
        >
          {{ duration }} {{ 'P2P_WIDGETS.DEPOSITING.TIME' | translate }}
        </div>
      </div>
    </div>
  </div>
</div>
