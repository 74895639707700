import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { IP2pDepositingItem, IP2pDepositItem, IUserP2pPermissionInterface, SteamErrorsEnum } from '@dev-fast/types';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { P2pDepositService } from '@app/modules/p2p';

import { P2pAuctionCompletedModalComponent, P2pSetupModalComponent } from '../modals';
import { P2pSellService } from './p2p-sell.service';

@Component({
  selector: 'app-p2p-sell',
  templateUrl: './p2p-sell.component.html',
  styleUrls: ['./p2p-sell.component.scss'],
})
export class P2pSellComponent {
  readonly sellerBanEndAt$: Observable<number | null>;
  readonly isEmptySeller$: Observable<boolean>;
  readonly permissions$: Observable<IUserP2pPermissionInterface>;
  selected$: Observable<IP2pDepositItem[]>;
  selectedSum$: Observable<number>;
  depositing$: Observable<IP2pDepositingItem[]>;
  steamErrorsEnum = SteamErrorsEnum;
  isItemsOnPause$: Observable<boolean>;

  constructor(
    private readonly p2pDepositService: P2pDepositService,
    readonly dialog: MatDialog,
    private readonly moduleService: P2pSellService,
  ) {
    this.permissions$ = this.moduleService.p2pPermissions$;
    this.selected$ = this.p2pDepositService.selected$;
    this.selectedSum$ = this.p2pDepositService.selectedSum$;
    this.isItemsOnPause$ = this.p2pDepositService.isItemsOnPause$;
    this.depositing$ = this.p2pDepositService.depositing$;
    this.sellerBanEndAt$ = this.moduleService.sellerBanEndAt$;
    this.isEmptySeller$ = combineLatest([this.selected$, this.depositing$]).pipe(
      map(([selected, depositing]) => !!(selected.length + depositing.length)),
    );
  }

  onSell(): void {
    this.p2pDepositService.depositSelected();
  }
  onResetSelected(): void {
    this.p2pDepositService.resetSelected();
  }
  onSetupSteam(): void {
    this.dialog.open(P2pSetupModalComponent, {
      data: ['steam', 'apiKey'],
    });
  }
  onStopAvailableItems(): void {
    this.p2pDepositService.stop();
  }
  onPauseAvailableItems(): void {
    this.p2pDepositService.pause();
  }
  onResumeAvailableItems(): void {
    this.p2pDepositService.resume();
  }
  trackingFunction(index: number, item: IP2pDepositingItem): number {
    return item.id;
  }
  openTechSupportDialog(): void {
    this.moduleService.openSupportModal();
  }

  test(): void {
    this.dialog.open(P2pAuctionCompletedModalComponent, {
      data: this.depositing$,
    });
  }
}
