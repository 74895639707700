import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import {
  CaseCardComponentModule,
  CaseFullInfoModule,
  SkinCardsListModule,
  UserAvatarModule,
  UserProfileBlockModule,
} from '@dev-fast/ui-components';
import { TranslateModule } from '@ngx-translate/core';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

import { LazyLoadImageModule } from '@app/shared/directives';
import { AppCurrencyModule, CustomAsyncPipeModule } from '@app/shared/pipes';

import { CaseRevisionComponent } from './games/cases/case-revision/case-revision.component';
import { BestCardComponent } from './user-profile/best-card/best-card.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { UserProfileNewComponent } from './user-profile-new/user-profile-new.component';

@NgModule({
  imports: [
    CommonModule,
    UserAvatarModule,
    TranslateModule,
    RouterModule,
    NgxSkeletonLoaderModule,
    LazyLoadImageModule,
    MatIconModule,
    MatSelectModule,
    MatMenuModule,
    MatIconModule,
    MatDialogModule,
    CustomAsyncPipeModule,
    AppCurrencyModule,
    NgScrollbarModule,
    MatTooltipModule,
    CaseCardComponentModule,
    SkinCardsListModule,
    CaseFullInfoModule,
    UserProfileBlockModule,
  ],
  declarations: [UserProfileNewComponent, UserProfileComponent, BestCardComponent, CaseRevisionComponent],
  exports: [UserProfileNewComponent, UserProfileComponent],
})
export class ModalsModule {}
