import { animate, query, style, transition, trigger } from '@angular/animations';

export const bellAnimation = trigger('bellAnimation', [
  transition('false => true', [
    query(
      ':self',
      [
        animate('100ms ease', style({ transform: 'translateX(0.125rem)' })),
        animate('100ms ease-in', style({ transform: 'translateX(-0.125rem)' })),
        animate('100ms ease-in', style({ transform: 'translateX(0.1rem)' })),
        animate('100ms ease-in', style({ transform: 'translateX(-0.1rem)' })),
        animate('100ms ease-in', style({ transform: 'translateX(0.075rem)' })),
        animate('100ms ease-in', style({ transform: 'translateX(-0.075rem)' })),
        animate('100ms ease-in', style({ transform: 'translateX(0.025rem)' })),
        animate('100ms ease-in', style({ transform: 'translateX(-0.025rem)' })),
      ],
      { optional: true },
    ),
  ]),
]);
