export enum WalletTransactionTypeId {
  DEPOSIT_ITEMS = 1,
  DOUBLE_GAME_BET = 2,
  SHOP_PURCHASE = 3,
  DOUBLE_GAME_WIN = 4,
  REFERRAL = 5,
  CLASSIC_OR_FAST_PLAYED = 6,
  SHOP_REVOKE = 7,
  WARWICK_RESERVE = 8,
  WARWICK_RELEASE = 9,
  WARWICK_WINNING = 10,
  PAYMENT = 11,
  DUEL_GAME_BET = 12,
  DUEL_GAME_WIN = 13,
  DUEL_GAME_RETURN = 14,
  BALANCE_PAYMENT = 15,
  FREE_COINS = 16,
  REFERRAL_WELCOME_BONUS = 17,
  SLOTS_GAME_BET = 18,
  SLOTS_GAME_WINNING = 19,
  CRASH_GAME_BET = 20,
  CRASH_GAME_WIN = 21,
  MANUAL_REFUND = 22,
  TRADE_REFUND = 23,
  PROMOCODE = 24,
  KING_BET = 25,
  KING_WIN = 26,
  KING_BONUS = 27,
  UPGRADER_PLAY = 28,
  UPGRADER_SELL = 29,
  CASE_PLAY = 30,
  CASE_SELL = 31,
  DAILY_GIFTS = 32,
  GLOBAL_POT = 33,
  WHEEL_REFILL = 34,
  SKIN_SELL = 35,
  X50_BET = 36,
  X50_WIN = 37,
  VIRTUAL_BET = 38,
  PAYOUT = 39,
  PAYOUT_REVOKE = 40,
  TOWER_GAME_BET = 41,
  TOWER_GAME_WIN = 42,
  WHEEL_SPIN = 43,
  MINI_JACKPOT_WIN = 44,
  HILO_BET = 46,
  HILO_WIN = 47,
  JACKPOT_REWARD = 48,
  BOOST_REWARD = 49,
  MONEY_MOVE_FROM_DAPUBG = 50,
  XP_REWARD = 51,
  CASHBACK_REWARD = 52,
  BACCARAT_PLAY = 53,
  BACCARAT_WIN = 54,
  CROSS_RETURN = 55,
  POGGI_SLOTS_BET = 56,
  POGGI_SLOTS_WIN = 57,
  WHEEL_FREE_BONUS = 58,
  CASES_FREE_BONUS = 59,
  HTTP_API_TAKE = 60,
  LEADERS_RACE_REWARD = 61,
  TAKING_RAFFLE_ITEM = 62,
  UNUSED_MONEY = 63,
  INVENTORY_SELL = 64,
  INVENTORY_BUY = 65,
  P2P_PAYOUT = 67,
  P2P_REFUND = 68,
  P2P_REFILL = 69,
  SAMURAI_PLAY = 70,
  OFFERWALL_PAYMENT = 71,
  CASE_REFUND = 90,
}
export const REFILL_IMG_SOURCE = '//d2lomvz2jrw9ac.cloudfront.net/common/refill-icons/refill-';

export enum LegacyTransactionTypes {
  Error = 'error',
  Unknown58 = 'unknown #58',
  Unknown59 = 'unknown #59',
}
export enum BalanceNamespaces {
  Balance = 'balance',
  Bonus = 'bonus',
  Game = 'game',
  Legacy = 'legacy',
  Inventory = 'inventory',
}

export enum OperationTypes {
  InventorySellSum = 'inventory_sell',
  InventoryBuySum = 'inventory_buy',
  BetSum = 'bet',
  CreditSum = 'credit',
  WinningSum = 'winning',
  AddItem = 'add',
  RemoveItem = 'remove',
}

// const namespaceToTypeIdsMap = {
//   [TransactionNamespaces.BALANCE]: [
//     WalletTransactionTypeId.DEPOSIT_ITEMS,
//     WalletTransactionTypeId.SHOP_PURCHASE,
//     WalletTransactionTypeId.SHOP_REVOKE,
//     WalletTransactionTypeId.PAYMENT,
//     WalletTransactionTypeId.BALANCE_PAYMENT,
//     WalletTransactionTypeId.MANUAL_REFUND,
//     WalletTransactionTypeId.TRADE_REFUND,
//     WalletTransactionTypeId.PAYOUT,
//     WalletTransactionTypeId.PAYOUT_REVOKE,
//     WalletTransactionTypeId.MONEY_MOVE_FROM_DAPUBG,
//     WalletTransactionTypeId.CROSS_RETURN,
//     WalletTransactionTypeId.HTTP_API_TAKE,
//     WalletTransactionTypeId.LEADERS_RACE_REWARD,
//     WalletTransactionTypeId.TAKING_RAFFLE_ITEM,
//     WalletTransactionTypeId.UNUSED_MONEY,
//     WalletTransactionTypeId.INVENTORY_SELL,
//     WalletTransactionTypeId.INVENTORY_BUY,
//   ],
//   [TransactionNamespaces.BONUS]: [
//     WalletTransactionTypeId.REFERRAL,
//     WalletTransactionTypeId.FREE_COINS,
//     WalletTransactionTypeId.REFERRAL_WELCOME_BONUS,
//     WalletTransactionTypeId.PROMOCODE,
//     WalletTransactionTypeId.DAILY_GIFTS,
//     WalletTransactionTypeId.BOOST_REWARD,
//     WalletTransactionTypeId.XP_REWARD,
//     WalletTransactionTypeId.CASHBACK_REWARD,
//     WalletTransactionTypeId.WHEEL_FREE_BONUS,
//     WalletTransactionTypeId.CASES_FREE_BONUS,
//   ],
//   [TransactionNamespaces.GAME]: [
//     WalletTransactionTypeId.DOUBLE_GAME_BET,
//     WalletTransactionTypeId.DOUBLE_GAME_WIN,
//     WalletTransactionTypeId.CLASSIC_OR_FAST_PLAYED,
//   ]
// }

export enum TransactionRefillSystem {
  Skrill = 'Skrill',
  FK = 'FK',
  CardsUSD = 'CardsUSD',
  Cards = 'Cards',
  BT = 'BT',
  BetaTransfer = 'betatransfer',
  GSS3 = 'GSS3',
  Aifory = 'Aifory',
  Decard = 'Decard',
  PayCode = 'PayCode', //
  Fast = 'CSGOFAST', //
  QR = 'Перевод по QR', //
  NowPayments = 'NowPayments', //
  CardTransfer = 'Перевод на карту', //
  Antarex = 'Antarex', //
  Paycos1 = 'Paycos 1', //
  Paycos2 = 'Paycos 2', //
  ZEN = 'ZEN', //
}

export enum TransactionWithdrawSystem {
  Doge = 'doge',
  Tether = 'tether',
  TetherTrc20 = 'tether_trc20',
  Ltc = 'ltc',
  Ethereum = 'ethereum',
  Bitcoin123 = 'bitcoin123',
  Tron = 'tron',
  Neteller = 'neteller',
  Bnb = 'bnb',
  Busd = 'busd',
  Dash = 'dash',
  Matic = 'matic',
  SkrillEur = 'skrill_eur',
  SkrillUsd = 'skrill_usd',
  Usdc = 'usdc',
  UsdcTrc20 = 'usdc_trc20',
  Ton = 'ton',
  UsdtBep20 = 'usdt_bep20',
}

export enum TransactionMethods {
  GiftCards = 'giftcards',
  GiroPay = 'giropay',
  Ideal = 'ideal',
  Klarna = 'klarna',
  LiteCoin = 'litecoin',
  Mana = 'MANA',
  Matic = 'MATIC',
  Mir = 'mir',
  NordeoSolo = 'nordeo-solo',
  Papara = 'papara',
  PaySafeCard = 'paysafecard',
  PerfectMoney = 'perfectmoney',
  Pix = 'pix',
  Poli = 'POLi',
  Qiwi = 'qiwi',
  RapidTransfer = 'rapid-transfer',
  Sbp = 'sbp',
  Shib = 'SHIB',
  Skrill = 'skrill',
  Sol = 'SOL',
  Ton = 'TON',
  Trx = 'TRX',
  Upi = 'upi',
  UpiTrust = 'upi_trust',
  USDC = 'USDC',
  USDCMatic = 'USDCMATIC',
  USDTErc20 = 'usdt_erc20',
  USDTMatic = 'USDTMATIC',
  USDTTrc20 = 'usdt_trc20',
  Xrp = 'XRP',
  YooMoney = 'yoomoney',
  Zec = 'ZEC',
}

export const PaymentSystemDictionary: Record<string, string> = {
  [TransactionWithdrawSystem.Bitcoin123]: 'Bitcoin',
  [TransactionWithdrawSystem.Bnb]: 'BNB',
  [TransactionWithdrawSystem.Busd]: 'BUSD',
  [TransactionWithdrawSystem.Dash]: 'Dash',
  [TransactionWithdrawSystem.Doge]: 'Dogecoin',
  [TransactionWithdrawSystem.Ethereum]: 'Etherium',
  [TransactionWithdrawSystem.Ltc]: 'Litecoin',
  [TransactionWithdrawSystem.Matic]: 'Matic',
  [TransactionWithdrawSystem.SkrillEur]: 'Skrill (EURO)',
  [TransactionWithdrawSystem.SkrillUsd]: 'Skrill (USD)',
  [TransactionWithdrawSystem.Tether]: 'USDT (ERC20)',
  [TransactionWithdrawSystem.TetherTrc20]: '(USDT TRC20)',
  [TransactionWithdrawSystem.Usdc]: '(USDC ERC20)',
  [TransactionWithdrawSystem.UsdcTrc20]: '(USDC TRC20)',
  [TransactionWithdrawSystem.Ton]: 'TON',
  [TransactionWithdrawSystem.Tron]: 'TRX',
  [TransactionWithdrawSystem.Neteller]: 'NETELLER',
  [TransactionRefillSystem.Skrill]: 'Skrill',
  [TransactionRefillSystem.FK]: 'FK',
  [TransactionRefillSystem.CardsUSD]: 'CardsUSD',
  [TransactionRefillSystem.Cards]: 'Cards',
  [TransactionRefillSystem.BetaTransfer]: 'Beta Transfer',
  [TransactionRefillSystem.GSS3]: 'GSS3',
  [TransactionRefillSystem.Aifory]: 'Aifory',
  [TransactionRefillSystem.Decard]: 'Decard',
  [TransactionRefillSystem.PayCode]: 'PayCode',
  [TransactionRefillSystem.Fast]: 'CSGOFAST',
  [TransactionRefillSystem.QR]: 'PROFILE.RENEW_PROFILE.BALANCE.HISTORY.QR_CODE',
  [TransactionRefillSystem.NowPayments]: 'Now Payments',
  [TransactionRefillSystem.CardTransfer]: 'PROFILE.RENEW_PROFILE.BALANCE.HISTORY.CARD_TRANSFER',
  [TransactionRefillSystem.Antarex]: 'Antarex',
  [TransactionRefillSystem.Paycos1]: 'Paycos',
  [TransactionRefillSystem.Paycos2]: 'Paycos',
};
// У нас не на все платежные системы есть иконки. Например на систему "Перевод на карту" не составить путь.
// Так что делаем таким альтернативу
export const AlternateTransferSystemIcons: Record<string, string> = {
  [TransactionRefillSystem.PayCode]: 'cardToCard',
  [TransactionRefillSystem.Fast]: 'cardToCard',
  [TransactionRefillSystem.QR]: 'qr',
  [TransactionRefillSystem.NowPayments]: 'cardToCard',
  [TransactionRefillSystem.CardTransfer]: 'cardToCard',
  [TransactionRefillSystem.Antarex]: 'cardToCard',
  [TransactionRefillSystem.Paycos1]: 'cardToCard',
  [TransactionRefillSystem.Paycos2]: 'cardToCard',
  [TransactionRefillSystem.ZEN]: 'zen',
};
