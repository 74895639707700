import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, signal } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { IMarketplaceKitItem, ISihDataset, ISihStatPeriod, ISteamStoreInventory } from '@dev-fast/types';
import { TranslateModule } from '@ngx-translate/core';

import { GhostComponentModule } from '../ghost/ghost.component';
import { P2pItemChartComponent } from './p2p-item-chart/p2p-item-chart.component';
import { SIH_STAT_PERIODS } from './sih-stat-periods.const';

@Component({
  standalone: true,
  selector: 'app-ui-sih-stats',
  templateUrl: './sih-stats.component.html',
  styleUrls: ['./sih-stats.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, MatIconModule, TranslateModule, GhostComponentModule, P2pItemChartComponent],
})
export class SihStatsComponent {
  @Input() item!: IMarketplaceKitItem | ISteamStoreInventory | undefined;
  @Input() isLoading = false;
  @Input() isError = false;
  @Input() set sihDataset(sihDataset: ISihDataset | null) {
    if (sihDataset) {
      this.sihData = sihDataset;
      this.#setMedianPricePerPeriod(sihDataset, this.selectedPeriod);
      this.#setSalesPerPeriod(sihDataset, this.selectedPeriod);
    } else {
      this.sihData = {};
    }
  }

  // Данные для графика
  periods: ISihStatPeriod[] = SIH_STAT_PERIODS;
  selectedPeriod = this.periods[0];
  sihData: ISihDataset = {};
  // Данные для списка
  sales = 0;
  price = 0;
  isSalesCountInvalid = signal(false);

  /**
   * Устанавливаем среднюю цену за переданный период
   */
  #setSalesPerPeriod(sihData: ISihDataset, period: ISihStatPeriod): void {
    if (sihData['steamlivesell']) {
      this.sales = new Array(...sihData['steamlivesell'])
        .slice(-period.value)
        .reduce((sales: number, data: any, index: number) => sales + data[1][8], 0);
    }
  }

  /**
   * Устанавливаем количество продаж за переданный период
   */
  #setMedianPricePerPeriod(sihData: ISihDataset, period: ISihStatPeriod): void {
    if (sihData['steamlivesell']) {
      const priceData = sihData['steamlivesell']
        .slice(-period.value) // Обрезаем массив до нужного количества дней (period.value)
        .reduce(
          (acc, data) => {
            const price = data[1][2]; // Предположим, что цена находится в data[1][2]
            if (price !== 0) {
              acc.sum += price; // Суммируем цену
              acc.count++; // Увеличиваем счетчик ненулевых дней
            }
            return acc;
          },
          { sum: 0, count: 0 }, // Инициализируем аккумулятор суммой и счетчиком
        );
      // Вычисляем среднее, деля сумму на количество ненулевых дней, если count не равен 0
      this.isSalesCountInvalid.set(!priceData.count);
      this.price = priceData.count !== 0 ? priceData.sum / priceData.count : 0;
    }
  }

  /**
   * Смена выбранного периода
   */
  selectPeriod(option: ISihStatPeriod): void {
    this.selectedPeriod = option;
    this.#setSalesPerPeriod(this.sihData, option);
    this.#setMedianPricePerPeriod(this.sihData, option);
  }
}
