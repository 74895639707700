export const LOCALES_DIC = {
  en_US: 'en-us',
  ru_RU: 'ru',
  en_GB: 'en',
  de_DE: 'de',
  pl_PL: 'pl',
  ro_RO: 'ro',
  sv_SE: 'sv',
  zh_CN: 'zh-cn',
  es_ES: 'es',
  tr_TR: 'tr',
  pt_PT: 'pt',
  th_TH: 'th',
  nb_NO: 'no',
  vi_VN: 'vn',
};
