<ng-container *ngIf="currency">
  <ng-container *ngIf="isOnlyIcon; else withValueOrText">
    <mat-icon
      class="currency-prefix"
      [class]="iconClass"
      [svgIcon]="icon"
    ></mat-icon>
  </ng-container>
  <ng-template #withValueOrText>
    <ng-container *ngIf="value !== undefined">
      <mat-icon
        *ngIf="!usePrefix && !hidePrefix"
        class="currency-prefix value-icon"
        [class]="iconClass"
        [svgIcon]="icon"
      ></mat-icon>
      <span
        *ngIf="transform() as result"
        class="currency-main"
        >{{ result?.sum
        }}<span
          class="currency-rest"
          *ngIf="result?.rest"
          >{{ delimiterRest }}{{ result.rest }}
        </span>
      </span>
    </ng-container>
    <ng-container *ngIf="text !== undefined && transformText(text, currency) as result">
      <ng-container *ngFor="let item of result; last as isLast">
        <span [innerHTML]="item"> </span>
        <mat-icon
          class="currency-prefix"
          [class]="iconClass"
          *ngIf="!isLast"
          [svgIcon]="icon"
        ></mat-icon>
      </ng-container>
    </ng-container>
  </ng-template>
</ng-container>
