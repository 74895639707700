import { AfterViewInit, Directive, ElementRef, OnChanges, SimpleChanges, ViewChild } from '@angular/core';

/** пока не пригодилось, поэтому не доделал */
@Directive({
  selector: 'ng-scrollbar[autoHeight]',
})
export class AutoHeightNgxScrollDirective implements AfterViewInit, OnChanges {
  @ViewChild('scrollable', { static: false, read: ElementRef }) scrollbar: ElementRef | undefined;
  public contentWrapper: HTMLElement | null;
  // @HostBinding('attr.src') private srcAttr: string | null = null;
  // @Input()
  // public src!: string;
  // @Input()
  // public fallback!: string;
  // @Input() public forceLoad = false;

  // private static canLazyLoad(): boolean {
  //   return window && 'IntersectionObserver' in window;
  // }
  // @HostListener('error')
  // useFallback(): void {
  //   this.srcAttr = this.fallback;
  // }
  constructor(private el: ElementRef) {
    this.contentWrapper = null;
  }
  ngAfterViewInit(): void {
    throw new Error('Method not implemented.');
  }
  ngOnChanges(changes: SimpleChanges): void {
    throw new Error('Method not implemented.');
  }
  public autoHeight() {
    if (!this.contentWrapper) {
      this.contentWrapper = document.querySelector('.ng-scrollbar-wrapper');
    }

    if (this.scrollbar && this.contentWrapper) {
      this.scrollbar.nativeElement.style.height = this.contentWrapper.clientHeight + 'px';
    }
  }
  // public ngAfterViewInit(): void {
  //   if (LazyLoadImageDirective.canLazyLoad() && !this.forceLoad) {
  //     this.lazyLoadImage();
  //   } else {
  //     this.loadImage();
  //   }
  // }

  // public ngOnChanges(): void {
  //   if (LazyLoadImageDirective.canLazyLoad() && !this.forceLoad) {
  //     this.lazyLoadImage();
  //   } else {
  //     this.loadImage();
  //   }
  // }

  // private lazyLoadImage(): void {
  //   const obs = new IntersectionObserver((entries) =>
  //     entries.forEach(({ isIntersecting }) => {
  //       if (isIntersecting) {
  //         this.loadImage();
  //         this.el.nativeElement.src = this.src;
  //         obs.unobserve(this.el.nativeElement);
  //       }
  //     })
  //   );
  //   obs.observe(this.el.nativeElement);
  // }

  // private loadImage(): void {
  //   this.srcAttr = this.src;
  //   if (this.forceLoad) {
  //     this.el.nativeElement.src = this.src;
  //   }
  // }
}
