<ng-container *ngIf="{ user: ($user | async) } as data">
  <button
    class="btn close"
    (click)="close()"
  >
    <mat-icon
      [title]="'LOCAL.CASES.MODAL.REVISION.CLOSE' | translate"
      class="close-icon"
      [svgIcon]="'close'"
    >
    </mat-icon>
  </button>
  <app-ui-user-profile-block
    [profile]="data.user"
    [showSocials]="true"
    [showLevel]="true"
  >
  </app-ui-user-profile-block>
  <ng-container *ngIf="data.user && !data.user.privacy?.statisticsIsHidden; else hiddenStatTemplate">
    <div class="user__activities activities">
      <div class="activities__item activity activity_games-played">
        <div class="activity__label">2333</div>
        <div class="activity__text">{{ 'PROFILE.OTHER.PLAYED' | translate }}</div>
      </div>
      <div class="activities__item activity activity_cases-opened">
        <div class="activity__label">182</div>
        <div class="activity__text">{{ 'PROFILE.OTHER.OPENED' | translate }}</div>
      </div>
      <div class="activities__item activity activity_tasks-done">
        <div class="activity__label">3</div>
        <div class="activity__text">{{ 'PROFILE.OTHER.TASKS' | translate }}</div>
      </div>
      <a
        class="activities__item activity activity_max-win-sum"
        langRouterLink
        [routerLink]="['/game/' + 'fast']"
        (click)="close()"
      >
        <img
          class="activity__icon"
          [src]="'assets/img/mat-icons/left-sidebar/x50.webp'"
          alt=""
        />
        <div class="activity__label activity__label_price">◎ 2333</div>
        <div class="activity__text">{{ 'PROFILE.OTHER.MAX_WIN' | translate }}</div>
      </a>
      <a
        class="activities__item activity activity_max-win-percent"
        langRouterLink
        [routerLink]="['/game/' + 'fast']"
        (click)="close()"
      >
        <img
          class="activity__icon"
          [src]="'assets/img/mat-icons/left-sidebar/x50.webp'"
          alt=""
        />
        <div class="activity__label">183%</div>
        <div class="activity__text">{{ 'PROFILE.OTHER.MAX_PROFIT' | translate }}</div>
      </a>
      <a
        class="activities__item activity activity_max-bet"
        langRouterLink
        [routerLink]="['/game/' + 'fast']"
        (click)="close()"
      >
        <img
          class="activity__icon"
          [src]="'assets/img/mat-icons/left-sidebar/fast.webp'"
          alt=""
        />
        <div class="activity__label activity__label_price">◎ 2333</div>
        <div class="activity__text">{{ 'PROFILE.OTHER.MAX_BET' | translate }}</div>
      </a>
      <a
        class="activities__item activity activity_favourite-game"
        langRouterLink
        [routerLink]="['/game/' + 'fast']"
        (click)="close()"
      >
        <img
          class="activity__icon"
          [src]="'assets/img/mat-icons/left-sidebar/slots.webp'"
          alt=""
        />
        <div class="activity__label">2333</div>
        <div class="activity__text">{{ 'PROFILE.OTHER.FAVORITE_GAME' | translate }}</div>
      </a>
      <a
        class="activities__item activity activity_favourite-cases"
        langRouterLink
        [routerLink]="['/game/cases/case/' + '12']"
        (click)="close()"
      >
        <div class="activity__left-block">
          <img
            src="assets/img/test-case.png"
            alt=""
            class="case case_1"
          />
          <img
            src="assets/img/test-case.png"
            alt=""
            class="case case_2"
          />
          <img
            src="assets/img/test-case.png"
            alt=""
            class="case case_3"
          />
        </div>
        <div class="activity__right-block">
          <div class="activity__text">{{ 'PROFILE.OTHER.FAVORITE_CASE' | translate }}</div>
          <div class="activity__label">THERMAL SHOCK</div>
        </div>
      </a>
      <a
        class="activities__item activity activity_best-drop"
        langRouterLink
        [routerLink]="['/skins/item/' + 'M4A4_The-Emperor']"
        (click)="close()"
      >
        <div class="activity__left-block">
          <img
            src="assets/img/test-item.png"
            alt=""
            class="item-image"
          />
        </div>
        <div class="activity__right-block">
          <div class="activity__text">{{ 'PROFILE.OTHER.BEST_DROP' | translate }}</div>
          <div class="item">
            <div class="item__short-name">knife</div>
            <div class="item__skin">Gamma Doppler</div>
            <div class="item__price">◎ 3 400,50</div>
          </div>
        </div>
      </a>
    </div>
  </ng-container>
  <ng-template #hiddenStatTemplate>
    <div class="user__hidden">
      <ng-container *ngIf="data.user; else mock">
        {{ 'USER_PANEL.STATISTIC_HIDDEN' | translate }}
      </ng-container>
      <ng-template #mock><div class="mock-state loading-gradient"></div></ng-template>
    </div>
  </ng-template>
</ng-container>
