import { PaymentWalletType } from '../../enums';

export interface IPaymentGate {
  // TODO: удалить после перехода на новую api (весь файл)
  id: number;
  name: string;
  title: string;
  method_name: string;
  gate_id: string;
  is_enabled: boolean;
  minPayment: number;
  maxPayment: number;
  walletType: PaymentWalletType;
  // TODO тут приходит по факту булевое, надо понять
  icon?: string;
  currency: string[];
  is_terminal: boolean;
  commission: string;
  problems: string;
  payFee?: string;
}
