import {
  CasesGamePhases,
  CasesGameType,
  ICaseItemDtoV2,
  ICaseOpenPrizeDataDto,
  ICaseOpenPrizeItemDto,
  ICaseRevisionItem,
} from '@dev-fast/types';

export interface ICasesGameStateModel {
  caseItem: ICaseItemDtoV2 | null;
  caseItemRequestState: boolean | null;
  gameType: CasesGameType;
  prizeData: ICaseOpenPrizeDataDto | null;
  prizeItems: ICaseOpenPrizeItemDto[];
  autoplayPrizeItems: ICaseOpenPrizeItemDto[];
  spinState: CasesGamePhases;
  spinTracksIds: number[][];
  caseItemsDictionary: Record<number, ICaseRevisionItem>;
  receivedBonusCase: boolean;
  isBonusCase: boolean;
  bonusCases: number[];
}

export const CASES_GAME_INITIAL_STATE: ICasesGameStateModel = {
  caseItem: null,
  caseItemRequestState: null,
  gameType: CasesGameType.MONEY,
  prizeData: null,
  prizeItems: [],
  autoplayPrizeItems: [],
  spinState: CasesGamePhases.INITIALIZE,
  receivedBonusCase: false,
  isBonusCase: false,
  bonusCases: [],
  spinTracksIds: [],
  caseItemsDictionary: {},
};
