import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'hash',
  standalone: true,
})
export class HashPipe implements PipeTransform {
  public transform(value: string | null | undefined): string {
    if (!value) return '';
    if (!(value.startsWith('http') || value.startsWith('//'))) {
      return `https://steamcommunity-a.akamaihd.net/economy/image/${value}`;
    }
    return value;
  }
}
