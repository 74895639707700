import { PayFeeType, PaymentExchangeCurrency, PaymentWalletType } from '../../enums';
import { IPaymentMethod, IPaymentMethodV2 } from './payment-method';

export interface IPaymentsDto {
  //TODO: удалить после перехода на новую API
  success: boolean;
  lastSuccess: IPaymentMethod | null;
  depositBonus: number;
  payFee: Record<string, number>;
  systems: IPaymentMethod[];
  regional: IPaymentMethod[];
  popular: IPaymentMethod[];
}
export interface IPaymentParams {
  currency: number;
  realCurrency: number;
  targetCurrency?: string;
  walletType?: PaymentWalletType | string;
}

export interface IPaymentsDtoV2 {
  depositBonus: number;
  methods: IPaymentMethodV2[];
  payFee: Record<string, number>;
  success: boolean;
  time: number;
}

export interface IPaymentType {
  name: string;
  methods: IPaymentMethod[];
  isExpanded: boolean;
}
export interface IPaymentTypeV2 {
  name: string;
  methods: IPaymentMethodV2[];
  isExpanded: boolean;
}
export interface IActivePayment {
  type: string | undefined;
  method: string | undefined;
  gate?: string;
}

export interface IGiftProvider {
  name: string;
  items: { amount: number; link: string }[];
}

export interface IRefillSuccessWS {
  amount: number;
  meta: any;
}
export interface IPayFee {
  type: PayFeeType;
  value: string;
}

/*  интерфейсы для пополнения через криптокошелек (qr-кодом)  */

export interface IBaseCryptoWallet {
  network: string;
  title: string;
  name: PaymentExchangeCurrency;
}
export interface IUserCryptoWallet extends IBaseCryptoWallet {
  id: string; //qr hash?
  address: string;
  currency: string;
}
export interface IAvailableCryptoWallet extends IBaseCryptoWallet {
  id: number;
  minimal_amount: number;
  system_code_name: string;
  rate: number;
}
export interface IWalletExchanger {
  currencys: string[]; // так-то currencies должно быть
  title: string;
  url: string;
}
export interface ICryptoMethodsBased {
  exchangers: IWalletExchanger[];
}
export interface ICryptoUserMethodsDto {
  success: boolean;
  wallets: IUserCryptoWallet[];
}
export interface ICryptoAvailableMethodsDto {
  success: boolean;
  list: IAvailableCryptoWallet[];
}
export interface ICryptoMethodsDto extends ICryptoAvailableMethodsDto, ICryptoMethodsBased {}

export interface ICryptoMethodData {
  availableWallets: IAvailableCryptoWallet[];
  userWallets: IUserCryptoWallet[] | null;
  exchangers: IWalletExchanger[];
  activeWalletIndex: number;
}
export interface INewCryptoWalletResponse {
  success: boolean;
  wallet: string;
}
