import { Injectable } from '@angular/core';
import { SelectDirection } from '@dev-fast/types';
import { Action, State, StateContext, Store } from '@ngxs/store';

import { P2pMarketState } from '@app/core/state/p2p';

import { GetCurrentCaseMotivator } from '../../motivator';
import { GetCase } from '../game-state';
import { SelectCase } from './select-state.actions';
import { CASES_SELECT_INITIAL_STATE, ICasesSelectStateModel } from './select-state.model';

@State<ICasesSelectStateModel>({
  name: 'casesSelect',
  defaults: CASES_SELECT_INITIAL_STATE,
})
@Injectable()
export class CasesSelectState {
  constructor(private readonly store: Store) {}

  @Action(GetCase)
  updateCasesIds({ patchState }: StateContext<ICasesSelectStateModel>, { id }: GetCase): void {
    const casesListIds = this.store.selectSnapshot(P2pMarketState.customCaseList).map((item) => item.id);
    const firstCaseId = casesListIds[0];
    const lastCaseId = casesListIds[casesListIds.length - 1];
    const currentIndex = casesListIds.indexOf(id);

    // Если текущего кейса нет в общем списке, то при переключении кейса возвращаем юзера в список (с первого элемента)
    if (currentIndex === -1) {
      patchState({
        nextCaseId: firstCaseId,
        prevCaseId: firstCaseId,
      });
      return;
    }

    patchState({
      nextCaseId: currentIndex > 0 ? casesListIds[currentIndex - 1] : lastCaseId,
      prevCaseId: currentIndex < casesListIds.length - 1 ? casesListIds[currentIndex + 1] : firstCaseId,
    });
  }

  @Action(SelectCase)
  selectCase({ dispatch, getState }: StateContext<ICasesSelectStateModel>, { target, getMotivator }: SelectCase): void {
    const { nextCaseId, prevCaseId } = getState();
    if (target === SelectDirection.NEXT && nextCaseId) {
      dispatch(new GetCase(nextCaseId));
      if (getMotivator) {
        dispatch(new GetCurrentCaseMotivator(nextCaseId));
      }
    }
    if (target === SelectDirection.PREV && prevCaseId) {
      dispatch(new GetCase(prevCaseId));
      if (getMotivator) {
        dispatch(new GetCurrentCaseMotivator(prevCaseId));
      }
    }
  }
}
