import { inject, Injectable } from '@angular/core';
import {
  IBattleEmojiNoty,
  IBattleEmojiSocketMessage,
  IBattleEndEmojisNoty,
  ICaseBattleCreateNew,
  ICaseBattleDto,
  ICaseBattleFilter,
  ICaseBattleGameCreateDto,
  ICaseBattleGameCreateNotify,
  ICaseBattleGameFinishDto,
  ICaseBattleGameJoinNotify,
  ICaseBattleHistory,
  ICaseBattleOneGame,
  ICaseBattleRoundDto,
  ICaseBattleStartDto,
  ICaseBattleUserJoinDto,
  ICaseItemDtoV2,
  ICasesFilter,
  SocketResponse,
} from '@dev-fast/types';
import { Socket } from 'ngx-socket-io';
import { map, Observable } from 'rxjs';

import { EnvironmentService } from '@app/core/environment-service';
import { paramsToQuery } from '@app/shared/utils';

import { ApiService } from '../../../api.service';

@Injectable({
  providedIn: 'root',
})
export class CaseBattleBackendService {
  readonly #api = inject(ApiService);
  readonly #environmentService = inject(EnvironmentService);
  readonly #ws = inject(Socket);

  readonly #environment = this.#environmentService.environments;

  getBattleGames = (me: boolean, params?: { filters: ICaseBattleFilter }): Observable<ICaseBattleDto> => {
    return this.#api.get(`${this.#environment.GATEWAY_URL}/api/cases/battles${paramsToQuery({ me: [me], ...params?.filters })}`, {
      withCredentials: true,
    });
  };

  getCaseBattleById = (battleId: number): Observable<ICaseBattleOneGame> => {
    return this.#api.get(`${this.#environment.GATEWAY_URL}/api/cases/battles/${battleId}`, {
      withCredentials: true,
    });
  };

  getAllCasesForRenderingInGames = (): Observable<ICaseItemDtoV2[]> => {
    return this.#api.get(`${this.#environment.GATEWAY_URL}/api/cases/suitcases?battles=true&detailed=true`);
  };

  getAllCasesForBattleCreate = (casesFilter: ICasesFilter | null): Observable<ICaseItemDtoV2[]> => {
    return this.#api.get(`${this.#environment.GATEWAY_URL}/api/cases/suitcases`, {
      params: { ...casesFilter, battles: true },
    });
  };
  getCasesByIds = (ids: number[]): Observable<ICaseItemDtoV2[]> => {
    return this.#api.get(`${this.#environment.GATEWAY_URL}/api/cases/suitcases/many/${ids.join('%2C%20')}`);
  };

  /**
   * Получает информацию о кейсе по его идентификатору.
   * @param id - Идентификатор кейса.
   * @returns Observable с информацией о кейсе.
   */
  getCasesById = (id: number): Observable<ICaseItemDtoV2> => {
    return this.#api.get(`${this.#environment.GATEWAY_URL}/api/cases/suitcases/${id}`);
  };

  createNewCaseBattle = (newBattleInfo: ICaseBattleCreateNew): Observable<ICaseBattleGameCreateNotify> => {
    const params = {
      ...newBattleInfo,
      double: newBattleInfo.settings.double,
      settings: { winnersSplit: newBattleInfo.settings.winnersSplit, jokerMode: newBattleInfo.settings.jokerMode },
    };
    return this.#api.post(`${this.#environment.GATEWAY_URL}/api/cases/battles/create`, params, { withCredentials: true });
  };
  joinCaseBattle = (payload: { battleId: number; teamNumber?: number }): Observable<ICaseBattleGameJoinNotify> =>
    this.#api.post(
      `${this.#environment.GATEWAY_URL}/api/cases/battles/${payload.battleId}/join`,
      { team: payload.teamNumber },
      { withCredentials: true },
    );

  addBot = (payload: { battleId: number; teamNumber?: number }): Observable<ICaseBattleGameJoinNotify> =>
    this.#api.post(
      `${this.#environment.GATEWAY_URL}/api/cases/battles/${payload.battleId}/join/bot`,
      { team: payload.teamNumber },
      { withCredentials: true },
    );

  getBattleUserHistory = (offset: number, limit = 20, user: boolean): Observable<ICaseBattleHistory> => {
    return this.#api.get(
      `${this.#environment.GATEWAY_URL}/api/cases/battles/history${paramsToQuery({
        sortBy: '-created_at',
        user: user,
        offset: offset,
        limit: limit,
      })}`,
      { withCredentials: true },
    );
  };
  getBattleListHistory = (): Observable<ICaseBattleHistory> => {
    return this.#api.get(
      `${this.#environment.GATEWAY_URL}/api/cases/battles/history${paramsToQuery({
        sortBy: '-finished_at',
        user: false,
        offset: 0,
        limit: 10,
      })}`,
      { withCredentials: true },
    );
  };
  setEmoji = (payload: { battleId: number; emojiId: number }): Observable<IBattleEmojiNoty> => {
    return this.#api.post(
      `${this.#environment.GATEWAY_URL}/api/cases/battles/${payload.battleId}/emoji`,
      { emojiId: payload.emojiId },
      { withCredentials: true },
    );
  };
  onCreateBattle(): Observable<SocketResponse<ICaseBattleGameCreateDto>> {
    return this.#ws.fromEvent<ICaseBattleGameCreateDto>('battle:new').pipe(map((value) => ({ payload: value, name: 'new' })));
  }

  onStartBattle(): Observable<SocketResponse<ICaseBattleStartDto>> {
    return this.#ws.fromEvent<ICaseBattleStartDto>('battle:start').pipe(map((value) => ({ payload: value, name: 'start' })));
  }

  onJoinBattle(): Observable<SocketResponse<ICaseBattleUserJoinDto>> {
    return this.#ws.fromEvent<ICaseBattleUserJoinDto>('battle:join').pipe(map((value) => ({ payload: value, name: 'join' })));
  }

  onEndBattleRound(): Observable<SocketResponse<ICaseBattleRoundDto[]>> {
    return this.#ws.fromEvent<ICaseBattleRoundDto[]>('battle:roll').pipe(map((value) => ({ payload: value, name: 'roll' })));
  }

  onEndBattle(): Observable<SocketResponse<ICaseBattleGameFinishDto>> {
    return this.#ws.fromEvent<ICaseBattleGameFinishDto>('battle:finish').pipe(map((value) => ({ payload: value, name: 'finish' })));
  }
  onEmojiGet(): Observable<SocketResponse<IBattleEmojiSocketMessage>> {
    return this.#ws.fromEvent<IBattleEmojiSocketMessage>('battle:new-emoji').pipe(map((value) => ({ payload: value, name: 'emoji' })));
  }
  onFinallyEmojisGet(): Observable<SocketResponse<IBattleEndEmojisNoty>> {
    return this.#ws.fromEvent<IBattleEndEmojisNoty>('battle:emoji').pipe(map((value) => ({ payload: value, name: 'finally-emojis' })));
  }
}
