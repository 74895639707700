import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
// import { P2pSetupModalModule } from '@shared/p2p';
import { RouterModule } from '@angular/router';
import { CountdownComponentModule } from '@dev-fast/ui-components';
import { TranslateModule } from '@ngx-translate/core';
import { NgScrollbarModule } from 'ngx-scrollbar';

import { LangRouterModule } from '@app/shared/directives';
import { AppCurrencyModule } from '@app/shared/pipes';

import { P2pSellComponent } from './p2p-sell.component';
import { P2pSellService } from './p2p-sell.service';
import { P2pSellSetupItemModule } from './p2p-sell-setup-item/p2p-sell-setup-item.module';
import { P2pSellingItemModule } from './p2p-selling-item/p2p-selling-item.module';

@NgModule({
  declarations: [P2pSellComponent],
  imports: [
    CommonModule,
    TranslateModule,
    NgScrollbarModule,
    P2pSellSetupItemModule,
    P2pSellingItemModule,
    // P2pSetupModalModule,
    CountdownComponentModule,
    AppCurrencyModule,
    MatIconModule,
    RouterModule,
    LangRouterModule,
  ],
  exports: [P2pSellComponent],
  providers: [P2pSellService],
})
export class P2pSellModule {}
