import {
  CaseBattleLobbyLists,
  CaseBattlePhases,
  IBattleEmojiSocketMessage,
  ICaseBattleCreateNew,
  ICaseBattleFilter,
  ICaseBattleGame,
  ICaseBattleGameCreateDto,
  ICaseBattleGameDto,
  ICaseBattleGameFinishDto,
  ICaseBattleRoundDto,
  ICaseBattleStartDto,
  ICaseBattleUserJoinDto,
  ICaseItemDtoV2,
  IEmojiState,
  IFilterRange,
  SoundNameFast,
} from '@dev-fast/types';

export class ChangeBattlesFilter {
  static readonly type = '[Case battle] Change Filter';
  constructor(public filter: ICaseBattleFilter) {}
}

export class ChangeBattlesPriceRangeValues {
  static readonly type = '[Case battle] Change Price Range Values';
  constructor(public range: IFilterRange) {}
}

export class AddBotToCaseBattle {
  static readonly type = '[Case battle] add bot battle';
  constructor(public payload: { battleId: number; teamNumber?: number }) {}
}

export class LeftBattles {
  static readonly type = '[Case battle] left battles';
}

export class AddCaseBattle {
  static readonly type = '[Case battle] add battle';
  constructor(public notify: ICaseBattleGameCreateDto) {}
}

export class AddPlayer {
  static readonly type = '[Case battle] add player';
  constructor(public notify: ICaseBattleUserJoinDto) {}
}

export class AddSelectedItems {
  static readonly type = '[Case battle] add selected item';
  constructor(public caseItems: ICaseItemDtoV2[]) {}
}

export class ClearActiveLobby {
  static readonly type = '[Case battle] remove active lobby';
}

export class CreateCaseBattle {
  static readonly type = '[Case battle] create battle';
  constructor(
    public newBattleInfo: ICaseBattleCreateNew,
    public createType: 'create' | 'recreate',
  ) {}
}

export class CreatePlayerCards {
  static readonly type = '[Case battle] create players game';
  constructor(public userId?: number) {}
}

export class EndBattleRound {
  static readonly type = '[Case battle] end battle round';
  constructor(public notify: ICaseBattleRoundDto[]) {}
}

export class EndRoundInLobby {
  static readonly type = '[Case battle] end round in lobby';
  constructor(public notify: ICaseBattleRoundDto[]) {}
}

export class EndRoundInList {
  static readonly type = '[Case battle] end round in list';
  constructor(public notify: ICaseBattleRoundDto[]) {}
}

export class FinishActiveBattle {
  static readonly type = '[Case battle] finish active battle';
  constructor(public notify: ICaseBattleGameFinishDto) {}
}

export class GetAllBattles {
  static readonly type = '[Case battle] get all battles';
  constructor(public userId: number | undefined) {}
}
export class GetBattleUserHistory {
  static readonly type = '[Case battle] get battle user history';
  constructor(public offset?: number) {}
}
export class GetBattleLastHistory {
  static readonly type = '[Case battle] get battle last history';
}
export class GetCasesByIdsForLobby {
  static readonly type = '[Case battle] get cases by ids for lobby';
  constructor(public game: ICaseBattleGameDto) {}
}

export class GetLobbyById {
  static readonly type = '[Case battle] get active lobby';
  constructor(public battleId: number) {}
}

export class InitCases {
  static readonly type = '[Case battle] init cases';
}

// export class OnHandleSockets {
//   public static readonly type = '[Case battle] on handle sockets';
// }

export class JoinCaseBattle {
  static readonly type = '[Case battle] join battle';
  constructor(public payload: { battleId: number; navigate: boolean; teamNumber?: number }) {}
}

export class RemoveCaseBattle {
  static readonly type = '[Case battle] remove battle';
  constructor(public notify: ICaseBattleGameFinishDto) {}
}

export class RemoveSelectedItems {
  static readonly type = '[Case battle] remove selected item';
  constructor(public caseItems?: ICaseItemDtoV2[]) {}
}

export class ReplayGame {
  static readonly type = '[Case battle] replay game';
  constructor(public battleId: number) {}
}

export class ReplayPreparation {
  static readonly type = '[Case battle] replay preparation';
}

export class ReplaySetRounds {
  static readonly type = '[Case battle] replay set rounds';
  constructor(public battleId: number) {}
}
export class SendEmoji {
  static readonly type = '[Case battle] send emoji';
  constructor(public payload: { battleId: number; emojiId: number }) {}
}
export class SetActiveEmoji {
  static readonly type = '[Case battle] set active emoji';
  constructor(
    public activeLobby: ICaseBattleGame | null,
    public payload: IBattleEmojiSocketMessage,
  ) {}
}
export class SetActiveSound {
  static readonly type = '[Case battle] set active sound';
  constructor(public sound?: SoundNameFast) {}
}
export class SetFinallyEmojis {
  static readonly type = '[Case battle] set finaly emojis';
  constructor(
    public activeLobby: ICaseBattleGame | null,
    public payload: IEmojiState,
  ) {}
}
export class GetOtherUsersBattleList {
  static readonly type = '[Case battle] get other users battles';
  constructor(
    public filter: ICaseBattleFilter | null,
    public offset?: number,
    public userId?: number | undefined,
  ) {}
}
export class GetUserBattleList {
  static readonly type = '[Case battle] get user battles';
  constructor(public userId: number | undefined) {}
}

export class SetBattlePhase {
  static readonly type = '[Case battle] set battle phase';
  constructor(public phase: CaseBattlePhases) {}
}
export class SetCaseBattleFilters {
  static readonly type = '[Case battle] set filters';
  constructor(public filters: ICaseBattleFilter) {}
}

export class SetPrizeItemAndRange {
  static readonly type = '[Case battle] set prize items and range';
}
export class SetUserId {
  static readonly type = '[Case battle] set user id';
  constructor(public userId: number | undefined) {}
}
export class StartCaseBattle {
  static readonly type = '[Case battle] start battle';
  constructor(public notify: ICaseBattleStartDto) {}
}
export class ToggleBattleLists {
  static readonly type = '[Case battle] toggle lists';
  constructor(public activeList: CaseBattleLobbyLists) {}
}

export class WatchCaseBattle {
  static readonly type = '[Case battle] watch battle';
  constructor(public gameId: number) {}
}
