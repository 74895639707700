import { inject, Injectable } from '@angular/core';
import { NotificationService } from '@dev-fast/backend-services';
import { EXCEPTION_NOTIFICATION_ARRAY, INotification, NotificationStatus, NotificationType } from '@dev-fast/types';
import { Action, NgxsOnInit, Selector, State, StateContext, Store } from '@ngxs/store';
import { insertItem, patch, removeItem, updateItem } from '@ngxs/store/operators';
import { includes } from 'lodash-es';
import moment from 'moment';
import { Socket as WrappedSocket } from 'ngx-socket-io';
import { finalize, Observable, tap } from 'rxjs';

import { FrameMessageTypes, IFrameMessageService } from '@app/core/iframe';
import { LocalStorageService } from '@app/core/local-storage-service';

import {
  AddNotification,
  AddNotificationSuccess,
  GetNotifications,
  RemoveNotification,
  WatchAllNotification,
  WatchNotification,
} from './notifications.actions';
import { NOTIFICATIONS_INITIAL_STATE, NotificationsStateModel } from './notifications-state.model';

@State<NotificationsStateModel>({
  name: 'notification',
  defaults: NOTIFICATIONS_INITIAL_STATE,
})
@Injectable()
export class NotificationsState implements NgxsOnInit {
  @Selector()
  static items({ items }: NotificationsStateModel): INotification[] {
    return items.filter((value) => value.status !== NotificationStatus.deleted);
  }

  @Selector()
  static hasNewItems({ items }: NotificationsStateModel): boolean {
    return items.some((el) => el.status === NotificationStatus.new);
  }

  @Selector()
  static countNewItems({ items }: NotificationsStateModel): number {
    return items.filter((el) => el.status === NotificationStatus.new).length;
  }

  readonly #frameMessageService = inject(IFrameMessageService, {
    optional: true,
  });

  readonly #store = inject(Store);
  readonly #ws = inject(WrappedSocket);
  readonly #storage = inject(LocalStorageService);
  readonly #apiService = inject(NotificationService);

  ngxsOnInit(): void {
    if (this.#frameMessageService) {
      this.#frameMessageService.on(
        FrameMessageTypes.MESSAGE_FROM_BB,
        'show.noty',
        (payload: { header?: string; text: string; type?: NotificationType; params: Record<string, any>; legacy: boolean }) => {
          if (payload.header && payload.header !== 'Battle') {
            this.#store.dispatch(
              new AddNotification({
                id: Date.now(),
                type: payload.type ? payload.type : NotificationType.Info,
                icon: payload.type ? payload.type : NotificationType.Info,
                category: payload.header,
                message: payload.text,
                createDate: Date.now(),
                system: true,
                status: NotificationStatus.new,
                legacy: payload.legacy,
              }),
            );
          }
        },
      );
    }

    this.#ws.on('notifications.message.created', (message: INotification) => this.#store.dispatch(new AddNotification(message)));
    this.#ws.on('exception', (exception: INotification) =>
      this.#store.dispatch(
        new AddNotification({
          id: Date.now(),
          type: NotificationType.Error,
          icon: 'warning',
          message: exception.message,
          createDate: Date.now(),
          system: true,
          status: NotificationStatus.new,
          params: exception?.params ?? {},
        }),
      ),
    );
  }

  @Action(AddNotification)
  addNotification({ setState, dispatch, getState }: StateContext<NotificationsStateModel>, { noty }: AddNotification): void {
    const getUpdatedNotificationId = (): number | undefined => {
      const notifications = getState().items;
      if (notifications.length === 0) {
        return;
      }
      const notDeletedItems = notifications.filter((notification) => notification.status !== NotificationStatus.deleted);

      return notDeletedItems.find((x) => x?.message === noty.message)?.id;
    };

    if (noty.message === undefined || typeof noty.message !== 'string' || this.#canIgnoreNotification(noty)) {
      return;
    }

    if (noty.updateNotification) {
      const UpdatedNotificationId = getUpdatedNotificationId();

      if (UpdatedNotificationId) {
        dispatch(new RemoveNotification(UpdatedNotificationId));
      }
    }

    if (noty.system) {
      const items = this.#storage.get('notifications') || [];
      if (items.length > 9) {
        items.shift();
      }
      items.push(noty);
      this.#storage.set('notifications', items);
    }
    setState(patch({ items: insertItem(noty) }));
    dispatch(new AddNotificationSuccess({ ...noty, showToast: noty.showToast ?? true }));
  }

  @Action(GetNotifications)
  getNotifications({ setState }: StateContext<NotificationsStateModel>): Observable<INotification[]> {
    return this.#apiService.getNotifications().pipe(
      tap((notys: INotification[]) => {
        const itemsInStorage = this.#storage.get('notifications') || [];
        const filtered = itemsInStorage.filter(
          (value) =>
            value.status !== NotificationStatus.deleted &&
            value.message &&
            typeof value.message === 'string' &&
            typeof value.type === 'string',
        );
        const sortedNotys = [...notys, ...filtered].sort((a, b) => +moment(b.createDate) - +moment(a.createDate));
        setState(patch({ items: sortedNotys }));
      }),
    );
  }

  @Action(RemoveNotification)
  removeNotification(
    { setState, getState }: StateContext<NotificationsStateModel>,
    { notyId }: RemoveNotification,
  ): Observable<void> | void {
    const noty = getState().items.find((x) => x?.id === notyId);
    if (!noty) {
      return;
    }
    if (noty.system) {
      const itemsInStorage = this.#storage.get('notifications');
      if (itemsInStorage) {
        this.#storage.set(
          'notifications',
          itemsInStorage.filter((item) => item.id !== notyId),
        );
      }
      setState(
        patch({
          items: removeItem<INotification>((x) => x?.id === notyId),
        }),
      );
    } else {
      return this.#apiService.deleteNotification(notyId).pipe(
        finalize(() => {
          setState(
            patch({
              items: removeItem<INotification>((x) => x?.id === notyId),
            }),
          );
        }),
      );
    }
  }

  @Action(WatchAllNotification)
  watchAllNotification({ setState, getState }: StateContext<NotificationsStateModel>): void | Observable<void> {
    const { items } = getState();
    if (items.length === 0) {
      return;
    }

    const notDeletedItems = items.filter((notification) => notification.status !== NotificationStatus.deleted);
    const newItemsFromStorage = notDeletedItems.filter((value) => value.system);
    const newItemsFromServer = notDeletedItems.filter((value) => !value.system && value.status === NotificationStatus.new);

    const watchInState = (): void => {
      const watchedItems = [...items.map((val) => ({ ...val, status: NotificationStatus.deleted }))];
      setState({ items: watchedItems });
    };

    const watchInStorage = (): void => {
      const itemsInStorage = this.#storage.get('notifications');
      if (itemsInStorage) {
        this.#storage.set(
          'notifications',
          itemsInStorage.map((value) => ({ ...value, status: NotificationStatus.deleted })),
        );
      }
    };

    if (newItemsFromStorage.length) {
      watchInStorage();
    }
    if (newItemsFromServer.length) {
      return this.#apiService.watchNotifications(newItemsFromServer).pipe(
        tap(() => {
          watchInState();
        }),
      );
    } else {
      watchInState();
    }
  }

  @Action(WatchNotification)
  watchNotification({ setState, getState }: StateContext<NotificationsStateModel>, { notyId }: WatchNotification): Observable<void> | void {
    const noty = getState().items.find((x) => x?.id === notyId);
    if (!noty) {
      return;
    }

    if (noty.system) {
      const itemsInStorage = this.#storage.get('notifications');
      if (itemsInStorage) {
        this.#storage.set(
          'notifications',
          itemsInStorage.map((item) => {
            if (item.id === notyId) {
              item.status = NotificationStatus.watched;
            }
            return item;
          }),
        );
      }
    } else {
      this.#apiService.watchNotifications([noty]);
    }
    setState(
      patch({
        items: updateItem<INotification>(
          (item) => item?.id === notyId,
          patch({
            status: NotificationStatus.watched,
          }),
        ),
      }),
    );
  }

  #canIgnoreNotification(noty: INotification): boolean {
    return includes(EXCEPTION_NOTIFICATION_ARRAY, noty.message) && !noty.ignoreExceptionList;
  }
}
