import { Injectable } from '@angular/core';
import { IFaq, QuickFaqName } from '@dev-fast/types';
import { Select } from '@ngxs/store';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { combineLatest, map, Observable } from 'rxjs';

import { FaqState, QuickFaqCheckStatus, QuickFaqSetStatus } from '../state';

@Injectable({
  providedIn: 'root',
})
export class QuickFaqService {
  @Select(FaqState.currentLangFaq) private readonly faq$!: Observable<IFaq[]>;
  @Select(FaqState.quickFaqStatuses) private readonly quickFaqStatuses$!: Observable<Partial<Record<QuickFaqName, boolean>> | undefined>;

  readonly quickFaq$ = (name: QuickFaqName): Observable<IFaq | undefined> =>
    combineLatest([this.faq$, this.quickFaqStatuses$]).pipe(
      map(([faq, quickFaqStatuses]) => {
        if (!quickFaqStatuses || (quickFaqStatuses && !quickFaqStatuses[name])) {
          this.checkStatus(name);
          return faq.find((f) => f.url === name);
        }
        return;
      }),
    );
  readonly quickFaqStatus$ = (name: QuickFaqName): Observable<boolean> =>
    this.quickFaqStatuses$.pipe(
      map((statuses) => {
        return !!(statuses && statuses[name]);
      }),
    );

  @Dispatch() setStatus = (payload: { menuName: QuickFaqName; wasClosed: boolean }): QuickFaqSetStatus => new QuickFaqSetStatus(payload);
  @Dispatch() checkStatus = (menuName: QuickFaqName): QuickFaqCheckStatus => new QuickFaqCheckStatus(menuName);
}
