import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, DestroyRef, EventEmitter, HostBinding, inject, Input, NgModule, Output } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { RouterModule } from '@angular/router';
import {
  IMarketplaceKitItem,
  ISticker,
  MAX_ACCEPTABLE_OVERPRICE,
  MAX_ACCEPTABLE_RECOMMENDED_PRICE,
  P2pItemStatus,
  SkinClickEvent,
} from '@dev-fast/types';
import { TranslateModule } from '@ngx-translate/core';
import moment from 'moment';
import { InViewportAction, InViewportModule } from 'ng-in-viewport';
import { BehaviorSubject, NEVER, Observable, timer } from 'rxjs';
import { map, startWith, switchMap, takeWhile } from 'rxjs/operators';

import { CurrencyComponent } from '@app/core/currency';
import { LazyLoadImageModule, StopPropagationModule } from '@app/shared/directives';
import { AppCurrencyModule, HashPipe, StickerHashPipe } from '@app/shared/pipes';
import { IColorPalette, SKIN_RARITY, SKIN_RARITY_V2, toShortRevisionWear } from '@app/shared/utils';

import { FloatIndicatorModule } from '../float-indicator/float-indicator.component';
import { FloatLineModule } from '../float-line/float-line.component';
import { SkinItemTooltipModule } from '../tooltips/skin-item-tooltip/skin-item-tooltip.module';
import { SkinStatusesOverlayComponent } from './components/skin-statuses-overlay/skin-statuses-overlay.component';
import { SkinItemNewMockComponent } from './mock/skin-item-new.component.mock';
import { appearanceAnimation, skinItemAnimation } from './skin-item.animation';

@Component({
  selector: 'app-ui-skin-item-new',
  templateUrl: './skin-item-new.component.html',
  styleUrls: ['./skin-item-new.component.scss', '../float-indicator/float-indicator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [skinItemAnimation, appearanceAnimation],
})
export class SkinItemNewComponent {
  @Input() name = '';
  @Input() type: string | null = null;
  @Input() shortName: string | null = null;
  @Input() skinName: string | null = '';
  @Input() exterior: string | null = '';
  @Input() statTrak: boolean | null = false;
  @Input() phase: string | null | undefined = null;
  @Input() icon = '//d2lomvz2jrw9ac.cloudfront.net/common/currency/money-bag-pngrepo-com.png';

  @Input() float: number | undefined | null;
  @Input() color = '#ffffff';
  @Input() rarity: string | undefined;
  @Input() backgroundColor: string | undefined;
  @Input() backgroundIcon: string | undefined;
  @Input() id: number | null = null;
  @Input() appId: number | null = null;
  @Input() hoverBehavior = 'none';
  @Input() isSelect = false;
  @Input() tradable = true;
  @Input() passed = true;
  @Input() inTrade = false;
  @Input() newLook = false;
  @Input() isDisabled = false;
  @Input() stickers: ISticker[] | undefined = [];
  @Input() price: number | undefined;
  @Input() customCase = false;
  @Input() userProviderId: string | undefined;
  @Input() isAuth: boolean | null = true;

  @Input() set delay(value: number | null) {
    if (value) {
      this.time = value;
      this.#setupDelay();
    }
  }

  // Обычный таймер по возрастанию
  @Input() set timerFrom(value: number | undefined) {
    if (value) {
      this.#timer.next(false);
      this.timeFrom = +new Date() - value;
      this.#setupTimer();
    }
  }

  @Input() set kitPrice(value: { price: number; recommendedPrice: number | undefined } | undefined) {
    if (value) {
      this.price = value.price;
      if (value.recommendedPrice && value.price < MAX_ACCEPTABLE_RECOMMENDED_PRICE) {
        this.overprice = this.#getOverprice(value.recommendedPrice, value.price);
        if (this.overprice > MAX_ACCEPTABLE_OVERPRICE) {
          this.overpriceClass = 'big-overprice';
        }
      }
    }
  }

  @Input() set kitColor(value: { color: string; rarity: string | undefined } | undefined) {
    if (value) {
      this.color = value.color;
      this.rarity = value.rarity;
      this.#skinColorsPalette = SKIN_RARITY_V2;
    }
  }
  @Input() kit: IMarketplaceKitItem[] = [];
  @Input() status: P2pItemStatus = P2pItemStatus.Default;
  @Input() tradeLockEndAt: string | null = null;
  @Output() skinClick: EventEmitter<SkinClickEvent> = new EventEmitter<SkinClickEvent>();
  @Output() confirmed: EventEmitter<void> = new EventEmitter<void>();
  @Output() showItemDetails: EventEmitter<void> = new EventEmitter<void>();

  readonly #destroyRef = inject(DestroyRef);
  readonly #interval = 1000;

  #countdown: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  #timer: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  #skinColorsPalette: Record<string, IColorPalette> = SKIN_RARITY;
  isElementVisible = false;
  overpriceClass: '' | 'big-overprice' = '';
  overprice: number | null = null;
  P2pItemStatus = P2pItemStatus;
  time = 0;
  timeFrom = 0;
  countdown$: Observable<boolean> = this.#countdown.asObservable();
  timer$: Observable<boolean> = this.#timer.asObservable();
  isMenuOpen = false;
  popupFix?: boolean;
  showPopup?: boolean;

  #remainingSeconds$: Observable<number> = this.countdown$.pipe(
    switchMap((running: boolean) => (running ? timer(0, this.#interval) : NEVER)),
    map((t: number) => this.#remainingSeconds(t)),
    takeWhile((time: number) => time >= 0),
  );

  passedTime$: Observable<string> = this.timer$.pipe(
    switchMap((running: boolean) => (running ? timer(0, this.#interval) : NEVER)),
    map((t: number) => this.#passedTime(t)),
  );

  #ms$: Observable<number> = this.#remainingSeconds$.pipe(map((t: number) => this.#toMs(t)));

  seconds$: Observable<number> = this.#ms$.pipe(
    map((t: number) => this.#toSeconds(t)),
    startWith(this.#toSeconds(this.time)),
  );

  @HostBinding(`style.--background-color`)
  get defaultColor(): string {
    const key = (this.rarity ? this.rarity : this.color).toLowerCase();
    return this.#getSkinColorsPalette(key).default;
  }

  @HostBinding(`style.--background-hover-color`)
  get hoverColor(): string {
    const key = (this.rarity ? this.rarity : this.color).toLowerCase();
    return this.#getSkinColorsPalette(key).hover;
  }

  @HostBinding(`style.--background-active-color`)
  get activeColor(): string {
    const key = (this.rarity ? this.rarity : this.color).toLowerCase();
    return this.#getSkinColorsPalette(key).active;
  }

  @HostBinding('class.disabled') get _isDisabled(): boolean {
    return this.isDisabled || this.time > 0 || !this.tradable || !this.passed || this.inTrade;
  }

  @HostBinding('class.selected') get _isSelected(): boolean {
    return this.status === P2pItemStatus.Selected;
  }

  constructor() {
    this.#remainingSeconds$.pipe(takeUntilDestroyed(this.#destroyRef)).subscribe({
      complete: () => {
        this.confirmed.emit();
        this.#countdown.next(false);
        this.#timer.next(false);
      },
    });
  }

  select(): void {
    if (this.inTrade) {
      return;
    }

    this.skinClick.emit({
      icon: this.icon,
      name: this.name,
      price: this.price,
      color: this.color,
      id: this.id,
      appId: this.appId,
    });
  }

  toShortRevision(revision: string): string {
    return toShortRevisionWear(revision);
  }

  showItemDetailsModal(): void {
    this.showItemDetails.emit();
  }

  elementVisibleToggle(event: InViewportAction): void {
    this.isElementVisible = event.visible;
  }

  isPopupFullname(): boolean {
    return !!this.name && this.hoverBehavior !== 'highlight';
  }

  #getOverprice(recommendedPrice: number, price: number): number {
    return Math.round((1 - recommendedPrice / price) * 100);
  }

  #setupDelay(): void {
    this.#countdown.next(true);
  }

  #setupTimer(): void {
    this.#timer.next(true);
  }

  #currentSeconds(): number {
    return this.time / this.#interval;
  }

  #remainingSeconds(t: number): number {
    return this.#currentSeconds() - t;
  }

  #passedTime(t: number): string {
    return moment.utc(this.timeFrom + t * 1000).format('HH:mm:ss');
  }

  #toMs(t: number): number {
    return t * this.#interval;
  }

  #toSeconds(ms: number): number {
    return Math.floor((ms / this.#interval) % 60);
  }

  #getSkinColorsPalette(key: string): IColorPalette {
    const palette = this.#skinColorsPalette[key];
    return palette ? palette : this.#skinColorsPalette['default'];
  }
}
@NgModule({
  declarations: [SkinItemNewComponent, SkinItemNewMockComponent, SkinStatusesOverlayComponent],
  imports: [
    CommonModule,
    RouterModule,
    MatIconModule,
    MatMenuModule,
    AppCurrencyModule,
    TranslateModule,
    SkinItemTooltipModule,
    LazyLoadImageModule,
    HashPipe,
    StopPropagationModule,
    StickerHashPipe,
    FloatLineModule,
    FloatIndicatorModule,
    CurrencyComponent,
    InViewportModule,
    MatProgressSpinnerModule,
  ],
  exports: [SkinItemNewComponent, SkinItemNewMockComponent],
})
export class SkinItemNewModule {}
