import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, NgModule } from '@angular/core';

@Component({
  selector: 'app-ui-plug',
  template: ``,
  styleUrls: ['./plug.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlugComponent {}

@NgModule({
  imports: [CommonModule],
  declarations: [PlugComponent],
  exports: [PlugComponent],
})
export class PlugComponentModule {}
