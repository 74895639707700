<ng-container *ngIf="!isServer">
  <ng-container
    *ngIf="{
      user: user$ | async,
      panels: panels$ | async,
      quickFaq: quickFaq$ | async,
      isSomeItemsSelected: isSomeItemsSelected$ | async
    } as data"
  >
    <div class="right-sidebar-container">
      <ng-container *ngIf="data.panels && isPanelOpen(data.panels)">
        <div
          class="right-sidebar--panel-wrapper flex fx-column"
          [ngClass]="currentPanel(data.panels) + '-panel'"
        >
          <div
            *ngIf="!data.panels.includes(PanelEnum.TRADES) || data.user"
            class="panel-header flex fx-align-center fx-justify-between"
          >
            <div class="panel-header__title tw-font-bold">{{ 'P2P_WIDGETS.' + currentPanel(data.panels) | uppercase | translate }}</div>
            <button
              class="tw-block tw-bg-unset tw-text-gray-400 tw-border-none"
              (click)="closeAllPanels()"
              [class.lg:tw-hidden]="!data.panels.includes(PanelEnum.CHAT)"
              [class.lg:tw-block]="data.panels.includes(PanelEnum.CHAT)"
            >
              <mat-icon svgIcon="close"> </mat-icon>
            </button>
          </div>
          <div class="panel-content">
            <ng-container *ngIf="data.panels && data.panels.length >= 0">
              <ng-container [ngSwitch]="currentPanel(data.panels)">
                <ng-container *ngSwitchCase="PanelEnum.CHAT">
                  <app-chat-v2
                    *ngIf="data.panels.includes(PanelEnum.CHAT)"
                    (authAttempt)="openAuthModal()"
                    class="chat-panel"
                  ></app-chat-v2>
                </ng-container>
                <ng-container *ngSwitchCase="PanelEnum.TRADES">
                  <ng-container *ngIf="data.user !== undefined; else loader">
                    <ng-container *ngIf="data.user; else tradeStub">
                      <app-p2p></app-p2p>
                    </ng-container>
                    <ng-template #tradeStub>
                      <app-ui-trades-stub
                        (authEvent)="openAuthModal()"
                        (closePanelEvent)="closeAllPanels()"
                      >
                      </app-ui-trades-stub>
                    </ng-template>
                  </ng-container>
                </ng-container>
                <ng-container *ngSwitchDefault> </ng-container>
              </ng-container>

              <ng-template #loader>
                <app-ui-spinner-loader> </app-ui-spinner-loader>
              </ng-template>
            </ng-container>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="data.quickFaq && !data.isSomeItemsSelected">
        <div class="right-sidebar--panel-wrapper flex fx-column absolute">
          <app-quick-faq
            [activeFaq]="data.quickFaq"
            (oncloseClick)="closeQuickFaq(data.quickFaq.url)"
          >
          </app-quick-faq>
        </div>
      </ng-container>
    </div>
  </ng-container>
</ng-container>
