import { TemplatePortal } from '@angular/cdk/portal';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  inject,
  OnDestroy,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import {
  ActiveRoute,
  HistoryItem,
  ICaseItemDtoV2,
  IFilterFormContent,
  IFilterMethod,
  IMarketplaceKitData,
  IP2pDepositingItem,
  IP2pDepositItem,
  IP2pPurchaseItem,
  ISteamStoreInventory,
  IUserP2pPermissionInterface,
  MarketPanel,
  MarketSortingTypes,
  ModalNames,
  NewPanel,
  OrderStatusEnum,
  P2pItemStatus,
  PageInfo,
  StateActionStatus,
  WalletTransactionTypeId,
} from '@dev-fast/types';
import { filter, map, Observable, of, Subject, switchMap, tap } from 'rxjs';

import { PortalService } from '@app/core/portal-service';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { CasesRenewService } from '@app/games/lib/services/cases/cases-renew.service';
import { IS_SERVER_TOKEN } from '@app/shared/utils';
import { IFilterCaseWitchIcon } from '@app/ui/components/lib/market-item-card/market-item-card.types';

import { P2pMarketService, P2PModalService } from './services';

@Component({
  templateUrl: './p2p-market.component.html',
  styleUrls: ['./p2p-market.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class P2pMarketComponent implements OnDestroy, AfterViewInit {
  readonly #p2pMarketService: P2pMarketService = inject(P2pMarketService);
  readonly #p2pModalService: P2PModalService = inject(P2PModalService);
  readonly #portalService: PortalService = inject(PortalService);
  readonly #viewContainerRef: ViewContainerRef = inject(ViewContainerRef);
  readonly isServer: boolean = inject(IS_SERVER_TOKEN);
  readonly #casesRenewService = inject(CasesRenewService);

  // P2P data
  readonly data$ = this.#p2pMarketService.marketItems$;
  readonly activeTab$ = this.#p2pMarketService.activeTab$;
  readonly depositing$: Observable<IP2pDepositingItem[]> = this.#p2pMarketService.depositing$;
  readonly itemsForPurchase$: Observable<IP2pPurchaseItem[]> = this.#p2pMarketService.purchasing$;
  readonly listLoadingStatus$: Observable<StateActionStatus> = this.#p2pMarketService.listLoadingStatus$;
  private readonly marketHistory$: Observable<HistoryItem[]> = this.#p2pMarketService.marketHistory$;
  //steam inventory
  readonly steamInventoryItems$ = toSignal<ISteamStoreInventory[]>(this.#p2pMarketService.steamInventoryItems$);
  readonly steamInventoryCachedAt$: Observable<string> = this.#p2pMarketService.steamInventoryCachedAt$;
  // filter
  readonly filterResultCount$: Observable<number | null> = this.#p2pMarketService.searchCount$;
  readonly searchResultCount$: Observable<number | null> = this.#p2pMarketService.preSearchCount$;
  readonly filters$: Observable<IFilterFormContent<MarketSortingTypes>> = this.#p2pMarketService.filters$;
  // View
  readonly pagination$: Observable<PageInfo | null> = this.#p2pMarketService.pagination$;
  // Enums && consts
  readonly MarketPanel: typeof MarketPanel = MarketPanel;
  readonly ActionStatusEnum: typeof StateActionStatus = StateActionStatus;
  readonly mockCardsArray = new Array(24);
  // Template
  isMarketItemsSelected = false;
  isAuth = toSignal<boolean>(this.#p2pMarketService.isAuth$);
  canShowSihCard = toSignal<boolean>(
    this.#p2pMarketService.isAuth$.pipe(
      switchMap((isAuth) =>
        isAuth ? this.#p2pMarketService.sih$.pipe(map((sih) => !!sih && (!sih.online || !sih.permission))) : of(false),
      ),
    ),
  );
  selectedSteamItems = toSignal<IP2pDepositItem[]>(this.#p2pMarketService.selectedSteamItems$.pipe(filter((val) => !!val)));
  #p2pPermissions = toSignal<IUserP2pPermissionInterface>(this.#p2pMarketService.p2pPermissions$.pipe(filter((val) => !!val)));
  itemsForPurchase = toSignal(
    this.itemsForPurchase$.pipe(
      filter((val) => !!val),
      tap((val) => {
        this.isMarketItemsSelected = val.some((item) => item.status === OrderStatusEnum.NEW);
      }),
    ),
  );
  //
  resetFilters$: Subject<Date> = new Subject();

  @ViewChild('controlPanelTmpl') controlPanelTmpl: TemplateRef<unknown> | undefined;

  constructor() {
    if (this.isServer) {
      return;
    }
    this.#p2pMarketService.setActiveRoute(ActiveRoute.STORE);
  }

  ngAfterViewInit(): void {
    if (!this.controlPanelTmpl) {
      return;
    }
    this.#portalService.controlPanelPortal.value = new TemplatePortal(this.controlPanelTmpl, this.#viewContainerRef, {});
  }
  ngOnDestroy(): void {
    if (this.isServer) {
      return;
    }
    this.#p2pMarketService.setActiveRoute(ActiveRoute.INITIAL);
    this.#portalService.controlPanelPortal.value = undefined;
  }

  resetFilters(): void {
    this.resetFilters$.next(new Date());
  }

  trackingFunction(index: number, item: IMarketplaceKitData | ICaseItemDtoV2): number {
    return item.id;
  }

  steamInventoryTrackingFunction(index: number, item: ISteamStoreInventory): number {
    return item.steamInventoryId;
  }

  openTradePanel(): void {
    this.#p2pMarketService.openPanel(NewPanel.TRADES);
  }

  showMoreItemsLoader(searchCount: number | null, filterResultCount: number | null): boolean {
    return (filterResultCount === null && searchCount === null) || this.isServer;
  }

  showNoItemsPlaceholder(itemsLength: number, searchCount?: number | null): boolean {
    return itemsLength === 0 && searchCount === 0;
  }

  onSelectItem(item: IMarketplaceKitData | ICaseItemDtoV2): void {
    if ('items' in item) {
      if (this.isAuth()) {
        if (item.status === OrderStatusEnum.NEW) {
          this.#p2pMarketService.toggleSelected(item);
        } else {
          this.#p2pMarketService.openPanel(NewPanel.TRADES);
        }
      } else {
        this.#p2pModalService.openAuthModal();
      }
    }
  }

  login(): void {
    this.#p2pModalService.openAuthModal();
  }

  onDetailsClick(item: IMarketplaceKitData | ISteamStoreInventory): void {
    this.#p2pModalService.openKITModal(item);
  }

  openMarketHistoryModal(): void {
    this.#p2pMarketService.getMarketHistory({ typeIds: [WalletTransactionTypeId.DEPOSIT_ITEMS, WalletTransactionTypeId.P2P_PAYOUT] });
    this.#p2pModalService.openHistoryModal(this.marketHistory$);
  }
  openModalByName(modalName: ModalNames, payload?: any): void {
    this.#p2pModalService.openModal(modalName, payload);
  }

  openCase(item: IFilterCaseWitchIcon): void {
    /**
     * todo: Пока страницу с товаром не вынесем в отдельный
     * компонент. Нужно делать задержку чтобы успел загрузиться кейс
     */
    setTimeout(() => {
      this.#casesRenewService.getItemDetails(item);
    }, 1000);
  }

  selectSteamInventoryItem(item: ISteamStoreInventory, items: IP2pDepositingItem[]): void {
    if (!this.#p2pPermissions()) {
      return;
    }

    const status = this.steamItemStatus(item);

    if (status === P2pItemStatus.Default || status === P2pItemStatus.Selected) {
      this.#p2pMarketService.toggleDepositItem(item);
    } else if (this.#p2pPermissions()?.canTrade?.success && status == P2pItemStatus.ReadyToSell) {
      const item$ = this.#filterDepositing(item.steamInventoryId, items);

      if (item$) {
        this.#p2pModalService.openAuctionModal(item$);
      }
    }
  }

  itemStatus(id: number): P2pItemStatus {
    if (this.itemsForPurchase()?.some((item) => item.id === id && item.status === OrderStatusEnum.AUCTION_FINISHED)) {
      return P2pItemStatus.Sold;
    }
    if (this.itemsForPurchase()?.some((item) => item.id === id)) {
      return P2pItemStatus.Selected;
    }
    //TODO переделать метод определения
    // if (selected.some((item) => item.id === id)) return P2pItemStatus.ReadyToSell;
    return P2pItemStatus.Default;
  }

  steamItemStatus(steamItem: ISteamStoreInventory): P2pItemStatus {
    if (this.selectedSteamItems()?.some((item) => item.steamInventoryId === steamItem.steamInventoryId)) {
      return P2pItemStatus.Selected;
    }
    if (steamItem.inTrade) {
      return P2pItemStatus.Deposit;
    }
    if (!steamItem.passed || !steamItem.tradable) {
      return P2pItemStatus.Disabled;
    }
    return P2pItemStatus.Default;
  }
  onScrollToBottom(scrolled = false, pagination: PageInfo | null): void {
    if (pagination && scrolled) {
      this.#p2pMarketService.changePage(pagination.numberPage + 1);
    }
  }

  sortSteamItems(sortingMethodIndex: number, sortingMethods: IFilterMethod<MarketSortingTypes>[] | null): void {
    if (sortingMethods) {
      this.#p2pMarketService.sortSteamInventoryByMethod(sortingMethods[sortingMethodIndex].value);
    }
  }

  #filterDepositing(itemId: number, items: IP2pDepositingItem[]): Observable<IP2pDepositingItem> | undefined {
    const item = items.find((i) => i.id === itemId);
    if (item) {
      return of(item);
    }
    return;
  }
}
