import { animate, query, style, transition, trigger } from '@angular/animations';

export const cardEnterAnimation = trigger('swipeCategory', [
  transition(':enter', [
    query(':self', [style({ opacity: '0' }), animate('150ms 150ms ease-in-out', style({ opacity: '1' }))]),
  ]),
  transition(':leave', [
    query(':self', [style({ opacity: '1' }), animate('150ms ease-in-out', style({ opacity: '0' }))]),
  ]),
]);
