import { IconConfig } from '@dev-fast/types';

export const iconsDouble: IconConfig[] = [
  {
    name: 'double-cursor',
    path: 'assets/img/mat-icons/double/double-cursor.svg',
  },
  {
    name: 'double-progress',
    path: 'assets/img/mat-icons/double/double-progress.svg',
  },
  {
    name: 'double-roulette',
    path: 'assets/img/mat-icons/double/double-roulette.svg',
  },
];
