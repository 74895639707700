import { ChangeDetectionStrategy, Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  DateFormat,
  IMarketplaceKitData,
  IUserP2pPermissionInterface,
  ModalNames,
  OrderStatusEnum,
  SocialType,
  SteamErrorsEnum,
  SteamErrorsEnumLocales,
  WalletTypeEnum,
} from '@dev-fast/types';
import { interval, Observable, of, timer } from 'rxjs';
import { filter, map, startWith, switchMap, takeUntil, tap } from 'rxjs/operators';

import { CommonStoreService } from '@app/core-state-common';
import { P2pBuyingService } from '@app/modules/p2p-new';

import { P2pPurchaseTradeModalComponent } from '../../modals/p2p-purchase-trade-modal/p2p-purchase-trade-modal.component';
import { P2pPurchaseService } from '../p2p-purchase.service';
import {
  BID_ON_ORDER_STATUSES,
  ORDER_CANCELED_STATUSES,
  ORDER_SUCCESS_STATUSES,
  PosibleBodyBorderClass,
} from './p2p-purchase-item.constants';

@Component({
  selector: 'app-p2p-purchase-item',
  templateUrl: './p2p-purchase-item.component.html',
  styleUrls: ['./p2p-purchase-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class P2pPurchaseItemComponent {
  readonly #dialog = inject(MatDialog);
  readonly #commonService = inject(CommonStoreService);
  readonly #p2pBuyingService = inject(P2pBuyingService);
  readonly #p2pPurchaseService = inject(P2pPurchaseService);

  @Input() rwtAgree = true;
  @Input() set purchaseItem(purchaseItem: IMarketplaceKitData | undefined) {
    if (purchaseItem) {
      this.#updatePurchaseItem(purchaseItem);
    }
  }
  @Output() oncheckValidation: EventEmitter<void> = new EventEmitter();
  // Template
  offerTimer$: Observable<number> | undefined;
  item: IMarketplaceKitData | undefined;
  dateFormat: DateFormat = DateFormat.MINUTES;
  bodyBorderClass: PosibleBodyBorderClass = '';
  lotStatusTitleLocale = '';
  showTimer = true;
  orderCompleted = false;
  // Enums
  readonly SteamErrorsEnum = SteamErrorsEnum;
  readonly OrderStatusEnum = OrderStatusEnum;
  // Consts
  readonly permissions$: Observable<IUserP2pPermissionInterface> = this.#p2pPurchaseService.p2pPermissions$;
  readonly canBid$: Observable<boolean> = this.#commonService
    .getWalletById(WalletTypeEnum.BALANCE)
    .pipe(map((amount) => (this.item ? amount >= this.item?.price : false)));
  readonly #bidOnOrderStatuses: OrderStatusEnum[] = BID_ON_ORDER_STATUSES;
  readonly #orderSuccessStatuses: OrderStatusEnum[] = ORDER_SUCCESS_STATUSES;
  readonly #orderCanceledStatuses: OrderStatusEnum[] = ORDER_CANCELED_STATUSES;

  bindingSocial(): void {
    this.#p2pPurchaseService.bindProvider(SocialType.Steam);
  }
  createBid(id: number): void {
    if (this.rwtAgree) {
      this.#p2pBuyingService.createBid(id);
    } else {
      this.oncheckValidation.emit();
    }
  }
  confirm(id: number): void {
    if (this.rwtAgree) {
      this.#p2pBuyingService.confirmBid(id);
    } else {
      this.oncheckValidation.emit();
    }
  }
  onClose(idx: number): void {
    this.#p2pBuyingService.close(idx);
  }
  onDeleted(idx: number): void {
    this.#p2pBuyingService.deleted(idx);
  }
  // FIXME Переделать метод открытия на modal.service
  getTrade(): void {
    if (this.rwtAgree) {
      this.#dialog.open(P2pPurchaseTradeModalComponent, {
        data: of(this.item),
        panelClass: 'p2p-trade-accept',
      });
    } else {
      this.oncheckValidation.emit();
    }
  }
  onSetupSteam(): void {
    this.#p2pBuyingService.openModal(ModalNames.P2P_SETUP);
  }
  onShowLotDetailsModal(item: IMarketplaceKitData): void {
    this.#p2pBuyingService.openModal(ModalNames.P2P_KIT_DETAILS, item);
  }
  #setOfferTime$(item: IMarketplaceKitData): Observable<number> {
    return of(item).pipe(
      filter(({ nextStatusAt }) => Date.parse(nextStatusAt) > Date.now()),
      switchMap(({ nextStatusAt }) =>
        interval(1000).pipe(
          startWith(Date.parse(nextStatusAt) - Date.now()),
          map(() => Date.parse(nextStatusAt) - Date.now()),
          tap((val) => (this.dateFormat = this.#getDateFormat(val))),
          takeUntil(timer(Date.parse(nextStatusAt) - Date.now())),
        ),
      ),
    );
  }
  #formatLotStatusTitleLocale(item: IMarketplaceKitData): string {
    if (item.status === OrderStatusEnum.COMPLETED) {
      return 'P2P_WIDGETS.PURCHASE.OFFER_PHASES.OFFER_COMPLETED';
    } else if (item.status === OrderStatusEnum.CANCELED_BY_TIMER) {
      return 'P2P_WIDGETS.PURCHASE.OFFER_PHASES.OFFER_CANCELED_BY_TIMER';
    } else if ([...this.#bidOnOrderStatuses, OrderStatusEnum.NEW].includes(item.status)) {
      return 'P2P_WIDGETS.RENEW_SIDEBAR.WAITING';
    } else if (this.#orderSuccessStatuses.includes(item.status)) {
      return item.isLastBidByUser ? 'P2P_WIDGETS.PURCHASE.OFFER_PHASES.OFFER_SUCCESS' : 'P2P_WIDGETS.PURCHASE.OFFER_PHASES.OFFER_LOOSE';
    } else if (this.#orderCanceledStatuses.includes(item.status)) {
      return 'P2P_WIDGETS.PURCHASE.OFFER_PHASES.OFFER_CANCELED';
    } else if (item.status === OrderStatusEnum.AUCTION_FINISHED) {
      return 'P2P_WIDGETS.RENEW_SIDEBAR.AUCTION_FINISHED';
    }
    return '';
  }
  #formatBodyBorderClass(item: IMarketplaceKitData): PosibleBodyBorderClass {
    if (this.#orderSuccessStatuses.includes(item.status) && item.isLastBidByUser) {
      return 'lot-success';
    }
    if (item.status === OrderStatusEnum.COMPLETED) {
      this.showTimer = false;
      return 'lot-completed';
    }
    if ([...this.#orderCanceledStatuses, OrderStatusEnum.CANCELED_BY_TIMER].includes(item.status)) {
      this.showTimer = false;
      return 'lot-canceled';
    }
    return '';
  }
  // TODO Move to utils
  #getDateFormat(data: number): DateFormat {
    return data > 3600000 ? DateFormat.DEFAULT : DateFormat.MINUTES;
  }
  #isOrderCompleted(item: IMarketplaceKitData): boolean {
    return (
      [...this.#orderCanceledStatuses, OrderStatusEnum.CANCELED_BY_TIMER, OrderStatusEnum.COMPLETED].includes(item.status) ||
      (OrderStatusEnum.WAIT_FOR_BUYER_ACCEPT === item.status && !item.isLastBidByUser)
    );
  }
  //FIXME Наглый копипаст аж в трех местах и напрашивается на превращение в компонент\директиву. Иделаьное решение: сделать компонент, который выводит текст или делает действие или все сразу в зависимости от ошибки
  formatErrBtnText(permissions: IUserP2pPermissionInterface): string {
    let title: string | undefined = 'P2P_SETTINGS.SETUP_TITLE';
    const errorMsg = permissions?.canTrade?.error || permissions?.canSteamAPI?.error || permissions?.error;
    if (errorMsg) {
      title = SteamErrorsEnumLocales[errorMsg];
    }
    return title ?? 'P2P_SETTINGS.SETUP_TITLE';
  }
  handleErrorAction(permissions: IUserP2pPermissionInterface): void {
    const errorMsg = permissions?.canTrade?.error || permissions?.canSteamAPI?.error || permissions?.error;
    switch (errorMsg) {
      case SteamErrorsEnum.UNBINDING_STEAM:
        window.open('/account/main', '_blank');
        break;
      case SteamErrorsEnum.STEAM_GUARD:
        window.open('https://store.steampowered.com/mobile', '_blank');
        break;
      case SteamErrorsEnum.PRIVATE_INVENTORY:
        window.open('https://steamcommunity.com/my/edit/settings', '_blank');
        break;
      case SteamErrorsEnum.STEAM_TRADE_BAN:
      case SteamErrorsEnum.STEAM_GUARD_HOLD:
        break;
      case SteamErrorsEnum.INVALID_TRADELINK:
      case SteamErrorsEnum.NO_API_KEY:
      default:
        this.onSetupSteam();
        break;
    }
  }

  #updatePurchaseItem(purchaseItem: IMarketplaceKitData): void {
    this.item = {
      ...purchaseItem,
      status: this.#preparingStatusesInTrade(purchaseItem.status),
    };
    this.bodyBorderClass = this.#formatBodyBorderClass(this.item);
    this.lotStatusTitleLocale = this.#formatLotStatusTitleLocale(this.item);
    this.offerTimer$ = this.#setOfferTime$(this.item);
    this.orderCompleted = this.#isOrderCompleted(this.item);
  }

  #preparingStatusesInTrade(status: OrderStatusEnum): OrderStatusEnum {
    switch (status) {
      case OrderStatusEnum.AUCTION_FINISHED:
        return OrderStatusEnum.WAIT_FOR_TRADE;
      case OrderStatusEnum.CRATED:
        return OrderStatusEnum.WAIT_FOR_TRADE;
      default:
        return status;
    }
  }
}
