<div class="category-container">
  <div class="items-category-wrapper">
    <ng-container *ngIf="!isServer && collection?.cases; else mock">
      <ng-container *ngIf="collection?.cases; else emptySearchTemplate">
        <div class="items-category-container">
          <app-ui-case-card
            *ngFor="let item of collection?.cases || []; trackBy: trackById; let playerIndex = index"
            [item]="item"
            [eventName]="eventName"
            @swipeCategory
          >
          </app-ui-case-card>
        </div>
      </ng-container>
    </ng-container>
    <ng-template #mock>
      <div class="items-category-container items-category-container_mock">
        <app-ui-case-card-mock></app-ui-case-card-mock>
        <app-ui-case-card-mock></app-ui-case-card-mock>
        <app-ui-case-card-mock></app-ui-case-card-mock>
        <app-ui-case-card-mock></app-ui-case-card-mock>
        <app-ui-case-card-mock></app-ui-case-card-mock>
        <app-ui-case-card-mock></app-ui-case-card-mock>
        <app-ui-case-card-mock></app-ui-case-card-mock>
        <app-ui-case-card-mock></app-ui-case-card-mock>
        <app-ui-case-card-mock></app-ui-case-card-mock>
        <app-ui-case-card-mock></app-ui-case-card-mock>
        <app-ui-case-card-mock></app-ui-case-card-mock>
        <app-ui-case-card-mock></app-ui-case-card-mock>
      </div>
    </ng-template>
    <ng-template #emptySearchTemplate>
      <div class="collection_is_none">
        <mat-icon
          class="empty_search"
          [svgIcon]="'empty-search'"
        ></mat-icon>
        <div>
          <div class="empty_search-title">
            {{ 'LOCAL.CASES.CATEGORIES.FILTERS.NOT_FOUND_WARNING.WARNING_TITLE' | translate }}
          </div>
          <div class="empty_search-description">
            {{ 'LOCAL.CASES.CATEGORIES.FILTERS.NOT_FOUND_WARNING.WARNING_TEXT' | translate }}
          </div>
        </div>
      </div>
    </ng-template>
  </div>
</div>
