import { Injectable } from '@angular/core';
import {
  ActivePanel,
  ActiveRoute,
  HistoryItem,
  ICaseItemDtoV2,
  IFilterFormContent,
  IFilterMethod,
  IMarketplaceKitData,
  IP2pDepositingItem,
  IP2pDepositItem,
  IP2pPurchaseItem,
  ISihDataset,
  ISteamStoreInventory,
  ITransactionHistoryIds,
  IUserP2pPermissionInterface,
  MarketPanel,
  MarketSortingTypes,
  NewPanel,
  OrderStatusEnum,
  PageInfo,
  SihParams,
  StateActionStatus,
} from '@dev-fast/types';
import { Actions, Select } from '@ngxs/store';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { combineLatest, distinctUntilChanged, filter, map, Observable } from 'rxjs';

//TODO fix
// eslint-disable-next-line @nx/enforce-module-boundaries
import { AuthState } from '@app/auth';
import { AllowTradePanelAutoOpen, ChangeActivePanel, OpenPanel, SetActiveRoute, ToggleBackground } from '@app/core/state/layout';
import {
  ApplyFilters,
  ApplySteamInventoryFilters,
  ChangeActiveTab,
  ChangeFilters,
  ChangePage,
  ChangeSteamInventoryFilters,
  ClearMeta,
  GetCustomCasesMetaData,
  GetItemSihStats,
  GetMarketHistory,
  GetMarketItems,
  GetSteamInventory,
  P2pBuyingState,
  P2pCommonState,
  P2pDepositState,
  P2pMarketState,
  ResetSihStat,
  SortSteamInventoryByMethod,
  ToggleDepositItem,
  ToggleSelected,
} from '@app/core/state/p2p';
import { UserState } from '@app/core/state/user-store';
import { actionLoadStatus } from '@app/shared/utils';

const PURCHASE_SUCCESSFULL = [OrderStatusEnum.WAIT_FOR_TRADE, OrderStatusEnum.WAIT_FOR_CONFIRM, OrderStatusEnum.WAIT_FOR_BUYER_ACCEPT];
const DEPOSIT_SUCCESSFULL = [
  OrderStatusEnum.FIRST_BID,
  OrderStatusEnum.SECOND_BID,
  OrderStatusEnum.THIRD_PLUS_BID,
  OrderStatusEnum.WAIT_FOR_TRADE,
  OrderStatusEnum.WAIT_FOR_BUYER_ACCEPT,
  OrderStatusEnum.WAIT_FOR_CONFIRM,
];

@Injectable()
export class P2pMarketService {
  @Select(AuthState.isAuth)
  readonly isAuth$!: Observable<boolean>;
  @Select(UserState.p2pPermissions)
  readonly p2pPermissions$!: Observable<IUserP2pPermissionInterface>;
  @Select(P2pCommonState.steamInventory)
  readonly steamInventoryItems$!: Observable<ISteamStoreInventory[]>;
  @Select(P2pCommonState.steamInventoryCachedAt)
  readonly steamInventoryCachedAt$!: Observable<string>;
  @Select(P2pBuyingState.purchasing)
  readonly purchasing$!: Observable<IP2pPurchaseItem[]>;
  // P2pDepositState
  @Select(P2pDepositState.selected)
  readonly selectedSteamItems$!: Observable<IP2pDepositItem[]>;
  @Select(P2pDepositState.depositingItems)
  readonly depositing$!: Observable<IP2pDepositingItem[]>;
  // P2pMarketState
  @Select(P2pMarketState.items)
  readonly marketItems$!: Observable<(IMarketplaceKitData | ICaseItemDtoV2)[]>;
  @Select(P2pMarketState.preSearchItemsLength)
  readonly preSearchCount$!: Observable<number | null>;
  @Select(P2pMarketState.searchItemsLength)
  readonly searchCount$!: Observable<number | null>;
  @Select(P2pMarketState.filters)
  readonly filters$!: Observable<IFilterFormContent<MarketSortingTypes>>;
  @Select(P2pMarketState.pagination)
  readonly pagination$!: Observable<PageInfo | null>;
  @Select(P2pMarketState.sortingMethods)
  readonly sortingMethods$!: Observable<IFilterMethod<MarketSortingTypes>[] | null>;
  @Select(P2pMarketState.activeTab)
  readonly activeTab$!: Observable<MarketPanel>;
  @Select(P2pMarketState.marketHistory)
  readonly marketHistory$!: Observable<HistoryItem[]>;
  @Select(P2pMarketState.sihDataset)
  readonly sihDataset$!: Observable<ISihDataset>;
  @Select(P2pMarketState.isLoading)
  readonly isLoading$!: Observable<boolean>;

  readonly listLoadingStatus$: Observable<StateActionStatus> = actionLoadStatus(this.actions$, ChangePage).pipe(
    distinctUntilChanged((prev, curr) => prev === curr),
  );

  readonly p2pActionsToNotify$: Observable<number> = combineLatest([this.depositing$, this.purchasing$]).pipe(
    map(([depositing = [], purchasing = []]) => {
      const notyCount =
        depositing.filter((d: IP2pDepositingItem) => DEPOSIT_SUCCESSFULL.includes(d.status)).length +
        purchasing.filter((p: IP2pPurchaseItem) => PURCHASE_SUCCESSFULL.includes(p.status)).length;
      return notyCount;
    }),
  );
  sih$: Observable<SihParams | undefined> = this.p2pPermissions$.pipe(
    filter((value) => !!value),
    map((permissions) => permissions?.connections?.SIH),
  );
  constructor(private readonly actions$: Actions) {}

  @Dispatch() setActiveRoute = (route: ActiveRoute): SetActiveRoute => new SetActiveRoute(route);
  @Dispatch() toggleBackground = (background?: string): ToggleBackground => new ToggleBackground(background);
  // Market
  @Dispatch() requestCustomCasesMeta = (): GetCustomCasesMetaData => new GetCustomCasesMetaData();
  @Dispatch() getItemSihStats = (itemName: string): GetItemSihStats => new GetItemSihStats(itemName);
  @Dispatch() getMarketItems = (params?: IFilterFormContent<MarketSortingTypes>): GetMarketItems => new GetMarketItems(params);
  @Dispatch() toggleSelected = (value: IMarketplaceKitData): ToggleSelected => new ToggleSelected(value);
  @Dispatch() applyFilters = (): ApplyFilters => new ApplyFilters();
  @Dispatch() changeFilters = (params?: IFilterFormContent<MarketSortingTypes>): ChangeFilters => new ChangeFilters(params);
  @Dispatch() changeActiveTab = (tab: MarketPanel): ChangeActiveTab => new ChangeActiveTab(tab);
  @Dispatch() getMarketHistory = (params: ITransactionHistoryIds): GetMarketHistory => new GetMarketHistory(params);
  @Dispatch() changePage = (page: number): ChangePage => new ChangePage(page);
  @Dispatch() clearMeta = (): ClearMeta => new ClearMeta();
  // Modals

  // Steam Inventory
  @Dispatch() toggleDepositItem = (payload: ISteamStoreInventory): ToggleDepositItem => new ToggleDepositItem(payload);
  @Dispatch() getSteamInventory = (params?: IFilterFormContent<MarketSortingTypes>): GetSteamInventory => new GetSteamInventory(params);
  @Dispatch() changeSteamInventoryFilters = (params?: IFilterFormContent<MarketSortingTypes>): ChangeSteamInventoryFilters =>
    new ChangeSteamInventoryFilters(params);
  @Dispatch() applySteamInventoryFilters = (): ApplySteamInventoryFilters => new ApplySteamInventoryFilters();
  @Dispatch() sortSteamInventoryByMethod = (sortBy: MarketSortingTypes): SortSteamInventoryByMethod =>
    new SortSteamInventoryByMethod(sortBy);
  @Dispatch() changeActivePanel = (panel: ActivePanel): ChangeActivePanel => new ChangeActivePanel(panel);
  @Dispatch() openPanel = (panel: NewPanel): OpenPanel => new OpenPanel(panel);

  @Dispatch() allowTradePanelAutoOpen = (isOpen: boolean): AllowTradePanelAutoOpen => new AllowTradePanelAutoOpen(isOpen);
  @Dispatch() resetSihStat = (): ResetSihStat => new ResetSihStat();
}
