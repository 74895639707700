export enum CaseBattlePhases {
  INITIALIZE = 'initialize',
  ROUND = 'round',
  RAFFLE = 'raffle',
  VICTORY = 'victory',
}
export enum CaseBattleLobbyLists {
  GAMES = 'games',
  CASES = 'cases',
}
export enum CaseBattleLists {
  HISTORY_GAMES = 'history',
  ACTIVE_GAMES = 'active',
}
export enum CaseBattleGameStatus {
  JOIN = 'join',
  AWAIT = 'await',
  DRAW = 'draw',
  END = 'end',
  ERROR = 'error',
}
