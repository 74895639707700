<div
  class="tech-works--widget"
  [ngClass]="{ 'tech-works--widget__hidden': state }"
>
  <div class="central-content">
    <img
      src="/assets/img/header/traffic-cone.png"
      alt="traffic-cone.png"
    />
    <div
      class="central-content--text"
      *ngIf="timer$"
    >
      {{ 'OFFLINE.TECH_WORK1' | translate: { date: config | date: 'dd.MM', time: config | date: 'HH:mm' } }}
      <div class="additional">
        {{ 'OFFLINE.TECH_WORK2' | translate: { timer: timer$ | async } }}
      </div>
    </div>
  </div>

  <button (click)="onClose()">
    <span class="btn-text">
      {{ 'OFFLINE.HIDE' | translate }}
    </span>
    <span class="btn-icon">
      <mat-icon [svgIcon]="'close'"></mat-icon>
    </span>
  </button>
</div>
