import { OverlayModule } from '@angular/cdk/overlay';
import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule, TransferState } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';
import { LocalizationApiService } from '@dev-fast/backend-services';
import { GIFT_PROVIDERS_TOKEN } from '@dev-fast/types';
import { NoIndexComponentModule, PlugComponentModule } from '@dev-fast/ui-components';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { NgxsRouterPluginModule, RouterStateSerializer } from '@ngxs/router-plugin';
import { NgxsModule } from '@ngxs/store';
import { NgxsActionsExecutingModule } from '@ngxs-labs/actions-executing';
import { NgxsDispatchPluginModule } from '@ngxs-labs/dispatch-decorator';
import * as Sentry from '@sentry/angular-ivy';
import { TUI_SANITIZER, TuiRootModule } from '@taiga-ui/core';
import { NgDompurifySanitizer } from '@tinkoff/ng-dompurify';
import { SocketIoConfig, SocketIoModule } from 'ngx-socket-io';
import { NgxsResetPluginModule } from 'ngxs-reset-plugin';

import { AuthState } from '@app/auth';
import { customTranslateLoader } from '@app/core/custom-trans-loader';
import { ENVIRONMENTS } from '@app/core/environment-service';
import { httpInterceptorProviders } from '@app/core/interceptors';
import { NAVIGATION_PATHS, PATHS } from '@app/core/navigation/common';
import { SEO_ROUTES } from '@app/core/seo-service';
import { CustomRouterStateSerializer } from '@app/core/state/utils';
import { BB_ROUTES, FEATURE_FLAGS } from '@app/feature-flags';
import { GofastLayoutModule } from '@app/gofast/layout-new';
import { ConfirmDialogModule, ConfirmDialogService } from '@app/shared/modules';
import { IS_SERVER_TOKEN, SHOW_CASE_PRICE } from '@app/shared/utils';
import { SPECIAL_ICONS, UiIconsModule } from '@app/ui/icons';
import { RecentActivitiesWidgetsModule } from '@app/widgets/recent-activities';

import { customIcons } from '../constants/custom-icons.const';
import { GIFT_PROVIDERS } from '../constants/gift-providers.const';
import { seoRoutes } from '../constants/seo-routes.const';
import { LOGIN_PROVIDERS, SOCIALS_MEDIA } from '../constants/socials.const';
import { environment } from '../environments/environment';
import { bbConfig, featureFlags } from '../environments/feature-flags';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { STATES } from './core-states';

const socketConfig: SocketIoConfig = {
  url: environment.WS_GATEWAY_URL,
  options: {
    reconnection: true,
    reconnectionDelay: 1000,
    transports: ['websocket'],
  },
};

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    // TODO возможно распределить некоторые стейты в лэзи модули
    NgxsModule.forRoot([AuthState, ...STATES /**NyAllEventState,NyEventState*/], {
      developmentMode: !environment.production,
    }),
    NgxsRouterPluginModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    NgxsDispatchPluginModule.forRoot(),
    NgxsResetPluginModule.forRoot(),
    NgxsActionsExecutingModule.forRoot(),
    SocketIoModule.forRoot(socketConfig),
    TranslateModule.forRoot({
      useDefaultLang: true,
      defaultLanguage: 'default-locale',
      loader: {
        provide: TranslateLoader,
        useFactory: customTranslateLoader,
        deps: [LocalizationApiService, TransferState],
      },
      isolate: false,
    }),
    BrowserAnimationsModule,
    UiIconsModule,
    OverlayModule,
    MatTooltipModule,
    MatDialogModule,
    MatMenuModule,
    ConfirmDialogModule,
    NgxsFormPluginModule.forRoot(),
    NgxsReduxDevtoolsPluginModule.forRoot({ disabled: environment.production }),
    GofastLayoutModule,
    PlugComponentModule,
    NoIndexComponentModule,
    RecentActivitiesWidgetsModule.forRoot(),
    NgxsLoggerPluginModule.forRoot({
      // Do not collapse log groups
      collapsed: true,
      // Do not log in production mode
      disabled: environment.production || !environment.LOG_ACTIONS,
    }),
    // TODO если будет нужно кропать изображение на мобилке то разобраться с Hummer JS
    // HammerModule,
    TuiRootModule,
  ],
  providers: [
    httpInterceptorProviders(environment.production),
    ConfirmDialogService,
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => null,
      deps: [Sentry.TraceService],
      multi: true,
    },
    { provide: RouterStateSerializer, useClass: CustomRouterStateSerializer },
    {
      provide: ENVIRONMENTS,
      useValue: environment,
    },
    {
      provide: SEO_ROUTES,
      useValue: seoRoutes,
    },
    {
      provide: FEATURE_FLAGS,
      useValue: featureFlags,
    },
    {
      provide: BB_ROUTES,
      useValue: bbConfig,
    },
    {
      provide: PATHS,
      useValue: NAVIGATION_PATHS,
    },
    {
      provide: IS_SERVER_TOKEN,
      useValue: false,
    },
    {
      provide: SHOW_CASE_PRICE,
      useValue: false,
    },
    {
      // Дефолтные сео данные. Вставляются если сео данные для конкретной страницы не найдены
      // Вынесены сюда для того, чтобы во все наши проекты не вставлялись данные про игры и режимы
      provide: 'seoDefaultData',
      useValue: { title: 'SEO.TITLE.UNIVERSAL', description: 'SEO.DESCRIPTION.DEFAULT' },
    },
    {
      provide: 'login-providers',
      useValue: LOGIN_PROVIDERS,
    },
    {
      provide: 'socialsFooter',
      useValue: SOCIALS_MEDIA,
    },
    {
      provide: GIFT_PROVIDERS_TOKEN,
      useValue: GIFT_PROVIDERS,
    },
    {
      provide: SPECIAL_ICONS,
      useValue: customIcons,
    },
    { provide: TUI_SANITIZER, useClass: NgDompurifySanitizer },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
