import { DOCUMENT } from '@angular/common';
import { Directive, ElementRef, Inject, Input, OnInit, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appDecimalBoldFormat]',
})
export class DecimalBoldFormatDirective implements OnInit {
  @Input('appDecimalBoldFormat')
  number: string | null | undefined;

  constructor(private el: ElementRef, private renderer: Renderer2, @Inject(DOCUMENT) private document: Document) {}

  ngOnInit(): void {
    this.renderer.setStyle(this.el.nativeElement, 'display', 'flex');
    if (this.number) {
      const [IntegerPart, fractionPart] = this.number.split('.');
      const integerElement = this.document.createElement('span');
      integerElement.innerText = IntegerPart;
      const dot = this.document.createElement('span');
      dot.innerText = '.';
      const fractionElement = this.document.createElement('span');
      fractionElement.innerText = fractionPart;
      this.renderer.setStyle(fractionElement, 'font-weight', 'normal');
      this.renderer.appendChild(this.el.nativeElement, integerElement);
      this.renderer.appendChild(this.el.nativeElement, dot);
      this.renderer.appendChild(this.el.nativeElement, fractionElement);
    }
  }
}
