import { CommonModule } from '@angular/common';
import { NgModule, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'replaceAvaHash',
})
export class ReplaceAvatarHashPipe implements PipeTransform {
  transform(value: string, url: string = 'https://avatars.steamstatic.com/'): string {
    if (value) {
      if (!(value.startsWith('http') || value.startsWith('//'))) {
        const normValue = `${value}_full.jpg`;
        return `${url}${normValue}`;
      }
    }
    return value;
  }
}

@NgModule({
  imports: [CommonModule],
  declarations: [ReplaceAvatarHashPipe],
  exports: [ReplaceAvatarHashPipe],
})
export class ReplaceAvatarHashPipeModule {}
