import { IconConfig } from '@dev-fast/types';

export const iconsPaymentProviders: IconConfig[] = [
  {
    name: 'g2a',
    path: 'assets/img/mat-icons/payment-providers/g2a.svg',
  },
  {
    name: 'skrill',
    path: 'assets/img/mat-icons/payment-providers/skrill.svg',
  },
];
