import { CasesGameType, ICaseType, IItemStatus, ProfileTypes, StateActionStatus, TicketTypeEnum } from '../../../enums';
import { IInventoryItemDetailed, IUserInventoryItemV2 } from '../../inventory';
import { ICaseLRInfo } from '../../leader-race.interface';
import { IMarketplaceKitData } from '../../merketplace';
import { IStatisticItem } from '../../motivator.interface';
import { IUserIdOnly, IUserShort } from '../../user';
import { ICaseQuestField, ICategoryQuestField, ITicketQuestPrice } from './cases-quest.interface';

export type ICaseCollectionType = number | 'all' | 'free';

export interface ICaseItemDetailedReq {
  shortName: string;
  skin: string;
}
export interface ICaseItemDetailed {
  item: IInventoryItemDetailed;
  icon: string; // case item image
  cases?: ICaseItemDtoV2[]; // our custom cases
  lots?: IMarketplaceKitData[]; // p2p lots with item
}
export interface ICaseItemDetailedPayload {
  shortName: string;
  skin: string;
  icon: string;
}
export interface ICaseLinesParams {
  tracksAmount: number;
  status: StateActionStatus;
}
export interface ICaseItemCard {
  //from ICaseItemDtoV2
  id: number;
  name: string;
  image?: string;
  itemImage?: string;
  caseImage?: string;
  historyImage?: string;
  backgroundImage?: string | null;
  enabled?: boolean;
  //from ICaseItemCard
  casePrice: number; // цена кейса, установленная в админке
  marketPrice?: number; // цена лота с таким кейсом в маркете
}
export interface ICaseItemDtoV2 extends ICaseLRInfo {
  id: number;
  createdAt: string;
  updatedAt: string;
  name: string;
  enabled: boolean;
  published: boolean;
  onlyBattles: boolean;
  isFavorite: boolean;
  image: string;
  itemImage: string;
  historyImage: string;
  backgroundImage: string | null;
  priority: number;
  type: ICaseType;
  regenerationIsDisabled: boolean;
  tags: string[];
  creatorId: number;
  categoryId: number;
  minAccountType: ProfileTypes;
  lastRevision: ICaseRevision;
  mainItem: IBaseItemDto;
  deletedAt: string;
  category?: ICaseCategory;
  free?: {
    count: number;
    caseId: number;
    nextUseAt: string;
  };
  tempId?: number;
  /** поле приходит только при запросе кейса по его id*/
  quest?: ICaseQuestField;
  ticketQuest?: ITicketQuestPrice;
}
export interface IBaseItemDto {
  color: string;
  exterior: string;
  icon: string;
  iconLarge?: string;
  name: string;
  phase?: string;
  rarity: string;
  shortName: string;
  skin: string;
  statTrak: boolean;
  steamPrice?: number;
  type: string;
  weapon: string;
}
export interface IBaseItemDtoV2 {
  name: string;
  icon: string;
  iconLarge?: string;
  color: string;
  weapon: string | null;
  type: string;
  rarity: string;
  shortName: string | null;
  skin: string | null;
  exterior: string | null;
  statTrak: boolean | null;
  isSouvenir?: boolean;
  steamPrice?: number;
  phase?: string | null;
}
export interface ICaseRevisionDto {
  id: number;
  revision?: number;
  prevPrice?: number;
  price?: number;
  results?: {
    initialized: boolean;
    dirty: boolean;
  };
}
export interface ICasePrizeItemDto {
  id: number;
  count: number;
  inventoryItem: {
    available: boolean;
    baseItem: IBaseItemDtoV2;
    price: number;
    id: number;
  };
}
export interface ICaseOpenPrizeDataDto {
  results: ICaseOpenPrizeItemDto[];
  strip: number[][];
}
export interface ICaseOpenPrizeItemDto {
  caseRevisionItem: ICasePrizeItemDto;
  caseRevisionPrice: number;
  rollId: number;
  winningPrice: number;
  userInventoryItemId: number;
  id: number;
  status?: IItemStatus;
}
export interface IItemFromHistory {
  id: number;
  case: ICaseItemDtoV2;
  caseRevisionItem: ICasePrizeItemDto;
  caseRevisionPrice: number;
  winningPrice: number;
  user: IUserIdOnly;
  rollId: number;
  openUUID: string;
  profit: number;
  createdAt: string;
  status: IItemStatus;
  userInventoryItem: IUserInventoryItemV2;
}
export interface IHistoryOfOpenCases {
  items: IItemFromHistory[];
  meta: {
    amount: number;
    limit: number;
    offset: number;
  };
}

export interface ICaseGameForm {
  columns: number;
  rows: number;
  raffling: boolean;
}
export interface ICaseBestDrops extends ICaseOpenPrizeItemDto {
  user: IUserShort;
}
export interface ICaseCategory {
  id: number;
  image: string;
  name: string;
}
export interface ICasePrizeItem extends ICasePrizeItemDto {
  type?: string;
  status?: string;
  valuable?: boolean;
  styles?: string[];
  holder?: boolean;
  image?: string;
  itemImage?: string;
  userInventoryItemId: number;
}
export interface ICaseContainedItem {
  baseItem: IBaseItemDto;
  exteriors: ICaseContainedItemExterior[];
  steamPrice: number;
}
export interface ICaseContainedItemExterior {
  exterior: string;
  steamPrice: number;
  odds: number;
  statTrak: boolean;
}
export interface ICaseRevisionInfo {
  maxPage: number;
  page: number;
  revisionIds: number[];
  revision: ICaseRevision;
}
export interface ICaseRevision {
  id: number;
  case: ICaseItemDtoV2;
  revision: number;
  price: number;
  prevPrice: number;
  items: ICaseRevisionItem[];
  createdAt: Date;
  updatedAt: Date;
}
export interface IPossibleItem {
  string: ICaseRevisionItem[];
}
export interface ICaseRevisionItem {
  id: number;
  count: number;
  inventoryItem: {
    baseItem: IBaseItemDto;
    price: number;
    available: boolean;
    id: number;
  };
  rangeFrom?: number;
  rangeTo?: number;
  revisionWear?: string;
  dropProbability?: string;
}
export interface ICaseSpinItem extends ICasePrizeItemDto {
  status?: IItemStatus;
  isProfit?: boolean;
  trackIndex: number;
  userInventoryItemId?: number;
}
export interface IUserDropStat {
  [key: string]: {
    count: number;
    bestDrop: IStatisticItem[];
  };
}
export interface ICasesSettings {
  showMoney: boolean;
}
export interface ICaseCollection {
  id: number;
  name: string;
  cases: ICaseItemDtoV2[];
  image: string | null;
  grid: string;
  disableAt: string | null;
  sortable: boolean;
  tags: string[] | null;
  /** приходит только при запросе всех категорий */
  quest?: ICategoryQuestField;
}
export interface ICaseEventCollection extends ICaseCollection {
  createdAt: string | null;
  updatedAt: string | null;
  priority: number;
  isEvent: boolean;
  deletedAt: string | null;
  cases: ICaseItemDtoV2[];
}
export interface ICategoryLink {
  path: string | number;
  title: string;
  id: null | number;
  tags: string[] | null;
  newCases: boolean;
}
export interface IAutoPlayMenuButton {
  amount: number;
  totalPrice: number;
  enabledBtn: boolean;
  titleP_1: string;
  titleP_2: string;
  isUnlimited: boolean;
}

export interface ICaseOpenAutoParams {
  amount: number;
  isUnlimited: boolean;
}
export interface ICaseOpenParams {
  caseId: number;
  count: number;
  countOfAutoplayCases?: string | number;
  gameType: CasesGameType;
  isFree?: boolean;
  ticketColor?: TicketTypeEnum;
}
