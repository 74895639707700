import { IMarketplaceItem, IMarketplaceKitData } from '../entities';
import { AgentKeys, GameTypes } from '../enums';

export interface ILobbyGame {
  key: string;
  count: number;
}

export interface IGameDetail {
  background: string;
  hover: string;
  label: string;
  icon: string;
  agentKey: AgentKeys;
  gameType: GameTypes;
}

export interface IWinning {
  key: string;
  user: string;
  avatar: string;
  amount: number;
  date: Date;
}

export interface IPayout {
  name: string;
  points: number;
  date: Date;
}

export interface IOrder {
  id: number;
  kit?: IMarketplaceKitData;
  item?: IMarketplaceItem;
}

export interface INews {
  preview: string;
  icon: string;
  label: string;
  description: string;
}

export interface IReward {
  label: string;
  description: string;
  icon: string;
}
