import { IActivePayment, ICountry, IPaymentParams, PaymentExchangeCurrency } from '@dev-fast/types';

export class RefillWithCode {
  static readonly type = '[Refill] Refill with giftcode';
  constructor(public code: string) {}
}
export class ChangePaymentsParams {
  static readonly type = '[Refill] Change Payments Params';
  constructor(public params: IPaymentParams | null) {}
}
export class CreateNewWallet {
  static readonly type = '[Refill] Create new wallet';
  constructor(
    public userId: number,
    public walletName: PaymentExchangeCurrency,
  ) {}
}
export class GetPaymentMethodsNew {
  static readonly type = '[Refill] Get payment methods new';
  constructor(
    public country: ICountry,
    public userId: number,
  ) {}
}
export class GetCryptoWallets {
  static readonly type = '[Refill] Get wallets';
  constructor(
    public userId: number,
    public requestCountryCryptoWallets?: boolean,
    public requestUserCryptoWallets?: boolean,
  ) {}
}
export class LeftRefill {
  static readonly type: string = '[Refill] Left refill';
}
export class Refill {
  static readonly type = '[Refill] Create invoice';
}
export class SelectCountry {
  static readonly type: string = '[Refill] Country select';
  constructor(public country: ICountry) {}
}
export class SelectPayment {
  static readonly type: string = '[Refill] Select payment';
  constructor(public activePayment: IActivePayment | null) {}
}
export class SetWalletTabIndex {
  static readonly type = '[Refill] Set wallet tab index';
  constructor(public index: number) {}
}
export class TogglePaymentTypeSize {
  static readonly type = '[Refill] Toggle payment type size';
  constructor(
    public isExpanded: boolean,
    public typeIndex: number,
  ) {}
}
