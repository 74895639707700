import { QuickFaqName } from '../enums';

export interface IFaq {
  url: string;
  title: string;
  position?: number;
  faq_items: IFaqArticleItems[];
}
export interface IFaqActiveMenu {
  menuIndex?: number;
  subMenuIndex?: number;
}
export interface FaqStateItem {
  lang: string;
  value: IFaq[];
}

export interface IFaqArticleItems {
  item_id: number;
  item_title: string;
  position: number;
  item_text: string;
  identifier?: string;
}

export interface ISeoFaq {
  title: string;
  text: string;
  descriptionLink?: string;
  link?: string;
}

export type IFaqStorageDictionary = Partial<Record<QuickFaqName, boolean>>;
