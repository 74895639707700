import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

import { ChatIntegration, ChatraSetup } from './chat.interface';

declare global {
  interface Window {
    Chatra?: any;
    ChatraID: string;
    ChatraSetup: ChatraSetup;
    ChatIntegration: ChatIntegration;
    BrevoConversations?: any;
    BrevoConversationsSetup?: any;
  }
}
@Injectable({
  providedIn: 'root',
})
export class ChatService {
  private readonly window: Window | null;

  constructor(@Inject(DOCUMENT) private readonly document: Document) {
    this.window = this.document.defaultView;
  }

  public setIntegrationData = (info: ChatIntegration): void => {
    if (!this.window) return;

    if (this.window.Chatra) {
      this.window.Chatra('setIntegrationData', info);
    }
    if (this.window.BrevoConversations) {
      this.window.BrevoConversations('updateIntegrationData', info);
    }

    // if (info.USER_ID) {
    //   this.chatraSetup({ clientId: info.USER_ID });
    // }
  };

  // public chatraSetup = (conf: ChatraSetup): void => {
  //   // не получилось пока
  //   if (!this.window) return;
  //   this.window.ChatraSetup = conf;
  //   this.window.Chatra('restart');
  // };

  public openChat = (): void => {
    if (!this.window) return;

    if (this.window.BrevoConversations) {
      this.window.BrevoConversations('openChat', true);
    } else if (this.window.Chatra) {
      this.window.Chatra('openChat', true);
    }
  };

  public hasChat = (): boolean => {
    if (!this.window) return false;

    return !!this.window.BrevoConversations || !!this.window.Chatra;
  };
}
