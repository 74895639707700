<div class="tw-flex tw-relative tw-items-center tw-justify-center">
  <div>
    <button
      (click)="toggleDropdown()"
      class="dropdown-button tw-w-14 tw-cursor-pointer tw-flex tw-justify-between tw-items-center tw-outline-none tw-border-0 tw-bg-transparent tw-gap-1 tw-p-0"
    >
      <img
        [src]="'//d2lomvz2jrw9ac.cloudfront.net/country-flags/' + lang() + '.png'"
        lazyLoadImage
        class="tw-w-8 tw-h-6 tw-object-cover"
        alt
      />
      <span
        [class.tw-rotate-0]="opened()"
        class="flex tw-text-white tw-transition-transform -tw-rotate-180 tw-ease-in tw-duration-300"
      >
        <mat-icon svgIcon="arrow-up"></mat-icon>
      </span>
    </button>

    <ng-container *ngIf="opened()">
      <div class="tw-bg-black tw-absolute tw-z-50 -tw-left-2">
        <ul class="tw-flex tw-space-y-2 tw-flex-col tw-p-2">
          <ng-container *ngFor="let item of items">
            <li
              *ngIf="item.path !== selected.path"
              class="tw-w-8 tw-cursor-pointer"
            >
              <div (click)="select(item)">
                <img
                  class="tw-w-full"
                  [src]="'//d2lomvz2jrw9ac.cloudfront.net/country-flags/' + item.path.slice(3).toLowerCase() + '.png'"
                  lazyLoadImage
                  alt
                />
              </div>
            </li>
          </ng-container>
        </ul>
      </div>
    </ng-container>
  </div>
</div>
