import { Howl } from 'howler';

import { IAudio } from '../sound.interface';

export class AudioController {
  audio: Howl;
  group: string;
  name: string;
  shortName: string;
  description: string;
  enabled: boolean | undefined;
  type: string;
  phase: string;
  forBtnsList?: boolean;

  constructor(options: IAudio) {
    this.audio = new Howl({
      src: [options.link],
      loop: options?.loop || false,
      format: options.format || 'mp3',
    });
    this.group = options.group;
    this.name = options.name;
    this.shortName = options.shortName;
    this.type = options.type || '';
    this.phase = options.phase || '';
    this.description = options.description;
    this.enabled = options.enabled;
    this.forBtnsList = options.forBtnsList;
  }

  play(tested = false): void {
    if (!tested && !this.enabled) {
      return;
    }
    this.audio.play();
  }

  pause(): void {
    this.audio.pause();
  }

  stop(): void {
    this.audio.stop();
  }

  load(): void {
    this.audio.load();
  }

  seek(position: number): void {
    this.audio.seek(position, undefined);
  }

  volume(value: number): void {
    this.audio.volume(value);
  }

  toggleAudio(): void {
    if (!this.audio.playing()) {
      this.audio.play();
    } else {
      this.audio.pause();
    }
  }

  mute(enabled: boolean): void {
    this.enabled = enabled;
  }
}
