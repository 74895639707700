import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CasesBackendService, MotivatorBackendService } from '@dev-fast/backend-services';
import {
  AgentKeys,
  AnimationSpeed,
  IActivities,
  IDropItemDto,
  IMotivatorDto,
  IMotivatorStatistic,
  MotivatorReqKeys,
  NotificationStatus,
  NotificationType,
  PROJECT,
  Widgets,
} from '@dev-fast/types';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { compose, insertItem, patch, removeItem, StateOperator } from '@ngxs/store/operators';
import { EMPTY, Observable } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';

import { EnvironmentService } from '@app/core/environment-service';
import { NotificationsService } from '@app/core/notification-service';
import { SetCurrentGameActivities } from '@app/core/state/games-store';

import { MIN_MOTIVATOR_ITEMS, MIN_PROFIT_OVERPRICE, OPENING_ANIM_DURATION } from '../../shared/constants';
import { CasesSettingsState } from '../cases';
import {
  AddLastWon,
  ClearCasesMotivator,
  GetCurrentCaseMotivator,
  GetStat,
  ToggleDropList,
  UpdateCurrentCaseMotivator,
} from './motivators.actions';
import { CurrentCaseMotivatorType, MOTIVATORS_INITIAL_STATE, MotivatorStateModel } from './motivators.model';

@State<MotivatorStateModel>({
  name: 'motivators',
  defaults: MOTIVATORS_INITIAL_STATE,
})
@Injectable()
export class MotivatorsState {
  constructor(
    private readonly store: Store,
    private readonly motivatorApiService: MotivatorBackendService,
    private readonly environmentService: EnvironmentService,
    private readonly casesApiService: CasesBackendService,
    private readonly notificationsService: NotificationsService,
  ) {}

  @Selector()
  static lastDropsList({ lastDropsList }: MotivatorStateModel): IDropItemDto[] {
    return lastDropsList;
  }
  @Selector()
  static bestDropsList({ bestDropsList }: MotivatorStateModel): IDropItemDto[] {
    return bestDropsList;
  }
  @Selector()
  static currentCaseMotivator({ currentCaseMotivator }: MotivatorStateModel): IMotivatorStatistic {
    return currentCaseMotivator;
  }

  ngxsOnInit({ dispatch }: StateContext<MotivatorStateModel>): void {
    // FIXME отписаться если не нужно
    this.motivatorApiService.onLastWon((motivatorDto: IMotivatorDto<IDropItemDto>) => {
      if (motivatorDto.agentKey !== AgentKeys.CASES_GAME) {
        return;
      }
      dispatch(new AddLastWon(motivatorDto));
    });
  }
  @Action(AddLastWon)
  addLastWon({ setState, getState, dispatch }: StateContext<MotivatorStateModel>, { motivatorDto }: AddLastWon): void {
    const { isPlayback, bestDropsList, lastDropsList, caseId } = getState();
    const isBest = motivatorDto.data.caseRevisionPrice + MIN_PROFIT_OVERPRICE < motivatorDto.data.winningPrice;
    if (isPlayback) {
      setState(
        patch({
          lastDropsList: compose(insertItem(motivatorDto.data, 0), removeItem(lastDropsList.length)),
          bestDropsList: isBest ? compose(insertItem(motivatorDto.data, 0), removeItem(bestDropsList.length)) : bestDropsList,
        }),
      );
      if (caseId && caseId === motivatorDto.data.case.id) {
        dispatch(new UpdateCurrentCaseMotivator(motivatorDto.data));
      }
    }
  }
  @Action(ClearCasesMotivator)
  clearCasesMotivator({ patchState }: StateContext<MotivatorStateModel>): void {
    patchState({
      caseId: null,
      currentCaseMotivator: MOTIVATORS_INITIAL_STATE.currentCaseMotivator,
    });
  }
  @Action(GetStat)
  getStat({ patchState, dispatch }: StateContext<MotivatorStateModel>): Observable<void> {
    //mb pass it as parameter
    const motivatorsReqKeys = [MotivatorReqKeys.bigOpens, MotivatorReqKeys.bestDrop, MotivatorReqKeys.bestLast, MotivatorReqKeys.last];
    return this.casesApiService
      .getStat({
        keys: motivatorsReqKeys.join(','),
        limit: MIN_MOTIVATOR_ITEMS,
      })
      .pipe(
        tap((response: IMotivatorStatistic) => {
          patchState({
            lastDropsList: response.last?.map((el) => el.data),
            bestDropsList: response.bestLast?.map((el) => el.data),
          });
        }),
        switchMap((response) => {
          let payload: IActivities = {};
          [Widgets.PlayerOfTheDay, Widgets.LuckOfTheDay].forEach((widget: Widgets) => {
            const stat = this.statsToWidget(widget, response);
            if (stat) {
              payload = Object.assign(payload, stat);
            }
            return stat;
          });
          return payload ? dispatch([new SetCurrentGameActivities(payload)]) : EMPTY;
        }),
      );
  }
  /**
   * Получение мотиватора текущего кейса
   */
  @Action(GetCurrentCaseMotivator)
  getCurrentCaseMotivator({ patchState }: StateContext<MotivatorStateModel>, { caseId }: GetCurrentCaseMotivator): Observable<any> {
    return this.motivatorApiService.getStat({ keys: 'last,bestLast', caseId }).pipe(
      tap((data) => {
        patchState({
          currentCaseMotivator: data as CurrentCaseMotivatorType,
          caseId,
        });
      }),
      catchError((error: HttpErrorResponse) => {
        patchState({
          currentCaseMotivator: undefined,
        });
        this.onError(error);
        throw new Error(error.message);
      }),
    );
  }

  @Action(UpdateCurrentCaseMotivator)
  updateCurrentCaseMotivator({ setState, getState }: StateContext<MotivatorStateModel>, { statistic }: UpdateCurrentCaseMotivator): void {
    const animationSpeed: AnimationSpeed = this.store.selectSnapshot(CasesSettingsState).animationSpeed;
    const updateDelay = OPENING_ANIM_DURATION[this.environmentService.getEnvironment().PROJECT as PROJECT]?.[animationSpeed];
    const { currentCaseMotivator } = getState();
    // навсякий случай оставил для защиты
    const last = currentCaseMotivator.last || [];
    const bestLast = currentCaseMotivator.bestLast || [];

    const isBest = statistic.caseRevisionPrice + MIN_PROFIT_OVERPRICE < statistic.winningPrice;
    const motivatorDto: IMotivatorDto<IDropItemDto> = { data: statistic, agentKey: AgentKeys.CASES_GAME };

    const composeLast: StateOperator<IMotivatorDto<IDropItemDto>[]>[] = [insertItem(motivatorDto, 0)];
    const composeBest: StateOperator<IMotivatorDto<IDropItemDto>[]>[] = [];
    if (isBest) {
      composeBest.push(insertItem(motivatorDto, 0));
      if (bestLast.length > MIN_MOTIVATOR_ITEMS) {
        composeBest.push(removeItem(bestLast.length));
      }
    }
    if (last.length < MIN_MOTIVATOR_ITEMS) {
      composeLast.push(removeItem(last.length));
    }
    setTimeout(() => {
      setState(
        patch({
          currentCaseMotivator: patch({
            last: compose(...composeLast),
            bestLast: compose(...composeBest),
          }),
        }),
      );
    }, updateDelay);
  }

  @Action(ToggleDropList)
  toggleDropList({ patchState }: StateContext<MotivatorStateModel>, { isPlayback }: ToggleDropList): void {
    patchState({
      isPlayback: isPlayback,
    });
  }
  // TODO rework it
  private statsToWidget(widget: Widgets, response: IMotivatorStatistic): any {
    if (!response) {
      return undefined;
    }
    switch (widget) {
      case 'playerOfTheDay': {
        if (response.bigOpens) {
          const { user, count } = response.bigOpens;
          return user && count
            ? {
                playerOfTheDay: {
                  header: 'LOCAL.CASES.MOTIVATOR.TOP_PLAYER',
                  avaLink: user.avatar,
                  id: user.id,
                  name: user.name,
                  payload: [
                    {
                      label: 'LOCAL.CASES.MOTIVATOR.OPENED',
                      value: count,
                      valueType: 'number',
                    },
                  ],
                },
              }
            : undefined;
        }
        return undefined;
      }
      case 'luckOfTheDay': {
        if (response.bestDrop) {
          const { user, caseRevisionItem, winningPrice, caseRevisionPrice } = response.bestDrop;
          return user && caseRevisionItem
            ? {
                luckOfTheDay: {
                  header: 'RECENT_ACTIVITIES_WIDGET.LUCK_OF_THE_DAY',
                  avaLink: user.avatar,
                  id: user.id,
                  name: user.name,
                  height: '24.5rem',
                  replayDrop: {
                    id: user.id,
                    case: response.bestDrop.case,
                    caseRevisionItem: caseRevisionItem,
                    caseRevisionPrice: caseRevisionPrice,
                    winningPrice: winningPrice,
                    user: user,
                    rollId: response.bestDrop.rollId,
                    createdAt: response.bestDrop.createdAt,
                    profit: response.bestDrop.profit,
                    openUUID: response.bestDrop.openUUID,
                  },
                  payload: [
                    // {
                    //   label: 'WIN',
                    //   value: winningPrice,
                    //   valueType: 'currency',
                    // },
                    {
                      label: 'LOCAL.CASES.BATTLE_GAME.BATTLE.WINNER',
                      value: winningPrice,
                      valueType: 'user',
                    },
                    {
                      label: 'CHANCE',
                      value: +(caseRevisionItem.count / 1000).toFixed(3),
                      valueType: 'percent',
                    },
                  ],
                },
              }
            : undefined;
        }
        return undefined;
      }
      default:
        return undefined;
    }
  }

  private onError(error: HttpErrorResponse): Observable<never> {
    this.notificationsService.addNotification({
      id: Date.now(),
      type: error.error && error.error.type ? error.error.type : NotificationType.Error,
      icon: 'warning',
      message: error.error && error.error.message ? error.error.message : typeof error.error === 'string' ? error.error : 'Error',
      createDate: Date.now(),
      system: true,
      status: NotificationStatus.new,
    });
    return EMPTY;
  }
}
