import { Component, inject } from '@angular/core';
import { IP2pPurchaseItem } from '@dev-fast/types';
import { Observable } from 'rxjs';

import { P2pBuyingService } from '@app/modules/p2p';

@Component({
  selector: 'app-p2p-purchase',
  templateUrl: './p2p-purchase.component.html',
  styleUrls: ['./p2p-purchase.component.scss'],
})
export class P2pPurchaseComponent {
  #p2pBuyingService = inject(P2pBuyingService);

  readonly purchasing$: Observable<IP2pPurchaseItem[]> = this.#p2pBuyingService.purchasing$;
  readonly showWarn$: Observable<boolean> = this.#p2pBuyingService.showWarn$;

  onChangeNotifiedStatus(value: boolean): void {
    this.#p2pBuyingService.updateShowWarnValue(value);
  }

  trackingFunction(index: number, item: IP2pPurchaseItem): number {
    return item.id;
  }
}
