import { inject, Injectable } from '@angular/core';
import {
  IMarketplaceKitData,
  IP2pDepositingItem,
  IP2pDepositingItemV2,
  IP2pDepositItem,
  ISteamInventoryAutoSelect,
  ISteamStoreInventory,
  IUserSettingsDetailed,
  MarketAction,
  ModalNames,
  NewPanel,
} from '@dev-fast/types';
import { Select, Store } from '@ngxs/store';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { Observable } from 'rxjs';

import { ClosePanel } from '@app/core/state/layout';
import { OpenModal } from '@app/core/state/modals';
import {
  ChangeSelectedItem,
  ChangeSelectedItems,
  Deposit,
  DepositSelected,
  GetDepositingAutoSelection,
  P2pCommonState,
  P2pDepositState,
  PauseDepositing,
  RemoveDepositItem,
  RemoveMarketItems,
  ReqAppointing,
  ResetSelected,
  ResumeDepositing,
  SellNow,
  StopDepositing,
  ToggleDepositItem,
  UpdateOverprice,
} from '@app/core/state/p2p';
import { ChangeAdditionalSettings, UserState } from '@app/core/state/user-store';

@Injectable()
export class P2pDepositService {
  @Select(UserState.additionalSettings)
  readonly additionalSettings$!: Observable<IUserSettingsDetailed>;

  @Select(P2pDepositState.depositingItems)
  depositing$!: Observable<IP2pDepositingItem[]>;

  @Select(P2pDepositState.isItemsOnPause)
  isItemsOnPause$!: Observable<boolean>;

  @Select(P2pDepositState.overprice)
  overprice$!: Observable<number>;

  @Select(P2pDepositState.selected)
  selected$!: Observable<IP2pDepositItem[]>;

  @Select(P2pDepositState.selectedSum)
  selectedSum$!: Observable<number>;

  @Select(P2pDepositState.selectedSumWithOverprice)
  selectedSumWithOverprice$!: Observable<number>;

  @Select(P2pCommonState.steamInventory)
  steamInventoryItems$!: Observable<ISteamStoreInventory[]>;
  //inject
  readonly #store = inject(Store);

  getDepositById(id: number): IP2pDepositItem | undefined {
    return this.#store.selectSnapshot(P2pDepositState.selected).find((s) => s.steamInventoryId === id);
  }

  requestConfirm(id: number): void {
    this.reqAppointing(id, MarketAction.Confirm);
  }

  requestDecline(id: number): void {
    this.reqAppointing(id, MarketAction.Decline);
  }

  @Dispatch() removeDepositItem = (payload: IP2pDepositItem): RemoveDepositItem => new RemoveDepositItem(payload);
  @Dispatch() toggleDepositItem = (payload: IP2pDepositItem): ToggleDepositItem => new ToggleDepositItem(payload);
  @Dispatch() depositSelected = (): DepositSelected => new DepositSelected();
  @Dispatch() resetSelected = (): ResetSelected => new ResetSelected();
  @Dispatch() updateSelected = (item: IP2pDepositItem[]): ChangeSelectedItems => new ChangeSelectedItems(item);
  @Dispatch() updateOverprice = (overpirce: number): UpdateOverprice => new UpdateOverprice(overpirce);
  @Dispatch() reqAppointing = (id: number, action: MarketAction): ReqAppointing => new ReqAppointing(id, action);
  @Dispatch() sellNow = (id: number): SellNow => new SellNow(id);
  @Dispatch() deleted = (ids: number[]): RemoveMarketItems => new RemoveMarketItems(ids);
  @Dispatch() stop = (): StopDepositing => new StopDepositing();
  @Dispatch() pause = (id?: number): PauseDepositing => new PauseDepositing(id);
  @Dispatch() resume = (id?: number): ResumeDepositing => new ResumeDepositing(id);
  @Dispatch() deposit = (id: number, price: number): Deposit => new Deposit(id, price);
  @Dispatch() setDepositById = (item: IP2pDepositItem): ChangeSelectedItem => new ChangeSelectedItem(item);
  @Dispatch() showAuctionCompletedModal = (item: IP2pDepositingItemV2): OpenModal => new OpenModal(ModalNames.P2P_AUCTION_COMPLETED, item);
  @Dispatch() showBatchDetailsModal = (item: IMarketplaceKitData): OpenModal => new OpenModal(ModalNames.P2P_KIT_DETAILS, item);
  @Dispatch() getDepositAutoSelection = (params: ISteamInventoryAutoSelect): GetDepositingAutoSelection =>
    new GetDepositingAutoSelection(params);
  @Dispatch() changeAdditionalSettings = (payload: IUserSettingsDetailed): ChangeAdditionalSettings =>
    new ChangeAdditionalSettings(payload);
  @Dispatch() closeMarketPanel = (): ClosePanel => new ClosePanel(NewPanel.TRADES);
}
