import { IconConfig } from '@dev-fast/types';

export const iconsEvents: IconConfig[] = [
  {
    name: 'pumpkin',
    path: 'assets/img/events/pumpkin.svg',
  },
  {
    name: 'pumpkin-yellow',
    path: 'assets/img/events/pumpkin-yellow.svg',
  },
];
