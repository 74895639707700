import { inject, Injectable } from '@angular/core';
import { CasesSocketName, ICaseItemDtoV2, ICaseLuckyWonEvent, ISocketMessage } from '@dev-fast/types';
import { Socket as WrappedSocket } from 'ngx-socket-io';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CasesSocketService {
  readonly #wrappedSocket = inject(WrappedSocket);

  // Cases
  onCaseAdd(callback: (message: ICaseItemDtoV2) => void): void {
    this.#wrappedSocket.on('game.cases.case.new', callback);
  }
  onCaseRemove(callback: (message: { id: number }) => void): void {
    this.#wrappedSocket.on('game.cases.case.delete', callback);
  }
  onRevisionUpdate(): Observable<ISocketMessage<CasesSocketName, { caseId: number }>> {
    return this.#wrappedSocket.fromEvent<{ caseId: number }>('game.cases.revision.new').pipe(
      map((value) => ({
        payload: value,
        name: CasesSocketName.UPDATE,
      })),
    );
  }
  // onRevisionUpdate(callback: (message: any) => void): void {
  //   this.#wrappedSocket.on('game.cases.case.updated', callback);
  // }

  // Categories
  onCategoryUpdate(callback: (message: ICaseLuckyWonEvent) => void): void {
    this.#wrappedSocket.on('cases-quest:lucky-won', callback);
  }
}
