<ng-container
  *ngIf="{
    applyActionStatus: applyActionStatus$ | async,
    promocodeReward: promocodeReward$ | async
  } as data"
>
  <div
    class="promo-wrapper"
    [formGroup]="promocodeForm"
    [class]="data.applyActionStatus"
  >
    <div class="tw-text-base tw-font-semibold tw-text-gray-100">
      <ng-container *ngIf="data.applyActionStatus !== actionStatusEnum.ERROR; else errorTmpl">
        {{ headerText | translate }}
      </ng-container>
      <ng-template #errorTmpl>
        {{ 'LOCAL.PROMOCODE_WIDGET.PROMOCODE_CHECK' | translate }}
      </ng-template>
    </div>
    <div class="promo-input">
      <app-ui-input
        [prefixIcon]="showIcon(data.applyActionStatus)"
        formControlName="code"
        [canPaste]="true"
        [placeholder]="'LOCAL.PROMOCODE_WIDGET.INPUT_PLACEHOLDER' | translate"
      >
        <div [ngSwitch]="data.applyActionStatus">
          <div *ngSwitchCase="actionStatusEnum.ERROR">
            <button
              class="btn promo--activate-button close"
              (click)="resetPromocode()"
            >
              <mat-icon svgIcon="x--promo"></mat-icon>
            </button>
          </div>
          <div *ngSwitchCase="actionStatusEnum.SUCCESS">
            <button
              class="btn promo--activate-button arrow"
              (click)="activatePromocode()"
            >
              <mat-icon svgIcon="arrow-right"></mat-icon>
            </button>
          </div>
          <div *ngSwitchCase="actionStatusEnum.DISPATCH">
            <button class="btn promo--activate-button">
              <app-ui-spinner-loader></app-ui-spinner-loader>
            </button>
          </div>
          <div *ngSwitchDefault>
            <button
              class="btn promo--activate-button arrow"
              (click)="activatePromocode()"
            >
              <mat-icon svgIcon="arrow-right"></mat-icon>
            </button>
          </div>
        </div>
      </app-ui-input>
    </div>
    <div
      class="promo-description"
      *ngIf="data.applyActionStatus"
    >
      <div
        class="description-default"
        *ngIf="data.applyActionStatus === actionStatusEnum.DEFAULT"
      >
        <ng-container *ngIf="!isBonusCodeEntered()">
          {{ 'PROMOCODE_WIDGET.DEFAULT_CODE_MESSAGE' | translate }}
          <a
            (click)="useDefPromo()"
            class="default-code"
            >{{ defaultPromocode }}</a
          >
        </ng-container>
      </div>
      <div
        class="description-bonus"
        *ngIf="data.applyActionStatus === actionStatusEnum.SUCCESS"
      >
        <ng-container *ngIf="data.promocodeReward as reward">
          {{ 'LOCAL.PROMOCODE_WIDGET.PROMOCODE_BONUS' | translate }}
          <ng-container *ngIf="reward.bonusPercent">
            {{ reward.bonusPercent }}
          </ng-container>
          <ng-container *ngIf="reward.bonusCoins"> + <app-currency [value]="reward.bonusCoins"></app-currency></ng-container>
          <ng-container *ngIf="reward.bonusCases">
            (+ {{ reward.bonusCases }} {{ 'LOCAL.PROMOCODE_WIDGET.PROMOCODE_BONUS_CASE' | translate }})
          </ng-container>
        </ng-container>
      </div>
      <div
        class="description-error"
        *ngIf="data.applyActionStatus === actionStatusEnum.ERROR"
      >
        {{ errorMessageConstructor(errorMessage) | translate }}
      </div>
    </div>
    <div
      class="refill-btn"
      *ngIf="data.applyActionStatus === actionStatusEnum.SUCCESS"
    >
      <button
        class="btn btn-inversion-yellow--design"
        (click)="onRefill()"
      >
        {{ 'LOCAL.PROMOCODE_MODAL.REFILL_BTN' | translate }}
      </button>
    </div>
  </div>
</ng-container>
