import { AudioController } from './audio-controller/audio-controller.class';

export interface IAudio {
  name: string;
  shortName: string;
  group: string;
  link: string;
  description: string;
  type?: string;
  enabled?: boolean;
  loop?: boolean;
  format?: string;
  phase?: string;
  forBtnsList?: boolean;
}

export interface ISoundDictionary {
  // [group: string]: Map<string, AudioController | Howl>;
  [group: string]: Map<string, AudioController>;
}
