import { animate, group, query, style, transition, trigger } from '@angular/animations';

export const skinItemTooltipAnimation = trigger('skinItemTooltipAnimation', [
  transition('void => *', [
    group([
      query('.skin-item-container', [
        style({ opacity: 0, transform: 'scale(0.4) translate(0, 0)' }),
        animate('150ms 50ms ease-in-out'),
      ]),
      query('.skin-item-popup-close', [style({ opacity: 0 }), animate('200ms 50ms ease-in-out')]),
    ]),
  ]),
]);
