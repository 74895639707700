import { IStickerPack } from '../entities';

export const stickers: IStickerPack[] = [
  {
    name: 'sad pepe',
    path: '//d2lomvz2jrw9ac.cloudfront.net/common/stickers/SadPepe',
    size: 20,
  },
  {
    name: 'meme',
    path: '//d2lomvz2jrw9ac.cloudfront.net/common/stickers/meme_stickers',
    size: 98,
  },
  {
    name: 'south park',
    path: '//d2lomvz2jrw9ac.cloudfront.net/common/stickers/southpark',
    size: 90,
  },
  {
    name: 'rageface',
    path: '//d2lomvz2jrw9ac.cloudfront.net/common/stickers/ragefaces',
    size: 91,
  },
];
