import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { AutoHeightNgxScrollDirective } from './auto-height-ngx-scroll.directive';

@NgModule({
  declarations: [AutoHeightNgxScrollDirective],
  imports: [CommonModule],
  exports: [AutoHeightNgxScrollDirective],
})
export class AutoHeightNgxScrollModule {}
