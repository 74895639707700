import { IP2pDepositingItem, IP2pDepositItem, ISteamInventoryAutoSelect, ISteamStoreInventory, MarketAction } from '@dev-fast/types';

export class ResetSelected {
  static readonly type = '[p2p_deposit] Reset Selected';
}
export class ChangeSelectedItem {
  static readonly type = '[p2p_deposit] Change Selected Item';
  constructor(public payload: IP2pDepositItem) {}
}
export class ChangeSelectedItems {
  static readonly type = '[p2p_deposit] Change Selected Items';
  constructor(public payload: IP2pDepositItem[]) {}
}
export class UpdateOverprice {
  static readonly type = '[p2p_deposit] Update overprice';
  constructor(public overprice: number) {}
}
export class StopDepositing {
  static readonly type = '[p2p_deposit] Stop Depositing';
  // constructor(public payload: IP2pDepositItem) {}
}
export class PauseDepositing {
  static readonly type = '[p2p_deposit] Pause Depositing';
  constructor(public id?: number) {}
}
export class ResumeDepositing {
  static readonly type = '[p2p_deposit] Resume Depositing';
  constructor(public id?: number) {}
}
export class ToggleDepositItem {
  static readonly type = '[p2p_deposit] Toggle Deposit Item';
  constructor(public payload: ISteamStoreInventory) {}
}
export class RemoveDepositItem {
  static readonly type = '[p2p_deposit] Remove Deposit Item';
  constructor(public payload: IP2pDepositItem) {}
}
export class ReqAppointing {
  static readonly type = '[p2p_deposit] Req Appointing';
  constructor(
    public id: number,
    public action: MarketAction,
  ) {}
}
export class GetDepositing {
  static readonly type = '[p2p_deposit] Get Depositing';
}
export class GetDepositingAutoSelection {
  static readonly type = '[p2p_deposit] Get Depositing Auto Selection';
  constructor(public params: ISteamInventoryAutoSelect) {}
}
export class RemoveMarketItems {
  static readonly type = '[p2p_deposit] Remove Market Items';
  constructor(public ids: number[]) {}
}
export class SellNow {
  static readonly type = '[p2p_deposit] Sell Now';
  constructor(public id: number) {}
}
export class DepositSelected {
  static readonly type = '[p2p_deposit] Deposit Selected';

  constructor(public staticOverprice?: boolean) {}
}
export class Deposit {
  static readonly type = '[p2p_deposit] Deposit';
  constructor(
    public id: number,
    public price: number,
  ) {}
}
export class ParticipantItemUpdatedEvent {
  static readonly type = '[p2p_deposit] Participant Item Updated Event';
  constructor(public payload: IP2pDepositingItem) {}
}
export class GetDepositModifier {
  static readonly type = '[p2p_deposit] Get Deposit Modifier';
}
export class SetDepositModifier {
  static readonly type = '[p2p_deposit] Set Deposit Modifier';
  constructor(public payload: { agree: boolean }) {}
}
export class AutoShowP2PAuctionCompletedModal {
  static readonly type = '[p2p_deposit] AutoShow P2P Auction Completed Modal';
  // constructor(public payload?: IP2pDepositingItem) {}
}
export class GetDepositModifierSuccess {
  static readonly type = '[p2p_deposit] Get Deposit Modifier Success';
  constructor(
    public payload:
      | {
          agree: boolean;
        }
      | undefined,
  ) {}
}

export class SetSuccessInventorySellItem {
  static readonly type = '[p2p] Success finish inventory sell Item';
  constructor(public ids: number[]) {}
}

export class SetErrorInventorySellItem {
  static readonly type = '[p2p] Error finish inventory sell Item';
  constructor(public ids: number[]) {}
}
