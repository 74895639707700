// TODO unknown @anubchik interface never used, never seen
import { GameIds } from '../enums';
import { IBaseItemDtoV2 } from './games';
import { IP2pDepositingItem, IP2pDepositItem } from './p2p';
import { ISticker } from './skin-sticker';

type GameIDKeys = keyof typeof GameIds;
export interface ISteamStore {
  // loading: boolean;
  // loaded: boolean;
  count?: number;
  cachedAt?: string;
  error?: boolean;
  items: ISteamStoreInventory[] | null;
  sellerBanEndAt?: string | null;
}
export interface ISteamInventoryAutoSelect {
  selectionSum: number;
  selectedIds?: string;
}
export interface ISteamStoreInventory {
  baseItem: IBaseItemDtoV2;
  custom: boolean;
  float?: number;
  passed: boolean;
  price: number;
  priceRange: {
    start: number;
    end: number;
  };
  safePrice: number;
  steamBotId: number;
  steamInventoryId: number;
  stickers: ISticker[];
  tradable: boolean;
  tradeLockEndAt: string | null;

  // TODO: выглядит как что-то, чего уже нет
  inTrade: boolean;
  isUserSeller: boolean;
  preferredPrice?: number;
  selectedPercent?: number;

  // TODO: костыль
  extra?: {
    increase: number;
    price: number;
    safeOverprice?: number;
  };
}

export type ISteamStoreTradeBotsFields = {
  [key in GameIDKeys]: number;
};

export interface ISteamStoreTradeBots {
  success: boolean;
  deposit: ISteamStoreTradeBotsFields;
  classic: ISteamStoreTradeBotsFields;
  fast: ISteamStoreTradeBotsFields;
}

export interface ISteamMappedItems {
  steamItems: ISteamStoreInventory[] | null | undefined;
  selectedItems: IP2pDepositItem[];
  depositingItems: IP2pDepositingItem[];
}
