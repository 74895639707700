import { AnimationSpeed, SoundNameFast } from '@dev-fast/types';

export interface ICasesSettingsStateModel {
  controlPanelHidden: boolean;
  soundEnabled: boolean;
  animationSpeed: AnimationSpeed;
  activeSound: SoundNameFast | undefined;
}

export const CASES_SETTINGS_INITIAL_STATE: ICasesSettingsStateModel = {
  controlPanelHidden: false,
  soundEnabled: false,
  animationSpeed: AnimationSpeed.SLOW,
  activeSound: SoundNameFast.STANDART,
};
