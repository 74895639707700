import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';

import { FeatureFlagsService } from './feature-flags.service';

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagGuard {
  readonly #featureFlags = inject(FeatureFlagsService);
  readonly #router = inject(Router);

  canActivate(next: ActivatedRouteSnapshot): boolean | UrlTree {
    const requiredFeatureFlag: string[] = next.data['requiredFeatureFlags'] as string[];
    const featureFlagRedirect: string = (next.data['featureFlagRedirect'] as string) || '/';
    return this.#featureFlags.isFeatureFlagEnabled(requiredFeatureFlag) ? true : this.#router.createUrlTree([featureFlagRedirect]);
  }
}
