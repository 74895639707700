<div
  *ngIf="sticker && sticker.icon"
  class="sticker tw-relative"
>
  <div class="hover-popup tw-flex tw-gap-2 tw-absolute">
    <img
      [src]="sticker.icon | stickerHash"
      alt
    />
    <div class="tw-flex tw-flex-col tw-gap-2">
      <span class="name tw-font-normal">{{ sticker.name }}</span>
      <span class="price tw-font-medium">$ {{ sticker.price }}</span>
    </div>
  </div>
  <div class="sticker-item">
    <img
      class="sticker-img"
      [src]="sticker.icon | stickerHash"
      alt
    />
  </div>
</div>
