<ng-container *ngIf="componentView === 'compact'; else defaultTemplate">
  <div
    class="compact-filter flex fx-align-center fx-justify-between gap-2 w-100"
    (click)="compactChangeSortMethod()"
  >
    <div class="flex fx-align-center gap-3">
      {{ translatePath + sortMethods[changedSortMethodIndex].viewValue | uppercase | translate }}
      <div
        [class]="'changed-index_' + changedSortMethodIndex"
        class="filter-icon"
      ></div>
    </div>
    <mat-icon
      [class]="'arrow-direction_' + changedSortMethodIndex"
      class="arrow-icon"
      svgIcon="arrow--design"
    ></mat-icon>
  </div>
</ng-container>
<ng-template #defaultTemplate>
  <div
    class="filter-box"
    [matMenuTriggerFor]="sortMenu"
  >
    <div
      class="filter-icon-wrapper"
      [class]="'changed-index_' + changedSortMethodIndex"
    >
      <div class="filter-icon"></div>
    </div>
    <button
      class="btn select-btn"
      mat-button
      *ngIf="changedSortMethodIndex >= 0"
    >
      {{ translatePath + sortMethods[changedSortMethodIndex].viewValue | uppercase | translate }}
    </button>
    <mat-menu
      class="mat-menu mat-menu--cases-filter"
      #sortMenu="matMenu"
      xPosition="after"
    >
      <ng-container *ngIf="sortMethods">
        <!--<ng-scrollbar [autoHeightDisabled]="false">-->
        <button
          mat-menu-item
          *ngFor="let method of sortMethods; let index = index"
          (click)="changeSortMethod(method)"
        >
          {{ translatePath + method.viewValue | uppercase | translate }}
        </button>
        <!--</ng-scrollbar>-->
      </ng-container>
    </mat-menu>
  </div>
</ng-template>
