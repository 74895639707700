<div class="tech-support">
  <div class="tech-support__wrapper">
    <div class="tech-support__top">
      <h2 class="tech-support__title">{{ 'LOCAL.SUPPORT_MODAL.HEADER' | translate }}</h2>
    </div>
    <div class="tech-support__content">
      <div class="tech-support__column">
        <div class="tech-support__header">
          <!--          FIXME Нужные другие иконки-->
          <mat-icon
            class="tech-support__icon"
            svgIcon="support-message"
          ></mat-icon>
          <span class="tech-support__title"> {{ 'LOCAL.SUPPORT_MODAL.CHAT_HEADER' | translate }}</span>
          <button
            class="tech-support__icon_close"
            (click)="close()"
          >
            <mat-icon svgIcon="notification-error"></mat-icon>
          </button>
        </div>
        <div class="tech-support__text">
          {{ 'LOCAL.SUPPORT_MODAL.CHAT_TEXT' | translate }}
        </div>
        <button
          class="btn btn-primary tech-support__button"
          [disabled]="!hasChat()"
          (click)="openChatra()"
        >
          {{ 'LOCAL.SUPPORT_MODAL.CHAT_BUTTON' | translate }}
          <!-- <a langRouterLink [routerLink]="'support'">Send message</a> -->
        </button>
      </div>
      <div class="tech-support__column">
        <div class="tech-support__header">
          <!--FIXME Нужные другие иконки-->
          <mat-icon
            class="tech-support__icon"
            svgIcon="ticket"
          ></mat-icon>
          <span class="tech-support__title">{{ 'LOCAL.SUPPORT_MODAL.TICKER_HEADER' | translate }}</span>
        </div>
        <div class="tech-support__text">
          {{ 'LOCAL.SUPPORT_MODAL.TICKER_TEXT' | translate }}
        </div>
        <!-- <a
          langRouterLink [routerLink]="'support'"
          class="btn btn-primary tech-support__button"
          (click)="close()"
          >Create a ticket</a>
        -->
        <a
          href="mailto:support@csgofast.com"
          class="btn btn-primary tech-support__button"
          >{{ 'LOCAL.SUPPORT_MODAL.TICKER_BUTTON' | translate }}</a
        >
      </div>
    </div>
  </div>
</div>
