import { IconConfig } from '@dev-fast/types';

export const iconsHilo: IconConfig[] = [
  {
    name: 'hilo-joker',
    path: 'assets/img/mat-icons/hilo/hilo-joker.svg',
  },
  {
    name: 'hilo-joker-symbol',
    path: 'assets/img/mat-icons/hilo/hilo-joker-symbol.svg',
  },
];
