import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { UserAvatarModule } from '@dev-fast/ui-components';
import { TranslateModule } from '@ngx-translate/core';

import { SkinItemImageModule } from '@app/shared/directives';
import { AppCurrencyModule, ReplaceAvatarHashPipeModule } from '@app/shared/pipes';

import { P2pAuctionCompletedModalComponent } from './p2p-auction-completed-modal.component';

@NgModule({
  declarations: [P2pAuctionCompletedModalComponent],
  imports: [
    CommonModule,
    MatIconModule,
    TranslateModule,
    AppCurrencyModule,
    SkinItemImageModule,
    UserAvatarModule,
    ReplaceAvatarHashPipeModule,
  ],
  exports: [P2pAuctionCompletedModalComponent],
})
export class P2pAuctionCompletedModalModule {}
