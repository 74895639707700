import { Component, HostBinding, Input } from '@angular/core';
import { ISkinItem } from '@dev-fast/types';

import { TooltipService } from '../tooltip.service';
import { skinItemTooltipAnimation } from './skin-item-tooltip.animation';

@Component({
  selector: 'app-ui-skin-item-tooltip',
  templateUrl: './skin-item-tooltip.component.html',
  styleUrls: ['./skin-item-tooltip.component.scss'],
  animations: [skinItemTooltipAnimation],
})
export class SkinItemTooltipComponent {
  @HostBinding('@skinItemTooltipAnimation') private animation: boolean;
  @Input()
  public skin!: ISkinItem;

  constructor(private tooltipService: TooltipService) {
    this.animation = true;
  }

  public close(): void {
    this.tooltipService.closeLastTooltip();
  }
}
