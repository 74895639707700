<div class="tw-h-full">
  <div
    inViewport
    [inViewportOptions]="{ threshold: [0] }"
    (inViewportAction)="elementVisibleToggle($event)"
    class="skin-item-container flex fx-column fx-align-center fx-justify-between w-100 tw-cursor-pointer"
    [class.new-look]="newLook"
    (click)="select()"
  >
    <ng-container *ngIf="isElementVisible">
      <div class="absolute w-100 h-100 z-0 bg-wrapper"></div>

      <ng-container *ngIf="backgroundIcon">
        <div class="skin-item-background_image">
          <mat-icon
            class="background-icon"
            [svgIcon]="backgroundIcon"
          ></mat-icon>
        </div>
      </ng-container>

      <ng-container *ngIf="newLook; else oldTemplate">
        <ng-container *ngIf="customCase; else kitItem">
          <div class="skin-item-image kit-container w-100">
            <img
              *ngFor="let item of kit | slice: 0 : 3"
              class="kit-image w-100"
              [src]="icon | hash"
              alt
            />
          </div>
        </ng-container>

        <ng-template #kitItem>
          <app-ui-skin-statuses-overlay
            [skinItemStatus]="status"
            [tradeLockEndAt]="tradeLockEndAt"
          />
          <ng-container *ngIf="kit.length > 1; else singleItemTemplate">
            <div
              class="skin-item-image kit-container w-100"
              [@shrinkAnim]="status"
            >
              <img
                *ngFor="let item of kit | slice: 0 : 3"
                class="kit-image w-100"
                [src]="item.baseItem.icon | hash"
                alt
              />
              <div
                *ngIf="kit.length - 3 > 0"
                clickStop
                class="kit-more-btn tw-cursor-pointer flex fx-justify-center fx-align-center tw-font-bold"
                (click)="showItemDetailsModal()"
              >
                +{{ kit.length - 3 }}
              </div>
            </div>
          </ng-container>
          <ng-template #singleItemTemplate>
            <div class="skin-item__header flex fx-justify-between w-100 tw-font-normal z-1">
              <app-ui-float-indicator
                class="tw-font-normal"
                [item]="kit[0]"
                [numberFormat]="5"
                [exteriorShortView]="true"
              />
            </div>
            <div class="skin-item-image w-100">
              <div class="flex gap-1 absolute z-4 stickers">
                <div
                  *ngFor="let sticker of kit[0].stickers"
                  class="sticker-item"
                >
                  <img
                    *ngIf="sticker.icon"
                    [src]="sticker.icon | stickerHash"
                    alt
                  />
                </div>
              </div>
              <img
                [@shrinkAnim]="status"
                class="skin-image w-80"
                [src]="kit[0].baseItem.icon | hash"
                [class.no-type]="!kit[0].baseItem.exterior"
                alt
              />
            </div>
          </ng-template>
          <div class="skin-item-info flex fx-column fx-align-start fx-justify-center p-sm-3 w-100">
            <ng-container *ngIf="!!kit[0].baseItem.phase">
              <div class="skin-item-info_phase disable-text-wrap flex w-100">
                {{ kit[0].baseItem.phase }}
                <mat-icon
                  class="icon"
                  svgIcon="diamond"
                ></mat-icon>
              </div>
            </ng-container>

            <ng-container *ngIf="kit[0].baseItem.name && kit[0].baseItem.skin && kit.length === 1">
              <div class="skin-item-info_name pb-xs-1 disable-text-wrap w-100">
                {{
                  kit[0].baseItem.type === 'weapon' && kit[0].baseItem.shortName
                    ? kit[0].baseItem.shortName
                    : kit[0].baseItem.name.split('|')[0]
                }}
              </div>
            </ng-container>
            <div class="flex fx-align-center fx-justify-between gap-xs-6 pb-sm-3 w-100 tw-font-medium">
              <div class="skin-item-info_skin disable-text-wrap">
                <ng-container *ngIf="kit.length === 1; else kitsNameTemplate">
                  {{
                    kit[0].baseItem.skin
                      ? kit[0].baseItem.skin
                      : kit[0].baseItem.type === 'weapon' && kit[0].baseItem.shortName
                        ? kit[0].baseItem.shortName
                        : kit[0].baseItem.name
                  }}
                </ng-container>
                <ng-template #kitsNameTemplate>
                  {{ 'P2P_WIDGETS.RENEW_INFO_MODAL.SET' | translate }} {{ kit.length }}
                  {{ 'P2P_WIDGETS.RENEW_INFO_MODAL.ITEMS' | translate }}
                </ng-template>
              </div>
              <a
                clickStop
                class="skin-item-header__card-info flex fx-align-center"
                mat-button
                (click)="showItemDetailsModal()"
              >
                <mat-icon
                  class="icon"
                  svgIcon="information"
                ></mat-icon>
              </a>
            </div>
            <app-ui-float-line
              *ngIf="kit.length === 1"
              class="w-100"
              [float]="kit[0].float"
            />
          </div>
        </ng-template>
        <div class="skin-item__price-container tw-font-medium w-100 z-1">
          <div class="skin-item__price p-1 tw-rounded-lg flex fx-align-center fx-justify-between">
            <ng-container *ngIf="price && price > 0; else blockedItemTemplate">
              <app-currency
                class="price"
                [class.disabled-by-price]="!passed"
                [value]="price"
              />
              <div
                *ngIf="overprice"
                class="overprice"
                [ngClass]="overpriceClass"
              >
                {{ (overprice > 0 ? '+' : '') + overprice + '%' }}
              </div>
            </ng-container>
            <ng-template #blockedItemTemplate>
              {{ 'P2P_WIDGETS.RENEW_INFO_MODAL.NOT_FOR_SALE' | translate }}
            </ng-template>
          </div>
        </div>
      </ng-container>

      <ng-template #oldTemplate>
        <ng-container *ngIf="kit.length > 1; else soloItemTemplate">
          <div class="skin-item-header flex fx-justify-end fx-align-start w-100">
            <a
              *ngIf="shortName && skinName"
              clickStop
              class="skin-item-header__card-info"
              mat-button
              (click)="showItemDetailsModal()"
            >
              <mat-icon
                class="icon"
                svgIcon="information"
              ></mat-icon>
            </a>
          </div>
          <div class="skin-item-image kit-container w-100">
            <img
              *ngFor="let item of kit | slice: 0 : 3"
              class="kit-image w-80"
              [src]="item.baseItem.icon | hash"
              alt
            />
            <div
              *ngIf="kit.length - 3 > 0"
              clickStop
              class="kit-more-btn tw-cursor-pointer flex fx-justify-center fx-align-center tw-font-bold"
              (click)="showItemDetailsModal()"
            >
              <span>+{{ kit.length - 3 }}</span>
            </div>
          </div>
          <div class="skin-item-info flex fx-column fx-align-start fx-justify-center p-sm-3 w-100">
            <div class="skin-item-info_skin disable-text-wrap w-100 tw-font-medium pb-xs-2">
              {{ 'Предметов:' | translate }} {{ kit.length }}
            </div>
            <ng-container *ngIf="price">
              <app-currency
                class="skin-item-info_price tw-font-medium"
                [value]="price"
              />
            </ng-container>
          </div>
        </ng-container>

        <ng-template #soloItemTemplate>
          <div class="skin-item-header flex fx-justify-end fx-align-center w-100">
            <ng-container *ngIf="exterior && type">
              <div class="skin-item-header_exterior w-100">
                <ng-container *ngIf="statTrak"><span class="stat-track">ST</span> | </ng-container>{{ toShortRevision(exterior) }}
                <ng-container *ngIf="float"> | {{ float | number: '1.1-3' }}</ng-container>
              </div>
            </ng-container>
            <a
              *ngIf="shortName && skinName"
              clickStop
              class="skin-item-header__card-info"
              mat-button
              (click)="showItemDetailsModal()"
            >
              <mat-icon
                class="icon"
                svgIcon="information"
              ></mat-icon>
            </a>
          </div>
          <div class="skin-item-image w-100">
            <div class="flex gap-1 absolute z-4">
              <div
                *ngFor="let sticker of stickers"
                class="sticker-item"
              >
                <img
                  *ngIf="sticker.icon"
                  [src]="sticker.icon | stickerHash"
                  alt
                />
              </div>
            </div>
            <img
              class="skin-image w-80"
              [src]="icon | hash"
              alt
            />
          </div>
          <div class="skin-item-info flex fx-column fx-align-start fx-justify-center w-100">
            <ng-container *ngIf="!!phase">
              <div class="skin-item-info_phase disable-text-wrap flex w-100">
                {{ phase }}
                <mat-icon
                  class="icon"
                  svgIcon="diamond"
                ></mat-icon>
              </div>
            </ng-container>
            <ng-container *ngIf="name">
              <div class="skin-item-info_name disable-text-wrap w-100 pb-xs-2">
                {{ type === 'weapon' && shortName ? shortName : name }}
              </div>
            </ng-container>
            <ng-container *ngIf="skinName">
              <div class="skin-item-info_skin disable-text-wrap w-100 tw-font-medium">{{ skinName }}</div>
            </ng-container>
            <ng-container *ngIf="price">
              <app-currency
                class="skin-item-info_price tw-font-medium"
                [value]="price"
              />
            </ng-container>
          </div>
          <div
            *ngIf="isSelect"
            class="skin-item-check_box flex fx-align-center fx-justify-center"
          >
            <mat-icon
              class="skin-item-check_icon"
              svgIcon="checked2"
            ></mat-icon>
          </div>
          <!-- {{status|json}}-->
          <ng-container *ngIf="status !== P2pItemStatus.Default">
            <div
              class="status-icon flex fx-align-center fx-justify-center"
              [ngSwitch]="status"
            >
              <div
                *ngSwitchCase="P2pItemStatus.Selected"
                class="is-selected icon flex fx-align-center fx-justify-center"
              >
                <mat-icon svgIcon="checked-icon"> </mat-icon>
              </div>
              <div
                *ngSwitchCase="P2pItemStatus.Deposit"
                class="status status_search tw-font-medium p-sm-3"
              >
                <div class="status__content status__content_with-icon">
                  <div>
                    <mat-spinner
                      [strokeWidth]="2"
                      [diameter]="22"
                      [color]="'primary'"
                    />
                  </div>
                  <div class="status__text">
                    <span>{{ 'MARKET.INVENTORY.SELLING' | translate }}</span>
                    <span class="status__timer">{{ passedTime$ | async }}</span>
                  </div>
                </div>
              </div>
              <div
                *ngSwitchCase="P2pItemStatus.ReadyToSell"
                class="is-selling tw-font-medium p-sm-3"
              >
                {{ 'P2P_WIDGETS.SELL' | translate }} {{ 11 | appCurrency }}
              </div>
              <div
                *ngSwitchCase="P2pItemStatus.SkinSearch"
                class="status status_search tw-font-medium p-sm-3"
              >
                <div class="status__content status__content_with-icon">
                  <div>
                    <mat-spinner
                      [strokeWidth]="2"
                      [diameter]="22"
                      [color]="'primary'"
                    />
                  </div>
                  <div class="status__text">
                    <span>{{ 'NOTIFICATIONS.ITEM_SEARCH' | translate }}</span>
                    <span class="status__timer">{{ passedTime$ | async }}</span>
                  </div>
                </div>
              </div>
              <div
                *ngSwitchCase="P2pItemStatus.WaitingForWithdraw"
                class="status status_search tw-font-medium p-sm-3"
              >
                <div class="status__content status__content_with-icon">
                  <div>
                    <mat-spinner
                      [strokeWidth]="2"
                      [diameter]="22"
                      [color]="'primary'"
                    />
                  </div>
                  <div class="status__text">
                    <span>{{ 'MARKET.INVENTORY.WITHDRAWAL_WAIT' | translate }}</span>
                    <span class="status__timer">{{ passedTime$ | async }}</span>
                  </div>
                </div>
              </div>
              <div
                *ngSwitchCase="P2pItemStatus.WaitingForSale"
                class="status status_search tw-font-medium p-sm-3"
              >
                <div class="status__content status__content_with-icon">
                  <div>
                    <mat-spinner
                      [strokeWidth]="2"
                      [diameter]="22"
                      [color]="'primary'"
                    />
                  </div>
                  <div class="status__text">
                    <span>{{ 'MARKET.INVENTORY.SALE_WAIT' | translate }}</span>
                    <span class="status__timer">{{ passedTime$ | async }}</span>
                  </div>
                </div>
              </div>
              <div
                *ngSwitchCase="P2pItemStatus.Sold"
                class="status tw-font-medium p-sm-3"
              >
                <div class="status__content">
                  <mat-icon
                    [svgIcon]="'item-sold'"
                    class="sold-icon"
                  />
                </div>
              </div>
              <div
                *ngSwitchCase="P2pItemStatus.AcceptTrade"
                class="status status_trade tw-font-medium tw-p-3.5"
              >
                <a
                  *ngIf="userProviderId"
                  class="btn btn-primary--design status__content tw-w-full tw-p-3.5 tw-cursor-pointer"
                  target="_blank"
                  [href]="'https://steamcommunity.com/profiles/' + userProviderId + '/tradeoffers/'"
                >
                  <span>{{ 'NOTIFICATIONS.TRADE_ACCEPT' | translate }}</span>
                  <span class="status__timer">{{ passedTime$ | async }}</span>
                </a>
              </div>
            </div>
          </ng-container>
        </ng-template>
      </ng-template>
      <ng-container *ngIf="countdown$ | async">
        <div class="skin-item-timeout flex fx-align-center fx-justify-center">
          <span *ngIf="seconds$ | async as seconds">{{ seconds }}s</span>
        </div>
      </ng-container>
    </ng-container>
  </div>
</div>
