<ng-container [ngSwitch]="getTemplateType">
  <div
    class="chat-info-container"
    *ngSwitchCase="'level'"
  >
    <span>{{ 'CHAT_WIDGET.RESTRICTION' | translate }}</span>
  </div>

  <div
    *ngSwitchCase="'banned'"
    class="ban-panel"
  >
    <div class="ban-panel__icon">
      <mat-icon svgIcon="telegram"></mat-icon>
    </div>
    <div class="ban-panel__content">
      <span
        class="ban-panel__content-reason"
        *ngIf="chatAccess && chatAccess.banReason"
      >
        {{ chatAccess.banReason | translate }}
      </span>
      <span
        *ngIf="chatAccess && chatAccess.bannedUntil"
        class="ban-panel__content-until"
        >{{ 'CHAT_WIDGET.BANNED' | translate }} {{ banTimer }}</span
      >
    </div>
  </div>

  <div
    class="h-100 input-container"
    *ngSwitchCase="'access'"
  >
    <form
      class="message message__block"
      [formGroup]="messageForm"
    >
      <div class="message__wrapper">
        <div class="textarea__wrapper">
          <tui-textarea
            formControlName="message"
            [expandable]="true"
            [rows]="5"
          >
          </tui-textarea>
          <div
            class="message__error-wrapper"
            *ngIf="canShowError"
            [class.error-color]="message && message.errors?.['maxlength']"
          >
            <span>{{ message.value?.length + '/ 139' }}</span>
          </div>
        </div>
        <div class="message__buttons">
          <button
            class="chat-footer__btn chat-footer__btn_send"
            (click)="onAddMessage()"
            [disabled]="!messageForm.valid"
          >
            <mat-icon
              svgIcon="send-renew"
              class="chat-footer__btn_icon"
            ></mat-icon>
          </button>
        </div>
      </div>
    </form>
  </div>
</ng-container>
