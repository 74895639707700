<div
  class="inventory__wrapper"
  [formGroup]="form"
>
  <div class="inventory__balance-panel">
    <div class="inventory__balance-panel-inner">
      <div class="inventory__balance-title">
        {{ 'STEAM_PANEL.PRICE' | translate }}
      </div>
      <div class="inventory__balance-sum">{{ totalBalance | appCurrency }}</div>
      <!--      FIXME Добавить функционал на открытие ещё одного окна-->
      <button
        class="inventory__balance-button"
        (click)="onToggleExchangePanel()"
      >
        <mat-icon
          class="inventory__balance-icon"
          svgIcon="{{ showNotice ? 'close' : 'update-arrows' }}"
        ></mat-icon>
      </button>
    </div>
    <div
      class="inventory__balance-btn__notice"
      *ngIf="!isInventoryAvailable && showNotice"
    >
      <span>{{ 'INVENTORY_PANEL.ROTATE' | translate }}</span>
    </div>
  </div>
  <div class="inventory__sort-panel">
    <!-- <span
      class="inventory__sort-panel_text"
    >
      {{ 'INVENTORY_PANEL.BY_PRICE' | translate }}
      <mat-icon
        class="inventory__sort-panel_icon inventory__sort-panel_icon-arrow"
        [ngClass]="{ rotate: sortPriceFlag }"
        svgIcon="arrow-up"
      ></mat-icon>
    </span> -->
    <label class="filters__link-wrapper">
      <span class="filters__link">{{ 'INVENTORY_PANEL.BY_PRICE' | translate }}</span>
      <input
        type="checkbox"
        formControlName="sortBy"
      />
      <mat-icon
        class="filters__link_icon"
        svgIcon="arrow-up"
      ></mat-icon>
    </label>
    <div
      class="inventory__sort-panel_icon inventory__sort-panel_icon-refresh"
      (click)="onRefresh()"
    >
      <mat-icon svgIcon="refresh"></mat-icon>
    </div>
  </div>
  <div class="inventory__items-wrapper">
    <ng-scrollbar
      visibility="hover"
      (reachedBottom)="onBottomReached()"
      [reachedOffset]="200"
    >
      <div class="inventory__items-list">
        <ng-container *ngIf="inventoryList && inventoryList.length > 0; else emptyList">
          <ng-container *ngFor="let item of inventoryList; trackBy: trackingFunction">
            <app-ui-skin-item
              [name]="item.name"
              [icon]="item.icon"
              [color]="item.color"
              [price]="item.price"
              [id]="item.id"
              [ngClass]="{ selected: isSelected(item.id) }"
              (skinClick)="clickOnSkin($event)"
            >
            </app-ui-skin-item>
          </ng-container>
        </ng-container>

        <ng-template #emptyList>
          <div class="inventory__items-empty">
            <div class="inventory__items-empty_wrapper">
              <span class="inventory__items-empty_title">
                {{ 'INVENTORY_PANEL.INVENTORY_IS_EMPTY' | translate }}
              </span>
              <span class="inventory__items-empty_description">
                {{ 'INVENTORY_PANEL.EXCHANGE_SKINS_IN_STORE' | translate }}
              </span>
              <button
                class="btn btn-primary inventory__items-empty_btn"
                (click)="onToggleExchangePanel()"
              >
                <span>{{ 'INVENTORY_PANEL.TRADE' | translate }}</span>
                <mat-icon svgIcon="update-arrows"></mat-icon>
              </button>
            </div>
          </div>
        </ng-template>
      </div>
    </ng-scrollbar>
  </div>
  <!--  FIXME Добавить ключ-->
  <div class="inventory__control-panel">
    <button
      class="btn btn-outline-secondary inventory__control-panel_btn"
      [disabled]="(inventoryList && !inventoryList.length) || gameInProgress"
      (click)="onSellItems()"
    >
      Sell all
    </button>
  </div>
</div>
<div
  *ngIf="subPanel !== subPanels.NONE"
  class="exchange-panel opened"
>
  <ng-content #exhangePanel> </ng-content>
  <div
    class="btn__close"
    (click)="onCloseSubPanel()"
  >
    <mat-icon
      class="btn__close_icon"
      svgIcon="close"
    ></mat-icon>
  </div>
</div>

<ng-container *ngIf="subPanel === subPanels.EXHANGE">
  <div
    class="overlay"
    (click)="onCloseSubPanel()"
  ></div>
</ng-container>
