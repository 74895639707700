import { IconConfig } from '@dev-fast/types';

export const iconsInformational: IconConfig[] = [
  {
    name: 'faq',
    path: 'assets/img/mat-icons/informational/faq.svg',
  },
  {
    name: 'more-info',
    path: 'assets/img/mat-icons/informational/more-info.svg',
  },
  {
    name: 'information',
    path: 'assets/img/mat-icons/informational/information.svg',
  },
  {
    name: 'info',
    path: 'assets/img/lobby/info-circle.svg',
  },
];
