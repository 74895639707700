import { AnimationSpeed, PROJECT } from '@dev-fast/types';

export const MIN_PROFIT_OVERPRICE = 2500;
export const MIN_MOTIVATOR_ITEMS = 10;

export const MAX_OPEN_CASE_COUNT = 5;

export const CASES_PRIZE_OFFSET_INDEX = 3;

export const MOBILE_BREAKPOINTS: string[] = ['xs', 's', 'm'];

export const AVAILABLE_KEY_CODES: string[] = [
  'KeyA',
  'KeyD',
  'KeyR',
  'KeyS',
  'KeyF',
  'ArrowLeft',
  'ArrowRight',
  'ArrowUp',
  'ArrowDown',
  'Digit1',
  'Digit2',
  'Digit3',
  'Digit4',
  'Digit5',
  'Enter',
];

export const OPENING_ANIM_DURATION: Partial<Record<PROJECT, Record<AnimationSpeed, number>>> = {
  [PROJECT.FAST]: {
    [AnimationSpeed.QUICK]: 2000,
    [AnimationSpeed.SLOW]: 4500,
  },
  [PROJECT.FAST_NEW]: {
    [AnimationSpeed.QUICK]: 2000,
    [AnimationSpeed.SLOW]: 4500,
  },
  [PROJECT.MARKET]: {
    [AnimationSpeed.QUICK]: 2500,
    [AnimationSpeed.SLOW]: 6000,
  },
};
