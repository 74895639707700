import { Component, inject, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

import { EnvironmentService } from '@app/core/environment-service';

@Component({
  standalone: true,
  selector: 'app-ui-auth-fail-tooltip',
  templateUrl: './auth-fail-tooltip.component.html',
  styleUrls: ['./auth-fail-tooltip.component.scss'],
  imports: [TranslateModule, MatIconModule],
})
export class AuthFailTooltipComponent {
  #envService = inject(EnvironmentService);
  @Input() email: string = this.#envService.getEnvironment().SUPPORTS_EMAIL;
}
