import { IBaseItemDtoV2 } from '..';
import { HistoryIcons, HistoryItemStatus, HistorySourceType, HistorySubTypes, HistoryTypes } from '../enums';

export interface IHistoryItem {
  type: HistoryTypes;
  subType: HistorySubTypes;
  date: Date;
  amount: number;
  description: string;
  icon: HistoryIcons;
  data?: any;
  // data?: HistoryData;
}
export type HistoryData = IHistoryGameData | IHistoryGameBetData | IHistoryGameCaseData | IHistoryInventoryData | IHistoryPaymentData;
export interface IHistoryGameData {
  gameId?: number;
}

export interface IHistoryGameBetData extends IHistoryGameData {
  bets?: IHistoryBet[];
}

export interface IHistoryGameCaseData extends IHistoryGameData {
  caseCount: number;
  lineCount: number;
  caseName: number;
}

export interface IHistoryInventoryData {
  skins: IHistorySkin[];
}

export interface IHistoryPaymentData {
  paymentSystem: string;
  paymentMethod: string;
  paymentWallet: any;
  paymentStatus: any;
}

export interface IHistorySkin {
  name: string;
  icon: string;
  color: string;
}

export interface IHistoryBet {
  amount: number;
  type: any;
  isWin: boolean;
}

export interface HistoryItem {
  createdAt: Date;
  id?: number;
  item: {
    baseItem: IBaseItemDtoV2;
    price: number;
  };
  status: HistoryStatus;
}
export interface HistoryItemTemplateData {
  img: string;
  locale: string;
  isIcon: boolean;
}

export interface HistoryStatus {
  status: HistoryItemStatus;
  source: {
    type: HistorySourceType;
    data?: {
      backgroundImage: string;
      name: string;
    };
  };
}
