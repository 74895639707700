import { animate, group, query, style, transition, trigger } from '@angular/animations';

export const gameMenuAnimation = [
  trigger('tableMenuOpenAnimation', [
    transition(':enter', [
      query(
        ':self',
        [
          style({ transform: 'scaleY(0.575)', opacity: 0, transformOrigin: '0% 100%' }),
          animate('150ms linear', style({ transform: 'scaleY(1)', opacity: 1 })),
        ],
        { optional: true }
      ),
    ]),
  ]),
  trigger('eventMenuHover', [
    transition('false => true', [
      group([
        query(
          '.event-menu__animation-body',
          [
            style({ transform: 'scaleX(0)', transformOrigin: '0% 100%', opacity: 0 }),
            animate('350ms linear', style({ transform: 'scaleX(1)', opacity: 1 })),
          ],
          { optional: true }
        ),
        query(
          '.menu-item__animation',
          [
            style({ transform: 'translateX(-5rem)', transformOrigin: '0% 100%', opacity: 0 }),
            animate('350ms linear', style({ transform: 'translateX(0)', opacity: 1 })),
          ],
          { optional: true }
        ),
      ]),
    ]),
    transition('true => false', [
      group([
        query('.event-menu__animation-body', animate('250ms linear', style({ width: 0, opacity: 0 })), {
          optional: true,
        }),
        query('.menu-item__animation', animate('250ms linear', style({ transform: 'translateX(-5rem)', opacity: 0 })), {
          optional: true,
        }),
      ]),
    ]),
  ]),
];
