import { NotificationCategory, NotificationStatus, NotificationType } from '../enums';

export interface INotification {
  id: number;
  type: NotificationType;
  category?: NotificationCategory | string;
  status: NotificationStatus;
  icon: string;
  message?: any;
  params?: Record<string, any>;
  actions?: { name: string; callback: () => void | Promise<any> }[];
  createDate: number | Date;
  // показать или нет всплывающий нотик ( если false, то только в окошке будет показан)
  showToast?: boolean;
  updateNotification?: boolean; // если true, то попробует поднять наверх существующий, а не добавить новый
  // на фронте используется для отображения времени в тосте
  remainingTime?: number;
  // игнорировать список исключений. По дефолту true
  ignoreExceptionList?: boolean;
  // из локалстораджа/кода или с бэка
  system?: boolean;
  legacy?: boolean;
}
export interface IOldNotificationDto {
  body: string;
  header: string;
  params: any;
  type: NotificationType;
  userId: number;
}
