import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { IGameSettings } from '@dev-fast/types';

@Component({
  selector: 'app-participation-panel-actions',
  templateUrl: './participation-panel-actions.component.html',
  styleUrls: ['./participation-panel-actions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ParticipationPanelActionsComponent {
  @Input() isPrticipationPanelOpened = false;
  @Input() isMobile: boolean | null = false;
  @Input() actionBtnIsDisabled: boolean | null = false;

  @Input() participatedItemsSum: number | null = 0;
  @Input() participatedItemsLength: number | undefined = 0;
  @Input() selectedItemsSum: number | null = 0;
  @Input() newItemsLength: number | null = 0;
  @Input() selectedItemsLength: number | null | undefined = 0;
  @Input() gameSettings: IGameSettings | null = null;

  @Output() panelBtnAction: EventEmitter<void> = new EventEmitter<void>();
  @Output() clearSelectedItems: EventEmitter<void> = new EventEmitter<void>();

  get btnCurrentClass(): string {
    if (this.newItemsLength || (!this.isPrticipationPanelOpened && !this.participatedItemsLength)) {
      return 'btn-primary--design';
    }
    if (this.isPrticipationPanelOpened && !this.newItemsLength && !this.participatedItemsLength) {
      return 'btn-basic--design';
    }
    if (this.participatedItemsLength && !this.newItemsLength) {
      return 'btn-default--design';
    }
    return '';
  }
}
