<mat-tab-group
  dynamicHeight
  [selectedIndex]="selected.value"
  [disablePagination]="true"
  (selectedIndexChange)="selected.setValue($event)"
>
  <mat-tab label="{{ 'P2P_WIDGETS.BUY' | translate }}">
    <app-p2p-purchase></app-p2p-purchase>
  </mat-tab>
  <mat-tab label="{{ 'P2P_WIDGETS.SELL' | translate }}">
    <app-p2p-sell></app-p2p-sell>
  </mat-tab>
  <mat-tab label="{{ 'Settings' }}">
    <app-p2p-settings></app-p2p-settings>
  </mat-tab>
</mat-tab-group>
