import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { IUICheckboxLabel, QuickFaqName } from '@dev-fast/types';
import { TranslateModule } from '@ngx-translate/core';

import { QuickFaqService } from '@app/core/state/faq';
import { UiCheckboxSAComponent } from '@app/ui/components/index';

@Component({
  selector: 'app-joker-mode-modal',
  templateUrl: './joker-mode-modal.component.html',
  styleUrls: ['./joker-mode-modal.component.scss'],
  standalone: true,
  imports: [ReactiveFormsModule, MatIconModule, UiCheckboxSAComponent, TranslateModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JokerModeModalSAComponent {
  readonly #dialogRef = inject(MatDialogRef<JokerModeModalSAComponent>);
  readonly #quickFaqService = inject(QuickFaqService);

  readonly formControlName = 'dont_show_again';
  readonly form: FormGroup = new FormGroup({
    [this.formControlName]: new FormControl<boolean>(true, {}),
  });
  readonly labels: IUICheckboxLabel[] = [
    {
      title: 'MARKET.RWT.MODAL.DONT_SHOW_AGAIN',
    },
  ];

  closeModal(): void {
    if (this.form.controls[this.formControlName].value) {
      this.#quickFaqService.setStatus({ menuName: QuickFaqName.JOKER_MODE, wasClosed: true });
    }
    this.#dialogRef.close();
  }
}
