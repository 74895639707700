<div
  class="wrapper"
  @expandAnimation
>
  <div
    class="close-row"
    (tuiSwipe)="onSwipe($event)"
  >
    <button class="close-btn"></button>
  </div>
  <ng-content></ng-content>
</div>
