import { inject, Injectable } from '@angular/core';
import { filter, map, Observable, switchMap } from 'rxjs';

import { BehaviorSubjectItem } from '@app/shared/utils';

import { REFILL_BANNER_ENGINE, RefillBannerEngine } from './symbols';

@Injectable()
export class RefillBannerService {
  readonly #engine: RefillBannerEngine = inject(REFILL_BANNER_ENGINE);
  readonly isAuth$: Observable<boolean> = this.#engine.user$.pipe(
    filter((value) => value !== undefined),
    map((value) => !!value),
  );
  lang$: Observable<string> = this.#engine.currentLocale$.pipe(map((value) => value.path));
  private isClosed: BehaviorSubjectItem<boolean> = new BehaviorSubjectItem<boolean>(false);

  openAuthModal(): void {
    this.#engine.openAuthModal();
  }
  close(): void {
    this.isClosed.value = true;
  }
  canShow(): Observable<boolean> {
    return this.isAuth$.pipe(switchMap((isAuth) => this.isClosed.value$.pipe(map((isClosed) => (isAuth ? false : !isClosed)))));
  }
}
