import { Injectable } from '@angular/core';
import { LOCALES_DIC } from '@dev-fast/types';
import { Action, Selector, State, StateContext } from '@ngxs/store';

import { CookieService } from '@app/core/cookie-service';
import { SetLanguage } from '@app/core/language-service';
import { LocalStorageService } from '@app/core/local-storage-service';

import { FrameMessageTypes } from '../iframe.model';
import { IFrameMessageService } from '../services/message.service';
import { ChangeFrameLocalStorage, SetFrameLoadedStatus } from './iframe.actions';
import { IFRAME_INITIAL_STATE, IFrameStateModel } from './iframe-state.model';

@State<IFrameStateModel>({
  name: 'iframe',
  defaults: IFRAME_INITIAL_STATE,
})
@Injectable()
export class IFrameState {
  constructor(
    private readonly storage: LocalStorageService,
    private readonly frameMessageService: IFrameMessageService,
    private readonly cookieService: CookieService
  ) {}

  @Selector()
  public static loaded({ loaded }: IFrameStateModel): boolean {
    return loaded;
  }

  @Action(SetFrameLoadedStatus)
  public setStatus({ patchState, dispatch }: StateContext<IFrameStateModel>, { loaded }: { loaded: boolean }): void {
    patchState({ loaded });
    if (loaded) {
      const localData = { fastDelivery: this.storage.get('fastDelivery') };
      dispatch(new ChangeFrameLocalStorage(localData, 3000));
    }
  }
  @Action(ChangeFrameLocalStorage)
  public changeFrameLocalStorage(
    { patchState, dispatch }: StateContext<IFrameStateModel>,
    { payload, delay }: ChangeFrameLocalStorage
  ): void {
    // FIXME знаю знаю херь, но фрейм же временно, да потомки?)
    setTimeout(
      () =>
        this.frameMessageService.sendMessage({
          type: FrameMessageTypes.MESSAGE_TO_BB,
          eventName: 'change.localStorage',
          payload,
        }),
      delay ? delay : 0
    );
  }

  @Action(SetLanguage)
  public sendLocaleToBB(ctx: StateContext<IFrameStateModel>, { locale }: SetLanguage): void {
    //TODO for bb if loaded ng but not loaded bb
    this.cookieService.setCookie('lang', LOCALES_DIC[locale.path as keyof typeof LOCALES_DIC], {
      expires: 60 * 60 * 24 * 30,
      path: '/',
    });
    this.frameMessageService.sendMessage({
      type: FrameMessageTypes.MESSAGE_TO_BB,
      eventName: 'changeLang',
      payload: locale,
    });
  }
}
