import { IError } from './error.model';

export interface IActivatePromoResponse {
  activationErr: IError | null;
  activePromoCode: IActivePromocode | null;
  activeReferralCampaign: any;
  affise: any;
  promoActivated: boolean;
}

export interface IActivePromocode {
  success: boolean;
  code: string;
  type: string;
  reward: number | string;
}

export interface IPromoStatus {
  success: string;
  error: string;
  default: string;
}
