import { IconConfig } from '@dev-fast/types';

export const arrowsCommonIcons: IconConfig[] = [
  //short, simple line
  {
    name: 'arrow-right-compact',
    path: 'assets/img/mat-icons/icons-lib/common/arrows/arrow-right-compact.svg',
  },
  {
    name: 'arrow-left-compact',
    path: 'assets/img/mat-icons/icons-lib/common/arrows/arrow-left-compact.svg',
  },
  //short, fill
  {
    name: 'arrow-right-compact-fill',
    path: 'assets/img/mat-icons/icons-lib/common/arrows/arrow-right-compact-fill.svg',
  },
  //default arrow, simple line
  {
    name: 'arrow-right-line',
    path: 'assets/img/mat-icons/icons-lib/common/arrows/arrow-right-line.svg',
  },
  //two ways arrow
  {
    name: 'arrow-two-way',
    path: 'assets/img/mat-icons/icons-lib/common/arrows/arrow-two-way.svg',
  },
];
