import { Privacy, TradeLinks } from '@dev-fast/types';

export class GetUserStatistics {
  static readonly type = '[Account] Get user statistics';
  constructor(public userId: string | number) {}
}

export class GetExperienceLeaders {
  static readonly type = '[Account] Get experience leaders';
}

export class GetLevelsRoadMap {
  static readonly type = '[Account] Get levels roadMap';
}

export class GetRecentExperienceGainList {
  static readonly type = '[Account] Get recent experience gain list';
}

export class GetExperienceStatistic {
  static readonly type = '[Account] Get experience statistic';
}

export class LevelUp {
  static readonly type = '[Account] Level up';
}

export class ChangePrivacy {
  static readonly type = '[Account] Change privacy';
  constructor(public payload: Privacy) {}
}

export class UpdateTradeLinks {
  static readonly type = '[Account] Update trade links';
  constructor(
    public links: TradeLinks,
    public apiKey?: string,
  ) {}
}

export class UpdateTradeLink {
  static readonly type = '[Account] Update trade link';
  constructor(public links: TradeLinks) {}
}

export class UpdateApiKey {
  static readonly type = '[Account] Update Api Key';
  constructor(public apiKey: string) {}
}

export class SteamGuard {
  static readonly type = '[Account] steam guard';
}

export class UpdateApiKeySuccess {
  static readonly type = '[Account] Update Api Key Success';
}

export class UpdateTradeLinkSuccess {
  static readonly type = '[Account] Update trade link Success';
}

export class GetBalanceHistory {
  static readonly type = '[Account] Get balance history';
  constructor(
    public offset?: number,
    public append = false,
  ) {}
}
