import { Injectable } from '@angular/core';
import { State } from '@ngxs/store';

import { DFORM_INITIAL_STATE, DynamicFormsStateModel } from './dynamic-forms.state.model';

@State<DynamicFormsStateModel>({
  name: 'dynamicforms',
  defaults: DFORM_INITIAL_STATE,
})
@Injectable()
export class DynamicFormsState {}
