export interface FooterCounts {
  casesCount: number | null;
  gamesCount: number | null;
  samuraiCount: number | null;
  upgradesCount: number | null;
  usersCount: number | null;
}

export interface MarketCountsData {
  totalDeals: number | null;
  onSale: number | null;
}

export interface MarketCounts {
  marketCounts: MarketCountsData;
}
