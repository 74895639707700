<div class="tw-relative tw-h-full">
  <img
    class="tw-w-full"
    [src]="img | hash"
    alt=""
  />
  <div class="stickers-list tw-flex tw-gap-2 tw-absolute">
    <app-p2p-sticker-image
      *ngFor="let sticker of stickers"
      [sticker]="sticker"
    />
  </div>
</div>
