import { Injectable } from '@angular/core';
import { PayService } from '@dev-fast/backend-services';
import { INotifyData, ModalNames, PROJECT, TransactionStatus, TransactionWay } from '@dev-fast/types';
import { Action, NgxsOnInit, Selector, State, StateContext, Store } from '@ngxs/store';
import { Socket as WrappedSocket } from 'ngx-socket-io';
import { Observable, tap } from 'rxjs';

import { AnalyticsService } from '@app/core/analytics-service';
import { EnvironmentService } from '@app/core/environment-service';
import { OpenModal } from '@app/core/state/modals';
import { UserState } from '@app/core/state/user-store';
import { addEventToGleam } from '@app/core/state/utils';

import { CheckPaymentTrust, SetTransactionWay, SuccessfulRefill } from './payment.actions';
import { PAYMENT_INITIAL_STATE, PaymentStateModel } from './payment-state.model';
import { RefillState } from './refill';
import { WithdrawalState } from './withdrawal';

@State<PaymentStateModel>({
  name: 'payment',
  defaults: PAYMENT_INITIAL_STATE,
  children: [WithdrawalState, RefillState],
})
@Injectable()
export class PaymentState implements NgxsOnInit {
  @Selector()
  static transactionWay({ transactionWay }: PaymentStateModel): TransactionWay | null {
    return transactionWay;
  }
  constructor(
    private readonly apiService: PayService,
    private readonly analytics: AnalyticsService,
    private readonly store: Store,
    private readonly environmentService: EnvironmentService,
    private readonly ws: WrappedSocket,
  ) {}

  ngxsOnInit(ctx: StateContext<any>): void {
    this.ws.on('deposit:confirmed', ({ amount }: any) => {
      ctx.dispatch(new SuccessfulRefill(amount));
    });
  }
  @Action(CheckPaymentTrust)
  checkTrust(
    { patchState }: StateContext<PaymentStateModel>,
    { userId }: CheckPaymentTrust,
  ): Observable<void | {
    status: boolean;
  }> {
    return this.apiService.checkPaymentTrust(userId).pipe(
      tap((response) =>
        patchState({
          paymentTrust: response ? response.status : true,
        }),
      ),
    );
  }
  @Action(SuccessfulRefill)
  successfulRefill({ patchState, dispatch }: StateContext<PaymentStateModel>, { amount }: SuccessfulRefill): void {
    const user = this.store.selectSnapshot(UserState.user);
    const isMarket = this.environmentService.isProject({ name: [PROJECT.MARKET, PROJECT.FAST_NEW], exclude: false });
    if (user && user.id) {
      addEventToGleam('deposit', user.id);
      this.analytics.successfulDepositEvent({ amount });
    }
    if (isMarket) {
      dispatch(
        new OpenModal(ModalNames.TRANSACTION_NOTIFICATION, {
          paymentStatus: TransactionStatus.COMPLETED,
          params: {
            amount: amount,
          },
        } as INotifyData),
      );
    }
  }
  @Action(SetTransactionWay)
  setTransactionWay({ patchState }: StateContext<PaymentStateModel>, { way }: SetTransactionWay): void {
    patchState({
      transactionWay: way,
    });
  }
}
