<div
  *ngIf="socials$ | async as socials"
  class="auth-modal__container flex fx-column relative tw-bg-gray-600 smm:tw-bg-transparent"
  [formGroup]="loginForm"
>
  <div
    class="tw-flex tw-flex-col tw-items-center smm:tw-mb-6 tw-px-8 tw-pt-8 smm:tw-pb-4 tw-rounded-2xl tw-bg-gray-600 smm:tw-shadow-modalShadow"
  >
    <mat-icon
      class="domain-logo pb-2"
      svgIcon="img-domain"
    ></mat-icon>
    <h3 class="auth-modal__header pb-4 tw-font-normal">
      {{ 'AUTHORIZATION.LOG_IN_CONTINUE' | translate }}
    </h3>
    <button
      (click)="authorize(socials[0].name)"
      class="tw-flex tw-items-center tw-justify-center tw-space-x-2 tw-rounded-xl tw-mx-auto tw-mt-0 tw-mb-7 tw-h-12 tw-w-full tw-font-medium tw-text-sm tw-outline-none"
      [ngClass]="{
        'tw-bg-gray-500 tw-text-gray-300 tw-cursor-default': loginForm.invalid,
        'tw-bg-yellow-500 tw-text-black-300 hover:tw-bg-yellow-400 hover:tw-scale-105 tw-ease-in tw-duration-200': loginForm.valid
      }"
    >
      <mat-icon
        svgIcon="steam"
        class="tw-fill-balck-300"
        [class.tw-fill-gray-300]="loginForm.invalid"
      ></mat-icon>
      <span>{{ 'AUTHORIZATION.LOGIN_WITH' | translate }} {{ socials[0].title | titlecase }}</span>
    </button>

    <ng-container *ngIf="socials.length > 1">
      <div class="tw-flex tw-items-center tw-gap-3 tw-w-full tw-text-gray-300 tw-mb-5">
        <div class="tw-text-xs tw-h-[1px] tw-bg-gray-500 tw-w-full tw-font-normal"></div>
        <span>{{ 'AUTHORIZATION.OR' | translate }}</span>
        <div class="tw-text-xs tw-h-[1px] tw-bg-gray-500 tw-w-full"></div>
      </div>
      <div class="auth-modal__body__auth-options flex gap-3">
        <div class="flex fx-wrap gap-1">
          <ng-container *ngFor="let social of socials; index as i">
            <div
              *ngIf="i !== 0"
              class="socials-btn p-1 tw-cursor-pointer"
              (click)="authorize(social.name)"
            >
              <mat-icon
                class="auth-option-icon"
                [class]="'icon-' + social.name"
                [svgIcon]="'socials-' + social.name"
                [ngClass]="{ 'tw-opacity-30': loginForm.invalid }"
              ></mat-icon>
            </div>
          </ng-container>
        </div>
      </div>
    </ng-container>
    <div class="tw-flex tw-flex-col item-start">
      <app-ui-checkbox
        formControlName="agreement"
        [checkValidation$]="checkValidation$"
        [checkboxClasses]="'auth-checkbox'"
        class="tw-mt-5 tw-pb-3 tw-pl-1"
      >
        {{ 'LOGIN_MODAL.AGE_LINE.AGE1' | translate }}
        <a routerLink="/tos">{{ 'LOGIN_MODAL.AGE_LINE.AGE2' | translate }}</a>
      </app-ui-checkbox>
      <app-ui-checkbox
        formControlName="agreementRWT"
        [checkValidation$]="checkValidation$"
        [checkboxClasses]="'auth-checkbox'"
        [innerLabels]="'LOGIN_MODAL.RWT' | translate"
        class="tw-pb-3 tw-pl-1"
      >
      </app-ui-checkbox>
      <app-ui-checkbox
        matTooltipShowDelay="300"
        [matTooltipClass]="'auth-checkbox-tooltip'"
        matTooltip="{{ 'AUTHORIZATION.ADDITION_TOOLTIP' | translate }}"
        formControlName="agreementRegion"
        [checkValidation$]="checkValidation$"
        [checkboxClasses]="'auth-checkbox'"
        [innerLabels]="'LOGIN_MODAL.REGION' | translate"
        class="tw-pb-4 tw-pl-1"
      >
      </app-ui-checkbox>
    </div>
  </div>

  <ng-container *ngIf="!attentionPanelToggle">
    <div
      class="auth-modal--attention-question pb-3 tw-font-medium tw-cursor-pointer"
      (click)="attentionPanelToggle = true"
    >
      {{ 'AUTHORIZATION.FAIL_AUT' | translate }}
    </div>
  </ng-container>

  <app-ui-auth-fail-tooltip
    *ngIf="attentionPanelToggle"
    class="auth-fail-tooltip-modal pb-4 w-100 tw-relative smm:tw-absolute tw-bottom-0 smm:tw-translate-y-full"
    [class.auth-modal-tooltip]="attentionPanelToggle"
  ></app-ui-auth-fail-tooltip>
  <div
    class="auth-modal__close-btn action-menu-btn absolute"
    (click)="close()"
  >
    <mat-icon svgIcon="close"></mat-icon>
  </div>
</div>
