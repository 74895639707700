import { GameMode } from '../enums';

export interface IGameStatistics {
  key: string;
  mode: GameMode;
  statistics: IStatisticsItem[];
}

export interface IStatisticsItem {
  isCurrency: boolean;
  isTop: boolean;
  title: string;
  key: string;
  value: string;
}

export interface IStatisticsMeta {
  key: string;
  title: string;
  value: string | number;
}

export interface IPublicGameStatistic {
  /** название игры */
  key: string;
  /** режим игры*/
  mode: GameMode;
  top: {
    /** напр. baccarat_max_bet */
    key: string;
    /** напр. Max bet */
    title: string;
    value: string;
    /** что есть value */
    isCurrency: boolean;
    /** дополнительное поле(поля) с описанием */
    metadata?: IStatisticsMeta[];
    additional?: IStatisticsMeta;
  };
}
