import { Directive, ElementRef, Input, OnChanges, Renderer2 } from '@angular/core';

import { EnvironmentService } from '@app/core/environment-service';

import { LazyLoadImageDirective } from '../lazy-load-image/lazy-load-image.directive';

@Directive({
  selector: 'img[skinItemImage]',
})
export class SkinItemImageDirective extends LazyLoadImageDirective implements OnChanges {
  @Input() public width = 330;
  @Input() public height = 192;

  constructor(el: ElementRef, private render: Renderer2, private readonly environmentService: EnvironmentService) {
    super(el, render);
  }
  public override ngOnChanges(): void {
    if (this.src) {
      if (!(this.src.startsWith('http') || this.src.startsWith('//'))) {
        this.src = `https://steamcommunity-a.akamaihd.net/economy/image/${this.src}/${this.width}x${this.height}`;
      }
    }
    this.fallback = this.environmentService.environments.CHIP_FALLBACK_URL;

    super.ngOnChanges();
  }
}
// export class SkinItemImageDirective extends LazyLoadImageDirective implements OnChanges {
//   @Input() public width = 16;
//   @Input() public height = 16;
//   private steamImgHost = 'https://steamcommunity-a.akamaihd.net/economy/image/';
//   private cashSrc: string | undefined;
//   @HostListener('load')
//   loadFull(): void {
//     const elWidth = this.el.nativeElement.width;
//     const elHeight = this.el.nativeElement.height;
//     this.srcAttr = this.cashSrc ? `${this.cashSrc}/${elWidth*2}x${elHeight*2}` : this.fallback;
//   }
//   @HostListener('window:resize', ['$event.target'])
//   onResize() {
//     // console.log(this.el.nativeElement.width);
//   }
//   constructor(
//     el: ElementRef,
//     private render: Renderer2,
//     private readonly environmentService: EnvironmentService,
//     private readonly layoutTypeService: LayoutTypeService
//   ) {
//     super(el, render);
//   }

//   public override ngOnChanges(): void {
//     if (this.src) {

//       const tmpSrc = !(this.src.startsWith('http') || this.src.startsWith('//')) ? `${this.steamImgHost}${this.src}` : `${this.src}`;
//       // if (!(this.src.startsWith('http') || this.src.startsWith('//'))) {
//       //   tmpSrc = `${this.steamImgHost}${this.src}`;
//       // }
//       // if (this.src.includes(this.steamImgHost)) {
//       //   tmpSrc= `${this.src}`;
//       // }
//       this.cashSrc = tmpSrc;
//       this.src = `${tmpSrc}/${this.width}x${this.height}`;

//     }
//     this.fallback = this.environmentService.environments.CHIP_FALLBACK_URL;

//     super.ngOnChanges();
//   }
// }
