import { AfterViewInit, ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { Subject } from 'rxjs';

import { UIConfettiSAComponent } from '../confetti/confetti.component';

@Component({
  selector: 'app-ui-badge',
  templateUrl: './badge.component.html',
  styleUrls: ['./badge.component.scss'],
  standalone: true,
  imports: [MatIconModule, UIConfettiSAComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UIBadgeSAComponent implements AfterViewInit {
  @Input() icon = 'coin_no_border';

  readonly confettiAnim$: Subject<void> = new Subject();

  ngAfterViewInit(): void {
    this.anim();
  }

  anim(): void {
    this.confettiAnim$.next();
  }
}
