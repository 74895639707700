<div *ngIf="payload && payload.rewardedCases.length">
  <div class="promo-modal">
    <div class="modal-content">
      <span
        class="close-btn"
        (click)="close()"
      >
        <mat-icon svgIcon="close"></mat-icon>
      </span>

      <div class="modal-header">
        <h2>{{ 'PROMOCODE.REF_PAID' | translate }}</h2>
      </div>
      <ng-container *ngFor="let item of payload.rewardedCases">
        <div class="modal-body">
          <div class="promo-main">
            <div class="promo-main-background"></div>
            <div class="promo-main-amount">
              <img src="/assets/img/promocode/promo-case.png" />
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            class="btn btn-primary"
            (click)="close()"
            LangRouterModule
            [routerLink]="['game/cases/case/' + item.caseId]"
          >
            {{ 'LOCAL.NY_EVENT.OPEN_CASE' | translate }}
          </button>
        </div>
      </ng-container>
    </div>
  </div>
</div>
