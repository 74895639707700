<ng-container *ngIf="activeFaq">
  <ng-scrollbar [visibility]="'hover'">
    <div class="quick-faq__items-list">
      <ng-container *ngFor="let faqItem of activeFaq.faq_items; let index = index">
        <div class="quick-faq__item">
          <div class="quick-faq__item-title">
            {{ faqItem.item_title }}
            <ng-container *ngIf="showClose && index === 0">
              <button
                class="btn btn-sm sq btn-round btn-transparent-dark--design"
                (click)="clickClose()"
              >
                <mat-icon [svgIcon]="'close'"></mat-icon>
              </button>
            </ng-container>
          </div>
          <div
            class="quick-faq__item-content"
            [innerHTML]="faqItem.item_text"
          ></div>
        </div>
      </ng-container>
    </div>
  </ng-scrollbar>
</ng-container>
