import { Injectable } from '@angular/core';
import { IProvablyFair, IProvablyFairHistory, IProvablyFairSettings } from '@dev-fast/types';
import { Observable } from 'rxjs';

import { Environments, EnvironmentService } from '@app/core/environment-service';

import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class ProvablyFairApiService {
  private environment: Environments;

  constructor(private readonly api: ApiService, private readonly environmentService: EnvironmentService) {
    this.environment = this.environmentService.environments;
  }
  // FIXME типы
  public requestClientSeedChange(seed: string): Observable<any> {
    return this.api.post<any>(`${this.environment.GATEWAY_URL}/api/provably-fair/seed`, { seed: seed }, { withCredentials: true });
  }
  // FIXME типы
  public serverSeedRevealChange(): Observable<any> {
    return this.api.post<any>(`${this.environment.GATEWAY_URL}/api/provably-fair/reveal`, {}, { withCredentials: true });
  }
  public rollCheckRequest(id: string): Observable<IProvablyFair> {
    return this.api.get<IProvablyFair>(`${this.environment.GATEWAY_URL}/api/provably-fair/check-roll/${`${encodeURIComponent(id)}`}`);
  }
  // FIXME типы
  public provablyFairInfoRequest(): Observable<IProvablyFairSettings> {
    return this.api.get<any>(`${this.environment.GATEWAY_URL}/api/provably-fair/seeds`, { withCredentials: true });
  }
  // FIXME типы
  public seedsHistoryRequest(): Observable<IProvablyFairHistory> {
    return this.api.get<any>(`${this.environment.GATEWAY_URL}/api/provably-fair/seeds/history`, {
      withCredentials: true,
    });
  }
}
