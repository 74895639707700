<div class="game-type-item">
  <a
    langRouterLink
    [routerLink]="'/game/' + game.key"
    [routerLinkActive]="'active'"
    class="game-type-item__link"
    (click)="proceedPanelStatus()"
    [class.game-type-item__link__table-view]="view === devices.TABLET"
  >
    <mat-icon
      class="game-type-item__icon"
      [svgIcon]="'logo-v2-' + game.key"
    ></mat-icon>
    <span
      class="subheader"
      *ngIf="view === devices.TABLET"
      >{{ game.title }}</span
    >
  </a>
  <div
    class="game-type-item__title"
    *ngIf="view === 'desktop'"
  >
    <div class="game-type-item__title-header">{{ game.title }}</div>
    <span
      class="game-type-item__title-subheader subheader"
      *ngIf="status"
    >
      <span class="subheader-item__subtitle">{{ status.title }}</span>
      <ng-container *ngIf="!status.notCurrencyValue; else notCurrencyTemplate">
        <span class="subheader-item__value">{{ status.value | appCurrency }}</span>
      </ng-container>
      <ng-template #notCurrencyTemplate>
        <span class="subheader-item__value">{{ status.value }}</span>
      </ng-template>
    </span>
  </div>
</div>
