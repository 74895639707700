import { DOCUMENT } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { INotification, NotificationType } from '@dev-fast/types';
import { Observable } from 'rxjs';

import { getHiddenKey } from '@app/shared/utils';

import { ToastService } from '../toast.service';
import { notificationSlideLeftAnimation } from './notification.animation';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
  animations: [notificationSlideLeftAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationComponent {
  notifications$: Observable<INotification[]> = this.notificationService.showcaseNotifications$;

  constructor(
    public notificationService: ToastService,
    @Inject(DOCUMENT) private document: Document & { msHidden?: boolean; webkitHidden?: boolean },
  ) {
    const { visibilityChange } = getHiddenKey(this.document);
    this.document.addEventListener(visibilityChange, () => this.handleVisibilityChange(), false);
  }

  handleVisibilityChange(): void {
    const { hidden } = getHiddenKey(this.document);
    if (this.document[hidden as keyof Document]) {
      this.pauseNotifications();
    } else {
      this.resumeNotifications();
    }
  }

  deleteNotification(notification: INotification): void {
    this.notificationService.deleteNotification(notification.id);
  }

  pauseNotifications(): void {
    this.notificationService.pauseTimer();
  }

  resumeNotifications(): void {
    this.notificationService.resumeTimer();
  }

  trackByIndex(index: number, element: INotification): number {
    return element.id;
  }

  getIcon(type: NotificationType): string {
    const normType = type !== NotificationType.Info && type !== NotificationType.Success ? NotificationType.Info : type;
    return `notification-new-${normType}`;
  }
}
