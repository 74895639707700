<div
  class="winner-banner__container"
  [@winnerAnimation]
  *ngIf="winnerBannerInfo"
>
  <div class="winner-banner__confetti">
    <img
      src="/assets/img/left-sidebar/confetti_1.svg"
      class="winner-banner__confetti__1"
    /><img
      src="/assets/img/left-sidebar/confetti_2.svg"
      class="winner-banner__confetti__2"
    /><img
      src="/assets/img/left-sidebar/confetti_3.svg"
      class="winner-banner__confetti__3"
    /><img
      src="/assets/img/left-sidebar/confetti_4.svg"
      class="winner-banner__confetti__4"
    /><img
      src="/assets/img/left-sidebar/confetti_5.svg"
      class="winner-banner__confetti__5"
    /><img
      src="/assets/img/left-sidebar/confetti_6.svg"
      class="winner-banner__confetti__6"
    /><img
      src="/assets/img/left-sidebar/confetti_7.svg"
      class="winner-banner__confetti__7"
    />
  </div>
  <div class="winner-banner__body">
    <app-ui-user-avatar class="winner-banner__avatar"></app-ui-user-avatar>
    <div class="winner-banner__text">
      <div class="winner-banner__text-title">won</div>
      <div class="winner-banner__text-gain">{{ winnerBannerInfo.profit | appCurrency }}</div>
    </div>
  </div>
</div>
<!-- <button (click)="testAnim()">{{ animTrigger }}</button> -->
