<ng-container *ngIf="drop">
  <div
    class="drop-item"
    [style]="getStyle(drop)"
    [class]="className"
  >
    <img
      class="item-image"
      skinItemImage
      [src]="drop.caseRevisionItem.inventoryItem.baseItem.icon"
      alt=""
    />
    <div class="stats-contaiter">
      <div class="stats-items">
        <div class="stats-item-name">
          <ng-container *ngIf="drop.caseRevisionItem.inventoryItem.baseItem.statTrak"> ★ </ng-container>
          {{ drop.caseRevisionItem.inventoryItem.baseItem.shortName }}
        </div>
        <div class="stats-skin-name">{{ drop.caseRevisionItem.inventoryItem.baseItem.skin }}</div>
      </div>
      <div class="price-item">
        {{ drop.caseRevisionItem.inventoryItem.price | appCurrency }}
      </div>
    </div>
    <ng-container *ngIf="!!drop.user">
      <div class="user-drop-info">
        <a
          class="case-container"
          langRouterLink
          [routerLink]="['/game/cases/case', drop.case.id]"
          [style]="getBackgroundImage(drop)"
        >
        </a>
        <a
          class="replay-container"
          [routerLink]="replayLink"
          [queryParams]="{ rollId: drop.rollId, openUUID: drop.isBattle ? null : drop.openUUID }"
        >
          <mat-icon
            class="arrow"
            [svgIcon]="'play'"
          ></mat-icon>
          <span *ngIf="isFullView">
            {{ 'LOCAL.CASES.BATTLE_GAME.CREATE_GAME.WATCH' | translate }}
          </span>
        </a>
        <div class="skin-container">
          <div
            class="skin-avatar-container"
            [ngClass]="{ minimal: isBoxFocused }"
            (mouseenter)="onElementHoverEnter('player')"
            (mouseleave)="onElementHoverLeave()"
          >
            <img
              class="item-avatar"
              skinItemImage
              [src]="drop.caseRevisionItem.inventoryItem.baseItem.icon"
              (click)="navigateToCaseItem(drop.caseRevisionItem)"
              alt=""
            />
          </div>
          <div class="user-info-container">
            <app-ui-user-avatar
              [url]="drop.user.avatar"
              [userId]="drop.user.id"
              [type]="drop.user.profileType"
              [noChevron]="true"
              [username]="{ name: drop.user.name, position: 'right' }"
              [ngClass]="{ minimal: isPlayerFocused }"
              (mouseenter)="onElementHoverEnter('box')"
              (mouseleave)="onElementHoverLeave()"
            >
            </app-ui-user-avatar>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</ng-container>
