export enum AgentKeys {
  FAUCET = 'faucet',
  MATCH_BETS = 'match-bets',
  PAYOUT = 'payout',
  REFERRAL = 'referral',
  LEADERS_RACE = 'leaders-race',
  INVENTORY = 'inventory',
  DOUBLE_GAME = 'double-game-new',
  X50_GAME = 'x50-game-new',
  CLASSIC_GAME = 'classic-game',
  CASES_GAME = 'cases',
  SLOTS_GAME = 'slots',
  HILO_GAME = 'hilo-game',
  WHEEL_GAME = 'wheel',
  TOWER_GAME = 'tower',
  CASES_QUEST = 'cases-quest',
  TOP_GAMES = 'topGames',
  // TODO: уточняются у бэка
  FAST_GAME = 'classic-game',
  BACCARAT_GAME = 'baccarat-game',
  CRASH_GAME = 'crash-game',
  POGGI_GAME = 'poggy-game',
  CASE_BATTLE = 'case-battle',
}
