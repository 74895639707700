import { IDropItemDto } from '../entities';

export type IBaseWidgetBlock<T = Record<string, unknown>> = T & {
  name: string;
  header: string;

  id: number;
  avaLink: string;
  height?: string;
  replayDrop?: IDropItemDto;
  payload: {
    label: string;
    value: string | number;
    valueType: 'currency' | 'number' | 'text' | 'percent';
  }[];
};

/** Shared activities */
export interface IActivities {
  playerOfTheDay?: IBaseWidgetBlock;
  luckOfTheDay?: IBaseWidgetBlock;
  lastWinner?: IBaseWidgetBlock;
  biggestBet?: IBaseWidgetBlock;
}
