import { IconConfig } from '@dev-fast/types';

export const iconsGames: IconConfig[] = [
  {
    name: 'classic-logo',
    path: 'assets/img/mat-icons/games/classic-logo.svg',
  },
  {
    name: 'fast-logo',
    path: 'assets/img/mat-icons/games/fast-logo.svg',
  },
  {
    name: 'double-logo',
    path: 'assets/img/mat-icons/games/double-logo.svg',
  },
  {
    name: 'bets-logo',
    path: 'assets/img/mat-icons/games/bets-logo.svg',
  },
  {
    name: 'hilo-logo',
    path: 'assets/img/mat-icons/games/hilo-logo.svg',
  },
  {
    name: 'crash-logo',
    path: 'assets/img/mat-icons/games/crash-logo.svg',
  },
  {
    name: 'tower-logo',
    path: 'assets/img/mat-icons/games/tower-logo.svg',
  },
  {
    name: 'contract',
    path: 'assets/img/mat-icons/games/contract.svg',
  },
  {
    name: 'icon-baccara-mini',
    path: 'assets/img/mat-icons/games/icon-baccara-mini.svg',
  },
  {
    name: 'icon-cases-mini',
    path: 'assets/img/mat-icons/games/icon-cases-mini.svg',
  },
  {
    name: 'icon-classic-mini',
    path: 'assets/img/mat-icons/games/icon-classic-mini.svg',
  },
  {
    name: 'icon-craft-mini',
    path: 'assets/img/mat-icons/games/icon-craft-mini.svg',
  },
  {
    name: 'icon-crash-mini',
    path: 'assets/img/mat-icons/games/icon-crash-mini.svg',
  },
  {
    name: 'icon-double-mini',
    path: 'assets/img/mat-icons/games/icon-double-mini.svg',
  },
  {
    name: 'icon-esports-bets-mini',
    path: 'assets/img/mat-icons/games/icon-esports-bets-mini.svg',
  },
  {
    name: 'icon-fast-mini',
    path: 'assets/img/mat-icons/games/icon-fast-mini.svg',
  },
  {
    name: 'icon-fortune-wheel-mini',
    path: 'assets/img/mat-icons/games/icon-fortune-wheel-mini.svg',
  },
  {
    name: 'icon-hilo-mini',
    path: 'assets/img/mat-icons/games/icon-hilo-mini.svg',
  },
  {
    name: 'icon-partner-slot-mini',
    path: 'assets/img/mat-icons/games/icon-partner-slot-mini.svg',
  },
  {
    name: 'icon-slot-mini',
    path: 'assets/img/mat-icons/games/icon-slot-mini.svg',
  },
  {
    name: 'icon-tower-mini',
    path: 'assets/img/mat-icons/games/icon-tower-mini.svg',
  },
  {
    name: 'icon-x50-mini',
    path: 'assets/img/mat-icons/games/icon-x50-mini.svg',
  },
  {
    name: 'fast',
    path: '/assets/img/lobby/games/fast.svg',
  },
  {
    name: 'classic',
    path: '/assets/img/lobby/games/classic.svg',
  },
  {
    name: 'double',
    path: '/assets/img/lobby/games/double.svg',
  },
  {
    name: 'hilo',
    path: '/assets/img/lobby/games/hilo.svg',
  },
  {
    name: 'crash',
    path: '/assets/img/lobby/games/crash.svg',
  },
  {
    name: 'slots',
    path: '/assets/img/lobby/games/slots.svg',
  },
  {
    name: 'tower',
    path: '/assets/img/lobby/games/tower.svg',
  },
  {
    name: 'cases',
    path: '/assets/img/lobby/games/cases.svg',
  },
  {
    name: 'wheel',
    path: '/assets/img/lobby/games/wheel.svg',
  },
  {
    name: 'baccarat',
    path: '/assets/img/lobby/games/baccarat.svg',
  },
  {
    name: 'poggi',
    path: '/assets/img/lobby/games/poggi.svg',
  },
  {
    name: 'x50',
    path: '/assets/img/lobby/games/x50.svg',
  },
  {
    name: 'caseBattle',
    path: '/assets/img/lobby/games/case-battle.svg',
  },
];
