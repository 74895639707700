import { IconConfig } from '@dev-fast/types';

export const iconsLogos: IconConfig[] = [
  {
    name: 'text-domain',
    path: 'assets/img/mat-icons/logos/domain/text_csmarket_logo.svg',
  },
  {
    name: 'csmarket-domain',
    path: 'assets/img/mat-icons/logos/domain/text_csmarket_logo.svg',
  },
  {
    name: 'img-domain',
    path: 'assets/img/mat-icons/logos/mirror/img_csmarket_logo.svg',
  },
  {
    name: 'CSMARKET-mirror',
    path: 'assets/img/mat-icons/logos/mirror/img_csmarket_logo.svg',
  },
  {
    name: 'currency',
    path: 'assets/img/mat-icons/logos/mirror/currency.svg',
  },

  {
    name: 'halloween',
    path: 'assets/img/mat-icons/logos/mirror/halloween.svg',
  },
  {
    name: 'NY',
    path: 'assets/img/mat-icons/logos/mirror/NY.svg',
  },
  {
    name: 'vgo',
    path: 'assets/img/mat-icons/logos/mirror/vgo.svg',
  },
];
