<div class="lobby-tooltip flex fx-column tw-bg-gray-600">
  <div class="lobby-tooltip--label">
    <mat-icon
      svgIcon="info"
      alt=""
    ></mat-icon>
    <div>
      {{ 'AUTHORIZATION.IF_FAIL' | translate }}
    </div>
  </div>
  <div class="lobby-tooltip--text tw-text-gray-400">
    <div>
      {{ 'AUTHORIZATION.WARNING' | translate }}
    </div>
    <div>
      {{ 'AUTHORIZATION.WARNING_PT2' | translate }}
      <a href="mailto:{{ email }}">{{ email }}</a>
    </div>
  </div>
</div>
