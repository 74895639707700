import { IconConfig } from '@dev-fast/types';

export const iconsMenu: IconConfig[] = [
  {
    name: 'games-menu',
    path: 'assets/img/mat-icons/menu/game-menu.svg',
  },
  {
    name: 'quest-menu',
    path: 'assets/img/left-sidebar/quest.svg',
  },
  {
    name: 'promocode-menu',
    path: 'assets/img/left-sidebar/promocode.svg',
  },
];
