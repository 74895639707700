<ng-container *ngIf="link.length; else notLink">
  <div
    class="event--btn"
    [class]="className"
    [routerLinkActive]="'active-btn'"
    [class.ny-btn-decal]="eventName === 'ny'"
    [class.active-btn]="isActive"
    [routerLink]="link"
  >
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </div>
</ng-container>
<ng-template #notLink>
  <button
    class="event--btn"
    [class]="className"
    [class.ny-btn-decal]="eventName === 'ny'"
    [class.active-btn]="isActive"
  >
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </button>
</ng-template>

<ng-template #content>
  <img
    [class]="'snow-' + snowType"
    [src]="'/assets/img/ny-event/snow/snow' + snowType + '.png'"
    *ngIf="eventName === 'ny' && snowType"
    alt=""
  />
  <span>
    {{ text | translate }}
  </span>
  <ng-content> </ng-content>
</ng-template>
