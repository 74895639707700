import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, NgModule, Output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { Router, RouterModule } from '@angular/router';
import { ICaseItemDtoV2, ICaseRevisionInfo, ICaseType } from '@dev-fast/types';
import { TranslateModule } from '@ngx-translate/core';
import { NgScrollbarModule } from 'ngx-scrollbar';

import { LanguageService } from '@app/core/language-service';
import { LangRouterModule } from '@app/shared/directives';
import { AppCurrencyModule, AppPercentModule } from '@app/shared/pipes';

import { IncreaseModule } from '../../increase/increase.component';
import { CaseCardComponentModule } from '../cards';

@Component({
  selector: 'app-ui-case-full-info',
  templateUrl: './case-full-info.component.html',
  styleUrls: ['./case-full-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CaseFullInfoComponent {
  @Input() selectedCases: ICaseItemDtoV2[] | null = null;
  @Input() caseItem: ICaseItemDtoV2 | undefined;
  @Input() showSelectRevision = true;
  @Input() isTable = true;
  @Input() set caseRevision(value: ICaseRevisionInfo | undefined) {
    this.activeRevision = value;
    if (this.showSelectRevision && value) {
      this.revisionsListConstructor(value);
      this.selectedRevision = value.revision.id;
    }
  }

  @Output() onaddCase: EventEmitter<ICaseItemDtoV2> = new EventEmitter();
  @Output() onselectRevision: EventEmitter<number> = new EventEmitter();
  @Output() oncloseModal: EventEmitter<void> = new EventEmitter();
  @Output() ontoggleView: EventEmitter<void> = new EventEmitter();

  caseTypeEnum: typeof ICaseType = ICaseType;
  revisionsList: { value: number; viewValue: number }[] | undefined;
  activeRevision: ICaseRevisionInfo | undefined;
  selectedRevision: number | undefined;
  isOpenMenu = false;

  constructor(
    private readonly router: Router,
    private readonly languageService: LanguageService,
  ) {}

  private revisionsListConstructor(caseRevision: ICaseRevisionInfo): void {
    const revisionsList: { value: number; viewValue: number }[] = [];
    caseRevision.revisionIds.forEach((revisionId: number, index: number) => {
      const revision = { value: index, viewValue: revisionId };
      revisionsList.push(revision);
    });
    this.revisionsList = revisionsList;
  }
  addCase(caseItem: ICaseItemDtoV2): void {
    this.onaddCase.emit(caseItem);
    if (LanguageService.getBaseUrl(this.router.url) !== '/game/case-battle') {
      this.router.navigate([this.languageService.getCurrentLangUrl('/game/case-battle')]);
    }
  }
  getCaseRevision(range: number): void {
    this.onselectRevision.emit(range);
  }
  menuToggle(value: boolean): void {
    this.isOpenMenu = value;
  }
  closeModal(): void {
    this.oncloseModal.emit();
  }
  toggleListView(): void {
    this.ontoggleView.emit();
  }
}
@NgModule({
  imports: [
    CommonModule,
    MatIconModule,
    MatMenuModule,
    AppCurrencyModule,
    AppPercentModule,
    TranslateModule,
    IncreaseModule,
    RouterModule,
    CaseCardComponentModule,
    NgScrollbarModule,
    LangRouterModule,
  ],
  declarations: [CaseFullInfoComponent],
  exports: [CaseFullInfoComponent],
})
export class CaseFullInfoModule {}
