export const SIH_STAT_PERIODS = [
  {
    value: 7,
    amount: 7,
    title: 'P2P_WIDGETS.RENEW_INFO_MODAL.SIH_D',
  },
  {
    value: 14,
    amount: 2,
    title: 'P2P_WIDGETS.RENEW_INFO_MODAL.SIH_W',
  },
  {
    value: 21,
    amount: 3,
    title: 'P2P_WIDGETS.RENEW_INFO_MODAL.SIH_W',
  },
  {
    value: 30,
    amount: 1,
    title: 'P2P_WIDGETS.RENEW_INFO_MODAL.SIH_M',
  },
];
