export interface MenuLink {
  path: string;
  label: string;
  authRequired: boolean;
}
export interface ILink {
  id?: string;
  path: string;
  label: string;
  key: string;
  icon?: string;
}
