import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ModuleIsOnDirective } from './module-is-on.directive';

@NgModule({
  declarations: [ModuleIsOnDirective],
  imports: [CommonModule],
  exports: [ModuleIsOnDirective],
})
export class ModuleIsOnModule {}
