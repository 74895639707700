<button
  class="btn btn-md sq btn-round btn-transparent-dark--design notifications--btn"
  [disabled]="showNotificationsPanel"
  (click)="openMenu()"
  [@notificationAnimation]="countNewNotifications()"
>
  <mat-icon
    class="notifications--icon-bell"
    svgIcon="bell-bold-design"
  >
    <ng-container *ngIf="countNewNotifications()">
      <div class="notifications--icon-status"></div>
    </ng-container>
  </mat-icon>
</button>
<ng-container *ngIf="showNotificationsPanel">
  <div
    class="notifications--list p-2 flex fx-column fx-justify-between"
    inViewport
    [inViewportOptions]="{ threshold: [0] }"
    (inViewportAction)="closeMenu($event)"
    (clickOutside)="onOutside()"
    @replacementScaleAnimation
  >
    <div class="flex fx-align-center fx-justify-between pb-2">
      <div class="header-text tw-font-bold">{{ 'NOTIFICATIONS.HEADER' | translate | titlecase }}</div>
      <button
        class="btn btn-sm sq btn-round btn-transparent-dark--design close-btn"
        (click)="closeMenu()"
      >
        <mat-icon
          class="header-icon"
          svgIcon="close"
        ></mat-icon>
      </button>
    </div>
    <ng-container *ngIf="notificationList() as notifications">
      <ng-container *ngIf="notifications.length; else noNotysTemplate">
        <ng-scrollbar>
          <div class="flex fx-column gap-2 w-100">
            <div
              class="notification-item flex"
              *ngFor="let notification of notifications; trackBy: trackingFunction"
              [class.new-noty]="notification.status === NotificationStatusEnum.new"
              (mouseenter)="onNotificationItemHover(notification)"
            >
              <div
                class="notification-item--status flex fx-align-center fx-justify-center gap-1 w-100"
                [ngClass]="notification.type"
              >
                <ng-container [ngSwitch]="notification.type">
                  <ng-container *ngSwitchCase="NotificationTypeEnum.Success">
                    <mat-icon svgIcon="notification-success-outline"></mat-icon>
                  </ng-container>
                  <ng-container *ngSwitchCase="NotificationTypeEnum.Error">
                    <mat-icon svgIcon="notification-new-error"></mat-icon>
                  </ng-container>
                  <ng-container *ngSwitchCase="NotificationTypeEnum.Info">
                    <mat-icon svgIcon="notification-attention"></mat-icon>
                  </ng-container>
                  <ng-container *ngSwitchDefault>
                    <mat-icon svgIcon="notification-attention"></mat-icon>
                  </ng-container>
                </ng-container>
              </div>
              <div class="notification-item--content flex fx-column fx-justify-center w-100">
                <div class="notification flex fx-column fx-justify-center">
                  <div
                    class="tw-font-medium"
                    *ngIf="notification.category"
                  >
                    {{ notification.category | titlecase }}
                  </div>
                  <div
                    class="notification--body tw-font-normal"
                    *ngIf="notification.message"
                  >
                    <ng-container *ngIf="notification.legacy; else notLegacyTemplate">
                      {{ notification.message }}
                    </ng-container>
                    <ng-template #notLegacyTemplate>
                      {{ notification.message | appCurrencyTranslate: notification.params }}
                    </ng-template>
                  </div>
                </div>
                <div class="notification-item--content-timer tw-font-light">{{ getDate(notification.createDate) }}</div>
              </div>
            </div>
          </div>
        </ng-scrollbar>
        <button
          class="btn btn-sm btn-semi-round btn-transparent-dark--design tw-font-normal mt-2"
          (click)="watchAll()"
        >
          {{ 'INVENTORY_PANEL.CLEAR_ALL' | translate }}
        </button>
      </ng-container>
    </ng-container>
    <ng-template #noNotysTemplate>
      <app-ui-not-found-stub
        image="/assets/img/not-found-ufo.png"
        message="NOTIFICATIONS.EMPTY"
      ></app-ui-not-found-stub>
    </ng-template>
  </div>
</ng-container>
