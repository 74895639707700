import { DOCUMENT } from '@angular/common';
import { inject, Injectable } from '@angular/core';
import { AnalyticsApiService } from '@dev-fast/backend-services';
import { Affise, RefillSystems } from '@dev-fast/types';
import { Observable } from 'rxjs';

import { Environments, EnvironmentService } from '@app/core/environment-service';

declare global {
  interface Window {
    dataLayer: any[];
  }
}

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  readonly #window: Window | null;
  #environment: Environments;

  readonly #document: Document = inject(DOCUMENT);
  readonly #environmentService = inject(EnvironmentService);
  readonly #api = inject(AnalyticsApiService);

  constructor() {
    this.#environment = this.#environmentService.environments;
    this.#window = this.#document.defaultView;
    if (this.#window) {
      this.#window.dataLayer = this.#window.dataLayer || [];
    }
  }

  registrationEvent(provider?: string): void {
    if (!this.#window || !this.#environment.production) {
      return;
    }
    this.#window.dataLayer.push({
      event: 'event-signup',
      // loginMethod: provider,
      Category: 'signup',
      Action: 'Success',
      Lable: 'signup',
    });
  }

  successfulDepositEvent(payment: { userId?: number; amount: number }): void {
    if (!this.#window || !this.#environment.production) {
      return;
    }
    this.#window.dataLayer.push({
      event: 'event-deposit',
      Category: 'deposit',
      Action: 'Success',
      Lable: 'deposit',
      Amount: payment.amount,
    });
  }

  depositEvent(payment: { payment: RefillSystems; amount: number }): void {
    if (!this.#window || !this.#environment.production) {
      return;
    }
    this.#window.dataLayer.push({
      event: 'event-deposit',
      Category: 'deposit',
      Action: 'begin',
      Lable: payment.payment,
      Amount: payment.amount / 100,
    });
  }

  betEvent(gameName: string): void {
    if (!this.#window || !this.#environment.production) {
      return;
    }
    this.#window.dataLayer.push({
      event: `event-${gameName}`,
      Category: 'Bet',
      Action: 'Success',
      Lable: gameName,
    });
  }

  caseAutoOpenEvent(lable: number | string): void {
    if (!this.#window || !this.#environment.production) {
      // eslint-disable-next-line no-console
      console.log(`'case_auto_open': ${lable}`);
      return;
    }
    this.#window.dataLayer.push({
      event: 'case_auto_open',
      Action: 'Success',
      Lable: lable,
    });
  }

  addUserIdEvent(userid: number): void {
    if (!this.#window || !this.#environment.production) {
      return;
    }
    if (this.#window.dataLayer.find((val) => val.userID)) {
      return;
    }

    this.#window.dataLayer.push({
      userID: userid,
    });
  }

  /** FIXME нельзя вроде так делать */
  resetDataLayer(): void {
    if (!this.#window || !this.#environment.production) {
      return;
    }
    this.#window.dataLayer = [];
  }

  affiseEvent(payload: Affise): Observable<void> {
    return this.#api.affisePost(payload);
  }
}
