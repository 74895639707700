import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { QRCodeType, QRCorrectionLevel } from '@dev-fast/types';
import { QRCodeModule } from 'angularx-qrcode';

@Component({
  selector: 'app-ui-qr-code',
  standalone: true,
  imports: [CommonModule, QRCodeModule],
  templateUrl: './qr-code.component.html',
  styleUrls: ['./qr-code.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QRCodeSAComponent {
  @Input() codeValue: string | undefined;
  @Input() qualityLevel: QRCorrectionLevel = QRCorrectionLevel.Q;
  @Input() codeType: QRCodeType = QRCodeType.SVG;
  @Input() codeMargin = 0;
  @Input() disabled = false;

  readonly breakSize: number = '100%' as unknown as number; // нужно, чтобы принудительно задать размер родительского контейнера для svg
  readonly color = '#000000ff';
  readonly backgroundColor = '#ffffffff';
}
