import { GameMode } from '../enums';

export const GAME_MODES: Record<GameMode, { title: string; description: string }> = {
  [GameMode.PVE]: {
    title: 'PVE games',
    description: 'Players vs Environment',
  },
  [GameMode.PVP]: {
    title: 'PVP games',
    description: 'Players vs Players',
  },
  [GameMode.SOLOPVE]: {
    title: 'Solo PVE',
    description: 'One Player vs Environment',
  },
};
