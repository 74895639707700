import { IDropItemDto, IMotivatorDto } from '@dev-fast/types';

export class GetStat {
  static readonly type = '[Motivator] Get stat';
}
export class AddLastWon {
  static readonly type = '[Motivator] Add Last Won';
  constructor(public motivatorDto: IMotivatorDto<IDropItemDto>) {}
}
export class ClearCasesMotivator {
  static readonly type = '[Motivator] ClearCasesMotivator';
}
// Получение мотиватора по текущему кейсу
export class GetCurrentCaseMotivator {
  static readonly type = '[Motivator] get current case motivator';
  constructor(public caseId: number) {}
}
// Добавление предмета в мотиватор
export class UpdateCurrentCaseMotivator {
  static readonly type = '[Motivator] update current case motivator';
  constructor(public statistic: IDropItemDto) {}
}
export class ToggleDropList {
  static readonly type = '[Motivator] Toggle list playback';
  constructor(public isPlayback: boolean) {}
}
