import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ILoginForm, SocialItem, SocialType } from '@dev-fast/types';
import { Observable, Subject } from 'rxjs';

import { AuthService } from '../../auth.service';

@Component({
  selector: 'fast-auth-modal-v2',
  templateUrl: './auth-modal.component.html',
  styleUrls: ['./auth-modal.component.scss', './auth-modal.component.media.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AuthModalV2Component {
  @Input() showAttention = true;

  #authService = inject(AuthService);

  checkValidation$: Subject<void> = new Subject();
  loginAttempt = false;
  socials$: Observable<SocialItem[]> = this.#authService.socials$;

  attentionPanelToggle = false;

  loginForm: FormGroup<ILoginForm> = new FormGroup<ILoginForm>({
    agreement: new FormControl(true, {
      nonNullable: true,
      validators: Validators.requiredTrue,
    }),
    agreementRegion: new FormControl(true, {
      nonNullable: true,
      validators: Validators.requiredTrue,
    }),
    agreementRWT: new FormControl(true, {
      nonNullable: true,
      validators: Validators.requiredTrue,
    }),
  });

  authorize(provider: SocialType): void {
    this.loginAttempt = true;
    const allCheckboxesChecked =
      this.loginForm.controls.agreement.valid &&
      this.loginForm.controls.agreementRegion.valid &&
      this.loginForm.controls.agreementRWT.valid;

    if (allCheckboxesChecked) {
      this.#authService.onLogin(provider);
    } else {
      this.checkValidation$.next();
    }
  }
  close(): void {
    this.#authService.closeAuthModal();
  }
}
