import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  NgModule,
  OnDestroy,
  Output,
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { SkinClickEvent } from '@dev-fast/types';
import { BehaviorSubject, fromEvent, merge, NEVER, Observable, of, Subject, timer } from 'rxjs';
import { delay, map, startWith, switchMap, takeUntil, takeWhile, tap } from 'rxjs/operators';

import { LazyLoadImageModule, SkinItemImageModule } from '@app/shared/directives';
import { AppCurrencyModule } from '@app/shared/pipes';

import { SkinItemTooltipModule } from '../tooltips/skin-item-tooltip/skin-item-tooltip.module';

@Component({
  selector: 'app-ui-skin-item',
  templateUrl: './skin-item.component.html',
  styleUrls: ['./skin-item.component.scss'],
})
export class SkinItemComponent implements AfterViewInit, OnDestroy {
  @Input() public icon: string;
  @Input() public name: string;
  @Input() public price?: number;
  @Input() public color: string;
  @Input() public backgroundColor: string | undefined;
  @Input() public id: number | null;
  @Input() public appId: number | null;
  @Input() public hoverBehavior: string;
  @Input() public set delay(value: number | null) {
    if (value) {
      this.time = value;
      this.setupDelay();
    }
  }

  @Output() public skinClick: EventEmitter<SkinClickEvent> = new EventEmitter<SkinClickEvent>();
  @Output() public confirmed: EventEmitter<void>;

  private timeout: number;
  private readonly destroyed$: Subject<void>;
  public popupFix?: boolean;
  public showPopup?: boolean;
  private time: number;
  private countdown: BehaviorSubject<boolean>;
  public countdown$: Observable<boolean>;
  public seconds$: Observable<number>;
  private ms$: Observable<number>;
  private remainingSeconds$: Observable<number>;
  private readonly interval = 1000;

  @HostBinding(`style.--rarity-color`)
  public get rarity(): string {
    return this.color;
  }
  @HostBinding(`style.--background-color`)
  public get backColor(): string {
    return this.backgroundColor || 'var(--color-theme-600)';
  }

  @HostListener('click', ['$event.target'])
  public onClick(): void {
    this.skinClick.emit({
      icon: this.icon,
      name: this.name,
      price: this.price,
      color: this.color,
      id: this.id,
      appId: this.appId,
    });
  }
  public isPopupFullname(): boolean {
    return !!this.name && this.hoverBehavior !== 'highlight';
  }
  constructor(private elementRef: ElementRef) {
    this.color = '#ffffff';
    this.name = '';
    this.price = 0;
    this.icon = '//d2lomvz2jrw9ac.cloudfront.net/common/currency/money-bag-pngrepo-com.png';
    this.id = null;
    this.appId = null;
    this.hoverBehavior = 'none';
    this.timeout = 0;
    this.time = 0;
    this.destroyed$ = new Subject();
    this.countdown = new BehaviorSubject<boolean>(false);
    this.countdown$ = this.countdown.asObservable();
    this.confirmed = new EventEmitter<void>();

    this.remainingSeconds$ = this.countdown$.pipe(
      switchMap((running: boolean) => (running ? timer(0, this.interval) : NEVER)),
      map((t: number) => this.remainingSeconds(t)),
      takeWhile((time: number) => time >= 0)
    );
    this.remainingSeconds$.pipe(takeUntil(this.destroyed$)).subscribe({
      complete: () => {
        this.confirmed.emit();
        this.countdown.next(false);
      },
    });

    this.ms$ = this.remainingSeconds$.pipe(
      tap((t) => (this.timeout = t)),
      map((t: number) => this.toMs(t))
    );

    this.seconds$ = this.ms$.pipe(
      map((t: number) => this.toSeconds(t)),
      startWith(this.toSeconds(this.time))
    );
  }

  public ngAfterViewInit(): void {
    if (this.hoverBehavior === 'popup') {
      this.popupWatcher();
    }
  }

  public popupWatcher(): void {
    const enter = fromEvent(this.elementRef.nativeElement, 'mouseenter').pipe(
      takeUntil(this.destroyed$),
      map(() => true)
    );
    const leave = fromEvent(this.elementRef.nativeElement, 'mouseleave').pipe(
      takeUntil(this.destroyed$),
      map(() => false)
    );
    merge(enter, leave)
      .pipe(
        takeUntil(this.destroyed$),
        switchMap((hovered: boolean) => (hovered ? of(true).pipe(delay(700)) : of(false)))
      )
      .subscribe((hovered: boolean) => {
        this.showPopup = hovered;
        if (hovered) {
          setTimeout(() => this.popupValidator(), 1);
        }
      });
  }

  public popupValidator(): void {
    const element = this.elementRef.nativeElement;
    const parentRect = element.getBoundingClientRect();
    const popup = this.elementRef.nativeElement.querySelector('.skin-item-popup');
    if (popup) {
      popup.style.top = `${parentRect.top}px`;
      popup.style.left = `calc(${parentRect.left}px)`;

      const { x, y } = popup.getBoundingClientRect();
      const outOfScreen = x < 0 || y < 0 || x > window.innerWidth || y > window.innerHeight;
      if (outOfScreen) {
        this.popupFix = true;
      }
    }
  }

  public ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
  private setupDelay(): void {
    this.countdown.next(true);
  }

  private currentSeconds(): number {
    return this.time / this.interval;
  }

  private remainingSeconds(t: number): number {
    return this.currentSeconds() - t;
  }
  private toMs(t: number): number {
    return t * this.interval;
  }

  private toSeconds(ms: number): number {
    return Math.floor((ms / this.interval) % 60);
  }
}
@NgModule({
  declarations: [SkinItemComponent],
  imports: [CommonModule, MatIconModule, AppCurrencyModule, SkinItemTooltipModule, LazyLoadImageModule, SkinItemImageModule],
  exports: [SkinItemComponent],
})
export class SkinItemModule {}
