/* eslint-disable @typescript-eslint/no-empty-function */
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Input, NgModule, OnDestroy } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-ui-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PaginationComponent),
      multi: true,
    },
  ],
})
export class PaginationComponent implements ControlValueAccessor, OnDestroy {
  @Input() public min: number;
  @Input() public max: number;
  @Input() public set value(value: number) {
    this.page.setValue(value);
  }

  public page: FormControl;
  public get pageDigitCount(): number {
    return this.isValid(this.max) ? `${this.max}`.length : 2;
  }

  private destroy$: Subject<void>;
  private onTouched = (): void => {};
  private onChange: (value: number) => void = () => {};

  constructor(private readonly cdr: ChangeDetectorRef) {
    this.destroy$ = new Subject();
    this.min = 1;
    this.max = 1;
    this.page = new FormControl(1);
    this.page.valueChanges
      .pipe(
        // distinctUntilChanged(),
        takeUntil(this.destroy$)
      )
      .subscribe((value: number) => {
        this.onChange(value);
        this.onTouched();
      });
  }

  public setNextPage(): void {
    this.updateValue(this.page.value + 1);
  }

  public setPrevPage(): void {
    this.updateValue(this.page.value - 1);
  }

  private updateValue(newValue: number): void {
    if (newValue < this.min) {
      this.page.patchValue(this.min);
    } else if (newValue > this.max) {
      this.page.patchValue(this.max);
    } else {
      this.page.patchValue(Math.ceil(newValue));
    }
  }

  public writeValue(value: number): void {
    const isValidNumber = this.isValid(value);
    if (!isValidNumber) return;
    this.page.patchValue(value, { emitEvent: false });
    // this.onChange(value);
    this.onTouched();
    this.cdr.detectChanges();
  }

  public registerOnChange(fn: (value: number) => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  private isValid(value: number): boolean {
    return typeof value === 'number' && Number.isInteger(value) && value > 0;
  }
  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}

@NgModule({
  imports: [CommonModule, ReactiveFormsModule, TranslateModule, MatIconModule],
  declarations: [PaginationComponent],
  exports: [PaginationComponent],
})
export class PaginationModule {}
