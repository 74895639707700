import { inject, Injectable, Optional } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalNames, RoutableModalNames } from '@dev-fast/types';
import { Action, NgxsOnInit, Selector, State, StateContext, Store } from '@ngxs/store';
import { append, patch, removeItem } from '@ngxs/store/operators';

import { FrameMessageTypes, IFrameMessageService } from '@app/core/iframe';

import { CloseAllModals, CloseModal, CloseRoutableModal, ModalOpened, OpenModal, OpenRoutableModal } from './modals.actions';
import { MODALS_INITIAL_STATE, ModalsStateModel } from './modals-state.model';

@State<ModalsStateModel>({
  name: 'modals',
  defaults: MODALS_INITIAL_STATE,
})
@Injectable()
export class ModalsState implements NgxsOnInit {
  readonly #store = inject(Store);
  readonly #router = inject(Router);
  readonly #route = inject(ActivatedRoute);

  constructor(@Optional() private readonly frameMessageService: IFrameMessageService) {}

  @Selector()
  static activeModals({ activeModals }: ModalsStateModel): ModalNames[] {
    return activeModals;
  }
  @Selector()
  static activeRoutableModal({ activeRoutableModal }: ModalsStateModel): RoutableModalNames | null {
    return activeRoutableModal;
  }
  @Selector()
  static isSomeModalOpened({ activeModals, activeRoutableModal }: ModalsStateModel): boolean {
    return !!(activeModals.length && activeRoutableModal?.length);
  }

  ngxsOnInit(): void {
    if (this.frameMessageService) {
      this.frameMessageService.on(FrameMessageTypes.MESSAGE_FROM_BB, 'open.user-profile.modal', (payload: { userId: number }) =>
        this.#store.dispatch(new OpenModal(ModalNames.USER_PROFILE, payload)),
      );
      this.frameMessageService.on(FrameMessageTypes.MESSAGE_FROM_BB, 'open.lottery-rules.modal', () =>
        this.#store.dispatch(new OpenModal(ModalNames.LOTTERY_RULES)),
      );
    }
  }

  @Action(OpenModal)
  open({ setState, getState, dispatch }: StateContext<ModalsStateModel>, { name, payload }: OpenModal): void {
    const { activeModals } = getState();
    if (!activeModals.includes(name)) {
      setState(
        patch<ModalsStateModel>({
          activeModals: append([name]),
        }),
      );
      dispatch(new ModalOpened(name, payload));
    }
  }
  @Action(OpenRoutableModal)
  openRoutableModal({ patchState }: StateContext<ModalsStateModel>, { name }: OpenRoutableModal): void {
    patchState({
      activeRoutableModal: name,
    });
  }
  @Action(CloseAllModals)
  closeAllModals({ patchState }: StateContext<ModalsStateModel>): ModalsStateModel {
    return patchState({ activeModals: [], activeRoutableModal: null });
  }
  @Action(CloseModal)
  close({ setState }: StateContext<ModalsStateModel>, { name }: CloseModal): void {
    setState(
      patch<ModalsStateModel>({
        activeModals: removeItem((el) => el === name),
      }),
    );
  }
  @Action(CloseRoutableModal)
  closeRoutableModal({ patchState }: StateContext<ModalsStateModel>, { payload }: CloseRoutableModal): void {
    patchState({
      activeRoutableModal: null,
    });
    const outlets: { primary?: string | null; modal: string | null } = {
      modal: null,
    };
    if (payload?.url) {
      outlets['primary'] = payload.url;
    }
    this.#router.navigate([{ outlets }], { relativeTo: this.#route.parent, queryParams: payload?.queryParams });
  }
}
