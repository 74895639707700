<div
  class="filterMobile flex fx-column relative"
  #mobileFilter
>
  <div class="filterMobile-header flex fx-justify-between pb-3 w-100">
    <div class="headerTitle tw-font-medium w-100">
      {{ 'LOCAL.FILTERS.TITLE' | translate }}
    </div>
    <div
      class="headerBtn"
      (click)="closeModal()"
    >
      <mat-icon svgIcon="close"></mat-icon>
    </div>
  </div>

  <ng-scrollbar class="filtersContainer">
    <div class="filtersContainer">
      <div *ngFor="let filterCategory of controls">
        <div *ngFor="let filter of filterCategory">
          <div class="filterCategory-title pb-xs-9">{{ localeRoot + (filter.localeKey | uppercase) | translate }}</div>
          <div
            class="filterCategory-body flex fx-column gap-xs-9 pb-4"
            [ngSwitch]="filter.type"
          >
            <!-- Ввод числа "от" и "до" с опциями -->
            <ng-container *ngSwitchCase="types.NumberFromToWithOptions">
              <app-ui-filter-number-options
                [filterForm]="data.filterForm"
                [control]="filter"
              />
            </ng-container>

            <!-- Ползунок с числовыми значениями "от" и "до" -->
            <ng-container *ngSwitchCase="types.RangeFromTo">
              <app-ui-filter-slider
                class="pt-2"
                [control]="filter"
                [filterForm]="data.filterForm"
              />
            </ng-container>

            <!-- Простой чекбокс -->
            <app-ui-filter-checkbox
              *ngSwitchCase="types.Checkbox"
              [filterForm]="data.filterForm"
              [checkboxData]="filter"
              [translateRoot]="localeRoot"
            />

            <!-- Список чекбоксов -->
            <ng-container *ngSwitchCase="types.CheckboxList">
              <ng-container *ngFor="let filterFormItem of filter.controls; let i = index">
                <!-- чекбокс с вложенными чекбоксами -->
                <app-ui-filter-checkbox-list
                  *ngIf="filterFormItem.type === types.CheckboxList"
                  [filterForm]="data.filterForm"
                  [checkboxListItem]="filterFormItem"
                  [translateRoot]="localeRoot"
                  [categoryTitle]="localeRoot + filter.localeKey"
                  [panelHeight]="mobileFilter.offsetHeight - 100 + 'px'"
                  [disabledParams]="disabledParams"
                  [subPanelTopOffset]="subPanelTopOffset"
                />

                <!-- Простой чекбокс -->
                <app-ui-filter-checkbox
                  *ngIf="filterFormItem.type === types.Checkbox"
                  [filterForm]="data.filterForm"
                  [checkboxData]="filterFormItem"
                  [translateRoot]="localeRoot"
                />
              </ng-container>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </ng-scrollbar>
  <div class="footer-btns flex gap-1 pt-2">
    <button
      class="btn btn-accent--design w-50"
      (click)="applyFilters()"
    >
      {{ 'ACCEPT' | translate }}
    </button>
    <button
      class="btn btn-basic--design w-50"
      (click)="this.resetFilters()"
    >
      {{ 'P2P_WIDGETS.AUCTION_COMPLETED_DIALOG.RESET' | translate }}
    </button>
  </div>
</div>
