import { ElementRef, Injectable } from '@angular/core';
import { filter, take } from 'rxjs';

import { EnvironmentService } from '@app/core/environment-service';
import { BehaviorSubjectItem } from '@app/shared/utils';

import { FrameMessage, FrameMessageTypes } from '../iframe.model';

@Injectable()
export class IFrameMessageService {
  private targetWindow: ElementRef<HTMLIFrameElement> | null = null;

  private readonly eventRoutingDictionary: Map<string, { func: (...args: any[]) => void; interval: number }>;
  private readonly eventSocketDictionary: Map<string, { func: (...args: any[]) => void; interval: number }>;
  private readonly eventApiDictionary: Map<string, { func: (...args: any[]) => void; interval: number }>;
  private readonly eventDictionary: Map<string, { func: (...args: any[]) => void; interval: number }>;

  private readonly eventRoutingDictionaryTimers: Map<string, NodeJS.Timeout | null>;
  private readonly eventSocketDictionaryTimers: Map<string, NodeJS.Timeout | null>;
  private readonly eventApiDictionaryTimers: Map<string, NodeJS.Timeout | null>;
  private readonly eventDictionaryTimers: Map<string, NodeJS.Timeout | null>;
  private readonly isProduction: boolean;

  private inited: BehaviorSubjectItem<boolean>;
  private initedVar: boolean;

  set target(targetWindow: ElementRef<HTMLIFrameElement>) {
    const targetId = targetWindow.nativeElement.id;
    if (targetId) {
      this.targetWindow = targetWindow;
      if (this.initedVar) {
        this.inited.value = true;
      }
    }
  }
  set setInited(status: boolean) {
    this.initedVar = status;
    if (this.targetWindow && status) {
      this.inited.value = status;
    }
  }

  constructor(private readonly environmentService: EnvironmentService) {
    this.inited = new BehaviorSubjectItem<boolean>(false);
    this.initedVar = false;
    this.isProduction = this.environmentService.environments.production;

    this.eventRoutingDictionary = new Map();
    this.eventSocketDictionary = new Map();
    this.eventApiDictionary = new Map();
    this.eventDictionary = new Map();

    this.eventRoutingDictionaryTimers = new Map();
    this.eventSocketDictionaryTimers = new Map();
    this.eventApiDictionaryTimers = new Map();
    this.eventDictionaryTimers = new Map();
  }

  sendMessage(val: FrameMessage): void {
    // console.log({ messageToIFrame: val });
    if (!this.isProduction) {
      console.log({ messageToIFrame: val });
    }
    const send = (): void => {
      if (this.targetWindow && this.targetWindow.nativeElement.contentWindow) {
        this.targetWindow.nativeElement.contentWindow.postMessage(val, '*');
      }
    };
    if (val.notWaitLoadingFrame) {
      send();
      return;
    }
    this.inited.value$
      .pipe(
        filter((v) => v),
        take(1),
      )
      .subscribe(() => send());
  }

  on(eventType: FrameMessageTypes, eventName: string, func: (...args: any[]) => void, interval = 0): void {
    if (eventType === FrameMessageTypes.MESSAGE_FROM_BB_SOCKET) {
      this.eventSocketDictionary.set(eventName, { func, interval });
    }
    if (eventType === FrameMessageTypes.MESSAGE_FROM_BB) {
      this.eventDictionary.set(eventName, { func, interval });
    }
    if (eventType === FrameMessageTypes.MESSAGE_FROM_BB_API) {
      this.eventApiDictionary.set(eventName, { func, interval });
    }
    if (eventType === FrameMessageTypes.ROUTING_FROM_BB) {
      this.eventRoutingDictionary.set(eventName, { func, interval });
    }
  }

  trigerEvent(eventType: FrameMessageTypes, eventName: string, payload: any): void {
    if (eventType === FrameMessageTypes.MESSAGE_FROM_BB_SOCKET) {
      this.handler(eventName, payload, this.eventSocketDictionary, this.eventSocketDictionaryTimers);
    }
    if (eventType === FrameMessageTypes.MESSAGE_FROM_BB) {
      this.handler(eventName, payload, this.eventDictionary, this.eventDictionaryTimers);
    }
    if (eventType === FrameMessageTypes.MESSAGE_FROM_BB_API) {
      this.handler(eventName, payload, this.eventApiDictionary, this.eventApiDictionaryTimers);
    }
    if (eventType === FrameMessageTypes.ROUTING_FROM_BB) {
      this.handler(eventName, payload, this.eventRoutingDictionary, this.eventRoutingDictionaryTimers);
    }
  }

  private handler(
    eventName: string,
    payload: any,
    eventsMap: Map<
      string,
      {
        func: (...args: any[]) => void;
        interval: number;
      }
    >,
    timersMap: Map<string, NodeJS.Timeout | null>,
  ): void {
    const event = eventName ? eventsMap.get(eventName) : undefined;
    if (!event) {
      return;
    }
    if (event.interval > 0) {
      const eventTimer = eventName ? timersMap.get(eventName) : undefined;

      if (eventTimer) {
        return;
      }

      if (!eventTimer) {
        const timerId = setTimeout(() => {
          event.func(payload);
          timersMap.set(eventName, null);
        }, event.interval);
        timersMap.set(eventName, timerId);
      }
    } else if (event.interval === 0) {
      event.func(payload);
    }
  }
}
