<ng-container *ngIf="item">
  <div
    class="card-wrapper"
    [ngClass]="classListConstructor()"
  >
    <div
      class="card-container"
      [bgSrc]="getBackgroundImage(item)"
      lazyLoadImage
    >
      <ng-container *ngIf="item.tags && showTags">
        <div class="tags-container">
          <ng-container *ngFor="let tag of item.tags | tuiMapper: filterTags">
            <app-ui-tag [class]="tag">
              {{ tag | uppercase }}
            </app-ui-tag>
          </ng-container>
        </div>
      </ng-container>
      <ng-container *ngIf="showRevision">
        <button
          class="btn revision-btn"
          (click)="openModal()"
        >
          <mat-icon
            class="info-icon"
            [svgIcon]="'information'"
          ></mat-icon>
        </button>
      </ng-container>
      <button
        class="avatar-container"
        [class.moving]="showInput"
        [disabled]="totalCasesAmount > 9 || !showInput"
        (click)="addCase(item)"
      >
        <ng-container *ngIf="item.itemImage !== null">
          <img
            class="avatar-img"
            [src]="item.itemImage"
            lazyLoadImage
            alt=""
          />
        </ng-container>
        <ng-container *ngIf="item.itemImage === null">
          <ng-container *ngIf="item.mainItem?.icon !== null">
            <img
              class="avatar-img"
              [src]="item.mainItem.icon"
              lazyLoadImage
              alt=""
            />
          </ng-container>
        </ng-container>
        <ng-container *ngIf="item.image !== null">
          <img
            class="avatar-img"
            [src]="item.image"
            lazyLoadImage
            alt=""
          />
        </ng-container>
      </button>
      <ng-container *ngIf="showDescription">
        <div class="description-container">
          <button
            class="description-container__case-info"
            [disabled]="totalCasesAmount > 9 || !showInput"
            (click)="addCase(item)"
          >
            <div class="info-container name">
              <div class="case-name">
                {{ item.name }}
              </div>
            </div>
            <ng-container *ngIf="item.ticketQuest && eventName">
              <div class="ticket ticket-price">
                <ng-container *ngIf="item.ticketQuest && item.ticketQuest.ticketsAmount">
                  <img
                    *ngIf="eventName === 'ny'"
                    [src]="'/assets/img/ny-event/' + ticketsImgDic[item.ticketQuest.ticketColor]"
                    alt=""
                  />
                  <img
                    *ngIf="eventName === 'ny_china'"
                    [src]="'/assets/img/ny-event/china/' + popImgDic[item.ticketQuest.ticketColor]"
                    alt=""
                  />
                  {{ item.ticketQuest.ticketsAmount }}
                </ng-container>
              </div>
            </ng-container>
            <ng-container *ngIf="item.type !== caseTypeEnum.FREE && !item.ticketQuest">
              <div class="info-container price">
                <div
                  class="case-price previous"
                  *ngIf="item.lastRevision.prevPrice > item.lastRevision.price"
                >
                  {{ item.lastRevision.prevPrice | appCurrency }}
                </div>
                <div class="case-price">
                  {{ item.lastRevision.price | appCurrency }}
                </div>
              </div>
            </ng-container>
          </button>
          <ng-container *ngIf="showInput">
            <div class="description-container__amount-container">
              <app-ui-selection-input
                [className]="'case_amount'"
                [showPlaceholder]="false"
                [disabledInput]="true"
                [minValue]="0"
                [maxValue]="10 - (totalCasesAmount - currentCaseAmount)"
                [currentValue]="currentCaseAmount"
                (valueChange)="changeAmount($event, item)"
              >
              </app-ui-selection-input>
            </div>
          </ng-container>
        </div>
      </ng-container>
      <ng-container *ngIf="!showInput && isHovered; then linkTemplate; else shadowTemplate"> </ng-container>
    </div>
  </div>
</ng-container>

<ng-template #linkTemplate>
  <a
    class="shadow-wrapper link"
    langRouterLink
    [routerLink]="['/game/cases/case', item.id]"
  >
  </a>
</ng-template>
<ng-template #shadowTemplate>
  <div class="shadow-wrapper"></div>
</ng-template>
