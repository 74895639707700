import { ChangeDetectionStrategy, Component, inject, Signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { INotification, NotificationStatus, NotificationType } from '@dev-fast/types';
import moment from 'moment';
import { InViewportAction } from 'ng-in-viewport';

import { isMoreThanDay } from '@app/shared/utils';
import { replacementScaleAnimation } from '@app/ui/animations';

import { notificationAnimation } from './notification.animation';
import { NOTIFICATIONS_ENGINE, NotificationsEngine } from './symbols';

@Component({
  selector: 'app-notifications-v2',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
  animations: [notificationAnimation, replacementScaleAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationsComponent {
  readonly #notificationsEngine = inject<NotificationsEngine>(NOTIFICATIONS_ENGINE);

  readonly notificationList: Signal<INotification[]> = toSignal(this.#notificationsEngine.notifications$, { requireSync: true });
  readonly countNewNotifications: Signal<number> = toSignal(this.#notificationsEngine.countNewNotifications$, { requireSync: true });

  readonly NotificationStatusEnum = NotificationStatus;
  readonly NotificationTypeEnum = NotificationType;

  showNotificationsPanel = false;

  trackingFunction(index: number, item: INotification): number {
    return item.id;
  }
  watchAll = (): void => {
    this.#notificationsEngine.watchAllNotifications();
    this.closeMenu();
  };

  openMenu(): void {
    this.showNotificationsPanel = true;
    this.#notificationsEngine.openWidget();
  }
  closeMenu(e?: InViewportAction): void {
    if (!e || (e && !e.visible)) {
      this.showNotificationsPanel = false;
      this.#notificationsEngine.closeWidget();
    }
  }
  onOutside(): void {
    this.closeMenu();
  }
  onNotificationItemHover(noty: INotification): void {
    if (noty.status === NotificationStatus.new) {
      this.#notificationsEngine.watchNotification(noty.id);
    }
  }
  getDate(date: Date | number): string {
    return `${isMoreThanDay(Date.now(), date) ? moment(date).format('DD.MM.YY (HH:mm)') : moment(date).format('HH:mm')}`;
  }
}
