import { Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-sih-custom-icon',
  standalone: true,
  imports: [MatIconModule],
  templateUrl: './sih-custom-icon.component.html',
  styleUrls: ['./sih-custom-icon.component.scss'],
})
export class SihCustomIconComponent {
  @Input() subIcon = 'checked-icon-round-bold';
}
