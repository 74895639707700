import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { RouterModule } from '@angular/router';
import { CountdownComponentModule } from '@dev-fast/ui-components';
import { TranslateModule } from '@ngx-translate/core';

import { LangRouterModule, SkinItemImageModule } from '@app/shared/directives';
import { AppCurrencyModule, ReplacePipeModule } from '@app/shared/pipes';

import { P2pAuctionCompletedModalModule } from '../../modals/p2p-auction-completed-modal/p2p-auction-completed-modal.module';
import { P2pSellingItemComponent } from './p2p-selling-item.component';

@NgModule({
  declarations: [P2pSellingItemComponent],
  imports: [
    CommonModule,
    RouterModule,
    MatIconModule,
    TranslateModule,
    AppCurrencyModule,
    SkinItemImageModule,
    CountdownComponentModule,
    MatProgressSpinnerModule,
    P2pAuctionCompletedModalModule,
    ReplacePipeModule,
    LangRouterModule,
  ],
  exports: [P2pSellingItemComponent],
})
export class P2pSellingItemModule {}
