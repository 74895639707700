export enum MarketAction {
  Decline = 'decline',
  Confirm = 'confirm',
}

export enum P2pItemStatus {
  Selected = 'isSelected',
  ReadyToSell = 'isSelling',
  Deposit = 'isDeposit',
  Default = 'default',
  SkinSearch = 'skinSearch',
  AcceptTrade = 'acceptTrade',
  Disabled = 'isDisabled',
  WaitingForWithdraw = 'waitingForWithdraw',
  WaitingForSale = 'waitingForSale',
  Sold = 'sold',
}

export enum P2pItemAttribute {
  ST = 'ST',
  SV = 'SV',
}
