<div class="account-noty-container">
  <button
    class="btn close-btn"
    (click)="closeModal()"
  >
    <mat-icon
      class="close-icon"
      [svgIcon]="'close'"
    ></mat-icon>
  </button>
  <div class="title">
    {{ 'LOCAL.CASES.MODAL.INVALID_ACCOUNT.HEADER' | translate }}
  </div>
  <div class="discriprion">
    {{ 'LOCAL.CASES.MODAL.INVALID_ACCOUNT.TEXT' | translate }}
  </div>
  <a
    class="btn btn-primary"
    langRouterLink
    routerLink="refill"
    (click)="closeModal()"
  >
    {{ 'LOCAL.CASES.MODAL.INVALID_ACCOUNT.BUTTON' | translate | titlecase }}
  </a>
</div>
