import { IWithdrawalReceive, IWithdrawalReceiveRequest, WithdrawalType } from '@dev-fast/types';

export class AddCard {
  public static readonly type = '[Withdrawal] Add card';
}
export class ClearWithdrawalState {
  public static readonly type = '[Withdrawal] clear state';
}
export class GetCards {
  public static readonly type = '[Withdrawal] Get cards';
}
export class GetWithdrawalMethods {
  public static readonly type = '[Withdrawal] Get methods';
}
export class GetWithdrawalHistory {
  public static readonly type = '[Withdrawal] Get history';
}
export class RemoveCard {
  public static readonly type = '[Withdrawal] Remove card';
  constructor(public card: string) {}
}
export class RequestWithdrawalReceive {
  public static readonly type = '[Withdrawal] Request withdrawal receive';
  constructor(public payload: IWithdrawalReceiveRequest) {}
}
export class RequestWithdrawalReceiveSuccess {
  public static readonly type = '[Withdrawal] Request withdrawal receive success';
  constructor(public result: IWithdrawalReceive) {}
}
export class SetActiveWithdrawalMethod {
  public static readonly type = '[Withdrawal] Set active method';
  constructor(public typeName: WithdrawalType | null, public method?: string) {}
}
export class Withdraw {
  public static readonly type = '[Withdrawal] Withdraw';
  constructor(public paymentSystem: string, public coinsAmount: number, public wallet: string, public recaptcha: string) {}
}
