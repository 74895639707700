import { ChangeDetectionStrategy, Component, DestroyRef, inject, Input, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup } from '@angular/forms';
import { IFilterFormTemplate } from '@dev-fast/types';

import { IDFormControlCheckbox, IDFormControlCheckboxList } from '@app/core/dynamic-forms-service';

/**
 * Компонент для группы чекбоксов в фильтре
 * Включает в себя один главный чекбокс группы и вложенные чекбоксы
 * Вложенные чекбоксы открываются на отдельной панели
 */
@Component({
  selector: 'app-ui-filter-checkbox-list',
  templateUrl: './filter-checkbox-list-item.component.html',
  styleUrls: ['./filter-checkbox-list-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterCheckboxListItemComponent implements OnInit {
  @Input() set filterForm(form: FormGroup<IFilterFormTemplate> | undefined) {
    this.form = form;
    this.#initSubCheckboxes();
  }

  @Input() set checkboxListItem(list: IDFormControlCheckboxList) {
    this.checkboxList = list;
    this.#initSubCheckboxes();
  }

  @Input() disabledParams: string[] = [];

  // Локаль для группы чекбоксов (нужна для того, чтобы отображать на панели с вложенными чекбоксами)
  @Input() categoryTitle = '';

  // Высота панели с вложенными чекбоксами
  @Input() panelHeight = '';

  // Корень для локалей
  @Input() translateRoot = '';

  // Fix FAST_NEW_FRONT-1676
  @Input() subPanelTopOffset: number | undefined;

  // Данные для группы чекбоксов в формате IDFormControlCheckboxList
  checkboxList!: IDFormControlCheckboxList;

  // Общая форма фильтра
  form: FormGroup<IFilterFormTemplate> | undefined;

  // Объект FormControl для чекбокса группы
  groupCheckboxControl: FormControl = new FormControl();

  // Начальное состояние панели с вложенными чекбоксами
  isSubPanelOpened = false;

  // Количество выбранных вложенных чекбоксов
  selectedSubCheckboxesNumber = 0;

  // Объекты FormControls для вложенных чекбоксов
  subCheckboxesControls: FormControl[] = [];

  #destroyRef = inject(DestroyRef);

  /**
   * Определяет является ли чекбокс группы неопределенным
   * Возвращает true, если вложенные чекбоксы выбраны частично
   * Возвращает false, если выбраны все вложенные чекбоксы или не выбран ни один из них
   */
  get isGroupCheckboxIndeterminate(): boolean {
    const isSomeSubCheckboxSelected = this.subCheckboxesControls.some(({ value }) => Boolean(value));
    const areAllSubCheckboxesSelected = this.subCheckboxesControls.every(({ value }) => Boolean(value));

    return isSomeSubCheckboxSelected && !areAllSubCheckboxesSelected;
  }

  ngOnInit(): void {
    this.#initGroupCheckboxSubscription();
  }

  /**
   * Изменяет значение чекбокса группы на противоположное
   */
  changeGroupCheckboxValue(): void {
    if (!this.checkboxList || !this.checkboxList.controls) {
      return;
    }

    this.groupCheckboxControl.patchValue(!this.groupCheckboxControl.value);
  }

  /**
   * Изменяет значение вложенного чекбокса
   */
  changeSubCheckboxValue(index: number): void {
    if (!this.checkboxList) {
      return;
    }

    this.subCheckboxesControls[index].patchValue(
      this.subCheckboxesControls[index].value ? false : this.checkboxList.controls[index].outputData.checkedValue,
    );
    this.applyValues();
  }

  /**
   * Открывает панель с вложенными чекбоксами
   */
  openSubPanel(): void {
    this.isSubPanelOpened = true;
  }

  /**
   * Применение всех текущих чекбоксов
   */
  applyValues(): void {
    // Обновляет значение чекбокса группы на основании количества выбранных вложенных чекбоксов
    this.selectedSubCheckboxesNumber = this.subCheckboxesControls.filter(({ value }) => value).length;
    this.groupCheckboxControl.patchValue(Boolean(this.selectedSubCheckboxesNumber), { emitEvent: false });

    // Изменяет значения чекбокса группы
    if (this.checkboxList.outputData) {
      const groupKey = this.checkboxList.outputData.checkboxKey;
      const groupValue = this.checkboxList.outputData.checkedValue;

      let groupCheckboxValue = this.form?.controls[groupKey].value?.split(',').filter((item: any) => Boolean(item));

      if (this.selectedSubCheckboxesNumber && !groupCheckboxValue.includes(groupValue)) {
        groupCheckboxValue.push(groupValue);
      } else if (!this.selectedSubCheckboxesNumber && groupCheckboxValue.includes(groupValue)) {
        groupCheckboxValue = groupCheckboxValue.filter((val: any) => val !== groupValue);
      }

      this.form?.controls[groupKey].patchValue(groupCheckboxValue.join(','), { emitEvent: false });
    }

    // Изменяет значения вложенных чекбоксов
    this.subCheckboxesControls.forEach((control, index) => {
      const outputData = this.checkboxList.controls[index].outputData;
      const key = outputData.checkboxKey;
      const value = outputData.checkedValue;

      let subCheckboxValue = this.form?.controls[key]?.value?.split(',').filter((item: any) => !!item);

      if (control.value && !this.disabledParams.includes(key)) {
        if (!subCheckboxValue || !subCheckboxValue.length) {
          subCheckboxValue = [value];
        } else if (!subCheckboxValue.includes(value)) {
          subCheckboxValue.push(value);
        }
      } else {
        if (subCheckboxValue?.includes(value)) {
          subCheckboxValue = subCheckboxValue.filter((val: string) => val !== value);
        }
      }

      this.form?.controls[key].patchValue(subCheckboxValue.join(','), { emitEvent: false });
    });

    this.form?.patchValue(this.form?.value);
  }

  /**
   * Инициализирует подписку на изменение значения чекбокса группы
   */
  #initGroupCheckboxSubscription(): void {
    // При изменении значения чекбокса группы все вложенные чекбоксы меняют свои значения на значение чекбокса группы
    this.groupCheckboxControl.valueChanges.pipe(takeUntilDestroyed(this.#destroyRef)).subscribe((value: boolean) => {
      this.subCheckboxesControls.forEach((control) => control.patchValue(value));
      this.applyValues();
    });
  }

  /**
   * Инициализирует вложенные чексбоксы
   * (если фильтры применены, то при открытии вложенной панели с чекбоксами нужно отобразить их состояние)
   */
  #initSubCheckboxes(): void {
    if (!this.form || !this.checkboxList) {
      return;
    }

    if (!this.subCheckboxesControls.length) {
      // Определяет текущее состояние для вложенных чекбоксов на основании входных данных checkboxList
      const checkboxes = this.checkboxList.controls.map(({ outputData }: IDFormControlCheckbox) => {
        const key = outputData.checkboxKey;
        const value = outputData.checkedValue;

        return this.form?.value[key].split(',').includes(value);
      });

      // Создает объекты FormControl для вложенных чекбоксов и устанавливает их значения
      this.subCheckboxesControls = checkboxes.map((checked) => new FormControl(checked)) || [];

      // Обновляет значение чекбокса группы на основании количества выбранных вложенных чекбоксов
      this.selectedSubCheckboxesNumber = checkboxes.filter(Boolean).length;
      this.groupCheckboxControl.patchValue(Boolean(this.selectedSubCheckboxesNumber), { emitEvent: false });
    }
  }
}
