<div
  class="history-item pointer"
  *ngIf="templateData"
>
  <div class="flex fx-align-center gap-3 pr-3">
    <div class="history-item__icon">
      <img [src]="historyItem.item.baseItem.icon | hash" />
    </div>
    <div
      [ngStyle]="{ background: historyItem.item.baseItem.color }"
      class="history-item__rarity"
    ></div>
    <div class="item-name">
      <div class="item-name__short-name">
        <ng-container *ngIf="historyItem.item.baseItem.shortName; else stickerName">
          {{ historyItem.item.baseItem.shortName }}
        </ng-container>
        <ng-template #stickerName>
          {{ historyItem.item.baseItem.name }}
        </ng-template>
      </div>
      <div
        *ngIf="historyItem.item.baseItem.skin"
        class="item-name__skin"
      >
        {{ historyItem.item.baseItem.skin }}
      </div>
    </div>
  </div>
  <div class="item-status flex fx-align-center gap-4">
    <div class="item-status__icon">
      <ng-container *ngIf="templateData.isIcon; else imgStatusTemplate">
        <mat-icon [svgIcon]="templateData.img"></mat-icon>
      </ng-container>
      <ng-template #imgStatusTemplate>
        <img
          *ngIf="templateData.img"
          [src]="templateData.img"
          alt=""
        />
      </ng-template>
    </div>
    <div class="flex fx-column gap-1">
      <div class="item-status__source">
        {{ 'HISTORY.INVENTORY_HISTORY.' + templateData.locale | translate }}
        <ng-container *ngIf="showCaseName">
          <span>&#160;{{ historyItem.status.source.data?.name }}</span>
        </ng-container>
      </div>
      <div class="item-status__date">{{ historyItem.createdAt | date: 'hh:mm:ss' }}</div>
    </div>
  </div>
  <div
    class="history-item__price flex fx-align-center gap-1"
    [class.green-highlight]="isSourceCasesOrBattles"
  >
    {{ historyItem.item.price > 0 ? '+' : '-' }}
    <app-currency [value]="price" />
  </div>
</div>
