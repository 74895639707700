<nav
  class="menu"
  [ngClass]="{ menu_opened: isShowFullView }"
>
  <button
    class="menu__collapse"
    (click)="showFullView()"
  >
    <mat-icon
      class="menu__collapse-icon"
      [svgIcon]="'chevron-double-right'"
    ></mat-icon>
  </button>
  <ng-scrollbar class="menu__scrollbar">
    <div
      [class]="'menu__group menu__group_' + mode"
      *ngFor="let mode of modes"
    >
      <ng-container *ngIf="gamesDict[mode]">
        <app-ui-menu-item
          *ngFor="let game of gamesDict[mode]"
          [game]="game"
          [status]="state[game.key]"
          [isNew]="newGames[game.key]"
          [isShowFullView]="isShowFullView"
          [isActive]="activeUrl === '/game/' + game.key"
        >
        </app-ui-menu-item>
      </ng-container>
    </div>
    <div
      class="menu__online"
      *ngIf="online"
    >
      <span class="menu__online_value">
        <!-- TODO Add locale -->
        <span class="menu__online_label"> Players online </span>
        {{ online }}
      </span>
    </div>
    <div class="menu__socials">
      <app-ui-social-groups></app-ui-social-groups>
    </div>
  </ng-scrollbar>
</nav>
