import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ICaseBattlePlayer, ICaseBattleRoundDto } from '@dev-fast/types';

import { colorDictionary } from '@app/shared/utils';

@Component({
  selector: 'app-provably-fair-modal',
  templateUrl: './provably-fair-modal.component.html',
  styleUrls: ['./provably-fair-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProvablyFairModalComponent {
  players: ICaseBattlePlayer[] = this.payload.players;
  rounds: ICaseBattleRoundDto[] | undefined = this.payload.rounds;

  constructor(
    public dialogRef: MatDialogRef<ProvablyFairModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    private readonly payload: { rounds: ICaseBattleRoundDto[] | undefined; players: ICaseBattlePlayer[] },
  ) {}

  close(): void {
    this.dialogRef.close();
  }
  filterByPlayer(rounds: ICaseBattleRoundDto[] = [], userId: number): ICaseBattleRoundDto[] {
    return rounds.filter((round) => round.user_id === userId);
  }
  getStyle(color: string): { background: string } {
    const newColor = colorDictionary[color.toUpperCase()];
    return {
      background: `linear-gradient(180.57deg, var(--color-theme-500) -20%, ${newColor} 90%, ${newColor} 130%)`,
    };
  }
}
