import { IInventoryPriceFilter } from '../../entities';

export const PRICE_FILTER: IInventoryPriceFilter[] = [
  {
    title: '0 - 10000',
    name: [0, 10000],
    icon: {
      type: 'icon',
      src: 'currency--inventory',
    },
  },
  {
    title: '0 - 10',
    name: [0, 10],
    icon: {
      type: 'icon',
      src: 'currency--inventory',
    },
  },
  {
    title: '10 - 30',
    name: [10, 30],
    icon: {
      type: 'icon',
      src: 'currency--inventory',
    },
  },
  {
    title: '30 - 100',
    name: [30, 100],
    icon: {
      type: 'icon',
      src: 'currency--inventory',
    },
  },
  {
    title: '100 - 200',
    name: [100, 200],
    icon: {
      type: 'icon',
      src: 'currency--inventory',
    },
  },
  {
    title: '200 - 499',
    name: [200, 499],
    icon: {
      type: 'icon',
      src: 'currency--inventory',
    },
  },
  {
    title: '500 - 3000',
    name: [500, 3000],
    icon: {
      type: 'icon',
      src: 'currency--inventory',
    },
  },
];

export const COLLECT_BY_PRICE: IInventoryPriceFilter[] = [
  {
    title: '10',
    name: 1000,
  },
  {
    title: '20',
    name: 2000,
  },
  {
    title: '50',
    name: 5000,
  },
  {
    title: '100',
    name: 10000,
  },
  {
    title: '150',
    name: 15000,
  },
  {
    title: '200',
    name: 20000,
  },
  {
    title: '500',
    name: 50000,
  },
  {
    title: '1000',
    name: 100000,
  },
];
