import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, Optional } from '@angular/core';
import { P2pApiService } from '@dev-fast/backend-services';
import {
  IMarketplaceItem,
  IP2pItemUpdated,
  IP2pParticipateItemUpdated,
  IP2pPurchaseItem,
  OrderStatusEnum,
  Panel,
  SubPanel,
} from '@dev-fast/types';
import { Action, NgxsOnInit, Selector, State, StateContext, Store } from '@ngxs/store';
import { insertItem, patch, removeItem, updateItem } from '@ngxs/store/operators';
import moment from 'moment';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { FrameMessageTypes, IFrameMessageService } from '@app/core/iframe';
import { LocalStorageService } from '@app/core/local-storage-service';
import { NotificationsService } from '@app/core/notification-service';
import { ChangeActivePanel, LayoutState } from '@app/core/state/layout';
import { RefreshCurrentUser } from '@app/core/state/user-store';
import { PriorityList, sortByPriority } from '@app/shared/utils';

import {
  ConfirmBid,
  CreateBid,
  DeleteBid,
  GetMyBids,
  MergePurchasing,
  MergeSelected,
  RemoveItem,
  ToggleSelected,
  UpdateShowWarnValue,
} from './buying.actions';
import { P2P_BUYING_INITIAL_STATE, P2pBuyingStateModel } from './buying-state.model';

const priorityList: PriorityList = {
  high: [OrderStatusEnum.WAIT_FOR_TRADE, OrderStatusEnum.WAIT_FOR_CONFIRM, OrderStatusEnum.WAIT_FOR_BUYER_ACCEPT],
};
@State<P2pBuyingStateModel>({
  name: 'buying',
  defaults: P2P_BUYING_INITIAL_STATE,
})
@Injectable()
export class P2pBuyingState implements NgxsOnInit {
  @Selector()
  static purchasing({ purchasing, selected }: P2pBuyingStateModel): IP2pPurchaseItem[] {
    return [...selected, ...sortByPriority(purchasing, 'status', 'statusAt', priorityList, 'asc')];
  }
  @Selector()
  static showWarn({ showWarn }: P2pBuyingStateModel): boolean {
    return showWarn;
  }

  constructor(
    @Optional() private readonly _frameMessageService: IFrameMessageService,
    private readonly _api: P2pApiService,
    private readonly _store: Store,
    private readonly _notificationsService: NotificationsService,
    private readonly _localStorage: LocalStorageService,
  ) {}

  ngxsOnInit({ getState, patchState, dispatch }: StateContext<P2pBuyingStateModel>): void {
    const showWarn = this._localStorage.get('marketplace-showWarn');
    if (showWarn !== undefined) {
      patchState({ showWarn });
    }

    const elementInState = (id: number): IP2pPurchaseItem | undefined => {
      const { purchasing } = getState();
      return purchasing.find((el) => el.id === id);
    };
    const elementIsSelected = (id: number): IP2pPurchaseItem | undefined => {
      const { selected } = getState();
      return selected.find((el) => el.id === id);
    };
    this._api.itemUpdatedEvent((payload: IP2pItemUpdated) => {
      const element = elementIsSelected(payload.id);

      if (element) {
        if (element.status === OrderStatusEnum.WAIT_FOR_TRADE && payload.status === OrderStatusEnum.CRATED) {
          return;
        }
        this._store.dispatch(new MergeSelected(payload.id, payload));
      }
    });
    this._api.participantItemUpdatedEvent((payload: IP2pParticipateItemUpdated) => {
      const element = elementInState(payload.id);
      if (element) {
        if (element.status === OrderStatusEnum.WAIT_FOR_TRADE && payload.status === OrderStatusEnum.CRATED) {
          return;
        }
        this._store.dispatch(new MergePurchasing(payload.id, payload));
      }
    });

    this._api.itemDeletedEvent((payload) => {
      const element = elementInState(payload.id);

      if (element) {
        this._store.dispatch(new RemoveItem(payload.id, true));
      }
    });
    if (this._frameMessageService) {
      this._frameMessageService.on(FrameMessageTypes.MESSAGE_FROM_BB, 'clickToTrade', (payload: IMarketplaceItem) =>
        dispatch(new ToggleSelected(payload)),
      );
    }
  }
  // public ngxsOnInit({ getState }: StateContext<P2pDepositStateModel>): void {
  //   this._api.participantItemUpdatedEvent((payload: IP2pParticipateItemUpdated) => {
  //     const { depositingItems } = getState();
  //     const element = depositingItems.find((el) => el.id === payload.id);
  //     if (element) {
  //       this._store.dispatch(new ParticipantItemUpdatedEvent(payload));
  //     }
  //   });
  // }
  @Action(RefreshCurrentUser)
  refreshUser({ dispatch, getState }: StateContext<P2pBuyingStateModel>, { payload }: RefreshCurrentUser): void {
    if (payload.id && payload.id !== null) {
      dispatch(new GetMyBids());
    }
  }
  @Action(UpdateShowWarnValue)
  updateShowWarnValue({ patchState }: StateContext<P2pBuyingStateModel>, { value }: UpdateShowWarnValue): void {
    this._localStorage.set('marketplace-showWarn', value);
    patchState({ showWarn: value });
  }
  @Action(GetMyBids)
  getMyBids({ patchState }: StateContext<P2pBuyingStateModel>): Observable<void | { items: IP2pPurchaseItem[] }> {
    const now = moment();

    return this._api.reqPurchasing().pipe(
      tap((response) => {
        return patchState({
          purchasing: response.items.filter((value) => moment(value.nextStatusAt).diff(now, 'days') >= 0),
        });
      }),
      catchError((e) => this.onError(e)),
    );
  }
  @Action(ToggleSelected)
  toggleSelected({ setState, getState, dispatch }: StateContext<P2pBuyingStateModel>, { payload }: ToggleSelected): void {
    const { selected } = getState();
    const has = selected.some((el) => el.id === payload.id);

    if (has) {
      dispatch(new RemoveItem(payload.id));
    } else {
      setState(patch({ selected: insertItem<IP2pPurchaseItem>({ ...payload }) }));
      const activePanel = this._store.selectSnapshot(LayoutState.activePanel);
      if (activePanel === null || activePanel.panel !== Panel.TRADES) {
        dispatch(new ChangeActivePanel({ panel: Panel.TRADES, subPanel: SubPanel.NONE }));
      }
    }
  }
  @Action(MergePurchasing)
  mergePurchasing({ setState, getState }: StateContext<P2pBuyingStateModel>, { id, data }: MergePurchasing): void {
    setState(
      patch({
        purchasing: updateItem<IP2pPurchaseItem>(
          (o) => o?.id === id,
          patch({
            ...data,
            isActive: false,
          }),
        ),
      }),
    );
  }
  @Action(MergeSelected)
  mergeSelected({ setState, getState }: StateContext<P2pBuyingStateModel>, { id, data }: MergeSelected): void {
    setState(
      patch({
        selected: updateItem<IP2pPurchaseItem>(
          (o) => o?.id === id,
          patch({
            ...data,
          }),
        ),
      }),
    );
  }
  @Action(CreateBid)
  createBid({ setState }: StateContext<P2pBuyingStateModel>, { id }: CreateBid): Observable<void | IP2pPurchaseItem> {
    return this._api.reqBid(id).pipe(
      tap((value) => {
        setState(
          patch({
            selected: removeItem<IP2pPurchaseItem>((x) => x?.id === value.id),
            purchasing: insertItem<IP2pPurchaseItem>(value),
          }),
        );
      }),
      catchError((e) => this.onError(e)),
    );
  }
  @Action(ConfirmBid)
  confirmBid({ dispatch }: StateContext<P2pBuyingStateModel>, { id }: ConfirmBid): Observable<void | Partial<IP2pPurchaseItem>> {
    return this._api.reqConfirm(id).pipe(
      tap((response) => dispatch([new MergePurchasing(id, response)])),
      catchError((e) => this.onError(e)),
    );
  }
  @Action(DeleteBid)
  deleteBid({ dispatch }: StateContext<P2pBuyingStateModel>, { id }: DeleteBid): Observable<void> {
    return this._api.reqBidDelete(id).pipe(
      tap((r) => {
        return dispatch(new RemoveItem(id));
      }),
      catchError((e) => {
        return this.onError(e);
      }),
    );
  }
  @Action(RemoveItem)
  removeItem({ setState }: StateContext<P2pBuyingStateModel>, { id }: RemoveItem): void {
    setState(
      patch({
        purchasing: removeItem<IP2pPurchaseItem>((x) => x?.id === id),
        selected: removeItem<IP2pPurchaseItem>((x) => x?.id === id),
      }),
    );
    if (this._frameMessageService) {
      this._frameMessageService.sendMessage({
        type: FrameMessageTypes.MESSAGE_TO_BB,
        eventName: 'RemoveSkin',
        payload: { id },
      });
    }
  }

  private onError(e: HttpErrorResponse): Observable<void> {
    const { error } = e;
    this._notificationsService.addErrorNotification(error.message || error.error, {
      icon: 'warning',
      system: error.system,
      params: error?.params ?? {},
    });
    return of();
  }
}
