import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  DateFormat,
  IMarketplaceKitData,
  INVENTORY_SETTINGS_LINK,
  ISteamStoreInventory,
  IUserP2pPermissionInterface,
  LotStatus,
  OrderStatusEnum,
  STEAM_GUARD,
  SteamErrorsEnum,
} from '@dev-fast/types';

import { LOT_CLOSED_STATUSES, PosibleSteamActionBtnLocale, USER_CANT_TRADE_ERRORS } from './p2p-action-btn.constants';

@Component({
  selector: 'app-p2p-action-btn',
  templateUrl: './p2p-action-btn.component.html',
  styleUrls: ['./p2p-action-btn.component.scss'],
})
export class P2pActionBtnComponent {
  @Input() public isAuth: boolean | null = true;
  @Input() public set lotItem(value: IMarketplaceKitData | ISteamStoreInventory) {
    this.isP2PItemTemplate = 'items' in value && !value.isUserSeller;
    if ('items' in value) {
      this.status = value.status;
      this.isLastBidByUser = value.isLastBidByUser;
      this.nextBidPrice = value.nextBidPrice;
      this.handleStatusChange(this.status);
      if (!value.isLastBidByUser) {
        this.steamItemStatusLocale = 'P2P_WIDGETS.RENEW_SIDEBAR.DEPOSITING';
        this.isSteamItemBtnDisable = true;
      }
    } else {
      this.steamItemStatusLocale = this.formatSteamStatusLocale(value);
      this.isSteamItemBtnDisable = value.inTrade || !value.passed || value.isUserSeller;
    }
  }

  @Input() public set offerTimer(value: number | null) {
    if (value) {
      this.timer = value;
      this.offerTimerDateFormat = this.getDateFormat(value);
    }
  }
  @Input() public permissions: IUserP2pPermissionInterface | null = null;

  @Output() public isLogin: EventEmitter<void> = new EventEmitter<void>();
  @Output() public isGetTrade: EventEmitter<void> = new EventEmitter<void>();
  @Output() public isPlaceBid: EventEmitter<void> = new EventEmitter<void>();
  @Output() public isSetupSteam: EventEmitter<void> = new EventEmitter<void>();
  @Output() public isBindingSocials: EventEmitter<void> = new EventEmitter<void>();
  @Output() public isSelectSteamItem: EventEmitter<void> = new EventEmitter<void>();
  // links
  public readonly inventorySettingsLink = INVENTORY_SETTINGS_LINK;
  public readonly steamGuardLink = STEAM_GUARD;
  // enums
  public readonly statuses: typeof OrderStatusEnum = OrderStatusEnum;
  public readonly LotStatus: typeof LotStatus = LotStatus;
  public readonly steamErrors: typeof SteamErrorsEnum = SteamErrorsEnum;
  private readonly LotClosedStatuses = LOT_CLOSED_STATUSES;
  public readonly cantTradeErrors = USER_CANT_TRADE_ERRORS;

  //template logic
  public dealStatus!: LotStatus;
  public status!: OrderStatusEnum;
  public steamItemStatusLocale!: PosibleSteamActionBtnLocale;
  public dealCloseStatusLocale = '';
  public offerTimerDateFormat: DateFormat = DateFormat.MINUTES;
  public timer = 0;
  public isLastBidByUser = false;
  public isP2PItemTemplate = false;
  public isSteamItemBtnDisable = false;
  public nextBidPrice: number | undefined;

  // actions
  // Что если вместо пяти аутпутов сделать енам с типом экшена и слать его, а на месте уже обрабатывать
  public login = (): void => this.isLogin.emit();
  public getTrade = (): void => this.isGetTrade.emit();
  public placeBid = (): void => this.isPlaceBid.emit();
  public setupSteam = (): void => this.isSetupSteam.emit();
  public bindSocial = (): void => this.isBindingSocials.emit();
  public selectSteamItem = (): void => this.isSelectSteamItem.emit();

  // TODO Move to utils
  private getDateFormat(date: number): DateFormat {
    return date > 3600000 ? DateFormat.DEFAULT : DateFormat.MINUTES;
  }
  private formatDealClosedText(status: OrderStatusEnum): string {
    if (status === OrderStatusEnum.COMPLETED) return '';
    return 'P2P_WIDGETS.PURCHASE.' + status.replace('-', '_').toUpperCase();
  }
  private formatSteamStatusLocale(steamItem: ISteamStoreInventory): PosibleSteamActionBtnLocale {
    if (steamItem.inTrade || steamItem.isUserSeller) return 'P2P_WIDGETS.RENEW_SIDEBAR.DEPOSITING';
    if (!steamItem.passed) return 'P2P_WIDGETS.RENEW_SIDEBAR.NOT_AVAILABLE';
    return 'GAMES.SELECT_ITEM';
  }
  private handleStatusChange(currentLotStatus: OrderStatusEnum): void {
    switch (true) {
      case [OrderStatusEnum.NEW, OrderStatusEnum.FIRST_BID, OrderStatusEnum.SECOND_BID, OrderStatusEnum.THIRD_PLUS_BID].includes(
        currentLotStatus
      ):
        this.dealStatus = LotStatus.AT_BID;
        break;
      case [OrderStatusEnum.WAIT_FOR_CONFIRM, OrderStatusEnum.WAIT_FOR_TRADE].includes(currentLotStatus):
        if (this.isLastBidByUser) {
          this.dealStatus = LotStatus.WAIT_FOR_TRADE;
        } else {
          this.dealStatus = LotStatus.CLOSED;
          this.dealCloseStatusLocale = this.formatDealClosedText(OrderStatusEnum.AUCTION_FINISHED);
        }
        break;
      case currentLotStatus === OrderStatusEnum.WAIT_FOR_BUYER_ACCEPT:
        if (this.isLastBidByUser) {
          this.dealStatus = LotStatus.WAIT_FOR_TRADE_ACCEPT;
        } else {
          this.dealStatus = LotStatus.CLOSED;
          this.dealCloseStatusLocale = 'P2P_WIDGETS.RENEW_INFO_MODAL.SOMEONE_BID';
        }
        break;
      case this.LotClosedStatuses.includes(currentLotStatus):
        this.dealStatus = LotStatus.CLOSED;
        this.dealCloseStatusLocale = this.formatDealClosedText(currentLotStatus);
        break;
    }
  }
}
