import { IconConfig } from '@dev-fast/types';

export const iconsFunctional: IconConfig[] = [
  {
    name: 'log-in',
    path: 'assets/img/mat-icons/functional/log-in.svg',
  },
  {
    name: 'log-out',
    path: 'assets/img/mat-icons/functional/log-out.svg',
  },
  {
    name: 'settings-selector',
    path: 'assets/img/mat-icons/functional/settings-selector.svg',
  },
  {
    name: 'close',
    path: 'assets/img/mat-icons/functional/close.svg',
  },
  {
    name: 'close-soft',
    path: 'assets/img/mat-icons/functional/close-soft.svg',
  },
  {
    name: 'sort-block',
    path: 'assets/img/mat-icons/functional/sort-block.svg',
  },
  {
    name: 'sort-table',
    path: 'assets/img/mat-icons/functional/sort-table.svg',
  },
];
