import { Injectable } from '@angular/core';
import { AgreementName } from '@dev-fast/types';
import { Select } from '@ngxs/store';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { distinctUntilChanged, map, Observable, tap } from 'rxjs';

import { AgreementsState, SetAgreement } from '../state';

@Injectable({
  providedIn: 'root',
})
export class AgreementsService {
  @Select(AgreementsState.agreements) readonly agreements$!: Observable<Partial<Record<AgreementName, boolean>>>;

  readonly agreementByName$ = (name: AgreementName, defaultValue?: boolean): Observable<boolean> =>
    this.agreements$.pipe(
      tap((agreements) => {
        if (agreements[name] === undefined && defaultValue !== undefined) {
          this.setAgreement(name, defaultValue);
        }
      }),
      map((agreements) => !!agreements[name]),
      distinctUntilChanged((prev, curr) => prev === curr),
    );

  @Dispatch() setAgreement = (name: AgreementName, isAgree: boolean): SetAgreement => new SetAgreement(name, isAgree);
}
