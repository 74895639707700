import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';
import {
  BreakpointsEnum,
  BreakpointsTypes,
  IInventoryShortInfo,
  ILocales,
  IUserDetailed,
  ModalNames,
  NewMenu,
  TransactionWay,
} from '@dev-fast/types';
import { BrandComponentModule } from '@dev-fast/ui-components';
import { TranslateModule } from '@ngx-translate/core';
import { Observable } from 'rxjs';

import { DesktopAppActionMenuModule } from '@app/desktop-app/action-menu';
import { CustomAsyncPipeModule } from '@app/shared/pipes';
import { WidgetsNotificationsNewModule } from '@app/widgets/notifications-new';

import { GofastLayoutService } from '../../services/gofast-layout.service';
import { BalanceBlockComponent } from './balance-block/balance-block.component';
import { PromoBlockComponent } from './promo-block/promo-block.component';

@Component({
  selector: 'ui-header',
  standalone: true,
  imports: [
    CommonModule,
    BrandComponentModule,
    CustomAsyncPipeModule,
    DesktopAppActionMenuModule,
    TranslateModule,
    WidgetsNotificationsNewModule,
    BalanceBlockComponent,
    PromoBlockComponent,
  ],
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent {
  readonly user$: Observable<IUserDetailed | null | undefined>;
  readonly locales$: Observable<ILocales[]>;
  readonly currentLocale$: Observable<ILocales>;
  readonly canLevelUp$: Observable<boolean>;
  readonly inventoryShortInfo$: Observable<IInventoryShortInfo>;
  readonly breakpoints$: Observable<BreakpointsTypes | null> = this.layoutService.breakpoints$;
  readonly menu$: Observable<NewMenu[]> = this.layoutService.activeMenu$;
  readonly userBalance$: Observable<number> = this.layoutService.userBalance$;
  breakpoint = BreakpointsEnum;

  constructor(
    private readonly router: Router,
    private readonly layoutService: GofastLayoutService,
  ) {
    this.user$ = this.layoutService.user$;
    this.locales$ = this.layoutService.locales$;
    this.currentLocale$ = this.layoutService.currentLocale$;
    this.canLevelUp$ = this.layoutService.canLevelUp$;
    this.inventoryShortInfo$ = this.layoutService.inventoryShortInfo$;
  }

  onLogin = (): void => this.layoutService.login();
  onOpenTransactions(way: TransactionWay): void {
    this.router.navigate([{ outlets: { modal: ['transaction', way] } }], { queryParamsHandling: 'preserve' });
  }
  isMainMenuOpen(menu: NewMenu[] | null): boolean {
    return !!menu && (menu.includes(NewMenu.MAIN_MENU) || menu.includes(NewMenu.LANGUAGE));
  }
  onOpenGleamModal(): void {
    this.layoutService.openModal(ModalNames.GLEAM);
  }

  onOpenInventory(): void {
    this.router.navigate(['/inventory']);
  }
}
