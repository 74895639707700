import { Injectable } from '@angular/core';
import { IUserDetailed, TradeLinks } from '@dev-fast/types';
import { Actions, ofActionSuccessful, Select } from '@ngxs/store';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { Observable } from 'rxjs';

import { UpdateApiKey, UpdateApiKeySuccess, UpdateTradeLink, UpdateTradeLinkSuccess } from '@app/core/state/account';
import { UserState } from '@app/core/state/user-store';

@Injectable()
export class P2pModalSetupService {
  @Select(UserState.user)
  public readonly user$!: Observable<IUserDetailed>;
  @Select(UserState.tradelinkIsValid)
  public readonly tradelinkIsValid$!: Observable<boolean>;
  @Select(UserState.apikeyIsValid)
  public readonly apikeyIsValid$!: Observable<boolean>;

  public tradeLinkUpdated$ = this.actions$.pipe(ofActionSuccessful(UpdateTradeLinkSuccess));
  public apiKeyUpdated$ = this.actions$.pipe(ofActionSuccessful(UpdateApiKeySuccess));

  constructor(private readonly actions$: Actions) {}

  @Dispatch() public updateApiKey = (apiKey: string): UpdateApiKey => new UpdateApiKey(apiKey);
  @Dispatch() public updateTradeLink = (links: TradeLinks): UpdateTradeLink => new UpdateTradeLink(links);
}
