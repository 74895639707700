import { ITradeItem } from '../../../models';
import { IClassicPlayerConfig } from './classic-player-config.interface';

export interface IClassicDepositConfig {
  chance: number;
  id: number;
  items: ITradeItem[];
  player: IClassicPlayerConfig;
  tickets: [number, number];
  totalPrice: number;
}
