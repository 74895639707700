export enum Widgets {
  PlayerOfTheDay = 'playerOfTheDay',
  LuckOfTheDay = 'luckOfTheDay',
  LastWinner = 'lastWinner',
  BiggestBet = 'biggestBet',
  CasesMotivator = 'casesMotivator',
  Portal = 'portal',
  Event = 'event',
  ThirdPartyBlock = 'thirdPartyBlock',
}
