export interface ICurrencyRate {
  rate: number;
  key: string;
  prefix: string;
}
export interface ICurrency {
  fixedCurrencyFloat: number;
  gmRate: number | null;
  icon: string;
  id: number;
  isDefault: boolean;
  key: string;
  postfix: string | null;
  prefix: string | null;
  rate: number;
  title: string;
}
export interface ICurrencyFormatArgs {
  iconType?: 'fill' | 'plain' | 'color-green';
  hasIcon?: boolean;
  rateKey?: 'fastcoins';
}
