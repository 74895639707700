import {
  ICountry,
  ICryptoMethodData,
  IPaymentMethodV2,
  IPaymentParams,
  IPaymentSelectedV2,
  IPaymentTypeV2,
  PaymentSpecialName,
  PaymentTypes,
  PaymentWalletType,
} from '@dev-fast/types';

export const SKIN_METHOD_DEFAULT: IPaymentMethodV2 = {
  currency: ['fastcoins'],
  id: 0,
  method_name: PaymentSpecialName.SKINS,
  method_title: PaymentSpecialName.SKINS,
  gate_title: PaymentSpecialName.SKINS,
  gate_id: 0,
  minPayment: 0,
  maxPayment: 0,
  walletType: PaymentWalletType.SKINS,
  icon: false,
  is_popular: false,
  is_terminal: false,
  bonus: 100,
  group_name: [PaymentTypes.POPULAR],
  feeString: '',
  hash: '',
  sort: 0,
  fees: {
    percent: 0,
    fixed: 0,
  },
};
export const GIFT_CARD_METHOD_DEFAULT: IPaymentMethodV2 = {
  currency: ['USD'],
  id: 0,
  method_name: PaymentSpecialName.WALLET_NUMBER,
  method_title: PaymentSpecialName.WALLET_NUMBER,
  gate_title: PaymentSpecialName.WALLET_NUMBER,
  gate_id: 0,
  minPayment: 0,
  maxPayment: 0,
  walletType: PaymentWalletType.WALLET,
  icon: false,
  is_popular: false,
  is_terminal: false,
  bonus: 100,
  group_name: [PaymentTypes.CARDS],
  feeString: '',
  hash: '',
  sort: 0,
  fees: {
    percent: 0,
    fixed: 0,
  },
};

export interface RefillStateModel {
  paymentTypesV2: Record<number, IPaymentTypeV2> | null;
  currentCountry: ICountry | null;
  selectedPayment: IPaymentSelectedV2 | null;
  params: IPaymentParams | null;
  cryptoData: ICryptoMethodData;
}

export const REFILL_INITIAL_STATE: RefillStateModel = {
  paymentTypesV2: {
    0: {
      name: 'popular',
      isExpanded: false,
      methods: [SKIN_METHOD_DEFAULT],
    },
  },
  currentCountry: null,
  selectedPayment: null,
  params: null,
  cryptoData: {
    availableWallets: [],
    userWallets: null,
    exchangers: [],
    activeWalletIndex: 0,
  },
};
