import { Injectable } from '@angular/core';
import { Select } from '@ngxs/store';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { Observable } from 'rxjs';

import { SetFrameLoadedStatus } from '../state/iframe.actions';
import { IFrameState } from '../state/iframe.state';

@Injectable()
export class IFrameCommonService {
  constructor() {
    // console.log('IFrameCommonService');
  }

  @Select(IFrameState.loaded)
  public readonly isIFrameLoaded$!: Observable<boolean>;

  @Dispatch() public setFrameStatus = (status: boolean): SetFrameLoadedStatus => new SetFrameLoadedStatus(status);
}
