import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  NgModule,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { SpinDirection } from '@dev-fast/types';
import anime from 'animejs';
import { Observable, Subscription } from 'rxjs';

import { casesAnimationConstructor } from '@app/shared/utils';

import { tracksAnimation } from './spin-block.animation';

@Component({
  selector: 'app-ui-spin-block',
  templateUrl: './spin-block.component.html',
  styleUrls: ['./spin-block.component.scss'],
  animations: [tracksAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpinBlockComponent implements OnInit, OnDestroy {
  @Input() public sizes: { cardSize: number; blockSize: number } = { cardSize: 15, blockSize: 24 }; // размеры в rem
  @Input() public lineLength = 32; // количество элементов в линии
  @Input() public prizeOffset = 4; // позиция начала / завершения анимации (индекс элемента с начала / с конца)
  @Input() public fastAnimation = false;
  @Input() public showFocus = true;
  @Input() public completeAnimation$!: Observable<void>;
  @Input() public startAnimation$!: Observable<void>;
  @Input() public set directionParams(value: { direction: SpinDirection; tracks?: number }) {
    this.tracksAmount = Number(value.tracks);
    this.direction = value.direction;
    this.breakAnimation();
    this.cdr.detectChanges();
  }
  @Input() public tracks: any[][] = [];
  @Input() public itemTemplate!: TemplateRef<any>;
  @Output() public onanimEnd: EventEmitter<void> = new EventEmitter();
  @HostBinding(`style.--card-size`) public get _cardWidth(): number {
    return this.sizes.cardSize;
  }
  @HostBinding(`style.--block-size`) public get _cardHeight(): number {
    return this.sizes.blockSize;
  }
  @HostBinding(`style.--line-length`) public get _lineLength(): number {
    return this.lineLength;
  }
  @HostBinding(`style.--start-ofset`) public get _startOfset(): number {
    return this.prizeOffset;
  }
  @HostBinding(`style.--end-ofset`) public get _endOfset(): number {
    return this.lineLength - this.prizeOffset;
  }

  public tracksAmount = 1;
  private subscriptions = new Subscription();
  private spinAnimation: anime.AnimeTimelineInstance | null = null;
  public direction: SpinDirection | undefined;
  public directionEnum: typeof SpinDirection = SpinDirection;

  constructor(private cdr: ChangeDetectorRef) {}

  public ngOnInit(): void {
    this.subscribeEmitters();
  }
  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
  public trackByIndex(index: number, item: any): number {
    return item.id;
  }
  private subscribeEmitters(): void {
    this.subscriptions.add(
      this.completeAnimation$.subscribe(() => {
        this.completeAnimation();
      })
    );
    this.subscriptions.add(
      this.startAnimation$.subscribe(() => {
        this.animation(true);
      })
    );
  }
  private breakAnimation(): void {
    this.animation(false);
    this.spinAnimation?.restart();
    this.spinAnimation?.pause();
  }
  private completeAnimation(): void {
    this.spinAnimation?.seek(10000);
    this.spinAnimation?.pause();
    this.spinAnimation = null;
  }
  private animation(complete: boolean): void {
    if (this.direction) {
      this.spinAnimation = anime.timeline({
        complete: () => {
          if (complete) {
            this.onanimEnd.emit();
          }
        },
      });
      for (let index = 0; index < this.tracksAmount; index++) {
        this.spinAnimation.add(
          {
            targets: `.track-${index}`,
            ...casesAnimationConstructor(
              index,
              this.fastAnimation,
              this.direction,
              this.sizes,
              this.lineLength,
              this.prizeOffset,
              complete
            ),
          },
          0
        );
      }
    }
  }
  // private animateArrows(direction: 'horizontal' | 'vertical' | 'mobile'): void {
  //   this.arrowsAnimation = anime.timeline({})
  //   if (direction === 'vertical') {
  //     this.arrowsAnimation.add({
  //       targets: []
  //     })
  //   }
  // }
}
@NgModule({
  imports: [CommonModule, MatIconModule],
  declarations: [SpinBlockComponent],
  exports: [SpinBlockComponent],
})
export class SpinBlockModule {}
