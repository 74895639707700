import { IconConfig } from '@dev-fast/types';

export const iconsInventory: IconConfig[] = [
  {
    name: 'contract-direction',
    path: 'assets/img/mat-icons/inventory/contract-direction.svg',
  },
  {
    name: 'contract-drag-n-drop',
    path: 'assets/img/mat-icons/inventory/contract-drag-n-drop.svg',
  },
  {
    name: 'contract-piece',
    path: 'assets/img/mat-icons/inventory/contract-piece.svg',
  },
  {
    name: 'exchange',
    path: 'assets/img/mat-icons/inventory/exchange.svg',
  },
  {
    name: 'inventory-bag',
    path: 'assets/img/mat-icons/inventory/inventory-bag.svg',
  },
  {
    name: 'inventory-bag-v2',
    path: 'assets/img/mat-icons/inventory/inventory-bag2.svg',
  },
  {
    name: 'withdrawal',
    path: 'assets/img/mat-icons/inventory/withdrawal.svg',
  },
  {
    name: 'ghost',
    path: 'assets/img/mat-icons/inventory/ghost.svg',
  },
  {
    name: 'polygon',
    path: 'assets/img/mat-icons/inventory/polygon.svg',
  },
  {
    name: 'fast-union',
    path: 'assets/img/mat-icons/inventory/fast-union.svg',
  },
  {
    name: 'ak',
    path: 'assets/img/mat-icons/inventory/ak.svg',
  },
  {
    name: 'refill',
    path: 'assets/img/mat-icons/inventory/refill.svg',
  },
  {
    name: 'currency--inventory',
    path: 'assets/img/mat-icons/inventory/currency.svg',
  },
  {
    name: 'polygon-wide',
    path: 'assets/img/mat-icons/inventory/polygon-wide.svg',
  },
  {
    name: 'steam-status',
    path: 'assets/img/mat-icons/inventory/steam-status.svg',
  },
  {
    name: 'upgrade',
    path: 'assets/img/mat-icons/inventory/upgrade.svg',
  },
  {
    name: 'sold-status',
    path: 'assets/img/mat-icons/inventory/sold-status.svg',
  },
  {
    name: 'unlocked',
    path: 'assets/img/mat-icons/inventory/unlocked.svg',
  },
  {
    name: 'unlocked--hover',
    path: 'assets/img/mat-icons/inventory/unlocked-hover.svg',
  },
  {
    name: 'locked',
    path: 'assets/img/mat-icons/inventory/locked.svg',
  },
  {
    name: 'locked--hover',
    path: 'assets/img/mat-icons/inventory/locked-hover.svg',
  },
  {
    name: 'arrow-right--trade',
    path: 'assets/img/mat-icons/inventory/arrow-right.svg',
  },
  {
    name: 'tooltip-triangle',
    path: 'assets/img/mat-icons/inventory/tooltip-triangle.svg',
  },
  {
    name: 'item-sold',
    path: 'assets/img/mat-icons/inventory/item-sold.svg',
  },
];
