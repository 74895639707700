import { IconConfig } from '@dev-fast/types';

export const iconsFooter: IconConfig[] = [
  {
    name: 'games--footer',
    path: 'assets/img/footer/games.svg',
  },
  {
    name: 'open-cases--footer',
    path: 'assets/img/footer/open-cases.svg',
  },
  {
    name: 'online--footer',
    path: 'assets/img/footer/online.svg',
  },
  {
    name: 'on--sale',
    path: 'assets/img/footer/onSale.svg',
  },
  {
    name: 'total--deals',
    path: 'assets/img/footer/totalDeals.svg',
  },
];
