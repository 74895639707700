import { Component, Input } from '@angular/core';
import { IMarketplaceKitData, IP2pDepositingItem, IP2pDepositingItemV2, OrderStatusEnum } from '@dev-fast/types';
import { DateTime } from 'luxon';
import { Observable, of, takeWhile, timer } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';

import { P2pDepositService } from '@app/modules/p2p-new';

import {
  BID_ON_ORDER_STATUSES,
  ORDER_CANCELED_STATUSES,
  ORDER_COMPLETE_STATUSES,
  PosibleBodyBorderClass,
  PosibleCardTitle,
  PosiblePriceStatusLocale,
} from './p2p-selling-item.constants';

@Component({
  selector: 'app-p2p-selling-item',
  templateUrl: './p2p-selling-item.component.html',
  styleUrls: ['./p2p-selling-item.component.scss'],
})
export class P2pSellingItemComponent {
  @Input() set depositItem(depositItem: IP2pDepositingItem | undefined) {
    if (depositItem) {
      this.item = depositItem;
      this.bodyBorderClass = this.#_formatBodyBorderClass(depositItem.status);
      this.priceStatusLocale = this.#_formatPriceStatusLocale(depositItem.status);
      this.lotStatusTitleLocale = this.#_formatLotStatusTitleLocale(depositItem.status);
      this.offerTimer$ = this.#_setOfferTime$(depositItem);
      this.#_createCancelReasonLocale(depositItem.status);
      this.isLotPaused = depositItem.status === OrderStatusEnum.PAUSED;
    }
  }

  increase$: Observable<number> | undefined;
  offerTimer$: Observable<number | null> | undefined;

  pauseBtnFillPercent = 0;

  // Template
  showTimer = true;
  // Статусы можно в енам объеденить
  isLotPaused = false;
  isBidOnOrder = false;
  isTimerActive = false;
  isLotDeleteTemplate = false;
  item!: IP2pDepositingItem;
  bodyBorderClass: PosibleBodyBorderClass = '';
  lotCanceledReasonLocale: string | null = null;
  lotStatusTitleLocale: PosibleCardTitle | null = null;
  priceStatusLocale: PosiblePriceStatusLocale = 'P2P_WIDGETS.RENEW_SIDEBAR.FINAL_PRICE';

  // Enums && consts
  readonly statuses = OrderStatusEnum;
  private readonly orderCompletedStatuses: OrderStatusEnum[] = ORDER_COMPLETE_STATUSES;
  private readonly bidOnOrderStatuses: OrderStatusEnum[] = BID_ON_ORDER_STATUSES;

  constructor(private readonly p2pDepositService: P2pDepositService) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    // this.increase$ = this.item$.pipe(
    //   map(({ recommendedPrice, sellerPrice, price }) => {
    //     return Math.round(((sellerPrice ? sellerPrice : price) / (recommendedPrice ? recommendedPrice : price)) * 100) - 100;
    //   })
    // );
  }

  operateDeleteAction(): void {
    this.isLotDeleteTemplate = !this.isLotDeleteTemplate;
    if (this.isLotDeleteTemplate) {
      this.lotStatusTitleLocale = 'CANCELED';
      this.bodyBorderClass = 'lot-canceled';
    } else {
      this.lotStatusTitleLocale = 'ON_AUCTION';
      this.bodyBorderClass = '';
    }
  }
  onShowLotDetailsModal(item: IP2pDepositingItem): void {
    this.p2pDepositService.showBatchDetailsModal(item as IMarketplaceKitData);
  }
  onSellNow(id: number): void {
    this.p2pDepositService.sellNow(id);
  }
  onConfirm(id: number): void {
    this.p2pDepositService.requestConfirm(id);
  }
  onDecline(id: number): void {
    this.p2pDepositService.requestDecline(id);
  }
  onDeposit(id: number, price: number): void {
    this.p2pDepositService.deposit(id, price);
  }
  onDeleted(id: number): void {
    this.p2pDepositService.deleted([id]);
  }
  onPauseItem(id: number): void {
    this.p2pDepositService.pause(id);
  }
  onResumeItem(id: number): void {
    this.p2pDepositService.resume(id);
  }
  onSendTrade(): void {
    if (this.item.order) {
      this.p2pDepositService.showAuctionCompletedModal(this.item as IP2pDepositingItemV2);
    }
  }

  #_createCancelReasonLocale(itemStatus: OrderStatusEnum): void {
    if (
      [...ORDER_CANCELED_STATUSES, OrderStatusEnum.AUCTION_FINISHED, OrderStatusEnum.COMPLETED, OrderStatusEnum.CANCELED_BY_TIMER].includes(
        itemStatus,
      )
    ) {
      const indexOfStatus = Object.values(OrderStatusEnum).indexOf(itemStatus);
      this.lotCanceledReasonLocale = Object.keys(OrderStatusEnum)[indexOfStatus];
    } else {
      this.lotCanceledReasonLocale = null;
    }
  }

  // надо убрать по всей приле. Я там начал делать пайп и форматер что бы сразу было в норм времени
  #_setOfferTime$(item: IP2pDepositingItem): Observable<number> {
    return of(item).pipe(
      filter(({ nextStatusAt }) => DateTime.fromISO(nextStatusAt).toLocal().toMillis() > DateTime.local().toMillis()),
      switchMap(({ nextStatusAt }) =>
        timer(0, 1000).pipe(
          map((i) => {
            const diff = DateTime.fromISO(nextStatusAt).toLocal().toMillis() - DateTime.local().toMillis();
            return diff - i;
          }),
          tap((timeLeft) => {
            this.pauseBtnFillPercent = this.#getPauseBtnFillPercent(timeLeft);
            this.isTimerActive = timeLeft >= 1000;
          }),
          takeWhile((value) => value >= 0),
        ),
      ),
    );
  }

  #_formatBodyBorderClass(itemStatus: OrderStatusEnum): PosibleBodyBorderClass {
    if ([OrderStatusEnum.WAIT_FOR_TRADE, OrderStatusEnum.CRATED].includes(itemStatus)) {
      return 'lot-success';
    }
    if (this.orderCompletedStatuses.includes(itemStatus)) {
      this.showTimer = false;
      return 'lot-completed';
    }
    if (ORDER_CANCELED_STATUSES.includes(itemStatus)) {
      this.showTimer = false;
      return 'lot-canceled';
    }
    return '';
  }

  #_formatLotStatusTitleLocale(itemStatus: OrderStatusEnum): PosibleCardTitle | null {
    if (itemStatus === OrderStatusEnum.WAIT_FOR_BUYER_ACCEPT) {
      return 'WAITING';
    } else if (itemStatus === OrderStatusEnum.PAUSED) {
      return 'PAUSED';
    } else if ([...this.bidOnOrderStatuses, OrderStatusEnum.NEW].includes(itemStatus)) {
      return 'ON_AUCTION';
    } else if ([OrderStatusEnum.WAIT_FOR_TRADE, OrderStatusEnum.CRATED].includes(itemStatus)) {
      this.isBidOnOrder = false;
      return 'SEND_TRADE';
    } else if ([OrderStatusEnum.COMPLETED, OrderStatusEnum.WAIT_FOR_CONFIRM].includes(itemStatus)) {
      return 'AUCTION_FINISHED';
    } else if (ORDER_CANCELED_STATUSES.includes(itemStatus)) {
      return 'CANCELED';
    }
    return null;
  }

  #_formatPriceStatusLocale(itemStatus: OrderStatusEnum): PosiblePriceStatusLocale {
    if ([OrderStatusEnum.NEW, OrderStatusEnum.PAUSED].includes(itemStatus)) {
      return 'P2P_WIDGETS.RENEW_SIDEBAR.SELLER_PRICE';
    }
    if (
      [
        OrderStatusEnum.WAIT_FOR_TRADE,
        OrderStatusEnum.WAIT_FOR_CONFIRM,
        OrderStatusEnum.WAIT_FOR_BUYER_ACCEPT,
        OrderStatusEnum.COMPLETED,
      ].includes(itemStatus)
    ) {
      this.isBidOnOrder = false;
      return 'P2P_WIDGETS.RENEW_SIDEBAR.FINAL_PRICE';
    }
    if (this.bidOnOrderStatuses.includes(itemStatus)) {
      this.isBidOnOrder = true;
      return 'P2P_WIDGETS.DEPOSITING.CURRENT_OFFER';
    }
    return 'P2P_WIDGETS.RENEW_SIDEBAR.FINAL_PRICE';
  }

  #getPauseBtnFillPercent(timeLeft: number): number {
    const FIVE_MIN_IN_MS = 300000;
    return ((FIVE_MIN_IN_MS - timeLeft) / FIVE_MIN_IN_MS) * 100;
  }
}
