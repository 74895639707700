import { InjectionToken } from '@angular/core';

export const FEATURE_FLAGS = new InjectionToken<Record<string, boolean>>('FEATURE_FLAGS');
export const BB_ROUTES = new InjectionToken<IBBConfig>('BB_ROUTES');
export const FEATURE_FLAGS_DEFAULT: Record<string, boolean> = {
  'case-modal': false,
};

/** Маршруты в текущем проекте (Ангуляр) */
const excludesRoutesHome = [
  // '/',
  '/block',
  '/not-found',
  '/game/cases',
  '/game/case-battle',
  // '/game/classic',
  '/events/ny',
  '/events',
  '/events/ny-event',
  '/events/ny-all-event',
  '/support',
  '/support/requests',
  // '/provably-fair',
  '/faq',
  // '/leaders-race-next'
  // '/game/match-bets',
  // '/game/poggi',
];

const excludesRoutesFrame = ['/service-route'];
/** для формирования маршрутов в ангуляре */

const legacyRoutes = [
  'refill',
  'refill/method',
  'refill/skins',
  'refill-bonus',
  'refill/prepaid',
  'refill/history',
  'bonuses',
  'bonuses/quest',
  'bonuses/referral',
  // 'balance/withdrawal',
  'balance/refill',
  'withdrawal/method',
  'withdrawal/common',
  'withdrawal/history',
  'withdrawal/error',
  'withdrawal/success',
  // 'support',
  // 'support/requests',
  'championship/classic',
  'championship/fast',
  'championship/crash',
  'championship/double',
  'history/classic',
  'history/classic/all/:id',
  'history/fast',
  'history/fast/all/:id',
  'history/crash',
  'history/double',
  'history/craft',
  'game/baccarat/history',
  'game/baccarat/how-to-play',
  'account',
  'account/settings',
  'account/experience',
  'account/referral',
  'account/referral/:id',
  'account/balance',
  'store',
  'service-route',
  'bonuses/referral/:id',
  'premium',
  'profile/:id',
  'tos',
  // 'privacy-policy',
  'aml-policy',
  'anti-bribery-policy',
  'refund-policy',
  // 'tos',
  // 'bug-bounty',
  'refill/undefined',
  'fail-payment/:userId',
  'success-payment/:userId',
  'free-coins',
  // TODO при переносе faq на ангуляр переделать под формат /faq?category=craft
  'faq',
  'faq/craft',
  'faq/fortune-wheel',
  'faq/x50',
  'faq/poggi',
  'faq/hilo',
  'faq/fast',
  'faq/baccarat/:id',
  'faq/general/:id',
  'faq/tower',
  'faq/crash',
  'faq/casebattle',
  'faq/:id',
];

/** для маршрутизации из ангуляра */
const MatchedRoutesToBB: Record<string, string> = {
  '/balance/withdrawal': 'withdrawal.method',
  '/balance/withdrawal/hub': 'withdrawal.method',
  '/withdrawal/method': 'withdrawal.method',
  '/balance/refill/hub': 'coins.store',
  '/balance/refill': 'coins',
  '/buy-coins': 'coins',
  '/refill': 'coins',
  '/refill/method': 'coins.method',
  '/refill/skins': 'coins.steam',
  '/balance/refill/skins': 'coins.steam',
  '/store': 'marketplace',
  '/balance/refill/currency': 'coins.method',
  '/balance/refill/items/skins': 'coins.steam',
  '/bonuses': 'bonuses',
  '/bonuses/faucet': 'bonuses.faucet',
  '/bonuses/quest': 'bonuses.quest',
  '/bonuses/referral': 'bonuses.referral',
  '/account/settings': 'account.settings',
  '/account/balance': 'account.balance',
  '/account/experience': 'account.experience',
  '/account/main': 'account',
  '/account': 'account',
  // '/support': '/support',
  '/bug-bounty': 'bug-bounty',
  // '/refill-bonus': 'refill-bonus',
  '/game/fortune-wheel': 'fortune-wheel',
  '/game/tower': 'tower',
  '/game/hilo': 'hilo',
  // '/game/cases': 'cases',
  '/game/baccarat': 'baccarat',
  '/game/poggi': 'poggi',
};

const SpecialGameRoutesToBB: Record<string, string> = {
  '/game/fast': '/game/fast',
  '/game/x50': '/game/x50',
  '/game/classic': '/game/classic',
  '/game/double': '/game/double',
  '/game/crash': '/game/crash',
  '/game/king': '/game/king',
  '/game/craft': '/game/craft',
  '/game/slot': '/game/slot',
  '/game/match-bets': '/game/match-bets',
  '/ladder': '/ladder',
  '/faq': '/faq',
  // '/faq/casebattle': '/faq/casebattle',
  '/tos': '/tos',
  '/privacy-policy': '/privacy-policy',
};

const MatchedRoutesFromBB: Record<string, string> = {
  // '/refill/method': '/balance/refill/currency',
  // '/refill/method': '/refill/method',
  // '/refill': '/refill',
  '/support': '/support',
  '/event': '/events',
  '/event/ny-all': '/events/ny-all-event',
};
const SpecialPartRoutesFrmBB: string[] = [
  // '/faq'
];
export interface IBBConfig {
  excludesRoutesHome: string[];
  excludesRoutesFrame: string[];
  legacyRoutes: string[];
  MatchedRoutesToBB: Record<string, string>;
  SpecialGameRoutesToBB: Record<string, string>;
  MatchedRoutesFromBB: Record<string, string>;
  SpecialPartRoutesFrmBB: string[];
}
export const BB_ROUTES_DEFAULT: IBBConfig = {
  excludesRoutesHome,
  excludesRoutesFrame,
  legacyRoutes,
  MatchedRoutesToBB,
  SpecialGameRoutesToBB,
  MatchedRoutesFromBB,
  SpecialPartRoutesFrmBB,
};
