/* way of working with ControlValueAccessor was taken from
  https://stackoverflow.com/questions/70916320/can-you-integrate-error-handling-in-a-control-value-accessor-component
*/

import { NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, DestroyRef, inject, Input, OnInit, Self, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ControlValueAccessor, FormControl, FormsModule, NgControl, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { IUICheckboxLabel } from '@dev-fast/types';
import { TranslateModule } from '@ngx-translate/core';
import { debounceTime, Subject, tap } from 'rxjs';

import { LangRouterModule } from '@app/shared/directives';
import { bellAnimation } from '@app/ui/animations';

@Component({
  selector: 'app-ui-accept',
  standalone: true,
  templateUrl: './accept.component.html',
  styleUrls: ['./accept.component.scss'],
  imports: [NgIf, NgFor, FormsModule, ReactiveFormsModule, TranslateModule, RouterModule, LangRouterModule],
  animations: [bellAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiAcceptSAComponent implements ControlValueAccessor, OnInit {
  @Input() checkValidation$: Subject<void> = new Subject();
  @Input() labels: IUICheckboxLabel[] = [];
  @Input() innerLabels: string | undefined;
  @Input() acceptedDisable = true;
  @Input() acceptText = 'Accept';
  @Input() btnClass: string | string[] = ['sm', 'btn-semi-round', 'btn-primary--design', 'fw-500', 'w-100'];

  readonly #destroyRef = inject(DestroyRef);
  readonly animTrigger = signal<boolean>(false);

  #value = false;

  constructor(@Self() readonly ngControl: NgControl) {
    this.ngControl.valueAccessor = this;
  }
  ngOnInit(): void {
    this.checkValidation$
      .pipe(
        tap(() => {
          if (this.ngControl.invalid) {
            this.animTrigger.set(true);
          }
        }),
        debounceTime(1000),
        tap(() => {
          this.animTrigger.set(false);
        }),
        takeUntilDestroyed(this.#destroyRef),
      )
      .subscribe();
  }

  registerOnTouched = (): void => undefined;
  registerOnChange = (): void => undefined;
  writeValue = (value: boolean): void => {
    this.#value = value;
  };
  checkValid(control: FormControl<boolean>): boolean {
    return control.dirty && control.invalid;
  }
}
