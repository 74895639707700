import { Portal, PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, NgModule } from '@angular/core';
import { Observable } from 'rxjs';

import { PortalService } from '@app/core/portal-service';

@Component({
  selector: 'app-bottom-control-panel',
  templateUrl: './bottom-control-panel.component.html',
  styleUrls: ['./bottom-control-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BottomControlPanelComponent {
  @Input() panelClass = '';
  controlPanelPortal$: Observable<Portal<any> | undefined>;
  constructor(private readonly portalService: PortalService) {
    this.controlPanelPortal$ = this.portalService.controlPanelPortal.value$;
  }
}
@NgModule({
  declarations: [BottomControlPanelComponent],
  imports: [CommonModule, PortalModule],
  exports: [BottomControlPanelComponent],
})
export class BottomControlPanelModule {}
