import { Injectable } from '@angular/core';
import { Permissions } from '@dev-fast/types';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';

import { UserState } from '@app/core/state/user-store';

@Injectable()
export class HasPermissionService {
  @Select(UserState.permissions)
  public readonly permissions$!: Observable<Permissions[]>;
}
