import {
  DefaulSortingTypes,
  DefaultPriceFilters,
  DefaultSortMethods,
  ICaseCollection,
  ICaseCollectionType,
  ICaseEventCollection,
  ICasesFilter,
  ICategoryLink,
  IHistoryOfOpenCases,
} from '@dev-fast/types';

import { CommonFiltersStateModel } from '../..';

export interface ICasesStateModel extends CommonFiltersStateModel<ICasesFilter, DefaulSortingTypes> {
  categories: ICaseCollection | null;
  eventCategories: ICaseEventCollection[];
  eventCategoriesSuccess: boolean;
  categoriesLinks: ICategoryLink[];
  freeCasesAvailable: number;
  casesHistory: IHistoryOfOpenCases | null;
  categoryType: ICaseCollectionType;
}

export const CASES_INITIAL_STATE: ICasesStateModel = {
  categories: null,
  eventCategories: [],
  eventCategoriesSuccess: false,
  categoriesLinks: [],
  freeCasesAvailable: 0,
  casesHistory: null,
  categoryType: 'all',
  filters: {
    sortBy: DefaulSortingTypes.DEFAULT,
    query: '',
    minPrice: 10,
  },
  sortingMethods: [...DefaultSortMethods],
  priceFilters: [...DefaultPriceFilters],
  priceRange: null,
};
