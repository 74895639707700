import { HttpErrorResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { PayService, WithdrawalApiService } from '@dev-fast/backend-services';
import {
  INotifyData,
  ITransactionDto,
  IWithdrawalActiveMethod,
  IWithdrawalMethod,
  IWithdrawalMethodGate,
  IWithdrawalMethods,
  IWithdrawalReceive,
  IWithdrawalReceiveDto,
  IWithdrawalWithdrawResponse,
  ModalNames,
  NotificationCategory,
  TransactionStatus,
  WalletTransactionTypeId,
  WithdrawalType,
} from '@dev-fast/types';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { filter, orderBy } from 'lodash-es';
import { DateTime } from 'luxon';
import { catchError, forkJoin, map, Observable, tap, throwError } from 'rxjs';

import { NotificationsService } from '@app/core/notification-service';
import { CloseRoutableModal, OpenModal } from '@app/core/state/modals';
import { UserState } from '@app/core/state/user-store';
import { mapFromWithdrawalHistoryToTransactionDto } from '@app/shared/utils/transformation';

import {
  ClearWithdrawalState,
  GetWithdrawalHistory,
  GetWithdrawalMethods,
  RequestWithdrawalReceive,
  SetActiveWithdrawalMethod,
  Withdraw,
} from './withdrawal.actions';
import { WITHDRAWAL_INITIAL_STATE, WithdrawalStateModel } from './withdrawal-state.model';

const PAYMENT_SYSTEM_DICTIONARY: Record<string, string> = {
  bitcoin123: 'BTC',
  bnb: 'BNB',
  busd: 'BUSD',
  dash: 'DASH',
  doge: 'DOGE',
  ethereum: 'ETH',
  ltc: 'LTC',
  matic: 'MATIC',
  skrill_eur: 'EURO',
  skrill_usd: 'USD',
  tether: 'USDT (ERC20)',
  tether_trc20: 'USDT (TRC20)',
  usdc: 'USDC (ERC20)',
  usdc_trc20: 'USDC (TRC20)',
  ton: 'TON',
  tron: 'TRX',
};

@State<WithdrawalStateModel>({
  name: 'withdrawal',
  defaults: WITHDRAWAL_INITIAL_STATE,
})
@Injectable()
export class WithdrawalState {
  readonly #_notificationsService = inject(NotificationsService);
  readonly #_store = inject(Store);
  readonly #_withdrawalApiService = inject(WithdrawalApiService);
  readonly #_payService = inject(PayService);

  @Selector()
  static activeMethod({ activeMethod }: WithdrawalStateModel): IWithdrawalActiveMethod | null {
    return activeMethod;
  }
  @Selector()
  static receive({ receive }: WithdrawalStateModel): IWithdrawalReceive | null {
    return receive;
  }
  @Selector()
  static withdrawalHistory({ withdrawalHistory }: WithdrawalStateModel): Record<string, ITransactionDto[]> | null {
    return withdrawalHistory;
  }
  @Selector()
  static withdrawalTypes({ withdrawalTypes }: WithdrawalStateModel): Record<WithdrawalType, IWithdrawalMethod[]> | null {
    return withdrawalTypes;
  }

  @Action(ClearWithdrawalState)
  clearWithdrawalState({ patchState }: StateContext<WithdrawalStateModel>): void {
    patchState({
      ...WITHDRAWAL_INITIAL_STATE,
    });
  }
  @Action(GetWithdrawalHistory, { cancelUncompleted: true })
  getHistory({ patchState }: StateContext<WithdrawalStateModel>): Observable<ITransactionDto[]> {
    return forkJoin({
      paymentHistory: this.#_payService.getPaymentHistory({
        typeIds: `${WalletTransactionTypeId.P2P_PAYOUT}`,
      }),
      withdrawalHistory: this.#_withdrawalApiService.getHistory(),
    }).pipe(
      map(({ paymentHistory, withdrawalHistory }) => {
        return [...mapFromWithdrawalHistoryToTransactionDto(withdrawalHistory), ...paymentHistory];
      }),
      tap((response) => {
        patchState({
          withdrawalHistory: this.#_parseHistoryItemsByDate(response),
        });
      }),
    );
  }
  @Action(GetWithdrawalMethods, { cancelUncompleted: true })
  getMethods({
    patchState,
    getState,
  }: StateContext<WithdrawalStateModel>): Observable<{ success: boolean; methods: IWithdrawalMethods }> | undefined {
    const userId = this.#_store.selectSnapshot(UserState.user)?.id;
    const { withdrawalTypes } = getState();
    if (userId && !withdrawalTypes) {
      return this.#_withdrawalApiService.getMethods(userId).pipe(
        tap((response) => {
          patchState({
            withdrawalTypes: this.#_updateMethods(response.methods),
          });
        }),
      );
    }
    return;
  }
  @Action(RequestWithdrawalReceive)
  requestWithdrawalReceive(
    { patchState, getState }: StateContext<WithdrawalStateModel>,
    { payload }: RequestWithdrawalReceive,
  ): Observable<IWithdrawalReceiveDto> | void {
    const { activeMethod } = getState();
    const activeGate = activeMethod?.method?.gates[0];
    if (activeGate) {
      return this.#_withdrawalApiService.withdrawalReceive(payload).pipe(
        tap((receive) => {
          patchState({
            receive: this.#_receiveAdapter(receive, activeGate),
          });
        }),
      );
    }
  }
  @Action(SetActiveWithdrawalMethod)
  setActiveMethod({ patchState, getState }: StateContext<WithdrawalStateModel>, { typeName, method }: SetActiveWithdrawalMethod): void {
    const { withdrawalTypes } = getState();
    let activeMethod: IWithdrawalActiveMethod | null;
    if (withdrawalTypes && typeName) {
      const selectedType = withdrawalTypes[typeName];
      const selectedMethod = selectedType.find((m) => m.name === method);
      activeMethod = { typeName: typeName, method: selectedMethod };
      patchState({
        activeMethod: activeMethod,
      });
    } else {
      activeMethod = null;
      patchState({
        activeMethod: activeMethod,
      });
    }
  }
  @Action(Withdraw)
  withdraw(
    { dispatch }: StateContext<WithdrawalStateModel>,
    { paymentSystem, coinsAmount, wallet }: Withdraw,
  ): Observable<IWithdrawalWithdrawResponse> | undefined {
    const userCountry = this.#_store.selectSnapshot(UserState.userCountry);
    if (!userCountry) {
      return;
    }
    return this.#_withdrawalApiService
      .withdraw({
        paymentSystem: paymentSystem,
        coinsAmount: coinsAmount,
        paymentWallet: wallet,
        ip: userCountry.ip || '',
      })
      .pipe(
        tap((response) => {
          if (!response || !response.success) {
            throw new Error('ERROR.WITHDRAW.WITHDRAW_LOCKED');
          }
          dispatch([
            new CloseRoutableModal(),
            new GetWithdrawalHistory(),
            new OpenModal(ModalNames.TRANSACTION_NOTIFICATION, {
              paymentStatus: TransactionStatus.PAYOUT_SUCCESSFUL,
              params: {
                amount: response.coinsAmount,
                id: response.id,
              },
            } as INotifyData),
          ]);
        }),
        catchError((e) => {
          const errorMsg =
            e.error && e.error.message ? e.error.message : typeof e.error === 'string' ? e.error : 'ERROR.WITHDRAW.WITHDRAW_LOCKED';
          dispatch([
            new CloseRoutableModal(),
            new OpenModal(ModalNames.TRANSACTION_NOTIFICATION, {
              paymentStatus: TransactionStatus.PAYOUT_ERRORED,
              customMessage: errorMsg,
              params: {
                amount: errorMsg === 'ERROR.WITHDRAW.WITHDRAW_LOCKED_WIN_MORE' ? e.error.params.amount : undefined,
              },
            } as INotifyData),
          ]);

          return throwError(() => e);
        }),
      );
  }
  #_updateMethods(method: IWithdrawalMethods): Record<WithdrawalType, IWithdrawalMethod[]> {
    const dictionary: Record<WithdrawalType, IWithdrawalMethod[]> = {
      [WithdrawalType.CARDS]: [],
      [WithdrawalType.CRYPTO]: [],
    };
    const methods = Object.values(method);
    dictionary[WithdrawalType.CARDS] = methods.filter((m) => m.gates[0].type === 'email');
    dictionary[WithdrawalType.CRYPTO] = methods.filter((m) => m.gates[0].type !== 'email');
    return dictionary;
  }
  #_parseHistoryItemsByDate(history: ITransactionDto[]): Record<string, ITransactionDto[]> | null {
    if (history.length) {
      // Сортируем и фильтруем перед группировкой, чтобы не было ошибок. И понятнее код в reduce. Это потяжелее, но тут не высоко нагруженное место
      return this.#_sortHistoryItemsByDate(this.#_filterHistoryItemsByTimeStamp(history)).reduce(
        (acc: Record<string, ITransactionDto[]>, cur: ITransactionDto) => {
          // Date.parse потому-что формат с сервера разный и не могу просто через fromISO или через fromFormat
          const date: string | null = cur.timestamp
            ? DateTime.fromJSDate(new Date(Date.parse(cur.timestamp!))).toFormat('yyyy-MM-dd')
            : null;
          return date ? { ...acc, [date]: [...(acc[date] || []), cur] } : acc;
        },
        {} as Record<string, ITransactionDto[]>,
      );
    }
    return null;
  }
  #_filterHistoryItemsByTimeStamp(history: ITransactionDto[]): ITransactionDto[] {
    return filter(history, (value) => !!value.timestamp);
  }
  #_sortHistoryItemsByDate(history: ITransactionDto[]): ITransactionDto[] {
    return orderBy(history, (transaction) => new Date(Date.parse(transaction.timestamp!)), 'desc');
  }

  #_receiveAdapter(receiveDto: IWithdrawalReceiveDto, activeGate: IWithdrawalMethodGate): IWithdrawalReceive {
    return {
      currency: PAYMENT_SYSTEM_DICTIONARY[activeGate.name] || activeGate.title,
      rate: receiveDto.rate || 0,
      receive: receiveDto.receive || 0,
      coinsAmount: receiveDto.coinsAmount || 0,
      coinsAmount2: receiveDto.coinsAmount2 || 0,
      crate: receiveDto.crate || 0,
      percent: activeGate.commission,
      decimal: receiveDto.decimal || 0,
      payback: receiveDto.payback || 0,
      needPlayCoinsAmount: receiveDto.needPlayCoinsAmount || 0,
    };
  }
  #_throeError(e: HttpErrorResponse, msg = 'Error in withdrawal'): Observable<never> {
    const { error } = e;
    this.#_notificationsService.addErrorNotification(
      error.error && error.error.message ? error.error.message : typeof error.error === 'string' ? error.error : msg,
      { category: NotificationCategory.BALANCE },
    );
    return throwError(() => e);
  }
}
