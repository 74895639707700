<ng-container *ngIf="{ applyActionStatus: applyActionStatus$ | async } as data">
  <form
    class="promocode__wrapper"
    [formGroup]="promocodeForm"
    [class]="activeClasses(promoStatus, isCompact)"
  >
    <ng-container *ngIf="!isCompact; else compactTemplate">
      <div class="promocode__section">
        <mat-icon
          class="promocode__label-icon"
          [svgIcon]="'promo-ticket'"
        ></mat-icon>
        <div class="promocode__label-wrapper">
          <div class="promocode__label-text promocode__label-text_bold">
            <ng-container *ngIf="promoStatus === promoStatusEnum.SUCCESS || promoStatus === promoStatusEnum.DEFAULT; else errorTmpl">
              {{ 'LOCAL.PROMOCODE_WIDGET.MAINLABEL.' + promoStatus | uppercase | translate }}
            </ng-container>
            <ng-template #errorTmpl>
              {{ errorMessageConstructor(errorMessage) | uppercase | translate }}
            </ng-template>
          </div>
          <div
            class="promocode__label-text promocode__label-text_regular"
            [ngSwitch]="promoStatus"
          >
            <ng-container *ngSwitchCase="promoStatusEnum.DEFAULT">
              <span>{{ 'LOCAL.PROMOCODE_WIDGET.PROMOCODE_LABEL' | translate }} </span>
              <a
                class="promocode__label-text promocode__label-text_green promocode__label-text_underline"
                (click)="useDefPromo()"
              >
                {{ 'LOCAL.PROMOCODE_WIDGET.USE_OUR_CORE' | translate }}
              </a>
            </ng-container>
            <ng-container *ngSwitchCase="promoStatusEnum.ERROR">
              <span>
                {{ 'LOCAL.PROMOCODE_WIDGET.PROMOCODE_CHECK' | translate }}
              </span>
            </ng-container>
            <ng-container *ngSwitchCase="promoStatusEnum.SUCCESS">
              <ng-container *ngIf="bonusAmountConstructor(reward) as bonus">
                <span class="promocode__label-text promocode__label-text_green">
                  {{ 'LOCAL.PROMOCODE_WIDGET.PROMOCODE_BONUS' | translate }}
                  <ng-container *ngIf="bonus.bonusPercent">
                    {{ bonus.bonusPercent }}
                  </ng-container>
                  <ng-container *ngIf="bonus.bonusCoins"> + {{ bonus.bonusCoins | appCurrency }} </ng-container>
                  <ng-container *ngIf="bonus.bonusCases">
                    (+ {{ bonus.bonusCases }} {{ 'LOCAL.PROMOCODE_WIDGET.PROMOCODE_BONUS_CASE' | translate }})
                  </ng-container>
                </span>
              </ng-container>
            </ng-container>
          </div>
        </div>
      </div>
      <div class="promocode__section promocode__section_form">
        <app-ui-input
          [class]="promoStatus"
          [prefixIcon]="showIcon(promoStatus)"
          formControlName="code"
          [canPaste]="true"
          [placeholder]="'LOCAL.PROMOCODE_WIDGET.INPUT_PLACEHOLDER' | translate"
        >
        </app-ui-input>
        <button
          [disabled]="isButtonDisabled(promocodeForm.invalid, data.applyActionStatus)"
          class="btn btn-semi-round md btn-primary-outline-green--design promocode__activate-button"
          (click)="activatePromocode()"
        >
          {{ 'LOCAL.PROMOCODE_WIDGET.APPLY' | translate }}
        </button>
      </div>
    </ng-container>
    <ng-template #compactTemplate>
      <div class="promocode__section">
        <div class="promocode__label-wrapper promocode__label-wrapper_compact">{{ 'LOCAL.PROMOCODE_WIDGET.TITLE' | translate }}:</div>
      </div>
      <div class="promocode__section promocode__section_form">
        <app-ui-input
          [class]="promoStatus"
          [prefixIcon]="showIcon(promoStatus)"
          formControlName="code"
          [canPaste]="true"
          [placeholder]="'LOCAL.PROMOCODE_WIDGET.INPUT_PLACEHOLDER' | translate"
        >
          <ng-container *ngIf="promocodeForm.dirty; else bonusTemplate">
            <button
              [disabled]="isButtonDisabled(promocodeForm.invalid, data.applyActionStatus)"
              class="btn promocode__activate-button promocode__activate-button_clear"
              (click)="activatePromocode()"
            >
              {{ 'LOCAL.PROMOCODE_WIDGET.APPLY' | translate }}
            </button>
          </ng-container>
          <ng-template #bonusTemplate>
            <ng-container *ngIf="bonusAmountConstructor(reward) as bonus">
              <div class="bonus-container">
                <ng-container *ngIf="bonus.bonusCases">
                  <div class="bonus-container__case">
                    {{ 'LOCAL.PROMOCODE_WIDGET.PROMOCODE_BONUS_CASE' | translate }}
                  </div>
                </ng-container>
                <ng-container *ngIf="bonus.bonusPercent">
                  <div class="bonus-container__amount">
                    {{ bonus.bonusPercent }}
                  </div>
                </ng-container>
                <ng-container *ngIf="bonus.bonusCoins">
                  <div class="bonus-container__amount">+ {{ bonus.bonusCoins | appCurrency }}</div>
                </ng-container>
              </div>
            </ng-container>
          </ng-template>
        </app-ui-input>
      </div>
      <ng-container *ngIf="minPayment">
        <div class="promocode__description">
          {{ 'LOCAL.PAYMENT.REFILL.MONEY_REFILL_DETAILS.FROM_FULL' | translate }}
          ${{ minPayment }}
        </div>
      </ng-container>
    </ng-template>
  </form>
</ng-container>
