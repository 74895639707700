import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';

import { SetData, SetDescription, SetTitle } from './seo.action';
import { ISeoData, SEO_INITIAL_STATE, SeoStateModel } from './seo.state.model';

@State<SeoStateModel>({
  name: 'seo',
  defaults: SEO_INITIAL_STATE,
})
@Injectable()
export class SeoState {
  @Selector()
  public static data({ data }: SeoStateModel): ISeoData {
    return data;
  }

  @Selector()
  public static title({ title }: SeoStateModel): { value: string; data: any } {
    return title;
  }

  @Selector()
  public static description({ description }: SeoStateModel): { value: string; data: any } {
    return description;
  }

  @Action(SetData)
  public setData({ patchState }: StateContext<SeoStateModel>, { data }: SetData): void {
    patchState({ data });
  }

  @Action(SetDescription)
  public setDescription({ patchState }: StateContext<SeoStateModel>, { description }: SetDescription): void {
    patchState({ description });
  }

  @Action(SetTitle)
  public setTitle({ patchState }: StateContext<SeoStateModel>, { title }: SetTitle): void {
    patchState({ title });
  }
}
