<div class="trade-stub">
  <div class="trade-stub__label tw-pb-8">
    {{ 'AUTHORIZATION.SIGN_TO_TRADE' | translate }}
  </div>
  <button
    class="trade-stub__login-btn btn btn-primary--design"
    (click)="authorize()"
  >
    <mat-icon svgIcon="steam"></mat-icon>
    {{ 'LOBBY.STEAM_LOGIN' | translate }}
  </button>
  <a
    class="btn-close tw-underline tw-text-sm tw-pt-3 tw-lowercase"
    (click)="closePanel()"
  >
    {{ 'GAMES.CLOSE' | translate }}
  </a>
</div>
