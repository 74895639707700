import { JsonPipe, NgIf, NgSwitch, NgSwitchCase, NgSwitchDefault, NgTemplateOutlet } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  Output,
  signal,
  SimpleChanges,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatIconModule } from '@angular/material/icon';
import { RouterLink } from '@angular/router';
import { CasesBackendService } from '@dev-fast/backend-services';
import { ICaseItemCard, ICaseItemDtoV2, IMarketplaceKitData, ModalNames, P2pItemStatus, PROJECT } from '@dev-fast/types';
import { TranslateModule } from '@ngx-translate/core';
import minBy from 'lodash/minBy';
import { BehaviorSubject, filter, map, switchMap } from 'rxjs';

import { CurrencyComponent } from '@app/core/currency';
import { EnvironmentService } from '@app/core/environment-service';
import { CurrencyFormatModule, StopPropagationModule } from '@app/shared/directives';
import { SHOW_CASE_PRICE } from '@app/shared/utils';

import { SkinItemNewModule } from '../skin-item-new/skin-item-new.component';
import { CustomCaseCardComponent } from './components/custom-case-card/custom-case-card.component';
import { ICaseItem, IFilterCase, IFilterCaseWitchIcon } from './market-item-card.types';

const enum ETemplateName {
  DEFAULT = 'default',
  CUSTOM_CASE_CARD = 'customCaseCard',
}

@Component({
  selector: 'app-ui-market-item-card',
  templateUrl: './market-item-card.component.html',
  styleUrls: ['./market-item-card.component.scss'],
  standalone: true,
  imports: [
    MatIconModule,
    CurrencyComponent,
    TranslateModule,
    CurrencyFormatModule,
    StopPropagationModule,
    SkinItemNewModule,
    NgIf,
    NgTemplateOutlet,
    CustomCaseCardComponent,
    NgSwitch,
    NgSwitchCase,
    NgSwitchDefault,
    RouterLink,
    JsonPipe,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MarketItemCardComponent implements OnChanges {
  readonly #environmentService = inject(EnvironmentService);
  readonly #casesBackendService = inject(CasesBackendService);

  readonly showCasePrice = inject(SHOW_CASE_PRICE);

  @Input() item!: IMarketplaceKitData | ICaseItemDtoV2;
  @Input() status!: P2pItemStatus;
  @Input() itemDropProbability = ''; // процент выпадения предмета для показа в левом углу

  @Output() clickItem: EventEmitter<void> = new EventEmitter<void>();
  @Output() openModal: EventEmitter<{ name: ModalNames; payload?: any }> = new EventEmitter<{
    name: ModalNames;
    payload?: any;
  }>();
  @Output() openCase: EventEmitter<IFilterCaseWitchIcon> = new EventEmitter<IFilterCaseWitchIcon>();

  showTemplateName = signal<ETemplateName>(ETemplateName.DEFAULT);
  marketItem = signal<IMarketplaceKitData | null>(null);
  caseItem = signal<ICaseItemCard | null>(null);
  isHovered = signal<boolean>(false);

  #filterCase$ = new BehaviorSubject<IFilterCase | null>(null);

  getAvailabilityItemInCases = toSignal<ICaseItem>(
    this.#filterCase$.pipe(
      filter((filterCase) => !!filterCase && this.showCasePrice),
      switchMap((filterCase) => this.#casesBackendService.getAvailabilityItemInCases(filterCase || { shortName: '', skin: '' })),
      map((cases) => minBy(cases, 'lastRevision.price') as ICaseItemDtoV2),
      map((caseItem) => {
        const [firstItem] = caseItem?.lastRevision?.items || [];

        const { shortName = '', skin = '', icon = '' } = firstItem?.inventoryItem?.baseItem || {};

        return {
          id: caseItem?.id || 0,
          price: caseItem?.lastRevision.price || 0,
          shortName,
          skin,
          icon,
        };
      }),
    ),
  );

  kitPrice = computed<{ price: number; recommendedPrice: number }>(() => {
    const marketItem = this.marketItem();
    const { price = 0, recommendedPrice = 0 } = marketItem || {};

    return { price, recommendedPrice };
  });

  getAvailabilityComputed = computed(() => {
    const price = this.kitPrice().price;
    const caseItem = this.getAvailabilityItemInCases();
    const casePrice = caseItem?.price || 0;

    return casePrice < price ? caseItem : { price: 0 };
  });

  kitColor = computed(() => {
    const marketItem = this.marketItem();
    const [firstItem] = marketItem?.items || [];
    const { color, rarity } = firstItem?.baseItem || {};
    return { color, rarity };
  });

  backgroundRGBAColor = computed(() => {
    const marketItem = this.marketItem();
    const [firstItem] = marketItem?.items || [];
    const { color } = firstItem?.baseItem || {}; // #ffffff

    const hex = color.replace('#', '');
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);

    return {
      color: `rgba(${r}, ${g}, ${b}, 0.1)`,
      hover: `rgba(${r}, ${g}, ${b}, 0.7)`,
    };
  });

  readonly ModalNames = ModalNames;

  openModalByName(modalName: ModalNames, payload?: any): void {
    this.openModal.emit({ name: modalName, payload: payload });
  }

  openProduct(): void {
    const { shortName = '', skin = '', icon = '', id = 0 } = this.getAvailabilityItemInCases() || {};

    this.openModal.emit({ name: ModalNames.CASE, payload: id });

    this.openCase.emit({ shortName, skin, icon });
  }

  // angular hooks
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['item']) {
      if ('items' in this.item) {
        //Кастомные кейсы в модуле маркета покеазываем только для сборки маркета
        const [firstItem] = this.item.items;

        const isProjectMarket = this.#environmentService.isProject({ name: PROJECT.MARKET });

        const { shortName, skin } = firstItem.baseItem;
        if (shortName && skin) {
          this.#filterCase$.next({ shortName, skin });
        }

        if (firstItem.caseId && firstItem.casePrice && isProjectMarket) {
          this.caseItem.set({
            id: firstItem.caseId,
            name: firstItem.baseItem.name,
            itemImage: firstItem.baseItem.icon,
            casePrice: firstItem.casePrice, // цена открытия кейса
            marketPrice: firstItem.price, // цена покупки кейса в маркете
          });
          this.showTemplateName.set(ETemplateName.CUSTOM_CASE_CARD);
        } else {
          this.marketItem.set(this.item);
          this.showTemplateName.set(ETemplateName.DEFAULT);
        }
      } else {
        this.caseItem.set({ ...this.item, casePrice: this.item.lastRevision.price });
        this.showTemplateName.set(ETemplateName.CUSTOM_CASE_CARD);
      }
    }
  }
}
