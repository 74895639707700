import { GameStatusesFF, IActivities, IInventoryItem, LegacyGameConfig, LegacyGameState, LegacyLiteGameState } from '@dev-fast/types';

export class GetAllGamesSettings {
  static readonly type = '[Games] Get items';
}
export class FindGameSettings {
  static readonly type = '[Games] Find item';
  constructor(public key: string) {}
}
export class SetCurrentGame {
  static readonly type = '[Games] Set current game';
  constructor(public key: string) {}
}
export class SetCurrentGameActivities {
  static readonly type = '[Games] Set current game activities';
  constructor(public activities: IActivities) {}
}
export class UpdStatuses {
  static readonly type = '[Games] Upd Statuses';
  constructor(public statuses: GameStatusesFF) {}
}
/**
 * делал для Gleam
 */
export class SuccessfulBid {
  static readonly type = '[Games] Successful Bid';
  constructor(public gameName: string) {}
}

export class PlaceBetLegacy {
  static readonly type = '[Games] Place Bet Legacy';
  constructor(
    public gameName: 'classic' | 'fast',
    public payload?: any,
  ) {}
}
export class ParticipateLegacy {
  static readonly type = '[Games] Participate Legacy';
  constructor(public items?: IInventoryItem[]) {}
}
export class ConfirmParticipateLegacy {
  static readonly type = '[Games] Confirm Participate Legacy';
  constructor(public payload: { items: any[]; timeout: number }) {}
}
export class ConfirmParticipateLegacySuccess {
  static readonly type = '[Games] Confirm Participate Legacy Success';
  constructor(public payload: { items: any[]; timeout: number }) {}
}
export class GameResultLegacy {
  static readonly type = '[Games] Game Result Legacy';
  constructor(
    public gameName: 'classic' | 'fast',
    public payload?: any,
  ) {}
}
export class ChangeConfigLegacy {
  static readonly type = '[Games] Change Config Legacy';
  constructor(
    public payload: LegacyGameConfig,
    public gameName?: 'classic' | 'fast',
  ) {}
}
export class ChangeStateLegacy {
  static readonly type = '[Games] Change State Legacy';
  constructor(
    public payload: LegacyGameState,
    public gameName: 'classic' | 'fast',
  ) {}
}
export class ChangeLiteStateLegacy {
  static readonly type = '[Games] Change Lite State Legacy';
  constructor(public payload: LegacyLiteGameState) {}
}
export class StartAutoBetLegacy {
  static readonly type = '[Games] Start AutoBet  Legacy';
  constructor(
    public gameName: 'x50' | 'crash',
    public payload?: any,
  ) {}
}
export class StopAutoBetLegacy {
  static readonly type = '[Games] Stop AutoBet  Legacy';
  constructor(
    public gameName: 'x50' | 'crash',
    public payload?: any,
  ) {}
}
