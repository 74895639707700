import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { IMarketplaceKitItem } from '@dev-fast/types';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-ui-p2p-lot-card',
  styleUrls: ['./p2p-lot-card.component.scss'],
  templateUrl: './p2p-lot-card.component.html',
})
export class P2pLotCardComponent implements OnChanges {
  @Input() isHidePrice = false;

  @Input() items: IMarketplaceKitItem[] | undefined;

  @Input() stickersLeft = false;

  @Output() showItemDetails: EventEmitter<void> = new EventEmitter<void>();

  kitLengthWithoutShownItems = 0;

  lotShortName = '';

  ngOnChanges(changes: SimpleChanges): void {
    const itemsProperty: keyof P2pLotCardComponent = 'items';
    const itemsCurrentValue = changes[itemsProperty]?.currentValue;

    if (itemsCurrentValue) {
      const [rawLotShortName] = itemsCurrentValue;

      this.lotShortName = this.#formatLotShortName(rawLotShortName);
      this.kitLengthWithoutShownItems = itemsCurrentValue.length - 2;
    }
  }

  showItemDetailsModal = (): void => this.showItemDetails.emit();

  #formatLotShortName(item: IMarketplaceKitItem): string {
    if (item.baseItem.weapon === 'other') {
      return 'P2P_SETTINGS.FILTERS.OTHER';
    }

    if (item.baseItem.shortName && item.baseItem.type === 'weapon') {
      return item.baseItem.shortName;
    }

    return item.baseItem.name.split('|')[0];
  }
}
