import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { Params, RouterModule } from '@angular/router';

import { LangRouterModule } from '@app/shared/directives';

@Component({
  selector: 'app-ui-brand',
  templateUrl: './brand.component.html',
  styleUrls: ['./brand.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BrandComponent {
  @Input() link = '';
  @Input() query: Params | null | undefined;
  @Input() mirrorIcon = 'img-domain';
  @Input() domainIcon = 'text-domain';
}

@NgModule({
  imports: [CommonModule, MatIconModule, RouterModule, LangRouterModule],
  declarations: [BrandComponent],
  exports: [BrandComponent],
})
export class BrandComponentModule {}
