import { IUser } from '../entities';
import { GameMode } from '../enums';
import { GameStatus } from './game-status.model';

export interface IGame {
  key: string;
  mode: GameMode;
  title: string;
  description?: string;
  routerLink?: string | null;
  order?: number;
}
export interface IMenuItemConfig {
  game: IGame;
  isNavbar?: boolean;
  status?: GameStatus;
  isTable: boolean | null;
}

export interface IAllGamesPanelConfig {
  games: Partial<Record<GameMode, IGame[]>>;
  state: Record<string, GameStatus> | null;
  isShowAllGames?: boolean;
  isTable: boolean | null;
}

export interface IWinnerBanner {
  user?: IUser;
  profit: number;
  isShow: boolean;
}
