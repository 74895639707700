<div class="tw-w-full">
  <canvas
    baseChart
    class="p2p-chart__canvas"
    [type]="'line'"
    [data]="lineChartData()"
    [options]="lineChartOptions"
    [legend]="true"
  >
  </canvas>
</div>
