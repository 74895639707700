<ng-container [ngSwitch]="showTemplateName()">
  <ng-container *ngSwitchCase="'customCaseCard'">
    <ng-container *ngTemplateOutlet="customCaseCard"></ng-container>
  </ng-container>
  <ng-container *ngSwitchDefault>
    <ng-container *ngTemplateOutlet="default"></ng-container>
  </ng-container>
</ng-container>

<ng-template #default>
  <ng-container *ngIf="marketItem() as marketElement">
    <div
      class="tw-w-full tw-flex tw-flex-col tw-cursor-pointer"
      [class.tw-h-full]="!getAvailabilityComputed()?.price"
    >
      <div
        class="tw-flex-1"
        [class.tw-h-56]="getAvailabilityComputed()?.price"
        [class.tw-h-72]="!getAvailabilityComputed()?.price"
      >
        <app-ui-skin-item-new
          class="tw-w-full tw-h-full"
          [kitPrice]="kitPrice()"
          [kitColor]="kitColor()"
          [newLook]="true"
          [status]="status"
          [id]="marketElement.id"
          [kit]="marketElement.items"
          (showItemDetails)="openModalByName(ModalNames.P2P_KIT_DETAILS, marketElement)"
          (skinClick)="clickItem.emit()"
        />
      </div>

      <!--Цена товара в кейсе-->
      <ng-container *ngIf="getAvailabilityComputed()?.price">
        <ng-container *ngIf="backgroundRGBAColor() as backgroundRGBA">
          <div
            class="min-case-cost tw-group"
            [style.backgroundColor]="isHovered() ? backgroundRGBA.hover : backgroundRGBA.color"
            (mouseenter)="isHovered.set(true)"
            (mouseleave)="isHovered.set(false)"
            (click)="openProduct()"
          >
            <span
              class="tw-whitespace-nowrap tw-opacity-0 tw-w-0 tw-transition-all tw-overflow-hidden tw-flex tw-duration-300 tw-ease-in-out group-hover:tw-opacity-100 group-hover:tw-w-6/12 group-hover:tw-pl-4 tw-flex-row tw-items-center tw-text-sm"
            >
              {{ 'MARKET.CASE_MODAL.IN_CASE' | translate }}
            </span>
            <span class="tw-inline-flex tw-flex-1 tw-justify-center tw-flex-row tw-items-center">
              <app-currency
                class="price"
                [value]="getAvailabilityComputed()?.price"
              />
            </span>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </ng-container>
</ng-template>

<ng-template #customCaseCard>
  <ng-container *ngIf="caseItem() as caseElement">
    <app-ui-custom-case-card
      [name]="caseElement.name"
      [status]="status"
      [casePrice]="caseElement.casePrice"
      [marketPrice]="caseElement.marketPrice"
      [itemDropProbability]="itemDropProbability"
      [backgroundImage]="caseElement.backgroundImage"
      [mainItemImage]="caseElement.itemImage"
      [enabled]="caseElement.enabled"
      [caseImage]="caseElement.caseImage"
      [historyImage]="caseElement.historyImage"
      (openCaseModal)="openModalByName(ModalNames.CASE, caseElement.id)"
      (buyCase)="clickItem.emit()"
    />
  </ng-container>
</ng-template>
