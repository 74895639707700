import { CommonModule } from '@angular/common';
import { Component, computed, DestroyRef, inject, Signal } from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { tap } from 'rxjs';
import { map } from 'rxjs/operators';

import { TRADE_BLOCK_ENGINE, TradeBlockEngine, TradeComponent } from '@app/shared/components';

import { DefaultSihModalTranslations, SIH_LINK, SihModalTranslations } from '../../model';
import { SihService } from '../../sih.service';
import { TradesService } from '../../trades.service';

@Component({
  selector: 'app-sih-check',
  standalone: true,
  imports: [CommonModule, TranslateModule, MatIconModule, TradeComponent],
  providers: [
    SihService,
    {
      provide: TRADE_BLOCK_ENGINE,
      useClass: TradesService,
    },
  ],
  templateUrl: './sih-check.component.html',
  styleUrls: ['../../shared-style.scss', './sih-check.component.scss'],
})
export class SihCheckComponent {
  readonly #engine: TradeBlockEngine = inject(TRADE_BLOCK_ENGINE);
  readonly #sihService = inject(SihService);
  readonly #destroyRef = inject(DestroyRef);
  readonly #dialogRef = inject(MatDialogRef<SihCheckComponent>);
  readonly dialogData: SihModalTranslations = inject(MAT_DIALOG_DATA) || DefaultSihModalTranslations;

  readonly loading: Signal<boolean | undefined> = toSignal(this.#sihService.checkUserLoading$);
  readonly isFirstStep: Signal<boolean | undefined> = toSignal(this.#sihService.sih$.pipe(map((sih) => (sih ? !sih.online : true))));
  readonly tradeLink: Signal<string | undefined> = toSignal(this.#engine.tradeLink$);
  readonly btnsDisabled: Signal<boolean | undefined> = computed(() => this.loading() || !this.tradeLink());
  readonly sihLink = SIH_LINK;
  constructor() {
    this.#sihService.sih$
      .pipe(
        tap((sih) => {
          if (sih && sih.online && sih.permission) {
            this.onClose();
          }
        }),
        takeUntilDestroyed(this.#destroyRef),
      )
      .subscribe();
  }

  onCheckSihInstall(): void {
    this.#sihService.checkUser();
  }
  onRequestPermissions(): void {
    this.#sihService.checkUser();
  }

  onClose(): void {
    this.#dialogRef.close();
  }
}
