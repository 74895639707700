<div
  class="arrow-wrapper"
  [class.show]="isShow"
>
  <mat-icon
    class="arrow-icon top-icon"
    [svgIcon]="'chevron-left'"
  ></mat-icon>
  <mat-icon
    class="arrow-icon bottom-icon"
    [svgIcon]="'chevron-left'"
  ></mat-icon>
</div>
