import {
  PaymentStatus,
  TransactionMethods,
  TransactionWithdrawSystem,
  WithdrawalType,
  WithdrawalWalletType,
  WithdrawState,
} from '../../enums';

export interface IWithdrawalFormModel {
  wallet: string;
  method: string;
  withdrawal: number | null;
  receive: number;
  recaptcha: string;
  deliverySpeed: string;
  agreement: boolean;
}
export interface IWithdrawalBaseRequest {
  paymentSystem: string;
  coinsAmount: number;
}
export interface IWithdrawalReceiveRequest extends IWithdrawalBaseRequest {
  feeIndex?: number;
}
export interface IWithdrawalWithdrawRequest extends IWithdrawalBaseRequest {
  paymentWallet: string;
  ip: string;
}
export interface IWithdrawalWithdrawResponse {
  success: boolean;
  coinsAmount: number;
  id: number;
}

export interface IWithdrawalReceiveDto {
  currency?: string;
  rate?: number;
  receive?: number;
  fixedCommission?: number;
  coinsAmount?: number;
  coinsAmount2?: number;
  crate?: number;
  decimal?: number;
  payback?: number;
  percent?: string;
  needPlayCoinsAmount?: number;
  reciveWithoutFee?: number;
}
export interface IWithdrawalReceive {
  currency: string;
  rate: number;
  receive: number;
  fixedCommission?: number;
  coinsAmount: number;
  coinsAmount2: number;
  crate: number;
  decimal: number;
  payback: number;
  percent: string;
  needPlayCoinsAmount: number;
  reciveWithoutFee?: number;
}

// TODO unknown @anubchik interface never used, never seen
export interface ISkinsBuyResponse {
  userId: number;
  successBuy: string[];
  failBuy: string[];
  pointsLeft: number;
  userName: string;
  userAva: string;
  isBet: boolean;
}

export interface IWithdrawResponse {
  id: number;
  userId: number;
  points: number;
  system: string;
  wallet: string;
  isFinished: boolean;
  isStarted: boolean;
  dateAdded: string;
  isApproved: boolean;
  payId: number;
  isReverted: boolean;
  dateFinished: string;
  price: number;
  timestampAdded: string;
  isPaid: boolean;
  timestampPaid: string;
  isRefunded: boolean;
  ip: string;
  transactionId: string;
  paymentUserId: string;
  paymentProvider: number;
  crate: number;
  type: string;
  extStatus: number;
  comment: string;
  state: string;
  approvedBy: number;
  fixedRubrate: number;
  errorCounter: number;
  isRejected: boolean;
  ipAddr: string;
  onHold: boolean;
  netAmount: number;
  coinsAmount?: number;
}

export type IWithdrawalMethods = Record<string, IWithdrawalMethod>;

export type IWithdrawalTypes = Record<WithdrawalType, IWithdrawalMethods>;
export interface IWithdrawalType {
  name: string;
  title: string;
  gates: IWithdrawalMethodGate[];
}
export interface IWithdrawalMethod {
  name: string;
  title: string;
  gates: IWithdrawalMethodGate[];
}

export interface IWithdrawalMethodGate {
  commission: string;
  feeSelector?: string[];
  gate: string;
  icon: string;
  minimum: number;
  name: string;
  regularExp: string;
  title: string;
  type: WithdrawalWalletType;
}
export interface IWithdrawalActiveMethod {
  typeName: WithdrawalType;
  method?: IWithdrawalMethod;
}
export interface IWithdrawalHistory {
  crate: number;
  currency: string;
  date_added: string;
  fixed_rubrate?: null;

  id: number;
  points: number;
  payment: IWithdrawalPayment;
  state: WithdrawState;
  system: TransactionWithdrawSystem;
  userGet?: number;
  wallet: string;
}

export interface IWithdrawalPayment {
  method: TransactionMethods;
  status: PaymentStatus;
  system: TransactionWithdrawSystem;
  wallet: string;
}
