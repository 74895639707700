import { inject, Injectable } from '@angular/core';
import { IUserDetailed, IUserP2pPermissionInterface, ModalNames, SihParams, StateActionStatus } from '@dev-fast/types';
import { Actions, Select } from '@ngxs/store';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { filter, Observable, of, switchMap } from 'rxjs';
import { map } from 'rxjs/operators';

import { OpenModal } from '@app/core/state/modals';
import { GetP2pPermissions, UserState } from '@app/core/state/user-store';
import { actionLoadStatus } from '@app/shared/utils';

import { SihModalTranslations } from './model';

@Injectable()
export class SihService {
  readonly #actions$ = inject(Actions);

  @Select(UserState.p2pPermissions)
  readonly p2pPermissions$!: Observable<IUserP2pPermissionInterface>;
  @Select(UserState.user)
  readonly user$!: Observable<IUserDetailed | null | undefined>;

  sih$: Observable<SihParams | undefined> = this.p2pPermissions$.pipe(
    filter((value) => !!value),
    map((permissions) => permissions?.connections?.SIH),
  );
  checkUserLoading$: Observable<boolean> = actionLoadStatus(this.#actions$, GetP2pPermissions, 10000).pipe(
    map((status) => status === StateActionStatus.DISPATCH),
  );
  checkBtnDisabled$: Observable<boolean> = this.sih$.pipe(map((sih) => !sih || !sih.online || !sih.permission));
  showSihWarn$ = this.user$.pipe(
    switchMap((user) => (user ? this.sih$.pipe(map((sih) => !sih || !sih.online || !sih.permission)) : of(false))),
  );
  @Dispatch() checkUser = (): GetP2pPermissions => new GetP2pPermissions();
  @Dispatch() openSihInstallModal = (translations?: SihModalTranslations): OpenModal => new OpenModal(ModalNames.SIH_INSTALL, translations);
}
