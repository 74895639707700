import { currencyDic, ICurrencyRate } from '@dev-fast/types';

export interface CurrencyStateModel {
  currency: ICurrencyRate | null;
  currencyList: ICurrencyRate[];
}
export const CURRENCY_INITIAL_STATE: CurrencyStateModel = {
  currency: null,
  currencyList: currencyDic,
};
