export interface IEmojiInfo {
  name: string;
  src: string;
  id: number;
}
export interface IEmojiTimeStampDTO {
  count: number; //emojis count
  id: number; //emoji id
  ts?: number[]; //timestamp
  lastUpdate?: number; //last timestamp
  userIds?: number[];
}
export interface IEmojiTimeStamp {
  id: number; //emoji id
  ts: number[]; //timestamp
}
export interface IEmojiState {
  emojis: Record<number, IEmojiTimeStampDTO>;
  lastUpdate: number;
  totalUniqueUsers: number;
  userIds: number[];
}
export interface IBattleEmojiNoty {
  state: IEmojiState;
  success: boolean;
}
export interface IBattleEndEmojisNoty {
  battleId: number;
  state: IEmojiState;
}
export interface IBattleEmojiSocketMessage {
  battleId: number;
  count: number;
  emojiId: number;
}
