import { Pipe, PipeTransform } from '@angular/core';
import { Observable, of, timer } from 'rxjs';
import { map } from 'rxjs/operators';

const msToTime = (msRemaining: number): string | null => {
  if (msRemaining < 0) {
    // eslint-disable-next-line no-console
    console.info('No Time Remaining:', msRemaining);
    return null;
  }

  const seconds: string | number = Math.floor((msRemaining / 1000) % 60);
  const minutes: string | number = Math.floor((msRemaining / (1000 * 60)) % 60);
  const hours: string | number = Math.floor((msRemaining / (1000 * 60 * 60)) % 24);

  const normSeconds = seconds < 10 ? '0' + seconds : seconds;
  const normMinutes = minutes < 10 ? '0' + minutes : minutes;
  const normHours = hours < 10 ? '0' + hours : hours;

  return hours > 0 ? `${normHours}:${normMinutes}:${normSeconds}` : `${normMinutes}:${normSeconds}`;
};
// надо докрутить. Когда делал время было огранчиено, поэтому пока обошелся форматером простым
@Pipe({
  name: 'countdown',
  standalone: true,
})
export class CountdownPipe implements PipeTransform {
  // DateTime.fromISO(nextStatusAt).toLocal().toMillis() - DateTime.local().toMillis()
  private getMsDiff = (futureDate: string | number): number => +new Date(futureDate) - Date.now();

  transform(dateTime: string | number, isDiff?: boolean): Observable<string | null> {
    const diff = typeof dateTime === 'number' && isDiff ? dateTime : this.getMsDiff(dateTime);

    if (!dateTime || diff < 0) {
      return of(null);
    }
    return timer(0, 1000).pipe(
      map(() => {
        return msToTime(diff);
      }),
    );
  }
}
@Pipe({
  name: 'countdownFormat',
  standalone: true,
})
export class CountdownFormatPipe implements PipeTransform {
  transform(msRemaining: number): string | null {
    if (msRemaining <= 0) {
      return null;
    }
    return msToTime(msRemaining);
  }
}
