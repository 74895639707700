import { DOCUMENT } from '@angular/common';
import { inject, Injectable, makeStateKey, TransferState } from '@angular/core';
import { Router } from '@angular/router';
import { LocalizationApiService } from '@dev-fast/backend-services';
import { ILocales, MAIN_LANG } from '@dev-fast/types';
import { TranslateService } from '@ngx-translate/core';
import { RouterNavigated } from '@ngxs/router-plugin';
import { Action, NgxsOnInit, Selector, State, StateContext } from '@ngxs/store';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { Environments, EnvironmentService } from '@app/core/environment-service';
import { RouterStateParams } from '@app/core/state/utils';
import { IS_SERVER_TOKEN } from '@app/shared/utils';

import { GetLocales, SetLanguage } from './language.action';
import { LanguageService } from './language.service';
import { LANGUAGE_INITIAL_STATE, LanguageStateModel } from './language.state.model';

const CURRENT_LANGUAGE_KEY = makeStateKey<ILocales>('currentLang');

@State<LanguageStateModel>({
  name: 'language',
  defaults: LANGUAGE_INITIAL_STATE,
})
@Injectable()
export class LanguageState implements NgxsOnInit {
  readonly #isServer = inject(IS_SERVER_TOKEN);
  readonly #doc = inject(DOCUMENT);
  readonly #environmentService = inject(EnvironmentService);
  readonly #apiService = inject(LocalizationApiService);
  readonly #transferState = inject(TransferState);
  readonly #translate = inject(TranslateService);
  readonly #router = inject(Router);

  readonly #environment: Environments = this.#environmentService.getEnvironment();

  @Selector()
  static locales({ locales }: LanguageStateModel): ILocales[] {
    return locales.filter((el: ILocales) => el.isActive);
  }

  @Selector()
  static lang({ lang }: LanguageStateModel): ILocales {
    return lang;
  }

  /**
   * Если мы находимся на клиенте, то при инициализации запрашиваем локали с рендера
   */
  ngxsOnInit({ dispatch }: StateContext<LanguageStateModel>): void {
    if (this.#isServer) {
      return;
    }

    const routerLang = LanguageService.getRouteLanguages()[MAIN_LANG];
    const defaultLocale = this.#transferState.get<ILocales>(CURRENT_LANGUAGE_KEY, routerLang);

    if (LanguageService.isAcceptedLanguageCode(defaultLocale.path)) {
      dispatch(new SetLanguage({ ...defaultLocale, isLocal: true }, false));
    }
  }

  /**
   * Обновление <html lang="  ">
   */
  #updateHeadLang(lang: string): void {
    this.#doc.querySelector('html')?.setAttribute('lang', lang);
  }

  @Action(SetLanguage)
  setLanguage({ patchState }: StateContext<LanguageStateModel>, { locale, useNavigation }: SetLanguage): Observable<any> {
    return this.#translate.use(locale.isLocal ? 'default-locale' : locale.path).pipe(
      tap(() => {
        this.#updateHeadLang(locale.path.substring(0, 2));
        patchState({
          lang: locale,
        });
        if (this.#isServer) {
          this.#transferState.set(CURRENT_LANGUAGE_KEY, locale as any);
        } else {
          // BB не нужно передавать денные, которые передаются из ССР. Передадим их после загрузки актуальных
          if (!locale.isLocal) {
            localStorage.setItem('lang', locale.path);
          }
        }
        if (useNavigation) {
          this.#navigateToLangUrl(locale);
        }
      }),
    );
  }

  /**
   * Навигация к аналогичной текущей ссылке, сменив язык на переданный в аргументы
   */
  #navigateToLangUrl(locale: ILocales): void {
    const lang = locale.path.substring(0, 2);
    const pathLang = this.#router.url.split('/')[1];

    const [path, params] = this.#router.url.split('?');
    const newState: string = LanguageService.updateLanguageURL(path, lang, pathLang);

    const normParams = (): any => {
      if (!params) {
        return null;
      }
      return JSON.parse('{"' + params.replace(/&/g, '","').replace(/=/g, '":"') + '"}', (key, value) => {
        return key === '' ? value : decodeURIComponent(value);
      });
    };
    this.#router.navigate([newState], { queryParams: normParams() });
  }

  /**
   * Проверяет маршрут по которому перешел юзер. Если маршрут не содержит языковой код, который юзер сохранил у себя, то
   * перенаправляем его на нужный языковой маршрут
   */
  @Action(RouterNavigated)
  // NOTE Костыль. Раньше этот функционал был в гварде. Но гвард некорректно работал с модалками.
  // Так что вынесли со старта маршрутизации на конец
  onNavigate({ getState, dispatch }: StateContext<LanguageStateModel>, { routerState }: RouterNavigated<RouterStateParams>): void {
    const { fragment, queryParams, url } = routerState;
    // Get user selected lang
    let localLang = !this.#isServer ? localStorage.getItem('lang')?.slice(0, 2) : null;
    let pathLang = url.split('/')[1].split(/[(?]/)[0];
    if (pathLang.includes('icons')) {
      return;
    }

    if (!LanguageService.isAcceptedLanguage(pathLang)) {
      pathLang = MAIN_LANG;
    }

    if (!this.#isServer && localLang && !LanguageService.isAcceptedLanguage(localLang)) {
      localLang = MAIN_LANG;
    }
    const snapshotLang = getState().lang;
    // If user has already saved the language but current path does not match it, navigate to correct path
    if (pathLang && localLang && localLang !== pathLang) {
      const [path] = url.split('?');
      // transform path into correct
      const newState = decodeURIComponent(LanguageService.updateLanguageURL(path, localLang, pathLang));
      const paths: any[] = [newState.split('(')[0]];
      // if current path includes outlet - add it to navigation
      const outlets = newState.match(/\((.*):(.*)\)/);
      if (outlets) {
        paths.push({ outlets: { [outlets[1]]: [...outlets[2].split('/')] } });
      }

      if (!this.#environment.production) {
        // eslint-disable-next-line no-console
        console.log(`change route: ${url} -> ${newState}`);
      }
      this.#router.navigate(paths, { queryParams: queryParams, replaceUrl: true, fragment: fragment || undefined });
    } else if (snapshotLang.isLocal || snapshotLang.path.substring(0, 2) !== pathLang) {
      dispatch(new SetLanguage(LanguageService.getRouteLanguages()[pathLang], false));
    }
  }

  /**
   * Запрос локалей
   */
  @Action(GetLocales)
  getLocales({ patchState }: StateContext<LanguageStateModel>): Observable<ILocales[]> {
    return this.#apiService.getAvailableLocales().pipe(
      tap((locales) =>
        patchState({
          locales,
        }),
      ),
    );
  }
}
