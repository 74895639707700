<ng-container *ngIf="modes.length; else loadingTmpl">
  <div
    class="main-control tw-cursor-pointer"
    (click)="open = !open"
  >
    <mat-icon [svgIcon]="'all-games'"> </mat-icon>
  </div>
</ng-container>

<ng-template #loadingTmpl>
  <div class="loader">
    <app-ui-spinner-loader></app-ui-spinner-loader>
  </div>
</ng-template>
<div
  class="wrapper"
  *ngIf="open"
  (clickOutside)="open = false"
>
  <div class="main-content custom-scroll">
    <div class="content">
      <ng-container *ngFor="let mode of modes">
        <ui-category
          *ngIf="getMode(mode) as category"
          [title]="modesDesc[mode].title"
          [description]="modesDesc[mode].description"
        >
          <ui-game-card
            *ngFor="let game of category"
            [game]="game"
            [titleType]="'bottom'"
            (click)="closeMenu()"
          >
          </ui-game-card>
        </ui-category>
      </ng-container>
    </div>
    <div
      class="promo-content"
      *hasPermission="[permissions.MEMBER, permissions.CAN_USE]"
    >
      <ui-category [title]="'Freecoins'">
        <ui-any-icon-card
          *ngFor="let item of promoCategory"
          [class]="item.label"
          [icon]="item.icon"
          [title]="item.label"
          [titleType]="'bottom'"
          (click)="item.callback && item.callback()"
        >
        </ui-any-icon-card>
      </ui-category>
    </div>
  </div>

  <div class="bottom-content">
    <div
      class="button tw-cursor-pointer"
      (click)="open = !open"
    >
      <mat-icon
        class="close-btn"
        [svgIcon]="'menu-close'"
      >
      </mat-icon>
    </div>
    <div
      class="last-games"
      *ngIf="lastGames && lastGames.length"
    >
      <ng-scrollbar
        track="horizontal"
        class="last-games--mobile"
      >
        <div class="games">
          <ui-game-card
            *ngFor="let game of lastGames"
            [game]="game"
          ></ui-game-card>
        </div>
      </ng-scrollbar>
    </div>
  </div>
</div>

<div
  class="mobile"
  *ngIf="openMobile"
  (clickOutside)="openMobile = false"
>
  <app-ui-mobile-wrapper (closeWrapper)="onSwipe()">
    <app-promocode-mobile></app-promocode-mobile>
  </app-ui-mobile-wrapper>
</div>
