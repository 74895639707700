import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IMenuItemConfig } from '@dev-fast/types';

@Component({
  selector: 'app-ui-menu-item-v2',
  templateUrl: './menu-item.component.html',
  styleUrls: ['./menu-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuItemV2Component {
  @Input() public isNew!: boolean;
  @Input() public itemConfig!: IMenuItemConfig;
}
