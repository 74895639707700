import { Signal } from '@angular/core';

import { ControlStateEnum } from '../enums';
import { ILink } from './link.model';

export type MenuItem = Partial<ILink> & {
  callback?: () => void;
  status?: ControlStateEnum;
  itemClass?: string;
  notificationCount?: Signal<number | undefined>; // кол-во нотиков рядом с кнопкой
};
