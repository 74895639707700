import { Injectable } from '@angular/core';
import {
  GameIds,
  IHistoryInventoryItem,
  IHistoryParams,
  IInventoryInfo,
  IInventoryItem,
  IInventoryItemDetailed,
  IInventoryItemDetailedReq,
  IShop,
  IUserInventoryItemV2,
} from '@dev-fast/types';
import { Observable } from 'rxjs';

import { Environments, EnvironmentService } from '@app/core/environment-service';

import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class InventoryLegacyService {
  private environment: Environments;

  constructor(private readonly api: ApiService, private readonly environmentService: EnvironmentService) {
    this.environment = this.environmentService.environments;
  }
  public sellInventoryItems(data: { ids: number[] }): Observable<void> {
    return this.api.post<void>(`${this.environment.GATEWAY_URL}/api/inventory/items/sell`, data, {
      withCredentials: true,
    });
  }
  public sellAll(ignoreIds?: number[]): Observable<void> {
    return this.api.post<void>(
      `${this.environment.GATEWAY_URL}/api/inventory/v2/items/sell/all`,
      {
        ignoreIds: ignoreIds ? [...ignoreIds] : [],
      },
      { withCredentials: true }
    );
  }

  public freezeItems(ids: number[]): Observable<void> {
    return this.api.patch(
      `${this.environment.GATEWAY_URL}/api/inventory/v2/items/freeze`,
      {
        ids: ids,
        unfreeze: true,
      },
      {
        withCredentials: true,
      }
    );
  }
  public getInventoryItemDetailed(params: IInventoryItemDetailedReq): Observable<IInventoryItemDetailed> {
    return this.api.get(`${this.environment.GATEWAY_URL}/api/inventory/items/detailed`, {
      withCredentials: true,
      params,
    });
  }
  public requestInventory(params?: object): Observable<Record<GameIds, IUserInventoryItemV2[]>> {
    return this.api.get<Record<GameIds, IUserInventoryItemV2[]>>(`${this.environment.GATEWAY_URL}/api/inventory/v2/items`, {
      withCredentials: true,
      params,
    });
  }

  public requestInventoryInfo(): Observable<IInventoryInfo[]> {
    return this.api.get<IInventoryInfo[]>(`${this.environment.GATEWAY_URL}/api/inventory/info`, {
      withCredentials: true,
    });
  }

  public requestInventoryHistory(params?: IHistoryParams): Observable<{ count: number; items: IHistoryInventoryItem[] }> {
    return this.api.get<{ count: number; items: IHistoryInventoryItem[] }>(`${this.environment.GATEWAY_URL}/api/inventory/v2/history`, {
      withCredentials: true,
      params,
    });
  }

  public requestShop(params: object): Observable<IShop> {
    return this.api.get<IShop>(`${this.environment.GATEWAY_URL}/api/inventory/v2/shop`, {
      withCredentials: true,
      params,
    });
  }

  public requestCreateTrade(params: { ids: number[]; userInventoryIds: number[] }): Observable<IInventoryItem[]> {
    return this.api.post(`${this.environment.GATEWAY_URL}/api/inventory/items/purchase`, params, {
      withCredentials: true,
    });
  }
}
