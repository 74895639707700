import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { UserApiService } from '@dev-fast/backend-services';
import { catchError, map, Observable, of, switchMap, take, tap } from 'rxjs';

import { UserService } from '@app/core/user-service';
import { IS_SERVER_TOKEN } from '@app/shared/utils';

import { AuthService } from '../../auth.service';

@Injectable({
  providedIn: 'root',
})
export class IsAuthGuard {
  constructor(
    private readonly userService: UserService,
    private readonly apiUserService: UserApiService,
    private readonly authService: AuthService,
    private readonly router: Router,
    @Inject(IS_SERVER_TOKEN) public isServer: boolean,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.isServer
      ? of(true)
      : this.userService.user$.pipe(
          switchMap((user) => {
            if (!user) {
              return this.apiUserService.getMeProfile({ detailed: false });
            }
            return of(true);
          }),
          take(1),
          map((res) => !!res),
          catchError((e: any): Observable<any> => {
            const redirectTo = route.data['redirectTo'] || '/';

            this.router.navigate([redirectTo], { replaceUrl: true });
            return of(false);
          }),
        );
  }

  canLoad(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.authService.isAuth$.pipe(
      tap((value) => {
        if (value) {
          return value;
        }
        return this.router.navigate(['/'], { replaceUrl: true });
      }),
    );
  }
}
