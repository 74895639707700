import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ISticker } from '@dev-fast/types';

@Component({
  selector: 'app-p2p-skin-image',
  templateUrl: './p2p-skin-image.component.html',
  styleUrls: ['./p2p-skin-image.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class P2pSkinImageComponent {
  @Input() img: string | undefined;
  @Input() stickers: ISticker[] | undefined;

  @Output() nameToShow: EventEmitter<string | null> = new EventEmitter<string | null>();
}
