<div
  class="trade-form"
  [class.half]="!showLink || !showKey"
  [formGroup]="tradeForm"
>
  <div
    class="trade-form__link"
    *ngIf="showLink && tradeForm.controls['link'] as inputLink"
  >
    <label>
      <div>
        <span>{{ 'PROFILE.RENEW_PROFILE.TRADE.TRADE_LINK' | translate }}</span>
        <a
          class="trade-form__external-link"
          [href]="steamTradeLink"
          target="_blank"
        >
          {{ 'PROFILE.RENEW_PROFILE.SETTINGS.WHERE_LINK' | translate }}
        </a>
      </div>
      <div
        *ngIf="!isLoading(); else loadingInput"
        class="trade-form__input-wrapper"
        [ngClass]="{
          'trade-form__input-wrapper_error': tradeLinkInvalid$ | async,
          'trade-form__input-wrapper_correct': tradeLinkChanged$ | async
        }"
      >
        <input
          #tradeLinkEl
          type="text"
          [formControlName]="'link'"
          [placeholder]="'PROFILE.RENEW_PROFILE.TRADE.INSERT' | translate"
        />

        <ng-container *ngIf="(tradeLinkLoading$ | async) === false; else loadingIndicator">
          <button
            class="trade-form__copy"
            (click)="copy(tradeLinkEl.value)"
            *ngIf="inputLink.valid"
            [matTooltip]="'PROFILE.RENEW_PROFILE.SETTINGS.COPY' | translate"
            [matTooltipPosition]="'above'"
          >
            <mat-icon [svgIcon]="'copy-new'" />
          </button>
          <button
            class="trade-form__change-link"
            (click)="onSubmitTradeLink()"
            *ngIf="canChangeInputLink$ | async"
          >
            <mat-icon [svgIcon]="'checked--design'" />
          </button>
        </ng-container>
      </div>
    </label>
  </div>

  <div
    class="trade-form__key"
    *ngIf="showKey && tradeForm.controls['apiKey'] as inputApi"
  >
    <label>
      <div>
        <span>{{ 'PROFILE.RENEW_PROFILE.TRADE.API_KEY' | translate }}</span>
        <a
          [href]="steamApiKeyLink"
          class="trade-form__external-link"
          target="_blank"
        >
          {{ 'PROFILE.RENEW_PROFILE.SETTINGS.WHERE_API' | translate }}
        </a>
      </div>
      <div
        *ngIf="!isLoading(); else loadingInput"
        class="trade-form__input-wrapper"
        [ngClass]="{
          'trade-form__input-wrapper_error': apiKeyInvalid$ | async,
          'trade-form__input-wrapper_correct': apiKeyChanged$ | async
        }"
      >
        <input
          type="text"
          [formControlName]="'apiKey'"
          #ApiKeyEl
          [placeholder]="'PROFILE.RENEW_PROFILE.TRADE.INSERT_API' | translate"
        />

        <ng-container *ngIf="(keyLoading$ | async) === false; else loadingIndicator">
          <button
            class="trade-form__copy"
            (click)="copy(ApiKeyEl.value)"
            *ngIf="inputApi.valid"
            [matTooltip]="'PROFILE.RENEW_PROFILE.SETTINGS.COPY' | translate"
            [matTooltipPosition]="'above'"
          >
            <mat-icon [svgIcon]="'copy-new'" />
          </button>
          <button
            class="trade-form__change-link"
            (click)="onSubmitApiKey()"
            *ngIf="canChangeInputKey$ | async"
          >
            <mat-icon [svgIcon]="'checked--design'" />
          </button>
        </ng-container>
      </div>
    </label>
  </div>
</div>

<ng-template #loadingIndicator>
  <div class="trade-form__pending">
    <mat-spinner
      [strokeWidth]="2"
      [diameter]="24"
      [color]="'primary'"
    >
    </mat-spinner>
  </div>
</ng-template>

<ng-template #loadingInput>
  <div class="mock-input loading-gradient"></div>
</ng-template>
