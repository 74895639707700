import { IconConfig } from '@dev-fast/types';

export const iconsWithdraw: IconConfig[] = [
  {
    name: 'accepted',
    path: 'assets/img/mat-icons/withdraw/accepted.svg',
  },
  {
    name: 'blocked',
    path: 'assets/img/mat-icons/withdraw/blocked.svg',
  },
];
