export interface ISihDataset {
  [key: string]: [number, number[], number[]][];
}

export interface ISihStatPeriod {
  value: number;
  amount: number;
  title: string;
}

export interface ISihCurrency {
  success: boolean;
  data: {
    base: SihCurrencyType;
    rates: Record<SihCurrencyType, number>;
    ts: number;
  };
}

export enum SihCurrencyType {
  'AED' = 'AED',
  'ARS' = 'ARS',
  'AUD' = 'AUD',
  'BRL' = 'BRL',
  'BYN' = 'BYN',
  'CAD' = 'CAD',
  'CHF' = 'CHF',
  'CLP' = 'CLP',
  'CNY' = 'CNY',
  'COP' = 'COP',
  'CRC' = 'CRC',
  'EUR' = 'EUR',
  'GBP' = 'GBP',
  'HKD' = 'HKD',
  'IDR' = 'IDR',
  'ILS' = 'ILS',
  'INR' = 'INR',
  'JPY' = 'JPY',
  'KRW' = 'KRW',
  'KWD' = 'KWD',
  'KZT' = 'KZT',
  'MXN' = 'MXN',
  'MYR' = 'MYR',
  'NOK' = 'NOK',
  'NZD' = 'NZD',
  'PEN' = 'PEN',
  'PHP' = 'PHP',
  'PLN' = 'PLN',
  'QAR' = 'QAR',
  'RUB' = 'RUB',
  'SAR' = 'SAR',
  'SGD' = 'SGD',
  'THB' = 'THB',
  'TRY' = 'TRY',
  'TWD' = 'TWD',
  'UAH' = 'UAH',
  'USD' = 'USD',
  'UYU' = 'UYU',
  'VND' = 'VND',
  'ZAR' = 'ZAR',
}
