import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import {
  IInventoryRequestParams,
  InventoryTradeBalance,
  IShopMeta,
  ISkinItem,
  IUserDetailed,
  ProfileTypes,
  SkinClickEvent,
} from '@dev-fast/types';
import { debounceTime, Subject, takeUntil } from 'rxjs';

import { validateAccountLevel } from '@app/shared/utils';

@Component({
  selector: 'app-exchange-sub-panel',
  templateUrl: './exchange-sub-panel.component.html',
  styleUrls: ['./exchange-sub-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExchangeSubPanelComponent implements OnDestroy {
  @Input() public shopItems: ISkinItem[] = [];
  @Input() public selectedShopItems: ISkinItem[] = [];
  @Input() public tradeBalance: InventoryTradeBalance | undefined;
  @Input() public meta: IShopMeta | undefined;
  @Input() public loading = true;
  @Input() public user: IUserDetailed | null = null;
  @Input() public set params(params: IInventoryRequestParams | undefined) {
    if (params) {
      this.storeForm.patchValue({ ...params }, { emitEvent: false });
    }
  }

  @Output() public closePanelEmit: EventEmitter<void> = new EventEmitter<void>();
  @Output() public createTrade: EventEmitter<void> = new EventEmitter<void>();
  @Output() public clickOnSkinEmit: EventEmitter<SkinClickEvent> = new EventEmitter<SkinClickEvent>();
  @Output() public changeParams: EventEmitter<IInventoryRequestParams> = new EventEmitter<IInventoryRequestParams>();

  public storeForm: FormGroup;
  private destroy$: Subject<void> = new Subject();

  constructor(private readonly fb: FormBuilder) {
    this.storeForm = this.fb.group({
      query: '',
      sortBy: [true],
      page: [1],
      minPrice: null,
      maxPrice: null,
      pageSize: 48,
    });
    this.storeForm.valueChanges.pipe(takeUntil(this.destroy$), debounceTime(600)).subscribe((val) => {
      this.changeParams.emit(val);
    });
  }
  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
  public onRefresh(): void {
    this.storeForm.patchValue(this.storeForm.value);
  }
  public isSelected(id: number): boolean {
    return this.selectedShopItems.some((item) => item.id === id);
  }
  public onCreateTrade(): void {
    this.createTrade.emit();
  }
  public totalPage(): number {
    return this.meta ? Math.ceil(this.meta.amount / this.meta.limit) : 1;
  }
  public validatePremium(user: IUserDetailed | null): boolean {
    return !!user && validateAccountLevel(user.profileType, ProfileTypes.SILVER);
  }
  public pageChange(page: number): void {
    this.storeForm.patchValue({
      page: page,
    });
  }
  public onBottomReached(): void {
    // this.paramsEmit.emit({ ...this.form.value, page: this.form.value.page && this.form.value.page + 1 });
  }
}
