import { FormControl, FormRecord } from '@angular/forms';

import { PaymentWalletType, RefillTargetCurrency } from '../../enums';
import { IBasedForm } from '../base-form';

// пополнения

export interface IRefillGateForm extends IBasedForm {
  realCurrency: FormControl<number | null>;
  currency: FormControl<number | null>;
  totalCurrency: FormControl<number>;
  targetCurrency: FormControl<RefillTargetCurrency>;
  walletType: FormControl<PaymentWalletType>;
  cardholderName?: FormControl<string | null>;
  cardholderSurname?: FormControl<string | null>;
  cpf?: FormControl<number | null>;
  email?: FormControl<string | null>;
  phoneNumber?: FormControl<string | null>;
  wallet?: FormControl<string | null>;
}
export type IRefillGateFormPartial = Partial<{
  realCurrency: number | null;
  currency: number | null;
  totalCurrency: number;
  targetCurrency: RefillTargetCurrency;
  walletType: PaymentWalletType;
  cardholderName?: string | null;
  cardholderSurname?: string | null;
  cpf?: number | null;
  phoneNumber?: string | null;
  email?: string | null;
  wallet?: string | null;
  agreement: boolean;
}>;
export interface IRefillGiftForm extends IBasedForm {
  giftcode: FormControl<string>;
}
export interface IRefillTradeForm extends IBasedForm {
  currency: FormControl<number | null>;
}
export interface IRefillItemPayForm extends IBasedForm {
  tradeLink: FormControl<string | null>;
}
export interface IRefillUserWallets extends IBasedForm {
  wallets: FormRecord<FormControl<string>>;
}

export interface IRefillWalletNumber extends IBasedForm {
  walletNumber: FormControl<string>;
}

// выводы

export interface IWithdrawalForm extends IBasedForm {
  wallet: FormControl<string>;
  method: FormControl<string>;
  withdrawal: FormControl<number | null>;
  receive: FormControl<number>;
  recaptcha: FormControl<string>;
  deliverySpeed: FormControl<string | undefined>;
}
