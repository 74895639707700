import { Directive, ElementRef, EventEmitter, HostListener, Input, OnChanges, Output, Renderer2 } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { LazyLoadImageDirective } from '../lazy-load-image/lazy-load-image.directive';

@Directive({
  selector: 'img[playerImage]',
})
export class PlayerImageDirective extends LazyLoadImageDirective implements OnChanges {
  @Input() width = 330;
  @Input() height = 192;
  @Input() userId?: number;
  @Output() clickOnUser: EventEmitter<number> = new EventEmitter();

  private dimensions: number | undefined;

  @HostListener('click', ['$event.target'])
  openProfile(): void {
    if (this.userId) {
      this.clickOnUser.emit(this.userId);
    }
  }

  constructor(
    private elementRef: ElementRef,
    private render: Renderer2,
    private dialog: MatDialog,
  ) {
    super(elementRef, render);
    // TODO отваливалось из-за циркулярки в LayoutState
    // this.fallback = `//d2lomvz2jrw9ac.cloudfront.net/common/fallback/${this.store.selectSnapshot(LayoutState.theme)}.png`;
    this.fallback = `//d2lomvz2jrw9ac.cloudfront.net/common/fallback/blue.png`;
    this.elementRef.nativeElement.onError = this.onImageError();
  }

  override ngOnChanges(): void {
    if (this.src) {
      this.src = this.computeUserAvatar(this.src);
    }

    super.ngOnChanges();
  }

  computeUserAvatar(url: string): string {
    if (!url) {
      return this.fallback;
    }
    this.dimensions = this.computeComponentDimensions();
    if (url.startsWith('data:') || url.startsWith('http') || url.startsWith('//')) {
      if ((url || '').startsWith('https://din8r827idtuo.cloudfront.net/')) {
        const [source, ...query] = url.split('?');
        query.push(`width=${this.dimensions}`);
        query.push(`height=${this.dimensions}`);
        // ToDo: move it out
        return source.replace('din8r827idtuo.cloudfront.net', 'de4khei8i4ut2.cloudfront.net') + `?${query.join('&')}`;
      }
      if (url.startsWith('https://api.adorable.io/avatars/')) {
        const avatar = url.split('/');
        const path = avatar[avatar.length - 1];
        return `https://api.adorable.io/avatars/${this.dimensions}/${path}`;
      }
      return url;
    }

    return `https://steamcdn-a.akamaihd.net/steamcommunity/public/images/avatars/${url}_full.jpg`;
  }

  computeComponentDimensions(): number {
    const host = getComputedStyle(this.elementRef.nativeElement);
    const size = host.getPropertyValue('--size');
    const [, dimensions, type] = size.split(/([0-9]+)/);
    const width = 120;
    const dimensionsNumber = isNaN(Number(dimensions)) ? width : Number(dimensions);
    if (type === 'rem') {
      const calculated = dimensionsNumber * 16;
      return calculated < width ? width : calculated;
    }
    return Math.max(width, dimensionsNumber);
  }

  onImageError(): void {
    this.src = this.fallback;
  }
}
