<ng-container *ngIf="lotDepositSettings">
  <div
    class="expand-btn flex fx-align-center gap-1 tw-font-medium"
    (click)="panelExpanded = !panelExpanded"
    [class.panel-expanded]="panelExpanded"
  >
    <mat-icon svgIcon="arrow--design"></mat-icon>
    <span>{{ 'P2P_WIDGETS.DEPOSIT_MODIFIER_DIALOG.ADDITION_SETTINGS' | translate }}</span>
  </div>
  <div
    class="settings-panel"
    *ngIf="panelExpanded"
  >
    <div class="settings-panel__duration-label tw-font-medium">
      {{ 'P2P_WIDGETS.RENEW_INFO_MODAL.SELLING_SPEED' | translate }}
    </div>
    <div class="settings-panel__duration flex pb-3">
      <div
        class="settings-panel__duration-btn flex fx-justify-center w-100 p-sm-3 tw-font-normal"
        (click)="updateSettings(1)"
        [class.active-tab]="lotDepositSettings.tradesDuration === 1"
      >
        1 {{ 'P2P_WIDGETS.DEPOSITING.TIME' | translate }}
      </div>
      <div
        class="settings-panel__duration-btn flex fx-justify-center w-100 p-sm-3 tw-font-normal"
        (click)="updateSettings(3)"
        [class.active-tab]="lotDepositSettings.tradesDuration === 3"
      >
        3 {{ 'P2P_WIDGETS.DEPOSITING.TIME' | translate }}
      </div>
      <div
        class="settings-panel__duration-btn flex fx-justify-center w-100 p-sm-3 tw-font-normal"
        (click)="updateSettings(12)"
        [class.active-tab]="lotDepositSettings.tradesDuration === 12"
      >
        12 {{ 'P2P_WIDGETS.DEPOSITING.TIME' | translate }}
      </div>
      <div
        class="settings-panel__duration-btn flex fx-justify-center w-100 p-sm-3 tw-font-normal"
        (click)="updateSettings(24)"
        [class.active-tab]="lotDepositSettings.tradesDuration === 24"
      >
        24 {{ 'P2P_WIDGETS.DEPOSITING.TIME' | translate }}
      </div>
    </div>
    <div class="settings-panel__confirm-checkbox flex gap-2 pb-2 tw-font-normal">
      <mat-checkbox
        [(ngModel)]="lotDepositSettings.autoApprove"
        (change)="updateSettings(lotDepositSettings.autoApprove)"
        class="checkbox-primary"
      ></mat-checkbox>
      <span>{{ 'P2P_WIDGETS.DEPOSITING.AUTOAPPROVE' | translate }}</span>
    </div>
    <div class="settings-panel__confirm-message flex gap-3 p-sm-3 tw-font-normal">
      <div class="settings-panel__confirm-message-icon flex p-1">
        <mat-icon svgIcon="danger-sign--design"></mat-icon>
      </div>
      {{ 'P2P_WIDGETS.DEPOSITING.AUTOAPPROVE_DESCRIPTION' | translate }}
    </div>
  </div>
</ng-container>
