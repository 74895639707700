import { AgentKeys } from '../enums';
import { ILobbyGame } from '../models';
import { ICaseItemDtoV2, ICaseRevisionItem } from './games';
import { IInventoryItemV2 } from './inventory';
import { IUserShort } from './user';

// export interface ICaseStatistics {
//   bestLastOpenedCases: IStatisticsItemDto[];
//   lastOpenedCases: IStatisticsItemDto[];
//   bestDrop: IStatisticsItemDto;
//   openedCaseCount: number;
// }

export interface ICommonStatistic {
  users: number;
  online: number;
  upgrades: number;
}

export interface IStatisticItem {
  data: any;
  source: 'upgrade' | 'cases';
  user: IUserShort;
}

export interface IMotivatorStatistic {
  bestLast?: IMotivatorDto<IDropItemDto>[];
  last?: IMotivatorDto<IDropItemDto>[];
  bestDrop?: IDropItemDto;
  count?: number;
  bigOpens?: IBiggestCountCasesDto;
}
export interface IBiggestCountCasesDto {
  count?: number;
  user?: IUserShort;
}
// export interface IStatisticsItemDto {
//   id: number;
//   case: ICaseItemDtoV2;
//   caseRevisionItem: ICaseRevisionItem;
//   caseRevisionPrice: number;
//   winningPrice: number;
//   user: IUserShort;
//   rollId: number;
// }
export interface IDropItemDto {
  /** Если реплей батла то это id самого батла */
  id: number;
  case: ICaseItemDtoV2;
  caseRevisionItem: ICaseRevisionItem;
  caseRevisionPrice: number;
  winningPrice: number;
  user: IUserShort;
  rollId: number;
  createdAt: string;
  profit: number;
  isBattle?: boolean;
  isFree: boolean;
  openUUID: string;
}

export interface IUpgradeStatisticData {
  isWon: boolean;
  items: IInventoryItemV2[];
}

//добавил для дропов
//внутри IStatisticsItemDto нехватает "createdAt"

export interface IAllDropsList {
  bestDrop: any;
  cases: number;
  upgrades: number;
  last: any[];
  lastBest: any[];
}

// export interface IDrop {
//   source: 'upgrade' | 'cases';
//   data: IStatisticsItemDto;
// }
export interface IMotivatorDto<T> {
  agentKey: AgentKeys;
  data: T;
}
export interface IMotivatorData {
  all: IMotivatorDto<any>[];
  best: IMotivatorDto<any>[];
}

export interface ILobbyGamesMotivator {
  topGames: ILobbyGame[];
}
