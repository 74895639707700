import { IUserShort } from './user';

export interface IExperience {
  gameType?: 'classic' | 'fast' | 'double' | 'tower';
  date: Date;
  amount: number;
  id: number;
}

export interface IExperienceStatistic {
  month: string;
  xp: number;
  levels?: number[];
}

export interface IExperienceLeader {
  user: IUserShort;
  xp: number;
}
