<div
  class="emptyResults flex fx-column fx-align-center gap-4"
  [class.compact]="isCompact"
>
  <mat-icon
    class="ghost"
    svgIcon="ghost--design"
  ></mat-icon>
  <div
    *ngIf="headerLocale"
    class="title tw-font-medium"
  >
    {{ headerLocale | translate }}
  </div>
  <div
    *ngIf="underLocale"
    class="text tw-font-normal"
  >
    {{ underLocale | translate }}
  </div>
  <button
    *ngIf="actionLocale"
    class="btn btn-primary--design"
    (click)="actionEmitted.emit()"
  >
    {{ actionLocale | translate }}
  </button>
</div>
