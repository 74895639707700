import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IEvent } from '@dev-fast/types';

import { gameMenuAnimation } from '../../game-menu.animation';

@Component({
  selector: 'app-ui-event-menu-container',
  templateUrl: './event-menu-container.component.html',
  styleUrls: ['./event-menu-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [gameMenuAnimation],
})
export class EventMenuContainerComponent {
  @Input() public set events(payload: IEvent[] | null) {
    if (!payload) return;
    this.eventsList = payload;
    const currentEvent = payload.find((e) => e.isCurrent);
    if (currentEvent) this.eventBtn.key = currentEvent.key;
  }

  public hovered = false;

  public eventsList!: IEvent[];
  public eventBtn: IEvent = {
    key: '',
    title: 'Events',
    isCurrent: true,
  };
}
