import { InjectionToken } from '@angular/core';

export interface ISeoData {
  h1?: string | null;
  main?: string | null;
  faq?: string | null;
}

export interface SeoStateModel {
  data: ISeoData;
  description: { value: string; data: any };
  title: { value: string; data: any };
}

export const SEO_INITIAL_STATE: SeoStateModel = {
  data: {
    h1: null,
    main: null,
    faq: null,
  },
  description: { value: '', data: null },
  title: { value: '', data: null },
};

export const SEO_ROUTES = new InjectionToken<string[]>('SEO_ROUTES');

// Если не получили список маршрутов для проекта, то хотя бы возьмем дефолтный список
export const SEO_ROUTES_DEFAULT: string[] = [
  '/',
  '/faq',
  '/tos',
  '/privacy-policy',
  '/game/fast',
  '/game/classic',
  '/game/x50',
  '/game/double',
  '/game/crash',
  '/game/king',
  '/game/craft',
  '/game/slot',
  '/game/match-bets',
  '/game/cases',
  '/game/fortune-wheel',
  '/game/tower',
  '/game/hilo',
  '/game/baccarat',
  '/game/case-battle',
  '/game/poggi',
];
