import { AfterViewInit, Directive, ElementRef, HostBinding, HostListener, inject, Input, OnChanges, Renderer2 } from '@angular/core';

import { IS_SERVER_TOKEN } from '@app/shared/utils';

@Directive({
  selector: '[lazyLoadImage]',
})
export class LazyLoadImageDirective implements AfterViewInit, OnChanges {
  @HostBinding('attr.src') srcAttr: string | null = null;
  @Input()
  src!: string;
  @Input()
  bgSrc!: string;
  @Input()
  fallback!: string;
  @Input() forceLoad = false;

  readonly #isServer = inject(IS_SERVER_TOKEN);

  static canLazyLoad(): boolean {
    return !!window && 'IntersectionObserver' in window;
  }

  @HostListener('error')
  useFallback(): void {
    this.srcAttr = this.fallback;
  }

  constructor(
    protected el: ElementRef,
    private renderer: Renderer2,
  ) {}

  ngAfterViewInit(): void {
    if (this.#isServer) {
      return;
    }

    if (LazyLoadImageDirective.canLazyLoad() && !this.forceLoad) {
      this.#lazyLoadImage();
    } else {
      this.#loadImage();
    }
  }

  ngOnChanges(): void {
    if (this.#isServer) {
      return;
    }
    if (LazyLoadImageDirective.canLazyLoad() && !this.forceLoad) {
      this.#lazyLoadImage();
    } else {
      this.#loadImage();
    }
  }

  #lazyLoadImage(): void {
    const obs = new IntersectionObserver((entries) =>
      entries.forEach(({ isIntersecting }) => {
        if (isIntersecting) {
          this.#loadImage();
          this.el.nativeElement.src = this.src;
          obs.unobserve(this.el.nativeElement);
        }
      }),
    );
    obs.observe(this.el.nativeElement);
  }

  #loadImage(): void {
    this.srcAttr = this.src;
    if (this.bgSrc) {
      this.renderer.setStyle(this.el.nativeElement, 'background-image', `url(${this.bgSrc})`);
    }
    if (this.forceLoad) {
      this.el.nativeElement.src = this.src;
    }
  }
}
