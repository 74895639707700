<div class="header-settings-container">
  <button
    class="btn header-control-button btn-sm"
    [ngClass]="{ 'm-0': user }"
    (click)="toggleSettingsComponent()"
  >
    <ng-container *ngIf="user; else notAuthIcoTemplate">
      <app-ui-user-avatar
        [url]="user.avatar"
        [level]="user.experience.level"
        [ngClass]="user.profileType"
      ></app-ui-user-avatar>
    </ng-container>
  </button>
  <div
    class="header-control-selected"
    @settingsAnimation
    *ngIf="isSettingsShowed"
    (click)="toggleSettingsComponent()"
  >
    <div class="header-control-selected-header">{{ 'HEADER.COMMON_SETTINGS' | translate }}</div>
    <div class="header-control-selected-icon">
      <ng-container *ngIf="user; else notAuthIcoTemplate">
        <app-ui-user-avatar
          [url]="user.avatar"
          [level]="user.experience.level"
          [ngClass]="user.profileType"
        ></app-ui-user-avatar>
      </ng-container>
    </div>
  </div>
  <div
    class="dropdown-host"
    *ngIf="isSettingsShowed"
  >
    <div class="header-settings-dropdown">
      <ng-container *ngIf="user">
        <div class="header-settings-dropdown-item">
          <!-- langRouterLink [routerLink]="[{ outlets: { primary: 'account', bottom: 'test' } }]" -->
          <div
            class="header-settings-dropdown-selector"
            langRouterLink
            [routerLink]="'/account'"
            (click)="onOverlayClick()"
          >
            <div class="header-settings-dropdown-description">
              <span class="header-settings-dropdown-description-selected">{{ 'HEADER_SETTINGS.COMMON_SETTINGS' | translate }}</span>
              <!-- <span class="header-settings-dropdown-description-type">{{ 'HEADER_SETTINGS.ACCOUNT_SETTINGS' | translate }}</span> -->
            </div>
            <div class="header-settings-dropdown-selector-wrapper">
              <mat-icon svgIcon="user"></mat-icon>
            </div>
          </div>
        </div>
        <div class="header-settings-dropdown-item">
          <div
            class="header-settings-dropdown-selector"
            langRouterLink
            [routerLink]="'/faq'"
            (click)="onOverlayClick()"
          >
            <div class="header-settings-dropdown-description">
              <span class="header-settings-dropdown-description-selected">{{ 'HEADER_SETTINGS.FAQ' | translate }}</span>
            </div>
            <div class="header-settings-dropdown-selector-wrapper">
              <mat-icon svgIcon="faq"></mat-icon>
            </div>
          </div>
        </div>
      </ng-container>

      <div class="header-settings-dropdown-item">
        <div
          class="header-settings-dropdown-selector"
          (click)="onTogleSound()"
        >
          <div class="header-settings-dropdown-description">
            <span class="header-settings-dropdown-description-selected">{{ soundState }}</span>
            <span class="header-settings-dropdown-description-type">{{ 'HEADER_SETTINGS.VOLUME_SETTINGS' | translate }}</span>
          </div>
          <div class="header-settings-dropdown-selector-wrapper">
            <mat-icon [svgIcon]="'volume-' + (soundState ? 'up' : 'off')"></mat-icon>
          </div>
        </div>
      </div>
      <div
        class="header-settings-dropdown-item"
        [ngClass]="{ selected: selectedSetting === 'currency' }"
        *ngIf="currentCurrency"
      >
        <div
          class="header-settings-dropdown-selector"
          (click)="selectSetting('currency')"
        >
          <div class="header-settings-dropdown-description">
            <span class="header-settings-dropdown-description-selected">{{ currentCurrency.key }}</span>
            <span class="header-settings-dropdown-description-type">{{ 'HEADER_SETTINGS.CURRENCY' | translate }}</span>
          </div>
          <div class="header-settings-dropdown-selector-wrapper">
            <span class="currency-icon">{{ currentCurrency.prefix }}</span>
          </div>
        </div>
        <div class="header-settings-dropdown-wrapper">
          <div
            class="header-settings-dropdown-addition"
            *ngIf="currencyList"
          >
            <div
              class="btn btn-dark header-settings-dropdown-addition-item currency-item"
              [ngClass]="{ selected: currentCurrency.key === cur.key }"
              (click)="selectCurrency(cur)"
              *ngFor="let cur of currencyList"
            >
              {{ cur.key }}
            </div>
          </div>
        </div>
      </div>
      <div
        class="header-settings-dropdown-item"
        [ngClass]="{ selected: selectedSetting === 'language' }"
        *ngIf="currentLang"
      >
        <div
          class="header-settings-dropdown-selector"
          (click)="selectSetting('language')"
        >
          <div class="header-settings-dropdown-description">
            <span class="header-settings-dropdown-description-selected">{{ currentLang.title }}</span>
            <span class="header-settings-dropdown-description-type">{{ 'HEADER_SETTINGS.LANGUAGE' | translate }}</span>
          </div>
          <div class="header-settings-dropdown-selector-wrapper">
            <div class="country-flag-image">
              <img
                [src]="'//d2lomvz2jrw9ac.cloudfront.net/country-flags/' + currentLang.path.slice(3, 5).toLowerCase() + '.png'"
                alt=""
              />
            </div>
          </div>
        </div>
        <div class="header-settings-dropdown-wrapper">
          <div class="header-settings-dropdown-addition">
            <div
              class="btn btn-dark header-settings-dropdown-addition-item language-item"
              *ngFor="let localization of langList"
              (click)="selectLocale(localization)"
              [ngClass]="{ selected: currentLang.path === localization.path }"
            >
              <span class="language-item-title">{{ localization.title }}</span>
              <span class="language-item-image">
                <img
                  [src]="'//d2lomvz2jrw9ac.cloudfront.net/country-flags/' + localization.path.slice(3, 5).toLowerCase() + '.png'"
                  alt=""
                />
              </span>
            </div>
          </div>
        </div>
      </div>
      <div
        class="header-settings-dropdown-item"
        *ngIf="currentTheme"
        [ngClass]="{ selected: selectedSetting === 'theme' }"
      >
        <div
          class="header-settings-dropdown-selector"
          (click)="selectSetting('theme')"
        >
          <div class="header-settings-dropdown-description">
            <span class="header-settings-dropdown-description-selected">{{ currentTheme | uppercase | translate }}</span>
            <span class="header-settings-dropdown-description-type">{{ 'HEADER_SETTINGS.THEME' | translate }}</span>
          </div>
          <div
            class="header-settings-dropdown-selector-wrapper"
            [ngClass]="currentTheme + '-theme'"
          >
            <mat-icon svgIcon="theme-selector"></mat-icon>
          </div>
        </div>
        <div class="header-settings-dropdown-wrapper">
          <ul class="header-settings-dropdown-addition">
            <li
              class="btn btn-dark header-settings-dropdown-addition-item"
              *ngFor="let theme of themeList"
              (click)="selectTheme(theme)"
              [ngClass]="{ selected: currentTheme === theme }"
              [class]="theme + '-theme'"
            >
              <span>{{ theme | uppercase | translate }}</span>
              <span class="theme-selector-substrate"> </span>
            </li>
          </ul>
        </div>
      </div>
      <div class="header-settings-dropdown-item">
        <ng-container *ngIf="user; else loginTemplate">
          <div
            class="header-settings-dropdown-selector"
            (click)="onLogout()"
          >
            <div class="header-settings-dropdown-description">
              <span class="header-settings-dropdown-description-selected">{{ 'LOGOUT' | translate }}</span>
            </div>
            <div class="header-settings-dropdown-selector-wrapper">
              <mat-icon svgIcon="log-out"></mat-icon>
            </div>
          </div>
        </ng-container>
        <ng-template #loginTemplate>
          <div
            class="header-settings-dropdown-selector"
            (click)="onLogin()"
          >
            <div class="header-settings-dropdown-description">
              <span class="header-settings-dropdown-description-selected">{{ 'LOGIN' | translate }}</span>
            </div>
            <div class="header-settings-dropdown-selector-wrapper">
              <mat-icon svgIcon="log-in"></mat-icon>
            </div>
          </div>
        </ng-template>
      </div>
    </div>
    <div
      class="header-settings-overlay"
      (click)="onOverlayClick()"
    ></div>
  </div>
</div>
<ng-template #notAuthIcoTemplate>
  <mat-icon svgIcon="settings-selector"></mat-icon>
</ng-template>
