import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { IInventoryItem, ISkinItem, LegacyGameConfig, LegacyGameState, LegacyLiteGameState, SkinClickEvent } from '@dev-fast/types';

@Component({
  selector: 'app-bet-sub-panel',
  templateUrl: './bet-sub-panel.component.html',
  styleUrls: ['./bet-sub-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BetSubPanelComponent {
  @Input() public selectedItems: IInventoryItem[] = [];
  @Input() public config: LegacyGameConfig | null = null;
  @Input() public state: LegacyGameState | null = null;
  @Input() public participatedItems: ISkinItem[] = [];
  @Input() public liteState: LegacyLiteGameState | undefined | null;
  private _participated: { items: IInventoryItem[]; timeout: number } | undefined;
  @Input() public set participated(val: { items: IInventoryItem[]; timeout: number } | undefined) {
    if (val) {
      this._participated = val;
      setTimeout(() => {
        this._participated = undefined;
      }, val?.timeout * 1000);
    }
  }
  public get participated(): { items: IInventoryItem[]; timeout: number } | undefined {
    return this._participated;
  }
  @Output() public onparticipate: EventEmitter<void> = new EventEmitter(false);
  @Output() public oncleanParticipate: EventEmitter<number[]> = new EventEmitter(false);
  @Output() public clickOnSkinEmit: EventEmitter<SkinClickEvent> = new EventEmitter<SkinClickEvent>();

  public get participatedTimer(): number {
    return this.participated ? this.participated.timeout * 1000 : 0;
  }
  public get totalPrice(): number {
    return this.selectedItems.reduce((a, b) => a + b.price, 0);
  }
  public get minItemsPerTrade(): unknown[] {
    if (this.config && this.config.min_items_per_trade) {
      return new Array(this.config.min_items_per_trade);
    } else {
      return [];
    }
  }
  public get maxItemsPerTrade(): unknown[] {
    if (this.config && this.config.max_items_per_trade) {
      return new Array(this.config.max_items_per_trade - (this.config.min_items_per_trade ? this.config.min_items_per_trade : 0));
    } else {
      return [];
    }
  }
  public get participationDisabled(): boolean {
    if (this.config) {
      return (
        this.selectedItems.length < (this.config.min_items_per_trade ? this.config.min_items_per_trade : 1) ||
        this.selectedItems.length > this.config.max_items_per_trade ||
        this.totalPrice < this.config.min_bet * 100 ||
        (!!this.config.max_bet && this.totalPrice > this.config.max_bet * 100)
      );
    }
    return true;
  }
  public get clearDisabled(): boolean {
    if (this.participated && this.selectedItems.length) {
      return this.participated.items.some((el) => this.selectedItems.map((item) => item.id).includes(el.id));
    } else if (this.selectedItems.length) {
      return false;
    }
    return true;
  }

  public cleanParticipate(): void {
    this.oncleanParticipate.next(this.selectedItems.map((item) => item.id));
  }
  public trackingFunction(index: number, item: { id: number }): number {
    return item.id;
  }
  public onParticipate(): void {
    this.onparticipate.emit();
  }
  public setupCountdown(): string {
    return '00:55';
  }
}
