import moment from 'moment';

export interface PriorityList {
  high?: Array<string | number>;
  medium?: Array<string | number>;
  low?: Array<string | number>;
}
export function sortBy(value: any[], key: PropertyKey, order: 'asc' | 'desc' = 'asc'): any[] {
  if (!Array.isArray(value)) {
    throw Error(`InvalidArgument: '${value}' for sortBy`);
  }

  return value.sort((l, r) => {
    if (l[key] && r[key]) {
      const left = moment(l[key]).isValid() ? new Date(l[key]).getTime() : l[key];
      const right = moment(r[key]).isValid() ? new Date(r[key]).getTime() : r[key];
      return order === 'asc' ? left - right : right - left;
    } else {
      return 0;
    }
  });

  // if (order) return value.sort((l, r) => (l[key] && r[key] ? (order === 'asc' ? l[key] - r[key] : r[key] - l[key]) : 0));
  // return value.sort((l, r) => (l[key] && r[key] ? (l[key] > r[key] ? -1 : 1) : 0));
}
/**
 * Сортирует и возвращает массив по статусам
 * @input целевой массив
 * @input ключи, по которым пройдет сортировка
 * @input массив со статусами, на которые будет ориентироваться сортировка
 */
export function sortByPriority<T extends Record<PropertyKey, any>, K extends keyof T>(
  value: T[],
  keyMatchList: K,
  keySort: K,
  priorityList: PriorityList,
  order: 'asc' | 'desc' = 'asc',
): T[] {
  if (!Array.isArray(value)) {
    throw Error(`InvalidArgument: '${value}' for sortByPriority`);
  }
  if (!value.every((element) => !!Object.keys(element).length)) {
    throw Error(`InvalidObjectType: '${value}' for sortByPriority`);
  }

  const sortedByPriority: {
    high: Array<any>;
    medium: Array<any>;
    low: Array<any>;
    unprioritized: Array<any>;
  } = {
    high: [],
    medium: [],
    low: [],
    unprioritized: [],
  };
  value.forEach((element) => {
    if (priorityList.high && priorityList.high.includes(element[keyMatchList])) {
      sortedByPriority.high.push(element);
      // return;
    } else if (priorityList.medium && priorityList.medium.includes(element[keyMatchList])) {
      sortedByPriority.medium.push(element);
      // return;
    } else if (priorityList.low && priorityList.low.includes(element[keyMatchList])) {
      sortedByPriority.low.push(element);
      // return;
    } else {
      sortedByPriority.unprioritized.push(element);
    }
  });
  return [
    ...sortBy(sortedByPriority.high, keySort, order),
    ...sortBy(sortedByPriority.medium, keySort, order),
    ...sortBy(sortedByPriority.low, keySort, order),
    ...sortBy(sortedByPriority.unprioritized, keySort, order),
  ];
}

// sortItems(lists: Record<string, Record<string, any>>): Record<string, Record<string, any>> {
//   return Object.keys(lists).reduce((sorted: Record<string, Record<string, any>>, key) => {
//     sorted[key] = Object.entries(lists[key])
//       .sort(([a], [b]) => new Date(a).getTime() - new Date(b).getTime())
//       .reduce((obj: Record<string, any>, [date, transactions]) => {
//         obj[date] = transactions;
//         return obj;
//       }, {});
//     return sorted;
//   }, {});
// }
