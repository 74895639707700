import { Injectable } from '@angular/core';
import { IFilterMethod, IFilterRange, IPriceFilter, Sorting, SortingOrder } from '@dev-fast/types';
import { createSelector } from '@ngxs/store';

@Injectable()
export class CommonFiltersState<TSource> {
  protected readonly defaultSorting: Sorting;

  constructor() {
    this.defaultSorting = { order: SortingOrder.Asc, field: 'name' };
  }

  public static sortingMethods<TSource>() {
    return createSelector([this], (state: { sortingMethods: IFilterMethod<TSource>[] }) => state.sortingMethods);
  }
  public static priceFilters() {
    return createSelector([this], (state: { priceFilters: IPriceFilter[] }) => state.priceFilters);
  }

  // public static pagination() {
  //   return createSelector([this], (state: { pagination: Pagination }) => state.pagination);
  // }
  //
  // public static sorting() {
  //   return createSelector([this], (state: { sorting: Sorting }) => state.sorting);
  // }
  public static filters<TSource>() {
    return createSelector([this], (state: { filters: TSource }) => state.filters);
  }
  public static priceRange() {
    return createSelector([this], (state: { priceRange: IFilterRange | null }) => state.priceRange);
  }
  public calcRange(oldRange: IFilterRange | null, range: IFilterRange): IFilterRange {
    const minValue = oldRange ? oldRange.minValue : 0;
    const maxValue = oldRange ? oldRange.maxValue : 1;

    return {
      minValue: minValue > 0 && minValue < range.minValue ? minValue : range.minValue,
      maxValue: maxValue < range.maxValue ? range.maxValue : maxValue,
    };
  }
}
