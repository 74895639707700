import { Injectable, Optional } from '@angular/core';
import { InventoryLegacyService } from '@dev-fast/backend-services';
import {
  GameIds,
  IHistoryInventoryItem,
  IHistoryParams,
  IInventoryInfo,
  IInventoryItem,
  IInventoryRequestParams,
  IInventoryShortInfo,
  InventoryTradeBalance,
  IShop,
  IShopMeta,
  ISkinItem,
  ISkinItemV2,
  IUserInventoryItemV2,
  Panel,
  SubPanel,
} from '@dev-fast/types';
import { Action, Actions, ofAction, Selector, State, StateContext } from '@ngxs/store';
import { append, insertItem, patch, removeItem } from '@ngxs/store/operators';
import { delay, Observable, switchMap, takeUntil, tap } from 'rxjs';

import { CurrencyService } from '@app/core/currency';
import { FrameMessageTypes, IFrameMessageService } from '@app/core/iframe';
import { RefreshActivePanel } from '@app/core/state/layout';

import {
  ChangeGamesItemsStatus,
  ChangeHistoryParams,
  ChangeInventoryPage,
  ChangeParamsInventory,
  ChangeParamsShop,
  ChangeShopPage,
  ClickOnInventoryItem,
  ClickOnShopItem,
  FreezeItems,
  GetInventoryInfo,
  GetInventoryItems,
  GetShopItems,
  Purchase,
  Refresh,
  RemoveInventoryItems,
  RequestInventoryHistory,
  SellAllItems,
  SellItems,
  ToggleAllInventoryItems,
  ToggleGameStatus,
  ToggleIsSelectAll,
  Trade,
  UnselectInventoryItemById,
  UnselectItems,
} from './inventory-legacy.actions';
import { INVENTORY_LEGACY_INITIAL_STATE, InventoryStateLegacyModel } from './inventory-state-legacy.model';

@State<InventoryStateLegacyModel>({
  name: 'inventory',
  defaults: INVENTORY_LEGACY_INITIAL_STATE,
})
@Injectable()
export class InventoryLegacyState {
  constructor(
    private readonly apiService: InventoryLegacyService,
    private readonly currencyService: CurrencyService,
    @Optional() private readonly frameMessageService: IFrameMessageService,
    private readonly actions$: Actions,
  ) {}

  @Selector()
  static isSelectAll({ isSelectAll }: InventoryStateLegacyModel): boolean {
    return isSelectAll;
  }
  @Selector()
  static items({ items }: InventoryStateLegacyModel): IInventoryItem[] {
    return items;
  }
  @Selector()
  static historyItems({ historyItems }: InventoryStateLegacyModel): IHistoryInventoryItem[] {
    return historyItems;
  }
  @Selector()
  static inventorySum({ inventorySum }: InventoryStateLegacyModel): number {
    return inventorySum;
  }
  @Selector()
  static inventoryCount({ inventoryCount }: InventoryStateLegacyModel): number {
    return inventoryCount;
  }
  @Selector()
  static tradeBalance({ contracts, selectedItems }: InventoryStateLegacyModel): InventoryTradeBalance {
    const selectedItemsSum = selectedItems.reduce((a, b) => a + b.price, 0);
    const selectedShopItemsSum = contracts.reduce((a, b) => a + b.price, 0);

    return { selectedItemsSum, selectedShopItemsSum, tradeBalance: selectedItemsSum - selectedShopItemsSum };
  }
  @Selector()
  static shopItems({ shopItems }: InventoryStateLegacyModel): ISkinItem[] {
    return shopItems;
  }
  @Selector()
  static itemsCount({ inventoryCount }: InventoryStateLegacyModel): number {
    return inventoryCount;
  }
  @Selector()
  static currentSum({ selectedItems }: InventoryStateLegacyModel): number {
    return selectedItems.reduce((sum, item) => sum + item.price, 0);
  }
  @Selector()
  static contracts({ contracts }: InventoryStateLegacyModel): ISkinItem[] {
    return contracts;
  }
  @Selector()
  static selectedItems({ selectedItems }: InventoryStateLegacyModel): ISkinItem[] {
    return selectedItems;
  }
  @Selector()
  static inventorySortingParams({ params }: InventoryStateLegacyModel): string | boolean {
    return params.sortBy ? params.sortBy : true;
  }
  @Selector()
  static shopParams({ shopParams }: InventoryStateLegacyModel): IInventoryRequestParams {
    return shopParams;
  }
  @Selector()
  static maxInventoryPages({ params, inventoryCount }: InventoryStateLegacyModel): number | null {
    if (params && params.pageSize) {
      return Math.ceil(inventoryCount / (params.pageSize ? params.pageSize : 50));
    }
    return null;
  }
  @Selector()
  static maxShopPages({ shopParams, shopMeta }: InventoryStateLegacyModel): number | null {
    if (shopParams && shopParams.pageSize) {
      return Math.ceil(shopMeta.amount / (shopParams.pageSize ? shopParams.pageSize : 50));
    }
    return null;
  }
  @Selector()
  static inventoryParams({ params }: InventoryStateLegacyModel): IInventoryRequestParams {
    return params;
  }
  @Selector()
  static shopMeta({ shopMeta }: InventoryStateLegacyModel): IShopMeta {
    return shopMeta;
  }
  @Selector()
  static gameInProgress({ gameInProgress }: InventoryStateLegacyModel): boolean {
    return gameInProgress;
  }
  @Selector()
  static inventoryShortInfo({ inventoryCount, inventorySum }: InventoryStateLegacyModel): IInventoryShortInfo {
    return { inventoryCount, inventorySum };
  }
  @Selector()
  static selectionAmount({ shopParams }: InventoryStateLegacyModel): number | null | undefined {
    return shopParams.selectionSum;
  }
  @Selector()
  static historyParams({ historyParams }: InventoryStateLegacyModel): IHistoryParams | null {
    return historyParams;
  }
  @Selector()
  static historyCount({ historyCount }: InventoryStateLegacyModel): number | null {
    return historyCount;
  }
  ngxsOnInit({ dispatch }: StateContext<InventoryStateLegacyModel>): void {
    if (this.frameMessageService) {
      this.frameMessageService.on(FrameMessageTypes.MESSAGE_FROM_BB, 'requestInventory', () => dispatch([new GetInventoryItems()]), 600);
    }
  }
  @Action(RefreshActivePanel)
  refreshActivePanel({ dispatch }: StateContext<InventoryStateLegacyModel>, { payload }: RefreshActivePanel): void {
    if (payload && payload.panel === Panel.INVENTORY) {
      dispatch([new GetInventoryItems()]);
    }
    if (payload && payload.subPanel === SubPanel.EXHANGE) {
      dispatch(new GetShopItems('old'));
    }
  }

  @Action(Refresh)
  refresh({ dispatch }: StateContext<InventoryStateLegacyModel>, { payload }: Refresh): void {
    dispatch([payload === 'inventory' ? new GetInventoryItems() : new GetShopItems()]);
  }

  @Action(SellItems)
  sellInventoryItems({ dispatch }: StateContext<InventoryStateLegacyModel>, { ids }: SellItems): Observable<void> {
    return this.apiService.sellInventoryItems({ ids }).pipe(
      delay(500),
      tap(() => {
        dispatch([new RemoveInventoryItems(ids), new GetInventoryInfo(), new ChangeGamesItemsStatus(ids)]);
      }),
      takeUntil(this.actions$.pipe(ofAction(SellItems))),
    );
  }

  @Action(SellAllItems)
  sellAllItems({ dispatch, getState, patchState }: StateContext<InventoryStateLegacyModel>): Observable<void> {
    const { itemsIds, selectedItems, params, isSelectAll } = getState();
    if (isSelectAll) {
      const ignoreIds = itemsIds.filter((item) => !selectedItems.map((selItem) => selItem.id).includes(item));
      patchState({
        isSelectAll: false,
      });
      return this.apiService
        .sellAll(ignoreIds)
        .pipe(
          switchMap(() =>
            dispatch([new ChangeParamsInventory({ ...params, page: 1 }), new ChangeGamesItemsStatus(selectedItems.map((item) => item.id))]),
          ),
        );
    }
    return this.apiService
      .sellAll()
      .pipe(switchMap(() => dispatch([new ChangeParamsInventory({ ...params, page: 1 }), new ChangeGamesItemsStatus(itemsIds)])));
  }

  @Action(Purchase)
  purchase({ patchState, dispatch }: StateContext<InventoryStateLegacyModel>, { ids, userInventoryIds }: Purchase): Observable<void> {
    return this.apiService.requestCreateTrade({ ids, userInventoryIds }).pipe(
      switchMap(() => dispatch([new GetInventoryItems()])),
      tap(() => patchState({ contracts: [] })),
    );
  }

  @Action(Trade)
  trade({ dispatch, getState }: StateContext<InventoryStateLegacyModel>): Observable<void> {
    const { selectedItems, contracts } = getState();

    const ids = contracts.map((el) => el.id);
    const userInventoryIds = selectedItems.map((el) => el.id);

    if (ids.length) {
      return dispatch([new Purchase(ids, userInventoryIds)]);
    } else {
      return dispatch([new SellItems(userInventoryIds)]);
    }
  }

  @Action(GetInventoryItems, { cancelUncompleted: true })
  getInventoryItems({ getState, dispatch, setState }: StateContext<InventoryStateLegacyModel>): Observable<void> {
    const { params, appId, items, isSelectAll, selectedItems } = getState();
    const normalizedParams = this.normalizeParams(params, appId);

    const isFirstPage = params.page ? params.page === 1 : false;
    return this.apiService.requestInventory(normalizedParams).pipe(
      tap((response: Record<GameIds, IUserInventoryItemV2[]>) => {
        const responseItems = response[appId].map((i) => this.IUserInventoryItemV2ToIInventoryItem(i, appId));
        const newItemsList = isFirstPage ? responseItems : items.concat(responseItems);
        setState(
          patch({
            items: newItemsList,
            itemsIds: newItemsList.map((item) => item.id),
            selectedItems: isSelectAll ? append(responseItems) : selectedItems,
          }),
        );
      }),
      switchMap(() => dispatch(new GetInventoryInfo())),
    );
  }
  // TODO перенес но не понимаю нахуа вообще нужен этот запрос
  @Action(GetInventoryInfo)
  getInventoryInfo({ patchState }: StateContext<InventoryStateLegacyModel>): Observable<IInventoryInfo[]> {
    return this.apiService.requestInventoryInfo().pipe(
      tap((response) => {
        const currentInfo = response.find((item) => item.appId === GameIds.CSGO);
        if (currentInfo) {
          const nullInfo = response.find((item) => !item.appId);
          patchState({
            inventoryCount: (currentInfo?.itemsCount ?? 0) + (nullInfo?.itemsCount ?? 0),
            inventorySum: currentInfo.itemsSum,
          });
        } else {
          patchState({
            inventoryCount: 0,
            inventorySum: 0,
          });
        }
      }),
    );
  }

  @Action(GetShopItems)
  getShopItems({ patchState, getState, setState }: StateContext<InventoryStateLegacyModel>, { design }: GetShopItems): Observable<IShop> {
    const { shopParams, appId } = getState();
    const normalizedParams = this.normalizeParams(shopParams, appId);
    const isFirstPage = shopParams.page ? shopParams.page === 1 : false;

    return this.apiService.requestShop(normalizedParams).pipe(
      tap((response) => {
        const responseItems = response.items.map((i) => this.ISkinItemV2ToISkinItem(i, response.appId));
        if (!normalizedParams.selectionSum) {
          setState(
            patch({
              shopItems: isFirstPage || design === 'old' ? responseItems : append<ISkinItem>(responseItems),
              shopMeta: response.meta,
            }),
          );
        } else {
          patchState({
            shopItems: responseItems,
            contracts: responseItems,
            shopMeta: response.meta,
          });
        }
      }),
    );
  }

  @Action(ChangeParamsShop)
  changeParamsShop(
    { patchState, dispatch, getState }: StateContext<InventoryStateLegacyModel>,
    { params, design }: ChangeParamsShop,
  ): Observable<void> {
    const state = getState();
    const page = params.page ? params.page : 1;
    patchState({
      shopParams: { ...state.shopParams, ...params, page: page },
    });
    return dispatch([new GetShopItems(design)]);
  }

  @Action(ChangeParamsInventory)
  changeParamsInventory(
    { patchState, dispatch, getState }: StateContext<InventoryStateLegacyModel>,
    { params }: ChangeParamsInventory,
  ): Observable<void> {
    const { ...state } = getState();
    patchState({
      params: { ...state.params, ...params, page: 1 },
      selectedItems: [],
    });
    return dispatch([new GetInventoryItems()]);
  }
  @Action(ChangeInventoryPage)
  changeInventoryPage(
    { patchState, getState, dispatch }: StateContext<InventoryStateLegacyModel>,
    { page }: ChangeInventoryPage,
  ): Observable<void> {
    const { params } = getState();
    patchState({
      params: {
        ...params,
        page: page,
      },
    });
    return dispatch([new GetInventoryItems()]);
  }

  @Action(ChangeShopPage)
  changeShopPage(
    { patchState, getState, dispatch }: StateContext<InventoryStateLegacyModel>,
    { page }: ChangeInventoryPage,
  ): Observable<void> {
    const { shopParams } = getState();
    patchState({
      shopParams: {
        ...shopParams,
        page: page,
      },
    });
    return dispatch([new GetShopItems()]);
  }

  @Action(ClickOnShopItem)
  clickOnShopItem({ setState, getState }: StateContext<InventoryStateLegacyModel>, { id }: ClickOnShopItem): void {
    const { contracts, shopItems } = getState();
    const item = shopItems.find((el) => el.id === id);
    const selected = contracts.some((el) => el.id === id);

    if (selected) {
      setState(
        patch({
          contracts: removeItem<ISkinItem>((x) => x?.id === id),
        }),
      );
    } else if (item) {
      setState(patch({ contracts: insertItem(item) }));
    }
  }
  @Action(ClickOnInventoryItem)
  clickOnInventoryItem({ setState, getState }: StateContext<InventoryStateLegacyModel>, { id }: ClickOnInventoryItem): void {
    const { selectedItems, items } = getState();
    const item = items.find((el) => el.id === id);
    const selected = selectedItems.some((el) => el.id === id);

    if (selected) {
      setState(
        patch({
          selectedItems: removeItem<IInventoryItem>((x) => x?.id === id),
        }),
      );
    } else if (item) {
      setState(patch({ selectedItems: insertItem(item) }));
    }
  }
  @Action(RemoveInventoryItems)
  removeInventoryItems({ patchState, getState }: StateContext<InventoryStateLegacyModel>, { itemsIds }: RemoveInventoryItems): void {
    const { items } = getState();

    const filteredItems = items.filter((el) => !itemsIds.includes(el.id));

    patchState({
      selectedItems: [],
      items: filteredItems,
      itemsIds: filteredItems.map((item) => item.id),
    });
  }

  @Action(ToggleAllInventoryItems)
  toggleAllInventoryItems({ patchState, getState }: StateContext<InventoryStateLegacyModel>): void {
    const { selectedItems, items } = getState();

    if (selectedItems.length === items.length) {
      patchState({
        selectedItems: [],
      });
    } else {
      patchState({
        selectedItems: [...items],
      });
    }
  }

  @Action(ToggleGameStatus)
  toggleGameStatus({ patchState }: StateContext<InventoryStateLegacyModel>, { gameInProgress }: ToggleGameStatus): void {
    patchState({
      gameInProgress: gameInProgress,
    });
  }

  @Action(UnselectInventoryItemById)
  clearSelection({ patchState, getState }: StateContext<InventoryStateLegacyModel>, { ids }: UnselectInventoryItemById): void {
    const { selectedItems } = getState();
    const filtered = selectedItems.filter((item: IInventoryItem) => !ids.includes(item.id));

    patchState({
      selectedItems: filtered,
    });
  }

  @Action(RequestInventoryHistory)
  requestInventoryHistory({
    getState,
    setState,
  }: StateContext<InventoryStateLegacyModel>): Observable<{ count: number; items: IHistoryInventoryItem[] }> {
    const { historyParams } = getState();
    return this.apiService.requestInventoryHistory(historyParams ? historyParams : undefined).pipe(
      tap((data) => {
        setState(
          patch({
            historyItems: append<IHistoryInventoryItem>(data.items),
            historyCount: data.count,
          }),
        );
      }),
    );
  }

  @Action(ChangeHistoryParams)
  changeHistoryParams(
    { patchState, getState, dispatch }: StateContext<InventoryStateLegacyModel>,
    { payload }: ChangeHistoryParams,
  ): Observable<void> {
    const { historyParams } = getState();
    patchState({ historyParams: { ...historyParams, ...payload } });
    return dispatch([new RequestInventoryHistory()]);
  }

  @Action(UnselectItems)
  unselectItems({ patchState, getState }: StateContext<InventoryStateLegacyModel>, { payload }: UnselectItems): void {
    const { shopParams, selectedItems, contracts, isSelectAll } = getState();
    patchState({
      isSelectAll: payload !== 'shop' && isSelectAll ? false : isSelectAll,
      selectedItems: payload === 'inventory' || !payload ? [] : selectedItems,
      contracts: payload === 'shop' || !payload ? [] : contracts,
      shopParams: {
        ...shopParams,
        selectionSum: null,
      },
    });
  }

  @Action(ToggleIsSelectAll)
  toggleIsSelectAll({ patchState, getState, dispatch }: StateContext<InventoryStateLegacyModel>): void {
    const { isSelectAll } = getState();
    patchState({
      isSelectAll: !isSelectAll,
    });
    dispatch([new ToggleAllInventoryItems()]);
  }

  @Action(FreezeItems)
  // eslint-disable-next-line no-empty-pattern
  freezeItems({}: StateContext<InventoryStateLegacyModel>, { ids }: FreezeItems): Observable<void> {
    return this.apiService.freezeItems(ids);
  }

  // private onError (e: HttpErrorResponse): Observable<object> {
  //   this.inventoryExists.next(false);
  //   this.notificationsService.addNotification({
  //     id: Date.now(),
  //     type: 'error',
  //     icon: 'warning',
  //     message: e.error.message || e.error.error,
  //     createDate: Date.now(),
  //     system: true,
  //     status: NotificationStatus.new,
  //   });
  //   return of(undefined);
  // }
  ////////////////////
  private normalizeParams(params: IInventoryRequestParams, appId: GameIds): any {
    const { page, pageSize, sortBy, minPrice, maxPrice, ...rest } = params;
    const normPrice = (val: number | null | undefined): any => val && this.currencyService.revert(val);
    return {
      ...rest,
      minPrice: normPrice(minPrice),
      maxPrice: normPrice(maxPrice),
      appId,
      page: {
        number: page,
        size: pageSize,
      },
      sortBy: sortBy ? 'price' : '-price',
    };
  }
  private ISkinItemV2ToISkinItem(item: ISkinItemV2, appId: GameIds | undefined): ISkinItem {
    return {
      appId: appId,
      available: item.available,
      color: item.baseItem.color,
      icon: item.baseItem.icon,
      id: item.id,
      name: item.baseItem.name,
      shortName: item.baseItem.shortName,
      skin: item.baseItem.skin,
      exterior: item.baseItem.exterior,
      statTrak: item.baseItem.statTrak,
      weapon: item.baseItem.weapon,
      price: item.price,
      type: item.baseItem.type,
      phase: item.baseItem.phase,
      rarity: item.baseItem.rarity,
    };
  }
  private IUserInventoryItemV2ToIInventoryItem(item: IUserInventoryItemV2, appId: GameIds): IInventoryItem {
    return {
      appId,
      available: item.inventoryItem.available,
      color: item.inventoryItem.baseItem.color,
      icon: item.inventoryItem.baseItem.icon,
      id: item.id,
      inventoryItemId: item.inventoryItemId,
      name: item.inventoryItem.baseItem.name,
      shortName: item.inventoryItem.baseItem.shortName,
      skin: item.inventoryItem.baseItem.skin,
      exterior: item.inventoryItem.baseItem.exterior,
      statTrak: item.inventoryItem.baseItem.statTrak,
      price: item.inventoryItem.price,
      weapon: item.inventoryItem.baseItem.weapon,
      rarity: item.inventoryItem.baseItem.rarity,
      //todo
      type: item.inventoryItem.baseItem.type,
      phase: item.inventoryItem.baseItem.phase,
      isFrozen: item.isFrozen,
    };
  }
}
