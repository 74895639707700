import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, NgModule } from '@angular/core';

@Component({
  selector: 'app-ui-noindex',
  template: ``,
  styleUrls: ['./noindex.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NoIndexComponent {}

@NgModule({
  imports: [CommonModule],
  declarations: [NoIndexComponent],
  exports: [NoIndexComponent],
})
export class NoIndexComponentModule {}
