import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { P2pLotCardModule, UserAvatarModule } from '@dev-fast/ui-components';
import { TranslateModule } from '@ngx-translate/core';
import { NgScrollbarModule } from 'ngx-scrollbar';

import { AppCurrencyModule, HashPipe, ReplaceAvatarHashPipeModule } from '@app/shared/pipes';

import { P2pNewAuctionCompletedModalComponent } from './p2p-auction-completed-modal.component';

@NgModule({
  declarations: [P2pNewAuctionCompletedModalComponent],
  exports: [P2pNewAuctionCompletedModalComponent],
  imports: [
    CommonModule,
    MatIconModule,
    TranslateModule,
    AppCurrencyModule,
    UserAvatarModule,
    P2pLotCardModule,
    ReplaceAvatarHashPipeModule,
    HashPipe,
    NgScrollbarModule,
  ],
})
export class P2pAuctionCompletedModalModule {}
