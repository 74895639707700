export interface IProvablyFair {
  id: number;
  clientId: number;
  nonce: number;
  roll: number;
  createdAt: Date;
  serverSeed: Record<string, any>;
  serverSeedHash: string;
  clientSeed: string;
  caseId: number;
  caseRevisionId: number;
  indexCaseRevision: number;
  json?: Record<string, any>;
}

export interface ISeedClient {
  clientId: number;
  createdAt: string;
  id: number;
  seed: string;
  updatedAt: string;
}

export interface ISeedServer {
  clientId: number;
  createdAt: string;
  id: number;
  nonce: number;
  revealedAt: string | null;
  seed: string;
  seedHash: string;
  updatedAt: string;
}

export interface IProvablyFairSettings {
  clientSeed: ISeedClient;
  serverSeed: ISeedServer;
}
export interface IProvablyFairHistory {
  clientSeedsHistory: ISeedClient[];
  serverSeedsHistory: ISeedServer[];
}
