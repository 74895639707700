import { Params, RouterStateSnapshot } from '@angular/router';
import { RouterStateSerializer } from '@ngxs/router-plugin';

declare global {
  interface Window {
    Gleam: any[];
  }
}
export interface RouterStateParams {
  url: string;
  params: Params;
  queryParams: Params;
  fragment: string | null;
}

// Map the router snapshot to { url, params, queryParams }
export class CustomRouterStateSerializer implements RouterStateSerializer<RouterStateParams> {
  serialize(routerState: RouterStateSnapshot): RouterStateParams {
    const {
      url,
      root: { queryParams },
    } = routerState;

    let { root: route } = routerState;
    while (route.firstChild) {
      route = route.firstChild;
    }

    const { params, fragment } = route;

    return { url, params, queryParams, fragment };
  }
}

//FIXME унести в какой-нибудь евент или third-party scripts сервис где еще window ормальный сделать
export const addEventToGleam = (gameName: string, userId: number): void => {
  window.Gleam = window.Gleam || [];
  window.Gleam.push([gameName, userId]);
};

// ['cases', 'fast', 'classic', 'hilo', 'crash', 'double', 'x50', 'baccarat', 'craft', 'wheel', 'tower', 'slot','deposit'];
