import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, DestroyRef, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { AgreementName, IUICheckboxLabel, QuickFaqName } from '@dev-fast/types';
import { UiCheckboxSAComponent } from '@dev-fast/ui-components';
import { TranslateModule } from '@ngx-translate/core';
import { tap } from 'rxjs';

import { AgreementsService } from '@app/core/state/agreements';
import { QuickFaqService } from '@app/core/state/faq';

@Component({
  selector: 'app-real-world-trading',
  templateUrl: './market-rwt.component.html',
  styleUrls: ['./market-rwt.component.scss'],
  standalone: true,
  imports: [NgIf, ReactiveFormsModule, TranslateModule, MatIconModule, UiCheckboxSAComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class P2pMarketRWTSAComponent {
  readonly #destroyRef = inject(DestroyRef);
  readonly #dialogRef = inject(MatDialogRef<P2pMarketRWTSAComponent>);
  readonly #quickFaqService = inject(QuickFaqService);
  readonly #agreementsService = inject(AgreementsService);
  readonly isCheckboxShow = inject(MAT_DIALOG_DATA);

  readonly labels: IUICheckboxLabel[] = [
    {
      title: 'MARKET.RWT.MODAL.DONT_SHOW_AGAIN',
    },
  ];
  readonly rwtFaqForm = new FormGroup({
    showModal: new FormControl<boolean>(false, { nonNullable: true }),
  });

  constructor() {
    if (this.isCheckboxShow) {
      this.#quickFaqService
        .quickFaqStatus$(QuickFaqName.RWT)
        .pipe(
          tap((RWTWasClosed) => {
            this.rwtFaqForm.controls.showModal.patchValue(RWTWasClosed, { emitEvent: false });
          }),
          takeUntilDestroyed(this.#destroyRef),
        )
        .subscribe();
    }
    this.rwtFaqForm.controls.showModal.valueChanges
      .pipe(
        tap((closed) => {
          this.#quickFaqService.setStatus({ menuName: QuickFaqName.RWT, wasClosed: closed });
        }),
        takeUntilDestroyed(this.#destroyRef),
      )
      .subscribe();
  }
  redirect(): void {
    this.#dialogRef.close();
  }
  closeModal = (): void => {
    this.#dialogRef.close();
  };
  RWTAgree = (): void => {
    this.#dialogRef.close();
    this.#agreementsService.setAgreement(AgreementName.RWT, true);
  };
}
