<ng-container *ngIf="message">
  <div
    class="chat-item"
    [class.chat-item--sended]="isMyMessage"
  >
    <div
      class="chat-item--header"
      *ngIf="!isMyMessage"
    >
      <app-ui-user-avatar
        *ngIf="message.user"
        [url]="message.user.avatar"
        [userId]="message.user.id"
      ></app-ui-user-avatar>
      <div>{{ message.user.name }}</div>
    </div>
    <div class="chat-item--body">
      <ng-container *ngIf="checkMessageType(message.value); else sticker">
        {{ message.value }}
      </ng-container>
      <ng-template #sticker>
        <div class="chat-message__sticker">
          <img
            lazyLoadImage
            [src]="getSticker(message.value)"
            [alt]="'chat message'"
          />
        </div>
      </ng-template>
    </div>
    <div class="chat-item--time">{{ getDate(message.createdAt) }}</div>
  </div>
</ng-container>
