import { IP2pPurchaseItem } from '@dev-fast/types';

export interface P2pBuyingStateModel {
  purchasing: IP2pPurchaseItem[];
  showWarn: boolean;
}
export const P2P_BUYING_INITIAL_STATE: P2pBuyingStateModel = {
  purchasing: [],
  showWarn: true,
};
