import { ActionStatus } from '@ngxs/store';

// ActionStatus в либе ngxs является declare const так что использовать в шаблонах мы его не можем
// Этот "кастомный enum" даст нам к этому доступ
export const ActionStatusEnum = {
  Dispatched: ActionStatus.Dispatched,
  Successful: ActionStatus.Successful,
  Canceled: ActionStatus.Canceled,
  Errored: ActionStatus.Errored,
};
