import { CurrencyPipe } from '@angular/common';
import { ChangeDetectorRef, OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { ICurrencyRate } from '@dev-fast/types';
import { combineLatest, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { CurrencyService } from '@app/core/currency';

/** @deprecated  заменить на app-currency компонент*/
@Pipe({
  name: 'appCurrency',
  pure: false,
})
export class AppCurrencyPipe implements PipeTransform, OnDestroy {
  currency: ICurrencyRate | undefined;
  currencyList: ICurrencyRate[] | null | undefined;
  private readonly destroyed$: Subject<void>;
  constructor(
    private currencyPipe: CurrencyPipe,
    private currencyService: CurrencyService,
    private cdr: ChangeDetectorRef,
  ) {
    this.destroyed$ = new Subject<void>();
    combineLatest([this.currencyService.currency$, this.currencyService.currencyList$])
      .pipe(
        takeUntil(this.destroyed$),
        filter(([currency, currencyList]) => !!currency && !!currencyList),
      )
      .subscribe(([currency, currencyList]) => {
        this.currency = currency as ICurrencyRate;
        this.currencyList = currencyList;
        this.cdr.markForCheck();
      });
  }

  transform(
    value: any,
    args?: {
      cKey?: 'usd' | 'fastcoins' | 'fastcoins_refill';
      currencyCode?: string;
      display?: 'code' | 'symbol' | 'symbol-narrow' | string | boolean;
      digitsInfo?: string;
      locale?: string;
      hidePrefix?: boolean;
    },
  ): string | null {
    if (value !== null) {
      value = this.currencyService.convertBySelectedRateKey(args && args.cKey ? args.cKey : 'fastcoins', value);
      const currencyBycKey = this.currencyList?.find((currency) => currency.key === args?.cKey);
      const result = this.currencyPipe.transform(
        value,
        args && args.currencyCode ? args.currencyCode : '',
        currencyBycKey ? currencyBycKey.prefix.trim() : (!args?.hidePrefix && this.currency && this.currency.prefix) || '',
        args?.digitsInfo,
      );

      return result && result.endsWith('.00') && result.length >= 5 ? result.slice(0, -3) : result;
    }
    return this.currencyPipe.transform(0, args?.currencyCode, args?.display, args?.digitsInfo, args?.locale);
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
