<ng-container *ngIf="emojis">
  <div
    class="emoji-container"
    [class.sticky-behavior]="isSticky"
  >
    <div class="emoji-rail-container">
      <div class="emoji-wrapper">
        <ng-container *ngFor="let emoji of emojis; let emojiIndex = index">
          <button
            class="btn btn-darken btn-sm sq emoji-btn"
            [disabled]="!canSend"
            (click)="changeEmoji(emoji.id)"
          >
            <div class="icons-wrapper">
              <mat-icon
                class="icon emoji-icon"
                [svgIcon]="emoji.src"
              >
              </mat-icon>
              <ng-container *ngFor="let activeEmoji of bufferArray; let activeEmojiIndex = index">
                <mat-icon
                  class="icon emoji-icon swapped"
                  [class]="'emoji_' + emoji.id + '_index_' + activeEmojiIndex"
                  [svgIcon]="emoji.src"
                >
                </mat-icon>
              </ng-container>
            </div>
          </button>
        </ng-container>
      </div>
    </div>
  </div>
</ng-container>
