import { HttpErrorResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { P2pApiNewService } from '@dev-fast/backend-services';
import {
  IDepositBatchPayloadV2,
  IMarketplaceItem,
  IP2pDepositingItem,
  IP2pDepositItem,
  IP2pParticipateItemUpdated,
  ISteamStore,
  ISteamStoreInventory,
  ModalNames,
  OrderStatusEnum,
  Panel,
  SubPanel,
} from '@dev-fast/types';
import { Action, NgxsOnInit, Selector, State, StateContext, Store } from '@ngxs/store';
import { insertItem, patch, removeItem, updateItem } from '@ngxs/store/operators';
import { round } from 'lodash';
import { delay, EMPTY, Observable, of, throwError } from 'rxjs';
import { catchError, startWith, switchMap, tap } from 'rxjs/operators';

import { LocalStorageService } from '@app/core/local-storage-service';
import { NotificationsService } from '@app/core/notification-service';
import { ChangeActivePanel, LayoutState } from '@app/core/state/layout';
import { CloseModal, ModalsState, OpenModal } from '@app/core/state/modals';
import { GetP2pPermissions, RefreshCurrentUser, UserState } from '@app/core/state/user-store';
import { sortByPriority } from '@app/shared/utils';

import { GetSteamInventory } from '../common';
import { P2pMarketState } from '../market';
import {
  AutoShowP2PAuctionCompletedModal,
  ChangeSelectedItem,
  ChangeSelectedItems,
  Deposit,
  DepositSelected,
  GetDepositing,
  GetDepositingAutoSelection,
  GetDepositModifier,
  GetDepositModifierSuccess,
  ParticipantItemUpdatedEvent,
  PauseDepositing,
  RemoveDepositItem,
  RemoveMarketItems,
  ReqAppointing,
  ResetSelected,
  ResumeDepositing,
  SellNow,
  SetDepositModifier,
  StopDepositing,
  ToggleDepositItem,
  UpdateOverprice,
} from './deposit.actions';
import { MIN_DEFAULT_OVERPRICE, P2P_DEPOSIT_INITIAL_STATE, P2pDepositStateModel, priorityList } from './deposit-state.model';

@State<P2pDepositStateModel>({
  name: 'p2p_deposit',
  defaults: P2P_DEPOSIT_INITIAL_STATE,
})
@Injectable()
export class P2pDepositState implements NgxsOnInit {
  readonly #store = inject(Store);
  readonly #storageService = inject(LocalStorageService);
  readonly #p2pApiService = inject(P2pApiNewService);
  readonly #notificationsService = inject(NotificationsService);

  @Selector()
  static depositingItems({ depositingItems }: P2pDepositStateModel): IP2pDepositingItem[] {
    return depositingItems;
  }
  @Selector()
  static overprice({ overprice }: P2pDepositStateModel): number {
    return overprice;
  }
  @Selector()
  static isItemsOnPause({ depositingItems }: P2pDepositStateModel): boolean {
    return depositingItems.some((el) => el.status === OrderStatusEnum.PAUSED);
  }
  @Selector()
  static selected({ selected }: P2pDepositStateModel): IP2pDepositItem[] {
    return selected;
  }
  @Selector()
  static selectedSum({ selected }: P2pDepositStateModel): number {
    return selected.reduce((sum, { price }) => sum + price, 0);
  }
  @Selector()
  static selectedSumWithOverprice({ selected }: P2pDepositStateModel): number {
    return selected.reduce((sum, { extra, price }) => sum + (extra.price ?? price), 0);
  }
  @Selector()
  static depositById(state: P2pDepositStateModel) {
    return (id: number) => {
      return state.selected.find((s) => s.steamInventoryId === id);
    };
  }

  ngxsOnInit({ getState, dispatch }: StateContext<P2pDepositStateModel>): void {
    dispatch(new GetP2pPermissions());
    dispatch(new GetDepositing());
    this.#p2pApiService.participantItemUpdatedEvent((payload: IP2pParticipateItemUpdated) => {
      const { depositingItems } = getState();
      const element = depositingItems.find((el) => el.id === payload.id);

      if (element) {
        if (element.status === OrderStatusEnum.WAIT_FOR_TRADE && payload.status === OrderStatusEnum.CRATED) {
          return;
        }
        dispatch(new ParticipantItemUpdatedEvent({ ...element, ...payload }));
      }
    });
  }
  @Action(RefreshCurrentUser)
  refreshUser({ dispatch }: StateContext<P2pDepositStateModel>, { payload }: RefreshCurrentUser): void {
    const user = this.#store.selectSnapshot(UserState.user);

    if (
      user &&
      ((payload.steamApiKey && user?.steamApiKey !== payload.steamApiKey) ||
        (payload.links?.steam && user?.links?.steam !== payload.links?.steam))
    ) {
      dispatch(new GetP2pPermissions());
    }

    if (payload.id && payload.id !== user?.id) {
      dispatch(new GetDepositing());
    }
  }
  @Action(AutoShowP2PAuctionCompletedModal)
  autoShowP2PAuctionCompletedModal({ dispatch, getState }: StateContext<P2pDepositStateModel>): Observable<void> {
    const { depositingItems } = getState();
    const filteredItemForDeposit = depositingItems.filter(
      (el) =>
        el.status === OrderStatusEnum.WAIT_FOR_TRADE &&
        el.order &&
        el.order.steamItemPosition &&
        el.order.steamItemPosition.length &&
        el.order.buyerTradeLink,
    );
    const sorted = filteredItemForDeposit.sort((l, r) =>
      Date.parse(l.statusAt) && Date.parse(r.statusAt) ? (Date.parse(l.statusAt) > Date.parse(r.statusAt) ? -1 : 1) : 0,
    );
    const lastItemForDeposit = sorted[0];
    const activeModals = this.#store.selectSnapshot(ModalsState.activeModals);

    if (lastItemForDeposit && (!activeModals.length || (activeModals.length && !activeModals.includes(ModalNames.P2P_AUCTION_COMPLETED)))) {
      return dispatch(new OpenModal(ModalNames.P2P_AUCTION_COMPLETED, of(lastItemForDeposit)));
    } else {
      return of();
    }
  }

  @Action(RemoveDepositItem)
  removeDepositItem({ setState }: StateContext<P2pDepositStateModel>, { payload }: RemoveDepositItem): void {
    setState(
      patch({
        selected: removeItem<IP2pDepositItem>((x) => x?.steamInventoryId === payload.steamInventoryId),
      }),
    );
  }

  @Action(ToggleDepositItem)
  toggleDepositItem(context: StateContext<P2pDepositStateModel>, { payload }: ToggleDepositItem): void {
    const { setState, getState, dispatch } = context;
    const { overprice, selected } = getState();

    const isSelected = selected.some(({ steamInventoryId }) => steamInventoryId === payload.steamInventoryId);

    if (isSelected) {
      setState(
        patch({
          selected: removeItem<IP2pDepositItem>((x) => x?.steamInventoryId === payload.steamInventoryId),
        }),
      );
    } else {
      const { activePanel, canOpenPanel } = this.#store.selectSnapshot(LayoutState);

      if ((activePanel === null || activePanel.panel !== Panel.TRADES) && selected.length === 0 && canOpenPanel) {
        dispatch(new ChangeActivePanel({ panel: Panel.TRADES, subPanel: SubPanel.NONE }));
      }

      setState(
        patch({
          selected: insertItem(this.#formatExtraForItem(payload, overprice)),
        }),
      );
    }

    const newOverprice = this.#getOverprice(context, payload, isSelected);

    dispatch(new UpdateOverprice(newOverprice));
  }

  @Action(ParticipantItemUpdatedEvent)
  participantItemUpdated(
    { setState, dispatch, getState, patchState }: StateContext<P2pDepositStateModel>,
    { payload }: ParticipantItemUpdatedEvent,
  ): void {
    if (payload.status && payload.status === OrderStatusEnum.DELETED) {
      setState(
        patch({
          depositingItems: removeItem<IP2pDepositingItem>((x) => x?.id === payload.id),
        }),
      );
    } else {
      setState(
        patch({
          depositingItems: updateItem<IP2pDepositingItem>((o) => o?.id === payload.id, payload),
        }),
      );
      const { depositingItems } = getState();

      patchState({
        depositingItems: sortByPriority(depositingItems, 'status', 'statusAt', priorityList, 'asc') as IP2pDepositingItem[],
      });

      if (payload.status === OrderStatusEnum.WAIT_FOR_TRADE && payload.order && payload.order.steamItemPosition) {
        dispatch(new AutoShowP2PAuctionCompletedModal());
      }
      const activeModals = this.#store.selectSnapshot(ModalsState.activeModals);

      if (
        activeModals.length &&
        activeModals.includes(ModalNames.P2P_AUCTION_COMPLETED) &&
        payload.status !== OrderStatusEnum.WAIT_FOR_TRADE
      ) {
        dispatch(new CloseModal(ModalNames.P2P_AUCTION_COMPLETED));
      }
    }
  }

  @Action(GetDepositing)
  getDepositing({ patchState, dispatch, getState }: StateContext<P2pDepositStateModel>): Observable<void> {
    const { depositingItems } = getState();
    return this.#p2pApiService.getMyItems().pipe(
      tap((response) => {
        patchState({ depositingItems: sortByPriority(response.kits, 'status', 'statusAt', priorityList, 'asc') as IP2pDepositingItem[] });
      }),
      delay(7000),
      switchMap(() => {
        if (depositingItems.length === 0) {
          return dispatch(new AutoShowP2PAuctionCompletedModal());
        } else {
          return of();
        }
      }),
    );
  }
  @Action(GetDepositingAutoSelection)
  getDepositItemsAutoSelection(
    { getState, patchState }: StateContext<P2pDepositStateModel>,
    { params }: GetDepositingAutoSelection,
  ): Observable<ISteamStore> {
    const { overprice } = getState();

    return this.#p2pApiService.getInventoryAutoSelect(params).pipe(
      // Сюда можно добавить отлавливание SellerBan
      catchError((e) => {
        this.#onError(e);
        return throwError(() => e).pipe(startWith(e));
      }),
      tap(({ items }: ISteamStore): void => {
        if (items) {
          const selected: IP2pDepositItem[] = this.#updateItems(items, overprice);
          patchState({ selected });
        }
      }),
    );
  }
  @Action(ResetSelected)
  resetSelected({ patchState }: StateContext<P2pDepositStateModel>): void {
    patchState({ selected: [] });
  }
  @Action(StopDepositing)
  stopDepositing({ dispatch, getState }: StateContext<P2pDepositStateModel>): Observable<void> {
    const { depositingItems } = getState();
    //TODO
    const excludesStatuses: OrderStatusEnum[] = [OrderStatusEnum.AUCTION_FINISHED, OrderStatusEnum.COMPLETED, OrderStatusEnum.DELETED];
    const ids = depositingItems.filter((val) => !excludesStatuses.includes(val.status)).map((el) => el.id);

    return dispatch(new RemoveMarketItems(ids));
  }
  @Action(PauseDepositing)
  pauseDepositing(
    { getState, patchState, dispatch }: StateContext<P2pDepositStateModel>,
    { id }: PauseDepositing,
  ): Observable<void> | Observable<{ kits: IMarketplaceItem[] }> {
    const { depositingItems } = getState();
    //TODO тупо, очень тупо
    const includesStatuses: OrderStatusEnum[] = [OrderStatusEnum.NEW];
    const ids = id ? [id] : depositingItems.filter((val) => includesStatuses.includes(val.status)).map((el) => el.id);
    if (ids.length === 0) {
      return dispatch(new StopDepositing());
    }
    return this.#p2pApiService.reqPause(ids).pipe(
      tap((resp) => {
        // FIXME сделано что бы состояние было актуальным в случае если запрос был долгим и из сокета что-то уже успело поменятся
        // смотри TODO выше
        // eslint-disable-next-line @typescript-eslint/no-shadow
        const { depositingItems } = getState();
        const respItemsIds = resp.kits.map((el) => el.id);
        const filteredItems = depositingItems.filter((el) => !respItemsIds.includes(el.id));

        patchState({
          depositingItems: sortByPriority(
            [...filteredItems, ...resp.kits],
            'status',
            'statusAt',
            priorityList,
            'asc',
          ) as IP2pDepositingItem[],
        });
      }),
    );
  }
  @Action(ResumeDepositing)
  resumeDepositing(
    { patchState, getState }: StateContext<P2pDepositStateModel>,
    { id }: ResumeDepositing,
  ): Observable<{ errors: { error: string; id?: number; steamInventoryId?: number }[]; kits: IMarketplaceItem[] }> {
    const { depositingItems } = getState();
    //TODO тупо, очень тупо
    const includesStatuses: OrderStatusEnum[] = [OrderStatusEnum.PAUSED];
    const ids = id ? [id] : depositingItems.filter((val) => includesStatuses.includes(val.status)).map((el) => el.id);

    return this.#p2pApiService.reqResume(ids).pipe(
      tap((resp) => {
        // FIXME сделано что бы состояние было актуальным в случае если запрос был долгим и из сокета что-то уже успело поменятся
        // смотри TODO выше
        // eslint-disable-next-line @typescript-eslint/no-shadow
        const { depositingItems } = getState();
        const respItemsIds = resp.kits.map((el) => el.id);
        const filteredItems = depositingItems.filter((el) => !respItemsIds.includes(el.id));
        patchState({
          depositingItems: sortByPriority(
            [...filteredItems, ...resp.kits],
            'status',
            'statusAt',
            priorityList,
            'asc',
          ) as IP2pDepositingItem[],
        });
      }),
    );
  }

  @Action(RemoveMarketItems)
  removeMarketItem(
    { dispatch, patchState, getState }: StateContext<P2pDepositStateModel>,
    { ids }: RemoveMarketItems,
  ): Observable<Observable<never> | { items: IMarketplaceItem[] }> {
    // удаление фейковой продажи
    // id у фейковых продаж это число от 0 до 1
    if (ids[0] < 1) {
      const { depositingItems } = getState();
      patchState({
        depositingItems: depositingItems.filter((order) => order.id !== ids[0]),
      });
      return of();
    }
    return this.#p2pApiService.reqDelete(ids).pipe(
      tap(() => {
        const { depositingItems } = getState();
        const filteredItems = depositingItems.filter((el) => !ids.includes(el.id));
        const tmpFilters = this.#store.selectSnapshot(P2pMarketState.tmpFilters);
        patchState({
          depositingItems: sortByPriority(filteredItems, 'status', 'statusAt', priorityList, 'asc') as IP2pDepositingItem[],
        });
        dispatch(new GetSteamInventory(tmpFilters));
      }),
      catchError((e) => {
        this.#onError(e);
        return of(EMPTY);
      }),
    );
  }
  @Action(SellNow)
  sellNow({ setState, dispatch }: StateContext<P2pDepositStateModel>, { id }: SellNow): Observable<void | Observable<never>> {
    setState(
      patch({
        depositingItems: updateItem<IP2pDepositingItem>(
          (o) => o?.id === id,
          patch({
            isActive: true,
          }),
        ),
      }),
    );
    return this.#p2pApiService.reqSellNow(id).pipe(
      switchMap((val) => dispatch(new GetDepositing())),
      catchError((e) => {
        setState(
          patch({
            depositingItems: updateItem<IP2pDepositingItem>(
              (o) => o?.id === id,
              patch({
                isActive: false,
              }),
            ),
          }),
        );
        this.#onError(e);
        return of(EMPTY);
      }),
    );
  }
  @Action(DepositSelected)
  depositSelected(
    { dispatch, getState }: StateContext<P2pDepositStateModel>,
    { staticOverprice }: DepositSelected,
  ): Observable<Observable<never> | { errors: { error: string; steamInventoryId: number }[]; items: IMarketplaceItem[] }> {
    const { selected } = getState();

    const additionalSettings = this.#store.selectSnapshot(UserState.additionalSettings);
    const tmpFilters = this.#store.selectSnapshot(P2pMarketState.tmpFilters);
    const payload: IDepositBatchPayloadV2 = {
      items: selected.map(({ extra, price, steamInventoryId }) => ({
        steamInventoryId,
        price: staticOverprice ? round(price * 0.95) : extra.price,
      })),
      autoApprove: typeof selected[0].autoApprove === 'boolean' ? selected[0].autoApprove : additionalSettings?.market?.autoApprove,
      fastDelivery: typeof selected[0].fastDelivery === 'boolean' ? selected[0].fastDelivery : additionalSettings?.fastDelivery,
      tradesDuration: selected[0].tradesDuration ? Number(selected[0].tradesDuration) : additionalSettings?.market?.tradesDuration,
    };
    return this.#p2pApiService.reqDepositSelected(payload).pipe(
      tap((response) => {
        if (response.errors?.length) {
          response.errors.forEach((el) => this.#notificationsService.addErrorNotification(el.error));
        }
        return dispatch([new GetSteamInventory(tmpFilters), new GetDepositing(), new ResetSelected()]);
      }),
      catchError((e) => {
        this.#onError(e);
        dispatch(new GetSteamInventory(tmpFilters));
        return of(EMPTY);
      }),
    );
  }
  @Action(GetDepositModifier)
  getDepositModifier({ dispatch }: StateContext<P2pDepositStateModel>): Observable<void> {
    const preview = this.#storageService.get('preview-deposit-modifier');
    return dispatch(new GetDepositModifierSuccess(preview));
  }
  @Action(SetDepositModifier)
  setDepositModifier(context: StateContext<P2pDepositStateModel>, { payload }: SetDepositModifier): void {
    this.#storageService.set('preview-deposit-modifier', payload);
  }
  @Action(Deposit)
  deposit(ctx: StateContext<P2pDepositStateModel>, { id, price }: Deposit): Observable<void | Observable<never>> {
    return this.#p2pApiService.reqDeposit(id, price).pipe(
      catchError((e) => {
        this.#onError(e);
        return of(EMPTY);
      }),
    );
  }
  @Action(ReqAppointing)
  reqAppointing(
    { setState }: StateContext<P2pDepositStateModel>,
    { id, action }: ReqAppointing,
  ): Observable<Observable<never> | IMarketplaceItem> {
    return this.#p2pApiService.reqAppointing(id, action).pipe(
      tap((val) =>
        setState(
          patch({
            depositingItems: updateItem<IP2pDepositingItem>((o) => o?.id === val.id, val),
          }),
        ),
      ),
      catchError((e) => {
        this.#onError(e);
        return of(EMPTY);
      }),
    );
  }
  @Action(ChangeSelectedItem)
  changeSelectedItem({ setState }: StateContext<P2pDepositStateModel>, { payload }: ChangeSelectedItem): void {
    setState(
      patch({
        selected: updateItem<IP2pDepositItem>((x) => x?.steamInventoryId === payload.steamInventoryId, payload),
      }),
    );
  }
  @Action(ChangeSelectedItems)
  changeSelectedItems({ setState }: StateContext<P2pDepositStateModel>, { payload }: ChangeSelectedItems): void {
    setState(
      patch({
        selected: payload,
      }),
    );
  }
  @Action(UpdateOverprice)
  updateOverprice({ setState, getState }: StateContext<P2pDepositStateModel>, { overprice }: UpdateOverprice): void {
    const { selected } = getState();

    setState(
      patch({
        overprice: overprice,
        selected: this.#updateItems(selected, overprice),
      }),
    );
  }

  #onError(e: HttpErrorResponse): void {
    const { error } = e;
    this.#notificationsService.addErrorNotification(error.message || error.error, {
      icon: 'warning',
      system: error.system,
      params: error?.params ?? {},
    });
  }

  #formatExtraForItem(item: ISteamStoreInventory, overprice = MIN_DEFAULT_OVERPRICE): IP2pDepositItem {
    let priceForItem = round(item.price + item.price * (overprice / 100));
    let overpriceForItem = overprice;

    if (priceForItem > item.priceRange.end) {
      priceForItem = item.priceRange.end;
      overpriceForItem = (100 * item.price) / item.priceRange.end - 100;
    } else if (priceForItem < item.priceRange.start) {
      priceForItem = item.priceRange.start;
      overpriceForItem = (100 * item.priceRange.start) / item.price - 100;
    }

    return {
      ...item,
      extra: {
        increase: round(overpriceForItem),
        price: priceForItem,
        safeOverprice: this.#getSafeOverprice(item),
      },
    };
  }

  #updateItems(items: ISteamStoreInventory[], overprice = 0): IP2pDepositItem[] {
    return [...items].map((item) => {
      return this.#formatExtraForItem(item, overprice);
    });
  }

  #getOverprice({ getState }: StateContext<P2pDepositStateModel>, item: ISteamStoreInventory, isSelected: boolean): number {
    const { overprice, selected } = getState();

    const itemSafeOverprice = this.#getSafeOverprice(item);
    const MIN_DEFAULT_OVERPRICE_FOR_TWO_ITEMS = -10;

    if (isSelected) {
      if (selected.length === 0) {
        return MIN_DEFAULT_OVERPRICE;
      }

      if (selected.length === 1) {
        return overprice === MIN_DEFAULT_OVERPRICE_FOR_TWO_ITEMS ? selected[0].extra.safeOverprice ?? 0 : overprice;
      }
    }

    if (!isSelected) {
      if (selected.length === 1) {
        return itemSafeOverprice;
      }

      if (selected.length === 2) {
        const firstSelectedItem = selected.filter(({ steamInventoryId }) => steamInventoryId !== item.steamInventoryId);

        return overprice === firstSelectedItem[0].extra.safeOverprice ? MIN_DEFAULT_OVERPRICE_FOR_TWO_ITEMS : overprice;
      }
    }

    return overprice;
  }

  #getSafeOverprice({ price, safePrice }: ISteamStoreInventory): number {
    return safePrice ? round(((safePrice - price) / price) * 100) : MIN_DEFAULT_OVERPRICE;
  }
}
