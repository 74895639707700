<ng-container *ngIf="modes.length; else loadingTmpl">
  <div
    [class]="'menu__group menu__group_' + mode"
    *ngFor="let mode of modes"
  >
    <ng-container *ngIf="getMode(mode) as category">
      <ui-game-card
        *ngFor="let game of category"
        [game]="game"
        [isNew]="newGames[game.key]"
        [isActive]="activeUrl === '/game/' + game.key"
        [matTooltip]="game.title"
        matTooltipPosition="right"
      >
      </ui-game-card>
    </ng-container>
  </div>
</ng-container>
<ng-template #loadingTmpl>
  <app-ui-spinner-loader></app-ui-spinner-loader>
</ng-template>
