import { IClassicWidgetBlock, ISkinItem } from '../../../../entities';
import { GameIds } from '../../../../enums';
import { Trade } from '../../../../models';

export interface IClassicStateDto {
  // Информация о игроке, сделавшем наибольшую ставку
  biggestBet: IClassicWidgetBlock[];

  // Массив ID игроков текущего раунда
  idsTape: number[];

  // Информация о победителе предыдущего раунда
  lastWinner: IClassicWidgetBlock[];

  // Информация о игроке, победившем с наименьшим шансом
  luckyPlayer: IClassicWidgetBlock[];

  // Хэш текущего раунда
  md5: string;

  // Порядковый номер текущего раунда
  number: number;

  // Разыгрываемая в текущем раунде сумма
  prize: number;

  rafflingTimestampDiff: number | null;

  rand: string | null;

  skin: Record<GameIds, ISkinItem>;

  // Массив ссылок на аватары игроков текущего раунда
  tape: string[] | null;

  timeOldEnds: number | null;

  // Массив данных о сделанных в текущем раунде ставках
  trades: Trade[] | null;

  // ID победителя текущего раунда
  winner: number | null;
}
