export enum SteamStatuses {
  NORMAL = 'normal',
  DELAYED = 'delayed',
  SURGE = 'surge',
  OFFLINE = 'offline',
}

export enum SteamItemsStatus {
  INITIAL = 'initial',
  ERROR = 'error',
  EMPTY = 'empty',
  LOADED = 'loaded',
}

export enum SteamTradeStatus {
  CANT_TRADE = 'cant_trade',
  NEED_STEAM_LOGIN = 'need_steam_login',
  NEED_PERMISSIONS_ADD = 'need_permissions_add',
  READY = 'ready',
}
