/* типы реферальных кодов */
export enum ReferralCampaignTypes {
  PERCENT = 'gofast-custom-percent', //проценты
  MONEY = 'gofast-custom-money', //деньги
  GOFAST = 'gofast', //проценты + фрикейс
  // чисто хэл история пока не юзается
  CASES = 'cases',
}
/* типы промокодов */
export enum PromocodeTypes {
  PERCENT = 'percent', //проценты
  MONEY = 'money', //деньги
}
