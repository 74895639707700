import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { INotifyData } from '@dev-fast/types';
import { TranslateModule } from '@ngx-translate/core';

import { AppCurrencyModule } from '@app/shared/pipes';

import { TransactionsDialogComponent } from './transactions-dialog/transactions-dialog.component';

@Component({
  selector: 'app-transactions-notification-modal-layout',
  templateUrl: './transactions-notification-modal-layout.component.html',
  styleUrls: ['./transactions-notification-modal-layout.component.scss'],
  standalone: true,
  imports: [CommonModule, MatIconModule, TranslateModule, AppCurrencyModule, TransactionsDialogComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TransactionsNotificationModalComponent {
  refillData: INotifyData | undefined;

  constructor(
    public dialogRef: MatDialogRef<TransactionsNotificationModalComponent>,
    @Inject(MAT_DIALOG_DATA) private data: INotifyData | undefined,
  ) {
    this.refillData = data;
  }
  closeModal(): void {
    this.dialogRef.close();
  }
}
