import { SocialItem } from '@dev-fast/types';

export interface AuthStateModel {
  isAuth: boolean;
  socials: SocialItem[];
  unlinkedSocials: SocialItem[] | null;
}

export const AUTH_INITIAL_STATE: AuthStateModel = {
  isAuth: false,
  socials: [],
  unlinkedSocials: null,
};
