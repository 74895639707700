import { FormArray, FormControl, FormGroup } from '@angular/forms';

import { DefaulSortingTypes, MarketSortingTypes, SortingCaseBattleTypes } from '../enums';
import { Pagination } from '../models';

export interface IFilterMethod<T> {
  value: T;
  viewValue: string;
  // все ниже - для совместимости с новым дропдауном и костыль
  dropdownItem?: IDropdownItem;
}
//дублируется ISelectionMenuItem, заведенным локально в паре мест
export interface IDropdownItem {
  title: string;
  name: string;
  icon?: {
    type: string;
    src: string;
  };
}
export interface IFilterRange {
  minValue: number;
  maxValue: number;
}

export interface IPriceFilter {
  minValue: number | null;
  maxValue: number | null;
  viewValue: string;
}

export interface ICasesFilter {
  minPrice?: number;
  maxPrice?: number;
  sortBy?: DefaulSortingTypes;
  query?: string;
}

export interface ICaseBattleFilter {
  minPrice?: number;
  maxPrice?: number;
  sortBy?: SortingCaseBattleTypes;
  minPlayers?: number;
  maxPlayers?: number;
}

export type IFilterFormTemplate = {
  minPrice?: FormControl<number | null>;
  maxPrice?: FormControl<number | null>;
  query?: FormControl<string | null>;
  sortBy?: FormControl<MarketSortingTypes>;
  type?: FormArray<FormControl>;
  phase?: FormArray<FormControl>;
  exterior?: FormArray<FormControl>;
  category?: FormArray<FormControl>;
} & {
  [key: string]: FormArray | FormControl;
};
export interface IFilterFormFields {
  minPrice: number | null;
  maxPrice: number | null;
  query: string;
  sortBy: MarketSortingTypes;
  minOverprice?: number | null;
  maxOverprice?: number | null;
  excludeNonSale?: boolean;
  [key: string]: any;
}

export interface IFilterFormConfig {
  translateRoot: string;
  templates: IFilterPanelItem[][][];
  fields: IFilterFormFields[];
  filters: {
    type?: IFilterFormConfigItem[];
    category?: IFilterFormConfigItem[];
    [key: string]: IFilterFormConfigItem[] | undefined;
  };
  sliders: {
    price: number[][];
    [key: string]: number[][] | undefined;
  };
}

export interface IFilterFormConfigItem {
  queryKey?: string;
  queryField?: string;
  className?: string;
  translateKey: string;
  subTree?: IFilterFormConfigItem[];
}

export interface IFilterFormContent<T> {
  type?: string[];
  phase?: string[];
  category?: string[];
  exterior?: string[];
  minPrice: number | null;
  maxPrice: number | null;
  minOverprice?: number | null;
  maxOverprice?: number | null;
  ignoreCache?: boolean;
  query: string;
  sortBy: T;
  page?: Pagination;
  [key: string]: any;
}

export interface IFilterPanelItem {
  key: string;
  view?: string; //'input-range' | 'input-slider'; //сделать бы енам
}

export interface IFilterMobilePayload {
  template: IFilterPanelItem[][];
  form: FormGroup<IFilterFormTemplate>;
}

export interface IFilterSubPanelData {
  categoryTitle: string;
  itemTitle: string;
  tree: IFilterFormConfigItem[] | undefined;
}

export interface ISteamInventoryCachedAt {
  cachedAt: string;
  timeToShow: string;
  localeSuff: string;
}
