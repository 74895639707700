<div
  class="cards-list"
  *ngIf="caseRevision"
>
  <div
    class="cards-list__header header"
    *ngIf="caseRevision.revision.items.length > 0 && showTitle"
  >
    <div class="header__title header__title-skin">
      {{ 'LOCAL.CASES.MODAL.REVISION.SKIN' | translate }}
    </div>
    <div class="header__title header__title-price">
      {{ 'LOCAL.CASES.MODAL.REVISION.PRICE' | translate }}
    </div>
    <div
      class="header__title header__title-range"
      *ngIf="showRange"
    >
      {{ 'LOCAL.CASES.MODAL.REVISION.RANGE' | translate }}
    </div>
    <div class="header__title header__title-odds">
      {{ 'LOCAL.CASES.MODAL.REVISION.CHANCE' | translate }}
    </div>
    <div class="header__title header__title-roll">
      {{ 'LOCAL.CASES.MODAL.REVISION.ROLL' | translate }}
    </div>
  </div>
  <div class="cards-list__list">
    <app-ui-skin-card-new
      *ngFor="let item of caseRevision.revision.items; let index = index"
      [weaponName]="
        item.inventoryItem.baseItem.shortName ? item.inventoryItem.baseItem.shortName : (item.inventoryItem.baseItem.type | titlecase)
      "
      [range]="showRange ? { min: item.rangeFrom, max: item.rangeTo } : undefined"
      [selected]="!!selected && item.id === selected.id"
      [skinWear]="item.inventoryItem.baseItem.exterior"
      [avatarSrc]="item.inventoryItem.baseItem.icon"
      [skinName]="item.inventoryItem.baseItem.skin"
      [price]="item.inventoryItem.price"
      [chance]="item.count"
      [index]="index"
      [roll]="selected.roll"
    ></app-ui-skin-card-new>
  </div>
</div>
