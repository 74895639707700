import { IDropItemDto, IMotivatorStatistic } from '@dev-fast/types';

// export type CurrentCaseMotivatorType = Required<Pick<IMotivatorStatistic, 'bestLast' | 'last'>>;
export type CurrentCaseMotivatorType = Required<Pick<IMotivatorStatistic, 'bestLast' | 'last'>>;
export interface MotivatorStateModel {
  currentCaseMotivator: CurrentCaseMotivatorType;
  lastDropsList: IDropItemDto[];
  bestDropsList: IDropItemDto[];
  isPlayback: boolean;
  caseId: number | null;
}
export const MOTIVATORS_INITIAL_STATE: MotivatorStateModel = {
  currentCaseMotivator: { bestLast: [], last: [] },
  lastDropsList: [],
  bestDropsList: [],
  isPlayback: true,
  caseId: null,
};
