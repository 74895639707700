import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, NgModule, Output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-ui-ghost',
  templateUrl: './ghost.component.html',
  styleUrls: ['./ghost.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GhostComponent {
  // можно вместо логин\нелогина передавать сразу локаль и тогда повысится гибкость
  @Input() isAuth = true;
  @Input() isCompact = false;
  @Input() actionLocale: string | null = null;
  @Input() headerLocale: string | null = null;
  @Input() underLocale: string | null = null;

  @Output() actionEmitted: EventEmitter<void> = new EventEmitter<void>();
}

@NgModule({
  imports: [CommonModule, MatIconModule, TranslateModule],
  declarations: [GhostComponent],
  exports: [GhostComponent],
})
export class GhostComponentModule {}
