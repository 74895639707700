<ng-container *ngIf="item">
  <div class="widget-container-best-card">
    <div class="widget-content-best-card">
      <h2 class="widget-content-best-card__header">
        {{ 'USER_PANEL.' + stringTransformator(item.top.title) | translate }}
      </h2>
      <div class="widget-content-best-card__game">{{ game }}</div>
    </div>
    <div
      class="statistics-widget"
      [class.additional]="item.top.additional"
    >
      <div
        class="statistics-widget-value"
        *ngIf="item.top.isCurrency"
      >
        {{ item.top.value | appCurrency }}
      </div>
      <div
        class="statistics-widget-value"
        *ngIf="!item.top.isCurrency"
      >
        {{ item.top.value }}
      </div>
      <ng-container *ngIf="item.top.additional && isDateCehck(item.top.additional.value)">
        <div class="statistics-widget-description">
          <div class="descriprion-item">
            <div class="descriprion-item-title">
              {{ 'USER_PANEL.' + stringTransformator(item.top.additional.title) | translate }}
            </div>
            <div class="descriprion-item-value">
              <ng-container *ngIf="item.top.additional.title === 'Max profit bet'">
                {{ item.top.additional.value | appCurrency }}
              </ng-container>
              <ng-container *ngIf="item.top.additional.title === 'Max bet date' || 'Max win date'">
                {{ item.top.additional.value | date: 'dd.MM.yyyy' }}
              </ng-container>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</ng-container>
