import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  NgModule,
  OnDestroy,
  Output,
} from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { debounceTime, Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-ui-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PaginationNewComponent),
      multi: true,
    },
  ],
})
export class PaginationNewComponent implements OnDestroy, ControlValueAccessor {
  @Input() public view: 'compact' | 'compact-plus' | 'full' = 'compact';
  @Input() public buttonClass = 'btn-dark';
  @Input() public minPage = 1;
  @Input() public maxPage = 1;
  @Input() public set value(value: number) {
    this.page.setValue(value, { emitEvent: false });
  }
  @Output() public onpageChange: EventEmitter<number> = new EventEmitter<number>();
  public page: FormControl = new FormControl(1, { validators: [Validators.max(2)] });
  public get pageDigitCount(): number {
    return this.isValid(this.maxPage) ? `${this.maxPage}`.length : 2;
  }

  public pagesArray = [2, 3, 4, 5];

  private destroy$: Subject<void>;
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private onTouched = (): void => {};
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private onChange: (value: number) => void = () => {};
  constructor(private readonly cdr: ChangeDetectorRef) {
    this.destroy$ = new Subject();
    this.page = new FormControl(1);
    this.page.valueChanges
      .pipe(
        // distinctUntilChanged(),
        debounceTime(300),
        takeUntil(this.destroy$)
      )
      .subscribe((value: string | number) => {
        const normValue = +value;
        const validValue = this.validate(normValue);
        if (normValue !== validValue) {
          this.page.patchValue(validValue);
        }
        this.onpageChange.emit(normValue);
        this.onChange(normValue);
        this.onTouched();
      });
  }
  public registerOnChange(fn: (value: number) => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }
  public writeValue(value: number): void {
    const isValidNumber = this.isValid(value);
    if (!isValidNumber) return;
    this.page.patchValue(value, { emitEvent: false });
    // this.onChange(value);
    this.onTouched();
    this.cdr.detectChanges();
  }
  public generatePagesArray(page: number): number[] {
    if (page <= 4) return [2, 3, 4, 5];
    if (page <= this.maxPage && page > this.maxPage - 4)
      return [this.maxPage - 5, this.maxPage - 4, this.maxPage - 3, this.maxPage - 2, this.maxPage - 1];
    return [page - 2, page - 1, page, page + 1, page + 2];
  }
  private updateValue(newValue: number): void {
    this.page.patchValue(this.validate(newValue));
    this.pagesArray = this.generatePagesArray(newValue);
  }
  private validate(newValue: number): number {
    if (newValue < this.minPage) {
      return this.minPage;
    } else if (newValue > this.maxPage) {
      return this.maxPage;
    } else {
      return Math.ceil(newValue);
    }
  }
  private isValid(value: any): boolean {
    return typeof value === 'number' && Number.isInteger(value) && value > 0;
  }
  public setNextPage(): void {
    this.updateValue(Number(this.page.value) + 1);
  }
  public setPrevPage(): void {
    this.updateValue(Number(this.page.value) - 1);
  }
  public setPage(page: number): void {
    this.updateValue(page);
  }
  public onAmountInput(event: KeyboardEvent): boolean {
    const reg = new RegExp(/(\d)/);
    return reg.test(String.fromCharCode(event.which));
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}

@NgModule({
  imports: [CommonModule, ReactiveFormsModule, TranslateModule, MatIconModule],
  declarations: [PaginationNewComponent],
  exports: [PaginationNewComponent],
})
export class PaginationNewModule {}
