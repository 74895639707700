// import { Breakpoints } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { ActivePanel, BREAKPOINTS, NewMenu, NewPanel } from '@dev-fast/types';
import { Select } from '@ngxs/store';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { WINDOW } from '@sentry/angular-ivy';
import { Observable } from 'rxjs';

import { ChangeActivePanel, LayoutState, OpenPanel, SetContentBreakpoint, SetLayoutType } from '@app/core/state/layout';

// export const LAYOUT_SHORT_TYPES_MAP = {
//   '(max-width: 499px)': Breakpoints.XSmall,
//   '(max-width: 750px)': Breakpoints.Small,
//   '(max-width: 840px)': Breakpoints.Tablet,
//   '(max-width: 980px)': Breakpoints.Handset,
//   '(min-width: 981px)': Breakpoints.Web,
// };

@Injectable({
  providedIn: 'root',
})
export class LayoutTypeService {
  @Select(LayoutState.activeMenu)
  activeMenu$!: Observable<NewMenu[]>;

  @Dispatch() setContentBreakpoint = (bp: string): SetContentBreakpoint => new SetContentBreakpoint(bp);
  @Dispatch() setNativeBreakpoint = (bp: string): SetLayoutType => new SetLayoutType(bp);
  @Dispatch() changeActivePanel = (panel: ActivePanel | null): ChangeActivePanel => new ChangeActivePanel(panel);
  @Dispatch() openRightPanel = (panel: NewPanel): OpenPanel => new OpenPanel(panel);

  setBreakpoints(clientWidth: number): void {
    let contentBreakpoint = 'xs';
    let nativeBreakpoint = 'xs';
    Object.values(BREAKPOINTS).forEach((bp, index) => {
      if (clientWidth > bp) {
        contentBreakpoint = Object.keys(BREAKPOINTS)[index];
      }
      if (WINDOW.innerWidth > bp) {
        nativeBreakpoint = Object.keys(BREAKPOINTS)[index];
      }
    });
    this.setContentBreakpoint(contentBreakpoint);
    this.setNativeBreakpoint(nativeBreakpoint);
  }
}
