import { ChangeDetectionStrategy, Component, EventEmitter, Inject, OnInit, Output, ViewChild } from '@angular/core';
import { IInputMessage, IMessage, IUserDetailed } from '@dev-fast/types';
import { NgScrollbar } from 'ngx-scrollbar';
import { Observable, tap } from 'rxjs';

import { CHAT_ENGINE, ChatEngine } from '@app/widgets/chat';

@Component({
  selector: 'app-chat-v2',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatV2Component implements OnInit {
  @ViewChild(NgScrollbar) scrollbarRef: NgScrollbar | undefined;

  @Output() authAttempt: EventEmitter<void>;

  messages$: Observable<IMessage[]>;
  user$: Observable<IUserDetailed | null>;

  constructor(@Inject(CHAT_ENGINE) private chatEngine: ChatEngine) {
    this.messages$ = this.chatEngine.messages$.pipe(
      tap(() => {
        this.scrollToBottom(150);
      }),
    );
    this.user$ = this.chatEngine.user$;
    this.authAttempt = new EventEmitter<void>();
  }
  ngOnInit(): void {
    this.chatEngine.checkCanChat();
    this.chatEngine.getMessagesList();
  }
  trackByFn(index: number, item: IMessage): string {
    return item.id;
  }
  scrollToBottom(duration = 1): void {
    setTimeout(() => this.scroll(duration), 0);
  }
  scroll(duration: number): void {
    if (this.scrollbarRef) {
      this.scrollbarRef.scrollTo({ bottom: 0, duration });
    }
  }
  sendMsg(msg: IInputMessage): void {
    this.chatEngine.addMessage(msg);
    this.scrollToBottom();
  }

  login(): void {
    this.authAttempt.emit();
  }

  isMyMessage(message: IMessage, user: IUserDetailed | null): boolean {
    return user !== null && user !== undefined && message && message.user?.id === user.id;
  }
}
