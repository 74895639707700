import { Injectable } from '@angular/core';
import { INotification } from '@dev-fast/types';
import { Socket as WrappedSocket } from 'ngx-socket-io';
import { Observable } from 'rxjs';

import { EnvironmentService } from '@app/core/environment-service';

// FIXME сокеты
// import { Socket } from 'ngx-socket-io';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  private host: string;
  private endpoint = 'api/notifications';

  constructor(
    private readonly api: ApiService,
    private readonly ws: WrappedSocket,
    private readonly environmentService: EnvironmentService // private ws: Socket
  ) {
    this.host = this.environmentService.environments.GATEWAY_URL;
  }
  public getNotifications(): Observable<INotification[]> {
    return this.api.get<INotification[]>(`${this.host}/${this.endpoint}`, {
      withCredentials: true,
    });
  }
  public watchNotification(id: number): Observable<void> {
    return this.api.patch<void>(`${this.host}/${this.endpoint}/${id}/watch`, {}, { withCredentials: true });
  }
  public deleteNotification(id: number): Observable<void> {
    return this.api.delete<void>(`${this.host}/${this.endpoint}/${id}`, {
      withCredentials: true,
    });
  }
  public watchNotifications(notifications: INotification[]): Observable<void> {
    return this.api.patch<void>(
      `${this.host}/${this.endpoint}/watch`,
      { ids: notifications.map((notification) => notification.id) },
      { withCredentials: true }
    );
  }
  // public listenExceptions() {
  //   // return this.ws.fromEvent<{ type: string; message: string; params?: object }>('exception');
  //   return of({ params: {}, message: '' });
  // }
  // // TODO типизировать
  // public listenCreatedMessages() {
  //   // return this.ws.fromEvent<any>('notifications.message.created');
  //   return of();
  // }
}
