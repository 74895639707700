import {
  CATEGORY_CHECKBOX_LIST,
  EXTERIOR_CHECKBOX_LIST,
  INVENTORY_SETTINGS_CHECKBOX,
  OVER_PRISE_RANGE,
  PHASE_CHECKBOX_LIST,
  SKINS_PRICE_NUMBER_OPTIONS,
  WEAPONS_CHECKBOX_LIST,
} from './dynamic-forms.const';
import { IDFormData } from './dynamic-forms.interface';

export interface DynamicFormsStateModel {
  [key: string]: IDFormData;
}

const inventoryForm: IDFormData = {
  localeRoot: 'P2P_SETTINGS.FILTERS.',
  disableCountParams: ['weapon'],
  controls: [
    [
      WEAPONS_CHECKBOX_LIST,
      { ...CATEGORY_CHECKBOX_LIST, group: 'category-exterior' },
      { ...EXTERIOR_CHECKBOX_LIST, group: 'category-exterior' },
      { ...SKINS_PRICE_NUMBER_OPTIONS, group: 'price' },
    ],
  ],
};

const marketForm: IDFormData = {
  localeRoot: 'P2P_SETTINGS.FILTERS.',
  disableCountParams: ['weapon'],
  controls: [
    [
      // BUY TAB
      WEAPONS_CHECKBOX_LIST,
      { ...CATEGORY_CHECKBOX_LIST, group: 'category-exterior' },
      { ...EXTERIOR_CHECKBOX_LIST, group: 'category-exterior' },
      PHASE_CHECKBOX_LIST,
      { ...SKINS_PRICE_NUMBER_OPTIONS, group: 'price' },
      { ...OVER_PRISE_RANGE, group: 'price' },
    ],
    [
      // SELL TAB
      WEAPONS_CHECKBOX_LIST,
      { ...CATEGORY_CHECKBOX_LIST, group: 'category-exterior' },
      { ...EXTERIOR_CHECKBOX_LIST, group: 'category-exterior' },
      PHASE_CHECKBOX_LIST,
      { ...SKINS_PRICE_NUMBER_OPTIONS, group: 'price' },
      { ...INVENTORY_SETTINGS_CHECKBOX, group: 'price' },
    ],
  ],
};

export const DFORM_INITIAL_STATE: DynamicFormsStateModel = {
  inventoryForm,
  marketForm,
};
