import { inject, Injectable } from '@angular/core';
import { AgreementName, BaseType, IUserDetailed } from '@dev-fast/types';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';

import { LocalStorageService } from '@app/core/local-storage-service';
import { UserState } from '@app/core/state/user-store';

import { SetAgreement } from './agreements.actions';
import { AGREEMENTS_INITIAL_STATE, AgreementsStateModel } from './agreements-state.model';

@State<AgreementsStateModel>({
  name: 'agreements',
  defaults: AGREEMENTS_INITIAL_STATE,
})
@Injectable()
export class AgreementsState {
  readonly #localStorageService = inject(LocalStorageService);
  readonly #store = inject(Store);

  @Selector([UserState.user])
  static agreements({ items }: AgreementsStateModel, user: IUserDetailed | null): Partial<Record<AgreementName, boolean>> {
    const item = items.find((el) => el.name === user?.id);
    return item ? item.value || {} : {};
  }

  ngxsOnInit({ patchState }: StateContext<AgreementsStateModel>): void {
    const agreements: BaseType<number, Partial<Record<AgreementName, boolean>>>[] | undefined =
      this.#localStorageService.get('agreementsNew');
    patchState({
      items: agreements || [],
    });
  }

  @Action(SetAgreement)
  setAgreement({ patchState, getState }: StateContext<AgreementsStateModel>, { name, isAgree }: SetAgreement): void {
    const { items } = getState();
    const user = this.#store.selectSnapshot(UserState.user);
    if (!user) {
      return;
    }
    const agreementByUserIdIndex = items.findIndex((el) => el.name === user.id);
    const updatedAgreements = [...items];

    if (agreementByUserIdIndex === -1) {
      if (updatedAgreements.length > 9) {
        updatedAgreements.shift();
      }
      updatedAgreements.push({ name: user.id, value: { [name]: isAgree } });
    } else {
      const oldAgreement = updatedAgreements[agreementByUserIdIndex];
      updatedAgreements[agreementByUserIdIndex] = {
        name: oldAgreement.name,
        value: { ...oldAgreement.value, [name]: isAgree },
      };
    }

    patchState({ items: updatedAgreements });
    this.#localStorageService.set('agreementsNew', updatedAgreements);
  }
}
