<ng-container *ngIf="sellerBanEndAt$ | async as sellerBanEndAt; else sellerLayout">
  <div class="placeholder-container flex fx-column fx-justify-center fx-align-center w-100">
    <mat-icon svgIcon="handcuffs--design"></mat-icon>
    <span>{{ 'P2P_WIDGETS.DEPOSITING.YOU_ARE_BANNED' | translate }}</span>
    <p>{{ 'P2P_WIDGETS.DEPOSITING.YOU_CAN_SEND_MAIL_TO_SUPPORT' | translate }}</p>
    <app-ui-countdown
      [until]="sellerBanEndAt"
      [showHours]="true"
      [minutes]="0.92"
      [onlyText]="true"
    >
    </app-ui-countdown>
    <a (click)="openTechSupportDialog()"> {{ 'P2P_WIDGETS.DEPOSITING.CONTACT_TECH_SUPPORT' | translate }}</a>
  </div>
</ng-container>

<ng-template #sellerLayout>
  <ng-container *ngIf="permissions$ | async as permissions">
    <ng-container *ngIf="permissions.success; else setupSteamTemplate">
      <app-p2p-sell-adviser
        (goToSteamInventoryMarketTabMobile)="goToTabAndClosePanel()"
        (reqAutoSelect)="onSelectItems($event)"
      />
      <ng-container *ngIf="{ depositing: depositing$ | async, selected: selected$ | async } as p2pData">
        <ng-scrollbar
          *ngIf="p2pData.depositing"
          class="container-viewport hide-scroll"
        >
          <div class="container-content tw-flex tw-flex-col">
            <app-p2p-selling-item
              *ngFor="let entity of p2pData.depositing; trackBy: trackingFunction"
              class="tw-mb-4"
              [depositItem]="entity"
              @expansionAnimation
            />
          </div>
        </ng-scrollbar>
        <app-p2p-sell-setup-panel
          *ngIf="p2pData.selected && p2pData.selected.length"
          [lotDepositSettings]="lotDepositSettings"
          [overprice]="overprice$ | async"
          [userPermissions]="permissions"
          [selectedItems]="p2pData.selected"
          [selectedSum]="selectedSum$ | async"
          [selectedSumWithOverprice]="selectedSumWithOverprice$ | async"
          (sellSelected)="onSell()"
          (setupSteam)="onSetupSteam()"
          (panelClosed)="onResetSelected()"
          (setOverprice)="updateOverprice($event)"
          (itemRemoved)="removeSelectedItem($event)"
          (settingsChanged)="updateTradeSettings($event)"
          (reqAutoSelectItems)="onSelectItems($event, p2pData.selected)"
        ></app-p2p-sell-setup-panel>
      </ng-container>
    </ng-container>
    <ng-template #setupSteamTemplate>
      <app-ui-ghost
        class="w-100 pt-10"
        [isCompact]="true"
        [underLocale]="formatErrBtnText(permissions)"
        [actionLocale]="'P2P_SETTINGS.SETUP_TITLE'"
        (actionEmitted)="handleErrorAction(permissions)"
        @expansionAnimation
      ></app-ui-ghost>
    </ng-template>
  </ng-container>
</ng-template>
