import { HttpErrorResponse } from '@angular/common/http';
import { inject, Injectable, Optional } from '@angular/core';
import { P2pApiService } from '@dev-fast/backend-services';
import { ISteamStore, ISteamStoreInventory, ModalNames, SteamStatuses } from '@dev-fast/types';
import { Action, NgxsOnInit, Selector, State, StateContext, Store } from '@ngxs/store';
import { delay, EMPTY, iif, Observable, of, throwError } from 'rxjs';
import { catchError, startWith, switchMap, tap } from 'rxjs/operators';

import { FrameMessageTypes, IFrameMessageService } from '@app/core/iframe';
import { NotificationsService } from '@app/core/notification-service';
import { CloseModal, ModalsState, OpenModal } from '@app/core/state/modals';

import { P2pBuyingState } from './buying';
import { DepositSelected, P2pDepositState } from './deposit';
import { GetSteamInventory, GetSteamStatus } from './p2p.actions';
import { P2P_INITIAL_STATE, P2pStateModel } from './p2p-state.model';

@State<P2pStateModel>({
  name: 'p2p',
  defaults: P2P_INITIAL_STATE,
  children: [P2pBuyingState, P2pDepositState],
})
@Injectable()
export class P2pCommonState implements NgxsOnInit {
  readonly #store = inject(Store);
  readonly #notificationsService = inject(NotificationsService);
  readonly #p2pApiService = inject(P2pApiService);

  constructor(@Optional() private readonly frameMessageService: IFrameMessageService) {}

  @Selector()
  static steamInventory({ items }: P2pStateModel): ISteamStoreInventory[] | null | undefined {
    return items;
  }
  @Selector()
  static sellerBanEndAt({ sellerBanEndAt }: P2pStateModel): number | null {
    return sellerBanEndAt && sellerBanEndAt > 0 ? sellerBanEndAt : null;
  }

  ngxsOnInit({ getState, dispatch }: StateContext<P2pStateModel>): void {
    if (this.frameMessageService) {
      this.frameMessageService.on(FrameMessageTypes.MESSAGE_FROM_BB, 'openSteamCredentialsModal', () =>
        dispatch(new OpenModal(ModalNames.P2P_SETUP, ['steam', 'apiKey'])),
      );
      this.frameMessageService.on(FrameMessageTypes.MESSAGE_FROM_BB, 'checkSteam', () => dispatch(new GetSteamStatus()));
      this.frameMessageService.on(FrameMessageTypes.MESSAGE_FROM_BB, 'p2p.deposit.requestInventory', (payload: { ignoreCache: boolean }) =>
        dispatch(new GetSteamInventory(payload.ignoreCache)),
      );
    }
  }
  @Action(CloseModal)
  closeModal({ dispatch }: StateContext<P2pStateModel>, { name }: CloseModal): void {
    if (name === ModalNames.P2P_SETUP && this.frameMessageService) {
      this.frameMessageService.sendMessage({
        type: FrameMessageTypes.MESSAGE_TO_BB,
        eventName: 'closeSteamCredentialsModal',
        payload: { message: 'closeSteamCredentialsModal' },
      });
    }
  }
  @Action(GetSteamStatus)
  getSteamStatus({ dispatch, patchState }: StateContext<P2pStateModel>): Observable<{ status: string }> {
    return this.#p2pApiService.getSteamStatus().pipe(
      tap((res) => {
        if (this.frameMessageService) {
          this.frameMessageService.sendMessage({
            type: FrameMessageTypes.MESSAGE_TO_BB,
            eventName: 'checkSteamRespons',
            payload: { ...res },
          });
        }

        if (
          (res.status === SteamStatuses.SURGE || res.status === SteamStatuses.OFFLINE) &&
          !this.#store.selectSnapshot(ModalsState.activeModals).includes(ModalNames.STEAM_ERROR)
        ) {
          dispatch(new OpenModal(ModalNames.STEAM_ERROR));
        }
        patchState({ steamStatus: <SteamStatuses>res.status });
      }),
    );
  }
  // @Action(RouterNavigation)
  // public routerNavigation(
  //   { dispatch }: StateContext<P2pStateModel>,
  //   { routerState }: RouterNavigation<RouterStateParams>
  // ) {
  //   // if (routerState.url.includes("refill/skins")) {
  //   //   dispatch(new GetSteamInventory());
  //   // }
  // }
  @Action(DepositSelected)
  depositSelected({ dispatch }: StateContext<P2pStateModel>): Observable<void> {
    return of(EMPTY).pipe(
      delay(1000),
      switchMap(() => dispatch(new GetSteamInventory())),
    );
  }
  @Action(GetSteamInventory, { cancelUncompleted: true })
  getSteamInventory({ patchState }: StateContext<P2pStateModel>, { ignoreCache }: GetSteamInventory): Observable<ISteamStore> {
    return this.#p2pApiService.getInventory(ignoreCache).pipe(
      switchMap((res) => iif(() => !!res?.sellerBanEndAt, throwError(() => res).pipe(startWith(res)), of(res))),
      // retryWhen((err) =>
      //   err.pipe(
      //     pluck("sellerBanEndAt"),
      //     delayWhen((val: string) => timer(Date.parse(val) - Date.now() + 5000))
      //   )
      // ),
      tap((store: ISteamStore) => {
        if (this.frameMessageService) {
          this.frameMessageService.sendMessage({
            type: FrameMessageTypes.MESSAGE_TO_BB,
            eventName: 'GetSteamInventory',
            payload: JSON.parse(JSON.stringify({ ...store })),
          });
        }

        const { sellerBanEndAt, items } = store;
        const diff = (sellerBanEndAt ? Date.parse(sellerBanEndAt) : Date.now()) - Date.now();
        patchState({ items, sellerBanEndAt: diff });
      }),
      catchError((e) => {
        if (this.frameMessageService) {
          this.frameMessageService.sendMessage({
            type: FrameMessageTypes.MESSAGE_TO_BB,
            eventName: 'GetSteamInventory',
            payload: JSON.parse(JSON.stringify({ ...e, isError: true })),
          });
        }
        this.#onError(e);
        return throwError(() => e).pipe(startWith(e));
      }),
    );
  }

  #onError(e: HttpErrorResponse): void {
    this.#notificationsService.addErrorNotification(e.error.message || 'BALANCE_REFILL.TRADE_BOT.ERROR_UNAVAILABLE', {
      icon: 'warning',
      system: true,
    });
  }
}
