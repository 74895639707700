import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Optional } from '@angular/core';
import { Router } from '@angular/router';
import { ModalNames, SocialItem } from '@dev-fast/types';
import { Navigate } from '@ngxs/router-plugin';
import { Action, NgxsOnInit, Selector, State, StateContext, Store } from '@ngxs/store';
import { StateReset } from 'ngxs-reset-plugin';
import { catchError, Observable } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

import { EnvironmentService } from '@app/core/environment-service';
import { FrameMessageTypes, IFrameMessageService } from '@app/core/iframe';
import { LocalStorageService } from '@app/core/local-storage-service';
import { CloseAllModals, ModalsState, OpenModal } from '@app/core/state/modals';
import { GetNotifications, NotificationsState } from '@app/core/state/notifications';
import { GetUser, Init, RefreshCurrentUser, UserState } from '@app/core/state/user-store';

import { AuthApiService } from '../api/auth.api.service';
import { BindAccount, GetAuthorizationToken, Login, Logout, LogoutSuccess, OpenAuthModal, RemoveAccount } from './auth.actions';
import { AUTH_INITIAL_STATE, AuthStateModel } from './auth-state.model';

@State<AuthStateModel>({
  name: 'auth',
  defaults: AUTH_INITIAL_STATE,
})
@Injectable()
export class AuthState implements NgxsOnInit {
  private window: Window | null;

  @Selector()
  static isAuth({ isAuth }: AuthStateModel): boolean {
    return isAuth;
  }

  @Selector()
  static socials({ socials }: AuthStateModel): SocialItem[] {
    return socials;
  }

  @Selector()
  static unlinkedSocials({ unlinkedSocials }: AuthStateModel): SocialItem[] | null {
    return unlinkedSocials;
  }

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private readonly apiService: AuthApiService,
    private readonly store: Store,
    private readonly storage: LocalStorageService,
    private readonly environmentService: EnvironmentService,
    private readonly router: Router,
    @Optional() private readonly frameMessageService: IFrameMessageService,
    @Inject('login-providers') private readonly socials: SocialItem[],
  ) {
    this.window = this.document.defaultView;
    // const { visibilityChange } = getHiddenKey(this.document);
    // FIXME два таких листенера на проекте уже есть, нужно бы сервис сделать
    // this.document.addEventListener(visibilityChange, () => this.handleVisibilityChange(), false);
  }
  // private documentIsHidden(): boolean {
  //   const { hidden } = getHiddenKey(this.document);
  //   if (this.document[hidden as keyof Document]) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // }
  // private handleVisibilityChange(): void {
  // if (!this.documentIsHidden()) {
  //   const isAuth = this.store.selectSnapshot<boolean>(AuthState.isAuth);
  //   const user = this.storage.get('user');
  //   if (!isAuth && user) {
  //     this.window?.location.reload();
  //   }
  // }
  // }
  ngxsOnInit({ dispatch, patchState }: StateContext<AuthStateModel>): void {
    // this.frameMessageService.on(FrameMessageTypes.MESSAGE_FROM_BB, 'login', (payload: { auth: boolean }) => {
    //   const isAuth = ctx.getState().isAuth;
    //   if (!isAuth && payload.auth) this.store.dispatch(new Login());
    // });

    patchState({
      socials: this.socials,
    });

    if (this.frameMessageService) {
      this.frameMessageService.on(FrameMessageTypes.MESSAGE_FROM_BB, 'socialAuthModalRender', () => dispatch(new OpenAuthModal()), 500);
    }

    if (this.window) {
      this.window.onstorage = (ev: StorageEvent) => {
        if (ev.key && ev.key === 'user' && ev.newValue && ev.oldValue == null) {
          const isUserNorm = JSON.parse(ev.newValue);
          this.store.dispatch(new RefreshCurrentUser(isUserNorm));

          if (this.frameMessageService) {
            this.frameMessageService.sendMessage({
              type: FrameMessageTypes.MESSAGE_TO_BB,
              eventName: 'login',
              notWaitLoadingFrame: true,
              payload: {},
            });
          }
          // if (!this.documentIsHidden()) {
          //   this.window?.location.reload();
          // }
        } else if (ev.key === null && ev.storageArea?.length === 0) {
          dispatch([new StateReset(UserState, AuthState)]);

          if (this.frameMessageService) {
            this.frameMessageService.sendMessage({
              type: FrameMessageTypes.MESSAGE_TO_BB,
              eventName: 'logout',
              notWaitLoadingFrame: true,
              payload: {},
            });
          }
          // if (!this.documentIsHidden()) {
          //   this.window?.location.reload();
          // }
        }
      };
    }
  }

  @Action(RefreshCurrentUser)
  refreshUser({ patchState, getState }: StateContext<AuthStateModel>, { payload }: RefreshCurrentUser): void {
    const { socials, isAuth } = getState();
    if (payload.providers && payload.providers.length > 0) {
      const providers = payload.providers.map((provider) => provider.name);
      patchState({
        unlinkedSocials: socials.filter((social) => !providers.includes(social.name)),
      });
    }
    if (payload.id && !isAuth) {
      patchState({
        isAuth: !isAuth,
      });
    }
  }

  @Action(OpenAuthModal)
  openAuthModal({ dispatch }: StateContext<AuthStateModel>): Observable<void> {
    const promo = this.storage.get('referralCode');
    const isActiveRefModal = this.store.selectSnapshot(ModalsState.activeModals).includes(ModalNames.REFERRAL);
    if (!isActiveRefModal && promo) {
      return dispatch(new OpenModal(ModalNames.REFERRAL, promo));
    } else {
      return dispatch(new OpenModal(ModalNames.AUTH));
    }
  }

  @Action(GetAuthorizationToken)
  getToken({ dispatch }: StateContext<AuthStateModel>, { payload }: GetAuthorizationToken): Observable<void> {
    return this.apiService.getAuthorizationTokens(payload).pipe(switchMap((result) => dispatch([new Login(result)])));
  }

  @Action(RemoveAccount)
  removeAccount({ dispatch, patchState }: StateContext<AuthStateModel>, { payload }: RemoveAccount): Observable<void> {
    return this.apiService.removeAccount(payload).pipe(
      tap(() => {
        dispatch(new GetUser());
      }),
      catchError((error) => {
        throw new Error(error);
      }),
    );
  }

  @Action(BindAccount)
  bindAccount({ dispatch }: StateContext<AuthStateModel>): Observable<void> {
    return dispatch(new GetUser());
  }

  // deprecated
  @Action(Login)
  login({ dispatch, patchState }: StateContext<AuthStateModel>, { payload }: Login): void {
    if (payload) {
      this.apiService.socketLogin(payload);
    }
    patchState({
      isAuth: true,
    });
    // dispatch([new Init(), new GetLocales(), new GetCurrencySettings(), new GetAllGamesSettings(), new AffiseEvent()]);
    // AffiseEvent now resets at referrals ny
    if (this.environmentService.environments.FULL_LOGIN_FLOW) {
      // this.window?.location.reload();
      dispatch([
        new Init(),
        // new GetLocales(),
        // new GetCurrencySettings(),
        // new GetAllGamesSettings(),
        new GetNotifications(),
        // new GetLevelsRoadMap(),
      ]);
    }

    if (this.frameMessageService) {
      setTimeout(
        () =>
          this.frameMessageService.sendMessage({
            type: FrameMessageTypes.MESSAGE_TO_BB,
            eventName: 'login',
            notWaitLoadingFrame: true,
            payload: {},
          }),
        300,
      );
    }

    this.storage.set('isAuth', true);
    return;
  }

  @Action(Logout)
  logout({ dispatch, patchState }: StateContext<AuthStateModel>): Observable<void> {
    const currentUrl = this.router.url;
    return this.apiService.logout().pipe(
      tap(() => {
        patchState({
          isAuth: false,
        });

        if (this.frameMessageService) {
          this.frameMessageService.sendMessage({
            type: FrameMessageTypes.MESSAGE_TO_BB,
            eventName: 'logout',
            notWaitLoadingFrame: true,
            payload: {},
          });
        }
      }),
      switchMap(() => {
        this.storage.clearExcept([
          'volume',
          'sound',
          'marketplace-showWarn',
          'theme',
          'isDesktop',
          'preview-deposit-modifier',
          'agreementsNew',
        ]);
        const actions = [new StateReset(UserState, NotificationsState), new CloseAllModals(), new LogoutSuccess()];
        if (currentUrl === '/block') {
          actions.push(new Navigate(['game/classic']));
        }
        // return dispatch([new StateReset(UserState, NotificationsState, ReferralsState)]);
        // RefferalsState now resets at his own ny
        return dispatch(actions);
      }),
    );
  }
}
