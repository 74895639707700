import { ISteamInventoryCachedAt } from '@dev-fast/types';
import { DateTime } from 'luxon';

import { TimeUnit } from './p2p-market-filter.types';

/**
 * Форматирование времени последнего обновления инвентаря стима
 */
export const formatTimeToShow = (asDay: number, asHour: number, cachedAtDateTime: DateTime): string => {
  if (asDay >= 1) {
    return Math.trunc(asDay) + '';
  }
  if (asHour >= 1) {
    return Math.trunc(asHour) + '';
  }
  return cachedAtDateTime.toFormat('HH:mm');
};

/**
 * Форматирование даты последнего обновления инвентаря стима
 */
export const formatTimer = (cachedAt: string): ISteamInventoryCachedAt | void => {
  if (!cachedAt) {
    return;
  }

  const cachedAtDateTime = DateTime.fromISO(cachedAt);
  const now = DateTime.now();
  const diff = now.diff(cachedAtDateTime);
  const asDay = diff.as(TimeUnit.DAY);
  const asHour = diff.as(TimeUnit.HOUR);

  const timeToShow = formatTimeToShow(asDay, asHour, cachedAtDateTime);
  const localeSuff = asDay >= 1 ? 'd. ago' : asHour >= 1 ? 'hr. ago' : '';

  return { cachedAt, timeToShow, localeSuff };
};
