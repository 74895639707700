import moment from 'moment/moment';

export function sleep(ms: number): Promise<unknown> {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
/**
 * для коротких массивов
 */
export const getMaxOfArray = (numArray: number[]): number => {
  return Math.max.apply(null, numArray);
};
/**
 * для коротких массивов
 */
export const getMinOfArray = (numArray: number[]): number => {
  return Math.min.apply(null, numArray);
};
/*
  для поиска максимального числа в массиве. В зависимости от fieldNameReturn возвращает либо массив объектов, либо массив значений
*/
export const minMaxValueOfItemField = (items: any[], fieldName: string, needMin: boolean, fieldNameReturn?: string): any[] => {
  if (items.length) {
    let value: number;
    if (needMin) {
      value = items.reduce((a, b) => (a[fieldName] < b[fieldName] ? a : b))[fieldName];
    } else {
      value = items.reduce((a, b) => (a[fieldName] > b[fieldName] ? a : b))[fieldName];
    }
    let returnedItems = items.filter((item) => item[fieldName] === value);
    if (fieldNameReturn) {
      returnedItems = returnedItems.map((item) => item[fieldNameReturn]);
    }
    return returnedItems;
  }
  return items;
};
export const getKeyByValue = <T extends object, K extends keyof T>(object: T, value: T[K] | undefined): string | undefined => {
  if (!value) {
    return;
  }
  return Object.keys(object).find((key) => (object as T)[key as K] === value);
};
export const getBaseDomain = (host: string, dot = true): string => {
  const regexWithoutPort = /^(.*)\\:\d*$/gm;
  const resultWithoutPort = regexWithoutPort.exec(host);
  const withoutPort = resultWithoutPort ? resultWithoutPort[1] : host;
  const regexBaseDomain = /^.*\.(.*\..*)$/gm;
  const resultBaseDomain = regexBaseDomain.exec(withoutPort);
  return (dot ? '.' : '') + (resultBaseDomain ? resultBaseDomain[1] : withoutPort);
};
export const notNull = (value: any): boolean => value !== null && value !== undefined;

// export const isActualPromo = (promo: PromoCode): boolean => {
//   return (
//     (promo.canActivate === null || promo.canActivate === undefined || promo.canActivate) &&
//     (!promo.activationEndDate || moment(promo.activationEndDate).isAfter(moment()))
//   );
// };

export const getUniqueObjectsFromArr = <T>(origArr: T[], key: string, groupKey?: string): T[] => {
  const uniqArr = <K>(arr: K[], k: string): K[keyof K][] => {
    return Array.from(new Set(arr.map((item) => item[k as keyof typeof item])));
  };
  const uniqByKey = <K>(arr: K[], k: string): K[] => {
    const filteredArr = uniqArr(arr, k);
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return filteredArr.map((value) => arr.find((el) => el[k as keyof typeof el] === value)!);
  };

  if (groupKey) {
    const uniqGroupValues = uniqArr(origArr, groupKey);
    const groupedArr = uniqGroupValues.map((value) => origArr.filter((el) => el[groupKey as keyof typeof el] === value));
    return groupedArr.flatMap((val) => uniqByKey(val, key));
  } else {
    return uniqByKey(origArr, key);
  }
};
export const measureHeight = (): number | null => {
  if (!isClient()) {
    return null;
  }
  return document.documentElement?.clientHeight || window.innerHeight;
};
export const measureWidth = (): number | null => {
  if (!isClient()) {
    return null;
  }
  return document.documentElement?.clientWidth || window.innerWidth;
};

export const isClient = (): boolean => {
  return typeof window !== 'undefined' && typeof document !== 'undefined';
};

export const isMoreThanDay = (
  date: Date | number | string | undefined | null,
  targetDate: Date | number | string | undefined | null,
): boolean => {
  if (!date) {
    return false;
  }
  const now = moment(targetDate || Date.now());
  const diffDaysEndNow = moment(date).diff(now, 'days');
  return diffDaysEndNow >= 1;
};
