import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SkinItemImageDirective } from './skin-item-image.directive';

@NgModule({
  declarations: [SkinItemImageDirective],
  imports: [CommonModule],
  exports: [SkinItemImageDirective],
})
export class SkinItemImageModule {}
