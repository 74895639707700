import { Environments, ENVIRONMENTS_DEFAULT_FAST } from '@app/core/environment-service';
import { getStageBackBoneUrl } from '@app/shared/utils';

// NOTE: Отдельная среда по которой будет собираться проект на отдельный стейдж. По дефолту дублирует дев среду
export const environment: Environments = {
  ...ENVIRONMENTS_DEFAULT_FAST,
  production: false,
  IS_NEW_WALLETS: false,
  hmr: true,
  PROJECT: 'csgofast-new',
  CLIENT_PROJECT: 'renew-fast',
  BUILD_NAME: 'stage-client',
  HOSTNAME: `${location.host}`,
  // ---------- URLS
  WS_GATEWAY_URL: `https://back-new.${location.host.includes('local') ? 'csgofast.com' : location.host.split('.').slice(-2).join('.')}`,
  GATEWAY_URL: `https://back-new.${location.host.includes('local') ? 'csgofast.com' : location.host.split('.').slice(-2).join('.')}`,
  BACKBONE_URL: getStageBackBoneUrl(location.host),
  ESPORTS_API_URL: 'stage',
  PAYMENT_URL: `https://pay.${location.hostname.split('.').slice(-2).join('.')}`,
  PAYMENT_URL_2: `https://pay.${location.hostname.split('.').slice(-2).join('.')}`,
  WITHDRAWAL_URL: `https://back-new.${location.host.includes('local') ? 'csgofast.com' : location.host.split('.').slice(-2).join('.')}`,
  POGGI_URL: 'https://d1phhoo4f618zo.cloudfront.net',
  // ---------- MODULES
  MODULES: {
    ...ENVIRONMENTS_DEFAULT_FAST.MODULES,
    transaction: {
      refill: [],
      withdrawal: [],
    },
    account: {
      main: [],
      balance: [],
      referrals: [],
    },
    soundToggler: true,
    inventory: {},
    games: [
      'classic',
      'case-battle',
      'fast',
      'double',
      'poggi',
      'baccarat',
      'hilo',
      'x50',
      'crash',
      'slot',
      'tower',
      'fortune-wheel',
      'cases',
      // 'match-bets',
      // 'craft',
    ],
  },
};
