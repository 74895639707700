import { CasesGamePhases, CasesGameType, ICaseOpenParams, ICaseOpenPrizeDataDto, IDropItemDto } from '@dev-fast/types';

// Добавление/Удаление кейса из списка любимых
export class ChangeFavorite {
  static readonly type = '[Cases game] change favorite';
  constructor(public isFavorite?: boolean | undefined) {}
}
export class ClearGameState {
  static readonly type = '[Cases game] clear game state';
  constructor(public isFavorite?: boolean | undefined) {}
}
export class GetCase {
  static readonly type = '[Cases game] Get case';
  constructor(public id: number) {}
}
export class GetSpinItemsIds {
  static readonly type = '[Cases game] Get spin items ids';
  constructor(
    public lines: number,
    public caseId: number,
  ) {}
}
export class SpinTracksConstructor {
  static readonly type = '[Cases game] Created spin items track';
  constructor(public spinItemsIds: number[][]) {}
}
export class SellChangedItems {
  static readonly type = '[Cases game] Sell changed items';
  constructor(
    public ids: number[],
    public gameType?: CasesGameType,
  ) {}
}
export class OpenCaseItems {
  static readonly type = '[Cases game] Open case items';
  constructor(public params: ICaseOpenParams) {}
}
export class SetPrizeItems {
  static readonly type = '[Cases game] Set prize items';
  constructor(public payload: ICaseOpenPrizeDataDto) {}
}
export class SetGameType {
  static readonly type = '[Cases game] Set game type';
  constructor(public gameType: CasesGameType) {}
}
export class StartReplay {
  static readonly type = '[Cases game] Start replay';
  constructor(public replayPrizeItems?: IDropItemDto[]) {}
}
export class GetReplayItems {
  static readonly type = '[Cases game] get replay items';
  constructor(public openUUID: string) {}
}
export class SetSpinState {
  static readonly type = '[Cases game] Set spin state';
  constructor(public spinState: CasesGamePhases) {}
}
export class SetSpinTracksAmount {
  static readonly type = '[Cases game] Set spin tracks amount';
  constructor(
    public spinTracksAmount: number,
    public caseId?: number,
  ) {}
}
export class SetPrizeData {
  static readonly type = '[Cases game] Set Prize Data';
  constructor(public payload: ICaseOpenPrizeDataDto | null) {}
}
export class SetToDropPosition {
  static readonly type = '[Cases game] Drop position';
}
export class UpdateBonusCasesInfo {
  static readonly type = '[Cases game] Update Bonus Cases Info';
  constructor(public caseId: number) {}
}
export class UpdateCase {
  static readonly type = '[Cases game] Update Case';
  constructor(public caseId: number) {}
}
export class HandleOpenBonusCase {
  static readonly type = '[Cases game] Handle Open Bonus Case';
  constructor(public isFinish: boolean) {}
}
