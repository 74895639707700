import { CommonModule, CurrencyPipe } from '@angular/common';
import { NgModule } from '@angular/core';

import { AppCurrencyPipe } from './currency.pipe';
/** @deprecated  заменить на app-currency компонент. Лежит в CurrencyComponent*/
@NgModule({
  imports: [CommonModule],
  declarations: [AppCurrencyPipe],
  exports: [AppCurrencyPipe],
  providers: [CurrencyPipe, AppCurrencyPipe],
})
export class AppCurrencyModule {}
