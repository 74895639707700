import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { NgScrollbarModule } from 'ngx-scrollbar';

import { LangRouterModule } from '@app/shared/directives';

import { P2pPurchaseComponent } from './p2p-purchase.component';
import { P2pPurchaseService } from './p2p-purchase.service';
import { P2pPurchaseItemModule } from './p2p-purchase-item/p2p-purchase-item.module';

@NgModule({
  declarations: [P2pPurchaseComponent],
  imports: [CommonModule, TranslateModule, NgScrollbarModule, P2pPurchaseItemModule, MatIconModule, RouterModule, LangRouterModule],
  exports: [P2pPurchaseComponent],
  providers: [P2pPurchaseService],
})
export class P2pPurchaseModule {}
