export interface FrameMessage<T = Record<string, any>> {
  type: FrameMessageTypes;
  eventName: string;
  notWaitLoadingFrame?: boolean;
  payload: T;
}

export enum FrameMessageTypes {
  ROUTING_FROM_BB = 'routingFromBB',
  ROUTING_TO_BB = 'routingToBB',
  MESSAGE_FROM_BB = 'fromBB',
  MESSAGE_TO_BB = 'toBB',
  MESSAGE_FROM_BB_API = 'apiResponseFromBB',
  MESSAGE_TO_BB_API = 'apiRequestToBB',
  MESSAGE_FROM_BB_SOCKET = 'socketResponseFromBB',
  MESSAGE_TO_BB_SOCKET = 'socketRequestToBB',
}
