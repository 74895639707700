<div
  class="balance-panel"
  [class.balance-panel__mobile-view]="view === 'mobile'"
>
  <div class="balance-panel__currency">{{ coinsAmount | appCurrency }}</div>
  <!-- <div class="balance-panel__refill-btn" *ngIf="view==='desktop'">REFILL</div>
  <div class="balance-panel__refill-btn" *ngIf="view==='mobile'">+</div> -->

  <a
    class="balance-panel__refill-btn"
    [class.refill_mobile]="view === 'mobile'"
    langRouterLink
    routerLink="refill"
  >
    {{ (view === 'desktop' ? 'BALANCE_REFILL.LINK.REFILL' : '+') | translate | titlecase }}
  </a>

  <a
    langRouterLink
    routerLink="withdrawal/method"
    class="balance-panel__withdraw-btn"
    [class.withdraw-disabled]="coinsAmount === 0"
    *ngIf="view === 'desktop'"
  >
    -
  </a>
</div>
