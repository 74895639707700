import { ICaseItemDetailed } from '@dev-fast/types';

import { CASES_GAME_INITIAL_STATE, ICasesGameStateModel } from '../game-state';
import { CASES_SETTINGS_INITIAL_STATE, ICasesSettingsStateModel } from '../settings-state';

export interface IMarketCasesStateModel {
  caseItemsDetailed: Record<string, ICaseItemDetailed>;
  selectedItemName: string | undefined;
  // саб стейт
  casesGame: ICasesGameStateModel;
  casesSettings: ICasesSettingsStateModel;
}

export const MARKET_CASES_INITIAL_STATE: IMarketCasesStateModel = {
  caseItemsDetailed: {},
  selectedItemName: undefined,
  casesGame: CASES_GAME_INITIAL_STATE,
  casesSettings: CASES_SETTINGS_INITIAL_STATE,
};
