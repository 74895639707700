<div
  class="trade-skin"
  [class.background-active]="isSelect || isActive"
  (click)="onClick()"
>
  <div
    *ngIf="isSelect || isActive"
    class="trade-skin--lock flex fx-all-center absolute"
    [class.selected]="isSelect"
    [class.active]="isActive"
  >
    <mat-icon
      *ngIf="isSelect"
      svgIcon="checkmark-outline"
    />
    <mat-icon
      *ngIf="isActive"
      svgIcon="dices"
    />
  </div>
  <div class="trade-skin--icon">
    <img
      [src]="icon"
      skinItemImage
      unselectable="on"
      alt=""
    />
  </div>
  <div class="trade-skin--name">{{ name }}</div>
  <app-currency
    class="trade-skin--price"
    [value]="price"
  ></app-currency>
  <div class="trade-skin--hover"></div>
</div>
