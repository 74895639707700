import { Injectable } from '@angular/core';
import { ILobbyGamesMotivator, IMotivatorDto, IMotivatorStatistic, IOrder, IPayout, IWinning } from '@dev-fast/types';
import { Socket as WrappedSocket } from 'ngx-socket-io';
import { Observable } from 'rxjs';

import { Environments, EnvironmentService } from '@app/core/environment-service';

import { ApiService } from '../api.service';

@Injectable({
  providedIn: 'root',
})
export class MotivatorBackendService {
  private readonly environment: Environments = this.environmentService.environments;

  constructor(
    private readonly ws: WrappedSocket,
    private readonly api: ApiService,
    private readonly environmentService: EnvironmentService,
  ) {}

  getStat = (filter: { keys: string; limit?: number; caseId?: number }): Observable<IMotivatorStatistic> =>
    this.api.get(`${this.environment.GATEWAY_URL}/api/motivator/cases`, { params: filter });

  onLastWon(callback: (payload: IMotivatorDto<any>) => void): void {
    this.ws.on('motivator.last.won', callback);
  }

  onTopGames(callback: (payload: ILobbyGamesMotivator) => void): void {
    this.ws.on('motivator.games-top', callback);
  }

  onTop24wins(callback: (payload: { topWins24: IWinning[] }) => void): void {
    this.ws.on('motivator.wins-top24', callback);
  }

  onTopWins(callback: (payload: IWinning) => void): void {
    this.ws.on('motivator.wins-top.last', callback);
  }
  onLastPayoutsOrders(callback: (payload: [{ lastPayouts: IPayout[] }, { lastOrders: IOrder[] }]) => void): void {
    this.ws.on('motivator.payouts-and-orders', callback);
  }
}
