export const getStageBackBoneUrl = (host: string): string | undefined => {
  if (host.includes('local')) {
    return undefined;
  }
  return `https://${host.includes('devfast') ? 'bridge' : 'gofast'}.${host.split('.').slice(-2).join('.')}`;
};

export const getStageMarketGateway = (host: string): string => {
  return `https://back-new.${host.includes('local') || host.includes('csgofast4') ? 'csmarket.gg' : host.split('.').slice(-2).join('.')}`;
};
