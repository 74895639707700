import { ChangeDetectionStrategy, Component, Inject, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IUserDetailed } from '@dev-fast/types';
import { Observable, Subject } from 'rxjs';
import { pluck, takeUntil, tap } from 'rxjs/operators';

import { P2pModalSetupService } from './modal.service';

@Component({
  selector: 'app-p2p-setup-modal',
  templateUrl: './p2p-setup-modal.component.html',
  styleUrls: ['./p2p-setup-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class P2pSetupModalComponent implements OnDestroy {
  apiKeyForm: FormGroup;
  tradeLinkForm: FormGroup;

  readonly steam$: Observable<IUserDetailed['links']['steam']>;
  readonly steamApiKey$: Observable<IUserDetailed['steamApiKey']>;
  readonly tradelinkIsValid$: Observable<boolean>;
  readonly apikeyIsValid$: Observable<boolean>;

  private destroyed$: Subject<void>;

  constructor(
    private readonly fb: FormBuilder,
    private readonly moduleService: P2pModalSetupService,
    @Inject(MAT_DIALOG_DATA) public data: string[],
  ) {
    this.tradelinkIsValid$ = this.moduleService.tradelinkIsValid$;
    this.apikeyIsValid$ = this.moduleService.apikeyIsValid$;

    this.destroyed$ = new Subject<void>();

    this.tradeLinkForm = this.fb.group({
      steam: ['', [Validators.required]],
      submitted: false,
    });

    this.apiKeyForm = this.fb.group({
      apikey: ['', [Validators.required]],
      submitted: false,
    });

    this.steam$ = this.moduleService.user$.pipe(
      pluck('links', 'steam'),
      tap((steam) =>
        this.tradeLinkForm.patchValue({
          steam: steam || '',
          submitted: !!steam,
        }),
      ),
    );

    this.steamApiKey$ = this.moduleService.user$.pipe(
      pluck('steamApiKey'),
      tap((apikey) =>
        this.apiKeyForm.patchValue({
          apikey: apikey || '',
          submitted: !!apikey,
        }),
      ),
    );
  }

  isDisabled(val: string, origin: string): boolean {
    // console.log(val);
    // console.log(origin);

    return val === origin || !val;
  }

  onSubmitTradeLink(steam: string): void {
    this.moduleService.updateTradeLink({ steam });

    this.moduleService.tradeLinkUpdated$.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      const control = this.tradeLinkForm.get('submitted');
      if (!control) {
        return;
      }
      control.setValue(true);
    });
  }

  onSubmitApiKey(key: string): void {
    this.moduleService.updateApiKey(key);
    this.moduleService.apiKeyUpdated$.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      const control = this.apiKeyForm.get('submitted');
      if (!control) {
        return;
      }
      control.setValue(true);
    });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
