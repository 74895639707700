import { Injectable } from '@angular/core';
import { INotification, NotificationCategory, NotificationStatus, NotificationType } from '@dev-fast/types';
import { Actions, ofActionDispatched, Select } from '@ngxs/store';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { map, Observable } from 'rxjs';

import {
  AddNotification,
  AddNotificationSuccess,
  GetNotifications,
  NotificationsState,
  RemoveNotification,
  WatchAllNotification,
  WatchNotification,
} from '@app/core/state/notifications';

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  isAddNotificationSuccess$: Observable<INotification> = this.actions$.pipe(
    ofActionDispatched(AddNotificationSuccess),
    map((data) => {
      return data.noty;
    }),
  );
  @Select(NotificationsState.items)
  readonly notifications$!: Observable<INotification[]>;
  @Select(NotificationsState.hasNewItems)
  readonly hasNewNotifications$!: Observable<boolean>;
  @Select(NotificationsState.countNewItems)
  readonly countNewNotifications$!: Observable<number>;
  constructor(private readonly actions$: Actions) {}
  @Dispatch() addNotification = (noty: INotification): AddNotification => new AddNotification(noty);
  @Dispatch() addErrorNotification = (message: string, noty?: Partial<INotification>): AddNotification =>
    new AddNotification({
      id: Date.now(),
      type: NotificationType.Error,
      category: NotificationCategory.ERROR,
      status: NotificationStatus.new,
      message,
      system: true,
      showToast: true,
      icon: '1',
      createDate: Date.now(),
      ...noty,
    });
  @Dispatch() addSuccessNotification = (message: string, noty?: Partial<INotification>): AddNotification =>
    new AddNotification({
      id: Date.now(),
      type: NotificationType.Success,
      status: NotificationStatus.new,
      message,
      icon: '1',
      system: true,
      showToast: true,
      createDate: Date.now(),
      ...noty,
    });
  @Dispatch() addInfoNotification = (message: string, noty?: Partial<INotification>): AddNotification =>
    new AddNotification({
      id: Date.now(),
      type: NotificationType.Info,
      status: NotificationStatus.new,
      message,
      icon: '1',
      system: true,
      showToast: true,
      createDate: Date.now(),
      ...noty,
    });
  @Dispatch() getNotifications = (): GetNotifications => new GetNotifications();
  @Dispatch() removeNotification = (notyId: number): RemoveNotification => new RemoveNotification(notyId);
  @Dispatch() watchAllNotifications = (): WatchAllNotification => new WatchAllNotification();
  @Dispatch() watchNotification = (notyId: number): WatchNotification => new WatchNotification(notyId);
}
