// TODO: должно быть до виджета?
export interface ChatraSetup {
  clientId: string;
  injectTo?: string;
}

/**
 * Данные о пользователе, которые мы передаем чатам
 */
export interface ChatIntegration {
  name: string;
  email?: string;
  phone?: string;
  notes?: string;
  USER_ID?: string;
}
