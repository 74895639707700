import { PromocodeTypes, ReferralCampaignTypes } from '../enums';
import { IError } from '../models';

export interface IReferralLevel {
  id: number;
  betRewardPercent: number;
  refillRewardBonus: number;
  numberOfReferrals: number;
}

export interface IReferralIncome {
  date: Date;
  amount: number;
}

export interface INewReferral {
  date: Date;
  count: number;
}

export interface IReferral {
  id: number;
  name: string;
  avatar: string;
  profileType: string;
  activity: {
    registered: Date;
  };
}

export interface ICampaignInfo {
  income: number;
  totalIncome: number;
  takenIncome: number;
  notTakenIncome: number;
}
export interface IReferralCode {
  code: string;
  id: number;
  name: string;
}
export interface IReferralCampaign extends IReferralCampaignLite {
  notTakenIncome: number;
  referrals: IReferralInfo[];
  referralsCount: number;
  totalIncome: number;
}
export interface IReferralCampaignLite extends IReferralCode {
  appId: number;
  createdAt: Date;
  updatedAt: Date;
  type: ReferralCampaignTypes;
  isPr?: boolean;
  reward?: number;
  activationLimit?: number;
  activationEndDate?: string;
}

export interface IReferralInfo {
  activated: boolean;
  createdAt: Date;
  deletedAt: Date;
  id: number;

  next: any;
  nextReferralCampaignId: number;
  nextReferrerUserId: number;
  prevReferralCampaignId: number;
  prevReferrerUserId: number;
  referralCampaignId: number;
  referrerUserId: number;
  rewardCoins: number;
  rewardCoinsTaken: number;

  status: string;

  updatedAt: Date;
  user: IReferralUser;
  userId: number;
}

interface IReferralUser {
  avatar: string;
  id: number;
  name: string;
  profileType: string;
}

export interface IReferralCampaignReport {
  date: string;
  amount: number;
  count: number;
  lostCount: number;
  clicks: number;
}
export interface IReferralCampaignReportParams {
  referralCampaignId: number;
  period: string;
  utcOffset: number;
  startAt: string;
  endAt: string;
}
export interface IPromoCode {
  code: string;
  referrer?: IReferrerUser;
  activationEndDate?: string | null;
  reward: number;
  rewarded: boolean;
  type: ReferralCampaignTypes | PromocodeTypes;
  canActivate?: boolean;
  condition?: number; //TODO:LK условие пополнения от Х.ХХ$
}

export interface IReferralBonus {
  rewardedCases: {
    freeCaseId: number;
    amount: number;
    caseId: number;
  }[];
}
export interface IPromocodeReward {
  rewardValue: { amount: number; type: PromocodeTypes };
  bonusCases: number;
  minRewardPayment: number;
}
export interface IPromocodeData {
  activationErr: IError | null;
  activePromoCode: IPromoCode | null;
  promoActivated: boolean;
}

export interface IActivateCampaignResponse extends IPromoCode {
  appId: number;
}

export interface IReferrerUser {
  user: {
    avatar: string;
    id: number;
    name: string;
    // silver etc
    profileType: string;
  };
}
export interface IReferralCampaignDto {
  campaigns: IReferralCampaign[];
  isReferralLocked: boolean;
}
