<div class="filter-button-container flex fx-align-center fx-justify-between">
  <!-- Состояние 1. Панель фильтра закрыта (начальное состояние) -->
  <ng-container *ngIf="!isFilterPanelOpened; else openedFilterButton">
    <!-- Десктопная версия кнопки -->
    <div
      class="filter-button filter-button_closed mobile-hide flex fx-align-center relative"
      [class.filter-button_disabled]="isLoading"
      (click)="changePanelStatus()"
    >
      <ng-container *ngIf="isLoading; else filterIcon">
        <ng-container *ngTemplateOutlet="loadingIcon"></ng-container>
      </ng-container>

      <div class="flex fx-justify-center w-100">
        <span>{{ 'LOCAL.FILTERS.TITLE' | translate }}</span>
      </div>

      <!-- Счетчик примененных фильтров (отображается если фильтры применены) -->
      <ng-container *ngIf="appliedFiltersAmount">
        <ng-container *ngTemplateOutlet="appliedFiltersNotifier"></ng-container>
      </ng-container>
    </div>

    <!-- Мобильная версия кнопки -->
    <div
      class="filter-button filter-button_closed mobile-show flex fx-align-center relative"
      [class.filter-button_disabled]="isLoading"
      (click)="openMobileFilterPanel()"
    >
      <ng-container *ngIf="isLoading; else filterIcon">
        <ng-container *ngTemplateOutlet="loadingIcon"></ng-container>
      </ng-container>

      <div class="flex fx-justify-center w-100">
        <span>{{ 'LOCAL.FILTERS.TITLE' | translate }}</span>
      </div>

      <!-- Счетчик примененных фильтров (отображается если фильтры применены) -->
      <ng-container *ngIf="appliedFiltersAmount">
        <ng-container *ngTemplateOutlet="appliedFiltersNotifier"></ng-container>
      </ng-container>
    </div>

    <!-- Шаблон счетчика примененных фильтров -->
    <ng-template #appliedFiltersNotifier>
      <div class="applied-filters-notifier absolute flex fx-all-center">
        <span>{{ appliedFiltersInfo }} </span>
      </div>
    </ng-template>
  </ng-container>

  <!-- Состояние 2. Панель фильтра открыта -->
  <ng-template #openedFilterButton>
    <div class="filter-button filter-button_opened flex fx-align-center fx-justify-between gap-1 p-sm-1">
      <!-- Пустая вложенная (левая) кнопка (отображается если фильтры не применены) -->
      <ng-container *ngIf="!appliedFiltersAmount; else clearButton">
        <div class="w-50"></div>
      </ng-container>

      <!-- Шаблон вложенной (левой) кнопки очистки примененных фильтров (отображается если фильтры применены) -->
      <ng-template #clearButton>
        <div
          class="filter-button__sub-button filter-button__sub-button_trashcan flex fx-all-center"
          (click)="clearSelectedFilters()"
        >
          <mat-icon svgIcon="trashcan"></mat-icon>
        </div>
      </ng-template>

      <!-- Вложенная (правая) кнопка сворачивания/закрытия панели фильтра (если фильтры не применены/применены, но предметов не найдено) -->
      <ng-container *ngIf="!appliedFiltersAmount || !itemsFoundAmount; else resultButton">
        <!-- Десктопная версия вложенной кнопки -->
        <div
          class="filter-button__sub-button filter-button__sub-button_gray mobile-hide flex fx-all-center relative"
          (click)="changePanelStatus()"
        >
          <ng-container *ngIf="isLoading; else emptyResult">
            <ng-container *ngTemplateOutlet="loadingIcon"></ng-container>
          </ng-container>
        </div>

        <!-- Мобильная версия вложенной кнопки -->
        <div
          class="filter-button__sub-button filter-button__sub-button_gray mobile-show flex fx-all-center"
          (click)="openMobileFilterPanel()"
        >
          <ng-container *ngIf="isLoading; else emptyResult">
            <ng-container *ngTemplateOutlet="loadingIcon"></ng-container>
          </ng-container>
        </div>

        <!-- Шаблон кнопки закрытия/сворачивания панели фильтра -->
        <ng-template #emptyResult>
          <ng-container *ngIf="appliedFiltersAmount && appliedFiltersAmount !== startAppliedFiltersAmount; else arrowIcon">
            <ng-container *ngTemplateOutlet="closeIcon"></ng-container>
            <ng-container *ngTemplateOutlet="resultTooltip"></ng-container>
          </ng-container>
        </ng-template>
      </ng-container>

      <!-- Шаблон вложенной (правой) кнопки показа результатов фильтрации (если предметы найдены) -->
      <ng-template #resultButton>
        <div
          class="filter-button__sub-button filter-button__sub-button_green flex fx-all-center relative"
          [class.filter-button_disabled]="isLoading"
          (click)="applySelectedFilters()"
        >
          <ng-container *ngIf="isLoading; else checkedIcon">
            <ng-container *ngTemplateOutlet="loadingIcon"></ng-container>
          </ng-container>

          <ng-container *ngTemplateOutlet="resultTooltip"></ng-container>
        </div>
      </ng-template>

      <ng-template #resultTooltip>
        <div class="filter-button__result-tooltip flex fx-justify-center tw-font-medium">
          <ng-container *ngIf="itemsFoundAmount; else emptyResultLocale">
            <span>{{ 'P2P_SETTINGS.FILTERS.SHOW' | translate }}</span>
            <span>{{ itemsFoundAmount }}</span>
          </ng-container>

          <ng-template #emptyResultLocale>
            <span>{{ 'P2P_SETTINGS.FILTERS.NOT_FOUND' | translate }}</span>
          </ng-template>
        </div>
      </ng-template>
    </div>
  </ng-template>
</div>

<ng-template #arrowIcon>
  <mat-icon svgIcon="arrow-left-compact"></mat-icon>
</ng-template>

<ng-template #checkedIcon>
  <mat-icon svgIcon="checked-icon" />
</ng-template>

<ng-template #closeIcon>
  <mat-icon svgIcon="close"></mat-icon>
</ng-template>

<ng-template #filterIcon>
  <mat-icon svgIcon="vortex--design"></mat-icon>
</ng-template>

<ng-template #loadingIcon>
  <mat-icon svgIcon="refresh-line"></mat-icon>
</ng-template>
