import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IWinnerBanner } from '@dev-fast/types';

import { winnerAnimation } from './winner-banner.animation';

@Component({
  selector: 'app-ui-winner-banner',
  templateUrl: './winner-banner.component.html',
  styleUrls: ['./winner-banner.component.scss'],
  animations: [winnerAnimation],

  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WinnerBannerComponent {
  @Input() public winnerBannerInfo!: IWinnerBanner;
}
