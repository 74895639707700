import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, NgModule, Output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { EventsConfig, ICaseItemDtoV2, ICaseType, MatchPopTypeImg, MatchTicketTypeImg, TicketTypeEnum } from '@dev-fast/types';
import { TranslateModule } from '@ngx-translate/core';
import { TuiMapperPipeModule } from '@taiga-ui/cdk';

import { LangRouterModule, LazyLoadImageModule } from '@app/shared/directives';
import { AppCurrencyModule } from '@app/shared/pipes';

import { SelectionInputModule } from '../../../selection-input/selection-input.component';
import { TagComponent } from '../../../tag/tag.component';
import { CaseCardMockComponent } from './mock/case-card.component.mock';

@Component({
  selector: 'app-ui-case-card',
  templateUrl: './case-card.component.html',
  styleUrls: ['./case-card.component.scss', './case-card.media.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CaseCardComponent {
  @Input() item!: ICaseItemDtoV2;
  @Input() eventName: keyof EventsConfig | null | undefined;
  @Input() totalCasesAmount = 0;
  @Input() currentCaseAmount = 0;
  @Input() showDescription = true;
  @Input() showInput = false;
  @Input() showTags = true;
  @Input() showRevision = false;
  @Input() isHovered = true;
  @Output() onaddCase: EventEmitter<ICaseItemDtoV2[]> = new EventEmitter();
  @Output() onremoveCase: EventEmitter<ICaseItemDtoV2[]> = new EventEmitter();
  @Output() openRevisionModal: EventEmitter<number> = new EventEmitter();

  caseTypeEnum: typeof ICaseType = ICaseType;
  ticketColorEnum: typeof TicketTypeEnum = TicketTypeEnum;
  ticketsImgDic: Record<string, string> = MatchTicketTypeImg;
  popImgDic: Record<string, string> = MatchPopTypeImg;
  classList: string[] = this.classListConstructor();

  filterTags(tags: string[]): string[] {
    return tags.filter((tag) => tag.length);
  }

  getBackgroundImage(item: ICaseItemDtoV2): string {
    if (item.backgroundImage) {
      return item.backgroundImage;
    }
    return '';
  }
  isCaseEnabled(): boolean {
    if (this.item?.enabled) {
      if (this.item.type !== ICaseType.FREE) {
        return true;
      } else if (this.item.type === ICaseType.FREE && (this.item.free?.count || this.item.ticketQuest)) {
        return true;
      }
      return false;
    }
    return false;
  }
  openModal(): void {
    this.openRevisionModal.emit(this.item.id);
  }
  addCase(caseItem: ICaseItemDtoV2): void {
    this.onaddCase.emit([caseItem]);
  }
  removeCase(caseItem: ICaseItemDtoV2): void {
    this.onremoveCase.emit([caseItem]);
  }
  changeAmount(amount: number, caseItem: ICaseItemDtoV2): void {
    if (this.currentCaseAmount < amount) {
      this.addCase(caseItem);
    } else {
      this.removeCase(caseItem);
    }
  }
  classListConstructor(): string[] {
    const list = [];
    if (!this.isCaseEnabled()) {
      list.push('disabled');
    }
    if (this.currentCaseAmount > 0) {
      list.push('changed');
    }
    if (this.isHovered) {
      list.push('hovered');
    }
    return list;
  }
}

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    LazyLoadImageModule,
    RouterModule,
    AppCurrencyModule,
    MatIconModule,
    SelectionInputModule,
    TagComponent,
    LangRouterModule,
    TuiMapperPipeModule,
  ],
  declarations: [CaseCardComponent, CaseCardMockComponent],
  exports: [CaseCardComponent, CaseCardMockComponent],
})
export class CaseCardComponentModule {}
