import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Optional } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { AuthType, ModalNames, NotificationStatus, NotificationType, SocialItem, SocialType } from '@dev-fast/types';
import { Select } from '@ngxs/store';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import isWebview from 'is-ua-webview';
import { Observable } from 'rxjs';

import { AnalyticsService } from '@app/core/analytics-service';
import { EnvironmentService } from '@app/core/environment-service';
import { FrameMessageTypes, IFrameMessageService } from '@app/core/iframe';
import { NotificationsService } from '@app/core/notification-service';
import { CloseModal } from '@app/core/state/modals';

import { BindAccount, GetAuthorizationToken, Logout, OpenAuthModal, RemoveAccount } from './state/auth.actions';
import { AuthState } from './state/auth.state';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  @Select(AuthState.socials)
  socials$!: Observable<SocialItem[]>;
  @Select(AuthState.unlinkedSocials)
  unlinkedSocials$!: Observable<SocialItem[]>;
  @Select(AuthState.isAuth)
  isAuth$!: Observable<boolean>;

  private authWindow: Window | undefined | null;
  private environment = this.environmentService.environments;
  private authWindowInterval: ReturnType<typeof setInterval> | undefined;
  private window = this.document.defaultView;

  constructor(
    private readonly environmentService: EnvironmentService,
    private readonly notifyService: NotificationsService,
    public dialog: MatDialog,
    @Optional() private readonly frameMessageService: IFrameMessageService,
    private readonly analyticsService: AnalyticsService,
    private activatedRoute: ActivatedRoute,
    @Inject(DOCUMENT) private document: Document,
  ) {
    if (this.frameMessageService) {
      this.frameMessageService.on(FrameMessageTypes.MESSAGE_FROM_BB, 'openAuthWindow', (payload: { provider: SocialType }) =>
        this.onLogin(payload.provider),
      );
    }

    this.activatedRoute.queryParams.subscribe((params) => {
      if ((params['event'] && params['event'] === AuthType.Auth) || params['event'] === AuthType.Reg || params['event'] === AuthType.Bind) {
        const { event, code, p, success, error } = params;
        this.handleAuthResult({ data: { event, success, provider: p, code, source: 'WebView', error } });
      }
    });
  }
  isWebview = (): boolean => {
    return isWebview(this.window ? this.window.navigator.userAgent : 'WebView');
  };
  onLogin = (provider: SocialType): void => {
    // TODO всегда приходит null в оригинале
    // const token = this.userService.getToken();
    // const token = '';
    const authUrl = `${this.environment.GATEWAY_URL}/api/authorization/provider/${provider}${
      this.isWebview() ? '?from=' + window.location.host : ''
    }`;
    this.#openAuthWindow(authUrl);
  };

  onLogout(): void {
    this.logout();
  }

  onRemoveAccount(account: string): void {
    this.removeAccount(account);
  }

  bindProvider(provider: SocialType): void {
    const authUrl = `${this.environment.GATEWAY_URL}/api/providers/${provider}/bind${
      this.isWebview() ? '?from=' + window.location.host : ''
    }`;
    this.#openAuthWindow(authUrl);
  }

  openLoginProvidersModal(): void {
    this.openAuthModal();
  }

  #openAuthWindow(url: string): void {
    if (this.authWindow) {
      this.authWindow.close();
    }
    const params = {
      width: 450,
      height: 600,
      resizable: 'yes',
      scrollbars: 'yes',
    };
    if (this.window) {
      this.window.addEventListener('message', this.handleAuthResult, false);
      this.authWindow = this.window.open(url, 'socialAuth', this.#paramsToString(params));
      if (this.authWindow) {
        this.authWindow.focus();
      }
      this.authWindowInterval = setInterval(() => {
        if (this.authWindow && this.authWindow.closed) {
          this.#cleanAuthMessageListener();
          if (this.authWindowInterval) {
            clearInterval(this.authWindowInterval);
          }
        }
      }, 1000);
    }
  }

  #paramsToString(params: Record<string, number | string>): string {
    return Object.keys(params)
      .map((key) => `${key}=${params[key]}`)
      .join(',');
  }

  #cleanAuthMessageListener(): void {
    if (this.window) {
      this.window.removeEventListener('message', this.handleAuthResult);
    }
  }

  private handleAuthResult = (message: {
    data: {
      source: string;
      success: boolean;
      code: string;
      event: AuthType;
      provider: SocialType;
      error: { message?: string; error: string; system: boolean };
    };
  }): void => {
    if (message.data.source === 'auth-server' || message.data.source === 'WebView') {
      this.#cleanAuthMessageListener();
      const { success, event, provider, code } = message.data;
      if (success) {
        this.closeAuthModal();
        if ([AuthType.Reg, AuthType.Auth].includes(event)) {
          if (event === AuthType.Reg) {
            //TODO мож вынести в экшон где получаю юзера и там чекать по дате
            this.analyticsService.registrationEvent(provider);
          }
          if (code) {
            this.login(code);
          } else {
            this.window?.location.reload();
          }
        } else if (event === AuthType.Bind) {
          this.bind();
        }
      } else {
        const { error } = message.data;
        this.notifyService.addNotification({
          id: Date.now(),
          type: NotificationType.Error,
          icon: 'warning',
          message: error ? error.message || error.error : 'Auth error. Try again later or write to support',
          createDate: Date.now(),
          status: NotificationStatus.new,
          system: error ? error.system : true,
        });
      }
      if (this.authWindow) {
        this.authWindow.close();
      }
    }
  };

  @Dispatch() private login = (code: string): GetAuthorizationToken => new GetAuthorizationToken(code);
  @Dispatch() private removeAccount = (account: string): RemoveAccount => new RemoveAccount(account);
  @Dispatch() private logout = (): Logout => new Logout();
  @Dispatch() private bind = (): BindAccount => new BindAccount();
  @Dispatch() private openAuthModal = (): OpenAuthModal => new OpenAuthModal();
  @Dispatch() closeAuthModal = (): CloseModal => new CloseModal(ModalNames.AUTH);
}
