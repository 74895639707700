import {
  ActivePanel,
  ActiveRoute,
  Design,
  DesignType,
  FooterCounts,
  MarketCounts,
  NewMenu,
  NewPanel,
  Panel,
  Themes,
  Widgets,
} from '@dev-fast/types';

export class ChangeTheme {
  static readonly type = '[Layout] Change theme';
  constructor(public theme: Themes) {}
}

export class ChangeDesign {
  static readonly type = '[Layout] Change Design';
  constructor(public design: Design) {}
}

export class ChangeAllowedPanels {
  static readonly type = '[Layout] Change Allowed Panels';
  constructor(public panels: Panel[]) {}
}
export class ChangeActivePanel {
  static readonly type = '[Layout] Change active panel';
  constructor(public panel: ActivePanel | null) {}
}

export class OpenPanel {
  static readonly type = '[Layout] open panel';
  constructor(public panel: NewPanel) {}
}
export class ClosePanel {
  static readonly type = '[Layout] close panel';
  constructor(public panel: NewPanel) {}
}
export class ToggleNewPanel {
  static readonly type = '[Layout] toggle new panel';
  constructor(public panel: NewPanel) {}
}

export class CloseAllPanels {
  static readonly type = '[Layout] close all panels';
}
export class OpenMenu {
  static readonly type = '[Layout] open menu ';
  constructor(public menu: NewMenu) {}
}

export class CloseMenu {
  static readonly type = '[Layout] close menu';
  constructor(public menu: NewMenu) {}
}

export class CloseAllMenu {
  static readonly type = '[Layout] close all menu';
}
export class OpenAvailablePanel {
  static readonly type = '[Layout] Open Available Panel';
  constructor(public design?: DesignType) {}
}
export class RefreshActivePanel {
  static readonly type = '[Layout] Refresh active panel';
  constructor(public payload: Partial<ActivePanel> | null) {}
}
export class AllowTradePanelAutoOpen {
  static readonly type = '[Layout] Allow trade panel auto open';
  constructor(public isOpen: boolean) {}
}
export class ChangeActiveWidgets {
  static readonly type = '[Layout] Change active widgets';
  constructor(public widgets: Widgets[]) {}
}
export class ToggleGameSelector {
  static readonly type = '[Layout] Toggle Game Selector';
  constructor(public active: boolean) {}
}
export class LockSides {
  static readonly type = '[Layout] Lock Sides';
}
export class UnLockSides {
  static readonly type = '[Layout] UnLock Sides';
}
export class ClickOnLockedSide {
  static readonly type = '[Layout] Click On Locked Side';
}
export class LockPanels {
  static readonly type = '[Layout] Lock Panels';
  constructor(public panels: Panel[]) {}
}
export class UnLockPanels {
  static readonly type = '[Layout] UnLock Panels';
  constructor(public panels: Panel[]) {}
}
export class SetLayoutType {
  static readonly type = '[Layout] Set Layout Type';
  constructor(public type: string) {}
}
export class ToggleBackground {
  static readonly type = '[Layout] Toggle Background';
  constructor(public background?: string) {}
}
export class NavigateToMobile {
  static readonly type = '[Layout] Navigate To Mobile';
}

/** не используется */
export class NavigateToBack {
  static readonly type = '[Layout] Navigate To Back';
}

export class SetContentBreakpoint {
  static readonly type = '[Layout] set layout breakpoint';
  constructor(public bp: string) {}
}

export class PatchOnline {
  static readonly type = '[Layout] patch online';
  constructor(public online: number) {}
}
export class PatchCounts {
  static readonly type = '[Layout] patch counts';
  constructor(public counts: FooterCounts) {}
}

export class PatchMarketCounts {
  static readonly type = '[Layout] patch marketCounts';
  constructor(public marketCounts: MarketCounts) {}
}

export class SetActiveRoute {
  static readonly type = '[Layout] set active route';
  constructor(public route: ActiveRoute) {}
}
