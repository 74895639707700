import { Injectable } from '@angular/core';
import { ActivePanel, ICurrencyRate, IUserDetailed, SocialType, Themes, WalletTypeEnum } from '@dev-fast/types';
import { Select, Store } from '@ngxs/store';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { map, Observable } from 'rxjs';

import { AuthService, AuthState } from '@app/auth';
import { CurrencyState, GetCurrencySettings } from '@app/core/state/currency-store';
import { GetAllGamesSettings } from '@app/core/state/games-store';
import { GetInventoryInfo } from '@app/core/state/inventory';
import { ChangeActivePanel, LayoutState } from '@app/core/state/layout';
import { DemandSelfExclusion, Init, UpdateCurrentUser, UserState } from '@app/core/state/user-store';

@Injectable({
  providedIn: 'root',
})
export class CommonStoreService {
  @Select(UserState.user)
  readonly user$!: Observable<IUserDetailed | null | undefined>;

  @Select(AuthState.isAuth)
  readonly isAuth$!: Observable<boolean>;

  @Select(LayoutState.theme)
  theme$!: Observable<Themes>;
  @Select(LayoutState.activePanel)
  activePanel$!: Observable<ActivePanel | null>;

  @Select(CurrencyState.currency)
  readonly currency$!: Observable<ICurrencyRate | null>;
  @Select(CurrencyState.currencyList)
  readonly currencyList$!: Observable<ICurrencyRate[]>;

  constructor(
    private readonly authService: AuthService,
    private readonly store: Store,
  ) {}

  switchTheme(theme: Themes): void {
    this.patchCurrentUser({ theme });
  }

  patchCurrentUser(data: Partial<IUserDetailed>): void {
    this.patchUser(data);
  }
  init = (): void => {
    this.initUserState();
    this.getCurrencySettings();
    this.getGamesSettings();
  };
  // методы авторизации и привязки соц сетей
  login = (): void => {
    this.authService.openLoginProvidersModal();
  };
  bindProvider = (provider: SocialType): void => {
    this.authService.bindProvider(provider);
  };
  removeProvider = (provider: SocialType): void => {
    this.authService.onRemoveAccount(provider);
  };
  logout = (): void => {
    this.authService.onLogout();
  };
  getWalletById(id: WalletTypeEnum): Observable<number> {
    return this.store.select(UserState.userWallet).pipe(map((filterFn) => filterFn(id)));
  }

  //------------------------------------------------------
  @Dispatch() getCurrencySettings = (): GetCurrencySettings => new GetCurrencySettings();
  @Dispatch() initUserState = (): Init => new Init();
  @Dispatch() getGamesSettings = (): GetAllGamesSettings => new GetAllGamesSettings();
  // @Dispatch() public getUser = () => new GetUser();
  @Dispatch() patchUser = (payload: Partial<IUserDetailed>): UpdateCurrentUser => new UpdateCurrentUser(payload);
  @Dispatch() changeActivePanel = (panel: ActivePanel | null): ChangeActivePanel => new ChangeActivePanel(panel);
  // Не придумал умнее места
  @Dispatch() demandSelfExclusion = (duration: number): DemandSelfExclusion => new DemandSelfExclusion(duration);
  @Dispatch() getInventoryInfo = (): GetInventoryInfo => new GetInventoryInfo();
}
