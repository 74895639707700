<ng-container
  *ngIf="{
    activities: activities$ | async,
    activitiesWidgets: activitiesWidgets$ | async
  } as data"
>
  <div
    class="widgets-list"
    *ngIf="data.activitiesWidgets && data.activitiesWidgets.length"
  >
    <!-- {{!!data.activities }}
{{data.activitiesWidgets|json}} -->
    <ng-container *ngFor="let widget of data.activitiesWidgets">
      <ng-container *ngIf="[widgets.PlayerOfTheDay, widgets.LuckOfTheDay].includes(widget)">
        <ng-container *ngIf="!isServer && data.activities; else mockWidgets">
          <app-motivator-widget
            [data]="widgetToData(data.activities, widget)"
            (navigateToItem)="navigateToCaseItem($event)"
          ></app-motivator-widget>
        </ng-container>

        <ng-template #mockWidgets>
          <app-motivator-widget-mock [widget]="widget"> </app-motivator-widget-mock>
        </ng-template>
      </ng-container>

      <ng-container *ngIf="widget === widgets.Portal && !isServer">
        <ng-template [cdkPortalOutlet]="customWidgetsPortal$ | async"></ng-template>
      </ng-container>
    </ng-container>
  </div>
</ng-container>
