<div class="game-type__container">
  <div class="game-type__header">
    <div class="game-type__header-title">{{ mode | uppercase }}</div>
    <div class="game-type__header-subtitle">{{ modesDesc[mode] }}</div>
  </div>
  <div
    class="game-type__body"
    [class.game-type__body__table-view]="isTable"
  >
    <app-ui-menu-item-v2
      *ngFor="let game of games"
      [itemConfig]="this.getItemConfig(game)"
      (click)="navigateGameItem()"
      class="menu-item"
    ></app-ui-menu-item-v2>
  </div>
</div>
