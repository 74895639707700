import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
// import {MatCheckboxModule} from '@anaawdaawdawdawdsdawgular/material/checkbox';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { AuthFailTooltipComponent, UiCheckboxSAComponent } from '@dev-fast/ui-components';
import { TranslateModule } from '@ngx-translate/core';
import { NgScrollbarModule } from 'ngx-scrollbar';

import { FeatureFlagsModule } from '@app/feature-flags';
import { IsProjectModule, ModuleIsOnModule } from '@app/shared/directives';

import { AuthModalComponent } from './auth-modal/auth-modal.component';
import { AuthModalV2Component } from './auth-modal-v2/auth-modal.component';

@NgModule({
  declarations: [AuthModalComponent, AuthModalV2Component],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    MatCheckboxModule,
    MatTooltipModule,
    MatDialogModule,
    MatIconModule,
    NgScrollbarModule,
    TranslateModule.forChild(),
    AuthFailTooltipComponent,
    FeatureFlagsModule,
    AuthFailTooltipComponent,
    ModuleIsOnModule,
    UiCheckboxSAComponent,
    IsProjectModule,
  ],
  exports: [AuthModalComponent, AuthModalV2Component],
})
export class AuthModalsModule {}
