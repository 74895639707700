<div
  class="games-panel__wrapper"
  [class.games-panel__table-view]="panelConfig.isTable"
>
  <ng-container *ngIf="panelConfig.isTable; else desktopView">
    <ng-scrollbar
      [@tableMenuOpenAnimation]
      class="scrollbar"
    >
      <div
        scrollViewport
        class="scrollbar"
      >
        <div class="games-panel__content">
          <div class="games-panel__content-services">
            <div>{{ 'Services' | translate }}</div>
            <div class="games-panel__content-services-wrapper">
              <!-- <app-ui-event-menu-container [events]="eventsConfig"></app-ui-event-menu-container> -->
              <app-ui-menu-item-v2
                [itemConfig]="market"
                (click)="proceedPanelStatus()"
              ></app-ui-menu-item-v2>
            </div>
          </div>
          <!-- <div class="games-panel__banner">BANNER IF NEEDED</div> -->
          <ng-container *ngFor="let mode of modes">
            <app-ui-game-type-container
              *ngIf="panelConfig.games[mode] as games"
              [games]="games"
              [state]="panelConfig.state"
              [isTable]="panelConfig.isTable"
              [mode]="mode"
              class="border-right"
              (AllGamesPanelStatus)="proceedPanelStatus()"
            ></app-ui-game-type-container>
          </ng-container>
        </div>
      </div>
    </ng-scrollbar>
  </ng-container>
  <ng-template #desktopView>
    <div class="games-panel__content">
      <ng-container *ngFor="let mode of modes">
        <app-ui-game-type-container
          *ngIf="panelConfig.games[mode] as games"
          [games]="games"
          [state]="panelConfig.state"
          [isTable]="panelConfig.isTable"
          [mode]="mode"
          class="border-right"
          (AllGamesPanelStatus)="proceedPanelStatus()"
        ></app-ui-game-type-container>
        <div class="panel-delimiter"></div>
      </ng-container>
    </div>
  </ng-template>
</div>

<div
  class="games-panel__overlay"
  (click)="proceedPanelStatus()"
></div>
