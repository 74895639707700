<div
  *ngIf="item"
  class="lot"
  [ngClass]="bodyBorderClass"
>
  <!-- {{ item.status }} -->
  <div class="lot__status flex fx-align-center fx-justify-between">
    <div class="lot__status-title tw-font-bold">
      {{ lotStatusTitleLocale | translate }}
    </div>
    <div
      *ngIf="showTimer"
      class="lot__status-timer lot__text-sm flex fx-align-center"
    >
      <ng-container *ngIf="offerTimer$ | async as offerTimer">
        <mat-icon svgIcon="clock--design"> </mat-icon>
        {{ offerTimer | date: dateFormat }}
      </ng-container>
    </div>
  </div>
  <app-ui-p2p-lot-card
    class="p-2 border-bottom"
    [items]="item.items"
    [isHidePrice]="true"
    [stickersLeft]="true"
    (showItemDetails)="onShowLotDetailsModal(item)"
  ></app-ui-p2p-lot-card>

  <div class="lot__offer price-tag border-bottom flex fx-column fx-justify-center gap-2">
    <div
      *ngIf="item.price"
      class="lot__text-sm flex fx-align-center fx-justify-between"
    >
      {{ (item.isLastBidByUser ? 'P2P_WIDGETS.PURCHASE.YOUR_OFFER' : 'P2P_WIDGETS.PURCHASE.CURRENT_OFFER') | translate }}
      <app-currency
        class="current-price tw-font-medium"
        [value]="item.price"
      />
    </div>
  </div>

  <div class="lot__actions lot__text-sm">
    <ng-container
      *ngIf="
        [OrderStatusEnum.NEW, OrderStatusEnum.FIRST_BID, OrderStatusEnum.SECOND_BID, OrderStatusEnum.THIRD_PLUS_BID].includes(item.status)
      "
    >
      <div class="price-tag flex fx-align-center fx-justify-between pt-sm-3">
        {{ 'P2P_WIDGETS.PURCHASE.BID_AMOUNT' | translate }}
        <app-currency
          class="current-price tw-font-medium"
          [value]="item.price"
        />
      </div>

      <ng-container *ngIf="item.isLastBidByUser; else nexBidTemplate">
        <div class="best-offer mt-3 tw-font-medium">
          {{ 'P2P_WIDGETS.PURCHASE.YOUR_OFFER' | translate }}
        </div>
      </ng-container>
      <ng-template #nexBidTemplate>
        <div class="flex gap-2 pt-sm-5">
          <div class="w-100">
            <ng-container *ngIf="permissions$ | async as permissions">
              <ng-container *ngIf="permissions?.error === SteamErrorsEnum.UNBINDING_STEAM; else tradeLayout">
                <button
                  class="btn btn-primary--design"
                  (click)="bindingSocial()"
                >
                  {{ 'P2P_WIDGETS.BIND_STEAM' | translate }}
                </button>
              </ng-container>
              <ng-template #tradeLayout>
                <ng-container *ngIf="permissions.canTrade?.success; else tradeSettingTemplate">
                  <ng-container *ngIf="canBid$ | async; else notEnoughtMoney">
                    <button
                      class="btn btn-primary--design"
                      (click)="createBid(item.id)"
                      [disabled]="item.isActive"
                    >
                      <mat-spinner
                        *ngIf="item.isActive"
                        class="primary-spinner"
                        diameter="16"
                      ></mat-spinner>
                      {{ 'P2P_WIDGETS.PURCHASE.BUY' | translate }}
                    </button>
                  </ng-container>
                  <ng-template #notEnoughtMoney>
                    <button
                      class="btn btn-primary--design"
                      langRouterLink
                      [routerLink]="[{ outlets: { modal: ['transaction', 'refill'] } }]"
                      [disabled]="item.isActive"
                    >
                      {{ 'BALANCE_REFILL.LINK.REFILL' | translate | titlecase }}
                    </button>
                  </ng-template>
                </ng-container>
                <ng-template #tradeSettingTemplate>
                  <button
                    class="btn btn-primary--design"
                    [class.shrink-text]="permissions.canTrade?.error === SteamErrorsEnum.PRIVATE_INVENTORY"
                    (click)="handleErrorAction(permissions)"
                  >
                    {{ formatErrBtnText(permissions) | translate: { days: permissions.canTrade?.days || 0 } }}
                  </button>
                </ng-template>
              </ng-template>
            </ng-container>
          </div>
          <div class="lot__actions__bid-close">
            <button
              class="btn btn-basic--design"
              (click)="onClose(item.id)"
            >
              {{ 'CLOSE' | translate }}
            </button>
          </div>
        </div>
      </ng-template>
    </ng-container>
    <ng-container *ngIf="item.isLastBidByUser">
      <ng-container *ngIf="{ timer: offerTimer$ | async } as offer">
        <ng-container *ngIf="[OrderStatusEnum.WAIT_FOR_CONFIRM, OrderStatusEnum.WAIT_FOR_TRADE].includes(item.status)">
          <div class="lot__actions-describe pt-sm-3">
            {{ 'P2P_WIDGETS.PURCHASE.WAIT_FOR_' + (OrderStatusEnum.WAIT_FOR_CONFIRM === item.status ? 'CONFIRM' : 'TRADE') | translate }}
            <ng-container *ngIf="offer.timer && offer.timer > 1000; else emptyTimerTeplate">
              {{ offer.timer | date: dateFormat }}
            </ng-container>
            <ng-template #emptyTimerTeplate> 00:00 </ng-template>
          </div>
        </ng-container>
        <ng-container *ngIf="item.status === OrderStatusEnum.WAIT_FOR_BUYER_ACCEPT">
          <div class="lot__actions-describe pt-sm-3">
            <ng-container *ngIf="offer.timer && offer.timer > 1000; else emptyTimerTeplate">
              {{ 'P2P_WIDGETS.PURCHASE.WAIT_FOR_BUYER_ACCEPT' | translate }} {{ offer.timer | date: dateFormat }}
            </ng-container>
            <ng-template #emptyTimerTeplate> {{ 'P2P_WIDGETS.WARN.TIMEOUT_BUY' | translate }} </ng-template>
          </div>
          <button
            class="btn btn-primary--design w-100 mt-sm-5"
            [disabled]="!offer.timer || offer.timer < 1000"
            (click)="getTrade()"
          >
            {{ 'P2P_WIDGETS.PURCHASE.GET_TRADE' | translate }}
          </button>
        </ng-container>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="orderCompleted">
      <div
        *ngIf="item.status !== OrderStatusEnum.COMPLETED"
        [ngSwitch]="item.status"
        class="lot__actions-describe pt-sm-3"
      >
        <ng-container *ngSwitchCase="OrderStatusEnum.CANCELED_BY_SELLER">{{
          'P2P_WIDGETS.PURCHASE.CANCELED_BY_SELLER' | translate
        }}</ng-container>
        <ng-container *ngSwitchCase="OrderStatusEnum.CANCELED_BY_BUYER">{{
          'P2P_WIDGETS.PURCHASE.CANCELED_BY_BUYER' | translate
        }}</ng-container>
        <ng-container *ngSwitchCase="OrderStatusEnum.CANCELED_BY_SELLER_BEFORE_TRADE">{{
          'P2P_WIDGETS.PURCHASE.CANCELED_BY_SELLER_BEFORE_TRADE' | translate
        }}</ng-container>
        <ng-container *ngSwitchCase="OrderStatusEnum.CANCELED_BY_TIMER">{{
          'P2P_WIDGETS.PURCHASE.CANCELED_BY_TIMER' | translate
        }}</ng-container>
        <ng-container *ngSwitchCase="OrderStatusEnum.CANCELED_BY_FASTOFFER">{{
          'P2P_WIDGETS.PURCHASE.CANCELED_BY_COMMONOFFER' | translate
        }}</ng-container>
        <ng-container *ngSwitchCase="OrderStatusEnum.AUCTION_FINISHED">
          {{ 'P2P_WIDGETS.PURCHASE.AUCTION_FINISHED' | translate }}
        </ng-container>
        <ng-container *ngSwitchCase="OrderStatusEnum.DELETED">{{ 'P2P_WIDGETS.PURCHASE.DELETED' | translate }}</ng-container>
        <ng-container *ngSwitchCase="OrderStatusEnum.WAIT_FOR_BUYER_ACCEPT">
          {{ 'P2P_WIDGETS.RENEW_INFO_MODAL.SOMEONE_BID' | translate }}
        </ng-container>
      </div>
      <button
        class="btn btn-basic--design mt-sm-5"
        (click)="onDeleted(item.id)"
      >
        {{ 'CLOSE' | translate }}
      </button>
    </ng-container>
  </div>
</div>
