/**
  Типы кошельков в пополнениях.
*/
export enum PaymentWalletType {
  /* кошельки создаваемые на фронте для определения  по факту не используются и выступают как шаблон */
  GIFT = 'gift',
  SKINS = 'skins',
  /* кошельки с бекенда */
  CPF = 'cpf',
  DEFAULT = 'default',
  EMAIL = 'email',
  FULL_NAME = 'full_name',
  PHONE = 'phone',
  WALLET = 'wallet',
}

/**
  Категории платежных методов.
*/
export enum PaymentTypes {
  POPULAR = 'popular',
  REGIONAL = 'regional',
  CARDS = 'cards',
  CRYPTO = 'crypto',
}

/**
  Специальные имена.
  Используются для определения маршрутов особых способов пополнения (скинами, подарочными картами, по qr-коду)
*/
export enum PaymentSpecialName {
  SKINS = 'skins',
  GIFT = 'giftcards',
  WALLET_NUMBER = 'wallet_number',
  ITEM_PAY = 'itempay',
}
/**
  Валюты обменников (используется в пополнениях через qr-код).
*/
export enum PaymentExchangeCurrency {
  BUSD = 'busd',
  USDC = 'usdc',
  USDT_ERC_20 = 'usdt_erc20',
  USDT_TRC_20 = 'usdt_trc20',
  MATIC = 'matic',
  ETH = 'ethereum',
  TRX = 'tron',
  DASH = 'dash',
  BCH = 'bitcoin_cash',
  DAI = 'dai',
}

/**
  Выбранная валюта пополнения (валюта пополнения/валюта сайта).
*/
export enum RefillTargetCurrency {
  REAL = 'real',
  LOCAL = 'local',
}

/**
  Статус платежа (используется в истории)
*/
export enum PaymentStatus {
  CREATED = 'created',
  PENDING = 'pending',
  COMPLETE = 'complete',
  REJECTED = 'rejected',
}

export enum PayFeeType {
  BONUS = 'bonus',
  COMMISSION = 'commission',
}
