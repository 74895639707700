<div
  *ngIf="data$ | async as data"
  class="modal-dialog modal-content auction-complete-modal-component"
  role="document"
>
  <div class="auction-complete-container">
    <div class="auction-complete-title">{{ 'P2P_WIDGETS.AUCTION_COMPLETED_DIALOG.AUCTION_COMPLETED' | translate }}</div>
    <div class="auction-complete-item-container">
      <div class="auction-complete-item-image-container">
        <img
          class="auction-complete-item-image"
          skinItemImage
          [src]="data.baseItem.icon"
          alt=""
        />
      </div>
      <ng-container *ngIf="data.baseItem.name; else noNameTemplate">
        <div class="auction-complete-item-name">{{ data.baseItem.name }}</div>
      </ng-container>
      <ng-template #noNameTemplate>
        <div class="auction-complete-item-float">
          {{ data.baseItem.exterior }}
        </div>
        <div class="auction-complete-item-name">{{ data.baseItem.shortName }} | {{ data.baseItem.skin }}</div>
      </ng-template>

      <div class="auction-complete-item-price">{{ data.sellerPrice | appCurrency }}</div>
    </div>
    <div class="auction-complete-alert-header">
      {{ 'P2P_WIDGETS.AUCTION_COMPLETED_DIALOG.ALERT_TITLE' | translate }}
    </div>
    <div class="auction-complete-alert-description">
      {{ 'P2P_WIDGETS.AUCTION_COMPLETED_DIALOG.ALERT_DESCRIBE' | translate }}
    </div>
    <div class="auction-complete-steam-container">
      <div class="auction-complete-seller-container">
        <div class="auction-complete-seller-about">
          <app-ui-user-avatar
            *ngIf="data.order"
            [url]="data.order.buyerSteamAvatar | replaceAvaHash"
          >
          </app-ui-user-avatar>

          <div class="auction-complete-seller-nickname mobile-display-none">{{ data.order?.buyerSteamName }}</div>
        </div>
        <div class="auction-complete-seller-data">
          <span class="tw-mr-2 text-muted"> {{ 'P2P_WIDGETS.AUCTION_COMPLETED_DIALOG.LEVEL' | translate }}:</span>
          {{ data.order?.buyerSteamLevel }}
        </div>
        <div class="auction-complete-seller-data">
          <span class="tw-mr-2 text-muted created-at mobile-display-none">
            {{ 'P2P_WIDGETS.AUCTION_COMPLETED_DIALOG.REGISTRATION_DATE' | translate }} :</span
          >
          {{ data.order?.buyerSteamCreatedAt | date: 'd MMM, y' }}
        </div>
      </div>
      <div class="auction-complete-steamuser-container">
        <div class="auction-complete-steamuser-panel">
          <div class="auction-complete-steamuser-panel-burger">
            <span></span>
            <span></span>
            <span></span>
          </div>
          STEAM.APP
        </div>
        <div class="auction-complete-steamuser-wrapper">
          <div class="auction-complete-steamuser-data">
            <div class="auction-complete-steamuser-level">
              <div class="auction-complete-steamuser-level-wrapper">{{ data.order?.buyerSteamLevel }}</div>
            </div>
            <div class="auction-complete-steamuser-name">{{ data.order?.buyerSteamName }}</div>
          </div>
          <div class="auction-complete-steamuser-data">
            <div class="auction-complete-steamuser-holder"></div>
            <div class="auction-complete-steamuser-date">{{ data.order?.buyerSteamCreatedAt | date: 'd MMM, y' }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="auction-complete-timer-container">
      <div class="auction-complete-timer-description">
        <span class="text-muted">
          {{ 'P2P_WIDGETS.AUCTION_COMPLETED_DIALOG.AUCTION_UNTIL_TIME' | translate }}
          <span class="auction-complete-timer-description-value text-white"
            ><ng-container *ngIf="offerTimer$ | async as offerTimer">
              {{ offerTimer | date: 'mm:ss' }}
            </ng-container></span
          >
        </span>
      </div>
      <div class="auction-complete-timer-progress">
        <!-- <div
          class="until-time-progress__line"
          [ngStyle]="{
            'animation-duration': (offerProgress$ | async) + 'ms',
            transform: 'translate3d(-' + (initialProgress$ | async) + '%, 0, 0)'
          }"
        ></div> -->
      </div>
      <div class="until-time-progress">
        <div
          class="until-time-progress__line"
          [ngStyle]="{
            'animation-duration': (offerProgress$ | async) + 'ms',
            transform: 'translate3d(-' + (initialProgress$ | async) + '%, 0, 0)'
          }"
        ></div>
      </div>
      <!-- <div class="auction-complete-timer-progress">
        <div class="auction-complete-timer-progress-bar"></div>
      </div> -->
    </div>
    <ng-container *ngIf="data.order && { timer: offerTimer$ | async } as offerInfo">
      <!-- {{offerInfo.timer }} -->
      <a
        rel="noopener noreferrer"
        target="_blank"
        [href]="data.order.buyerTradeLink"
        class="btn btn-primary auction-complete-button"
        [class.disabled]="offerInfo.timer && offerInfo.timer < 59999"
        >{{ 'P2P_WIDGETS.AUCTION_COMPLETED_DIALOG.SEND_TRADE' | translate }}
      </a>
    </ng-container>
    <div class="auction-complete-profit-container">
      <div class="auction-complete-profit-description text-white">
        {{ 'P2P_WIDGETS.AUCTION_COMPLETED_DIALOG.YOU_WILL_GET' | translate }}:
      </div>
      <div class="auction-complete-profit-description text-primary">{{ data.sellerPrice | appCurrency }}</div>
    </div>
  </div>
  <div
    class="auction-attention-container"
    *ngIf="data.order && data.order.steamItemPosition && data.order.steamItemPosition.length"
  >
    <div class="auction-attention-item-container">
      <div class="auction-attention-item-description">{{ 'P2P_WIDGETS.AUCTION_COMPLETED_DIALOG.POSITION_HEADER' | translate }}:</div>
      <div class="auction-attention-item-header">
        {{ data.order.steamItemPosition[0] }}
        {{ 'P2P_WIDGETS.DEPOSITING.ITEM_LOCATION_PHRASE_1' | translate }}
        {{ data.order.steamItemPosition[1] }}
        {{ 'P2P_WIDGETS.DEPOSITING.ITEM_LOCATION_PHRASE_2' | translate }}
        {{ data.order.steamItemPosition[2] }} *
      </div>
      <div class="auction-attention-pages">
        <div
          class="auction-attention-page"
          *ngFor="let page of calcPages(data.order.steamItemPosition)"
          [class.active]="page === data.order.steamItemPosition[0]"
        >
          {{ page }}
        </div>
      </div>
      <div class="auction-attention-items-container">
        <div
          class="auction-attention-item"
          *ngFor="let card of cardsBulk; index as i"
          [class.active]="data.order.steamItemPosition[1] * 4 - (4 - data.order.steamItemPosition[2]) - 1 === i"
        >
          <mat-icon
            *ngIf="data.order.steamItemPosition[1] * 4 - (4 - data.order.steamItemPosition[2]) - 1 !== i"
            class="icon-item-mock"
            svgIcon="item-mock"
          ></mat-icon>
          <img
            *ngIf="data.order.steamItemPosition[1] * 4 - (4 - data.order.steamItemPosition[2]) - 1 === i"
            [src]="data.baseItem.icon"
            alt=""
          />
        </div>
      </div>

      <div class="auction-attention-item-tip">* {{ 'P2P_WIDGETS.AUCTION_COMPLETED_DIALOG.POSITION_FOOTER' | translate }}</div>
    </div>
    <h2 class="auction-attention-header">
      <!-- <svg rv-svg-use="'balance-attention'"></svg> -->
      <mat-icon
        class="icon-balance-attention"
        svgIcon="balance-attention"
      ></mat-icon>
      <span> {{ 'P2P_WIDGETS.AUCTION_COMPLETED_DIALOG.POSITION_ATTENTION_HEADER' | translate }} </span>
    </h2>
    <p class="auction-attention-description">
      {{ 'P2P_WIDGETS.PURCHASE_TRADE_DIALOG.ALERT_TITLE' | translate }}
      {{ 'P2P_WIDGETS.PURCHASE_TRADE_DIALOG.ALERT_DESCRIBE' | translate }}
    </p>
  </div>
</div>
