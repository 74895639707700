import { CrossServices } from '../enums';

export interface LegacyGameState {
  players: any[];
  itemsNum: number;
  tradesNum: number;
  lastUpdate: number;
  trades: Trade[];
  prize: number;
  timeOldEnds: number | null;
  md5: string;
  rand: string | null;
  tape: string[] | null;
  number: number;
  winner: number | null;
  title?: string;
}

export interface LegacyLiteGameState {
  timer: number;
  freeze: boolean;
}

/** todo для новых игр тоже*/
export interface ITradeItem {
  canTake: boolean;
  color: string;
  icon: string;
  name: string;
  price: number;
  //todo допривести
  source: 'real';
  tickets: [number, number];
  //todo допривести
  type: 'steam';
}
/** todo для новых игр тоже*/
export interface Trade {
  betId: number;
  id: number;
  isNewClient: boolean;
  items: Record<CrossServices, ITradeItem[]>;
  itemsNum: number;
  original: TradeOriginalItem[];
  original_game_id: number;
  original_is_real: boolean;
  playerId: number;
  playerImage: string;
  playerName: string;
  steamid64: string;
  tickets: [number, number];
  totalPrice: number;
}
export interface TradeOriginalItem {
  isInventory: boolean;
  selectedGameId: CrossServices;
  inventoryItemId: number;
  userInventoryItemId: number;
  name: string;
  icon: string;
  price: number;
  color: string;
}
export interface LegacyGameConfig {
  id: number;
  name: 'classic' | 'fast';
  min_bet: number;
  max_bet: number | null;
  timeout_ms: number | null;
  roulette_ms: number;
  endgame_ms: number | null;
  max_items: number | null;
  max_players: number | null;
  max_items_per_trade: number;
  min_items_per_trade: number | null;
  min_item_price: null;
  allowSkinsFromGames: number[];
  betSources: any[];
  defaultSource: string;
  limit: Record<
    string,
    {
      allowed: boolean;
      maxBet?: number;
    }
  >;
  minAccountType: string;
  enabled: boolean;
  title: string;
  description: string;
  mode: 'pvp';
}
