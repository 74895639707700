import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, DestroyRef, ElementRef, inject, Input, OnInit, ViewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatIconModule } from '@angular/material/icon';
import { debounceTime, Subject, tap, throttleTime } from 'rxjs';

@Component({
  selector: 'app-ui-confetti',
  templateUrl: './confetti.component.html',
  styleUrls: ['./confetti.component.scss'],
  standalone: true,
  imports: [NgIf, MatIconModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UIConfettiSAComponent implements OnInit {
  readonly destroyRef = inject(DestroyRef);

  @Input() anim$: Subject<void> = new Subject();
  @Input() icon: string | undefined;
  @Input() settings = {
    numConfetti: 150,
    distance: 75,
    elemSize: 0.75,
    colors: ['#fea239', '#fbc531'],
  };

  @ViewChild('confettiContainer') readonly confettiContainer!: ElementRef<HTMLDivElement>;

  #clientWidth = 100;

  ngOnInit(): void {
    this.anim$
      .pipe(
        debounceTime(100),
        throttleTime(2000),
        tap(() => {
          this.#clientWidth = this.confettiContainer.nativeElement.clientWidth;
          const containerRect = this.confettiContainer.nativeElement.getBoundingClientRect();
          for (let i = 0; i < this.settings.numConfetti; i++) {
            const confetti = document.createElement('div');
            confetti.style.opacity = '0';
            confetti.style.left = `${containerRect.width / 2}px`;
            confetti.style.top = `${containerRect.height / 2}px`;
            this.confettiContainer.nativeElement.appendChild(confetti);

            setTimeout(() => this.#animateConfetti(confetti), 10);
          }
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();
  }

  #getRandomArrayItem(array: any[]): any {
    return array[Math.floor(Math.random() * array.length)];
  }

  #getRandomInt(min: number, max: number): number {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  #getRotation(): number {
    return Math.floor(Math.random() * 360);
  }

  #animateConfetti(confetti: HTMLElement): void {
    const endPosition = this.#calculateEndPosition();
    confetti.style.transition = `all ${this.#getRandomFloat(2.5, 0.5)}s ease-in-out`;
    confetti.style.left = endPosition.x + 'px';
    confetti.style.top = endPosition.y + 'px';
    confetti.style.transform = `rotate(${this.#getRotation()}deg) scale(0)`;
    confetti.style.position = 'absolute';
    confetti.style.height = `${this.settings.elemSize}rem`;
    confetti.style.width = `${this.settings.elemSize}rem`;
    confetti.style.opacity = '0.8';
    confetti.style.zIndex = '10';
    confetti.style.backgroundColor = this.#getRandomArrayItem(this.settings.colors);
    confetti.style.borderRadius = '2px';

    confetti.className = `element ${this.#getRandomArrayItem(this.settings.colors)}`;

    confetti.addEventListener('transitionend', () => {
      confetti.remove();
    });
  }

  #calculateEndPosition(): { x: number; y: number } {
    const maxY = this.#clientWidth + this.settings.distance;
    const minY = -this.settings.distance;
    const maxX = this.#clientWidth + this.settings.distance;
    const minX = -this.settings.distance;
    return {
      x: this.#getRandomInt(minX, maxX),
      y: this.#getRandomInt(minY, maxY),
    };
  }

  #getRandomFloat(min: number, max: number): number {
    return Math.random() * (max - min) + min;
  }
}
