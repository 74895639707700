<div
  class="game-break-container"
  clickStop
  (click)="goBack()"
>
  <mat-icon
    class="error-icon"
    [svgIcon]="'error'"
  ></mat-icon>
  <div class="discriprion">
    {{ 'LOCAL.CASES.MODAL.TECHNICAL_ERROR' | translate }}
  </div>
</div>
