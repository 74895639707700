import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { LangRouterModule } from '@app/shared/directives';

@Component({
  selector: 'app-ui-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss', './footer.component.media.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterComponent {
  // public ngOnDestroy(): void {
  //   console.log('destroy')
  // }
  //
  // public ngOnInit(): void {
  //   console.log('init')
  //
  // }
  // public ngAfterViewInit(): void {
  //   // init antillephone
  //   // @ts-ignore
  //   // apg_fea4d2c6_3a5c_41c6_87d8_b62705c91616.init()
  // }
}

@NgModule({
  imports: [CommonModule, RouterModule, LangRouterModule],
  declarations: [FooterComponent],
  exports: [FooterComponent],
})
export class FooterModule {}
