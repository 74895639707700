import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, NgModule, Output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { HistoryItem, MONTH } from '@dev-fast/types';
import { TranslateModule } from '@ngx-translate/core';
import { InViewportAction, InViewportModule } from 'ng-in-viewport';
import { NgScrollbarModule } from 'ngx-scrollbar';

import { CurrencyComponent } from '@app/core/currency';
import { AppCurrencyModule, HashPipe } from '@app/shared/pipes';

import { NotFoundStubModule } from '../not-found-stub/not-found-stub.module';
import { HistoryItemComponent } from './history-item/history-item.component';

@Component({
  selector: 'app-ui-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HistoryComponent {
  @Input() set items(value: HistoryItem[]) {
    const items = this.formatHistoryItems(value);
    this.formatedItems = Object.values(items);
    this.historyDates = Object.keys(items);
  }
  @Input() loading: boolean | null = false;
  @Output() private scrollToBottom: EventEmitter<void> = new EventEmitter<void>();
  @Output() private clickItem: EventEmitter<void> = new EventEmitter<void>();
  formatedItems!: HistoryItem[][];
  historyDates: string[] = [];

  onScrollToBottom(event: InViewportAction): void {
    if (event.visible) {
      this.scrollToBottom.emit();
    }
  }
  onClickItem(): void {
    this.clickItem.emit();
  }
  trackingFunction(index: number, item: HistoryItem): Date {
    return item.createdAt;
  }
  trackingFunctionDates(index: number, item: string): number {
    return index;
  }
  private formatHistoryItems(payload: HistoryItem[]): Record<string, HistoryItem[]> {
    const history = {} as Record<string, HistoryItem[]>;
    [...payload]
      .sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
      .forEach((item) => {
        const dateKey = `${new Date(item.createdAt).getDate()} ${MONTH[new Date(item.createdAt).getMonth()]}`;
        history[dateKey] = history[dateKey] ? [...history[dateKey], item] : [item];
      });
    return history;
  }
}
@NgModule({
  declarations: [HistoryItemComponent, HistoryComponent],
  exports: [HistoryComponent],
  imports: [
    CommonModule,
    MatIconModule,
    NotFoundStubModule,
    TranslateModule,
    AppCurrencyModule,
    TranslateModule,
    NgScrollbarModule,
    InViewportModule,
    HashPipe,
    MatProgressSpinnerModule,
    CurrencyComponent,
  ],
})
export class HistoryModule {}
