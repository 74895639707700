import { Inject, Injectable, makeStateKey, Optional, TransferState } from '@angular/core';
import { GameService } from '@dev-fast/backend-services';
import {
  GameMode,
  GAMES,
  GameStatusesFF,
  IActivities,
  IGame,
  IGameSettings,
  IInventoryItem,
  IUserDetailed,
  IUserShort,
  LegacyGameConfig,
  LegacyGameState,
  LegacyLiteGameState,
  NewPanel,
  TradeOriginalItem,
} from '@dev-fast/types';
import { RouterNavigation } from '@ngxs/router-plugin';
import { Action, NgxsOnInit, Selector, State, StateContext, Store } from '@ngxs/store';
import { reduce } from 'lodash-es';
import { Observable } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

import { AnalyticsService } from '@app/core/analytics-service';
import { EnvironmentService } from '@app/core/environment-service';
import { FrameMessageTypes, IFrameMessageService } from '@app/core/iframe';
import { LocalStorageService } from '@app/core/local-storage-service';
import { ChangeParamsInventory, InventoryLegacyState } from '@app/core/state/inventory-legacy';
import { LayoutState, ToggleNewPanel } from '@app/core/state/layout';
import { UserState } from '@app/core/state/user-store';
import { addEventToGleam, RouterStateParams } from '@app/core/state/utils';
import { IS_SERVER_TOKEN } from '@app/shared/utils';

import {
  ChangeConfigLegacy,
  ChangeLiteStateLegacy,
  ChangeStateLegacy,
  ConfirmParticipateLegacy,
  ConfirmParticipateLegacySuccess,
  FindGameSettings,
  GetAllGamesSettings,
  ParticipateLegacy,
  PlaceBetLegacy,
  SetCurrentGame,
  SetCurrentGameActivities,
  StartAutoBetLegacy,
  StopAutoBetLegacy,
  SuccessfulBid,
  UpdStatuses,
} from './games.actions';
// import { ICasesStateModel } from "../../../../../games/src/lib/cases/state";
import { GAMES_INITIAL_STATE, GamesStateModel } from './games-state.model';

@State<GamesStateModel>({
  name: 'games',
  defaults: GAMES_INITIAL_STATE,
})
@Injectable()
export class GamesState implements NgxsOnInit {
  private readonly AVAILABLE_GAMES_KEY = makeStateKey('availableGames');

  constructor(
    private readonly apiService: GameService,
    private readonly store: Store,
    private readonly storage: LocalStorageService,
    private readonly environmentService: EnvironmentService,
    @Optional() private readonly frameMessageService: IFrameMessageService,
    private readonly analytics: AnalyticsService,
    private readonly transferState: TransferState,
    @Inject(IS_SERVER_TOKEN) private isServer: boolean,
  ) {}

  @Selector()
  static currentGameActivities({ currentGameActivities }: GamesStateModel): IActivities | null {
    return currentGameActivities;
  }
  @Selector()
  static currentGameSettings({ currentGame }: GamesStateModel): IGameSettings | null {
    // кост потому-что в легаси фасте минимальная ставка приходит в коинах, а нужно в центах
    const normalizeBetValues = (game: IGameSettings): { minBet: number; maxBet: number } => {
      const isFastGameShit = currentGame?.name === 'fast' && currentGame.minBet < 10;
      return {
        minBet: isFastGameShit ? game.minBet * 100 : game.minBet,
        maxBet: isFastGameShit ? game.maxBet * 100 : game.maxBet,
      };
    };
    return currentGame ? { ...currentGame, ...normalizeBetValues(currentGame) } : currentGame;
  }
  @Selector()
  static curLegacyGame({ curLegacyGame }: GamesStateModel): string | null {
    return curLegacyGame;
  }
  @Selector()
  static currentGame({ currentGame }: GamesStateModel): IGame | null {
    if (!currentGame) {
      return currentGame;
    }
    return GamesState.mapToIGame(currentGame, GAMES);
  }
  @Selector()
  static gamesList({ gamesList }: GamesStateModel): IGameSettings[] {
    return gamesList;
  }
  @Selector()
  static availableGames({ availableGames }: GamesStateModel): IGame[] {
    return availableGames;
  }
  @Selector()
  static lastGames({ lastGames }: GamesStateModel): IGame[] {
    return lastGames;
  }
  @Selector()
  static statuses({ statuses }: GamesStateModel): GameStatusesFF {
    return statuses;
  }
  @Selector()
  static autoBetIsOn({ autoBetIsOn }: GamesStateModel): boolean {
    return autoBetIsOn;
  }
  @Selector()
  static legacyConfig({ legacyConfig }: GamesStateModel): LegacyGameConfig | null {
    return legacyConfig;
  }
  @Selector()
  static legacyState({ legacyState }: GamesStateModel): LegacyGameState | null {
    return legacyState;
  }
  @Selector()
  static legacyLiteState({ legacyLiteState }: GamesStateModel): LegacyLiteGameState | null {
    return legacyLiteState;
  }
  @Selector()
  static legacyParticipation({ legacyParticipation }: GamesStateModel): TradeOriginalItem[] {
    return legacyParticipation;
  }
  @Selector([UserState.user])
  static me({ legacyState }: GamesStateModel, user: IUserDetailed | null): IUserShort | null {
    // такие проверки для подстраховки хз что придет из фрейма
    const player = user && legacyState && legacyState.players ? legacyState.players.find(({ id }) => id === user.id) : null;

    return player ?? null;
  }

  @Selector([GamesState.me])
  static myTradeItems({ legacyState }: GamesStateModel, me: IUserShort | null): TradeOriginalItem[] | null {
    // такие проверки для подстраховки хз что придет из фрейма
    const myTrade = me && legacyState && legacyState.trades ? legacyState.trades.filter(({ playerId }) => playerId === me.id) : null;

    return myTrade && myTrade.length ? reduce(myTrade, (acc: TradeOriginalItem[], trade) => acc.concat(trade.original), []) : null;
  }
  @Selector()
  static gameTitle(state: GamesStateModel) {
    return (key: string) => {
      const game = state.gamesList.find((el) => el.name === key);
      return game !== undefined ? (game.title ? game.title : `${key} game`) : `${key} game`;
    };
  }
  static mapToIGame(game: IGameSettings, localValues: IGame[]): IGame {
    const key = this.linksDictionary(game.name);
    return {
      key,
      mode: game.mode || GameMode.PVE,
      title: game.title || '',
      description: game.description,
      order: localValues.find((el) => el.key === key)?.order || 0,
    };
  }
  static linksDictionary(name: string): string {
    const dictionary: any = {
      wheel: 'fortune-wheel',
      bets: 'match-bets',
      case_battle: 'case-battle',
    };
    return dictionary[name] ? dictionary[name] : name;
  }
  ngxsOnInit({ dispatch, patchState }: StateContext<GamesStateModel>): void {
    const lastGames = this.storage.get('lastGames');
    if (lastGames) {
      patchState({
        lastGames: this.#_filterAvailableGames(lastGames),
      });
    }
    if (this.frameMessageService) {
      this.frameMessageService.on(
        FrameMessageTypes.MESSAGE_FROM_BB,
        'change.gameStatus',
        (payload: { statuses: GameStatusesFF }) => {
          const gameSelectorOpened = this.store.selectSnapshot(LayoutState.gameSelectorOpened);
          if (gameSelectorOpened) {
            dispatch(new UpdStatuses(payload.statuses));
          }
          //TODO fix it
          // this.store.dispatch(new UpdStatuses(payload.statuses));
        },
        900,
      );
      this.frameMessageService.on(FrameMessageTypes.MESSAGE_FROM_BB, 'participation', (payload: { gameName: 'classic' | 'fast' }) =>
        dispatch(new PlaceBetLegacy(payload.gameName)),
      );
      this.frameMessageService.on(FrameMessageTypes.MESSAGE_FROM_BB, 'SuccessfulBid', (payload: { gameName: string }) =>
        dispatch(new SuccessfulBid(payload.gameName)),
      );
      this.frameMessageService.on(FrameMessageTypes.MESSAGE_FROM_BB, 'startAutoBet', (payload: { gameName: 'x50' | 'crash' }) =>
        dispatch(new StartAutoBetLegacy(payload.gameName)),
      );
      this.frameMessageService.on(FrameMessageTypes.MESSAGE_FROM_BB, 'stopAutoBet', (payload: { gameName: 'x50' | 'crash' }) =>
        dispatch(new StopAutoBetLegacy(payload.gameName)),
      );
      this.frameMessageService.on(
        FrameMessageTypes.MESSAGE_FROM_BB,
        'ChangeStateLegacy',
        (payload: { state: LegacyGameState; gameName: 'classic' | 'fast' }) =>
          dispatch(new ChangeStateLegacy(payload.state, payload.gameName)),
      );
      this.frameMessageService.on(FrameMessageTypes.MESSAGE_FROM_BB, 'ChangeConfigLegacy', (payload: LegacyGameConfig) =>
        dispatch(new ChangeConfigLegacy(payload)),
      );
      this.frameMessageService.on(FrameMessageTypes.MESSAGE_FROM_BB, 'ChangeLiteStateLegacy', (payload: LegacyLiteGameState) =>
        dispatch(new ChangeLiteStateLegacy(payload)),
      );
      this.frameMessageService.on(
        FrameMessageTypes.MESSAGE_FROM_BB,
        'participated',
        (payload: { items: IInventoryItem[]; timeout: number }) =>
          dispatch([
            new ConfirmParticipateLegacy(payload),
            // new UnselectInventoryItemById(payload.items.map((el) => el.id)),
          ]),
      );
    }

    const availableGames = this.transferState.get(this.AVAILABLE_GAMES_KEY, [] as any);
    if (availableGames) {
      patchState({ availableGames });
    }
  }
  // FIXME один большой костыль. Я прям кайфану когда выпилю фрейм
  // с релизом ренью для легаси фаста еще больше костыльнул. На мой взгляд приоритет выпилить или переписать фаст с легаси
  @Action(ConfirmParticipateLegacy)
  confirmParticipateLegacy(
    { dispatch, patchState, getState }: StateContext<GamesStateModel>,
    { payload }: ConfirmParticipateLegacy,
  ): Observable<void> {
    const { curLegacyGame } = getState();
    return dispatch(new ConfirmParticipateLegacySuccess(payload)).pipe(
      // delay(payload.timeout * 1000),
      switchMap(() => dispatch([new ChangeParamsInventory({})])),
      tap(() => {
        if (curLegacyGame === 'fast') {
          patchState({
            legacyParticipation: payload.items,
          });
        }
      }),
    );
  }
  @Action(ToggleNewPanel) // TODO убрать когда выпилю фрейм
  toggleNewPanel({ getState }: StateContext<GamesStateModel>, { panel }: ToggleNewPanel): void {
    const { curLegacyGame } = getState();
    if (!this.frameMessageService || panel !== NewPanel.BET || curLegacyGame !== 'fast') {
      return;
    }

    this.frameMessageService.sendMessage({
      type: FrameMessageTypes.MESSAGE_TO_BB,
      eventName: 'toggleBetPanel',
      payload: {},
    });
  }
  @Action(RouterNavigation)
  navigate({ patchState, dispatch }: StateContext<GamesStateModel>, { routerState }: RouterNavigation<RouterStateParams>): void {
    const url = routerState.url;
    if (url.includes('game')) {
      const lastGames = this.storage.get('lastGames') ?? [];
      const gameKey = url.split('/')[2];
      const updatedLastGames = [gameKey, ...lastGames.filter((game) => game !== gameKey)].slice(0, 8);

      patchState({
        lastGames: this.#_filterAvailableGames(updatedLastGames),
      });
      this.storage.set('lastGames', updatedLastGames);
      // финт для легаси фаста
      if (url.includes('game/fast')) {
        patchState({ curLegacyGame: 'fast' });
        dispatch([new SetCurrentGame('fast')]);
      } else {
        patchState({ curLegacyGame: 'none' });
      }
    }
  }
  @Action(SetCurrentGameActivities)
  setCurrentGameActivities({ patchState }: StateContext<GamesStateModel>, { activities }: SetCurrentGameActivities): void {
    patchState({ currentGameActivities: activities });
  }
  @Action(ChangeConfigLegacy)
  changeConfigLegacy({ patchState }: StateContext<GamesStateModel>, { payload }: ChangeConfigLegacy): void {
    patchState({ legacyConfig: payload });
  }
  @Action(ChangeStateLegacy)
  changeStateLegacy({ patchState }: StateContext<GamesStateModel>, { payload }: ChangeStateLegacy): void {
    const user = this.store.selectSnapshot(UserState.user);
    const newItem = payload.trades.find((trade) => trade.playerId === user?.id);

    patchState({
      legacyLastGameNumber: payload.number,
      legacyState: payload,
      legacyParticipation: newItem ? newItem.original : [],
    });
  }
  @Action(ChangeLiteStateLegacy)
  changeLiteStateLegacy({ patchState }: StateContext<GamesStateModel>, { payload }: ChangeLiteStateLegacy): void {
    patchState({ legacyLiteState: payload });
  }
  @Action(StartAutoBetLegacy)
  startAutoBetLegacy({ patchState }: StateContext<GamesStateModel>, { gameName }: StartAutoBetLegacy): void {
    patchState({ curLegacyGame: gameName, autoBetIsOn: true });
  }
  @Action(StopAutoBetLegacy)
  stopAutoBetLegacy({ patchState }: StateContext<GamesStateModel>, { gameName }: StopAutoBetLegacy): void {
    patchState({ curLegacyGame: gameName, autoBetIsOn: false });
  }
  @Action(GetAllGamesSettings)
  get({ patchState }: StateContext<GamesStateModel>): Observable<IGameSettings[]> {
    return this.apiService.getAvailableGames().pipe(
      tap((response) => {
        const mapedResponse = response
          .filter((el) => el.mode && el.enabled)
          .map((el) => GamesState.mapToIGame(el, GAMES))
          .sort((a, b) => (a.order || 1) - (b.order || 1));

        const availableGames = this.#_filterAvailableGames(mapedResponse.map((value) => value.key));

        if (this.isServer) {
          this.transferState.set(this.AVAILABLE_GAMES_KEY, [] as any);
        }

        patchState({ gamesList: response, availableGames });
      }),
    );
  }
  @Action(FindGameSettings)
  find({ patchState, getState }: StateContext<GamesStateModel>, { key }: FindGameSettings): Observable<IGameSettings> {
    const { gamesList } = getState();
    return this.apiService.getGameSettingsByKey(key).pipe(
      tap((response) => {
        patchState({ gamesList: [...gamesList, response], currentGame: response });
      }),
    );
  }
  @Action(SetCurrentGame)
  set({ patchState, dispatch, getState }: StateContext<GamesStateModel>, { key }: SetCurrentGame): void {
    const { gamesList } = getState();
    const currentGame = gamesList.find((game) => game.name === key);

    if (currentGame !== undefined) {
      patchState({
        currentGame,
      });
    } else {
      dispatch(new FindGameSettings(key));
    }
  }
  @Action(UpdStatuses, { cancelUncompleted: true })
  updStatuses({ patchState }: StateContext<GamesStateModel>, { statuses }: UpdStatuses): void {
    patchState({ statuses });
  }

  // TODO может вместо curLegacyGame сетить эти игр как и другие
  @Action(PlaceBetLegacy)
  placeBetLegacy({ patchState, dispatch }: StateContext<GamesStateModel>, { gameName }: PlaceBetLegacy): void {
    patchState({ curLegacyGame: gameName });
    // для открытия окна в легаси проекте, пока не удалять потому-что на сегодня он еще в проде 29,02,2024
    // dispatch(new ChangeActivePanel({ panel: Panel.INVENTORY, subPanel: SubPanel.BET }));
    dispatch(new ToggleNewPanel(NewPanel.BET));
  }
  @Action(ParticipateLegacy)
  participateLegacy(context: StateContext<GamesStateModel>, { items }: ParticipateLegacy): void {
    if (!this.frameMessageService) {
      return;
    }
    const selectedItems = items ? items : this.store.selectSnapshot(InventoryLegacyState.selectedItems);

    this.frameMessageService.sendMessage({
      type: FrameMessageTypes.MESSAGE_TO_BB,
      eventName: 'betLegacyGame',
      payload: { items: selectedItems.map((el) => ({ ...el, type: 'skin' })) },
    });
  }
  @Action(SuccessfulBid)
  successfulBid(ctx: StateContext<GamesStateModel>, { gameName }: SuccessfulBid): void {
    const user = this.store.selectSnapshot(UserState.user);
    if (user && user.id) {
      addEventToGleam(gameName, user.id);
      this.analytics.betEvent(gameName);
    }
  }
  #_getEnabledModules(): IGame[] {
    const env = this.environmentService.getEnvironment();
    return GAMES.filter((game) => env.MODULES.games?.includes(game.key));
  }
  #_filterAvailableGames(games: string[]): IGame[] {
    const currentGames = this.#_getEnabledModules();
    return games.map((gameKey) => currentGames.find((el) => el.key === gameKey)).filter((el) => el !== undefined) as IGame[];
  }
}
