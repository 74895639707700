import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ChatApiService, UserApiService } from '@dev-fast/backend-services';
import { IChatAccess, IMessage, IStickerPack } from '@dev-fast/types';
import { Action, NgxsOnInit, Selector, State, StateContext, Store } from '@ngxs/store';
import { append, patch, removeItem } from '@ngxs/store/operators';
import { catchError, Observable, of, tap } from 'rxjs';

import { EnvironmentService } from '@app/core/environment-service';
import { SetLanguage } from '@app/core/language-service';
import { NotificationsService } from '@app/core/notification-service';

import {
  CheckCanChat,
  CreateMsgEventHandling,
  DeleteMsgEventHandling,
  GetMessagesList,
  SendMessage,
  SetActiveStickerPack,
  SetChatID,
} from './chat.actions';
import { CHAT_INITIAL_STATE, ChatStateModel } from './chat-state.model';

@State<ChatStateModel>({
  name: 'chat',
  defaults: CHAT_INITIAL_STATE,
})
@Injectable()
export class ChatState implements NgxsOnInit {
  @Selector()
  static items({ items }: ChatStateModel): IMessage[] {
    return items;
  }
  @Selector()
  static chatId({ chatId }: ChatStateModel): string {
    return chatId;
  }
  @Selector()
  static stickers({ stickers }: ChatStateModel): IStickerPack[] {
    return stickers;
  }
  @Selector()
  static chatAccess({ chatAccess }: ChatStateModel): IChatAccess {
    return chatAccess;
  }
  @Selector()
  static activeStickerPack({ activeStickerPack }: ChatStateModel): IStickerPack {
    return activeStickerPack;
  }

  constructor(
    private readonly _store: Store,
    private readonly _api: ChatApiService,
    private readonly _userApi: UserApiService,
    private readonly _environmentService: EnvironmentService,
    private readonly _notyService: NotificationsService,
  ) {}
  ngxsOnInit(): void {
    this._api.messageCreatedEvent((msg: IMessage) => this._store.dispatch(new CreateMsgEventHandling(msg)));
    this._api.messageDeletedEvent((msg: IMessage) => this._store.dispatch(new DeleteMsgEventHandling(msg)));
  }

  @Action(SetLanguage)
  setLanguage({ dispatch }: StateContext<ChatStateModel>, { locale }: SetLanguage): Observable<void> {
    // const prefix = this._environmentService.environments.PROJECT === 'csgofast' ? 'fast_' : '';
    // const chatId = prefix + locale.path.split('_')[0];
    const chatId = locale.path.split('_')[0];
    const normChatId = ['en', 'ru'].includes(chatId) ? chatId : 'other';
    // FIXME при ините не нужен
    return dispatch([new SetChatID(normChatId), new GetMessagesList()]);
  }

  @Action(SendMessage)
  send({ getState, dispatch }: StateContext<ChatStateModel>, { payload }: SendMessage): Observable<void | HttpErrorResponse> {
    const { chatId } = getState();
    const { game, value } = payload;
    return this._api.addMessage(chatId, { value, game }).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 400) {
          dispatch(new CheckCanChat());
          this._notyService.addErrorNotification(error.error.message);
        }
        return of(error);
      }),
    );
  }

  @Action(GetMessagesList)
  getAll({ getState, patchState }: StateContext<ChatStateModel>): void {
    const { chatId } = getState();
    return this._api.getMessagesList(chatId, (msgs: IMessage[]) => {
      patchState({ items: msgs });
    });
  }

  @Action(CheckCanChat)
  checkCanChat({ patchState }: StateContext<ChatStateModel>): Observable<IChatAccess> {
    return this._userApi.checkCanChat().pipe(tap((response: IChatAccess) => patchState({ chatAccess: response })));
  }

  @Action(CreateMsgEventHandling)
  createdNew({ setState }: StateContext<ChatStateModel>, { payload }: CreateMsgEventHandling): void {
    setState(patch({ items: append<IMessage>([payload]) }));
  }

  @Action(DeleteMsgEventHandling)
  remove({ setState }: StateContext<ChatStateModel>, { payload }: DeleteMsgEventHandling): void {
    setState(patch({ items: removeItem<IMessage>((x) => x?.id === payload.id) }));
  }

  @Action(SetActiveStickerPack)
  setStickerPack({ patchState, getState }: StateContext<ChatStateModel>, { name }: SetActiveStickerPack): void {
    const { activeStickerPack, stickers } = getState();
    if (name === activeStickerPack.name) {
      return;
    }
    const pack = stickers.find((collection: IStickerPack) => collection.name === name) || activeStickerPack;
    patchState({ activeStickerPack: pack });
  }

  @Action(SetChatID)
  setChatId({ patchState }: StateContext<ChatStateModel>, { chatId }: SetChatID): void {
    patchState({ chatId });
  }

  private validateMessage(message: IMessage): boolean {
    return typeof message.value === 'string' || true;
  }
}
