import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, forwardRef, Input, NgModule, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { RouterModule } from '@angular/router';
import { IFilterMethod } from '@dev-fast/types';
import { TranslateModule } from '@ngx-translate/core';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { NgScrollbarReachedModule } from 'ngx-scrollbar/reached-event';

import { CurrencyComponent } from '@app/core/currency';
import { AppCurrencyModule } from '@app/shared/pipes';

@Component({
  selector: 'app-ui-filter-by-method',
  templateUrl: './filter-by-method.component.html',
  styleUrls: ['./filter-by-method.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FilterByMethodComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterByMethodComponent<T> implements ControlValueAccessor {
  @Input() translatePath = '';
  @Input() componentView: 'default' | 'compact' = 'default';
  @Input() sortMethods: IFilterMethod<T>[] = [];
  @Input() set selectedValue(val: T | null | undefined) {
    this.changedSortMethodIndex = this.changedSelectedValue(val);
  }
  @Output() changeMethod: EventEmitter<IFilterMethod<T>> = new EventEmitter<IFilterMethod<T>>();

  changedSortMethodIndex = 0;
  constructor(private readonly _cdr: ChangeDetectorRef) {}
  private onTouched = (): void => {
    //
  };
  private onChange: (value: T | null | undefined) => void = (): void => {
    //
  };
  private changedSelectedValue = (value: T | null | undefined): number =>
    value && this.sortMethods.length ? this.sortMethods.findIndex((el) => el.value === value) : 0;

  compactChangeSortMethod(): void {
    this.changeSortMethod(this.changedSortMethodIndex ? this.sortMethods[0] : this.sortMethods[1]);
    this.changedSortMethodIndex = this.changedSortMethodIndex ? 0 : 1;
  }
  changeSortMethod(method: IFilterMethod<T>): void {
    this.changeMethod.emit(method);
    if (this.onChange) {
      this.onChange(method.value);
    }
    this._cdr.markForCheck();
  }
  registerOnChange(onChange: (value: T | null | undefined) => void): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: () => void): void {
    this.onTouched = onTouched;
  }

  setDisabledState(isDisabled: boolean): void {
    //
  }

  writeValue(obj: T | null | undefined): void {
    this.changedSortMethodIndex = this.changedSelectedValue(obj);
    this._cdr.markForCheck();
  }
}
@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule,
    CurrencyComponent,
    AppCurrencyModule,
    NgScrollbarModule,
    NgScrollbarReachedModule,
    MatMenuModule,
    MatIconModule,
  ],
  declarations: [FilterByMethodComponent],
  exports: [FilterByMethodComponent],
})
export class FilterByMethodModule {}
