import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, NgModule, Output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { IInventoryItem, ISkinItem, LegacyGameConfig, LegacyGameState, LegacyLiteGameState, SkinClickEvent } from '@dev-fast/types';
import { TranslateModule } from '@ngx-translate/core';

import { AppCurrencyModule } from '@app/shared/pipes';

import { CountdownComponentModule } from '../countdown/countdown.component';
import { SkinItemModule } from '../skin-item/skin-item.component';

@Component({
  selector: 'app-ui-bet-panel-legacy',
  templateUrl: './bet-panel-legacy.component.html',
  styleUrls: ['./bet-panel-legacy.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BetPanelLegacyComponent {
  @Input() public selectedItems: IInventoryItem[];
  @Input() public config: LegacyGameConfig | undefined | null;
  @Input() public state: LegacyGameState | undefined | null;
  @Input() public participatedItems: ISkinItem[] | undefined | null;
  @Input() public liteState: LegacyLiteGameState | undefined | null;
  //FIXME мммм хуета
  private _participated:
    | {
        items: IInventoryItem[];
        timeout: number;
      }
    | undefined;
  @Input() public set participated(
    val:
      | {
          items: IInventoryItem[];
          timeout: number;
        }
      | undefined
  ) {
    if (val) {
      this._participated = val;
      setTimeout(() => {
        this._participated = undefined;
      }, val?.timeout * 1000);
    }
  }
  public get participated():
    | {
        items: IInventoryItem[];
        timeout: number;
      }
    | undefined {
    return this._participated;
  }
  @Output() public participate: EventEmitter<void>;
  @Output() public clean: EventEmitter<number[]>;
  @Output() public clickOnSkinEmit: EventEmitter<SkinClickEvent> = new EventEmitter<SkinClickEvent>();
  // public participateAccepted$: Observable<boolean>;

  public get participatedTimer(): number {
    return this.participated ? this.participated.timeout * 1000 : 0;
  }
  // public setDelay(item: IInventoryItem): number | null {
  //   if (this.participated && this.participated.items.some((el) => el.id === item.id)) {
  //     return this.participated.timeout * 1000;
  //   }
  //   return null;
  // }
  public get totalPrice(): number {
    return this.selectedItems.reduce((a, b) => a + b.price, 0);
  }
  public get minItemsPerTrade(): unknown[] {
    if (this.config && this.config.min_items_per_trade) {
      return new Array(this.config.min_items_per_trade);
    } else {
      return [];
    }
  }
  public get maxItemsPerTrade(): unknown[] {
    if (this.config && this.config.max_items_per_trade) {
      return new Array(this.config.max_items_per_trade - (this.config.min_items_per_trade ? this.config.min_items_per_trade : 0));
    } else {
      return [];
    }
  }
  public get participationDisabled(): boolean {
    if (this.config) {
      return (
        this.selectedItems.length < (this.config.min_items_per_trade ? this.config.min_items_per_trade : 1) ||
        this.selectedItems.length > this.config.max_items_per_trade ||
        this.totalPrice < this.config.min_bet * 100 ||
        (!!this.config.max_bet && this.totalPrice > this.config.max_bet * 100)
      );
    }
    return true;
  }
  public get clearDisabled(): boolean {
    if (this.participated && this.selectedItems.length) {
      return this.participated.items.some((el) => this.selectedItems.map((item) => item.id).includes(el.id));
    } else if (this.selectedItems.length) {
      return false;
    }
    return true;
  }
  constructor() {
    this.selectedItems = [];
    // this.participateAccepted$ = of(true).pipe(delay(this.participatedTimer));
    this.participate = new EventEmitter(false);
    this.clean = new EventEmitter(false);
  }

  public onClear(): void {
    this.clean.next(this.selectedItems.map((item) => item.id));
  }
  public trackingFunction(index: number, item: { id: number }): number {
    return item.id;
  }
  public onParticipate(): void {
    this.participate.emit();
  }
  public setupCountdown(): string {
    return '00:55';
  }
}

@NgModule({
  imports: [CommonModule, MatIconModule, SkinItemModule, AppCurrencyModule, TranslateModule, CountdownComponentModule],
  declarations: [BetPanelLegacyComponent],
  exports: [BetPanelLegacyComponent],
})
export class BetPanelLegacyComponentModule {}
