import { Injectable } from '@angular/core';
import { ISteamStoreInventory } from '@dev-fast/types';
import { Select } from '@ngxs/store';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { Observable } from 'rxjs';

import { Environments, EnvironmentService } from '@app/core/environment-service';

import { GetSteamInventory, P2pCommonState } from '../states';

@Injectable()
export class P2pCommonService {
  @Select(P2pCommonState.steamInventory)
  public steamItems$!: Observable<ISteamStoreInventory[]>;

  private _environment: Environments;

  constructor(private readonly environmentService: EnvironmentService) {
    this._environment = this.environmentService.environments;
  }

  @Dispatch() public getSteamInventory = (): GetSteamInventory => new GetSteamInventory();
}
