import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ICaseRevisionItem } from '@dev-fast/types';

import { colorDictionary, getMaxOfArray, getMinOfArray, SKIN_RARITY_V2, toShortRevisionWear } from '@app/shared/utils';

@Component({
  selector: 'app-ui-possible-card',
  templateUrl: './possible-card.component.html',
  styleUrls: ['./possible-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PossibleCardComponent {
  @Input() index = 0;
  @Input() showChance = true;
  @Input() set itemRevisions(items: ICaseRevisionItem[]) {
    this.revisions = items;
    this.countSum = items.reduce((a, b) => a + b.count, 0);
  }
  @Input() tracksAmount = 1;
  @Input() isHovered = true;
  @Input() isNewPalette = false;
  @Input() cardClass = '';
  @Output() clickOnCard: EventEmitter<boolean> = new EventEmitter(false);

  showRevisions = false;
  countSum = 0;
  revisions: ICaseRevisionItem[] = [];
  cardBackground = '';

  toggleDropdown(event: Event): void {
    event.stopPropagation();
    this.showRevisions = !this.showRevisions;
  }
  onCard(): void {
    this.clickOnCard.emit(true);
  }
  setCardBackground(isNewPalette: boolean, itemRevisions: ICaseRevisionItem = this.revisions[0]): string {
    const colorDTO = itemRevisions.inventoryItem.baseItem.color;
    if (isNewPalette) {
      return SKIN_RARITY_V2[(itemRevisions.inventoryItem.baseItem.rarity ?? colorDTO).toLowerCase()].default;
    } else {
      return `linear-gradient(180.57deg, #213137 0%, ${colorDictionary[colorDTO.toUpperCase()]} 100%)`;
    }
  }

  getSkinPrice(itemRevisions: ICaseRevisionItem[]): { min?: number; max?: number; current: number } | null {
    if (itemRevisions.length === 1) {
      return itemRevisions[0].inventoryItem && itemRevisions[0].inventoryItem.price
        ? { current: itemRevisions[0].inventoryItem.price }
        : null;
    }
    if (itemRevisions.length > 1) {
      const arrPrices = itemRevisions.map((el) => el.inventoryItem.price).filter((val) => !!val);
      return {
        current: itemRevisions[0].inventoryItem.price,
        max: getMaxOfArray(arrPrices),
        min: getMinOfArray(arrPrices),
      };
    }
    return null;
  }
  toShortRevisionWear(wear: string): string {
    return toShortRevisionWear(wear);
  }
}
