export enum CardStatusEnum {
  SELECTED = 'SELECTED',
  PARTICIPATED = 'PARTICIPATED',
  TIMER = 'TIMER',
  DISABLED = 'DISABLED',
  DEFAULT = 'DEFAULT',
}
interface ICardStatusConfig {
  matIcon?: string;
  class: string;
}
type CardStatusConfigType = { [key in CardStatusEnum]?: ICardStatusConfig };

export const CardStatusConfig: CardStatusConfigType = {
  [CardStatusEnum.SELECTED]: {
    matIcon: 'checkmark-outline',
    class: 'selected',
  },
  [CardStatusEnum.PARTICIPATED]: {
    matIcon: 'dices',
    class: 'participated flex fx-all-center',
  },
  [CardStatusEnum.TIMER]: {
    class: 'timer flex fx-all-center',
  },
  [CardStatusEnum.DISABLED]: {
    class: 'disabled flex fx-all-center',
  },
};
