import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  BidCondition,
  IBaseItemDtoV2,
  IMarketplaceNameToShow,
  IP2pPurchaseItem,
  IUserP2pPermissionInterface,
  OrderStatusEnum,
  P2pItemAttribute,
  SocialType,
  SteamErrorsEnum,
} from '@dev-fast/types';
import moment from 'moment';
import { interval, Observable, timer } from 'rxjs';
import { filter, map, startWith, switchMap, takeUntil } from 'rxjs/operators';

import { P2pBuyingService } from '@app/modules/p2p';

import { P2pSetupModalComponent } from '../../modals';
import { P2pPurchaseTradeModalComponent } from '../../modals/p2p-purchase-trade-modal/p2p-purchase-trade-modal.component';
import { P2pPurchaseService } from '../p2p-purchase.service';

@Component({
  selector: 'app-p2p-purchase-item',
  templateUrl: './p2p-purchase-item.component.html',
  styleUrls: ['./p2p-purchase-item.component.scss', '../../styles/tooltip.scss', '../../styles/progress-line.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class P2pPurchaseItemComponent {
  @Input() id: number | undefined;
  readonly permissions$: Observable<IUserP2pPermissionInterface>;
  item$: Observable<IP2pPurchaseItem>;
  initialProgress$: Observable<string> | undefined;
  offerProgress$: Observable<number> | undefined;
  offerTimer$: Observable<number> | undefined;
  bidCondition$: Observable<BidCondition> | undefined;
  readonly bibConditions: typeof BidCondition;
  readonly steamErrors: typeof SteamErrorsEnum;
  readonly statuses: typeof OrderStatusEnum;
  readonly socialType: typeof SocialType;
  constructor(
    readonly dialog: MatDialog,
    private readonly p2pBuyingService: P2pBuyingService,
    private readonly moduleService: P2pPurchaseService,
  ) {
    this.permissions$ = moduleService.p2pPermissions$;
    this.statuses = OrderStatusEnum;
    this.steamErrors = SteamErrorsEnum;
    this.bibConditions = BidCondition;
    this.socialType = SocialType;
    this.item$ = this.p2pBuyingService.purchasing$.pipe(
      filter((items) => items.some((el) => el.id === this.id)),
      map((items) => items.find((el) => el.id === this.id)),
      filter((item): item is IP2pPurchaseItem => item !== undefined),
    );
    this.subscribeEmitters();
  }
  private skinStatus(baseItem: IBaseItemDtoV2): P2pItemAttribute | undefined {
    if (baseItem.name.includes('Souvenir')) {
      return P2pItemAttribute.SV;
    }
    if (baseItem.statTrak) {
      return P2pItemAttribute.ST;
    }
    return;
  }
  skinNameConstructor(baseItem: IBaseItemDtoV2): IMarketplaceNameToShow | undefined {
    if (!baseItem.shortName) {
      return;
    }
    const skinName: IMarketplaceNameToShow = { name: '' };
    skinName.attribute = this.skinStatus(baseItem);
    skinName.name = baseItem.shortName + (baseItem.skin ? ' | ' + baseItem.skin : '');
    return skinName;
  }
  bindingSocial(provider: SocialType): void {
    this.moduleService.bindProvider(provider);
  }

  createBid(id: number): void {
    this.p2pBuyingService.createBid(id);
  }

  confirm(id: number): void {
    this.p2pBuyingService.confirmBid(id);
  }

  onClose(idx: number): void {
    this.p2pBuyingService.close(idx);
  }

  onDeleted(idx: number): void {
    this.p2pBuyingService.deleted(idx);
  }

  getTrade(): void {
    this.dialog.open(P2pPurchaseTradeModalComponent, {
      data: this.item$,
    });
  }

  onSetupSteam(): void {
    this.dialog.open(P2pSetupModalComponent, {
      data: ['steam'],
    });
  }

  private subscribeEmitters(): void {
    this.offerTimer$ = this.item$.pipe(
      filter(({ nextStatusAt }) => Date.parse(nextStatusAt) > Date.now()),
      switchMap(({ nextStatusAt }) =>
        interval(1000).pipe(
          startWith(Date.parse(nextStatusAt) - Date.now()),
          map(() => Date.parse(nextStatusAt) - Date.now()),
          takeUntil(timer(Date.parse(nextStatusAt) - Date.now())),
        ),
      ),
    );

    this.initialProgress$ = this.item$.pipe(
      map(({ nextStatusAt, statusAt }) =>
        (100 - (moment(nextStatusAt).diff(moment()) / moment(nextStatusAt).diff(statusAt)) * 100).toFixed(2),
      ),
    );

    this.offerProgress$ = this.item$.pipe(map(({ nextStatusAt }) => Date.parse(nextStatusAt) - Date.now()));

    this.bidCondition$ = this.item$.pipe(
      map(({ baseItem, nextBidPrice }) =>
        baseItem.steamPrice === nextBidPrice
          ? BidCondition.RECOMMEND_PRICE
          : // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            baseItem.steamPrice && baseItem.steamPrice > nextBidPrice!
            ? BidCondition.UN_PRICE
            : BidCondition.OVERPRICE,
      ),
    );
  }
}
