import { Injectable } from '@angular/core';
import { IExperience, IExperienceLeader, IExperienceStatistic, ILevel } from '@dev-fast/types';
import { Observable } from 'rxjs';

import { Environments, EnvironmentService } from '@app/core/environment-service';

import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class LevelsApiService {
  private environment: Environments;

  constructor(private readonly api: ApiService, private readonly environmentService: EnvironmentService) {
    this.environment = this.environmentService.getEnvironment();
  }

  public getLevelsRoadMap(): Observable<ILevel[]> {
    return this.api.get<ILevel[]>(`${this.environment.GATEWAY_URL}/api/experience/levels`);
  }

  public getRecentExperienceGainList(): Observable<IExperience[]> {
    return this.api.get<IExperience[]>(`${this.environment.GATEWAY_URL}/api/experience/logs`, {
      withCredentials: true,
    });
  }

  public getExperienceStatistic(): Observable<IExperienceStatistic[]> {
    return this.api.get<IExperienceStatistic[]>(`${this.environment.GATEWAY_URL}/api/experience/statistics`, {
      withCredentials: true,
    });
  }

  public getExperienceLeaders(): Observable<IExperienceLeader[]> {
    return this.api.get<IExperienceLeader[]>(`${this.environment.GATEWAY_URL}/api/experience/leaders`, {
      withCredentials: true,
    });
  }

  public levelUp(): Observable<void> {
    return this.api.post<void>(
      `${this.environment.GATEWAY_URL}/api/experience/level-up`,
      {},
      {
        withCredentials: true,
      }
    );
  }
}
