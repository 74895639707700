import { FormControl } from '@angular/forms';

import { InventorySortingTypes } from '../enums';

export interface IItemsFilterForm {
  sortBy: FormControl<InventorySortingTypes | null>;
  minPrice: FormControl<number | null>;
  maxPrice: FormControl<number | null>;
  pageSize: FormControl<number | null>;
  page: FormControl<number | null>;
}
export interface IFilterByPriceOption {
  from: number;
  to: number | null;
}
