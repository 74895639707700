import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';

import { IsProjectModule } from '@app/shared/directives';

import { UserAvatarModule } from '../user-avatar';
import { UserProfileBlockComponent } from './user-profile-block.component';

@NgModule({
  imports: [CommonModule, UserAvatarModule, TranslateModule, MatIconModule, MatTooltipModule, MatProgressSpinnerModule, IsProjectModule],
  declarations: [UserProfileBlockComponent],
  exports: [UserProfileBlockComponent],
})
export class UserProfileBlockModule {}
