import { TransactionWay } from '@dev-fast/types';

export class GetItems {
  public static readonly type = '[Payment] Get items';
  constructor(public country: string, public userId: number) {}
}
export class CheckPaymentTrust {
  public static readonly type = '[Payment] Check payment trust';
  constructor(public userId: number) {}
}
/**
 * делал для Gleam
 */
export class SuccessfulRefill {
  public static readonly type = '[Payment] Successful Refill';
  constructor(public amount: number) {}
}
export class SetTransactionWay {
  public static readonly type = '[Transaction] Set transaction way';
  constructor(public way: TransactionWay | null) {}
}
