<div
  class="skin-card"
  [ngClass]="{
      selected,
      light : index % 2 === 0,
      dark: index % 2 !== 0
     }"
>
  <div class="skin-card__main main">
    <div class="main__left">
      <div
        class="avatar-wrapper"
        *ngIf="!!avatarSrc"
      >
        <img
          [src]="avatarSrc"
          class="avatar-image"
          lazyLoadImage
        />
      </div>
      <div class="item-weapon">
        <span
          class="item-name"
          *ngIf="!!weaponName"
          >{{ weaponName }} |</span
        >
      </div>
    </div>

    <div class="main__right">
      <span
        class="skin-name"
        *ngIf="!!skinName"
        >{{ skinName }}</span
      >
      <span
        class="skin-name"
        *ngIf="!!skinWear"
        >({{ skinWear }})</span
      >
    </div>
  </div>

  <div class="skin-card__stats stats">
    <div class="stats__price">
      <span class="stats__title">{{ 'LOCAL.CASES.MODAL.REVISION.PRICE' | translate }}: </span>
      <div class="stats__value">{{ price | appCurrency }}</div>
    </div>

    <div
      class="stats__range"
      *ngIf="range"
    >
      <span class="stats__title">{{ 'LOCAL.CASES.MODAL.REVISION.RANGE' | translate }}: </span>
      <span class="stats__value">
        <ng-container *ngIf="range.min">{{ range.min }}</ng-container>
        <ng-container *ngIf="range.max"> - {{ range.max }} </ng-container>
      </span>
    </div>

    <div class="stats__chance">
      <span class="stats__title">{{ 'LOCAL.CASES.MODAL.REVISION.CHANCE' | translate }}: </span>
      <span class="stats__value">{{ chance / 1000 + '%' }}</span>
    </div>

    <div class="stats__roll">
      <span class="stats__title">{{ 'LOCAL.CASES.MODAL.REVISION.ROLL' | translate }}: </span>
      <span class="stats__value">{{ selected ? roll : '' }}</span>
    </div>
  </div>
</div>
