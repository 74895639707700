import { Directive, ElementRef, OnChanges, Renderer2 } from '@angular/core';

import { EnvironmentService } from '@app/core/environment-service';

import { LazyLoadImageDirective } from '../lazy-load-image/lazy-load-image.directive';

@Directive({
  selector: 'img[skinStickerImage]',
})
export class SkinStickerImageDirective extends LazyLoadImageDirective implements OnChanges {
  // @Input() public width = 330;
  // @Input() public height = 192;

  constructor(el: ElementRef, private render: Renderer2, private readonly environmentService: EnvironmentService) {
    super(el, render);
  }
  public override ngOnChanges(): void {
    if (this.src) {
      if (!(this.src.startsWith('http') || this.src.startsWith('//'))) {
        this.src = `https://steamcdn-a.akamaihd.net/apps/730/icons/econ/${this.src}`;
      }
    }
    this.fallback = this.environmentService.environments.CHIP_FALLBACK_URL;

    super.ngOnChanges();
  }
}
