import { INotification } from '@dev-fast/types';

export class AddNotification {
  public static readonly type = '[Notifications] Add Notification';
  constructor(public readonly noty: INotification) {}
}
export class AddNotificationSuccess {
  public static readonly type = '[Notifications] Add Notification success';
  constructor(public readonly noty: INotification) {}
}
export class GetNotifications {
  public static readonly type = '[Notifications] Fetch notifications';
}
export class RemoveNotification {
  public static readonly type = '[Notifications] Remove notification';
  constructor(public readonly notyId: number) {}
}
export class WatchAllNotification {
  public static readonly type = '[Notifications] Watch all notification';
}
export class WatchNotification {
  public static readonly type = '[Notifications] Watch notification';
  constructor(public readonly notyId: number) {}
}
