<ng-container
  *ngIf="{
    inventoryItems: inventoryItems$ | async,
    selectedItems: selectedItems$ | async,
    selectedItemsPrice: selectedItemsPrice$ | async,
    inventoryParams: inventoryParams$ | async,
    inventoryInfo: inventoryInfo$ | async,
    shopItems: shopItems$ | async,
    selectedShopItems: selectedShopItems$ | async,
    selectedShopItemsPrice: selectedShopItemsPrice$ | async,
    shopParams: shopParams$ | async,
    userBalance: userBalance$ | async,
    userBalanceDelta: userBalanceDelta$ | async,
    maxInventoryPages: maxInventoryPages$ | async,
    shopMeta: shopMeta$ | async,
    maxShopPages: maxShopPages$ | async,
    selectionAmount: selectionAmount$ | async,
    isSelectAll: isSelectAll$ | async,
    breakpoints: breakpoints$ | async
  } as data"
>
  <div
    class="trade"
    #tradeModal
  >
    <div class="trade--header">
      {{ 'TRADE_PANEL.EXCHANGE' | translate }}
      <div
        class="close-button"
        (click)="closeModal()"
      >
        <mat-icon svgIcon="close" />
      </div>
    </div>
    <div
      *ngIf="data.breakpoints && data.breakpoints.content"
      [ngClass]="{ 'desktop-grid': breakpointStg(data.breakpoints.content) >= 3 }"
      class="trade--content"
    >
      <ng-container *ngIf="breakpointStg(data.breakpoints.content) >= 3; else mobileView">
        <div class="balance-info tw-inline-flex tw-items-center tw-gap-4 tw-font-medium">
          <div>{{ 'LOCAL.CASES.HISTORY.IN_INVENTORY' | translate }} {{ ': ' }}</div>
          <app-currency
            class="currency tw-font-bold"
            [value]="data.inventoryInfo?.inventorySum"
          />
          <span class="balance-info__tooltip tw-font-normal grey-color">
            ({{ data.inventoryInfo?.inventoryCount }}
            <!-- <ng-container *ngIf="data.selectedItems?.length">-{{ data.selectedItems?.length }}</ng-container> -->
            <!-- TODO Add locale -->
            {{ 'items' | translate }})
          </span>
        </div>
        <div class="shop-header balance-info tw-inline-flex tw-items-center tw-gap-4 tw-font-medium tw-relative">
          <div>{{ 'LOCAL.INVENTORY.EXCHANGER.TOTAL' | translate }}</div>
          <app-currency
            class="currency tw-font-bold"
            [value]="data.userBalance || 0"
            [class.grey-color]="data.userBalanceDelta"
          />
          <ng-container *ngIf="data.userBalance && data.userBalanceDelta">
            <mat-icon
              class="grey-color"
              svgIcon="arrow-right-line"
            />
            <app-currency
              class="currency tw-font-bold"
              [value]="data.userBalance + data.userBalanceDelta"
              [hidePrefix]="true"
            />
            <div class="hover-tooltip tw-inline-flex tw-items-center tw-gap-1 tw-font-bold tw-absolute">
              <app-currency [value]="data.userBalance || 0" />
              <app-currency
                *ngIf="data.selectedShopItemsPrice"
                class="negative-delta"
                [value]="data.selectedShopItemsPrice"
                [hidePrefix]="true"
              />
              <app-currency
                *ngIf="data.selectedItemsPrice"
                class="positive-delta"
                [value]="data.isSelectAll ? data.inventoryInfo?.inventorySum : data.selectedItemsPrice"
                [hidePrefix]="true"
              />
            </div>
          </ng-container>
        </div>

        <div
          class="trade--content--filters--inventory"
          [formGroup]="inventoryFilter"
        >
          <button
            class="btn btn-basic--design filter-button price-button"
            (click)="changeFormSortBy(inventoryFilter)"
          >
            {{ 'INVENTORY_PANEL.BY_PRICE' | translate }}
            <mat-icon [svgIcon]="data.inventoryParams?.sortBy === InventorySortingTypes.MAX_PRICE ? 'sort-3-line' : 'sort-3-line-asc'" />
          </button>
          <button
            class="btn btn-basic--design filter-button refresh-button"
            (click)="refresh('inventory')"
          >
            <mat-icon svgIcon="refresh-line" />
          </button>
        </div>

        <div
          class="trade--content--filters--shop"
          [formGroup]="shopFilter"
        >
          <div>
            <button
              class="btn btn-basic--design filter-button price-button"
              (click)="changeFormSortBy(shopFilter)"
              [disabled]="data.selectionAmount"
            >
              {{ 'INVENTORY_PANEL.BY_PRICE' | translate }}
              <mat-icon [svgIcon]="data.shopParams?.sortBy === InventorySortingTypes.MAX_PRICE ? 'sort-3-line' : 'sort-3-line-asc'" />
            </button>
            <app-ui-selection-menu
              class="price-select trade-select-panel"
              [formClass]="'ui-form trade-select'"
              [panelClass]="'ui-menu trade-select'"
              [selectionItems]="{ items: priceFilterCfg, activeIndex: 0 }"
              (activeTabChanged)="onPriceSelect($event)"
              [disabled]="!!data.selectionAmount"
            ></app-ui-selection-menu>
            <label class="search-icon">
              <input
                placeholder="{{ 'TRADE_PANEL.SEARCH_PLACEHOLDER' | translate }}"
                formControlName="query"
                class="search-input"
                [readonly]="data.selectionAmount"
              />
            </label>
          </div>
          <button
            class="btn btn-basic--design filter-button refresh-button"
            (click)="refresh('trade')"
            [disabled]="data.selectionAmount"
          >
            <mat-icon svgIcon="refresh-line"></mat-icon>
          </button>
        </div>

        <div class="trade--content--inventory">
          <ng-scrollbar
            track="vertical"
            visibility="hover"
            #inventoryScroll
          >
            <div class="trade--content--inventory--items">
              <app-ui-item-simple-card
                *ngFor="let item of data.inventoryItems; trackBy: inventoryTrackByFn"
                [color]="item.color"
                [rarity]="item.rarity"
                [icon]="item.icon"
                [name]="item.name"
                [price]="item.price"
                [status]="getCardStatus(item.id, data.selectedItems)"
                (cardClick)="selectInventoryItem(item.id)"
              />
              <ng-container *ngIf="data.inventoryParams && data.inventoryParams.page && data.maxInventoryPages">
                <div
                  class="pagination-viewport"
                  *ngIf="data.inventoryParams.page < data.maxInventoryPages"
                  inViewport
                  [inViewportOptions]="{ threshold: [0] }"
                  (inViewportAction)="onScrollToBottomInventory($event, data.inventoryParams.page)"
                ></div>
              </ng-container>
            </div>
          </ng-scrollbar>
        </div>
        <div class="trade-content-filters tw-flex tw-flex-col tw-items-center tw-gap-1 tw-px-4">
          <div
            class="trade-filter-button !tw-w-5/6 tw-mx-auto !tw-min-h-8 !tw-h-auto"
            [class.disabled]="true"
          >
            <mat-icon svgIcon="polygon" />
            <mat-icon svgIcon="steam-bold" />
          </div>
          <div
            class="trade-filter-button tw-flex tw-justify-centter !tw-w-5/6 tw-mx-auto"
            (click)="selectAll()"
          >
            <mat-icon svgIcon="polygon" />
            <mat-icon svgIcon="automate-gun" />
            <mat-icon svgIcon="automate-gun" />
          </div>
          <div
            class="trade-filter-button"
            [class.disabled]="!data.userBalance"
            (click)="getShopByValue(data.userBalance || 0)"
          >
            <mat-icon svgIcon="polygon" />
            <mat-icon svgIcon="fast-union" />
          </div>
          <div
            *ngFor="let item of collectByPrice"
            class="trade-filter-button"
            [class.active]="data.selectionAmount === item.name"
            (click)="getShopByValue(item.name)"
          >
            <mat-icon svgIcon="polygon" />
            <span>{{ item.title }}</span>
          </div>
        </div>
        <div class="trade--content--inventory">
          <ng-scrollbar
            track="vertical"
            visibility="hover"
            #shopScroll
          >
            <div class="trade--content--inventory--items">
              <app-ui-item-simple-card
                *ngFor="let item of data.shopItems"
                [color]="item.color"
                [rarity]="item.rarity"
                [icon]="item.icon"
                [name]="item.name"
                [price]="item.price"
                [status]="getCardStatus(item.id, data.selectedShopItems)"
                (cardClick)="selectShopItem(item.id)"
              />
              <ng-container *ngIf="data.shopParams && data.shopParams.page && data.maxShopPages">
                <div
                  class="pagination-viewport"
                  *ngIf="data.shopParams.page < data.maxShopPages"
                  inViewport
                  [inViewportOptions]="{ threshold: [0] }"
                  (inViewportAction)="onScrollToBottomShop($event, data.shopParams.page)"
                ></div>
              </ng-container>
            </div>
          </ng-scrollbar>
        </div>
      </ng-container>

      <ng-template #mobileView>
        <div class="trade-mobile-container trade-tabs">
          <mat-tab-group
            class="tabs-default trade-tabs"
            [(selectedIndex)]="activeTab"
          >
            <mat-tab>
              <ng-template mat-tab-label>
                <div class="tab-label">
                  <div [ngStyle]="{ color: !activeTab ? 'var(--blue-300, #80D1FF)' : 'var(--gray-400, #727488)' }">
                    {{ 'INVENTORY_ITEM_TOOLTIP.SELL' | translate }}
                  </div>
                  <app-currency
                    class="currency"
                    [value]="data.inventoryInfo?.inventorySum"
                  />
                </div>
              </ng-template>
              <div class="tab-content">
                <ng-container *ngIf="breakpointStg(data.breakpoints.content) >= 2; else mobileFilters">
                  <div class="tab-content--filters">
                    <button
                      class="btn btn-basic--design filter-button price-button"
                      (click)="changeFormSortBy(inventoryFilter)"
                    >
                      {{ 'INVENTORY_PANEL.BY_PRICE' | translate }}
                      <mat-icon
                        [svgIcon]="data.inventoryParams?.sortBy === InventorySortingTypes.MAX_PRICE ? 'sort-3-line' : 'sort-3-line-asc'"
                      />
                    </button>
                    <div class="inventory-filters">
                      <div
                        class="close-button"
                        (click)="unselectItems('inventory')"
                        [class.disabled]="!data.selectedItems?.length"
                      >
                        <mat-icon svgIcon="close" />
                      </div>
                      <div
                        class="trade-filter-button inventory-tab"
                        [class.disabled]="true"
                      >
                        <mat-icon svgIcon="polygon-wide" />
                        <mat-icon svgIcon="steam-bold" />
                      </div>
                      <div
                        class="trade-filter-button inventory-tab flex fx-justify-center"
                        (click)="selectAll()"
                        [class.disabled]="!data.inventoryInfo?.inventoryCount"
                      >
                        <mat-icon svgIcon="polygon-wide" />
                        <mat-icon svgIcon="automate-gun" />
                        <mat-icon svgIcon="automate-gun" />
                      </div>
                    </div>
                    <button
                      class="btn btn-basic--design filter-button refresh-button"
                      (click)="refresh('inventory')"
                    >
                      <mat-icon svgIcon="refresh-line"></mat-icon>
                    </button>
                  </div>
                </ng-container>
                <ng-template #mobileFilters>
                  <div class="tab-content--mobile-filters">
                    <div class="inventory-filters">
                      <div
                        class="close-button"
                        (click)="unselectItems('inventory')"
                        [class.disabled]="!data.selectedItems?.length"
                      >
                        <mat-icon svgIcon="close" />
                      </div>
                      <div
                        class="trade-filter-button inventory-tab"
                        [class.disabled]="true"
                      >
                        <mat-icon svgIcon="polygon-wide" />
                        <mat-icon svgIcon="steam-bold" />
                      </div>
                      <div
                        class="trade-filter-button inventory-tab flex fx-justify-center"
                        (click)="selectAll()"
                        [class.disabled]="!data.inventoryInfo?.inventoryCount"
                      >
                        <mat-icon svgIcon="polygon-wide" />
                        <mat-icon svgIcon="automate-gun" />
                        <mat-icon svgIcon="automate-gun" />
                      </div>
                    </div>
                    <div class="inventory-filters">
                      <button
                        class="btn btn-basic--design filter-button price-button"
                        (click)="changeFormSortBy(inventoryFilter)"
                      >
                        {{ 'INVENTORY_PANEL.BY_PRICE' | translate }}
                        <mat-icon
                          [svgIcon]="data.inventoryParams?.sortBy === InventorySortingTypes.MAX_PRICE ? 'sort-3-line' : 'sort-3-line-asc'"
                        />
                      </button>
                      <button
                        class="btn btn-basic--design filter-button refresh-button"
                        (click)="refresh('inventory')"
                      >
                        <mat-icon svgIcon="refresh-line"></mat-icon>
                      </button>
                    </div>
                  </div>
                </ng-template>

                <div class="tab-content--list">
                  <ng-scrollbar
                    track="vertical"
                    #inventoryScroll
                  >
                    <div class="tab-content--list--items">
                      <app-ui-item-simple-card
                        *ngFor="let item of data.inventoryItems"
                        [color]="item.color"
                        [rarity]="item.rarity"
                        [icon]="item.icon"
                        [name]="item.name"
                        [price]="item.price"
                        [status]="getCardStatus(item.id, data.selectedItems)"
                        (cardClick)="selectInventoryItem(item.id)"
                      />
                      <ng-container *ngIf="data.inventoryParams && data.inventoryParams.page && data.maxInventoryPages">
                        <div
                          class="pagination-viewport"
                          *ngIf="data.inventoryParams.page < data.maxInventoryPages"
                          inViewport
                          [inViewportOptions]="{ threshold: [0] }"
                          (inViewportAction)="onScrollToBottomInventory($event, data.inventoryParams.page)"
                        ></div>
                      </ng-container>
                    </div>
                  </ng-scrollbar>
                </div>
              </div>
            </mat-tab>

            <mat-tab>
              <ng-template mat-tab-label>
                <div class="tab-label">
                  <div [ngStyle]="{ color: activeTab ? 'var(--blue-300, #80D1FF)' : 'var(--gray-400, #727488)' }">
                    {{ 'P2P_WIDGETS.BUY' | translate }}
                  </div>
                  <app-currency
                    class="currency"
                    [value]="data.userBalance || undefined"
                  />
                </div>
              </ng-template>
              <div class="tab-content pt-3">
                <ng-container *ngIf="breakpointStg(data.breakpoints.content) >= 2; else mobileTradeFilters">
                  <div class="tab-content--filters">
                    <button
                      class="btn btn-basic--design filter-button price-button"
                      (click)="changeFormSortBy(shopFilter)"
                    >
                      {{ 'INVENTORY_PANEL.BY_PRICE' | translate }}
                      <mat-icon
                        [svgIcon]="data.shopParams?.sortBy === InventorySortingTypes.MAX_PRICE ? 'sort-3-line' : 'sort-3-line-asc'"
                      />
                    </button>
                    <div class="inventory-filters">
                      <ng-container *ngIf="data.userBalance">
                        <div
                          class="trade-filter-button inventory-tab"
                          (click)="getShopByValue(data.userBalance)"
                        >
                          <mat-icon
                            svgIcon="polygon-wide"
                            class="trade-polygon"
                          ></mat-icon>
                          <mat-icon svgIcon="fast-union" />
                        </div>
                        <div class="get-shop-input inventory-tab trade-polygon">
                          <mat-icon
                            svgIcon="coin-primary"
                            class="fast-coin"
                          >
                          </mat-icon>
                          <mat-icon
                            svgIcon="polygon-wide"
                            class="trade-polygon get-shop-input--polygon"
                          ></mat-icon>
                          <input
                            type="number"
                            [formControl]="collectionControl"
                            class="no-spinner"
                          />
                        </div>
                      </ng-container>
                      <div
                        class="close-button"
                        (click)="unselectItems('shop')"
                        [class.disabled]="!data.selectedShopItems?.length"
                      >
                        <mat-icon svgIcon="close" />
                      </div>
                    </div>
                    <button
                      class="btn btn-basic--design filter-button refresh-button"
                      (click)="refresh('trade')"
                    >
                      <mat-icon svgIcon="refresh-line"></mat-icon>
                    </button>
                  </div>
                </ng-container>
                <ng-template #mobileTradeFilters>
                  <div class="tab-content--mobile-filters">
                    <div class="inventory-filters">
                      <ng-container *ngIf="data.userBalance">
                        <div
                          class="trade-filter-button inventory-tab"
                          (click)="getShopByValue(data.userBalance)"
                        >
                          <mat-icon
                            svgIcon="polygon-wide"
                            class="trade-polygon"
                          ></mat-icon>
                          <mat-icon svgIcon="fast-union"></mat-icon>
                        </div>
                        <div class="get-shop-input inventory-tab trade-polygon">
                          <mat-icon
                            svgIcon="coin-primary"
                            class="fast-coin"
                          />
                          <mat-icon
                            svgIcon="polygon-wide"
                            class="trade-polygon get-shop-input--polygon"
                          ></mat-icon>
                          <input
                            type="number"
                            [formControl]="collectionControl"
                            class="no-spinner"
                          />
                        </div>
                      </ng-container>
                      <div
                        class="close-button"
                        (click)="unselectItems('shop')"
                        [class.disabled]="!data.selectedShopItems?.length"
                      >
                        <mat-icon svgIcon="close" />
                      </div>
                    </div>
                    <div class="inventory-filters">
                      <button
                        class="btn btn-basic--design filter-button price-button"
                        (click)="changeFormSortBy(shopFilter)"
                      >
                        {{ 'INVENTORY_PANEL.BY_PRICE' | translate }}
                        <mat-icon
                          [svgIcon]="data.shopParams?.sortBy === InventorySortingTypes.MAX_PRICE ? 'sort-3-line' : 'sort-3-line-asc'"
                        />
                      </button>
                      <button
                        class="btn btn-basic--design filter-button refresh-button"
                        (click)="refresh('trade')"
                      >
                        <mat-icon svgIcon="refresh-line"></mat-icon>
                      </button>
                    </div>
                  </div>
                </ng-template>
                <div class="tab-content--list">
                  <ng-scrollbar
                    track="vertical"
                    #shopScroll
                  >
                    <div class="tab-content--list--items">
                      <app-ui-item-simple-card
                        *ngFor="let item of data.shopItems"
                        [color]="item.color"
                        [rarity]="item.rarity"
                        [icon]="item.icon"
                        [name]="item.name"
                        [price]="item.price"
                        [status]="getCardStatus(item.id, data.selectedShopItems)"
                        (cardClick)="selectShopItem(item.id)"
                      />
                      <ng-container *ngIf="data.shopParams && data.shopParams.page && data.maxShopPages">
                        <div
                          class="pagination-viewport"
                          *ngIf="data.shopParams.page < data.maxShopPages"
                          inViewport
                          [inViewportOptions]="{ threshold: [0] }"
                          (inViewportAction)="onScrollToBottomShop($event, data.shopParams.page)"
                        ></div>
                      </ng-container>
                    </div>
                  </ng-scrollbar>
                </div>
              </div>
            </mat-tab>
          </mat-tab-group>
        </div>
      </ng-template>
    </div>
    <ng-container
      *ngIf="(data.selectedItems && data.selectedItems.length > 0) || (data.selectedShopItems && data.selectedShopItems.length > 0)"
    >
      <ng-container
        *ngIf="data.breakpoints && data.breakpoints.content && breakpointStg(data.breakpoints.content) >= 3; else mobileActions"
      >
        <div class="trade--actions">
          <div
            class="trade-close-button reset-button"
            (click)="unselectItems()"
          >
            <mat-icon svgIcon="inventory-bag-v2"></mat-icon>
          </div>
          <ng-container
            *ngIf="(data.userBalance || 0) + (data.selectedItemsPrice || 0) - (data.selectedShopItemsPrice || 0) >= 0; else refillBtn"
          >
            <button
              class="btn btn-primary--design trade-button"
              (click)="trade()"
            >
              {{ 'LOCAL.INVENTORY.BUTTON_EXCHANGE' | translate }}
            </button>
          </ng-container>
          <ng-template #refillBtn>
            <button
              class="btn btn-primary--design refill-btn"
              (click)="navigateToRefill()"
            >
              {{ 'INVENTORY_PANEL.REFILL' | translate }}
            </button>
          </ng-template>
        </div>
      </ng-container>
      <ng-template #mobileActions>
        <ng-container *ngIf="!activeTab && data.selectedItems && data.selectedItems.length > 0">
          <div class="trade--actions">
            <button
              class="btn btn-primary--design trade-button"
              (click)="sellItems(data.selectedItems)"
            >
              {{ 'INVENTORY_ITEM_TOOLTIP.SELL' | translate }}
              {{ data.selectedItemsPrice | appCurrency }}
            </button>
          </div>
        </ng-container>
        <ng-container *ngIf="activeTab && data.selectedShopItems && data.selectedShopItems.length > 0">
          <div class="trade--actions">
            <ng-container
              *ngIf="(data.userBalance || 0) + (data.selectedItemsPrice || 0) - (data.selectedShopItemsPrice || 0) >= 0; else refillBtn"
            >
              <button
                class="btn btn-primary--design trade-button"
                (click)="purchaseItems(data.selectedShopItems, data.selectedItems)"
              >
                {{ 'P2P_WIDGETS.BUY' | translate }}
                {{ data.selectedShopItemsPrice | appCurrency }}
              </button>
            </ng-container>
            <ng-template #refillBtn>
              <button
                class="btn btn-primary--design refill-btn"
                (click)="navigateToRefill()"
              >
                {{ 'INVENTORY_PANEL.REFILL_FOR_EXCHANGE' | translate }}
              </button>
            </ng-template>
          </div>
        </ng-container>
      </ng-template>
    </ng-container>
  </div>
</ng-container>
