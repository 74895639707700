<div
  class="possible-card-container"
  [class.hovered]="isHovered"
  [ngClass]="cardClass"
  [style.background]="setCardBackground(isNewPalette)"
  (clickOutside)="showRevisions = false"
>
  <div
    class="possible-card-wrapper"
    (click)="onCard()"
  >
    <div class="possible-card-avatar-container">
      <img
        class="card-avatar"
        [src]="revisions[0].inventoryItem.baseItem.icon | hash"
        alt=""
      />
    </div>
    <div class="possible-card-description-container">
      <div class="card-description_item-name">{{ revisions[0].inventoryItem.baseItem.shortName }}</div>
      <div class="card-description_item-skin">{{ revisions[0].inventoryItem.baseItem.skin }}</div>
      <div
        class="tw-flex tw-flex-wrap tw-text-base tw-font-bold tw-text-gray-100"
        *ngIf="getSkinPrice(revisions) as prices"
      >
        <ng-container *ngIf="prices.max; else onePriceTemplate">
          <app-currency [value]="prices.min" />
          &nbsp;&ndash;&nbsp;
          <app-currency [value]="prices.max" />
        </ng-container>
        <ng-template #onePriceTemplate>
          <app-currency [value]="prices.current" />
        </ng-template>
      </div>
    </div>
  </div>
  <div
    class="revision-variables-container"
    [class]="showRevisions ? 'visible' : 'hidden'"
    (click)="showRevisions = false"
  >
    <div class="stats-title">
      <div>{{ 'LOCAL.CASES.SPIN_GAME.POSSIBLE_ITEMS.POSSIBLE_CARD.CARD_WEAR' | translate }}</div>
      <div>{{ 'LOCAL.CASES.SPIN_GAME.POSSIBLE_ITEMS.POSSIBLE_CARD.CARD_PRICE' | translate }}</div>
      <div>{{ 'LOCAL.CASES.SPIN_GAME.POSSIBLE_ITEMS.POSSIBLE_CARD.CARD_ODDS' | translate }}</div>
    </div>
    <div
      class="revision-variables"
      *ngFor="let revision of revisions"
    >
      <div
        class="revision-wear"
        [class.stat-trak]="revision.inventoryItem.baseItem.statTrak"
      >
        {{ toShortRevisionWear(revision.inventoryItem.baseItem.exterior) }}
      </div>
      <div class="revision-price">{{ revision.inventoryItem.price | appCurrency }}</div>
      <div
        class="revision-odds"
        [class.stat-trak]="revision.inventoryItem.baseItem.statTrak"
      >
        {{ revision.count | appPercent: { rank: 1000 } }}
      </div>
    </div>
  </div>
  <app-ui-increase
    *ngIf="showChance"
    (click)="toggleDropdown($event)"
    [valueType]="'percent'"
    [maxValue]="95000"
    [setCurValue]="countSum * tracksAmount"
    [index]="index"
    [differencePosition]="'bottom'"
  >
  </app-ui-increase>
</div>
