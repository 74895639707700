import { TransactionWay } from '@dev-fast/types';

export interface PaymentStateModel {
  paymentTrust: boolean;
  transactionWay: TransactionWay | null;
}

export const PAYMENT_INITIAL_STATE: PaymentStateModel = {
  paymentTrust: false,
  transactionWay: null,
};
