import { Injectable } from '@angular/core';
import { ILobbyGame, IOrder, IPayout, IWinning } from '@dev-fast/types';
import { map, Observable } from 'rxjs';

import { Environments, EnvironmentService } from '@app/core/environment-service';

import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class LobbyService {
  private environment: Environments;
  constructor(private api: ApiService, private environmentService: EnvironmentService) {
    this.environment = this.environmentService.getEnvironment();
  }

  public getTopGames(): Observable<ILobbyGame[]> {
    return this.api
      .get<{ topGames: ILobbyGame[] }>(`${this.environment.GATEWAY_URL}/api/motivator/games-top`, {
        withCredentials: true,
      })
      .pipe(map((data) => data.topGames));
  }

  public getTopWins(): Observable<IWinning[]> {
    return this.api
      .get<{ topWins: IWinning[] }>(`${this.environment.GATEWAY_URL}/api/motivator/wins-top`, {
        withCredentials: true,
        params: {
          limit: 20,
        },
      })
      .pipe(map((data) => data.topWins));
  }

  public getTop24h(): Observable<IWinning[]> {
    return this.api
      .get<{ topWins24: IWinning[] }>(`${this.environment.GATEWAY_URL}/api/motivator/wins-top24`, {
        withCredentials: true,
        params: {
          limit: 20,
        },
      })
      .pipe(map((data) => data.topWins24));
  }

  public getLastPayouts(): Observable<IPayout[]> {
    return this.api
      .get<{ lastPayouts: IPayout[] }>(`${this.environment.GATEWAY_URL}/api/motivator/last-payouts`, {
        withCredentials: true,
      })
      .pipe(map((data) => data.lastPayouts));
  }

  public getLastOrders(): Observable<IOrder[]> {
    return this.api
      .get<{ lastOrders: IOrder[] }>(`${this.environment.GATEWAY_URL}/api/motivator/last-orders`, {
        withCredentials: true,
      })
      .pipe(map((data) => data.lastOrders));
  }
}
