import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, NgModule, OnDestroy, OnInit } from '@angular/core';
import { ICurrencyRate } from '@dev-fast/types';
import anime from 'animejs';
import { Subscription } from 'rxjs';

import { CurrencyService } from '@app/core/currency';
import { AppCurrencyModule, AppPercentModule } from '@app/shared/pipes';

type IValueType = 'number' | 'percent' | 'currency';
type IDifferencePosition = 'top' | 'bottom' | 'right' | 'left' | 'hide';

@Component({
  selector: 'app-ui-increase',
  templateUrl: './increase.component.html',
  styleUrls: ['./increase.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IncreaseComponent implements OnDestroy, OnInit {
  @Input() index: string | number = Number.MAX_SAFE_INTEGER;
  @Input() className = 'increase';
  @Input() valueType: IValueType = 'number';
  @Input() maxValue: number | null = null;
  @Input() delay = 0;
  @Input() set setCurValue(newValue: number) {
    this.animate(newValue);
  }
  @Input() differencePosition: IDifferencePosition = 'hide';
  @Input() duration = 600;

  private currency$: Subscription | null = null;
  private animation: anime.AnimeInstance | null = null;
  private target = '';
  curValue = 0;
  preValue = 0;
  difference = 0;
  isDifferenceShow = false;
  mainPerfix = '';
  differencePrefix = '';
  rank = 1;
  currency: ICurrencyRate | null = null;

  constructor(
    private cdr: ChangeDetectorRef,
    private currencyService: CurrencyService,
  ) {}

  ngOnInit(): void {
    if (this.valueType === 'currency') {
      this.currency$ = this.currencyService.currency$.subscribe((currency) => {
        this.currency = currency;
        this.curValue = this.transform(this.curValue);
        this.preValue = this.curValue;
      });
    }
  }

  ngOnDestroy(): void {
    this.currency$?.unsubscribe();
    this.animation = null;
  }
  private animate(value: number): void {
    this.rank = this.valueType === 'percent' ? 1000 : this.valueType === 'currency' ? 100 : 1;
    this.target = `.${this.className}_${this.index}`;
    if (this.valueType === 'currency' && this.currency) {
      this.animateCurrency(value, this.duration);
    } else {
      this.animatePercent(value, this.duration);
    }
  }

  private animatePercent(newValue: number, duration: number): void {
    this.curValue = newValue;
    if (this.maxValue !== null) {
      this.curValue = this.maxValue < this.curValue ? this.maxValue : this.curValue;
      this.preValue = this.maxValue < this.preValue ? this.maxValue : this.preValue;
      if (this.maxValue <= this.curValue) {
        this.mainPerfix = '~';
      } else {
        this.mainPerfix = '';
      }
    }
    if (this.preValue !== 0) {
      this.difference = this.curValue - this.preValue;
      if (this.differencePosition !== 'hide' && this.difference !== 0) {
        this.isDifferenceShow = true;
      }
      if (this.difference > 0) {
        this.differencePrefix = '+';
      } else {
        this.differencePrefix = '-';
      }
      this.animation = anime({
        targets: this.target,
        value: [this.preValue / this.rank, this.curValue / this.rank],
        round: this.rank,
        easing: 'easeInOutExpo',
        duration: duration,
        delay: this.delay,
        complete: () => {
          this.isDifferenceShow = false;
          this.cdr.detectChanges();
        },
      });
    }
    this.preValue = newValue;
  }
  private animateCurrency(newValue: number, duration: number): void {
    const bufferValue = this.transform(newValue);
    if (this.currency) {
      this.difference = Number((bufferValue - this.preValue).toFixed(2));
      if (this.differencePosition !== 'hide' && this.difference !== 0) {
        this.isDifferenceShow = true;
      }
      if (this.difference > 0) {
        this.differencePrefix = '+';
      } else {
        this.differencePrefix = '';
      }
      this.animation = anime({
        targets: this.target,
        value: [this.preValue, bufferValue],
        round: this.rank,
        easing: 'easeInExpo',
        duration: duration,
        delay: this.delay,
        complete: () => {
          this.curValue = this.transform(newValue);
          this.isDifferenceShow = false;
          this.cdr.detectChanges();
        },
      });
    }
    this.preValue = this.transform(newValue);
  }
  private transform(value: number): number {
    return this.currencyService.convert(value);
  }
}
@NgModule({
  declarations: [IncreaseComponent],
  imports: [CommonModule, AppCurrencyModule, AppPercentModule],
  exports: [IncreaseComponent],
})
export class IncreaseModule {}
