import { animate, keyframes, query, style, transition, trigger } from '@angular/animations';

export const skinItemAnimation = trigger('shrinkAnim', [
  transition('* => *', [
    query(':self', [
      animate(
        '350ms ease-in-out',
        keyframes([style({ transform: 'scale(1)' }), style({ transform: 'scale(0.8)' }), style({ transform: 'scale(1)' })])
      ),
    ]),
  ]),
]);

export const appearanceAnimation = trigger('appearanceAnimation', [
  transition(':enter', [
    query(':self', [style({ opacity: 0 }), animate('3000ms ease-in-out', style({ opacity: 1 }))], {
      optional: true,
    }),
  ]),
]);
