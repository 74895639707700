import { IBaseItemDtoV2, ICaseItemDtoV2 } from '../games';
import { IP2pBaseItem } from '../p2p';
import { ISticker } from '../skin-sticker';
import { IMarketplaceMetaData } from './marketplace-metadata.interface';

export interface IMarketplaceData {
  items: Map<number, IMarketplaceItem>;
  meta: IMarketplaceMetaData;
  loading: boolean;
  loaded: boolean;
  error?: boolean;
}

export interface IBaseMarketplaceItem {
  id: number;
  baseItem: IBaseItemDtoV2;
}

export interface IMarketplaceDto {
  items: IMarketplaceItem[];
  meta?: IMarketplaceMetaData;
  loading: boolean;
  loaded: boolean;
  error?: boolean;
}

export interface IMarketplaceItem extends IP2pBaseItem, IBaseMarketplaceItem {
  steamItemPosition?: number[];
}

export interface IMarktetplaceKit {
  id: string;
  createAt: Date;
  updatedAt: Date;
  price: number;
  sellerPrice: number;
  realPrice: number;
  source: string;
  express: boolean;
  recommendedPrice: number;
  sellerId: number;
  isCrossFast: boolean;
  status: string;
  statusAt: Date;
  deletedAt: Date | null;
  lastBidId: string;
  lastBidAt: Date;
  isMoneyReceived: boolean;
  autoApprove: boolean;
  fastDelievery: boolean;
  tradesDuration: number;
  items: IMarketplaceItem[];
}
export interface IMarketplaceFilterConfig {
  // weapon: Map<string, string>;
  type: Map<string, string>;
  types: Map<string, string[]>;
  category: Map<string, string>;
  phase: Map<string, string>;
  exterior: Map<string, string>;
  price: Map<string, number[]>;
  common: Map<string, string>;
}

export interface IKitResponseDto {
  kits: IMarketplaceKitData[];
  meta: IMarketplaceMetaData;
}

export interface IKitExtendedDto {
  kits: (IMarketplaceKitData | ICaseItemDtoV2)[];
  meta: IMarketplaceMetaData;
}

export interface IMarketplaceKitData extends IP2pBaseItem {
  id: number;
  nextSellerPrice?: number | null;
  isActive?: boolean;
  items: IMarketplaceKitItem[];
}

export interface IMarketplaceKitItem {
  id?: number;
  baseItem: IBaseItemDtoV2;
  float?: number;
  price: number;
  stickers?: ISticker[];
  extra?: {
    price: number;
    increase: number;
  };
  caseId?: number; // если это стимовский кейс и у нас есть с таким же названием, возвращает его id
  casePrice?: number; // цена открытия кейса
}
