import { ModalNames, RoutableModalNames } from '@dev-fast/types';

export interface ModalsStateModel {
  activeModals: ModalNames[];
  activeRoutableModal: RoutableModalNames | null;
  lastClosedModal: ModalNames | null;
}

export const MODALS_INITIAL_STATE: ModalsStateModel = {
  activeModals: [],
  activeRoutableModal: null,
  lastClosedModal: null,
};
