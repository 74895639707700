import { NgIf } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  EventEmitter,
  HostBinding,
  inject,
  Input,
  OnInit,
  Output,
  Self,
  signal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ControlValueAccessor, FormsModule, NgControl, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MaskitoModule } from '@maskito/angular';
import { MaskitoOptions } from '@maskito/core';
import { TranslateModule } from '@ngx-translate/core';
import { debounceTime, Subject, tap } from 'rxjs';

import { bellAnimation, narrowingAnimation, wideningAnimation } from '@app/ui/animations';

@Component({
  selector: 'app-ui-input',
  standalone: true,
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  imports: [
    NgIf,
    FormsModule,
    ReactiveFormsModule,
    MatIconModule,
    MatFormFieldModule,
    MatSelectModule,
    MatAutocompleteModule,
    MatInputModule,
    TranslateModule,
    MaskitoModule,
  ],
  animations: [bellAnimation, narrowingAnimation, wideningAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiInputSAComponent implements ControlValueAccessor, OnInit {
  @Input() checkValidation$: Subject<void> = new Subject();
  @Input() placeholder: string | undefined;
  @Input() autocomplete = false;
  @Input() prefixIcon: string | undefined;
  @Input() prefixText: string | undefined;
  @Input() canPaste = false;
  @Input() maskOptions: MaskitoOptions | null = null;
  @Output() oniconHover: EventEmitter<boolean> = new EventEmitter();

  @HostBinding('style.--input-padding') get _prefix(): string {
    if (!!this.prefixIcon || !!this.prefixText) {
      return '0rem';
    }
    return '0.75rem';
  }
  readonly #destroyRef = inject(DestroyRef);
  readonly animTrigger = signal<boolean>(false);
  readonly _autocomplete: 'off' | 'on' = 'off';
  #value = '';

  constructor(@Self() readonly _ngControl: NgControl) {
    this._ngControl.valueAccessor = this;
    this._autocomplete = this.autocomplete ? 'on' : 'off';
  }
  ngOnInit(): void {
    this._ngControl.valueChanges
      ?.pipe(
        tap((value) => {
          this.#value = value;
        }),
        takeUntilDestroyed(this.#destroyRef),
      )
      .subscribe();
    this.checkValidation$
      .pipe(
        tap(() => {
          if (this._ngControl.invalid) {
            this.animTrigger.set(true);
          }
        }),
        debounceTime(1000),
        tap(() => {
          this.animTrigger.set(false);
        }),
        takeUntilDestroyed(this.#destroyRef),
      )
      .subscribe();
  }
  registerOnTouched = (): void => undefined;
  registerOnChange = (): void => undefined;
  writeValue = (value: any): void => (this.#value = value);

  pasteDefender(): boolean {
    return this.canPaste;
  }
  hoverEvent(isHover: boolean): void {
    this.oniconHover.emit(isHover);
  }
}
