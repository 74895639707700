import { FormControl } from '@angular/forms';

import { CardStatusEnum, GameIds, HistoryItemStatus, InventorySortingTypes, LangKeys } from '../enums';
import { IBaseItemDto } from './games';
import { ISkinItem, ISkinItemV2 } from './skin-item';

export interface IInventoryPriceFilter {
  title: string;
  name: any;
  icon?: { type: string; src: string };
}
export interface IInventoryItems {
  error: string;
  success: boolean;
  items: ISkinItem[];
}

export interface IInventoryItemDetailedReq {
  shortName: string;
  skin: string;
  lang: string;
}
export interface IInventoryItemDetailed {
  name: string;
  rarity: string;
  collection: string;
  description: string;
  similar: ISimilarInventoryItem[];
}
export interface ISimilarInventoryItem {
  name: string;
  exterior: string;
  statTrak: boolean;
  rarity: boolean;
  img: string;
  shortName: string;
  skin: string;
  description: string;
  collection: string;
  lang: LangKeys;
  steamPrice: number;
}
export interface IItemExterior {
  img: string;
  exterior: string;
  steamPrice: number;
  statTrackSteamPrice: number;
}
export interface IInventoryItem extends ISkinItem {
  type: string;
  isWithdrawAvailable?: boolean;
  inventoryItemId: number;
  isFrozen?: boolean;
  itemStatus?: InventoryWithdrawalStatuses | InventorySellStatuses | null; // для динамического отображения статуса выводов предметов в инвентаре
  participateStatus?: CardStatusEnum; // для динамического отображения статуса предметов в окне ставки
  statusUpdatedAt?: string;
}

export interface IInventoryItemV2 {
  available: boolean;
  baseItem: IBaseItemDto;
  price: number;
  id: number;
  steamPrice: number;
}

export interface IUserInventoryItemV2 {
  id: number;
  inventoryItem: IInventoryItemV2;
  inventoryItemId: number;
  isAvailable: boolean;
  shown: boolean;
  gameId?: number;
  isFrozen?: boolean;
  source: any[];
  transactions: boolean;
}

export interface IInventoryRequestParams {
  sortBy?: InventorySortingTypes | null;
  page?: number;
  pageSize?: number | null;
  append?: boolean;
  query?: string;
  minPrice?: number | null;
  maxPrice?: number | null;
  selectionSum?: number | null;
}

export interface IInventorySellAllRequestParams {
  weaponType?: string;
  weapon?: string;
  glovesType?: string;
  otherType?: string;
  exterior?: string;
  query?: string;
  phase?: string;
  category?: string;
  minPrice?: number;
  maxPrice?: number;
  t?: boolean;
}

export type IInventoryRequestResponse = {
  [key in GameIds]: IUserInventoryItemV2[];
} & {
  success: boolean;
  meta?: {
    total: number;
    limit: number;
    offset: number;
    totalSum: number;
  };
};

export interface IShop {
  appId?: GameIds;
  success: boolean;
  items: ISkinItemV2[];
  meta: IShopMeta;
}
export interface IShopMeta {
  amount: number;
  limit: number;
  offset: number;
}
export interface IInventoryInfo {
  appId?: GameIds;
  itemsCount: number;
  itemsSum: number;
  isCurrent?: boolean;
}
export interface IInventoryShortInfo {
  inventoryCount: number;
  inventorySum: number;
}

export interface IOutputInventoryData {
  items: IOutputInventoryItems[];
  outputItems: number;
}

export interface IWithdrawalRequest {
  userItems: IWithdrawalRequestPayload[];
}

export interface IWithdrawalRequestPayload {
  userInventoryItemId: IInventoryItem['id'];
  price: number;
}

export interface IWithdrawalResponse {
  userInventoryItemId: number;
  success: boolean;
  orderId?: number;
}

export interface SellItemsData {
  executeTime: string;
  sellItems: ISellSkinItemShort[];
}

export interface ISellSkinItemShort {
  icon: string;
  name: string;
  id: number;
  weapon: string;
  type: string;
  skin: string;
  shortName: string;
  color: string;
  exterior: string;
  statTrak: boolean;
  rarity: string;
  price: number;
}

export interface SellItemsResponse {
  et: string; // Дата начала продажи (от которой считать t)
  items: ISellSkinItemData[];
}

export interface ISellSkinItemData {
  id: number;
  createdAt: string;
  updatedAt: string;
  inventoryItem: {
    id: number;
    createdAt: string;
    updatedAt: string;
    price: number;
    baseItem: ISellSkinBaseItemData;
  };
}

export interface ISellSkinBaseItemData extends IBaseItemDto {
  createdAt: string;
  updatedAt: string;
  appId: number;
  phase?: string;
  isSouvenir?: boolean;
  price: number;
}

export interface IWithdrawalRequestParams {
  page?: number;
  pageSize?: number;
  append?: boolean;
}

export interface IWithdrawalItems {
  items: IWithdrawalItem[];
  meta: { amount: number; limit: number; offset: number };
}

export enum InventoryWithdrawalStatuses {
  WAITING_FOR_START = -1, // кастомный статус для фронта. Говорит о том, что мы ждем пока не начался вывод (пока нет ответа от бэка)
  SEARCH = 1,
  WAIT_FOR_SENT = 2,
  OFFER_SENT = 4,
  SUCCESS = 10,
  ITEM_NOT_FOUND = 20,
}

export enum InventorySellStatuses {
  WAITING_FOR_START,
  SELLING,
  SOLD,
}

export interface IWithdrawalItemUpdate {
  orderId: number;
  state: InventoryWithdrawalStatuses;
  updatedAt: string;
  offerIsSent: boolean;
  userInventoryItem: {
    gameId: number;
    id: number;
    inventoryItem: {
      available: boolean;
      baseItem: IBaseItemDto;
    };
  };
}

export interface IWithdrawalItem {
  express: boolean;
  fastDelivery: boolean;
  isCrossFast: boolean;
  offerIsSent: boolean;
  orderId: number;
  price: number;
  status: InventoryWithdrawalStatuses;
  steamInspectLink: string;
  userInventoryItem: IUserInventoryItemV2;
  statusUpdatedAt: string;
  nextStatusUpdatedAt?: string;
  source: any;
}

export interface IHistoryInventoryItem {
  createdAt: Date;
  gameId: number;
  id: number;
  inventoryItem: IInventoryItemV2;
  source: {
    type: string;
    data: IHistoryItemSource;
  };
  status: HistoryItemStatus;
}
export interface IHistoryItemSource {
  backgroundImage: string;
  enabled: boolean;
  historyImage: string;
  id: number;
  image: any;
  itemImage: string;
  lastRevision: any;
  limitedEndAt: any;
  mainItem: {
    name: string;
  };
  mainItemName: string;
  minAccountType: string;
  minOpensCount: number;
  name: string;
  onlyBattles: boolean;
  priority: any;
  publishedAt: Date;
  results: boolean;
  tags: string[];
  type: string;
}

export type IOutputInventoryItems = IInventoryItem & IWithdrawalResponse;

export interface IHistoryParams {
  page?: number;
  size?: number;
}

export interface IInventoryItemsFilterForm {
  sortBy: FormControl<InventorySortingTypes | null>;
}
export interface IStoreItemsFilterForm {
  sortBy: FormControl<InventorySortingTypes | null>;
  minPrice: FormControl<number | null>;
  maxPrice: FormControl<number | null>;
  query: FormControl<string | null>;
}
