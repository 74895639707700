<ng-container *ngIf="codeValue">
  <qrcode
    class="qrcode"
    [class.disabled]="disabled"
    [qrdata]="codeValue"
    [width]="breakSize"
    [allowEmptyString]="false"
    [elementType]="codeType"
    [errorCorrectionLevel]="qualityLevel"
    [colorLight]="color"
    [colorDark]="backgroundColor"
    [margin]="codeMargin"
  >
  </qrcode>
  <div class="sticker__wrapper">
    <div
      class="sticker__block"
      [class.sticker__block_active]="disabled"
    ></div>
  </div>
</ng-container>
