import { CurrencyPipe, DOCUMENT } from '@angular/common';
import { ChangeDetectorRef, Directive, ElementRef, Inject, Input, OnDestroy, OnInit, Renderer2, ViewContainerRef } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { ICurrencyFormatArgs, ICurrencyRate } from '@dev-fast/types';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { CurrencyService } from '@app/core/currency';

@Directive({
  selector: '[currencyFormat]',
})
export class CurrencyFormatDirective implements OnInit, OnDestroy {
  /**
   * Получает число, на  выходе дает котейнер с целым числом и дробной частью
   *  Класс контейнера: currency-format-directive

   * --gap: Отступ иконки
   * --font-size: Размер шрифта
   * --value-font-weight: Толщина целой части
   * --value-color: Цвет шрифта целой части
   * --fraction-font-weight: Толщина дрбной части
   * --fraction-color: Цвет шрифта дрбной части
   * --icon-width: Ширина иконки
   * --icon-height: Высота иконки
   * --icon-color: Цвет иконки
   */
  @Input('currencyFormat') number: number | null | undefined;
  @Input() args: ICurrencyFormatArgs;

  readonly #destroyed$: Subject<void> = new Subject<void>();
  #currency: ICurrencyRate | undefined;
  readonly #defaultArgs: ICurrencyFormatArgs = {
    iconType: 'fill',
    hasIcon: true,
    // rateKey: 'fastcoins',
  };

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private readonly currencyService: CurrencyService,
    private readonly currencyPipe: CurrencyPipe,
    private el: ElementRef,
    private renderer: Renderer2,
    private cdr: ChangeDetectorRef,
    private _viewContainer: ViewContainerRef,
  ) {
    this.args = this.#defaultArgs;
    this.currencyService.currency$
      .pipe(
        takeUntil(this.#destroyed$),
        filter((val): val is ICurrencyRate => !!val),
      )
      .subscribe((currency: ICurrencyRate) => {
        this.#currency = currency;
        this.cdr.markForCheck();
      });
  }

  ngOnInit(): void {
    if (this.number) {
      this.renderer.addClass(this.el.nativeElement, 'currency-format-directive');
      const value = this.currencyPipe
        .transform(this.currencyService.convertBySelectedRateKey('fastcoins', this.number).toString())
        ?.slice(1);
      if (value) {
        this.args = { ...this.#defaultArgs, ...this.args };
        const result = (value.endsWith('.00') && value.length >= 5) || value.length >= 9 ? value.slice(0, -3) : value;
        const [IntegerPart, fractionPart] = result.split('.');
        // 123,456,789 part
        const valueElement = this.document.createElement('span');
        valueElement.innerText = IntegerPart;
        // Собираем готовое число
        if (this.args.hasIcon) {
          const iconElementRef = this._viewContainer.createComponent<MatIcon>(MatIcon);
          iconElementRef.instance.svgIcon = `gem-${this.args.iconType}`;
          const iconElement = iconElementRef.injector.get(MatIcon)._elementRef.nativeElement;
          this.renderer.appendChild(this.el.nativeElement, iconElement);
        }
        this.renderer.appendChild(this.el.nativeElement, valueElement);
        //.12 part
        if (fractionPart) {
          const fractionElement = this.document.createElement('span');
          fractionElement.innerText = '.' + fractionPart;
          this.renderer.addClass(fractionElement, 'currency-format-directive__fraction');
          this.renderer.appendChild(this.el.nativeElement, fractionElement);
        }
      }
    }
  }
  ngOnDestroy(): void {
    this.#destroyed$.next();
    this.#destroyed$.complete();
  }
}
