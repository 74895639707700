import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { ICaseRevisionInfo } from '@dev-fast/types';
import { TranslateModule } from '@ngx-translate/core';
import { NgScrollbarModule } from 'ngx-scrollbar';

import { AppCurrencyModule, AppPercentModule } from '@app/shared/pipes';

import { IncreaseModule } from '../../increase/increase.component';
import { SkinCardModuleNew } from '../cards';

@Component({
  selector: 'app-ui-skin-cards-list-new',
  templateUrl: './skin-cards-list.component.html',
  styleUrls: ['./skin-cards-list.component.scss', './skin-cards-list.component.media.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SkinCardsListNewComponent {
  @Input() public caseRevision: ICaseRevisionInfo | undefined;
  @Input() public selected!: { id: number | undefined; roll: number | undefined };
  @Input() public showTitle = true;
  @Input() public showRange = true;
}
@NgModule({
  imports: [
    SkinCardModuleNew,
    NgScrollbarModule,
    AppCurrencyModule,
    AppPercentModule,
    TranslateModule,
    IncreaseModule,
    MatIconModule,
    CommonModule,
    RouterModule,
  ],
  declarations: [SkinCardsListNewComponent],
  exports: [SkinCardsListNewComponent],
})
export class SkinCardsListModuleNew {}
