import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, NgModule } from '@angular/core';

@Component({
  selector: 'app-ui-float-line',
  templateUrl: './float-line.component.html',
  styleUrls: ['./float-line.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FloatLineComponent {
  @Input() public float: number | null | undefined;
}

@NgModule({
  imports: [CommonModule],
  declarations: [FloatLineComponent],
  exports: [FloatLineComponent],
})
export class FloatLineModule {}
