<div
  class="confetti-wrapper"
  #confettiContainer
>
  <ng-container *ngIf="icon">
    <mat-icon
      class="confetti-icon"
      [svgIcon]="icon"
    ></mat-icon>
  </ng-container>
</div>
