<div
  class="user-avatar-container tw-flex tw-gap-3 tw-items-center tw-justify-center"
  [class]="position"
  [class.tw-flex-row]="position === 'right'"
  [class.tw-flex-row-reverse]="position === 'left'"
  [class.tw-flex-col]="position === 'bottom'"
  [class.tw-flex-col-reverse]="position === 'top'"
  [class.tw-cursor-pointer]="!!userId"
  (click)="openProfile($event, userId)"
>
  <div class="user-avatar-wrapper">
    <ng-container *ngIf="winnerIcon">
      <mat-icon
        class="winner-icon"
        [class]="winnerIcon"
        [svgIcon]="winnerIcon"
        @winnerIconAnimation
      ></mat-icon>
    </ng-container>
    <div
      class="user-avatar-box"
      [class]="type"
      [ngClass]="{
        'no-border-radius': notRound,
        'no-border': noBorder,
        'no-borderline': noBorderline,
        'no-chevron': noChevron
      }"
    >
      <ng-container *ngIf="levelUpAnimation; else normalTemplate">
        <div class="levelup-container">
          <div class="levelup-first">
            LVL
            <span class="levelup-first_lazy">UP!</span>
          </div>
          <div class="levelup-second">{{ newLevel }}</div>
        </div>
      </ng-container>
      <ng-template #normalTemplate>
        <img
          class="user-avatar-image"
          lazyLoadImage
          [src]="url"
          [alt]="alt"
          (error)="onImageError()"
        />
        <mat-icon
          *ngIf="type.length"
          [class]="'user-avatar-shield'"
          [svgIcon]="'premium-' + type"
        ></mat-icon>

        <ng-container *ngIf="level">
          <div
            class="user-level"
            [class.levelLabelOn]="levelLabelOn"
            *ngIf="level > 0"
          >
            {{ level }}
            <ng-container *ngIf="levelLabelOn">
              {{ ' LVL' }}
            </ng-container>
          </div>
        </ng-container>
      </ng-template>
    </div>
    <div
      class="additional-icon-box"
      *ngIf="!!additionalIcon"
    >
      <mat-icon
        class="additional-icon"
        [svgIcon]="additionalIcon"
      ></mat-icon>
    </div>
  </div>
  <ng-container *ngIf="username">
    <div class="username-container">
      <div class="nickname">
        {{ username.name }}
      </div>
    </div>
  </ng-container>
</div>
