import {
  HistoryItem,
  ICaseItemDtoV2,
  IFilterFormContent,
  IFilterMethod,
  IMarketplaceKitData,
  IMarketplaceMetaData,
  ISihDataset,
  ISteamStoreInventory,
  MarketPanel,
  MarketSortingTypes,
} from '@dev-fast/types';

export interface P2pMarketStateModel {
  customCaseList: ICaseItemDtoV2[];
  customCasesMetaData: { min: number; max: number | null };

  activeTab: MarketPanel;
  isLoading: boolean;

  actualItems: (IMarketplaceKitData | ICaseItemDtoV2)[];
  meta: IMarketplaceMetaData | null;
  filters: IFilterFormContent<MarketSortingTypes>;
  sortingMethods: IFilterMethod<MarketSortingTypes>[];
  marketHistory: HistoryItem[];

  tmpItems: (IMarketplaceKitData | ICaseItemDtoV2)[] | ISteamStoreInventory[];
  tmpMeta: IMarketplaceMetaData | null;
  tmpFilters: IFilterFormContent<MarketSortingTypes>;
  sihDataset: ISihDataset | null;
  customCasesMeta: { min: number; max: number | null };
  loadedCustomCases: number[] | boolean;
}

export const SortingEnum: Record<MarketSortingTypes, number> = {
  [MarketSortingTypes.MAX_PRICE]: 0,
  [MarketSortingTypes.MIN_PRICE]: 1,
  [MarketSortingTypes.MAX_OVERPRICE]: 2,
  [MarketSortingTypes.MIN_OVERPRICE]: 3,
};

const P2P_MARKET_SORTING_TYPES: IFilterMethod<MarketSortingTypes>[] = [
  {
    value: MarketSortingTypes.MAX_PRICE,
    viewValue: 'MAX_PRICE',
    dropdownItem: {
      title: 'BALANCE_WITHDRAWAL.FILTERS.SORT_BY.OPTION_DESC_PRICE',
      name: '',
      icon: {
        type: 'icon',
        src: 'sort-sm-desc--design',
      },
    },
  },
  {
    value: MarketSortingTypes.MIN_PRICE,
    viewValue: 'MIN_PRICE',
    dropdownItem: {
      title: 'BALANCE_WITHDRAWAL.FILTERS.SORT_BY.OPTION_ASC_PRICE',
      name: '',
      icon: {
        type: 'icon',
        src: 'sort-sm-asc--design',
      },
    },
  },

  {
    value: MarketSortingTypes.MAX_OVERPRICE,
    viewValue: 'MAX_OVERPRICE',
    dropdownItem: {
      title: 'BALANCE_WITHDRAWAL.FILTERS.SORT_BY.OPTION_OVERPRICE',
      name: '',
      icon: {
        type: 'icon',
        src: 'sort-sm-desc--design',
      },
    },
  },
  {
    value: MarketSortingTypes.MIN_OVERPRICE,
    viewValue: 'MIN_OVERPRICE',
    dropdownItem: {
      title: 'BALANCE_WITHDRAWAL.FILTERS.SORT_BY.OPTION_UNDERPRICE',
      name: '',
      icon: {
        type: 'icon',
        src: 'sort-sm-asc--design',
      },
    },
  },
];

export const P2P_MARKET_INITIAL_STATE: P2pMarketStateModel = {
  customCaseList: [],
  customCasesMetaData: { min: 0, max: null },

  activeTab: MarketPanel.Purchase,
  isLoading: false,
  actualItems: [],
  meta: null,
  tmpItems: [],
  tmpMeta: null,
  tmpFilters: {
    query: '',
    sortBy: MarketSortingTypes.MAX_PRICE,
    minPrice: null,
    maxPrice: null,
    page: {
      size: 100,
      number: 1,
    },
  },
  filters: {
    query: '',
    sortBy: MarketSortingTypes.MAX_PRICE,
    minPrice: null,
    maxPrice: null,
    exterior: [],
    page: {
      size: 100,
      number: 1,
    },
  },
  sortingMethods: P2P_MARKET_SORTING_TYPES,
  marketHistory: [],
  sihDataset: null,
  customCasesMeta: { min: 0, max: null },
  loadedCustomCases: [],
};
