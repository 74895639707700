<div class="card-wrapper">
  <div class="card-container">
    <div class="tags-container"></div>
    <button class="avatar-container"></button>
    <div class="description-container">
      <button class="description-container__case-info">
        <div class="info-container">
          <div class="name"></div>
        </div>
        <div class="info-container">
          <div class="price loading-gradient">
            <div></div>
          </div>
        </div>
      </button>
    </div>
    <div class="shadow-wrapper"></div>
  </div>
</div>
