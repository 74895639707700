import { IconConfig } from '@dev-fast/types';

export const iconsUser: IconConfig[] = [
  {
    name: 'user',
    path: 'assets/img/mat-icons/user/user.svg',
  },
  {
    name: 'additional-flag',
    path: 'assets/img/mat-icons/user/additional-flag.svg',
  },
  {
    name: 'join-game',
    path: 'assets/img/mat-icons/user/join-game.svg',
  },
  {
    name: 'joker',
    path: 'assets/img/mat-icons/user/joker.svg',
  },
  {
    name: 'winner',
    path: 'assets/img/mat-icons/user/winner.svg',
  },
  {
    name: 'versus',
    path: 'assets/img/mat-icons/user/versus.svg',
  },
];
