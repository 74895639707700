import { ICaseItemDetailedPayload } from '@dev-fast/types';

// Очистка текущего кейса (при закрытии модалки)
export class ClearMarketCasesState {
  static readonly type = '[Market Cases] clear cases state';
}
// Получение подробностей об итеме кейса
export class GetItemDetails {
  static readonly type = '[Market Cases] get item details';
  constructor(public payload: ICaseItemDetailedPayload) {}
}
// Получение подробностей об итеме кейса
export class SetSelectedItemName {
  static readonly type = '[Market Cases] set selected item name';
  constructor(public selectedItemName?: string) {}
}
// Получение кейсов, в которых есть этот итем
export class GetCaseItemAffiliatedCases {
  static readonly type = '[Market Cases] get case item affiliated cases';
  constructor(
    public shortName: string,
    public skin: string,
  ) {}
}
// Получение лотов маркета, в которых есть этот итем
export class GetCaseItemAffiliatedMarketLots {
  static readonly type = '[Market Cases] get case item affiliated p2p lots';
  constructor(public query: string) {}
}
