import { Location } from '@angular/common';
import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { IP2pDepositItem, IP2pPurchaseItem } from '@dev-fast/types';
import { merge, Observable, Subject } from 'rxjs';
import { distinct, map, mapTo, skip, takeUntil } from 'rxjs/operators';

import { P2pBuyingService, P2pDepositService } from '@app/modules/p2p';

import { SectionType } from './p2p.constants';

@Component({
  selector: 'app-p2p',
  styleUrls: ['./p2p.component.scss'],
  templateUrl: './p2p.component.html',
})
export class P2pComponent implements OnDestroy, OnInit {
  selected: FormControl;

  #destroyed$: Subject<void> = new Subject();

  readonly SectionType = SectionType;

  /** Подключение сервисов */
  readonly #location: Location = inject(Location);
  readonly #p2pDepositService: P2pDepositService = inject(P2pDepositService);
  readonly #p2pBuyingService: P2pBuyingService = inject(P2pBuyingService);

  /** Переменные сервисов */
  readonly purchasing$: Observable<IP2pPurchaseItem[]> = this.#p2pBuyingService.purchasing$;
  readonly selected$: Observable<IP2pDepositItem[]> = this.#p2pDepositService.selected$;

  constructor() {
    this.selected = new FormControl(this.#location.path().includes('store') ? SectionType.Purchase : SectionType.Sell);
  }

  /** Методы жизненного цикла */
  ngOnInit(): void {
    this.#subscribeEmitters();
  }

  ngOnDestroy(): void {
    this.#destroyed$.next();
    this.#destroyed$.complete();
  }

  #subscribeEmitters(): void {
    // FIXME всю логику по определению активной вкладки возложить на стейт, тут только отображать

    merge(
      this.selected$.pipe(skip(1), mapTo(SectionType.Sell)),
      this.purchasing$.pipe(
        skip(1),
        map((purchasing) => purchasing?.length),
        distinct(),
        mapTo(SectionType.Purchase),
      ),
    )
      .pipe(takeUntil(this.#destroyed$))
      .subscribe((value) => {
        if (this.selected) {
          this.selected.patchValue(value);
        }
      });
  }
}
