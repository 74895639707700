import { ChangeDetectionStrategy, Component, inject, signal, WritableSignal } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IFormatedMarketplaceItem, IP2pDepositingItemV2, OrderStatusEnum } from '@dev-fast/types';
import { interval, Observable, of, timer } from 'rxjs';
import { filter, map, startWith, switchMap, takeUntil, tap } from 'rxjs/operators';

import { LocalStorageService } from '@app/core/local-storage-service';

import { MIN_TIME_LEFT_FOR_TRADE_MS, PAGES_DEFAULT } from './constants';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-p2p-new-auction-completed-modal',
  templateUrl: './p2p-auction-completed-modal.component.html',
  styleUrls: ['./p2p-auction-completed-modal.component.scss', './p2p-auction-completed-modal.media.scss', '../../styles/modal.scss'],
})
export class P2pNewAuctionCompletedModalComponent {
  readonly offerTimer$: Observable<number>;
  // Template
  templateTypeClass: 'multiple-items' | '' = '';
  isTimerValid = false;
  showLeftArrow = false;
  visiblePagesArray: number[] = PAGES_DEFAULT;
  allowedPagesDict: { [page: number]: IFormatedMarketplaceItem[] } = {};

  lot: IP2pDepositingItemV2;
  currentSteamItem: IFormatedMarketplaceItem | null = null;

  readonly #depositingItem: IP2pDepositingItemV2 = inject(MAT_DIALOG_DATA);
  readonly #dialogRef = inject(MatDialogRef<P2pNewAuctionCompletedModalComponent>);

  // TODO временный блок на пару дней. Удалить потом
  readonly #_storageService = inject(LocalStorageService);
  readonly warningSeen: WritableSignal<boolean | undefined> = signal(false);
  ////
  constructor() {
    // TODO временный блок на пару дней. Удалить потом
    this.warningSeen.set(this.#_storageService.get('auctionCompletedWarningViewed'));
    /////
    if (this.#depositingItem.status !== OrderStatusEnum.WAIT_FOR_TRADE && !this.#depositingItem.items) {
      this.onClose();
    }
    this.lot = { ...this.#depositingItem, items: this.#formatItemSteamPosition(this.#depositingItem.items) };
    this.templateTypeClass = this.#depositingItem.items.length > 1 ? 'multiple-items' : '';
    this.setActiveSteamItem(this.lot.items[0]);
    this.allowedPagesDict = this.#mapAllowedPages(this.lot.items);
    //TODO selling item & purchase item срефачены на moment, т.к. он точнее
    this.offerTimer$ = of(this.#depositingItem).pipe(
      filter(({ nextStatusAt }) => Date.parse(nextStatusAt) > Date.now()),
      switchMap(({ nextStatusAt }) =>
        interval(1000).pipe(
          startWith(Date.parse(nextStatusAt) - Date.now()),
          map(() => Date.parse(nextStatusAt) - Date.now()),
          tap((timeLeft) => {
            this.isTimerValid = timeLeft > MIN_TIME_LEFT_FOR_TRADE_MS;
          }),
          takeUntil(timer(Date.parse(nextStatusAt) - Date.now())),
        ),
      ),
    );
  }
  // TODO временный блок на пару дней. Удалить потом
  onCloseWarning(): void {
    this.warningSeen.set(true);
    this.#_storageService.set('auctionCompletedWarningViewed', true);
  }
  #mapAllowedPages(items: IFormatedMarketplaceItem[]): { [page: number]: IFormatedMarketplaceItem[] } {
    const dict: { [page: number]: IFormatedMarketplaceItem[] } = {};

    items.forEach((item) => {
      if (!dict[item.page]) {
        dict[item.page] = Array(16);
      }
      dict[item.page].splice(this.#calculateLotPosition(item.line, item.number), 1, item);
    });
    return dict;
  }
  // trackBy
  lotItemsTrackBy(index: number, item: IFormatedMarketplaceItem): number {
    return index;
  }

  trackPagesByIndex(index: number, item: number): number {
    return index;
  }

  calcPages(position: number[]): number[] {
    const [page] = position;
    this.showLeftArrow = page > 2;
    return page > 2 ? [page - 2, page - 1, page, page + 1, page + 2] : PAGES_DEFAULT;
  }

  highlightCurentPage(page: number, steamPositionPage: number): boolean {
    return page === steamPositionPage;
  }

  setActiveSteamItem(newItem: IFormatedMarketplaceItem): void {
    this.currentSteamItem = newItem;
    this.visiblePagesArray = this.calcPages(newItem.steamItemPosition);
  }

  onClose(): void {
    this.#dialogRef.close();
  }

  /**
   *
   * @param steamItemPositionX
   * @param steamItemPositionY
   * @returns индекс итема в пустом массиве из 16 элементов, который заполняет грид 4х4
   */
  #calculateLotPosition(steamItemPositionX: number, steamItemPositionY: number): number {
    return steamItemPositionX * 4 - (4 - steamItemPositionY) - 1;
  }

  /**
   *
   * @param items Стандартные маркетовые объекты
   * @returns Тот же массив, но теперь отдельно указаны страница строчка и позиция в строчке каждого итема
   */
  #formatItemSteamPosition(items: IFormatedMarketplaceItem[]): IFormatedMarketplaceItem[] {
    return items.map((depositItem, index) => {
      const [page, line, number] = depositItem.steamItemPosition;
      return { ...depositItem, indicator: index + 1, page, line, number };
    });
  }
}
