<a
  langRouterLink
  [routerLink]="itemConfig.game.routerLink !== undefined ? itemConfig.game.routerLink : '/game/' + itemConfig.game.key"
  [routerLinkActive]="'active-menu-item'"
  [class.tableView]="itemConfig.isTable"
  class="menuItem flex decoration-none"
  *ngIf="itemConfig"
  draggable="false"
>
  <div
    class="icon flex fx-align-center fx-justify-center fx-column"
    [class]="itemConfig.game.key"
  >
    <img
      [src]="'assets/img/mat-icons/left-sidebar/' + itemConfig.game.key + '.webp'"
      alt=""
    />
    <span
      class="subheader"
      *ngIf="itemConfig.isTable && !itemConfig.isNavbar"
      >{{ itemConfig.game.title }}</span
    >
  </div>

  <div
    class="title flex fx-column fx-justify-center tw-font-normal"
    [class.popup-title]="itemConfig.isNavbar"
    *ngIf="!itemConfig.isTable"
  >
    {{ itemConfig.game.title }}
    <span
      class="title-subheader"
      *ngIf="itemConfig.status"
    >
      {{ itemConfig.status.title }}
      <span class="menu-item__value">
        <ng-container *ngIf="!itemConfig.status.notCurrencyValue; else notCurrencyTemplate">
          <span class="coins">
            {{ itemConfig.status.value | appCurrency }}
          </span>
        </ng-container>
        <ng-template #notCurrencyTemplate>
          {{ itemConfig.status.value }}
        </ng-template>
      </span>
    </span>
  </div>
</a>
