import {
  DFormControlType,
  IDFormControlCheckbox,
  IDFormControlCheckboxList,
  IDFormControlNumberFromToWithOptions,
  IDFormControlRangeFromTo,
} from './dynamic-forms.interface';

export const KNIFES = [
  'Bayonet',
  'Bowie Knife',
  'Butterfly Knife',
  'Classic Knife',
  'Falchion Knife',
  'Flip Knife',
  'Gut Knife',
  'Huntsman Knife',
  'Karambit',
  'M9 Bayonet',
  'Navaja Knife',
  'Nomad Knife',
  'Paracord Knife',
  'Shadow Daggers',
  'Skeleton Knife',
  'Stiletto Knife',
  'Survival Knife',
  'Talon Knife',
  'Ursus Knife',
];

export const GLOVES = [
  'Bloodhound Gloves',
  'Broken Fang Gloves',
  'Hand Wraps',
  'Driver Gloves',
  'Hydra Gloves',
  'Moto Gloves',
  'Specialist Gloves',
  'Sport Gloves',
];

export const PISTOLS = [
  'CZ75-Auto',
  'Desert Eagle',
  'Dual Berettas',
  'Five-SeveN',
  'Glock-18',
  'P2000',
  'P250',
  'R8 Revolver',
  'Tec-9',
  'USP-S',
];

export const SMGS = ['MAC-10', 'MP7', 'MP9', 'P90', 'PP-Bizon', 'UMP-45', 'MP5-SD'];

export const RIFLES = ['AK-47', 'AUG', 'FAMAS', 'Galil AR', 'M4A4', 'M4A1-S', 'SG 553'];

export const SHOTGUNS = ['MAG-7', 'Nova', 'Sawed-Off', 'XM1014'];

export const MACHINEGUNS = ['Negev', 'M249'];

export const SNIPER_RIFLES = ['AWP', 'G3SG1', 'SCAR-20', 'SSG 08'];

export const OTHER_ITEMS = ['collectible', 'container', 'graffiti', 'key', 'music kit', 'pass', 'gift'];

export const CATEGORIES = ['normal', 'souvenir', 'stattrak'];

export const PHASES = ['Emerald', 'Sapphire', 'Ruby', 'Black Pearl', 'Phase 1', 'Phase 2', 'Phase 3', 'Phase 4'];

export const EXTERIORS = ['Factory New', 'Minimal Wear', 'Field-Tested', 'Well-Worn', 'Battle-Scarred'];

export const KNIFES_CHECKBOX_LIST: IDFormControlCheckboxList = {
  name: 'knife',
  type: DFormControlType.CheckboxList,
  hasGroupCheckbox: true,
  localeKey: 'KNIFE',
  outputData: {
    checkboxKey: 'weapon',
    checkedValue: 'Knife',
  },
  controls: KNIFES.map((knife) => ({
    type: DFormControlType.Checkbox,
    name: 'kinfe-' + knife.replace(/ /g, '-').toLowerCase(),
    localeKey: knife.replace(/ Knife$/, ''),
    checked: false,
    outputData: {
      checkboxKey: 'weaponType',
      checkedValue: knife,
    },
  })),
};

export const GLOVES_CHECKBOX_LIST: IDFormControlCheckboxList = {
  name: 'gloves',
  type: DFormControlType.CheckboxList,
  hasGroupCheckbox: true,
  localeKey: 'GLOVES',
  outputData: {
    checkboxKey: 'weapon',
    checkedValue: 'gloves',
  },
  controls: GLOVES.map((gloves) => ({
    type: DFormControlType.Checkbox,
    name: 'gloves-' + gloves.replace(/ /g, '-').toLowerCase(),
    localeKey: gloves.replace(/ Gloves$/, ''),
    checked: false,
    outputData: {
      checkboxKey: 'glovesType',
      checkedValue: gloves,
    },
  })),
};

export const PISTOLS_CHECKBOX_LIST: IDFormControlCheckboxList = {
  type: DFormControlType.CheckboxList,
  name: 'pistols',
  localeKey: 'PISTOL',
  hasGroupCheckbox: true,
  outputData: {
    checkboxKey: 'weapon',
    checkedValue: 'Pistol',
  },
  controls: PISTOLS.map((pistol) => ({
    type: DFormControlType.Checkbox,
    name: 'pistol-' + pistol.replace(/ /g, '-').toLowerCase(),
    localeKey: pistol,
    checked: false,
    outputData: {
      checkboxKey: 'weaponType',
      checkedValue: pistol,
    },
  })),
};

export const SMG_CHECKBOX_LIST: IDFormControlCheckboxList = {
  type: DFormControlType.CheckboxList,
  name: 'smg',
  localeKey: 'SMG',
  hasGroupCheckbox: true,
  outputData: {
    checkboxKey: 'weapon',
    checkedValue: 'SMG',
  },
  controls: SMGS.map((smg) => ({
    type: DFormControlType.Checkbox,
    name: 'smg-' + smg.replace(/ /g, '-').toLowerCase(),
    localeKey: smg,
    checked: false,
    outputData: {
      checkboxKey: 'weaponType',
      checkedValue: smg,
    },
  })),
};

export const RIFLE_CHECKBOX_LIST: IDFormControlCheckboxList = {
  type: DFormControlType.CheckboxList,
  name: 'rifle',
  localeKey: 'RIFLE',
  hasGroupCheckbox: true,
  outputData: {
    checkboxKey: 'weapon',
    checkedValue: 'Rifle',
  },
  controls: RIFLES.map((riffle) => ({
    type: DFormControlType.Checkbox,
    name: 'riffle-' + riffle.replace(/ /g, '-').toLowerCase(),
    localeKey: riffle,
    checked: false,
    outputData: {
      checkboxKey: 'weaponType',
      checkedValue: riffle,
    },
  })),
};

export const SHOTGUN_CHECKBOX_LIST: IDFormControlCheckboxList = {
  type: DFormControlType.CheckboxList,
  name: 'shotgun',
  localeKey: 'SHOTGUN',
  hasGroupCheckbox: true,
  outputData: {
    checkboxKey: 'weapon',
    checkedValue: 'Shotgun',
  },
  controls: SHOTGUNS.map((shotgun) => ({
    type: DFormControlType.Checkbox,
    name: 'shotgun-' + shotgun.replace(/ /g, '-').toLowerCase(),
    localeKey: shotgun,
    checked: false,
    outputData: {
      checkboxKey: 'weaponType',
      checkedValue: shotgun,
    },
  })),
};

export const MACHINEGUN_CHECKBOX_LIST: IDFormControlCheckboxList = {
  type: DFormControlType.CheckboxList,
  name: 'machinegun',
  localeKey: 'MACHINEGUN',
  hasGroupCheckbox: true,
  outputData: {
    checkboxKey: 'weapon',
    checkedValue: 'Machinegun',
  },
  controls: MACHINEGUNS.map((machinegun) => ({
    type: DFormControlType.Checkbox,
    name: 'machinegun-' + machinegun.replace(/ /g, '-').toLowerCase(),
    localeKey: machinegun,
    checked: false,
    outputData: {
      checkboxKey: 'weaponType',
      checkedValue: machinegun,
    },
  })),
};

export const SNIPER_RIFLE_CHECKBOX_LIST: IDFormControlCheckboxList = {
  type: DFormControlType.CheckboxList,
  name: 'sniper-rifle',
  localeKey: 'SNIPER_RIFLE',
  hasGroupCheckbox: true,
  outputData: {
    checkboxKey: 'weapon',
    checkedValue: 'Sniper Rifle',
  },
  controls: SNIPER_RIFLES.map((sniperRifle) => ({
    type: DFormControlType.Checkbox,
    name: 'sniper-rifle-' + sniperRifle.replace(/ /g, '-').toLowerCase(),
    localeKey: sniperRifle,
    checked: false,
    outputData: {
      checkboxKey: 'weaponType',
      checkedValue: sniperRifle,
    },
  })),
};

export const AGENT_CHECKBOX: IDFormControlCheckbox = {
  type: DFormControlType.Checkbox,
  name: 'agent',
  localeKey: 'AGENT',
  checked: false,
  outputData: {
    checkboxKey: 'weapon',
    checkedValue: 'agent',
  },
};

export const OTHER_CHECKBOX_LIST: IDFormControlCheckboxList = {
  type: DFormControlType.CheckboxList,
  name: 'other',
  localeKey: 'OTHER',
  hasGroupCheckbox: true,
  outputData: {
    checkboxKey: 'weapon',
    checkedValue: 'other',
  },
  controls: OTHER_ITEMS.map((other) => ({
    type: DFormControlType.Checkbox,
    name: 'other-' + other.replace(/ /g, '-').toLowerCase(),
    localeKey: other,
    checked: false,
    outputData: {
      checkboxKey: 'otherType',
      checkedValue: other,
    },
  })),
};

export const WEAPONS_CHECKBOX_LIST: IDFormControlCheckboxList = {
  name: 'type',
  type: DFormControlType.CheckboxList,
  hasGroupCheckbox: false,
  localeKey: 'TYPE',
  controls: [
    KNIFES_CHECKBOX_LIST,
    GLOVES_CHECKBOX_LIST,
    PISTOLS_CHECKBOX_LIST,
    SMG_CHECKBOX_LIST,
    RIFLE_CHECKBOX_LIST,
    SHOTGUN_CHECKBOX_LIST,
    MACHINEGUN_CHECKBOX_LIST,
    SNIPER_RIFLE_CHECKBOX_LIST,
    AGENT_CHECKBOX,
    OTHER_CHECKBOX_LIST,
  ],
};

export const CATEGORY_CHECKBOX_LIST: IDFormControlCheckboxList = {
  name: 'category',
  type: DFormControlType.CheckboxList,
  hasGroupCheckbox: false,
  localeKey: 'CATEGORY',
  controls: CATEGORIES.map((category) => ({
    type: DFormControlType.Checkbox,
    name: 'category-' + category.replace(/ /g, '-').toLowerCase(),
    localeKey: category.toUpperCase(),
    checked: false,
    outputData: {
      checkboxKey: 'category',
      checkedValue: category,
    },
  })),
};

export const PHASE_CHECKBOX_LIST: IDFormControlCheckboxList = {
  type: DFormControlType.CheckboxList,
  name: 'phase',
  localeKey: 'PHASE',
  hasGroupCheckbox: false,
  controls: PHASES.map((phase) => ({
    type: DFormControlType.Checkbox,
    name: 'phase-' + phase.replace(/ /g, '-').toLowerCase(),
    localeKey: phase.replace(/[ -]/g, '_').toUpperCase(),
    checked: false,
    outputData: {
      checkboxKey: 'phase',
      checkedValue: phase,
    },
  })),
};

export const EXTERIOR_CHECKBOX_LIST: IDFormControlCheckboxList = {
  type: DFormControlType.CheckboxList,
  name: 'exterior',
  localeKey: 'EXTERIOR',
  hasGroupCheckbox: false,
  controls: EXTERIORS.map((exterior) => ({
    type: DFormControlType.Checkbox,
    name: 'exterior-' + exterior.replace(/ /g, '-').toLowerCase(),
    localeKey: exterior.replace(/[ -]/g, '_').toUpperCase(),
    checked: false,
    outputData: {
      checkboxKey: 'exterior',
      checkedValue: exterior,
    },
  })),
};

export const INVENTORY_SETTINGS_CHECKBOX: IDFormControlCheckbox = {
  type: DFormControlType.Checkbox,
  name: 'exclude-non-sale',
  localeKey: 'EXCLUDE_NON_SALE',
  checked: false,
  outputData: {
    checkboxKey: 'excludeNonSale',
    checkedValue: 'true',
  },
};

export const OVER_PRISE_RANGE: IDFormControlRangeFromTo = {
  type: DFormControlType.RangeFromTo,
  name: 'overprice',
  localeKey: 'OVERPICE',
  min: -30,
  max: 120,
  initMin: -30,
  initMax: 100,
  outputData: {
    minValueKey: 'minOverprice',
    maxValueKey: 'maxOverprice',
  },
};

export const SKINS_PRICE_NUMBER_OPTIONS: IDFormControlNumberFromToWithOptions = {
  type: DFormControlType.NumberFromToWithOptions,
  localeKey: 'PRICE',
  name: 'price',
  minMaxOptions: [
    { from: 0, to: 99 },
    { from: 100, to: 149 },
    { from: 150, to: 199 },
    { from: 200, to: 499 },
    { from: 500, to: null },
  ],
  min: null,
  max: null,
  initMin: 0,
  initMax: null,
  outputData: {
    minValueKey: 'minPrice',
    maxValueKey: 'maxPrice',
  },
};
