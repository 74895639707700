import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-ui-trades-stub',
  standalone: true,
  imports: [CommonModule, TranslateModule, MatIconModule],
  templateUrl: './trades-stub.component.html',
  styleUrls: ['./trades-stub.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TradesStubComponent {
  @Output() authEvent: EventEmitter<void> = new EventEmitter();
  @Output() closePanelEvent: EventEmitter<void> = new EventEmitter();

  authorize(): void {
    this.authEvent.emit();
  }
  closePanel(): void {
    this.closePanelEvent.emit();
  }
}
