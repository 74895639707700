import { Injectable } from '@angular/core';
import { IWithdrawalMethods } from '@dev-fast/types';
import { Observable } from 'rxjs';

import { Environments, EnvironmentService } from '@app/core/environment-service';

import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class TransactionApiService {
  private environment: Environments;

  constructor(private readonly api: ApiService, private readonly environmentService: EnvironmentService) {
    this.environment = this.environmentService.environments;
  }
  public getMethods(userId: number): Observable<{ success: boolean; methods: IWithdrawalMethods }> {
    return this.api.get<{ success: boolean; methods: IWithdrawalMethods }>(`${this.environment.PAYMENT_URL}/payout-methods/${userId}`, {
      withCredentials: true,
    });
  }
}
