import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { DropCardComponentModule, UserAvatarModule } from '@dev-fast/ui-components';
import { TranslateModule } from '@ngx-translate/core';

import { AppCurrencyModule } from '@app/shared/pipes';

import { RecentActivitiesService } from '../../recent-activities.service';
import { MotivatorWidgetMockComponent } from './mock/motivator-widget.component.mock';
import { MotivatorWidgetComponent } from './motivator-widget.component';

@NgModule({
  imports: [CommonModule, MatIconModule, TranslateModule, AppCurrencyModule, UserAvatarModule, DropCardComponentModule],
  declarations: [MotivatorWidgetComponent, MotivatorWidgetMockComponent],
  exports: [MotivatorWidgetComponent, MotivatorWidgetMockComponent],
  providers: [RecentActivitiesService],
})
export class MotivatorWidgetModule {}
