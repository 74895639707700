<div
  class="tw-flex tw-items-center tw-gap-3 tw-relative"
  *ngIf="items && items.length"
>
  <div class="tw-flex tw-justify-center tw-items-center tw-self-stretch tw-gap-3 tw-relative tw-min-w-20">
    <ng-container
      *ngIf="stickersLeft"
      [ngTemplateOutlet]="stickersTemplate"
    ></ng-container>

    <ng-container *ngFor="let skin of items | slice: 0 : 2">
      <img
        class="tw-h-[3.4375rem] tw-min-w-[4.625rem]"
        [alt]="skin.baseItem.skin"
        [src]="skin.baseItem.icon | hash"
      />
    </ng-container>
  </div>

  <ng-container *ngIf="items.length > 1; else soloItemTemplate"
    ><div
      class="lot-card__more-btn action-menu-btn flex fx-justify-center fx-align-center tw-font-bold"
      (click)="showItemDetailsModal()"
    >
      <ng-container *ngIf="kitLengthWithoutShownItems > 0; else infoBtnTemplate"> +{{ kitLengthWithoutShownItems }}</ng-container>
      <ng-template #infoBtnTemplate><mat-icon svgIcon="information"></mat-icon></ng-template>
    </div>
  </ng-container>

  <ng-container
    *ngIf="!stickersLeft"
    [ngTemplateOutlet]="stickersTemplate"
  ></ng-container>

  <ng-template #soloItemTemplate>
    <div class="lot-card__info flex fx-column fx-justify-center">
      <div class="lot-card__info-type tw-font-normal pb-sm-1">{{ lotShortName | translate }}</div>
      <div class="lot-card__info-name tw-font-medium pb-1">
        <ng-container *ngIf="items[0].baseItem.skin; else noNameTmpl">
          {{ items[0].baseItem.skin }}
        </ng-container>
        <ng-template #noNameTmpl> {{ items[0].baseItem.name }} </ng-template>
      </div>
      <app-ui-float-indicator
        class="lot-card__info-exterior tw-font-normal"
        [item]="items[0]"
      >
      </app-ui-float-indicator>
      <div
        class="lot-card__info-price pt-1 tw-font-medium"
        *ngIf="!isHidePrice"
      >
        <app-currency [value]="items[0].price" />
        <ng-container *ngIf="items[0].extra && items[0].extra.increase">
          <span
            class="modifier"
            [ngClass]="{ positive: items[0].extra.increase > 0, negative: items[0].extra.increase < -4 }"
          >
            {{ items[0].extra.increase > 0 ? '+' : '' }}{{ items[0].extra.increase }}%
          </span>
        </ng-container>
      </div>
    </div>
  </ng-template>

  <!-- Шаблон контейнера со стикерами -->
  <ng-template #stickersTemplate>
    <ng-container *ngIf="items.length === 1">
      <div
        class="tw-flex tw-justify-end tw-flex-wrap tw-absolute tw-top-0"
        [class.tw-left-0]="stickersLeft"
        [class.tw-right-0]="!stickersLeft"
      >
        <ng-container *ngFor="let sticker of items[0].stickers">
          <ng-container *ngIf="sticker.icon">
            <div
              [class.tw-size-5]="stickersLeft"
              [class.tw-size-6]="!stickersLeft"
            >
              <img
                alt=""
                class="tw-size-full"
                [src]="sticker.icon | stickerHash"
              />
            </div>
          </ng-container>
        </ng-container>
      </div>
    </ng-container>
  </ng-template>
</div>
