<div
  class="md:tw-p-x-3 md:tw-p-y-2 item-simple-card tw-p-2 tw-flex tw-flex-col tw-justify-between md:tw-justify-end tw-items-center tw-gap-2 tw-w-full tw-h-full tw-cursor-pointer tw-relative tw-rounded"
  [class.active-background]="isSelected"
  [style.background]="isSelected ? activeBackgroundColor() : backgroundColor()"
  (click)="handlerCard()"
>
  <ng-container *ngIf="isElementVisible()">
    <div
      *ngIf="status !== CardStatusEnum.DEFAULT"
      class="card-status tw-w-full tw-h-full tw-absolute tw-z-20 tw-top-0"
      [ngClass]="statusClass"
    >
      <mat-icon
        *ngIf="statusIcon"
        class="tw-absolute"
        [svgIcon]="statusIcon"
      />
      <div
        *ngIf="timestamp && status === CardStatusEnum.TIMER"
        class="timer-wrapper tw-flex tw-items-center tw-gap-1 tw-relative tw-p-2"
      >
        <app-ui-spinner-loader></app-ui-spinner-loader>
        {{ placeholderText | translate }}
      </div>
    </div>
    <div
      class="tw-w-full tw-h-full tw-absolute tw-z-0 tw-transition-opacity tw-ease-in-out tw-duration-300 tw-opacity-0 tw-rounded tw-top-0 hover:tw-opacity-100"
      [style.background]="hoverBackgroundColor()"
    ></div>
    <div class="tw-flex tw-grow-0 tw-shrink-1 tw-basis-auto tw-h-full tw-min-h-0">
      <img
        *ngIf="icon"
        [src]="icon | hash"
        loading="lazy"
        unselectable="on"
        alt=""
      />
    </div>
    <div class="tw-flex tw-flex-col tw-gap-1 md:tw-gap-2 tw-items-center tw-justify-center tw-w-full tw-pb-1">
      <div class="item-name tw-font-normal tw-w-full tw-z-10 md:!tw-text-sm">{{ name }}</div>
      <app-currency
        class="tw-z-10 !tw-text-sm tw-font-normal"
        [value]="price"
        [delimiterRest]="','"
      />
    </div>
  </ng-container>
</div>
