<ng-container *ngIf="item$ | async as item">
  <!-- {{ item.status }} -->
  <!-- <span
    class="icomoon icon-close"
    (click)="onClose(item.id)"
    *ngIf="statuses.NEW === item.status"
  ></span> -->

  <!-- auc in progress -->
  <div
    class="purchase-indicator"
    *ngIf="[statuses.NEW, statuses.FIRST_BID, statuses.SECOND_BID, statuses.THIRD_PLUS_BID].includes(item.status)"
  >
    <div class="purchase-indicator__icon">
      <mat-spinner
        [diameter]="14"
        color="accent"
      ></mat-spinner>
    </div>
    <div class="purchase-indicator__text">{{ 'P2P_WIDGETS.PURCHASE.UNTIL_TIME' | translate }}</div>
    <button
      class="icon-close"
      (click)="onClose(item.id)"
      *ngIf="statuses.NEW === item.status"
    >
      <mat-icon svgIcon="close"> </mat-icon>
    </button>

    <ng-container *ngIf="offerTimer$ | async as offerTimer">
      <span class="purchase-indicator__text countdown">
        <mat-icon
          class="p2p-timer"
          svgIcon="p2p-timer"
        >
        </mat-icon>
        <!-- <mat-icon svgIcon="p2p-timer-icon"></mat-icon> -->
        <!-- <app-ui-countdown
          class="p2p-purchase-modal__until-time__timer"
          [until]="offerTimer"
          [reset]="false"
          [minutes]="0.92"
        >
        </app-ui-countdown> -->
        {{ offerTimer | date : 'mm:ss' }}
      </span>
    </ng-container>
  </div>

  <div
    class="purchase-indicator"
    *ngIf="![statuses.NEW, statuses.FIRST_BID, statuses.SECOND_BID, statuses.THIRD_PLUS_BID].includes(item.status)"
  >
    <ng-container
      *ngIf="
        ([statuses.WAIT_FOR_TRADE, statuses.WAIT_FOR_CONFIRM, statuses.WAIT_FOR_BUYER_ACCEPT].includes(item.status) &&
          item.isLastBidByUser) ||
          [statuses.COMPLETED].includes(item.status);
        else status_alert
      "
    >
      <div class="purchase-indicator__icon">
        <mat-icon svgIcon="p2p-status-success"></mat-icon>
      </div>
      <div class="purchase-indicator__text text-color-success">
        {{
          [statuses.COMPLETED].includes(item.status)
            ? ('P2P_WIDGETS.PURCHASE.OFFER_PHASES.OFFER_COMPLETED' | translate)
            : ('P2P_WIDGETS.PURCHASE.OFFER_PHASES.OFFER_SUCCESS' | translate)
        }}
      </div>
    </ng-container>
    <ng-template #status_alert>
      <div
        class="purchase-indicator__icon"
        [class.color-warning]="[statuses.AUCTION_FINISHED].includes(item.status)"
      >
        <ng-container *ngIf="[statuses.CRATED].includes(item.status); else IconTempalte">
          <mat-spinner
            [diameter]="14"
            color="accent"
          ></mat-spinner>
        </ng-container>
        <ng-template #IconTempalte>
          <mat-icon svgIcon="p2p-status-alert"></mat-icon>
        </ng-template>
      </div>
      <div
        class="purchase-indicator__text"
        *ngIf="[statuses.CRATED].includes(item.status)"
      >
        {{ 'P2P_WIDGETS.PURCHASE.OFFER_PHASES.OFFER_PROCESSED' | translate }}
      </div>
      <div
        class="purchase-indicator__text"
        *ngIf="
          [statuses.WAIT_FOR_TRADE, statuses.WAIT_FOR_CONFIRM, statuses.WAIT_FOR_BUYER_ACCEPT].includes(item.status) &&
          !item.isLastBidByUser
        "
      >
        {{ 'P2P_WIDGETS.PURCHASE.OFFER_PHASES.OFFER_LOOSE' | translate }}
      </div>
      <div
        class="purchase-indicator__text color-danger"
        *ngIf="
          [
            statuses.CANCELED_BY_BUYER,
            statuses.CANCELED_BY_SELLER,
            statuses.CANCELED_BY_FASTOFFER,
            statuses.CANCELED_BY_SELLER_BEFORE_TRADE
          ].includes(item.status)
        "
      >
        {{ 'P2P_WIDGETS.PURCHASE.OFFER_PHASES.OFFER_CANCELED' | translate }}
      </div>
      <div
        class="purchase-indicator__text color-danger"
        *ngIf="[statuses.CANCELED_BY_TIMER].includes(item.status)"
      >
        {{ 'P2P_WIDGETS.PURCHASE.OFFER_PHASES.OFFER_CANCELED_BY_TIMER' | translate }}
      </div>
      <div
        class="purchase-indicator__text color-warning"
        *ngIf="[statuses.AUCTION_FINISHED].includes(item.status)"
      >
        {{ 'P2P_WIDGETS.PURCHASE.OFFER_PHASES.OFFER_LOOSE' | translate }}
      </div>
    </ng-template>
  </div>

  <!-- offer success header-->
  <!-- <div
    class="purchase-indicator"
    *ngIf="
      [statuses.WAIT_FOR_TRADE, statuses.WAIT_FOR_CONFIRM, statuses.WAIT_FOR_BUYER_ACCEPT].includes(item.status) && item.isLastBidByUser
    "
  >
    <div class="purchase-indicator__icon">
      <mat-icon svgIcon="p2p-phase-success-icon"></mat-icon>
    </div>
    <div class="purchase-indicator__text text-color-success">{{ 'P2P_WIDGETS.PURCHASE.OFFER_PHASES.OFFER_SUCCESS' | translate }}</div>
  </div> -->
  <!-- auc loose header-->
  <!-- <div
    class="purchase-indicator"
    *ngIf="
      [statuses.WAIT_FOR_TRADE, statuses.WAIT_FOR_CONFIRM, statuses.WAIT_FOR_BUYER_ACCEPT].includes(item.status) && !item.isLastBidByUser
    "
  >
    <div class="purchase-indicator__icon">
      <mat-icon svgIcon="p2p-status-alert"></mat-icon>
    </div>
    <div class="purchase-indicator__text">{{ 'P2P_WIDGETS.PURCHASE.OFFER_PHASES.OFFER_LOOSE' | translate }}</div>
  </div> -->
  <!-- canceled by timer -->
  <!-- <div
    class="purchase-indicator"
    *ngIf="[statuses.CANCELED_BY_TIMER].includes(item.status)"
  >
    <div class="purchase-indicator__icon">
      <mat-icon svgIcon="p2p-status-alert"></mat-icon>
    </div>
    <div class="purchase-indicator__text color-danger">{{ 'P2P_WIDGETS.PURCHASE.OFFER_PHASES.OFFER_CANCELED_BY_TIMER' | translate }}</div>
  </div> -->
  <!-- canceled by users -->
  <!-- <div
    class="purchase-indicator"
    *ngIf="
      [
        statuses.CANCELED_BY_BUYER,
        statuses.CANCELED_BY_SELLER,
        statuses.CANCELED_BY_FASTOFFER,
        statuses.CANCELED_BY_SELLER_BEFORE_TRADE
      ].includes(item.status)
    "
  >
    <div class="purchase-indicator__icon">
      <mat-icon svgIcon="p2p-status-alert"></mat-icon>
    </div>
    <div class="purchase-indicator__text color-danger">{{ 'P2P_WIDGETS.PURCHASE.OFFER_PHASES.OFFER_CANCELED' | translate }}</div>
  </div> -->
  <!-- order completed -->
  <!-- <div
    class="purchase-indicator"
    *ngIf="[statuses.COMPLETED].includes(item.status)"
  >
    <div class="purchase-indicator__icon">
      <mat-icon svgIcon="p2p-status-success"></mat-icon>
    </div>
    <div class="purchase-indicator__text text-color-success">{{ 'P2P_WIDGETS.PURCHASE.OFFER_PHASES.OFFER_COMPLETED' | translate }}</div>
  </div> -->
  <!-- auc finished -->
  <!-- <div
    class="purchase-indicator"
    *ngIf="[statuses.AUCTION_FINISHED].includes(item.status)"
  >
    <div class="purchase-indicator__icon color-warning">
      <mat-icon svgIcon="p2p-status-alert"></mat-icon>
    </div>
    <div class="purchase-indicator__text color-warning">{{ 'P2P_WIDGETS.PURCHASE.OFFER_PHASES.OFFER_LOOSE' | translate }}</div>
  </div> -->

  <a
    class="buying-lot"
    langRouterLink
    routerLink="store"
  >
    <div class="buying-lot__thumbnail">
      <img
        [src]="item.baseItem.icon"
        [alt]="item.baseItem.skin"
      />
    </div>
    <div class="buying-lot__details">
      <div class="display-name">
        <ng-container *ngIf="skinNameConstructor(item.baseItem) as skinNameData; else noShortNameTemplate">
          <ng-container *ngIf="skinNameData.attribute">
            <span [class]="skinNameData.attribute">{{ skinNameData.attribute }}</span> |
          </ng-container>
          {{ skinNameData.name }}
        </ng-container>
        <ng-template #noShortNameTemplate> {{ item.baseItem.name | replace : '(.*)(\\(.*\\))(.*)' : '$1' }}</ng-template>
      </div>
      <div class="display-skin">{{ item.baseItem.exterior }}</div>
      <!-- <div
        class="price"
        *ngIf="[statuses.NEW, statuses.FIRST_BID, statuses.SECOND_BID, statuses.THIRD_PLUS_BID].includes(item.status)"
      >
        <span>
          {{ item.recommendedPrice | appCurrency }}
        </span>
      </div> -->
    </div>
  </a>
  <div class="buying-lot__price">
    <!-- <div
  class="buying-lot__price"
  *ngIf="
    [
      statuses.NEW,
      statuses.FIRST_BID,
      statuses.SECOND_BID,
      statuses.THIRD_PLUS_BID,
      statuses.WAIT_FOR_TRADE,
      statuses.WAIT_FOR_CONFIRM,
      statuses.WAIT_FOR_BUYER_ACCEPT,
      statuses.AUCTION_FINISHED,
    ].includes(item.status)
  "
> -->
    <div class="buing-lot__current-offer __price">
      <span class="price-header">
        <ng-container *ngIf="item.price; else waiting_for_offers">
          <ng-container *ngIf="item.isLastBidByUser; else current_offer">
            {{ 'P2P_WIDGETS.PURCHASE.YOUR_OFFER' | translate }}
          </ng-container>
          <ng-template #current_offer> {{ 'P2P_WIDGETS.PURCHASE.CURRENT_OFFER' | translate }} </ng-template>:
        </ng-container>
        <ng-template #waiting_for_offers>
          {{ 'P2P_WIDGETS.PURCHASE.OFFER_WAITING' | translate }}
        </ng-template>
      </span>
      <!-- TODO -->
      <div style="align-items: center">
        <span class="user-offer price-amount">{{ item.price | appCurrency }} </span>
        <!-- <div class="current-offer__amount">
          <ng-container *ngIf="item.recommendedPrice">
            <div
              class="icon"
              *ngIf="item.recommendedPrice === item.price"
            >
            </div>
            <div
              style="width: 0.65rem; height: 0.65rem"
              class="icon up-icon"
              *ngIf="item.price > item.recommendedPrice"
            >
              <mat-icon
                svgIcon="market-price-direction"
                style="width: 100%; height: 100%"
              ></mat-icon>
            </div>
            <div
              style="width: 0.65rem; height: 0.65rem"
              class="icon down-icon"
              *ngIf="item.price < item.recommendedPrice"
            >
              <mat-icon
                svgIcon="market-price-direction"
                style="width: 100%; height: 100%"
              ></mat-icon>
            </div>
          </ng-container>
        </div> -->
      </div>
    </div>
    <div class="__price">
      <span class="price-header">{{ 'P2P_WIDGETS.PURCHASE.OFFER_CONDITIONS.RECOMMENDED_PRICE_MESSAGE' | translate }} :</span>
      <span> {{ item.recommendedPrice | appCurrency }} </span>
    </div>
  </div>

  <!-- bidings -->
  <div
    class="buying-lot__price buying-actions border-top"
    *ngIf="[statuses.NEW, statuses.FIRST_BID, statuses.SECOND_BID, statuses.THIRD_PLUS_BID].includes(item.status)"
  >
    <div class="buing-lot__current-offer __price">
      <span class="price-header">
        {{ 'P2P_WIDGETS.PURCHASE.BID_AMOUNT' | translate }}
      </span>
      <!-- TODO -->
      <div style="align-items: center">
        <span class="user-offer price-amount">{{ item.price | appCurrency }} </span>
      </div>
    </div>

    <!-- <div class="current-offer">
      <ng-container *ngIf="item.isLastBidByUser; else current_offer">
        <div class="current-offer__title">
          {{ 'P2P_WIDGETS.PURCHASE.YOUR_OFFER' | translate }}
        </div>
      </ng-container>
      <ng-template #current_offer>
        <div class="current-offer__title">
          {{ 'P2P_WIDGETS.PURCHASE.CURRENT_OFFER' | translate }}
        </div>
      </ng-template>
      <div class="current-offer__amount">
        <ng-container *ngIf="item.recommendedPrice">
          <div
            class="icon"
            *ngIf="item.recommendedPrice === item.price"
          >
            <mat-icon svgIcon="p2p-dash-icon"></mat-icon>
          </div>
          <div
            class="icon up-icon"
            *ngIf="item.price > item.recommendedPrice"
          >
            <mat-icon svgIcon="p2p-up-arrow-icon"></mat-icon>
          </div>
          <div
            class="icon down-icon"
            *ngIf="item.price < item.recommendedPrice"
          >
            <mat-icon svgIcon="p2p-down-arrow-icon"></mat-icon>
          </div>
        </ng-container>
        <span class="value">
          {{ item.price | appCurrency }}
        </span>
      </div>
    </div> -->

    <div
      class="buying-actions__controllers"
      *ngIf="!item.isLastBidByUser"
    >
      <ng-container *ngIf="permissions$ | async as permissions">
        <div class="bid-action">
          <ng-container *ngIf="permissions?.error === steamErrors.UNBINDING_STEAM; else tradeLayout">
            <button
              class="btn btn-primary"
              (click)="bindingSocial(socialType.Steam)"
            >
              {{ 'P2P_WIDGETS.BIND_STEAM' | translate }}
            </button>
          </ng-container>
          <ng-template #tradeLayout>
            <ng-container [ngSwitch]="permissions.canTrade?.error">
              <a
                *ngSwitchCase="steamErrors.STEAM_GUARD"
                class="btn btn-primary"
                href="https://store.steampowered.com/mobile"
                target="_blank"
              >
                {{ 'P2P_WIDGETS.PURCHASE.ADD_STEAM_GUARD' | translate }}
              </a>
              <button
                *ngSwitchCase="steamErrors.STEAM_GUARD_HOLD"
                class="btn btn-primary"
                disabled
              >
                {{ 'P2P_WIDGETS.PURCHASE.STEAM_GUARD_HOLD' | translate : { days: permissions.canTrade?.days || 0 } }}
              </button>
              <button
                *ngSwitchCase="steamErrors.STEAM_GUARD_HOLD"
                class="btn btn-primary"
                disabled
              >
                {{ 'P2P_WIDGETS.PURCHASE.YOU_ARE_BANNED' | translate }}
              </button>
              <button
                *ngSwitchCase="steamErrors.INVALID_TRADELINK || steamErrors.NO_API_KEY"
                class="btn btn-primary"
                (click)="onSetupSteam()"
              >
                {{ 'P2P_WIDGETS.ADD_TRADE_LINK' | translate }}
              </button>
              <a
                *ngSwitchCase="steamErrors.PRIVATE_INVENTORY"
                class="btn btn-primary"
                href="https://steamcommunity.com/my/edit/settings"
                target="_blank"
              >
                {{ 'BALANCE_REFILL.P2P.ALERTS.UNLOCK_STEAM_ACTION' | translate }}
              </a>
              <ng-container *ngSwitchDefault>
                <!-- <div class="offer">
                  <div
                    class="offer__icon tooltip"
                    [ngSwitch]="bidCondition$ | async"
                  >
                    <ng-container *ngSwitchCase="bibConditions.RECOMMEND_PRICE">
                      <mat-icon svgIcon="p2p-dash-icon"></mat-icon>
                      <div class="tooltip-text left">
                        {{ 'P2P_WIDGETS.PURCHASE.OFFER_CONDITIONS.RECOMMENDED_PRICE_MESSAGE' | translate }}
                      </div>
                    </ng-container>
                    <ng-container *ngSwitchCase="bibConditions.OVERPRICE">
                      <mat-icon svgIcon="p2p-up-arrow-icon"></mat-icon>
                      <div class="tooltip-text left">
                        {{ 'P2P_WIDGETS.PURCHASE.OFFER_CONDITIONS.OVER_PRICE_MESSAGE' | translate }}
                      </div>
                    </ng-container>
                    <ng-container *ngSwitchCase="bibConditions.UN_PRICE">
                      <mat-icon svgIcon="p2p-down-arrow-icon"></mat-icon>
                      <div class="tooltip-text left">
                        {{ 'P2P_WIDGETS.PURCHASE.OFFER_CONDITIONS.UN_PRICE_MESSAGE' | translate }}
                      </div>
                    </ng-container>
                  </div>
                  <div class="offer__value">
                    {{ item.nextBidPrice | appCurrency }}
                  </div>
                </div> -->
                <button
                  class="btn btn-sm btn-primary"
                  (click)="createBid(item.id)"
                >
                  {{ 'P2P_WIDGETS.PURCHASE.BID_BUTTON' | translate }}
                </button>
              </ng-container>
            </ng-container>
          </ng-template>
        </div>
      </ng-container>
    </div>
    <!-- <div class="buying-actions__until-at">
      <span>
        {{ 'P2P_WIDGETS.PURCHASE.UNTIL_TIME' | translate }}
      </span>
      <span
        class="timer"
        *ngIf="offerTimer$ | async as offerTimer"
      >
        {{ offerTimer | date: 'mm:ss' }}
      </span>
    </div> -->
  </div>
  <!-- wait for trade -->
  <div
    class="offer-wait-for-confirm border-top"
    *ngIf="[statuses.WAIT_FOR_CONFIRM, statuses.WAIT_FOR_TRADE].includes(item.status) && item.isLastBidByUser"
  >
    <!-- <div class="offer-wait-for-confirm__amount">
      {{ item.price | appCurrency }}
    </div> -->
    <div class="progress-line">
      <div
        class="inner-line primary"
        [ngStyle]="{
          'animation-duration': (offerProgress$ | async) + 'ms',
          transform: 'translate3d(-' + (initialProgress$ | async) + '%, 0, 0)'
        }"
      ></div>
    </div>

    <div class="offer-wait-for-confirm__until-at">
      <span
        class="offer-wait-for-confirm__until-at-describe"
        *ngIf="statuses.WAIT_FOR_CONFIRM === item.status"
      >
        {{ 'P2P_WIDGETS.PURCHASE.WAIT_FOR_CONFIRM' | translate }}
        <span
          class="timer"
          *ngIf="offerTimer$ | async as offerTimer"
        >
          <!-- <mat-icon svgIcon="p2p-timer-icon"></mat-icon> -->
          {{ offerTimer | date : 'mm:ss' }}
        </span>
      </span>
      <span
        class="offer-wait-for-confirm__until-at-describe"
        *ngIf="statuses.WAIT_FOR_TRADE === item.status"
      >
        {{ 'P2P_WIDGETS.PURCHASE.WAIT_FOR_TRADE' | translate }}
        <span
          class="timer"
          *ngIf="offerTimer$ | async as offerTimer"
        >
          <!-- <mat-icon svgIcon="p2p-timer-icon"></mat-icon> -->
          {{ offerTimer | date : 'mm:ss' }}
        </span>
      </span>
    </div>
  </div>
  <div
    class="offer-wait-trade border-top"
    *ngIf="[statuses.WAIT_FOR_BUYER_ACCEPT].includes(item.status) && item.isLastBidByUser"
  >
    <!-- <div class="offer-wait-trade__amount">Current offer: {{ item.price | appCurrency }}</div> -->

    <div class="progress-line">
      <div
        class="inner-line primary"
        [ngStyle]="{
          'animation-duration': (offerProgress$ | async) + 'ms',
          transform: 'translate3d(-' + (initialProgress$ | async) + '%, 0, 0)'
        }"
      ></div>
    </div>
    <!-- <div class="offer-wait-trade__until-at-describe">
      {{ 'P2P_WIDGETS.PURCHASE.WAIT_FOR_BUYER_ACCEPT' | translate }}
    </div> -->
    <div class="offer-wait-trade__until-at">
      <!-- <div
        class="timer"
        *ngIf="offerTimer$ | async as offerTimer"
      >
        <mat-icon svgIcon="p2p-timer-icon"></mat-icon>
        {{ offerTimer | date: 'mm:ss' }}
      </div> -->
      <span class="offer-wait-for-confirm__until-at-describe">
        {{ 'P2P_WIDGETS.PURCHASE.WAIT_FOR_BUYER_ACCEPT' | translate }}

        <span
          class="timer"
          *ngIf="offerTimer$ | async as offerTimer"
        >
          {{ offerTimer | date : 'mm:ss' }}
        </span>
      </span>
      <!-- <div>
        <div class="alert tooltip">
          <mat-icon svgIcon="p2p-tooltip-alert-icon"></mat-icon>
          <div class="tooltip-text right">
            {{ 'P2P_WIDGETS.PURCHASE.TOOLTIP_ALERT' | translate }}
          </div>
        </div>
        <div class="info tooltip">
          <mat-icon svgIcon="p2p-tooltip-info-icon"></mat-icon>
          <div class="tooltip-text right">
            {{ 'P2P_WIDGETS.PURCHASE.TOOLTIP_INFO' | translate }}
          </div>
        </div>
      </div> -->
    </div>
    <div class="offer-wait-trade__controller">
      <button
        class="btn btn-sm btn-outline-primary p2p-get-btn"
        (click)="getTrade()"
      >
        {{ 'P2P_WIDGETS.PURCHASE.GET_TRADE' | translate }}
      </button>
    </div>
  </div>

  <!-- bid canseled footer -->
  <div
    class="cancel-timer border-top"
    *ngIf="
      [
        statuses.CANCELED_BY_SELLER,
        statuses.CANCELED_BY_BUYER,
        statuses.CANCELED_BY_SELLER_BEFORE_TRADE,
        statuses.CANCELED_BY_TIMER,
        statuses.CANCELED_BY_FASTOFFER,
        statuses.AUCTION_FINISHED,
        statuses.COMPLETED,
        statuses.DELETED
      ].includes(item.status) ||
      ([statuses.WAIT_FOR_BUYER_ACCEPT].includes(item.status) && !item.isLastBidByUser)
    "
  >
    <div
      [ngSwitch]="item.status"
      class="cancel-timer__text"
    >
      <ng-container *ngSwitchCase="statuses.CANCELED_BY_SELLER">{{ 'P2P_WIDGETS.PURCHASE.CANCELED_BY_SELLER' | translate }}</ng-container>
      <ng-container *ngSwitchCase="statuses.CANCELED_BY_BUYER">{{ 'P2P_WIDGETS.PURCHASE.CANCELED_BY_BUYER' | translate }}</ng-container>
      <ng-container *ngSwitchCase="statuses.CANCELED_BY_SELLER_BEFORE_TRADE">{{
        'P2P_WIDGETS.PURCHASE.CANCELED_BY_SELLER_BEFORE_TRADE' | translate
      }}</ng-container>
      <ng-container *ngSwitchCase="statuses.CANCELED_BY_TIMER">{{ 'P2P_WIDGETS.PURCHASE.CANCELED_BY_TIMER' | translate }}</ng-container>
      <ng-container *ngSwitchCase="statuses.CANCELED_BY_FASTOFFER">{{
        'P2P_WIDGETS.PURCHASE.CANCELED_BY_COMMONOFFER' | translate
      }}</ng-container>
      <ng-container *ngSwitchCase="statuses.AUCTION_FINISHED"
        ><div class="info">{{ 'P2P_WIDGETS.PURCHASE.AUCTION_FINISHED' | translate }}</div></ng-container
      >
      <ng-container *ngSwitchCase="statuses.COMPLETED"
        ><div class="info text-color-success">{{ 'P2P_WIDGETS.PURCHASE.COMPLETED' | translate }}</div></ng-container
      >
      <ng-container *ngSwitchCase="statuses.DELETED"
        ><div class="info">{{ 'P2P_WIDGETS.PURCHASE.DELETED' | translate }}</div></ng-container
      >
      <ng-container *ngSwitchCase="statuses.WAIT_FOR_BUYER_ACCEPT"
        ><div class="info">Auction finished! Someone else bid win</div></ng-container
      >
    </div>
    <button
      class="btn btn-sm btn-darken p2p-close-btn"
      (click)="onDeleted(item.id)"
    >
      {{ 'CLOSE' | translate }}
    </button>
  </div>
</ng-container>
