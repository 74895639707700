import { ITransactionDto, IWithdrawalActiveMethod, IWithdrawalMethod, IWithdrawalReceive, WithdrawalType } from '@dev-fast/types';

export interface WithdrawalStateModel {
  withdrawalTypes: Record<WithdrawalType, IWithdrawalMethod[]> | null;
  activeMethod: IWithdrawalActiveMethod | null;
  withdrawalHistory: Record<string, ITransactionDto[]> | null;
  receive: IWithdrawalReceive | null;
}

export const WITHDRAWAL_INITIAL_STATE: WithdrawalStateModel = {
  withdrawalTypes: null,
  activeMethod: null,
  withdrawalHistory: null,
  receive: null,
};
