import { ICasesFilter, IFilterRange } from '@dev-fast/types';

export class ChangeFilter {
  public static readonly type = '[Case battle cases] Change Filter';
  constructor(public casesFilter: ICasesFilter) {}
}
// export class ResetFilters{
//   public static readonly type = '[Case battle cases] Reset Filter';
// }
export class ChangePriceRangeValues {
  public static readonly type = '[Case battle cases] Change Price Range Values';
  constructor(public range: IFilterRange) {}
}

export class GetAllCasesForBattleCreate {
  public static readonly type = '[Case battle cases] get cases for battle create';
  constructor(public casesFilter?: ICasesFilter) {}
}
