import { Injectable } from '@angular/core';
import { INotification, NewMenu } from '@dev-fast/types';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { Observable } from 'rxjs';

import { NotificationsService as CoreNotiService } from '@app/core/notification-service';
import { CloseMenu, OpenMenu } from '@app/core/state/layout';
import { NotificationsEngine } from '@app/widgets/notifications-new';

@Injectable()
export class NotificationsService implements NotificationsEngine {
  public notifications$: Observable<INotification[]>;
  public countNewNotifications$: Observable<number>;

  constructor(private readonly notificationsService: CoreNotiService) {
    this.notifications$ = this.notificationsService.notifications$;
    this.countNewNotifications$ = this.notificationsService.countNewNotifications$;
  }

  public removeNotification(id: number): void {
    this.notificationsService.removeNotification(id);
  }
  public watchAllNotifications(): void {
    this.notificationsService.watchAllNotifications();
  }
  public watchNotification(id: number): void {
    this.notificationsService.watchNotification(id);
  }
  @Dispatch() public closeWidget = (): CloseMenu => new CloseMenu(NewMenu.NOTIFICATIONS);
  @Dispatch() public openWidget = (): OpenMenu => new OpenMenu(NewMenu.NOTIFICATIONS);
}
