import { PaymentStatus, WithdrawState } from '../../enums';

export interface IRenewTable {
  meta: { length: number };
  groups: IRenewTableGroup[];
}

export interface IRenewTableGroup {
  title: string;
  list: Record<string, IRenewTableItem[]>;
}

export interface IRenewTableItem {
  value: number;
  date: string | null | undefined;
  status?: PaymentStatus | WithdrawState;
  title: string;
  description?: string;
  icon?: string;
  id?: number | string;
  isWithdrawal?: boolean;
  isRefill?: boolean;
}
