import { IconConfig } from '@dev-fast/types';
// TODO Delete -design prefix after release
export const iconsTransactionsDesign: IconConfig[] = [
  {
    name: 'balance-received',
    path: 'assets/img/design/transactions/balance-received.svg',
  },
  {
    name: 'balance-errored',
    path: 'assets/img/design/transactions/balance-errored.svg',
  },
];
