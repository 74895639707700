import { IconConfig } from '@dev-fast/types';

export const statusCommonIcons: IconConfig[] = [
  //Check icon
  {
    name: 'checked-icon',
    path: 'assets/img/mat-icons/icons-lib/common/status/checked-icon.svg',
  },
  {
    name: 'checked-icon-round-bold',
    path: 'assets/img/mat-icons/icons-lib/common/status/checked-icon-round-bold.svg',
  },
  {
    name: 'checkmark-outline',
    path: 'assets/img/mat-icons/icons-lib/common/status/checkmark-outline.svg',
  },
  {
    name: 'checkmark-outline-error',
    path: 'assets/img/mat-icons/icons-lib/common/status/checkmark-outline-error.svg',
  },
  //Close icon
  {
    name: 'close-circle',
    path: 'assets/img/mat-icons/icons-lib/common/status/close-circle.svg',
  },
  {
    name: 'close-circle-bold',
    path: 'assets/img/mat-icons/icons-lib/common/status/close-circle-bold.svg',
  },
  {
    name: 'warning-circle',
    path: 'assets/img/mat-icons/icons-lib/common/status/warning-circle.svg',
  },
];
