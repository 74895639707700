import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { PROJECT } from '@dev-fast/types';

import { Environments, EnvironmentService } from '@app/core/environment-service';

/**
 * Директива для включения или отключения шаблонов в зависимости от проекта
 * Примеры:
 * --------------------------------
 * Так выглядит разрешение или запрет для конкретного проекта
 *
 * <div *isProject="{name: MARKET}">
 *   Тут только для маркета
 * </div>
 * <div *isProject="{name: MARKET, exclude: true}">
 *   Тут для всех кроме маркета
 * </div>
 * --------------------------------
 * Можно передавать сразу массив проектов для которых нужно применить условие
 *
 * <div *isProject="{name: [FAST, FAST_NEW]}">
 *   Тут для старого и нового фаста
 * </div>
 * <div *isProject="{name: [FAST, FAST_NEW], exclude: true}">
 *   Тут для всех кроме старого и нового фаста
 * </div>
 * --------------------------------
 * Если условие "НЕ" не нужно, то можно использовать сокращенную запись
 *
 * <div *isProject="MARKET">
 *   Сокращенная запись "только для маркета"
 * </div>
 * <div *isProject="[FAST, FAST_NEW]">
 *   Сокращенная запись "только для старого и нового фаста"
 * </div>
 * ---------------------------
 * Так же доступно else
 *
 * <div *isProject="MARKET; else template">
 *   Сокращенная запись "только для маркета"
 * </div>
 * <ng-template #template>
 *   Отрабатывает для всех остальных проектов
 *   </ng-template>
 */
@Directive({
  selector: '[isProject]',
})
export class IsProjectDirective {
  private environments: Environments;
  private project!: PROJECT[];
  private hasView = false;
  private isExclude = false;

  constructor(
    private readonly templateRef: TemplateRef<any>,
    private readonly viewContainer: ViewContainerRef,
    private readonly environmentService: EnvironmentService
  ) {
    this.environments = this.environmentService.getEnvironment();
  }

  @Input()
  public set isProject(project: PROJECT | PROJECT[] | { name: PROJECT | PROJECT[]; exclude: boolean }) {
    if (typeof project === 'string') {
      // Если передали сокращенной записью один проект
      this.project = [project];
    } else if (Array.isArray(project)) {
      // Если передали сокращенной записью массив проектов
      this.project = project;
    } else {
      // Если используем полную запись, то учитываем включено ли условие "НЕ"
      this.project = Array.isArray(project.name) ? project.name : [project.name];
      this.isExclude = project.exclude;
    }
    this.updateView();
  }

  private updateView(): void {
    const condition = this.isExclude
      ? !this.project.includes(this.environments.PROJECT as PROJECT)
      : this.project.includes(this.environments.PROJECT as PROJECT);

    // Если шаблон не показан, но показать нужно, то рисуем его
    if (condition && !this.hasView) {
      this.viewContainer.createEmbeddedView(this.templateRef);
      this.hasView = true;
      // Если шаблон показан но нужно спрятать, то скрываем
    } else if (!condition && this.hasView) {
      this.viewContainer.clear();
      this.hasView = false;
    }
    // Если шаблон нужно показать и он уже показан, то не делаем ничего
  }

  @Input()
  public set isProjectElse(templateRef: TemplateRef<any> | undefined) {
    if (!this.hasView && templateRef) {
      this.viewContainer.createEmbeddedView(templateRef);
    }
  }
}
