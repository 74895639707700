<ng-container *ngIf="faq">
  <div class="faq">
    <mat-expansion-panel
      #panel
      class="panel-wrapper"
    >
      <mat-expansion-panel-header>
        <mat-panel-title>
          <img
            *ngIf="!panel.expanded"
            lazyLoadImage
            src="/assets/img/mat-icons/icomoon/message-question-grey.svg"
            class="question-icon tw-w-6"
          />
          <img
            *ngIf="panel.expanded"
            lazyLoadImage
            src="/assets/img/mat-icons/icomoon/message-question.svg"
            class="question-icon tw-w-6"
          />

          <div class="faq-text tw-text-white">
            {{ faq.title | translate }}
          </div>
        </mat-panel-title>
      </mat-expansion-panel-header>

      <ng-container *ngIf="faq.link && faq.descriptionLink; else noLinkTmpl">
        <p class="faq-body-wrapper tw-pt-3 tw-pl-14 tw-text-base tw-text-slate-400">
          {{ faq.text | translate }}
          <a
            class="btn-link"
            langRouterLink
            [routerLink]="faq.link"
          >
            {{ faq.descriptionLink | translate }}
          </a>
        </p>
      </ng-container>

      <ng-template #noLinkTmpl>
        <p
          class="faq-body-wrapper tw-pt-3 tw-pl-14 tw-text-base tw-text-slate-400 max-sm:tw-pl-0"
          innerHTML="{{ faq.text | translate }}"
        ></p>
      </ng-template>
    </mat-expansion-panel>
  </div>
</ng-container>
