import { IconConfig } from '@dev-fast/types';

export const iconsPaymentSystems: IconConfig[] = [
  {
    name: 'bitcoin-logo',
    path: 'assets/img/mat-icons/payment-systems/bitcoin-logo.svg',
  },
  {
    name: 'credit-card-logo',
    path: 'assets/img/mat-icons/payment-systems/credit-card-logo.svg',
  },
  {
    name: 'marketplace-logo',
    path: 'assets/img/mat-icons/payment-systems/marketplace-logo.svg',
  },
  {
    name: 'neteller-logo',
    path: 'assets/img/mat-icons/payment-systems/neteller-logo.svg',
  },
  {
    name: 'qiwi-logo',
    path: 'assets/img/mat-icons/payment-systems/qiwi-logo.svg',
  },
  {
    name: 'skrill-logo',
    path: 'assets/img/mat-icons/payment-systems/skrill-logo.svg',
  },
  {
    name: 'steam-logo',
    path: 'assets/img/mat-icons/payment-systems/steam-logo.svg',
  },
  {
    name: 'vgo-logo',
    path: 'assets/img/mat-icons/payment-systems/vgo-logo.svg',
  },
  {
    name: 'webmoney-logo',
    path: 'assets/img/mat-icons/payment-systems/webmoney-logo.svg',
  },
  {
    name: 'yandex-money-logo',
    path: 'assets/img/mat-icons/payment-systems/yandex-money-logo.svg',
  },
];
