import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, NgModule, TemplateRef } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { RoomNavigationConfig } from '@dev-fast/types';
import { TranslateModule } from '@ngx-translate/core';

import { LangRouterModule } from '@app/shared/directives';

@Component({
  selector: 'app-ui-room-navigation',
  templateUrl: './room-navigation.component.html',
  styleUrls: ['./room-navigation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RoomNavigationComponent {
  @Input() public config: RoomNavigationConfig | undefined;
  @Input() public howToPlayBtn: TemplateRef<any> | undefined;
}

@NgModule({
  imports: [CommonModule, RouterLink, TranslateModule, MatIconModule, RouterLinkActive, LangRouterModule],
  declarations: [RoomNavigationComponent],
  exports: [RoomNavigationComponent],
})
export class RoomNavigationComponentModule {}
