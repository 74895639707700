<ng-container
  *ngIf="{
    user: user$ | async,
    messages: messages$ | async
  } as data"
>
  <div class="chat flex-column">
    <ng-scrollbar>
      <div class="chat-body flex-column">
        <app-chat-item
          [message]="message"
          [isMyMessage]="isMyMessage(message, data.user)"
          *ngFor="let message of data.messages; trackBy: trackByFn"
        ></app-chat-item>
      </div>
    </ng-scrollbar>

    <div class="chat-footer">
      <ng-container *ngIf="data.user; else notAuthTemplate">
        <app-message-input-v2 (addMessage)="sendMsg($event)"></app-message-input-v2>
      </ng-container>
      <ng-template #notAuthTemplate>
        <a
          class="chat-footer__not-auth"
          (click)="login()"
        >
          {{ 'LOGIN' | translate }}
        </a>
      </ng-template>
    </div>
  </div>
</ng-container>
