import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import {
  IInventoryRequestParams,
  IInventoryShortInfo,
  ILocales,
  IUserDetailed,
  NewMenu,
  Panel,
  ProfileTypes,
  PROJECT,
} from '@dev-fast/types';
import moment from 'moment';
import { Observable } from 'rxjs';

import { daysToGo } from '@app/shared/utils';

const ALLOWED_PROFILE_TYPES = [ProfileTypes.GOLD, ProfileTypes.PLATINUM, ProfileTypes.DIAMOND]; //типы профиля, для которых нужно показать сколько дней премиум осталось

@Component({
  selector: 'app-menu-panel',
  templateUrl: './menu-panel.component.html',
  styleUrls: ['./menu-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuPanelComponent {
  @Input() set userDetailed(value: IUserDetailed | null | undefined) {
    if (!value) {
      return;
    }
    this.user = value;

    if (this.user.experience) {
      this.xpProgress = this.#barProgress(this.user.experience.xp, this.user.experience.nextLevelXp);
      this.xpBlurPosition = this.#barBlurPosition(this.user.experience.xp, this.user.experience.nextLevelXp);
      this.activityProgress = this.#barProgress(this.user.experience.xp, this.user.experience.nextLevelXp);
    }

    if (this.user.premium && ALLOWED_PROFILE_TYPES.includes(this.user.profileType)) {
      this.daysLeft = this.#daysToGo(this.user.premium.until);
    }
  }
  @Input() userBalance = 0;
  @Input() locale!: ILocales | null;
  @Input() locales!: ILocales[] | null;
  @Input() activeMenu!: NewMenu[] | null;
  @Input() isMobile!: boolean | null;
  @Input() canLevelUp!: boolean | null;
  @Input() soundStatus!: boolean | null;
  @Input() inventoryShortInfo!: IInventoryShortInfo;
  @Input() totalBalance$!: Observable<number>;
  @Input() shopParams$!: Observable<IInventoryRequestParams>;
  @Input() lockedPanels$!: Observable<Panel[]>;
  @Input() isTabletByWidth$: Observable<boolean> | undefined;

  @Output() onauthClick: EventEmitter<void> = new EventEmitter<void>();
  @Output() soundToggle: EventEmitter<void> = new EventEmitter<void>();
  @Output() clickBack: EventEmitter<void> = new EventEmitter<void>();
  @Output() languageChange: EventEmitter<ILocales> = new EventEmitter<ILocales>();
  @Output() openMenuEvent: EventEmitter<NewMenu> = new EventEmitter<NewMenu>();
  @Output() closeMenuEvent: EventEmitter<NewMenu> = new EventEmitter<NewMenu>();

  menu = NewMenu;
  projects: typeof PROJECT = PROJECT;
  user: IUserDetailed | null = null;
  xpProgress = '';
  xpBlurPosition = '';
  activityProgress = '';
  premiumTimeLeft = 0;
  daysLeft = 0;

  /**
   * Открыть меню
   * @param menu - выбранное меню
   */
  openMenu(menu: NewMenu): void {
    if (menu === this.menu.LANGUAGE) {
      this.closeMenu(this.menu.MAIN_MENU);
    }
    this.openMenuEvent.emit(menu);
  }

  /**
   * Закрыть меню
   * @param menu - меню
   */
  closeMenu(menu: NewMenu): void {
    this.closeMenuEvent.emit(menu);
  }

  /**
   * Авторизация
   */
  onAuthClick(): void {
    this.onauthClick.emit();
    this.clickBack.emit();
  }

  /**
   * Переключение звука
   */
  onSoundToggle(): void {
    this.soundToggle.emit();
  }

  /**
   * Метод для перехода на предыдущую страницу
   */
  onMenuNavigation(): void {
    this.clickBack.emit();
  }

  /**
   * Метод для определения дней до события
   */
  #daysToGo(date: Date): number {
    return daysToGo(moment(date).unix());
  }

  /**
   * Метод для определения прогресса прогресс-бара
   * @param current - текущее значение
   * @param target - целевое значение
   * @returns - строка с прогрессом
   */
  #barProgress(current: number, target: number): string {
    const FULL = 100;

    const progress = (current * FULL) / target;
    const progressResult = progress > FULL ? FULL : progress;

    return `${progressResult}`;
  }

  /**
   * Метод для определения позиции размытия прогресс-бара
   * @param current - текущее значение
   * @param target - целевое значение
   * @returns строка с позицией размытия
   */
  #barBlurPosition(current: number, target: number): string {
    const OFFSET = 20;
    const barProgress = this.#barProgress(current, target);

    return `calc(${barProgress}% - ${OFFSET}px)`;
  }
}
