/* eslint-disable no-console */
import { enableProdMode, NgModuleRef } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular-ivy';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { hmrBootstrap } from './hmr';

if (environment.production) {
  Sentry.init({
    dsn: environment.SENTRY_DSN,
    integrations: [
      new Sentry.BrowserTracing({
        // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/, /^\//],
        routingInstrumentation: Sentry.routingInstrumentation,
      }),
    ],
    ignoreErrors: ['ResizeObserver loop limit exceeded'],
    environment: environment.production ? 'prod' : 'dev',
    release: `${environment.CLIENT_PROJECT}${environment.VERSION}`,

    tracesSampleRate: 1,
  });
  enableProdMode();
}

const bootstrap = (): Promise<NgModuleRef<any>> => platformBrowserDynamic().bootstrapModule(AppModule);

if (environment.hmr && module['hot']) {
  hmrBootstrap(module, bootstrap);
} else {
  bootstrap().catch((err) => console.log(err));
}
