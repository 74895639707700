<div class="autocomplete-list__wrapper">
  <ng-scrollbar
    #autocompleteScrollbar
    appearance="standard"
    [class]="'autocomplete-list-scrollbar'"
  >
    <div
      #autocompleteList
      class="autocomplete-list__container"
    >
      <ng-container *ngFor="let item of menuItems; trackBy: trackFn; let index = index">
        <div
          class="autocomplete-element"
          [class]="'autocomplete-element_' + index"
          [class.autocomplete-element_selected]="selectedItem?.name === item.name"
          (click)="selectItem(item.name)"
        >
          <ng-container [ngSwitch]="item.icon?.type">
            <ng-container *ngSwitchCase="'image'">
              <div class="autocomplete-element__avatar-wrapper">
                <img
                  class="autocomplete-element__avatar-image"
                  [class]="item.icon?.type"
                  [src]="item.icon?.src"
                />
              </div>
            </ng-container>
            <ng-container *ngSwitchCase="'icon'">
              <div class="autocomplete-element__avatar-wrapper">
                <mat-icon
                  class="autocomplete-element__avatar-image"
                  [class]="item.icon?.type"
                  [svgIcon]="item.icon?.src || ''"
                >
                </mat-icon>
              </div>
            </ng-container>
          </ng-container>
          <div class="autocomplete-element__title">
            {{ item.title }}
          </div>
        </div>
      </ng-container>
    </div>
  </ng-scrollbar>
</div>
