import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, Input, NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { FooterCounts, MarketCountsData, PROJECT, SocialItem } from '@dev-fast/types';
import { TranslateModule } from '@ngx-translate/core';

import { IsProjectModule, LangRouterModule } from '@app/shared/directives';

@Component({
  selector: 'app-ui-new-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss', './footer.component.media.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterNewComponent {
  constructor(@Inject('socialsFooter') readonly socials: SocialItem[]) {}
  @Input() breakpoint?: string;
  @Input() online!: number | null;
  @Input() counts!: FooterCounts | null;
  @Input() marketCounts!: MarketCountsData | undefined;

  // Enums
  readonly PROJECT = PROJECT;
}

@NgModule({
  imports: [CommonModule, RouterModule, LangRouterModule, IsProjectModule, MatIconModule, TranslateModule],
  declarations: [FooterNewComponent],
  exports: [FooterNewComponent],
})
export class FooterNewModule {}
