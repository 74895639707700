import { Inject, NgModule, Optional } from '@angular/core';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { IconConfig } from '@dev-fast/types';

import { IS_SERVER_TOKEN } from '@app/shared/utils';

import { iconsAccount } from './account';
import { iconsArrows } from './arrows.index';
import { iconsBugBounty } from './bug-bounty';
import { iconsCases } from './cases.index';
import { iconsClassic } from './classic.index';
import { iconsCommon } from './common.index';
import { iconsCommonDesign } from './design/common.index';
import { iconsHeaderMenu } from './design/header-menu.index';
import { iconsMarketplaceDesign } from './design/market.index';
import { iconsPromocodeDesign } from './design/promocode.index';
import { iconsRightSidebarDesign } from './design/right-sidebar.index';
import { iconsTransactionsDesign } from './design/transactions.index';
import { iconsDouble } from './double.index';
import { iconsEmojis } from './emojis.index';
import { iconsEsport } from './esport.index';
import { iconsEvents } from './events.index';
import { iconsFaucet } from './faucet.index';
import { iconsFooter } from './footer.index';
import { iconsFunctional } from './functional.index';
import { iconsGames } from './games.index';
import { iconsHeader } from './header.index';
import { iconsHilo } from './hilo.index';
import { iconsMoon } from './icomoon.index';
import { commonIcons } from './icons-lib/common/common.index';
import { iconsInformational } from './information.index';
import { iconsInventory } from './inventory.index';
import { iconsLeftSidebar } from './left-sidebar.index';
import { iconsLevels } from './levels.index';
import { iconsLogos } from './logos.index';
import { iconsMedia } from './media.index';
import { iconsMenu } from './menu.index';
import { iconsNotifications } from './notifications.index';
import { iconsNumbers } from './numbers.index';
import { iconsObjects } from './objects.index';
import { iconsPaymentMethods } from './payment-methods.index';
import { iconsPaymentProviders } from './payment-providers.index';
import { iconsPaymentSystems } from './payment-systems.index';
import { iconsPeerToPeer } from './peer-to-peer.index';
import { iconsPremiumTypes } from './premium-types.index';
import { iconsRefill } from './refill.index';
import { iconsRightSidebar } from './right-sidebar';
import { iconsSettings } from './settings.index';
import { iconsSocials } from './socials.index';
import { substratesImg } from './substrates.index';
import { iconsSupport } from './support.index';
import { SPECIAL_ICONS } from './symbols';
import { iconsUser } from './user.index';
import { iconsWidgets } from './widget.index';
import { iconsWidgetsMenu } from './widget-menu.index';
import { iconsWithdraw } from './withdraw.index';

const icons = [
  ...iconsArrows,
  ...iconsGames,
  ...iconsLogos,
  ...iconsMenu,
  ...iconsNotifications,
  ...iconsObjects,
  ...iconsSocials,
  ...iconsWidgets,
  ...iconsFunctional,
  ...iconsUser,
  ...iconsInformational,
  ...iconsMedia,
  ...iconsCases,
  ...iconsClassic,
  ...iconsCommon,
  ...iconsDouble,
  ...iconsEsport,
  ...iconsFaucet,
  ...iconsHeader,
  ...iconsNumbers,
  ...iconsHilo,
  ...iconsInventory,
  ...iconsLevels,
  ...iconsPaymentProviders,
  ...iconsPaymentSystems,
  ...iconsPeerToPeer,
  ...iconsPaymentMethods,
  ...iconsPremiumTypes,
  ...iconsSettings,
  ...iconsSupport,
  ...iconsWidgetsMenu,
  ...iconsWithdraw,
  ...iconsMoon,
  ...iconsRightSidebar,
  ...iconsLeftSidebar,
  ...iconsBugBounty,
  ...substratesImg,
  ...iconsEmojis,
  ...iconsRefill,
  ...iconsEvents,
  //new design
  ...iconsHeaderMenu,
  ...iconsCommonDesign,
  ...iconsMarketplaceDesign,
  ...iconsRightSidebarDesign,
  ...iconsPromocodeDesign,
  ...iconsAccount,
  ...iconsFooter,
  ...iconsTransactionsDesign,
  //icon-libs
  ...commonIcons,
];
@NgModule({
  imports: [MatIconModule],
  exports: [MatIconModule],
})
export class UiIconsModule {
  constructor(
    private readonly matIconRegistry: MatIconRegistry,
    private readonly domSanitizer: DomSanitizer,
    @Inject(IS_SERVER_TOKEN) private isServer: boolean,
    @Optional() @Inject(SPECIAL_ICONS) private readonly specialIcons: IconConfig[],
  ) {
    [...icons, ...(specialIcons ? specialIcons : [])].forEach((icon) => this.add(icon));
  }

  private add(config: IconConfig): void {
    this.matIconRegistry.addSvgIcon(
      config.name,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        this.isServer ? `http://localhost:${process.env['PORT'] || 4000}/` + config.path : config.path,
      ),
    );
  }
}
