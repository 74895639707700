import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, Optional } from '@angular/core';
import { InventoryApiService } from '@dev-fast/backend-services';
import {
  GameIds,
  IFilterMethod,
  IHistoryInventoryItem,
  IHistoryParams,
  IInventoryInfo,
  IInventoryItem,
  IInventoryRequestParams,
  IInventoryRequestResponse,
  IInventorySellAllRequestParams,
  IInventoryShortInfo,
  INotification,
  InventorySellStatuses,
  InventorySortingTypes,
  InventoryTradeBalance,
  InventoryWithdrawalStatuses,
  IShop,
  IShopMeta,
  ISkinItem,
  ISkinItemV2,
  IUserInventoryItemV2,
  IWithdrawalItem,
  IWithdrawalItems,
  IWithdrawalItemUpdate,
  IWithdrawalRequest,
  IWithdrawalRequestParams,
  NotificationType,
  Panel,
  SellItemsResponse,
  SubPanel,
} from '@dev-fast/types';
import { Action, Actions, Selector, State, StateContext } from '@ngxs/store';
import { append, insertItem, patch, removeItem } from '@ngxs/store/operators';
import { omit } from 'lodash-es';
import { catchError, Observable, switchMap, tap, throwError, timeout } from 'rxjs';

import { CurrencyService } from '@app/core/currency';
import { EnvironmentService } from '@app/core/environment-service';
import { FrameMessageTypes, IFrameMessageService } from '@app/core/iframe';
import { LocalStorageService } from '@app/core/local-storage-service';
import { NotificationsService } from '@app/core/notification-service';
import { RefreshActivePanel } from '@app/core/state/layout';
import { ChangeActiveTab, SetErrorInventorySellItem, SetSuccessInventorySellItem } from '@app/core/state/p2p';
import { InitSuccess } from '@app/core/state/user-store';

import {
  ApplyInventoryFilters,
  ChangeGamesItemsStatus,
  ChangeHistoryParams,
  ChangeInventoryPage,
  ChangeParamsInventory,
  ChangeParamsShop,
  ChangeShopPage,
  ClearPreSearchItemsLength,
  ClearTotalSum,
  ClickOnInventoryItem,
  ClickOnShopItem,
  FreezeItems,
  GetInventoryInfo,
  GetInventoryItems,
  GetShopItems,
  GetWithdrawalItems,
  InventoryActionsDisable,
  InventoryActionsEnable,
  InventoryHistoryLoaded,
  InventoryHistoryLoading,
  InventoryItemsLoad,
  InventoryItemsLoaded,
  Purchase,
  Refresh,
  RemoveInventoryItems,
  RequestInventoryHistory,
  SellAllItems,
  SellItems,
  ToggleAllInventoryItems,
  ToggleGameStatus,
  ToggleIsSelectAll,
  Trade,
  UnselectInventoryItemById,
  UnselectItems,
  UpdateTotalSum,
  WithdrawItems,
} from './inventory.actions';
import { INVENTORY_INITIAL_STATE, InventoryStateModel } from './inventory-state.model';

@State<InventoryStateModel>({
  name: 'inventory',
  defaults: INVENTORY_INITIAL_STATE,
})
@Injectable()
export class InventoryState {
  constructor(
    private readonly apiService: InventoryApiService,
    private readonly currencyService: CurrencyService,
    @Optional() private readonly frameMessageService: IFrameMessageService,
    private readonly actions$: Actions,
    private readonly notificationsService: NotificationsService,
    private readonly environmentService: EnvironmentService,
    private readonly storage: LocalStorageService,
  ) {}

  @Selector()
  static isSelectAll({ isSelectAll }: InventoryStateModel): boolean {
    return isSelectAll;
  }
  @Selector()
  static items({ items }: InventoryStateModel): IInventoryItem[] | null {
    return items;
  }
  @Selector()
  static historyItems({ historyItems }: InventoryStateModel): IHistoryInventoryItem[] {
    return historyItems;
  }
  @Selector()
  static historyItemsLoading({ historyItemsLoading }: InventoryStateModel): boolean {
    return historyItemsLoading;
  }
  @Selector()
  static inventorySum({ inventorySum }: InventoryStateModel): number {
    return inventorySum;
  }

  @Selector()
  static tradeBalance({ contracts, selectedItems }: InventoryStateModel): InventoryTradeBalance {
    const selectedItemsSum = selectedItems.reduce((a, b) => a + b.price, 0);
    const selectedShopItemsSum = contracts.reduce((a, b) => a + b.price, 0);

    return { selectedItemsSum, selectedShopItemsSum, tradeBalance: selectedItemsSum - selectedShopItemsSum };
  }
  @Selector()
  static shopItems({ shopItems }: InventoryStateModel): ISkinItem[] {
    return shopItems;
  }
  //TODO удалить лишние селекторы
  @Selector()
  static inventoryCount({ inventoryCount }: InventoryStateModel): number {
    return inventoryCount;
  }
  @Selector()
  static itemsCount({ inventoryCount }: InventoryStateModel): number {
    return inventoryCount;
  }
  @Selector()
  static inventoryItemsCount({ inventoryCount }: InventoryStateModel): number {
    return inventoryCount;
  }
  @Selector()
  static currentSum({ selectedItems }: InventoryStateModel): number {
    return selectedItems.reduce((sum, item) => sum + item.price, 0);
  }
  @Selector()
  static contracts({ contracts }: InventoryStateModel): ISkinItem[] {
    return contracts;
  }
  @Selector()
  static selectedItems({ selectedItems }: InventoryStateModel): ISkinItem[] {
    return selectedItems;
  }
  @Selector()
  static inventorySortingParams({ params }: InventoryStateModel): string | boolean {
    return params?.sortBy ? params.sortBy : true;
  }
  @Selector()
  static shopParams({ shopParams }: InventoryStateModel): IInventoryRequestParams {
    return shopParams;
  }
  @Selector()
  static actionsDisabled({ actionsDisabled }: InventoryStateModel): boolean {
    return actionsDisabled;
  }
  @Selector()
  static maxInventoryPages({ params, inventoryCount }: InventoryStateModel): number | null {
    if (params && params.pageSize) {
      return Math.ceil(inventoryCount / (params.pageSize ? params.pageSize : 50));
    }
    return null;
  }

  @Selector()
  static maxFilterPages({ params, preSearchItemsLength, inventoryCount }: InventoryStateModel): number | null {
    if (params && params.pageSize) {
      return Math.ceil((preSearchItemsLength || inventoryCount) / (params.pageSize ? params.pageSize : 50));
    }
    return null;
  }

  @Selector()
  static maxShopPages({ shopParams, shopMeta }: InventoryStateModel): number | null {
    if (shopParams && shopParams.pageSize) {
      return Math.ceil(shopMeta.amount / (shopParams.pageSize ? shopParams.pageSize : 50));
    }
    return null;
  }
  @Selector()
  static inventoryParams({ params }: InventoryStateModel): IInventoryRequestParams | null {
    return params;
  }
  @Selector()
  static shopMeta({ shopMeta }: InventoryStateModel): IShopMeta {
    return shopMeta;
  }
  @Selector()
  static currentFiltersAllSold({ currentFiltersAllSold }: InventoryStateModel): boolean {
    return currentFiltersAllSold;
  }
  @Selector()
  static gameInProgress({ gameInProgress }: InventoryStateModel): boolean {
    return gameInProgress;
  }
  // TODO Убрать всю старю логику с inventoryCount inventorySum
  @Selector()
  static inventoryShortInfo({ preSearchItemsLength, totalSum, inventoryCount }: InventoryStateModel): IInventoryShortInfo {
    return { inventoryCount, inventorySum: totalSum || 0 };
  }

  @Selector()
  static selectionAmount({ shopParams }: InventoryStateModel): number | null | undefined {
    return shopParams.selectionSum;
  }
  @Selector()
  static historyParams({ historyParams }: InventoryStateModel): IHistoryParams | null {
    return historyParams;
  }
  @Selector()
  static historyCount({ historyCount }: InventoryStateModel): number | null {
    return historyCount;
  }

  @Selector()
  static preSearchItemsLength({ preSearchItemsLength }: InventoryStateModel): number | null {
    return preSearchItemsLength;
  }

  @Selector()
  static totalSum({ totalSum }: InventoryStateModel): number | null {
    return totalSum;
  }

  @Selector()
  static preliminarySum({ preliminarySum }: InventoryStateModel): number | null {
    return preliminarySum;
  }

  @Selector()
  static isItemsLoading({ isItemsLoading }: InventoryStateModel): boolean | null {
    return isItemsLoading;
  }

  @Selector()
  static sortingMethods({ sortingMethods }: InventoryStateModel): IFilterMethod<InventorySortingTypes>[] {
    return sortingMethods;
  }

  @Selector()
  static withdrawalList({ withdrawalList }: InventoryStateModel): IWithdrawalItems | null {
    return withdrawalList;
  }

  ngxsOnInit({ dispatch, getState, patchState }: StateContext<InventoryStateModel>): void {
    if (this.frameMessageService) {
      this.frameMessageService.on(
        FrameMessageTypes.MESSAGE_FROM_BB,
        'requestInventory',
        () => dispatch([new GetInventoryItems({ page: 1 })]),
        600,
      );
    }

    // Если у нас какой-то предмет из вывода поменял свое состояние, то нужно в текущем списке на вывод эти изменения отобразить
    this.apiService.updateWithdrawItem((item: IWithdrawalItemUpdate) => {
      const currentWithdrawalList = getState().withdrawalList;
      const currentInventoryList = getState().items;

      // Обновляем предметы в списке выводимых
      if (currentWithdrawalList?.items) {
        patchState({
          withdrawalList: {
            items: this.updateWithdrawalItemsStatus(currentWithdrawalList.items, [item.userInventoryItem.id], item.state, item.updatedAt),
            meta: currentWithdrawalList.meta,
          },
        });
      }

      // Обновляем предметы в инвентаре
      if (currentInventoryList?.length) {
        patchState({
          items: this.updateItemStatus(currentInventoryList, [item.userInventoryItem.id], item.state, item.updatedAt),
        });
      }
    });

    this.apiService.onInventoryItemDelayedSellSuccess(({ ids }) => {
      dispatch([new SetSuccessInventorySellItem(ids), new GetInventoryInfo()]);

      this.notificationsService.addSuccessNotification('NOTIFICATIONS.SUCCESS_SALE');
      const inventoryItems = getState().items;
      if (inventoryItems?.length) {
        patchState({
          items: this.updateItemStatus(inventoryItems, ids, InventorySellStatuses.SOLD),
        });
      }
    });

    this.apiService.onInventoryItemDelayedSellError(({ ids }) => {
      dispatch(new SetErrorInventorySellItem(ids));

      this.notificationsService.addErrorNotification('MARKET.INVENTORY.UNABLE_TO_SELL');

      const inventoryItems = getState().items;
      if (inventoryItems?.length) {
        patchState({
          items: this.updateItemStatus(inventoryItems, ids, (item: IInventoryItem) => {
            return item.itemStatus === InventorySellStatuses.SOLD ? InventorySellStatuses.SOLD : null;
          }),
        });
      }
    });
  }

  @Action(InventoryItemsLoaded)
  marketItemsLoaded({ patchState }: StateContext<InventoryStateModel>, { tab }: ChangeActiveTab): void {
    patchState({ isItemsLoading: false });
  }

  @Action(InventoryItemsLoad)
  marketItemsLoad({ patchState }: StateContext<InventoryStateModel>, { tab }: ChangeActiveTab): void {
    patchState({ isItemsLoading: true });
  }

  @Action(RefreshActivePanel)
  refreshActivePanel({ dispatch }: StateContext<InventoryStateModel>, { payload }: RefreshActivePanel): void {
    if (payload && payload.panel === Panel.INVENTORY) {
      dispatch([new GetInventoryItems()]);
    }
    if (payload && payload.subPanel === SubPanel.EXHANGE) {
      dispatch(new GetShopItems('old'));
    }
  }

  @Action(InitSuccess)
  initSuccess({ dispatch }: StateContext<InventoryStateModel>, { user }: InitSuccess): void {
    dispatch([new GetInventoryInfo()]);
  }
  @Action(Refresh)
  refresh({ dispatch }: StateContext<InventoryStateModel>, { payload }: Refresh): void {
    dispatch([payload === 'inventory' ? new GetInventoryItems({ page: 1 }) : new ChangeParamsShop({ page: 1 })]);
  }

  @Action(SellItems, { cancelUncompleted: true })
  sellInventoryItems(
    { dispatch, getState, patchState }: StateContext<InventoryStateModel>,
    { ids }: SellItems,
  ): Observable<SellItemsResponse> {
    dispatch(new InventoryActionsDisable());

    patchState({
      selectedItems: [],
    });
    return this.apiService.sellInventoryItems(ids).pipe(
      tap(() => {
        const { items } = getState();
        dispatch(new ChangeGamesItemsStatus(ids));
        if (items?.length) {
          patchState({
            items: this.updateItemStatus(items, ids, InventorySellStatuses.SOLD),
          });
        }
        dispatch([new GetInventoryInfo(), new GetInventoryItems({ page: 1, needUpdateTotalSum: true }), new InventoryActionsEnable()]);
      }),
      timeout(60000),
      catchError((error) => {
        dispatch(new InventoryActionsEnable());
        return this.#_onError(error);
      }),
    );
  }

  @Action(SellAllItems)
  sellAllItems(
    { dispatch, getState, patchState }: StateContext<InventoryStateModel>,
    { useFilters }: { useFilters: boolean },
  ): Observable<SellItemsResponse> {
    const { itemsIds, selectedItems, params, isSelectAll } = getState();
    dispatch(new InventoryActionsDisable());

    let filters: IInventorySellAllRequestParams | null = null;

    if (useFilters && params) {
      filters = {
        query: params.query,
        minPrice: params.minPrice,
        maxPrice: params.maxPrice,
        weaponType: params.weaponType,
        weapon: params.weapon,
        otherType: params.otherType,
        glovesType: params.glovesType,
        exterior: params.exterior,
        category: params.category,
      };
    }

    if (isSelectAll) {
      const ignoreIds = itemsIds.filter((item) => !selectedItems.map((selItem) => selItem.id).includes(item));
      patchState({
        isSelectAll: false,
      });
      return this.apiService.sellAll(ignoreIds, filters).pipe(
        tap(() => {
          dispatch([
            new ChangeParamsInventory({ ...params, page: 1 }),
            new InventoryActionsEnable(),
            new ChangeGamesItemsStatus(selectedItems.map((item) => item.id)),
            new GetInventoryInfo(),
            new GetInventoryItems({ needUpdateTotalSum: true }),
          ]);
        }),
        catchError((error) => {
          dispatch(new InventoryActionsEnable());
          return this.#_onError(error);
        }),
      );
    }

    return this.apiService.sellAll([], filters).pipe(
      tap((response) => {
        const inventoryItems = getState().items;
        if (inventoryItems?.length) {
          patchState({
            items: this.updateItemStatus(inventoryItems, null, InventorySellStatuses.SOLD),
          });
        }

        dispatch([
          new ChangeParamsInventory({ ...params, page: 1 }),
          new ChangeGamesItemsStatus(itemsIds),
          new InventoryActionsEnable(),
          new GetInventoryInfo(),
          new GetInventoryItems({ needUpdateTotalSum: true }),
        ]);
      }),
      catchError((error) => {
        patchState({ currentFiltersAllSold: false });
        dispatch(new InventoryActionsEnable());
        return this.#_onError(error);
      }),
    );
  }

  @Action(InventoryActionsDisable)
  inventoryActionsDisable({ patchState }: StateContext<InventoryStateModel>): void {
    patchState({
      actionsDisabled: true,
    });
  }

  @Action(InventoryActionsEnable)
  inventoryActionsEnable({ patchState }: StateContext<InventoryStateModel>): void {
    patchState({
      actionsDisabled: false,
    });
  }

  @Action(Purchase)
  purchase({ patchState, dispatch }: StateContext<InventoryStateModel>, { ids, userInventoryIds }: Purchase): Observable<void> {
    return this.apiService.requestCreateTrade({ ids, userInventoryIds }).pipe(
      switchMap(() => dispatch([new GetInventoryItems({ needUpdateTotalSum: true })])),
      tap(() => patchState({ contracts: [] })),
    );
  }

  @Action(Trade)
  trade({ dispatch, getState }: StateContext<InventoryStateModel>): Observable<void> {
    const { selectedItems, contracts, isSelectAll } = getState();

    const ids = contracts.map((el) => el.id);
    const userInventoryIds = selectedItems.map((el) => el.id);

    if (ids.length) {
      return dispatch([new Purchase(ids, userInventoryIds)]);
    } else {
      if (isSelectAll) {
        return dispatch(new SellAllItems());
      } else {
        return dispatch([new SellItems(userInventoryIds)]);
      }
    }
  }

  @Action(GetInventoryItems, { cancelUncompleted: true })
  getInventoryItems(
    { getState, dispatch, setState }: StateContext<InventoryStateModel>,
    payload: { buffer: boolean; params: any },
  ): Observable<void> {
    const { params, appId, items, isSelectAll, selectedItems, totalSum: stateTotalSum } = getState();
    const normalizedParams = this.#normalizeParams({ ...params, ...payload.params }, appId);

    dispatch([new ClearPreSearchItemsLength(), new InventoryItemsLoad()]);

    const isFirstPage = normalizedParams.page.number === 1;
    return this.apiService.requestInventory(normalizedParams).pipe(
      tap((response: IInventoryRequestResponse) => {
        const responseItems = response[appId].map((i) => this.#adapterFromV2ToInventoryItem(i, appId));
        const newItemsList = isFirstPage ? responseItems : items ? items.concat(responseItems) : responseItems;
        const needUpdateTotalSum = payload?.params && 'needUpdateTotalSum' in payload.params;
        const totalSum = needUpdateTotalSum || stateTotalSum === null ? response.meta?.totalSum : stateTotalSum;
        if (payload.buffer) {
          setState(
            patch({
              bufferItems: newItemsList,
              preSearchItemsLength: response.meta?.total,
              totalSum,
              preliminarySum: response.meta?.totalSum,
              bufferParams: { ...params, ...payload.params },
            }),
          );
        } else {
          setState(
            patch({
              currentFiltersAllSold: false,
              items: newItemsList,
              itemsIds: newItemsList.map((item) => item.id),
              selectedItems: isSelectAll ? append(responseItems) : selectedItems,
              preSearchItemsLength: response.meta?.total,
              totalSum,
              preliminarySum: response.meta?.totalSum,
              params: { ...params, ...payload.params },
            }),
          );
        }
      }),
      switchMap(() => dispatch([new InventoryItemsLoaded()])),
      catchError((error) => {
        setState(
          patch({
            items: null,
            preSearchItemsLength: null,
            currentFiltersAllSold: false,
          }),
        );
        return this.#_onError(error, { message: 'Inventory items loading error' });
      }),
    );
  }

  @Action(ApplyInventoryFilters)
  confirmSearch({ dispatch, setState, getState }: StateContext<InventoryStateModel>): void {
    const { bufferItems, isSelectAll, selectedItems, bufferParams, preliminarySum } = getState();

    if (bufferItems) {
      setState(
        patch({
          items: bufferItems,
          bufferItems: null,
          params: bufferParams,
          currentFiltersAllSold: false,
          bufferParams: null,
          totalSum: preliminarySum,
          itemsIds: bufferItems.map((item) => item.id),
          selectedItems: isSelectAll ? append(bufferItems) : selectedItems,
        }),
      );
      dispatch(new GetInventoryInfo());
    }
  }

  @Action(GetInventoryInfo)
  getInventoryInfo({ patchState }: StateContext<InventoryStateModel>): Observable<IInventoryInfo[]> {
    return this.apiService.requestInventoryInfo().pipe(
      tap((response) => {
        const currentInfo = response.find((item) => item.appId === GameIds.CSGO);
        if (currentInfo) {
          const nullInfo = response.find((item) => !item.appId);
          patchState({
            inventoryCount: (currentInfo?.itemsCount ?? 0) + (nullInfo?.itemsCount ?? 0),
            inventorySum: currentInfo.itemsSum,
          });
        } else {
          patchState({
            inventoryCount: 0,
            inventorySum: 0,
          });
        }
      }),
      catchError((error) => this.#_onError(error, { showToast: false })),
    );
  }

  @Action(GetShopItems)
  getShopItems({ patchState, getState, setState }: StateContext<InventoryStateModel>, { design }: GetShopItems): Observable<IShop> {
    const { shopParams, appId } = getState();
    const normalizedParams = this.#normalizeParams(shopParams, appId);
    const isFirstPage = shopParams.page ? shopParams.page === 1 : false;

    return this.apiService.requestShop(normalizedParams).pipe(
      tap((response) => {
        const responseItems = response.items.map((i) => this.#adapterFromItemToV2(i, response.appId));
        if (!normalizedParams.selectionSum) {
          setState(
            patch({
              shopItems: isFirstPage || design === 'old' ? responseItems : append<ISkinItem>(responseItems),
              shopMeta: response.meta,
            }),
          );
        } else {
          patchState({
            shopItems: responseItems,
            contracts: responseItems,
            shopMeta: response.meta,
          });
        }
      }),
    );
  }

  @Action(ChangeParamsShop)
  changeParamsShop(
    { patchState, dispatch, getState }: StateContext<InventoryStateModel>,
    { params, design }: ChangeParamsShop,
  ): Observable<void> {
    const state = getState();
    const page = params.page ? params.page : 1;
    patchState({
      shopParams: { ...state.shopParams, ...params, page: page },
    });
    return dispatch([new GetShopItems(design)]);
  }

  @Action(ChangeParamsInventory)
  changeParamsInventory(
    { patchState, dispatch, getState }: StateContext<InventoryStateModel>,
    { params }: ChangeParamsInventory,
  ): Observable<void> {
    const { ...state } = getState();
    patchState({
      params: { ...state.params, ...params, page: 1 },
      selectedItems: [],
    });
    return dispatch([new GetInventoryItems()]);
  }
  @Action(ChangeInventoryPage)
  changeInventoryPage(
    { patchState, getState, dispatch }: StateContext<InventoryStateModel>,
    { page }: ChangeInventoryPage,
  ): Observable<void> {
    const { params } = getState();
    patchState({
      params: {
        ...params,
        page: page,
      },
    });
    return dispatch([new GetInventoryItems()]);
  }

  @Action(ChangeShopPage)
  changeShopPage({ patchState, getState, dispatch }: StateContext<InventoryStateModel>, { page }: ChangeInventoryPage): Observable<void> {
    const { shopParams } = getState();
    patchState({
      shopParams: {
        ...shopParams,
        page: page,
      },
    });
    return dispatch([new GetShopItems()]);
  }

  @Action(ClickOnShopItem)
  clickOnShopItem({ setState, getState }: StateContext<InventoryStateModel>, { id }: ClickOnShopItem): void {
    const { contracts, shopItems } = getState();
    const item = shopItems.find((el) => el.id === id);
    const selected = contracts.some((el) => el.id === id);

    if (selected) {
      setState(
        patch({
          contracts: removeItem<ISkinItem>((x) => x?.id === id),
        }),
      );
    } else if (item) {
      setState(patch({ contracts: insertItem(item) }));
    }
  }
  @Action(ClickOnInventoryItem)
  clickOnInventoryItem({ setState, getState }: StateContext<InventoryStateModel>, { id }: ClickOnInventoryItem): void {
    const { selectedItems, items } = getState();
    if (items) {
      const item = items.find((el) => el.id === id);
      const selected = selectedItems.some((el) => el.id === id);

      if (selected) {
        setState(
          patch({
            selectedItems: removeItem<IInventoryItem>((x) => x?.id === id),
          }),
        );
      } else if (item) {
        setState(patch({ selectedItems: insertItem(item) }));
      }
    }
  }

  @Action(RemoveInventoryItems)
  removeInventoryItems({ patchState, getState }: StateContext<InventoryStateModel>, { itemsIds }: RemoveInventoryItems): void {
    const { items } = getState();

    if (items) {
      const filteredItems = items.filter((el) => !itemsIds.includes(el.id));

      patchState({
        selectedItems: [],
        items: filteredItems,
        itemsIds: filteredItems.map((item) => item.id),
      });
    }
  }

  @Action(ToggleAllInventoryItems)
  toggleAllInventoryItems({ patchState, getState }: StateContext<InventoryStateModel>): void {
    const { selectedItems, items } = getState();

    if (selectedItems.length === items?.length) {
      patchState({
        selectedItems: [],
      });
    } else if (items) {
      patchState({
        selectedItems: [...items],
      });
    }
  }

  @Action(ToggleGameStatus)
  toggleGameStatus({ patchState }: StateContext<InventoryStateModel>, { gameInProgress }: ToggleGameStatus): void {
    patchState({
      gameInProgress: gameInProgress,
    });
  }

  @Action(UnselectInventoryItemById)
  clearSelection({ patchState, getState }: StateContext<InventoryStateModel>, { ids }: UnselectInventoryItemById): void {
    const { selectedItems } = getState();
    const filtered = selectedItems.filter((item: IInventoryItem) => !ids.includes(item.id));

    patchState({
      selectedItems: filtered,
    });
  }

  @Action(InventoryHistoryLoaded)
  inventoryHistoryLoaded({ patchState }: StateContext<InventoryStateModel>): void {
    patchState({ historyItemsLoading: false });
  }

  @Action(InventoryHistoryLoading)
  inventoryHistoryLoad({ patchState }: StateContext<InventoryStateModel>): void {
    patchState({ historyItemsLoading: true });
  }

  @Action(RequestInventoryHistory)
  requestInventoryHistory({
    getState,
    setState,
    dispatch,
  }: StateContext<InventoryStateModel>): Observable<{ count: number; items: IHistoryInventoryItem[] }> {
    const { historyParams } = getState();
    dispatch(new InventoryHistoryLoading());
    return this.apiService.requestInventoryHistory(historyParams ? historyParams : undefined).pipe(
      tap((data) => {
        setState(
          patch({
            historyItems: append<IHistoryInventoryItem>(data.items),
            historyCount: data.count,
          }),
        );
        dispatch(new InventoryHistoryLoaded());
      }),
    );
  }

  @Action(ChangeHistoryParams)
  changeHistoryParams(
    { patchState, getState, dispatch }: StateContext<InventoryStateModel>,
    { payload }: ChangeHistoryParams,
  ): Observable<void> {
    const { historyParams } = getState();
    patchState({ historyParams: { ...historyParams, ...payload } });
    return dispatch([new RequestInventoryHistory()]);
  }

  @Action(UnselectItems)
  unselectItems({ patchState, getState }: StateContext<InventoryStateModel>, { payload }: UnselectItems): void {
    const { shopParams, selectedItems, contracts, isSelectAll } = getState();
    patchState({
      isSelectAll: payload !== 'shop' && isSelectAll ? false : isSelectAll,
      selectedItems: payload === 'inventory' || !payload ? [] : selectedItems,
      contracts: payload === 'shop' || !payload ? [] : contracts,
      shopParams: {
        ...shopParams,
        selectionSum: null,
      },
    });
  }

  @Action(ToggleIsSelectAll)
  toggleIsSelectAll({ patchState, getState, dispatch }: StateContext<InventoryStateModel>): void {
    const { isSelectAll } = getState();
    patchState({
      isSelectAll: !isSelectAll,
    });
    dispatch([new ToggleAllInventoryItems()]);
  }

  @Action(FreezeItems)
  // eslint-disable-next-line no-empty-pattern
  freezeItems({}: StateContext<InventoryStateModel>, { ids }: FreezeItems): Observable<void> {
    return this.apiService.freezeItems(ids);
  }

  @Action(GetWithdrawalItems)
  getWithdrawalItems({ patchState }: StateContext<InventoryStateModel>, { params }: { params: IWithdrawalRequestParams }): Observable<any> {
    return this.apiService.getWithdrawalItems(params).pipe(
      tap((withdrawalList: IWithdrawalItems) => {
        patchState({
          withdrawalList,
        });
      }),
    );
  }

  updateItemStatus(
    inventoryItems: IInventoryItem[],
    withdrawalIds: number[] | null,
    status:
      | InventoryWithdrawalStatuses
      | InventorySellStatuses
      | null
      | ((item: IInventoryItem) => InventoryWithdrawalStatuses | InventorySellStatuses | null),
    updatedAt = new Date().toISOString(),
  ): IInventoryItem[] {
    if (!inventoryItems?.length) {
      return [];
    }

    return inventoryItems.map((invItem) => {
      // Если передали конкретные предметы, то меняем статус лишь у них
      if (withdrawalIds) {
        withdrawalIds.forEach((id) => {
          if (id === invItem.id) {
            invItem = {
              ...invItem,
              itemStatus: typeof status === 'function' ? status(invItem) : status,
              statusUpdatedAt: updatedAt,
            };
          }
        });
      }
      // Если не передали, то у всех
      else if (!invItem.itemStatus) {
        invItem = {
          ...invItem,
          itemStatus: typeof status === 'function' ? status(invItem) : status,
          statusUpdatedAt: updatedAt,
        };
      }
      return invItem;
    });
  }

  updateWithdrawalItemsStatus(
    withdrawalItems: IWithdrawalItem[],
    withdrawalIds: number[],
    status: InventoryWithdrawalStatuses,
    updatedAt = new Date().toISOString(),
  ): IWithdrawalItem[] {
    if (!withdrawalItems?.length) {
      return [];
    }

    return withdrawalItems.map((wItem) => {
      withdrawalIds.forEach((id) => {
        if (id === wItem.userInventoryItem.id) {
          wItem = {
            ...wItem,
            status: status,
            statusUpdatedAt: updatedAt,
          };
        }
      });
      return wItem;
    });
  }

  @Action(WithdrawItems)
  withdrawItems(
    { patchState, getState, dispatch }: StateContext<InventoryStateModel>,
    { withdrawalRequest }: { withdrawalRequest: IWithdrawalRequest },
  ): Observable<any> {
    patchState({
      selectedItems: [],
    });

    dispatch(new InventoryActionsDisable());

    const inventoryItems = getState().items;

    // Помечаем предметы инвентаря, которые мы выводим как "ожидается вывод"
    if (inventoryItems?.length) {
      patchState({
        items: this.updateItemStatus(
          inventoryItems,
          withdrawalRequest.userItems.map((item) => item.userInventoryItemId),
          InventoryWithdrawalStatuses.WAITING_FOR_START,
        ),
      });
    }

    return this.apiService.withdrawSelectedItems(withdrawalRequest).pipe(
      tap(() => {
        dispatch([(new GetInventoryInfo(), new InventoryActionsEnable())]);
      }),
      catchError((err: HttpErrorResponse) => {
        // Если предмет не найден, то показываем нотик об этом
        if (err.status === 400) {
          this.notificationsService.addErrorNotification(err.error.message);
        }
        if (inventoryItems?.length) {
          patchState({
            // Удаляем у предметов статус "ожидание вывода"
            items: this.updateItemStatus(
              inventoryItems,
              withdrawalRequest.userItems.map((item) => item.userInventoryItemId),
              null,
            ),
          });
        }
        dispatch(new InventoryActionsEnable());
        throw err;
      }),
    );
  }

  @Action(ClearPreSearchItemsLength)
  clearPreSearchItemsLength({ patchState }: StateContext<InventoryStateModel>): void {
    patchState({ preSearchItemsLength: null });
  }

  @Action(ClearTotalSum)
  clearTotalSum({ patchState }: StateContext<InventoryStateModel>): void {
    patchState({ totalSum: null });
  }

  @Action(UpdateTotalSum)
  updateTotalSum({ setState, getState }: StateContext<InventoryStateModel>): void {
    const { preliminarySum } = getState();
    setState(
      patch({
        totalSum: preliminarySum,
      }),
    );
  }

  #normalizeParams(params: IInventoryRequestParams, appId: GameIds): any {
    const { page, pageSize, sortBy, minPrice, maxPrice, ...rest } = params;
    const normPrice = (val: number | null | undefined): any => val && this.currencyService.revert(val);
    return {
      ...rest,
      minPrice: normPrice(minPrice),
      maxPrice: normPrice(maxPrice),
      appId,
      page: {
        number: page,
        size: pageSize,
      },
      sortBy: typeof sortBy === 'boolean' ? (sortBy ? 'price' : '-price') : sortBy,
    };
  }

  #adapterFromItemToV2(item: ISkinItemV2, appId: GameIds | undefined): ISkinItem {
    return {
      appId: appId,
      available: item.available,
      color: item.baseItem.color,
      icon: item.baseItem.icon,
      id: item.id,
      name: item.baseItem.name,
      shortName: item.baseItem.shortName,
      skin: item.baseItem.skin,
      exterior: item.baseItem.exterior,
      statTrak: item.baseItem.statTrak,
      weapon: item.baseItem.weapon,
      price: item.price,
      type: item.baseItem.type,
      phase: item.baseItem.phase,
      rarity: item.baseItem.rarity,
    };
  }
  #adapterFromV2ToInventoryItem(item: IUserInventoryItemV2, appId: GameIds): IInventoryItem {
    return {
      appId,
      available: item.inventoryItem.available,
      color: item.inventoryItem.baseItem.color,
      icon: item.inventoryItem.baseItem.icon,
      id: item.id,
      inventoryItemId: item.inventoryItemId,
      name: item.inventoryItem.baseItem.name,
      shortName: item.inventoryItem.baseItem.shortName,
      skin: item.inventoryItem.baseItem.skin,
      exterior: item.inventoryItem.baseItem.exterior,
      statTrak: item.inventoryItem.baseItem.statTrak,
      price: item.inventoryItem.price,
      weapon: item.inventoryItem.baseItem.weapon,
      rarity: item.inventoryItem.baseItem.rarity,
      //todo
      type: item.inventoryItem.baseItem.type,
      phase: item.inventoryItem.baseItem.phase,
      isFrozen: item.isFrozen,
    };
  }

  #_onError(error: HttpErrorResponse, params?: Partial<INotification>): Observable<never> {
    const errorText =
      params && params.message
        ? params.message
        : error.error && error.error.message
          ? error.error.message
          : typeof error.error === 'string'
            ? error.error
            : 'Error';
    this.notificationsService.addErrorNotification(errorText, {
      type: error.error && error.error.type ? error.error.type : NotificationType.Error,
      icon: 'warning',
      ...omit(params, 'message'),
    });
    return throwError(() => error);
  }
}
