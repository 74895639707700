import { animate, query, style, transition, trigger } from '@angular/animations';
import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, NgModule, Output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { GAME_MODES, GameMode, IGame, MenuItem, Permissions } from '@dev-fast/types';
import { MobileWrapperComponent, SpinnerLoaderSAComponent } from '@dev-fast/ui-components';
import { TuiSwipeModule } from '@taiga-ui/cdk';
import { NgScrollbarModule } from 'ngx-scrollbar';

import { ClickOutsideModule, HasPermissionModule } from '@app/shared/directives';
import { PromocodeMobileComponent } from '@app/widgets/promocode';

import { getModes } from '../../utils';
import { AnyIconCardComponent } from '../any-icon-card/any-icon-card.component';
import { GameCardComponent } from '../game-card/game-card.component';
import { CategoryComponent } from './category/category.component';

export const expansionAnimation = trigger('expansionAnimation', [
  transition(':enter', [
    query(':self', [style({ opacity: '0', height: '0rem' }), animate('300ms ease-in-out', style({ opacity: '1', height: '*' }))]),
  ]),
  transition(':leave', [
    query(':self', [style({ opacity: '1', height: '*' }), animate('300ms ease-in-out', style({ opacity: '0', height: '0rem' }))]),
  ]),
]);
@Component({
  selector: 'ui-mobile-game-menu',
  templateUrl: './mobile-game-menu.component.html',
  styleUrls: ['./mobile-game-menu.component.scss'],
  animations: [expansionAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MobileGameMenuComponent {
  open = false;
  modes: GameMode[] = [];
  gamesDict: Partial<Record<GameMode, IGame[]>> = {};
  modesDesc = GAME_MODES;
  promoCategory: MenuItem[] = [
    {
      icon: 'promocode-menu',
      key: 'promo',
      label: 'Promo',
      callback: () => {
        this.openPromo();
      },
    },
    {
      icon: 'quest-menu',
      key: 'quest',
      label: 'Giveaway',
      callback: () => {
        this.openGleam();
      },
    },
  ];
  @Input() lastGames: IGame[] | null = [];
  @Input() set availableGames(payload: Partial<Record<GameMode, IGame[]>> | null | undefined) {
    if (!payload) {
      return;
    }
    this.gamesDict = payload;
    this.modes = getModes(payload);
  }
  @Output() readonly openGleamModal: EventEmitter<void> = new EventEmitter(false);
  readonly permissions = Permissions;
  openMobile = false;

  getMode(mode: GameMode): IGame[] | undefined {
    return this.gamesDict[mode];
  }

  openPromo(): void {
    this.openMobile = !this.openMobile;
    this.open = !this.open;
  }
  openGleam(): void {
    this.openGleamModal.emit();
    this.closeMenu();
  }

  onSwipe(): void {
    this.closeMenu();
  }
  closeMenu(): void {
    this.openMobile = false;
    this.open = false;
  }
}
@NgModule({
  declarations: [MobileGameMenuComponent, CategoryComponent],
  imports: [
    CommonModule,
    PortalModule,
    GameCardComponent,
    NgScrollbarModule,
    ClickOutsideModule,
    AnyIconCardComponent,
    MatIconModule,
    TuiSwipeModule,
    HasPermissionModule,
    PromocodeMobileComponent,
    MobileWrapperComponent,
    SpinnerLoaderSAComponent,
  ],
  exports: [MobileGameMenuComponent],
})
export class MobileGameMenuModule {}
