import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';
// use example:
// in component
//  providers: [DestroyService],
//  constructor(@Self() private readonly destroy$: DestroyService)
//  .pipe(takeUntil(this.destroy$))
@Injectable()
export class DestroyService extends Observable<void> implements OnDestroy {
  protected readonly destroy$ = new Subject<void>();

  constructor() {
    super((subscriber) => this.destroy$.subscribe(subscriber));
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
