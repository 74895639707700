import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ICaseBattleWinnersModalData } from '@dev-fast/types';

@Component({
  selector: 'app-winners-modal',
  templateUrl: './winners-modal.component.html',
  styleUrls: ['./winners-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WinnersModalComponent {
  players: ICaseBattleWinnersModalData[] | null = null;
  battleId: number | undefined;
  userData: ICaseBattleWinnersModalData | undefined;
  userPosition: number | undefined;
  constructor(
    private dialogRef: MatDialogRef<WinnersModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    private data: {
      battleId: number;
      playersData: { userId: number | undefined; players: ICaseBattleWinnersModalData[] };
    },
  ) {
    this.players = this.data.playersData.players.sort((a, b) => b.prizeSum - a.prizeSum);
    this.battleId = this.data.battleId;
    this.findUser();
  }
  findUser(): ICaseBattleWinnersModalData | undefined {
    this.players?.find((player, index) => {
      if (player.player.user_id === this.data.playersData.userId) {
        this.userData = player;
        this.userPosition = index + 1;
      }
    });
    // this.data.playersData.userId;
    return;
  }
  closeModal(): void {
    this.dialogRef.close();
  }
}
