import { IconConfig } from '@dev-fast/types';

export const iconsHeader: IconConfig[] = [
  {
    name: 'automate-gun',
    path: 'assets/img/mat-icons/header/automate-gun.svg',
  },
  {
    name: 'csgo-logo',
    path: 'assets/img/mat-icons/header/csgo-logo.svg',
  },
  {
    name: 'currency-logo',
    path: 'assets/img/mat-icons/header/currency-logo.svg',
  },
  {
    name: 'dota2-logo',
    path: 'assets/img/mat-icons/header/dota2-logo.svg',
  },
  {
    name: 'faq',
    path: 'assets/img/mat-icons/header/faq.svg',
  },
  {
    name: 'free-coins',
    path: 'assets/img/mat-icons/header/free-coins.svg',
  },
  {
    name: 'game-menu',
    path: 'assets/img/mat-icons/header/game-menu.svg',
  },
  {
    name: 'leaders-race',
    path: 'assets/img/mat-icons/header/leaders-race.svg',
  },
  {
    name: 'p2p',
    path: 'assets/img/mat-icons/header/p2p.svg',
  },
  {
    name: 'pubg-logo',
    path: 'assets/img/mat-icons/header/pubg-logo.svg',
  },
  {
    name: 'settings-selector',
    path: 'assets/img/mat-icons/header/settings-selector.svg',
  },
  {
    name: 'theme-selector',
    path: 'assets/img/mat-icons/header/theme-selector.svg',
  },
  {
    name: 'vgo-logo',
    path: 'assets/img/mat-icons/header/vgo-logo.svg',
  },
  {
    name: 'crane-hook',
    path: 'assets/img/mat-icons/header/crane-hook.svg',
  },
  {
    name: 'crane-hook-hover',
    path: 'assets/img/mat-icons/header/crane-hook-hover.svg',
  },
];
