import { Injectable } from '@angular/core';
import { CurrencyService } from '@dev-fast/backend-services';
import { countriesDic, currencyDic, ICurrency, ICurrencyRate } from '@dev-fast/types';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { Observable, retry, throwError, timer } from 'rxjs';
import { tap } from 'rxjs/operators';

import { LocalStorageService } from '@app/core/local-storage-service';
import { RefreshCurrentUser } from '@app/core/state/user-store';

import { ChangeCurrency, GetCurrencySettings, SelectCurrencyByCountry } from './currency.actions';
import { CURRENCY_INITIAL_STATE, CurrencyStateModel } from './currency-state.model';

@State<CurrencyStateModel>({
  name: 'currency',
  defaults: CURRENCY_INITIAL_STATE,
})
@Injectable()
export class CurrencyState {
  constructor(private readonly apiService: CurrencyService, private readonly store: Store, private readonly storage: LocalStorageService) {}

  @Selector()
  public static currency({ currency }: CurrencyStateModel): ICurrencyRate | null {
    return currency;
  }
  @Selector()
  public static currencyList({ currencyList }: CurrencyStateModel): ICurrencyRate[] {
    return currencyList;
  }

  @Action(RefreshCurrentUser)
  public refreshUser({ dispatch }: StateContext<CurrencyStateModel>, { payload }: RefreshCurrentUser): void {
    // if (payload.currency && payload.currency !== null) {
    //   dispatch(new ChangeCurrency(payload.currency));
    // } else if (payload.country) {
    //   dispatch(new SelectCurrencyByCountry(payload.country));
    // }
  }

  @Action(GetCurrencySettings)
  public getSettings({ patchState }: StateContext<CurrencyStateModel>): Observable<ICurrency[]> {
    return this.apiService.getCurrencySettings().pipe(
      retry({
        count: 5,
        delay: (error, retryCount) => (error && retryCount < 5 ? timer(5000 * retryCount) : throwError(() => error)),
      }),
      tap((response: ICurrency[]) => {
        const currencies: ICurrencyRate[] = response.map((value) => ({
          key: value.key,
          rate: value.rate,
          prefix:
            (value.key === 'fastcoins' && ' ◎ ') || (value.key === 'fastcoins_refill' && ' ◎ ') || value.prefix || value.postfix || '',
        }));
        // const revertCoins: ICurrencyRate[] = currencies.reduce((acc: ICurrencyRate[], curr: ICurrencyRate) => {
        //   if (curr.key.includes('refill')) {
        //     const currencyKey = `${curr.key.split('_')[0]}_withdraw`
        //     const currency = {
        //       ...curr,
        //       rate: 1 / curr.rate * 0.01,
        //       key: currencyKey
        //     }
        //     return [...acc, currency]
        //   }
        //   return acc
        // }, [])
        // if (revertCoins.length) {
        //   currencies.push(...revertCoins)
        // }

        if (currencies.length > 0) {
          const currency = currencies.find((item: ICurrencyRate) => item.key === 'fastcoins');
          patchState({
            currency: currency || null,
            currencyList: currencies,
          });
          this.storage.set('currency', currency ? currency.key : currencyDic[0].key);
        }
      })
    );
  }

  @Action(ChangeCurrency)
  public changeCurrency({ patchState, getState }: StateContext<CurrencyStateModel>, { key }: ChangeCurrency): void {
    const { currency, currencyList } = getState();
    if (currency && currency.key === key) return;

    if (key !== undefined) {
      const nextCurrency = currencyList.find((rate) => rate.key === key);
      patchState({
        currency: nextCurrency || null,
      });
      this.storage.set('currency', nextCurrency?.key);
    } else {
      const newCurrency = currency && currency.key === currencyList[0].key ? currencyList[1] : currencyList[0];
      patchState({
        currency: newCurrency || null,
      });
      this.storage.set('currency', newCurrency.key);
    }
  }

  @Action(SelectCurrencyByCountry)
  public selectCurrencyByCountry({ dispatch, getState }: StateContext<CurrencyStateModel>, { country }: SelectCurrencyByCountry): void {
    const { currency, currencyList } = getState();
    let currencyByCountry: ICurrencyRate | undefined;
    if (countriesDic.china.includes(country)) {
      currencyByCountry = currencyList.find((c) => c.key === 'CNY');
    }
    if (countriesDic.europe.includes(country)) {
      currencyByCountry = currencyList.find((c) => c.key === 'EUR');
    }
    if (countriesDic.poland.includes(country)) {
      currencyByCountry = currencyList.find((c) => c.key === 'PLN');
    }
    if (countriesDic.usa.includes(country)) {
      currencyByCountry = currencyList.find((c) => c.key === 'USD');
    }
    if (countriesDic.russia.includes(country)) {
      currencyByCountry = currencyList.find((c) => c.key === 'RUB');
    }
    currencyByCountry = currencyByCountry || currencyList[0];
    if (currency && currency.key === currencyByCountry.key) return;
    // dispatch(new ChangeCurrency(currencyByCountry.key));
  }
}
