import { OrderStatusEnum, SteamErrorsEnum } from '@dev-fast/types';

export const LOT_CLOSED_STATUSES = [
  OrderStatusEnum.CANCELED_BY_SELLER,
  OrderStatusEnum.CANCELED_BY_BUYER,
  OrderStatusEnum.CANCELED_BY_SELLER_BEFORE_TRADE,
  OrderStatusEnum.CANCELED_BY_TIMER,
  OrderStatusEnum.CANCELED_BY_FASTOFFER,
  OrderStatusEnum.AUCTION_FINISHED,
  OrderStatusEnum.COMPLETED,
  OrderStatusEnum.DELETED,
];
export const USER_CANT_TRADE_ERRORS = [
  SteamErrorsEnum.INVALID_TRADELINK,
  SteamErrorsEnum.NO_TRADE_TOKEN,
  SteamErrorsEnum.NO_TRADE_LINK,
  SteamErrorsEnum.NO_API_KEY,
  SteamErrorsEnum.FAKE_API_KEY,
  SteamErrorsEnum.INVALID_API_KEY,
];
export type PosibleSteamActionBtnLocale =
  | 'P2P_WIDGETS.RENEW_SIDEBAR.DEPOSITING'
  | 'P2P_WIDGETS.RENEW_SIDEBAR.NOT_AVAILABLE'
  | 'GAMES.SELECT_ITEM';
