<div
  class="custom-case-container background-cover w-100 h-100 relative tw-cursor-pointer"
  [class.tw-pointer-events-none]="!enabledStatus"
  (click)="clickOnCard()"
>
  <!-- Overlay for SELECTED or SOLD case-->
  <div
    *ngIf="status === P2pItemStatus.Selected || status === P2pItemStatus.Sold"
    class="status-icon flex fx-align-center fx-justify-center"
  >
    <div class="is-selected flex fx-align-center fx-justify-center tw-mb-16">
      <mat-icon svgIcon="checked-icon"> </mat-icon>
    </div>
  </div>
  <!-- Overlay for DISABLED case -->
  <div
    *ngIf="!enabledStatus"
    class="tw-absolute tw-bg-contain tw-w-full tw-h-full tw-z-10 tw-bg-gray-750 tw-opacity-70"
  ></div>

  <div class="background tw-absolute tw-w-full tw-h-full tw-z-0"></div>
  <div
    *ngIf="backgroundImage"
    class="background-layer absolute background-cover w-100 h-100 z-0"
    [class.tw-saturate-0]="!enabledStatus"
    [style.background-image]="'url(' + backgroundImage + ')'"
  ></div>
  <div
    *ngIf="collectionImage"
    class="collection-img absolute background-cover w-100 h-100 z-1"
    [style.background-image]="'url(' + collectionImage + ')'"
  ></div>
  <ng-container *ngIf="!caseImageFallbackImage; else caseImageFallback">
    <img
      [@shrinkAnim]="status"
      *ngIf="mainItemImage"
      class="item-img absolute w-100 z-2"
      [class.tw-saturate-0]="!enabledStatus"
      [src]="mainItemImage | hash"
      alt=""
    />
    <img
      class="case-img absolute w-100 z-3"
      [class.tw-saturate-0]="!enabledStatus"
      [src]="caseImage"
      alt=""
    />
  </ng-container>
  <ng-template #caseImageFallback>
    <img
      *ngIf="caseImageFallbackImage"
      class="case-img absolute w-100 z-3"
      [class.tw-saturate-0]="!enabledStatus"
      [src]="caseImageFallbackImage"
      alt=""
    />
  </ng-template>

  <div class="custom-case-info flex fx-column fx-justify-end gap-2 p-sm-3 h-100 z-3">
    <div class="probability mb-a">{{ itemDropProbability }}</div>

    <div class="custom-case-name tw-font-medium">{{ name }}</div>
    <div class="custom-case-price flex fx-column tw-cursor-pointer">
      <div
        clickStop
        class="price-tag market-price flex"
        (click)="openCaseModal.emit(); $event.stopPropagation()"
      >
        <app-currency
          class="price tw-font-medium w-100 p-1"
          [value]="casePrice"
        />
        <div class="icon flex fx-all-center p-sm-2">
          <mat-icon svgIcon="cardboard"></mat-icon>
        </div>
      </div>
      <div
        *ngIf="marketPrice"
        clickStop
        class="price-tag steam-price flex"
        (click)="buyCase.emit(); $event.stopPropagation()"
      >
        <app-currency
          class="price tw-font-medium w-100 p-1"
          [value]="marketPrice"
        />
        <div class="icon flex fx-all-center p-sm-2">
          <mat-icon svgIcon="shopping-cart"></mat-icon>
        </div>
      </div>
    </div>
  </div>
</div>
