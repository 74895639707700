<div class="p2pMarket pb-10">
  <div class="p2pMarket-header flex fx-justify-between pb-2">
    <div class="p2pMarket-header-title flex gap-1 tw-font-medium">
      {{ 'P2P_WIDGETS.MARKET' | translate }}
    </div>
    <div class="p2pMarket-header-options flex gap-6 tw-font-normal">
      <a
        *ngIf="isAuth()"
        (click)="openMarketHistoryModal()"
      >
        {{ 'LOCAL.CASES.CATEGORIES.FAQ.HISTORY' | translate }}
      </a>
    </div>
  </div>
  <ng-container
    *ngIf="{
      filters: filters$ | async,
      searchResultCount: searchResultCount$ | async,
      filterResultCount: filterResultCount$ | async,
      depositing: depositing$ | myAsync,
      pagination: pagination$ | async,
      data: data$ | async,
      activeTab: activeTab$ | async,
      listLoadingStatus: listLoadingStatus$ | myAsync
    } as marketData"
  >
    <app-p2p-market-filter
      [resetFiltersEvent]="resetFilters$ | async"
      class="p2pMarket-filter mb-3"
    />
    <ng-container *ngIf="marketData.activeTab === MarketPanel.Purchase">
      <ng-container *ngIf="marketData.data as items">
        <ng-container *ngIf="items.length && !isServer; else loadItems">
          <div class="p2pMarket-body relative">
            <ng-container *ngFor="let item of items; trackBy: trackingFunction">
              <app-ui-market-item-card
                [item]="item"
                [status]="itemStatus(item.id)"
                (openModal)="openModalByName($event.name, $event.payload)"
                (openCase)="openCase($event)"
                (clickItem)="onSelectItem(item)"
              />
            </ng-container>
          </div>
        </ng-container>

        <ng-container *ngIf="marketData.pagination && marketData.pagination.numberPage && marketData.pagination.totalSize">
          <ng-container *ngIf="marketData.pagination.numberPage < marketData.pagination.totalSize">
            <div
              class="load-more"
              scrolled-to
              [scrollCheck]="marketData.pagination.numberPage"
              (scrolledEvent)="onScrollToBottom($event, marketData.pagination)"
            >
              <ng-container *ngIf="marketData.listLoadingStatus === ActionStatusEnum.DISPATCH; else loadMoreBtn">
                <mat-spinner
                  [diameter]="24"
                  color="primary"
                >
                </mat-spinner>
              </ng-container>

              <ng-template #loadMoreBtn>
                <button class="btn btn-primary--design load-more__button">
                  {{ 'PROFILE.RENEW_PROFILE.BALANCE.LOAD_MORE' | translate }}
                </button>
              </ng-template>
            </div>
          </ng-container>
        </ng-container>
        <ng-template #loadItems>
          <ng-container *ngIf="showMoreItemsLoader(marketData.searchResultCount, marketData.filterResultCount)">
            <div class="p2pMarket-body pb-10 relative">
              <div
                *ngFor="let mockCard of mockCardsArray"
                class="loadMoreCard loading-gradient"
              ></div>
            </div>
          </ng-container>
          <ng-container *ngIf="showNoItemsPlaceholder(items.length, marketData.filterResultCount)">
            <app-ui-ghost
              class="tw-w-full pt-10"
              [headerLocale]="'P2P_SETTINGS.FILTERS.NO_SEARCH'"
              [underLocale]="'P2P_SETTINGS.FILTERS.NO_SEARCH_UNDER'"
              [actionLocale]="'P2P_SETTINGS.FILTERS.RESET'"
              (actionEmitted)="resetFilters()"
            ></app-ui-ghost>
          </ng-container>
        </ng-template>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="marketData.activeTab === MarketPanel.Sell">
      <ng-container *ngIf="isAuth(); else notAuthTemplate">
        <ng-container *ngIf="steamInventoryItems$() as items; else loadMoreSteamItemsTemplate">
          <ng-container *ngIf="items.length; else noSteamItemsTemplate">
            <div class="p2pMarket-body tw-relative">
              <app-sih-check-card *ngIf="canShowSihCard()"></app-sih-check-card>

              <ng-container *ngFor="let item of items; trackBy: steamInventoryTrackingFunction">
                <app-ui-skin-item-new
                  *ngIf="item.baseItem"
                  [isAuth]="!!isAuth()"
                  [id]="item.steamInventoryId"
                  [tradable]="item.tradable"
                  [passed]="item.passed"
                  [inTrade]="item.inTrade"
                  [newLook]="true"
                  [tradeLockEndAt]="item.tradeLockEndAt"
                  [kit]="[item]"
                  [kitPrice]="{ price: item.price, recommendedPrice: undefined }"
                  [kitColor]="{ color: item.baseItem.color, rarity: item.baseItem.rarity }"
                  [backgroundIcon]="'steam-logo-2'"
                  [status]="steamItemStatus(item)"
                  (showItemDetails)="onDetailsClick(item)"
                  (skinClick)="selectSteamInventoryItem(item, marketData.depositing)"
                >
                </app-ui-skin-item-new>
              </ng-container>
            </div>
          </ng-container>
          <ng-template #noSteamItemsTemplate>
            <app-ui-ghost
              class="w-100 pt-10"
              [headerLocale]="'P2P_SETTINGS.FILTERS.NO_SEARCH'"
              [underLocale]="'P2P_SETTINGS.FILTERS.NO_SEARCH_UNDER'"
              [actionLocale]="'P2P_SETTINGS.FILTERS.RESET'"
              (actionEmitted)="resetFilters()"
            ></app-ui-ghost>
          </ng-template>
        </ng-container>
        <ng-template #loadMoreSteamItemsTemplate>
          <div class="p2pMarket-body pb-2 relative">
            <div
              *ngFor="let mockCard of mockCardsArray"
              class="loadMoreCard loading-gradient"
            ></div>
          </div>
        </ng-template>
      </ng-container>
      <ng-template #notAuthTemplate>
        <app-ui-ghost
          class="w-100 pt-10"
          [headerLocale]="'LOBBY.AUTH'"
        ></app-ui-ghost>
      </ng-template>
    </ng-container>
    <ng-template #controlPanelTmpl>
      <div class="control-panel">
        <button
          class="btn btn-primary--design tw-rounded-xl"
          *ngIf="isMarketItemsSelected && marketData.activeTab === MarketPanel.Purchase"
          (click)="openTradePanel()"
        >
          {{ 'P2P_WIDGETS.PURCHASE.BUY' | translate }}
        </button>

        <button
          class="btn btn-primary--design tw-rounded-xl"
          *ngIf="selectedSteamItems.length > 0 && marketData.activeTab === MarketPanel.Sell"
          (click)="openTradePanel()"
        >
          {{ 'P2P_WIDGETS.DEPOSITING.SELL_ITEM' | translate }}
        </button>
      </div>
    </ng-template>
  </ng-container>
</div>
