import { Directive, ElementRef, HostListener, Input, OnInit, Renderer2 } from '@angular/core';

@Directive({
  selector: '[gamesHover]',
})
export class GamesHoverDirective implements OnInit {
  @Input() public background?: string;
  @Input() public hover?: string;

  constructor(private elRef: ElementRef, private renderer: Renderer2) {}

  ngOnInit(): void {
    this.elRef.nativeElement.style.background = this.background;
    this.elRef.nativeElement.style.transition = 'background-color 0.5s ease-out';
  }

  @HostListener('mouseenter') onMouseEnter() {
    this.elRef.nativeElement.style.transition = 'background-color 0.5s ease-out';
    this.elRef.nativeElement.style.background = this.hover;
  }

  @HostListener('mouseleave') onMouseLeave() {
    this.elRef.nativeElement.style.transition = 'background-color 0.5s ease-out';
    this.elRef.nativeElement.style.background = this.background;
  }
}
