import { Injectable } from '@angular/core';
import {
  ITransactionDto,
  IUserDetailed,
  IWithdrawalActiveMethod,
  IWithdrawalFormModel,
  IWithdrawalMethod,
  IWithdrawalReceive,
  IWithdrawalReceiveRequest,
  TransactionWay,
  WithdrawalType,
} from '@dev-fast/types';
import { Actions, ofActionSuccessful, Select, Store } from '@ngxs/store';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { map, Observable } from 'rxjs';

import { CurrencyService } from '@app/core/currency';
import { UserState } from '@app/core/state/user-store';

import { SetTransactionWay } from '../payment.actions';
import {
  ClearWithdrawalState,
  GetWithdrawalHistory,
  GetWithdrawalMethods,
  RequestWithdrawalReceive,
  SetActiveWithdrawalMethod,
  Withdraw,
  WithdrawalState,
} from '../withdrawal';

@Injectable()
export class WithdrawalService {
  withdrawalHistoryIsLoad$ = this.actions$.pipe(
    ofActionSuccessful(GetWithdrawalHistory),
    map((action: GetWithdrawalHistory) => action),
  );

  @Select(WithdrawalState.withdrawalTypes)
  readonly withdrawalTypes$!: Observable<Record<WithdrawalType, IWithdrawalMethod[]> | null>;

  @Select(WithdrawalState.activeMethod)
  readonly activeMethod$!: Observable<IWithdrawalActiveMethod | null>;

  @Select(WithdrawalState.withdrawalHistory)
  readonly withdrawalHistory$!: Observable<Record<string, ITransactionDto[]> | null>;

  @Select(WithdrawalState.receive)
  readonly receive$!: Observable<IWithdrawalReceive | null>;

  @Select(UserState.user)
  readonly user$!: Observable<IUserDetailed | null | undefined>;

  constructor(
    private readonly store: Store,
    private readonly currencyService: CurrencyService,
    private readonly actions$: Actions,
  ) {}

  @Dispatch()
  clearWithdrawalState(): ClearWithdrawalState {
    return new ClearWithdrawalState();
  }
  @Dispatch()
  getWithdrawalMethods(): GetWithdrawalMethods {
    return new GetWithdrawalMethods();
  }
  @Dispatch()
  getWithdrawalHistory(): GetWithdrawalHistory {
    return new GetWithdrawalHistory();
  }
  @Dispatch()
  requestWithdrawalAction(value: IWithdrawalReceiveRequest): RequestWithdrawalReceive {
    return new RequestWithdrawalReceive(value);
  }
  @Dispatch()
  setActiveMethod(typeName: WithdrawalType | null, method?: string): SetActiveWithdrawalMethod {
    return new SetActiveWithdrawalMethod(typeName, method);
  }
  @Dispatch()
  setTransactionWay(way: TransactionWay | null): SetTransactionWay {
    return new SetTransactionWay(way);
  }
  @Dispatch()
  withdrawAction(paymentSystem: string, coinsAmount: number, wallet: string, recaptcha: string): Withdraw {
    return new Withdraw(paymentSystem, coinsAmount, wallet, recaptcha);
  }
  requestWithdrawalReceive(formValue: IWithdrawalFormModel): void {
    const request: IWithdrawalReceiveRequest = {
      paymentSystem: formValue.method,
      coinsAmount: Math.round(this.currencyService.revert(formValue.withdrawal || 0)),
    };
    const activeMethod = this.store.selectSnapshot(WithdrawalState.activeMethod);
    if (!activeMethod?.method?.gates[0]) {
      return;
    }
    if (activeMethod?.method?.gates[0].feeSelector) {
      request.feeIndex = activeMethod?.method?.gates[0].feeSelector.indexOf(formValue.deliverySpeed);
    }
    this.requestWithdrawalAction(request);
  }
  withdraw(form: IWithdrawalFormModel): void {
    const coinsAmount = this.currencyService.revert(form.withdrawal || 0);
    this.withdrawAction(form.method, coinsAmount, form.wallet, form.recaptcha);
  }
}
