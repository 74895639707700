<div
  class="panel-actions tw-flex tw-justify-center tw-font-normal tw-mt-auto"
  [class.panel-openned]="isPrticipationPanelOpened"
  [class.mobile-view]="isMobile"
>
  <div
    *ngIf="isPrticipationPanelOpened && !isMobile"
    class="tw-flex tw-flex-col tw-items-center tw-gap-1.5 tw-w-1/3"
  >
    <div class="gray-400 tw-text-sm/4">{{ 'TRADE_PANEL.BET_SUM' | translate }}</div>
    <div class="panel-actions__label tw-inline-flex tw-justify-center tw-text-2xl">
      <app-currency
        class="panel-actions__label tw-inline-flex tw-justify-center tw-text-2xl"
        [value]="participatedItemsSum || 0"
        [delimiterRest]="','"
      />
      <app-currency
        *ngIf="selectedItemsSum"
        class="panel-actions__label tw-inline-flex tw-justify-center tw-text-2xl tw-pl-1 tw-font-bold add-to-highlight"
        [value]="selectedItemsSum"
        [hidePrefix]="true"
        [delimiterRest]="','"
      />
    </div>
  </div>
  <div
    *ngIf="isPrticipationPanelOpened && isMobile"
    class="clear-selection-btn tw-flex tw-items-center tw-justify-center"
    (click)="clearSelectedItems.emit()"
  >
    <mat-icon svgIcon="close" />
  </div>
  <div class="panel-actions__action-btn tw-flex tw-flex-col tw-gap-2 tw-p-2 tw-w-fit">
    <button
      class="btn tw-min-w-80 tw-w-80 tw-inline-flex !tw-rounded-2xl"
      [disabled]="actionBtnIsDisabled"
      [ngClass]="btnCurrentClass"
      (click)="panelBtnAction.emit()"
    >
      <ng-container *ngIf="selectedItemsLength || participatedItemsLength; else selectItemsBtnTemplate">
        <ng-container *ngIf="selectedItemsLength && isPrticipationPanelOpened">
          {{ 'TRADE_PANEL.BET' | translate }}:
          <app-currency [value]="selectedItemsSum || 0" />
        </ng-container>
        <ng-container *ngIf="!selectedItemsLength && participatedItemsLength">
          {{ 'TRADE_PANEL.YOUR_BET' | translate }}:
          <app-currency [value]="participatedItemsSum || 0" />
        </ng-container>
      </ng-container>
      <ng-template #selectItemsBtnTemplate>
        {{ (isPrticipationPanelOpened ? 'GAMES.CLASSIC_GAME.CHOOSE_ITEM' : 'GAMES.CLASSIC_GAME.MAKE_BET') | translate }}
      </ng-template>
    </button>
    <div class="panel-actions__action-btn-tooltip gray-400 tw-flex tw-justify-between tw-items-center tw-font-normal tw-text-xs">
      <div
        *ngIf="gameSettings?.minBet"
        class="tw-inline-flex tw-gap-1"
      >
        {{ 'TRADE_PANEL.BET_TOTAL' | translate }}
        <app-currency [value]="gameSettings?.minBet" />
        <ng-container *ngIf="gameSettings?.maxBet">- <app-currency [value]="gameSettings?.maxBet" /></ng-container>
      </div>
      <div *ngIf="gameSettings?.maxItemsPerTrade">
        {{ 'TRADE_PANEL.BET_ITEMS' | translate }}
        {{ gameSettings?.minItemsPerTrade || 1 }} - {{ gameSettings?.maxItemsPerTrade }}
      </div>
    </div>
  </div>
  <div
    *ngIf="isPrticipationPanelOpened && !isMobile"
    class="tw-flex tw-flex-col tw-items-center tw-gap-2 tw-w-1/3"
  >
    <div class="gray-400 tw-text-sm">{{ 'TRADE_PANEL.BET_ITEMS' | translate }}</div>
    <div class="panel-actions__label tw-flex tw-items-center tw-justify-center tw-text-2xl tw-font-bold">
      {{ participatedItemsLength || newItemsLength }}
      <ng-container *ngIf="participatedItemsLength && newItemsLength">
        <span class="add-to-highlight">{{ newItemsLength }}</span>
      </ng-container>
      <ng-container *ngIf="gameSettings?.maxItemsPerTrade">/{{ gameSettings?.maxItemsPerTrade }} </ng-container>
      <div
        *ngIf="newItemsLength"
        class="icon-wrapper tw-flex tw-pl-2 tw-cursor-pointer"
        (click)="clearSelectedItems.emit()"
      >
        <mat-icon svgIcon="close-circle" />
      </div>
    </div>
  </div>
</div>
