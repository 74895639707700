import { IconConfig } from '@dev-fast/types';

import { arrowsCommonIcons } from './categories/arrows.index';
import { coinsCommonIcons } from './categories/coins.index';
import { socialsCommonIcons } from './categories/socials.index';
import { statusCommonIcons } from './categories/status.index';
//TODO Заменить -bold  на -fill; привести к одному виду модификаторы
// -line -- иконка без заливки; -fill -- иконка с заливкой;
export const commonIcons: IconConfig[] = [
  ...arrowsCommonIcons,
  ...coinsCommonIcons,
  ...socialsCommonIcons,
  ...statusCommonIcons,

  {
    name: 'cardboard',
    path: 'assets/img/mat-icons/icons-lib/common/cardboard.svg',
  },
  {
    name: 'dices',
    path: 'assets/img/mat-icons/icons-lib/common/dices.svg',
  },
  {
    name: 'shopping-cart',
    path: 'assets/img/mat-icons/icons-lib/common/shopping-cart.svg',
  },
  {
    name: 'trashcan',
    path: 'assets/img/mat-icons/icons-lib/common/trashcan.svg',
  },
  {
    name: 'settings-outline',
    path: 'assets/img/mat-icons/icons-lib/common/settings-outline.svg',
  },
  {
    name: 'refresh-line',
    path: 'assets/img/mat-icons/icons-lib/common/refresh-line.svg',
  },
  {
    name: 'hand-move',
    path: 'assets/img/mat-icons/icons-lib/common/hand-move.svg',
  },
  {
    name: 'crown',
    path: 'assets/img/mat-icons/icons-lib/common/crown.svg',
  },
  //users icons
  {
    name: 'users-bold',
    path: 'assets/img/mat-icons/icons-lib/common/users-bold.svg',
  },
  {
    name: 'users-line-bold',
    path: 'assets/img/mat-icons/icons-lib/common/users-line-bold.svg',
  },
  {
    name: 'heart-line',
    path: 'assets/img/mat-icons/icons-lib/common/heart-line.svg',
  },
  {
    name: 'heart-bold',
    path: 'assets/img/mat-icons/icons-lib/common/heart-bold.svg',
  },
  {
    name: 'thunder-line',
    path: 'assets/img/mat-icons/icons-lib/common/thunder-line.svg',
  },
  {
    name: 'thunder-fill',
    path: 'assets/img/mat-icons/icons-lib/common/thunder-fill.svg',
  },
  {
    name: 'question-mark',
    path: 'assets/img/mat-icons/icons-lib/common/question-mark.svg',
  },
  {
    name: 'plus-line-bold',
    path: 'assets/img/mat-icons/icons-lib/common/plus-line-bold.svg',
  },
  // Sort
  {
    name: 'sort-3-line',
    path: 'assets/img/mat-icons/icons-lib/common/sorting/sort-3-line.svg',
  },
  {
    name: 'sort-3-line-asc',
    path: 'assets/img/mat-icons/icons-lib/common/sorting/sort-3-line-asc.svg',
  },
  // Guns
  {
    name: 'gun-detailed',
    path: 'assets/img/mat-icons/icons-lib/common/gun-detailed.svg',
  },
];
