import { IUserShort } from './user';

export interface IInputMessage {
  value: string | IStickerMessage;
  game: string;
}
export interface IMessage extends IInputMessage {
  user: IUserShort;
  createdAt: Date;
  id: string;
}

export interface IStickerPack {
  name: string;
  size: number;
  path: string;
}

export interface IStickerMessage {
  id: number;
  path: string;
}
