import { FormControl } from '@angular/forms';

/**
 * Информация о пагианции
 */
export interface Pagination {
  number: number;
  size: number;
}
export interface PageInfo {
  numberPage: number;
  totalSize: number;
}
export interface PaginationForm {
  number: FormControl<number>;
  size: FormControl<number>;
}
