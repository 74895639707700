import { inject, Injectable } from '@angular/core';
import {
  IDepositBatchPayloadV2,
  IFilterFormContent,
  IKitResponseDto,
  IMarketplaceItem,
  IP2pDepositingItem,
  IP2pItemUpdated,
  IP2pParticipateItemUpdated,
  IP2pPurchaseItem,
  ISihDataset,
  ISteamInventoryAutoSelect,
  ISteamStore,
  ITransactionDto,
  ITransactionHistoryIds,
  MarketAction,
  MarketSortingTypes,
  SihCurrencyType,
} from '@dev-fast/types';
import { Socket as WrappedSocket } from 'ngx-socket-io';
import { Observable } from 'rxjs';

import { Environments, EnvironmentService } from '@app/core/environment-service';
import { paramsToQuery } from '@app/shared/utils';

import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class P2pApiNewService {
  readonly #api = inject(ApiService);
  readonly #environmentService = inject(EnvironmentService);
  readonly #ws = inject(WrappedSocket);

  readonly #environment: Environments = this.#environmentService.environments;

  /**
   * Получает инвентарь из Steam Store.
   * @param params Параметры фильтрации и сортировки.
   * @returns Observable с данными инвентаря из Steam Store.
   */
  getInventory(params?: IFilterFormContent<MarketSortingTypes>): Observable<ISteamStore> {
    return this.#api.get<ISteamStore>(`${this.#environment.GATEWAY_URL}/api/market/steam/inventory${paramsToQuery(params)}`, {
      withCredentials: true,
    });
  }

  /**
   * Получает автоматически выбранный инвентарь.
   * @param params Параметры запроса для автоматического выбора инвентаря Steam.
   * @returns Observable, который эмитирует объект ISteamStore.
   */
  getInventoryAutoSelect(params: ISteamInventoryAutoSelect): Observable<ISteamStore> {
    return this.#api.get<ISteamStore>(`${this.#environment.GATEWAY_URL}/api/market/steam/inventory${paramsToQuery(params)}`, {
      withCredentials: true,
    });
  }

  /**
   * Выполняет запрос на назначение действия для элемента рынка.
   * @param id - Идентификатор элемента рынка.
   * @param action - Действие, которое требуется назначить.
   * @returns Observable с объектом IMarketplaceItem.
   */
  reqAppointing(id: number, action: MarketAction): Observable<IMarketplaceItem> {
    return this.#api.post(`${this.#environment.GATEWAY_URL}/api/market/kits/${id}/${action}`, {}, { withCredentials: true });
  }

  reqDeposit(id: number, price: number): Observable<void> {
    return this.#api.post(`${this.#environment.GATEWAY_URL}/api/market/kits/new`, { id, price }, { withCredentials: true });
  }

  reqDepositSelected(
    items: IDepositBatchPayloadV2,
  ): Observable<{ errors: { error: string; steamInventoryId: number }[]; items: IMarketplaceItem[] }> {
    return this.#api.post(`${this.#environment.GATEWAY_URL}/api/market/kits/new`, items, { withCredentials: true });
  }

  reqSellNow(id: number): Observable<IMarketplaceItem> {
    return this.#api.post(`${this.#environment.GATEWAY_URL}/api/market/kits/${id}/sell-now`, {}, { withCredentials: true });
  }

  reqDelete(kitIds: number[]): Observable<{ items: IMarketplaceItem[] }> {
    return this.#api.post(`${this.#environment.GATEWAY_URL}/api/market/kits/my/delete`, { kitIds }, { withCredentials: true });
  }

  reqPause(kitIds: number[]): Observable<{ kits: IMarketplaceItem[] }> {
    return this.#api.post(`${this.#environment.GATEWAY_URL}/api/market/kits/my/pause`, { kitIds }, { withCredentials: true });
  }

  reqResume(
    kitIds: number[],
  ): Observable<{ errors: { error: string; id?: number; steamInventoryId?: number }[]; kits: IMarketplaceItem[] }> {
    return this.#api.post(`${this.#environment.GATEWAY_URL}/api/market/kits/my/resume`, { kitIds }, { withCredentials: true });
  }

  getMyItems(): Observable<{ kits: IP2pDepositingItem[] }> {
    return this.#api.get(`${this.#environment.GATEWAY_URL}/api/market/my/kits`, { withCredentials: true });
  }

  reqPurchasing(): Observable<{ kits: IP2pPurchaseItem[] }> {
    return this.#api.get(`${this.#environment.GATEWAY_URL}/api/market/my/bids`, { withCredentials: true });
  }

  reqBid(id: number): Observable<void> {
    return this.#api.post(`${this.#environment.GATEWAY_URL}/api/market/kits/${id}/bid`, {}, { withCredentials: true });
  }

  reqConfirm(id: number): Observable<Partial<IP2pPurchaseItem>> {
    return this.#api.post(`${this.#environment.GATEWAY_URL}/api/market/kits/${id}/confirm`, {}, { withCredentials: true });
  }

  reqBidDelete(id: number): Observable<void> {
    return this.#api.post(`${this.#environment.GATEWAY_URL}/api/market/kits/${id}/bid/delete`, {}, { withCredentials: true });
  }

  itemUpdatedEvent(callback: (response: IP2pItemUpdated) => void): void {
    this.#ws.on('market.item.updated', callback);
  }

  participantItemUpdatedEvent(callback: (response: IP2pParticipateItemUpdated) => void): void {
    this.#ws.on('market.participant.item.updated', callback);
  }

  itemDeletedEvent(callback: (response: { id: number }) => void): void {
    this.#ws.on('market.item.deleted', callback);
  }

  getSteamStatus(): Observable<{ status: string }> {
    return this.#api.get(`${this.#environment.GATEWAY_URL}/api/steam/status`);
  }

  /**
   * Получает список товаров рынка с заданными параметрами.
   *
   * @param params - Параметры фильтрации и сортировки.
   * @returns Observable с объектом IKitResponseDto, содержащим список товаров рынка.
   */
  getMarketItems(params?: IFilterFormContent<MarketSortingTypes>): Observable<IKitResponseDto> {
    const query = paramsToQuery(params);
    return this.#api.get(`${this.#environment.GATEWAY_URL}/api/market/kits${query}`, { withCredentials: true });
  }

  /**
   * Получает количество элементов на рынке.
   * @param params Параметры фильтрации и сортировки.
   * @returns Observable с объектом, содержащим количество элементов.
   */
  getMarketItemsCount(params?: IFilterFormContent<MarketSortingTypes>): Observable<{ count: number }> {
    const query = paramsToQuery(params);
    return this.#api.get(`${this.#environment.GATEWAY_URL}/api/market/kits/count${query}`, { withCredentials: true });
  }

  getCurrencies(base: SihCurrencyType): Observable<any> {
    return this.#api.get(`${this.#environment.SIH_API_URL}/rates`, {
      params: { base },
    });
  }

  getItemSihStats(params: any): Observable<ISihDataset> {
    return this.#api.get<ISihDataset>(`${this.#environment.SIH_STAT_API_URL}/item-prices`, {
      headers: { 'Access-Control-Allow-Origin': '*' },
      params,
    });
  }

  /**
   *
   * @deprecated использовать getPaymentHistory из pay.service.ts (возможно переименовать)
   */
  getMarketHistory(params: ITransactionHistoryIds): Observable<ITransactionDto[]> {
    return this.#api.get<ITransactionDto[]>(`${this.#environment.GATEWAY_URL}/api/transactions-history${paramsToQuery(params)}`, {
      withCredentials: true,
    });
  }
}
