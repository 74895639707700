import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import {
  ActivePanel,
  IInventoryItem,
  IInventoryRequestParams,
  InventoryTradeBalance,
  IShopMeta,
  ISkinItem,
  IUserDetailed,
  LegacyGameConfig,
  LegacyGameState,
  LegacyLiteGameState,
  Panel,
  SkinClickEvent,
  SubPanel,
} from '@dev-fast/types';
import { NgScrollbar } from 'ngx-scrollbar';
import { debounceTime, Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-inventory-panel',
  templateUrl: './inventory-panel.component.html',
  styleUrls: ['./inventory-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InventoryPanelComponent implements OnDestroy {
  @ViewChild(NgScrollbar) public scrollbarRef: NgScrollbar | undefined;
  @Input() public isMobile = true;
  @Input() public user: IUserDetailed | null = null;
  @Input() public totalBalance = 0;
  @Input() public inventoryList: IInventoryItem[] = [];
  @Input() public selectedItems: IInventoryItem[] = [];
  @Input() public shopItems: ISkinItem[] = [];
  @Input() public selectedShopItems: ISkinItem[] = [];
  @Input() public inventoryTradeBalance: InventoryTradeBalance | undefined;
  @Input() public shopParams: IInventoryRequestParams | undefined;
  @Input() public shopMeta: IShopMeta | undefined;
  @Input() public inventoryShopLoading = false;
  @Input() public legacyConfig: LegacyGameConfig | null = null;
  // @Input() public legacyConfig: LegacyGameConfig | null = null;
  @Input() public legacyState: LegacyGameState | null = null;
  @Input() public legacyLiteState: LegacyLiteGameState | null = null;
  @Input() public participatedSuccess: { items: any[]; timeout: number } | undefined;
  @Input() public legacyParticipation: IInventoryItem[] = [];
  @Input() public activePanel: ActivePanel | null = null;
  @Input() public gameInProgress: boolean | null = false;
  @Input() public set params(params: IInventoryRequestParams) {
    if (params) {
      this.form.patchValue({ ...params }, { emitEvent: false });
    }
  }

  @Output() public paramsEmit: EventEmitter<IInventoryRequestParams> = new EventEmitter<IInventoryRequestParams>();
  @Output() public withdrawEmit: EventEmitter<void> = new EventEmitter<void>();
  @Output() public reloadPriceEmit: EventEmitter<void> = new EventEmitter<void>();
  @Output() public onsetActivePanel: EventEmitter<ActivePanel | null> = new EventEmitter<ActivePanel | null>();
  @Output() public onselectInventoryItem: EventEmitter<SkinClickEvent> = new EventEmitter<SkinClickEvent>();
  @Output() public ontoggleInventoryItems: EventEmitter<void> = new EventEmitter<void>();
  @Output() public sellAll: EventEmitter<void> = new EventEmitter<void>();
  @Output() public oncreateTrade: EventEmitter<void> = new EventEmitter<void>();
  @Output() public onselectShopItem: EventEmitter<SkinClickEvent> = new EventEmitter<SkinClickEvent>();
  @Output() public onchangeShopParams: EventEmitter<IInventoryRequestParams> = new EventEmitter<IInventoryRequestParams>();
  @Output() public onparticipate: EventEmitter<void> = new EventEmitter<void>();
  @Output() public oncleanParticipate: EventEmitter<number[]> = new EventEmitter<number[]>();

  private destroy$: Subject<void> = new Subject();
  public panelEnum = Panel;
  public subPanelEnum = SubPanel;
  public form: FormGroup;
  public isInventoryAvailable = true;
  public showNotice = !this.isInventoryAvailable;

  constructor(private readonly fb: FormBuilder) {
    this.form = this.fb.group({
      query: '',
      sortBy: [true],
      page: [1],
      minPrice: null,
      maxPrice: null,
      pageSize: 50,
    });
    this.form.valueChanges.pipe(takeUntil(this.destroy$), debounceTime(100)).subscribe((val) => {
      this.paramsEmit.emit(val);
      this.scrollToTop();
    });
  }
  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
  public scrollToTop(): void {
    if (this.scrollbarRef) {
      this.scrollbarRef.scrollTo({ top: 0 });
    }
  }
  public sortPrice(): void {
    this.paramsEmit.emit(this.form.value);
  }
  public trackingFunction(index: number, item: IInventoryItem): number {
    return item.id;
  }
  public onRefresh(): void {
    this.form.patchValue(this.form.value);
  }
  public onCloseSubPanel(): void {
    this.setActivePanel({ panel: Panel.INVENTORY, subPanel: SubPanel.NONE });
  }
  public setActivePanel(panel: ActivePanel | null): void {
    this.onsetActivePanel.emit(panel);
  }
  public onToggleExchangePanel(): void {
    if (this.isInventoryAvailable) {
      if (this.activePanel?.subPanel === SubPanel.NONE) {
        this.setActivePanel({ panel: Panel.INVENTORY, subPanel: SubPanel.EXHANGE });
      } else {
        this.setActivePanel({ panel: Panel.INVENTORY, subPanel: SubPanel.NONE });
      }
    } else {
      this.showNotice = !this.showNotice;
    }
  }
  public onSellItems(): void {
    this.sellAll.emit();
  }
  public selectItem(event: SkinClickEvent): void {
    this.onselectInventoryItem.emit(event);
  }
  public toggleAllInventoryItems(): void {
    this.ontoggleInventoryItems.emit();
  }
  public isSelected(id: number): boolean {
    return this.selectedItems.some((item) => item.id === id);
  }
  public onBottomReached(): void {
    this.paramsEmit.emit({ ...this.form.value, page: this.form.value.page && this.form.value.page + 1 });
  }
  public calcItemsPrice(items: IInventoryItem[]): number {
    return items.reduce((acc, curr) => {
      return acc + curr.price;
    }, 0);
  }
  public createTrade(): void {
    this.oncreateTrade.emit();
  }
  public selectShopItem(event: SkinClickEvent): void {
    this.onselectShopItem.emit(event);
  }
  public changeShopParams(event: IInventoryRequestParams): void {
    this.onchangeShopParams.emit(event);
  }
  public participate(): void {
    this.onparticipate.emit();
  }
  public cleanParticipate(event: number[]): void {
    this.oncleanParticipate.emit(event);
  }
}
