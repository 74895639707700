import { CommonModule } from '@angular/common';
import { Component, Inject, Input, NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { EventsConfig, ICaseCollection, ICaseCollectionType, ICaseItemDtoV2 } from '@dev-fast/types';
import { TranslateModule } from '@ngx-translate/core';

import { IS_SERVER_TOKEN } from '@app/shared/utils';

import { CubeGridLoaderComponentModule } from '../../loaders/cube-grid/cube-grid.component';
import { CaseCardComponentModule } from '../cards';
import { cardEnterAnimation } from './cases-collection.animation';

@Component({
  selector: 'app-ui-cases-collection',
  templateUrl: './cases-collection.component.html',
  styleUrls: ['./cases-collection.component.scss', '../cases-collection/cases-collection.media.scss'],
  animations: [cardEnterAnimation],
})
export class CasesCollectionComponent {
  @Input() collection: ICaseCollection | null = null;
  @Input() index: number | undefined;
  @Input() activeCategory: ICaseCollectionType = 0;
  @Input() categoriesIsLoad: boolean;
  @Input() eventName: keyof EventsConfig | null;

  constructor(@Inject(IS_SERVER_TOKEN) public isServer: boolean) {
    this.categoriesIsLoad = false;
    this.eventName = null;
  }
  trackById(index: number, caseItem: ICaseItemDtoV2): number {
    return caseItem.id;
  }
}

@NgModule({
  imports: [CommonModule, MatIconModule, TranslateModule, CubeGridLoaderComponentModule, CaseCardComponentModule],
  declarations: [CasesCollectionComponent],
  exports: [CasesCollectionComponent],
})
export class CasesCollectionComponentModule {}
