import { Injectable } from '@angular/core';
import {
  ActivePanel,
  IInventoryItem,
  IInventoryRequestParams,
  IInventoryShortInfo,
  ILocales,
  INotification,
  IUserDetailed,
  LegacyGameConfig,
  LegacyGameState,
  LegacyLiteGameState,
  ModalNames,
  NewMenu,
  NewPanel,
  Panel,
} from '@dev-fast/types';
import { Navigate } from '@ngxs/router-plugin';
import { Actions, ofActionSuccessful, Select } from '@ngxs/store';
import { ActionsExecuting, actionsExecuting } from '@ngxs-labs/actions-executing';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { map, Observable } from 'rxjs';

import { AuthService } from '@app/auth';
import { LanguageService, SetLanguage } from '@app/core/language-service';
import { NotificationsService } from '@app/core/notification-service';
import { AccountState } from '@app/core/state/account';
import { ConfirmParticipateLegacySuccess, GamesState } from '@app/core/state/games-store';
import { ChangeParamsShop, GetInventoryInfo, GetShopItems, InventoryState } from '@app/core/state/inventory';
import { CloseAllMenu, CloseMenu, LayoutState, NavigateToMobile, OpenMenu } from '@app/core/state/layout';
import { OpenModal } from '@app/core/state/modals';
import { RemoveNotification, WatchAllNotification } from '@app/core/state/notifications';
import { CommonStoreService } from '@app/core-state-common';

@Injectable()
export class DesktopAppActionMenuService {
  user$: Observable<IUserDetailed | null | undefined> = this.commonService.user$;
  activePanel$: Observable<ActivePanel | null> = this.commonService.activePanel$;
  notifications$: Observable<INotification[]> = this.notificationsService.notifications$;
  hasNewNotifications$: Observable<boolean> = this.notificationsService.hasNewNotifications$;
  countNewNotifications$: Observable<number> = this.notificationsService.countNewNotifications$;
  locales$: Observable<ILocales[]> = this.languageService.locales$;
  currentLocale$: Observable<ILocales> = this.languageService.currentLocale$;

  @Select(actionsExecuting([GetShopItems, ChangeParamsShop]))
  inventoryShopLoading$!: Observable<ActionsExecuting>;
  @Select(InventoryState.inventorySum)
  totalBalance$!: Observable<number>;
  @Select(InventoryState.shopParams)
  shopParams$!: Observable<IInventoryRequestParams>;
  @Select(InventoryState.inventoryShortInfo)
  inventoryShortInfo$!: Observable<IInventoryShortInfo>;
  @Select(GamesState.legacyConfig)
  legacyConfig$!: Observable<LegacyGameConfig | null>;
  @Select(GamesState.legacyState)
  legacyState$!: Observable<LegacyGameState | null>;
  @Select(GamesState.legacyLiteState)
  legacyLiteState$!: Observable<LegacyLiteGameState | null>;
  @Select(GamesState.legacyParticipation)
  legacyParticipation$!: Observable<IInventoryItem[]>;
  @Select(AccountState.canLevelUp)
  canLevelUp$!: Observable<boolean>;
  @Select(LayoutState.lockedPanels)
  lockedPanels$!: Observable<Panel[]>;
  @Select(LayoutState.activeMenu)
  activeMenu$!: Observable<NewMenu[] | null>;
  @Select(LayoutState.activePanelNew)
  panels$!: Observable<NewPanel[]>;

  participatedSuccess$ = this.actions$.pipe(
    ofActionSuccessful(ConfirmParticipateLegacySuccess),
    map((action: ConfirmParticipateLegacySuccess) => action.payload),
  );

  constructor(
    private commonService: CommonStoreService,
    private readonly authService: AuthService,
    private readonly notificationsService: NotificationsService,
    private readonly actions$: Actions,
    private readonly languageService: LanguageService,
  ) {}
  changeActivePanel = (activePanel: ActivePanel | null): void => {
    this.commonService.changeActivePanel(activePanel);
    if (activePanel?.panel === Panel.MAIN_MENU) {
      this.getInventoryInfo();
    }
  };
  login = (): void => this.authService.openLoginProvidersModal();
  logout = (): void => this.authService.onLogout();
  switchLang = (locale: ILocales): SetLanguage => this.languageService.setLanguage(locale);
  removeNoty = (notyId: number): RemoveNotification => this.notificationsService.removeNotification(notyId);
  watchAllNotifications = (): WatchAllNotification => this.notificationsService.watchAllNotifications();

  @Dispatch() openSupportSelectorModal = (): OpenModal => new OpenModal(ModalNames.TECH_SUPPORT_SELECT_METHOD);
  @Dispatch() getInventoryInfo = (): GetInventoryInfo => new GetInventoryInfo();
  @Dispatch() navigateToMobile = (): NavigateToMobile => new NavigateToMobile();
  @Dispatch() navigate = (path: any[]): Navigate => new Navigate(path);
  @Dispatch() openMenu = (menu: NewMenu): OpenMenu => new OpenMenu(menu);
  @Dispatch() closeMenu = (menu: NewMenu): CloseMenu => new CloseMenu(menu);
  @Dispatch() closeAllMenu = (): CloseAllMenu => new CloseAllMenu();
}
