import { GameTypes, LotteryType, TicketTypeEnum } from '../enums';
import { IUserShort } from './user';

export interface ILRBalance {
  green: number;
  blue: number;
  orange: number;
  // для новогоднего евента нижние ключики
  greenKeys: number;
  blueKeys: number;
  orangeKeys: number;
}
export interface ILRQuestParams {
  count: number;
  amount?: number;
  betName?: string;
  floorNumber?: number;
  coef?: number;
}
export interface ILRQuest {
  id: number;
  name: string;
  gameTypeId: GameTypes;
  /** только викли и дейли*/
  type: LotteryType;
  params: ILRQuestParams;
  isHidden: boolean;
  startAt: Date;
  /** у дейликов сутки у викли неделя*/
  finishAt: Date;
  /** Важно для викли последовательность, для дейликов всегда 0 */
  priority: number;
  progress: number;
  rewardTaken: boolean;
  isOpened: boolean;
}

export interface ICaseLRInfo {
  freeCaseId?: number;
  ticketsAmount?: number;
  ticketColor?: TicketTypeEnum;
}
export interface ILRPlayer {
  tickets: number;
  place: number;
  prizeTicketsAmount: number | null;
  prizeCoinsAmount: number | null;
  user: IUserShort;
}
export interface ILRLeaderboards {
  color: TicketTypeEnum;
  players: ILRPlayer[];
  totalTickets: number;
  firstTop: number;
  secondTop: number;
  myStats?: {
    place: number;
    tickets: number;
  };
}
export interface ILRTop {
  count: number;
  total: number;
  prizes: number;
  superPrizes: number;
  user: IUserShort;
  tickets: ILRBalance;
}
export interface ILRLeaders {
  startAt: Date;
  finishAt: Date;
  leaderboards: ILRLeaderboards[];
  top: ILRTop[];
}
// export type ILRCase = ICaseItemDtoV2 & ICaseLRInfo
