import { animate, query, style, transition, trigger } from '@angular/animations';

export const replacementAnimation = trigger('replacementAnimation', [
  transition(':enter', [query(':self', [style({ opacity: 0 }), animate('150ms ease-in-out', style({ opacity: 1 }))], { optional: true })]),
  transition(':leave', [query(':self', [style({ opacity: 1 }), animate('150ms ease-in-out', style({ opacity: 0 }))], { optional: true })]),
]);

export const replacementScaleAnimation = trigger('replacementScaleAnimation', [
  transition(':enter', [
    query(
      ':self',
      [style({ transform: 'scale(0.875)', opacity: 0 }), animate('150ms ease-in-out', style({ transform: 'scale(1)', opacity: 1 }))],
      { optional: true },
    ),
  ]),
  transition(':leave', [
    query(
      ':self',
      [style({ transform: 'scale(1)', opacity: 1 }), animate('150ms ease-in-out', style({ transform: 'scale(0.875)', opacity: 0 }))],
      { optional: true },
    ),
  ]),
]);
