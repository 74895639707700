import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, NgModule } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { IReferralBonus } from '@dev-fast/types';
import { UserAvatarModule } from '@dev-fast/ui-components';
import { TranslateModule } from '@ngx-translate/core';

import { LangRouterModule } from '@app/shared/directives';
import { AppCurrencyModule } from '@app/shared/pipes';

@Component({
  selector: 'app-case-bonus-modal',
  templateUrl: './case-bonus-modal.component.html',
  styleUrls: ['./case-bonus-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CaseBonusModalComponent {
  payload!: IReferralBonus | null;

  constructor(
    private readonly dialogRef: MatDialogRef<CaseBonusModalComponent>,
    @Inject(MAT_DIALOG_DATA) private data: IReferralBonus,
  ) {
    this.payload = data;
  }

  close(): void {
    this.dialogRef.close();
  }
}

@NgModule({
  imports: [CommonModule, MatIconModule, RouterModule, UserAvatarModule, AppCurrencyModule, TranslateModule, LangRouterModule],
  declarations: [CaseBonusModalComponent],
  exports: [CaseBonusModalComponent],
})
export class CaseBonusModalComponentModule {}
