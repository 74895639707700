import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { LazyLoadImageModule } from '@app/shared/directives';

import { LanguageDropDownComponent } from './language/language.component';

@NgModule({
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatIconModule,
    RouterModule,
    TranslateModule,
    LazyLoadImageModule,
  ],
  declarations: [LanguageDropDownComponent],
  exports: [LanguageDropDownComponent],
})
export class SharedComponentsModule {}
