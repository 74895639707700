<div class="flex fx-column gap-2">
  <ng-container *ngIf="isAuth && permissions; else loginTemplate">
    <ng-container *ngIf="isP2PItemTemplate; else steamBtnTemplate">
      <ng-container *ngIf="dealStatus === LotStatus.AT_BID">
        <div
          class="flex fx-justify-between"
          *ngIf="!isLastBidByUser"
        >
          {{ 'P2P_WIDGETS.PURCHASE.BID_AMOUNT' | translate }}
          <app-currency
            class="color-white tw-font-medium p2p-modal-value"
            [value]="nextBidPrice"
          />
        </div>
        <ng-container *ngIf="permissions.error; else tradeLayout">
          <button
            *ngIf="permissions.error === steamErrors.UNBINDING_STEAM; else p2psettings"
            class="btn btn-primary--design"
            (click)="bindSocial()"
          >
            {{ 'P2P_WIDGETS.BIND_STEAM' | translate }}
          </button>
          <ng-template #p2psettings>
            <button
              *ngIf="cantTradeErrors.includes(permissions.error)"
              class="btn btn-primary--design"
              (click)="setupSteam()"
            >
              <mat-icon svgIcon="steam"></mat-icon> {{ 'P2P_SETTINGS.SETUP_TITLE' | translate }}
            </button>
          </ng-template>
        </ng-container>

        <ng-template #tradeLayout>
          <ng-container [ngSwitch]="permissions.canTrade?.error">
            <a
              *ngSwitchCase="steamErrors.STEAM_GUARD"
              class="btn btn-primary--design"
              [href]="steamGuardLink"
              target="_blank"
            >
              {{ 'P2P_WIDGETS.PURCHASE.ADD_STEAM_GUARD' | translate }}
            </a>
            <button
              *ngSwitchCase="steamErrors.STEAM_GUARD_HOLD"
              class="btn btn-primary--design"
              disabled
            >
              {{ 'P2P_WIDGETS.PURCHASE.STEAM_GUARD_HOLD' | translate: { days: permissions.canTrade?.days || 0 } }}
            </button>
            <button
              *ngSwitchCase="steamErrors.STEAM_TRADE_BAN"
              class="btn btn-primary--design"
              disabled
            >
              {{ 'P2P_WIDGETS.PURCHASE.YOU_ARE_BANNED' | translate }}
            </button>
            <button
              *ngSwitchCase="steamErrors.INVALID_TRADELINK"
              class="btn btn-primary--design"
              (click)="setupSteam()"
            >
              {{ 'P2P_WIDGETS.ADD_TRADE_LINK' | translate }}
            </button>
            <a
              *ngSwitchCase="steamErrors.PRIVATE_INVENTORY"
              class="btn btn-primary--design"
              [href]="inventorySettingsLink"
              target="_blank"
            >
              {{ 'BALANCE_REFILL.P2P.ALERTS.UNLOCK_STEAM_ACTION' | translate }}
            </a>
            <ng-container *ngSwitchDefault>
              <button
                class="btn btn-primary--design"
                (click)="placeBid()"
              >
                {{ (isLastBidByUser ? 'P2P_WIDGETS.PURCHASE.YOUR_OFFER' : 'P2P_WIDGETS.PURCHASE.BID_BUTTON') | translate }}
              </button>
            </ng-container>
          </ng-container>
        </ng-template>
        <div
          class="flex fx-align-center gap-2"
          *ngIf="timer"
        >
          <div class="flex fx-align-center gap-1">
            <mat-icon
              svgIcon="clock"
              class="clock-icon"
            ></mat-icon>
            {{ 'P2P_WIDGETS.RENEW_INFO_MODAL.AUCTION_END' | translate }}
          </div>
          <div class="color-white weight-500">{{ timer | date: offerTimerDateFormat }}</div>
        </div>
      </ng-container>
      <ng-container *ngIf="dealStatus === LotStatus.WAIT_FOR_TRADE">
        <div
          class="flex gap-1"
          *ngIf="timer"
        >
          {{
            (statuses.WAIT_FOR_CONFIRM === status ? 'P2P_WIDGETS.PURCHASE.WAIT_FOR_CONFIRM' : 'P2P_WIDGETS.PURCHASE.WAIT_FOR_TRADE')
              | translate
          }}
          <div class="color-white weight-500">{{ timer | date: offerTimerDateFormat }}</div>
        </div>
      </ng-container>
      <ng-container *ngIf="dealStatus === LotStatus.WAIT_FOR_TRADE_ACCEPT">
        <div
          class="flex gap-1"
          *ngIf="timer"
        >
          {{ 'P2P_WIDGETS.PURCHASE.WAIT_FOR_BUYER_ACCEPT' | translate }}
          <div class="color-white weight-500">{{ timer | date: offerTimerDateFormat }}</div>
        </div>
        <button
          class="btn btn-primary--design w-100 mt-sm-5"
          (click)="getTrade()"
        >
          {{ 'P2P_WIDGETS.PURCHASE.GET_TRADE' | translate }}
        </button>
      </ng-container>
      <div
        *ngIf="dealStatus === LotStatus.CLOSED"
        class="lot__actions-describe pt-sm-3"
      >
        {{ (status === statuses.COMPLETED ? 'P2P_WIDGETS.AUCTION_COMPLETED_DIALOG.AUCTION_COMPLETED' : dealCloseStatusLocale) | translate }}
      </div>
    </ng-container>
    <ng-template #steamBtnTemplate>
      <button
        class="btn btn-primary--design"
        (click)="selectSteamItem()"
        [disabled]="isSteamItemBtnDisable"
      >
        {{ steamItemStatusLocale | translate }}
      </button>
    </ng-template>
  </ng-container>
  <ng-template #loginTemplate>
    <button
      class="btn btn-primary--design"
      (click)="login()"
    >
      {{ 'LOGIN' | translate }}
    </button>
  </ng-template>
</div>
