import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, NgModule, ViewEncapsulation } from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { RouterModule } from '@angular/router';
import { ISeoFaq } from '@dev-fast/types';
import { TranslateModule } from '@ngx-translate/core';

import { LangRouterModule } from '@app/shared/directives';

import { SkinItemModule } from '../skin-item/skin-item.component';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  selector: 'app-ui-faq',
  styleUrls: ['./faq.component.scss'],
  templateUrl: './faq.component.html',
})
export class FaqComponent {
  @Input() faq: ISeoFaq | null | undefined;
}

@NgModule({
  imports: [NgIf, MatExpansionModule, RouterModule, SkinItemModule, TranslateModule, LangRouterModule],
  declarations: [FaqComponent],
  exports: [FaqComponent],
})
export class FaqModule {}
