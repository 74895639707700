<div
  class="scroller-buttons-container"
  #paddingContainer
>
  <button
    *ngIf="!isHideLeftButton && focusedElement > 0"
    [ngStyle]="{ visibility: isHideLeftButton ? 'hidden' : 'inherit' }"
    class="scroller-btn scroller-left"
    (click)="scrollToPrevious()"
  >
    <img
      src="https://d2lomvz2jrw9ac.cloudfront.net/common/left.webp"
      alt=""
    />
  </button>
  <ng-scrollbar
    track="horizontal"
    class="scrollbar hidden-scrollbar"
  >
    <div
      scrollViewport
      class="scrollbar"
    >
      <div
        #container
        class="roulette"
        [ngStyle]="{ gap: gap + measure }"
      >
        <ng-content></ng-content>
      </div>
    </div>
  </ng-scrollbar>
  <button
    *ngIf="!isHideRightButton"
    [ngStyle]="{ visibility: isHideRightButton ? 'hidden' : 'inherit' }"
    class="scroller-btn scroller-right"
    (click)="scrollToNext()"
  >
    <img
      src="https://d2lomvz2jrw9ac.cloudfront.net/common/right.webp"
      alt=""
    />
  </button>
</div>
