import { IBaseMarketplaceItem, IMarketplaceItem } from '../merketplace';
import { IP2pBaseItem } from './p2p-base-item.interface';

export interface IP2pDepositingItem extends IBaseMarketplaceItem, IP2pBaseItem {
  order?: {
    buyerSteamName: string;
    buyerId: number;
    buyerMarket: string;
    // хэш, нужно достроить линку
    buyerSteamAvatar: string;
    buyerSteamCreatedAt: string;
    buyerSteamId: number;
    buyerSteamLevel: number;
    buyerTradeLink: string;
    fastOfferError: null;
    fastOfferReason: null;
    id: number;
    steamOfferId: null;
    steamItemPosition: number[];
  };
  // попытка малой кровью перейти на новый тип данных с бека
  items?: IMarketplaceItem[];
  isActive?: boolean;
  disableActions?: boolean; // Убирает кнопки паузы и отмены аукциона (для продаж из инвентаря)
  endTime?: string;
}
export interface IP2pDepositingItemV2 extends IBaseMarketplaceItem, IP2pBaseItem {
  order: {
    buyerSteamName: string;
    buyerId: number;
    buyerMarket: string;
    buyerSteamAvatar: string; // хэш для пайпа | replaceAvaHash
    buyerSteamCreatedAt: string;
    buyerSteamId: number;
    buyerSteamLevel: number;
    buyerTradeLink: string;
    fastOfferError: null;
    fastOfferReason: null;
    id: number;
    steamOfferId: null;
    steamItemPosition: number[];
  };
  items: IFormatedMarketplaceItem[];
  isActive?: boolean;
}
export interface IFormatedMarketplaceItem extends IMarketplaceItem {
  indicator: number;
  page: number;
  line: number;
  number: number;
  steamItemPosition: number[];
}
