import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSliderModule } from '@angular/material/slider';
import { RouterModule } from '@angular/router';
import { CountdownComponentModule, GhostComponentModule, UiInputSAComponent } from '@dev-fast/ui-components';
import { TranslateModule } from '@ngx-translate/core';
import { NgScrollbarModule } from 'ngx-scrollbar';

import { AppCurrencyModule } from '@app/shared/pipes';

import { P2pSellAdditionalSettingsComponent } from './components/p2p-sell-additional-settings/p2p-sell-additional-settings.component';
import { P2pSellAdviserComponent } from './components/p2p-sell-adviser/p2p-sell-adviser.component';
import { P2pSellSetupPanelComponent } from './components/p2p-sell-setup-panel/p2p-sell-setup-panel.component';
import { P2pSellComponent } from './p2p-sell.component';
import { P2pSellService } from './p2p-sell.service';
import { P2pSellSetupItemModule } from './p2p-sell-setup-item/p2p-sell-setup-item.module';
import { P2pSellingItemModule } from './p2p-selling-item/p2p-selling-item.module';

@NgModule({
  declarations: [P2pSellComponent, P2pSellSetupPanelComponent, P2pSellAdviserComponent, P2pSellAdditionalSettingsComponent],
  exports: [P2pSellComponent],
  providers: [P2pSellService],
  imports: [
    CommonModule,
    TranslateModule,
    NgScrollbarModule,
    P2pSellSetupItemModule,
    P2pSellingItemModule,
    ReactiveFormsModule,
    CountdownComponentModule,
    GhostComponentModule,
    AppCurrencyModule,
    MatIconModule,
    MatSliderModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatInputModule,
    RouterModule,
    FormsModule,
    UiInputSAComponent,
  ],
})
export class P2pSellModule {}
