<div
  class="tw-relative tw-flex tw-w-full tw-h-full tw-animate-fade-in-center"
  (click)="anim()"
>
  <app-ui-confetti
    class="tw-z-[1] tw-absolute tw-overflow-visible tw-inset-0 tw-m-auto tw-w-full tw-h-full tw-cursor-pointer"
    [anim$]="confettiAnim$"
  >
  </app-ui-confetti>
  <div
    class="tw-absolute tw-overflow-visible tw-inset-0 tw-m-auto tw-flex tw-flex-row tw-justify-evenly tw-items-center tw-w-full tw-h-full"
  >
    <div class="tw-flex tw-flex-col tw-justify-evenly tw-w-0 tw-h-full left-wings">
      <div class="tw-relative tw-h-0 tw-w-0 tw-z-[3] tw-text-[--color-yellow-500] animate animate_left animate_top">
        <mat-icon
          class="tw-absolute tw-right-0 -tw-top-3 !tw-w-20 !tw-h-auto shadow_md"
          [svgIcon]="'wing-left'"
        >
        </mat-icon>
      </div>
      <div class="tw-relative tw-h-0 tw-w-0 tw-z-[2] tw-text-orange-300 animate animate_left animate_middle">
        <mat-icon
          class="tw-absolute tw-right-0 -tw-top-3 !tw-w-16 !tw-h-auto shadow_md"
          [svgIcon]="'wing-left'"
        >
        </mat-icon>
      </div>
      <div class="tw-relative tw-h-0 tw-w-0 tw-z-[1] tw-text-orange-400 animate animate_left animate_bottom">
        <mat-icon
          class="tw-absolute tw-right-0 -tw-top-4 !tw-w-12 !tw-h-auto shadow_md"
          [svgIcon]="'wing-left'"
        >
        </mat-icon>
      </div>
    </div>
    <div class="tw-flex tw-flex-col tw-justify-evenly tw-w-0 tw-h-full rigth-wings">
      <div class="tw-relative tw-h-0 tw-w-0 tw-z-[3] tw-text-[--color-yellow-500] animate animate_right animate_top">
        <mat-icon
          class="tw-absolute tw-left-0 -tw-top-3 !tw-w-20 !tw-h-auto shadow_md"
          [svgIcon]="'wing-right'"
        >
        </mat-icon>
      </div>
      <div class="tw-relative tw-h-0 tw-w-0 tw-z-[2] tw-text-orange-300 animate animate_right animate_middle">
        <mat-icon
          class="tw-absolute tw-left-0 -tw-top-3 !tw-w-16 !tw-h-auto shadow_md"
          [svgIcon]="'wing-right'"
        >
        </mat-icon>
      </div>
      <div class="tw-relative tw-h-0 tw-w-0 tw-z-[1] tw-text-orange-400 animate animate_right animate_bottom">
        <mat-icon
          class="tw-absolute tw-left-0 -tw-top-4 !tw-w-12 !tw-h-auto shadow_md"
          [svgIcon]="'wing-right'"
        >
        </mat-icon>
      </div>
    </div>
  </div>
  <div class="tw-absolute tw-inset-0 tw-m-auto tw-flex tw-justify-center tw-items-center tw-w-full tw-h-full tw-z-[5]">
    <div
      class="tw-grid tw-justify-center tw-content-center tw-overflow-hidden tw-inset-0 tw-m-auto tw-w-4/5 tw-h-4/5 tw-animate-spin-slow tw-rounded shadow_sm"
    >
      <div class="tw-grid tw-grid-cols-2 tw-grid-rows-2 tw-rotate-45 tw-w-52 tw-h-52">
        <div class="tw-bg-gray-200"></div>
        <div class="tw-bg-blue-300"></div>
        <div class="tw-bg-blue-300"></div>
        <div class="tw-bg-gray-200"></div>
      </div>
    </div>
  </div>
  <mat-icon
    class="!tw-w-full !tw-h-full tw-z-[5] shadow_sm"
    [svgIcon]="'shield-circle'"
  >
  </mat-icon>
  <mat-icon
    class="tw-absolute tw-inset-0 tw-m-auto !tw-w-auto !tw-h-2/5 tw-z-[5]"
    [svgIcon]="icon"
  >
  </mat-icon>
</div>
