import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { GAME_MODES, GameMode, GameStatus, IGame, IMenuItemConfig } from '@dev-fast/types';

@Component({
  selector: 'app-ui-game-type-container',
  templateUrl: './game-type-container.component.html',
  styleUrls: ['./game-type-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GameTypeContainerComponent {
  @Input() public games!: IGame[];
  @Input() public isTable: boolean | null = false;
  @Input() public mode: GameMode = GameMode.PVE;

  @Input() public state!: Record<string, GameStatus> | null;

  @Output() public AllGamesPanelStatus = new EventEmitter<boolean>();
  public modesDesc = GAME_MODES;

  public navigateGameItem(): void {
    this.AllGamesPanelStatus.emit(false);
  }

  public getItemConfig(game: IGame): IMenuItemConfig {
    return {
      game: game,
      isTable: this.isTable,
      status: this.state ? this.state[game.key] : undefined,
    };
  }
}
