<ng-container *ngIf="menuItems.length">
  <ng-container *ngIf="isInputAutocomplete; else defaultInput">
    <mat-form-field
      #matForm
      appearance="fill"
      [ngClass]="formClass"
    >
      <mat-icon
        matSuffix
        class="arrow-icon"
        [svgIcon]="'arrow-left-design'"
        (click)="closeMenu($event)"
      ></mat-icon>
      <ng-container *ngIf="selectedItem?.icon">
        <ng-container *ngIf="isInputFocused || !selectedItem; else itemIconTemplate">
          <mat-icon
            class="icon prefix-icon"
            [svgIcon]="'peer-to-peer-edit-icon'"
          >
          </mat-icon>
        </ng-container>
        <ng-template #itemIconTemplate>
          <img
            class="prefix-icon"
            [class]="selectedItem?.icon?.type"
            [src]="selectedItem?.icon?.src"
          />
        </ng-template>
      </ng-container>
      <input
        matInput
        matAutocompleteTrigger
        matAutocompleteOrigin
        #elementInput
        [matAutocompletePosition]="'below'"
        [placeholder]="labelText || ''"
        [matAutocomplete]="autocomplete"
        [formControl]="autocompleteControl"
        (input)="filterItems($event)"
      />
      <mat-autocomplete
        [class]="panelClass"
        (opened)="toggleInputValue(true)"
        (closed)="toggleInputValue(false)"
        #autocomplete="matAutocomplete"
      >
        <ng-scrollbar
          [autoHeightDisabled]="false"
          [appearance]="'standard'"
        >
          <div class="menu-scroll-wrapper">
            <ng-container *ngFor="let item of sortMenuItems(filteredItems$ | async, importantItems); let index = index">
              <mat-option
                [autofocus]="false"
                [value]="item.title"
                (click)="selectItemForAutocomplete(item.name); elementInput.blur()"
              >
                <ng-container *ngIf="item.icon">
                  <ng-container *ngIf="item.icon.type === 'image'">
                    <img
                      class="menu-icon"
                      [class]="item.icon.type"
                      [src]="item.icon.src"
                    />
                  </ng-container>
                  <ng-container *ngIf="item.icon.type === 'icon'">
                    <mat-icon
                      class="menu-icon"
                      [class]="item.icon.type"
                      [svgIcon]="item.icon.src"
                    ></mat-icon>
                  </ng-container>
                </ng-container>
                {{ item.title | translate }}
              </mat-option>
            </ng-container>
          </div>
        </ng-scrollbar>
      </mat-autocomplete>
    </mat-form-field>
  </ng-container>
  <ng-template #defaultInput>
    <mat-form-field
      appearance="fill"
      [ngClass]="formClass"
    >
      <mat-label *ngIf="labelText">
        {{ labelText }}
      </mat-label>
      <mat-icon
        matSuffix
        class="arrow-icon"
        [svgIcon]="'arrow-left-design'"
      ></mat-icon>
      <mat-select
        [(value)]="selectedItem"
        [panelClass]="panelClass"
        [disabled]="disabled"
      >
        <mat-select-trigger>
          <ng-container *ngIf="selectedItem">
            <ng-container *ngIf="selectedItem.icon">
              <ng-container *ngIf="selectedItem.icon.type === 'image'">
                <img
                  class="prefix-icon"
                  [class]="selectedItem.icon.type"
                  [src]="selectedItem.icon.src"
                />
              </ng-container>
              <ng-container *ngIf="selectedItem.icon.type === 'icon'">
                <mat-icon
                  class="prefix-icon"
                  [class]="selectedItem.icon.type"
                  [svgIcon]="selectedItem.icon.src"
                ></mat-icon>
              </ng-container>
            </ng-container>
            {{ selectedItem.title | translate }}
          </ng-container>
        </mat-select-trigger>
        <ng-scrollbar
          [autoHeightDisabled]="false"
          [appearance]="'standard'"
        >
          <div class="menu-scroll-wrapper">
            <ng-container *ngFor="let item of sortMenuItems(menuItems, importantItems); let index = index">
              <mat-option
                [value]="item"
                (click)="selectItem(index)"
              >
                <ng-container *ngIf="item.icon">
                  <!-- тут бы свич вместо ифов пачки -->
                  <ng-container *ngIf="item.icon.type === 'image'">
                    <img
                      class="menu-icon"
                      [class]="item.icon.type"
                      [src]="item.icon.src"
                    />
                  </ng-container>
                  <ng-container *ngIf="item.icon.type === 'icon'">
                    <mat-icon
                      class="menu-icon"
                      [class]="item.icon.type"
                      [svgIcon]="item.icon.src"
                    ></mat-icon>
                  </ng-container>
                </ng-container>
                {{ item.title | translate }}
              </mat-option>
            </ng-container>
          </div>
        </ng-scrollbar>
      </mat-select>
    </mat-form-field>
  </ng-template>
</ng-container>
