export interface IPercent {
  rate: number;
  prefix: string;
  postfix: string;
}

export interface IPercentProperties {
  prefix?: boolean;
  rank?: number;
  maxValue?: number | null;
}
