import { Directive, ElementRef, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

@Directive({
  selector: '[scrolled-to]',
})
export class ScrolledToDirective implements OnInit, OnChanges {
  @Output() public scrolledEvent: EventEmitter<boolean>;
  /**
   * Как только это значение изменится, то следующий скролл будет игнорировать старый стейт
   * Передавать можно, например, количество загруженных скролом элементов
   * Нужно для того, чтобы после подгрузки элементов, если директива осталась в видимой области, чтобы повторный скролл засчитался без ыхода из области видимости
   */
  @Input() public scrollCheck: any = null;
  /**
   * То за сколько пикселей заранее мы тригерим наведение
   */
  @Input() public scrollOffset = 1000;
  /**
   * То когда мы тригерим событие. При любом скролле (и вверх и вниз) или только вниз
   */
  @Input() public scrollType: 'all' | 'bottom' = 'all';

  /**
   * Используем ли мы автонаведение на подгруженные элементы
   */
  @Input() public disableAutoScroll = false;

  private visible = false;
  private lastScrollPositionTop = 0;
  private lastScrollPositionBottom = 0;
  private savedScrollPosition = 0; // Сохраненная позиция прокрутки

  constructor(private el: ElementRef<HTMLElement>) {
    this.scrolledEvent = new EventEmitter(false);
  }

  @HostListener('document:scroll')
  public onScroll(): void {
    const rect = this.el.nativeElement.getBoundingClientRect();
    const elemTop = rect.top;
    const elemBottom = rect.bottom;

    if (this.shouldTriggerScrollEvent(elemTop, elemBottom)) {
      this.handleScrollEvent(elemTop, elemBottom);
    }

    this.lastScrollPositionTop = elemTop;
    this.lastScrollPositionBottom = elemBottom;

    this.savedScrollPosition = window.scrollY + elemTop - 8; // Сохранение позиции элемента
  }

  private shouldTriggerScrollEvent(elemTop: number, elemBottom: number): boolean {
    return (
      (['all', 'bottom'].includes(this.scrollType) && elemTop < this.lastScrollPositionTop) ||
      (this.scrollType === 'all' && elemBottom > this.lastScrollPositionBottom)
    );
  }

  private handleScrollEvent(elemTop: number, elemBottom: number): void {
    const isVisible = elemTop >= -this.scrollOffset && elemBottom <= window.innerHeight + this.scrollOffset;
    if (isVisible && !this.visible) {
      this.visible = true;
      this.scrolledEvent.emit(true);
    } else if (!isVisible && this.visible) {
      this.visible = false;
      this.scrolledEvent.emit(false);
    }
  }

  private updateScrollPositions(): void {
    const rect = this.el.nativeElement.getBoundingClientRect();
    this.lastScrollPositionTop = rect.top;
    this.lastScrollPositionBottom = rect.bottom;
  }

  private scrollToSavedPosition(): void {
    if (window.scrollY > this.savedScrollPosition) {
      window.scrollTo({ top: this.savedScrollPosition });
    }
  }

  // -------- ANGULAR LIFE CYCLE --------

  public ngOnInit(): void {
    this.updateScrollPositions();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    // Если состояние скролла изменилось (изменилось количество предметов, которое проверяет директива)
    // И если мы находимся НИЖЕ этого списка, то нас автоматически наведет на новые подгруженные элементы
    if (changes['scrollCheck'].currentValue) {
      this.visible = false;
      if (!this.disableAutoScroll) {
        this.scrollToSavedPosition();
      }
    }
  }
}
