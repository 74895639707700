import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ILocales, NewMenu } from '@dev-fast/types';

@Component({
  selector: 'app-language-panel',
  templateUrl: './language-panel.component.html',
  styleUrls: ['./language-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LanguagePanelComponent {
  @Input() set locale(value: ILocales | null) {
    if (value) {
      this.currentLanguage = value.path;
    }
  }
  @Input() locales: ILocales[] | null = null;

  @Output() languageChange: EventEmitter<ILocales> = new EventEmitter<ILocales>();
  @Output() closePanel: EventEmitter<NewMenu> = new EventEmitter<NewMenu>();

  menu = NewMenu;
  currentLanguage = '';

  onLangChange(newLanguage: string): void {
    const findLocale = this.locales?.find((locale) => locale.path === newLanguage);
    if (findLocale) {
      this.languageChange.emit(findLocale);
    }
  }

  onClosePanel(panel: NewMenu): void {
    this.closePanel.emit(panel);
  }
}
