<ng-scrollbar>
  <ng-container *ngFor="let mode of modes">
    <div
      *ngIf="itemsDictionary[mode]"
      [class]="'wrapper-group wrapper-group--' + mode"
    >
      <div class="menu-group">
        <app-ui-game-item
          *ngFor="let game of itemsDictionary[mode]"
          [game]="game"
        >
        </app-ui-game-item>
      </div>
    </div>
  </ng-container>
</ng-scrollbar>
