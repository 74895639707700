<div class="provably-fair-modal__container">
  <div class="provably-fair__title">{{ 'LOCAL.PROVABLY_FAIR.TITLE' | translate }}</div>
  <div class="provably-fair__players-wrapper">
    <ng-scrollbar
      track="all"
      appearance="standard"
      position="invertX"
    >
      <div class="provably-fair__players-container">
        <ng-container *ngFor="let player of players">
          <div class="provably-fair__player-card">
            <div class="user-info__container">
              <app-ui-user-avatar
                [url]="player.ava_link"
                [userId]="player.name.toLowerCase().includes('bot') ? null : player.user_id"
                [username]="{
                  name: player.name,
                  position: 'right'
                }"
              >
              </app-ui-user-avatar>
              <div class="user-seed__container">
                <span class="seed-title">Client seed:</span>
                <span class="seed-descriprion-wrapper">
                  <span class="seed-descriprion"> adfnfjb23e23resd2342qwdf </span>
                </span>
              </div>
              <div class="rounds__container">
                <ng-container *ngFor="let round of filterByPlayer(rounds, player.user_id)">
                  <div
                    class="item__container"
                    [style]="getStyle(round.result.baseItem.color)"
                  >
                    <div class="item-top__container">
                      <div class="item-avatar__container">
                        <img
                          class="item-avatar__img"
                          [src]="round.result.baseItem.icon"
                        />
                      </div>
                      <div class="item-description__container">
                        <span class="item-name"> {{ round.result.baseItem.shortName }} | {{ round.result.baseItem.skin }} </span>
                        <span class="item-price">
                          {{ round.result.price | appCurrency }}
                        </span>
                      </div>
                    </div>
                    <a
                      class="btn btn-gray w-s"
                      langRouterLink
                      [routerLink]="'/provably-fair'"
                      [queryParams]="{ id: round.id }"
                      (click)="close()"
                    >
                      <mat-icon
                        class="icon md"
                        [svgIcon]="'protect-works'"
                      ></mat-icon>
                      {{ 'LOCAL.PROVABLY_FAIR.TABS.CHECK_ROLL' | translate }}:
                      {{ round.id }}
                    </a>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </ng-scrollbar>
  </div>
  <button
    class="btn close-btn"
    (click)="close()"
  >
    <mat-icon
      [title]="'LOCAL.CASES.MODAL.REVISION.CLOSE' | translate"
      class="close-icon"
      [svgIcon]="'close'"
    >
    </mat-icon>
  </button>
</div>
