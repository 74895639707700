import { IconConfig } from '@dev-fast/types';

export const iconsRightSidebar: IconConfig[] = [
  {
    name: 'chat-sidebar',
    path: 'assets/img/mat-icons/right-sidebar/chat-sidebar.svg',
  },
  {
    name: 'inventory-sidebar',
    path: 'assets/img/mat-icons/right-sidebar/inventory-sidebar.svg',
  },
  {
    name: 'market-sidebar',
    path: 'assets/img/mat-icons/right-sidebar/market-sidebar.svg',
  },
  {
    name: 'notification-sidebar',
    path: 'assets/img/mat-icons/right-sidebar/notification-sidebar.svg',
  },
  {
    name: 'setting-sidebar',
    path: 'assets/img/mat-icons/right-sidebar/setting-sidebar.svg',
  },
  {
    name: 'techsupport-sidebar',
    path: 'assets/img/mat-icons/right-sidebar/techsupport-sidebar.svg',
  },
  {
    name: 'to-mobile',
    path: 'assets/img/mat-icons/right-sidebar/to-mobile.svg',
  },
];
