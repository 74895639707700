import { IMarketplaceKitData, IP2pPurchaseItem } from '@dev-fast/types';

export class GetMyBids {
  static readonly type = '[p2p_buying] Get My Bids';
}

export class ToggleSelected {
  static readonly type = '[p2p_buying] Toggle Selected';
  constructor(public payload: IMarketplaceKitData) {}
}
export class DeleteBid {
  static readonly type = '[p2p_buying] Delete Bid';
  constructor(public id: number) {}
}
export class RemoveItem {
  static readonly type = '[p2p_buying] Remove Item';
  constructor(
    public id: number,
    public isActive: boolean = false,
  ) {}
}
export class ConfirmBid {
  static readonly type = '[p2p_buying] Confirm Bid';
  constructor(public id: number) {}
}
export class CreateBid {
  static readonly type = '[p2p_buying] Create Bid';
  constructor(public id: number) {}
}
export class MergePurchasing {
  static readonly type = '[p2p_buying] Merge Purchasing';
  constructor(
    public id: number,
    public data: Partial<IP2pPurchaseItem>,
  ) {}
}
export class SetBidStatus {
  static readonly type = '[p2p_buying] Set Bid Status';
  constructor(
    public id: number,
    public isBidActive: boolean,
  ) {}
}
export class UpdateShowWarnValue {
  static readonly type = '[p2p_buying] Update Show Warn Value';
  constructor(public value: boolean) {}
}
