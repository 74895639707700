<div class="stats-container tw-flex tw-flex-col tw-w-full tw-h-full tw-relative">
  <div class="stats-header tw-absolute">{{ 'P2P_SETTINGS.ITEM_DETAIL.SIH' | translate }}</div>
  <div class="tabs flex fx-align-center gap-1 tw-font-medium pb-2">
    <div
      *ngFor="let period of periods"
      class="tab p-sm-3 pointer tw-rounded-lg"
      (click)="selectPeriod(period)"
      [class.active]="selectedPeriod === period"
    >
      {{ period.amount }}{{ period.title | translate }}
    </div>
  </div>

  <div class="p2p-modal__chart">
    <app-ui-p2p-item-chart
      *ngIf="!isLoading && sihData && !isError && !isSalesCountInvalid(); else mockChart"
      [selectedPeriod]="selectedPeriod"
      [dataset]="sihData['steamlivesell']"
    ></app-ui-p2p-item-chart>

    <ng-template #mockChart>
      <ng-container *ngIf="isError || isSalesCountInvalid(); else loadingSihChart">
        <app-ui-ghost
          class="p2p-modal__chart-error m-a"
          [underLocale]="'ERRORS.MARKET.SIH_STAT_ERROR'"
        ></app-ui-ghost>
      </ng-container>
      <ng-template #loadingSihChart>
        <div class="p2p-modal__mock-chart loading-gradient"></div>
      </ng-template>
    </ng-template>
  </div>

  <div class="p2p-modal__labels flex fx-column">
    <div class="stat-item flex fx-align-center fx-justify-between">
      <div class="title flex fx-align-center gap-2">
        <div class="dot steam"></div>
        {{ 'P2P_SETTINGS.ITEM_DETAIL.STEAM_PRICE' | translate }}
      </div>

      <div
        *ngIf="!isLoading; else mockPrice"
        class="text"
      >
        $ {{ price / 100 | number: '1.1-2' }}
      </div>
      <ng-template #mockPrice>
        <ng-container *ngIf="isError; else loadingPriceTemplate"> $ 0.00 </ng-container>
        <ng-template #loadingPriceTemplate>
          <div class="p2p-modal__mock-price loading-gradient"></div>
        </ng-template>
      </ng-template>
    </div>

    <div class="stat-item flex fx-align-center fx-justify-between">
      <div class="title flex fx-align-center gap-2">
        <div class="dot sells"></div>
        {{ 'P2P_SETTINGS.ITEM_DETAIL.SALES' | translate }}
      </div>

      <div
        *ngIf="!isLoading; else mockSales"
        class="text"
      >
        {{ sales }}
      </div>
      <ng-template #mockSales>
        <ng-container *ngIf="isError; else loadingSalesTemplate"> 0 </ng-container>
        <ng-template #loadingSalesTemplate>
          <div class="p2p-modal__mock-price loading-gradient"></div>
        </ng-template>
      </ng-template>
    </div>
  </div>
</div>
