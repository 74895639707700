import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { filter, map, Observable } from 'rxjs';

import { IS_SERVER_TOKEN } from '@app/shared/utils';

import { RefillBannerService } from './refill-banner.service';

@Component({
  selector: 'app-refill-banner',
  standalone: true,
  imports: [CommonModule, TranslateModule, MatIconModule],
  providers: [RefillBannerService],
  templateUrl: './refill-banner.component.html',
  styleUrls: ['./refill-banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RefillBannerComponent {
  readonly canShow$: Observable<boolean> = this.refillBannerService.canShow().pipe(filter(() => !this.isServer));
  readonly imgName$: Observable<string> = this.refillBannerService.lang$.pipe(map((value) => `50${value === 'ru_RU' ? '_' + value : ''}`));
  constructor(
    private readonly refillBannerService: RefillBannerService,
    @Inject(IS_SERVER_TOKEN) public isServer: boolean,
  ) {}
  onLogin(): void {
    this.refillBannerService.openAuthModal();
  }
  onClose(): void {
    this.refillBannerService.close();
  }
}
