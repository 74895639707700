export enum SteamErrorsEnum {
  STEAM_GUARD = 'steam guard is not enabled',
  STEAM_GUARD_HOLD = 'steam guard hold',
  STEAM_GUARD_IS_IN_HOLD = 'steam guard is in hold',
  STEAM_TRADE_BAN = 'steam trade ban',
  UNBINDING_STEAM = 'User without steam',
  PRIVATE_INVENTORY = 'private inventory',
  // API key err
  INVALID_API_KEY = 'invalid API key',
  FAKE_API_KEY = 'fake API key',
  // trade link err
  INVALID_TRADELINK = 'invalid tradelink',
  NO_TRADE_TOKEN = 'no trade token',
  // Бек вернет {success: false error: *ошибка*}
  NO_TRADE_LINK = 'User without trade link',
  NO_API_KEY = 'User without steam api key',
  //def values
  DEFAULT = 'default',
  DISPATCH = 'dispatch',
  SUCCESS = 'success',
  ERROR = 'error',
}
type SteamErrorsLocales = { [key in SteamErrorsEnum]?: string };
// steam errors to locales
export const SteamErrorsEnumLocales: SteamErrorsLocales = {
  [SteamErrorsEnum.NO_API_KEY]: 'P2P_SETTINGS.ADD_TRADE_LINK_AND_API_KEY',
  [SteamErrorsEnum.INVALID_API_KEY]: 'PROFILE.RENEW_PROFILE.TRADE.INVALID_API',
  [SteamErrorsEnum.STEAM_GUARD]: 'PROFILE.RENEW_PROFILE.TRADE.STEAM_GUARD_IS_NOT_ENABLED',
  [SteamErrorsEnum.STEAM_GUARD_HOLD]: 'PROFILE.RENEW_PROFILE.TRADE.STEAM_GUARD_IS_IN_HOLD',
  [SteamErrorsEnum.STEAM_GUARD_IS_IN_HOLD]: 'PROFILE.RENEW_PROFILE.TRADE.STEAM_GUARD_IS_IN_HOLD',
  [SteamErrorsEnum.INVALID_TRADELINK]: 'PROFILE.RENEW_PROFILE.TRADE.INVALID_LINK',
  [SteamErrorsEnum.PRIVATE_INVENTORY]: 'BALANCE_REFILL.P2P.ALERTS.UNLOCK_STEAM_ACTION',
  [SteamErrorsEnum.STEAM_TRADE_BAN]: 'P2P_WIDGETS.DEPOSITING.YOU_ARE_BANNED',
  [SteamErrorsEnum.UNBINDING_STEAM]: 'P2P_WIDGETS.BIND_STEAM',
  [SteamErrorsEnum.ERROR]: 'P2P_SETTINGS.MUST_BE_VALID',
};
