import { CommonModule } from '@angular/common';
import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  Input,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { NgScrollbar, NgScrollbarModule } from 'ngx-scrollbar';
import { debounceTime, map, Subject, takeUntil } from 'rxjs';

import { IS_SERVER_TOKEN } from '@app/shared/utils';

@Component({
  selector: 'app-ui-arrow-list',
  standalone: true,
  imports: [CommonModule, NgScrollbarModule],
  templateUrl: './arrow-list.component.html',
  styleUrls: ['./arrow-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ArrowListComponent implements AfterViewChecked, AfterViewInit, OnDestroy {
  @Input() offset = 0;
  @Input() gap = 0.75;
  @Input() measure: 'px' | 'rem' = 'rem';

  @ViewChild(NgScrollbar) scrollbarRef!: NgScrollbar;
  @ViewChild('container') container!: ElementRef;

  leftPadding!: number;
  isHideRightButton = false;
  isHideLeftButton = true;
  private scrollPosition = 0;
  private destroy$ = new Subject();
  focusedElement = 0;

  constructor(
    private cdr: ChangeDetectorRef,
    @Inject(IS_SERVER_TOKEN) private isServer: boolean,
  ) {
    if (this.isServer) {
      this.isHideRightButton = true;
    }
  }

  ngAfterViewInit(): void {
    if (!this.isServer) {
      this.scrollbarRef.scrolled
        .pipe(
          takeUntil(this.destroy$),
          debounceTime(350),
          map((e: any) => {
            let position: number | null = null;
            let difference: number | null = null;
            if (e.target.scrollLeft === 0) {
              position = 0;
              this.focusedElement = position;
            } else if (
              Math.abs(e.target.scrollLeft - this.container.nativeElement.scrollWidth + this.container.nativeElement.clientWidth) < 1
            ) {
              let indexItem: number | null = null;
              Array.from(this.container.nativeElement.children).forEach((child: any, index: number) => {
                if (child.offsetLeft - e.target.scrollLeft - this.leftPadding + this.offset > 0) {
                  if (!indexItem) {
                    indexItem = index;
                    this.focusedElement = index;
                  }
                }
              });
              this.scrollbarRef.scrollTo({ right: 0 });
            } else {
              Array.from(this.container.nativeElement.children).forEach((child: any, index: number) => {
                if (e.target.scrollLeft) {
                  if (!difference || Math.abs(child.offsetLeft - e.target.scrollLeft - this.leftPadding + this.offset) < difference) {
                    difference = Math.abs(child.offsetLeft - e.target.scrollLeft - this.leftPadding + this.offset);
                    position = index;
                  }
                }
              });
            }
            if (Math.abs(e.target.scrollLeft - this.container.nativeElement.scrollWidth + this.container.nativeElement.clientWidth) < 1) {
              return e;
            } else {
              if (position || position === 0) {
                this.focusedElement = position;
                let offset = this.computedMeasure(this.measure, this.offset) - this.leftPadding;
                if (offset >= 0) {
                  offset = 0;
                }
                this.scrollbarRef.scrollToElement(this.container.nativeElement.children[position], {
                  left: position === 0 ? 0 - this.leftPadding : offset,
                  duration: 350,
                });
              }
              return e;
            }
          }),
        )
        .subscribe((e: any) => {
          this.scrollPosition = e.target.scrollLeft;
          this.isHideLeftButton = this.scrollPosition === 0;
          this.isHideRightButton =
            e.target.clientWidth > e.target.scrollWidth || this.scrollPosition + e.target.clientWidth + 5 >= e.target.scrollWidth;
          this.cdr.detectChanges();
        });
    }
  }

  ngAfterViewChecked(): void {
    if (!this.isServer) {
      this.leftPadding = parseInt(getComputedStyle(this.container.nativeElement).paddingLeft);
      this.isHideLeftButton = this.scrollPosition === 0;
      this.isHideRightButton =
        this.container.nativeElement.clientWidth > this.container.nativeElement.scrollWidth ||
        this.scrollPosition + this.container.nativeElement.clientWidth >= this.container.nativeElement.scrollWidth;
      this.cdr.detectChanges();
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next('');
    this.destroy$.complete();
  }

  scrollToNext(): void {
    if (!this.isHideRightButton) {
      this.focusedElement += 1;
      this.scrollbarRef.scrollToElement(this.container.nativeElement.children[this.focusedElement], {
        left: this.computedMeasure(this.measure, this.offset) - this.leftPadding,
        duration: 350,
      });
    }
  }

  scrollToPrevious(): void {
    if (!this.isHideLeftButton && this.focusedElement > 0) {
      this.focusedElement -= 1;
      this.scrollbarRef.scrollToElement(this.container.nativeElement.children[this.focusedElement], {
        left: this.focusedElement === 0 ? 0 - this.leftPadding : this.computedMeasure(this.measure, this.offset) - this.leftPadding,
        duration: 350,
      });
    }
  }

  private computedMeasure(measure: 'px' | 'rem', count: number): number {
    return measure === 'rem' ? count * 16 : count;
  }
}
