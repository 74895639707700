import { IPriceFilter } from '../entities';
// Conts
export const MAX_ACCEPTABLE_RECOMMENDED_PRICE = 200000;
export const MAX_ACCEPTABLE_OVERPRICE = 35;
export const moneyToCoinsTransferRate = 1.53;
export const MAX_ACCEPTABLE_AUTODEPOSIT_PRICE = 100000;

// Price Filters
export const DefaultPriceFilters: IPriceFilter[] = [
  { minValue: null, maxValue: null, viewValue: 'ALL' },
  { minValue: 0, maxValue: 899, viewValue: '◎ 0 - 8.99' },
  { minValue: 900, maxValue: 4999, viewValue: '◎ 9 - 49.99' },
  { minValue: 5000, maxValue: 14999, viewValue: '◎ 50 - 149.99' },
  { minValue: 15000, maxValue: 35000, viewValue: '◎ 150 - 350' },
];
// Refill Sums
export const refillSums: number[] = [100, 500, 1000, 2500, 5000, 10000, 50000, 100000];
export const refillPrepaidSums: number[] = [500, 1000, 2500, 5000, 10000, 20000];
