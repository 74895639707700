<ng-container *ngIf="breadcrumbsList$ | async as list">
  <div class="breadcrumbs">
    <a
      class="breadcrumbs__breadcrumb breadcrumb breadcrumb-link"
      langRouterLink
      [routerLink]="breadcrumb.value"
      *ngFor="let breadcrumb of list"
    >
      {{ breadcrumb.name }}
    </a>
  </div>
</ng-container>
