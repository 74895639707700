import { Injectable } from '@angular/core';
import { BehaviorSubject, fromEvent, merge, Observable, of } from 'rxjs';
import { mapTo } from 'rxjs/operators';

export enum execute {
  unloaded = 'unloaded',
  loading = 'loading',
  loaded = 'loaded',
  error = 'error',
}

@Injectable({
  providedIn: 'root',
})
export class RecaptchaService {
  public executed: BehaviorSubject<any>;
  public executed$: Observable<execute>;
  constructor() {
    this.executed = new BehaviorSubject(execute.unloaded);
    this.executed$ = this.executed.asObservable();
    this.subscribeEmitters();
  }

  private subscribeEmitters() {
    merge(fromEvent(window, 'onloadrecaptcha').pipe(mapTo(execute.loaded)), this.loader()).subscribe({
      next: (value) => {
        this.executed.next(value);
      },
    });
  }

  private loader(): Observable<execute> {
    const event = new CustomEvent('onloadrecaptcha');
    window.onRecaptchaLoaded = () => window.dispatchEvent(event);
    const script = document.createElement('script');
    script.innerHTML = '';
    script.async = true;
    script.defer = true;
    script.src = `https://www.google.com/recaptcha/api.js?onload=onRecaptchaLoaded&render=explicit`;
    document.head.appendChild(script);
    return of(execute.loading);
  }
}
