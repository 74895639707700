import { ILocales } from '@dev-fast/types';

export class SetLanguage {
  static readonly type = '[Layout] Set language';
  constructor(
    public locale: ILocales,
    public useNavigation: boolean,
    public saveLang: boolean = true,
  ) {}
}

export class GetLocales {
  static readonly type = '[Layout] Get locales';
}
