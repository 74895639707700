import { Injectable } from '@angular/core';
import { IP2pDepositItem, IUserP2pPermissionInterface, ModalNames, SocialType } from '@dev-fast/types';
import { Actions, ofActionSuccessful, Select } from '@ngxs/store';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { map, Observable } from 'rxjs';

import { AuthService } from '@app/auth';
import { OpenModal } from '@app/core/state/modals';
import { UserState } from '@app/core/state/user-store';
import {
  GetDepositModifier,
  GetDepositModifierSuccess,
  OpenModifierDialogInBB,
  P2pCommonState,
  SetDepositModifier,
} from '@app/modules/p2p';

@Injectable()
export class P2pSellService {
  @Select(UserState.p2pPermissions)
  public readonly p2pPermissions$!: Observable<IUserP2pPermissionInterface>;
  @Select(P2pCommonState.sellerBanEndAt)
  public readonly sellerBanEndAt$!: Observable<number | null>;

  public depositModifier$ = this.actions$.pipe(
    ofActionSuccessful(GetDepositModifierSuccess),
    map((action: GetDepositModifierSuccess) => action.payload)
  );

  constructor(private readonly actions$: Actions, private readonly authService: AuthService) {}

  public bindProvider(provider: SocialType): void {
    this.authService.bindProvider(provider);
  }
  @Dispatch() public getDepositModifier = (): GetDepositModifier => new GetDepositModifier();
  @Dispatch() public setDepositModifier = (payload: { agree: boolean }): SetDepositModifier => new SetDepositModifier(payload);
  @Dispatch() public openModifierDialog = (item: IP2pDepositItem): OpenModifierDialogInBB => new OpenModifierDialogInBB(item);
  @Dispatch() public openSupportModal = (): OpenModal => new OpenModal(ModalNames.TECH_SUPPORT_SELECT_METHOD);
}
