import { SpinDirection } from '../../enums';

export interface IAnimeJSKeyframes {
  value: number | string;
  duration?: number;
  delay?: number;
  easing?: string;
}
export interface IAnimeJSConfig {
  translateY: IAnimeJSKeyframes[];
  translateX: IAnimeJSKeyframes[];
}
export interface IAnimeJSPayload {
  direction: SpinDirection;
  lineLength: number;
  prizeOffset: number;
  cardSize: number;
  blockSize: number;
  duration?: number;
}
