import { CommonModule } from '@angular/common';
import { Component, inject, Input, signal } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ChartConfiguration, ChartOptions } from 'chart.js';
import { NgChartsModule } from 'ng2-charts';

import { P2P_ITEM_CHART_DATASETS, P2P_ITEM_CHART_OPTIONS } from './p2p-item-chart.const';

@Component({
  standalone: true,
  selector: 'app-ui-p2p-item-chart',
  imports: [CommonModule, MatIconModule, TranslateModule, NgChartsModule],
  templateUrl: './p2p-item-chart.component.html',
  styleUrls: ['./p2p-item-chart.component.scss'],
})
export class P2pItemChartComponent {
  //TODO any[] Заменить на нормальный интерфейс
  #translateService = inject(TranslateService);

  @Input() set dataset(chartDataset: any[]) {
    if (chartDataset) {
      this.#lineChartDataset = chartDataset;
      this.#updateChart(chartDataset);
    }
  }
  @Input() set selectedPeriod(period: { value: number; amount: number; title: string }) {
    if (period) {
      this.#updateChart(this.#lineChartDataset, period.value);
    }
  }

  lineChartData = signal<ChartConfiguration<'line'>['data']>({
    labels: [],
    datasets: [{ data: [] }, { data: [] }],
  });

  #lineChartDataset: any[] = [];
  readonly lineChartOptions: ChartOptions<'line'> & { plugins: any } = P2P_ITEM_CHART_OPTIONS;

  /**
   * Обновдение графика с текущими данными chartDataset, period = 7|14|30 дней
   */
  #updateChart(chartDataset: any[], period = 7): void {
    const labels: number[] = [];
    const sellsData: number[] = [];
    const pricesData: number[] = [];
    const datasetLength = chartDataset.length;
    const startIndexFromDataset = datasetLength - period > -1 ? datasetLength - period : 0; // проверяем не вывалились ли за границы
    new Array(...chartDataset)
      .slice(startIndexFromDataset) // фильтруем только за выбраный период
      .forEach((day: any) => {
        labels.push(day[0]); // Делим данные на сегменты (7 сегментов для 7 дней)
        sellsData.push(day[1][8]); // Наполняем сегменты данными о продажах
        pricesData.push(day[1][2]); // наполняем сегменты данными о ценах
      });
    this.lineChartData.set({
      labels: this.#formatLabels(labels),
      datasets: [
        {
          ...P2P_ITEM_CHART_DATASETS[0],
          data: sellsData,
          pointHitRadius: 0,
        },
        {
          ...P2P_ITEM_CHART_DATASETS[1],
          data: pricesData,
        },
      ],
    });
  }

  /**
   * Переводим заголовки из формата '20230909' в '9 September'
   */
  #formatLabels(dates: number[]): string[] {
    return dates.map((date: number) => {
      const stringDate = String(date);
      const year = Number(stringDate.slice(0, 3));
      const month = Number(stringDate.slice(4, 6));
      const day = Number(stringDate.slice(6, 8));
      return `${day}  ${this.#translateService.instant('DATE.MONTHS.' + new Date(year, month - 1, day).getMonth())}`;
    });
  }
}
