import {
  IUserDetailed,
  IUserP2pPermissionInterface,
  IUserSettingsDetailed,
  IUserWallet,
  Permissions,
  RequestState,
  UserCountry,
} from '@dev-fast/types';

export const USER_STATE_UNDEFINED = undefined;
export interface UserStateModel {
  /** IUserDetailed - юзер авторизован, null - юзер неавторизован, undefined - значение до ответа сервера о статусе юзера */
  user: IUserDetailed | null | undefined;
  wallets: IUserWallet[];
  // country: UserCountry | null;
  country: UserCountry | null;
  permissions: Permissions[];
  p2pPermissions: IUserP2pPermissionInterface | null;
  additionalSettings: IUserSettingsDetailed | null;
  emailConfirmRequestState: RequestState;
  updateEmailRequestState: RequestState;
}

export const USER_INITIAL_STATE: UserStateModel = {
  user: null,
  wallets: [],
  country: null,
  permissions: [Permissions.CAN_USE],
  p2pPermissions: null,
  additionalSettings: null,
  emailConfirmRequestState: RequestState.Unset,
  updateEmailRequestState: RequestState.Unset,
};
