import { IP2pDepositingItem, IP2pDepositItem, OrderStatusEnum } from '@dev-fast/types';

import { PriorityList } from '@app/shared/utils';

const P2P_COMPLETED_MOCK = [
  {
    order: {
      buyerSteamName: 'very very very very long buyer steam name',
      buyerId: 42069,
      buyerMarket: 'market?',
      buyerSteamAvatar: '',
      buyerSteamCreatedAt: '2022-10-30T19:38:00.838Z',
      buyerSteamId: 69420,
      buyerSteamLevel: 42,
      buyerTradeLink: 'dsfgsdfgsdhdtgh',
      fastOfferError: null,
      fastOfferReason: null,
      id: 880005553535,
      steamOfferId: null,
      steamItemPosition: [3, 3, 2],
    },
    isCrossFast: false,
    status: OrderStatusEnum.WAIT_FOR_TRADE,
    statusAt: '2022-11-30T19:38:00.838Z',
    nextStatusAt: '2024-10-30T19:38:00.838Z',
    isUserSeller: true,
    isLastBidByUser: false,
    price: 8100,
    express: true,
    phase: null,
    float: undefined,
    stickers: [],
    recommendedPrice: 110,
    sellerPrice: 150,
    steamInspectLink: '',
    id: 18,
    baseItem: {
      name: 'very very very very long Item name',
      icon: 'https://steamcommunity-a.akamaihd.net/economy/image/-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KU0Zwwo4NUX4oFJZEHLbXH5ApeO4YmlhxYQknCRvCo04DEVlxkKgposbaqKAxf0Ob3djFN79eJh46KlPz1J6_QlWBu5Mx2gv3--Y3nj1H6-kdpN2H1J4GUdlBsYQ6E_Ae5x-i605S06p7PynswunV0sSqJmhW2iQYMMLKO7TPsAg',
      color: '',
      weapon: 'weapon name',
      type: 'type',
      rarity: 'rarity',
      shortName: 'Negev',
      skin: 'dev-texture',
      exterior: 'Factory New',
      statTrak: true,
      steamPrice: 100,
    },
  },
];

export interface P2pDepositStateModel {
  depositingItems: IP2pDepositingItem[];
  overprice: number;
  selected: IP2pDepositItem[];
}

export const P2P_DEPOSIT_INITIAL_STATE: P2pDepositStateModel = {
  depositingItems: [],
  overprice: -5,
  selected: [],
};

export const P2P_DEPOSIT_MOCK_STATE: P2pDepositStateModel = {
  depositingItems: P2P_COMPLETED_MOCK,
  overprice: -5,
  selected: [],
};

export const priorityList: PriorityList = {
  high: [OrderStatusEnum.WAIT_FOR_TRADE, OrderStatusEnum.WAIT_FOR_BUYER_ACCEPT, OrderStatusEnum.WAIT_FOR_CONFIRM],
  medium: [OrderStatusEnum.FIRST_BID, OrderStatusEnum.SECOND_BID, OrderStatusEnum.THIRD_PLUS_BID, OrderStatusEnum.NEW],
  low: [OrderStatusEnum.PAUSED],
};

export const MIN_DEFAULT_OVERPRICE = -5;
