import {
  GameIds,
  IFilterMethod,
  IHistoryInventoryItem,
  IHistoryParams,
  IInventoryItem,
  IInventoryRequestParams,
  InventorySortingTypes,
  IShopMeta,
  ISkinItem,
  IWithdrawalItems,
} from '@dev-fast/types';

const INVENTORY_SORTING_TYPES: IFilterMethod<InventorySortingTypes>[] = [
  {
    value: InventorySortingTypes.MAX_PRICE,
    viewValue: 'MAX_PRICE',
    dropdownItem: {
      title: 'BALANCE_WITHDRAWAL.FILTERS.SORT_BY.OPTION_DESC_PRICE',
      name: '',
      icon: {
        type: 'icon',
        src: 'sort-sm-desc--design',
      },
    },
  },
  {
    value: InventorySortingTypes.MIN_PRICE,
    viewValue: 'MIN_PRICE',
    dropdownItem: {
      title: 'BALANCE_WITHDRAWAL.FILTERS.SORT_BY.OPTION_ASC_PRICE',
      name: '',
      icon: {
        type: 'icon',
        src: 'sort-sm-asc--design',
      },
    },
  },
];

export interface InventoryStateModel {
  // FIXME убрать отсюда
  appId: GameIds;
  items: IInventoryItem[] | null;
  currentFiltersAllSold: boolean;
  isSelectAll: boolean;
  itemsIds: number[];
  selectedItems: IInventoryItem[];
  inventoryCount: number;
  inventorySum: number;
  params: any;
  bufferParams: IInventoryRequestParams | null;
  shopItems: ISkinItem[];
  contracts: ISkinItem[];
  shopParams: IInventoryRequestParams;
  shopMeta: IShopMeta;
  gameInProgress: boolean;
  historyItems: IHistoryInventoryItem[];
  historyItemsLoading: boolean;
  historyParams: IHistoryParams | null;
  historyCount: number | null;
  preSearchItemsLength: number | null;
  totalSum: number | null;
  preliminarySum: number | null; // предварительная сумма хранится до подтверждения фильтрации
  bufferItems: IInventoryItem[] | null;
  isItemsLoading: boolean;
  sortingMethods: IFilterMethod<InventorySortingTypes>[];
  withdrawalList: IWithdrawalItems | null;
  actionsDisabled: boolean;
}

export const INVENTORY_INITIAL_STATE: InventoryStateModel = {
  appId: GameIds.CSGO,
  items: null,
  isSelectAll: false,
  itemsIds: [],
  selectedItems: [],
  inventoryCount: 0,
  inventorySum: 0,
  params: {
    sortBy: InventorySortingTypes.MAX_PRICE,
    page: 1,
    pageSize: 50,
    append: false,
  },
  bufferParams: null,
  shopItems: [],
  contracts: [],
  shopParams: {
    sortBy: InventorySortingTypes.MAX_PRICE,
    page: 1,
    pageSize: 50,
    append: false,
  },
  shopMeta: {
    amount: 0,
    limit: 0,
    offset: 0,
  },
  gameInProgress: false,
  historyItems: [],
  historyItemsLoading: false,
  historyParams: {
    page: 1,
    size: 100,
  },
  historyCount: null,
  bufferItems: [],
  preSearchItemsLength: null,
  totalSum: null,
  preliminarySum: null,
  isItemsLoading: false,
  sortingMethods: INVENTORY_SORTING_TYPES,
  withdrawalList: null,
  actionsDisabled: false,
  currentFiltersAllSold: false,
};
