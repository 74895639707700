import { Injectable } from '@angular/core';
import {
  IClassicHistory,
  IClassicStateDto,
  IGameBet,
  IGameBetResponseDto,
  TClassicStateGameDto,
  TClassicStateParticipantDto,
  TClassicStateWinnerDto,
  TradeOriginalItem,
} from '@dev-fast/types';
import { Socket } from 'ngx-socket-io';
import { Observable } from 'rxjs';

import { Environments, EnvironmentService } from '@app/core/environment-service';

import { ApiService } from '../../api.service';

@Injectable({ providedIn: 'root' })
export class ClassicBackendService {
  private readonly environment: Environments = this.environmentService.environments;

  constructor(
    private readonly apiService: ApiService,
    private readonly environmentService: EnvironmentService,
    private readonly socket: Socket,
  ) {}

  // API
  getState(): Observable<IClassicStateDto> {
    return this.apiService.get<IClassicStateDto>(`${this.environment.GATEWAY_URL}/api/classic/state`, { withCredentials: true });
  }

  getHistory(my?: boolean): Observable<IClassicHistory[]> {
    return this.apiService.get<IClassicHistory[]>(`${this.environment.GATEWAY_URL}/api/classic/history${my ? '/my' : ''}`, {
      withCredentials: true,
    });
  }

  addBet(bet: IGameBet): Observable<IGameBetResponseDto> {
    return this.apiService.post<IGameBetResponseDto>(`${this.environment.GATEWAY_URL}/api/classic/bet`, bet, {
      withCredentials: true,
    });
  }

  // SOCKETS
  /**
   * Вызывается единожды в момент окончания текущего раунда
   * @returns Observable с данными для сброса состояния текущего раунда, а также для начала нового
   */
  gameSocket(): Observable<TClassicStateGameDto> {
    return this.socket.fromEvent<TClassicStateGameDto>('game.classic.game');
  }

  inventorySocket(): Observable<{ items: TradeOriginalItem[] }> {
    return this.socket.fromEvent<{ items: TradeOriginalItem[] }>('game.classic.inventory');
  }

  /**
   * Вызывается при добавлении к текущему раунду нового игрока
   * @returns Observable с обновленными данными состояния текущего раунда, также содержащие данные присоединившегося игрока
   */
  participantsSocket(): Observable<TClassicStateParticipantDto> {
    return this.socket.fromEvent<TClassicStateParticipantDto>('game.classic.participants');
  }

  /**
   * Вызывается единожды в начале розыгрыша текущего раунда
   * @returns Observable с данными о победителе текущего раунда, а также данными для проведения розыгрыша
   */
  winnerSocket(): Observable<TClassicStateWinnerDto> {
    return this.socket.fromEvent<TClassicStateWinnerDto>('game.classic.winner');
  }
}
