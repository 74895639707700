import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IUserDetailed } from '@dev-fast/types';

@Component({
  selector: 'app-ui-balance-widget-v2',
  templateUrl: './balance-widget-v2.component.html',
  styleUrls: ['./balance-widget-v2.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BalanceWidgetV2Component {
  @Input() public user!: IUserDetailed;
  @Input() public view: 'mobile' | 'desktop' = 'mobile';

  // constructor() {  }
}
