<form
  *ngIf="storeForm"
  [formGroup]="storeForm"
  class="inventory"
>
  <div class="exchange-panel">
    <div class="exchange-panel__control-panel">
      <div
        class="exchange-panel__resume"
        *ngIf="tradeBalance"
      >
        <div class="exchange-panel__header">
          {{ 'TRADE_PANEL.CHOOSE_SKINS' | translate }}
        </div>
        <div class="exchange-panel__balance">
          <div class="exchange-panel__balance-column">
            <div class="exchange-panel__balance-title">
              {{ 'TRADE_PANEL.YOU_WILL_SPEND' | translate }}
            </div>
            <div class="exchange-panel__balance-value">
              {{ tradeBalance.selectedItemsSum | appCurrency }}
            </div>
          </div>
          <div class="exchange-panel__balance-divider"></div>
          <div class="exchange-panel__balance-column">
            <div class="exchange-panel__balance-title">
              {{ 'TRADE_PANEL.YOU_WILL_GET' | translate }}
            </div>
            <div class="exchange-panel__balance-value">
              {{ tradeBalance.selectedShopItemsSum | appCurrency }}
            </div>
          </div>
        </div>
        <div class="exchange-panel__sum-balance">
          <div class="exchange-panel__sum-balance-info">
            <span class="exchange-panel__balance-title">
              <!--          FIXME Поменять ключ с текстом на Balance-->
              {{ 'TRADE_PANEL.SUBTOTAL' | translate }}:
            </span>
            <span class="exchange-panel__balance-value"> {{ tradeBalance.tradeBalance | appCurrency }} </span>
          </div>
          <a
            class="btn btn-primary btn-sm exchange-panel__balance-button"
            (click)="onCreateTrade()"
          >
            {{ 'TRADE_PANEL.CONFIRM_ACTION' | translate | titlecase }}
          </a>
        </div>
      </div>
      <div class="filters exchange-panel__filters">
        <label class="filters__link-wrapper">
          <span class="filters__link">{{ 'CONTRACT_PANEL.PRICE' | translate }}</span>
          <input
            type="checkbox"
            formControlName="sortBy"
          />
          <mat-icon
            class="filters__link_icon"
            svgIcon="arrow-up"
          ></mat-icon>
        </label>
        <div class="filters__input-wrapper filters__input-wrapper_name">
          <input
            type="text"
            class="filters__input"
            [placeholder]="'TRADE_PANEL.SEARCH_PLACEHOLDER' | translate"
            formControlName="query"
          />
        </div>
        <div class="filters__price-wrapper">
          <div class="filters__input-wrapper">
            <input
              type="number"
              class="filters__input"
              [placeholder]="'TRADE_PANEL.MIN_PRICE' | translate"
              formControlName="minPrice"
            />
          </div>
          <div class="filters__input-wrapper">
            <input
              type="number"
              class="filters__input"
              [placeholder]="'TRADE_PANEL.MAX_PRICE' | translate"
              formControlName="maxPrice"
            />
          </div>
        </div>
        <div
          class="filters__refresh-button"
          (click)="onRefresh()"
        >
          <mat-icon
            class="filters__refresh-button_icon"
            svgIcon="update-arrows"
          ></mat-icon>
        </div>
      </div>
    </div>
    <div class="exchange-panel__items-wrapper">
      <ng-container *ngIf="loading; then loadingTemplate; else loadedTemplate"></ng-container>
      <ng-template #loadingTemplate>
        <app-ui-cube-grid-loader
          class="inventory-loader-wrapper"
          [size]="'5rem'"
          [color]="'var(--color-typography-light)'"
        ></app-ui-cube-grid-loader>
      </ng-template>
      <ng-template #loadedTemplate>
        <ng-container *ngIf="inventoryList.length; else emptyTemplate">
          <div class="exchange-panel__items-list">
            <ng-container *ngFor="let item of inventoryList">
              <app-ui-skin-item
                [name]="item.name"
                [icon]="item.icon"
                [color]="item.color"
                [price]="item.price"
                [backgroundColor]="'var(--color-theme-500)'"
                [id]="item.id"
                [ngClass]="{ selected: isSelected(item.id) }"
                (skinClick)="clickOnSkinEmit.emit($event)"
              >
              </app-ui-skin-item>
            </ng-container>
          </div>
        </ng-container>
        <ng-template #emptyTemplate>
          <div class="inventory-empty-wrapper">
            <h5 class="inventory-empty-header">{{ 'No items found' | translate }}</h5>
            <p class="inventory-empty-description">{{ 'Change your filter preferences' | translate }}</p>
          </div>
        </ng-template>
      </ng-template>
    </div>
    <div
      class="exchange-panel__pagination"
      *ngIf="meta"
    >
      <button
        (click)="onNextPage()"
        [disabled]="disabledNextPage"
        class="btn btn-sm btn-outline-secondary"
      >
        {{ 'CONTRACT_PANEL.NEXT' | translate }}
      </button>
      <app-ui-pagination
        formControlName="page"
        [max]="totalPage"
        [min]="1"
      >
      </app-ui-pagination>
    </div>
    <!-- <div
      class="btn__close"
      (click)="closePanelEmit.emit()"
    >
      <mat-icon
        class="btn__close_icon"
        svgIcon="close"
      ></mat-icon>
    </div> -->
  </div>
</form>

<ng-container *ngIf="validatePremium">
  <div class="account-requirement-container">
    <div class="account-requirement-wrapper">
      <div class="account-requirement-icon">
        <mat-icon svgIcon="account-minimal"></mat-icon>
      </div>
      <div class="account-requirement-description">
        {{ 'BET_PANEL.PREVENT_DESCRIPTION' | translate : { amount: 250 | appCurrency } }}
      </div>
      <a
        langRouterLink
        [routerLink]="['refill']"
        class="btn btn-primary"
        >{{ 'Refill' | translate }}</a
      >
    </div>
  </div>
</ng-container>
