<div
  class="event-menu-item__container"
  *ngIf="event"
>
  <div class="event-menu-item__container__icon">
    <mat-icon
      *ngIf="event.key === 'events'"
      class="event-menu-item__icon"
      [svgIcon]="'logo-v2-' + event.key"
    ></mat-icon>

    <div
      *ngIf="event.key === 'promo'"
      class="event-menu-item__container__icon-promo"
    >
      <img
        class="event-menu-item__container__icon-promo__code"
        src="/assets/img/left-sidebar/promocode.svg"
      /><img
        class="event-menu-item__container__icon-promo__bg"
        src="/assets/img/left-sidebar/promo-bg.svg"
      />
    </div>
    <img
      *ngIf="event.key === 'quest'"
      class="event-menu-item__icon"
      src="/assets/img/left-sidebar/quest.svg"
    />
  </div>

  <div class="event-menu-item__container__title">
    <div class="event-menu-item__container__title-container">
      {{ event.title }}
    </div>
  </div>
</div>
