import { ComponentType } from '@angular/cdk/overlay';
import { MatDialogConfig } from '@angular/material/dialog';

import { ModalNames } from '../enums';

export interface ModalConfig extends MatDialogConfig {
  mobileViewAllowed?: boolean;
}
export interface ModalModel<T = ModalNames> {
  name: T;
  component: ComponentType<any>;
  config: ModalConfig;
}
