import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, NgModule, Output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { ICaseRevisionItem } from '@dev-fast/types';
import { TranslateModule } from '@ngx-translate/core';
import { NgScrollbarModule } from 'ngx-scrollbar';

import { CurrencyComponent } from '@app/core/currency';
import { ClickOutsideModule, SkinItemImageModule } from '@app/shared/directives';
import { AppCurrencyModule, AppPercentModule, HashPipe } from '@app/shared/pipes';

import { IncreaseModule } from '../../increase/increase.component';
import { PossibleItemsListMockComponent } from './mock/possible-items-list.component.mock';
import { PossibleCardMockComponent } from './possible-card/mock/possible-card.component.mock';
import { PossibleCardComponent } from './possible-card/possible-card.component';

@Component({
  selector: 'app-ui-possible-items-list',
  templateUrl: './possible-items-list.component.html',
  styleUrls: ['./possible-items-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PossibleItemsListComponent {
  @Input() caseId!: number | undefined;
  @Input() rollId: number | undefined;
  @Input() set caseItems(items: ICaseRevisionItem[] | undefined) {
    if (!items) {
      return;
    }
    this.sortedItems = this.sortItems(this.previewCardsConstructor(items), '-price');
  }
  @Input() tracksAmount = 1;
  @Input() isHovered = true;
  @Input() showChance = true;
  @Input() isCompact = false;
  @Input() isRevisionShow = true;
  @Input() isNewPalette = false;
  @Input() cardClass = '';
  @Input() clientSeed: string | undefined;
  @Output() onopenModal: EventEmitter<number> = new EventEmitter();
  @Output() clickOnCard: EventEmitter<ICaseRevisionItem> = new EventEmitter();
  @Output() onchangeSeed: EventEmitter<string> = new EventEmitter();
  sortedItems: ICaseRevisionItem[][] = [];
  isClientSeedEditing = false;

  totalPossibleWin(): number {
    return this.sortedItems[0][0].inventoryItem.price * this.tracksAmount;
  }
  previewCardsConstructor(items: ICaseRevisionItem[]): Record<string, ICaseRevisionItem[]> {
    const possibleItems: Record<string, ICaseRevisionItem[]> = {};
    items.forEach((item: ICaseRevisionItem) => {
      const shortName = item.inventoryItem.baseItem.shortName;
      const skinName = item.inventoryItem.baseItem.skin;
      const fullName = shortName + ' | ' + skinName;
      if (!Object.keys(possibleItems).includes(fullName)) {
        possibleItems[fullName] = [item];
      } else {
        possibleItems[fullName].push(item);
      }
    });
    return possibleItems;
  }
  sortItems(possibleItems: Record<string, ICaseRevisionItem[]>, sortDirection: string): ICaseRevisionItem[][] {
    return Object.keys(possibleItems)
      .map((key) => possibleItems[key])
      .sort((a, b) => {
        if (sortDirection === '-price') {
          return b[0].inventoryItem.price - a[0].inventoryItem.price;
        }
        return a[0].inventoryItem.price - b[0].inventoryItem.price;
      });
  }
  openCaseRevisionModal(): void {
    this.onopenModal.emit(this.caseId);
  }
  navigateToCaseItem(itemRevisions: ICaseRevisionItem[]): void {
    this.clickOnCard.emit(itemRevisions[0]);
  }
  toggleClientSeedEditing(): void {
    this.isClientSeedEditing = !this.isClientSeedEditing;
  }
  changeClientSeed(event: Event): void {
    const value = (event.target as HTMLInputElement).value;
    if (value === this.clientSeed) {
      return;
    }
    this.clientSeed = value;
    this.toggleClientSeedEditing();
    this.onchangeSeed.emit(value.trim());
  }
}

@NgModule({
  imports: [
    CommonModule,
    MatIconModule,
    AppCurrencyModule,
    AppPercentModule,
    CurrencyComponent,
    TranslateModule,
    IncreaseModule,
    RouterModule,
    NgScrollbarModule,
    ClickOutsideModule,
    SkinItemImageModule,
    HashPipe,
  ],
  declarations: [PossibleItemsListComponent, PossibleCardComponent, PossibleItemsListMockComponent, PossibleCardMockComponent],
  exports: [PossibleItemsListComponent, PossibleItemsListMockComponent, PossibleCardComponent, PossibleCardMockComponent],
})
export class PossibleItemsListComponentModule {}
