<ng-container
  *ngIf="{
    user: user$ | async,
    locales: locales$ | async,
    currentLocale: currentLocale$ | async,
    inventoryShortInfo: inventoryShortInfo$ | async,
    canLevelUp: canLevelUp$ | async,
    menu: menu$ | async,
    balance: userBalance$ | myAsync
  } as data"
>
  <div
    [ngClass]="{ 'header-wrapper_open-main-menu': isMainMenuOpen(data.menu) }"
    class="header-wrapper tw-flex tw-justify-between tw-items-center tw-gap-2 tw-w-full tw-p-3"
  >
    <app-ui-brand [link]="'/'"></app-ui-brand>

    <div
      *ngIf="data.user"
      class="header__actions"
    >
      <ui-balance-block
        [inventoryCount]="data.inventoryShortInfo?.inventoryCount"
        [userBalance]="data.balance"
        (onopenTransactions)="onOpenTransactions($event)"
        (openInventory)="onOpenInventory()"
      >
      </ui-balance-block>
      <div class="delimiter"></div>
      <ui-promo-block (openGleamModal)="onOpenGleamModal()"> </ui-promo-block>
    </div>

    <div class="header-wrapper__menu">
      <ng-container *ngIf="data.user; else notAuthTemplate">
        <app-notifications-v2></app-notifications-v2>
      </ng-container>
      <ng-template #notAuthTemplate>
        <button
          class="login-btn btn btn-basic--design"
          (click)="onLogin()"
        >
          {{ 'LOGIN' | translate }}
        </button>
      </ng-template>
      <app-header-action-menu
        [user]="data.user"
        [locales]="data.locales"
        [locale]="data.currentLocale"
        [canLevelUp]="data.canLevelUp"
        [userBalance]="data.balance"
      >
      </app-header-action-menu>
    </div>
  </div>
</ng-container>
