import { Component } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-steam-double-icon',
  standalone: true,
  imports: [MatIconModule],
  templateUrl: './steam-double-icon.component.html',
  styleUrls: ['./steam-double-icon.component.scss'],
})
export class SteamDoubleIconComponent {}
