import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import { IP2pDepositItem } from '@dev-fast/types';

import { P2pDepositService } from '@app/modules/p2p';

import { P2pSellService } from '../p2p-sell.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-p2p-sell-setup-item',
  styleUrls: ['./p2p-sell-setup-item.component.scss'],
  templateUrl: './p2p-sell-setup-item.component.html',
})
export class P2pSellSetupItemComponent {
  @Input() item: IP2pDepositItem | undefined;

  readonly #moduleService: P2pSellService = inject(P2pSellService);
  readonly #p2pDepositService: P2pDepositService = inject(P2pDepositService);

  openModifierDialog(): void {
    if (this.item) {
      this.#moduleService.openModifierDialog(this.item);
    }
  }

  removeAt(): void {
    if (this.item) {
      this.#p2pDepositService.removeDepositItem(this.item);
    }
  }
}
