import { HttpErrorResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { CasesBackendService, P2pApiNewService } from '@dev-fast/backend-services';
import {
  DefaulSortingTypes,
  HistoryItem,
  HistoryItemStatus,
  HistorySourceType,
  ICaseItemDtoV2,
  ICasesFilter,
  IFilterFormContent,
  IFilterMethod,
  IMarketplaceKitData,
  IMarketplaceMetaData,
  IP2pParticipateItemUpdated,
  ISihDataset,
  ISteamStore,
  ISteamStoreInventory,
  ITransactionDto,
  MarketPanel,
  MarketSortingTypes,
  NotificationType,
  PageInfo,
  Pagination,
  PROJECT,
  SihCurrencyType,
  TransactionNamespaces,
} from '@dev-fast/types';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { patch, updateItem } from '@ngxs/store/operators';
import { catchError, combineLatest, finalize, Observable, of, switchMap, tap } from 'rxjs';

import { EnvironmentService } from '@app/core/environment-service';
import { NotificationsService } from '@app/core/notification-service';

import {
  ApplyFilters,
  ChangeActiveTab,
  ChangeFilters,
  ChangePage,
  ChangeSteamInventoryFilters,
  ClearCustomCasesState,
  ClearItems,
  ClearMeta,
  GetAllCustomCases,
  GetCustomCases,
  GetCustomCasesMetaData,
  GetItemSihStats,
  GetMarketHistory,
  GetMarketItems,
  MarketItemsLoad,
  MarketItemsLoaded,
  ResetSihStat,
  SetCustomCases,
  SetCustomCaseState,
  SortSteamInventoryByMethod,
} from './market.actions';
import { P2P_MARKET_INITIAL_STATE, P2pMarketStateModel } from './market-state.model';

@State<P2pMarketStateModel>({
  name: 'p2p_market',
  defaults: P2P_MARKET_INITIAL_STATE,
})
@Injectable()
export class P2pMarketState {
  readonly #environmentService = inject(EnvironmentService);

  @Selector()
  static items({ actualItems }: P2pMarketStateModel): (IMarketplaceKitData | ICaseItemDtoV2)[] {
    return actualItems;
  }

  @Selector()
  static tmpItems({ tmpItems }: P2pMarketStateModel): (IMarketplaceKitData | ICaseItemDtoV2)[] | ISteamStoreInventory[] {
    return tmpItems;
  }

  @Selector()
  static pagination({ meta }: P2pMarketStateModel): PageInfo | null {
    return meta
      ? {
          numberPage: meta.offset > 0 ? meta.offset / meta.limit + 1 : 1,
          totalSize: Math.ceil(meta.amount / meta.limit),
        }
      : null;
  }

  @Selector()
  static preSearchItemsLength({ tmpMeta }: P2pMarketStateModel): number | null {
    return tmpMeta ? tmpMeta.amount : null;
  }
  @Selector()
  static searchItemsLength({ meta }: P2pMarketStateModel): number | null {
    return meta ? meta.amount : null;
  }
  @Selector()
  static filters({ filters }: P2pMarketStateModel): IFilterFormContent<MarketSortingTypes> {
    return filters;
  }

  @Selector()
  static tmpFilters({ tmpFilters }: P2pMarketStateModel): IFilterFormContent<MarketSortingTypes> {
    return tmpFilters;
  }

  @Selector()
  static marketHistory({ marketHistory }: P2pMarketStateModel): HistoryItem[] {
    return marketHistory;
  }

  @Selector()
  static sihDataset({ sihDataset }: P2pMarketStateModel): ISihDataset | null {
    return sihDataset;
  }

  @Selector()
  static activeTab({ activeTab }: P2pMarketStateModel): MarketPanel {
    return activeTab;
  }
  @Selector()
  static isLoading({ isLoading }: P2pMarketStateModel): boolean {
    return isLoading;
  }

  @Selector()
  static customCaseList({ customCaseList }: P2pMarketStateModel): ICaseItemDtoV2[] {
    return customCaseList;
  }

  @Selector()
  static sortingMethods({ sortingMethods, activeTab }: P2pMarketStateModel): IFilterMethod<MarketSortingTypes>[] {
    return activeTab === MarketPanel.Purchase ? sortingMethods : sortingMethods.slice(0, 2);
  }

  @Selector()
  static loadedCustomCases({ loadedCustomCases }: P2pMarketStateModel): number[] | boolean {
    return loadedCustomCases;
  }

  constructor(
    private readonly _api: P2pApiNewService,
    private readonly store: Store,
    private casesBackendService: CasesBackendService,
    private readonly notificationsService: NotificationsService,
  ) {}

  ngxsOnInit({ setState }: StateContext<P2pMarketStateModel>): void {
    const updateItemStatus = (socketPayload: IP2pParticipateItemUpdated): void => {
      setState(
        patch({
          actualItems: updateItem<IMarketplaceKitData | ICaseItemDtoV2>(
            (item) => item.id === socketPayload.id,
            patch({
              status: socketPayload.status,
            }),
          ),
        }),
      );
    };
    this._api.participantItemUpdatedEvent((payload: IP2pParticipateItemUpdated) => {
      updateItemStatus(payload);
    });
  }

  @Action(ChangeActiveTab)
  changeActiveTab({ patchState }: StateContext<P2pMarketStateModel>, { tab }: ChangeActiveTab): void {
    patchState({ activeTab: tab });
  }

  /**
   * Проверка на то, можно ли добавить в текущий список кастомный кейс
   *
   * @param casePrice цена кастомного кейса
   * @param maxKitPrice максимальная цена текущего списка китов
   * @param minKitPrice минимальаня цена текущего списка китов
   * @param kitPrice цена текущего предмета в китах
   * @param isIncreaseOrder по возрастанию ли сортировка
   */
  canAddCaseItemNextToKit(
    casePrice: number,
    maxKitPrice: number,
    minKitPrice: number,
    kitPrice: number,
    isIncreaseOrder: boolean,
  ): boolean {
    return (
      casePrice <= maxKitPrice &&
      casePrice >= minKitPrice &&
      ((isIncreaseOrder && casePrice <= kitPrice) || (!isIncreaseOrder && casePrice >= kitPrice))
    );
  }

  /**
   * Удаление из списка кейсов те, что не должны попасть в фильтрацию
   *
   * @param customCases список кейсов
   * @param filterMin минимальная цена фильтров
   * @param filterMax максимальная цена фильтров
   * @param filterQuery поиск по названию
   * @param isIncreaseOrder по возрастанию ли сортировка
   * @param alreadyAddedCases список кейсов, что уже был загружен
   */
  sortFilterCaseItems(
    customCases: ICaseItemDtoV2[],
    filterMin: number,
    filterMax: number,
    filterQuery: string,
    isIncreaseOrder: boolean,
    alreadyAddedCases: boolean | number[],
  ): ICaseItemDtoV2[] {
    return customCases
      .filter(
        (caseItem) =>
          // Удаляем кейсы, что не в диапазоне фильтра
          caseItem.lastRevision.price >= filterMin &&
          caseItem.lastRevision.price <= filterMax &&
          (!filterQuery || caseItem.name.toLowerCase().includes(filterQuery.toLowerCase())) &&
          // Удаляем те кейсы что уже загружены
          typeof alreadyAddedCases !== 'boolean' &&
          !alreadyAddedCases.includes(caseItem.id),
      )
      .sort((a, b) => (isIncreaseOrder ? a.lastRevision.price - b.lastRevision.price : b.lastRevision.price - a.lastRevision.price));
  }
  // убирает кастомные кейсы если есть такие же среди китов
  #filterCustomCases(kits: IMarketplaceKitData[], customCases: ICaseItemDtoV2[]): ICaseItemDtoV2[] {
    kits.forEach((kit) => {
      if (kit.items[0].caseId) {
        const customCaseIndex = customCases.findIndex((caseItem) => caseItem.id === kit.items[0].caseId);
        if (customCaseIndex > -1) {
          [...customCases].splice(customCaseIndex, 1);
        }
      }
    });
    return customCases;
  }
  /**
   * Добавление кастомных кейсов в список предметов маркета
   */
  addCustomCasesToKits(
    kits: IMarketplaceKitData[],
    customCases: ICaseItemDtoV2[],
    params = {
      isLastPage: false,
      isFirstPage: false,
      sort: '-price',
      filterMin: 0,
      filterMax: Infinity,
      filterQuery: '',
    },
  ): (IMarketplaceKitData | ICaseItemDtoV2)[] {
    const overpriceSort = ['overprice', '-overprice'].includes(params.sort);
    const isIncreaseOrder = params.sort === 'price';

    // минимальная и максимальная цена пришедших предметов на маркете
    const minKitPrice = Math.min(...kits.map((kit) => kit.price));
    const maxKitPrice = Math.max(...kits.map((kit) => kit.price));

    // Кейсы что уже загружены. true если все, number[] если не все
    const alreadyAddedCases = this.store.selectSnapshot(P2pMarketState.loadedCustomCases);
    customCases = this.#filterCustomCases(kits, customCases);
    // убираем кейсы вне фильтров и сортируем кастомные кейсы в зависимости от того какая сортировка выставлена у пользователя
    const sortedCustomCases = this.sortFilterCaseItems(
      customCases,
      params.filterMin,
      params.filterMax,
      params.filterQuery,
      isIncreaseOrder,
      alreadyAddedCases,
    );

    // Объект, который мы будем наполнять кейсами
    const kitsWithCustomCases: (IMarketplaceKitData | ICaseItemDtoV2)[] = [...kits];
    // Количество найденных кейсов
    let foundedCases = 0;

    if (overpriceSort) {
      if (alreadyAddedCases === true) {
        return kits;
      }

      // ------------------------- Добавление кастомных кейсов в список отсортированный по наценке-уценке
      let lastOverPrice = (kits[0].price / (kits[0].recommendedPrice || kits[0].price)) * 100;
      for (let i = 0; i < kits.length; i++) {
        lastOverPrice = (kits[i].price / (kits[i].recommendedPrice || kits[i].price)) * 100;
        if (params.sort === '-overprice' ? lastOverPrice < 100 : lastOverPrice > 100) {
          kitsWithCustomCases.splice(i, 0, ...customCases);
          this.store.dispatch(new SetCustomCaseState(true));
          break;
        }
      }
      return kitsWithCustomCases;
    }

    sortedCustomCases.forEach((customCase) => {
      const casePrice = customCase.lastRevision.price;

      // ------------------------- Вставление кастомных кейсов в начало всех предметов
      if (params.isFirstPage) {
        // Если по возрастанию, кейсы меньше по цене первого предмета, но больше минимальной цены фильтров
        if (isIncreaseOrder && casePrice < minKitPrice && casePrice > params.filterMin) {
          kitsWithCustomCases.splice(foundedCases, 0, customCase);
          foundedCases = foundedCases + 1;
          this.store.dispatch(new SetCustomCaseState([customCase.id]));
          return;
        }

        // Если по убыванию, кейсы больше по цене первого предмета, но меньше максимальной цены фильтров
        if (!isIncreaseOrder && casePrice > maxKitPrice && casePrice < params.filterMax) {
          kitsWithCustomCases.splice(foundedCases, 0, customCase);
          foundedCases = foundedCases + 1;
          this.store.dispatch(new SetCustomCaseState([customCase.id]));
          return;
        }
      }

      // ------------------------- Добавление кастомных кейсов посреди списка предметов
      for (let i = 0; i < kits.length; i++) {
        const kitPrice = kits[i].price;
        if (this.canAddCaseItemNextToKit(casePrice, maxKitPrice, minKitPrice, kitPrice, isIncreaseOrder)) {
          kitsWithCustomCases.splice(i + foundedCases, 0, customCase);
          foundedCases = foundedCases + 1;
          this.store.dispatch(new SetCustomCaseState([customCase.id]));
          return;
        }
      }

      // ------------------------- Добавление кейсов в конец списка предметов
      if (params.isLastPage) {
        // Если по возрастанию, кейсы больше по цене последнего предмета, но меньше максимальной цены фильтров
        if (isIncreaseOrder && casePrice > maxKitPrice && casePrice < params.filterMax) {
          kitsWithCustomCases.push(customCase);
          foundedCases = foundedCases + 1;
          this.store.dispatch(new SetCustomCaseState([customCase.id]));
          return;
        }

        // Если по убыванию, кейсы меньше по цене последнего предмета, но больше минимальной цены фильтров
        if (!isIncreaseOrder && casePrice < minKitPrice && casePrice > params.filterMin) {
          kitsWithCustomCases.push(customCase);
          foundedCases = foundedCases + 1;
          this.store.dispatch(new SetCustomCaseState([customCase.id]));
          return;
        }
      }
    });

    return kitsWithCustomCases;
  }

  /**
   * Проверка на то нужно ли нам запрашивать список кастомных кейсов
   * Если текущие киты не вписываются в ценовой диапазон кастомных кейсов, то не запрашиваем их
   * @param kits текущие предметы маркета
   * @param customCasesMeta метаданные с ценами кастомных кейсов
   * @param filters текущие фильтры маркета
   */
  #canRequestCustomCases(
    kits: IMarketplaceKitData[],
    customCasesMeta: { min: number; max: number | null },
    filters: IFilterFormContent<MarketSortingTypes>,
  ): boolean {
    if (this.#environmentService.isProject({ name: PROJECT.MARKET, exclude: true })) {
      return false;
    }
    // Если не выбрано никакое оружие или если среди всех фильтров есть OtherType container (выбраны конкретно кейсы)
    const includeCases = (!filters['weapon'] && !filters['weaponType']) || filters['otherType'].split(',').includes('container');

    // Если хотябы один кейс попадает в ценовой диапазон текущей страницы
    const casesPriceMatch = kits.some(
      (kit) =>
        kit.price >= customCasesMeta.min && (customCasesMeta.max === null ? kit.price <= Infinity : kit.price <= customCasesMeta.max),
    );

    return includeCases && (casesPriceMatch || !kits.length);
  }

  /**
   * Смешиваем кастомные кейсы с предметами маркета
   * @param filters Фильтры маркета
   * @param kits Предметы маркета
   * @param customCases список кастомных кейсов
   * @param meta Метаданные маркета
   * @param callback колбык в который передастся список смешанных предметов маркета и кастомных кейсов
   */
  #requestCustomCasesForKits(
    filters: IFilterFormContent<MarketSortingTypes>,
    kits: IMarketplaceKitData[],
    customCases: ICaseItemDtoV2[],
    meta: IMarketplaceMetaData,
    callback: (kits: (IMarketplaceKitData | ICaseItemDtoV2)[]) => void,
  ): Observable<any> {
    // Параметры необходимые для слияния кастомных кейсов с предметами маркета
    const customCasesFormatParams = {
      sort: filters.sortBy,
      isFirstPage: meta.offset === 0,
      isLastPage: meta.amount - meta.offset <= meta.limit,
      filterMin: filters.minPrice || 0,
      filterMax: filters.maxPrice || Infinity,
      filterQuery: filters.query,
    };

    // Если у нас еще нет кастомных кейсов, то запрашиваем их
    if (!customCases || !customCases.length) {
      return this.casesBackendService
        .getCasesFromCategoryAll({
          sortBy: filters.sortBy === 'price' ? DefaulSortingTypes.MIN_PRICE : DefaulSortingTypes.MAX_PRICE,
        })
        .pipe(
          tap((cases) => {
            const kitsWithCustomCases = this.addCustomCasesToKits(kits, cases, customCasesFormatParams);
            // Сохраняем полученные кастомные кейсы, чтобы в дальнейшем доставать их, а не делать новый запрос
            this.store.dispatch(new SetCustomCases(cases));
            callback(kitsWithCustomCases);
          }),
        );
    } else {
      // Если кастомные кейсы уже есть, то смешиваем их
      const kitsWithCustomCases = this.addCustomCasesToKits(kits, customCases, customCasesFormatParams);
      // И возвращаем то, что смешали
      callback(kitsWithCustomCases);
      return of(null);
    }
  }

  /**
   * Получить кастомные кейсы по фильтрам
   */
  @Action(GetCustomCases)
  getCustomCases({ patchState }: StateContext<P2pMarketStateModel>, filters: ICasesFilter): Observable<any> {
    return this.casesBackendService.getCasesFromCategoryAll(filters).pipe(
      tap((cases) => {
        patchState({
          customCaseList: cases,
        });
      }),
    );
  }

  @Action(ClearCustomCasesState)
  clearCustomCasesState({ patchState }: StateContext<P2pMarketStateModel>): void {
    patchState({
      loadedCustomCases: [],
    });
  }

  @Action(SetCustomCaseState)
  set({ patchState, getState }: StateContext<P2pMarketStateModel>, { loadData }: { loadData: boolean | number[] }): void {
    const currentState = getState().loadedCustomCases;
    if (typeof loadData === 'boolean') {
      patchState({
        loadedCustomCases: loadData ? loadData : [],
      });
    } else {
      patchState({
        loadedCustomCases: typeof currentState === 'boolean' ? [] : currentState.concat(loadData),
      });
    }
  }

  @Action(SetCustomCases)
  setCustomCases({ patchState }: StateContext<P2pMarketStateModel>, { cases }: { cases: ICaseItemDtoV2[] }): void {
    patchState({
      customCaseList: cases,
    });
  }

  @Action(GetMarketItems, { cancelUncompleted: true })
  getItems({ patchState, getState, dispatch }: StateContext<P2pMarketStateModel>, { params }: GetMarketItems): Observable<any> {
    const { filters, customCaseList } = getState();
    const formattedFilters = this.#formatPriceAtFilters(params ? params : filters);

    patchState({
      filters: params ? params : P2P_MARKET_INITIAL_STATE.filters,
    });

    dispatch([new ClearCustomCasesState(), new MarketItemsLoad(), new ClearItems()]);

    /**
     *  TODO: Временно закрываю код, пока не будет на проде работать код бека который возвращает правильный count
     */
    // return combineLatest([
    //   this._api.getMarketItems(formattedFilters),
    //   // this._api.getMarketItemsCount(formattedFilters),
    // ])
    return this._api.getMarketItems(formattedFilters).pipe(
      // map(([items, countItem]) => {
      //   return {
      //     ...items,
      //     meta: {
      //       ...items.meta,
      //       amount: countItem.count,
      //     },
      //   };
      // }),
      switchMap(({ kits, meta }) => {
        // Получаем данные о том, какой диапазон цен у кастомных кейсов
        const canRequestCustomCases = this.#canRequestCustomCases(kits, getState().customCasesMeta, formattedFilters);
        // Если текущие прешедшие предметы попадают в диапазон кастомных кейсов, то смотрим можно ли добавить
        if (canRequestCustomCases) {
          return this.#requestCustomCasesForKits(formattedFilters, kits, customCaseList, meta, (kitsWithCustomCases) => {
            patchState({
              actualItems: kitsWithCustomCases,
              meta: meta,
              tmpMeta: meta,
            });
            return of(null);
          }).pipe(
            catchError(() => {
              patchState({
                actualItems: kits,
                meta: meta,
                tmpMeta: meta,
              });
              return of(null);
            }),
          );
        } else {
          patchState({
            actualItems: kits,
            meta: meta,
            tmpMeta: meta,
          });
          return of(null);
        }
      }),
      finalize(() => {
        dispatch(new MarketItemsLoaded());
      }),
    );
  }

  @Action(ClearMeta)
  clearFiltersMeta({ patchState }: StateContext<P2pMarketStateModel>, { tab }: ChangeActiveTab): void {
    patchState({ tmpMeta: null });
  }

  @Action(MarketItemsLoaded)
  marketItemsLoaded({ patchState }: StateContext<P2pMarketStateModel>, { tab }: ChangeActiveTab): void {
    patchState({ isLoading: false });
  }

  @Action(MarketItemsLoad)
  marketItemsLoad({ patchState }: StateContext<P2pMarketStateModel>, { tab }: ChangeActiveTab): void {
    patchState({ isLoading: true });
  }

  @Action(ClearItems)
  clearItems({ patchState }: StateContext<P2pMarketStateModel>): void {
    patchState({
      actualItems: [],
      tmpItems: [],
      meta: null,
      tmpMeta: null,
    });
  }

  /**
   * Получение метаданных о кастомных кейсов (самый дешевый и самый дорогой кес)
   * Нужно для того, чтобы запрашивать кастомные кейсы только если юзер загрузил предметы в ценовом диапазоне
   */
  @Action(GetCustomCasesMetaData)
  getCustomCasesMetaData({ patchState }: StateContext<P2pMarketStateModel>): Observable<any> {
    return this.casesBackendService.getCasesMetaData().pipe(
      tap((meta: { min: number; max: number }) => {
        patchState({ customCasesMetaData: meta });
      }),
      catchError((err: HttpErrorResponse) => {
        patchState({ customCasesMetaData: { min: 0, max: null } });
        this.#onError(err);
        throw new Error(err.message);
      }),
    );
  }

  /**
   * Получить все кастомные кейсы
   */
  @Action(GetAllCustomCases)
  getAllCustomCases({ patchState }: StateContext<P2pMarketStateModel>): Observable<any> {
    return this.casesBackendService
      .getCasesFromCategoryAll({
        sortBy: DefaulSortingTypes.MAX_PRICE,
      })
      .pipe(
        tap((cases) => {
          patchState({
            customCaseList: cases,
          });
        }),
      );
  }

  @Action(ChangeFilters)
  changeFilters({ patchState, dispatch, getState }: StateContext<P2pMarketStateModel>, { params }: ChangeFilters): Observable<any> {
    const tmpFilters = {
      ...params,
      page: P2P_MARKET_INITIAL_STATE.filters.page,
    } as IFilterFormContent<MarketSortingTypes>;
    const { customCaseList, customCasesMeta } = getState();

    // Обнуляется ради красоты в фильтрах, если есть место лучше, то милости прошу
    dispatch([new ClearMeta(), new MarketItemsLoad(), new ClearCustomCasesState()]);
    const formattedFilters = this.#formatPriceAtFilters(tmpFilters);
    /**
     *  TODO: Временно закрываю код, пока не будет на проде работать код бека который возвращает правильный count
     */
    // return combineLatest([
    //   this._api.getMarketItems({
    //     ...formattedFilters,
    //     sortBy: tmpFilters.sortBy ? tmpFilters.sortBy : MarketSortingTypes.MAX_PRICE,
    //   }),
    //   this._api.getMarketItemsCount(formattedFilters),
    // ])
    return this._api
      .getMarketItems({
        ...formattedFilters,
        sortBy: tmpFilters.sortBy ? tmpFilters.sortBy : MarketSortingTypes.MAX_PRICE,
      })
      .pipe(
        // map(([items, countItem]) => {
        //   return {
        //     ...items,
        //     meta: {
        //       ...items.meta,
        //       amount: countItem.count,
        //     },
        //   };
        // }),
        switchMap(({ kits, meta }) => {
          // Получаем данные о том, какой диапазон цен у кастомных кейсов
          const canRequestCustomCases = this.#canRequestCustomCases(kits, customCasesMeta, formattedFilters);

          // Если текущие прешедшие предметы попадают в диапазон кастомных кейсов, то смотрим можно ли добавить
          if (canRequestCustomCases) {
            return this.#requestCustomCasesForKits(formattedFilters, kits, customCaseList, meta, (kitsWithCustomCases) => {
              patchState({
                tmpItems: kitsWithCustomCases,
                tmpMeta: meta,
                tmpFilters,
              });
              dispatch(new MarketItemsLoaded());
              return of(null);
            }).pipe(
              catchError(() => {
                patchState({
                  actualItems: kits,
                  meta: meta,
                  tmpMeta: meta,
                });
                return of(null);
              }),
            );
          } else {
            patchState({
              tmpItems: kits,
              tmpMeta: meta,
              tmpFilters,
            });
            dispatch(new MarketItemsLoaded());
            return of(null);
          }
        }),
      );
  }

  @Action(ChangeSteamInventoryFilters)
  changeSteamFilters({ patchState, dispatch }: StateContext<P2pMarketStateModel>, { params }: ChangeFilters): Observable<ISteamStore> {
    const tmpFilters = {
      ...params,
      page: P2P_MARKET_INITIAL_STATE.filters.page,
    } as IFilterFormContent<MarketSortingTypes>;
    dispatch([new ClearMeta(), new MarketItemsLoad()]);
    return this._api
      .getInventory({
        ...this.#formatPriceAtFilters(tmpFilters),
        sortBy: tmpFilters.sortBy ? tmpFilters.sortBy : MarketSortingTypes.MAX_PRICE,
      })
      .pipe(
        tap(({ items, count }) => {
          if (count !== undefined && items) {
            patchState({
              tmpItems: items,
              tmpMeta: { amount: count, limit: 999999, offset: 0 },
              tmpFilters,
            });
          }
          dispatch(new MarketItemsLoaded());
        }),
      );
  }

  @Action(ChangePage)
  changePage({ patchState, getState }: StateContext<P2pMarketStateModel>, { page }: ChangePage): Observable<any> {
    const { filters, actualItems, customCaseList } = getState();
    const formattedFilters = this.#formatPriceAtFilters(filters);
    return this._api.getMarketItems({ ...formattedFilters, page: { ...(filters.page as Pagination), number: page } }).pipe(
      switchMap(({ kits, meta }) => {
        // Получаем данные о том, какой диапазон цен у кастомных кейсов
        const canRequestCustomCases = this.#canRequestCustomCases(kits, getState().customCasesMeta, formattedFilters);

        // Если текущие прешедшие предметы попадают в диапазон кастомных кейсов, то смотрим можно ли добавить
        if (canRequestCustomCases) {
          return this.#requestCustomCasesForKits(formattedFilters, kits, customCaseList, meta, (kitsWithCustomCases) => {
            patchState({
              actualItems: actualItems.concat(kitsWithCustomCases),
              meta: meta,
            });
            return of(null);
          }).pipe(
            catchError(() => {
              patchState({
                actualItems: kits,
                meta: meta,
                tmpMeta: meta,
              });
              return of(null);
            }),
          );
        } else {
          patchState({
            actualItems: actualItems.concat(kits),
            meta: meta,
          });
          return of(null);
        }
      }),
    );
  }

  @Action(ApplyFilters)
  confirmSearch({ patchState, getState }: StateContext<P2pMarketStateModel>): void {
    const { tmpMeta, tmpItems, tmpFilters } = getState();
    // patchState({ actualItems: [] });
    if (tmpMeta && tmpItems) {
      patchState({
        actualItems: tmpItems as IMarketplaceKitData[],
        meta: tmpMeta,
        filters: tmpFilters,
        tmpMeta: null,
        tmpFilters: P2P_MARKET_INITIAL_STATE.tmpFilters,
        tmpItems: [],
      });
    }
  }

  @Action(SortSteamInventoryByMethod)
  SortSteamInventoryByMethod(
    { patchState, getState }: StateContext<P2pMarketStateModel>,
    { sortingMethod }: SortSteamInventoryByMethod,
  ): void {
    const { tmpFilters } = getState();
    patchState({ tmpFilters: { ...tmpFilters, sortBy: sortingMethod } });
  }

  @Action(GetItemSihStats)
  getItemSihStats({ patchState }: StateContext<P2pMarketStateModel>, payload: { itemName: string }): Observable<ISihDataset> {
    return combineLatest([
      this._api.getItemSihStats({ name: payload.itemName, appid: 730, market: 'steamlivesell' }), // Запрашиваем данные о предмете. приходит в рублях (к сожалению)
      this._api.getCurrencies(SihCurrencyType.RUB), // Запрашиваем у Сиха курс по которому он переводит цены
    ]).pipe(
      tap((result: any) => {
        // Если сих нам успешно вернул данные, но они битые, то мы не показываем ошибку "что-то с сервером", а показываем нули
        if (!Object.keys(result[0]).length) {
          result[0] = { steamlivesell: new Array(30).fill([0, [0, 0, 0, 0, 0, 0, 0, 0, 0], []]) };
        }

        if (result[0] && result[1]?.success) {
          patchState({
            sihDataset: {
              // Всё, что мы тут делаем, это переводим рубли в доллары. Выглядит неоч. Но Сих сам нам шлет неудобный формат
              steamlivesell: result[0]['steamlivesell'].map((item: any) => {
                item[1] = item[1].map((value: any, index: number) =>
                  index < 3 ? value * result[1].data.rates[SihCurrencyType.USD] : value,
                );
                return item;
              }),
            },
          });
        }
      }),
    );
  }

  @Action(ResetSihStat)
  resetSihStat({ patchState }: StateContext<P2pMarketStateModel>): void {
    patchState({
      sihDataset: null,
    });
  }

  @Action(GetMarketHistory)
  getMArketHistoryItems({ patchState }: StateContext<P2pMarketStateModel>, { params }: GetMarketHistory): Observable<any[]> {
    return this._api.getMarketHistory(params).pipe(
      tap((value) => {
        patchState({
          marketHistory: this.#formatMarketHistoryItems(value),
        });
      }),
    );
  }

  #formatMarketHistoryItems(payload: ITransactionDto[]): HistoryItem[] {
    return payload.reduce((formatedHistory: HistoryItem[], apiHistoryItem: ITransactionDto) => {
      if (
        apiHistoryItem.namespace === TransactionNamespaces.BALANCE &&
        apiHistoryItem.kit &&
        (apiHistoryItem.timestamp || apiHistoryItem.kit.statusAt) &&
        apiHistoryItem.coinsAmount
      ) {
        formatedHistory.push({
          createdAt: new Date(apiHistoryItem.timestamp || apiHistoryItem.kit.statusAt),
          item: {
            baseItem: apiHistoryItem.kit.items[0].baseItem,
            price: apiHistoryItem.coinsAmount,
          },
          status: {
            status: apiHistoryItem.coinsAmount > 0 ? HistoryItemStatus.DEPOSIT : HistoryItemStatus.WITHDRAW,
            source: {
              type: HistorySourceType.INVENTORY,
            },
          },
        });
      }
      return formatedHistory;
    }, []);
  }

  #formatPriceAtFilters(filters: IFilterFormContent<MarketSortingTypes>): IFilterFormContent<MarketSortingTypes> {
    return {
      ...filters,
      minPrice: filters.minPrice ? filters.minPrice * 100 : null,
      maxPrice: filters.maxPrice ? filters.maxPrice * 100 : null,
      withCase: true, // если вернется стимовский кейс, который совпадает с нашим кастомным, то вернется id и цена кастомного кейса
    };
  }

  #onError(error: HttpErrorResponse): void {
    this.notificationsService.addErrorNotification(
      error.error && error.error.message ? error.error.message : typeof error.error === 'string' ? error.error : 'Error',
      { type: error.error && error.error.type ? error.error.type : NotificationType.Error },
    );
  }
}
