import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CustomAsyncPipe } from './custom-async.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [CustomAsyncPipe],
  exports: [CustomAsyncPipe],
  providers: [CustomAsyncPipe],
})
export class CustomAsyncPipeModule {}
