import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule, TypeProvider } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { TranslateModule } from '@ngx-translate/core';

import { P2pAuctionCompletedModalModule } from './modals/p2p-auction-completed-modal/p2p-auction-completed-modal.module';
import { P2pSetupModalModule } from './modals/p2p-setup-modal/p2p-setup-modal.module';
import { P2pComponent } from './p2p.component';
import { P2pPurchaseModule } from './p2p-purchase/p2p-purchase.module';
import { P2pSellModule } from './p2p-sell/p2p-sell.module';
import { P2pSettingsModule } from './p2p-settings/p2p-settings.module';
import { P2P_ENGINE } from './symbols';

@NgModule({
  declarations: [P2pComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    P2pAuctionCompletedModalModule,
    P2pSetupModalModule,
    P2pSellModule,
    P2pPurchaseModule,
    P2pSettingsModule,
    MatTabsModule,
    MatIconModule,
  ],
  exports: [P2pComponent],
  // bootstrap: [P2pComponent],
})
export class P2pWidgetModule {
  static forRoot(engine: TypeProvider): ModuleWithProviders<P2pWidgetModule> {
    return {
      ngModule: P2pWidgetModule,
      providers: [
        {
          provide: P2P_ENGINE,
          useClass: engine,
        },
      ],
    };
  }
}
