import { Injectable } from '@angular/core';
import { INotification } from '@dev-fast/types';
import { BehaviorSubject, Observable } from 'rxjs';

import { NotificationsService } from '@app/core/notification-service';

import { Timer } from './utils/timer';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  private readonly STACK_SIZE = 4;
  private readonly COOLDOWN = 5000;
  private readonly stackFrom: 'top' | 'bottom';
  private notificationsStack: Timer[];
  private showcaseNotifications: BehaviorSubject<INotification[]>;
  showcaseNotifications$: Observable<INotification[]>;

  constructor(private readonly notificationsService: NotificationsService) {
    this.showcaseNotifications = new BehaviorSubject<INotification[]>([]);
    this.showcaseNotifications$ = this.showcaseNotifications.asObservable();
    this.notificationsStack = [];
    this.stackFrom = 'bottom';

    this.notificationsService.isAddNotificationSuccess$.subscribe((val) => {
      if (val && val.showToast) {
        this.addNotification(val);
      }
    });
  }
  addNotification(notification: INotification): void {
    this.notifyMessage(notification);
  }
  deleteNotification(notyId: number): void {
    this.notificationsService.removeNotification(notyId);
    this.showcaseNotifications.next(this.showcaseNotifications.getValue().filter((oldNotification) => oldNotification.id !== notyId));
  }

  popNotification(id: number): void {
    this.showcaseNotifications.next(this.showcaseNotifications.getValue().filter((oldNotification) => oldNotification.id !== id));
  }

  notifyMessage(notification: INotification): void {
    const notifications = this.showcaseNotifications.getValue();

    if (['top'].includes(this.stackFrom)) {
      notifications.unshift(notification);
      if (this.STACK_SIZE < notifications.length) {
        notifications.pop();
      }
    } else {
      notifications.push({ ...notification, remainingTime: this.COOLDOWN });
      if (this.STACK_SIZE < notifications.length) {
        notifications.shift();
      }
    }

    this.showcaseNotifications.next(notifications);

    this.notificationsStack.push(new Timer(notification.id, () => this.popNotification(notification.id), this.COOLDOWN));

    if (document.hidden) {
      this.pauseTimer();
    }
  }

  pauseTimer(): void {
    this.notificationsStack.forEach((t: Timer) => t.pause());
  }

  resumeTimer(): void {
    this.notificationsStack.forEach((t: Timer) => t.resume());
  }
}
