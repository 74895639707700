<mat-tab-group dynamicHeight>
  <mat-tab label="{{ 'P2P_WIDGETS.BUY' | translate }}">
    <div class="market-attention">
      <div class="market-attention__container">
        <div class="market-attention__header">
          <!--          FIXME Нужна другая иконка-->
          <mat-icon svgIcon="attention"></mat-icon>
          <span>{{ 'P2P_WIDGETS.WARN.WARN' | translate }}</span>
        </div>
        <div class="market-attention__description">
          {{ 'P2P_WIDGETS.WARN.DELIBERATELY_BREAKDOWN_OFFER' | translate }}
        </div>
        <div
          class="market-attention__close"
          (click)="closeAttentionEmit.emit()"
        >
          <mat-icon svgIcon="close"></mat-icon>
        </div>
      </div>
    </div>
    <div class="market__empty-wrapper">
      <mat-icon
        svgIcon="p2p-trading-icon"
        class="market__icon"
      ></mat-icon>
      <div class="market__description">
        {{ 'P2P_WIDGETS.INVITE.YOU_CAN_BUY_SKINS' | translate }}
      </div>
      <a
        langRouterLink
        [routerLink]="'/store'"
        class="btn btn-primary market__button"
        >{{ 'P2P_WIDGETS.INVITE.MARKETPLACE' | translate }}</a
      >
    </div>
  </mat-tab>
  <mat-tab label="{{ 'P2P_WIDGETS.SELL' | translate }}">
    <div class="market__empty-wrapper">
      <mat-icon
        svgIcon="p2p-trading-icon"
        class="market__icon"
      ></mat-icon>
      <div class="market__description">
        {{ 'P2P_WIDGETS.INVITE.YOU_CAN_SELL_SKINS' | translate }}
      </div>
      <!--      FIXME Добавить ключ-->
      <a
        langRouterLink
        [routerLink]="'/balance/refill'"
        class="btn btn-primary market__button"
        >To refill</a
      >
    </div>
  </mat-tab>
  <mat-tab>
    <ng-template mat-tab-label>
      <mat-icon svgIcon="settings-selector"></mat-icon>
    </ng-template>
    <form
      class="p2p-settings"
      [formGroup]="tradesSettings"
    >
      <!--    FIXME Добавить ключи для всего блока-->
      <div class="p2p-settings__wrapper">
        <div class="p2p-settings__control">
          <span class="p2p-settings__title"> Trades duration: </span>
          <div class="p2p-settings__buttons">
            <label class="p2p-settings__label">
              <input
                class="p2p-settings__label_radio"
                type="radio"
                name="duration"
                value="1"
                formControlName="duration"
              />
              <span class="p2p-settings__label_button">1 hr.</span>
            </label>
            <label class="p2p-settings__label">
              <input
                class="p2p-settings__label_radio"
                type="radio"
                name="duration"
                value="3"
                formControlName="duration"
              />
              <span class="p2p-settings__label_button">3 hr.</span>
            </label>
            <label class="p2p-settings__label">
              <input
                class="p2p-settings__label_radio"
                type="radio"
                name="duration"
                value="12"
                formControlName="duration"
              />
              <span class="p2p-settings__label_button">12 hr.</span>
            </label>
          </div>
        </div>
        <div class="p2p-settings__content">
          <div class="p2p-settings__control">
            <div class="p2p-settings__row">
              <input
                class="p2p-settings__checkbox"
                type="checkbox"
                id="approve"
                formControlName="autoapprove"
              />
              <label
                for="approve"
                class="p2p-settings__title fake-checkbox"
                >Autoapprove</label
              >
            </div>
            <div class="p2p-settings__text">
              Automatic confirmation of the transaction with the sold item at the end of the auction. You just have to send the trade offer
              to the buyer.
            </div>
          </div>
          <div class="p2p-settings__control">
            <div class="p2p-settings__row">
              <input
                class="p2p-settings__checkbox"
                type="checkbox"
                id="delivery"
                formControlName="quickDelivery"
              />
              <label
                for="delivery"
                class="p2p-settings__title fake-checkbox"
                >Quick delivery</label
              >
            </div>
            <div class="p2p-settings__text">Reduces the delivery time, increases the item position in the list of items.</div>
          </div>
        </div>
      </div>
    </form>
  </mat-tab>
</mat-tab-group>
