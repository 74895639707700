import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { GameMode, GameStatus, IAllGamesPanelConfig, IEvent, IGame, IMenuItemConfig, IWinnerBanner } from '@dev-fast/types';

@Component({
  selector: 'app-ui-game-menu-v2',
  templateUrl: './game-menu-v2.component.html',
  styleUrls: ['./game-menu-v2.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GameMenuV2Component {
  @Input() public isOpen?: boolean;
  @Input() public isTable!: boolean | null;
  @Input() public state: Record<string, GameStatus>;
  @Input() public lastGames: IGame[] = [];
  @Input() public set availableGames(payload: IGame[] | null) {
    if (!payload) return;
    this.gamesDict = {};
    payload.forEach((game) => {
      if (this.gamesDict[game.mode]) {
        this.gamesDict[game.mode]?.push(game);
      } else {
        this.gamesDict[game.mode] = [game];
      }
    });
    this.modes = Object.values(GameMode).filter((value) => Object.keys(this.gamesDict).includes(value));
  }
  @Output() public toggleFullView: EventEmitter<void> = new EventEmitter();

  public testAnim = false;

  public sliceMod = 3;
  public modes: GameMode[];
  public isShowFullView = false;
  public gamesDict: Partial<Record<GameMode, IGame[]>>;

  //TODO mock data
  public marketBtn: IGame = {
    key: 'store',
    mode: GameMode.PVE,
    title: 'Marketplace',
    routerLink: 'store',
  };
  public allGamesBtn: IGame = {
    key: 'all-games',
    mode: GameMode.PVE,
    title: 'All Games',
    routerLink: null,
  };
  public eventsConfig: IEvent[] = [
    {
      key: 'promo',
      title: 'Promo',
      isCurrent: false,
    },
    {
      key: 'quest',
      title: 'Quest',
      isCurrent: false,
    },
    {
      key: 'events',
      title: 'New Year',
      isCurrent: true,
    },
  ];

  public winnerBannerInfo: IWinnerBanner = {
    profit: 250000,
    isShow: true,
  };

  constructor() {
    this.availableGames = [];
    this.gamesDict = {};
    this.modes = [];
    this.state = {};
  }
  public proceedPanelStatus(): void {
    this.toggleFullView.emit();
  }

  public getItemConfig(game: IGame): IMenuItemConfig {
    return {
      game: game,
      isNavbar: true,
      isTable: this.isTable,
      status: this.state[game.key],
    };
  }
  public getPanelConfig(): IAllGamesPanelConfig {
    return {
      state: this.state,
      games: this.gamesDict,
      isTable: this.isTable,
    };
  }
  public operateNavbar(): void {
    this.sliceMod = this.sliceMod === 3 ? 10 : 3;
  }
}
