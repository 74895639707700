import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { GameMode, IAllGamesPanelConfig, IEvent, IMenuItemConfig } from '@dev-fast/types';

import { gameMenuAnimation } from '../../game-menu.animation';

@Component({
  selector: 'app-ui-games-list-panel',
  templateUrl: './games-list-panel.component.html',
  styleUrls: ['./games-list-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [gameMenuAnimation],
})
export class GamesListPanelComponent {
  @Input() public panelConfig!: IAllGamesPanelConfig;
  @Input() public events!: IEvent[];
  @Input() public modes: GameMode[] = [];

  @Output() public AllGamesPanelStatus: EventEmitter<boolean>;

  public eventsConfig: IEvent[] = [
    {
      key: 'promo',
      title: 'Promo',
      isCurrent: false,
    },
    {
      key: 'quest',
      title: 'Quest',
      isCurrent: false,
    },
    {
      key: 'events',
      title: 'New Year',
      isCurrent: true,
    },
  ];

  public market: IMenuItemConfig = {
    isNavbar: false,
    game: {
      key: 'store',
      mode: GameMode.PVE,
      title: 'Market',
      routerLink: 'store',
    },
    isTable: true,
  };

  constructor() {
    this.AllGamesPanelStatus = new EventEmitter<boolean>();
    // this.market.view = ViewportType.TABLET;
  }
  public proceedPanelStatus(): void {
    this.AllGamesPanelStatus.emit();
  }
}
