import { ICaseItemDtoV2, ICasesFilter, IFilterFormContent, ITransactionHistoryIds, MarketPanel, MarketSortingTypes } from '@dev-fast/types';

export class GetMarketItems {
  static readonly type = '[p2p_market] Get Market Items';
  constructor(public params?: IFilterFormContent<MarketSortingTypes>) {}
}

export class GetSteamInventoryItems {
  static readonly type = '[p2p_market] Get Steam Inventory Items';
  constructor(public params?: IFilterFormContent<MarketSortingTypes>) {}
}

export class ChangeFilters {
  static readonly type = '[p2p_market] Change Filters';
  constructor(public params?: IFilterFormContent<MarketSortingTypes>) {}
}

export class ClearCustomCasesState {
  static readonly type = '[p2p_market] Remove custom case';
}

export class SetCustomCaseState {
  static readonly type = '[p2p_market] set custom case';
  constructor(public loadData: boolean | number[]) {}
}

export class ChangeSteamInventoryFilters {
  static readonly type = '[p2p_market] Change Steam Inventory Filters';
  constructor(public params?: IFilterFormContent<MarketSortingTypes>) {}
}

export class ChangePage {
  static readonly type = '[p2p_market] Change Page';
  constructor(public page: number) {}
}

export class ChangeActiveTab {
  static readonly type = '[p2p_market] Change Active Tab';
  constructor(public tab: MarketPanel) {}
}

export class ApplyFilters {
  static readonly type = '[p2p_market] Apply Filters';
}
export class ClearMeta {
  static readonly type = '[p2p_market] Clear Filters tmp Meta';
}
export class MarketItemsLoaded {
  static readonly type = '[p2p_market] Market Items Loaded';
}
export class MarketItemsLoad {
  static readonly type = '[p2p_market] Market Items Load';
}

export class ClearItems {
  static readonly type = '[p2p_market] Clear Items';
}
export class ApplySteamInventoryFilters {
  static readonly type = '[p2p_market] Apply Steam Inventory Filters';
}

export class GetMarketHistory {
  static readonly type = '[p2p_market] Get Market History';
  constructor(public params: ITransactionHistoryIds) {}
}

export class SortSteamInventoryByMethod {
  static readonly type = '[p2p_market] Sort Steam Inventory By Method';
  constructor(public sortingMethod: MarketSortingTypes) {}
}

export class GetItemSihStats {
  static readonly type = '[p2p] Get Item SIH Stats';
  constructor(public itemName: string) {}
}

export class ResetSihStat {
  static readonly type = '[p2p] Reset SIH Stats';
}

// Получение всех кастомных кейсов
export class GetAllCustomCases {
  static readonly type = '[Market Cases] get all custom cases';
}
// Получение кастомных кейсов по фильтрам
export class GetCustomCases {
  static readonly type = '[Market Cases] get custom cases';
  constructor(public casesFilter?: ICasesFilter) {}
}

// Получение метаданных о ценах кейсов (самый дешевый и самый дорогой)
export class GetCustomCasesMetaData {
  static readonly type = '[Market Cases] get custom cases meta data';
}

export class SetCustomCases {
  static readonly type = '[Case Modal] set custom cases';
  constructor(public cases: ICaseItemDtoV2[]) {}
}
