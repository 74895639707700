import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IP2pPurchaseItem } from '@dev-fast/types';
import moment from 'moment';
import { interval, Observable, timer } from 'rxjs';
import { filter, map, startWith, switchMap, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-p2p-purchase-trade-modal',
  templateUrl: './p2p-purchase-trade-modal.component.html',
  styleUrls: ['./p2p-purchase-trade-modal.component.scss', '../../styles/progress-line.scss'],
})
export class P2pPurchaseTradeModalComponent {
  initialProgress$: Observable<string> | undefined;
  offerProgress$: Observable<number> | undefined;
  offerTimer$: Observable<number> | undefined;
  constructor(
    public dialogRef: MatDialogRef<P2pPurchaseTradeModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data$: Observable<IP2pPurchaseItem>,
  ) {
    this.subscribeEmitters();
  }

  private subscribeEmitters(): void {
    this.offerTimer$ = this.data$.pipe(
      filter(({ nextStatusAt }) => Date.parse(nextStatusAt) > Date.now()),
      switchMap(({ nextStatusAt }) =>
        interval(1000).pipe(
          startWith(Date.parse(nextStatusAt) - Date.now()),
          map(() => Date.parse(nextStatusAt) - Date.now()),
          takeUntil(timer(Date.parse(nextStatusAt) - Date.now())),
        ),
      ),
    );

    this.initialProgress$ = this.data$.pipe(
      map(({ nextStatusAt, statusAt }) =>
        (100 - (moment(nextStatusAt).diff(moment()) / moment(nextStatusAt).diff(statusAt)) * 100).toFixed(2),
      ),
    );

    this.offerProgress$ = this.data$.pipe(map(({ nextStatusAt }) => Date.parse(nextStatusAt) - Date.now()));
  }
  onClose(): void {
    this.dialogRef.close();
  }
}
