import { Injectable } from '@angular/core';
import {
  ICaseItemDetailedReq,
  ICaseItemDtoV2,
  IFilterFormContent,
  IInventoryItemDetailed,
  IInventoryItemDetailedReq,
  IMarketplaceKitData,
  IP2pPurchaseItem,
  ISteamStoreInventory,
  MarketSortingTypes,
  ModalNames,
  Widgets,
} from '@dev-fast/types';
import { Actions, ofActionSuccessful, Select } from '@ngxs/store';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { StateReset } from 'ngxs-reset-plugin';
import { Observable } from 'rxjs';

import { ChangeActiveWidgets, ToggleBackground } from '@app/core/state/layout';
import { OpenModal } from '@app/core/state/modals';
import { P2pBuyingState, P2pMarketState, ToggleSelected } from '@app/core/state/p2p';

import { GetAvailabilityItemInCases, GetCaseItemDetails, GetItemAvailabilityOnThenMarket } from './skins.actions';
import { SkinsState } from './skins.state';

@Injectable({ providedIn: 'root' })
export class SkinsService {
  @Select(SkinsState.itemDetails)
  readonly itemDetails$!: Observable<IInventoryItemDetailed>;
  @Select(SkinsState.itemDetailsRequestState)
  readonly itemDetailsRequestState$!: Observable<boolean | null>;
  @Select(SkinsState.itemAvailabilityOnThenMarket)
  readonly itemAvailabilityOnThenMarket$!: Observable<IMarketplaceKitData[]>;
  @Select(SkinsState.itemAvailabilityInCases)
  readonly itemAvailabilityInCases$!: Observable<ICaseItemDtoV2[]>;
  @Select(SkinsState.direction)
  readonly direction$!: Observable<string>;

  @Select(P2pBuyingState.purchasing)
  purchasing$!: Observable<IP2pPurchaseItem[]>;

  getMarketItemsAction$ = this.actions$.pipe(ofActionSuccessful(GetItemAvailabilityOnThenMarket));

  constructor(private readonly actions$: Actions) {}

  @Dispatch() GetItemAvailabilityOnThenMarket = (params: IFilterFormContent<MarketSortingTypes>): GetItemAvailabilityOnThenMarket =>
    new GetItemAvailabilityOnThenMarket(params);
  @Dispatch() toggleSelected = (value: IMarketplaceKitData): ToggleSelected => new ToggleSelected(value);

  @Dispatch() getCaseItemDetails = (params: IInventoryItemDetailedReq): GetCaseItemDetails => new GetCaseItemDetails(params);
  @Dispatch()
  getItemAvailabilityInCases = (params: ICaseItemDetailedReq): GetAvailabilityItemInCases => new GetAvailabilityItemInCases(params);
  @Dispatch() changeActiveWidgets = (widgets: Widgets[]): ChangeActiveWidgets => new ChangeActiveWidgets(widgets);
  @Dispatch() resetState = (): StateReset => new StateReset(SkinsState);
  @Dispatch() resetP2pMarketState = (): StateReset => new StateReset(P2pMarketState);

  @Dispatch() toggleBackground = (color?: string): ToggleBackground => new ToggleBackground(color);
  @Dispatch()
  showBatchDetailsModal = (item: IMarketplaceKitData | ISteamStoreInventory): OpenModal => new OpenModal(ModalNames.P2P_KIT_DETAILS, item);
}
