import { Injectable } from '@angular/core';
import {
  ICryptoMethodsDto,
  ICryptoUserMethodsDto,
  INewCryptoWalletResponse,
  IPaymentsDto,
  IPaymentsDtoV2,
  IRefillOrder,
  IRefillSuccessWS,
  ITransactionDto,
  SocketResponse,
} from '@dev-fast/types';
import { Socket } from 'ngx-socket-io';
import { catchError, map, Observable, of } from 'rxjs';

import { Environments, EnvironmentService } from '@app/core/environment-service';

import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class PayService {
  private environment: Environments;

  constructor(
    private readonly api: ApiService,
    private readonly environmentService: EnvironmentService,
    private readonly ws: Socket,
  ) {
    this.environment = this.environmentService.getEnvironment();
  }
  getLastRefill(): Observable<IRefillOrder> {
    return this.api.get<IRefillOrder>(`${this.environment.PAYMENT_URL_2}/pay/last`, { withCredentials: true });
  }

  getPaymentMethodsByCountry(country: string, userId: number): Observable<IPaymentsDto> {
    return this.api.get(`${this.environment.PAYMENT_URL_2}/methods/${userId}`, { params: { country } });
  }
  getPaymentMethodsByCountryNew(country: string, userId: number): Observable<IPaymentsDtoV2> {
    return this.api.get(`${this.environment.PAYMENT_URL_2}/methods/${userId}`, { params: { country, v3: 1 } });
  }
  getPaymentHistory(queryParameters: Record<string, any>): Observable<ITransactionDto[]> {
    return this.api.get<ITransactionDto[]>(`${this.environment.GATEWAY_URL}/api/transactions-history`, {
      withCredentials: true,
      params: queryParameters,
    });
  }
  getUserCryptoWallets(userId: number): Observable<ICryptoUserMethodsDto> {
    return this.api.get(`${this.environment.PAYMENT_URL_2}/wallets/${userId}`);
  }
  getCryptoWallets(countryCode?: string): Observable<ICryptoMethodsDto> {
    return this.api.get(`${this.environment.PAYMENT_URL_2}/wallets`, { params: { country: countryCode } });
  }
  checkPaymentTrust(userId: number): Observable<void | { status: boolean }> {
    return this.api
      .get(`${this.environment.PAYMENT_URL_2}/payment-trust/${userId}`, { withCredentials: true })
      .pipe(catchError(() => of({ status: true })));
  }
  getPaymentsMethods(userId: number): Observable<IPaymentsDto> {
    return this.api.get(`${this.environment.PAYMENT_URL_2}/${userId}`, { withCredentials: true });
  }
  createNewWallet(userId: number, network: string, currency: string): Observable<INewCryptoWalletResponse> {
    return this.api.post(`${this.environment.PAYMENT_URL_2}/wallets/${userId}`, { network, currency }, { withCredentials: true });
  }
  onRefillSuccess(): Observable<SocketResponse<IRefillSuccessWS>> {
    return this.ws.fromEvent<IRefillSuccessWS>('deposit:confirmed').pipe(map((value) => ({ payload: value, name: 'deposit_confirmed' })));
  }
}
