import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-ui-ny-navigate-button',
  standalone: true,
  imports: [CommonModule, RouterLinkActive, RouterLink, TranslateModule],
  templateUrl: './ny-navigate-button.component.html',
  styleUrls: ['./ny-navigate-button.component.scss'],
})
export class NyNavigateButtonComponent {
  @Input() public eventName: 'ny' | 'ny_china' | 'halloween' | null = 'ny';
  @Input() public snowType: 1 | 2 | 7 | 0 = 7;
  @Input() public isActive = false;
  @Input() public link = '';
  @Input() public text = '';
  @Input() public className = '';
}
