import { Location } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-game-break-modal',
  templateUrl: './game-break-modal.component.html',
  styleUrls: ['./game-break-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GameBreakModalComponent implements OnDestroy {
  constructor(
    private location: Location,
    private dialogRef: MatDialogRef<GameBreakModalComponent>,
  ) {}

  ngOnDestroy(): void {
    this.location.back();
  }
  goBack(): void {
    this.dialogRef.close();
  }
}
