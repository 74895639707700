import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, NgModule } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { ChatService } from '@app/core/chatra-service';

@Component({
  selector: 'app-tech-support',
  templateUrl: './tech-support.component.html',
  styleUrls: ['./tech-support.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TechSupportComponent {
  constructor(
    private readonly dialogRef: MatDialogRef<TechSupportComponent>,
    private readonly chatService: ChatService,
  ) {}

  close(): void {
    this.dialogRef.close();
  }
  openChatra(): void {
    this.chatService.openChat();
    this.close();
  }
  hasChat(): boolean {
    return this.chatService.hasChat();
  }
}

@NgModule({
  imports: [CommonModule, MatIconModule, TranslateModule, RouterModule],
  declarations: [TechSupportComponent],
  exports: [TechSupportComponent],
})
export class TechSupportComponentModule {}
