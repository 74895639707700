<ng-container *ngIf="link && link.length; else noLink">
  <a
    langRouterLink
    [routerLink]="[link]"
    [queryParams]="query"
    class="brand-link"
  >
    <mat-icon
      class="mirror-icon"
      [svgIcon]="mirrorIcon"
    ></mat-icon>
    <mat-icon
      class="domain-icon"
      [svgIcon]="domainIcon"
    ></mat-icon>
  </a>
</ng-container>
<ng-template #noLink>
  <mat-icon
    class="mirror-icon"
    [svgIcon]="mirrorIcon"
  ></mat-icon>
  <mat-icon
    class="domain-icon"
    [svgIcon]="domainIcon"
  ></mat-icon>
</ng-template>
