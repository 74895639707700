import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { FillContainerDirective } from './fill-container.directive';

@NgModule({
  declarations: [FillContainerDirective],
  imports: [CommonModule],
  exports: [FillContainerDirective],
})
export class FillContainerModule {}
