import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@Component({
  selector: 'app-ui-spinner-loader',
  templateUrl: './spinner.component.html',
  standalone: true,
  imports: [CommonModule, MatProgressSpinnerModule],
  styleUrls: ['./spinner.component.scss'],
})
export class SpinnerLoaderSAComponent {}
