export interface SihModalTranslations {
  header?: string;
  body?: string;
  body_2?: string;
  button?: string;
  button_2?: string;
  footer?: string;
}

export const DefaultSihModalTranslations: SihModalTranslations = {
  header: 'SIH.FIRST_MODAL.HEADER',
  body: 'SIH.FIRST_MODAL.BODY',
  body_2: 'SIH.FIRST_MODAL.SIH',
  button: 'SIH.FIRST_MODAL.BUTTON',
  button_2: 'STEAM_PANEL.UPDATE',
  footer: 'SIH.FIRST_MODAL.NOTE',
};

export const SIH_LINK = '//chrome.google.com/webstore/detail/steam-inventory-helper/cmeakgjggjdlcpncigglobpjbkabhmjl';
