<div class="header">
  <div
    class="title"
    *ngIf="title"
  >
    {{ title }}
  </div>
  <div
    class="description"
    *ngIf="description"
  >
    {{ description }}
  </div>
</div>
<div class="items">
  <ng-content></ng-content>
</div>
