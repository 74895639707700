import { IconConfig } from '@dev-fast/types';

export const iconsPaymentMethods: IconConfig[] = [
  {
    name: 'payment_bitcoin123',
    path: 'assets/img/mat-icons/payment-methods/bitcoin123.svg',
  },
  {
    name: 'payment_bnb',
    path: 'assets/img/mat-icons/payment-methods/bnb.svg',
  },
  {
    name: 'payment_busd',
    path: 'assets/img/mat-icons/payment-methods/busd.svg',
  },
  {
    name: 'payment_dash',
    path: 'assets/img/mat-icons/payment-methods/dash.svg',
  },
  {
    name: 'payment_doge',
    path: 'assets/img/mat-icons/payment-methods/doge.svg',
  },
  {
    name: 'payment_ethereum',
    path: 'assets/img/mat-icons/payment-methods/ethereum.svg',
  },
  {
    name: 'payment_ltc',
    path: 'assets/img/mat-icons/payment-methods/ltc.svg',
  },
  {
    name: 'payment_matic',
    path: 'assets/img/mat-icons/payment-methods/matic.svg',
  },
  {
    name: 'payment_skrill_eur',
    path: 'assets/img/mat-icons/payment-methods/skrill_eur.svg',
  },
  {
    name: 'payment_skrill_usd',
    path: 'assets/img/mat-icons/payment-methods/skrill_usd.svg',
  },
  {
    name: 'payment_tether',
    path: 'assets/img/mat-icons/payment-methods/tether.svg',
  },
  {
    name: 'payment_tether_trc20',
    path: 'assets/img/mat-icons/payment-methods/tether_trc20.svg',
  },
  {
    name: 'payment_ton',
    path: 'assets/img/mat-icons/payment-methods/ton.svg',
  },
  {
    name: 'payment_tron',
    path: 'assets/img/mat-icons/payment-methods/tron.svg',
  },
  {
    name: 'payment_usdc',
    path: 'assets/img/mat-icons/payment-methods/usdc.svg',
  },
  {
    name: 'payment_usdc_trc20',
    path: 'assets/img/mat-icons/payment-methods/usdc_trc20.svg',
  },
];
