import { IconConfig } from '@dev-fast/types';
// TODO Delete -design prefix after release
export const iconsCommonDesign: IconConfig[] = [
  {
    name: 'pause-circle',
    path: 'assets/img/design/common/pause-circle.svg',
  },
  {
    name: 'bell-bold-design',
    path: 'assets/img/design/common/bell-bold.svg',
  },
  {
    name: 'handcuffs--design',
    path: 'assets/img/design/common/handcuffs.svg',
  },
  {
    name: 'minus--design',
    path: 'assets/img/design/common/minus.svg',
  },
  {
    name: 'minus--bold',
    path: 'assets/img/design/common/minus-bold.svg',
  },
  {
    name: 'x--promo',
    path: 'assets/img/design/common/x.svg',
  },
  {
    name: 'plus--design',
    path: 'assets/img/design/common/plus.svg',
  },
  {
    name: 'settings--design',
    path: 'assets/img/design/common/settings.svg',
  },
  {
    name: 'checked--design',
    path: 'assets/img/design/common/status/check.svg',
  },
  {
    name: 'danger-sign--design',
    path: 'assets/img/design/common/status/danger-sign.svg',
  },
  {
    name: 'notification-network-error',
    path: 'assets/img/design/common/status/network-error.svg',
  },
  {
    name: 'arrow--design',
    path: 'assets/img/design/common/arrow.svg',
  },
  {
    name: 'point-arrow--design',
    path: 'assets/img/design/common/point-arrow.svg',
  },
  {
    name: 'double-arrow--design',
    path: 'assets/img/design/common/double-arrow.svg',
  },
  {
    name: 'clock--design',
    path: 'assets/img/design/common/clock.svg',
  },
  {
    name: 'sort-sm-asc--design',
    path: 'assets/img/design/common/sort-sm-asc.svg',
  },
  {
    name: 'sort-sm-desc--design',
    path: 'assets/img/design/common/sort-sm-desc.svg',
  },
  {
    name: 'vortex--design',
    path: 'assets/img/design/common/vortex.svg',
  },
  {
    name: 'search--design',
    path: 'assets/img/design/common/search.svg',
  },
  {
    name: 'close--design',
    path: 'assets/img/design/common/close.svg',
  },
  {
    name: 'lock--design',
    path: 'assets/img/design/common/lock.svg',
  },
  {
    name: 'lock-line--design',
    path: 'assets/img/design/common/lock-line.svg',
  },
  {
    name: 'ticket--design',
    path: 'assets/img/design/common/ticket.svg',
  },
  {
    name: 'arrow-back',
    path: 'assets/img/design/common/arrow-back.svg',
  },
];
