<div
  class="refill-bonus"
  *ngIf="canShow$ | async"
>
  <div class="refill-bonus__content-wrapper">
    <div class="promo-text">{{ 'BANNER.BONUS_FOR' | translate }}</div>
    <div
      class="promo-img"
      *ngIf="imgName$ | async as img"
    >
      <picture>
        <source
          type="image/webp"
          [srcset]="'/assets/img/banners/refill/' + img + '.webp'"
        />
        <img
          [src]="'/assets/img/banners/refill/' + img + '.png'"
          alt="bonus-50-img"
        />
      </picture>
    </div>
    <div class="promo-btn">
      <button
        class="btn btn-primary--design"
        (click)="onLogin()"
      >
        {{ 'BANNER.DEPOSIT_NOW' | translate }}
      </button>
    </div>
    <div class="promo-close-btn">
      <button
        class="btn btn-transparent-basic--design"
        (click)="onClose()"
      >
        <mat-icon svgIcon="close"></mat-icon>
      </button>
    </div>
  </div>
</div>
