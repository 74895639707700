import { IconConfig } from '@dev-fast/types';

export const iconsArrows: IconConfig[] = [
  {
    name: 'caret-down',
    path: 'assets/img/mat-icons/arrows/caret-down.svg',
  },
  {
    name: 'chevron-double-right',
    path: 'assets/img/mat-icons/arrows/chevron-double-right.svg',
  },
  {
    name: 'chevron-double-left',
    path: 'assets/img/mat-icons/arrows/chevron-double-left.svg',
  },
  {
    name: 'chevron-left',
    path: 'assets/img/mat-icons/arrows/chevron-left.svg',
  },
  {
    name: 'arrow-right',
    path: 'assets/img/mat-icons/arrows/arrow-right.svg',
  },
  {
    name: 'arrow-right-up',
    path: 'assets/img/mat-icons/arrows/arrow-right-up.svg',
  },
  {
    name: 'roulette-arrow-left',
    path: 'assets/img/lobby/arrow-left.svg',
  },
  {
    name: 'roulette-arrow-right',
    path: 'assets/img/lobby/arrow-right.svg',
  },
  {
    name: 'long-line-solid',
    path: 'assets/img/mat-icons/arrows/long-line-solid.svg',
  },
  {
    name: 'long-line-dotted',
    path: 'assets/img/mat-icons/arrows/long-line-dotted.svg',
  },
  {
    name: 'short-line-solid',
    path: 'assets/img/mat-icons/arrows/short-line-solid.svg',
  },
  {
    name: 'short-line-dotted',
    path: 'assets/img/mat-icons/arrows/short-line-dotted.svg',
  },
];
