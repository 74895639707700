import { IconConfig } from '@dev-fast/types';

export const iconsNumbers: IconConfig[] = [
  {
    name: 'number-four',
    path: 'assets/img/mat-icons/numbers/four.svg',
  },
  {
    name: 'number-zero',
    path: 'assets/img/mat-icons/numbers/zero.svg',
  },
];
