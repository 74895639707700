import { OverlayRef } from '@angular/cdk/overlay';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TooltipService {
  private readonly activeTooltip: BehaviorSubject<OverlayRef>;

  constructor() {
    this.activeTooltip = new BehaviorSubject<OverlayRef>(null as any);
  }

  public setTooltip(tooltip: OverlayRef) {
    this.closeLastTooltip();
    this.activeTooltip.next(tooltip);
  }

  public closeLastTooltip() {
    if (this.activeTooltip.getValue()) {
      const prevTooltip = this.activeTooltip.getValue();
      prevTooltip.detach();
    }
  }
}
