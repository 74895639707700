<div class="amount-container">
  <button
    class="btn amount-btn"
    [disabled]="true"
  >
    -
  </button>
  <div class="input-wrapper">
    <label class="input-label"></label>
  </div>
  <button
    class="btn amount-btn"
    [disabled]="true"
  >
    +
  </button>
</div>
