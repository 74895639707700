import { Directive, ElementRef, Input, TemplateRef, ViewContainerRef } from '@angular/core';

import { Environments, EnvironmentService } from '@app/core/environment-service';

@Directive({
  selector: '[moduleIsOn]',
})
export class ModuleIsOnDirective {
  private isHidden = true;
  private environments: Environments;
  private module: keyof typeof this.environments.MODULES | undefined;
  private subModule: string | undefined;
  private block: string | undefined;

  constructor(
    private element: ElementRef,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private readonly environmentService: EnvironmentService
  ) {
    this.environments = this.environmentService.getEnvironment();
  }

  @Input()
  public set moduleIsOn(module: keyof typeof this.environments.MODULES) {
    this.module = module;
    this.updateView();
  }

  @Input()
  public set moduleIsOnSub(subModule: string) {
    this.subModule = subModule;
    this.updateView();
  }
  @Input()
  public set moduleIsOnBlock(block: string) {
    this.block = block;
    this.updateView();
  }
  /** todo улучшить*/

  private updateView(): void {
    if (this.checkPermission()) {
      if (this.isHidden) {
        this.viewContainer.createEmbeddedView(this.templateRef);
        this.isHidden = false;
      }
    } else {
      this.isHidden = true;
      this.viewContainer.clear();
    }
  }

  private checkPermission(): boolean {
    if (this.module) {
      return this.environmentService.moduleIsOn(this.module, this.subModule, this.block);
    } else {
      return false;
    }
  }
}
