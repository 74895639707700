import { CommonModule, PercentPipe } from '@angular/common';
import { NgModule } from '@angular/core';

import { AppPercentPipe } from './percent.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [AppPercentPipe],
  exports: [AppPercentPipe],
  providers: [PercentPipe, AppPercentPipe],
})
export class AppPercentModule {}
