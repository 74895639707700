import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { HasPermissionDirective } from './has-permission.directive';
import { HasPermissionService } from './has-permission.service';

@NgModule({
  declarations: [HasPermissionDirective],
  imports: [CommonModule],
  providers: [HasPermissionService],
  exports: [HasPermissionDirective],
})
export class HasPermissionModule {}
