import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { DateFormat, P2pItemStatus } from '@dev-fast/types';
import { filter, interval, map, Observable, of, startWith, switchMap, takeUntil, tap, timer } from 'rxjs';

const HOUR_IN_MS = 3_600_000;
const DAY_IN_MS = 86_400_000;

@Component({
  selector: 'app-ui-skin-statuses-overlay',
  templateUrl: './skin-statuses-overlay.component.html',
  styleUrls: ['./skin-statuses-overlay.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SkinStatusesOverlayComponent implements OnChanges {
  @Input() set skinItemStatus(status: P2pItemStatus) {
    if (status) {
      this.status = status;
      this.darkenBackground = status !== P2pItemStatus.Selected;
    }
  }
  @Input() set tradeLockEndAt(val: string | null) {
    if (val !== null) {
      this.tradelock$ = this.#setTradeLockEndAt$(val);
    }
  }
  // enums
  readonly P2pItemStatus = P2pItemStatus;
  readonly DateFormat = DateFormat;
  // Template
  tradelock$!: Observable<number>;
  darkenBackground = false;
  dateFormat: DateFormat = DateFormat.DEFAULT;
  status: P2pItemStatus = P2pItemStatus.Default;

  ngOnChanges(changes: SimpleChanges): void {
    if ('skinItemStatus' in changes) {
      const change = changes['skinItemStatus'];
      this.status =
        change.previousValue === P2pItemStatus.Sold && change.currentValue === P2pItemStatus.Default ? P2pItemStatus.Sold : this.status;
    }
  }

  #setTradeLockEndAt$(tradeLockEndDate: string): Observable<number> {
    return of(tradeLockEndDate).pipe(
      filter((val) => Date.parse(val) > Date.now()),
      switchMap((val) =>
        interval(1000).pipe(
          startWith(Date.parse(val) - Date.now()),
          map(() => Date.parse(val) - Date.now()),
          tap((currentTime) => (this.dateFormat = this.#getDateFormat(currentTime))),
          takeUntil(timer(Date.parse(val) - Date.now())),
        ),
      ),
    );
  }

  #getDateFormat(date: number): DateFormat {
    return date > DAY_IN_MS ? DateFormat.DAYS : date > HOUR_IN_MS ? DateFormat.DEFAULT : DateFormat.MINUTES;
  }
}
