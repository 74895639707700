export enum TransactionWay {
  REFILL = 'refill',
  WITHDRAWAL = 'withdrawal',
}
/**
 Статус платежа (используется в модалках-нотиках)
 */
export enum TransactionStatus {
  RECEIVED = 'received',
  ERRORED = 'errored',
  COMPLETED = 'completed',
  PAYOUT_SUCCESSFUL = 'payout_successful',
  PAYOUT_ERRORED = 'payout_errored',
}
