import { HttpErrorResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { PayService } from '@dev-fast/backend-services';
import {
  ICountry,
  ICryptoMethodData,
  ICryptoMethodsDto,
  ICryptoUserMethodsDto,
  INewCryptoWalletResponse,
  IPaymentMethodV2,
  IPaymentsDtoV2,
  IPaymentSelectedV2,
  IPaymentTypeV2,
  PaymentSpecialName,
  PaymentTypes,
} from '@dev-fast/types';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { catchError, combineLatest, distinctUntilChanged, Observable, of, tap } from 'rxjs';

import { Environments, EnvironmentService } from '@app/core/environment-service';
import { LocalStorageService } from '@app/core/local-storage-service';
import { NotificationsService } from '@app/core/notification-service';
import { UserState } from '@app/core/state/user-store';
import { paramsToQuery } from '@app/shared/utils';

import { AVAILABLE_WALLETS } from '../constants';
import {
  ChangePaymentsParams,
  CreateNewWallet,
  GetCryptoWallets,
  GetPaymentMethodsNew,
  LeftRefill,
  Refill,
  RefillWithCode,
  SelectCountry,
  SelectPayment,
  SetWalletTabIndex,
  TogglePaymentTypeSize,
} from './';
import { GIFT_CARD_METHOD_DEFAULT, REFILL_INITIAL_STATE, RefillStateModel, SKIN_METHOD_DEFAULT } from './refill-state.model';

@State<RefillStateModel>({
  name: 'refill',
  defaults: REFILL_INITIAL_STATE,
})
@Injectable()
export class RefillState {
  readonly #storageService: LocalStorageService = inject(LocalStorageService);
  readonly #environmentService: EnvironmentService = inject(EnvironmentService);
  readonly #store: Store = inject(Store);
  readonly #apiService: PayService = inject(PayService);
  readonly #notificationsService: NotificationsService = inject(NotificationsService);

  readonly #environment: Environments = this.#environmentService.getEnvironment();

  @Selector()
  static currentCountry({ currentCountry }: RefillStateModel): ICountry | null {
    return currentCountry;
  }
  @Selector()
  static paymentTypesV2({ paymentTypesV2 }: RefillStateModel): Record<number, IPaymentTypeV2> | null {
    return paymentTypesV2;
  }
  @Selector()
  static selectedPayment({ selectedPayment }: RefillStateModel): IPaymentSelectedV2 | null {
    return selectedPayment;
  }
  @Selector()
  static cryptoData({ cryptoData }: RefillStateModel): ICryptoMethodData {
    return cryptoData;
  }

  ngxsOnInit({ patchState }: StateContext<RefillStateModel>): void {
    const currentCountry = this.#storageService.get('refillCountry');
    patchState({
      currentCountry,
    });
  }

  @Action(ChangePaymentsParams)
  changePaymentsParams({ patchState }: StateContext<RefillStateModel>, { params }: ChangePaymentsParams): void {
    patchState({
      params: params,
    });
  }
  @Action(CreateNewWallet)
  createNewWallet(
    { dispatch, getState }: StateContext<RefillStateModel>,
    { userId, walletName }: CreateNewWallet,
  ): Observable<INewCryptoWalletResponse> | void {
    const { cryptoData } = getState();
    const wallet = cryptoData.availableWallets.find((w) => w.name === walletName);
    if (wallet) {
      return this.#apiService.createNewWallet(userId, wallet.network, wallet.name).pipe(
        tap(() => {
          dispatch(new GetCryptoWallets(userId));
        }),
      );
    }
  }
  @Action(GetPaymentMethodsNew)
  getMethodsNew(
    { patchState, dispatch, getState }: StateContext<RefillStateModel>,
    { country, userId }: GetPaymentMethodsNew,
  ): Observable<IPaymentsDtoV2> {
    const { selectedPayment, cryptoData } = getState();
    return this.#apiService.getPaymentMethodsByCountryNew(country.key, userId).pipe(
      distinctUntilChanged((prev, curr) => JSON.stringify(prev) === JSON.stringify(curr)),
      tap((response: IPaymentsDtoV2) => {
        patchState({
          paymentTypesV2: this.#methodsConstructor(response.methods),
        });
        dispatch(new SelectCountry(country));

        const isWalletNumberMethodSelected = selectedPayment?.method?.gate_title === PaymentSpecialName.WALLET_NUMBER;

        if (!isWalletNumberMethodSelected) {
          patchState({
            cryptoData: {
              ...cryptoData,
              availableWallets: [],
            },
          });
        }

        if (isWalletNumberMethodSelected) {
          const requestCountryCryptoWallets = true;
          const requestUserCryptoWallets = false;

          dispatch(new GetCryptoWallets(userId, requestCountryCryptoWallets, requestUserCryptoWallets));
        }
      }),
    );
  }
  @Action(GetCryptoWallets)
  getWallets(
    { patchState, getState }: StateContext<RefillStateModel>,
    { userId, requestCountryCryptoWallets, requestUserCryptoWallets = true }: GetCryptoWallets,
  ): Observable<(ICryptoUserMethodsDto | ICryptoMethodsDto)[]> {
    const { cryptoData, currentCountry } = getState();
    const requests: Observable<ICryptoUserMethodsDto | ICryptoMethodsDto>[] = [];

    if (requestUserCryptoWallets) {
      requests.push(this.#apiService.getUserCryptoWallets(userId).pipe(catchError((err) => this.#onError(err))));
    }

    if (!cryptoData.availableWallets.length || requestCountryCryptoWallets) {
      requests.push(this.#apiService.getCryptoWallets(currentCountry?.key).pipe(catchError((err) => this.#onError(err))));
    }

    return combineLatest(requests).pipe(
      tap((wallets) => {
        const updatedData = {
          ...cryptoData,
        };

        wallets.forEach((wallet) => {
          if (typeof wallet === 'object' && 'wallets' in wallet) {
            updatedData.userWallets = wallet.wallets.filter((w) => AVAILABLE_WALLETS.includes(w.name));
          }
          if (typeof wallet === 'object' && 'list' in wallet && 'exchangers' in wallet) {
            updatedData.availableWallets = wallet.list.filter((w) => AVAILABLE_WALLETS.includes(w.name) && !!w.rate);
            updatedData.exchangers = wallet.exchangers;
          }
        });

        patchState({
          cryptoData: updatedData,
        });
      }),
    );
  }
  @Action(LeftRefill)
  leftRefill({ patchState }: StateContext<RefillStateModel>): void {
    const currentCountry = this.#storageService.get('refillCountry');
    patchState({
      ...REFILL_INITIAL_STATE,
      currentCountry,
    });
  }
  @Action(Refill)
  refill({ getState }: StateContext<RefillStateModel>): void {
    const { selectedPayment, params, currentCountry } = getState();
    const user = this.#store.selectSnapshot(UserState.user);
    const payUrl = this.#environment.PAYMENT_URL;
    if (selectedPayment && selectedPayment.method && params && user) {
      const amount = params.realCurrency.toFixed(2);
      const name =
        selectedPayment.method.method_name !== 'crypto'
          ? selectedPayment.method.method_name
          : selectedPayment.method.gate_title.toLowerCase();
      let currency = 'USD';
      if (!selectedPayment.method?.currency.includes(currency)) {
        currency = selectedPayment.method.currency[0];
      }
      window.open(
        `${payUrl}/new_pay/${name}/${user.id}/${amount}${paramsToQuery({
          hostname: window.location.hostname,
          gate: selectedPayment.method.gate_id,
          country: currentCountry?.key,
          currency: currency,
          lang: user.locale,
          wallet: params.walletType,
        })}`,
      );
    }
  }
  @Action(RefillWithCode)
  refillWithCode({ getState }: StateContext<RefillStateModel>, { code }: RefillWithCode): void {
    const { selectedPayment, currentCountry } = getState();
    const user = this.#store.selectSnapshot(UserState.user);
    const payUrl = this.#environment.PAYMENT_URL;
    if (selectedPayment && selectedPayment.method && user) {
      const name =
        selectedPayment.method.method_name !== 'crypto'
          ? selectedPayment.method.method_name
          : selectedPayment.method.gate_title.toLowerCase();

      let currency = 'USD';
      if (!selectedPayment.method?.currency.includes(currency)) {
        currency = selectedPayment.method.currency[0];
      }
      window.open(
        `${payUrl}/new_pay/${name}/${user.id}/${5}${paramsToQuery({
          hostname: window.location.hostname,
          gate: selectedPayment.method.gate_id,
          country: currentCountry?.key,
          currency: currency,
          lang: user.locale,
          wallet: code ? code : selectedPayment.method.walletType,
        })}`,
      );
    }
  }
  @Action(SelectCountry)
  selectCountry({ patchState }: StateContext<RefillStateModel>, { country }: SelectCountry): void {
    patchState({
      currentCountry: country,
    });
    this.#storageService.set('refillCountry', country);
  }
  @Action(SelectPayment)
  selectPayment({ patchState, getState }: StateContext<RefillStateModel>, { activePayment }: SelectPayment): void {
    const { paymentTypesV2 } = getState();
    if (paymentTypesV2) {
      if (activePayment?.type) {
        const type: IPaymentTypeV2 | undefined = Object.values(paymentTypesV2).find((t) => {
          return t.name === activePayment.type;
        });
        if (type) {
          const method = type.methods.find((m) => {
            return m.method_name === activePayment.method && m.gate_title === activePayment.gate;
          });
          patchState({
            selectedPayment: { type: type, method: method },
          });
        }
      }
    }
    if (!activePayment) {
      patchState({
        selectedPayment: null,
      });
    }
  }
  @Action(SetWalletTabIndex)
  setWalletTabIndex({ patchState, getState }: StateContext<RefillStateModel>, { index }: SetWalletTabIndex): void {
    const { cryptoData } = getState();
    patchState({
      cryptoData: { ...cryptoData, activeWalletIndex: index },
    });
  }
  /*
    Экшн переключения флага isExpanded (свернуть/развернуть категорию)
  */
  @Action(TogglePaymentTypeSize)
  togglePaymentTypeSize({ patchState, getState }: StateContext<RefillStateModel>, { isExpanded, typeIndex }: TogglePaymentTypeSize): void {
    const { paymentTypesV2 } = getState();
    if (paymentTypesV2) {
      const updatedPaymentTypes: Record<number, IPaymentTypeV2> = {
        ...paymentTypesV2,
        [typeIndex]: { ...paymentTypesV2[typeIndex], isExpanded: !isExpanded },
      };
      patchState({
        paymentTypesV2: updatedPaymentTypes,
      });
    }
  }
  /*
    Метод сборки категорий из новой апишки
  */
  #methodsConstructor(methods: IPaymentMethodV2[]): Record<number, IPaymentTypeV2> {
    const indexDictionary = {
      popular: 0,
      cards: 1,
      crypto: 2,
      regional: 3,
    };

    return [SKIN_METHOD_DEFAULT, ...methods, GIFT_CARD_METHOD_DEFAULT].reduce(
      (acc: Record<number, IPaymentTypeV2>, cur: IPaymentMethodV2) => {
        if (!cur.group_name.length) {
          if (cur.is_popular || cur.method_name === PaymentSpecialName.GIFT) {
            const updatedMethod = {
              name: PaymentTypes.POPULAR,
              isExpanded: false,
              methods: acc[indexDictionary[PaymentTypes.POPULAR]] ? [...acc[indexDictionary[PaymentTypes.POPULAR]].methods, cur] : [cur],
            };
            return { ...acc, [indexDictionary[PaymentTypes.POPULAR]]: updatedMethod };
          }
          const updatedMethod = {
            name: PaymentTypes.REGIONAL,
            isExpanded: false,
            methods: acc[indexDictionary[PaymentTypes.REGIONAL]] ? [...acc[indexDictionary[PaymentTypes.REGIONAL]].methods, cur] : [cur],
          };
          return { ...acc, [indexDictionary[PaymentTypes.REGIONAL]]: updatedMethod };
        }
        let updatedAcc = { ...acc };
        cur.group_name.forEach((name: PaymentTypes) => {
          const trimName = name.trim() as PaymentTypes;
          const updatedMethod = {
            name: name,
            isExpanded: false,
            methods: updatedAcc[indexDictionary[trimName]] ? [...updatedAcc[indexDictionary[trimName]].methods, cur] : [cur],
          };
          updatedAcc = { ...updatedAcc, [indexDictionary[trimName]]: updatedMethod };
        });
        return updatedAcc;
      },
      {},
    );
  }

  #onError(e: HttpErrorResponse, msg?: string, system = true): Observable<never> {
    const { error } = e;
    this.#notificationsService.addErrorNotification(msg || error.message || error.error, {
      icon: 'error',
      system: error.system || system,
    });
    return of();
  }
}
