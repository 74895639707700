export const combineItems = (
  items: number[] = [],
  maximum: number = 3000,
  minimumItems: number = 1,
  limitItems: number = 25
): number[] => {
  const thousandRest = maximum % 10;
  const confusingRest = [];
  if (thousandRest > 50 && thousandRest !== maximum) {
    confusingRest.push(thousandRest);
    maximum -= thousandRest;
  }
  const fillUntilValue = (array: number[], item: number, max: number): any => {
    if (item > max) {
      return [array, max];
    }
    if (max - item === 0) {
      if (minimumItems > 1 && array.length < minimumItems) {
        return [array, max];
      }
    }
    return fillUntilValue([item, ...array], item, max - item);
  };
  const result = [...items]
    .sort((i1, i2) => i2 - i1)
    .filter((item) => item <= maximum)
    .reduce((res, item) => {
      if (maximum < item) return res;
      const respond = fillUntilValue(res, item, maximum);
      maximum = respond[1];
      return respond[0];
    }, [])
    .reverse();
  if (limitItems > 0) {
    return [...result, ...confusingRest].slice(0, limitItems).reverse();
  }
  return [...result, ...confusingRest].reverse();
};
