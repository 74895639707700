import { Injectable } from '@angular/core';
import { NavigationExtras, Params } from '@angular/router';
import { IFaq, IFaqArticleItems } from '@dev-fast/types';
import { Navigate } from '@ngxs/router-plugin';
import { Select, Store } from '@ngxs/store';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { map, Observable } from 'rxjs';

import { ToggleBackground } from '@app/core/state/layout';

import { ChangeIsActive, FaqState, GetItems } from '../state';

@Injectable({
  providedIn: 'root',
})
export class FaqService {
  @Select(FaqState.currentLangFaq) readonly faq$!: Observable<IFaq[]>;
  @Select(FaqState.activeSection) readonly activeSection$!: Observable<string>;
  @Select(FaqState.activePosition) readonly activePosition$!: Observable<string>;
  @Select(FaqState.selectedItem) readonly selectedItem$!: Observable<IFaqArticleItems | undefined>;
  constructor(private readonly store: Store) {}
  getItemsByQuery(query: string | null): Observable<IFaq[] | undefined> {
    return this.store.select(FaqState.filteredItems).pipe(map((filterFn) => filterFn(query)));
  }
  @Dispatch() getItems = (payload?: { activeMenu?: string; lang?: string }): GetItems => new GetItems(payload);
  @Dispatch() changeIsActive = (isActive: boolean): ChangeIsActive => new ChangeIsActive(isActive);
  @Dispatch() toggleBackground = (background?: string): ToggleBackground => new ToggleBackground(background);
  @Dispatch()
  navigateTo(path: any[], queryParams?: Params | undefined, extras?: NavigationExtras | undefined): Navigate {
    return new Navigate(path, queryParams, extras);
  }
}
