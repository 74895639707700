import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { GameStatus, IGame, ViewportType } from '@dev-fast/types';

@Component({
  selector: 'app-ui-games-item',
  templateUrl: './game-type-item.component.html',
  styleUrls: ['./game-type-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GameTypeItemComponent {
  @Input() public game!: IGame;
  @Input() public status!: GameStatus;
  @Input() public isShowFullView!: boolean | null;
  @Input() public isNew!: boolean;
  @Input() public view: ViewportType;

  @Output() public AllGamesPanelStatus: EventEmitter<boolean>;

  public readonly devices = ViewportType;

  constructor() {
    this.AllGamesPanelStatus = new EventEmitter<boolean>();
    this.view = ViewportType.TABLET;
  }
  public proceedPanelStatus(): void {
    this.AllGamesPanelStatus.emit(false);
  }
}
